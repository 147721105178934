import * as React from 'react';
import { initI18n } from '../../utils';
import Location from '../../Location';
import FlightCard from '../../FlightCard';
import { getDurationString } from '../../Results/utils';
import { useTheme } from '../../theme';
import TransferInfo from '../../TransferInfo';
initI18n('FlightInfo');
const FlightInfo = ({ flight }) => {
    const { FlightInfo: css } = useTheme('FlightInfo');
    const getNextSegment = (index) => flight.segments[index + 1].segment;
    return (React.createElement("div", null, flight.segments.map(({ transferDuration, segment }, index) => {
        var _a, _b, _c, _d;
        return (React.createElement("div", { key: segment.flightNumber, className: css.segment },
            React.createElement("div", { className: css.segment__info },
                React.createElement(Location, Object.assign({ className: css.segment__location, type: "departure" }, segment.departure)),
                segment.aircraft && (React.createElement(FlightCard, { className: css.segment__flight, airline: segment.operatingAirline, flightNumber: `${(_a = segment.marketingAirline) === null || _a === void 0 ? void 0 : _a.iata}-${segment.flightNumber}`, duration: getDurationString(segment.duration), aircraft: segment.aircraft, amenities: (_b = segment.flightInfo) === null || _b === void 0 ? void 0 : _b.amenities, markers: (_c = segment.flightInfo) === null || _c === void 0 ? void 0 : _c.markers, statistics: (_d = segment.flightInfo) === null || _d === void 0 ? void 0 : _d.statistics })),
                React.createElement(Location, Object.assign({ className: css.segment__location, type: "arrival" }, segment.arrival))),
            transferDuration && (React.createElement(TransferInfo, { className: css.segment__transfer, segment: segment, nextSegment: getNextSegment(index), transferDuration: transferDuration, showStops: true }))));
    })));
};
export default FlightInfo;
