import cn from 'classnames';
import * as React from 'react';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
import { CloseButton } from '../../../index';
initI18n('FullscreenDialog');
const FullscreenDialogClose = ({ text, onClose, closeClassName, textClassName }) => {
    const { FullscreenDialog: theme } = useTheme('FullscreenDialog');
    const { t } = useTranslation('FullscreenDialog');
    return (React.createElement("div", { className: cn(theme.close, closeClassName), onClick: onClose },
        React.createElement("span", { className: cn(theme.close__text, textClassName) }, text !== null && text !== void 0 ? text : t('Close')),
        React.createElement(CloseButton, { variant: "closeFullscreen", className: theme.close__icon })));
};
export default FullscreenDialogClose;
