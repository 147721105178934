import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { ArrowBack } from '../../Icons';
import { initI18n } from '../../utils';
import { AGENT_FORM_OF_PAYMENT, AGENT_NAME, AGENT_PHONE_NUMBER, AGENT_URL, PASSENGER_DATE_OF_SALE, PASSENGER_FIRST_NAME, PASSENGER_LAST_NAME, PASSENGER_LOYALTY_CARD_NUMBER, PASSENGER_PASSPORT_NUMBER, PASSENGER_RESERVATION_NUMBER, PASSENGER_TICKET_NUMBER } from './ticketExampleData';
initI18n('TicketExample');
const TicketExample = () => {
    const { TicketExample: css } = useTheme('TicketExample');
    const { t } = useTranslation('TicketExample');
    return (React.createElement("div", { className: css.ticketExample },
        React.createElement("div", { className: css.ticketExample__leftSide },
            React.createElement("div", { className: css.ticketExample__header }, t('E-Ticket')),
            React.createElement("div", { className: css.ticketExample__flightInfo },
                React.createElement("div", { className: css.ticketExample__content },
                    React.createElement("div", { className: css.ticketExample__passengerName },
                        React.createElement("div", null, PASSENGER_FIRST_NAME),
                        React.createElement("div", { className: css.allocated },
                            PASSENGER_LAST_NAME,
                            React.createElement("div", { className: css.icon }, ArrowBack))),
                    React.createElement("div", { className: css.ticketExample__contentGroupItems },
                        React.createElement("div", { className: css.ticketExample__contentItem },
                            React.createElement("div", { className: css.ticketExample__contentItemLabel }, t('№ Passport')),
                            React.createElement("div", { className: css.ticketExample__contentItemValue }, PASSENGER_PASSPORT_NUMBER)),
                        React.createElement("div", { className: cn(css.ticketExample__contentItem, css.allocated) },
                            React.createElement("div", { className: css.ticketExample__contentItemLabel }, t('№ Reservation')),
                            React.createElement("div", { className: css.ticketExample__contentItemValue }, PASSENGER_RESERVATION_NUMBER),
                            React.createElement("div", { className: css.icon }, ArrowBack)))))),
        React.createElement("div", { className: css.ticketExample__rightSide },
            React.createElement("div", { className: css.ticketExample__header },
                React.createElement("div", { className: css.ticketExample__headerAgent },
                    t('Agent'),
                    " ",
                    React.createElement("span", null, AGENT_NAME)),
                React.createElement("div", { className: css.ticketExample__headerContacts },
                    React.createElement("span", null, AGENT_URL),
                    React.createElement("span", null, AGENT_PHONE_NUMBER))),
            React.createElement("div", { className: css.ticketExample__flightInfo },
                React.createElement("div", { className: css.ticketExample__content },
                    React.createElement("div", { className: cn(css.ticketExample__contentItem, css.allocated) },
                        React.createElement("div", { className: css.ticketExample__contentItemLabel }, t('№ Ticket')),
                        React.createElement("div", { className: css.ticketExample__contentItemValue }, PASSENGER_TICKET_NUMBER),
                        React.createElement("div", { className: css.icon }, ArrowBack)),
                    React.createElement("div", { className: css.ticketExample__contentItem },
                        React.createElement("div", { className: css.ticketExample__contentItemLabel }, t('Date of sale')),
                        React.createElement("div", { className: css.ticketExample__contentItemValue }, PASSENGER_DATE_OF_SALE)),
                    React.createElement("div", { className: css.ticketExample__contentItem },
                        React.createElement("div", { className: css.ticketExample__contentItemLabel }, t('№ Loyalty program card')),
                        React.createElement("div", { className: css.ticketExample__contentItemValue }, PASSENGER_LOYALTY_CARD_NUMBER)))),
            React.createElement("div", { className: css.ticketExample__paymentInfo },
                t('Form of payment'),
                " ",
                React.createElement("span", null, AGENT_FORM_OF_PAYMENT)))));
};
export default TicketExample;
