import * as React from 'react';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@material-ui/core/ButtonBase';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import Tooltip from '../../../Tooltip';
import { PassengerType } from '../../../enums';
import { infoIcon, minusIcon, mobileMinusIcon, mobilePlusIcon, plusIcon } from '../../icons';
import { useTheme } from '../../../theme';
export const Passenger = React.memo(props => {
    const { t } = useTranslation('Passengers');
    const titles = {
        [PassengerType.Adult]: t('Adults'),
        [PassengerType.Teenager]: t('Teenagers'),
        [PassengerType.Child]: t('Children'),
        [PassengerType.Infant]: t('Infants'),
        [PassengerType.InfantWithSeat]: t('Infants with seat'),
        [PassengerType.YoungAdult]: t('Young adult'),
        [PassengerType.RetireeM]: t('Senior (men)'),
        [PassengerType.RetireeF]: t('Senior (female)'),
        [PassengerType.Retiree_F7]: t('Senior'),
        [PassengerType.Teenager_F7]: t('Teenagers'),
        [PassengerType.InfantWithSeat_RVT]: t('Infants with seat'),
        [PassengerType.Adult_D2]: t('Adults'),
        [PassengerType.Child_D2]: t('Children'),
        [PassengerType.Infant_D2]: t('Infants'),
        [PassengerType.Retiree_D2]: t('Senior'),
        [PassengerType.InfantWithSeat_D2]: t('Infants with seat'),
        [PassengerType.AdultWithChild_D2]: t('Adult with child'),
        [PassengerType.Family_D2]: t('Family'),
        [PassengerType.Teenager_D2]: t('Teenagers')
    };
    const hints = {
        [PassengerType.Adult]: t('12+ years'),
        [PassengerType.Teenager]: t('12-18 years'),
        [PassengerType.Child]: t('2-11 years'),
        [PassengerType.Infant]: t('Up to 2 years'),
        [PassengerType.InfantWithSeat]: t('Up to 2 years'),
        [PassengerType.YoungAdult]: t('12-23 years'),
        [PassengerType.RetireeF]: t('55+ years'),
        [PassengerType.RetireeM]: t('60+ years'),
        [PassengerType.Teenager_GCF]: t('12-18 years'),
        [PassengerType.Retiree_F7]: t('men 60+ female 55+'),
        [PassengerType.Teenager_F7]: t('12-23 years'),
        [PassengerType.InfantWithSeat_RVT]: t('Up to 2 years'),
        [PassengerType.Adult_D2]: t('12+ years'),
        [PassengerType.Child_D2]: t('2-11 years'),
        [PassengerType.Infant_D2]: t('Up to 2 years'),
        [PassengerType.Retiree_D2]: t('men 60+ female 55+'),
        [PassengerType.InfantWithSeat_D2]: t('Up to 2 years'),
        [PassengerType.AdultWithChild_D2]: t('Adults 18-100 children 0-14'),
        [PassengerType.Family_D2]: t('Adults 18-100 children 0-18'),
        [PassengerType.Teenager_D2]: t('12-18 years')
    };
    const descriptions = {
        [PassengerType.Adult]: t('Adults over twelve years old'),
        [PassengerType.Teenager]: t('Young peoples from twelve to eighteen years old'),
        [PassengerType.Child]: t('Children from two to eleven years old'),
        [PassengerType.Infant]: t('Infants up to two years old, without seat'),
        [PassengerType.InfantWithSeat]: t('Infants up to two years old, with seat'),
        [PassengerType.YoungAdult]: t('Young peoples 12-23 years old'),
        [PassengerType.RetireeM]: t('Senior men up 60 years'),
        [PassengerType.RetireeF]: t('Senior women up 55 years'),
        [PassengerType.Teenager_GCF]: t('Young peoples 12-18 years old'),
        [PassengerType.Retiree_F7]: t('Senior men up 60 years, Senior women up 55 years'),
        [PassengerType.Teenager_F7]: t('12-23 years'),
        [PassengerType.InfantWithSeat_RVT]: t('Infants up to two years old, with seat'),
        [PassengerType.Adult_D2]: t('Adults over twelve years old'),
        [PassengerType.Child_D2]: t('Children from two to eleven years old'),
        [PassengerType.Infant_D2]: t('Infants up to two years old, without seat'),
        [PassengerType.Retiree_D2]: t('Senior men up 60 years, Senior women up 55 years'),
        [PassengerType.InfantWithSeat_D2]: t('Infants up to two years old, with seat'),
        [PassengerType.AdultWithChild_D2]: t('Adults 18 years, children up to 14 years'),
        [PassengerType.Family_D2]: t('Adults 18 years, children up to 18 years'),
        [PassengerType.Teenager_D2]: t('Young peoples 12-18 years old')
    };
    const { PassengerStyles: css } = useTheme('Passengers'), hint = hints[props.passenger.type], description = descriptions[props.passenger.type];
    return (React.createElement("div", { className: css.passenger, "data-passenger-type": props.passenger.type },
        React.createElement("div", { className: css.info },
            React.createElement("div", { className: css.passengerType }, titles[props.passenger.type]),
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement(Tooltip, { title: description },
                    React.createElement("div", { className: css.age },
                        hint,
                        React.createElement("span", { className: css.info__icon }, infoIcon)))),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { className: css.age }, hint),
                React.createElement("div", { className: css.description }, description))),
        React.createElement("div", { className: css.controls },
            React.createElement(ButtonBase, { className: cn(css.control, css.decrease), onClick: () => props.removePassenger({
                    passengerType: props.passenger.type,
                    modifyCount: props.passenger.modifyCount
                }), disabled: !props.decreaseIsEnabled },
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, minusIcon),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, mobileMinusIcon)),
            React.createElement("div", { className: cn(css.counter, {
                    [css.counter_disabled]: props.passenger.count === 0
                }), "data-passenger-type": props.passenger.type }, props.passenger.count),
            React.createElement(ButtonBase, { className: cn(css.control, css.increase), onClick: () => props.addPassenger({
                    passengerType: props.passenger.type,
                    modifyCount: props.passenger.modifyCount
                }), disabled: !props.increaseIsEnabled },
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, plusIcon),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, mobilePlusIcon)))));
}, (prev, next) => {
    return (next.passenger.count === prev.passenger.count &&
        next.passenger.type === prev.passenger.type &&
        next.removePassenger === prev.removePassenger &&
        next.addPassenger === prev.addPassenger &&
        next.increaseIsEnabled === prev.increaseIsEnabled &&
        next.decreaseIsEnabled === prev.decreaseIsEnabled);
});
