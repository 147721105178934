import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { TravellerFields } from '../../../../TravellerForm/types';
import { useTranslation } from 'react-i18next';
import { overridedIcons } from '../../../../Icons';
const Header = ({ passengerId, label, numberIcon, correct, onClick, passengerFieldsMap, open, showErrorNotice }) => {
    const theme = useTheme('PassengerForm').Header;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const { t } = useTranslation('Passenger');
    return (React.createElement("div", { className: cn(theme.header, {
            [theme.filled]: correct,
            [theme.collapsed]: !open,
            [theme.header_error]: showErrorNotice
        }), onClick: onClick },
        React.createElement("div", { className: theme.number }, numberIcon || passengerId + 1),
        React.createElement("div", { className: theme.payload },
            React.createElement("div", { className: theme.passengerName }, label),
            isMobile && showErrorNotice && React.createElement("div", { className: theme.error }, t('Please, check passenger data')),
            isMobile && correct && passengerFieldsMap && (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    passengerFieldsMap[TravellerFields.Gender],
                    ",",
                    ' ',
                    passengerFieldsMap[TravellerFields.BirthDate],
                    ",",
                    ' ',
                    passengerFieldsMap[TravellerFields.Nationality]),
                passengerFieldsMap['docType'] && (React.createElement("div", null,
                    passengerFieldsMap['docType'],
                    ": ",
                    passengerFieldsMap['docNumber'])),
                passengerFieldsMap['docExpiryDate'] && (React.createElement("div", null,
                    t('docExpiryDate'),
                    ": ",
                    passengerFieldsMap['docExpiryDate'])),
                passengerFieldsMap['loyaltyNumber'] && (React.createElement("div", { className: theme.loyalty_number },
                    t('loyaltyNumber'),
                    ": ",
                    passengerFieldsMap['loyaltyNumber']))))),
        correct && !numberIcon && overridedIcons['Included']));
};
export default Header;
