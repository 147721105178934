import * as React from 'react';
export const SeatIcon = (React.createElement("svg", { width: "20", height: "15", viewBox: "0 0 20 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M0 2C0 0.89543 0.895431 0 2 0H11.7V15H2C0.895429 15 0 14.1046 0 13V2Z", fill: "currentColor" }),
    React.createElement("path", { d: "M11.7002 0H17.5002C18.6047 0 19.5002 0.895431 19.5002 2V13C19.5002 14.1046 18.6047 15 17.5002 15H11.7002V0Z", fill: "currentColor" }),
    React.createElement("rect", { x: "17.0088", y: "4.32458", width: "2.49105", height: "6.35074", fill: "url(#paint0_linear)" }),
    React.createElement("rect", { opacity: "0.25", x: "10.9199", width: "0.78", height: "15", fill: "#1A1A1A" }),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear", x1: "18.0608", y1: "7.49996", x2: "18.6994", y2: "7.49996", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#F2F2F2", stopOpacity: "0.53" }),
            React.createElement("stop", { offset: "1", stopColor: "#F2F2F2" })))));
export const ChevronRightIcon = (React.createElement("svg", { width: "8", height: "12", viewBox: "0 0 8 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z", fill: "currentColor" })));
