import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from '../../../theme';
import Button from '../../../Button';
import * as Icons from '../../../Icons';
import { useTranslation } from 'react-i18next';
import TabButton from '../../../Button/components/TabButton/TabButton';
import cn from 'classnames';
import { useRouteMatch, generatePath, useLocation } from 'react-router-dom';
import { matchPath, Route, Switch, useHistory } from 'react-router-dom';
import Loader from '../../../Loader';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { Badge } from '../../../Badge/Badge';
export const NotificationsPage = props => {
    const { Notifications: css, Communication: baseCss } = useTheme('Communications');
    const { t } = useTranslation('Communications');
    const { path, params } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState();
    const tabRefs = React.useRef({});
    useEffect(() => {
        if ((params === null || params === void 0 ? void 0 : params.categoryId) !== activeTab) {
            const category = props.categories.find(category => category.path === params.categoryId);
            if (category && activeTab !== category.id) {
                setActiveTab(category.id);
                if (tabRefs.current) {
                    tabRefs.current[category.id].scrollIntoView({
                        behavior: 'smooth',
                        inline: 'center',
                        block: 'nearest'
                    });
                }
            }
        }
    }, [params, props.categories]);
    useEffect(() => {
        const scrollToTabEl = () => {
            if (activeTab && tabRefs.current) {
                tabRefs.current[activeTab].scrollIntoView({
                    behavior: 'smooth',
                    inline: 'center',
                    block: 'nearest'
                });
            }
        };
        screen.orientation.addEventListener('change', scrollToTabEl);
        return () => screen.orientation.removeEventListener('change', scrollToTabEl);
    }, [activeTab, tabRefs.current]);
    const onBack = () => {
        const newPath = location.pathname.split('/').slice(0, -1).join('/');
        history.push(newPath);
    };
    const onClickTab = (id) => {
        history.push(generatePath(path, { categoryId: id }));
    };
    const hasUnreadMessageInCategory = useMemo(() => {
        const category = props.categories.find(category => category.id === activeTab);
        return !!(category && category.unreadMessagesCount);
    }, [props.categories, activeTab]);
    return (React.createElement("div", null,
        React.createElement("div", { className: css.notifications__head },
            React.createElement("div", { className: css.notifications__top },
                React.createElement(MediaQuery, { maxWidth: 'mobile' },
                    React.createElement(Button, { variant: "text", onClick: onBack, className: cn(baseCss.btn, baseCss.btn_back) }, Icons.ArrowBack)),
                React.createElement("h1", { className: baseCss.title }, t('Notifications'))),
            React.createElement("div", { className: css.notifications__sub },
                React.createElement(MediaQuery, { minWidth: 'mobile' },
                    React.createElement(Button, { variant: "text", onClick: onBack, className: cn(baseCss.btn, baseCss.btn_back) },
                        Icons.ArrowBack,
                        t('Back'))),
                hasUnreadMessageInCategory && matchPath(location.pathname, { path, exact: true }) && (React.createElement(Button, { variant: "text", className: cn(baseCss.btn, baseCss.btn_read), onClick: () => props.markAllAsRead(activeTab) },
                    props.loadingRead ? (React.createElement(Loader, { className: baseCss.btn__loader, spinnerClassName: baseCss.btn__loaderSpinner, hideText: true })) : (Icons.Choosed),
                    t('Mark all as read')))),
            React.createElement("div", { className: css.notifications__tabs }, props.categories.map(tab => (React.createElement("div", { key: tab.id, ref: el => (tabRefs.current[tab.id] = el) },
                React.createElement(TabButton, { className: css.notifications__tabsItem, onClick: () => onClickTab(tab.path), inverted: true, active: activeTab === tab.id },
                    React.createElement("span", null, t(tab.title)),
                    tab.unreadMessagesCount > 0 && (React.createElement(Badge, { value: tab.unreadMessagesCount, active: activeTab === tab.id })))))))),
        React.createElement(Switch, null,
            props.categories.map(item => (React.createElement(Route, { exact: true, key: item.id, path: generatePath(path, { categoryId: item.path }) }, item.listComponent))),
            props.categories.map(item => (React.createElement(Route, { exact: true, key: `page-detail-${item.id}`, path: `${generatePath(path, { categoryId: item.path })}/:detailId` }, item.detailComponent))))));
};
