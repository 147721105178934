import * as React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import { LocationType } from '../../../types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import ModalTransitionComponent from '../../../ModalTransitionComponent';
const closeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z", fill: "#9A9A9A" })));
const MobileDialog = ({ isOpen, options, onClose, onInputChange, onSelect, renderOption, renderGroup, type }) => {
    const { MobileDialog: css } = useTheme('LocationAutocomplete');
    const [query, setQuery] = useState('');
    const { t } = useTranslation('LocationAutocomplete');
    const onChange = (event) => {
        setQuery(event.target.value);
        if (onInputChange) {
            onInputChange(event);
        }
    };
    return (React.createElement(Dialog, { open: isOpen, fullScreen: true, disableRestoreFocus: true, fullWidth: true, keepMounted: true, TransitionComponent: ModalTransitionComponent, classes: {
            root: css.modal
        } },
        React.createElement("div", { className: css.dialog },
            React.createElement("div", { className: cn(css.header, {
                    [css.header_white]: !!query
                }) },
                React.createElement("input", { className: css.input, type: "text", placeholder: `${t(type === LocationType.Departure ? 'From' : 'To')}`, value: query, onChange: onChange, autoFocus: true, spellCheck: false, autoComplete: "one-time-code", autoCorrect: "off" }),
                React.createElement("div", { className: cn(css.hint, {
                        [css.hint_hidden]: query
                    }) }, t('Enter city or country')),
                React.createElement("div", { className: css.closer, onClick: onClose }, closeIcon)),
            React.createElement("div", { className: css.content }, options.map((item, index) => 'value' in item ? renderOption(item, index) : renderGroup(item, index))))));
};
export default MobileDialog;
