import i18n from 'i18next';
import { createSelector } from 'reselect';
import { getSegments } from '../order/selectors';
import { ALL_FLIGHT } from './reducers';
export const getFlightIdsToExchange = (state) => state.flightsToExchange;
export const getExchangeFlights = createSelector(getSegments, segments => {
    let flights = [];
    if (segments.length > 1) {
        flights = [...flights, { key: ALL_FLIGHT, value: i18n.t('Exchange:Whole flight') }];
    }
    segments.forEach(segment => {
        const departure = segment.segments[0].departure;
        const arrival = segment.segments[segment.segments.length - 1].arrival;
        flights = [
            ...flights,
            {
                key: segment.groupId,
                value: [departure.airport.city.name, arrival.airport.city.name].join(' - '),
                date: new Date(departure.date)
            }
        ];
    });
    return flights;
});
export const getIsExchangeFlightsSelected = createSelector(getFlightIdsToExchange, selectedExchangeFlights => {
    return selectedExchangeFlights.filter(flight => flight.key !== ALL_FLIGHT).every(flight => flight.date);
});
