import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { setLocation, switchLocation } from '../../store/segments/actions';
import { LocationType } from '../../types';
import { getSegments } from '../../store/segments/selectors';
import Location from '../Location/Location';
import Button from '../../../Button';
import { Search, Sort } from '../../../Icons';
import { setFieldMutator } from '../../utils';
const ScheduleForm = ({ className, onSubmit, onSelect, onSwitch, onClear }) => {
    const { ScheduleForm: theme } = useTheme('ScheduleForm');
    const { t } = useTranslation('ScheduleForm');
    const dispatch = useDispatch();
    const segments = useSelector(getSegments);
    const onSubmitHandler = useCallback(() => onSubmit({
        departure: segments.departure.iata,
        arrival: segments.arrival.iata
    }), [onSubmit, segments]);
    const onSelectLocation = useCallback((location, locationType) => {
        dispatch(setLocation({ location, locationType }));
        onSelect === null || onSelect === void 0 ? void 0 : onSelect(location, locationType);
    }, [dispatch, onSelect]);
    const onSwitchLocation = useCallback(() => {
        dispatch(switchLocation());
        onSwitch === null || onSwitch === void 0 ? void 0 : onSwitch();
    }, [dispatch, onSwitch]);
    return (React.createElement("div", { className: cn(className, theme.container) },
        React.createElement("div", { className: theme.wrapper },
            React.createElement("div", { className: theme.title }, t('Schedule')),
            React.createElement(Form, { onSubmit: onSubmitHandler, mutators: { setField: setFieldMutator } }, ({ handleSubmit }) => (React.createElement("form", { className: theme.form, onSubmit: handleSubmit },
                React.createElement("div", { className: theme.locations },
                    React.createElement(Button, { className: theme.buttonSwitch, onClick: onSwitchLocation }, Sort),
                    React.createElement(Location, { className: cn(theme.location, theme.from), inputClassName: theme.input, placeholder: t('From'), locations: segments, onSelect: onSelectLocation, onClear: onClear, type: LocationType.Departure }),
                    React.createElement(Location, { className: cn(theme.location, theme.to), inputClassName: theme.input, placeholder: t('To'), locations: segments, onSelect: onSelectLocation, onClear: onClear, type: LocationType.Arrival })),
                React.createElement(Button, { className: theme.button, type: "submit" }, Search)))))));
};
export default ScheduleForm;
