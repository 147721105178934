import * as React from 'react';
import { useCallback, useMemo } from 'react';
import MediaQuery from '../../../../../MediaQuery/MediaQuery';
import cn from 'classnames';
import { SeatComfort } from '@websky/graphql';
import { findPassengerWithThisSeat } from '../../Seat/utils';
import { CombinedDirection } from '../../../../types';
import { useTheme } from '../../../../../theme';
import Seat from '../../Seat/Seat';
import { getAllowedPassengerSeatServices, getSeatAvailability } from './utils';
import { useTranslation } from 'react-i18next';
import { cabinCodeComfortMap, getActualService, getMinPriceOfServiceClass } from '../../utils';
import { OverrideComponent } from '../../../../../renderProps';
import { useConfig } from '../../../../../context';
import { useDoubleSeat } from '../../../../hooks';
import TechnicalFacilities from './TechnicalFacilities/TechnicalFacilities';
import SeatPrice from '../../../SeatPrice/SeatPrice';
import { getSeatPrice, isZeroPrice } from '../../../../utils';
const Parts = React.memo(({ part, nextPartService, prevPartService, partIndex, passengerId, serviceId, segmentId, isSeatMapWide, passengers, allSeatServices, partService, isCheckinMode, onSelect, newClass, onClear, isStatic, rows, rowIndex, activeDoubleSeats, rowsWithDifferentClasses }) => {
    var _a, _b;
    const theme = useTheme('SeatMap').SeatMapStyles;
    const { t } = useTranslation('SeatMap');
    const nextPartServiceId = nextPartService === null || nextPartService === void 0 ? void 0 : nextPartService.comfort;
    const prevPartServiceId = prevPartService === null || prevPartService === void 0 ? void 0 : prevPartService.comfort;
    const passengerServices = (_a = allSeatServices === null || allSeatServices === void 0 ? void 0 : allSeatServices[segmentId]) === null || _a === void 0 ? void 0 : _a[passengerId];
    const actualService = React.useMemo(() => getActualService(passengerServices, part), [passengerServices, part]);
    const minServicePrice = newClass && actualService
        ? getMinPriceOfServiceClass(rows, rowIndex, actualService, rowsWithDifferentClasses)
        : null;
    const isAllSeatsFree = React.useMemo(() => part.seats.every(seat => isZeroPrice(getSeatPrice(seat))), [
        part.seats
    ]);
    const { global: { companyInfo: { iataCode }, seatMap: { splitByCabin } } } = useConfig();
    const showSeatPriceNotServicePrice = ['DV', 'RM'].includes(iataCode);
    const { combineWith, selectedDoubleSeatsBySegments, combinedSeat, onSelectHandler, onMouseEnter, onMouseLeave } = useDoubleSeat(activeDoubleSeats, segmentId, part.seats, passengers, onSelect);
    const renderServiceHeader = useCallback((comfort) => {
        return (React.createElement("div", { className: cn(theme.row__serviceHeader, {
                [theme.row__serviceHeader_comfort]: comfort === SeatComfort.Comfort,
                [theme.row__serviceHeader_preferred]: comfort === SeatComfort.Preferred,
                [theme.row__serviceHeader_free]: isAllSeatsFree
            }) },
            React.createElement("div", { className: theme.row__serviceHeader__wrapper },
                t(`SeatMap_${comfort}`) && (React.createElement("span", { className: theme.row__serviceHeader__serviceName }, t(`SeatMap_${comfort}`))),
                minServicePrice && (React.createElement(SeatPrice, { className: theme.row__serviceHeader__price, priceFromClassName: theme.row_serviceHeader_from, money: minServicePrice, priceFrom: true })))));
    }, [minServicePrice]);
    const delimiter = useMemo(() => {
        var _a;
        if (!newClass || ((_a = part.service) === null || _a === void 0 ? void 0 : _a.isAisle)) {
            return null;
        }
        if (splitByCabin) {
            const rowService = cabinCodeComfortMap.get(rows[rowIndex].cabinCode);
            return renderServiceHeader(rowService);
        }
        else {
            if (actualService) {
                return renderServiceHeader(actualService);
            }
        }
        return null;
    }, [splitByCabin, part, newClass, actualService, minServicePrice]);
    return (React.createElement("div", { className: theme.row__PartWrpWithTechFacilities },
        React.createElement("div", { className: cn(theme.row__rowPartWrp, {
                [theme.row__seatWrapper_newClass]: newClass,
                [theme.row__rowPartWrp_aisle]: (_b = part.service) === null || _b === void 0 ? void 0 : _b.isAisle,
                [theme.row__rowPartWrp_static]: isStatic
            }), key: partIndex },
            React.createElement(MediaQuery, { minWidth: 'tablet' },
                React.createElement("div", { className: cn(theme.row__seatWrapper, {
                        [theme.row__seatWrapper_boundaryRight]: partService !== nextPartServiceId,
                        [theme.row__seatWrapper_boundaryLeft]: partService !== prevPartServiceId,
                        [theme.row__seatWrapper_service_business]: partService === SeatComfort.Comfort,
                        [theme.row__seatWrapper_service_preferred]: partService === SeatComfort.Preferred,
                        [theme.row__seatWrapper_service_standard]: partService === SeatComfort.Standart,
                        [theme.row__seatWrapper_service_business_wide]: partService === SeatComfort.Comfort && isSeatMapWide,
                        [theme.row__seatWrapper_service_preferred_wide]: partService === SeatComfort.Preferred && isSeatMapWide,
                        [theme.row__seatWrapper_service_standard_wide]: partService === SeatComfort.Standart && isSeatMapWide
                    }), "data-service-id": serviceId })),
            delimiter,
            part.seats.map((seat, index) => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                const currentPassenger = passengers.find(passenger => passenger.id === passengerId);
                const selectedSeat = (_a = currentPassenger.seats) === null || _a === void 0 ? void 0 : _a.find(seat => seat.segmentId === segmentId);
                const prevSeat = (_b = part.seats) === null || _b === void 0 ? void 0 : _b[index - 1];
                const nextSeat = (_c = part.seats) === null || _c === void 0 ? void 0 : _c[index + 1];
                let actualSeat = seat;
                const occupiedBy = findPassengerWithThisSeat(actualSeat, passengers, segmentId);
                if (((actualSeat === null || actualSeat === void 0 ? void 0 : actualSeat.seatService) || !!((_d = actualSeat === null || actualSeat === void 0 ? void 0 : actualSeat.seatServices) === null || _d === void 0 ? void 0 : _d.length)) &&
                    !showSeatPriceNotServicePrice) {
                    const allowedPassengerSeatService = getAllowedPassengerSeatServices(passengerServices, seat);
                    if (!!(allowedPassengerSeatService === null || allowedPassengerSeatService === void 0 ? void 0 : allowedPassengerSeatService.length)) {
                        actualSeat = Object.assign({}, seat);
                        actualSeat.services = Object.assign({}, allowedPassengerSeatService);
                        actualSeat.service = Object.assign({}, allowedPassengerSeatService[0]);
                        actualSeat.seatService = Object.assign({}, allowedPassengerSeatService[0]);
                        actualSeat.price = {
                            amount: allowedPassengerSeatService.reduce((prev, currentService) => prev + (currentService.price.amount || 0), 0),
                            currency: allowedPassengerSeatService[0].price.currency
                        };
                    }
                }
                const hasConfirmedSeat = (_e = currentPassenger.seats) === null || _e === void 0 ? void 0 : _e.some(seat => (seat === null || seat === void 0 ? void 0 : seat.isChangeable) === false && seat.segmentId === segmentId);
                let { isAvailable } = getSeatAvailability({
                    seat: actualSeat,
                    passenger: currentPassenger,
                    segmentId
                });
                if (isCheckinMode) {
                    if (hasConfirmedSeat) {
                        isAvailable = (selectedSeat === null || selectedSeat === void 0 ? void 0 : selectedSeat.row) === actualSeat.number;
                    }
                    if (actualSeat === null || actualSeat === void 0 ? void 0 : actualSeat.seatService) {
                        isAvailable = isAvailable && actualSeat.seatService.canBeAdded;
                    }
                }
                const isConfirmed = hasConfirmedSeat && (selectedSeat === null || selectedSeat === void 0 ? void 0 : selectedSeat.number) === actualSeat.number;
                const hasCombinedSeats = seat.combinedWith && Object.keys(seat.combinedWith).length > 0;
                const isHideSeat = isSeatMapWide && !seat.isExistent && !seat.isAisle && prevSeat && nextSeat;
                const isSelectedDoubleSeat = (_f = selectedDoubleSeatsBySegments.get(segmentId)) === null || _f === void 0 ? void 0 : _f.has(seat.number);
                const isOccupiedDoubleSeat = occupiedBy && isSelectedDoubleSeat;
                if (activeDoubleSeats) {
                    isAvailable = isAvailable && hasCombinedSeats && !hasConfirmedSeat;
                    if (!occupiedBy &&
                        ((!prevSeat &&
                            nextSeat &&
                            findPassengerWithThisSeat(nextSeat, passengers, segmentId)) ||
                            (!nextSeat &&
                                prevSeat &&
                                findPassengerWithThisSeat(prevSeat, passengers, segmentId)))) {
                        isAvailable = false;
                    }
                    else if (occupiedBy && !isSelectedDoubleSeat) {
                        isAvailable = false;
                    }
                }
                let isCombinedWith = activeDoubleSeats ? combineWith === null || combineWith === void 0 ? void 0 : combineWith[seat.number] : null;
                if (isOccupiedDoubleSeat &&
                    prevSeat &&
                    ((_g = selectedDoubleSeatsBySegments.get(segmentId)) === null || _g === void 0 ? void 0 : _g.has(prevSeat.number)) &&
                    findPassengerWithThisSeat(prevSeat, passengers, segmentId)) {
                    isCombinedWith = CombinedDirection.Left;
                }
                else if (isOccupiedDoubleSeat &&
                    nextSeat &&
                    ((_h = selectedDoubleSeatsBySegments.get(segmentId)) === null || _h === void 0 ? void 0 : _h.has(nextSeat.number)) &&
                    findPassengerWithThisSeat(nextSeat, passengers, segmentId)) {
                    isCombinedWith = CombinedDirection.Right;
                }
                const onMouseEnterHandler = () => onMouseEnter(seat, segmentId);
                if (isHideSeat) {
                    return null;
                }
                return (React.createElement(OverrideComponent, { componentProps: {
                        isSmall: isSeatMapWide,
                        seat: actualSeat,
                        isAvailable: isAvailable && !hasConfirmedSeat,
                        occupiedBy,
                        isConfirmed: hasConfirmedSeat,
                        segmentId,
                        passengerId: passengerId,
                        onClear,
                        onSelect: isConfirmed ? () => null : onSelectHandler,
                        onMouseEnter: onMouseEnterHandler,
                        onMouseLeave: onMouseLeave,
                        isCombinedWith: isCombinedWith,
                        combinedSeat: combinedSeat,
                        passenger: currentPassenger
                    }, component: { SeatMapSeat: Seat }, key: actualSeat.number }));
            })),
        React.createElement(TechnicalFacilities, { part: part, isSmall: isSeatMapWide })));
});
export default Parts;
