import { __awaiter } from "tslib";
import * as React from 'react';
import { addExternalResource } from './utils';
import { emitEvent } from '../utils';
import { WebskyEventType } from '../types';
import { useTranslation } from 'react-i18next';
const addResources = (i18n, resource) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (!i18n) {
            return ['I18N library is not initialized'];
        }
        if (typeof resource !== 'object') {
            return ['Locales should only be of `object` type'];
        }
        return yield addExternalResource(i18n, resource);
    }
    catch (e) {
        return [e];
    }
});
/**
 * Component for support third party (external) translation resources.
 * External js script must subscribe on `WebskyEventType.LanguageChanged` event.
 * It is emitted after i18next was initialized or locale of Websky's engine was changed,
 * and indicated that `__webskyAddLocaleResource` can be used.
 *
 * `__webskyAddLocaleResource` is global function that can pass bundles from external resources to i18next instance.
 * It takes an argument in the following format:
 *
 * If MODULE_NAME is not specified, translation will be placed into default bundle
 *
 * ```
 * 	{
 * 	   [LANG_KEY]: {
 * 	       '[MODULE_NAME]:[KEY]': '[TRANSLATION]'
 * 	   }
 * 	}
 * ```
 */
const ExternalLocaleResources = () => {
    const initEvent = React.useRef(false);
    const { i18n } = useTranslation();
    const lang = i18n.language;
    React.useEffect(() => {
        window['__webskyAddLocaleResource'] = (resource) => {
            addResources(i18n, resource).then(errors => {
                if (errors.length) {
                    console.log(...['External locales errors:', ...errors]);
                }
                i18n.changeLanguage(i18n.language);
                console.info(`External locales added`);
            });
        };
        if (!initEvent.current) {
            emitEvent(document, {
                type: WebskyEventType.LanguageChanged,
                data: i18n.language
            });
            initEvent.current = true;
        }
        return () => {
            initEvent.current = false;
        };
    }, []);
    React.useEffect(() => {
        emitEvent(document, {
            type: WebskyEventType.LanguageChanged,
            data: i18n.language
        });
    }, [lang]);
    return null;
};
export default ExternalLocaleResources;
