import * as React from 'react';
import { useTheme } from '../../theme';
import { BaseButton } from '../../index';
import Modal from '../../Modal';
import cn from 'classnames';
import { useIsMobile } from '../../utils';
const WarningModal = ({ title, content, className, classes, errorMessage, buttonText, isOpen, hideContentText, bottomDrawer, onClose, onButtonClick }) => {
    const { WarningModal: css } = useTheme('WarningModal');
    const isMobile = useIsMobile();
    return (React.createElement(Modal, { maxWidth: "sm", open: isOpen, onClose: onClose, isRounded: bottomDrawer, classes: Object.assign(Object.assign({}, classes), { scrollBody: cn(css.scrollBody, classes === null || classes === void 0 ? void 0 : classes.scrollBody), paper: cn(css.paper, className, classes === null || classes === void 0 ? void 0 : classes.paper) }), slideUp: isMobile, bottomDrawer: bottomDrawer },
        title && React.createElement("div", { className: css.header }, title),
        React.createElement("div", { className: css.content },
            React.createElement("div", { className: cn(css.content__text, { [css.content__text_hidden]: hideContentText }) }, typeof content === 'string' ? (React.createElement("div", { dangerouslySetInnerHTML: { __html: content } })) : (React.createElement("div", null, content))),
            errorMessage && typeof errorMessage === 'string' && (React.createElement("div", { className: css.content__error, dangerouslySetInnerHTML: { __html: errorMessage } })),
            errorMessage && typeof errorMessage !== 'string' && (React.createElement("div", { className: css.content__error }, errorMessage)),
            buttonText && (React.createElement("div", { className: css.buttons },
                React.createElement(BaseButton, { variant: "optionAction", onClick: onButtonClick }, buttonText))))));
};
export default WarningModal;
