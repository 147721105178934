import * as DesktopFlightRouteStyles from './components/DesktopFlightRoute.css';
import * as SegmentStyles from './components/Segment/Segment.css';
import * as PointStyles from './components/Segment/Point/Point.css';
import * as RouteArrowStyles from './components/Segment/RouteArrow/RouteArrow.css';
import * as StopsInfoStyles from './components/Segment/StopsInfo/StopsInfo.css';
import * as FlightPriceStyles from './components/FlightPrice/FlightPrice.css';
import * as TotalDuration from './components/TotalDuration/TotalDuration.css';
import * as SelectButton from './components/SelectButton/SelectButton.css';
export default {
    SelectButton,
    DesktopFlightRouteStyles,
    SegmentStyles,
    PointStyles,
    RouteArrowStyles,
    StopsInfoStyles,
    FlightPriceStyles,
    TotalDuration
};
