import { __awaiter } from "tslib";
import React from 'react';
import { useTheme } from '../../../theme';
import { OrderCard } from './OrderCard/OrderCard';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { PaximizerCommunicationSectionEnum, useGetPaxOrderNotificationTeasersQuery } from '@websky/graphql';
import InfiniteScroll from 'react-infinite-scroller';
import { useCommunicationList } from '../../hooks';
import Loader from '../../../Loader';
import { CommunicationPageWrapper } from '../CommunicationPageWrapper/CommunicationPageWrapper';
export const OrdersPage = () => {
    const { Communication: css } = useTheme('Communications');
    const { path } = useRouteMatch();
    const { loading, refetch } = useGetPaxOrderNotificationTeasersQuery({
        skip: true
    });
    const loadOrders = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield refetch({
            offset: values.offset,
            count: values.count
        });
        const notification = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationOrderNotificationTeasers;
        return {
            items: (notification === null || notification === void 0 ? void 0 : notification.items.length) ? notification.items : [],
            total: (notification === null || notification === void 0 ? void 0 : notification.total) || 0
        };
    });
    const { readIds, items: orders, pagination, loadMore, markAsRead, errorMessage } = useCommunicationList(PaximizerCommunicationSectionEnum.order_notifications, loadOrders, loading);
    return (React.createElement(CommunicationPageWrapper, { type: PaximizerCommunicationSectionEnum.order_notifications, pagination: { total: pagination.total, hasMore: pagination.hasMore }, errorMessage: errorMessage },
        React.createElement(InfiniteScroll, { loadMore: loadMore, hasMore: pagination.hasMore, loader: React.createElement(Loader, { key: "orders-loader" }), pageStart: pagination.pageLoaded },
            React.createElement("div", { className: css.list }, orders.map(item => (React.createElement(Link, { key: item.id, to: `${path}/${item.id}?locator=${item.locator}` },
                React.createElement(OrderCard, { title: item.title, locator: item.locator, passengers: item.travellers, departureAirport: item.departureAirport.iata, arrivalAirport: item.arrivalAirport.iata, arrivalDate: item.arrivalDate, departureDate: item.departureDate, text: item.text, isRead: item.read || readIds[item.id], sendingDate: item.date, routeType: item.routeType, orderId: item.orderId, markAsRead: () => markAsRead(item.id) }))))))));
};
