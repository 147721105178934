import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllRequiredSeatsAreSelected, getCheckinOrderId, getHasAvailableSeats, getSeatMap, getTotalPrice, getTravellers } from '../../../../store/order/selectors';
import SeatMap from '../../../../../../SeatMap';
import { useTheme } from '../../../../../../theme';
import { CheckinStep } from '../../../../types';
import { Money } from '../../../../../../Money';
import ServicesToolbar from '../../../../../../ServicesToolbar';
import { CheckinEmptySeatMapError } from '../../CheckinErrorBoundary/CheckinErrors';
import { setCheckinOrder } from '../../../../store/order/actions';
import { getSelectedExtraSeats } from '../../../../store/selectedServices/selectors';
import { useToggleable } from '../../../../../../hooks';
import { useConfig } from '../../../../../../context';
import { useGetGoBack } from '../utils';
import { useSeatMapToolbarProps } from '../../../../../../SeatMap/components/hooks';
const Seats = ({ onProceed, travellers, setSeatCallback, passengers, setClearSeatCallback, isLoading, setIsLoading, seatsPriceToPay, onSegmentClear }) => {
    const orderId = useSelector(getCheckinOrderId);
    const totalPrice = useSelector(getTotalPrice);
    const seatMap = useSelector(getSeatMap);
    const hasAvailableSeats = useSelector(getHasAvailableSeats);
    const orderTravellers = useSelector(getTravellers);
    const dispatch = useDispatch();
    const allRequiredSeatsAreSelected = useSelector(getAllRequiredSeatsAreSelected);
    const selectedExtraSeats = useSelector(getSelectedExtraSeats);
    const { Checkin: css } = useTheme('Checkin');
    const { t } = useTranslation('Checkin');
    // const { runServiceRequest } = useServiceRequest();
    const { segmentIndex, passengerIndex, setSegment, setPassenger } = useSeatMapToolbarProps(seatMap);
    const { global: { seatMap: { showRecommendedSeats } } } = useConfig();
    const { isOpen: activeAutomaticSeating, close: disableAutomaticSeating } = useToggleable(true);
    const setOrder = (order) => {
        dispatch(setCheckinOrder(order));
    };
    useEffect(() => {
        disableAutomaticSeating();
    }, []);
    useEffect(() => {
        if (!allRequiredSeatsAreSelected && !hasAvailableSeats) {
            throw new CheckinEmptySeatMapError('Empty seat map and travellers without seats');
        }
    }, [allRequiredSeatsAreSelected]);
    const hasPassengersWithSeat = useMemo(() => {
        const passengersIds = passengers.filter(passenger => { var _a; return (_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.length; }).map(passenger => passenger.id);
        const travellersIds = orderTravellers
            .filter(traveller => { var _a, _b; return (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.length; })
            .map(traveller => traveller.id);
        return new Set([...passengersIds, ...travellersIds]).size > 0;
    }, [passengers, orderTravellers]);
    const confirmRecommendedSeatsWithoutDialog = activeAutomaticSeating && !hasPassengersWithSeat && !showRecommendedSeats;
    const goBack = useGetGoBack(CheckinStep.Seats);
    return (React.createElement("div", { className: css.seats },
        React.createElement(ServicesToolbar, { toolbarProps: {
                totalPrice,
                onButtonClick: onProceed,
                onBackButtonClick: goBack,
                additional: seatsPriceToPay.amount ? (React.createElement("div", { className: css.servicePrice },
                    t('Seats'),
                    ": ",
                    React.createElement(Money, { money: seatsPriceToPay }))) : null
            }, footerMobileProps: {
                onContinue: onProceed,
                onBack: goBack
            } },
            React.createElement(SeatMap, { orderId: orderId, seatMap: seatMap, passengers: passengers, segments: seatMap.segments.map(segment => {
                    return {
                        segmentId: segment.segmentsInfo.id,
                        departure: segment.segmentsInfo.departure,
                        arrival: segment.segmentsInfo.arrival
                    };
                }), travellers: travellers, showRecommendations: false, segmentIndex: segmentIndex, passengerIndex: passengerIndex, confirmRecommendedSeatsWithoutDialog: confirmRecommendedSeatsWithoutDialog, fillOrder: setOrder, setSeatCallback: setSeatCallback, setClearSeatCallback: setClearSeatCallback, setSegment: setSegment, setPassenger: setPassenger, onProceed: onProceed, onSegmentClear: onSegmentClear, selectedExtraSeats: selectedExtraSeats, isNewOrder: false }))));
};
export default Seats;
