import * as React from 'react';
import { useContext } from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PriceBlock from './PriceBlock/PriceBlock';
import { getDisabled, getPrice } from '../../store/selector';
import { BaggageContext } from '../../../context';
import { BaseButton } from '../../../../BaseComponents';
const BaggageFooter = ({ onConfirm, onCancel }) => {
    var _a;
    const theme = useTheme('UpgradeBaggageDialog').BaggageFooter;
    const isReadOnly = (_a = useContext(BaggageContext)) === null || _a === void 0 ? void 0 : _a.isReadOnly;
    const { t } = useTranslation('IncludedBaggage');
    const disabled = useSelector(getDisabled) || isReadOnly;
    const price = useSelector(getPrice);
    return (React.createElement("div", { className: theme.root },
        React.createElement("div", { className: theme.row },
            price && React.createElement(PriceBlock, { price: price, hideClear: isReadOnly }),
            React.createElement("div", { className: theme.buttons_row },
                React.createElement(BaseButton, { variant: "smallSecondary", className: theme.cancel_btn, onClick: onCancel }, t('Cancel')),
                !isReadOnly && (React.createElement(BaseButton, { variant: "smallPrimary", className: theme.confirm_btn, onClick: onConfirm, disabled: disabled }, t('Confirm')))))));
};
export default BaggageFooter;
