import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import cn from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '../theme';
const loaderIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { width: "24", height: "24", fill: "white" }),
    React.createElement("circle", { opacity: "0.3", cx: "12", cy: "12", r: "9", stroke: "url(#paint0_angular)", strokeWidth: "2" }),
    React.createElement("defs", null,
        React.createElement("radialGradient", { id: "paint0_angular", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(12 12) rotate(90) scale(10)" },
            React.createElement("stop", { stopOpacity: "0" }),
            React.createElement("stop", { offset: "1" })))));
const Input = props => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { Input: theme } = useTheme('Input');
    const variant = props.variant || 'filled';
    return (React.createElement(TextField, Object.assign({ fullWidth: true, placeholder: props.placeholder, label: props.label, disabled: props.disabled, variant: variant, focused: props.focused, inputProps: {
            autoComplete: (_a = props.autoComplete) !== null && _a !== void 0 ? _a : 'one-time-code',
            autoCorrect: 'off',
            autoCapitalize: 'off',
            autoFocus: props.autoFocus,
            spellCheck: false,
            tabIndex: props.tabIndex ? props.tabIndex : null,
            className: theme.real_input,
            inputMode: props.inputMode
        }, FormHelperTextProps: {
            classes: {
                error: cn(theme.hint, { [theme.hint_error]: props.absoluteHintPosition })
            }
        }, value: props.value, name: props.name }, props.TextFieldProps, { className: cn(theme.root, props.className, (_b = props === null || props === void 0 ? void 0 : props.TextFieldProps) === null || _b === void 0 ? void 0 : _b.className, {
            [theme.valueFilled]: (_c = props.TextFieldProps) === null || _c === void 0 ? void 0 : _c.value
        }), InputLabelProps: {
            classes: Object.assign({ root: cn(theme.textField__root, props.labelClassName, {
                    [theme.textField_standard]: variant === 'standard'
                }), focused: cn(theme.textField, theme.textField_focused, props.labelFocusedClassName), error: cn(theme.label_error, props.labelErrorClassName), shrink: cn(theme.label_shrink, props.labelShrinkClassName), outlined: theme.textField_outlined, filled: theme.textField_filled }, (_e = (_d = props.TextFieldProps) === null || _d === void 0 ? void 0 : _d.InputLabelProps) === null || _e === void 0 ? void 0 : _e.classes),
            shrink: props.shrink
        }, InputProps: Object.assign(Object.assign({ endAdornment: props.isLoading ? (React.createElement(InputAdornment, { position: "end", className: theme.loader }, loaderIcon)) : (props.endAdornment), inputComponent: props.inputComponent, inputRef: props.inputRef, disableUnderline: variant !== 'standard', inputMode: props.inputMode }, (_f = props === null || props === void 0 ? void 0 : props.TextFieldProps) === null || _f === void 0 ? void 0 : _f.InputProps), { classes: Object.assign({ disabled: theme.disabled, focused: cn(theme.input_focused, props.inputFocusedClassName), root: cn(theme.input, props.inputClassName, {
                    [theme.input_filled]: variant === 'filled',
                    [theme.input_outline]: variant === 'outlined',
                    [theme.input_standard]: variant === 'standard'
                }), underline: theme.underline, notchedOutline: variant === 'outlined' ? theme.input_outline : null, error: cn(theme.input_error, props.inputErrorClassName) }, (_h = (_g = props.TextFieldProps) === null || _g === void 0 ? void 0 : _g.InputProps) === null || _h === void 0 ? void 0 : _h.classes) }) })));
};
export default Input;
