import { useMemo } from 'react';
export const useTransferServiceSummary = (service, segments, passengers, servicesState) => {
    const serviceSummaryOptions = useMemo(() => {
        const serviceSummaryOptions = [];
        for (const [passengerId] of Object.keys(servicesState)) {
            const summaryOption = {
                header: passengers[passengerId].fullName,
                items: []
            };
            for (const [segmentId] of Object.keys(servicesState[passengerId])) {
                const passengerService = servicesState[passengerId][segmentId].find(passengerService => passengerService.service.id === service.id);
                if (!passengerService) {
                    continue;
                }
                if (passengerService.selected) {
                    summaryOption.items.push({
                        price: service.price,
                        name: `${segments[segmentId].date}, ${segments[segmentId].city}`,
                        id: passengerService.service.id
                    });
                }
            }
            serviceSummaryOptions.push(summaryOption);
        }
        return serviceSummaryOptions.filter(summaryOption => { var _a; return (_a = summaryOption.items) === null || _a === void 0 ? void 0 : _a.length; });
    }, [servicesState, segments, service]);
    return {
        serviceSummaryOptions
    };
};
