import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { getLocale, initI18n } from '../../../utils';
initI18n('AdditionalServiceCard');
const Header = props => {
    const { headClassName, header, icon, logo, horizontal = false, renderHeader } = props;
    const theme = useTheme('AdditionalServiceCard').AdditionalServiceStyles;
    const { t } = useTranslation('AdditionalServiceCard');
    return (React.createElement("div", { className: cn(theme.head, headClassName, {
            [theme.insurance]: header === 'Insurance',
            [theme.transfer]: header === 'Transfer',
            [theme.head_horizontal]: horizontal
        }) },
        !horizontal && (React.createElement(React.Fragment, null,
            logo && (React.createElement("div", { className: theme.logo__wrapper, "data-locale": getLocale() }, logo)),
            renderHeader ? renderHeader() : React.createElement("div", { className: theme.header }, t(header)))),
        React.createElement("div", { className: theme.whiteLines },
            React.createElement("div", { className: theme.whiteLine_wide }),
            React.createElement("div", { className: theme.whiteLine_narrow })),
        icon && React.createElement("div", { className: theme.icon }, icon)));
};
export default Header;
