import { __awaiter } from "tslib";
import React from 'react';
import { useTheme } from '../../../theme';
import { AppealCard } from './AppealCard/AppealCard';
import { Link } from 'react-router-dom';
import { PaximizerCommunicationSectionEnum, useGetPaxMyAppealTeasersQuery } from '@websky/graphql';
import InfiniteScroll from 'react-infinite-scroller';
import { useCommunicationList } from '../../hooks';
import Loader from '../../../Loader';
import { useRouteMatch } from 'react-router-dom';
import { CommunicationPageWrapper } from '../CommunicationPageWrapper/CommunicationPageWrapper';
export const AppealsPage = () => {
    const { Communication: css } = useTheme('Communications');
    const { path } = useRouteMatch();
    const { loading, refetch } = useGetPaxMyAppealTeasersQuery({ skip: true });
    const loadAppeals = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield refetch({
            offset: values.offset,
            count: values.count
        });
        const notification = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationMyAppealTeasers;
        return {
            items: (notification === null || notification === void 0 ? void 0 : notification.items.length) ? notification.items : [],
            total: (notification === null || notification === void 0 ? void 0 : notification.total) || 0
        };
    });
    const { readIds, markAsRead, items: appealss, pagination, loadMore, errorMessage } = useCommunicationList(PaximizerCommunicationSectionEnum.my_appeals, loadAppeals, loading);
    return (React.createElement(CommunicationPageWrapper, { type: PaximizerCommunicationSectionEnum.my_appeals, pagination: { total: pagination.total, hasMore: pagination.hasMore }, errorMessage: errorMessage },
        React.createElement(InfiniteScroll, { loadMore: loadMore, hasMore: pagination.hasMore, loader: React.createElement(Loader, { key: "appeals-loader" }), pageStart: pagination.pageLoaded },
            React.createElement("div", { className: css.list }, appealss.map(item => (React.createElement(Link, { key: item.id, to: `${path}/${item.id}` },
                React.createElement(AppealCard, { title: item.title, id: item.id, updatedAt: item.updatedAt, createdAt: item.createdAt, message: item.firstMessage, status: item.status, isRead: item.read || readIds[item.id], markAsRead: () => markAsRead(item.id) }))))))));
};
