import { isValidPhoneNumber } from 'libphonenumber-js';
const { formatPhoneNumberIntl } = require('react-phone-number-input');
const { parsePhoneNumberFromString } = require('libphonenumber-js');
export const clearPhoneNumber = (phone) => {
    if (!phone) {
        return '';
    }
    return phone.replace(/[+()\- ]/g, '');
};
export const isCorrectPhoneNumber = (phone) => {
    const phoneNumber = parsePhoneNumberFromString(phone, 'RU');
    if (!phoneNumber) {
        return false;
    }
    return isValidPhoneNumber(clearPhoneNumber(phone), phoneNumber.country);
};
export const parsePhone = (value) => {
    let parsedPhone;
    if (value.startsWith('8') && clearPhoneNumber(value).length === 11 && parsePhoneNumberFromString(value, 'RU')) {
        parsedPhone = parsePhoneNumberFromString(value, 'RU').formatNational();
    }
    else if (value.startsWith('9') && parsePhoneNumberFromString(value, 'RU')) {
        parsedPhone = parsePhoneNumberFromString(value, 'RU').formatNational();
    }
    else {
        parsedPhone = formatPhoneNumberIntl(value) || formatPhoneNumberIntl(`+${value}`);
    }
    return parsedPhone;
};
export const isRusNumber = (value) => {
    const rawValue = clearPhoneNumber(value);
    return ((value.startsWith('8') && rawValue.length === 11 && !!parsePhoneNumberFromString(value, 'RU')) ||
        (value.startsWith('9') && rawValue.length === 10 && !!parsePhoneNumberFromString(value, 'RU')));
};
