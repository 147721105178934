import { ADD_NEW_FLIGHT, CLEAR_FLIGHT } from './actions';
export const newFlightsReducer = (state = [], action) => {
    if (action.type === ADD_NEW_FLIGHT) {
        return [...state, action.payload];
    }
    else if (action.type === CLEAR_FLIGHT) {
        return [];
    }
    return state;
};
