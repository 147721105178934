import * as React from 'react';
import cn from 'classnames';
import { checkIcon, dollarIcon, noIcon } from '../../Icons';
import { useTheme } from '../../../theme';
import { FareFeaturePaymentType } from '@websky/graphql';
const availabilityIcons = {
    [FareFeaturePaymentType.Free]: checkIcon,
    [FareFeaturePaymentType.Pay]: dollarIcon,
    [FareFeaturePaymentType.NotAvailable]: noIcon
};
const Cell = ({ cell, isFullFareCondition, customAvailabilityIcons }) => {
    const { CompareFares: css } = useTheme('CompareFares');
    const icons = customAvailabilityIcons !== null && customAvailabilityIcons !== void 0 ? customAvailabilityIcons : availabilityIcons;
    if (cell === null) {
        return (React.createElement("div", { className: cn(css.cell, {
                [css.cell_notFullFareConditions]: !isFullFareCondition
            }) },
            React.createElement("div", { className: css.cellContent }, '')));
    }
    const { description, value, availability, shortDescription } = cell;
    return (React.createElement("div", { className: cn(css.cell, {
            [css.cell_notFullFareConditions]: !isFullFareCondition
        }) },
        isFullFareCondition && (React.createElement("div", { className: css.col },
            React.createElement("div", { className: cn(css.cellContent, css.cellContent_fullDescr) }, description))),
        !isFullFareCondition && (React.createElement("div", { className: css.cellContent },
            value && React.createElement("span", null, value),
            !value &&
                (availability === FareFeaturePaymentType.Free ? (React.createElement("div", { className: css.availabilityIcon }, icons[availability])) : (React.createElement("div", { className: css.cellContent }, shortDescription)))))));
};
export default Cell;
