import { getIn } from 'final-form';
// get all inputs on the page
const getAllInputs = () => {
    if (typeof document === 'undefined') {
        return [];
    }
    return Array.prototype.slice
        .call(document.forms)
        .reduce((accumulator, form) => accumulator.concat(Array.prototype.slice.call(form).filter(input => 'focus' in input)), []);
};
const findInput = (inputs, errors) => inputs.find(input => input.name && getIn(errors, input.name));
// In fact, this is slightly modified form-focus decorator
// https://github.com/final-form/final-form-focus/blob/master/src/decorator.js
const scrollToFirstErrorDecorator = (form) => {
    if (!('scrollTo' in window)) {
        return null;
    }
    const scrollToFirstError = (errors) => {
        const firstInput = findInput(getAllInputs(), errors);
        if (firstInput) {
            window.scrollBy({ top: firstInput.getBoundingClientRect().top - 150, behavior: 'smooth' });
        }
    };
    const originalSubmit = form.submit;
    let state = {};
    const unsubscribe = form.subscribe(nextState => {
        state = nextState;
    }, { errors: true, submitErrors: true });
    // What to do after submit
    const afterSubmit = () => {
        const { errors, submitErrors } = state;
        if (errors && Object.keys(errors).length) {
            scrollToFirstError(errors);
        }
        else if (submitErrors && Object.keys(submitErrors).length) {
            scrollToFirstError(submitErrors);
        }
    };
    // Rewrite submit function
    form.submit = () => {
        const result = originalSubmit.call(form);
        if (result && typeof result.then === 'function') {
            // async
            result.then(afterSubmit, () => { });
        }
        else {
            // sync
            afterSubmit();
        }
        return result;
    };
    return () => {
        unsubscribe();
        form.submit = originalSubmit;
    };
};
export default scrollToFirstErrorDecorator;
