import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Unvoluntary, StepType } from '../../types';
import { getPassengerFirstName, getPassengerLastName } from '../../../Checkout/store/passengers/selectors';
import { capitalizeFirstLetter } from '../../../utils';
import { isRefundPassenger } from '../../utils';
import { useTheme } from '../../../theme';
import { usePassengerNames } from '../Passenger/hooks';
import { useSteps } from '../Refund/hooks';
const Breadcrumbs = props => {
    const { passengers, reason, renderAfter } = props;
    const { t } = useTranslation('Refund');
    const { Breadcrumbs: css } = useTheme('Refund');
    const { getPassengerName } = usePassengerNames();
    const { currentStep } = useSteps();
    const renderBreadcrumb = useCallback((title, type) => (React.createElement("div", { title: title, className: css.breadcrumb, key: title },
        React.createElement("div", { className: css.text }, title),
        type ? renderAfter === null || renderAfter === void 0 ? void 0 : renderAfter(type) : null)), [renderAfter]);
    const renderPassengers = useCallback(() => {
        if (!passengers.length) {
            return null;
        }
        let passengerName;
        if (isRefundPassenger(passengers[0])) {
            passengerName = passengers.map(getPassengerName).join(', ');
        }
        else {
            passengerName = passengers
                .map(passenger => `${capitalizeFirstLetter(getPassengerFirstName(passenger).value.toLowerCase())} ${capitalizeFirstLetter(getPassengerLastName(passenger).value.toLowerCase())}`)
                .join(', ');
        }
        return passengerName.split(',').map(name => renderBreadcrumb(name, 'passenger'));
    }, [passengers]);
    const renderRefundInfo = useCallback(() => {
        if (!reason) {
            return null;
        }
        const isVoluntaryReason = reason === Unvoluntary.Unvoluntary;
        const title = t(isVoluntaryReason ? 'Voluntary refund' : 'Involuntary refund');
        if (reason && !isVoluntaryReason) {
            return renderBreadcrumb(`${title} – ${t(reason)}`, 'refundInfo');
        }
        return renderBreadcrumb(title, 'refundInfo');
    }, [reason]);
    return (React.createElement("div", { className: cn(css.breadcrumbs, props.className, {
            [css.passengersStep]: currentStep.type === StepType.Passengers
        }) },
        renderPassengers(),
        " ",
        renderRefundInfo()));
};
export default Breadcrumbs;
