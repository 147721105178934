import { RefundMode, StepType, Unvoluntary } from './types';
import { initialState as stepsInitialState } from './store/steps/reducer';
import { TravellerFieldEnum } from '@websky/graphql';
import { concatPassengerNames, getUserValue } from '../utils';
const isSplitAllowed = (passengerType, isChildRefundWithoutAdultAllowed) => passengerType === 'CLD' && isChildRefundWithoutAdultAllowed;
const getPassengerName = (traveller) => {
    const firstName = getUserValue(traveller, TravellerFieldEnum.FirstName);
    const lastName = getUserValue(traveller, TravellerFieldEnum.LastName);
    return concatPassengerNames(lastName, firstName);
};
const getPassengerStateObject = (travellers, traveller, childRefundWithoutAdultAllowed, adultsCount, isSelected) => {
    return {
        id: traveller.id,
        name: getPassengerName(traveller),
        children: travellers
            .filter(orderTraveller => {
            var _a;
            const isSplitPassengerAllowed = isSplitAllowed(orderTraveller.type, childRefundWithoutAdultAllowed);
            const isLinkedChild = ((_a = orderTraveller.linkedTraveller) === null || _a === void 0 ? void 0 : _a.id) === traveller.id;
            return isLinkedChild && (!isSplitPassengerAllowed || (isSplitPassengerAllowed && adultsCount === 1));
        })
            .map(child => ({
            name: getPassengerName(child),
            id: child.id,
            selected: false
        })),
        selected: isSelected,
        phone: getUserValue(traveller, TravellerFieldEnum.Phone),
        email: getUserValue(traveller, TravellerFieldEnum.Email)
    };
};
export const useCheckoutOrderRefundAdapter = (order) => {
    var _a;
    const passengers = {};
    const isOnlyOnePassenger = order.travellers.length === 1;
    const adultsCount = order.travellers.filter(traveller => traveller.type === 'ADT').length;
    const childRefundWithoutAdultAllowed = (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.childRefundWithoutAdultAllowed;
    order.travellers.forEach(traveller => {
        if (traveller.linkedTraveller) {
            if (isSplitAllowed(traveller.type, childRefundWithoutAdultAllowed)) {
                passengers[traveller.id] = getPassengerStateObject(order.travellers, traveller, childRefundWithoutAdultAllowed, adultsCount, isOnlyOnePassenger);
            }
        }
        else {
            passengers[traveller.id] = getPassengerStateObject(order.travellers, traveller, childRefundWithoutAdultAllowed, adultsCount, isOnlyOnePassenger);
        }
    });
    const documents = {};
    Object.keys(passengers).forEach(passengerId => {
        documents[passengerId] = [];
    });
    const stepsState = isOnlyOnePassenger
        ? stepsInitialState.map(step => {
            if (step.type === StepType.Reason) {
                return Object.assign(Object.assign({}, step), { isActive: true });
            }
            return Object.assign(Object.assign({}, step), { isActive: false });
        })
        : stepsInitialState;
    return {
        passengers,
        reason: order.exareInfo.refundAutoMode ? Unvoluntary.Unvoluntary : null,
        steps: stepsState,
        mode: order.exareInfo.refundAutoMode ? RefundMode.Auto : RefundMode.Manual,
        customer: null,
        documents,
        flightId: order.flight.id,
        orderId: order.id,
        refundInfo: {},
        aviaOrder: order
    };
};
