import { useMemo } from 'react';
import { initI18n } from '../utils';
import i18n from 'i18next';
import { useConfig } from '../context';
import { StepsEnum } from '../types';
initI18n('Stepbar');
export const useAvailableSteps = (steps) => {
    const config = useConfig().global;
    const defaultSteps = useMemo(() => {
        return {
            [StepsEnum.Flights]: {
                label: i18n.t('Stepbar:Flights')
            },
            [StepsEnum.Passengers]: {
                label: i18n.t('Stepbar:Passengers')
            },
            [StepsEnum.Order]: {
                label: i18n.t('Stepbar:Order')
            },
            [StepsEnum.Payment]: {
                label: i18n.t('Stepbar:Payment')
            }
        };
    }, [i18n.language]);
    return useMemo(() => {
        var _a;
        const actualSteps = steps !== null && steps !== void 0 ? steps : defaultSteps;
        if (((_a = config.stepsAvailable) === null || _a === void 0 ? void 0 : _a[StepsEnum.Meal]) === false) {
            delete actualSteps.Meal;
        }
        return actualSteps;
    }, [defaultSteps, steps, config.stepsAvailable]);
};
