import * as React from 'react';
export const alfaInsuranceLogo = (React.createElement("svg", { id: "Layer_1", width: "233.324", height: "48.47", viewBox: "0 0 233.324 48.47", overflow: "visible" },
    React.createElement("g", null,
        React.createElement("path", { fill: "#E31836", d: "M34.051,0H14.422C6.467,0,0,6.47,0,14.42v19.63c0,7.949,6.467,14.42,14.422,14.42h19.628\n\t\tc7.948,0,14.418-6.471,14.418-14.42V14.42C48.469,6.47,41.999,0,34.051,0z" }),
        React.createElement("path", { fill: "#FFFFFF", d: "M39.536,30.192l-0.018,0.216c-0.069,0.841-0.596,1.692-1.471,1.692c-0.669,0-1.207-0.248-1.646-0.756\n\t\tc-0.527-0.607-1.001-1.336-1.481-2.657c1.359-4.613,5.552-19.39,5.552-19.39h-7.957c0,0-0.735,3.465-1.398,6.617\n\t\tc-0.033-0.077-0.067-0.153-0.102-0.226c-0.663-1.417-1.722-2.992-2.898-4.099c-2.05-1.927-4.461-2.866-7.372-2.866\n\t\tc-4.018,0-7.417,1.486-10.103,4.418c-2.673,2.914-4.085,6.653-4.085,10.811c0,4.631,1.341,8.435,3.985,11.301\n\t\tc2.758,2.995,6.056,4.39,10.376,4.39c2.517,0,4.919-0.748,7.146-2.221c1.439-0.95,2.752-2.266,3.993-3.975\n\t\tc0.521,1.687,1.046,2.84,1.674,3.703c1.016,1.393,2.538,2.191,4.175,2.191c1.704,0,3.256-0.873,4.053-2.276\n\t\tc0.706-1.245,0.994-2.79,0.994-5.331v-1.542H39.536z M26.198,33.146c-1.206,1.766-2.781,2.698-4.555,2.698\n\t\tc-2.067,0-3.63-0.926-4.781-2.84c-1.207-2.004-1.818-5.007-1.818-8.928c0-3.595,0.648-6.452,1.925-8.492\n\t\tc1.2-1.915,2.704-2.809,4.732-2.809c1.805,0,3.214,0.75,4.439,2.366c1.313,1.734,2.423,4.599,3.218,8.282\n\t\tC28.36,28.402,27.384,31.412,26.198,33.146z" }),
        React.createElement("g", null,
            React.createElement("path", { fill: "#E31836", d: "M72.734,9.999c-0.55-2.477-2.112-4.207-5.54-4.207c-3.225,0-4.992,1.73-5.542,4.207l-2.489,11.212h2.661\n\t\t\tc0.607,0,1.211-0.484,1.341-1.077l0.503-2.281h7.051l0.504,2.281c0.13,0.592,0.733,1.077,1.34,1.077h2.662L72.734,9.999\n\t\t\t M64.365,14.693l0.877-3.971c0.141-0.642,0.641-1.623,1.953-1.623c1.311,0,1.81,0.98,1.953,1.623l0.876,3.971H64.365z" }),
            React.createElement("path", { fill: "#E31836", d: "M81.784,9.537h5.936v11.674h3.674V6.156H80.313c-1.211,0-2.205,0.992-2.205,2.205v7.608\n\t\t\tc0,1.018-0.469,1.666-1.284,1.829l-0.753,0.18l0.682,3.234h0.699c2.514,0,4.332-1.871,4.332-5.053V9.537" }),
            React.createElement("path", { fill: "#E31836", d: "M123.331,6.156h-1.339v-1.75h-2.572c-0.607,0-1.103,0.493-1.103,1.101v0.649h-1.339\n\t\t\tc-4.542,0-7.381,3.096-7.381,7.527c0,4.432,2.839,7.528,7.381,7.528h1.339v1.825h2.572c0.607,0,1.103-0.496,1.103-1.1v-0.725\n\t\t\th1.339c4.542,0,7.38-3.096,7.38-7.528C130.711,9.252,127.873,6.156,123.331,6.156 M116.978,17.886\n\t\t\tc-2.516,0-3.706-1.592-3.706-4.203c0-2.609,1.19-4.201,3.706-4.201h1.339v8.404H116.978z M123.331,17.886h-1.339V9.482h1.339\n\t\t\tc2.517,0,3.703,1.592,3.703,4.201C127.034,16.294,125.847,17.886,123.331,17.886z" }),
            React.createElement("path", { fill: "#E31836", d: "M102.798,9.974h-5.274V6.158h-3.675v13.952c0,0.606,0.497,1.102,1.103,1.102h7.846\n\t\t\tc3.204,0,5.616-2.414,5.616-5.619C108.414,12.389,106.39,9.974,102.798,9.974 M102.798,17.832h-5.274v-4.478h5.274\n\t\t\tc0.896,0,1.942,0.802,1.942,2.238C104.74,17.027,103.694,17.832,102.798,17.832z" }),
            React.createElement("path", { fill: "#E31836", d: "M144.417,9.999c-0.552-2.477-2.113-4.207-5.542-4.207c-3.222,0-4.99,1.73-5.542,4.207l-2.489,11.212h2.663\n\t\t\tc0.605,0,1.21-0.484,1.338-1.077l0.504-2.281h7.052l0.502,2.281c0.13,0.592,0.734,1.077,1.34,1.077h2.661L144.417,9.999\n\t\t\t M136.048,14.693l0.873-3.971c0.145-0.642,0.644-1.623,1.954-1.623c1.312,0,1.809,0.98,1.954,1.623l0.875,3.971H136.048z" })),
        React.createElement("g", null,
            React.createElement("path", { fill: "#E31836", d: "M165.721,34.525c0.648-0.638,1.065-1.507,1.065-2.712c0-2.668-2.067-4.534-4.737-4.534h-8.985\n\t\t\tc-0.606,0-1.102,0.495-1.102,1.102v12.851c0,0.606,0.496,1.102,1.102,1.102l9.552,0.002c2.815,0,4.684-2.014,4.684-4.682\n\t\t\tC167.3,36.311,166.662,35.259,165.721,34.525 M162.045,30.404c0.489,0,1.157,0.548,1.157,1.484c0,0.943-0.668,1.424-1.157,1.483\n\t\t\th-6.482v-2.968H162.045z M162.34,39.212h-6.778v-2.97h6.778c0.489,0.06,1.156,0.54,1.156,1.484\n\t\t\tC163.496,38.661,162.829,39.212,162.34,39.212z" }),
            React.createElement("path", { fill: "#E31836", d: "M141.445,26.933c-4.543,0-7.719,3.235-7.719,7.874c0,4.637,3.175,7.875,7.719,7.875h1.258\n\t\t\tc4.544,0,7.719-3.238,7.719-7.875c0-4.639-3.174-7.874-7.719-7.874H141.445 M137.696,34.807c0-2.7,1.438-4.764,3.971-4.764h0.816\n\t\t\tc2.531,0,3.968,2.064,3.968,4.764c0,2.7-1.438,4.766-3.968,4.766h-0.816C139.133,39.572,137.696,37.507,137.696,34.807z" }),
            React.createElement("path", { fill: "#E31836", d: "M72.86,27.279l0.484,2.277c0.129,0.607,0.731,1.102,1.337,1.102h3.426v11.675h3.676V30.659h3.319\n\t\t\tc0.605,0,1.206-0.495,1.334-1.102l0.484-2.277H72.86" }),
            React.createElement("polyline", { fill: "#E31836", points: "196.656,27.279 196.656,33.257 189.506,33.257 189.506,27.279 185.83,27.279 185.83,42.334\n\t\t\t189.506,42.334 189.506,36.486 196.656,36.486 196.656,42.334 200.332,42.334 200.332,27.279 196.656,27.279 \t\t" }),
            React.createElement("path", { fill: "#E31836", d: "M115.633,31.124c-0.551-2.478-2.11-4.206-5.541-4.206c-3.22,0-4.989,1.729-5.538,4.206l-2.491,11.21h2.662\n\t\t\tc0.607,0,1.21-0.482,1.341-1.075l0.502-2.282h7.049l0.503,2.282c0.129,0.593,0.734,1.075,1.341,1.075h2.661L115.633,31.124\n\t\t\t M107.266,35.816l0.875-3.971c0.142-0.641,0.641-1.623,1.953-1.623c1.312,0,1.811,0.982,1.954,1.625l0.875,3.968H107.266z" }),
            React.createElement("path", { fill: "#E31836", d: "M181.717,31.124c-0.552-2.478-2.111-4.206-5.542-4.206c-3.223,0-4.989,1.729-5.54,4.206l-2.489,11.21\n\t\t\th2.663c0.605,0,1.209-0.482,1.338-1.075l0.505-2.282h7.05l0.505,2.282c0.131,0.593,0.733,1.075,1.338,1.075h2.665L181.717,31.124\n\t\t\t M173.346,35.816l0.877-3.971c0.145-0.641,0.642-1.623,1.952-1.623s1.811,0.982,1.954,1.625l0.875,3.968H173.346z" }),
            React.createElement("path", { fill: "#E31836", d: "M74.004,42.293v0.043h-7.18c-4.347,0-7.383-3.095-7.383-7.529c0-4.432,3.037-7.527,7.383-7.527h5.447\n\t\t\tl-0.533,2.307c-0.136,0.592-0.744,1.073-1.351,1.073h-3.563c-2.323,0-3.708,1.734-3.708,4.148c0,2.413,1.384,4.148,3.708,4.148\n\t\t\th5.307c0.605,0,1.214,0.482,1.35,1.074L74.004,42.293" }),
            React.createElement("path", { fill: "#E31836", d: "M96.772,27.279l-7.845,0.001c-0.608,0-1.103,0.496-1.103,1.102v13.953H91.5v-3.819h5.272\n\t\t\tc3.596,0,5.619-2.415,5.619-5.617C102.391,29.696,99.976,27.279,96.772,27.279 M96.772,35.137H91.5v-4.474h5.272\n\t\t\tc0.897,0,1.944,0.801,1.944,2.237C98.716,34.334,97.669,35.137,96.772,35.137z" }),
            React.createElement("path", { fill: "#E31836", d: "M231.487,39.156h-7.917v-2.891h8.606v-2.904h-8.606v-2.905h7.917c0.652,0,1.167-0.286,1.347-1.074\n\t\t\tl0.49-2.104h-12.326c-0.605,0-1.103,0.495-1.103,1.102v12.851c0,0.606,0.497,1.104,1.103,1.104h12.326l-0.49-2.104\n\t\t\tC232.654,39.444,232.14,39.156,231.487,39.156" }),
            React.createElement("path", { fill: "#E31836", d: "M202.852,42.334V27.279h3.676v9.933l6.367-9.045c0.359-0.488,1.146-0.888,1.757-0.888h2.725v15.055H213.7\n\t\t\tv-9.932l-6.369,9.044c-0.357,0.488-1.146,0.888-1.754,0.888H202.852" }),
            React.createElement("path", { fill: "#E31836", d: "M129.209,34.727l5.13-7.447h-3.376c-0.729,0-1.242,0.222-1.693,0.929l-2.733,4.292h-0.489l-2.735-4.292\n\t\t\tc-0.449-0.708-0.961-0.929-1.694-0.929h-3.485l5.24,7.606l-5.13,7.448h3.375c0.732,0,1.245-0.22,1.694-0.929l2.735-4.293h0.489\n\t\t\tl2.733,4.293c0.451,0.709,0.964,0.929,1.693,0.929h3.485L129.209,34.727" })))));
export const UmbrellaIcon = (React.createElement("svg", { width: "127", height: "108", viewBox: "0 0 127 108", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { opacity: "0.8", d: "M71.4551 8.02934V3.95508C71.4551 1.77082 69.6843 0 67.5 0C65.3157 0 63.5449 1.77082 63.5449 3.95508V8.02934C28.4178 10.0659 0 39.3786 0 75.4102C0 76.9305 0.871436 78.3161 2.24174 78.975C3.61178 79.6334 5.23837 79.4483 6.42568 78.4986C16.5288 70.416 31.1953 70.416 41.2987 78.4986C42.7573 79.6653 44.8553 79.6497 46.2981 78.4867C51.6032 75.0268 57.5182 72.9857 63.5449 72.3661V119.18C63.5449 127.903 70.6419 135 79.3652 135C88.0886 135 95.1855 127.903 95.1855 119.18C95.1855 116.995 93.4147 115.225 91.2305 115.225C89.0462 115.225 87.2754 116.995 87.2754 119.18C87.2754 123.541 83.7269 127.09 79.3652 127.09C75.0036 127.09 71.4551 123.541 71.4551 119.18V72.3661C77.4818 72.9857 83.3968 75.0268 88.7019 78.4867C90.1278 79.636 92.2319 79.674 93.7013 78.4986C103.804 70.416 118.471 70.416 128.574 78.4986C129.766 79.4517 131.393 79.631 132.758 78.975C134.129 78.3163 135 76.9308 135 75.4102C135 57.4507 127.959 40.5192 115.175 27.7351C103.334 15.8936 87.9338 8.98462 71.4551 8.02934ZM87.243 68.6435C80.9813 65.7147 74.241 64.2452 67.5 64.2452C60.759 64.2452 54.019 65.7147 47.757 68.6435C48.3783 38.2859 58.2828 15.8203 67.5 15.8203C76.4411 15.8203 86.6054 37.4833 87.243 68.6435ZM8.36657 68.0163C11.3848 43.7313 29.0738 23.9351 52.259 17.7968C43.3498 30.915 40.1728 52.4085 39.8474 68.2538C29.9969 63.3859 18.2775 63.3068 8.36657 68.0163ZM95.1523 68.2538C94.8272 52.4087 91.6502 30.9153 82.7408 17.7968C105.926 23.9351 123.615 43.7313 126.633 68.0163C116.722 63.3066 105.003 63.3859 95.1523 68.2538Z", fill: "white" })));
