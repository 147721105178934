import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CheckinOrderStatus } from '@websky/graphql';
import { getBaggage, getBaggagePrice, getCheckinBrandIncludedBaggage, getUpgradeBaggage, getAdditionalBaggage, getSpecialEquipment, getOverweightBaggage, getCheckinOrder, getPassengersNames, getSegmentsForServices, getTravellers, getTotalPrice } from '../../../../store/order/selectors';
import { useSteps } from '../../../../utils';
import { CheckinStep } from '../../../../types';
import BaggagePage from '../../../../../../BaggagePage/components/BaggagePage';
import { Money } from '../../../../../../Money';
import { useTheme } from '../../../../../../theme';
import ServicesToolbar from '../../../../../../ServicesToolbar';
import { getSelectedBaggage } from '../../../../store/selectedServices/selectors';
import { useConfig } from '../../../../../../context';
import { useGetGoBack } from '../utils';
const Baggage = () => {
    const order = useSelector(getCheckinOrder);
    const totalPrice = useSelector(getTotalPrice);
    const baggagePriceToPay = useSelector(getBaggagePrice);
    const travellers = useSelector(getTravellers);
    const segments = useSelector(getSegmentsForServices);
    const baggage = useSelector(getBaggage);
    const passengersNames = useSelector(getPassengersNames);
    const selectedBaggage = useSelector(getSelectedBaggage);
    const includedBaggage = useSelector(getCheckinBrandIncludedBaggage);
    const upgradeBaggage = useSelector(getUpgradeBaggage);
    const additionalBaggage = useSelector(getAdditionalBaggage);
    const specialBaggage = useSelector(getSpecialEquipment);
    const overweightBaggage = useSelector(getOverweightBaggage);
    const { goToNextStep } = useSteps();
    const { t } = useTranslation('Checkin');
    const { Checkin: css } = useTheme('Checkin');
    const { global: { dynamicBaggagePricing } } = useConfig();
    const passengerLabels = passengersNames.reduce((result, { id, name }) => {
        result[id] = name;
        return result;
    }, {});
    const servicesByPassengers = travellers.map(traveller => {
        var _a, _b, _c;
        return ({
            id: traveller.id,
            services: (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services.map(({ count, serviceId, segmentIds, confirmedCount }) => ({
                count,
                segmentIds,
                serviceId,
                confirmedCount
            }))) !== null && _c !== void 0 ? _c : []
        });
    });
    const goBack = useGetGoBack(CheckinStep.Baggage);
    return (React.createElement(React.Fragment, null,
        React.createElement(ServicesToolbar, { toolbarProps: {
                totalPrice,
                onBackButtonClick: goBack,
                onButtonClick: () => goToNextStep(CheckinStep.Baggage),
                additional: baggagePriceToPay.amount ? (React.createElement("div", { className: css.servicePrice },
                    t('Baggage'),
                    ": ",
                    React.createElement(Money, { money: baggagePriceToPay }))) : null
            }, footerMobileProps: {
                onContinue: () => goToNextStep(CheckinStep.Baggage),
                onBack: goBack
            } },
            React.createElement(BaggagePage, { baggage: baggage, includedBaggage: includedBaggage, upgradeBaggage: upgradeBaggage, additionalBaggage: additionalBaggage, specialBaggage: specialBaggage, overweightBaggage: overweightBaggage, selectedBaggage: selectedBaggage, passengers: servicesByPassengers, passengerLabels: passengerLabels, segments: segments, disabled: order.status === CheckinOrderStatus.Expired, dynamicBaggagePricing: dynamicBaggagePricing }))));
};
export default Baggage;
