import { __rest } from "tslib";
import * as React from 'react';
import MUITooltip from '@material-ui/core/Tooltip';
import classnames from 'classnames';
import { useTheme } from '../theme';
const Tooltip = (_a) => {
    var { title, children, titleClassName, tooltipClassName, popperClassName, placement = 'top' } = _a, restProps = __rest(_a, ["title", "children", "titleClassName", "tooltipClassName", "popperClassName", "placement"]);
    const { Tooltip: css } = useTheme('Tooltip');
    return (React.createElement(MUITooltip, Object.assign({ title: React.createElement("span", { className: classnames(css.title, titleClassName) }, title), placement: placement, arrow: true, PopperProps: {
            modifiers: {
                flip: {
                    enabled: false
                },
                preventOverflow: {
                    escapeWithReference: false
                }
            }
        }, classes: {
            tooltip: classnames(css.tooltip, tooltipClassName),
            popper: classnames(css.popper, popperClassName),
            arrow: css.tooltip_arrow
        } }, restProps), children));
};
export default Tooltip;
