import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import RefundPaper from '../RefundPaper/RefundPaper';
import { useTheme } from '../../../theme';
import Money from '../../../Money';
const Price = props => {
    const { price } = props;
    const { t } = useTranslation('Refund');
    const { Price: css } = useTheme('Refund');
    return (React.createElement(RefundPaper, { className: cn(css.price, props.className) },
        React.createElement("div", { className: css.total },
            React.createElement("h2", { className: css.totalTitle },
                t('Refund amount'),
                ":"),
            React.createElement(Money, { moneyClassName: css.money, money: Object.assign(Object.assign({}, price.priceToPay), { amount: Math.abs(price.priceToPay.amount) }) })),
        React.createElement("p", { className: css.row },
            t('Ticket price'),
            ": ",
            React.createElement(Money, { money: price.basePrice }))));
};
export default Price;
