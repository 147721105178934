import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { NotificationCard } from '../../NotificationCard/NotificationCard';
import { format } from '../../../../utils';
import { Group } from '../../NotificationCard/Group/Group';
import { Info } from '../../NotificationCard/Info/Info';
import cn from 'classnames';
import { NOTIFICATION_DATE_FORMAT } from '../../../constants';
import { fromUTCToLocalTimeZone } from '../../../utils';
export const AppealCard = props => {
    const { AppealCard: css } = useTheme('Communications');
    const { t } = useTranslation('Communications');
    const datePrefix = props.updatedAt ? t('Updated') : t('Created');
    const date = fromUTCToLocalTimeZone(props.updatedAt ? props.updatedAt : props.createdAt);
    const dateFormat = `${format(date, NOTIFICATION_DATE_FORMAT)}`;
    return (React.createElement(NotificationCard, { isRead: props.isRead, title: props.title, date: `${datePrefix}: ${dateFormat}`, preDescRender: () => (React.createElement(Group, null,
            React.createElement(Info, null,
                t('Status'),
                ":",
                React.createElement("span", { className: cn(css.status, {
                        [css.status__solved]: props.status === 'Solved',
                        [css.status__new]: props.status === 'New',
                        [css.status__progress]: props.status === 'InProgress',
                        [css.status__declined]: props.status === 'Rejected',
                        [css.status__awaiting]: props.status === 'WaitingAnswer'
                    }) }, t(props.status))),
            React.createElement(Info, { align: "right" },
                t('ID'),
                ": ",
                props.id))), markAsRead: props.markAsRead },
        React.createElement("p", null, props.message)));
};
