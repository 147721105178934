import * as React from 'react';
import { useCallback, useReducer } from 'react';
import { getTotalPrice } from '../utils';
const initialState = [];
const insuranceReducer = (state = initialState, action) => {
    if (action.type === 'toggle') {
        if (state.includes(action.payload)) {
            return state.filter(code => code !== action.payload);
        }
        return [...state, action.payload];
    }
    return state;
};
const createInitialState = (selectedInsurances) => {
    return selectedInsurances.map(insurance => insurance.insuranceProgram.code);
};
export const useInsuranceState = (insurances, selectedInsurances) => {
    const initialState = createInitialState(selectedInsurances);
    const [state, dispatch] = useReducer(insuranceReducer, initialState);
    const confirmedCodes = React.useMemo(() => {
        return state.filter(code => selectedInsurances.some(insurance => { var _a; return !!insurance.policyNumber && ((_a = insurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.code) === code; }));
    }, [state, selectedInsurances]);
    const selectedWithoutConfirmedCodes = React.useMemo(() => {
        return state.filter(code => !confirmedCodes.includes(code));
    }, [state, confirmedCodes]);
    const totalPrice = React.useMemo(() => {
        const insurancePrograms = insurances.filter(insurance => selectedWithoutConfirmedCodes.includes(insurance.code));
        const totalPrice = getTotalPrice(insurancePrograms, insurance => insurance.totalPrice);
        if (totalPrice.amount > 0) {
            return totalPrice;
        }
        return null;
    }, [insurances, selectedWithoutConfirmedCodes]);
    const hasChanges = React.useMemo(() => {
        return JSON.stringify(initialState.sort()) !== JSON.stringify(state.sort());
    }, [initialState, state]);
    const onToggle = useCallback((code) => {
        dispatch({ type: 'toggle', payload: code });
    }, [state]);
    return {
        state,
        dispatch,
        confirmedCodes,
        selectedWithoutConfirmedCodes,
        totalPrice,
        hasChanges,
        onToggle
    };
};
