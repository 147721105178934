import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import Input from '../../Input';
import { BaseButton, IconButton } from '../../index';
import { useTheme } from '../../theme';
import * as icons from '../../Icons';
import { PromoCodeStatus } from '../types';
import FormField from './Field/Field';
const PromoCodeForm = props => {
    const { t } = useTranslation('PromoCode');
    const { PromoCodeForm: theme } = useTheme('PromoCode');
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.title }, props.title),
        React.createElement(Form, { onSubmit: props.onSubmit }, ({ handleSubmit }) => {
            var _a;
            return (React.createElement("form", { onSubmit: handleSubmit, className: theme.form },
                React.createElement(Field, { name: 'promoCode', validate: value => {
                        if (!value || !value.trim()) {
                            return t('Required');
                        }
                    } }, ({ input, meta }) => (React.createElement(Input, { className: theme.codeInput, absoluteHintPosition: true, TextFieldProps: Object.assign(Object.assign({}, input), { label: t('Enter a promo code'), type: 'input', onChange: e => {
                            var _a;
                            input.onChange(e);
                            (_a = props.onChangePromoCode) === null || _a === void 0 ? void 0 : _a.call(props, e.target.value);
                        }, error: meta.touched && !meta.valid, helperText: meta.touched && !meta.valid ? meta.error : null }) }))),
                ((_a = props.additionalFields) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement("div", { className: theme.form_additionalFields }, props.additionalFields.map((field, index) => (React.createElement(FormField, Object.assign({ key: index }, field)))))),
                React.createElement("div", { className: cn(theme.statusMessage, {
                        [theme.statusMessage_applied]: props.status === PromoCodeStatus.Applied,
                        [theme.statusMessage_notApplied]: props.status === PromoCodeStatus.NotApplied
                    }) },
                    props.submitButtonText && (React.createElement(BaseButton, { type: 'submit', className: cn(theme.button, {
                        // [theme.button_empty]: props.status === PromoCodeStatus.Empty,
                        // [theme.button_applied]: props.status === PromoCodeStatus.Applied
                        }), isLoading: props.isLoading, "data-promocode-submit-button": true }, (props.status === PromoCodeStatus.Empty ||
                        props.status === PromoCodeStatus.NotApplied) &&
                        props.submitButtonText)),
                    !props.submitButtonText && (React.createElement(IconButton, { type: 'submit', className: cn(theme.button, {
                            [theme.button_empty]: props.status === PromoCodeStatus.Empty,
                            [theme.button_applied]: props.status === PromoCodeStatus.Applied
                        }), isLoading: props.isLoading, isSuccess: props.status === PromoCodeStatus.Applied, "data-promocode-submit-button": true }, (props.status === PromoCodeStatus.Empty ||
                        props.status === PromoCodeStatus.NotApplied) &&
                        icons.ArrowForward)),
                    !props.isLoading && (React.createElement("span", { className: theme.statusText }, props.status === PromoCodeStatus.Applied && t('Promo code applied'))))));
        })));
};
export default PromoCodeForm;
