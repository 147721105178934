import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Redirect, Route as RouteComponent, Switch, useHistory, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import MediaQuery from 'react-responsive';
import { initI18n, TABLET_MIN_WIDTH } from '../../utils';
import Sidebar from '../../Sidebar';
import Loader from '../../Loader';
import SimpleLoader from '../../SimpleLoader';
import { Aircraft, Communications, Group, LogoutIcon, MyCabinet, Settings, Star } from '../../Modules/Account/Icons';
import TravelCompanions from '../../TravelCompanions';
import { useConfig } from '../../context';
import LoginPage from '../../LoginPage';
import MobileMenu from '../../Sidebar/components/MobileMenu/MobileMenu';
import { reachGoal, UserGoal } from '../../analytics';
import Logout from './Logout/Logout';
import Loyalty from '../../Loyalty';
import Orders from './Orders/Orders';
import { useTheme } from '../../theme';
import { LoginType, useGetAccountInfoQuery } from '@websky/graphql';
import WarningModal from '../../WarningModal';
import HomePage from './HomePage';
import { renderType } from '../../TravelCompanions/components/TravelCompanions';
import SettingsPage from './Settings/Settings';
import CommunicationPage from '../../Communications/components/CommunicationBase';
import { useLogout } from '../../Modules/Account/hooks';
import { useHasLoyaltyProgram } from '../../hooks';
import { useCurrentUser } from '../../CurrentUser/CurrentUser.hooks';
import { useSelector } from 'react-redux';
import { getUnreadTotal } from '../../Communications/store/selectors';
import { OverrideComponent } from '../../renderProps';
import { AUTH_DUPLICATES, sessionStorageGet } from '../../cache';
import { tinkoffCallbackUrlSuffix } from '../../SocialAuth/components/utils';
initI18n('Account');
let routes = [
    {
        title: i18n.t('My office'),
        pathname: '/account',
        icon: MyCabinet
    },
    {
        title: i18n.t('Loyalty program'),
        pathname: '/account/loyalty',
        icon: Star
    },
    {
        title: i18n.t('Orders'),
        pathname: '/account/orders',
        icon: Aircraft
    },
    {
        title: i18n.t('Passengers'),
        pathname: '/account/companions',
        icon: Group
    },
    {
        title: i18n.t('Profile'),
        pathname: '/account/settings',
        icon: Settings
    },
    {
        title: i18n.t('Communications'),
        pathname: '/account/communications',
        icon: Communications,
        hasChild: true
    }
    // {
    // 	title: i18n.t('Logout'),
    // 	pathname: '/account/logout',
    // 	icon: LogoutIcon
    // }
    // {
    // 	title: i18n.t('Client support'),
    // 	pathname: '/account/support',
    // 	icon: Support
    // }
];
var LoginStages;
(function (LoginStages) {
    LoginStages[LoginStages["LoggedIn"] = 0] = "LoggedIn";
    LoginStages[LoginStages["Loading"] = 1] = "Loading";
    LoginStages[LoginStages["NotLoggedIn"] = 2] = "NotLoggedIn";
})(LoginStages || (LoginStages = {}));
const SYSTEM_PAGES = [tinkoffCallbackUrlSuffix];
const Account = ({ location }) => {
    const [isLoggedIn, setLoggedIn] = useState(LoginStages.Loading);
    const [accountInfoError, setAccountInfoError] = useState(null);
    const hasLoyaltyProgram = useHasLoyaltyProgram();
    const communicationUnreadTotal = useSelector(getUnreadTotal);
    const history = useHistory();
    const { Account: css } = useTheme('AccountModule');
    const { t } = useTranslation('Account');
    const { t: checkoutT } = useTranslation('Checkout');
    const currentUser = useGetAccountInfoQuery({
        variables: {
            skipOrdersList: true
        },
        fetchPolicy: 'no-cache'
    });
    const { onLogout, loading: logoutLoading } = useLogout('/account');
    const [isLoading, setLoading] = React.useState(false);
    const { Account: { engineURL, showCommunications }, global: { companyInfo } } = useConfig();
    useCurrentUser();
    useEffect(() => {
        var _a, _b;
        if ((_b = (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.authMethods) {
            setLoggedIn(LoginStages.LoggedIn);
        }
        else if (currentUser.loading) {
            setLoggedIn(LoginStages.Loading);
        }
        else {
            setLoggedIn(LoginStages.NotLoggedIn);
        }
        if (currentUser === null || currentUser === void 0 ? void 0 : currentUser.error) {
            setAccountInfoError(currentUser.error.message);
        }
    }, [currentUser]);
    const [userSettingsError, setUserSettingsError] = useState(null);
    const getUserInfo = () => {
        const userValues = {
            email: '',
            firstName: '',
            lastName: ''
        };
        if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.data) && !currentUser.loading && currentUser.data.CurrentUser) {
            currentUser.data.CurrentUser.userProfile.values.forEach(value => {
                if (value.name === 'lastName' || value.name === 'firstName' || value.name === 'email') {
                    userValues[value.name] = value.value;
                }
            });
        }
        return userValues;
    };
    const refetchAccountInfo = () => {
        setLoading(true);
        currentUser.refetch().finally(() => setLoading(false));
    };
    const clearUserSettingsError = useCallback(() => {
        setUserSettingsError(null);
    }, []);
    const onLoyaltyCardSaved = () => {
        setTimeout(() => {
            refetchAccountInfo();
        }, 2000);
        return new Promise(res => res(true));
    };
    routes = routes.filter(route => {
        const pathRoute = route;
        if (pathRoute.pathname === '/account/loyalty') {
            return hasLoyaltyProgram;
        }
        if (pathRoute.pathname === '/account/communications') {
            return showCommunications;
        }
        return true;
    });
    routes.find(route => {
        var _a, _b;
        const pathRoute = route;
        if (pathRoute.pathname === '/account/loyalty') {
            route.title = checkoutT(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyaltyProgramName);
            if ((_a = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyalty) === null || _a === void 0 ? void 0 : _a.redirectTo) {
                pathRoute.pathname = (_b = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyalty) === null || _b === void 0 ? void 0 : _b.redirectTo;
                pathRoute.isRedirect = true;
            }
        }
        if (pathRoute.pathname === '/account/communications') {
            pathRoute.badgeContent = communicationUnreadTotal;
        }
    });
    useEffect(() => {
        routes = [
            ...routes,
            {
                title: i18n.t('Logout'),
                icon: LogoutIcon,
                action: onLogout
            }
        ];
    }, []);
    useEffect(() => {
        if (isLoggedIn === LoginStages.NotLoggedIn && !SYSTEM_PAGES.some(path => location.pathname.includes(path))) {
            history.replace('/account');
        }
    }, [isLoggedIn]);
    const onCloseAccountInfoError = () => {
        setAccountInfoError(null);
    };
    const onGoToSettings = () => {
        history.push('/account/settings');
    };
    useEffect(() => {
        var _a;
        if (!!sessionStorageGet(AUTH_DUPLICATES) && ((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser)) {
            onGoToSettings();
        }
    }, [currentUser]);
    return (React.createElement(React.Fragment, null,
        logoutLoading && React.createElement(SimpleLoader, null),
        isLoggedIn === LoginStages.LoggedIn && (React.createElement("div", { className: css.wrapper },
            React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
                React.createElement(Sidebar, { routes: routes, selectedRoute: location.pathname, userInfo: getUserInfo() })),
            React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                React.createElement(MobileMenu, { routes: routes, selectedRoute: location.pathname, userInfo: getUserInfo() })),
            React.createElement("div", { className: css.active__route },
                React.createElement(Switch, null,
                    React.createElement(RouteComponent, { path: "/account", exact: true, render: () => (React.createElement(HomePage, { currentUser: currentUser.data.CurrentUser.userProfile })) }),
                    React.createElement(RouteComponent, { path: "/account/orders", exact: true, render: () => React.createElement(Orders, null) }),
                    React.createElement(RouteComponent, { path: "/account/profile", exact: true, render: () => React.createElement(Redirect, { to: "/account/companions" }) }),
                    React.createElement(RouteComponent, { path: "/account/settings", render: () => {
                            var _a;
                            return (React.createElement(SettingsPage, { currentUser: (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser, setUserSettingsError: setUserSettingsError, refetchAccountInfo: refetchAccountInfo }));
                        } }),
                    React.createElement(RouteComponent, { path: "/account/logout", exact: true, render: () => {
                            if (isLoggedIn === LoginStages.LoggedIn) {
                                return React.createElement(Logout, null);
                            }
                            else if (isLoggedIn === LoginStages.NotLoggedIn) {
                                return React.createElement(Redirect, { to: "/account" });
                            }
                            else {
                                return React.createElement(Loader, null);
                            }
                        } }),
                    hasLoyaltyProgram && (React.createElement(RouteComponent, { path: "/account/loyalty", exact: true, render: () => {
                            var _a, _b, _c, _d, _e;
                            return (React.createElement(React.Fragment, null,
                                React.createElement("h1", { className: css.route__title }, checkoutT(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyaltyProgramName)),
                                React.createElement(OverrideComponent, { componentProps: {
                                        isAuthorizationNeeded: (_b = (_a = currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.isFfpAuthorizationNeeded,
                                        links: (_c = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyalty) === null || _c === void 0 ? void 0 : _c.links,
                                        ffpInfo: (_e = (_d = currentUser.data) === null || _d === void 0 ? void 0 : _d.CurrentUser) === null || _e === void 0 ? void 0 : _e.userFfpInfo,
                                        loyaltyName: checkoutT(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyaltyProgramName),
                                        onLoyaltyCardSaved: onLoyaltyCardSaved,
                                        userInfo: getUserInfo(),
                                        onGoToSettings: onGoToSettings,
                                        isLoading: isLoading
                                    }, component: { AccountLoyalty: Loyalty } })));
                        } })),
                    React.createElement(RouteComponent, { path: "/account/companions", exact: true, render: () => (React.createElement(React.Fragment, null,
                            React.createElement(TravelCompanions, { format: renderType.Companions }))) }),
                    showCommunications && (React.createElement(RouteComponent, { path: "/account/communications", render: () => React.createElement(CommunicationPage, null) })),
                    React.createElement(RouteComponent, { path: "/", render: () => {
                            window.location.href = `${engineURL}#/404`;
                            return null;
                        } }))))),
        isLoggedIn === LoginStages.NotLoggedIn && (React.createElement("div", { className: css.loginWrapper },
            React.createElement(LoginPage, { loginType: LoginType.Email, onSuccess: isRegistration => {
                    reachGoal(isRegistration ? UserGoal.Registration : UserGoal.Login);
                    window.location.reload();
                } }),
            React.createElement(WarningModal, { isOpen: !!accountInfoError, onClose: onCloseAccountInfoError, onButtonClick: onCloseAccountInfoError, errorMessage: accountInfoError, title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), buttonText: t('Ok') }))),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), errorMessage: userSettingsError ? userSettingsError.message : '', onClose: clearUserSettingsError, onButtonClick: clearUserSettingsError, buttonText: t('OK, thank you'), isOpen: !!userSettingsError })));
};
export default withRouter(Account);
