import { __awaiter } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TravellerFieldEnum } from '@websky/graphql';
import { useConfig } from '../../../context';
import { useToggleable } from '../../../hooks';
import { getMinPrice, getTotalPrice, getUserValue } from '../../../utils';
const isCheckoutPassengerGuard = (passenger) => {
    return !passenger.services;
};
export const useInsurance = ({ orderId, insurances, insurancePrograms, passengers, onSaveServices, onUpdateOrder }) => {
    var _a;
    const { t } = useTranslation('Checkout');
    const { global: { companyInfo: { multiInsurance, insurance: insuranceRulesUrl, specialDocumentForInsurance: insuranceDocuments } } } = useConfig();
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const { isOpen: insuranceModalIsOpen, open: openInsuranceModal, close: closeInsuranceModal } = useToggleable(false);
    const { isOpen: downloadModalIsOpen, open: openDownloadModal, close: closeDownloadModal } = useToggleable(false);
    const { isOpen: confirmationModalIsOpen, open: openConfirmationModal, close: closeConfirmationModal } = useToggleable(false);
    const initialState = passengers.map(passenger => {
        var _a, _b, _c, _d, _e, _f, _g;
        const insurancePassenger = (_a = insurances === null || insurances === void 0 ? void 0 : insurances.travellerExtraValues) === null || _a === void 0 ? void 0 : _a.find(traveller => traveller.traveller.id === passenger.id);
        const firstName = isCheckoutPassengerGuard(passenger)
            ? getUserValue(passenger, TravellerFieldEnum.FirstName)
            : '';
        const lastName = isCheckoutPassengerGuard(passenger)
            ? getUserValue(passenger, TravellerFieldEnum.LastName)
            : '';
        return {
            name: `${lastName} ${firstName}`,
            value: ((_c = (_b = insurancePassenger === null || insurancePassenger === void 0 ? void 0 : insurancePassenger.extraValues) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.value) || '',
            id: passenger.id,
            regex: (_e = (_d = insurancePassenger === null || insurancePassenger === void 0 ? void 0 : insurancePassenger.extraValues) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.validationRules[0].regExp[0],
            isRequired: insurancePassenger === null || insurancePassenger === void 0 ? void 0 : insurancePassenger.needKzIinDocument,
            disabled: !!((_g = (_f = insurancePassenger === null || insurancePassenger === void 0 ? void 0 : insurancePassenger.extraValues) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.value)
        };
    });
    const [passengerDocuments, setPassengerDocuments] = React.useState(initialState);
    const availablePrograms = new Set((_a = insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.map(program => program.code)) !== null && _a !== void 0 ? _a : []);
    const selectedInsurances = React.useMemo(() => {
        var _a;
        const selectedInsuranceMap = new Map();
        if ((_a = insurances === null || insurances === void 0 ? void 0 : insurances.selectedInsurances) === null || _a === void 0 ? void 0 : _a.length) {
            insurances.selectedInsurances.forEach(insurance => {
                const insuranceCode = insurance.insuranceProgram.code;
                const key = `${insuranceCode}-${insurance.passengerName}`;
                // exclude duplicate selected insurances
                if (availablePrograms.has(insuranceCode) && !selectedInsuranceMap.has(key)) {
                    selectedInsuranceMap.set(key, insurance);
                }
            });
        }
        return [...selectedInsuranceMap.values()];
    }, [insurances, availablePrograms]);
    const onSave = (selectedCodes, passengersWithDoc) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        setIsLoading(true);
        const selectedInsuranceCodes = insurances.selectedInsurances
            .filter(insurance => !availablePrograms.has(insurance.insuranceProgram.code))
            .map(insurance => insurance.insuranceProgram.code);
        const selectedProgramCodes = [...new Set([...selectedInsuranceCodes, ...selectedCodes])];
        const programsByPassengers = passengers.map(passenger => {
            const travellerDocumentNumber = passengersWithDoc
                ? passengersWithDoc.find(traveller => traveller.id === passenger.id).value
                : '';
            return {
                travellerId: passenger.id,
                programCodes: selectedProgramCodes,
                travellerDocumentNumber
            };
        });
        setPassengerDocuments(passengersWithDoc);
        try {
            const { data } = yield onSaveServices({
                variables: {
                    params: {
                        id: orderId,
                        insurances: [
                            Object.assign({ selectedPrograms: selectedProgramCodes }, (multiInsurance ? { selectedProgramsByTravellers: programsByPassengers } : {}))
                        ]
                    }
                }
            });
            if (data) {
                onUpdateOrder(data);
            }
        }
        catch (e) {
            const error = e;
            if ((_b = error.graphQLErrors) === null || _b === void 0 ? void 0 : _b.length) {
                setError(error.graphQLErrors[0]);
            }
        }
        setIsLoading(false);
        closeInsuranceModal();
    });
    const onAddInsurance = () => __awaiter(void 0, void 0, void 0, function* () {
        if (multiInsurance) {
            openInsuranceModal();
        }
        else if (selectedInsurances.length) {
            openConfirmationModal();
        }
        else {
            yield onSave([insurancePrograms[0].code]);
        }
    });
    const onRemoveInsurance = () => __awaiter(void 0, void 0, void 0, function* () {
        yield onSave([]);
        closeConfirmationModal();
    });
    const clearError = () => {
        setError(null);
    };
    const buttons = React.useMemo(() => {
        let buttons = [
            {
                action: onAddInsurance,
                text: t(multiInsurance ? 'Change' : 'Cancel'),
                isLoading,
                buttonType: 'change'
            }
        ];
        if (selectedInsurances.some(insurance => insurance.policyUrl) && !isLoading) {
            buttons = [
                ...buttons,
                {
                    action: () => {
                        if (selectedInsurances.length > 1) {
                            openDownloadModal();
                        }
                        else {
                            window.open(selectedInsurances[0].policyUrl, '_blank');
                        }
                    },
                    text: t('Download')
                }
            ];
        }
        if (insuranceRulesUrl && !isLoading) {
            buttons = [
                ...buttons,
                {
                    action: () => window.open(insuranceRulesUrl),
                    text: t('AdditionalServiceCard:Terms of service')
                }
            ];
        }
        return buttons;
    }, [selectedInsurances, isLoading]);
    const minPrice = React.useMemo(() => {
        if (insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.length) {
            return getMinPrice(insurancePrograms, program => program.totalPrice);
        }
        return null;
    }, [insurancePrograms]);
    const totalPrice = React.useMemo(() => {
        return getTotalPrice(selectedInsurances, insurance => insurance.passengerPrice);
    }, [selectedInsurances]);
    return {
        // common
        multiInsurance,
        insuranceDocuments,
        insuranceRulesUrl,
        passengerDocuments,
        selectedInsurances,
        buttons,
        minPrice,
        totalPrice,
        error,
        onSave,
        onAddInsurance,
        onRemoveInsurance,
        clearError,
        // insurance modal
        insuranceModalIsOpen,
        openInsuranceModal,
        closeInsuranceModal,
        // confirmation modal
        confirmationModalIsOpen,
        openConfirmationModal,
        closeConfirmationModal,
        // download modal
        downloadModalIsOpen,
        openDownloadModal,
        closeDownloadModal
    };
};
