import * as React from 'react';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { LocationType } from '../../../../../../../types';
import { MOBILE_MIN_WIDTH } from '../../../../../../../utils';
import { ConfigContext } from '../../../../../../../context';
import { useTheme } from '../../../../../../../theme';
import { getDirectionAttribute } from './utils';
const Value = ({ className, isOpen, isLoading, location, inputValue, type, withMultiCity }) => {
    const { Value: css } = useTheme('SearchForm');
    const { t } = useTranslation('SearchForm');
    const alternative = React.useContext(ConfigContext).SearchForm.mobileFormType !== 'common';
    const proMode = React.useContext(ConfigContext).SearchForm.proMode;
    const isDeparture = type === LocationType.Departure;
    const renderFocusedWrapper = () => {
        return (React.createElement("div", { className: cn(css.wrapper, css.wrapper_focused, className, {
                [css.wrapper_padded]: isLoading
            }) },
            !inputValue && (React.createElement("span", { className: css.placeholder }, t(isDeparture ? 'From, enter city or country' : 'To, enter city or country'))),
            isLoading && React.createElement("span", { className: css.loader })));
    };
    const renderValue = () => {
        return (React.createElement("div", { className: cn(css.wrapper, className, {
                [css.wrapper_alternative]: alternative,
                [css.withMultiCity]: withMultiCity,
                [css.advanced]: proMode
            }) },
            React.createElement("span", Object.assign({ className: css.value }, getDirectionAttribute(isDeparture, 'city')), location.name),
            React.createElement("span", Object.assign({ className: css.code }, getDirectionAttribute(isDeparture, 'iata')), location.iata)));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            location && renderValue(),
            isOpen && renderFocusedWrapper()),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, location && renderValue())));
};
export default Value;
