import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useTheme } from '../../../../../theme';
import ModalConfirm from '../../../../../ModalConfirm/components/ModalConfirm';
const Warning = ({ open, onClose, redirectURL }) => {
    const { Warning: css } = useTheme('Checkout');
    const { t } = useTranslation('Checkout');
    const onClick = useCallback(() => (window.location.href = redirectURL), [redirectURL]);
    return (React.createElement(ModalConfirm, { size: "medium", isOpen: open, onClose: onClose, title: t('Next step'), confirmBtn: t('Continue'), onConfirm: onClick },
        React.createElement("div", { className: css.text }, t('Now you will be redirected to the previous version of the site. There you can choose ancillary services.'))));
};
export default Warning;
