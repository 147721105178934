import { __rest } from "tslib";
import * as React from 'react';
import ServiceSummaryOption from '../../ServiceSummary/components/ServiceSummaryOption';
import { getPassengerTypeLabel } from '../../utils';
const CartItemOptionAdapter = (_a) => {
    var _b;
    var _c = _a.option, { headerType, traveller } = _c, option = __rest(_c, ["headerType", "traveller"]);
    if (!option.items.length) {
        return null;
    }
    let itemHeader = option.header;
    if (headerType === 'travellerName') {
        itemHeader =
            (_b = traveller.name) !== null && _b !== void 0 ? _b : `${getPassengerTypeLabel(traveller.type, traveller.supplierTravellerType)} ${traveller.typeNumber}`;
    }
    return (React.createElement(ServiceSummaryOption, { groupOption: Object.assign(Object.assign({}, option), { header: itemHeader }) }));
};
export default CartItemOptionAdapter;
