import * as React from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import cn from 'classnames';
import DownloadAeroexpress from '../RegisteredPassenger/DownloadAeroexpress/DownloadAeroexpress';
import SelectedPassenger from '../../../../../../../SelectedPassenger';
import MediaQuery from '../../../../../../../MediaQuery/MediaQuery';
import AddPassenger from '../../../../../../../AddPassenger/AddPassenger';
import BoardingPassMob from '../../../../../../../BoardingPassMob';
import RegisteredPassenger from '../RegisteredPassenger/RegisteredPassenger';
import { getCheckinHasCompleted, getCheckinNotStarted, getCheckinOrder, getHasConfirmedAeroexpress, getIsAllTravellersHavePreseating, getPassengerIdsWithLinkedPassengers } from '../../../../../store/order/selectors';
import { useTheme } from '../../../../../../../theme';
const PassengersContent = ({ className, segmentsFailedBoardingPasses, onAddTraveller, onRemoveTraveller, onCheckinCancel, setIsLoading, setSegmentsFailedBoardingPasses }) => {
    const { PassengersContent: css } = useTheme('Checkin');
    const order = useSelector(getCheckinOrder);
    const passengerIdsWithLinkedPassengers = useSelector(getPassengerIdsWithLinkedPassengers);
    const hasConfirmedAeroexpress = useSelector(getHasConfirmedAeroexpress);
    const isConfirmed = useSelector(getCheckinHasCompleted);
    const isNotStarted = useSelector(getCheckinNotStarted);
    const isAllTravellersHavePreseating = useSelector(getIsAllTravellersHavePreseating);
    const registeredPassengers = order.travellers.map((traveller, index) => {
        return (React.createElement("div", { className: css.registeredPassenger },
            React.createElement(MediaQuery, { maxWidth: "mobile" }, order.segments.map((segment, index) => (React.createElement(BoardingPassMob, { key: index, className: css.segment, segment: segment, isCheckedIn: traveller.isConfirmed })))),
            React.createElement(RegisteredPassenger, { key: index, passenger: traveller, linkedPassengers: passengerIdsWithLinkedPassengers.get(traveller.id), segments: order.segments, segmentsFailedBoardingPasses: segmentsFailedBoardingPasses, setSegmentsFailedBoardingPasses: setSegmentsFailedBoardingPasses, onCheckinCancel: onCheckinCancel })));
    });
    const sliderOptions = {
        className: css.slider,
        slidesToShow: order.travellers.length > 1 ? 1.1 : 1.03,
        dots: true,
        arrows: false,
        infinite: false,
        useCSS: true
    };
    return (React.createElement("div", { className: cn(className, css.passengers, {
            [css.passengers_confirmed]: isConfirmed,
            [css.passengers_started]: !isNotStarted
        }) },
        isConfirmed && hasConfirmedAeroexpress && (React.createElement(DownloadAeroexpress, { travellers: order.travellers.map(traveller => ({
                id: traveller.id
            })), orderId: order.id, setLoading: setIsLoading })),
        isConfirmed ? (React.createElement("div", null,
            React.createElement(MediaQuery, { minWidth: "mobile" }, registeredPassengers),
            React.createElement(MediaQuery, { maxWidth: "mobile" },
                React.createElement(Slider, Object.assign({}, sliderOptions), registeredPassengers)))) : (React.createElement("div", null, order.travellers.map((traveller, index) => {
            const canRemove = isNotStarted && order.travellers.length > 1 && traveller.canBeDeleted;
            return (React.createElement(SelectedPassenger, { key: index, className: css.passenger, passenger: traveller, onDelete: canRemove ? onRemoveTraveller : undefined }));
        }))),
        isNotStarted && !isAllTravellersHavePreseating && React.createElement(AddPassenger, { onSubmit: onAddTraveller })));
};
export default PassengersContent;
