import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RowContext } from '../SeatMap/Row/context';
import { useIataCode } from '../../../hooks';
export const useRestrictionsConditionForKV = () => {
    var _a;
    const row = (_a = useContext(RowContext)) === null || _a === void 0 ? void 0 : _a.row;
    const iataCode = useIataCode();
    return iataCode === 'KV' && row ? row.exitRow : true;
};
export const useSeatAdditionalInfo = (seat) => {
    var _a, _b;
    const { t } = useTranslation('SeatMap');
    let additional = [];
    if (!(seat === null || seat === void 0 ? void 0 : seat.isRecline)) {
        additional = [...additional, t('Chair back does not recline')];
    }
    if (seat === null || seat === void 0 ? void 0 : seat.isNearWindow) {
        additional = [...additional, t('Window seat')];
    }
    else if ((seat === null || seat === void 0 ? void 0 : seat.isNearWindow) === false || (seat === null || seat === void 0 ? void 0 : seat.isNearWindowWithoutWindow)) {
        additional = [...additional, t('Seat with no window')];
    }
    if (seat === null || seat === void 0 ? void 0 : seat.isComfort) {
        additional = [...additional, t('Comfort seat')];
    }
    if (seat === null || seat === void 0 ? void 0 : seat.isNearPartitionWall) {
        additional = [...additional, t('Seat next to the partition')];
    }
    const restrictions = (_a = seat === null || seat === void 0 ? void 0 : seat.restrictions) !== null && _a !== void 0 ? _a : [];
    const permissions = (_b = seat === null || seat === void 0 ? void 0 : seat.permissions) !== null && _b !== void 0 ? _b : [];
    const withInfo = additional.length > 0 || (restrictions.length > 0 && useRestrictionsConditionForKV()) || permissions.length > 0;
    return {
        restrictions,
        permissions,
        additional,
        withInfo
    };
};
