import { __awaiter } from "tslib";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { LoginType, useAuthenticateMutation } from '@websky/graphql';
import LoginTypeSelector from './LoginTypeSelector/LoginTypeSelector';
import { useTheme } from '../../../../theme';
import Button, { ActionButton } from '../../../../Button';
import { Steps } from '../../../types';
import { getLoginTypeValue } from '../../../utils';
import { AUTH_DUPLICATES, removeFromSessionStorage } from '../../../../cache';
const LoginTypeSelectorForm = ({ loginTypes, login, code, stayLoggedIn, onSuccess, setLoginData, setStep }) => {
    const { LoginTypeSelectorForm: css, SubmitButton: cssSubmit } = useTheme('LoginPage');
    const { t } = useTranslation('LoginPage');
    const [loginType, setLoginType] = useState(LoginType.Email);
    const [authenticate, { loading: authenticateLoading }] = useAuthenticateMutation();
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (loginType === LoginType.FFP) {
            removeFromSessionStorage(AUTH_DUPLICATES);
            try {
                yield authenticate({
                    variables: {
                        params: {
                            login: login,
                            loginType: loginType,
                            secureCode: code,
                            oneSession: !stayLoggedIn
                        }
                    }
                });
                onSuccess();
            }
            catch (e) {
                console.log(e);
            }
        }
        else if (loginType === LoginType.Email) {
            setLoginData({
                login: getLoginTypeValue(loginTypes, LoginType.Email),
                loginType: LoginType.Email
            });
            setStep(Steps.LogIn);
        }
    });
    const onBack = () => {
        removeFromSessionStorage(AUTH_DUPLICATES);
        setLoginData({ login: '', loginType: undefined });
        setStep(Steps.LogIn);
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.title }, t('Choose an account to enter'))),
        React.createElement("div", { className: css.content },
            React.createElement("span", { className: css.text, dangerouslySetInnerHTML: {
                    __html: t('We found an account created with the email', {
                        email: getLoginTypeValue(loginTypes, LoginType.Email)
                    })
                } }),
            React.createElement("div", { className: css.types }, loginTypes.map(item => (React.createElement(LoginTypeSelector, { key: item.type, type: item.type, value: item.value, selected: item.type === loginType, onClick: () => setLoginType(item.type) })))),
            React.createElement("div", { className: css.footer },
                React.createElement(Button, { variant: "text", onClick: onBack }, t('Back')),
                React.createElement(ActionButton, { onClick: onSubmit, className: cn(cssSubmit.root, css.buttonSubmit, {
                        [cssSubmit.loading]: authenticateLoading
                    }) },
                    React.createElement("span", { className: cssSubmit.content }, t('Continue')))))));
};
export default LoginTypeSelectorForm;
