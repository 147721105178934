import * as React from 'react';
import { memo, useEffect } from 'react';
import { useLogout } from '../../../Modules/Account/hooks';
import Loader from '../../../Loader';
const Logout = memo(() => {
    const { onLogout } = useLogout('/account');
    useEffect(() => {
        onLogout();
    }, []);
    return React.createElement(Loader, null);
});
export default Logout;
