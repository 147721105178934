import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
const BaggageCount = ({ className, count }) => {
    const { BaggageCount: css } = useTheme('BaggageList');
    if (count === 0) {
        return null;
    }
    return React.createElement("div", { className: cn(css.root, className) },
        "x",
        count);
};
export default BaggageCount;
