import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { format, parse } from 'date-fns';
import { ThemeProvider, useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { scheduleFlightAdapter } from '../../adapters';
import FlightSelect from '../../../FlightSelect/components/FlightSelect';
import WeekDays from '../WeekDays/WeekDays';
import Amenities from '../../../Amenities';
import { MobileFlight } from '../../../Flight';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { API_DATE_FORMAT } from '../../../utils';
const ScheduleFlight = ({ startDate, flight, onClickDate }) => {
    const { ScheduleFlight: theme } = useTheme('CoreSchedule');
    const { t } = useTranslation('Schedule');
    const flightStyles = useMemo(() => ({
        FlightSelect: {
            SegmentInfo: {
                segment: theme.segmentInfo__segment
            }
        },
        DesktopFlightRoute: {
            SegmentStyles: {
                planeContainer: theme.segment__planeContainer,
                route__info: theme.segment__routeInfo,
                totalDuration: theme.totalDuration,
                totalDuration__decorator: theme.totalDuration__decorator
            },
            RouteArrowStyles: {
                container: theme.routeArrow__container,
                flightNumber: theme.routeArrow__flightNumber,
                route: theme.routeArrow__route,
                circle: theme.routeArrow__circle,
                timeInRoute: theme.routeArrow__timeInRoute,
                stop: theme.routeArrow__stop
            },
            PointStyles: {
                date__container: theme.point__dateContainer,
                date: theme.point__date,
                time: theme.point__time
            },
            StopsInfoStyles: {
                stop: theme.stop,
                stop__label: theme.stop__label
            }
        },
        MobileSegmentInfo: {
            MobileSegmentFlat: {
                aircraft: theme.mobileSegment__aircraft
            }
        },
        MobileFlight: {
            FlightStyles: {
                flight: theme.mobileFlight__flight,
                readonly: theme.mobileFlight__readonly,
                segments: theme.mobileFlight__segments,
                expand: theme.mobileFlight__expand,
                expanded: theme.mobileFlight__expanded
            }
        }
    }), []);
    const renderFlightInfo = useCallback(({ segment }) => (React.createElement("div", { className: theme.segmentInfo },
        React.createElement("span", { className: theme.segmentInfo__flightNumber },
            segment.marketingAirline.iata,
            "-",
            segment.flightNumber),
        React.createElement("span", { className: theme.segmentInfo__operating },
            t('Is operated by'),
            " ",
            segment.operatingAirline.name))), []);
    const renderWeekdays = useCallback(() => {
        const availableDates = flight.segments[0].segment.datesData.map(date => parse(date.date, API_DATE_FORMAT, new Date()));
        return React.createElement(WeekDays, { startDate: startDate, availableDates: availableDates, onClickDate: onClickDate });
    }, [startDate, flight, onClickDate]);
    const getSegmentById = useCallback((id) => { var _a; return (_a = flight.segments.find(segment => segment.segment.id === id)) === null || _a === void 0 ? void 0 : _a.segment; }, [flight]);
    const renderPlaneInfo = useCallback((segmentInfo) => {
        var _a, _b;
        const segment = getSegmentById(segmentInfo.id);
        const aircrafts = ((_a = segment === null || segment === void 0 ? void 0 : segment.aircrafts) === null || _a === void 0 ? void 0 : _a.map(aircraft => aircraft.name)) || [];
        return (React.createElement("div", { className: theme.planeInfo },
            aircrafts.length > 0 && React.createElement("div", { className: theme.aircrafts }, aircrafts.join(', ')),
            ((_b = segment === null || segment === void 0 ? void 0 : segment.flightInfo) === null || _b === void 0 ? void 0 : _b.amenities.length) > 0 && (React.createElement(Amenities, { className: theme.amenities, amenities: segment.flightInfo.amenities }))));
    }, [flight]);
    const renderCompanyName = useCallback(({ companyName, segment: segmentInfo }) => {
        var _a, _b;
        const aircrafts = ((_b = (_a = getSegmentById(segmentInfo.segment.id)) === null || _a === void 0 ? void 0 : _a.aircrafts) === null || _b === void 0 ? void 0 : _b.map(aircraft => aircraft.name)) || [];
        return (React.createElement("div", { className: theme.companyName },
            companyName,
            " ",
            aircrafts.length > 0 ? `(${aircrafts.join(', ')})` : ''));
    }, [flight]);
    return (React.createElement(ThemeProvider, { value: flightStyles },
        React.createElement(MediaQuery, { minWidth: "mobile" },
            React.createElement(FlightSelect, { className: theme.flight, segmentsClassName: theme.flightSegments, flight: scheduleFlightAdapter(flight), selectedFares: [], renderFlightInfo: renderFlightInfo, renderPlaneInfo: renderPlaneInfo, renderSlider: renderWeekdays })),
        React.createElement(MediaQuery, { maxWidth: "mobile" },
            React.createElement(MobileFlight, { flight: scheduleFlightAdapter(flight, format(startDate, API_DATE_FORMAT)), renderSelectButton: renderWeekdays, renderCompanyName: renderCompanyName }))));
};
export default ScheduleFlight;
