import { __awaiter } from "tslib";
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TravellerFieldEnum, useCheckinSaveOrderServicesMutation, useGetCheckinOrderQuery } from '@websky/graphql';
import { getCheckinOrderId, getCheckinHasStarted, hasMealServices, hasBaggageServices, getCheckinNotStarted, hasInsurances, hasTransfers, getCheckinIsBlockedForPayment, hasUpgradeServices, getIsCheckinCompletedOrPreseating } from './store/order/selectors';
import { fillCheckinOrder } from './store/order/actions';
import { CheckinStep } from './types';
import { getPassengerFirstName, getPassengerLastName } from '../../Checkout/store/passengers/selectors';
import { capitalizeFirstLetter, getUserValue } from '../../utils';
import { useIataCode } from '../../hooks';
export const getTravellerRetryLink = (traveller) => {
    var _a, _b;
    const lastName = getUserValue(traveller, TravellerFieldEnum.LastName);
    const ticket = (_b = (_a = traveller.tickets) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.number;
    return `/search/${ticket}/${lastName}`;
};
export const getTravellerName = (traveller) => {
    const parts = [];
    traveller.values.forEach(({ type, value }) => {
        if ((type === TravellerFieldEnum.FirstName ||
            type === TravellerFieldEnum.LastName ||
            type === TravellerFieldEnum.MiddleName) &&
            !!value) {
            const travellerFirstName = getPassengerFirstName(traveller);
            const travellerLastName = getPassengerLastName(traveller);
            if (travellerFirstName && travellerLastName) {
                parts[traveller.id] = [];
                parts[traveller.id].push(capitalizeFirstLetter(travellerLastName.value.toLowerCase()), capitalizeFirstLetter(travellerFirstName.value.toLowerCase()));
            }
        }
    });
    return parts[traveller.id].join(' ');
};
const defaultSteps = [
    CheckinStep.Passengers,
    CheckinStep.Upgrade,
    CheckinStep.Baggage,
    CheckinStep.Meal,
    CheckinStep.Seats,
    CheckinStep.Extras,
    CheckinStep.Payment
];
const y7Steps = [
    CheckinStep.Passengers,
    CheckinStep.Extras,
    CheckinStep.Baggage,
    CheckinStep.Meal,
    CheckinStep.Seats,
    CheckinStep.Payment
];
export const useSteps = () => {
    const orderId = useSelector(getCheckinOrderId);
    const checkinHasStarted = useSelector(getCheckinHasStarted);
    const checkinIsBlockedForPayment = useSelector(getCheckinIsBlockedForPayment);
    const hasMeals = useSelector(hasMealServices);
    const hasBaggage = useSelector(hasBaggageServices);
    const hasInsurance = useSelector(hasInsurances);
    const hasUpgrade = useSelector(hasUpgradeServices);
    const hasTransfer = useSelector(hasTransfers);
    const isCheckinCompletedOrPreseating = useSelector(getIsCheckinCompletedOrPreseating);
    const history = useHistory();
    const iataCode = useIataCode();
    const steps = iataCode === 'Y7' ? y7Steps : defaultSteps;
    const availableSteps = steps.filter(step => {
        switch (step) {
            case CheckinStep.Upgrade:
                return hasUpgrade;
            case CheckinStep.Baggage:
                return hasBaggage;
            case CheckinStep.Meal:
                return hasMeals;
            case CheckinStep.Extras:
                return hasTransfer || hasInsurance;
            default:
                return true;
        }
    });
    const result = {
        setStep: (step, searchParams) => {
            if (searchParams) {
                history.push({ pathname: `/${orderId}/${step}`, search: searchParams });
            }
            else {
                history.push(`/${orderId}/${step}`);
            }
        },
        getNextStepEnum: (currentStep) => {
            if (currentStep === CheckinStep.Passengers && isCheckinCompletedOrPreseating) {
                return CheckinStep.Passengers;
            }
            else if (checkinIsBlockedForPayment) {
                return CheckinStep.Payment;
            }
            else {
                const stepsList = checkinHasStarted ? availableSteps : steps;
                const currentStepIndex = Math.max(stepsList.indexOf(currentStep), 0);
                if (currentStepIndex === stepsList.length - 1) {
                    return currentStep;
                }
                else {
                    return stepsList[currentStepIndex + 1];
                }
            }
        },
        getNextStep: (currentStep) => {
            return `/${orderId}/${result.getNextStepEnum(currentStep)}`;
        },
        goToNextStep: (currentStep, replace = false) => {
            const newURL = result.getNextStep(currentStep);
            if (replace) {
                history.replace(newURL);
            }
            else {
                history.push(newURL);
            }
        },
        getPreviousStepEnum: (currentStep) => {
            if (checkinHasStarted) {
                const currentStepIndex = availableSteps.indexOf(currentStep);
                if (currentStepIndex === 0) {
                    return currentStep;
                }
                else {
                    return availableSteps[availableSteps.indexOf(currentStep) - 1];
                }
            }
            return currentStep;
        },
        previousStep: (currentStep, replace = false) => {
            const newURL = `/${orderId}/${result.getPreviousStepEnum(currentStep)}`;
            if (replace) {
                history.replace(newURL);
            }
            else {
                history.push(newURL);
            }
        },
        availableSteps
    };
    return result;
};
export const useServiceRequest = () => {
    const dispatch = useDispatch();
    const orderId = useSelector(getCheckinOrderId);
    const [save] = useCheckinSaveOrderServicesMutation();
    const request = (services) => __awaiter(void 0, void 0, void 0, function* () {
        const { data, errors } = yield save({
            variables: {
                params: {
                    id: orderId,
                    services
                }
            }
        });
        if (errors) {
            throw errors;
        }
        else if (data && data.CheckinSaveOrderServices) {
            dispatch(fillCheckinOrder(data.CheckinSaveOrderServices));
            return data.CheckinSaveOrderServices;
        }
    });
    /**
     * This is a hack to support old RunServiceRequest thing.
     * Do not use this intentionally.
     *
     * @deprecated
     */
    const runServiceRequest = (passengers) => __awaiter(void 0, void 0, void 0, function* () {
        return yield request(passengers.map((passenger) => ({
            travellerId: passenger.passengerId,
            seats: passenger.seats
                ? passenger.seats.map((seat) => ({
                    segmentId: seat.segment.id,
                    letter: seat.letter,
                    row: seat.row
                }))
                : [],
            gdsServices: {
                setServices: passenger.setServices
            }
        })));
    });
    return {
        runServiceRequest
    };
};
export const getRelatedSegments = (groups, segmentId = null) => {
    const result = new Set();
    if (segmentId === null) {
        groups.forEach(group => {
            result.add(group[0]);
        });
    }
    else {
        groups.some(group => {
            if (group.includes(segmentId)) {
                group.forEach(segmentId => result.add(segmentId));
                return true;
            }
            return false;
        });
    }
    return result;
};
export const createServicesForRequest = (selectedServices, baggageServices) => {
    const passengers = new Map();
    for (const passengerId in selectedServices) {
        if (selectedServices.hasOwnProperty(passengerId)) {
            const passengerServices = selectedServices[passengerId];
            let ignoreServiceId = null;
            for (const serviceId in passengerServices) {
                if (passengerServices.hasOwnProperty(serviceId)) {
                    const servicesBySegments = passengerServices[serviceId];
                    const relatedService = baggageServices === null || baggageServices === void 0 ? void 0 : baggageServices.find(baggageService => baggageService.id === serviceId);
                    for (const segmentId in servicesBySegments) {
                        if (servicesBySegments.hasOwnProperty(segmentId)) {
                            const count = servicesBySegments[segmentId];
                            if (ignoreServiceId === serviceId) {
                                ignoreServiceId = null;
                                continue;
                            }
                            if (!passengers.has(passengerId)) {
                                passengers.set(passengerId, {
                                    travellerId: passengerId,
                                    gdsServices: {
                                        setServices: []
                                    }
                                });
                            }
                            const passenger = passengers.get(passengerId);
                            // Sometimes service should be added to a multiple segments at the same time.
                            // This happens when service is tightly bound to a whole trip itself (to a "leg" in terms of Nemo),
                            // rather than to a particular segment. in this case, just ignore next segment in selectedServices
                            if (relatedService === null || relatedService === void 0 ? void 0 : relatedService.allowedSegments.some(allowedSegment => allowedSegment.includes(segmentId))) {
                                ignoreServiceId = serviceId;
                                passenger.gdsServices.setServices.push({
                                    serviceId,
                                    count,
                                    segmentIds: [
                                        ...relatedService === null || relatedService === void 0 ? void 0 : relatedService.allowedSegments.find(allowedSegment => allowedSegment.includes(segmentId))
                                    ]
                                });
                            }
                            else {
                                passenger.gdsServices.setServices.push({
                                    serviceId,
                                    count,
                                    segmentIds: [segmentId]
                                });
                            }
                        }
                    }
                }
            }
        }
    }
    return [...passengers.values()];
};
export const useCheckinAvailabilityInfo = (pollInterval = 60000) => {
    var _a, _b;
    const [showExpireModal, setShowExpireModal] = useState(false);
    const isCheckinNew = useSelector(getCheckinNotStarted);
    const isCheckinStarted = useSelector(getCheckinHasStarted);
    const checkinOrderId = useSelector(getCheckinOrderId);
    const { startPolling, stopPolling, loading, data } = useGetCheckinOrderQuery({
        variables: {
            id: checkinOrderId
        },
        skip: !checkinOrderId
    });
    useEffect(() => {
        if (checkinOrderId) {
            startPolling(pollInterval);
        }
        return stopPolling;
    }, [checkinOrderId]);
    const isAvailable = (_b = (_a = data === null || data === void 0 ? void 0 : data.CheckinOrder) === null || _a === void 0 ? void 0 : _a.segments) === null || _b === void 0 ? void 0 : _b.some(segment => { var _a; return (_a = segment.checkinInfo) === null || _a === void 0 ? void 0 : _a.checkinAvailable; });
    const open = useCallback(() => setShowExpireModal(true), []);
    const close = useCallback(() => setShowExpireModal(false), []);
    useEffect(() => {
        if ((isCheckinStarted || isCheckinNew) && checkinOrderId && !loading && !isAvailable) {
            setShowExpireModal(true);
            stopPolling();
        }
    }, [isAvailable]);
    return { loading, isAvailable, showExpireModal, open, close, stopPolling };
};
