import * as Passenger from './Components/Passenger/Passenger.css';
import * as StepTitle from './Components/StepTitle/StepTitle.css';
import * as Reason from './Components/Reason/Reason.css';
import * as ReasonForm from './Components/ReasonForm/ReasonForm.css';
import * as RefundPaper from './Components/RefundPaper/RefundPaper.css';
import * as AddFiles from './Components/AddFiles/AddFiles.css';
import * as AddFile from './Components/AddFile/AddFile.css';
import * as StepsButtons from './Components/StepsButtons/StepsButtons.css';
import * as RequestForm from './Components/RequestForm/RequestForm.css';
import * as Ticket from './Components/Ticket/Ticket.css';
import * as Status from './Components/Status/Status.css';
import * as Price from './Components/Price/Price.css';
import * as Breadcrumbs from './Components/Breadcrumbs/Breadcrumbs.css';
import * as Refund from './Components/Refund/Refund.css';
import * as Passengers from './Components/Passengers/Passengers.css';
import * as Wrapper from './Components/Wrapper/Wrapper.css';
import * as Note from './Components/Note/Note.css';
import * as MobileStep from './Components/MobileStep/MobileStep.css';
import * as RefundInfoModal from './Components/Modals/RefundInfoModal/RefundInfoModal.css';
import * as OrderRefundStatus from './Components/OrderRefundStatus/OrderRefundStatus.css';
export default {
    Passenger,
    StepTitle,
    Reason,
    ReasonForm,
    RefundPaper,
    AddFiles,
    AddFile,
    RequestForm,
    StepsButtons,
    Breadcrumbs,
    Ticket,
    Status,
    Price,
    Refund,
    Passengers,
    Wrapper,
    Note,
    MobileStep,
    RefundInfoModal,
    OrderRefundStatus
};
