import { __awaiter } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../utils';
import { Status } from '../types';
import { useState } from 'react';
import { useTheme } from '../../theme';
initI18n('Timer');
const sentIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z", fill: "#55D590" }),
    React.createElement("mask", { id: "mask0", mask: "alpha", maskUnits: "userSpaceOnUse", x: "2", y: "2", width: "20", height: "20" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
const waitIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52\n\t\t\t\t2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42\n\t\t\t\t20 12 20ZM11 7H12.5V12.25L17 14.92L16.25 16.15L11 13V7Z", fill: "#9A9A9A" })));
const Timer = ({ resendCode, classes }) => {
    const { t } = useTranslation('Timer');
    const { Timer: css } = useTheme('Timer');
    const [status, setStatus] = useState(Status.Ready);
    const startTimer = () => {
        let seconds = 60;
        setStatus(Status.Ticking);
        const timer = setInterval(() => {
            seconds = seconds - 1;
            if (seconds <= 0) {
                clearInterval(timer);
                setStatus(Status.Ready);
                return;
            }
            if (document.getElementById('seconds')) {
                document.getElementById('seconds').innerText = seconds.toString();
            }
        }, 1000);
    };
    const sendCode = () => __awaiter(void 0, void 0, void 0, function* () {
        setStatus(Status.Sending);
        yield resendCode();
        setStatus(Status.Sent);
        setTimeout(() => {
            startTimer();
        }, 2500);
    });
    return (React.createElement("div", { className: cn(css.timer, classes === null || classes === void 0 ? void 0 : classes.timer) },
        status === Status.Ready && (React.createElement("span", { className: cn(css.sliding, classes === null || classes === void 0 ? void 0 : classes.sliding) },
            React.createElement("span", null, t(`Didn't get the code?`)),
            React.createElement("span", { className: cn(css.link, classes === null || classes === void 0 ? void 0 : classes.link), onClick: sendCode }, t('Resend it')))),
        status === Status.Ticking && (React.createElement("span", { className: cn(css.wait, css.sliding, classes === null || classes === void 0 ? void 0 : classes.sliding, classes === null || classes === void 0 ? void 0 : classes.wait) },
            waitIcon,
            t('You can get new code in'),
            " ",
            React.createElement("span", { id: "seconds" }, "60"))),
        status === Status.Sending && (React.createElement("span", { className: cn(css.sliding, classes === null || classes === void 0 ? void 0 : classes.sliding) },
            t('Sending message'),
            "...")),
        status === Status.Sent && (React.createElement("span", { className: cn(css.sliding, classes === null || classes === void 0 ? void 0 : classes.sliding) },
            React.createElement("span", { className: cn(css.sent, classes === null || classes === void 0 ? void 0 : classes.sent) },
                sentIcon,
                " ",
                t('Code sent'))))));
};
export default Timer;
