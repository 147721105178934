import * as React from 'react';
import { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import cn from 'classnames';
// import Passenger from '../../../../../Passenger/index';
import Passenger from '../../../../../PassengerForm/components/PassengerForm';
import { AdditionalTravellerFieldEnum, InputType } from '../../../../../Passenger/types';
import { useTheme } from '../../../../../theme';
import memes from '../../../../../memes';
import { getPassengerLabels } from '../../../../store/passengers/selectors';
import { getIsNew, getIsReadonly, getServices, getTravellers, getCustomer } from '../../../../store/order/selectors';
import { getPassengerStateCompatible } from '../../../../store/selectedServices/selectors';
import { getCurrency } from '../../../../../cache';
import { OverrideComponent } from '../../../../../renderProps';
import PassengerSubsidy from './PassengerSubsidy/PassengerSubsidy';
import { getAccompanyingFields } from './utils';
import { TravellerFieldEnum } from '@websky/graphql';
import LoyaltyConnect, { ConnectType } from '../../../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import { getUserInfo } from '../../../../utils';
import DisabilitiesContextProvider from '../../../../../PassengerForm/components/components/Disabilities/context';
import { useConfigRedirects } from '../../../../../hooks';
import { useFormState } from 'react-final-form';
export const getInputType = memes((type) => {
    switch (type) {
        case TravellerFieldEnum.Gender:
            return InputType.Switch;
        case TravellerFieldEnum.Phone:
            return InputType.Phone;
        case TravellerFieldEnum.BirthDate:
        case TravellerFieldEnum.DocExpiryDate:
            return InputType.Date;
        case TravellerFieldEnum.LoyaltyAirline:
        case TravellerFieldEnum.DocType:
            return InputType.Select;
        case TravellerFieldEnum.FirstName:
        case TravellerFieldEnum.LastName:
            return InputType.Autocomplete;
        case TravellerFieldEnum.Nationality:
            return InputType.Nationality;
        default:
            return InputType.Text;
    }
});
const Passengers = ({ className, passengersState, isReadonly, isNew, isAuthorized, fields, services, onFill, labels, isInputsReadonly, inModal, isChangePassengers, orderSubsidies, backToResults, autofocus, showLoginForm, userInfo, refetchUser, isLoginFormOpen, isChangeFiles, selectedProfileIdsByPassenger, onSwap, onToggleChangeFiles }) => {
    const theme = useTheme('Checkout').PassengersStyles;
    const [openedPassengerFormId, setOpenedPassengerFormId] = React.useState(0);
    const [mobileOpenedPassengerId, setMobileOpenedPassengerId] = React.useState(null);
    const [loginBeforeCreateLoyaltyAccount, setLoginBeforeCreateLoyaltyAccount] = React.useState(false);
    const [loyaltyConnectOpen, setLoyaltyConnectOpen] = React.useState(false);
    const { initialValues } = useFormState();
    const { onGoToAccountSettings } = useConfigRedirects();
    const onCorrectFormFilledHandler = (passengerId) => {
        if (passengerId + 1 > openedPassengerFormId) {
            setOpenedPassengerFormId(passengerId + 1);
        }
    };
    const orderTravellers = useSelector(getTravellers);
    const orderCustomer = useSelector(getCustomer);
    const accompanyingFields = useMemo(() => getAccompanyingFields(orderCustomer), [orderCustomer]);
    const onSignUpLoyaltyProgram = () => {
        if (!isAuthorized) {
            setLoginBeforeCreateLoyaltyAccount(true);
            showLoginForm();
        }
        else {
            setLoyaltyConnectOpen(true);
        }
    };
    React.useEffect(() => {
        if (isAuthorized && loginBeforeCreateLoyaltyAccount && userInfo.isFfpAuthorizationNeeded) {
            setLoyaltyConnectOpen(true);
        }
    }, [loginBeforeCreateLoyaltyAccount, isAuthorized]);
    React.useEffect(() => {
        if (!isLoginFormOpen) {
            setLoginBeforeCreateLoyaltyAccount(false);
        }
    }, [isLoginFormOpen]);
    React.useEffect(() => {
        if (isAuthorized && loginBeforeCreateLoyaltyAccount && userInfo.isFfpAuthorizationNeeded) {
            setLoyaltyConnectOpen(true);
        }
    }, [loginBeforeCreateLoyaltyAccount, isAuthorized]);
    React.useEffect(() => {
        if (!isLoginFormOpen) {
            setLoginBeforeCreateLoyaltyAccount(false);
        }
    }, [isLoginFormOpen]);
    const onLoyaltyCardSaved = () => {
        setLoyaltyConnectOpen(false);
        refetchUser();
    };
    return (React.createElement("div", { className: cn(theme.passengers, { [theme.passengers_readonly]: isReadonly }) }, fields.map((passenger, passengerId) => {
        var _a, _b, _c;
        let firstName, lastName;
        const profileId = selectedProfileIdsByPassenger.get(passengerId);
        let passengerFields = passenger.values.map(({ name, type, validationRules, value, isEditable }) => {
            var _a;
            if (type === TravellerFieldEnum.FirstName) {
                firstName = value;
            }
            if (type === TravellerFieldEnum.LastName) {
                lastName = value;
            }
            const initialValue = (_a = initialValues.passengers[passengerId]) === null || _a === void 0 ? void 0 : _a[name];
            return {
                name,
                readonly: !isEditable,
                type,
                input: getInputType(type),
                placeholder: name,
                validations: validationRules,
                defaultValue: typeof initialValue === 'string' ? initialValue : value
            };
        });
        if (isChangePassengers) {
            passengerFields = [
                ...passengerFields,
                {
                    name: 'files',
                    readonly: false,
                    type: AdditionalTravellerFieldEnum.ChangeTravellerFile,
                    input: InputType.Text,
                    placeholder: '',
                    validations: [],
                    defaultValue: null
                }
            ];
        }
        const passengerLabel = firstName && lastName ? `${lastName} ${firstName}` : labels[passengerId];
        let currentCurrency = (_b = (_a = services.find(service => { var _a; return !!((_a = service === null || service === void 0 ? void 0 : service.price) === null || _a === void 0 ? void 0 : _a.currency); })) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.currency;
        if (!currentCurrency) {
            currentCurrency = getCurrency();
        }
        const servicesPrice = {
            amount: 0,
            currency: currentCurrency
        };
        passengersState[passengerId].services.forEach(selectedService => {
            const service = services.find(service => service.id === selectedService.serviceId);
            if (service) {
                servicesPrice.amount +=
                    selectedService.count * selectedService.segmentIds.length * service.price.amount;
                servicesPrice.currency = service.price.currency;
            }
        });
        if (passengersState[passengerId].seats) {
            passengersState[passengerId].seats.forEach(seat => {
                if (seat.price) {
                    servicesPrice.amount += seat.price.amount;
                    servicesPrice.currency = seat.price.currency;
                }
            });
        }
        const traveller = orderTravellers.find(traveller => traveller.id === passenger.id);
        const passAccompamyingFields = passengerId + 1 === orderTravellers.length ? accompanyingFields : [];
        return (React.createElement(DisabilitiesContextProvider, { value: {
                availableDisabilities: passenger.availableDisabilities,
                passengerId
            }, key: passenger.id },
            React.createElement(PassengerSubsidy, { passengerId: passenger.id, subsidyInfo: (_c = traveller.subsidyInfo) === null || _c === void 0 ? void 0 : _c.subsidies, fields: traveller.values, orderSubsidyState: orderSubsidies, backToResults: backToResults }, props => (React.createElement(OverrideComponent, { key: passengerId, componentProps: {
                    className,
                    id: passengerId,
                    onFill: (values, fieldValue) => onFill(values, passengerId, fieldValue),
                    fields: passengerFields,
                    passengerType: passenger.type,
                    supplierType: passenger.supplierTravellerType,
                    selectedProfileId: profileId,
                    isReadonly,
                    isAuthorized,
                    isChangePassengers,
                    servicesTotalPrice: servicesPrice,
                    withSubsidies: props.withSubsidy,
                    noSubsidyAvailable: props.noSubsidyAvailable,
                    subsidyRejectMessage: props.subsidyRejectMessage,
                    label: passengerLabel,
                    isInputsReadonly,
                    onCorrectFilled: onCorrectFormFilledHandler,
                    open: !isNew || inModal || passengerId <= openedPassengerFormId,
                    mobileOpenedPassengerId: mobileOpenedPassengerId,
                    onSetMobileOpenedPassengerId: setMobileOpenedPassengerId,
                    needOthsSsr: passenger.needOthsSsr,
                    autofocus: autofocus && passengerId === 0,
                    isChangeFiles,
                    accompanyingFields: passAccompamyingFields,
                    onSignUpLoyaltyProgram: onSignUpLoyaltyProgram,
                    onSwap: onSwap ? () => onSwap(passengerId) : null,
                    onToggleChangeFiles
                }, component: {
                    CheckoutFormPassenger: Passenger
                } }))),
            React.createElement(LoyaltyConnect, { isOpen: loyaltyConnectOpen, type: ConnectType.Login, userInfo: getUserInfo(userInfo), showRegisterAction: true, onLoyaltyCardSaved: onLoyaltyCardSaved, onClose: () => setLoyaltyConnectOpen(false), onGoToSettings: onGoToAccountSettings })));
    })));
};
const mapStateToProps = (state, ownProps) => {
    return Object.assign(Object.assign({}, ownProps), { labels: getPassengerLabels(state), passengersState: getPassengerStateCompatible(state), services: getServices(state), isNew: getIsNew(state), isReadonly: getIsReadonly(state) });
};
export default connect(mapStateToProps)(Passengers);
