import * as Additional from './components/SearchForm/Bottom/Additional/Additional.css';
import * as Bottom from './components/SearchForm/Bottom/Bottom.css';
import * as MultiCity from './components/SearchForm/Bottom/MultiCity/MultiCity.css';
import * as Passengers from './components/SearchForm/Passengers/Passengers.css';
import * as SearchForm from './components/SearchForm/SearchForm.css';
import * as Datepicker from './components/SearchForm/Segments/Segment/Datepicker/Datepicker.css';
import * as Segment from './components/SearchForm/Segments/Segment/Segment.css';
import * as Actions from './components/SearchForm/Segments/Actions/Actions.css';
import * as Segments from './components/SearchForm/Segments/Segments.css';
import * as StartSearch from './components/SearchForm/StartSearch/StartSearch.css';
import * as Location from './components/SearchForm/Segments/Segment/Location/Location.css';
import * as Value from './components/SearchForm/Segments/Segment/Location/Value/Value.css';
import * as Controls from './components/SearchForm/Controls/Controls.css';
import * as PromoCode from './components/SearchForm/Bottom/PromoCode/PromoCode.css';
import * as PassengerInput from './components/SearchForm/AdvancedPassengers/PassengerInput/PassengerInput.css';
import * as AdvancedPassengers from './components/SearchForm/AdvancedPassengers/AdvancedPassengers.css';
import * as OtherCategories from './components/SearchForm/AdvancedPassengers/OtherCategories/OtherCategories.css';
import * as LastSearch from './components/SearchForm/LastSearch/LastSearch.css';
import * as PayByMiles from './components/SearchForm/Bottom/PayByMiles/PayByMiles.css';
import * as Currency from './components/SearchForm/Currency/Currency.css';
import * as OfferHotels from './components/SearchForm/Bottom/OfferHotels/OfferHotels.css';
import * as PassengersBlockedModal from './components/SearchForm/Bottom/PayByMiles/PassengersBlockedModal/PassengersBlockedModal.css';
export const theme = {
    SearchForm,
    Bottom,
    Additional,
    MultiCity,
    Passengers,
    StartSearch,
    Segments,
    Segment,
    Datepicker,
    Location,
    Controls,
    Value,
    Actions,
    PromoCode,
    PassengerInput,
    AdvancedPassengers,
    OtherCategories,
    LastSearch,
    PayByMiles,
    Currency,
    OfferHotels,
    PassengersBlockedModal
};
