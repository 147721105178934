import * as React from 'react';
import CheckoutAdditionalService from '../../../../CheckoutAdditionalService';
import { useTranslation } from 'react-i18next';
import CommonContent from '../CommonContent';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
const Seat = props => {
    const { t } = useTranslation('Checkout');
    const getServiceName = (service, productId, passengerId) => {
        const traveller = props.passengers.find(passenger => passenger.id === passengerId);
        const seat = traveller.services.seats.find(seat => {
            return seat.product.id === productId;
        });
        let segment;
        if (Array.isArray(props.flight)) {
            segment = props.flight.find(segment => segment.id === seat.segment.id);
        }
        else {
            segment = props.flight.segments.find(segment => segment.segment.id === seat.segment.id).segment;
        }
        return (React.createElement(React.Fragment, null,
            segment.departure.airport.iata,
            " - ",
            segment.arrival.airport.iata,
            ", ",
            seat.seat.number));
    };
    const theme = useTheme('RefundSummary').CommonContent;
    return (React.createElement(CheckoutAdditionalService, { isSelected: true, className: cn(theme.serviceCard, theme.serviceCard_seats), addClick: () => null, header: t('Seats'), description: '' },
        React.createElement(CommonContent, Object.assign({}, props, { getServiceName: getServiceName }))));
};
export default Seat;
