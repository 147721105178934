import { NASProductCategory } from '@websky/graphql';
import { PassengerType } from '../../../enums';
import { AirportControl, Alcohol, DocumentPassport, InfoTimer, ServicesMeal, ServicesTranfer, Wifi } from '../../../Icons';
export const getEveryPortSelectedServices = (state) => {
    const result = [];
    const travellersByFareId = new Map();
    state.forEach(item => {
        travellersByFareId.set(item.fare.id, [...(travellersByFareId.get(item.fare.id) || []), item.travellerId]);
    });
    travellersByFareId.forEach((travellerIds, fareId) => {
        const stateItem = state.find(item => item.fare.id === fareId);
        result.push({
            product: stateItem.product,
            fare: stateItem.fare,
            travellerIds,
            additionalServices: [],
            orders: []
        });
    });
    return result;
};
export const filterServicesForAdultTravellerExists = (state, travellers) => {
    const travellersBySegment = new Map();
    state.forEach(item => {
        travellersBySegment.set(item.product.segmentId.toString(), [
            ...(travellersBySegment.get(item.product.segmentId.toString()) || []),
            item
        ]);
    });
    const filteredServices = [];
    travellersBySegment.forEach((servicesOnSegment, segmentId) => {
        if (servicesOnSegment.some(service => { var _a; return ((_a = travellers.find(traveller => traveller.id === service.travellerId)) === null || _a === void 0 ? void 0 : _a.type) === PassengerType.Adult; })) {
            filteredServices.push(...servicesOnSegment);
        }
    });
    return filteredServices;
};
export const findMinPrice = (product, travellers) => {
    var _a, _b, _c, _d, _e;
    const firstPrice = (_d = (_c = (_b = (_a = product.fares) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.prices) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.price;
    const minPrice = {
        amount: (_e = firstPrice === null || firstPrice === void 0 ? void 0 : firstPrice.amount) !== null && _e !== void 0 ? _e : 0,
        currency: firstPrice === null || firstPrice === void 0 ? void 0 : firstPrice.currency
    };
    product.fares.forEach(fare => fare.prices
        .filter(price => { var _a; return ((_a = travellers.find(traveller => traveller.id === price.traveller.id)) === null || _a === void 0 ? void 0 : _a.type) === PassengerType.Adult; })
        .forEach(({ price }) => {
        if (price.amount > 0 && price.amount < minPrice.amount) {
            minPrice.amount = price.amount;
        }
    }));
    return minPrice;
};
const NasServicesIcons = {
    [NASProductCategory.fast_track]: [AirportControl, DocumentPassport, InfoTimer],
    [NASProductCategory.vip]: [AirportControl, Wifi, Alcohol, ServicesTranfer],
    [NASProductCategory.lounge]: [Wifi, ServicesMeal, Alcohol],
    [NASProductCategory.meet_and_greet]: [AirportControl, ServicesTranfer, DocumentPassport]
};
const NasServicesTitle = {
    [NASProductCategory.fast_track]: 'Fast track',
    [NASProductCategory.vip]: 'VIP',
    [NASProductCategory.lounge]: 'Business lounge',
    [NASProductCategory.meet_and_greet]: 'Meet and greet'
};
const NasServicesDescription = {
    [NASProductCategory.fast_track]: 'Pass pre-boarding security check faster',
    [NASProductCategory.vip]: 'Services of the highest comfort level',
    [NASProductCategory.lounge]: 'For your comfortable stay while waiting for a flight',
    [NASProductCategory.meet_and_greet]: 'For your comfortable stay while waiting for a flight'
};
export const getNasServiceCardParams = (category) => {
    return {
        icons: NasServicesIcons[category],
        title: NasServicesTitle[category],
        description: NasServicesDescription[category]
    };
};
