import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import SwitchComponent from '../../../../Switch';
import { useIataCode } from '../../../../hooks';
import { useIsMobile } from '../../../../utils';
const Switch = props => {
    const theme = useTheme('PassengerForm').Switch;
    const iataCode = useIataCode();
    const isMobile = useIsMobile();
    const switchPosition = iataCode === 'B2' && !isMobile ? 'right' : 'left';
    return (React.createElement(SwitchComponent, Object.assign({ mainClassName: cn(theme.toggle, props.className), activeClassName: theme.toggle_active, labelClassName: theme.switch__label, switchPosition: switchPosition }, props)));
};
export default Switch;
