import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import Categories from '../Categories/Categories';
import Warning from '../Warning/Warning';
import { useTheme } from '../../../theme';
import { ALL_DISHES_CATEGORY_ID, SELECTED_DISHES_CATEGORY_ID } from '../../constants';
import { getCategories, removeDuplicatesByName } from '../../utils';
import { MealViewMode } from '../../../Meal/types';
import { useConfig } from '../../../context';
import { WxEvents } from '../../../DataLayerAnalytics/types';
import WebskyAnalyticsApollo from '../../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { isDemo } from '../../../utils';
import { useTranslation } from 'react-i18next';
import InfoMessages from '../../../InfoMessages';
import { InfoMessagePositionsEnum } from '@websky/graphql';
import { OverrideComponent } from '../../../renderProps';
const ServiceWrapper = ({ activeSegmentId, meals, selectedMeals = [], mealRenderer, recommendedMeals }) => {
    const { t } = useTranslation('MealService');
    const { ServiceWrapper: css } = useTheme('MealService');
    const { Engine: { mealWarning, mealDefaultFilterButtonPlacement } } = useConfig();
    const [category, setCategory] = useState(mealDefaultFilterButtonPlacement === 'end' ? 0 : ALL_DISHES_CATEGORY_ID);
    const categories = useMemo(() => getCategories(meals, activeSegmentId), [meals, activeSegmentId]);
    const mealsBySegment = useMemo(() => meals.filter(meal => meal.segmentsId.includes(activeSegmentId)), [
        meals,
        activeSegmentId
    ]);
    const { selectedMealsBySegment, selectedMealsCount, hasSelectedMeals } = useMemo(() => {
        const selectedMealsBySegment = selectedMeals.filter(meal => meal.segmentId === activeSegmentId);
        const selectedMealsCount = selectedMealsBySegment.reduce((total, meal) => total + (meal.count - meal.confirmedCount), 0);
        return {
            selectedMealsBySegment,
            selectedMealsCount,
            hasSelectedMeals: !!selectedMealsBySegment.length
        };
    }, [selectedMeals, activeSegmentId]);
    const mealIsSelected = (meal) => {
        return selectedMealsBySegment.find(selectedMeal => meal.id === selectedMeal.mealId);
    };
    const mealIsConfirmed = (meal) => {
        return selectedMealsBySegment.find(selectedMeal => meal.id === selectedMeal.mealId && selectedMeal.confirmedCount);
    };
    const filteredMeals = useMemo(() => {
        const filtered = mealsBySegment.filter(meal => {
            return (category === ALL_DISHES_CATEGORY_ID ||
                meal.category === categories[category] ||
                (hasSelectedMeals && category === SELECTED_DISHES_CATEGORY_ID && mealIsSelected(meal)));
        });
        return isDemo ? removeDuplicatesByName(filtered) : filtered;
    }, [mealsBySegment, category, hasSelectedMeals]);
    const confirmedMeals = useMemo(() => {
        const confirmedMeals = mealsBySegment.filter(mealIsConfirmed);
        return isDemo ? removeDuplicatesByName(confirmedMeals) : confirmedMeals;
    }, [mealsBySegment, category, selectedMeals]);
    useEffect(() => {
        // if user remove meal on selected dishes tab, return to first category
        if (category === SELECTED_DISHES_CATEGORY_ID && !selectedMealsCount) {
            setCategory(ALL_DISHES_CATEGORY_ID);
        }
    }, [selectedMeals, activeSegmentId, category]);
    useEffect(() => {
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.MealsList, { segmentId: activeSegmentId });
    }, [activeSegmentId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Categories, { categories: categories, onSelect: setCategory, selectedCategory: category, numOfSelectedMeals: selectedMealsCount, defaultGroupPlacement: mealDefaultFilterButtonPlacement }),
        React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.meal, className: css.infoMessage, itemHtmlClassName: css.infoMessage__htmlMessage }),
        mealWarning && (React.createElement(OverrideComponent, { componentProps: {
                warning: mealWarning
            }, component: { MealServiceWarning: Warning } })),
        React.createElement("div", { className: cn(css.meals, {
                [css.meals_singleMeal]: filteredMeals.length === 1
            }) }, filteredMeals.map(meal => mealRenderer(meal, activeSegmentId, filteredMeals.length === 1))),
        (confirmedMeals === null || confirmedMeals === void 0 ? void 0 : confirmedMeals.length) > 0 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn(css.meals_purchased) }, t('Purchased')),
            React.createElement("div", { className: cn(css.meals, {
                    [css.meals_singleMeal]: confirmedMeals.length === 1
                }) }, confirmedMeals.map(meal => mealRenderer(meal, activeSegmentId, confirmedMeals.length === 1, MealViewMode.confirmed)))))));
};
export default ServiceWrapper;
