import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
// TODO: по факту это обвязка для любого места ( см SeatsInfoByPrice )
const FreeSeatInfo = ({ className, seatClassName, customTitle, dataColorNum }) => {
    const { SeatsInfoStyles: theme } = useTheme('SeatMap');
    const { t } = useTranslation('SeatMap');
    return (React.createElement("div", { className: cn(theme.freeSeat__container, className) },
        React.createElement("span", { "data-color-num": dataColorNum, className: cn(theme.freeSeat__seat, seatClassName) }),
        React.createElement("span", null, customTitle !== null && customTitle !== void 0 ? customTitle : t('Free seats'))));
};
export default FreeSeatInfo;
