// States
export const isCheckoutOrderGuard = (order) => {
    return order.hasOwnProperty('locator');
};
export const isExchangeStateGuard = (state) => {
    return 'flightsToExchange' in state;
};
export const isResultsStateGuard = (state) => {
    return 'legs' in state;
};
export const isCheckoutStateGuard = (state) => {
    return 'passengers' in state;
};
// Responses
export const isOrderServicesResponse = (response) => {
    return (response === null || response === void 0 ? void 0 : response.SaveOrderServices) !== undefined;
};
export const isCheckinOrderServicesResponse = (response) => {
    return (response === null || response === void 0 ? void 0 : response.CheckinSaveOrderServices) !== undefined;
};
export const isOrderResponse = (response) => {
    return (response === null || response === void 0 ? void 0 : response.Order) !== undefined;
};
export const isCheckinOrderResponse = (response) => {
    return (response === null || response === void 0 ? void 0 : response.CheckinOrder) !== undefined;
};
export const isCheckinFlightGuard = (flight) => {
    return (flight === null || flight === void 0 ? void 0 : flight.checkinInfo) !== undefined;
};
