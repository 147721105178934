import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
const PlaneSpinner = ({ className }) => {
    const theme = useTheme('PlaneSpinner').PlaneSpinner;
    return (React.createElement("div", { className: cn(theme.wrapper, className) },
        React.createElement("div", { className: theme.loader },
            React.createElement("svg", { width: "120", height: "120", viewBox: "0 0 120 120", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.9996 30.0881C19.9881 21.1257 28.4999 14.1402 38.4584 10.0153C48.417 5.89031 59.3752 4.81102 69.9471 6.91391C80.519 9.0168 90.2301 14.2075 97.852 21.8294L95.7307 23.9507C88.5283 16.7483 79.3518 11.8434 69.3618 9.85627C59.3718 7.86913 49.0169 8.88901 39.6065 12.7869C30.1961 16.6848 22.1529 23.2857 16.494 31.7548C10.8351 40.2239 7.8147 50.181 7.8147 60.3667L4.8147 60.3667C4.8147 49.5877 8.01109 39.0506 13.9996 30.0881ZM13.9996 90.6453C11.0059 86.1649 8.70997 81.291 7.16232 76.1901L10.0331 75.3191C11.4956 80.1392 13.6651 84.7448 16.494 88.9786C22.1529 97.4477 30.1961 104.049 39.6065 107.946L38.4584 110.718C28.4999 106.593 19.9881 99.6077 13.9996 90.6453ZM61.6051 114.819C58.7893 114.937 55.9718 114.837 53.1781 114.52L53.5159 111.539C56.1559 111.838 58.8182 111.933 61.479 111.821C64.116 111.71 66.7513 111.396 69.3618 110.877L69.9471 113.819C67.1846 114.369 64.3957 114.701 61.6051 114.819ZM83.4726 109.22C81.1409 110.373 78.7253 111.36 76.2438 112.171L75.3119 109.319C77.6568 108.553 79.9394 107.62 82.1428 106.531L83.4726 109.22ZM97.852 98.904C95.7773 100.979 93.5478 102.873 91.1882 104.575L89.4337 102.141C91.6634 100.534 93.7701 98.7432 95.7307 96.7827L97.852 98.904Z", fill: "currentColor" }),
                React.createElement("mask", { style: { maskType: 'alpha' }, id: "mask0_7791_250", maskUnits: "userSpaceOnUse", x: "87", y: "17", width: "34", height: "34" },
                    React.createElement("rect", { x: "120.559", y: "38.5962", width: "24", height: "24", transform: "rotate(150 120.559 38.5962)", fill: "currentColor" })),
                React.createElement("g", { mask: "url(#mask0_7791_250)" },
                    React.createElement("path", { d: "M103.497 23.0437L104.747 25.2088L103.199 28.5275L104.999 31.6452L110.327 24.8739L111.827 27.472L107.699 36.3217L109.899 40.1322C110.174 40.6085 110.24 41.1142 110.097 41.6492C109.953 42.1842 109.643 42.5893 109.167 42.8643C108.691 43.1393 108.185 43.2051 107.65 43.0617C107.115 42.9184 106.71 42.6085 106.435 42.1322L104.235 38.3217L94.5069 37.472L93.0069 34.8739L101.535 33.6452L99.7351 30.5275L96.087 30.2088L94.837 28.0437L99.9172 26.8428L103.497 23.0437Z", fill: "currentColor" }))))));
};
export default PlaneSpinner;
