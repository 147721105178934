import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useIataCode } from '../../../../hooks';
const IATA_WING_HEIGHT = {
    F7: 7
};
const DIFF_IN_PX_BETWEEN_PICTURE_TOP_AND_WING_TOP = 55;
const Wings = React.memo(props => {
    var _a;
    const ref = React.useRef(null), wings = React.useRef(null);
    const [hasWings, setHasWings] = React.useState(false);
    const iataCode = useIataCode();
    const theme = useTheme('SeatMap').SeatMapStyles;
    const wingHeight = (_a = IATA_WING_HEIGHT[iataCode]) !== null && _a !== void 0 ? _a : 6;
    React.useEffect(() => {
        if (ref) {
            const wingSeats = ref.current.querySelectorAll('div[with-wings="true"]');
            if (!wingSeats.length) {
                setHasWings(false);
                return;
            }
            setHasWings(true);
            const firstSeat = wingSeats.item(0), count = wingSeats.length;
            let topDiff = DIFF_IN_PX_BETWEEN_PICTURE_TOP_AND_WING_TOP;
            if (count > wingHeight) {
                const rate = count / wingHeight;
                topDiff *= rate;
                wings.current.style.transform = `scaleY(${rate})`;
            }
            wings.current.style.top = `${firstSeat['offsetTop'] - topDiff}px`;
        }
    }, [ref]);
    return (React.createElement("div", { ref: ref },
        props.children,
        React.createElement("div", { ref: wings, className: cn(theme.wings, { [theme.wings_center]: !hasWings }) })));
});
export default Wings;
