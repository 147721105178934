import { createSelector } from 'reselect';
const getState = (state) => state;
export const getCommunicationCategory = createSelector(getState, state => state);
export const getUnreadTotal = createSelector(getState, state => {
    let count = 0;
    for (const category in state) {
        count += state[category].unreadMessagesCount;
    }
    return count;
});
