import { isBefore, parse } from 'date-fns';
import { API_DATE_FORMAT } from '../../../../../../../utils';
const selectSegmentDateReducer = (state, { payload: action }) => {
    const newState = {};
    const previousSegment = state[Object.keys(state).length - 1];
    const previousSelectedDate = previousSegment ? parse(previousSegment.date, API_DATE_FORMAT, new Date()) : null;
    const isChangePreviousDateAction = isBefore(parse(action.segment.date, API_DATE_FORMAT, new Date()), previousSelectedDate);
    if (isChangePreviousDateAction && Object.entries(state).length === 1) {
        return Object.assign(Object.assign({}, state), { [0]: action.segment });
    }
    if (action.legId === Object.keys(state).length && !isChangePreviousDateAction) {
        return Object.assign(Object.assign({}, state), { [action.legId]: action.segment });
    }
    Object.entries(state).forEach(([segmentLegId, segment]) => {
        if (Number(segmentLegId) < action.legId) {
            newState[segmentLegId] = segment;
        }
        if (Number(segmentLegId) === action.legId) {
            newState[segmentLegId] = action.segment;
        }
    });
    return newState;
};
const clearSegmentDateReducer = (state, { payload: action }) => {
    const newState = {};
    if (Object.keys(state).length <= action.legId) {
        // do not delete dates for already selected flights
        return state;
    }
    const legsArr = Object.keys(state);
    legsArr.pop();
    legsArr.forEach(legIndex => {
        newState[legIndex] = Object.assign({}, state[legIndex]);
    });
    return newState;
};
const actualizeSegmentsDateReducer = (state, { payload: action }) => {
    const newState = {};
    action.segmentsSearchParams.forEach((searchParam, segmentLegId) => {
        newState[segmentLegId] = searchParam;
    });
    return newState;
};
export const selectedDatesReducer = (state, action) => {
    switch (action.type) {
        case 'select':
            return selectSegmentDateReducer(state, action);
        case 'clear':
            return clearSegmentDateReducer(state, action);
        case 'actialize':
            return actualizeSegmentsDateReducer(state, action);
        default:
            return state;
    }
};
