import * as React from 'react';
import { useFormState } from 'react-final-form';
import { validate } from '../../../../../../Passenger/utils';
import { getInputType } from '../Passengers';
import { findSubsidyForPassenger } from '../../SubsidyRequest/utils';
import SubsidyRejectPopup from '../../../../../../SubsidyRejectPopup/SubsidyRejectPopup';
import { useTranslation } from 'react-i18next';
export var SubsidyState;
(function (SubsidyState) {
    SubsidyState[SubsidyState["None"] = 0] = "None";
    SubsidyState[SubsidyState["Checking"] = 1] = "Checking";
    SubsidyState[SubsidyState["Confirmed"] = 2] = "Confirmed";
    SubsidyState[SubsidyState["NoAvailable"] = 3] = "NoAvailable";
})(SubsidyState || (SubsidyState = {}));
const PassengerSubsidy = ({ passengerId, subsidyInfo, fields, children, orderSubsidyState, backToResults }) => {
    var _a, _b;
    const formState = useFormState(), passengerValues = (_a = formState.values['passengers']) === null || _a === void 0 ? void 0 : _a[parseInt(passengerId)];
    const { t } = useTranslation('PassengerSubsidy');
    const [errorPopupOpen, setErrorPopupOpen] = React.useState(false);
    const [subsidyResult, setSubsidyResult] = React.useState();
    const isValidForSubsidyCheck = React.useMemo(() => {
        if (!(subsidyInfo === null || subsidyInfo === void 0 ? void 0 : subsidyInfo.length)) {
            return false;
        }
        else {
            for (const field of fields) {
                if (!!validate(passengerValues === null || passengerValues === void 0 ? void 0 : passengerValues[field.name], passengerValues, getInputType(field.type), field.validationRules)) {
                    return false;
                }
            }
            return true;
        }
    }, [passengerValues, subsidyInfo, fields]);
    React.useEffect(() => {
        if (isValidForSubsidyCheck && (orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.canCheckSubsidies)) {
            const subsidyId = findSubsidyForPassenger(passengerValues, subsidyInfo);
            if (subsidyId) {
                orderSubsidyState
                    .checkSubsidy({
                    id: passengerId,
                    subsidyId,
                    values: fields
                        .filter(field => passengerValues.hasOwnProperty(field.name))
                        .map(field => ({
                        type: field.type,
                        name: field.name,
                        value: passengerValues[field.name]
                    }))
                })
                    .then(data => {
                    if (!data.isSubsidyApplied) {
                        setErrorPopupOpen(true);
                        setSubsidyResult(data);
                    }
                });
            }
        }
    }, [isValidForSubsidyCheck, orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.canCheckSubsidies]);
    const subsidyTravellerState = React.useMemo(() => {
        return orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.travellers.find(traveller => traveller.traveller.id === passengerId);
    }, [orderSubsidyState, passengerId]);
    return (React.createElement(React.Fragment, null,
        (orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.isSubsidiesAvailable) && (React.createElement(SubsidyRejectPopup, { open: errorPopupOpen, traveller: subsidyResult === null || subsidyResult === void 0 ? void 0 : subsidyResult.traveller, segment: subsidyResult === null || subsidyResult === void 0 ? void 0 : subsidyResult.failedSegment, rejectMessage: (_b = subsidyResult === null || subsidyResult === void 0 ? void 0 : subsidyResult.rejectMessage) !== null && _b !== void 0 ? _b : t('Subsidies not available'), onClose: () => setErrorPopupOpen(false), onNewSearch: backToResults })),
        children({
            noSubsidyAvailable: subsidyTravellerState === null || subsidyTravellerState === void 0 ? void 0 : subsidyTravellerState.isSubsidyRejected,
            withSubsidy: orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.isSubsidiesAvailable,
            subsidyRejectMessage: (subsidyTravellerState === null || subsidyTravellerState === void 0 ? void 0 : subsidyTravellerState.rejectMessage)
                ? subsidyTravellerState.rejectMessage
                : t('Subsidies not available')
        })));
};
export default PassengerSubsidy;
