import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
const BaggageDirection = ({ directions, allSegments, className }) => {
    const { BaggageDirection: css } = useTheme('BaggageList');
    const { t } = useTranslation('IncludedBaggage');
    const text = useMemo(() => {
        if (allSegments) {
            return t('All flights');
        }
        return directions.map(point => `${point.departure.iata} - ${point.arrival.iata}`).join('; ');
    }, [directions]);
    if (text === null) {
        return null;
    }
    return React.createElement("span", { className: cn(css.root, className) }, text);
};
export default BaggageDirection;
