import { __awaiter } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSaveOrderServicesMutation } from '@websky/graphql';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { getAeroexpress, getOrder } from '../../../Checkout/store/order/selectors';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import { ThemeProvider, useTheme } from '../../../theme';
import { currentCurrency, useIsMobile } from '../../../utils';
import icons from '../../icons';
import Modal from '../../../Modal';
import { fillOrder } from '../../../Checkout/store/order/actions';
import { useAeroexpress } from '../../../Aeroexpress/components/hooks';
import { getAeroexpressPrice } from '../../../Checkout/store/orderPrice/selectors';
import AeroexpressPopup from '../AeroexpressPopup/AeroexpressPopup';
import { useRenderers } from '../../../renderProps';
import Close from '../Close/Close';
import AeroexpressSummary from '../../../ServiceSummary/services/AeroexpressSummary';
const AeroexpressAdditionalService = ({ aggregationMode, aeroexpress: aeroexpressProps, isSelected: isSelectedProps, icons: iconsProps }) => {
    var _a, _b, _c;
    const { t } = useTranslation('Checkout');
    const { Aeroexpress: cardStyles } = useTheme('CheckoutAdditionalService');
    const { Aeroexpress: css } = useTheme('Aeroexpress');
    const dispatch = useDispatch();
    const order = useSelector(getOrder);
    const aeroexpress = aggregationMode
        ? aeroexpressProps
        : useSelector(getAeroexpress);
    const isSelected = aggregationMode ? isSelectedProps : ((_a = useSelector(getAeroexpressPrice)) === null || _a === void 0 ? void 0 : _a.amount) > 0;
    const selectedTrips = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.selectedTrips;
    const isMobile = useIsMobile();
    const minPrice = useMemo(() => {
        var _a, _b, _c, _d, _e;
        let minAmount = (_b = (_a = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.trips[0]) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.amount;
        for (const trip of aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.trips) {
            if (((_c = trip === null || trip === void 0 ? void 0 : trip.price) === null || _c === void 0 ? void 0 : _c.amount) < minAmount) {
                minAmount = trip.price.amount;
            }
        }
        return {
            amount: minAmount,
            currency: ((_e = (_d = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.trips) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.price.currency) || currentCurrency
        };
    }, [aeroexpress]);
    const selectedTripsPrice = selectedTrips.reduce((price, selectedTrip) => ({ amount: selectedTrip.price.amount + price.amount, currency: currentCurrency }), {
        amount: 0,
        currency: currentCurrency
    });
    const [saveOrderServicesMutation, { loading }] = useSaveOrderServicesMutation();
    const selectAeroexpress = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield saveOrderServicesMutation({
                variables: {
                    params: {
                        id: order.id,
                        aeroexpress: { selectedTrips: [...ids] }
                    }
                }
            });
            dispatch(fillOrder(data === null || data === void 0 ? void 0 : data.SaveOrderServices));
            close();
        }
        catch (e) {
            console.log(e);
        }
    });
    const { showWarning, servicesRedirectURL, closeWarning, buttonText, isOpen, close, handleOpenModalClick } = useAeroexpress(selectAeroexpress, order.id);
    const onAeroexpressItemRemove = (_, payload) => {
        if (aeroexpress.selectedTrips.length) {
            const filterTripIds = aeroexpress.selectedTrips
                .filter(trip => trip.id !== payload.tripId)
                .map(trip => trip.id);
            selectAeroexpress(filterTripIds);
        }
    };
    const renderClose = (onClick) => React.createElement(Close, { className: css.close, onClick: onClick });
    const AdditionalServiceRender = (_c = (_b = useRenderers()) === null || _b === void 0 ? void 0 : _b.CheckoutAdditionalService) === null || _c === void 0 ? void 0 : _c.CheckoutAdditionalService;
    const ServiceWrapper = AdditionalServiceRender !== null && AdditionalServiceRender !== void 0 ? AdditionalServiceRender : CheckoutAdditionalService;
    return (React.createElement(React.Fragment, null,
        React.createElement(AeroexpressSummary, { travellers: order.travellers, selectedTrips: aeroexpress.selectedTrips, onServiceDelete: onAeroexpressItemRemove }, content => (React.createElement(ServiceWrapper, { isLoading: loading, className: cardStyles.aeroexpress, header: t('Aeroexpress'), isSelected: isSelected, priceFrom: minPrice, priceTotal: selectedTripsPrice, mode: aggregationMode ? 'aggregator' : 'default', description: t('Aeroexpress:No worries about traffic jams, arrival at the airport strictly on schedule'), addClick: handleOpenModalClick, coverClassName: cardStyles.aeroexpress_aggregator, icon: icons.transferIcon, aggregationIcons: iconsProps, buttons: [
                {
                    action: handleOpenModalClick,
                    isLoading: loading,
                    text: buttonText,
                    buttonType: 'change'
                },
                !loading && {
                    action: () => open('https://aeroexpress.ru', '_blank'),
                    text: t('Aeroexpress:View schedule')
                }
            ] },
            React.createElement(ThemeProvider, { value: {
                    ServiceSummary: {
                        ServiceSummaryOption: {
                            option: css.option
                        }
                    }
                } }, content)))),
        React.createElement(Modal, { maxWidth: false, open: isOpen, slideUp: isMobile, classes: { scrollBody: css.aeroexpress__scrollBody, paper: css.aeroexpress_paper }, onClose: close, closeButtonRenderer: renderClose },
            React.createElement(AeroexpressPopup, { trips: aeroexpress.trips, selectedTrips: aeroexpress.selectedTrips, isLoading: loading, onSubmit: selectAeroexpress, onClose: close })),
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning })));
};
export default AeroexpressAdditionalService;
