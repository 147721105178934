import { __rest } from "tslib";
import * as React from 'react';
import { forwardRef } from 'react';
import PhoneInputComponent from 'react-phone-number-input';
const { formatPhoneNumberIntl } = require('react-phone-number-input');
const { parsePhoneNumberFromString } = require('libphonenumber-js');
import Input from '../Input/Input';
import { useTheme } from '../theme';
import { isPossibleNumber } from 'libphonenumber-js';
import { isRusNumber } from './utils';
import cn from 'classnames';
const Context = React.createContext({});
const InputWrapper = forwardRef((_a, ref) => {
    var _b;
    var { onFocus, onKeyDown } = _a, props = __rest(_a, ["onFocus", "onKeyDown"]);
    const onChange = (e) => {
        let value = e.target.value.replace(/[()\s]/g, '');
        const oldValue = props.value.replace(/[()\s]/g, '');
        const ruCountryCodeAlternatives = ['89', '+89'];
        ruCountryCodeAlternatives.forEach(code => {
            if (value.startsWith(code)) {
                value = value.replace(code, '+79');
            }
        });
        if (value.startsWith('+7') &&
            value.length > oldValue.length &&
            !isPossibleNumber(value) &&
            isPossibleNumber(oldValue)) {
            props.onChange(oldValue);
        }
        else {
            props.onChange(value);
        }
    };
    let parsedPhone;
    if (isRusNumber(props.value)) {
        parsedPhone = parsePhoneNumberFromString(props.value, 'RU').formatInternational();
    }
    else {
        parsedPhone = formatPhoneNumberIntl(props.value) || formatPhoneNumberIntl(`+${props.value}`);
    }
    const textFieldsProps = React.useContext(Context);
    const _c = (_b = textFieldsProps === null || textFieldsProps === void 0 ? void 0 : textFieldsProps.InputProps) !== null && _b !== void 0 ? _b : {}, { value } = _c, InputProps = __rest(_c, ["value"]);
    const css = useTheme('PhoneInput').PhoneInput;
    return (React.createElement(Input, { absoluteHintPosition: true, className: cn(css.input, props.className), inputClassName: textFieldsProps.className, variant: textFieldsProps.variant, TextFieldProps: {
            onFocus,
            onKeyDown,
            onChange,
            // Do not spread `context.textFieldsProps` there,
            // or `react-phone-number-input` won't work.
            name: textFieldsProps.name,
            onBlur: textFieldsProps.onBlur,
            label: textFieldsProps.label,
            error: textFieldsProps.error,
            helperText: textFieldsProps.helperText,
            disabled: textFieldsProps.disabled || props.disabled,
            placeholder: textFieldsProps.placeholder,
            type: 'tel',
            InputProps,
            className: textFieldsProps.className
        }, disabled: props.disabled, value: parsedPhone ? parsedPhone : props.value, tabIndex: textFieldsProps.tabIndex, inputMode: "tel", autoFocus: props.autoFocus }));
});
const PhoneInput = ({ className, value, textFieldsProps = {}, displayInitialValueAsLocalNumber = true, disabled, autoFocus }) => {
    return (React.createElement(Context.Provider, { value: textFieldsProps },
        React.createElement(PhoneInputComponent, { className: className, onChange: textFieldsProps.onChange, onFocus: textFieldsProps.onFocus, onBlur: textFieldsProps.onBlur, showCountrySelect: false, limitMaxLength: true, displayInitialValueAsLocalNumber: displayInitialValueAsLocalNumber, value: value || '', inputComponent: InputWrapper, disabled: disabled, autoFocus: autoFocus })));
};
export default PhoneInput;
