import { useCallback, useMemo } from 'react';
import { useConfig } from '../../../context';
import { useImportantInsurancesTouched } from '../../hooks';
/**
 * model for insurance product
 */
export const useInsuranceProduct = (insuranceProgram, onToggle, isPopular, productState) => {
    const { global: { insurance: { allowImplicitConfirm } } } = useConfig();
    const importantInsurancesTouched = useImportantInsurancesTouched();
    const isAvailable = productState === 'available';
    const isSelected = productState === 'selected';
    const isConfirmed = productState === 'confirmed';
    const showIconButton = useMemo(() => {
        if (!isPopular) {
            return true;
        }
        else if (productState === 'confirmed') {
            return true;
        }
        return false;
    }, [isPopular, productState]);
    const isMainInsurance = isPopular;
    const isTouched = !isMainInsurance || (isMainInsurance && importantInsurancesTouched.getIsTouched(insuranceProgram.code));
    const isError = allowImplicitConfirm && !isTouched;
    const handleToggle = useCallback(() => {
        onToggle(insuranceProgram.code);
    }, [insuranceProgram, productState, onToggle]);
    return {
        // data
        showIconButton,
        isAvailable,
        isSelected,
        isConfirmed,
        isTouched,
        isError,
        // methods
        handleToggle
    };
};
