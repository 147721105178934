import { __awaiter } from "tslib";
import { useState } from 'react';
import { useFfpAccountHistoryQuery, useFfpAccountLogoutMutation } from '@websky/graphql';
import { ApolloError } from 'apollo-boost';
export function useLoyalty({ ffpInfo, onLoyaltyCardSaved }) {
    const [error, setError] = useState(null);
    const { data: ffpAccountHistory, refetch: getFfpAccountHistory } = useFfpAccountHistoryQuery({ skip: !ffpInfo });
    const [ffpAccountLogoutMutation] = useFfpAccountLogoutMutation();
    const onError = (errors = []) => {
        const error = errors[0];
        setError((error === null || error === void 0 ? void 0 : error.debugMessage) || (error === null || error === void 0 ? void 0 : error.message) || 'Unknown error');
    };
    const onClearError = () => {
        setError(null);
    };
    const onQueryWithErrorHandler = (query) => __awaiter(this, void 0, void 0, function* () {
        try {
            return yield query();
        }
        catch (e) {
            if (e instanceof ApolloError) {
                onError(e.graphQLErrors);
            }
            return null;
        }
    });
    const onQueryWithLoyaltyCardSaved = (query) => {
        return () => __awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield query();
                const savedResult = yield onLoyaltyCardSaved();
                return Boolean(result) && savedResult;
            }
            catch (_a) {
                return false;
            }
        });
    };
    const onRefetchFfpAccountHistory = () => __awaiter(this, void 0, void 0, function* () {
        const { data } = yield onQueryWithErrorHandler(getFfpAccountHistory);
        return data === null || data === void 0 ? void 0 : data.FfpAccountHistory;
    });
    const onLogoutFfpAccount = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const { data } = yield onQueryWithErrorHandler(ffpAccountLogoutMutation);
        return (_a = data === null || data === void 0 ? void 0 : data.FfpAccountLogout) === null || _a === void 0 ? void 0 : _a.result;
    });
    return {
        // data
        ffpAccountHistory,
        error,
        // methods
        onError,
        onClearError,
        onLoyaltySaved: onQueryWithLoyaltyCardSaved(onRefetchFfpAccountHistory),
        onLogoutFfpAccount: onQueryWithLoyaltyCardSaved(onLogoutFfpAccount)
    };
}
