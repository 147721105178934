import { handleActions } from 'redux-actions';
import { SET_REDEEM_MILES, TOGGLE_REDEEM_MILES } from './actions';
export const redeemMilesReducer = handleActions({
    [TOGGLE_REDEEM_MILES]: (state) => {
        return !state;
    },
    [SET_REDEEM_MILES]: (state, action) => {
        return action.payload;
    }
}, false);
