import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import cn from 'classnames';
import Fade from '@material-ui/core/Fade';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import FullScreenDialogHeader from './Header/Header';
const SlideUp = React.forwardRef((props, ref) => (React.createElement(Slide, Object.assign({ direction: "up" }, props, { ref: ref, children: props.children }))));
const FadeTransition = React.forwardRef((props, ref) => (React.createElement(Fade, Object.assign({}, props, { ref: ref, children: props.children }))));
initI18n('FullscreenDialog');
const FullscreenDialog = React.forwardRef(({ mountOnEnter, isOpen, children, title, headerClassName, closeClassName, contentClassName, fadeTransition, keepMounted, onClose, rootDialogClassName, hideHeader, backButtonRender }, ref) => {
    const { FullscreenDialog: theme } = useTheme('FullscreenDialog');
    const [dialogIsMounted, setDialogIsMounted] = useState(!mountOnEnter);
    useEffect(() => {
        if (isOpen && !dialogIsMounted) {
            setDialogIsMounted(true);
        }
    }, [isOpen, dialogIsMounted]);
    return (React.createElement(Dialog, { open: isOpen, TransitionComponent: !fadeTransition ? SlideUp : FadeTransition, fullScreen: true, onClose: onClose, classes: {
            root: cn(theme.withoutPadding, rootDialogClassName)
        }, keepMounted: keepMounted },
        !hideHeader && (React.createElement(FullScreenDialogHeader, { onClose: onClose, title: title, headerClassName: headerClassName, closeClassName: closeClassName })),
        dialogIsMounted && (React.createElement("div", { ref: ref, className: cn(theme.content, contentClassName, {
                [theme.content_withoutOffset]: hideHeader
            }) }, children))));
});
export default FullscreenDialog;
