import { TravellerFieldEnum } from '@websky/graphql';
import { getInputType } from './Passengers';
import i18n from 'i18next';
import { AdditionalTravellerFieldEnum, InputType } from '../../../../../Passenger/types';
export const getAccompanyingFields = (orderCustomer) => {
    const customerFields = orderCustomer.values
        .filter(value => [TravellerFieldEnum.AccompanyingPersonName, TravellerFieldEnum.AccompanyingPersonTicketNumber].includes(value.type))
        .map(({ name, type, validationRules, value, isEditable }) => {
        return {
            name,
            readonly: !isEditable,
            type: type,
            input: getInputType(type),
            placeholder: i18n.t(`Passenger:${name}`),
            validations: validationRules,
            defaultValue: value
        };
    });
    if (customerFields.length) {
        customerFields.push({
            name: `accompanyingCheck`,
            readonly: false,
            type: AdditionalTravellerFieldEnum.AccompanyingCheck,
            input: InputType.Checkbox,
            placeholder: '',
            validations: [
                {
                    required: true,
                    with: null,
                    hidden: null,
                    label: null,
                    hint: null,
                    placeholder: null,
                    minDate: null,
                    maxDate: null,
                    maxLength: null,
                    regExp: null,
                    options: null
                }
            ],
            defaultValue: null
        });
    }
    return customerFields;
};
