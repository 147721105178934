import * as React from 'react';
import { Provider } from 'react-redux';
import { lazy, Suspense } from 'react';
import { createStore } from '../store';
import { useGraphQLClient } from '../../graphql-client';
import { initI18n } from '../../utils';
import { GuardedApolloProvider } from '../../GuardedApolloProvider';
const Results = lazy(() => import('./Results/Results'));
initI18n('Results');
const Component = props => {
    const client = useGraphQLClient();
    const store = createStore();
    return (React.createElement(Provider, { store: store },
        React.createElement(GuardedApolloProvider, { client: client },
            React.createElement(Suspense, { fallback: React.createElement("div", null) },
                React.createElement(Results, Object.assign({}, props))))));
};
export default Component;
