import memes from '../../../../memes';
import { addDays, format, isAfter, isBefore, max, min } from 'date-fns';
import { getDateWithoutTimezone } from '../../../../Engine/components/FastSearch/utils';
import { API_DATE_FORMAT } from '../../../../utils';
export const isFlightsDatesInRightOrder = memes((prev, curr, index, segments) => {
    const currentSegment = segments[index];
    const nextSegment = segments[index + 1];
    if (nextSegment && !prev) {
        const currentSegmentDate = new Date(currentSegment.date);
        const nextSegmentDate = new Date(nextSegment.date);
        return isAfter(currentSegmentDate, nextSegmentDate);
    }
    return prev;
});
export const isWrongOrder = (segments, reduceRight = false) => reduceRight
    ? segments.reduceRight(isFlightsDatesInRightOrder, false)
    : segments.reduce(isFlightsDatesInRightOrder, false);
export const sortSegments = memes((legId, date, segments) => {
    const segmentsCopy = [...segments];
    const DATE_FORMAT = 'yyyy-MM-dd';
    if (legId === 0 && isWrongOrder(segmentsCopy)) {
        const maxDate = max(segmentsCopy.map(segment => getDateWithoutTimezone(segment.date)));
        segmentsCopy.forEach(segment => {
            segment.date = format(maxDate, DATE_FORMAT);
        });
    }
    else if (legId > 0 && isWrongOrder(segmentsCopy, true)) {
        const minDate = min([...segmentsCopy.map(segment => getDateWithoutTimezone(segment.date)), date]);
        segmentsCopy.forEach(segment => {
            segment.date = format(minDate, DATE_FORMAT);
        });
    }
    return segmentsCopy;
});
export const getSegmentsWithOffset = (segments, legId, offset) => {
    const currentSegmentDate = addDays(getDateWithoutTimezone(segments[legId].date), offset);
    return segments.map((segment, segmentIndex) => {
        let date = getDateWithoutTimezone(segment.date);
        if (segmentIndex === legId) {
            date = currentSegmentDate;
        }
        else if (segmentIndex < legId && isBefore(currentSegmentDate, date)) {
            date = currentSegmentDate;
        }
        else if (segmentIndex > legId && isAfter(currentSegmentDate, date)) {
            date = currentSegmentDate;
        }
        return Object.assign(Object.assign({}, segment), { date: format(date, API_DATE_FORMAT) });
    });
};
