import * as React from 'react';
import { useMemo } from 'react';
import { getTotalPrice } from '../utils';
import { getVipServiceStateKeys } from './utils';
const serviceReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { [action.segmentId]: Object.assign(Object.assign({}, state[action.segmentId]), { [action.passengerId]: action.count }) });
};
export const useVipServiceState = (passengers, services, selectedServices) => {
    const initialState = useMemo(() => {
        let state = {};
        passengers.forEach(passenger => {
            services.forEach(service => {
                if (selectedServices[passenger.id] && selectedServices[passenger.id][service.id]) {
                    selectedServices[passenger.id][service.id].forEach(service => {
                        service.segments.forEach(segment => {
                            state = Object.assign(Object.assign({}, state), { [segment]: Object.assign(Object.assign({}, state[segment]), { [passenger.id]: service.number }) });
                        });
                    });
                }
            });
        });
        return state;
    }, [passengers, services, selectedServices]);
    const [state, dispatch] = React.useReducer(serviceReducer, initialState);
    const minPrice = useMemo(() => {
        if (services.length) {
            let min = services[0].price;
            services.forEach(service => {
                if (service.price.amount < min.amount) {
                    min = service.price;
                }
            });
            return min;
        }
        return null;
    }, [services]);
    const totalPrice = useMemo(() => {
        var _a;
        if (Object.keys(state).length) {
            let servicePrices = [];
            for (const segmentId in state) {
                for (const passengerId in state[segmentId]) {
                    const count = state[segmentId][passengerId];
                    if (count > 0) {
                        const serviceByPassengerId = services.find(service => service.allowedSegments.find(ids => ids.includes(segmentId)) &&
                            service.allowedPassengers.find(ids => ids.includes(passengerId)));
                        const servicePrice = (_a = serviceByPassengerId === null || serviceByPassengerId === void 0 ? void 0 : serviceByPassengerId.price) !== null && _a !== void 0 ? _a : services[0].price;
                        servicePrices = [...servicePrices, Object.assign(Object.assign({}, servicePrice), { amount: servicePrice.amount * count })];
                    }
                }
            }
            const totalPrice = getTotalPrice(servicePrices, price => price);
            if (totalPrice.amount > 0) {
                return totalPrice;
            }
            return null;
        }
        return null;
    }, [state, services]);
    const isDifferentState = React.useMemo(() => {
        const initialStateKeys = getVipServiceStateKeys(initialState);
        const stateKeys = getVipServiceStateKeys(state);
        return JSON.stringify([...initialStateKeys]) !== JSON.stringify([...stateKeys]);
    }, [initialState, state]);
    return {
        state,
        minPrice,
        totalPrice,
        isDifferentState,
        dispatch
    };
};
