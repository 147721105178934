import cn from 'classnames';
import * as React from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { addPassenger, removePassenger, setPassengers } from '../../../store/passengers/actions';
import { getPassengersErrors, getPassengersState, getPassengersTitle, getTotalPassengersCount } from '../../../store/passengers/selectors';
import { getSegments, isMultiCity } from '../../../store/segments/selectors';
import Dropdown from '../../../../Dropdown';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { Passengers as PassengersComponent } from '../../../../Passengers/components/Passengers';
import { useConfig } from '../../../../context';
import { useTheme } from '../../../../theme';
import { Tooltip } from '../../../../index';
import { canBeDecrease, canBeIncrease, isRoutesMatch } from './utils';
import { useIsWithSelectableCurrencies } from '../hooks';
import { getRedeemMiles } from '../../../store/selectors';
import ModalConfirm from '../../../../ModalConfirm';
import { getSelectedFareGroup } from '../../../store/fareGroup/selectors';
import { setSelectedFareGroup } from '../../../store/fareGroup/actions';
const arrowIcon = (React.createElement("svg", { width: "10", height: "5", viewBox: "0 0 10 5", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M0 0L5 5L10 0L0 0Z", fill: "currentColor" })));
const passengerIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { opacity: "0.5" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 12C13.66 12 15 10.66 15 9C15 7.34 13.66 6 12 6C10.34 6 9 7.34 9 9C9 10.66 10.34 12 12 12ZM12 13.9C10 13.9 6 15 6 17V18H18V17C18 15 14 13.9 12 13.9Z", fill: "white" }))));
const Passengers = memo(({ isMultiCity, passengersTitle, passengers, selectedFareGroup, setSelectedFareGroup, setPassengers, addPassenger, removePassenger, passengersCount, segments, redeemMiles, customCanBeIncrease, customCanBeDecrease }) => {
    var _a, _b;
    const { Passengers: css } = useTheme('SearchForm');
    const validation = useSelector(getPassengersErrors);
    const alternative = useConfig().SearchForm.mobileFormType !== 'common';
    const withSelectableCurrencies = useIsWithSelectableCurrencies();
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation(['SearchForm', 'PassengerTypes']);
    const buttonRef = useRef();
    const open = useCallback(() => setIsOpen(!isOpen), [isOpen]);
    const close = useCallback(() => setIsOpen(false), []);
    const focus = useCallback((event) => event.target.blur(), []);
    const onClose = useCallback((event) => {
        if (event.target !== buttonRef.current) {
            close();
        }
    }, [buttonRef]);
    const { maxPassengersCount, passengersConfig } = useConfig().SearchForm;
    const filterPassengers = () => {
        const state = Object.assign({}, passengers);
        passengersConfig
            .filter(it => it.blockPayByMiles)
            .map(it => {
            state[it.code] = 0;
        });
        setPassengers(state);
    };
    React.useEffect(() => {
        if (redeemMiles) {
            filterPassengers();
        }
    }, [redeemMiles]);
    const fareGroupsMap = new Map();
    if (passengersConfig === null || passengersConfig === void 0 ? void 0 : passengersConfig.length) {
        passengersConfig.forEach(passenger => {
            var _a, _b;
            const group = (_a = passenger.group) !== null && _a !== void 0 ? _a : 'Standard';
            fareGroupsMap.set(group, [...((_b = fareGroupsMap.get(group)) !== null && _b !== void 0 ? _b : []), passenger]);
        });
    }
    // группа может остаться в кеше юзера но ее например убрали в обнове - тогда сброс
    if (!fareGroupsMap.get(selectedFareGroup)) {
        setSelectedFareGroup('Standard');
    }
    const passengerSchemas = React.useMemo(() => {
        var _a;
        return (((_a = fareGroupsMap
            .get(selectedFareGroup)) === null || _a === void 0 ? void 0 : _a.filter(passenger => {
            var _a;
            if (passenger.disabled || (redeemMiles && (passenger === null || passenger === void 0 ? void 0 : passenger.blockPayByMiles))) {
                return false;
            }
            if (!passenger.onlyRoutes && !passenger.disabledRoutes) {
                return true;
            }
            let isRouteAvailable = true;
            if ((_a = passenger === null || passenger === void 0 ? void 0 : passenger.disabledRoutes) === null || _a === void 0 ? void 0 : _a.some(routes => {
                if (routes.length === segments.length) {
                    return routes.every((route, index) => isRoutesMatch(route, segments[index]));
                }
                return false;
            })) {
                isRouteAvailable = false;
            }
            else if (passenger === null || passenger === void 0 ? void 0 : passenger.onlyRoutes) {
                isRouteAvailable = passenger.onlyRoutes.some(routes => {
                    if (routes.length === segments.length) {
                        return routes.every((route, index) => isRoutesMatch(route, segments[index]));
                    }
                    return false;
                });
            }
            if (isRouteAvailable) {
                return true;
            }
            else {
                if (passengers[passenger.code] > 0) {
                    setPassengers(Object.assign(Object.assign({}, passengers), { [passenger.code]: 0 }));
                }
                return false;
            }
        }).map(passenger => {
            var _a;
            return {
                type: passenger.code,
                count: passengers[passenger.code] || 0,
                modifyCount: (_a = passengersConfig.find(conf => conf.code === passenger.code)) === null || _a === void 0 ? void 0 : _a.modifyCount,
                canBeIncreased: customCanBeIncrease
                    ? customCanBeIncrease(passenger, passengersConfig, passengers, passengersCount, maxPassengersCount)
                    : canBeIncrease(passenger, passengersConfig, passengers, passengersCount, maxPassengersCount),
                canBeDecreased: customCanBeDecrease
                    ? customCanBeDecrease(passenger, passengersConfig, passengers)
                    : canBeDecrease(passenger, passengersConfig, passengers)
            };
        })) || []);
    }, [passengersConfig, passengers, segments, redeemMiles, selectedFareGroup]);
    const setCurrentGroup = (val) => {
        setSelectedFareGroup(val);
    };
    return (React.createElement("div", { className: cn(css.passengers, {
            [css.passengers_isMultiCity]: isMultiCity,
            [css.passengers_withCurrency]: withSelectableCurrencies,
            [css.alternative]: alternative
        }) },
        React.createElement(Tooltip, { tooltipClassName: css.tooltip, popperClassName: css.tooltip__popper, title: t(validation === null || validation === void 0 ? void 0 : validation.error, {
                passengerTypes: (_b = (_a = validation === null || validation === void 0 ? void 0 : validation.interpolateData) === null || _a === void 0 ? void 0 : _a.map(required => t(`PassengerTypes:${required}`))) === null || _b === void 0 ? void 0 : _b.join(', ')
            }), open: !!validation, interactive: false },
            React.createElement("button", { type: "button", ref: buttonRef, className: cn(css.selector, { [css.selector_focused]: isOpen }), onClick: open, onFocus: focus },
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    React.createElement("span", { className: css.passengerIcon }, passengerIcon)),
                React.createElement("span", { className: css.title }, passengersTitle),
                React.createElement("span", { className: cn(css.arrowIcon, { [css.arrowIcon_flipped]: isOpen }) }, arrowIcon))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dropdown, { isOpen: isOpen, onClose: onClose },
                React.createElement("div", { className: css.menu },
                    React.createElement(PassengersComponent, { passengers: passengerSchemas, fareGroups: [...fareGroupsMap.keys()], currentGroup: selectedFareGroup, setCurrentGroup: setCurrentGroup, removePassenger: removePassenger, addPassenger: addPassenger })))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(ModalConfirm, { isOpen: isOpen, onClose: close, title: t('Passengers'), classes: {
                    title: css.modal__Title,
                    closeButton: css.modal__closeButton,
                    swipeBlock: css.modal__swipeBlock
                } },
                React.createElement("div", { className: css.mobileMenu },
                    React.createElement("div", { className: css.mobileContent },
                        React.createElement(PassengersComponent, { passengers: passengerSchemas, removePassenger: removePassenger, addPassenger: addPassenger, fareGroups: [...fareGroupsMap.keys()], currentGroup: selectedFareGroup, setCurrentGroup: setCurrentGroup })))))));
});
const mapStateToProps = (state) => {
    return {
        isMultiCity: isMultiCity(state),
        passengersTitle: getPassengersTitle(state),
        passengers: getPassengersState(state),
        passengersCount: getTotalPassengersCount(state),
        segments: getSegments(state),
        redeemMiles: getRedeemMiles(state),
        selectedFareGroup: getSelectedFareGroup(state)
    };
};
const mapDispatchToProps = {
    addPassenger,
    removePassenger,
    setPassengers,
    setSelectedFareGroup
};
export default connect(mapStateToProps, mapDispatchToProps)(Passengers);
