import { createSelector } from 'reselect';
import { ExareStatus, TravellerFieldEnum } from '@websky/graphql';
import { getInsurancesByTypes, getUserValue } from '../../../../utils';
export const getOrder = (state) => state.order;
export const getPassengers = createSelector([getOrder], order => (order ? order.travellers : []));
export const getSegments = createSelector([getOrder], order => ((order === null || order === void 0 ? void 0 : order.flight) ? order.flight.segmentGroups : []));
export const getLastExchange = createSelector([getOrder], order => { var _a; return (_a = order === null || order === void 0 ? void 0 : order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange; });
export const isOpenedExchange = createSelector([getLastExchange], order => {
    return (order &&
        order.status !== ExareStatus.Refused &&
        order.status !== ExareStatus.Rejected &&
        order.status !== ExareStatus.Finished);
});
export const isAutoMode = createSelector([getOrder], order => {
    return order === null || order === void 0 ? void 0 : order.exareInfo.exchangeAutoMode;
});
export const getCustomerData = createSelector([getOrder], (order) => {
    const customer = order === null || order === void 0 ? void 0 : order.customer;
    if (!customer.values) {
        return null;
    }
    const firstName = getUserValue(customer, TravellerFieldEnum.FirstName), lastName = getUserValue(customer, TravellerFieldEnum.LastName);
    return {
        name: firstName ? `${lastName} ${firstName}` : '',
        phone: getUserValue(customer, TravellerFieldEnum.Phone),
        email: getUserValue(customer, TravellerFieldEnum.Email)
    };
});
export const getSelectedInsurancesByType = (insuranceType) => createSelector(getOrder, order => getInsurancesByTypes(order, insuranceType));
