import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterGroupsForDirectionsDialog } from '../../utils';
import DropdownComponent from '../../../Dropdown/Dropdown';
import DirectionsDialog from '../DirectionsDialog/DirectionsDialog';
import { LocationGroupType } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { BaseButton } from '../../../index';
const Dropdown = props => {
    const { Dropdown: css } = useTheme('LocationAutocomplete');
    const { t } = useTranslation('LocationAutocomplete');
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const onDirectionsClose = () => setDialogIsOpen(false);
    const onDirectionsOpen = () => setDialogIsOpen(true);
    // Check if we do have some additional groups that we could split from the whole list of options.
    const splitOptions = !props.hasInputData &&
        props.options.length > 5 &&
        props.options.some(item => 'type' in item &&
            (item.type === LocationGroupType.Nearest ||
                item.type === LocationGroupType.Popular ||
                item.type === LocationGroupType.AdditionalCities));
    let desktopOptions = [...props.options];
    if (splitOptions) {
        desktopOptions = props.options.filter(item => 'type' in item &&
            (item.type === LocationGroupType.Nearest ||
                item.type === LocationGroupType.Popular ||
                item.type === LocationGroupType.AdditionalCities));
    }
    return (React.createElement(DropdownComponent, Object.assign({}, props.onClickOutsideProps, { keepMounted: true, isOpen: props.options.length && props.isOpen, onClose: props.onClose }),
        React.createElement("div", { className: css.options },
            desktopOptions.map((item, index) => 'value' in item ? props.renderOption(item, index) : props.renderGroup(item, index)),
            splitOptions && (React.createElement("div", { className: css.allDirections },
                React.createElement(BaseButton, { variant: "smallThird", className: css.allDirections__button, onMouseDown: onDirectionsOpen }, t('show all directions')),
                React.createElement(DirectionsDialog, { isOpen: dialogIsOpen, onClose: onDirectionsClose, onSelect: props.onSelect, groups: filterGroupsForDirectionsDialog(props.options) }))))));
};
export default Dropdown;
