import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import AircraftPopupContent from './AircraftPopupContent/AircraftPopupContent';
import SimpleLoader from '../../SimpleLoader';
import { useAircraftInfoLazyQuery } from '@websky/graphql';
import Modal from '../../Modal';
import Button from '../../Button';
import { useToggleable } from '../../hooks';
import { useTheme } from '../../theme';
import { InfoIcon } from '../../FlightCard/components/Icons';
import { CloseButton } from '../../index';
const AircraftPopup = ({ className, id, operatingIata, name, renderButton, renderInBody }) => {
    var _a;
    const { AircraftPopup: css } = useTheme('AircraftPopup');
    const { t } = useTranslation('Checkout');
    const { isOpen, open, close } = useToggleable(false);
    const [aircraftInfo, { data, loading }] = useAircraftInfoLazyQuery({
        variables: { id, operatingAirlineIata: operatingIata }
    });
    const onOpen = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield aircraftInfo();
        open();
    }), [aircraftInfo, open]);
    const renderCloseButton = useCallback((onClick) => React.createElement(CloseButton, { variant: "closePopup", className: css.close, onClick: onClick }), [isOpen]);
    const renderModal = () => {
        return (React.createElement(Modal, { classes: {
                paper: css.paper
            }, open: isOpen, onClose: close, disableAutoFocus: true, closeButtonRenderer: renderCloseButton },
            React.createElement(AircraftPopupContent, { name: name, aircraftInfo: data === null || data === void 0 ? void 0 : data.AircraftInfo, onClose: close })));
    };
    return (React.createElement(React.Fragment, null,
        loading && React.createElement(SimpleLoader, null), (_a = renderButton === null || renderButton === void 0 ? void 0 : renderButton(onOpen, loading)) !== null && _a !== void 0 ? _a : (React.createElement(Button, { className: cn(className, css.button), variant: "text", onClick: onOpen },
        t('Aircraft info'),
        React.createElement("span", { className: css.icon }, InfoIcon))),
        (data === null || data === void 0 ? void 0 : data.AircraftInfo) && isOpen && (React.createElement(React.Fragment, null, renderInBody ? createPortal(renderModal(), document.body) : renderModal()))));
};
export default AircraftPopup;
