import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { useTheme } from '../../theme';
import { SlideBottom } from '../../index';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import Modal from '../../Modal';
import CustomerContactsForm from '../CustomerContactsForm';
initI18n('CustomerContactsForm');
const CustomerContactsModal = props => {
    const { open: contactsModalOpen } = props;
    const { CustomerContactsModal: css } = useTheme('CustomerContactsForm');
    const { t } = useTranslation('CustomerContactsForm');
    const renderTitle = useCallback(() => React.createElement("span", { className: css.saveContactInfo__title }, t('Please, enter your contacts')), []);
    const renderForm = useCallback(() => React.createElement(CustomerContactsForm, Object.assign({}, props)), [props.isLoading, props.open]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { classes: { root: css.root, paper: css.saveContactInfo__modal }, open: contactsModalOpen },
                renderTitle(),
                renderForm())),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: contactsModalOpen, onClose: () => null, wrapperClassName: css.root },
                renderTitle(),
                renderForm()))));
};
export default CustomerContactsModal;
