import * as React from 'react';
import Input from '../../../../Input';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
const SpecialDocumentField = ({ passenger, setValue, documentError }) => {
    var _a;
    const inputRef = React.useRef(null);
    const { t } = useTranslation('AdditionalServiceCard');
    const theme = useTheme('Insurance').SpecialDocumentField;
    const onBlur = (event) => {
        const value = event.target.value;
        setValue(passenger.id, value);
    };
    React.useEffect(() => {
        inputRef.current.value = passenger.value;
    }, [passenger]);
    return (React.createElement("div", { className: theme.field },
        React.createElement("div", { className: theme.name }, passenger.name),
        React.createElement(Input, { name: `insurance_field_${passenger.id}`, label: t('Enter document number'), TextFieldProps: {
                onBlur,
                error: !passenger.isValid,
                helperText: !passenger.isValid && t(((_a = passenger.regex) === null || _a === void 0 ? void 0 : _a.error) || 'Required'),
                defaultValue: passenger.value,
                disabled: passenger.disabled
            }, inputRef: inputRef, absoluteHintPosition: true, className: theme.input })));
};
export default SpecialDocumentField;
