import * as React from 'react';
import { memo } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { useGraphQLClient } from '../../graphql-client';
import SocialAuth from './SocialAuth';
const Component = memo(props => {
    const client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(SocialAuth, Object.assign({}, props))));
});
Component.displayName = 'SocialAuth';
export default Component;
