import { __rest } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { useIsMobile } from '../../utils';
import { useToggleable } from '../../hooks';
import Button from '../../Button';
import Modal from '../../Modal';
import SureModalContent from './SureModalContent/SureModalContent';
const SureModal = (_a) => {
    var _b;
    var { renderTrigger } = _a, props = __rest(_a, ["renderTrigger"]);
    const { SureModal: css } = useTheme('SureModal');
    const { t } = useTranslation('Loyalty');
    const isMobile = useIsMobile();
    const { isOpen, open, close } = useToggleable(false);
    return (React.createElement(React.Fragment, null, (_b = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger(open)) !== null && _b !== void 0 ? _b : React.createElement(Button, { onClick: open }, t('Sure?')),
        React.createElement(Modal, { maxWidth: 'xs', classes: {
                scrollBody: css.scrollBody,
                paper: css.paper
            }, slideUp: isMobile, open: isOpen, onClose: close },
            React.createElement(SureModalContent, Object.assign({}, props, { onClose: close })))));
};
export default SureModal;
