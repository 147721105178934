import * as React from 'react';
import { FormControlLabel } from '@mui/material';
import BaseCheckbox from '../../../BaseComponents/Checkbox';
import { useTheme } from '../../../theme';
import Tooltip from '../../../Tooltip';
import cn from 'classnames';
const Checkbox = React.forwardRef(({ className, label, tooltipLabel, isChecked, isTooltipOpen, onChange }, ref) => {
    const { Checkbox: theme } = useTheme('Consents');
    return (React.createElement(FormControlLabel, { classes: { root: cn(className, theme.control), label: theme.label }, checked: isChecked, control: React.createElement(Tooltip, { classes: { popper: theme.popper }, title: tooltipLabel, open: isTooltipOpen },
            React.createElement(BaseCheckbox, { onChange: onChange })), label: React.createElement("div", { className: theme.label, dangerouslySetInnerHTML: { __html: label } }), ref: ref }));
});
export default Checkbox;
