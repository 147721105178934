import * as React from 'react';
import { Button } from '../../../../../../index';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../../theme';
const ActualiztionOk = props => {
    const { t } = useTranslation('Checkout');
    const { ActualizationOk: theme } = useTheme('ActualizationOk');
    return (React.createElement("div", { className: theme.wrapper },
        React.createElement("p", { className: theme.text }, t('The flight is available, hurry up with booking!')),
        React.createElement(Button, { className: theme.button, onClick: props.onClick }, t('Continue'))));
};
export default ActualiztionOk;
