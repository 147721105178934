import { __decorate } from "tslib";
import * as React from 'react';
import { withTheme } from '../../theme';
import classnames from 'classnames';
import { Money } from '../../../Money';
let Matrix = class Matrix extends React.Component {
    constructor(props) {
        super(props);
        this.refsArray = [];
        this.rowIdHoveredCell = -1;
        this.colIdHoveredRow = -1;
        this.hoverCalculate = this.hoverCalculate.bind(this);
        this.outsideHandler = this.outsideHandler.bind(this);
        this.rowClick = this.rowClick.bind(this);
    }
    hoverCalculate(e) {
        const item = document.elementFromPoint(e.clientX, e.clientY).closest(`.${this.props.theme.MatrixStyles.day}`), itemRowId = parseInt(item.getAttribute('row-id')), itemColId = parseInt(item.getAttribute('col-id'));
        if (this.rowIdHoveredCell === itemRowId && this.colIdHoveredRow === itemColId) {
            return;
        }
        this.rowIdHoveredCell = itemRowId;
        this.colIdHoveredRow = itemColId;
        if (item.classList.contains(this.props.theme.MatrixStyles.day) &&
            !item.classList.contains(this.props.theme.MatrixStyles.empty)) {
            this.fillBackground(itemRowId, itemColId);
        }
        else {
            this.fillBackground(-1, -1);
        }
    }
    outsideHandler() {
        this.fillBackground(-1, -1);
    }
    fillBackground(itemRowId, itemColId) {
        if (this.refsArray) {
            this.refsArray.map((items, rowId) => {
                if (this.vertical && this.vertical.childNodes[rowId]) {
                    const node = this.vertical.childNodes[rowId];
                    if (rowId === itemRowId) {
                        node.classList.add(this.props.theme.MatrixStyles.highlighted);
                    }
                    else {
                        node.classList.remove(this.props.theme.MatrixStyles.highlighted);
                    }
                }
                if (this.horizontal && this.horizontal.childNodes[rowId]) {
                    const node = this.horizontal.childNodes[rowId];
                    if (rowId === itemColId) {
                        node.classList.add(this.props.theme.MatrixStyles.highlighted);
                    }
                    else {
                        node.classList.remove(this.props.theme.MatrixStyles.highlighted);
                    }
                }
                items.map((dateDiv, colId) => {
                    if (dateDiv) {
                        if ((rowId <= itemRowId && colId === itemColId) ||
                            (rowId === itemRowId && colId <= itemColId)) {
                            dateDiv.classList.add(this.props.theme.MatrixStyles.highlighted);
                        }
                        else {
                            dateDiv.classList.remove(this.props.theme.MatrixStyles.highlighted);
                        }
                    }
                });
            });
        }
    }
    componentDidMount() {
        if (this.containerRef) {
            this.containerRef.addEventListener('mousemove', this.hoverCalculate);
            this.containerRef.addEventListener('mouseleave', this.outsideHandler);
        }
    }
    componentWillUnmount() {
        if (this.containerRef) {
            this.containerRef.removeEventListener('mousemove', this.hoverCalculate);
            this.containerRef.removeEventListener('mouseleave', this.outsideHandler);
        }
    }
    rowClick(outboundDate, returnDate, price) {
        if (price.money && !price.isLoading && this.props.onDateSelect) {
            this.props.onDateSelect(outboundDate, returnDate);
        }
    }
    render() {
        this.refsArray = [];
        const averagePrice = (this.props.maxPrice.amount + this.props.minPrice.amount) / 2, style = this.props.theme.MatrixStyles;
        return (React.createElement("div", { className: style.matrix },
            React.createElement("div", { className: style.header }, "Price for round trip"),
            this.props.horizontalHeader(ref => (this.horizontal = ref)),
            this.props.verticalHeader(ref => (this.vertical = ref)),
            React.createElement("div", { className: style.container, ref: ref => (this.containerRef = ref) }, this.props.dates.map((outboundDate, outboundIndex) => {
                this.refsArray.push([]);
                return (React.createElement("div", { key: outboundIndex, className: style.wrapper }, outboundDate.returnDates.map((returnDate, returnIndex) => {
                    const dateStr = `${outboundDate.outboundDate.getTime()}-${returnDate.getTime()}`, price = this.props.prices.hasOwnProperty(dateStr)
                        ? this.props.prices[dateStr]
                        : null, money = price ? price.money : null, attr = { 'row-id': outboundIndex, 'col-id': returnIndex };
                    return (React.createElement("div", Object.assign({ key: returnIndex, ref: ref => {
                            if (this.refsArray.length > outboundIndex) {
                                if (this.refsArray[outboundIndex].length > returnIndex) {
                                    this.refsArray[outboundIndex][returnIndex] = ref;
                                }
                                else {
                                    this.refsArray[outboundIndex].push(ref);
                                }
                            }
                        }, className: classnames(style.day, {
                            [style.lowerThanAverage]: money && money.amount < averagePrice
                        }, {
                            [style.empty]: !price || !money
                        }, {
                            [style.day_loading]: price && price.isLoading
                        }) }, attr, { onClick: () => this.rowClick(outboundDate.outboundDate, returnDate, price) }), money && React.createElement(Money, { money: price.money, moneyClassName: style.money })));
                })));
            }))));
    }
};
Matrix = __decorate([
    withTheme
], Matrix);
export { Matrix };
