import { useCallback, useState } from 'react';
import { Tabs } from '../../types';
import { useSelector } from 'react-redux';
import { getSelectedFares as getExchangeSelectedFares } from '../../../Modules/Exchange/store/newFlights/selectors';
import { getSelectedFares } from '../../../Results/store/selectedFares/selectors';
import { FlightClass } from '@websky/graphql';
export const useLastSelectedFlight = () => {
    var _a, _b;
    const lastSelectedResultFlights = (_a = useSelector(getSelectedFares)) !== null && _a !== void 0 ? _a : [];
    const lastSelectedExchangeFlights = (_b = useSelector(getExchangeSelectedFares)) !== null && _b !== void 0 ? _b : [];
    if (lastSelectedResultFlights.length) {
        return lastSelectedResultFlights[lastSelectedResultFlights.length - 1];
    }
    if (lastSelectedExchangeFlights.length) {
        return lastSelectedExchangeFlights[lastSelectedExchangeFlights.length - 1].fare;
    }
    return null;
};
export const useDesktopFlightModalContent = (props) => {
    const [active, onSelect] = useState(() => {
        var _a, _b, _c, _d, _e;
        const hasBusinessPrices = (_a = props.businessFares) === null || _a === void 0 ? void 0 : _a.some(({ prices }) => prices), hasEconomyPrices = (_b = props.economyFares) === null || _b === void 0 ? void 0 : _b.some(({ prices }) => prices), hasMilesPrices = (_c = props.milesFares) === null || _c === void 0 ? void 0 : _c.some(({ prices }) => prices);
        const availableServices = [];
        if (hasEconomyPrices) {
            availableServices.push({
                flightClass: FlightClass.Economy,
                tab: Tabs.EconomyFares
            });
        }
        if (hasBusinessPrices) {
            availableServices.push({
                flightClass: FlightClass.Business,
                tab: Tabs.BusinessFares
            });
        }
        if (hasMilesPrices) {
            availableServices.push({
                flightClass: FlightClass.Miles,
                tab: Tabs.MilesFares
            });
        }
        if (props.preselectedFlightClass) {
            const existService = availableServices.find(service => service.flightClass === props.preselectedFlightClass);
            if (existService) {
                return existService.tab;
            }
        }
        const lastSelectedFareCategory = (_d = props.lastSelectedFare) === null || _d === void 0 ? void 0 : _d.category;
        if (!!lastSelectedFareCategory) {
            const existsService = availableServices.find(service => service.flightClass === lastSelectedFareCategory);
            if (existsService) {
                return existsService.tab;
            }
        }
        return (_e = availableServices[0]) === null || _e === void 0 ? void 0 : _e.tab;
    });
    const [isFareCompareOpen, setFareCompareOpen] = useState(false);
    const openFareCompare = useCallback(() => {
        setFareCompareOpen(true);
    }, [isFareCompareOpen]);
    const closeFareCompare = useCallback(() => {
        setFareCompareOpen(false);
    }, [isFareCompareOpen]);
    const handleEconomyButtonCLick = useCallback(() => {
        onSelect(Tabs.EconomyFares);
    }, [active]);
    const handleBusinessButtonClick = useCallback(() => {
        onSelect(Tabs.BusinessFares);
    }, [active]);
    const handleMilesButtonClick = useCallback(() => {
        onSelect(Tabs.MilesFares);
    }, [active]);
    const handleFlightDetailsButtonClick = useCallback(() => {
        onSelect(Tabs.FlightInfo);
    }, [active]);
    return {
        active,
        handleEconomyButtonCLick,
        handleBusinessButtonClick,
        handleMilesButtonClick,
        handleFlightDetailsButtonClick,
        isFareCompareOpen,
        openFareCompare,
        closeFareCompare
    };
};
