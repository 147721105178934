import * as React from 'react';
import { Button } from '@material-ui/core';
import { useRef, useEffect } from 'react';
import RootRef from '@material-ui/core/RootRef';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import { additionalEllipse, checkIcon } from '../../Icons';
const SegmentTab = props => {
    const css = useTheme('SegmentTabs').SegmentTab;
    const buttonRef = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            // without delay buttons are blinking because color changes with scroll simultaneously
            if (buttonRef.current && props.isActive && props.scrollToActive) {
                buttonRef.current.scrollIntoView({ inline: 'center', block: 'center' });
            }
        }, 200);
    }, [props.isActive, props.scrollToActive]);
    return (React.createElement(RootRef, { rootRef: buttonRef },
        React.createElement(Button, { classes: {
                root: cn(css.segment, props.className, {
                    [css.segment_active]: props.isActive
                }),
                disabled: css.segment_disabled
            }, disabled: props.segment.isDisabled, onClick: props.onClick },
            props.segment.isChecked && React.createElement("span", { className: css.checked }, checkIcon),
            props.isCompact && !props.isActive ? props.index : props.segment.title,
            props.segment.additional && (props.isActive || !props.isCompact) && (React.createElement(React.Fragment, null,
                React.createElement("span", { className: css.ellipse }, additionalEllipse),
                props.segment.additional)),
            (props === null || props === void 0 ? void 0 : props.countSeatsSelected) > 0 && React.createElement("span", { className: css.count }, props.countSeatsSelected))));
};
export default SegmentTab;
