import React, { useEffect, useRef } from 'react';
export const Chat = () => {
    const chatContainer = useRef();
    useEffect(() => {
        if (chatContainer.current) {
            document.dispatchEvent(new CustomEvent('paxChatPlacement', {
                detail: {
                    chatPlacementElement: chatContainer.current
                }
            }));
        }
        return () => {
            document.dispatchEvent(new CustomEvent('paxChatPlacement', {
                detail: undefined
            }));
        };
    }, [chatContainer]);
    return React.createElement("div", { ref: chatContainer });
};
