import * as React from 'react';
export const alertIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 17V15H13V17H11ZM11 7V13H13V7H11Z", fill: "currentColor" })));
export const timeIcon = (React.createElement("svg", { width: "158", height: "213", viewBox: "0 0 158 213", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M79.1696 213C103.16 213 122.609 210.852 122.609 208.203C122.609 205.554 103.16 203.407 79.1696 203.407C55.1788 203.407 35.7305 205.554 35.7305 208.203C35.7305 210.852 55.1788 213 79.1696 213Z", fill: "#DBDBDB" }),
    React.createElement("path", { d: "M96.6503 17.5124C96.673 7.85118 88.8285 0 79.1477 0H79.1023C69.4441 0.0226259 61.5997 7.82855 61.5997 17.4672C61.577 25.522 67.0182 32.2871 74.4093 34.3461C75.8376 34.7533 77.3566 34.957 78.9209 34.9796C78.9663 34.9796 79.0116 34.9796 79.057 34.9796H79.1023C80.7347 34.9796 82.299 34.776 83.7954 34.3461C91.2091 32.3097 96.6503 25.5446 96.6503 17.5124ZM79.1023 30.4997C71.8927 30.4997 66.066 24.6396 66.066 17.4672C66.066 10.2948 71.938 4.4573 79.125 4.4573C86.3346 4.4573 92.1613 10.3174 92.1613 17.4898C92.1613 24.6622 86.312 30.4997 79.1023 30.4997Z", fill: "#C6C6C6" }),
    React.createElement("path", { d: "M74.4361 27.767L74.4141 42.3833L83.7775 42.3974L83.7996 27.7811L74.4361 27.767Z", fill: "#A0A0A0" }),
    React.createElement("path", { d: "M88.0122 17.4445L87.9895 26.6759C87.9895 27.151 87.7855 27.5583 87.4908 27.7167C87.4227 27.7619 87.3321 27.7845 87.2414 27.7845L70.963 27.7619C70.9403 27.7619 70.9177 27.7619 70.895 27.7619C70.8043 27.7393 70.7136 27.694 70.6229 27.6488C70.3735 27.4678 70.2148 27.0831 70.2148 26.6533L70.2375 17.4219C70.2375 16.992 70.3962 16.6074 70.6456 16.4264C70.7363 16.3585 70.827 16.3132 70.9177 16.3132C70.9403 16.3132 70.963 16.3132 70.9857 16.3132L87.264 16.3359C87.3547 16.3359 87.4454 16.3585 87.5134 16.4037C87.8082 16.5395 88.0122 16.9468 88.0122 17.4445Z", fill: "#B7B7B7" }),
    React.createElement("path", { opacity: "0.3", d: "M88.0125 17.4445L87.9898 26.6758C87.9898 27.151 87.7858 27.5582 87.491 27.7166C87.423 27.7619 87.3323 27.7845 87.2416 27.7845H83.7955L83.7729 42.4008H78.8984L78.9438 16.3358L87.2643 16.3584C87.355 16.3584 87.4457 16.3811 87.5137 16.4263C87.8084 16.5394 88.0125 16.9467 88.0125 17.4445Z", fill: "white" }),
    React.createElement("path", { d: "M74.4367 27.7532L74.4316 31.1018L83.7951 31.1159L83.8001 27.7673L74.4367 27.7532Z", fill: "#919191" }),
    React.createElement("path", { d: "M116.94 36.6539L115.897 39.0975L112.27 47.6048L107.939 45.7721L103.654 43.9394L107.282 35.4321L108.325 32.9885L112.632 34.8212L116.94 36.6539Z", fill: "#A0A0A0" }),
    React.createElement("path", { d: "M122.562 29.5268L118.255 39.6631C118.164 39.8668 117.937 39.9573 117.733 39.8668L115.919 39.0975L111.589 37.2648L107.281 35.4321L105.468 34.6628C105.264 34.5723 105.173 34.3461 105.264 34.1424L109.571 24.006C109.662 23.8024 109.889 23.7119 110.093 23.8024L116.259 26.427L122.358 29.029C122.562 29.0969 122.653 29.3231 122.562 29.5268Z", fill: "#B7B7B7" }),
    React.createElement("path", { opacity: "0.3", d: "M122.563 29.5267L118.255 39.6631C118.164 39.8667 117.938 39.9572 117.734 39.8667L115.92 39.0975L112.292 47.6048L107.939 45.7495L111.59 37.2421L112.633 34.7985L116.26 26.3817L122.359 28.9837C122.563 29.0968 122.653 29.3231 122.563 29.5267Z", fill: "white" }),
    React.createElement("path", { d: "M107.282 35.4321L115.897 39.0975L114.786 41.6995L106.148 38.0794L107.282 35.4321Z", fill: "#919191" }),
    React.createElement("path", { d: "M158 114.6C157.932 158.155 122.496 193.406 78.8753 193.338C64.5694 193.315 51.1477 189.492 39.5851 182.84C39.0409 182.523 38.4968 182.206 37.9527 181.867C15.1449 167.997 -0.0452424 142.95 0.000101239 114.374C0.0681166 72.4028 32.9876 38.1246 74.4543 35.7715C75.9506 35.681 77.447 35.6357 78.966 35.6357H79.1474C80.7117 35.6357 82.2761 35.681 83.8404 35.7715C91.3221 36.224 98.5544 37.7173 105.333 40.093C106.807 40.6134 108.258 41.1791 109.686 41.7674C111.137 42.3783 112.566 43.0344 113.971 43.7132C115.422 44.4372 116.851 45.1839 118.234 45.9758C118.415 46.0663 118.574 46.1794 118.755 46.2699C142.243 60.0038 158.045 85.4806 158 114.6Z", fill: "#3290FF" }),
    React.createElement("path", { d: "M145.486 114.6C145.441 151.254 115.627 180.917 78.8988 180.849C66.8601 180.826 55.5695 177.613 45.8206 172.025C25.8694 160.553 12.4704 139.036 12.5157 114.396C12.5384 102.314 15.7805 91.0013 21.4484 81.2722C29.9957 66.5653 44.0295 55.4334 60.7613 50.682C66.588 49.0303 72.7547 48.1252 79.1255 48.1479C91.0962 48.1705 102.319 51.3381 112.022 56.8815C132.019 68.3075 145.509 89.8926 145.486 114.6Z", fill: "white" }),
    React.createElement("path", { d: "M80.6295 51.6347L77.5234 51.63L77.5052 63.667L80.6113 63.6716L80.6295 51.6347Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M64.2787 53.3285L61.2773 54.1263L64.3819 65.7577L67.3833 64.9598L64.2787 53.3285Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M48.9189 59.2474L46.2266 60.7932L52.2416 71.2269L54.9339 69.681L48.9189 59.2474Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M35.602 68.8974L33.4023 71.0858L41.9177 79.6104L44.1174 77.422L35.602 68.8974Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M25.2641 81.647L23.707 84.3292L34.1437 90.3633L35.7008 87.6811L25.2641 81.647Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M18.5663 96.6318L17.7578 99.6246L29.4031 102.758L30.2116 99.7651L18.5663 96.6318Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M15.993 112.847L15.9883 115.947L28.0497 115.965L28.0544 112.865L15.993 112.847Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M17.7181 129.156L18.5176 132.151L30.1725 129.053L29.373 126.057L17.7181 129.156Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M23.617 144.484L25.166 147.171L35.6208 141.168L34.0719 138.481L23.617 144.484Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M33.3013 157.779L35.4941 159.974L44.036 151.476L41.8432 149.281L33.3013 157.779Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M46.0604 168.093L48.748 169.647L54.7944 159.231L52.1068 157.677L46.0604 168.093Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M61.1125 174.779L64.1113 175.586L67.2509 163.964L64.252 163.158L61.1125 174.779Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M77.3471 177.353L80.4531 177.357L80.4713 165.32L77.3653 165.316L77.3471 177.353Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M93.6842 175.634L96.6855 174.836L93.581 163.204L90.5796 164.002L93.6842 175.634Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M109.042 169.735L111.734 168.189L105.719 157.756L103.027 159.302L109.042 169.735Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M122.363 160.086L124.562 157.897L116.047 149.373L113.847 151.561L122.363 160.086Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M132.711 147.354L134.268 144.672L123.831 138.638L122.274 141.32L132.711 147.354Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M139.397 132.335L140.205 129.342L128.56 126.209L127.751 129.202L139.397 132.335Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M141.966 116.123L141.971 113.023L129.909 113.005L129.905 116.105L141.966 116.123Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M140.251 99.8171L139.451 96.8218L127.796 99.9201L128.596 102.915L140.251 99.8171Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M134.36 84.4909L132.811 81.8041L122.356 87.8069L123.905 90.4938L134.36 84.4909Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M124.675 71.1938L122.482 68.9985L113.941 77.4966L116.133 79.6919L124.675 71.1938Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M111.92 60.8751L109.232 59.3212L103.186 69.7367L105.874 71.2906L111.92 60.8751Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M96.868 54.1888L93.8691 53.382L90.7296 65.0036L93.7284 65.8105L96.868 54.1888Z", fill: "#2B69AD" }),
    React.createElement("path", { d: "M79.6469 119.895C76.4729 119.962 73.8883 117.36 74.0016 114.193L78.8988 54.189L85.0428 113.944C85.2695 117.112 82.821 119.827 79.6469 119.895Z", fill: "#FF4817" }),
    React.createElement("path", { d: "M80.9736 117.695C82.6923 116.939 83.4717 114.936 82.7144 113.221C81.9571 111.505 79.9499 110.728 78.2312 111.483C76.5124 112.239 75.733 114.242 76.4903 115.957C77.2476 117.673 79.2548 118.451 80.9736 117.695Z", fill: "white" }),
    React.createElement("path", { d: "M79.7142 131.705C77.8098 131.728 76.2454 130.212 76.2227 128.289L76.1094 118.288L83.0243 118.22L83.1376 128.221C83.1603 130.121 81.6186 131.683 79.7142 131.705Z", fill: "#FF4817" }),
    React.createElement("path", { opacity: "0.27", d: "M157.998 114.786C157.93 158.341 122.494 193.592 78.8738 193.524C64.5679 193.501 51.1462 189.678 39.5835 183.026C39.0394 182.709 38.4953 182.392 37.9512 182.053L42.9616 173.659L44.8207 170.536L51.2368 159.766L76.0625 118.067L76.9693 116.551L79.69 111.958L81.277 109.288L105.4 68.8329L111.816 58.063L113.675 54.918L118.685 46.5238C142.241 60.1898 158.044 85.6666 157.998 114.786Z", fill: "white" })));
