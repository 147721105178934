import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSingleInsurancePrograms } from '../../../store/order/selectors';
import { Insurance } from '../../../../CheckoutAdditionalService/components';
const OrderInsuranceBlockSlot = () => {
    const withOutSingleInsurancePrograms = useSelector(getSingleInsurancePrograms(true));
    const singleInsurancePrograms = useSelector(getSingleInsurancePrograms());
    const singleInsuranceProgramsLists = useMemo(() => singleInsurancePrograms.map(p => [p]), [
        singleInsurancePrograms
    ]);
    return (React.createElement(React.Fragment, null,
        withOutSingleInsurancePrograms.length > 0 && (React.createElement(Insurance, { insurancePrograms: withOutSingleInsurancePrograms })),
        singleInsuranceProgramsLists.map((insurance, index) => (React.createElement(Insurance, { key: index, insurancePrograms: insurance, asSingle: true })))));
};
export default OrderInsuranceBlockSlot;
