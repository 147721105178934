import { CombinedDirection } from './types';
import { Currency } from '../enums';
import memes from '../memes';
import { compose } from 'redux';
import { isOccupiedByOtherPassenger } from './components/SeatMap/Seat/utils';
import { getTotalPrice as getTotalPriceBase, getUserValue } from '../utils';
import { AviaPassengerType, OrderAdditionalServiceGdsServiceServiceType, SeatMapRestrictions, TravellerFieldEnum } from '@websky/graphql';
export const getTabs = (segments, passengers, servicesMap) => {
    return segments.map((segment, index) => {
        let seatsCount = 0;
        const seatsPrice = {
            amount: 0,
            currency: null
        };
        passengers.forEach(passenger => {
            if (!passenger.seats) {
                return;
            }
            const seats = passenger.seats.filter(seat => seat.segmentId === segment.segmentInfo.id);
            seats.forEach(seat => {
                var _a, _b, _c, _d, _e, _f;
                seatsCount++;
                if (seat === null || seat === void 0 ? void 0 : seat.rfisc) {
                    seatsPrice.amount += (_d = (_c = (_b = (_a = servicesMap[seat.segmentId]) === null || _a === void 0 ? void 0 : _a[passenger.id]) === null || _b === void 0 ? void 0 : _b[seat.id]) === null || _c === void 0 ? void 0 : _c.price.amount) !== null && _d !== void 0 ? _d : 0;
                }
                else {
                    seatsPrice.amount += (_e = seat === null || seat === void 0 ? void 0 : seat.price) === null || _e === void 0 ? void 0 : _e.amount;
                }
                if (!seatsPrice.currency) {
                    seatsPrice.currency = (_f = seat === null || seat === void 0 ? void 0 : seat.price) === null || _f === void 0 ? void 0 : _f.currency;
                }
            });
        });
        return {
            value: index,
            isAvailable: segment.decks.length > 0,
            header: `${segment.segmentInfo.departure.airport.city.name} — ${segment.segmentInfo.arrival.airport.city.name}`,
            seatsCount: seatsCount,
            price: seatsCount > 0 ? seatsPrice : null
        };
    });
};
export const getSeatServicesForSegments = (segment) => {
    const uniqueServiceIdsSet = new Set();
    const uniqueServices = [];
    if (segment && segment.decks.length) {
        segment.decks[0].rows.forEach(row => {
            row.parts.forEach(part => {
                part.seats.forEach(seat => {
                    if (seat.seatService && !uniqueServiceIdsSet.has(seat.seatService.id)) {
                        const seatService = seat.service;
                        if (seatService) {
                            uniqueServices.push(seatService);
                            uniqueServiceIdsSet.add(seatService.id);
                        }
                    }
                });
            });
        });
        return uniqueServices;
    }
    return [];
};
const getSeatsOnOtherSegments = (traveller, segment) => {
    var _a;
    return ((_a = traveller.seats) === null || _a === void 0 ? void 0 : _a.filter(travellerSeat => travellerSeat.segmentId !== segment.segmentsInfo.id)) || [];
};
export const updateSeatServices = (passengers, segment, passengerId, seats) => {
    const services = [];
    const currentPassenger = passengers.find(passenger => passenger.id === passengerId);
    const otherSelectedSeats = getSeatsOnOtherSegments(currentPassenger, segment).map(seat => ({
        isConfirmed: seat.isConfirmed,
        segment: {
            id: seat.segmentId
        },
        letter: seat.letter,
        row: parseInt(seat.number, 10),
        seat
    }));
    seats.forEach(seat => {
        var _a, _b;
        const isSameSeat = ((_b = (_a = currentPassenger === null || currentPassenger === void 0 ? void 0 : currentPassenger.seats) === null || _a === void 0 ? void 0 : _a.find(selectedSeat => selectedSeat.segmentId === segment.segmentsInfo.id)) === null || _b === void 0 ? void 0 : _b.number) === seat.number;
        const selectedSeat = isSameSeat || !seat
            ? null
            : {
                isConfirmed: false,
                onRequest: false,
                isChangeable: true,
                segment: { id: segment.segmentsInfo.id },
                letter: seat.letter,
                row: parseInt(seat.number, 10),
                seat,
                product: null,
                type: null
            };
        if (selectedSeat) {
            const isSeatOccupiedByOtherPassenger = isOccupiedByOtherPassenger(seat, passengers, currentPassenger.id, segment.segmentsInfo.id);
            if (isSeatOccupiedByOtherPassenger) {
                const passengerWithThisSeat = passengers.find(passenger => {
                    var _a;
                    return (_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.some(passengerSeat => `${passengerSeat.row}${passengerSeat.letter}` === seat.number &&
                        passengerSeat.segmentId === segment.segmentsInfo.id);
                });
                const otherPassengerSeats = getSeatsOnOtherSegments(passengerWithThisSeat, segment);
                services.push({
                    passengerId: passengerWithThisSeat.id,
                    seats: [
                        ...otherPassengerSeats.map(seat => ({
                            isConfirmed: seat.isConfirmed,
                            segment: {
                                id: seat.segmentId
                            },
                            letter: seat.letter,
                            row: parseInt(seat.number, 10),
                            seat
                        }))
                    ]
                });
            }
        }
        services.push({
            passengerId: currentPassenger.id,
            seats: selectedSeat ? [...otherSelectedSeats, selectedSeat] : [...otherSelectedSeats]
        });
    });
    return services;
};
export const getSelectedServices = (traveller, segment, passenger, seat) => {
    var _a, _b;
    const seats = getSeatsOnOtherSegments(traveller, segment);
    const confirmedSeats = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.seats) === null || _a === void 0 ? void 0 : _a.filter(seat => seat.segmentId === segment.segmentsInfo.id && (seat === null || seat === void 0 ? void 0 : seat.isConfirmed))) !== null && _b !== void 0 ? _b : [];
    if (seat) {
        seats.push({
            segmentId: segment.segmentsInfo.id,
            row: seat.number,
            letter: seat.letter,
            price: seat.price,
            id: seat.seatService.id,
            type: OrderAdditionalServiceGdsServiceServiceType.Seat,
            rfisc: seat.rfisc,
            deckId: null
            // !fixme
            // isConfirmed: false
        });
    }
    return [...seats, ...confirmedSeats];
};
export const seatMapHasAnySeats = (seatMap) => {
    return seatMap && seatMap.segments.some(segment => segment.decks.length > 0);
};
export const getSeatPrice = (seat) => {
    var _a;
    if ((_a = seat.seatServices) === null || _a === void 0 ? void 0 : _a.length) {
        let seatServices = [...seat.seatServices];
        const hasServicesCanBeAdded = seatServices.some(service => service.canBeAdded);
        if (hasServicesCanBeAdded) {
            seatServices = seatServices.filter(service => service.canBeAdded);
        }
        return getTotalPriceBase(seatServices, service => service.price);
    }
    return seat.price;
};
export const getTotalPrice = (selectedSeatsMap) => {
    const totalPrice = {
        amount: 0,
        currency: Currency.RUB
    };
    selectedSeatsMap.forEach(segment => {
        segment.forEach(passenger => {
            if (passenger.seat) {
                totalPrice.amount += passenger.seat.price.amount;
                if (totalPrice.currency !== passenger.seat.price.currency) {
                    totalPrice.currency = passenger.seat.price.currency;
                }
            }
        });
    });
    return totalPrice;
};
export const segmentHasAvailableSeats = memes((segment, services) => {
    const segmentId = segment.segmentsInfo.id;
    const seatServices = services.gdsServices.services.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Seat);
    let hasAvailableSeats = false;
    for (const [, deck] of Object.entries(segment.decks)) {
        for (const [, row] of Object.entries(deck.rows)) {
            for (const [, seat] of Object.entries(row.seats)) {
                if (hasAvailableSeats) {
                    return true;
                }
                if (!seat.seatService) {
                    // free seat
                    hasAvailableSeats = seat.isAvailable && seat.isExistent && !seat.isAisle;
                    continue;
                }
                const relatedService = seatServices.find(service => service.id === seat.seatService.id);
                hasAvailableSeats =
                    seat.isAvailable &&
                        seat.isExistent &&
                        !seat.isAisle &&
                        relatedService.allowedSegments.some(allowedSegment => allowedSegment.includes(segmentId));
            }
        }
    }
    return hasAvailableSeats;
});
export const checkAllSeatsAreSelected = (passengers, segmentsCount) => {
    return passengers.every(passenger => {
        var _a;
        return passenger.type === AviaPassengerType.INF || ((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.length) >= segmentsCount;
    });
};
const getSeatServiceById = memes((services, id = null) => {
    if (!(services === null || services === void 0 ? void 0 : services.length)) {
        return null;
    }
    return services.find(service => service.id === id);
});
export const addServicesToSeats = memes((seatMap) => {
    return {
        additionalServices: seatMap.additionalServices,
        segments: seatMap.segments.map(segment => (Object.assign(Object.assign({}, segment), { decks: segment.decks.map(deck => (Object.assign(Object.assign({}, deck), { rows: deck.rows.map(row => (Object.assign(Object.assign({}, row), { seats: row.seats.map(seat => {
                        var _a, _b;
                        return (Object.assign(Object.assign({}, seat), { service: getSeatServiceById((_a = seatMap.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services, seat.seatService && seat.seatService.id), services: (_b = seat.seatServices) === null || _b === void 0 ? void 0 : _b.map(service => { var _a; return getSeatServiceById((_a = seatMap.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services, service.id); }) }));
                    }) }))) }))) })))
    };
});
export const isAisle = (seat) => {
    return !seat.isExistent && seat.isAisle;
};
export const splitRows = memes((rows) => {
    return rows.map((row, rowIndex) => {
        var _a, _b;
        const parts = [
            {
                seats: [],
                service: {
                    minPrice: ((_a = row.seats[0]) === null || _a === void 0 ? void 0 : _a.price) && getSeatPrice(row.seats[0]),
                    seatService: (_b = row.seats[0]) === null || _b === void 0 ? void 0 : _b.seatService
                }
            }
        ];
        let currentParts = parts[parts.length - 1];
        row.seats.forEach((seat, seatIndex) => {
            var _a, _b, _c;
            const prev = seatIndex > 0 ? row.seats[seatIndex - 1] : null;
            if ((prev && isAisle(prev)) || isAisle(seat)) {
                const length = parts.push({
                    seats: [seat],
                    service: {
                        isAisle: isAisle(seat),
                        seatService: seat.seatService,
                        minPrice: getSeatPrice(seat)
                    }
                });
                currentParts = parts[length - 1];
            }
            else {
                currentParts.seats.push(seat);
                const seatPrice = getSeatPrice(seat);
                if (!currentParts.service.seatService && seat.seatService) {
                    currentParts.service.seatService = seat.seatService;
                    currentParts.service.minPrice = seatPrice;
                }
                if (currentParts.service.minPrice.amount > seatPrice.amount &&
                    seat.isExistent &&
                    ((_a = seat.service) === null || _a === void 0 ? void 0 : _a.comfort) === ((_c = (_b = currentParts.service) === null || _b === void 0 ? void 0 : _b.seatService) === null || _c === void 0 ? void 0 : _c.comfort)) {
                    currentParts.service.minPrice = seatPrice;
                }
            }
        });
        return Object.assign(Object.assign({}, row), { parts });
    });
});
export const splitSeatMapRows = memes((seatMap) => {
    return {
        additionalServices: seatMap.additionalServices,
        segments: seatMap.segments.map(segment => (Object.assign(Object.assign({}, segment), { decks: segment.decks.map(deck => (Object.assign(Object.assign({}, deck), { rows: splitRows(deck.rows) }))) })))
    };
});
export const getSeatCombinedWith = (seats) => (seat, index) => {
    const combinedWith = {};
    const prevSeat = seats === null || seats === void 0 ? void 0 : seats[index - 1];
    const nextSeat = seats === null || seats === void 0 ? void 0 : seats[index + 1];
    const isPrevSeatAvailable = prevSeat && isSeatAvailableAndExistent(prevSeat);
    const isNextSeatAvailable = nextSeat && isSeatAvailableAndExistent(nextSeat);
    if (seat.isAvailable && isPrevSeatAvailable) {
        combinedWith[prevSeat.number] = CombinedDirection.Right;
    }
    if (seat.isAvailable && isNextSeatAvailable) {
        combinedWith[nextSeat.number] = CombinedDirection.Left;
    }
    return Object.assign(Object.assign({}, seat), { combinedWith });
};
export const doubleSeatsAdapter = (segments) => {
    return segments.map(segment => (Object.assign(Object.assign({}, segment), { decks: segment.decks.map(deck => (Object.assign(Object.assign({}, deck), { rows: deck.rows.map(row => {
                var _a;
                return (Object.assign(Object.assign({}, row), { seats: (_a = row.seats) === null || _a === void 0 ? void 0 : _a.map(getSeatCombinedWith(row.seats)), parts: row.parts.map(part => {
                        var _a;
                        return (Object.assign(Object.assign({}, part), { seats: (_a = part.seats) === null || _a === void 0 ? void 0 : _a.map(getSeatCombinedWith(part.seats)) }));
                    }) }));
            }) }))) })));
};
export const isOccupiedSeat = (seat, selectedSeats) => {
    const selectedSeatsByNumber = selectedSeats.get(seat.number);
    if (!(selectedSeatsByNumber === null || selectedSeatsByNumber === void 0 ? void 0 : selectedSeatsByNumber.length)) {
        return false;
    }
    return !!selectedSeatsByNumber.find(selectedSeat => selectedSeat.rfisc === seat.rfisc && selectedSeat.number === seat.number && selectedSeat.row === seat.row);
};
export const getAvailabilitySeat = (passenger, passengers, selectedSeats, isNewOrder, hasDoubleSeats) => (seat) => {
    var _a, _b, _c, _d;
    let isAvailable = seat.isAvailable && seat.isExistent;
    if (isAvailable && ((_a = seat.restrictions) === null || _a === void 0 ? void 0 : _a.length) && ((_b = passenger.seatRestrictions) === null || _b === void 0 ? void 0 : _b.length)) {
        // Check if at least one of the passenger restrictions coincides with the seat restriction
        const hasMatchingSeatRestriction = seat.restrictions.some(restriction => passenger.seatRestrictions.includes(restriction));
        if (hasMatchingSeatRestriction) {
            isAvailable = false;
        }
    }
    if (isAvailable && ((_c = seat.restrictions) === null || _c === void 0 ? void 0 : _c.length)) {
        if (seat.restrictions.includes(SeatMapRestrictions.Infant)) {
            // check that the passenger with infantPassengerId is a real infant
            if (passenger.type === AviaPassengerType.ADT && !!passenger.infantPassengerId) {
                const passengerByInfantId = passengers.find(pass => pass.id === passenger.infantPassengerId);
                if ((passengerByInfantId === null || passengerByInfantId === void 0 ? void 0 : passengerByInfantId.type) === AviaPassengerType.INF) {
                    isAvailable = false;
                }
            }
        }
    }
    // in a booked order with selected double seats, we make all seats inaccessible, except for the selected ones
    if (isAvailable && !isNewOrder) {
        isAvailable = !hasDoubleSeats && !isOccupiedSeat(seat, selectedSeats);
    }
    // if the seat is unavailable, but still selected, check if it is a double seat, so that the passenger can remove it
    if (!isAvailable && !hasDoubleSeats && isOccupiedSeat(seat, selectedSeats)) {
        const isDoubleSeat = ((_d = selectedSeats.get(seat.number)) === null || _d === void 0 ? void 0 : _d.length) > 1;
        isAvailable = !isDoubleSeat;
    }
    return Object.assign(Object.assign({}, seat), { isAvailable });
};
export const getSegments = (seatMap, passenger, passengers, selectedSeats, amenities, isNewOrder) => {
    var _a;
    const passengerSeats = ((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.length) ? passenger.seats : [];
    const selectedSeatsBySegment = getSelectedSeatsBySegment(passengerSeats);
    return doubleSeatsAdapter(seatMap.segments.map(segment => {
        var _a;
        const hasDoubleSeats = ((_a = selectedSeatsBySegment.get(segment.segmentsInfo.id)) === null || _a === void 0 ? void 0 : _a.length) > 1;
        return {
            isSeatsAvailableInCheckinOnly: segment.isSeatsAvailableInCheckinOnly,
            services: getSeatServicesForSegments(segment),
            sunSide: segment.sunSide,
            segmentInfo: segment.segmentsInfo,
            amenities: amenities,
            decks: segment.decks.map(deck => (Object.assign(Object.assign({}, deck), { rows: deck.rows.map(row => {
                    var _a, _b;
                    return (Object.assign(Object.assign({}, row), { seats: (_a = row.seats) === null || _a === void 0 ? void 0 : _a.map(getAvailabilitySeat(passenger, passengers, selectedSeats, isNewOrder, hasDoubleSeats)), parts: (_b = row.parts) === null || _b === void 0 ? void 0 : _b.map(part => {
                            var _a;
                            return (Object.assign(Object.assign({}, part), { seats: (_a = part.seats) === null || _a === void 0 ? void 0 : _a.map(getAvailabilitySeat(passenger, passengers, selectedSeats, isNewOrder, hasDoubleSeats)) }));
                        }) }));
                }) })))
        };
    }));
};
export const isSeatAvailableAndExistent = (seat) => {
    return seat.isAvailable && seat.isExistent && !seat.isAisle;
};
export const seatMapAdapter = compose(splitSeatMapRows, addServicesToSeats);
export const getOppositeDirection = (direction) => direction === CombinedDirection.Left ? CombinedDirection.Right : CombinedDirection.Left;
export const getCombinedNumber = (seat, combinedWith) => {
    if (!combinedWith) {
        return null;
    }
    return Object.keys(combinedWith).filter(key => key !== seat.number)[0];
};
export const getPassengerLabel = (passenger, t, showNumber = false) => {
    const passengersType = {
        [AviaPassengerType.ADT]: 'Adult',
        [AviaPassengerType.CLD]: 'Child',
        [AviaPassengerType.INF]: 'Infant',
        [AviaPassengerType.INS]: 'Infant with seat'
    };
    const firstName = getUserValue(passenger, TravellerFieldEnum.FirstName);
    const lastName = getUserValue(passenger, TravellerFieldEnum.LastName);
    const fullName = [firstName || '', lastName || ''].filter(Boolean);
    if (fullName.length) {
        return fullName.join(' ');
    }
    const passengerNumber = showNumber ? ` ${(+passenger.id + 1).toString()}` : '';
    return `${t(passengersType[passenger.type])}${passengerNumber}`;
};
export const getSelectedSeatsBySegment = (passengerSeats = []) => {
    const selectedSeatsBySegmentsMap = new Map();
    passengerSeats.forEach(seat => selectedSeatsBySegmentsMap.set(seat.segmentId, [
        ...(selectedSeatsBySegmentsMap.get(seat.segmentId) || []),
        seat
    ]));
    return selectedSeatsBySegmentsMap;
};
export const getSeatsWithDoubleSeats = (passengerSeats = []) => {
    const seatsWithDoubleSeatsMap = new Map();
    const selectedSeatsBySegments = getSelectedSeatsBySegment(passengerSeats);
    selectedSeatsBySegments.forEach(seats => {
        if (seats.length > 1) {
            const seatNumbers = seats.map(seat => seat.number);
            seats.forEach(seat => {
                seatsWithDoubleSeatsMap.set(seat.number, seatNumbers);
            });
        }
    });
    return seatsWithDoubleSeatsMap;
};
export const isZeroPrice = (money) => {
    return (money === null || money === void 0 ? void 0 : money.amount) === 0;
};
