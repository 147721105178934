import { __awaiter } from "tslib";
import { useState } from 'react';
import { useUpdateCheckinOrderMutation } from '@websky/graphql';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getCheckinOrder } from '../../../../store/order/selectors';
import { getUnconfirmedServices } from './utils';
import { fillCheckinOrder } from '../../../../store/order/actions';
import { UnconfirmedSeatsError } from '../../CheckinErrorBoundary/CheckinErrors';
import { captureErrorSentry } from '../../../../../../utils';
import { removeService, saveServices } from '../../../../store/selectedServices/actions';
import { getIsServicesSaving } from '../../../../store/servicesLoading/selectors';
const pollingIntervalInMs = 1000;
const pollingCountThreshold = 10;
export const useUnconfirmedServicesModal = () => {
    const order = useSelector(getCheckinOrder);
    const isServicesSaving = useSelector(getIsServicesSaving);
    const dispatch = useDispatch();
    const [unconfirmedServices, setUnconfirmedServices] = useState();
    const [updateCheckinOrder] = useUpdateCheckinOrderMutation({
        variables: {
            id: order.id
        }
    });
    const polling = (callback, attempt) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (attempt === pollingCountThreshold) {
            const error = new UnconfirmedSeatsError(`updateCheckinOrder polling reach ${pollingCountThreshold} attempts`);
            captureErrorSentry(`${error.name}: ${error.message}`);
        }
        try {
            const result = yield updateCheckinOrder();
            const updatedCheckinOrder = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.UpdateCheckinOrder;
            if (updatedCheckinOrder) {
                const hasUnconfirmedProduct = getUnconfirmedServices(updatedCheckinOrder).size > 0;
                if (hasUnconfirmedProduct) {
                    setTimeout(() => polling(callback, attempt + 1), pollingIntervalInMs);
                }
                else {
                    dispatch(fillCheckinOrder(updatedCheckinOrder));
                    setUnconfirmedServices(null);
                    callback();
                }
            }
        }
        catch (error) {
            console.error(error);
            setTimeout(() => polling(callback, attempt + 1), pollingIntervalInMs);
        }
    });
    const startPolling = (callback) => {
        setTimeout(() => polling(callback, 1), pollingIntervalInMs);
    };
    const removeServices = (services, callback) => {
        batch(() => {
            services.forEach(service => {
                dispatch(removeService(service));
            });
            dispatch(saveServices(undefined, {
                resolve: callback
            }));
        });
    };
    return {
        startPolling,
        unconfirmedServices,
        setUnconfirmedServices,
        removeServices,
        isServicesSaving
    };
};
