import * as React from 'react';
import { Form } from 'react-final-form';
import Fields from './Fields/Fields';
import { useTheme } from '../../../../../theme';
import Link from '../../../../../Link';
import Resend from '../../../../../Contacts/components/Resend/Resend';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../../../context';
import { useFfpNewFfpPasswordMutation, useFfpResetPasswordCodeMutation } from '@websky/graphql';
import { BaseButton } from '../../../../../BaseComponents';
export var ForgotPasswordState;
(function (ForgotPasswordState) {
    ForgotPasswordState[ForgotPasswordState["Email"] = 1] = "Email";
    ForgotPasswordState[ForgotPasswordState["Code"] = 2] = "Code";
    ForgotPasswordState[ForgotPasswordState["Success"] = 3] = "Success";
})(ForgotPasswordState || (ForgotPasswordState = {}));
const successIcon = (React.createElement("svg", { width: "36", height: "36", viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { width: "36", height: "36", rx: "18", fill: "#5FCA43" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.5535 22.7305L27.8743 12.4097L26.1066 10.6419L15.7858 20.9627L10.7357 15.9126L8.9679 17.6804L14.018 22.7305L14.0179 22.7306L15.7856 24.4984L15.7858 24.4982L15.7862 24.4987L17.554 22.7309L17.5535 22.7305Z", fill: "white" })));
const errorIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12ZM13 13V7.00001H11V13H13ZM12 4.00001C7.58999 4.00001 3.99999 7.59001 3.99999 12C3.99999 16.41 7.58999 20 12 20C16.41 20 20 16.41 20 12C20 7.59001 16.41 4.00001 12 4.00001ZM13 17V15H11V17H13Z", fill: "#B31B1B" })));
const ForgotPassword = props => {
    const [state, setState] = React.useState(ForgotPasswordState.Email);
    const [error, setError] = React.useState('');
    const [verifiedEmail, setEmail] = React.useState('');
    const [codeSent, setCodeSended] = React.useState(false);
    const iataCode = useConfig().global.companyInfo.iataCode;
    const [resetPassword, { loading: resetPasswordLoading }] = useFfpResetPasswordCodeMutation();
    const [sendCode] = useFfpNewFfpPasswordMutation();
    const { t } = useTranslation('Loyalty');
    const onSubmit = (values) => {
        setError('');
        if (state === ForgotPasswordState.Email) {
            return sendCodeRequest(values['email'])
                .then(data => {
                var _a;
                if ((_a = data.data) === null || _a === void 0 ? void 0 : _a.SendResetFfpPasswordCode) {
                    setEmail(values['email']);
                    setState(ForgotPasswordState.Code);
                    setCodeSended(true);
                    return undefined;
                }
                else {
                    return t('Profile not found. Check spelling your e-mail address');
                }
            })
                .catch(e => {
                setError(t('Profile not found. Check spelling your e-mail address'));
            });
        }
        else if (state === ForgotPasswordState.Code) {
            return sendCode({
                variables: {
                    cardNumber: values['cardNumber'],
                    airlineCode: iataCode,
                    code: values['code']
                }
            })
                .then(data => {
                var _a;
                if ((_a = data.data) === null || _a === void 0 ? void 0 : _a.SendNewFfpPassword) {
                    setState(ForgotPasswordState.Success);
                    setTimeout(() => {
                        var _a;
                        setState(ForgotPasswordState.Email);
                        (_a = props.toLogin) === null || _a === void 0 ? void 0 : _a.call(props);
                    }, 2000);
                    return undefined;
                }
                else {
                    return t('You entered an incorrect code');
                }
            })
                .catch(e => {
                setError(t('You entered an incorrect code'));
            });
        }
        return undefined;
    };
    const initialValues = React.useMemo(() => {
        if (state === ForgotPasswordState.Email) {
            return {
                email: props.email
            };
        }
        else {
            return {
                code: '',
                cardNumber: ''
            };
        }
    }, [state]);
    const sendCodeRequest = (email) => {
        return resetPassword({
            variables: {
                email: email,
                airlineCode: iataCode
            }
        });
    };
    const theme = useTheme('Loyalty').ForgotPassword;
    const getHeader = () => {
        if (state === ForgotPasswordState.Email) {
            return t('Forgot password?');
        }
        else if (state === ForgotPasswordState.Code) {
            return t('Enter code');
        }
        else {
            return t('Password reset');
        }
    };
    const renderInner = () => {
        return (React.createElement("div", { className: theme.wrapper },
            React.createElement("div", { className: theme.header }, getHeader()),
            state === ForgotPasswordState.Success && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: theme.success },
                    successIcon,
                    t('A new password has been sent to your e-mail')),
                React.createElement("div", { className: theme.controls },
                    React.createElement(Link, { action: () => {
                            var _a;
                            setState(ForgotPasswordState.Email);
                            (_a = props.toLogin) === null || _a === void 0 ? void 0 : _a.call(props);
                        } }, t('Back to login'))))),
            React.createElement(Form, { onSubmit: onSubmit, initialValues: initialValues }, ({ submitError, submitting, handleSubmit, valid }) => {
                return (React.createElement("form", { onSubmit: handleSubmit }, state !== ForgotPasswordState.Success && (React.createElement("div", null,
                    (submitError || error) && (React.createElement("div", { className: theme.error },
                        errorIcon,
                        " ",
                        submitError || error)),
                    React.createElement("div", { className: theme.step },
                        React.createElement("span", null,
                            t('Step'),
                            " ",
                            state,
                            "/2",
                            state === ForgotPasswordState.Code && ': '),
                        state === ForgotPasswordState.Code &&
                            t('The verification code has been sent to the email')),
                    React.createElement(Fields, { state: state }),
                    React.createElement("div", { className: theme.controls },
                        React.createElement("div", { className: theme.controls__left },
                            React.createElement(Link, { action: () => {
                                    var _a;
                                    setState(ForgotPasswordState.Email);
                                    (_a = props.toLogin) === null || _a === void 0 ? void 0 : _a.call(props);
                                } }, t('Back')),
                            state === ForgotPasswordState.Code && (React.createElement(Resend, { codeSent: codeSent, codeSending: resetPasswordLoading, sendCode: () => {
                                    setCodeSended(false);
                                    sendCodeRequest(verifiedEmail).then(data => {
                                        setCodeSended(true);
                                        return data;
                                    });
                                }, className: theme.resend, labelClassName: theme.resend__label }))),
                        React.createElement(BaseButton, { type: "submit", isLoading: submitting }, t('Continue')))))));
            })));
    };
    return renderInner();
};
export default ForgotPassword;
