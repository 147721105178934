import { BaggageType } from '@websky/graphql';
import * as React from 'react';
import { BaggageCabin, CabinUpsize, CheckedBaggage, MusicalInstrument, Overweight, PetInCabin, PetInHold, SportEquipment } from '../../Icons';
const BaggageIcon = React.memo(({ baggageType }) => {
    switch (baggageType) {
        case BaggageType.CarryOn:
            return BaggageCabin;
        case BaggageType.CheckedBaggage:
            return CheckedBaggage;
        case BaggageType.BaggageExcess:
            return Overweight;
        case BaggageType.HandLuggageExcess:
            return CabinUpsize;
        case BaggageType.PetInCabin:
            return PetInCabin;
        case BaggageType.PetInHold:
        case BaggageType.PetInHandCarrier:
            return PetInHold;
        case BaggageType.SportingEquipment:
            return SportEquipment;
        case BaggageType.SurfingEquipment:
        case BaggageType.Ski:
        case BaggageType.FishingEquipment:
        case BaggageType.Bike:
            return SportEquipment;
        case BaggageType.MusicalInstrument:
            return MusicalInstrument;
        default:
            return null;
    }
});
export default BaggageIcon;
