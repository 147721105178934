import React from 'react';
import { NotificationCard } from '../../NotificationCard/NotificationCard';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { format } from '../../../../utils';
import { Group } from '../../NotificationCard/Group/Group';
import { Info } from '../../NotificationCard/Info/Info';
import { RouteType } from '@websky/graphql';
import { NOTIFICATION_DATE_FORMAT } from '../../../constants';
import { fromUTCToLocalTimeZone } from '../../../utils';
import { useConfig } from '../../../../context';
import { Button } from '../../../../index';
export const OrderCard = props => {
    const { OrderCard: css } = useTheme('Communications');
    const { t } = useTranslation('Communications');
    const { Account: { engineURL } } = useConfig();
    const passengerList = () => {
        return props.passengers
            .map(passenger => {
            return `${passenger.firstName} ${passenger.lastName}`;
        })
            .join(', ');
    };
    const sendingDate = fromUTCToLocalTimeZone(props.sendingDate);
    let routeDate = '';
    if (props.routeType === RouteType.OW) {
        routeDate = format(props.arrivalDate, 'dd MMM Y');
    }
    else {
        routeDate = `${format(props.departureDate, 'dd')} — ${format(props.arrivalDate, 'dd MMM Y')}`;
    }
    const onClick = (orderId) => (e) => {
        e.preventDefault();
        window.location.href = `${engineURL}#/booking/${orderId}`;
    };
    return (React.createElement(NotificationCard, { isRead: props.isRead, title: props.title, date: format(sendingDate, NOTIFICATION_DATE_FORMAT), markAsRead: props.markAsRead, preDescRender: () => (React.createElement(Group, { className: css.orderGroup },
            React.createElement(Info, { className: css.destination },
                routeDate,
                React.createElement("span", null, "|"),
                props.departureAirport,
                " \u2014 ",
                props.arrivalAirport,
                " ",
                React.createElement("span", null, "|"),
                passengerList()),
            React.createElement(Button, { variant: "text", className: css.locator, onClick: onClick(props.orderId) },
                React.createElement(Info, null,
                    t('Order'),
                    ": ",
                    React.createElement("span", null, props.locator))))) },
        React.createElement("p", null, props.text)));
};
