import * as React from 'react';
import VipServiceSelect from '../../../VipServiceSelect';
import CheckinBusinessLoungeAdapter from './CheckinBusinessLoungeAdapter';
import { useToggleable } from '../../../hooks';
import Modal from '../../../Modal';
import BusinessAdditionalService from './components/BusinessAdditionalService';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { SlideBottom } from '../../../index';
import { useTheme } from '../../../theme';
const Business = () => {
    const { open, isOpen, close } = useToggleable(false);
    const { Business: theme } = useTheme('AdditionalServices');
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckinBusinessLoungeAdapter, { onClose: close }, props => (React.createElement(React.Fragment, null,
            React.createElement(BusinessAdditionalService, Object.assign({}, props, { onOpen: open })),
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement(Modal, { open: isOpen, onClose: props.onClose, maxWidth: 'sm' },
                    React.createElement(VipServiceSelect, Object.assign({}, props, { headerClassName: theme.header })))),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement(SlideBottom, { isOpen: isOpen, onClose: props.onClose },
                    React.createElement(VipServiceSelect, Object.assign({}, props)))))))));
};
export default Business;
