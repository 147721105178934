import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme, ThemeProvider } from '../../../theme';
import PetPopup from '../PetPopup/PetPopup';
import Modal from '../../../Modal';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { getAnimalServices, getSegments, getTravellers, getIsConfirmed, getOrder } from '../../../Checkout/store/order/selectors';
import { useAnimalService } from '../hooks';
import { isServicesSaving } from '../../../Checkout/store/servicesIsSaving/selectors';
import { useRenderers } from '../../../renderProps';
import { WxEvents } from '../../../DataLayerAnalytics/types';
import { getTravellersMap } from '../../../utils';
import BaggageSummary from '../../../ServiceSummary/services/BaggageSummary';
import { useCart } from '../../../Checkout/components/Checkout/Stepbar/hooks';
import { useIsCanModifyServices } from '../../hooks';
import { useToggleable } from '../../../hooks';
import Close from '../Close/Close';
import WebskyAnalyticsApollo from '../../../DataLayerAnalytics/WebskyAnalyticsApollo';
const Animal = () => {
    var _a;
    const { Animal: css } = useTheme('CheckoutAdditionalService');
    const { t, i18n } = useTranslation('Checkout');
    const order = useSelector(getOrder);
    const segments = useSelector(getSegments);
    const services = useSelector(getAnimalServices);
    const travellers = useSelector(getTravellers);
    const isOrderConfirmed = useSelector(getIsConfirmed);
    const isOrderServicesSaving = useSelector(isServicesSaving);
    const { isOpen, open, close } = useToggleable(false);
    const { servicesByRfisc, minPrice, totalPrice, onSave, onClear } = useAnimalService(services, travellers);
    const { onDeleteItem } = useCart();
    useEffect(() => {
        if (!isOrderServicesSaving) {
            close();
        }
    }, [isOrderServicesSaving]);
    const isCanModifyServices = useIsCanModifyServices(services);
    React.useEffect(() => {
        if (isOpen) {
            WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.PetsList);
        }
    }, [isOpen]);
    const travellersServices = useMemo(() => {
        const travellersMap = new Map();
        const servicesIds = services.map(service => service.id);
        travellers.forEach(traveller => {
            var _a, _b, _c;
            const travellerServices = ((_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.filter(service => servicesIds.includes(service.serviceId))) ||
                [];
            if (travellerServices.length) {
                const segmentsIds = travellerServices.reduce((acc, service) => [...acc, ...service.segmentIds], []);
                const allConfirmed = segmentsIds.length > 1 && travellerServices.some(service => service.confirmedCount > 0);
                const isAllFlights = segmentsIds.length === segments.length;
                travellerServices.forEach(travellerService => {
                    const ids = !allConfirmed && isOrderConfirmed
                        ? segmentsIds.filter(id => id === travellerService.segmentIds[0])
                        : segmentsIds;
                    const id = `${traveller.id}-${ids.join('-')}${allConfirmed ? `-confirmed` : ''}`;
                    const services = travellerServices.filter(service => service.serviceId === travellerService.serviceId &&
                        service.confirmedCount === travellerService.confirmedCount);
                    travellersMap.set(id, Object.assign(Object.assign({}, traveller), { travellerServices: services, segmentsIds: ids, isAllFlights }));
                });
            }
        });
        return [...travellersMap.values()];
    }, [travellers, segments, isOrderConfirmed]);
    const { CheckoutAdditionalService: CustomCheckoutAdditionalService } = useRenderers();
    const ServiceWrapper = (_a = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _a !== void 0 ? _a : CheckoutAdditionalService;
    return (React.createElement(React.Fragment, null,
        React.createElement(BaggageSummary, { flight: order.flight, travellers: travellers, travellersById: getTravellersMap(order.travellers), services: order.additionalServices, onServiceDelete: onDeleteItem, isAnimalBaggage: true }, content => (React.createElement(ServiceWrapper, { className: css.container, header: t('Animal'), description: i18n.exists('Checkout:Take your pet in a trip') ? t('Checkout:Take your pet in a trip') : '', priceFrom: minPrice, priceTotal: totalPrice, buttons: [
                {
                    text: isCanModifyServices ? t('Change') : t('More details'),
                    action: open,
                    buttonType: 'change'
                }
            ], isSelected: !!travellersServices.length, isLoading: isOrderServicesSaving, addClick: open, addButtonText: !isCanModifyServices ? t('More details') : null },
            React.createElement(ThemeProvider, { value: {
                    ServiceSummary: {
                        ServiceSummaryOption: {
                            option_icon: css.option_icon,
                            option__icon: css.option__icon,
                            option__name: css.option__name
                        }
                    }
                } }, content)))),
        React.createElement(Modal, { maxWidth: "sm", classes: { root: css.modal__root }, open: isOpen, onClose: close, closeButtonRenderer: onClick => React.createElement(Close, { onClick: onClick }) },
            React.createElement(PetPopup, { segments: segments, services: servicesByRfisc, travellers: travellers, isLoading: isOrderServicesSaving, onConfirm: onSave, onClear: onClear, onClose: close, readOnly: !isCanModifyServices }))));
};
export default Animal;
