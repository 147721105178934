import * as React from 'react';
import Modal from '../../../Modal';
import { useTheme } from '../../../theme';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { WarningRounded } from '../../../Icons';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { BaseButton } from '../../../index';
export const InsuranceUpsaleModal = ({ insurance, onAgree, onDisagree, isOpen, isLoading }) => {
    const { InsuranceUpsaleModal: theme } = useTheme('InsurancePage');
    const { t } = useTranslation('InsurancePage');
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const tryTranslateWithProductKey = (insuranceProgram, key, defaultValue) => {
        return defaultValue
            ? t([`${insuranceProgram === null || insuranceProgram === void 0 ? void 0 : insuranceProgram.code}_${key}`, key], { defaultValue: defaultValue })
            : t([`${insuranceProgram === null || insuranceProgram === void 0 ? void 0 : insuranceProgram.code}_${key}`, key]);
    };
    return (React.createElement(Modal, { bottomDrawer: isMobile, open: isOpen, classes: {
            paper: theme.root
        } },
        React.createElement("span", { className: theme.iconWrapper }, WarningRounded),
        React.createElement("h3", { className: theme.header, dangerouslySetInnerHTML: {
                __html: tryTranslateWithProductKey(insurance, 'You lose protection and peace of mind in unforeseen situations.')
            } }),
        React.createElement("div", { className: theme.listWrapper },
            React.createElement("div", { className: cn(theme.listContent, theme.listContent_main), dangerouslySetInnerHTML: {
                    __html: tryTranslateWithProductKey(insurance, 'insuranceUpsaleProductDescription', insurance === null || insurance === void 0 ? void 0 : insurance.description)
                } }),
            React.createElement("div", { className: theme.listContent, dangerouslySetInnerHTML: {
                    __html: tryTranslateWithProductKey(insurance, 'InsuranceUpsaleAdditionalDescription')
                } })),
        React.createElement(BaseButton, { className: theme.button, variant: "action", isLoading: isLoading, onClick: onAgree }, tryTranslateWithProductKey(insurance, 'Buy insurance')),
        React.createElement(BaseButton, { className: theme.button, variant: "secondary", disabled: isLoading, onClick: onDisagree }, tryTranslateWithProductKey(insurance, 'I assume all risks and Cancel insurance'))));
};
