import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Money } from '../../../Money';
import { getPassengerTypeLabel } from '../../../utils';
const PassengerPrices = ({ prices, isDifferentPrices }) => {
    const { t } = useTranslation('Checkout');
    return (React.createElement(React.Fragment, null, prices.map((passenger, index) => (React.createElement("span", { key: index },
        getPassengerTypeLabel(passenger.type, passenger.supplierType),
        ": ",
        isDifferentPrices && t('from'),
        React.createElement(Money, { money: passenger.price }))))));
};
export default PassengerPrices;
