import { __awaiter } from "tslib";
import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { setBreakdownPrices } from '../../../store/priceBreakdown/action';
import { debounce } from '../../../../utils';
import { getOrderId, getSegmentsForServices, getServices, getIsBookedOrConfirmed } from '../../../store/order/selectors';
import { fillOrder } from '../../../store/order/actions';
import { setOrderPrice } from '../../../store/orderPrice/actions';
import { getBaggagePriceToPay, getMealsPriceToPay, getSeatsPriceToPay } from '../../../store/orderPrice/selectors';
import { getPassengerStateCompatible } from '../../../store/selectedServices/selectors';
import { addServiceAction, deleteAllTypesOnSegment, removeServiceAction, setServiceAction, setMultipleSelectedServices as setMultipleSelectedServicesAction } from '../../../store/selectedServices/actions';
import { ShowLoaderAfterBookingContext } from './ServicePageCtx';
import { useOrderPriceQueryQuery, useSaveOrderServicesMutation, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
const ServicePage = ({ orderId, services, segments, passengers, children, mealTotalPrice, baggageTotalPrice, seatsTotalPrice, addService, removeService, setService, deleteAllTypesOnSegment, setMultipleSelectedServices }) => {
    const dispatch = useDispatch();
    const isOrderBookedOrConfirmed = useSelector(getIsBookedOrConfirmed);
    const [serviceRequest, { data, loading }] = useSaveOrderServicesMutation();
    const orderPriceRequest = useOrderPriceQueryQuery({ skip: true });
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.SaveOrderServices) {
            dispatch(fillOrder(data.SaveOrderServices));
        }
    }, [data]);
    const selectedMeals = [];
    passengers.forEach(passenger => {
        if (passenger.services) {
            passenger.services.forEach(product => {
                const serviceObject = services.find(({ id }) => id === product.serviceId);
                if (serviceObject && serviceObject.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
                    selectedMeals.push({
                        passengerId: passenger.id,
                        segmentId: product.segmentIds[0],
                        mealId: product.serviceId,
                        count: product.count,
                        confirmedCount: product.confirmedCount
                    });
                }
            });
        }
    });
    const memoizedServiceRequest = React.useMemo(() => debounce(serviceRequest, 500), [orderId]);
    // #fixme: use `TravellerSelectedServices`
    const runServiceRequest = useCallback((passengersServices, onSuccess) => __awaiter(void 0, void 0, void 0, function* () {
        const services = passengersServices.map(passengerServices => {
            // #fixme: сохраняются лишние допы.
            const otherSelectedPassengerServices = passengers[parseInt(passengerServices.passengerId)].services.filter(selectedService => passengerServices.setServices &&
                !passengerServices.setServices.some(service => service.serviceId === selectedService.serviceId));
            /*if (passengerServices.setServices) {
                setPassengerServices(parseInt(passengerServices.passengerId), [
                    ...otherSelectedPassengerServices,
                    ...passengerServices.setServices
                ]);
            }

            if (passengerServices.seats !== undefined && setPassengerSeats) {
                setPassengerSeats(parseInt(passengerServices.passengerId), passengerServices.seats);
            }*/
            return {
                travellerId: passengerServices.passengerId,
                gdsServices: passengerServices.setServices
                    ? {
                        setServices: [...otherSelectedPassengerServices, ...passengerServices.setServices].map(service => ({
                            count: service.count,
                            segmentIds: service.segmentIds,
                            serviceId: service.serviceId
                        }))
                    }
                    : null,
                seats: passengerServices.seats !== undefined
                    ? passengerServices.seats.map(seat => ({
                        segmentId: seat.segment.id,
                        letter: seat.letter,
                        row: seat.row
                    }))
                    : null
            };
        });
        if (!isOrderBookedOrConfirmed) {
            orderPriceRequest.refetch({ params: { id: orderId, services } }).then(results => {
                var _a;
                if ((_a = results === null || results === void 0 ? void 0 : results.data) === null || _a === void 0 ? void 0 : _a.OrderPrice) {
                    dispatch(setOrderPrice(results.data.OrderPrice));
                }
            });
        }
        // Unfortunately, `memoizedServiceRequest` doesn't return Promise, so we can't use it here.
        if (onSuccess) {
            const response = yield serviceRequest({
                variables: {
                    params: {
                        id: orderId,
                        services: services
                    }
                }
            });
            if (response && response.errors) {
                throw response.errors;
            }
            else if (response && response.data.SaveOrderServices) {
                dispatch(fillOrder(response.data.SaveOrderServices));
                onSuccess(response.data.SaveOrderServices);
            }
        }
        else {
            // #fixme: this is thing should be refactored
            memoizedServiceRequest({
                variables: {
                    params: {
                        id: orderId,
                        services: services
                    }
                }
            });
        }
    }), [passengers, orderId]);
    return (React.createElement(ShowLoaderAfterBookingContext.Provider, { value: loading && isOrderBookedOrConfirmed }, children({
        showLoader: loading && isOrderBookedOrConfirmed,
        runServiceRequest,
        segments,
        selectedMeals,
        mealTotalPrice,
        seatsTotalPrice,
        baggageTotalPrice,
        addService,
        removeService,
        setService,
        deleteAllTypesOnSegment,
        setMultipleSelectedServices
    })));
};
const mapStateToProps = () => {
    return (state, ownProps) => (Object.assign(Object.assign({}, ownProps), { orderId: getOrderId(state), segments: getSegmentsForServices(state), passengers: getPassengerStateCompatible(state), services: getServices(state), mealTotalPrice: getMealsPriceToPay(state), seatsTotalPrice: getSeatsPriceToPay(state), baggageTotalPrice: getBaggagePriceToPay(state) }));
};
const mapDispatchToProps = {
    setBreakdownPrices,
    addService: addServiceAction,
    removeService: removeServiceAction,
    setService: setServiceAction,
    deleteAllTypesOnSegment,
    setMultipleSelectedServices: setMultipleSelectedServicesAction
};
export default connect(mapStateToProps, mapDispatchToProps)(ServicePage);
