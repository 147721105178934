import * as React from 'react';
import { useSelector } from 'react-redux';
import { getRefundableNasServices } from '../../../store/selectors';
import NasRefundServices from '../../../../../NasRefundServices/NasRefundServices';
import { getOrder, getPassengers } from '../../../store/order/selectors';
import { useTheme } from '../../../../../theme';
const AirportServices = props => {
    const theme = useTheme('Exchange').AirportServices;
    const nasServicesToRefund = useSelector(getRefundableNasServices), order = useSelector(getOrder), travellersToRefund = useSelector(getPassengers);
    const onSuccessfulRefund = () => {
        props.reloadOrder();
    };
    return (React.createElement("div", { className: theme.wrapper },
        React.createElement(NasRefundServices, { services: nasServicesToRefund.map(selected => selected.product), selectedServices: nasServicesToRefund, onSuccessfullyRefunded: onSuccessfulRefund, onCancel: props.onCancel, order: order, travellersToRefund: travellersToRefund.map(traveller => traveller.id) })));
};
export default AirportServices;
