import * as React from 'react';
import Checkbox from '../../../BaseComponents/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { isCheckinFlightGuard, isCheckinSegmentGuard } from '../../store';
import { SelectableCheckoutSegment } from '../SelectableSegment/SelectableSegment';
import { useCallback } from 'react';
import { Fade } from '@mui/material';
import { SelectableCheckinSegment } from '../SelectableSegment/CheckinSelectableSegment';
const FlightSelector = ({ flights, selectedSegments, onChange, type, transferInfo }) => {
    const { FlightSelector: css, SelectableSegment: selectableSegmentStyles } = useTheme('UpgradeFlightClass');
    const handleToggle = useCallback((segmentId) => {
        if (type === 'select' && onChange) {
            onChange(segmentId);
        }
    }, [selectedSegments, onChange]);
    const renderSegment = useCallback((segment, index, flight) => {
        var _a;
        let isMultipleSegmentsOnFlightSelected;
        if (flight) {
            isMultipleSegmentsOnFlightSelected =
                ((_a = flight.segments.filter(flightSegment => selectedSegments.includes(flightSegment.id))) === null || _a === void 0 ? void 0 : _a.length) > 1;
        }
        return (React.createElement(Fade, { key: segment.id, in: type === 'select' || selectedSegments.includes(segment.id), unmountOnExit: true },
            React.createElement("div", { key: segment.id },
                React.createElement(FormControlLabel, { className: css.formControl, classes: {
                        label: css.label
                    }, control: type === 'select' ? (React.createElement(Checkbox, { defaultChecked: selectedSegments.includes(segment.id), onChange: () => handleToggle(segment.id), className: css.checkbox })) : (React.createElement("div", null)), label: isCheckinSegmentGuard(segment) ? (React.createElement(SelectableCheckinSegment, { showStopsInfo: type === 'select' || isMultipleSegmentsOnFlightSelected, segment: segment, index: index, highlight: type === 'select' && selectedSegments.includes(segment.id), parentFlight: {
                            id: null,
                            price: null,
                            flightNumber: null,
                            arrival: null,
                            departure: null,
                            duration: null,
                            stops: [],
                            operatingAirline: null,
                            marketingAirline: null,
                            checkinInfo: null,
                            segments: [
                                Object.assign(Object.assign({}, segment), { messages: [] })
                            ] // todo: write adapter
                        }, transferInfo: transferInfo[segment.id] })) : (React.createElement(SelectableCheckoutSegment, { showStopsInfo: type === 'select' || isMultipleSegmentsOnFlightSelected, segment: segment, index: index, highlight: type === 'select' && selectedSegments.includes(segment.id), parentFlight: flight, transferInfo: transferInfo[segment.id] })) }))));
    }, [selectedSegments, flights, type, transferInfo]);
    if (isCheckinFlightGuard(flights[0])) {
        return (React.createElement("div", { className: cn(selectableSegmentStyles.flight, {
                [selectableSegmentStyles.transfer]: flights.length > 1
            }) }, flights.map((segment, index) => renderSegment(segment, index, null))));
    }
    else {
        return (React.createElement(React.Fragment, null, flights.map(flight => {
            const aviaFlight = flight;
            return (React.createElement("div", { key: aviaFlight.flightId + aviaFlight.groupId, className: cn(selectableSegmentStyles.flight, {
                    [selectableSegmentStyles.transfer]: aviaFlight.segments.length > 1
                }) }, aviaFlight.segments.map((segment, index) => renderSegment(segment, index, flight))));
        })));
    }
};
export default FlightSelector;
