import { __rest } from "tslib";
import * as React from 'react';
import MUIButton from '@mui/material/Button';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Loader from './Loader/Loader';
import { Add } from '../../../Icons';
const Button = (_a) => {
    var { variant = 'optionAction', classes, disabled, isLoading, children, endIcon, disableElevation = true } = _a, props = __rest(_a, ["variant", "classes", "disabled", "isLoading", "children", "endIcon", "disableElevation"]);
    const { Button: theme } = useTheme('BaseComponents');
    const isOptionActionPlusIcon = variant === 'optionActionPlusIcon';
    return (React.createElement(MUIButton, Object.assign({ classes: Object.assign(Object.assign({}, classes), { root: cn(theme.root, theme[variant], {
                [theme.loading]: isLoading
            }, classes === null || classes === void 0 ? void 0 : classes.root), disabled: cn(theme.disabled, classes === null || classes === void 0 ? void 0 : classes.disabled), endIcon: cn(theme.endIcon, classes === null || classes === void 0 ? void 0 : classes.endIcon), startIcon: cn(theme.startIcon, classes === null || classes === void 0 ? void 0 : classes.startIcon) }), disabled: disabled || isLoading, endIcon: isOptionActionPlusIcon && !isLoading ? Add : endIcon, disableElevation: disableElevation }, props), isLoading ? React.createElement(Loader, null) : children));
};
export default Button;
