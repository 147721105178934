import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import ActionButton from '../../../Button/components/ActionButton/ActionButton';
const StepsButtons = props => {
    const { StepsButtons: css } = useTheme('Refund');
    return (React.createElement("div", { className: cn(css.wrapper, props.className, {
            [css.right]: props.position === 'right',
            [css.center]: props.position === 'center',
            [css.left]: props.position === 'left'
        }) },
        props.backButton && (React.createElement(ActionButton, Object.assign({}, Object.assign(Object.assign({ white: true }, props.backButton), { className: cn(css.backButton, css.button, props.backButton.className) })))),
        props.nextButton && (React.createElement(ActionButton, Object.assign({ "data-next-button": true }, Object.assign(Object.assign({}, props.nextButton), { className: cn(css.button, props.nextButton.className) }))))));
};
export default StepsButtons;
