import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { isSameDay } from 'date-fns';
import { useTheme } from '../theme';
import { format, initI18n } from '../utils';
import Notification from '../Notification';
import { InfoMessageTypeEnum } from '@websky/graphql';
initI18n('PaymentTimeLimit');
const PaymentTimeLimit = props => {
    const theme = useTheme('PaymentTimeLimit').PaymentTimeLimit;
    const { t } = useTranslation('PaymentTimeLimit');
    const isToday = isSameDay(props.date, new Date());
    // const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const messageText = React.useMemo(() => {
        let message = props.orderIsBooked ? t('Booking must be paid') : t('Booking must be completed');
        message += isToday
            ? ` ${t('today before')} ${format(props.date, 'HH:mm')}`
            : ` ${t('before')} ${format(props.date, 'dd MMMM, HH:mm')}`;
        return message;
    }, [isToday, props.date]);
    return (React.createElement(Notification, { infoMessage: {
            text: messageText,
            type: InfoMessageTypeEnum.Info,
            code: null
        }, status: InfoMessageTypeEnum.Info, type: "context", className: cn(props.className, theme.wrapper, {
            [theme.wrapper_outlined]: props.variant === 'outlined'
        }), actionText: props.orderIsBooked && props.onCancelOrder && t('Cancel order'), onClickAction: props.onCancelOrder ? props.onCancelOrder : null }));
};
export default PaymentTimeLimit;
