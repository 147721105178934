import { BaggageType } from '@websky/graphql';
export const detectDifferentForReturnFlight = (segments, selectedServices) => {
    const servicesCount = new Map(), segmentsOfServices = new Map();
    for (const selectedService of selectedServices) {
        const serviceId = selectedService.serviceId, passengerId = selectedService.passengerId, key = `${passengerId}:${serviceId}`;
        if (!servicesCount.has(key)) {
            servicesCount.set(key, selectedService.count);
        }
        // detect case if different service count
        if (servicesCount.get(key) !== selectedService.count) {
            return true;
        }
        // collect all segments where service is active
        segmentsOfServices.set(key, [...(segmentsOfServices.get(key) || []), ...selectedService.segmentIds]);
    }
    for (const segmentOfService of segmentsOfServices.values()) {
        if (new Set(segmentOfService).size !== segments.length) {
            return true;
        }
    }
    return false;
};
export const joinServicesByRfics = (baggage) => {
    const servicesByRficsMap = new Map();
    baggage.forEach(baggage => {
        if (servicesByRficsMap.has(baggage.rfics)) {
            servicesByRficsMap.get(baggage.rfics).push(baggage);
        }
        else {
            servicesByRficsMap.set(baggage.rfics, [baggage]);
        }
    });
    return [...servicesByRficsMap.values()];
};
export const joinIncludedBaggageByRfics = (includedServices) => {
    const includedServiceMap = new Map();
    includedServices.forEach(service => {
        var _a;
        const key = `${service.confirmedCount}_${(_a = service.baggage) === null || _a === void 0 ? void 0 : _a.rfics}`;
        if (includedServiceMap.has(key)) {
            includedServiceMap.get(key).push(service);
        }
        else {
            includedServiceMap.set(key, [service]);
        }
    });
    const includedBaggageByRfics = [];
    includedServiceMap.forEach(items => {
        includedBaggageByRfics.push(items);
    });
    return includedBaggageByRfics;
};
export const filterIncludedBaggageByRfics = (baggageServices, includedBaggage, selectedBaggage) => {
    const selectedBaggageService = new Map();
    const filteredIncludedServices = [];
    selectedBaggage.forEach(baggage => {
        baggage.segmentIds.forEach(segmentId => {
            const service = baggageServices.find(baggageService => baggageService.id === baggage.serviceId);
            if (service) {
                const key = `${service.rfics}_${baggage.passengerId}_${segmentId}`;
                selectedBaggageService.set(key, service);
            }
        });
    });
    includedBaggage.forEach(baggage => {
        if (Array.isArray(baggage.segmentIds)) {
            baggage.segmentIds.forEach(segmentId => {
                var _a;
                const isReplaced = (_a = baggage.baggage.isReplacedBy) === null || _a === void 0 ? void 0 : _a.some(rfics => {
                    const key = `${rfics}_${baggage.passengerId}_${segmentId}`;
                    return !!selectedBaggageService.get(key);
                });
                if (baggage.baggage && !isReplaced) {
                    filteredIncludedServices.push(baggage);
                }
            });
        }
    });
    return filteredIncludedServices;
};
export const getRelatedSegments = (groups, segmentId = null) => {
    const result = new Set();
    if (segmentId === null) {
        groups.forEach(group => {
            group.forEach(segmentId => result.add(segmentId));
        });
    }
    else {
        groups.some(group => {
            if (group.includes(segmentId)) {
                group.forEach(segmentId => result.add(segmentId));
                return true;
            }
            return false;
        });
    }
    return result;
};
export const getMinPriceInBaggageGroup = (baggages) => {
    const price = {
        amount: baggages[0].price.amount,
        currency: baggages[0].price.currency
    };
    baggages.forEach(baggage => {
        if (baggage.price.amount < price.amount) {
            price.amount = baggage.price.amount;
        }
    });
    return price;
};
export const getActualPriceInBaggageGroup = (baggages, multipleSegments) => {
    const price = {
        amount: baggages[0].price.amount,
        currency: baggages[0].price.currency,
        withFrom: false
    };
    let actualBaggage = null;
    baggages.forEach(bag => {
        const lessActualPrice = !actualBaggage || bag.price.amount < (actualBaggage === null || actualBaggage === void 0 ? void 0 : actualBaggage.price.amount);
        if (isActualBaggage(bag, actualBaggage) && lessActualPrice) {
            actualBaggage = bag;
        }
        if (bag.price.amount < (actualBaggage === null || actualBaggage === void 0 ? void 0 : actualBaggage.price.amount) || bag.price.amount > (actualBaggage === null || actualBaggage === void 0 ? void 0 : actualBaggage.price.amount)) {
            price.withFrom = true;
        }
    });
    if (actualBaggage) {
        const segmentsCount = multipleSegments ? getRelatedSegments(actualBaggage.segmentIds).size : 1;
        price.amount = actualBaggage.price.amount * segmentsCount;
    }
    return price;
};
export const getSegmentsInBaggageGroup = (baggages) => {
    const segmentsSet = new Set();
    baggages.forEach(baggage => baggage.segmentIds.forEach(segments => segments.forEach(segment => segmentsSet.add(segment))));
    return segmentsSet;
};
export const getBaggageCountInSegment = (services, segmentId) => {
    let count = 0;
    services.forEach(service => {
        if (!segmentId || service.segmentIds.find(segmentGroup => segmentGroup.includes(segmentId))) {
            count += service.count;
        }
    });
    return count;
};
export const findMaxCountServicesInFlight = (services) => {
    const segmentCountsMap = new Map();
    services.forEach(service => {
        service.segmentIds.forEach(segmentId => {
            segmentCountsMap.set(segmentId, (segmentCountsMap.get(segmentId) || 0) + service.count);
        });
    });
    let maxCount = 0;
    segmentCountsMap.forEach(count => {
        if (count > maxCount) {
            maxCount = count;
        }
    });
    return maxCount;
};
export const getMaxBaggageCount = (defaultMaxBaggage, bagLimitConfig, baggageType, selectedCount, selectedPassengerBaggageByTypeCount, selectedPassengerBaggageCount) => {
    let maxBaggagePerPassengerByType = defaultMaxBaggage;
    let maxBaggagePerPassenger = defaultMaxBaggage;
    if (bagLimitConfig) {
        if (bagLimitConfig.baggageByTypeLimit) {
            const maxBaggageByType = bagLimitConfig.baggageByTypeLimit.find(i => i.baggageType === baggageType);
            if (maxBaggageByType) {
                maxBaggagePerPassengerByType = maxBaggageByType.maxBaggagePerPassenger;
            }
        }
        if (bagLimitConfig.maxBaggagePerPassenger >= 0) {
            maxBaggagePerPassenger = bagLimitConfig.maxBaggagePerPassenger;
        }
    }
    maxBaggagePerPassengerByType -= selectedPassengerBaggageByTypeCount - selectedCount;
    maxBaggagePerPassenger -= selectedPassengerBaggageCount - selectedCount;
    return Math.min(maxBaggagePerPassengerByType, maxBaggagePerPassenger);
};
export const getMinBaggageCount = (passenger, baggageId, segmentId) => {
    var _a;
    return (_a = passenger.services.find(s => (!segmentId || s.segmentIds.includes(segmentId)) && baggageId.map(b => b.id).includes(s.serviceId))) === null || _a === void 0 ? void 0 : _a.confirmedCount;
};
export const isActualBaggage = (currBaggage, actualBaggage, onlyAvailable = true) => {
    const actualBaggageById = !actualBaggage || currBaggage.id > actualBaggage.id;
    if (onlyAvailable) {
        return currBaggage.canBeAdded && actualBaggageById;
    }
    return actualBaggageById;
};
export const getActualBaggage = (baggage, segmentId, onlyAvailable = true) => {
    let actualBaggage = null;
    baggage.forEach(bag => {
        if (isActualBaggage(bag, actualBaggage, onlyAvailable) &&
            (!segmentId || (segmentId && bag.segmentIds.some(ids => ids.some(id => id === segmentId))))) {
            actualBaggage = bag;
        }
    });
    return actualBaggage;
};
export const convertToBaggageAdapter = (item, canBeAdded = false) => {
    return item
        ? Object.assign(Object.assign({}, item), { id: item.serviceId, canBeAdded }) : null;
};
export const updateSelectedItems = (currCount, newCount, items, // empty items we can fill by current counter
selectedItems // items previously selected by user for current segment, rfics and passenger
) => {
    const dCount = newCount - currCount;
    // count > 1 can be booked by multiple services with same ids
    // Join services with same segment ids
    const selectedServicesBySegments = new Map();
    selectedItems.forEach(selected => {
        const key = selected.segmentIds.sort().join('-');
        selectedServicesBySegments.set(key, [...(selectedServicesBySegments.get(key) || []), selected]);
    });
    if (dCount > 0) {
        items.forEach(item => {
            var _a;
            if (!item.disabledToAdd) {
                const key = item.segmentIds.sort().join('-');
                const existServicesBySegment = (_a = selectedServicesBySegments.get(key)) !== null && _a !== void 0 ? _a : [];
                const existServiceById = existServicesBySegment.find(service => service.serviceId === item.serviceId);
                if (existServiceById) {
                    selectedServicesBySegments.set(key, existServicesBySegment.map(service => {
                        if (service.serviceId === item.serviceId) {
                            return Object.assign(Object.assign({}, service), { count: service.count + dCount });
                        }
                        return service;
                    }));
                }
                else {
                    const existServicesCount = existServicesBySegment.reduce((acc, service) => +acc + +service.count, 0);
                    selectedServicesBySegments.set(key, [
                        ...existServicesBySegment,
                        Object.assign(Object.assign({}, item), { count: newCount - existServicesCount })
                    ]);
                }
            }
        });
    }
    else {
        // Change the count only first service in segment and reset the rest
        selectedServicesBySegments.forEach((services, key) => {
            const servicesWithCount = services.filter(service => service.count > 0);
            selectedServicesBySegments.set(key, servicesWithCount.map((service, index) => {
                const isLastService = index === servicesWithCount.length - 1;
                if (isLastService) {
                    return Object.assign(Object.assign({}, service), { count: service.count + dCount });
                }
                return service;
            }));
        });
    }
    return [...selectedServicesBySegments.values()].reduce((acc, services) => [...acc, ...services], []);
};
export const isBaggageExcess = (baggage) => {
    return baggage.type === BaggageType.BaggageExcess;
};
export const isCanModifyServices = (services, passenger) => {
    return services.some(baggage => {
        var _a;
        if (!baggage.allowedPassengers.includes(passenger.id)) {
            return false;
        }
        const service = (_a = passenger.services) === null || _a === void 0 ? void 0 : _a.find(service => service.serviceId === baggage.id);
        const canBeRemoved = !service || service.count > service.confirmedCount;
        return baggage.canBeAdded || canBeRemoved;
    });
};
export const getSelectedServicesKeys = (services) => {
    const selectedServicesSet = new Set();
    services.forEach(service => {
        if (service.count) {
            const key = [service.serviceId, service.passengerId, service.segmentIds.join(''), service.count].join('-');
            selectedServicesSet.add(key);
        }
    });
    return [...selectedServicesSet].sort();
};
export const getHasChangesServices = (initialServices, selectedServices) => {
    const initialKeys = getSelectedServicesKeys(initialServices);
    const selectedKeys = getSelectedServicesKeys(selectedServices);
    if (initialKeys.length !== selectedKeys.length) {
        return true;
    }
    return JSON.stringify(initialKeys) !== JSON.stringify(selectedKeys);
};
