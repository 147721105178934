import * as ComponentStyles from './components/Component.css';
import * as SeatMapStyles from './components/SeatMap/SeatMap.css';
import * as SeatStyles from './components/SeatMap/Seat/Seat.css';
import * as SeatTooltipStyles from './components/SeatMap/Seat/SeatTooltip/SeatTooltip.css';
import * as AirplaneStyles from './components/Airplane/Airplane.css';
import * as PassengersStyles from './components/Passengers/Passengers.css';
import * as Passenger from './components/Passengers/Passenger/Passenger.css';
import * as AirplaneInfoStyles from './components/AirplaneInfo/AirplaneInfo.css';
import * as SeatsInfoStyles from './components/SeatsInfo/SeatsInfo.css';
import * as SeatMapDisclaimerStyles from './components/SeatMapDisclaimer/SeatMapDisclaimer.css';
import * as SeatServiceSlide from './components/SeatMap/Seat/SeatServiceSlide/SeatServiceSlide.css';
import * as SeatsRecommendPopup from './components/SeatsRecommend/SeatsRecommendPopup/SeatsRecommendPopup.css';
import * as Controls from './components/SeatMapService/Controls/Controls.css';
import SeatMapError from './components/SeatMap/SeatMapError/SeatMapError.css';
import DoubleSeat from './components/Passengers/DoubleSeat/DoubleSeat.css';
import SureModal from './components/Passengers/DoubleSeat/SureModal/SureModal.css';
import MobilePassenger from './components/Passengers/Passenger/MobilePassenger/MobilePassenger.css';
import SeatPrice from './components/SeatPrice/SeatPrice.css';
import SeatsInfoByPrice from './components/SeatsInfo/SeatsInfoByPrice/SeatsInfoByPrice.css';
export default {
    ComponentStyles,
    SeatMapStyles,
    SeatStyles,
    SeatTooltipStyles,
    AirplaneStyles,
    PassengersStyles,
    Passenger,
    AirplaneInfoStyles,
    SeatsInfoStyles,
    SeatServiceSlide,
    SeatMapDisclaimerStyles,
    SeatsRecommendPopup,
    Controls,
    SeatMapError,
    DoubleSeat,
    SureModal,
    MobilePassenger,
    SeatPrice,
    SeatsInfoByPrice
};
