import { __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import DesktopPassenger from '../DesktopPassenger/DesktopPassenger';
import { useTheme } from '../../../../../theme';
import DoubleSeat from '../../DoubleSeat/DoubleSeat';
const MobilePassenger = (_a) => {
    var { className, isActive, isActiveDoubleSeat, isDisabled, hasSeatsByPassenger, showDoubleSeatInfo, onClick, onClearSeat, toggleExtraSeat } = _a, props = __rest(_a, ["className", "isActive", "isActiveDoubleSeat", "isDisabled", "hasSeatsByPassenger", "showDoubleSeatInfo", "onClick", "onClearSeat", "toggleExtraSeat"]);
    const { MobilePassenger: theme } = useTheme('SeatMap');
    const onClearSeatHandler = () => {
        if (isActive) {
            onClearSeat();
        }
    };
    const toggleExtraSeatHandler = (active) => {
        if (isActive) {
            toggleExtraSeat(active);
        }
    };
    return (React.createElement("div", { className: cn(className, theme.container, {
            [theme.active]: isActive,
            [theme.disabled]: isDisabled
        }), onClick: onClick },
        React.createElement(DesktopPassenger, Object.assign({ className: theme.passenger, showSelect: false, onClearSeat: onClearSeatHandler }, props)),
        toggleExtraSeat && !isDisabled && (React.createElement(DoubleSeat, { className: theme.doubleSeat, isActive: isActiveDoubleSeat, hasSeatsByPassenger: hasSeatsByPassenger, showInfo: showDoubleSeatInfo, toggleExtraSeat: toggleExtraSeatHandler }))));
};
export default MobilePassenger;
