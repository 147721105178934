import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { useTheme } from '../../../../theme';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { SwitchV2 } from '../../../../Switch';
import { getDifferentForReturnFlight, getDisabled, getIsAllServicesCanAddedToEntireFlight } from '../../store/selector';
import { differentForReturnFlight } from '../../store/actions';
import HeaderBaggageItem from './HeaderBaggageItem/HeaderBaggageItem';
import MobileBaggageSelector from './MobileBaggageSelector/MobileBaggageSelector';
import { getBaggageTab, getSelectedServices } from '../../store/selector';
import { setBaggageTab } from '../../store/actions';
import HeaderEquipmentItem from './HeaderEquipmentItem/HeaderEquipmentItem';
import Money from '../../../../Money';
import { getRelatedSegments, isActualBaggage } from '../../utils';
import PromoMessage from '../../../AdditionalBaggage/components/PromoMessage/PromoMessage';
import { OverrideComponent } from '../../../../renderProps';
const BaggageDialogHeader = props => {
    const theme = useTheme('UpgradeBaggageDialog').BaggageDialogHeader;
    const { t } = useTranslation('IncludedBaggage');
    const dispatch = useDispatch();
    const selectedServices = useSelector(getSelectedServices);
    const baggageTab = useSelector(getBaggageTab);
    const disabled = useSelector(getDisabled);
    const isDifferentForReturnFlight = useSelector(getDifferentForReturnFlight);
    const isMultipleSegments = props.segments.length > 1;
    const availableToEntireFlight = useSelector(getIsAllServicesCanAddedToEntireFlight);
    const handlerClickSwitcher = () => dispatch(differentForReturnFlight());
    const onChangeBaggageTab = (type) => {
        dispatch(setBaggageTab(type));
    };
    const sliderSettings = {
        slidesToShow: 6.5,
        slidesToScroll: 5,
        dots: false,
        speed: 500,
        infinite: false,
        arrows: false
    };
    const serviceByRfics = React.useMemo(() => {
        if (baggageTab) {
            // ищем багаж с максимальным id
            let actualService = null;
            const servicesByRfisc = props.services
                .filter(services => services.find(service => service.rfics === baggageTab))
                .reduce((acc, services) => [...acc, ...services], []);
            if (servicesByRfisc.length === 1) {
                actualService = servicesByRfisc[0];
            }
            else {
                props.services.forEach(services => {
                    services.forEach(service => {
                        if (service.rfics === baggageTab &&
                            service.price.amount > 0 &&
                            isActualBaggage(service, actualService)) {
                            actualService = service;
                        }
                    });
                });
            }
            return actualService;
        }
        return null;
    }, [props.services, baggageTab]);
    const servicePrice = React.useMemo(() => {
        if (serviceByRfics) {
            const price = {
                amount: serviceByRfics.price.amount,
                currency: serviceByRfics.price.currency
            };
            if (!isDifferentForReturnFlight) {
                const relatedSegments = getRelatedSegments(serviceByRfics.segmentIds, null);
                price.amount = price.amount * relatedSegments.size;
            }
            return price;
        }
        return null;
    }, [serviceByRfics, isDifferentForReturnFlight]);
    const renderCategory = useCallback((services) => {
        const actualService = services[0];
        const baggageRfics = actualService.rfics;
        const baggageType = actualService.type;
        const isSelected = !!selectedServices.find(({ serviceId, count }) => serviceId === actualService.id && count > 0);
        return (React.createElement(HeaderEquipmentItem, { key: baggageRfics, className: theme.categories__item, rfics: baggageRfics, baggageType: baggageType, isActive: baggageTab === baggageRfics, isSelected: isSelected, onClick: () => onChangeBaggageTab(baggageRfics) }));
    }, [selectedServices, baggageTab]);
    const renderCategories = useCallback(() => {
        return (React.createElement(React.Fragment, null,
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH + 1 },
                React.createElement("div", { className: theme.categories }, props.services.map(renderCategory))),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement(Slider, Object.assign({ className: theme.categories }, sliderSettings), props.services.map(renderCategory))),
            React.createElement("div", { className: theme.category__wrapper },
                React.createElement("div", { className: theme.category_info },
                    React.createElement("span", { className: theme.category_title }, serviceByRfics.name),
                    React.createElement("span", { className: theme.category_description, dangerouslySetInnerHTML: { __html: serviceByRfics.description } })),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Money, { money: servicePrice, moneyClassName: theme.category__price })))));
    }, [baggageTab, selectedServices, props.services, isDifferentForReturnFlight]);
    return (React.createElement("div", { className: cn(theme.row) },
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            baggageTab && renderCategories(),
            React.createElement("div", { className: cn(theme.info, {
                    [theme.equipment]: baggageTab
                }) },
                React.createElement(PromoMessage, { className: theme.promoMessage }),
                props.headerProps.description && (React.createElement("div", { className: theme.titleContainer },
                    React.createElement("div", { className: theme.description }, props.headerProps.description))),
                React.createElement("div", { className: theme.info_price },
                    React.createElement("div", { className: cn(theme.price_label, {
                            [theme.perFlight]: isDifferentForReturnFlight,
                            [theme.price_label_withPrice]: servicePrice
                        }) }, isDifferentForReturnFlight ? (t('Price per flight')) : (React.createElement(React.Fragment, null,
                        t('Price for all flights'),
                        servicePrice && (React.createElement("div", { className: theme.price_amount },
                            React.createElement(Money, { money: servicePrice })))))),
                    isMultipleSegments && availableToEntireFlight && (React.createElement(SwitchV2, { label: React.createElement("span", { className: theme.label }, t('Choose for each flight')), switchPosition: 'right', isActive: isDifferentForReturnFlight, onClick: handlerClickSwitcher, isDisabled: disabled })))),
            !baggageTab && (React.createElement("div", { className: theme.baggage__container }, props.services.map(service => (React.createElement(HeaderBaggageItem, { services: service, key: service[0].rfics, allSegments: !isDifferentForReturnFlight })))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            baggageTab ? (renderCategories()) : (React.createElement(OverrideComponent, { component: { MobileBaggageSelector: MobileBaggageSelector }, componentProps: {
                    services: props.services,
                    selectedBaggageId: props.mobileSelectedServiceId,
                    setSelectedBaggageId: props.setMobileServiceId,
                    allSegments: !isDifferentForReturnFlight,
                    segments: props.segments
                } })),
            React.createElement("div", { className: theme.toggle__wrapper },
                React.createElement("div", { className: cn(theme.price_label, { [theme.perFlight]: isDifferentForReturnFlight }) }, t(isDifferentForReturnFlight ? 'Price per flight' : 'Price for all flights')),
                isMultipleSegments && availableToEntireFlight && (React.createElement(SwitchV2, { label: React.createElement("span", { className: theme.label }, t('Choose for each flight')), switchPosition: 'right', isActive: isDifferentForReturnFlight, onClick: handlerClickSwitcher, isDisabled: disabled }))))));
};
export default BaggageDialogHeader;
