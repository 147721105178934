import * as React from 'react';
export const checkIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M7.49993 13.5003L3.99993 10.0003L2.83327 11.167L7.49993 15.8337L17.4999 5.83366L16.3333 4.66699L7.49993 13.5003Z", fill: "currentColor" })));
export const expandIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M16.59 8.59003L12 13.17L7.41 8.59003L6 10L12 16L18 10L16.59 8.59003Z", fill: "currentColor" })));
export const planeIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M6.66665 18.3337H8.33331L12.5 11.3161H17.0833C17.775 11.3161 18.3333 10.7284 18.3333 10.0003C18.3333 9.27226 17.775 8.68454 17.0833 8.68454H12.5L8.33331 1.66699L6.66665 1.66699L8.74998 8.68454H4.16665L2.91665 6.93015H1.66665L2.49998 10.0003L1.66665 13.0705L2.91665 13.0705L4.16665 11.3161H8.74998L6.66665 18.3337Z", fill: "currentColor" })));
export const seatIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8.45985 17.1309L9.41406 15.2757H6.30318L3.57147 6.12609V2.64933C3.57147 1.9752 4.13363 1.42871 4.82709 1.42871C5.52055 1.42871 6.08271 1.9752 6.08271 2.64933V6.12609L8.11003 12.1278H13.9266C14.8208 12.1278 15.5457 12.8325 15.5457 13.7018C15.5457 14.571 14.8208 15.2757 13.9266 15.2757H11.0259L10.0717 17.1309H13.6422V18.5324H9.35085L9.33069 18.5716L9.25006 18.5324H4.78633V17.1309H8.45985Z", fill: "currentColor" })));
export const baggageIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.05358 3.75073H5.94768C5.2568 3.75073 4.69663 4.33481 4.69678 5.05552V16.2426C4.69678 16.9633 5.25695 17.5476 5.94783 17.5476H6.1158V18.4947C6.1158 18.6689 6.25091 18.8102 6.41823 18.8102H7.84873C8.01555 18.8102 8.15117 18.6691 8.15117 18.4947V17.5476H12.682V18.4947C12.682 18.6689 12.8173 18.8102 12.9844 18.8102H14.4151C14.5819 18.8102 14.7174 18.6691 14.7174 18.4947V17.5476H14.8851C15.5759 17.5476 16.1362 16.9635 16.1362 16.2426V5.05544C16.1362 4.33488 15.576 3.75065 14.8851 3.75065H13.7793V2.3282C13.7793 1.50438 13.1364 0.833984 12.3466 0.833984H8.48625C7.69622 0.833984 7.05358 1.50438 7.05358 2.3282V3.75073ZM12.8243 3.75065H8.00891V2.3282C8.00891 2.05359 8.22317 1.83013 8.48646 1.83013H12.3468C12.6101 1.83013 12.8243 2.05359 12.8243 2.3282V3.75065ZM6.24982 6.87565C6.24982 6.53047 6.52964 6.25065 6.87482 6.25065H13.9582C14.3033 6.25065 14.5832 6.53047 14.5832 6.87565C14.5832 7.22083 14.3033 7.50065 13.9582 7.50065H6.87482C6.52964 7.50065 6.24982 7.22083 6.24982 6.87565ZM6.24982 9.37565C6.24982 9.03047 6.52964 8.75065 6.87482 8.75065H13.9582C14.3033 8.75065 14.5832 9.03047 14.5832 9.37565C14.5832 9.72083 14.3033 10.0007 13.9582 10.0007H6.87482C6.52964 10.0007 6.24982 9.72083 6.24982 9.37565ZM6.87482 11.2507C6.52964 11.2507 6.24982 11.5305 6.24982 11.8757C6.24982 12.2208 6.52964 12.5007 6.87482 12.5007H13.9582C14.3033 12.5007 14.5832 12.2208 14.5832 11.8757C14.5832 11.5305 14.3033 11.2507 13.9582 11.2507H6.87482ZM6.24982 14.3757C6.24982 14.0305 6.52964 13.7507 6.87482 13.7507H13.9582C14.3033 13.7507 14.5832 14.0305 14.5832 14.3757C14.5832 14.7208 14.3033 15.0007 13.9582 15.0007H6.87482C6.52964 15.0007 6.24982 14.7208 6.24982 14.3757Z", fill: "currentColor" })));
export const mealIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.0909 2.5V5.22727H17.5L16.375 16.4977C16.3068 17.0568 15.8364 17.5 15.2637 17.5H14.1046L14.0909 12.0455C14.0909 9.16136 11.9773 7.38182 9.47503 6.80227L9.31821 5.22727H12.7273V2.5H14.0909ZM3.18864 17.4932C2.80682 17.4932 2.5 17.1864 2.5 16.8045V16.1364H12.7341V16.8045C12.7341 17.1864 12.4273 17.4932 12.0455 17.4932H3.18864ZM7.61364 7.94772C5.05682 7.94772 2.5 9.31817 2.5 12.0454H12.7273C12.7273 9.31817 10.1705 7.94772 7.61364 7.94772ZM12.7273 13.4091H2.5V14.7727H12.7273V13.4091Z", fill: "currentColor" })));
export const contactsIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.0001 9.16732C15.0001 6.60898 13.6334 4.46732 11.2501 3.90065V3.33398C11.2501 2.64232 10.6918 2.08398 10.0001 2.08398C9.30844 2.08398 8.75011 2.64232 8.75011 3.33398V3.90065C6.35844 4.46732 5.00011 6.60065 5.00011 9.16732V13.334L3.33344 15.0007V15.834H16.6668V15.0007L15.0001 13.334V9.16732ZM5.12523 2.20898L6.31689 3.40065C4.61689 4.60898 3.48356 6.54231 3.35856 8.75065H1.69189C1.80856 6.08398 3.12523 3.73398 5.12523 2.20898ZM18.3085 8.75065H16.6418C16.5168 6.54231 15.3752 4.60898 13.6918 3.40065L14.8752 2.20898C16.8668 3.73398 18.1835 6.08398 18.3085 8.75065ZM10.3334 18.3007C10.2251 18.3257 10.1167 18.334 10.0001 18.334C9.07507 18.334 8.3334 17.584 8.32507 16.6673H11.6584C11.6584 16.9007 11.6167 17.1173 11.5334 17.3173C11.3167 17.8173 10.8751 18.184 10.3334 18.3007Z", fill: "currentColor" })));
export const passengerIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.3333 6.66732C13.3333 8.50898 11.8416 10.0007 9.99992 10.0007C8.15825 10.0007 6.66659 8.50898 6.66659 6.66732C6.66659 4.82565 8.15825 3.33398 9.99992 3.33398C11.8416 3.33398 13.3333 4.82565 13.3333 6.66732ZM11.6666 6.66732C11.6666 5.75065 10.9166 5.00065 9.99992 5.00065C9.08325 5.00065 8.33325 5.75065 8.33325 6.66732C8.33325 7.58398 9.08325 8.33398 9.99992 8.33398C10.9166 8.33398 11.6666 7.58398 11.6666 6.66732ZM9.99992 10.834C7.77492 10.834 3.33325 11.9507 3.33325 14.1673V16.6673H16.6666V14.1673C16.6666 11.9507 12.2249 10.834 9.99992 10.834ZM4.99992 14.1757V15.0007H14.9999V14.1673C14.8333 13.5757 12.2499 12.5007 9.99992 12.5007C7.74992 12.5007 5.16659 13.5757 4.99992 14.1757Z", fill: "currentColor" })));
export const insuranceIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M19.1667 10.1022C18.7414 9.80595 18.0959 9.61685 17.3728 9.61685C16.2533 9.61685 15.3191 10.07 15.1018 10.6727H15.0085C14.7965 10.0661 13.8595 9.60895 12.7351 9.60895C12.0109 9.60895 11.3657 9.79924 10.9406 10.0964V15.1976C10.9406 16.4672 9.87511 17.5 8.56544 17.5C7.25573 17.5 6.19026 16.4672 6.19026 15.1976C6.19026 14.9171 6.42479 14.6897 6.7142 14.6897C7.00361 14.6897 7.23815 14.9171 7.23815 15.1976C7.23815 15.907 7.83355 16.4842 8.56544 16.4842C9.2973 16.4842 9.8927 15.907 9.8927 15.1976V10.1047C9.46752 9.80737 8.82202 9.61685 8.09743 9.61685C6.97785 9.61685 6.04371 10.07 5.82642 10.6727H5.73317C5.52114 10.0661 4.58414 9.60895 3.45976 9.60895C2.73714 9.60895 2.09195 9.79778 1.66669 10.0937C2.0084 6.47675 5.51925 3.60167 9.89273 3.38757V3.0079C9.89273 2.72732 10.1273 2.5 10.4167 2.5C10.7061 2.5 10.9406 2.72735 10.9406 3.0079V3.38762C15.3172 3.60219 18.8296 6.48149 19.1667 10.1022Z", fill: "currentColor" })));
export const transferIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.2701 2.12402L19.4992 8.48951L13.2701 14.855V11.1336H5.12024V9.94845L2.83253 12.2862L5.12024 14.624V13.3221H12.005V14.9887H6.78691V18.7101L0.50061 12.2862L6.78691 5.86234V9.46692H14.9367V10.7689L17.1673 8.48951L14.9367 6.21013V7.27845H8.77096V5.61178H13.2701V2.12402Z", fill: "currentColor" })));
