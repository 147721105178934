import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import { useToggleable } from '../../../../hooks';
import { useIsMobile } from '../../../../utils';
import { getSegmentsForServices } from '../../../store/order/selectors';
import { getSelectedExtraSeats } from '../../../store/passengers/selectors';
import { getIsAllExtraSeatsSelected, getIsAllSeatsSelected } from '../../../utils';
import DoubleSeatsModal from '../../../../DoubleSeatsModal';
import SuggestionModalContent from '../../../../SuggestionModalContent/SuggestionModalContent';
import Modal from '../../../../Modal';
import { SeatIcon } from './icons';
const BookingSeatsWrapper = ({ children, passengers, goToNextStep }) => {
    const { t } = useTranslation('SeatMap');
    const { BookingSeatsWrapper: theme } = useTheme('Checkout');
    const { global: { seatMap: { showRecommendedSeats } } } = useConfig();
    const segments = useSelector(getSegmentsForServices);
    const selectedExtraSeatsServices = useSelector(getSelectedExtraSeats);
    const isMobile = useIsMobile();
    const { isOpen: showSeatsModal, open: openSeatsModal, close: closeSeatsModal } = useToggleable(false);
    const { isOpen: showDoubleSeatsModal, open: openDoubleSeatsModal, close: closeDoubleSeatsModal } = useToggleable(false);
    const goToNextStepHandler = () => {
        if (showRecommendedSeats && !getIsAllSeatsSelected(passengers, segments)) {
            openSeatsModal();
        }
        else if (!getIsAllExtraSeatsSelected(selectedExtraSeatsServices, passengers, segments)) {
            openDoubleSeatsModal();
        }
        else {
            goToNextStep();
        }
    };
    return (React.createElement(React.Fragment, null,
        children({ goToNext: goToNextStepHandler }),
        React.createElement(DoubleSeatsModal, { classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, open: showDoubleSeatsModal, onClose: closeDoubleSeatsModal, slideUp: isMobile }),
        React.createElement(Modal, { maxWidth: "xs", classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, open: showSeatsModal, onClose: closeSeatsModal, slideUp: isMobile },
            React.createElement(SuggestionModalContent, { icon: SeatIcon, agreeButtonText: t('Select seat'), disagreeButtonText: t('PopUpContinue'), text: t(`If you don't select a seat, we'll automatically allocate your seats when you check-in.`), onAgree: closeSeatsModal, onDisagree: goToNextStep }))));
};
export default BookingSeatsWrapper;
