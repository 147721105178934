import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { Select } from '../Select/Select';
import { findAcceptableRules } from '../../../utils';
import { TravellerFieldEnum } from '@websky/graphql';
const DocumentType = ({ field, className, error, fieldState, onChange, passengerId, passengerType, label, disabled, variant }) => {
    const formApi = useForm();
    const { values: formValues } = useFormState();
    let selectedNationality;
    let options = [];
    // options factory
    const acceptableOptions = useMemo(() => {
        const acceptableRules = findAcceptableRules(formValues.passengers[passengerId], field.validations).slice();
        // Сортируем применяемые опции согласно коменту
        // https://youtrack.websky.aero/issue/WEBSKY-3214/Ne-otrabatyvaet-proverka-na-minDate-dlya-lyubogo-dokumenta-so-srokom-dejstviya#focus=Comments-4-47829.0-0
        // первыми идут опции из правил с максимальным количеством совпавших with
        acceptableRules.sort((a, b) => {
            var _a, _b;
            if (!a.with && !b.with) {
                return 0;
            }
            else if (!a.with) {
                return 1;
            }
            else if (!b.with) {
                return -1;
            }
            return ((_a = b.with) === null || _a === void 0 ? void 0 : _a.length) - ((_b = a.with) === null || _b === void 0 ? void 0 : _b.length);
        });
        return acceptableRules[0].options;
    }, [formValues]);
    const onChangeHandler = useCallback((value, event) => {
        onChange && onChange(field.name, value);
        fieldState.input.onChange(event);
    }, [onChange, fieldState]);
    if (field.type === TravellerFieldEnum.DocType) {
        selectedNationality = formValues.passengers[passengerId].nationality;
        if (!selectedNationality) {
            selectedNationality = 'other';
        }
    }
    if (selectedNationality) {
        options = acceptableOptions;
    }
    useEffect(() => {
        var _a;
        if (!fieldState.input.value) {
            return;
        }
        const existOption = options.some(option => option.value === fieldState.input.value);
        if (!existOption) {
            formApi.change(field.name, (_a = options[0]) === null || _a === void 0 ? void 0 : _a.value);
        }
    }, [options, fieldState.input.value]);
    return (React.createElement(Select, { value: fieldState.input.value, options: options.map(option => ({
            key: option.value,
            value: option.label
        })), name: field.name, label: label, className: className, onChange: onChangeHandler, onBlur: fieldState.input.onBlur, error: error, translateValue: true, disabled: disabled, variant: variant }));
};
export default DocumentType;
