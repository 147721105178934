import * as React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import { arrowIcon, infoIcon } from '../Icons';
import { useTheme } from '../../../theme';
const Warning = ({ warning, defaultOpen = false }) => {
    const { t } = useTranslation('Warnings');
    const { Warning: theme } = useTheme('MealService');
    const [warningIsOpen, setWarningIsOpen] = useState(defaultOpen);
    const toggleWarning = () => setWarningIsOpen(state => !state);
    return (React.createElement("div", { className: theme.warning },
        React.createElement("div", { className: theme.warning__trigger, onClick: toggleWarning },
            React.createElement("span", { className: theme.warning__title },
                infoIcon,
                " ",
                t('Clarification')),
            React.createElement("span", { className: cn(theme.warning__arrow, {
                    [theme.warning__arrow_rotated]: warningIsOpen
                }) }, arrowIcon)),
        React.createElement(Collapse, { in: warningIsOpen },
            React.createElement("div", { className: theme.warning__text }, t(warning)))));
};
export default Warning;
