import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { format } from '../../../utils';
import { useTranslation } from 'react-i18next';
export const Voucher = props => {
    const css = useTheme('Loyalty').voucher;
    const { t } = useTranslation('Loyalty');
    return (React.createElement("div", { className: cn(css.voucher, [props.voucher.expires < new Date() ? css.expired : '']) },
        React.createElement("div", { className: css.header },
            React.createElement("span", { className: css.title },
                "LightJet ",
                t('E-voucher')),
            React.createElement("span", { className: css.number },
                "#",
                props.voucher.number)),
        React.createElement("div", { className: css.info },
            React.createElement("span", { className: css.value },
                "$",
                props.voucher.value),
            React.createElement("span", { className: css.expires }, props.voucher.expires < new Date()
                ? t('expired')
                : `${t('expiry')} ${format(props.voucher.expires, 'dd.MM.yyyy')}`))));
};
