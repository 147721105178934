import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getServicesFallbackURL, shouldRedirectToFallback } from '../../../store/order/selectors';
export const useUpsaleWarning = () => {
    const [showWarning, setShowWaring] = useState(false);
    const shouldRedirect = useSelector(shouldRedirectToFallback);
    const redirectURL = useSelector(getServicesFallbackURL);
    const openWarning = useCallback(() => {
        setShowWaring(true);
    }, []);
    const closeWarning = useCallback(() => {
        setShowWaring(false);
    }, []);
    const toggleWarningIfOrderConfirmed = useCallback(() => {
        if (shouldRedirect && redirectURL) {
            setShowWaring(true);
        }
        else {
            setShowWaring(false);
        }
    }, [shouldRedirect, redirectURL]);
    return { showWarning, openWarning, closeWarning, toggleWarningIfOrderConfirmed };
};
