import * as CheckoutStyles from './components/Checkout/Checkout.css';
import * as PassengersStyles from './components/Checkout/Form/Passengers/Passengers.css';
import * as PriceChangedStyles from './components/Checkout/PriceChanged/PriceChanged.css';
import * as FlightNotAvailable from './components/Checkout/FlightNotAvailable/FlightNotAvailable.css';
import * as ActualizationStyles from './components/Checkout/Actualization/Actualization.css';
import * as ThanksBanner from './components/Checkout/ThanksBanner/ThanksBanner.css';
import * as ServicesCardsStyles from './components/Checkout/ServicesCards/ServicesCards.css';
import * as FindOrder from './components/Checkout/FindOrder/FindOrder.css';
import * as Upsale from './components/Checkout/Upsale/Upsale.css';
import * as Warning from './components/Checkout/Upsale/Warning/Warning.css';
import * as Subscribe from './components/Checkout/Upsale/Subscribe/Subscribe.css';
import * as PassengersToolbar from './components/Checkout/Form/PassengersToolbar/PassengersToolbar.css';
import * as ToggleableFlights from './components/Checkout/ToggleableFlights/ToggleableFlights.css';
import * as OrderPaidServiceCards from './components/Checkout/OrderPaidServiceCards/OrderPaidServiceCards.css';
import * as ServiceCardFooter from './components/Checkout/OrderPaidServiceCards/components/ServiceCardFooter/ServiceCardFooter.css';
import * as GroupOrder from './components/Checkout/GroupOrder/GroupOrder.css';
import ParentOrderStatus from './components/Checkout/ParentOrderStatus/ParentOrderStatus.css';
import ChangePaxOrderStatus from './components/Checkout/ChangePaxOrderStatus/ChangePaxOrderStatus.css';
import PromoCodes from './components/Checkout/PromoCodes/PromoCodes.css';
import * as Stepbar from './components/Checkout/Stepbar/Stepbar.css';
import * as Consents from '../Consents/components/Consents.css';
import BookingSeatsWrapper from './components/Checkout/BookingSeatsWrapper/BookingSeatsWrapper.css';
import * as CheckDataModal from './components/Checkout/Form/CheckDataModal/CheckDataModal.css';
import * as PassengersConsents from './components/Checkout/PassengersConsents/PassengersConsents.css';
export default {
    CheckoutStyles,
    PassengersStyles,
    PriceChangedStyles,
    ServicesCardsStyles,
    FlightNotAvailable,
    ActualizationStyles,
    ThanksBanner,
    FindOrder,
    Upsale,
    Warning,
    Subscribe,
    PassengersToolbar,
    ToggleableFlights,
    OrderPaidServiceCards,
    ServiceCardFooter,
    GroupOrder,
    ParentOrderStatus,
    ChangePaxOrderStatus,
    PromoCodes,
    Consents,
    Stepbar,
    BookingSeatsWrapper,
    CheckDataModal,
    PassengersConsents
};
