import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BaggageType as BaggageTypeEnum } from '@websky/graphql';
import Baggage from '../../../../Baggage';
import { BaseButton } from '../../../../../index';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
import Money from '../../../../../Money';
import { useIsMobile } from '../../../../../utils';
const MobileBaggageSelector = props => {
    const theme = useTheme('UpgradeBaggageDialog').MobileBaggageSelector;
    const { t } = useTranslation('BaggageService');
    const selectedService = React.useMemo(() => {
        var _a;
        return (_a = props.services.find(services => services[0].rfics === props.selectedBaggageId)) === null || _a === void 0 ? void 0 : _a[0];
    }, [props.services, props.selectedBaggageId]);
    const price = React.useMemo(() => {
        if (props.allSegments) {
            let amount = 0;
            props.segments.forEach(segment => {
                if (selectedService.segmentIds.some(segments => segments.includes(segment.id))) {
                    amount += selectedService.price.amount;
                }
            });
            return Object.assign(Object.assign({}, selectedService.price), { amount });
        }
        return selectedService.price;
    }, [selectedService, props.segments, props.allSegments]);
    const isMobile = useIsMobile();
    const isBaggageExcess = selectedService.type === BaggageTypeEnum.BaggageExcess;
    let showOnlyIcon = !isMobile || (isMobile && !isBaggageExcess);
    if (typeof props.showBaggageSizeOnMobile !== 'undefined') {
        showOnlyIcon = !props.showBaggageSizeOnMobile;
    }
    return (React.createElement("div", null,
        selectedService && React.createElement("div", { className: theme.description }, selectedService.name),
        props.services.length === 1 && (React.createElement("div", { className: cn(theme.container, {
                [theme === null || theme === void 0 ? void 0 : theme[props.services[0][0].type]]: theme === null || theme === void 0 ? void 0 : theme[props.services[0][0].type]
            }) },
            React.createElement(Baggage, { baggage: props.services[0][0], selected: false, onlyIcon: showOnlyIcon, short: true }),
            React.createElement("div", { className: theme.description }, props.services[0][0].size))),
        props.services.length > 1 && (React.createElement("div", { className: theme.container },
            React.createElement(Baggage, { baggage: props.services[0][0], selected: false, short: true, iconClassName: theme.icon, onlyIcon: showOnlyIcon }),
            props.services.map(service => {
                return (React.createElement(BaseButton, { variant: "smallNeitral", className: cn(theme.selector, {
                        [theme.selected]: service[0].rfics === props.selectedBaggageId
                    }), onClick: () => props.setSelectedBaggageId(service[0].rfics) },
                    service[0].value.amount,
                    " ",
                    service[0].value.measurement));
            }))),
        React.createElement("div", { className: theme.footer },
            React.createElement("div", null,
                selectedService.value.amount,
                " ",
                t(selectedService.value.measurement),
                ", ",
                selectedService.description),
            React.createElement(Money, { moneyClassName: theme.price, money: price }))));
};
export default MobileBaggageSelector;
