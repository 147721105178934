import { __awaiter } from "tslib";
import React, { useCallback, useState } from 'react';
import { useTheme } from '../../../../theme';
import SocialAuth from '../../../../SocialAuth';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { emailRegex, MOBILE_MIN_WIDTH } from '../../../../utils';
import SubmitButton from '../SubmitButton/SubmitButton';
import LoginInput from '../LoginInput/LoginInput';
import { useConfig } from '../../../../context';
import StayLoggedIn from './StayLoggedIn/StayLoggedIn';
import { useMediaQuery } from 'react-responsive';
import { LoginType, useAccountExistenceQuery, useSendSecureCodeMutation } from '@websky/graphql';
import { clearPhoneNumber, isCorrectPhoneNumber } from '../../../../PhoneInput/utils';
import { useToggleable } from '../../../../hooks';
import { getCorporateNumber } from '../../../../Loyalty/utils';
import Switch from '../../../../PassengerForm/components/components/Switch/Switch';
import { detectInputType } from '../../../utils';
import { LoginInputType } from '../../../types';
const LoginForm = ({ onSuccess, initialData, stayLoggedIn, setStayLoggedIn, onError }) => {
    const { LoginForm: css } = useTheme('LoginPage');
    const { t } = useTranslation('LoginPage');
    const { global: { socialAuthMethods, companyInfo: { iataCode } } } = useConfig();
    const isB2 = iataCode === 'B2';
    const FFPRegex = useConfig().global.FFPRegex;
    const availableAuthMethods = socialAuthMethods.filter(method => method.isAvailable);
    const [isLoading, setLoading] = useState(false);
    const [isShownCorporateCardSwitch, setShownCorporateCardSwitch] = useState(false);
    const [sendCode] = useSendSecureCodeMutation();
    const { refetch: userExistence } = useAccountExistenceQuery({
        skip: true
    });
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const { isOpen: isActiveCorporateCard, toggle: onToggleCorporateCard, close: onHideCorporateCard } = useToggleable(false);
    const validate = useCallback((values) => {
        const errors = {};
        if (values.login) {
            if (!emailRegex.test(values.login) && !isCorrectPhoneNumber(values.login) && !FFPRegex.test(values.login)) {
                errors['login'] = t('Please enter a valid Email / Phone / Card number');
            }
            if (detectInputType(values.login) === LoginInputType.Email && values.login.includes('*')) {
                errors['login'] = t('Please enter a valid Email');
            }
        }
        else {
            errors['login'] = t('Required');
        }
        return errors;
    }, []);
    const onSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        setLoading(true);
        const pureLogin = values.login;
        let login = pureLogin;
        let loginType;
        if (emailRegex.test(login)) {
            loginType = LoginType.Email;
        }
        else if (isCorrectPhoneNumber(login)) {
            loginType = LoginType.Phone;
            login = clearPhoneNumber(pureLogin);
        }
        else if (FFPRegex.test(login)) {
            loginType = LoginType.FFP;
            if (isActiveCorporateCard) {
                login = getCorporateNumber(pureLogin);
            }
        }
        if (loginType === LoginType.FFP) {
            onSuccess({
                login: login,
                type: loginType,
                needRegistration: false
            });
        }
        if (loginType === LoginType.Email || loginType === LoginType.Phone) {
            const isAccountExistence = yield userExistence({
                params: {
                    login,
                    loginType
                }
            });
            if ((_b = (_a = isAccountExistence === null || isAccountExistence === void 0 ? void 0 : isAccountExistence.data) === null || _a === void 0 ? void 0 : _a.AccountExistence) === null || _b === void 0 ? void 0 : _b.result) {
                const data = yield sendCode({
                    variables: {
                        params: {
                            login,
                            loginType: loginType
                        }
                    }
                });
                if (data.data.SendSecureCode.result) {
                    onSuccess({
                        login,
                        type: loginType,
                        needRegistration: false
                    });
                }
                else {
                    onError('Something went wrong');
                }
            }
            else {
                onSuccess({
                    login,
                    type: loginType,
                    needRegistration: true
                });
            }
        }
    }), [onSuccess, isActiveCorporateCard]);
    const setBelaviaCardType = useCallback((value) => {
        if (value && FFPRegex.test(value)) {
            setShownCorporateCardSwitch(true);
        }
        else {
            setShownCorporateCardSwitch(false);
            onHideCorporateCard();
        }
    }, [onHideCorporateCard]);
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.title }, t('Log in')),
        React.createElement("div", { className: css.text }, t(`If you don't have an account, it will be created automatically`)),
        React.createElement(Form, { initialValues: {
                login: initialData === null || initialData === void 0 ? void 0 : initialData.login
            }, validate: validate, onSubmit: !isLoading ? onSubmit : () => null, render: ({ handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("div", { className: css.row },
                    React.createElement("div", { className: css.input_wrapper },
                        React.createElement(Field, { name: 'login', render: props => {
                                return (React.createElement(LoginInput, Object.assign({}, props, { input: Object.assign(Object.assign({}, props.input), { onChange: isB2
                                            ? event => {
                                                props.input.onChange(event);
                                                setBelaviaCardType(event.target.value);
                                            }
                                            : props.input.onChange }) })));
                            } })),
                    isMobile && React.createElement(StayLoggedIn, { isActive: stayLoggedIn, setActive: setStayLoggedIn }),
                    isMobile && isShownCorporateCardSwitch && (React.createElement("div", { className: css.loyaltySwitch_wrapper },
                        React.createElement(Switch, { switchPosition: "right", className: css.loyaltySwitch_root, labelClassName: css.loyaltySwitch_label, label: t('Passenger:Corporate card'), isActive: isActiveCorporateCard, onClick: onToggleCorporateCard }))),
                    React.createElement("div", { className: css.button_wrapper },
                        React.createElement(SubmitButton, { loading: isLoading }, t('Continue')))))) }),
        !isMobile && React.createElement(StayLoggedIn, { isActive: stayLoggedIn, setActive: setStayLoggedIn }),
        !isMobile && isShownCorporateCardSwitch && (React.createElement("div", { className: css.loyaltySwitch_wrapper },
            React.createElement(Switch, { switchPosition: "right", className: css.loyaltySwitch_root, labelClassName: css.loyaltySwitch_label, label: t('Passenger:Corporate card'), isActive: isActiveCorporateCard, onClick: onToggleCorporateCard }))),
        availableAuthMethods.length > 0 && (React.createElement("div", { className: css.social_wrapper },
            React.createElement("div", { className: css.social_title }, t('or with you social network')),
            React.createElement(SocialAuth, null)))));
};
export default LoginForm;
