import * as React from 'react';
import CheckoutAdditionalService from '../..';
import { useSelector } from 'react-redux';
import { getOrder, getPriorityBoarding, getSegments } from '../../../Checkout/store/order/selectors';
import { useTranslation } from 'react-i18next';
import { getPassengerState } from '../../../Checkout/store/passengers/selectors';
import MediaQuery from 'react-responsive';
import { getTravellersMap, MOBILE_MIN_WIDTH } from '../../../utils';
import Modal from '../../../Modal';
import VipServiceSelect from '../../../VipServiceSelect';
import { Money, SlideBottom } from '../../../index';
import { useTheme } from '../../../theme';
import { useRenderers } from '../../../renderProps';
import { AdditionalServiceType } from '../../types';
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { useOrderService } from '../hooks';
import { useIsCanModifyServices } from '../../hooks';
import { closeRender } from '../Close/Close';
import Control from '../../../VipServiceSelect/components/Control/Control';
import VipSummary from '../../../ServiceSummary/services/VipSummary';
const PriorityBoarding = props => {
    var _a, _b;
    const { t } = useTranslation('Checkout');
    const order = useSelector(getOrder);
    const passengers = useSelector(getPassengerState);
    const segments = useSelector(getSegments);
    const priorityServices = useSelector(getPriorityBoarding);
    const { opened, onOpen, onClose, selectedServices, renderSubheader, onSaveHandler, onRemoveCartService } = useOrderService(priorityServices, OrderAdditionalServiceGdsServiceServiceType.Vip);
    const theme = useTheme('CheckoutAdditionalService').PriorityBoarding;
    const selectedPriorityPrice = React.useMemo(() => {
        const price = {
            amount: 0,
            currency: null
        };
        passengers.forEach(passenger => {
            priorityServices.forEach(service => {
                if (selectedServices[passenger.id].hasOwnProperty(service.id)) {
                    selectedServices[passenger.id][service.id].forEach(selectedService => {
                        price.amount += selectedService.segments.length * service.price.amount;
                        price.currency = service.price.currency;
                    });
                }
            });
        });
        return price;
    }, [selectedServices, priorityServices]);
    const isCanModifyServices = useIsCanModifyServices(priorityServices);
    const minPrice = React.useMemo(() => {
        let price = priorityServices[0].price;
        priorityServices.forEach(service => {
            if (service.price.amount < price.amount) {
                price = service.price;
            }
        });
        return price;
    }, [priorityServices]);
    const AdditionalServiceRender = (_b = (_a = useRenderers()) === null || _a === void 0 ? void 0 : _a.CheckoutAdditionalService) === null || _b === void 0 ? void 0 : _b.CheckoutAdditionalService;
    const ServiceWrapper = AdditionalServiceRender !== null && AdditionalServiceRender !== void 0 ? AdditionalServiceRender : CheckoutAdditionalService;
    const renderPassenger = ({ passenger, type, count, isConfirmed, onSetCount }) => {
        var _a;
        const price = (_a = priorityServices.find(service => service.id === passenger.serviceId)) === null || _a === void 0 ? void 0 : _a.price;
        return (React.createElement("div", { className: theme.passenger },
            React.createElement(Control, { type: type, count: count, label: React.createElement("div", { className: theme.label }, passenger.name), disabled: isConfirmed, onSetCount: onSetCount(passenger) }),
            React.createElement("div", { className: theme.price }, count > 0 && React.createElement(Money, { money: price }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(VipSummary, { flight: order.flight, travellers: order.travellers, travellersById: getTravellersMap(order.travellers), services: order.additionalServices, serviceName: t('Priority boarding'), serviceSubType: GdsServiceVipSubType.PriorityBoarding, onServiceDelete: onRemoveCartService }, content => (React.createElement(ServiceWrapper, { header: t('Priority boarding'), description: priorityServices[0].description, isSelected: !!content, addClick: onOpen, priceTotal: selectedPriorityPrice, priceFrom: minPrice, className: theme.priority, buttons: [
                {
                    action: onOpen,
                    text: isCanModifyServices ? t('Change') : t('More details'),
                    buttonType: 'change'
                }
            ], serviceType: AdditionalServiceType.PriorityBoarding, addButtonText: !isCanModifyServices ? t('More details') : null }, content))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { className: theme.priorityBoardingModal, classes: { paper: theme.paper }, open: opened, onClose: onClose, maxWidth: "sm", closeButtonRenderer: closeRender },
                React.createElement(VipServiceSelect, { header: t('Priority boarding'), headerClassName: theme.header, passengers: passengers, renderPassenger: renderPassenger, services: priorityServices, segments: segments, onSave: onSaveHandler, selectedServices: selectedServices, onClose: onClose, subheader: renderSubheader(), showFromPrice: true, showTotalPrice: true, type: "checkbox", readOnly: !isCanModifyServices }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: opened, onClose: onClose },
                React.createElement(VipServiceSelect, { header: t('Priority boarding'), passengers: passengers, services: priorityServices, segments: segments, onSave: onSaveHandler, selectedServices: selectedServices, onClose: onClose, subheader: renderSubheader(), readOnly: !isCanModifyServices })))));
};
export default PriorityBoarding;
