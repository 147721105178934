import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCheckinOrderQuery } from '@websky/graphql';
import { fillCheckinOrder } from '../../store/order/actions';
import OrderGuard from '../../../../OrderGuard/OrderGuard';
import Order from './Order/Order';
import { getCheckinOrder } from '../../store/order/selectors';
import CheckinErrorBoundary from './CheckinErrorBoundary/CheckinErrorBoundary';
import { getPassengerLastName } from '../../../../Checkout/store/passengers/selectors';
import { useUpdateCheckinTravellerServices } from '../../hooks';
const OrderWrapper = ({ orderId }) => {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const order = useSelector(getCheckinOrder);
    const onOrderAccessGranted = useCallback(() => window.location.reload(), []);
    let lastName;
    let ticketNumber;
    if (order && order.travellers.length) {
        lastName = (_a = getPassengerLastName(order.travellers[0])) === null || _a === void 0 ? void 0 : _a.value;
        ticketNumber = (_d = (_c = (_b = order.travellers[0]) === null || _b === void 0 ? void 0 : _b.tickets) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.number;
    }
    const onOrderLoad = (order) => {
        dispatch(fillCheckinOrder(order));
    };
    useUpdateCheckinTravellerServices();
    return (React.createElement(OrderGuard, { orderId: orderId, orderLoaded: !!order, onOrderLoad: onOrderLoad, onOrderAccessGranted: onOrderAccessGranted, loadOrder: useGetCheckinOrderQuery },
        React.createElement(CheckinErrorBoundary, { lastName: lastName, ticketNumber: ticketNumber },
            React.createElement(Order, null))));
};
export default OrderWrapper;
