import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { BaseButton, SlideBottom } from '../../../../../index';
import SeatInfo from '../../../SeatsInfo/SeatInfo/SeatInfo';
import { getPassengerLabel } from '../../../../utils';
const SeatServiceSlide = ({ seats, passenger, isOpen, isSelected, onClose, onSelect, isFreeSeat, dataColorNum }) => {
    var _a, _b;
    const theme = useTheme('SeatMap').SeatServiceSlide;
    const { t } = useTranslation('SeatMap');
    const seatNumbers = seats.map(seat => seat.number);
    const onSelectHandler = () => {
        onSelect(seats[0]);
    };
    return (React.createElement(SlideBottom, { isOpen: isOpen, className: theme.serviceInfo__slider, onClose: onClose, header: isSelected ? t('Seat selection') : null },
        React.createElement("div", null,
            React.createElement("div", { className: theme.serviceInfo__seat, "data-color-num": dataColorNum },
                React.createElement(SeatInfo, { dataColorNum: dataColorNum, seatService: seats[0].service, seat: seats[0], isFreeSeat: isFreeSeat }),
                React.createElement("div", { className: theme.serviceInfo__selectable, "data-rfisc": (_b = (_a = seats === null || seats === void 0 ? void 0 : seats[0]) === null || _a === void 0 ? void 0 : _a.service) === null || _b === void 0 ? void 0 : _b.rfisc },
                    React.createElement("div", { className: theme.serviceInfo__passenger }, getPassengerLabel(passenger, t, true)),
                    React.createElement("div", { className: cn(theme.seat, {
                            [theme.seat_double]: seatNumbers.length > 1
                        }) }, seatNumbers.join(', ')))),
            React.createElement(BaseButton, { variant: "action", onClick: onSelectHandler, className: cn(theme.single, { [theme.single_cancel]: isSelected }) }, isSelected ? t('Cancel') : t('Take this seat')))));
};
export default SeatServiceSlide;
