import * as React from 'react';
import { useCallback } from 'react';
import { Choosed } from '../../../../index';
import { useTheme } from '../../../../theme';
import ModalConfirm from '../../../../ModalConfirm/components/ModalConfirm';
const RefundInfoModal = props => {
    const { open, onAgree, onDisagree, renderAgreeText, renderDisagreeText, renderText } = props;
    const { RefundInfoModal: css } = useTheme('Refund');
    const renderContent = useCallback(() => (React.createElement("div", { className: css.content },
        React.createElement("span", { className: css.icon }, Choosed),
        React.createElement("div", { className: css.text }, renderText()))), []);
    return (React.createElement(ModalConfirm, { isOpen: open, size: "small", confirmBtn: renderAgreeText(), onConfirm: onAgree, cancelBtn: onDisagree && renderDisagreeText && renderDisagreeText(), onCancel: onDisagree, onClose: onDisagree }, renderContent()));
};
export default RefundInfoModal;
