import { __awaiter } from "tslib";
import * as React from 'react';
import { memo, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Payment from '../../../../../../Checkout/components/Checkout/routes/Payment';
import { getCheckinOrderId } from '../../../../store/order/selectors';
import { useSteps } from '../../../../utils';
import { CheckinStep } from '../../../../types';
import SimpleLoader from '../../../../../../SimpleLoader';
import { useUnlockCheckinOrder } from '../hooks';
import { useCartPrices } from '../../../../../../Cart/hooks';
import { useGetCheckinOrderQuery } from '@websky/graphql';
const PaymentPage = memo(() => {
    const location = useLocation();
    const orderId = useSelector(getCheckinOrderId);
    const [isLoading, setIsLoading] = useState(false);
    const { previousStep, setStep } = useSteps();
    const priceToPay = useCartPrices();
    const unlockOrder = useUnlockCheckinOrder(setIsLoading);
    const { refetch } = useGetCheckinOrderQuery({
        fetchPolicy: 'no-cache',
        skip: true
    });
    const onRefetchOrder = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield refetch({ id: orderId });
    }), [orderId]);
    useEffect(() => {
        let refetchInterval;
        if (orderId) {
            refetchInterval = setInterval(onRefetchOrder, 60000 * 3);
        }
        return () => clearInterval(refetchInterval);
    }, [orderId]);
    const onSuccessPayment = useCallback((reload) => {
        setStep(CheckinStep.Passengers);
        if (reload) {
            window.location.reload();
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Payment, { orderId: orderId, priceToPay: priceToPay, goToPrevStep: () => previousStep(CheckinStep.Payment), onSuccess: onSuccessPayment, redirectPathname: location.pathname, onPaymentMethodsLoaded: onRefetchOrder, onBackToOrder: unlockOrder }),
        isLoading && React.createElement(SimpleLoader, null)));
});
export default PaymentPage;
