import { __rest } from "tslib";
import * as React from 'react';
import { Provider } from 'react-redux';
import Schedule from './Schedule/Schedule';
import { createStore } from '../store';
import { initI18n } from '../../utils';
initI18n('Schedule');
const Component = (_a) => {
    var { defaultParameters } = _a, restProps = __rest(_a, ["defaultParameters"]);
    const store = createStore(defaultParameters);
    return (React.createElement(Provider, { store: store },
        React.createElement(Schedule, Object.assign({}, restProps))));
};
export default Component;
