import { __rest } from "tslib";
import React, { useMemo } from 'react';
import { TextField } from '@material-ui/core';
import cn from 'classnames';
import { useComputedStyleProperty } from '../../hooks';
import { Included } from '../../../Icons';
import { useTheme } from '../../../theme';
const Input = (_a) => {
    var { className, variant = 'labelIn', label, InputLabelProps, InputProps, FormHelperTextProps, isValid, classes } = _a, restProps = __rest(_a, ["className", "variant", "label", "InputLabelProps", "InputProps", "FormHelperTextProps", "isValid", "classes"]);
    const { Input: theme } = useTheme('BaseComponents');
    const { value: inputVariant, elRef } = useComputedStyleProperty('--variant', variant);
    const labelClasses = InputLabelProps === null || InputLabelProps === void 0 ? void 0 : InputLabelProps.classes;
    const inputClasses = InputProps === null || InputProps === void 0 ? void 0 : InputProps.classes;
    const helperTextClasses = FormHelperTextProps === null || FormHelperTextProps === void 0 ? void 0 : FormHelperTextProps.classes;
    const muiVariant = inputVariant === 'underline' ? 'filled' : 'outlined';
    const variantClasses = useMemo(() => {
        if (muiVariant === 'outlined') {
            return {
                notchedOutline: cn(theme.notchedOutline, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.notchedOutline)
            };
        }
        return {};
    }, [muiVariant]);
    return (React.createElement(TextField, Object.assign({ className: cn(className, theme[inputVariant], {
            [classes === null || classes === void 0 ? void 0 : classes.underline]: inputVariant === 'underline',
            [classes === null || classes === void 0 ? void 0 : classes.labelIn]: inputVariant === 'labelIn',
            [classes === null || classes === void 0 ? void 0 : classes.labelOut]: inputVariant === 'labelOut',
            [classes === null || classes === void 0 ? void 0 : classes.labelOnLine]: inputVariant === 'labelOnLine',
            [classes === null || classes === void 0 ? void 0 : classes.withoutLabel]: !label,
            [theme.withoutLabel]: !label
        }), variant: muiVariant, label: label, InputLabelProps: Object.assign(Object.assign({}, InputLabelProps), { classes: {
                root: cn(theme.label, labelClasses === null || labelClasses === void 0 ? void 0 : labelClasses.root),
                focused: cn(theme.focused, labelClasses === null || labelClasses === void 0 ? void 0 : labelClasses.focused),
                disabled: cn(theme.disabled, labelClasses === null || labelClasses === void 0 ? void 0 : labelClasses.disabled),
                error: cn(theme.error, labelClasses === null || labelClasses === void 0 ? void 0 : labelClasses.error),
                shrink: cn(theme.shrink, labelClasses === null || labelClasses === void 0 ? void 0 : labelClasses.shrink)
            } }), InputProps: Object.assign(Object.assign({}, InputProps), { classes: Object.assign({ root: cn(theme.input, theme[muiVariant], inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.root, {
                    [classes === null || classes === void 0 ? void 0 : classes.outlined]: muiVariant === 'outlined',
                    [classes === null || classes === void 0 ? void 0 : classes.filled]: muiVariant === 'filled',
                    [theme.valid]: isValid
                }), focused: cn(theme.focused, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.focused), disabled: cn(theme.disabled, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.disabled), error: cn(theme.error, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.error) }, variantClasses), endAdornment: (InputProps === null || InputProps === void 0 ? void 0 : InputProps.endAdornment) ? (React.createElement(React.Fragment, null,
                isValid && React.createElement("div", { className: theme.valid_icon }, Included),
                InputProps.endAdornment)) : undefined }), FormHelperTextProps: Object.assign(Object.assign({}, FormHelperTextProps), { classes: {
                root: cn(theme.helperText, helperTextClasses === null || helperTextClasses === void 0 ? void 0 : helperTextClasses.root),
                focused: cn(theme.focused, helperTextClasses === null || helperTextClasses === void 0 ? void 0 : helperTextClasses.root),
                disabled: cn(theme.disabled, helperTextClasses === null || helperTextClasses === void 0 ? void 0 : helperTextClasses.root),
                error: cn(theme.error, helperTextClasses === null || helperTextClasses === void 0 ? void 0 : helperTextClasses.error)
            } }), ref: elRef }, restProps)));
};
export default Input;
