import { createAction } from 'redux-actions';
export const SET_PASSENGERS_STATE = 'SET_PASSENGERS_STATE';
export const SET_PASSENGER_VALUES = 'SET_PASSENGER_VALUES';
export const SET_PASSENGER_SERVICES = 'SET_PASSENGER_SERVICES';
export const SET_PASSENGER_SEATS = 'SET_PASSENGER_SEATS';
export const setPassengersState = createAction(SET_PASSENGERS_STATE);
export const setPassengerValues = createAction(SET_PASSENGER_VALUES, (id, values) => ({
    id,
    values
}));
