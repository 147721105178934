import * as React from 'react';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { TABLET_MIDDLE_WIDTH } from '../../../utils';
import { useTheme } from '../../../theme';
import SeatInfoGroup from './SeatInfoGroup/SeatInfoGroup';
import FreeSeatInfo from './FreeSeatInfo/FreeSeatInfo';
import { OverrideComponent } from '../../../renderProps';
const SeatsInfo = props => {
    const theme = useTheme('SeatMap').SeatsInfoStyles;
    const seatsByClassesMap = React.useMemo(() => {
        const seatsByClasses = new Map();
        props.seatsInfo.forEach(seat => {
            seatsByClasses.set(seat.serviceClass, seatsByClasses.has(seat.serviceClass) ? [...seatsByClasses.get(seat.serviceClass), seat] : [seat]);
        });
        return seatsByClasses;
    }, [props.seatsInfo]);
    const seatsByClasses = React.useMemo(() => {
        const seatsByClasses = [];
        seatsByClassesMap.forEach((seats, classType) => {
            var _a;
            seatsByClasses.push({
                classType,
                seats,
                image: (_a = seats.find(seat => !!seat.serviceImage)) === null || _a === void 0 ? void 0 : _a.serviceImage
            });
        });
        return seatsByClasses;
    }, [seatsByClassesMap]);
    const renderSeatsByClasses = (mobile) => {
        const seatsInfoGroups = seatsByClasses.map(seats => {
            return (React.createElement("div", { key: seats.classType },
                React.createElement(OverrideComponent, { componentProps: {
                        seatService: seats.seats,
                        fullInfo: mobile,
                        hideImage: false,
                        segmentId: props.segmentId
                    }, component: { SeatInfoGroup } })));
        });
        if (props.hasFreeSeats) {
            seatsInfoGroups.push(React.createElement(FreeSeatInfo, { key: seatsInfoGroups.length }));
        }
        return seatsInfoGroups;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: +TABLET_MIDDLE_WIDTH + 1 },
            React.createElement("aside", { className: cn(theme.info) }, renderSeatsByClasses())),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH }, renderSeatsByClasses(true))));
};
export default SeatsInfo;
