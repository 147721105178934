import { createSelector } from 'reselect';
import { Unvoluntary } from '../types';
import { ExareReason, NASStatus } from '@websky/graphql';
import { getCustomer } from './customer/selectors';
import { getSelectedPassengersWithChildrenIds } from './passengers/selectors';
import { getSelectedReason } from './reason/selectors';
import { getRefundFiles } from './documents/selectors';
import { getNasServices } from './refundInfo/selectors';
import { getInsurancesByTypes } from '../../utils';
export const getState = (state) => state;
export const getOrder = createSelector(getState, state => state.aviaOrder);
export const getRefundMode = createSelector(getState, state => state.mode);
export const isAutoRefundMode = createSelector(getSelectedReason, (reason) => reason === Unvoluntary.Unvoluntary);
export const getIsRefundVoluntary = createSelector(getState, state => !!ExareReason[state.reason]);
export const getRefundSeatReleaseMode = createSelector(getState, state => state.refundInfo.seatReleaseMode);
export const createGetCreateRefundParams = (orderId) => createSelector(getCustomer, getSelectedPassengersWithChildrenIds, isAutoRefundMode, getSelectedReason, getRefundFiles, (customer, selectedPassengersIds, isAutoRefundMode, selectedReason, refundFiles) => {
    var _a;
    if (!customer) {
        return null;
    }
    const isVoluntary = selectedReason === Unvoluntary.Unvoluntary;
    return {
        orderId: orderId,
        isCompelled: !isVoluntary,
        passengers: selectedPassengersIds,
        email: customer.email,
        name: customer.name,
        phone: customer.phone,
        comment: (_a = customer.comments) !== null && _a !== void 0 ? _a : '',
        files: isVoluntary ? [] : refundFiles,
        reason: isVoluntary ? null : selectedReason
    };
});
export const isNasServiceRefundRequired = createSelector([getNasServices], nas => {
    var _a, _b;
    return (_b = (_a = nas === null || nas === void 0 ? void 0 : nas.selectedServices) === null || _a === void 0 ? void 0 : _a.some(selected => selected.orders.some(order => order.status === NASStatus.Confirmed && order.isRefundable))) !== null && _b !== void 0 ? _b : false;
});
export const getSelectedInsurancesByType = (insuranceType) => createSelector(getOrder, order => getInsurancesByTypes(order, insuranceType));
