import { isInfant } from '../../components/SearchForm/Passengers/utils';
export const isResetSelectedOnlyInfants = (state, passengersConfig) => {
    let isReset = true;
    Object.entries(state).forEach(([passengerType, count]) => {
        const passengerConfig = passengersConfig.find(config => config.code === passengerType);
        if ((isInfant(passengerConfig) && count > 0 && count <= (passengerConfig === null || passengerConfig === void 0 ? void 0 : passengerConfig.maxCountWithoutAdult)) ||
            (!isInfant(passengerConfig) && count > 0)) {
            isReset = false;
        }
    });
    return isReset;
};
export const getPassengerCountByType = (passengers, searchingType) => {
    return Object.entries(passengers).reduce((sum, [type, count]) => (type === searchingType ? sum + (count || 0) : sum), 0);
};
export const setPassengerCountLessOrEqualAdt = (state, adultCount, passengersConfig, passengerCode) => {
    const newState = Object.assign({}, state);
    const passengerConfig = passengersConfig.find(config => config.code === passengerCode || config.parentCode === passengerCode);
    const currentCount = getPassengerCountByType(newState, passengerConfig === null || passengerConfig === void 0 ? void 0 : passengerConfig.code);
    if (newState[passengerCode] || newState[passengerConfig === null || passengerConfig === void 0 ? void 0 : passengerConfig.code]) {
        const passengerPath = newState[passengerCode] ? passengerCode : passengerConfig === null || passengerConfig === void 0 ? void 0 : passengerConfig.code;
        newState[passengerPath] = adultCount <= currentCount ? adultCount : currentCount;
    }
    return newState;
};
