export var CartService;
(function (CartService) {
    CartService[CartService["Flights"] = 0] = "Flights";
    CartService[CartService["Seats"] = 1] = "Seats";
    CartService[CartService["Baggage"] = 2] = "Baggage";
    CartService[CartService["Meal"] = 3] = "Meal";
    CartService[CartService["Passengers"] = 4] = "Passengers";
    CartService[CartService["Extra"] = 5] = "Extra";
    CartService[CartService["Aeroexpress"] = 6] = "Aeroexpress";
    CartService[CartService["BusinessLounge"] = 7] = "BusinessLounge";
    CartService[CartService["Insurances"] = 8] = "Insurances";
    CartService[CartService["SMS"] = 9] = "SMS";
})(CartService || (CartService = {}));
