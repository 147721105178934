var _a;
import { animalBaggageTypes, getAiPurchasePopupGoal, getBaggageDatalayerName, getFunnelParameters, getServiceCategory, getTravellersTypes } from './utils';
import { OrderAdditionalServiceGdsServiceServiceType, OrderStatus, CheckinOrderStatus } from '@websky/graphql';
import { DataLayerItemsListName, ItemCategory, PurchaseShowPopupAction, WxEvents } from './types';
import getOrderDataLayer from './orderDataLayerInstance';
import WebskyServicesAnalytics from './WebskyServicesAnalytics';
import { isFareLockService } from '../FareLock/utils';
import Gds from './Diff/Gds';
import Aeroexpress from './Diff/Aeroexpress';
import Seat from './Diff/Seat';
import { getHoldDatalayerItem } from './Diff/utils';
import { convertBaggageObject } from '../Checkout/types';
import { CheckinGoal, InsuranceGoal, PurchaseGoal, reachGoal } from '../analytics';
import { Transliteration } from '../transliteration';
import { CheckinOrderFragmentDoc, CheckoutOrderFragmentDoc } from '@websky/graphql';
import { getCurrency } from '../cache';
class WebskyAnalyticsApollo {
    static isCheckoutOrderGuard(order) {
        return order === null || order === void 0 ? void 0 : order.hasOwnProperty('flight');
    }
    static analyzeOrderQuery(orderAfterChange) {
        const orderBeforeChange = this.classStoredCachedOrder;
        const extraServicesCategories = [
            ItemCategory.Seat,
            ItemCategory.Baggage,
            ItemCategory.Meal,
            ItemCategory.Insurance,
            ItemCategory.Aeroexpress,
            ItemCategory.Pets,
            ItemCategory.Business
        ];
        if (this.isQueryAfterOrderWasPaid(orderAfterChange) || this.isQueryAfterServicesWerePaid(orderAfterChange)) {
            const selectedServices = WebskyServicesAnalytics.getCheckoutSelectedServices(orderBeforeChange // orderBeforeChange because we need services and order.priceToPay.airticket?.amount
            );
            const selectedExtraServices = selectedServices.filter(service => extraServicesCategories.includes(service.category));
            getOrderDataLayer().purchaseOrder(orderAfterChange.locator, selectedServices);
            if (selectedExtraServices.length) {
                reachGoal(PurchaseGoal.PurchaseWithExtraServices, {
                    order_price: selectedExtraServices.length,
                    currency: getCurrency()
                });
            }
        }
        if (this.isQueryAfterOrderWasPaid(orderAfterChange)) {
            const selectedInsurances = WebskyServicesAnalytics.getCheckoutSelectedServices(orderBeforeChange).filter(service => service.category === ItemCategory.Insurance);
            if (selectedInsurances.length) {
                const insurancesToAnalytics = selectedInsurances
                    .map(item => Transliteration.cyrillicToLatin(item.name).replace(/ /g, '_'))
                    .join('_');
                reachGoal((InsuranceGoal.PurchaseWithInsurance + insurancesToAnalytics));
            }
        }
    }
    static analyzeGetCheckinOrderQuery(orderAfterChange) {
        const orderBeforeChange = this.classStoredCachedOrder;
        if ((orderBeforeChange === null || orderBeforeChange === void 0 ? void 0 : orderBeforeChange.status) === CheckinOrderStatus.Started &&
            orderAfterChange.status === CheckinOrderStatus.Confirmed) {
            getOrderDataLayer().purchaseOrder(orderBeforeChange.id, WebskyServicesAnalytics.getCheckinSelectedServices(orderBeforeChange) // orderBeforeChange for correct calculation additional services
            );
        }
    }
    static analyzeCheckinCreateOrderQuery(order) {
        if ((order === null || order === void 0 ? void 0 : order.status) === CheckinOrderStatus.New) {
            reachGoal(CheckinGoal.Create, { funnel_checkin: getFunnelParameters(order) });
        }
    }
    static analyzeCheckinStartQuery(order) {
        if ((order === null || order === void 0 ? void 0 : order.status) === CheckinOrderStatus.Started) {
            reachGoal(CheckinGoal.Start, { funnel_checkin: getFunnelParameters(order) });
        }
    }
    static analyzeCheckinCompleteQuery(order) {
        var _b;
        if (order.status === CheckinOrderStatus.Confirmed && !((_b = order.price.total) === null || _b === void 0 ? void 0 : _b.amount)) {
            reachGoal(CheckinGoal.CompleteWithoutPayment, { funnel_checkin: getFunnelParameters(order) });
        }
    }
    static analyzeCheckinDocumentsQuery(data) {
        const order = this.classStoredCachedOrder;
        if (data) {
            reachGoal(CheckinGoal.BoardingPassDownload, { funnel_checkin: getFunnelParameters(order) });
        }
    }
    static analyzeSaveOrderServicesQuery(orderAfterChange, ecommerceName) {
        if (ecommerceName) {
            reachGoal(ecommerceName, { funnel_purchase: getFunnelParameters(orderAfterChange) });
        }
        const orderBeforeChange = this.classStoredCachedOrder;
        if (!orderBeforeChange) {
            return;
        }
        const added = [];
        const removed = [];
        const differs = [Gds, Seat, Aeroexpress];
        differs.forEach(Differ => {
            const differ = new Differ(orderBeforeChange, orderAfterChange);
            const diff = differ.diff();
            added.push(...diff.added);
            removed.push(...diff.removed);
        });
        getOrderDataLayer().removeServicesFromCart(removed);
        getOrderDataLayer().addServicesToCart(added);
    }
    static analyzePriceFreezeView() {
        var _b, _c, _d, _e, _f;
        const freezeService = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.find(isFareLockService);
        if (freezeService) {
            getOrderDataLayer().setViewItem(getHoldDatalayerItem(freezeService, (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.travellers));
        }
    }
    static analyzeViewItem(e, type) {
        var _b, _c, _d, _e, _f, _g, _h;
        const options = e.detail.options;
        if (!(options === null || options === void 0 ? void 0 : options.servicesIds) || !(options === null || options === void 0 ? void 0 : options.segmentId)) {
            return null;
        }
        const item = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.find(service => {
            var _b;
            return options.servicesIds.includes(service.id) &&
                ((_b = service.allowedSegments) === null || _b === void 0 ? void 0 : _b.some(segmentsIds => segmentsIds.some(segmentId => segmentId === options.segmentId)));
        });
        if (item) {
            getOrderDataLayer().setViewItem({
                category: getServiceCategory(type),
                name: item.name,
                price: item.price.amount,
                segmentId: options.segmentId,
                isRecommend: (_g = (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.travellers) === null || _g === void 0 ? void 0 : _g.some(traveller => {
                    var _b, _c;
                    return (_c = (_b = traveller.preselectedServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => {
                        return service.serviceId === item.id;
                    });
                }),
                count: 1,
                travellersTypes: getTravellersTypes((_h = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _h === void 0 ? void 0 : _h.travellers)
            });
        }
    }
    static analyzeAeroexpressView() {
        var _b, _c, _d, _e, _f;
        const availableServices = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.transfer) === null || _d === void 0 ? void 0 : _d.aeroexpress) === null || _e === void 0 ? void 0 : _e.trips;
        if (availableServices) {
            getOrderDataLayer().setServicesViewItemList(DataLayerItemsListName.Aeroexpress, WebskyServicesAnalytics.aeroexpress(availableServices, (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.travellers));
        }
    }
    static analyzePetsView() {
        var _b, _c, _d, _e, _f;
        const pets = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.filter(service => animalBaggageTypes.includes(service.baggageType) &&
            service.canBeAdded);
        if (pets) {
            getOrderDataLayer().setServicesViewItemList(DataLayerItemsListName.Pets, WebskyServicesAnalytics.baggage(pets.map(service => convertBaggageObject(service)), (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.travellers));
        }
    }
    static analyzeInsuranceView(e) {
        var _b, _c, _d, _e, _f;
        const options = e.detail.options;
        if (!(options === null || options === void 0 ? void 0 : options.servicesIds)) {
            return null;
        }
        const insurances = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.insurances) === null || _d === void 0 ? void 0 : _d.insurancePrograms) === null || _e === void 0 ? void 0 : _e.filter(insurance => options.servicesIds.includes(insurance.code));
        if (insurances) {
            getOrderDataLayer().setServicesViewItemList(DataLayerItemsListName.Insurance, WebskyServicesAnalytics.insurances(insurances, (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.travellers));
        }
    }
    static analyzeBaggageListView() {
        var _b, _c, _d, _e;
        const baggages = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage &&
            !animalBaggageTypes.includes(service.baggageType) &&
            service.canBeAdded);
        const dataLayerBaggages = [];
        baggages === null || baggages === void 0 ? void 0 : baggages.forEach(baggage => {
            var _b;
            (_b = baggage.allowedSegments) === null || _b === void 0 ? void 0 : _b.forEach(segmentIds => {
                segmentIds === null || segmentIds === void 0 ? void 0 : segmentIds.forEach(segmentId => {
                    var _b, _c, _d;
                    dataLayerBaggages.push({
                        name: getBaggageDatalayerName(baggage),
                        price: baggage.price.amount,
                        category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Baggage, baggage.baggageType),
                        segmentId,
                        count: 1,
                        isRecommend: (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.travellers) === null || _c === void 0 ? void 0 : _c.some(traveller => {
                            var _b, _c, _d;
                            return (_d = (_c = (_b = traveller.preselectedServices) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.some(service => {
                                return service.serviceId === baggage.id;
                            });
                        }),
                        travellersTypes: getTravellersTypes((_d = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _d === void 0 ? void 0 : _d.travellers)
                    });
                });
            });
        });
        getOrderDataLayer().setServicesViewItemList(DataLayerItemsListName.Baggage, dataLayerBaggages);
    }
    static analyzeMealsListView(e) {
        var _b, _c, _d, _e;
        const options = e.detail.options;
        if (!(options === null || options === void 0 ? void 0 : options.segmentId)) {
            return;
        }
        const meals = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Meal &&
            service.allowedSegments.some(segmentsIds => segmentsIds.some(segmentId => segmentId === options.segmentId)) &&
            service.canBeAdded);
        const dataLayerMeals = [];
        meals === null || meals === void 0 ? void 0 : meals.forEach(meal => {
            var _b, _c, _d;
            dataLayerMeals.push({
                category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Meal),
                name: meal.name,
                price: meal.price.amount,
                segmentId: options.segmentId,
                isRecommend: (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.travellers) === null || _c === void 0 ? void 0 : _c.some(traveller => {
                    var _b, _c;
                    return (_c = (_b = traveller.preselectedServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => {
                        return service.serviceId === meal.id;
                    });
                }),
                count: 1,
                travellersTypes: getTravellersTypes((_d = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _d === void 0 ? void 0 : _d.travellers)
            });
        });
        getOrderDataLayer().setServicesViewItemList(DataLayerItemsListName.Meal, dataLayerMeals);
    }
    static analyzeSeatsListView(e) {
        var _b, _c, _d, _e, _f, _g, _h, _j;
        const options = e.detail.options;
        if (!(options === null || options === void 0 ? void 0 : options.segmentId)) {
            return;
        }
        const seats = WebskyAnalyticsApollo.isCheckoutOrderGuard(WebskyAnalyticsApollo.classStoredCachedOrder)
            ? (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.flight) === null || _c === void 0 ? void 0 : _c.seatMap) === null || _d === void 0 ? void 0 : _d.segments) === null || _e === void 0 ? void 0 : _e[options.segmentId]
            : (_h = (_g = (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.seatMap) === null || _g === void 0 ? void 0 : _g.segments) === null || _h === void 0 ? void 0 : _h[options.segmentId];
        const travellers = (_j = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _j === void 0 ? void 0 : _j.travellers;
        if (seats && travellers) {
            getOrderDataLayer().setServicesViewItemList(DataLayerItemsListName.Seats, WebskyServicesAnalytics.seats(seats, travellers));
        }
    }
    static analyzeVipListView(e) {
        var _b, _c, _d, _e, _f;
        const options = e.detail.options;
        const vipServices = [];
        if (!(options === null || options === void 0 ? void 0 : options.subType)) {
            return null;
        }
        (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.forEach(service => {
            const typedService = service;
            if (typedService.canBeAdded &&
                [
                    OrderAdditionalServiceGdsServiceServiceType.Vip,
                    OrderAdditionalServiceGdsServiceServiceType.BusinessLounge
                ].includes(typedService.type) &&
                typedService.subType === options.subType &&
                typedService.canBeAdded) {
                vipServices.push(typedService);
            }
        });
        if (vipServices.length) {
            getOrderDataLayer().setServicesViewItemList(WebskyServicesAnalytics.getVipItemListName(vipServices[0]), WebskyServicesAnalytics.vip(vipServices, (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.travellers));
        }
    }
    static analyzeAiPurchaseModalView(e, action) {
        var _b, _c, _d;
        const options = e.detail.options;
        if (!(options === null || options === void 0 ? void 0 : options.servicesIds)) {
            return null;
        }
        const order = WebskyAnalyticsApollo.classStoredCachedOrder;
        const serviceItem = (_d = (_c = (_b = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.find(service => options.servicesIds.includes(service.id));
        if (!serviceItem) {
            return;
        }
        const event = getAiPurchasePopupGoal(serviceItem.type, action);
        reachGoal(event, { funnel_purchase: getFunnelParameters(order) });
    }
    static analyzeWxViewTypeWrapper(e) {
        WebskyAnalyticsApollo.analyzeWxView(e);
    }
    static analyzeWxView(e) {
        switch (e.detail.itemCategory) {
            case WxEvents.PriceFreeze:
                WebskyAnalyticsApollo.analyzePriceFreezeView();
                break;
            case WxEvents.MealItem:
                WebskyAnalyticsApollo.analyzeViewItem(e, OrderAdditionalServiceGdsServiceServiceType.Meal);
                break;
            case WxEvents.AeroexpressList:
                WebskyAnalyticsApollo.analyzeAeroexpressView();
                break;
            case WxEvents.PetsList:
                WebskyAnalyticsApollo.analyzePetsView();
                break;
            case WxEvents.InsurancesList:
                WebskyAnalyticsApollo.analyzeInsuranceView(e);
                break;
            case WxEvents.BaggageList:
                WebskyAnalyticsApollo.analyzeBaggageListView();
                break;
            case WxEvents.BaggageItem:
                WebskyAnalyticsApollo.analyzeViewItem(e, OrderAdditionalServiceGdsServiceServiceType.Baggage);
                break;
            case WxEvents.MealsList:
                WebskyAnalyticsApollo.analyzeMealsListView(e);
                break;
            case WxEvents.SeatsList:
                WebskyAnalyticsApollo.analyzeSeatsListView(e);
                break;
            case WxEvents.VipList:
                WebskyAnalyticsApollo.analyzeVipListView(e);
                break;
            case WxEvents.AiPurchaseModalOpen:
                WebskyAnalyticsApollo.analyzeAiPurchaseModalView(e);
                break;
            case WxEvents.AiPurchaseModalClose:
                WebskyAnalyticsApollo.analyzeAiPurchaseModalView(e, PurchaseShowPopupAction.Decline);
                break;
            default:
                break;
        }
    }
    static setClassStoredOrder(operation, response, client) {
        var _b;
        // key - operationName, value - path to order in response
        const initializationQueriesOperationNameAndPath = {
            CreateOrder: 'CreateOrder',
            FindOrder: 'FindOrder',
            Order: 'Order',
            GetCheckinOrder: 'CheckinOrder',
            CheckinCreateOrder: 'CheckinCreateOrder',
            CheckinStart: 'CheckinStart'
        };
        const currentInitializationQuery = Object.entries(initializationQueriesOperationNameAndPath).find(([name, path]) => operation.operationName === name && response.data[path]);
        const orderId = (_b = response.data[operation.operationName === 'GetCheckinOrder' ? 'CheckinOrder' : operation.operationName]) === null || _b === void 0 ? void 0 : _b.id;
        const cachedCheckoutOrder = WebskyAnalyticsApollo.readCachedOrder(client, orderId, 'checkout');
        const cachedCheckinOrder = WebskyAnalyticsApollo.readCachedOrder(client, orderId, 'checkin');
        if (currentInitializationQuery && !(cachedCheckoutOrder || cachedCheckinOrder)) {
            const [, pathToOrder] = currentInitializationQuery;
            this.classStoredCachedOrder = response.data[pathToOrder];
        }
        else {
            if (cachedCheckoutOrder || cachedCheckinOrder) {
                this.classStoredCachedOrder = cachedCheckoutOrder !== null && cachedCheckoutOrder !== void 0 ? cachedCheckoutOrder : cachedCheckinOrder;
            }
        }
    }
    static listenWxViewEvent() {
        document.removeEventListener(this.wxView, this.analyzeWxViewTypeWrapper);
        document.addEventListener(this.wxView, this.analyzeWxViewTypeWrapper);
    }
    static dispatchWxViewEvent(itemCategory, options) {
        const event = new CustomEvent(this.wxView, { detail: { itemCategory, options } });
        document.dispatchEvent(event);
    }
    static analyze(operation, response, client) {
        if (!(response === null || response === void 0 ? void 0 : response.data)) {
            return;
        }
        WebskyAnalyticsApollo.setClassStoredOrder(operation, response, client);
        switch (operation.operationName) {
            case 'Order':
                this.analyzeOrderQuery(response.data.Order);
                break;
            case 'SaveOrderServices':
                this.analyzeSaveOrderServicesQuery(response.data.SaveOrderServices, operation.getContext().custom_ecommerce_name);
                break;
            case 'CheckinSaveOrderServices':
                this.analyzeSaveOrderServicesQuery(response.data.CheckinSaveOrderServices);
                break;
            case 'GetCheckinOrder':
                this.analyzeGetCheckinOrderQuery(response.data.CheckinOrder);
                break;
            case 'CheckinCreateOrder':
                this.analyzeCheckinCreateOrderQuery(response.data.CheckinCreateOrder);
                break;
            case 'CheckinStart':
                this.analyzeCheckinStartQuery(response.data.CheckinStart);
                break;
            case 'CheckinComplete':
                this.analyzeCheckinCompleteQuery(response.data.CheckinComplete);
                break;
            case 'CheckinDocuments':
                this.analyzeCheckinDocumentsQuery(response.data.CheckinDocuments);
                break;
        }
    }
}
_a = WebskyAnalyticsApollo;
WebskyAnalyticsApollo.wxView = 'wxView';
WebskyAnalyticsApollo.classStoredCachedOrder = null;
WebskyAnalyticsApollo.readCachedOrder = (client, orderId, type) => {
    const params = {
        fragment: type === 'checkout' ? CheckoutOrderFragmentDoc : CheckinOrderFragmentDoc,
        fragmentName: type === 'checkout' ? 'CheckoutOrder' : 'CheckinOrder',
        id: type === 'checkout' ? `AviaOrder:${orderId}` : `CheckinOrder:${orderId}`
    };
    try {
        const order = client.readFragment(params);
        return order;
    }
    catch (e) {
        return null;
    }
};
WebskyAnalyticsApollo.isQueryAfterOrderWasPaid = (orderAfterChange) => {
    const orderBeforeChange = _a.classStoredCachedOrder;
    return (orderBeforeChange === null || orderBeforeChange === void 0 ? void 0 : orderBeforeChange.status) === OrderStatus.Booked && orderAfterChange.status === OrderStatus.Confirmed;
};
WebskyAnalyticsApollo.isQueryAfterServicesWerePaid = (orderAfterChange) => {
    const orderBeforeChange = _a.classStoredCachedOrder;
    const unpaidServicesBeforeChange = WebskyServicesAnalytics.getCheckoutSelectedServices(orderBeforeChange);
    const unpaidServicesAfterChange = WebskyServicesAnalytics.getCheckoutSelectedServices(orderAfterChange);
    return ((orderBeforeChange === null || orderBeforeChange === void 0 ? void 0 : orderBeforeChange.status) === OrderStatus.Confirmed &&
        orderAfterChange.status === OrderStatus.Confirmed &&
        unpaidServicesBeforeChange.length &&
        !unpaidServicesAfterChange.length);
};
export default WebskyAnalyticsApollo;
