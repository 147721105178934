import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MealPage from '../../../../../../MealPage';
import { getCheckinOrder, getCheckinOrderId, getMeals, getMealServices, getMealsPrice, getPassengersNames, getRecommendedMeals, getSegmentsForServices, getTotalPrice, hasBaggageServices, showMealRecommendations } from '../../../../store/order/selectors';
import { useSteps } from '../../../../utils';
import { CheckinStep } from '../../../../types';
import { Money } from '../../../../../../Money';
import { useTheme } from '../../../../../../theme';
import { MEAL_RECOMMENDATION_SHOWN, set } from '../../../../../../cache';
import { setServices, addService, clearService, removeService, saveServices } from '../../../../store/selectedServices/actions';
import { getSelectedMeals, getSelectedServices } from '../../../../store/selectedServices/selectors';
import ServicesToolbar from '../../../../../../ServicesToolbar';
import AvailableServicesCountContextProvider from '../../../../../../AdditionalServices/context';
import { useGetGoBack } from '../utils';
const Meal = ({ isLoading }) => {
    const orderId = useSelector(getCheckinOrderId);
    const order = useSelector(getCheckinOrder);
    const selectedServices = useSelector(getSelectedServices);
    const totalPrice = useSelector(getTotalPrice);
    const mealsPriceToPay = useSelector(getMealsPrice);
    const originalMealServices = useSelector(getMealServices);
    const meals = useSelector(getMeals);
    const isBaggageServiceAvailable = useSelector(hasBaggageServices);
    const selectedMeals = useSelector(getSelectedMeals);
    const recommendedMeals = useSelector(getRecommendedMeals);
    const showRecommendations = useSelector(showMealRecommendations);
    const passengersNames = useSelector(getPassengersNames);
    const segments = useSelector(getSegmentsForServices);
    const dispatch = useDispatch();
    const { goToNextStep, previousStep } = useSteps();
    const { t } = useTranslation('Checkin');
    const { Checkin: css } = useTheme('Checkin');
    // Do not show recommendations twice.
    const cacheRecommendationState = () => set(`${MEAL_RECOMMENDATION_SHOWN}_${orderId}`, true);
    const onMealClear = useCallback((meal, segmentId) => {
        const service = originalMealServices.find(({ id }) => id === meal.id);
        passengersNames.forEach(({ id }) => {
            dispatch(clearService({
                service,
                segmentId,
                passengerId: id
            }));
        });
    }, [passengersNames, originalMealServices]);
    const onMealAdd = useCallback((meal, segmentId, passengerId, count = 1) => {
        dispatch(addService({
            service: originalMealServices.find(({ id }) => id === meal.id),
            segmentId,
            passengerId,
            count
        }));
    }, [originalMealServices]);
    const onMealRemove = useCallback((meal, segmentId, passengerId) => {
        dispatch(removeService({
            service: originalMealServices.find(({ id }) => id === meal.id),
            segmentId,
            passengerId
        }));
    }, [originalMealServices]);
    const onMeadAddForPassengers = useCallback((meal, segmentId, passengerIds) => {
        const service = originalMealServices.find(({ id }) => id === meal.id);
        dispatch(setServices(passengerIds.map(passengerId => ({
            passengerId,
            segmentId,
            service: service,
            serviceId: service.id,
            count: 1
        }))));
    }, [originalMealServices]);
    const onMealConfirm = useCallback(() => {
        dispatch(saveServices());
    }, [originalMealServices]);
    const onProceed = useCallback(() => goToNextStep(CheckinStep.Meal), [goToNextStep]);
    const goBack = useGetGoBack(CheckinStep.Meal);
    const passengers = React.useMemo(() => order.travellers.map(traveller => (Object.assign(Object.assign({}, traveller), { label: passengersNames[traveller.id].name }))), [order.travellers, passengersNames]);
    return (React.createElement(ServicesToolbar, { toolbarProps: {
            totalPrice,
            onButtonClick: onProceed,
            onBackButtonClick: goBack,
            additional: mealsPriceToPay.amount ? (React.createElement("div", { className: css.servicePrice },
                t('Meal'),
                ": ",
                React.createElement(Money, { money: mealsPriceToPay }))) : null
        } },
        React.createElement(AvailableServicesCountContextProvider, { value: { order, selectedServices } },
            React.createElement(MealPage, { showRecommendations: showRecommendations, meals: meals, passengers: passengers, segments: segments, selectedMeals: selectedMeals, recommendedMeals: recommendedMeals, onMealClear: onMealClear, gdsServices: order.additionalServices.gdsServices, onMealAdd: onMealAdd, onMealRemove: onMealRemove, onMealAddForPassengers: onMeadAddForPassengers, onMobileProceed: onProceed, onMobileBack: isBaggageServiceAvailable ? () => previousStep(CheckinStep.Meal) : null, onMealConfirm: onMealConfirm, onRecommendationConfirm: () => {
                    // #TODO
                    cacheRecommendationState();
                }, onRecommendationDisagree: cacheRecommendationState, isLoading: isLoading }))));
};
export default Meal;
