import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
const StatusMessage = props => {
    const { type, title, text } = props;
    const { StatusMessage: css } = useTheme('StatusMessage');
    return (React.createElement("div", { className: cn(css.statusMessage, {
            [css.statusMessage_success]: type === 'success',
            [css.statusMessage_fail]: type === 'fail'
        }) },
        React.createElement("h3", { className: css.statusMessage__title }, title),
        React.createElement("p", { className: css.statusMessage__text }, text)));
};
export default StatusMessage;
