import { __awaiter } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import MetasearchLoader from '../../../MetasearchLoader';
import { isOdd, changeLocale } from '../../../utils';
import { setSplitTest, SplitTest } from '../../../analytics';
import { useHandleMetasearchRedirectMutation } from '@websky/graphql';
const MetaRedirect = ({ onSuccess, onResultsRedirect, location, match }) => {
    let token = match.params['token'];
    const [hasErrors, setHasErrors] = useState(false);
    const redirectParams = parse(location.search);
    const [loadOrderMutation] = useHandleMetasearchRedirectMutation();
    const loadOrder = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setHasErrors(false);
        try {
            const data = yield loadOrderMutation({
                variables: {
                    id: token
                }
            });
            if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.HandleMetasearchRedirect) {
                if (data.data.HandleMetasearchRedirect.order) {
                    setSplitTest(SplitTest.SkipServicesSteps, !data.data.HandleMetasearchRedirect.order.showServicesBeforeBooking);
                    setSplitTest(SplitTest.SkipServicesRecommendations, isOdd(data.data.HandleMetasearchRedirect.order.id));
                    onSuccess(data.data.HandleMetasearchRedirect.order, true);
                }
                else if (data.data.HandleMetasearchRedirect.searchId) {
                    onResultsRedirect(data.data.HandleMetasearchRedirect.searchId);
                }
                else {
                    setHasErrors(true);
                }
            }
            else {
                setHasErrors(true);
            }
        }
        catch (e) {
            setHasErrors(true);
            console.error(e);
        }
    });
    useEffect(() => {
        if (token) {
            if (redirectParams === null || redirectParams === void 0 ? void 0 : redirectParams.language) {
                changeLocale(redirectParams.language);
            }
            loadOrder();
        }
        return () => {
            token = '';
        };
    }, []);
    return React.createElement(MetasearchLoader, { hasError: hasErrors, variant: redirectParams.source, onRetry: loadOrder });
};
export default withRouter(MetaRedirect);
