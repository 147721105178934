import { __rest } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useConfig } from '../../../context';
import { getLocale } from '../../../utils';
import Money from '../../../Money';
import { isZeroPrice as getIsZeroPrice } from '../../utils';
const SeatPrice = (_a) => {
    var { className, priceFromClassName, moneyClassName, amountClassName, currencyClassName, priceFrom } = _a, props = __rest(_a, ["className", "priceFromClassName", "moneyClassName", "amountClassName", "currencyClassName", "priceFrom"]);
    const { t } = useTranslation('SeatMap');
    const { SeatPrice: theme } = useTheme('SeatMap');
    const { global: { companyInfo: { iataCode } } } = useConfig();
    const locale = getLocale();
    const showFromAfterMoney = ['A9', 'RM'].includes(iataCode) && ['ka', 'hy'].includes(locale);
    const isZeroPrice = getIsZeroPrice(props.money);
    const money = (React.createElement(React.Fragment, null,
        priceFrom && !showFromAfterMoney && React.createElement("span", { className: priceFromClassName }, t('from')),
        React.createElement(Money, Object.assign({ moneyClassName: cn(moneyClassName, theme.money), amountClassName: cn(amountClassName, theme.amount), currencyClassName: cn(currencyClassName, theme.currency) }, props)),
        priceFrom && showFromAfterMoney && React.createElement("span", { className: priceFromClassName }, t('from'))));
    if (!isZeroPrice) {
        return React.createElement("span", { className: className }, money);
    }
    return (React.createElement("span", { className: cn(theme.root, theme.root_zeroPrice, className) },
        isZeroPrice && React.createElement("span", { className: theme.root__text }, t('Free seat')),
        React.createElement("span", { className: theme.root__money }, money)));
};
export default SeatPrice;
