import { TravellerFieldEnum } from '@websky/graphql';
export const initialPassengersValuesFactory = (passengers, withDisabilities) => {
    return passengers.map(passenger => {
        const values = {
            type: passenger.type
        };
        if (withDisabilities) {
            values.disabilities = { enabled: false, values: {} };
        }
        passenger.values.forEach(value => {
            if (value.type === TravellerFieldEnum.Nationality &&
                !value.validationRules[0].options.find(option => option.value === value.value)) {
                value.value = value.validationRules[0].options[0].value;
            }
            else {
                values[value.name] = value.value;
            }
        });
        return values;
    });
};
