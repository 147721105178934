import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import { Currency, CurrencySymbols } from '../../enums';
import { useTheme } from '../../theme';
import { getFormattedAmount } from '../utils';
import { useConfig } from '../../context';
export const Money = ({ money, moneyClassName, amountClassName, currencyClassName, multiplyBy = 1, amountRenderer, currencyRenderer, showCurrency = true, showCurrencyBeforeAmount = false, roundType }) => {
    const { Money: css } = useTheme('Money');
    const { global: { roundMoneyAmount } } = useConfig();
    if (!money) {
        return null;
    }
    // TODO:
    const splitMoneyMin = 999;
    const moneyRoundType = roundType || (roundMoneyAmount && 'round') || null;
    const formattedAmount = React.useMemo(() => getFormattedAmount(money, multiplyBy, moneyRoundType, splitMoneyMin), [
        moneyRoundType,
        money,
        multiplyBy,
        splitMoneyMin
    ]);
    const currencySymbol = CurrencySymbols[money.currency];
    const hasCurrency = money && showCurrency;
    const isCurrencyBefore = [Currency.USD].includes(money.currency);
    const showCurrencyBefore = isCurrencyBefore || showCurrencyBeforeAmount;
    const renderCurrency = useCallback(() => {
        if (currencyRenderer) {
            return currencyRenderer(currencySymbol);
        }
        return (React.createElement("span", { className: cn(currencyClassName, {
                [css.currency_before]: showCurrencyBefore,
                [css.currency_after]: !showCurrencyBefore
            }) }, currencySymbol));
    }, [currencySymbol, currencyRenderer, showCurrencyBefore]);
    return (React.createElement("span", { className: cn(css.money, moneyClassName) },
        hasCurrency && showCurrencyBefore && renderCurrency(),
        amountRenderer ? (amountRenderer(money.amount, money.currency)) : (React.createElement("span", { className: amountClassName }, formattedAmount ? formattedAmount : (money === null || money === void 0 ? void 0 : money.currency) ? '0' : null)),
        hasCurrency && !showCurrencyBefore && renderCurrency()));
};
export default Money;
