import classnames from 'classnames';
import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMultiCity } from '../../../store/segments/selectors';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import { getHasPassengersErrors, getTotalPassengersCount } from '../../../store/passengers/selectors';
import { useForm } from 'react-final-form';
import { Search } from '../../../../Icons';
import { useIsWithSelectableCurrencies } from '../hooks';
import { BaseButton } from '../../../../index';
const StartSearch = React.memo(props => {
    const { StartSearch: css } = useTheme('SearchForm');
    const { t } = useTranslation('SearchForm');
    const hasPassengersErrors = useSelector(getHasPassengersErrors);
    const { resumeValidation } = useForm();
    const withSelectableCurrencies = useIsWithSelectableCurrencies();
    const { SearchForm: { proMode } } = useConfig();
    return (React.createElement("div", { className: classnames(css.startSearch, {
            [css.startSearch_iconMode]: props.mode === 'icon',
            [css.startSearch_multiCity]: props.isMultiCity,
            [css.startSearch_withCurrency]: withSelectableCurrencies,
            [css.startSearch_advanced]: proMode
        }) },
        React.createElement(BaseButton, { type: "submit", variant: "smallPrimary", disabled: hasPassengersErrors, onClick: resumeValidation }, props.mode === 'icon' ? React.createElement(React.Fragment, null, Search) : React.createElement("span", { className: css.text },
            " ",
            t('Search'),
            " "))));
});
const mapStateToProps = (state, props) => {
    return Object.assign(Object.assign({}, props), { isMultiCity: isMultiCity(state), passengersCount: getTotalPassengersCount(state) });
};
export default connect(mapStateToProps)(StartSearch);
