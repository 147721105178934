import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { FlightClass, InfoMessagePositionsEnum } from '@websky/graphql';
import { InfoDetails, BaseButton } from '../../../index';
import { useDesktopFlightModalContent, useLastSelectedFlight } from './hooks';
import { Tabs } from '../../types';
import { useTheme } from '../../../theme';
import { FlightInfoV2 } from '../../../FlightInfo';
import { usePagination } from '../../../Pagination/hooks';
import { initI18n, MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../../utils';
import Pagination from '../../../Pagination';
import Modal from '../../../Modal';
import CompareFares from '../../../CompareFares/components/CompareFares';
import Link from '../../../Link';
import FareGroupGrid from '../../../FareGroup/components/FareGroupGrid/FareGroupGrid';
import { OverrideComponent } from '../../../renderProps';
import { findIndexFirstAvailableFareGroup, isFareGroupAvailable, isFaresHasOneItem, isShowFaresPagination, roundAvailableSliderIndex } from './utils';
import FlightInfoSegment from '../FlightInfoSegment/FlightInfoSegment';
import { getDurationString, getFlightDuration } from '../../../Results/utils';
import DesktopFlightModalHeader from '../DesktopFlightModalHeader/DesktopFlightModalHeader';
import { getNextSegment } from '../../utils';
import { analytics_fareAddToCart, analytics_setFareGroupsListItems } from '../../../Results/components/Results/dataLayer/results';
import InfoMessages from '../../../InfoMessages';
import { filterMessageByPosition } from '../../../InfoMessages/utils';
import Dialog from '../../../Service/components/Dialog/Dialog';
import { SwipeableDrawer } from '@material-ui/core';
import { useToggleable } from '../../../hooks';
initI18n('FlightInfoModalContent');
const DesktopFlightModalContent = props => {
    var _a, _b;
    const { isSelectorInModal = true } = props;
    const { active, handleBusinessButtonClick, handleEconomyButtonCLick, handleMilesButtonClick, isFareCompareOpen, openFareCompare, closeFareCompare } = useDesktopFlightModalContent(props);
    const { t } = useTranslation('FlightInfoModalContent');
    const isTablet = useMediaQuery({ maxWidth: TABLET_MIN_WIDTH });
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const isSelectorInModalOrMobile = isSelectorInModal || isMobile;
    const allFares = !isSelectorInModalOrMobile
        ? [...props.economyFares, ...props.businessFares, ...props.milesFares]
        : [];
    const lastSelectedFare = useLastSelectedFlight();
    const isActiveEconomyFares = active === Tabs.EconomyFares;
    const isActiveBusinessFares = active === Tabs.BusinessFares;
    const isActiveMilesFares = active === Tabs.MilesFares;
    const isActiveFlightInfo = active === Tabs.FlightInfo;
    const isOneEconomyFare = isFaresHasOneItem(props.economyFares);
    const isOneBusinessFare = isFaresHasOneItem(props.businessFares);
    const isOneMilesFare = isFaresHasOneItem(props.milesFares);
    const isOneAllFare = isFaresHasOneItem(allFares);
    const slidesToShow = isTablet ? (isMobile ? 1.05 : 2) : (_b = (_a = props.sliderSettings) === null || _a === void 0 ? void 0 : _a.slidesToShow) !== null && _b !== void 0 ? _b : 3;
    const slidesToScroll = slidesToShow >= 2 ? slidesToShow : 1;
    const slidesToShowOnce = props.slidesToShowOnce || 1.1;
    const { isOpen, open, close, toggle } = useToggleable(false);
    const [isTooltipsOpened, setTooltipsOpened] = React.useState(false);
    const { firstEconomyAvailableSliderIndex, firstBusinessAvailableSliderIndex } = useMemo(() => {
        const firstEconomyAvailableFareGroup = findIndexFirstAvailableFareGroup(props.economyFares, lastSelectedFare);
        const firstBusinessAvailableFareGroup = findIndexFirstAvailableFareGroup(props.businessFares, lastSelectedFare);
        const firstEconomyAvailableSliderIndex = roundAvailableSliderIndex(firstEconomyAvailableFareGroup, slidesToScroll);
        const firstBusinessAvailableSliderIndex = roundAvailableSliderIndex(firstBusinessAvailableFareGroup, slidesToScroll);
        return {
            firstEconomyAvailableSliderIndex,
            firstBusinessAvailableSliderIndex
        };
    }, [props.economyFares, props.businessFares, lastSelectedFare, slidesToScroll]);
    const { currentIndex: economySliderIndex, handleChange: handleEconomySliderIndexChange } = usePagination(firstEconomyAvailableSliderIndex);
    const { currentIndex: businessSliderIndex, handleChange: handleBusinessSliderIndexChange } = usePagination(firstBusinessAvailableSliderIndex);
    const { currentIndex: milesSliderIndex, handleChange: handleMilesSliderIndexChange } = usePagination(0);
    const { currentIndex: allFaresSliderIndex, handleChange: handleAllFaresSliderIndexChange } = usePagination(0);
    const economySliderRef = React.useRef();
    const businessSliderRef = React.useRef();
    const milesSliderRef = React.useRef();
    useEffect(() => {
        // return to previous item when user change active tab
        handleEconomySliderIndexChange(firstEconomyAvailableSliderIndex);
        handleBusinessSliderIndexChange(firstBusinessAvailableSliderIndex);
    }, [active]);
    useEffect(() => {
        var _a;
        (_a = props.setIsComparisonOpen) === null || _a === void 0 ? void 0 : _a.call(props, isFareCompareOpen);
    }, [isFareCompareOpen]);
    const { ModalContent: theme } = useTheme('DesktopFlight');
    const renderFareGroup = (group, index) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const isEconomyFareGroup = group.fareFamily.category === FlightClass.Economy;
        const isAvailable = isFareGroupAvailable(group, lastSelectedFare);
        const disabledForPriceInMiles = ((_b = (_a = group.prices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.priceInMiles) > 0 && !((_e = (_d = (_c = group.prices) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.flight) === null || _e === void 0 ? void 0 : _e.canBePaidByMiles);
        const onFareSelect = () => {
            analytics_fareAddToCart(group, index, props.flight, props.searchParameters);
            props.getOnFareSelect(group)();
        };
        const fareGroupGridProps = {
            className: cn(theme.fareGroup__slide, { [theme.fareGroup_withoutUpgradeOption]: !!props.lastSelectedFare }),
            showUnavailableOptions: true,
            onUpgrade: isEconomyFareGroup &&
                props.upgradeOptions.has(group.fareFamily.id) &&
                props.getOnFareSelect(props.upgradeOptions.get(group.fareFamily.id).canUpgradeTo),
            upgradeOption: isEconomyFareGroup && props.upgradeOptions.get(group.fareFamily.id),
            variant: 'white',
            isDisabled: !isAvailable || disabledForPriceInMiles,
            fare: group.fareFamily,
            onSelect: onFareSelect,
            price: !props.hidePrice && group.prices && group.prices[0] ? group.prices[0].price : null,
            originalPrice: !props.hidePrice && group.prices && group.prices[0] && group.prices[0].originalPrice
                ? group.prices[0].originalPrice
                : null,
            flightId: (_g = (_f = group.prices) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.flight.id,
            priceInMiles: (_j = (_h = group.prices) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.priceInMiles,
            disabledForMiles: disabledForPriceInMiles,
            orderId: props.orderId,
            isTooltipsOpened,
            setTooltipsOpened,
            flightMessages: props.flight.segments[0].segment.messages
        };
        return React.createElement(OverrideComponent, { componentProps: fareGroupGridProps, component: { FareGroupGrid }, key: index });
    };
    const renderSliderIndex = (slidesCount, index, slidesToScroll) => {
        let pagesCount = Math.ceil(slidesCount / slidesToScroll);
        if (isMobile) {
            pagesCount = slidesCount;
        }
        if ((isTablet && slidesCount > 2) || slidesCount > sliderSettings.slidesToScroll) {
            return (React.createElement("span", { className: theme.pagination__index },
                Math.ceil(index / slidesToScroll) + 1,
                " / ",
                pagesCount));
        }
        else {
            return null;
        }
    };
    const isEconomyExists = React.useMemo(() => {
        return props.economyFares.some(economyFare => economyFare.prices);
    }, [props.economyFares]);
    const isBusinessFaresExists = React.useMemo(() => {
        return props.businessFares.some(businessFare => businessFare.prices);
    }, [props.businessFares]);
    const isMilesExists = React.useMemo(() => {
        return props.milesFares.some(milesFare => milesFare.prices);
    }, [props.milesFares]);
    const container = useRef();
    const sliderAnchorRef = useRef();
    const onScrollTop = useCallback(() => {
        container.current.scrollIntoView({
            block: 'start',
            behavior: 'smooth'
        });
    }, [container]);
    React.useEffect(() => {
        var _a;
        analytics_setFareGroupsListItems([...props.economyFares, ...props.businessFares, ...props.milesFares], props.flight, props.searchParameters);
        (_a = sliderAnchorRef === null || sliderAnchorRef === void 0 ? void 0 : sliderAnchorRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, []);
    const renderInfoMessages = (position, renderCallback) => {
        var _a, _b, _c;
        const messages = (_c = (_b = (_a = props.flight.segments[0]) === null || _a === void 0 ? void 0 : _a.segment) === null || _b === void 0 ? void 0 : _b.messages) === null || _c === void 0 ? void 0 : _c.filter(message => filterMessageByPosition(position, message));
        // to prevent the slide from being displayed if there are no messages
        if (messages === null || messages === void 0 ? void 0 : messages.length) {
            return renderCallback();
        }
        return null;
    };
    const infoMessageByClass = React.useMemo(() => {
        const position = active === Tabs.EconomyFares
            ? InfoMessagePositionsEnum.upsaleEconomy
            : InfoMessagePositionsEnum.upsaleBusiness;
        return renderInfoMessages(position, () => (React.createElement(InfoMessages, { position: position, className: theme.infoMessage, itemHtmlClassName: theme.infoMessage__htmlMessage, htmlClassName: theme.infoMessage__htmlContainer, messages: props.flight.segments[0].segment.messages })));
    }, [props.flight.segments, active]);
    const infoMessageRight = React.useMemo(() => {
        const position = InfoMessagePositionsEnum.upsaleRight;
        return renderInfoMessages(position, () => (React.createElement(InfoMessages, { position: position, className: cn(theme.infoMessage, theme.upsaleRightInfoMessage), itemHtmlClassName: theme.infoMessage__htmlMessage, contentClassName: theme.infoMessage__contentClassName, messages: props.flight.segments[0].segment.messages })));
    }, [props.flight.segments]);
    const hasInfoMessage = !!infoMessageByClass || !!infoMessageRight;
    const isOneActiveFare = (isActiveEconomyFares && isOneEconomyFare) ||
        (isActiveBusinessFares && isOneBusinessFare) ||
        (isActiveMilesFares && isOneMilesFare && !infoMessageRight);
    const sliderSettings = Object.assign(Object.assign({ slidesToShow: isOneActiveFare ? slidesToShowOnce : slidesToShow, infinite: false, speed: 250, slidesToScroll, swipe: isTablet }, props.sliderSettings), { className: theme.slider, onSwipe: () => {
            setTooltipsOpened(false);
        } });
    const allSliderSettings = Object.assign(Object.assign({}, sliderSettings), { slidesToShow: isOneAllFare ? slidesToShowOnce : slidesToShow, className: cn(sliderSettings.className, { [theme.slider_allOnce]: isOneAllFare }), beforeChange(_, nextSlide) {
            handleAllFaresSliderIndexChange(nextSlide);
        } });
    const economySliderSettings = Object.assign(Object.assign({}, sliderSettings), { className: cn(sliderSettings.className, theme.slider_economy), initialSlide: firstEconomyAvailableSliderIndex, beforeChange(currentSlide, nextSlide) {
            handleEconomySliderIndexChange(nextSlide);
        } });
    const businessSliderSettings = Object.assign(Object.assign({}, sliderSettings), { className: cn(sliderSettings.className, theme.slider_business), initialSlide: firstBusinessAvailableSliderIndex, beforeChange(currentSlide, nextSlide) {
            handleBusinessSliderIndexChange(nextSlide);
        } });
    const milesSliderSettings = Object.assign(Object.assign({}, sliderSettings), { className: cn(sliderSettings.className, theme.slider_miles), beforeChange(currentSlide, nextSlide) {
            handleMilesSliderIndexChange(nextSlide);
        } });
    const sliderWrapperClassName = cn(theme.slider__wrp, {
        [theme.slider__wrp_singleItem]: isOneActiveFare && !hasInfoMessage
    });
    return (React.createElement(Paper, { elevation: 0, className: cn(theme.paper, {
            [theme.paper_dropdown]: !isSelectorInModalOrMobile,
            [theme.paper_pagination]: !isSelectorInModalOrMobile && allFares.length > allSliderSettings.slidesToScroll
        }), ref: container },
        isSelectorInModalOrMobile && (React.createElement(DesktopFlightModalHeader, { title: t('Select your bundle'), onClose: props.onClose })),
        React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.upsaleTop, className: theme.topInfoMessage, itemHtmlClassName: theme.topInfoMessage__htmlMessage, messages: props.flight.segments[0].segment.messages }),
        React.createElement("div", { className: theme.buttons },
            isSelectorInModalOrMobile && (React.createElement(React.Fragment, null,
                (isEconomyExists || (!isMilesExists && !isBusinessFaresExists)) && (React.createElement(BaseButton, { variant: "optionAction", className: cn(theme.fareGroup__button, {
                        [theme.fareGroup__button_active]: isActiveEconomyFares
                    }), onClick: handleEconomyButtonCLick }, t('Economy'))),
                isBusinessFaresExists && (React.createElement(BaseButton, { variant: "optionAction", className: cn(theme.fareGroup__button, theme.fareGroup__button_business, {
                        [theme.fareGroup__button_active]: isActiveBusinessFares
                    }), onClick: handleBusinessButtonClick }, t('Business'))),
                isMilesExists && (React.createElement(BaseButton, { variant: "optionAction", className: cn(theme.fareGroup__button, theme.fareGroup__button, {
                        [theme.fareGroup__button_active]: isActiveMilesFares
                    }), onClick: handleMilesButtonClick }, t('Premium'))))),
            active !== Tabs.FlightInfo && (React.createElement(Link, { className: theme.compareFaresLink, action: openFareCompare },
                t('View full conditions'),
                React.createElement("span", { className: theme.compareFaresLink_icon }, InfoDetails)))),
        React.createElement("div", { className: theme.content },
            React.createElement("div", { className: cn(theme.content_wrapper, {
                    [theme.content_offset]: hasInfoMessage
                }) },
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement("div", null, allFares.length > 0 ? (React.createElement("div", { className: sliderWrapperClassName },
                        React.createElement(Slider, Object.assign({}, allSliderSettings), allFares.map(renderFareGroup)),
                        renderSliderIndex(allFares.length, allFaresSliderIndex, allSliderSettings.slidesToScroll))) : (React.createElement(React.Fragment, null,
                        isActiveEconomyFares && (React.createElement("div", { className: sliderWrapperClassName },
                            React.createElement(Slider, Object.assign({}, economySliderSettings),
                                infoMessageByClass,
                                props.economyFares.map(renderFareGroup),
                                infoMessageRight),
                            renderSliderIndex(props.economyFares.length, economySliderIndex, slidesToScroll))),
                        isActiveBusinessFares && (React.createElement("div", { className: sliderWrapperClassName },
                            React.createElement(Slider, Object.assign({}, businessSliderSettings),
                                infoMessageByClass,
                                props.businessFares.map(renderFareGroup),
                                infoMessageRight),
                            renderSliderIndex(props.businessFares.length, businessSliderIndex, slidesToScroll))),
                        isActiveMilesFares && (React.createElement("div", { className: sliderWrapperClassName },
                            React.createElement(Slider, Object.assign({}, milesSliderSettings),
                                props.milesFares.map(renderFareGroup),
                                infoMessageRight),
                            renderSliderIndex(props.milesFares.length, milesSliderIndex, milesSliderSettings.slidesToShow)))))),
                    React.createElement("div", { ref: sliderAnchorRef }),
                    isActiveFlightInfo && (React.createElement(OverrideComponent, { componentProps: {
                            flight: props.flight,
                            selectedFareFamily: props.lastSelectedFare
                        }, component: { DesktopFlightContentFlightInfo: FlightInfoV2 } }))),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    isActiveEconomyFares && (React.createElement("div", { className: sliderWrapperClassName },
                        React.createElement(Slider, Object.assign({}, economySliderSettings, { ref: economySliderRef }), props.economyFares.map(renderFareGroup)),
                        isShowFaresPagination(props.economyFares) && (React.createElement(Pagination, { className: theme.pagination, itemsCount: props.economyFares.length, currentIndex: economySliderIndex, onChange: index => economySliderRef.current.slickGoTo(index) })))),
                    isActiveBusinessFares && (React.createElement("div", { className: sliderWrapperClassName },
                        React.createElement(Slider, Object.assign({}, businessSliderSettings, { ref: businessSliderRef }), props.businessFares.map(renderFareGroup)),
                        isShowFaresPagination(props.businessFares) && (React.createElement(Pagination, { className: theme.pagination, itemsCount: props.businessFares.length, currentIndex: businessSliderIndex, onChange: index => businessSliderRef.current.slickGoTo(index) })))),
                    isActiveMilesFares && (React.createElement("div", { className: sliderWrapperClassName },
                        React.createElement(Slider, Object.assign({}, milesSliderSettings, { ref: milesSliderRef }), props.milesFares.map(renderFareGroup)),
                        isShowFaresPagination(props.milesFares) && (React.createElement(Pagination, { className: theme.pagination, itemsCount: props.milesFares.length, currentIndex: milesSliderIndex, onChange: index => milesSliderRef.current.slickGoTo(index) })))),
                    React.createElement("div", { ref: sliderAnchorRef }),
                    (infoMessageRight || infoMessageByClass) && (React.createElement(SwipeableDrawer, { anchor: "bottom", classes: { paper: theme.swipeable__paper }, open: isOpen, onOpen: open, onClose: close, onClick: toggle, swipeAreaWidth: 76, disableSwipeToOpen: false, ModalProps: { keepMounted: true } }, infoMessageRight !== null && infoMessageRight !== void 0 ? infoMessageRight : infoMessageByClass))),
                isSelectorInModalOrMobile && (React.createElement("div", { className: theme.segments },
                    props.flight.segments.map((segment, index) => (React.createElement(FlightInfoSegment, { key: index, className: theme.segments__flight, segment: segment, nextSegment: segment.transferDuration ? getNextSegment(props.flight.segments, index) : null, isReturnFlight: props.isReturnFlight }))),
                    props.flight.segments.length > 1 && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: theme.total_time },
                            t('Total duration'),
                            ":",
                            ' ',
                            getDurationString(getFlightDuration(props.flight.segments))),
                        React.createElement("div", { className: theme.content_footer },
                            React.createElement(BaseButton, { variant: "smallPrimary", onClick: onScrollTop, className: theme.scroll_up }, t('Scroll up'))))))))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: isFareCompareOpen, onClose: closeFareCompare, isRounded: true },
                React.createElement(CompareFares, { flightId: props.flightId, orderId: props.orderId }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dialog, { isOpen: isFareCompareOpen, dialogType: "fullscreen", dialogHeader: t('MobileSelectedFare:Compare fare families'), onClose: closeFareCompare, content: React.createElement(CompareFares, { flightId: props.flightId, orderId: props.orderId }), mountOnEnter: true }))));
};
export default DesktopFlightModalContent;
