import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
const RouteArrow = ({ className, timeInRouteClassName, flightNumberClassName, routeClassName, circleClassName, timeInRoute, flightNumber, mobile, stopCount }) => {
    const theme = useTheme('DesktopFlightRoute').RouteArrowStyles;
    const { t } = useTranslation('DesktopFlight');
    return (React.createElement("div", { className: cn(theme.container, {
            [theme.mobile]: mobile
        }, className) },
        React.createElement("div", { className: cn(theme.route, routeClassName) },
            React.createElement("div", { className: cn(theme.circle, circleClassName) }),
            React.createElement("div", { className: cn(theme.timeInRoute, timeInRouteClassName) }, timeInRoute),
            React.createElement("div", { className: cn(theme.circle, circleClassName, theme.circle_right) })),
        flightNumber && !stopCount && (React.createElement("div", { className: cn(theme.flightNumber, flightNumberClassName) }, flightNumber)),
        stopCount > 0 && (React.createElement("div", { className: cn(theme.flightNumber, theme.stop) },
            stopCount,
            " ",
            t(stopCount > 1 ? 'stops' : 'stop')))));
};
export default RouteArrow;
