import * as React from 'react';
import { useTheme } from '../../theme';
import Link from '../../Link';
import { infoIcon } from '../Insurance';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../utils';
import { useTranslation } from 'react-i18next';
const closeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z", fill: "white" })));
const MobileScreen = props => {
    const { t } = useTranslation('AdditionalServiceCard');
    const theme = useTheme('Insurance').MobileScreenStyles;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    return (React.createElement("div", null,
        React.createElement("div", { className: theme.header },
            t('Insurance'),
            React.createElement("div", { className: theme.close, onClick: props.onClose }, closeIcon)),
        isMobile && props.rulesURL && (React.createElement(Link, { action: props.rulesURL, target: "_blank", className: theme.rules },
            t('Terms of service'),
            infoIcon)),
        React.createElement("div", null, props.content)));
};
export default MobileScreen;
