import { BaggageDirection } from '../BaggageList/types';
export const convertBaggageOnBaggageItem = (baggages, selectedServices, segments) => {
    var _a, _b, _c;
    const firstBaggage = baggages[0];
    const minPrice = {
        amount: ((_a = firstBaggage.price) === null || _a === void 0 ? void 0 : _a.amount) * ((_b = firstBaggage.segmentIds[0]) === null || _b === void 0 ? void 0 : _b.length),
        currency: (_c = firstBaggage.price) === null || _c === void 0 ? void 0 : _c.currency
    };
    baggages === null || baggages === void 0 ? void 0 : baggages.forEach(baggage => {
        baggage.segmentIds.forEach(segmentId => {
            if (!minPrice.amount || baggage.price.amount * segmentId.length < (minPrice === null || minPrice === void 0 ? void 0 : minPrice.amount)) {
                minPrice.amount = baggage.price.amount * segmentId.length;
                minPrice.currency = baggage.price.currency;
            }
        });
    });
    return {
        baggage: baggages,
        selected: isSelected(baggages[0], selectedServices),
        priceFrom: minPrice,
        servicesBySegments: getSelectedServicesBySegments(baggages, selectedServices, segments)
    };
};
export const getSelectedServicesBySegments = (baggages, selectedServices, segments) => {
    // Collect selected services, merging segmentIds and count
    const selectedServicesByPassengerMap = new Map();
    selectedServices.forEach(selectedService => {
        var _a, _b;
        if (baggages.some(baggage => baggage.id === selectedService.serviceId)) {
            const key = [selectedService.passengerId, selectedService.serviceId, selectedService.count].join('-');
            const existService = selectedServicesByPassengerMap.get(key);
            const segmentIds = [...new Set([...((_a = existService === null || existService === void 0 ? void 0 : existService.segmentIds) !== null && _a !== void 0 ? _a : []), ...selectedService.segmentIds])];
            const count = Math.max((_b = existService === null || existService === void 0 ? void 0 : existService.count) !== null && _b !== void 0 ? _b : 0, selectedService.count);
            selectedServicesByPassengerMap.set(key, Object.assign(Object.assign({}, selectedService), { segmentIds,
                count }));
        }
    });
    const selectedServicesMap = new Map();
    selectedServicesByPassengerMap.forEach(selectedService => {
        var _a, _b;
        const key = [selectedService.serviceId, ...selectedService.segmentIds].join('/');
        const count = ((_b = (_a = selectedServicesMap.get(key)) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0) + selectedService.count;
        const allSegments = selectedService.segmentIds.length === segments.length;
        const service = baggages.find(baggage => baggage.id === selectedService.serviceId);
        const directions = segments
            .filter(segment => selectedService.segmentIds.includes(segment.id))
            .map(segment => ({
            departure: { iata: segment.departure.airport.iata },
            arrival: { iata: segment.arrival.airport.iata }
        }));
        selectedServicesMap.set(key, {
            count,
            allSegments,
            directions,
            service
        });
    });
    const selectedSegmentIdSet = new Set();
    selectedServicesByPassengerMap.forEach(selectedService => {
        selectedService.segmentIds.forEach(id => selectedSegmentIdSet.add(id));
    });
    const selectedBaggageServices = [...selectedServicesMap.values()];
    // If the count and allSegments of all selected services matches, then display as for all flights
    if (selectedSegmentIdSet.size === segments.length &&
        selectedBaggageServices.every(selectedService => {
            const firstSelectedBaggage = selectedBaggageServices[0];
            return (firstSelectedBaggage.count === selectedService.count &&
                firstSelectedBaggage.allSegments === selectedService.allSegments);
        })) {
        return [Object.assign(Object.assign({}, selectedBaggageServices[0]), { allSegments: true })];
    }
    return selectedBaggageServices;
};
export const getSegmentsIatasKey = (leftSegment, rightSegment, separator = ' – ') => {
    return [leftSegment.departure.airport.iata, rightSegment.arrival.airport.iata].join(separator);
};
export const isSelected = (baggage, selectedServices) => {
    return calcCount(baggage, selectedServices) > 0;
};
export const calcCount = (baggage, selectedServices) => {
    return selectedServices.filter(el => el.serviceId === baggage.id).reduce((acc, el) => acc + el.count, 0);
};
export const detectDirection = (baggage, selectedServices, order) => {
    const firstSegment = order.flight.segments[0];
    const lastSegment = order.flight.segments[order.flight.segments.length - 1];
    const allDirections = order === null || order === void 0 ? void 0 : order.travellers.map(traveller => {
        const services = selectedServices.filter(el => traveller.id === el.passengerId);
        if (services.length === 0) {
            return null;
        }
        return detectDirectionForTraveller(baggage, services, firstSegment, lastSegment);
    });
    const existRoundTrip = allDirections.some(direction => direction === BaggageDirection.RoundTrip);
    const existThere = allDirections.some(direction => direction === BaggageDirection.There);
    const existReturn = allDirections.some(direction => direction === BaggageDirection.Return);
    if (existRoundTrip) {
        return BaggageDirection.RoundTrip;
    }
    else if (existThere && existReturn) {
        return BaggageDirection.RoundTrip;
    }
    else if (existThere) {
        return BaggageDirection.There;
    }
    else if (existReturn) {
        return BaggageDirection.Return;
    }
    return null;
};
export const detectDirectionForTraveller = (baggage, selectedServices, firstSegment, lastSegment) => {
    const segmentIds = [];
    selectedServices
        .filter(service => baggage.id === service.serviceId)
        .forEach(el => el.segmentIds.forEach(segmentId => {
        segmentIds.push(segmentId);
    }));
    if (segmentIds.length === 0) {
        return null;
    }
    const thereDirection = segmentIds.includes(firstSegment.segment.id);
    let returnDirection = false;
    if (firstSegment.segment.id !== lastSegment.segment.id) {
        returnDirection = segmentIds.includes(lastSegment.segment.id);
    }
    if (thereDirection && returnDirection) {
        return BaggageDirection.RoundTrip;
    }
    else if (thereDirection) {
        return BaggageDirection.There;
    }
    else if (returnDirection) {
        return BaggageDirection.Return;
    }
    return null;
};
export const factorySelectedServices = (selectedServices) => {
    var _a, _b;
    const result = [];
    const addedKeys = new Map();
    for (const [passengerId, passengerSelectedServices] of Object.entries(selectedServices)) {
        for (const [serviceId, segments] of Object.entries(passengerSelectedServices)) {
            for (const [segmentId, selectedService] of Object.entries(segments)) {
                const segmentIds = (_a = selectedServices[passengerId][serviceId][segmentId]) === null || _a === void 0 ? void 0 : _a.segmentIds;
                const key = `${passengerId}-${serviceId}-${segmentIds.join('-')}`;
                if (!addedKeys.has(key)) {
                    result.push({
                        passengerId,
                        segmentIds: (_b = selectedServices[passengerId][serviceId][segmentId]) === null || _b === void 0 ? void 0 : _b.segmentIds,
                        serviceId,
                        count: selectedService.count
                    });
                    addedKeys.set(key, key);
                }
            }
        }
    }
    return result;
};
