import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { Choosed, DeleteIcon } from '../../../../Icons';
import { Statuses } from '../../../types';
import { LoginType, useAccountExistenceQuery, useConfirmMethodMutation, useSaveMethodMutation, useSendPersonalDetailsMutation, useSendSecureCodeMutation } from '@websky/graphql';
import { clearPhoneNumber } from '../../../../PhoneInput/utils';
import { useIataCode } from '../../../../hooks';
import { BaseButton } from '../../../../BaseComponents';
import SimpleLoader from '../../../../SimpleLoader';
import AuthDialog from '../AuthDialog/AuthDialog';
export var ModalTypesEnum;
(function (ModalTypesEnum) {
    ModalTypesEnum["Remove"] = "Remove";
    ModalTypesEnum["Connect"] = "Connect";
    ModalTypesEnum["Change"] = "Change";
})(ModalTypesEnum || (ModalTypesEnum = {}));
const AuthMethod = props => {
    var _a;
    const css = useTheme('Account').InfoField, { t } = useTranslation('Account');
    const iataCode = useIataCode();
    const [inputValue, setInputValue] = useState(props.value), [loyaltyCard, setLoyaltyCard] = useState(null), [error, setError] = useState(null), [verificationError, setVerificationError] = useState(null), [modalType, setModalType] = useState(null), [isLoading, setIsLoading] = useState(false);
    const [saveMethod] = useSaveMethodMutation();
    const [updateUserProfile] = useSendPersonalDetailsMutation();
    const [confirmMethod] = useConfirmMethodMutation();
    const [sendCode] = useSendSecureCodeMutation();
    const checkAccountExistence = useAccountExistenceQuery({
        skip: true
    });
    const errors = { error, verificationError };
    const cleanInputValue = props.type === LoginType.Phone ? clearPhoneNumber(inputValue) : inputValue;
    const isSubmitAvailable = !!inputValue
        ? props.type === LoginType.Phone
            ? clearPhoneNumber(props.value) !== clearPhoneNumber(inputValue)
            : props.value !== inputValue
        : false;
    const onSuccess = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c;
        yield props.refetchInfo();
        (_b = props.setStatus) === null || _b === void 0 ? void 0 : _b.call(props, Statuses.VerificationSuccess);
        (_c = props.onConnectResult) === null || _c === void 0 ? void 0 : _c.call(props, true);
    }), [props.refetchInfo, props.setStatus, props.onConnectResult]);
    const onError = useCallback((error) => {
        var _a, _b;
        setVerificationError(error);
        (_a = props.setStatus) === null || _a === void 0 ? void 0 : _a.call(props, Statuses.VerificationError);
        (_b = props.onConnectResult) === null || _b === void 0 ? void 0 : _b.call(props, false);
    }, [setVerificationError, props.setStatus, props.onConnectResult]);
    const onUpdateUserProfileQuery = useCallback((loyaltyCard) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const { data } = yield updateUserProfile({
                variables: {
                    params: {
                        loyaltyCards: [loyaltyCard]
                    }
                }
            });
            if (!(data === null || data === void 0 ? void 0 : data.UpdateUserProfile)) {
                onError(Error(t('Incorrect card number or password')));
                setIsLoading(false);
            }
            if (modalType === ModalTypesEnum.Change) {
                yield props.onDelete(props.id);
            }
            yield onSuccess();
            setIsLoading(false);
            onCloseModal();
        }
        catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }), [props.id, modalType, props.onDelete, updateUserProfile, onSuccess, onError, setIsLoading]);
    const onUpdateUserProfile = useCallback((pinCode) => onUpdateUserProfileQuery(Object.assign(Object.assign({}, loyaltyCard), { pinCode })), [loyaltyCard, onUpdateUserProfileQuery]);
    const onConfirmedMethod = useCallback((secureCode) => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e;
        try {
            const { data } = yield confirmMethod({
                variables: {
                    params: {
                        loginType: props.type,
                        login: cleanInputValue,
                        secureCode
                    }
                }
            });
            if (!((_d = data === null || data === void 0 ? void 0 : data.ConfirmAuthMethod) === null || _d === void 0 ? void 0 : _d.result)) {
                onError(Error((_e = data === null || data === void 0 ? void 0 : data.ConfirmAuthMethod) === null || _e === void 0 ? void 0 : _e.message));
                return;
            }
            if (modalType === ModalTypesEnum.Change) {
                yield props.onDelete(props.id);
            }
            yield onSuccess();
            onCloseModal();
        }
        catch (e) {
            onError(e);
        }
    }), [props.type, modalType, cleanInputValue, confirmMethod, onSuccess, onError]);
    const onSubmit = useCallback((field) => __awaiter(void 0, void 0, void 0, function* () {
        var _f;
        (_f = props.setStatus) === null || _f === void 0 ? void 0 : _f.call(props, Statuses.VerificationLoading);
        if (props.type === LoginType.FFP) {
            yield onUpdateUserProfile(field.confirmationCode);
        }
        else {
            yield onConfirmedMethod(field.confirmationCode);
        }
    }), [props.type, onUpdateUserProfile, onConfirmedMethod, props.setStatus]);
    const onContinueClick = () => __awaiter(void 0, void 0, void 0, function* () {
        var _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        try {
            (_g = props.setStatus) === null || _g === void 0 ? void 0 : _g.call(props, Statuses.Loading);
            const accountExistence = yield checkAccountExistence.refetch({
                params: {
                    login: props.type === LoginType.Phone ? clearPhoneNumber(inputValue) : inputValue,
                    loginType: props.type
                }
            });
            if ((_j = (_h = accountExistence === null || accountExistence === void 0 ? void 0 : accountExistence.data) === null || _h === void 0 ? void 0 : _h.AccountExistence) === null || _j === void 0 ? void 0 : _j.result) {
                (_k = props.onConnectResult) === null || _k === void 0 ? void 0 : _k.call(props, false);
                throw Error(`${props.type} is already in use by another account`);
            }
            if (props.type === LoginType.FFP) {
                setLoyaltyCard({ airlineCode: iataCode, cardNumber: inputValue });
                (_l = props.setStatus) === null || _l === void 0 ? void 0 : _l.call(props, Statuses.Verification);
            }
            else {
                const { data, errors } = yield saveMethod({
                    variables: {
                        params: {
                            loginType: props.type,
                            login: props.type === LoginType.Phone ? clearPhoneNumber(inputValue) : inputValue
                        }
                    }
                });
                if (errors === null || errors === void 0 ? void 0 : errors.length) {
                    setError(errors[0]);
                    (_m = props.onConnectResult) === null || _m === void 0 ? void 0 : _m.call(props, false);
                }
                else if ((_o = data === null || data === void 0 ? void 0 : data.SaveAuthMethod) === null || _o === void 0 ? void 0 : _o.result) {
                    (_p = props.setStatus) === null || _p === void 0 ? void 0 : _p.call(props, Statuses.Verification);
                }
                else {
                    (_q = props.onConnectResult) === null || _q === void 0 ? void 0 : _q.call(props, false);
                    throw Error((_r = data === null || data === void 0 ? void 0 : data.SaveAuthMethod) === null || _r === void 0 ? void 0 : _r.message);
                }
            }
        }
        catch (error) {
            setError(error);
            (_s = props.setStatus) === null || _s === void 0 ? void 0 : _s.call(props, Statuses.default);
            (_t = props.onConnectResult) === null || _t === void 0 ? void 0 : _t.call(props, false);
        }
    });
    useEffect(() => {
        if (props.autoConnectValue) {
            if (props.type === LoginType.FFP && props.autoConnectCode) {
                onUpdateUserProfileQuery({
                    airlineCode: iataCode,
                    cardNumber: props.autoConnectValue,
                    pinCode: props.autoConnectCode
                });
            }
            else if (props.type === LoginType.Email) {
                setInputValue(props.autoConnectValue);
                if (inputValue) {
                    setModalType(ModalTypesEnum.Connect);
                    onContinueClick();
                }
            }
        }
    }, [inputValue, props.autoConnectValue]);
    const isSocialAuth = useMemo(() => {
        return [LoginType.Facebook, LoginType.Google, LoginType.Vkontakte, LoginType.TinkoffID].includes(props.type);
    }, [props.type]);
    const resendCode = useCallback(() => {
        const login = props.type === LoginType.Phone ? clearPhoneNumber(inputValue) : inputValue;
        return sendCode({
            variables: {
                params: {
                    login,
                    loginType: props.type
                }
            }
        });
    }, [props.type, inputValue]);
    const onOpenModal = useCallback((type) => {
        setModalType(type);
    }, []);
    const onCloseModal = useCallback(() => {
        var _a, _b;
        setError(null);
        setVerificationError(null);
        setModalType(null);
        setInputValue(props.value);
        if (props.type === LoginType.Email && props.autoConnectValue) {
            (_a = props.onConnectResult) === null || _a === void 0 ? void 0 : _a.call(props, false);
        }
        (_b = props.setStatus) === null || _b === void 0 ? void 0 : _b.call(props, Statuses.default);
    }, [props.type, props.value, props.autoConnectValue, props.onConnectResult, props.setStatus]);
    const onDelete = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield props.onDelete(props.id);
        onCloseModal();
    }), [props.id, props.onDelete, onCloseModal]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(css.field, props.className), "data-type": props.type },
            React.createElement("div", { className: css.field__info },
                props.icon && React.createElement("div", { className: css.field__icon }, props.icon),
                React.createElement("div", null,
                    React.createElement("div", { className: css.field__name },
                        t(props.type),
                        " ",
                        props.confirmed && Choosed),
                    React.createElement("div", { className: css.field__user },
                        props.value && React.createElement("span", { className: css.field__value }, props.value),
                        React.createElement("span", { className: css.field__status }, props.confirmed ? t('Connected') : t('Not connected'))))),
            React.createElement("div", { className: css.field__buttons }, props.confirmed ? (React.createElement(React.Fragment, null,
                !isSocialAuth && (React.createElement(BaseButton, { variant: "smallSecondary", className: cn(css.field__button, css.field__change), onClick: () => onOpenModal(ModalTypesEnum.Change) }, t('Edit'))),
                props.isRemovable && (React.createElement("span", { className: css.field__delete, onClick: () => onOpenModal(ModalTypesEnum.Remove) }, (_a = props.deleteIcon) !== null && _a !== void 0 ? _a : DeleteIcon)))) : (React.createElement(BaseButton, { variant: "smallNeitral", className: cn(css.field__button, css.field__connect), onClick: () => onOpenModal(ModalTypesEnum.Connect) }, t('Connect')))),
            React.createElement(AuthDialog, { type: modalType, isOpen: !!modalType, onClose: onCloseModal, onSubmit: onSubmit, onDelete: onDelete, onChange: setInputValue, status: props.status, fieldType: props.type, fieldValue: inputValue, errors: errors, setStatus: props.setStatus, setError: setError, setVerificationError: setVerificationError, resendCode: resendCode, onContinue: onContinueClick, isSubmitAvailable: isSubmitAvailable, isAutoConnect: !!props.autoConnectValue })),
        isLoading && props.autoConnectValue && React.createElement(SimpleLoader, null)));
};
export default AuthMethod;
