import * as React from 'react';
import { useMemo, useReducer, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';
import Modal from '../../../../../../Modal';
import Datepicker from './Datepicker/Datepicker';
import Button from '../../../../../../Button/Button';
import { ThemeProvider, useTheme } from '../../../../../../theme';
import { API_DATE_FORMAT, format } from '../../../../../../utils';
import { sortSegments } from '../../../../../../Results/components/Results/Calendar/utils';
import { selectedDatesReducer } from './store/store';
import { useAvailableDates } from '../../hooks';
const calendarIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.3333 4.88911H18.2222C19.2 4.88911 20 5.68911 20 6.66688V19.1113C20 20.0891 19.2 20.8891 18.2222 20.8891H5.77778C4.79111 20.8891 4 20.0891 4 19.1113L4.00889 6.66688C4.00889 5.68911 4.79111 4.88911 5.77778 4.88911H6.66667V3.11133H8.44444V4.88911H15.5556V3.11133H17.3333V4.88911ZM5.77778 19.1113H18.2222V9.33355H5.77778V19.1113Z", fill: "currentColor" })));
const Calendar = ({ searchParams, startNewSearch, legId, minDate }) => {
    var _a;
    const [open, setOpen] = React.useState(false);
    const [selectedDatesState, dispatch] = useReducer(selectedDatesReducer, {});
    const [currentLeg, setCurrentLeg] = React.useState(typeof legId !== 'undefined' ? legId : 0);
    const segmentsCount = searchParams.segments.length;
    const selectedDatesCount = Object.keys(selectedDatesState).length;
    const isAllDatesSelected = selectedDatesCount === segmentsCount;
    const hasDatesToClear = selectedDatesCount >= legId + 1;
    const theme = useTheme('Exchange').Calendar;
    const { t } = useTranslation('Exchange');
    const date = parse(searchParams.segments[0].date, 'yyyy-MM-dd', new Date());
    const currentSegment = (_a = searchParams.segments) === null || _a === void 0 ? void 0 : _a[legId];
    const { dates } = useAvailableDates(currentSegment);
    const selectedDates = useMemo(() => {
        if (Object.keys(selectedDatesState).length > 0) {
            return Object.keys(selectedDatesState).map(key => parse(selectedDatesState[key].date, API_DATE_FORMAT, new Date()));
        }
        else {
            return searchParams.segments.map(segment => parse(segment.date, API_DATE_FORMAT, new Date()));
        }
    }, [selectedDatesState]);
    const onStartSearch = () => {
        startNewSearch(Object.assign(Object.assign({}, searchParams), { segments: sortSegments(currentLeg, date, Object.keys(selectedDatesState).map(key => {
                const segment = selectedDatesState[key];
                if (parseInt(key, 10) + 1 === legId) {
                    return Object.assign(Object.assign({}, segment), { date: format(date, API_DATE_FORMAT) });
                }
                return segment;
            })) }));
    };
    const onSelect = (date) => {
        const segment = searchParams.segments
            .map((segment, key) => {
            if (key === currentLeg) {
                return Object.assign(Object.assign({}, segment), { date: format(date, API_DATE_FORMAT) });
            }
            return null;
        })
            .filter(Boolean)[0];
        dispatch({
            type: 'select',
            payload: {
                legId: Math.max(currentLeg, legId),
                segment
            }
        });
        if (searchParams.segments[legId + 1]) {
            setCurrentLeg(legId + 1);
        }
    };
    useEffect(() => {
        dispatch({
            type: 'actialize',
            payload: {
                segmentsSearchParams: searchParams.segments
            }
        });
    }, [legId]);
    const datepickerStyles = useMemo(() => ({
        Datepicker: {
            DayStyles: {
                day: theme.day__day,
                day__inside: theme.day__inside,
                day_endPeriod: theme.day_end,
                day_startPeriod: theme.day_start,
                day_selected: theme.day_selected
            },
            PopupDatepickerStyles: {
                block: theme.datepicker__modal
            }
        }
    }), []);
    const clearLastSelectedDate = () => {
        dispatch({
            type: 'clear',
            payload: {
                legId
            }
        });
        setCurrentLeg(legId);
    };
    const renderFooter = () => {
        const hasButtons = hasDatesToClear || isAllDatesSelected;
        if (!hasButtons) {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement("div", { className: theme.buttons },
            hasDatesToClear && (React.createElement(Button, { size: 'small', variant: 'flat', className: cn(theme.button, theme.clear), onClick: clearLastSelectedDate }, t('Clear'))),
            isAllDatesSelected && (React.createElement(Button, { size: 'small', variant: 'flat', className: theme.button, onClick: onStartSearch }, t('Search')))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: () => setOpen(true), className: theme.calendar },
            t('Open calendar'),
            calendarIcon),
        React.createElement(Modal, { open: open, classes: { paper: theme.datepicker__paper }, closeClassName: theme.datepicker__closeIcon, onClose: () => setOpen(false) },
            React.createElement(ThemeProvider, { value: datepickerStyles },
                React.createElement(Datepicker, { onSelect: onSelect, openDate: date, monthCount: 3, minDate: minDate, availableDates: dates, highlightClassName: theme.day_highlight, selectedDates: selectedDates, renderFooter: renderFooter })))));
};
export default Calendar;
