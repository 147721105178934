export const getPassengerAbbreviation = (name) => {
    const [lastName, firstName] = name.split(' ');
    let result = '';
    if (lastName) {
        result += lastName[0];
    }
    if (firstName) {
        result += firstName[0];
    }
    return result;
};
