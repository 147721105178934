import * as React from 'react';
import cn from 'classnames';
import { parse } from 'date-fns';
import { useTheme } from '../../../theme';
import { format, removeDotsFromDate, TABLET_MIDDLE_WIDTH } from '../../../utils';
import { weatherIcons } from '../../../Weather/icons';
import { getTemperaturePrefix } from '../../../Weather/utils';
import { Land, Takeoff } from '../../../Icons';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { TemperatureUnits } from '@websky/graphql';
const Location = ({ className, airport, date, time, weather, type, isMobile: isMobileProps, terminal }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const { Location: css } = useTheme('DesktopFlight');
    const { t } = useTranslation('Location');
    console.log(terminal);
    const flightDate = parse(date, 'yyyy-MM-dd', new Date());
    const weatherIcon = (weather === null || weather === void 0 ? void 0 : weather.type) && weatherIcons[weather.type];
    const image = (_d = (_c = (_b = (_a = airport === null || airport === void 0 ? void 0 : airport.city) === null || _a === void 0 ? void 0 : _a.images) === null || _b === void 0 ? void 0 : _b.panorama) === null || _c === void 0 ? void 0 : _c.url) !== null && _d !== void 0 ? _d : (_f = (_e = airport.images) === null || _e === void 0 ? void 0 : _e.panorama) === null || _f === void 0 ? void 0 : _f.url;
    const flightIcon = {
        arrival: Land,
        departure: Takeoff
    };
    const mobile = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH }) || isMobileProps;
    const titleEqualsAirportName = ((_g = airport.city) === null || _g === void 0 ? void 0 : _g.name) === airport.title;
    return (React.createElement("div", { className: cn(className, css.wrapper, {
            [css.isMobile]: mobile
        }) },
        React.createElement("div", { className: css.info },
            React.createElement("span", { className: css.time },
                time,
                React.createElement("span", { className: css.time_iata }, (_h = airport.iata) === null || _h === void 0 ? void 0 : _h.toUpperCase())),
            React.createElement("span", { className: css.date },
                React.createElement("div", { className: css.date_icon }, flightIcon[type]),
                removeDotsFromDate(format(flightDate, 'dd MMM, EEEEEE')))),
        React.createElement("div", { className: css.airport },
            React.createElement("span", { className: css.city }, ((_j = airport.city) === null || _j === void 0 ? void 0 : _j.name) ? (_k = airport.city) === null || _k === void 0 ? void 0 : _k.name : airport.title),
            React.createElement("span", { className: css.terminal },
                React.createElement("span", { className: css.terminal_city }, (_l = airport.city) === null || _l === void 0 ? void 0 : _l.name),
                !mobile && airport.iata && (React.createElement("span", { className: css.terminal_iata }, titleEqualsAirportName ? airport.iata : `(${airport.iata})`)),
                !titleEqualsAirportName && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: cn(css.terminal_airport, {
                            [css.terminal_airportTerminal]: terminal
                        }) }, airport.title))),
                terminal && (React.createElement("span", { className: css.terminal_number },
                    t('Terminal'),
                    " ",
                    terminal)))),
        React.createElement("div", { className: cn(css.weather, {
                [css.weather_gradient]: weather
            }), style: image ? { backgroundImage: `url(${image})` } : {} }, weather && (React.createElement("div", { className: css.weather_wrapper },
            weatherIcon,
            React.createElement("span", { className: css.weather_temp },
                getTemperaturePrefix(weather.temperature),
                Math.abs(Math.round(weather.temperature)),
                "\u00B0",
                weather.measurement === TemperatureUnits.Fahrenheit && 'F'))))));
};
export default Location;
