import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { useRetrieveBookingValidation } from '../../RetrieveBooking/hooks';
import { Chevron } from '../../Icons';
import Input from '../../Input';
import { BaseButton } from '../../BaseComponents';
import TicketExample from '../../TicketExample';
import BookingPreview from '../../BookingPreview';
export var FormType;
(function (FormType) {
    FormType["Booking"] = "Booking";
    FormType["Checkin"] = "Checkin";
})(FormType || (FormType = {}));
const AddBooking = ({ className, type, isLoading, onClose, onSubmit, order, onClearOrder }) => {
    const { t } = useTranslation('Account');
    const { AddBooking: css } = useTheme('AddBooking');
    const isBookingFormType = type === FormType.Booking;
    const { lastNameValidation, ticketNumberValidation, validateByOrderNumber, ticketNumberFormat } = useRetrieveBookingValidation();
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        React.createElement("div", { className: css.close, onClick: onClose }),
        React.createElement("div", { className: css.title }, type === FormType.Booking ? t('Add booking') : t('Online check-in')),
        React.createElement(Form, { onSubmit: onSubmit, render: ({ handleSubmit, values }) => (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("div", { className: css.inputs },
                    React.createElement(Field, { name: "lastName", validate: (value) => {
                            if (!value) {
                                return t('AddPassenger:Required field');
                            }
                            if (type === FormType.Checkin) {
                                return lastNameValidation(value);
                            }
                        }, render: ({ input, meta }) => (React.createElement(Input, Object.assign({}, input, { className: css.input, inputClassName: css.input_root, disabled: !!order || isLoading, TextFieldProps: {
                                type: 'lastName',
                                placeholder: t('Last name'),
                                error: (meta.error || meta.submitError) && meta.touched,
                                helperText: !!meta.error && meta.touched ? meta.error : '',
                                onChange: (event) => {
                                    input.onChange(event);
                                }
                            } }))) }),
                    React.createElement(Field, { name: "ticket", parse: ticketNumberFormat, validate: (value) => {
                            if (!value) {
                                return t('AddPassenger:Required field');
                            }
                            if (type === FormType.Checkin) {
                                return ticketNumberValidation(value);
                            }
                            if (type === FormType.Booking) {
                                if (!ticketNumberValidation(value) || !validateByOrderNumber(value)) {
                                    return undefined;
                                }
                                else {
                                    return ticketNumberValidation(value) || validateByOrderNumber(value);
                                }
                            }
                        }, render: ({ input, meta }) => {
                            var _a;
                            return (React.createElement(Input, Object.assign({}, input, { className: css.input, inputClassName: css.input_root, disabled: !!order || isLoading, TextFieldProps: {
                                    type: 'ticket',
                                    placeholder: t('Ticket or booking number'),
                                    error: (meta.error || meta.submitError) && meta.touched,
                                    helperText: (meta.error || meta.submitError) && meta.touched
                                        ? (_a = meta.submitError) !== null && _a !== void 0 ? _a : meta.error
                                        : '',
                                    onChange: (event) => {
                                        input.onChange(event);
                                    }
                                } })));
                        } })),
                isBookingFormType && (isLoading || order) ? (React.createElement(BookingPreview, { order: order, onClearOrder: onClearOrder, isLoading: isLoading, redirectViaHistory: false })) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css.spoilers },
                        React.createElement(Accordion, { classes: {
                                root: css.spoiler,
                                expanded: css.spoilerExpanded
                            } },
                            React.createElement(AccordionSummary, { classes: {
                                    root: css.spoilerHeader,
                                    content: css.spoilerHeaderContent,
                                    expanded: css.spoilerHeaderExpended
                                } },
                                Chevron,
                                t('How to find a booking id?')),
                            React.createElement(AccordionDetails, { classes: {
                                    root: css.spoilerDetails
                                } },
                                React.createElement("p", null, t('Enter the passengers surname (in latin characters) and ticket number, order ID or booking ID. You can find these in the itinerary receipt sent to purchasers Email after booking.')),
                                React.createElement(TicketExample, null)))),
                    React.createElement("div", { className: css.footer },
                        !isLoading && (React.createElement(BaseButton, { className: cn(css.button, css.buttonCancel), variant: "secondary", onClick: onClose }, t('Cancel'))),
                        React.createElement(BaseButton, { className: cn(css.button, css.buttonContinue, {
                                [css.buttonDisabled]: isLoading
                            }), variant: "action", type: 'submit', disabled: isLoading }, t('Continue'))))))) })));
};
export default AddBooking;
