import { __awaiter } from "tslib";
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useSaveOrderServicesMutation } from '@websky/graphql';
import { AdditionalServiceType } from '../../types';
import { getInsurances, getIsConfirmed, getOrder } from '../../../Checkout/store/order/selectors';
import { getPassengerState } from '../../../Checkout/store/passengers/selectors';
import { fillOrder } from '../../../Checkout/store/order/actions';
import { ThemeProvider, useTheme } from '../../../theme';
import { useConfig } from '../../../context';
import { useInsurance } from './hooks';
import { useIsMobile } from '../../../utils';
import { useRenderers } from '../../../renderProps';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import DialogContent from '../../../AdditionalServices/components/Insurance/DialogContent/DialogContent';
import InsuranceSummary from '../../../ServiceSummary/services/InsuranceSummary';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import DownloadInsurances from '../../../AdditionalServices/components/Insurance/DownloadInsurances/DownloadInsurances';
import WarningModal from '../../../WarningModal';
import InsurancePopup from '../../../InsurancePopup';
import Modal from '../../../Modal';
import Close from '../Close/Close';
import icons from '../../icons';
import { useInsuranceGroupTexts } from '../InsuranceUpsale/hooks';
import { analytics_removeInsurance } from '../../../Insurance/utils';
const InsuranceAdditionalService = ({ insurancePrograms, groupName, asSingle, aggregationMode }) => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation('Checkout');
    const { Insurance: cardStyles } = useTheme('CheckoutAdditionalService');
    const { Insurance: css } = useTheme('AdditionalServices');
    const texts = useInsuranceGroupTexts(groupName, insurancePrograms);
    const { global: { checkoutAdditionalService } } = useConfig();
    const dispatch = useDispatch();
    const order = useSelector(getOrder);
    const isConfirmed = useSelector(getIsConfirmed);
    const insurances = useSelector(getInsurances);
    const passengers = useSelector(getPassengerState);
    const isMobile = useIsMobile();
    const [saveOrderServicesMutation, { loading }] = useSaveOrderServicesMutation();
    const onUpdateOrder = (data) => {
        dispatch(fillOrder(data.SaveOrderServices));
    };
    const { 
    // common
    multiInsurance, selectedInsurances, buttons, minPrice, totalPrice, error, onSave, onAddInsurance, onRemoveInsurance, clearError, 
    // insurance modal
    insuranceModalIsOpen, closeInsuranceModal, 
    // confirmation modal
    confirmationModalIsOpen, closeConfirmationModal, 
    // download modal
    downloadModalIsOpen, closeDownloadModal } = useInsurance({
        orderId: order.id,
        insurances,
        insurancePrograms,
        passengers,
        onSaveServices: saveOrderServicesMutation,
        onUpdateOrder
    });
    const hasInsurancePriceToPay = ((_a = order.priceToPay) === null || _a === void 0 ? void 0 : _a.insurance) !== null;
    const byCodeMap = (_c = (_b = checkoutAdditionalService === null || checkoutAdditionalService === void 0 ? void 0 : checkoutAdditionalService.insurance) === null || _b === void 0 ? void 0 : _b.insuranceProgramCodeMap) === null || _c === void 0 ? void 0 : _c[insurancePrograms[0].code];
    const headerIcon = React.useMemo(() => {
        if (asSingle && (byCodeMap === null || byCodeMap === void 0 ? void 0 : byCodeMap.headerIcon)) {
            return byCodeMap.headerIcon;
        }
        return icons.insuranceIcon;
    }, [asSingle, byCodeMap]);
    const isSingleNotSelected = asSingle && !selectedInsurances.length;
    const style = React.useMemo(() => {
        if (isSingleNotSelected && (byCodeMap === null || byCodeMap === void 0 ? void 0 : byCodeMap.color)) {
            return { background: byCodeMap.color };
        }
        return null;
    }, [isSingleNotSelected, byCodeMap]);
    const endAdornment = React.useMemo(() => {
        if (isSingleNotSelected && (byCodeMap === null || byCodeMap === void 0 ? void 0 : byCodeMap.contentIcon)) {
            return React.createElement("div", { className: cardStyles.serviceWrapperEndAdornment }, byCodeMap.contentIcon);
        }
        return null;
    }, [isSingleNotSelected, byCodeMap]);
    const header = React.useMemo(() => {
        if (asSingle) {
            const insuranceProgram = insurancePrograms[0];
            const isImportant = insuranceProgram.important;
            if (isImportant && insuranceProgram.name) {
                return insuranceProgram.name;
            }
            else if (byCodeMap === null || byCodeMap === void 0 ? void 0 : byCodeMap.header) {
                return byCodeMap.header;
            }
        }
        return texts.title;
    }, [asSingle, insurancePrograms, byCodeMap, texts]);
    const renderCloseButton = (onClick) => {
        return React.createElement(Close, { onClick: onClick });
    };
    const onInsuranceItemRemove = (_, payload) => __awaiter(void 0, void 0, void 0, function* () {
        var _g;
        const insuranceService = payload;
        const filterCodes = insurances.selectedInsurances
            .filter(insurance => insurance.insuranceProgram.code !== insuranceService.code)
            .map(insurance => insurance.insuranceProgram.code);
        const deletingProgram = (_g = insurances.selectedInsurances.find(selected => selected.insuranceProgram.code === payload.code)) === null || _g === void 0 ? void 0 : _g.insuranceProgram;
        if (deletingProgram) {
            analytics_removeInsurance(deletingProgram, order.travellers);
        }
        yield onSave(filterCodes);
    });
    const { CheckoutAdditionalService: CustomCheckoutAdditionalService } = useRenderers();
    const ServiceWrapper = (_d = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _d !== void 0 ? _d : CheckoutAdditionalService;
    return (React.createElement(React.Fragment, null,
        React.createElement(InsuranceSummary, { flight: order.flight, travellers: order.travellers, services: Object.assign(Object.assign({}, order.additionalServices), { insurances: Object.assign(Object.assign({}, order.additionalServices.insurances), { insurancePrograms: insurancePrograms.filter(program => program.canBeAdded) }) }), onServiceDelete: hasInsurancePriceToPay ? onInsuranceItemRemove : null, isConfirmed: isConfirmed }, selectedInsurances => (React.createElement(ServiceWrapper, { style: style, icon: headerIcon, className: cn(cardStyles.insurance, insurancePrograms[0].code, {
                [cardStyles.withEndAdornment]: !!endAdornment
            }), insuranceCode: insurancePrograms[0].code, isLoading: loading, addClick: onAddInsurance, header: header, isSelected: !!selectedInsurances.length, priceFrom: minPrice, priceTotal: totalPrice, description: texts.descr, buttons: buttons, endAdornment: endAdornment, mode: aggregationMode ? 'aggregator' : 'default', coverClassName: cardStyles.insurance_aggregator, serviceType: AdditionalServiceType.Insurance, servicesCount: insurancePrograms.length },
            React.createElement(ThemeProvider, { value: {
                    ServiceSummary: {
                        ServiceSummaryOption: {
                            option: cardStyles.selectedOption,
                            option__name: cardStyles.option__name
                        }
                    }
                } },
                React.createElement(ServiceSummaryDivider, null,
                    React.createElement(ServiceSummaryOption, { groupOption: {
                            items: selectedInsurances
                        } }))),
            selectedInsurances.length === 1 && (React.createElement("div", { dangerouslySetInnerHTML: { __html: selectedInsurances[0].description } }))))),
        multiInsurance && (React.createElement(Modal, { classes: {
                scrollBody: css.scrollBody,
                paper: css.paper
            }, open: insuranceModalIsOpen, slideUp: isMobile, onClose: closeInsuranceModal, closeButtonRenderer: renderCloseButton },
            React.createElement(InsurancePopup, { header: header, description: (_e = insurancePrograms[0]) === null || _e === void 0 ? void 0 : _e.description, insurances: insurancePrograms, selectedInsurances: selectedInsurances, onClose: closeInsuranceModal, onSave: onSave, isLoading: loading, travellers: order.travellers }))),
        React.createElement(Modal, { maxWidth: "sm", classes: { scrollBody: css.scrollBody, paper: cn(css.paper, css.paper_confirm) }, open: confirmationModalIsOpen, onClose: closeConfirmationModal, slideUp: isMobile },
            React.createElement(DialogContent, { closeConfirmationPopup: closeConfirmationModal, onInsuranceRemove: onRemoveInsurance, refusingIsInProgress: loading })),
        React.createElement(Modal, { maxWidth: "sm", classes: { scrollBody: css.scrollBody, paper: cn(css.paper, css.paper_download) }, open: downloadModalIsOpen, onClose: closeDownloadModal, slideUp: isMobile },
            React.createElement(DownloadInsurances, { selectedInsurances: selectedInsurances })),
        React.createElement(WarningModal, { title: t('Oops, something went wrong'), content: t('An unexpected error has occurred during the booking process. Please try again later or contact a customer support service.'), errorMessage: t((_f = error === null || error === void 0 ? void 0 : error.message) !== null && _f !== void 0 ? _f : ''), isOpen: !!error, onClose: clearError, onButtonClick: clearError })));
};
export default InsuranceAdditionalService;
