import * as BaggageDialogHeader from './components/BaggageDialogHeader/BaggageDialogHeader.css';
import * as BaggagePassenger from './components/BaggagePassenger/BaggagePassenger.css';
import * as BaggageSegment from './components/BaggageSegment/BaggageSegment.css';
import * as UpgradeBaggageDialog from './components/UpgradeBaggageDialog.css';
import * as BaggageFooter from './components/BaggageFooter/BaggageFooter.css';
import * as MobileBaggageSelector from './components/BaggageDialogHeader/MobileBaggageSelector/MobileBaggageSelector.css';
import * as HeaderEquipmentItem from './components/BaggageDialogHeader/HeaderEquipmentItem/HeaderEquipmentItem.css';
export default {
    BaggageDialogHeader,
    BaggagePassenger,
    BaggageSegment,
    UpgradeBaggageDialog,
    BaggageFooter,
    MobileBaggageSelector,
    HeaderEquipmentItem
};
