import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import Gateway from '../Gateway';
import { useTheme } from '../../../../theme';
import { PaymentType } from '../../../types';
import Button from '../../../../Button';
import Input from '../../../../Input';
import { Money } from '../../../../Money';
const Invoice = ({ children, isPreOpened, frameInfo, headerTitle, type, onCompletePayment, onClick }) => {
    const { Invoice: css } = useTheme('PaymentForm');
    const { t } = useTranslation('PaymentForm');
    const onCompletePaymentHandler = useCallback((values) => onCompletePayment(Object.assign({ transactionId: frameInfo.transactionId }, values)), [onCompletePayment, frameInfo]);
    const onClickGateway = useCallback(() => onClick({
        title: headerTitle,
        type: PaymentType.Invoice,
        content: (React.createElement(Form, { onSubmit: onCompletePaymentHandler, render: ({ handleSubmit }) => (React.createElement("div", { className: css.content },
                React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Field, { name: "additionalText", render: ({ input }) => (React.createElement(Input, Object.assign({}, input, { TextFieldProps: {
                                label: t('Payment order numbers'),
                                onChange: input.onChange
                            } }))) }),
                    React.createElement("div", { className: css.footer },
                        React.createElement(Button, { type: "submit" },
                            t('Pay'),
                            React.createElement(Money, { money: frameInfo.totalPrice })))))) }))
    }), [onCompletePayment, onClick, frameInfo]);
    useEffect(() => {
        if (isPreOpened) {
            onClickGateway();
        }
    }, [isPreOpened]);
    return React.createElement(Gateway, { header: children, onClick: onClickGateway, type: type, paymentType: PaymentType.Invoice });
};
export default Invoice;
