import * as React from 'react';
import { useCallback, useContext } from 'react';
import { createStore } from '../store';
import { Provider, useSelector } from 'react-redux';
import BaggageDialogHeader from './BaggageDialogHeader/BaggageDialogHeader';
import BaggageBody from './BaggageBody/BaggageBody';
import Modal from '../../../Modal';
import { useTheme } from '../../../theme';
import BaggageFooter from './BaggageFooter/BaggageFooter';
import { getHasChangesServices, joinServicesByRfics } from '../utils';
import { BaggageType } from '@websky/graphql';
import { saveServices, setAllServices } from '../store/actions';
import { OverrideComponent, useRenderers } from '../../../renderProps';
import SimpleLoader from '../../../SimpleLoader';
import WebskyAnalyticsApollo from '../../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { WxEvents } from '../../../DataLayerAnalytics/types';
import { ModeContext } from '../../../context';
import { Mode } from '../../../types';
import { getUpgradableBaggage as getCheckinUpgradableBaggage } from '../../../Modules/Checkin/store/order/selectors';
import { getUpgradableBaggage as getCheckoutUpgradableBaggage } from '../../../Checkout/store/order/selectors';
const closeIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { y: "0.000488281", width: "32", height: "32", rx: "16", fill: "#F6F6F6" }),
    React.createElement("path", { d: "M21.8337 11.3422L20.6587 10.1672L16.0003 14.8255L11.342 10.1672L10.167 11.3422L14.8253 16.0005L10.167 20.6588L11.342 21.8338L16.0003 17.1755L20.6587 21.8338L21.8337 20.6588L17.1753 16.0005L21.8337 11.3422Z", fill: "#808080" })));
const UpgradeBaggageDialog = props => {
    const theme = useTheme('UpgradeBaggageDialog').UpgradeBaggageDialog;
    const [mobileServiceRfics, setMobileServiceRfics] = React.useState(props.upgradeBaggage[0].rfics);
    const baggagesByRfics = React.useMemo(() => joinServicesByRfics(props.upgradeBaggage), [props.upgradeBaggage]);
    const mode = useContext(ModeContext);
    let upgradableBaggage;
    if (mode === Mode.Checkin) {
        upgradableBaggage = useSelector(getCheckinUpgradableBaggage);
    }
    else {
        upgradableBaggage = useSelector(getCheckoutUpgradableBaggage);
    }
    const store = React.useMemo(() => {
        var _a;
        return createStore(props.upgradeBaggage, props.segments, props.passengers, props.selectedServices, props.maxBaggageCount || 40, props.maxBaggagePerPassengerCount || 3, props.baggageType === BaggageType.SportingEquipment ? (_a = [...baggagesByRfics.values()]) === null || _a === void 0 ? void 0 : _a[0][0].rfics : null, props.disabled, upgradableBaggage);
    }, [
        props.upgradeBaggage,
        props.segments,
        props.passengers,
        props.selectedServices,
        props.maxBaggageCount,
        props.maxBaggagePerPassengerCount,
        props.open,
        props.disabled,
        upgradableBaggage
    ]);
    const initialState = React.useMemo(() => store.getState(), [store]);
    React.useEffect(() => {
        var _a;
        if (props.open) {
            store.dispatch(setAllServices(props.selectedServices));
            if (baggagesByRfics.length === 1) {
                const baggage = (_a = baggagesByRfics[0].find(baggage => baggage.canBeAdded)) !== null && _a !== void 0 ? _a : baggagesByRfics[0][0];
                WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.BaggageItem, {
                    servicesIds: [baggage.id],
                    segmentId: baggage.segmentIds[0][0]
                });
            }
        }
    }, [props.open, props.selectedServices, store]);
    const handlerConfirm = useCallback(() => {
        const actualState = store.getState();
        const hasChanges = getHasChangesServices(initialState.selectedServices, actualState.selectedServices);
        if (!props.dynamicBaggagePricing && hasChanges) {
            store.dispatch(saveServices({ meta: props.analyticsMeta }));
        }
        props.onClose();
    }, [store, initialState, props.dynamicBaggagePricing, props.analyticsMeta]);
    const { specialEquipmentItemsSort } = useRenderers();
    const sortedBaggagesByRfics = specialEquipmentItemsSort
        ? baggagesByRfics.sort(specialEquipmentItemsSort)
        : baggagesByRfics.sort((first, second) => first[0].price.amount - second[0].price.amount);
    return (React.createElement(Provider, { store: store },
        React.createElement(Modal, { open: props.open, onClose: props.onClose, isRounded: true, className: props.className, classes: { root: theme.modal__root }, PaperProps: { className: theme.modal__paper }, disableAutoFocus: true, closeButtonRenderer: onClick => {
                return (React.createElement("div", { className: theme.modalHeader },
                    props.headerProps.header,
                    React.createElement("div", { className: theme.close, onClick: onClick }, closeIcon)));
            } },
            React.createElement("div", { className: theme.root },
                props.isLoading && React.createElement(SimpleLoader, null),
                React.createElement(BaggageDialogHeader, { services: sortedBaggagesByRfics, headerProps: props.headerProps, segments: props.segments, setMobileServiceId: setMobileServiceRfics, mobileSelectedServiceId: mobileServiceRfics }),
                React.createElement(OverrideComponent, { componentProps: {
                        upgradeBaggages: baggagesByRfics,
                        passengers: props.passengers,
                        passengerLabels: props.passengerLabels,
                        segments: props.segments,
                        mobileSelectedServiceId: mobileServiceRfics
                    }, component: {
                        renderBaggageBody: BaggageBody
                    } }),
                React.createElement(BaggageFooter, { onCancel: props.onClose, onConfirm: handlerConfirm })))));
};
export default UpgradeBaggageDialog;
