import { createAction } from 'redux-actions';
export const ADD_SERVICE = 'ADD_SERVICE';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';
export const ADD_SERVICE_NS = 'ADD_SERVICE_NS'; // _NS  - No Saga
export const REMOVE_SERVICE_NS = 'REMOVE_SERVICE_NS';
export const SET_SERVICE = 'SET_SERVICE';
export const SAVE_SERVICES = 'SAVE_SERVICES';
export const SET_ALL_SELECTED_SERVICES = 'SET_ALL_SELECTED_SERVICES';
export const DELETE_ALL_TYPES_ON_SEGMENT = 'DELETE_ALL_TYPES_ON_SEGMENT';
export const DELETE_ALL_TYPES_ON_SEGMENT_NS = 'DELETE_ALL_TYPES_ON_SEGMENT_NS';
export const CLEAR_ALL_SERVICES = 'CLEAR_ALL_SERVICES';
export const SET_MULTIPLE_SELECTED_SERVICES = 'SET_MULTIPLE_SELECTED_SERVICES';
export const SET_MULTIPLE_SELECTED_SERVICES_NS = 'SET_MULTIPLE_SELECTED_SERVICES_NS';
export const addServiceAction = createAction(ADD_SERVICE);
export const removeServiceAction = createAction(REMOVE_SERVICE);
export const addServiceAction_NS = createAction(ADD_SERVICE_NS); // _NS - No Saga
export const removeServiceAction_NS = createAction(REMOVE_SERVICE_NS);
export const setServiceAction = createAction(SET_SERVICE);
export const saveServicesAction = createAction(SAVE_SERVICES);
export const setMultipleSelectedServices = createAction(SET_MULTIPLE_SELECTED_SERVICES, (payload) => payload, (_, meta) => meta);
export const setMultipleSelectedServicesAction_NS = createAction(SET_MULTIPLE_SELECTED_SERVICES_NS);
export const setAllSelectedServices = createAction(SET_ALL_SELECTED_SERVICES);
export const deleteAllTypesOnSegment = createAction(DELETE_ALL_TYPES_ON_SEGMENT);
export const deleteAllTypesOnSegmentAction_NS = createAction(DELETE_ALL_TYPES_ON_SEGMENT_NS);
