import * as React from 'react';
import { useTranslation } from 'react-i18next';
import WarningModal from '../../../WarningModal';
import { initI18n } from '../../../utils';
import { ThemeProvider, useTheme } from '../../../theme';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
initI18n('PaymentErrors');
const ErrorModal = ({ open, onClose, error }) => {
    const { t } = useTranslation('PaymentErrors');
    const theme = useTheme('PaymentMethodsError').ErrorModal;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const styles = React.useMemo(() => ({
        WarningModal: {
            WarningModal: {
                header: theme.header
            }
        }
    }), []);
    return (React.createElement(ThemeProvider, { value: styles },
        React.createElement(WarningModal, { title: t('An error occurred while executing the request'), onClose: onClose, content: t('Please try again later or contact a customer support service'), errorMessage: `${t('Error')}: ${t(error)}`, onButtonClick: onClose, buttonText: t('OK'), bottomDrawer: isMobile, isOpen: open })));
};
export default ErrorModal;
