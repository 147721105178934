import { detectDirection as detectDirectionAdditionalBaggage } from '../AdditionalBaggage/utils';
import { mergeDuplicatedTravellerServices } from '../../Checkout/utils';
export const convertBaggageOnBaggageItem = (selectedItems, segments) => {
    const directions = getDirections(selectedItems, segments);
    const mergedBaggage = mergeDuplicatedTravellerServices(selectedItems, (item) => `${item.segmentIds.toString()}`);
    const servicesMap = new Map(mergedBaggage.map(service => [service.baggage.allowedPassengers[0], service]));
    const totalCount = [...servicesMap.values()].reduce((count, service) => +count + +service.count, 0);
    return {
        baggage: [selectedItems[0].baggage],
        selected: true,
        priceFrom: null,
        servicesBySegments: [
            {
                count: totalCount,
                directions,
                allSegments: directions.length === segments.length,
                service: null
            }
        ]
    };
};
export const calcCount = (selectedItems) => {
    return selectedItems[0].confirmedCount;
};
export const getDirections = (selectedItems, segments) => {
    const segmentIds = new Set();
    selectedItems.forEach(item => {
        const segments = item.segmentIds;
        segments.forEach(id => segmentIds.add(id));
    });
    const segmentRoutes = [];
    segmentIds.forEach(id => {
        const orderSegment = segments.find(segment => segment.id === id);
        if (orderSegment) {
            segmentRoutes.push({
                arrival: orderSegment.arrival.airport,
                departure: orderSegment.departure.airport
            });
        }
    });
    return segmentRoutes;
};
export const factorySelectedUpgrades = (selectedServices) => {
    var _a, _b, _c;
    const result = [];
    const addedKeys = new Map();
    for (const [passengerId, passengerSelectedServices] of Object.entries(selectedServices)) {
        for (const [serviceId, segments] of Object.entries(passengerSelectedServices)) {
            for (const [segmentId] of Object.entries(segments)) {
                const segmentIds = (_a = selectedServices[passengerId][serviceId][segmentId]) === null || _a === void 0 ? void 0 : _a.segmentIds;
                const key = `${passengerId}-${serviceId}-${segmentIds.join('-')}`;
                if (!addedKeys.has(key)) {
                    result.push({
                        passengerId,
                        segmentIds: (_b = selectedServices[passengerId][serviceId][segmentId]) === null || _b === void 0 ? void 0 : _b.segmentIds,
                        serviceId,
                        count: (_c = selectedServices[passengerId][serviceId][segmentId]) === null || _c === void 0 ? void 0 : _c.count
                    });
                    addedKeys.set(key, key);
                }
            }
        }
    }
    return result;
};
export const detectUpgradeDirection = (service, selectedServices, order) => {
    return detectDirectionAdditionalBaggage(service, selectedServices, order);
};
export const joinSelectedServicesByRfics = (selectedServices) => {
    const selectedServiceMap = new Map();
    selectedServices.forEach(service => {
        const key = service.baggage.rfics;
        if (selectedServiceMap.has(key)) {
            selectedServiceMap.get(key).push(service);
        }
        else {
            selectedServiceMap.set(key, [service]);
        }
    });
    return [...selectedServiceMap.values()];
};
