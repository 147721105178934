import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useMobileSegmentFlatInfo } from '../MobileSegmentFlat/hooks';
import RouteArrow from '../../../DesktopFlightRoute/components/Segment/RouteArrow/RouteArrow';
import { getDurationString } from '../../../Results/utils';
import { format } from '../../../utils';
import AircraftPopup from '../../../AircraftPopup';
import Amenities from '../../../Amenities';
import StopsInfo from '../../../DesktopFlightRoute/components/Segment/StopsInfo/StopsInfo';
import { InfoDetails, Link } from '../../../index';
const MobileSegmentUpgrade = props => {
    var _a, _b, _c, _d, _e;
    const { MobileSegmentUpgrade: css } = useTheme('MobileSegmentInfo');
    const { firstSegment, lastSegment } = useMobileSegmentFlatInfo(props);
    const departureDate = format(firstSegment.departure.date, 'dd MMM');
    const arrivalDate = format(firstSegment.departure.date, 'dd MMM');
    return (React.createElement("div", { className: cn(css.root, props.className) },
        React.createElement("div", { className: css.company },
            firstSegment.marketingAirline.icon && (React.createElement("img", { className: css.company__logo, src: firstSegment.marketingAirline.icon, alt: firstSegment.marketingAirline.name })),
            React.createElement("span", { className: css.company__name }, firstSegment.marketingAirline.name)),
        React.createElement("div", { className: css.segmentInfo },
            React.createElement("span", { className: css.segmentInfo__date }, departureDate),
            React.createElement("span", { className: css.segmentInfo__cityName }, firstSegment.departure.airport.city.name),
            React.createElement("span", { className: css.segmentInfo__airportIATA },
                "(",
                firstSegment.departure.airport.iata,
                ")")),
        React.createElement(RouteArrow, { className: css.routeArrow, routeClassName: css.route, circleClassName: css.circle, timeInRouteClassName: css.timeInRoute, flightNumberClassName: cn(css.flightNumber, props.flightNumberClassName), timeInRoute: getDurationString(firstSegment.duration), mobile: true, flightNumber: `${firstSegment.marketingAirline.iata}-${firstSegment.flightNumber}`, stopCount: props.stopCount }),
        React.createElement("div", { className: css.segmentInfo },
            React.createElement("span", { className: css.segmentInfo__date }, arrivalDate),
            React.createElement("span", { className: css.segmentInfo__cityName }, lastSegment.arrival.airport.city.name),
            React.createElement("span", { className: css.segmentInfo__airportIATA },
                "(",
                lastSegment.arrival.airport.iata,
                ")")),
        React.createElement("div", { className: css.aircraftInfoAndAmenities },
            React.createElement(AircraftPopup, { id: firstSegment.aircraft.id, operatingIata: firstSegment.operatingAirline.iata, name: firstSegment.aircraft.name, renderButton: onCLick => (React.createElement(Link, { className: css.aircraftInfo, action: onCLick },
                    firstSegment.aircraft.name,
                    " ",
                    InfoDetails)) }),
            ((_b = (_a = firstSegment.flightInfo) === null || _a === void 0 ? void 0 : _a.amenities) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement(Amenities, { className: css.amenities, amenities: firstSegment.flightInfo.amenities })),
            (props.segments[0].transferDuration || ((_c = firstSegment.stops) === null || _c === void 0 ? void 0 : _c.length) > 0) && (React.createElement(StopsInfo, { className: css.stopsInfo, stopCityName: firstSegment.arrival.airport.city.name, stops: firstSegment.stops, transferDuration: (_e = (_d = props.segments) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.transferDuration })))));
};
export default MobileSegmentUpgrade;
