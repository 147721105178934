var _a;
import { createContext, useContext } from 'react';
import getKey from './randomHeaderGenerator';
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-boost';
import errorLink from './apolloErrorLink';
import * as Cookie from 'js-cookie';
import { captureErrorSentry, getLocale, isRequireCurrencyParam, STAY_LOGGED_IN } from './utils';
import introspectionResult from './fragmentTypes';
import './custom-event-polyfill';
import { CUSTOM_SCHEMAS_URL, get, getCurrency, GRAPHQL_KEY } from './cache';
import { Currency } from './enums';
import { v4 as uuidv4 } from 'uuid';
import ignoredErrorsLink from './ingoredErrorsLink';
import paximizerUTMLink from './paximizerUTMLink';
import WebskyAnalyticsApollo from './DataLayerAnalytics/WebskyAnalyticsApollo';
let token = Cookie.get(GRAPHQL_KEY);
let client;
const urlFromLocalStorage = process.env.ACCEPT_CUSTOM_SCHEMAS_URL === 'true' ? get(CUSTOM_SCHEMAS_URL) : null;
let GRAPHQL_URL = (_a = urlFromLocalStorage !== null && urlFromLocalStorage !== void 0 ? urlFromLocalStorage : process.env.API_URL) !== null && _a !== void 0 ? _a : 'https://hz.websky.aero/graphql/query/nemo';
let GraphQLClientContext;
export const setApiUrl = (newApiUrl) => {
    if (!urlFromLocalStorage) {
        GRAPHQL_URL = newApiUrl;
    }
};
const webskyAnalyticsLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        try {
            WebskyAnalyticsApollo.analyze(operation, response, client);
        }
        catch (e) {
            console.error('Error in webskyAnalyticsLink', e);
            captureErrorSentry(`Error in webskyAnalyticsLink: ${e}`);
        }
        return response;
    });
});
export const createClient = () => {
    const isDemo = document.location.href.includes('demo.websky.tech');
    const needToSendCurrencyInHeaders = !isRequireCurrencyParam();
    if (!client) {
        const fragmentMatcher = new IntrospectionFragmentMatcher({
            introspectionQueryResultData: introspectionResult
        });
        const getTokenLink = new ApolloLink((operation, forward) => {
            const context = operation.getContext();
            let lang = getLocale();
            if (context.lang) {
                lang = context.lang;
            }
            let currency = getCurrency();
            if (isDemo) {
                currency = Currency.EUR;
            }
            else if (context.currency) {
                currency = context.currency;
            }
            const headers = {
                requestUID: Math.floor(Math.random() * 90000000000000 + 10000000000000),
                'Accept-Language': lang,
                'X-EventId': uuidv4()
            };
            if (needToSendCurrencyInHeaders ||
                ['FlightsMinPricesInPeriod', 'MinPrices'].includes(operation.operationName)) {
                headers['X-Currency'] = currency;
            }
            if (token) {
                headers.authorization = token ? `Bearer ${token}` : '';
            }
            operation.setContext(() => ({
                headers: Object.assign(Object.assign({}, headers), getKey())
            }));
            return forward(operation).map(response => {
                const { response: { headers } } = operation.getContext();
                if (headers) {
                    const currentToken = headers.get('X-Token');
                    if (currentToken) {
                        token = currentToken;
                        Cookie.set(GRAPHQL_KEY, token, {
                            expires: !!localStorage.getItem(STAY_LOGGED_IN) ? 90 : undefined,
                            sameSite: 'lax',
                            secure: process.env.NODE_ENV !== 'development'
                        });
                    }
                }
                return response;
            });
        });
        const httpLink = new HttpLink({
            uri: GRAPHQL_URL,
            credentials: 'include'
        });
        client = new ApolloClient({
            link: from([ignoredErrorsLink, errorLink, getTokenLink, paximizerUTMLink, webskyAnalyticsLink, httpLink]),
            cache: new InMemoryCache({ fragmentMatcher })
        });
        WebskyAnalyticsApollo.listenWxViewEvent();
    }
    return client;
};
export const useGraphQLClient = () => {
    if (!GraphQLClientContext) {
        GraphQLClientContext = createContext(createClient());
    }
    return useContext(GraphQLClientContext);
};
