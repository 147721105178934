import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { Sorting } from '../../../../enums';
import { ThemeConsumer } from '../../../../../theme';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import SlideBottom from '../../../../../SlideBottom';
import { Switch, BaseButton, CloseButton } from '../../../../../index';
const sortings = [Sorting.Price, Sorting.FlightTime, Sorting.Departure, Sorting.Arrival];
class Sortings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.getTitleRef = this.getTitleRef.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    getTitleRef(element) {
        this.titleRef = element;
    }
    getOnClick(sorting) {
        return () => {
            this.props.onSelect(sorting);
        };
    }
    onToggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    onClose() {
        this.setState({
            isOpen: false
        });
    }
    render() {
        const { t } = this.props;
        const sortingLabels = {
            [Sorting.Price]: t('Price'),
            [Sorting.Departure]: t('Departure time'),
            [Sorting.FlightTime]: t('Flight time'),
            [Sorting.Arrival]: t('Arrival')
        };
        return (React.createElement(ThemeConsumer, null, ({ Results: { Sortings: css } }) => (React.createElement("div", { className: css.sortings },
            React.createElement("span", { className: cn(css.title, { [css.title_open]: this.state.isOpen }), ref: this.getTitleRef, "data-test-id": "title", onClick: this.onToggle },
                React.createElement("span", { className: css.sortBy },
                    t('Sort by'),
                    ": "),
                React.createElement("span", { className: css.currentSorting }, sortingLabels[this.props.currentSort])),
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", null,
                    this.props.showDirectFlightsSwitch && (React.createElement(BaseButton, { variant: "optionAction", className: cn(css.flightType, {
                            [css.flightType__active]: this.props.onlyDirect
                        }), onClick: this.props.onFilterChange }, t('Nonstop'))),
                    this.props.showBusinessClass && (React.createElement(BaseButton, { variant: "optionAction", className: cn(css.flightType, {
                            [css.flightType__active]: this.props.onlyBusinessFlights || this.props.onlyBusinessFilter
                        }), disabled: this.props.onlyBusinessFlights, onClick: this.props.onBusinessClassChange }, t('Business Class')))),
                React.createElement(Menu, { anchorEl: this.titleRef, open: this.state.isOpen, onClose: this.onClose, classes: {
                        paper: css.paper
                    } }, sortings.map(sorting => (React.createElement(MenuItem, { key: sorting, onClick: this.getOnClick(sorting), classes: {
                        gutters: cn(css.listItem, {
                            [css.listItem_active]: sorting === this.props.currentSort
                        })
                    } }, sortingLabels[sorting]))))),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, ReactDOM.createPortal(React.createElement(SlideBottom, { className: css.slideBottom, isOpen: this.state.isOpen, onClose: this.onClose },
                React.createElement("div", { className: css.mobile },
                    React.createElement("ul", { className: cn(css.mobile__list, { [css.mobile__list_sorting]: true }) },
                        React.createElement("div", { className: css.mobile__list__title },
                            t('Sorting'),
                            ' ',
                            React.createElement(CloseButton, { className: css.mobile__list__close, onClick: this.onClose, variant: "closePopup" })),
                        sortings.map(sorting => (React.createElement("li", { key: sorting, className: css.mobile__list__item },
                            React.createElement(BaseButton, { variant: "optionAction", onClick: this.getOnClick(sorting), className: cn(css.mobile__list__button, {
                                    [css.mobile__list__button_inactive]: sorting !== this.props.currentSort
                                }) }, sortingLabels[sorting]))))),
                    (this.props.showDirectFlightsSwitch || this.props.showBusinessClass) && (React.createElement("div", { className: css.switchWrp },
                        this.props.showDirectFlightsSwitch && (React.createElement(Switch, { labelClassName: css.switch__label, mainClassName: css.switch__component, activeClassName: css.switch_active, label: t('Only direct flights'), isActive: this.props.onlyDirect, onClick: this.props.onFilterChange, switchPosition: 'right' })),
                        this.props.showBusinessClass && (React.createElement(Switch, { labelClassName: css.switch__label, mainClassName: css.switch__component, activeClassName: css.switch_active, label: t('Only business class'), isActive: this.props.onlyBusinessFlights ||
                                this.props.onlyBusinessFilter, onClick: this.props.onBusinessClassChange, switchPosition: 'right', isDisabled: this.props.onlyBusinessFlights })))),
                    React.createElement("div", { className: css.mobile__actions },
                        React.createElement(BaseButton, { className: css.mobile__closeButton, variant: "text", onClick: this.onClose }, t('Ok'))))), document.body))))));
    }
}
Sortings.defaultProps = {
    currentSort: Sorting.Price,
    onlyDirect: false,
    showBusinessClass: false
};
export default withTranslation('Results')(Sortings);
