import * as Inital from './components/Checkin/Initial/Initial.css';
import * as Passengers from './components/Checkin/Order/Passengers/Passengers.css';
import * as Checkin from './components/Checkin/Checkin.css';
import * as Order from './components/Checkin/Order/Order.css';
import * as SelectSegmentsModal from './components/Checkin/Order/Passengers/SelectSegmentsModal/SelectSegmentsModal.css';
import * as VisaForm from './components/Checkin/Order/Passengers/VisaForm/VisaForm.css';
import * as CheckinErrorBoundary from './components/Checkin/CheckinErrorBoundary/CheckinErrorBoundary.css';
import * as DownloadAeroexpress from './components/Checkin/Order/Passengers/RegisteredPassenger/DownloadAeroexpress/DownloadAeroexpress.css';
import * as Hint from './components/Checkin/Hint/Hint.css';
import * as CheckinAddDocuments from './components/Checkin/CheckinAddDocuments/components/CheckinAddDocumentsContent/CheckinAddDocuments.css';
import * as CheckinAddDocumentsService from './components/Checkin/CheckinAddDocuments/components/CheckinAddDocumentsService/CheckinAddDocumentsService.css';
import * as Conditions from './components/Checkin/ConditionsModal/Conditions/Conditions.css';
import * as ConditionsModal from './components/Checkin/ConditionsModal/ConditionsModal.css';
import * as SelectedService from './components/Checkin/SelectedService/SelectedService.css';
import * as PassengersHeader from './components/Checkin/Order/Passengers/PassengersHeader/PassengersHeader.css';
import * as PassengersContent from './components/Checkin/Order/Passengers/PassengersContent/PassengersContent.css';
import * as UnconfirmedServicesModal from './components/Checkin/Order/UnconfirmedServicesModal/UnconfirmedServicesModal.css';
import * as AdditionalServices from './components/Checkin/AdditionalServices/AdditionalServices.css';
export default {
    Inital,
    Passengers,
    Checkin,
    SelectSegmentsModal,
    Order,
    VisaForm,
    CheckinErrorBoundary,
    DownloadAeroexpress,
    Hint,
    CheckinAddDocuments,
    CheckinAddDocumentsService,
    Conditions,
    ConditionsModal,
    SelectedService,
    PassengersHeader,
    PassengersContent,
    UnconfirmedServicesModal,
    AdditionalServices
};
