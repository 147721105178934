import { defaultTheme as priceGraphDefaultTheme } from '../PriceGraph/theme';
import { defaultTheme as priceMatrixDefaultTheme } from '../PriceMatrix/theme';
import * as SearchFormDatepickerStyles from './components/SearchFormDatepicker.css';
import * as MonthStyles from './css/Month/Month.css';
import * as MonthHeaderStyles from './css/Month/MonthHeader/MonthHeader.css';
import * as DayStyles from './css/Month/Day/Day.css';
import * as WeeklyHeaderStyles from './css/Month/WeeklyHeader/WeeklyHeader.css';
import * as MonthsWrapperStyles from './css/MonthsWrapper/MonthsWrapper.css';
import * as PriceGraphStyles from './css/PriceGraph/PriceGraph.css';
import * as SliderStyles from './css/PriceGraph/Slider/Slider.css';
import * as PriceMatrixStyles from './css/PriceMatrix/PriceMatrix.css';
const merge = require('lodash.merge');
export const priceGraphTheme = merge({}, priceGraphDefaultTheme, {
    PriceGraphStyles,
    SliderStyles
});
export const priceMatrixTheme = merge({}, priceMatrixDefaultTheme, {
    PriceMatrixStyles
});
export const defaultDatepickerTheme = {
    MonthsWrapperStyles,
    MonthStyles,
    DayStyles,
    WeeklyHeaderStyles,
    MonthHeaderStyles
};
export default {
    SearchFormDatepickerStyles,
    MonthsWrapperStyles,
    MonthStyles,
    DayStyles,
    WeeklyHeaderStyles,
    MonthHeaderStyles
};
