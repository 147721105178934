import * as React from 'react';
import { NASStatus } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { getPassengerFirstName, getPassengerLastName } from '../Checkout/store/passengers/selectors';
import NasServiceCard from './NasServices/NasServiceCard';
import Tooltip from '../Tooltip';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import InfoLinksLabel from '../InfoLinksLabel';
import Button from '../BaseComponents/Button';
import { useAirportServicesRefund } from './hooks';
import Notice from '../RefundSummary/components/Notice/Notice';
const NasRefundServices = props => {
    const { t } = useTranslation('Refund');
    const css = useTheme('NasRefundServices');
    const travellers = props.order.travellers;
    const [activeCheckbox, setActiveCheckbox] = React.useState(false), [showAgreeTooltip, setShowAgreeTooltip] = React.useState(false);
    React.useEffect(() => {
        if (activeCheckbox) {
            setShowAgreeTooltip(false);
        }
    }, [activeCheckbox]);
    const { refund, loading, refundedWithError, refundedServiceIds } = useAirportServicesRefund(props.order.id);
    const servicesToRefund = React.useMemo(() => {
        const servicesToRefund = [];
        props.selectedServices.forEach(selected => {
            const service = props.services.find(service => service.id === selected.product.id), priceToRefund = {
                amount: 0,
                currency: null
            }, passengerNames = [], passengerIds = [];
            let ticketsNumber = 0;
            selected.orders.forEach(order => {
                if (props.travellersToRefund.includes(order.travellers.id) &&
                    order.status === NASStatus.Confirmed &&
                    order.isRefundable) {
                    const passengerPrice = selected.fare.prices.find(price => price.traveller.id === order.travellers.id);
                    priceToRefund.amount += passengerPrice.price.amount;
                    priceToRefund.currency = passengerPrice.price.currency;
                    const traveller = travellers.find(traveller => traveller.id === order.travellers.id);
                    passengerNames.push(`${getPassengerFirstName(traveller).value} ${getPassengerLastName(traveller).value}`);
                    ticketsNumber++;
                    passengerIds.push(traveller.id);
                }
            });
            if (ticketsNumber > 0) {
                servicesToRefund.push({
                    serviceId: service.id,
                    totalPrice: priceToRefund,
                    type: service.category,
                    serviceName: {
                        name: service.name,
                        terminal: service.terminalName
                    },
                    ticketsNumber,
                    passengerNames,
                    passengerIds
                });
            }
        });
        return servicesToRefund;
    }, [props.selectedServices, props.services, travellers]);
    React.useEffect(() => {
        if ((refundedServiceIds === null || refundedServiceIds === void 0 ? void 0 : refundedServiceIds.length) > 0 || (refundedWithError === null || refundedWithError === void 0 ? void 0 : refundedWithError.length) > 0) {
            props.onSuccessfullyRefunded();
        }
    }, [refundedServiceIds, refundedWithError]);
    const onRefundHandler = () => {
        if (activeCheckbox) {
            refund(servicesToRefund);
        }
        else {
            setShowAgreeTooltip(true);
        }
    };
    return (React.createElement("div", { className: css.nasServicesRefund },
        React.createElement(Notice, { type: "notice", header: t('You should return airport services for all passengers to refund or exchange air tickets'), text: t(servicesToRefund.length > 1
                ? 'Return of services is made automatically when you click on the «Return services» button. The money will be credited to the card within 10 working days'
                : 'Return of service is made automatically when you click on the «Return service» button. The money will be credited to the card within 10 working days') }),
        React.createElement("div", { className: css.nasServices }, servicesToRefund.map((service, key) => (React.createElement(NasServiceCard, { type: service.type, serviceName: service.serviceName, totalPrice: service.totalPrice, passengerNames: service.passengerNames, key: key, ticketsNumber: service.ticketsNumber, travellers: travellers })))),
        (refundedWithError === null || refundedWithError === void 0 ? void 0 : refundedWithError.length) > 0 && (React.createElement(Notice, { type: "notice", header: t('Couldn\'t return the service(s).'), text: t('Please, try again later or contact the airline') })),
        React.createElement("div", { className: css.nasServicesActions },
            React.createElement(Tooltip, { title: t('Please, read the rules of service and accept it'), open: showAgreeTooltip },
                React.createElement(FormControlLabel, { className: css.agree, checked: activeCheckbox, onChange: (_, value) => setActiveCheckbox(value), control: React.createElement(Checkbox, { classes: { root: css.checkbox, checked: css.active } }), label: React.createElement(InfoLinksLabel, { type: 'refund' }) })),
            React.createElement(Button, { variant: "smallSecondary", className: css.cancel, disabled: loading, onClick: props.onCancel }, t('Reject')),
            React.createElement(Button, { variant: "action", className: css.confirm, isLoading: loading, disabled: !activeCheckbox, onClick: onRefundHandler }, t(servicesToRefund.length === 1 ? 'Refund service' : 'Refund services')))));
};
export default NasRefundServices;
