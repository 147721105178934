import { emailRegex } from '../../../utils';
import { isCorrectPhoneNumber } from '../../../PhoneInput/utils';
const requiredField = (value) => (typeof value === 'undefined' ? 'Required' : undefined);
const onlyLetters = (value) => (value.match(/\d/gi) ? 'Only letters' : undefined);
export const useRequestFormValidation = () => {
    const validateName = value => {
        let testResult = undefined;
        for (const testFunc of [requiredField, onlyLetters]) {
            testResult = testFunc(value);
            if (testResult) {
                break;
            }
        }
        return testResult;
    };
    const validateComments = value => {
        return undefined;
    };
    const validateEmail = value => {
        let testResult = undefined;
        for (const testFunc of [requiredField]) {
            testResult = testFunc(value);
            if (testResult) {
                break;
            }
            if (!emailRegex.test(value)) {
                return 'Wrong email';
            }
        }
        return testResult;
    };
    const validatePhone = value => {
        let testResult = undefined;
        for (const testFunc of [requiredField]) {
            testResult = testFunc(value);
            if (testResult) {
                break;
            }
            if (!isCorrectPhoneNumber(value)) {
                return 'Wrong phone';
            }
        }
        return testResult;
    };
    const validateAgreeButton = requiredField;
    return {
        validateName,
        validateEmail,
        validatePhone,
        validateComments,
        validateAgreeButton
    };
};
