import * as React from 'react';
import { CartService } from '../../Cart/types';
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { getActualProduct } from '../utils';
import { useTranslation } from 'react-i18next';
import CommonSummary from './CommonSummary';
const UpgradeFlightClassSummary = props => {
    const { t } = useTranslation('Cart');
    const onDeleteHandler = (travellerId, serviceId, segmentId, allSegments) => {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Extra, {
            type: OrderAdditionalServiceGdsServiceServiceType.Vip,
            passengerId: travellerId,
            segmentId,
            serviceId,
            allSegments
        });
    };
    return (React.createElement(CommonSummary, { flight: props.flight, services: props.services, travellersById: props.travellersById, travellers: props.travellers, groupType: OrderAdditionalServiceGdsServiceServiceType.Vip, serviceFilterPredicate: service => service.subType === GdsServiceVipSubType.Upgrade, serviceOptionItemFactory: ({ selectedService, key, travellerId }) => {
            const segment = props.flight.segments.find(segment => segment.segment.id === key), direction = key === 'allSegments'
                ? t('All flights')
                : `${segment.segment.departure.airport.iata} - ${segment.segment.arrival.airport.iata}`;
            const actualProduct = getActualProduct(selectedService.products);
            return {
                name: `${direction}`,
                id: selectedService.service.id,
                included: selectedService.count === selectedService.confirmedCount,
                price: selectedService.service.price,
                status: actualProduct === null || actualProduct === void 0 ? void 0 : actualProduct.status,
                onDelete: () => onDeleteHandler(travellerId, selectedService.serviceId, key, key === 'allSegments')
            };
        }, serviceOptionGroupFactory: ({ items, travellerId }) => {
            const newItems = [];
            if (!!items.length) {
                newItems.push({
                    traveller: props.travellersById.get(travellerId),
                    headerType: 'travellerName',
                    items,
                    headerIcon: null
                });
            }
            return newItems;
        } }, children => props.children(children)));
};
export default UpgradeFlightClassSummary;
