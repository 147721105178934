import { __rest } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { closeIcon } from '../icons';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer/SwipeableDrawer';
import ModalTransitionComponent from '../../ModalTransitionComponent';
const Modal = (_a) => {
    var { closeClassName, isRounded, closeButtonRenderer, slideUp, bottomDrawer, bottomDrawerClassName } = _a, props = __rest(_a, ["closeClassName", "isRounded", "closeButtonRenderer", "slideUp", "bottomDrawer", "bottomDrawerClassName"]);
    const [dialogIsMounted, setDialogIsMounted] = useState(!props.mountOnEnter);
    const { Modal: css } = useTheme('Modal');
    const defaultProps = {
        scroll: 'body',
        maxWidth: 'lg'
    };
    if (slideUp) {
        defaultProps.TransitionComponent = ModalTransitionComponent;
    }
    useEffect(() => {
        if (props.open && !dialogIsMounted) {
            setDialogIsMounted(true);
        }
    }, [props.open, dialogIsMounted]);
    const dialogClasses = props.classes ? props.classes : {};
    const renderContent = () => {
        return (React.createElement(React.Fragment, null,
            props.extraTitle && React.createElement("div", { className: cn(css.extraTitle) }, props.extraTitle),
            props.onClose && !closeButtonRenderer && (React.createElement("span", { className: cn(css.closeIcon, closeClassName, {
                    [css.closeIcon_mobileFullScreen]: props.fullScreen
                }), onClick: props.onClose }, closeIcon)),
            props.onClose && closeButtonRenderer && closeButtonRenderer(props.onClose),
            dialogIsMounted && props.children));
    };
    if (bottomDrawer) {
        return (React.createElement(SwipeableDrawer, { onClose: props.onClose, onOpen: () => { }, open: props.open, anchor: "bottom", classes: Object.assign(Object.assign({}, dialogClasses), { paper: cn(css.modal, css.modal_bottom, { [css.modal_mobileFullScreen]: props.fullScreen, [css.modal_rounded]: isRounded }, dialogClasses.paper, bottomDrawerClassName) }) }, renderContent()));
    }
    return (React.createElement(Dialog, Object.assign({}, defaultProps, props, { classes: Object.assign(Object.assign({}, dialogClasses), { paper: cn(css.modal, { [css.modal_mobileFullScreen]: props.fullScreen, [css.modal_rounded]: isRounded }, dialogClasses.paper), scrollBody: cn(css.scrollBody, dialogClasses.scrollBody) }) }), renderContent()));
};
export default Modal;
