import { useEffect, useMemo, useState } from 'react';
import { useConfig } from '../context';
export const useUpsaleServicesModal = (travellers, additionalServices, serviceType, onRefuse, onAgreeServices) => {
    const [isOpen, setOpen] = useState(false);
    const { global: { upsaleServices: upsaleServicesConfig } } = useConfig();
    const validServices = upsaleServicesConfig === null || upsaleServicesConfig === void 0 ? void 0 : upsaleServicesConfig[serviceType];
    const [refuseServicesPayload, upsaleServices] = useMemo(() => {
        const refuseServicesPayload = [];
        const upsaleServices = [];
        travellers.forEach(traveller => {
            traveller.preselectedServices.gdsServices.services.forEach(preSelectedService => {
                const service = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.gdsServices.services.find(srv => srv.id === preSelectedService.serviceId);
                if (service && service.type === serviceType) {
                    const validSrv = validServices === null || validServices === void 0 ? void 0 : validServices.find(srv => srv.rfisc === service.rfisc);
                    if (validSrv) {
                        const payloadService = {
                            id: service.id,
                            type: service.type,
                            price: service.price,
                            name: service.name
                        };
                        preSelectedService.segmentIds.forEach(segmentId => {
                            refuseServicesPayload.push({
                                passengerId: traveller.id,
                                segmentId,
                                serviceId: preSelectedService.serviceId,
                                service: payloadService
                            });
                        });
                        upsaleServices.push({
                            service,
                            description: validSrv.description,
                            icon: validSrv.icon,
                            title: validSrv === null || validSrv === void 0 ? void 0 : validSrv.title,
                            extraTitle: validSrv === null || validSrv === void 0 ? void 0 : validSrv.extraTitle,
                            extraTitleShowDates: validSrv === null || validSrv === void 0 ? void 0 : validSrv.extraTitleShowDates
                        });
                    }
                }
            });
        });
        return [refuseServicesPayload, upsaleServices];
    }, [travellers, additionalServices, serviceType]);
    useEffect(() => {
        if (refuseServicesPayload.length) {
            setOpen(true);
        }
    }, []);
    const refuseHandler = () => {
        setOpen(false);
        onRefuse === null || onRefuse === void 0 ? void 0 : onRefuse(refuseServicesPayload);
    };
    const agreeHandler = () => {
        setOpen(false);
        onAgreeServices === null || onAgreeServices === void 0 ? void 0 : onAgreeServices(refuseServicesPayload);
    };
    return {
        isOpen,
        upsaleServices,
        refuseHandler,
        agreeHandler
    };
};
