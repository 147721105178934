import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import StepTitle from '../StepTitle/StepTitle';
import StepsButtons from '../StepsButtons/StepsButtons';
import AddFiles from '../AddFiles/AddFiles';
import MobileStep from '../MobileStep/MobileStep';
import { AttachFile } from '../../../Icons';
import { useTheme } from '../../../theme';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { useSteps } from '../Refund/hooks';
import { StepType } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { getDocuments } from '../../store/documents/selectors';
import { getSelectedReason } from '../../store/reason/selectors';
import { getPassengers, getSelectedPassengers } from '../../store/passengers/selectors';
import { setDocuments } from '../../store/documents/actions';
import cn from 'classnames';
const AddFilesPage = props => {
    const { Refund: css } = useTheme('Refund');
    const { t } = useTranslation('Refund');
    const { docsToFilesMap } = props;
    const selectedDocuments = useSelector(getDocuments);
    const selectedReason = useSelector(getSelectedReason);
    const selectedPassengers = useSelector(getSelectedPassengers);
    const passengers = useSelector(getPassengers);
    const { currentStep, canGoToNextStep, goToNextStep, goToPrevStep, isCurrentActiveStepAfter, handleMobileCloseClick } = useSteps();
    const dispatch = useDispatch();
    const handleSetDocuments = useCallback((documents) => dispatch(setDocuments(documents)), [
        passengers
    ]);
    const isActive = currentStep.type === StepType.AttachFiles;
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: 'mobile' }, isActive && (React.createElement(React.Fragment, null,
            React.createElement(StepTitle, { className: css.stepTitle, icon: AttachFile, title: t('Attach the required files') }),
            React.createElement(AddFiles, { selectedDocuments: selectedDocuments, refundReason: selectedReason, docsFilesMap: docsToFilesMap, passengers: selectedPassengers, onFilesLoaded: handleSetDocuments }),
            React.createElement(StepsButtons, { className: css.buttons, position: 'center', backButton: { children: t('Back'), onClick: goToPrevStep }, nextButton: {
                    children: t('Continue'),
                    onClick: goToNextStep,
                    disabled: !canGoToNextStep
                } })))),
        isActive && (React.createElement(MobileStep, { isActive: isActive, onCloseClick: handleMobileCloseClick(StepType.AttachFiles), className: cn(css.mobileStep, {
                [css.passed]: isCurrentActiveStepAfter(StepType.AttachFiles)
            }), icon: AttachFile, renderTitle: () => React.createElement("span", null, t('Attach the required files')) },
            React.createElement(AddFiles, { selectedDocuments: selectedDocuments, refundReason: selectedReason, docsFilesMap: docsToFilesMap, passengers: selectedPassengers, onFilesLoaded: handleSetDocuments })))));
};
export default AddFilesPage;
