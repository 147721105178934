import { __awaiter } from "tslib";
import React, { useCallback, useMemo } from 'react';
import './UpgradeFlightClass.css';
import { useTheme } from '../../../../../theme';
import { getTravellersMap, initI18n } from '../../../../../utils';
import { useToggleable } from '../../../../../hooks';
import { useRenderers } from '../../../../../renderProps';
import CheckoutAdditionalService from '../../../../';
import { UpgradeFlightClass } from '../../../../../UpgradeFlightClass';
import { useDispatch, useSelector } from 'react-redux';
import { getIsUpgradeClassSelectedForAllSegments, getIsUpgradeClassServiceSelected, getOrder, getUpgradeClassServices } from '../../../../../Checkout/store/order/selectors';
import FullscreenDialog from '../../../../../FullscreenDialog';
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType, useSaveOrderServicesMutation } from '@websky/graphql';
import UpgradeFlightClassSummary from '../../../../../ServiceSummary/services/UpgradeFlightClassSummary';
import { useTranslation } from 'react-i18next';
import { useCart } from '../../../../../Checkout/components/Checkout/Stepbar/hooks';
import { fillOrder } from '../../../../../Checkout/store/order/actions';
import { useServiceEventToggleable } from '../../../../../Checkout/components/Checkout/hooks';
initI18n('UpgradeFlightClass');
const UpgradeFlightClassCheckout = () => {
    var _a;
    const { t } = useTranslation('Checkout');
    const service = useSelector(getUpgradeClassServices)[0];
    const order = useSelector(getOrder);
    const { onDeleteItem } = useCart();
    const dispatch = useDispatch();
    const isSelected = useSelector(getIsUpgradeClassServiceSelected);
    const isSelectedForAllSegments = useSelector(getIsUpgradeClassSelectedForAllSegments);
    const { UpgradeFlightCLassCheckoutService: css } = useTheme('CheckoutAdditionalService');
    const { isOpen, open, close } = useToggleable(false);
    useServiceEventToggleable({
        services: [service],
        serviceType: OrderAdditionalServiceGdsServiceServiceType.Vip,
        serviceSubType: GdsServiceVipSubType.Upgrade,
        onOpen: open
    });
    const { CheckoutAdditionalService: CustomCheckoutAdditionalService } = useRenderers();
    const ServiceWrapper = (_a = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _a !== void 0 ? _a : CheckoutAdditionalService;
    const [saveOrderServices, { data, loading }] = useSaveOrderServicesMutation();
    // const totalPrice = useMemo(() => {
    // 	const totalPrice: Money = {
    // 		amount: 0,
    // 		currency: order?.price?.total?.currency
    // 	};
    //
    // 	for (const traveller of order.travellers) {
    // 		const travellerService = traveller.services?.gdsServices?.services?.find(
    // 			travellerService => travellerService.serviceId === service.id
    // 		);
    //
    // 		if (travellerService) {
    // 			totalPrice.amount += travellerService.count * service.price.amount;
    // 		}
    // 	}
    //
    // 	return totalPrice;
    // }, [order.travellers]);
    const handleSaveServices = useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield saveOrderServices({
            variables: params
        });
        if (data.SaveOrderServices) {
            dispatch(fillOrder(data.SaveOrderServices));
        }
        close();
    }), [data, loading, saveOrderServices]);
    const buttons = useMemo(() => {
        if (isSelectedForAllSegments) {
            return null;
        }
        if (isSelected) {
            return [
                {
                    action: open,
                    text: t('Change'),
                    buttonType: 'change',
                    variant: 'text'
                }
            ];
        }
        else {
            return [
                {
                    action: open,
                    text: t('Add'),
                    buttonType: 'change',
                    variant: 'optionEdit'
                }
            ];
        }
    }, [isSelected, isSelectedForAllSegments]);
    if (!(service === null || service === void 0 ? void 0 : service.canBeAdded)) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(UpgradeFlightClassSummary, { flight: order.flight, travellers: order.travellers, services: order.additionalServices, onServiceDelete: onDeleteItem, travellersById: getTravellersMap(order.travellers) }, children => {
            return (React.createElement(ServiceWrapper, { buttons: buttons, header: service.name, className: css.upgradeFlightClass, description: service.description, isSelected: isSelected, addClick: open, priceFrom: service.price, priceTotal: order.price.vip }, children));
        }),
        React.createElement(FullscreenDialog, { isOpen: isOpen, onClose: close, contentClassName: css.content, fadeTransition: true },
            React.createElement(UpgradeFlightClass, { onBackClick: close, onSaveServices: handleSaveServices, loading: loading }))));
};
export default UpgradeFlightClassCheckout;
