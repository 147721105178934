import { addMonths, differenceInCalendarMonths, differenceInDays, toDate, parseISO } from 'date-fns';
import * as React from 'react';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { ThemeConsumer } from '../../theme';
import Slider from '../../Slider/components/Slider';
import { RouteType } from '../types';
import Legends from './Legends/Legends';
import { Month } from './Month/Month';
import { safeAddDays, safeStartOfMonth } from '../utils';
import { MonthsList } from './MonthsList/MonthsList';
import { createWithThemeDecorator, format, removeDotsFromDate, initI18n, TABLET_MIN_WIDTH } from '../../utils';
import { Money } from '../../Money';
import { ThemeProvider } from '../../theme';
import { BaseButton } from '../../index';
initI18n('PriceGraph');
export const MonthsPrices = React.createContext({
    monthsPricesMap: {},
    prices: {}
});
const SLIDER_STEP = 5;
class PriceGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openedDate: this.props.openDate
        };
        this.monthPricesMap = {};
        this.onItemCentered = this.onItemCentered.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.sliderArrowHandler = this.sliderArrowHandler.bind(this);
        this.mobileArrowHandler = this.mobileArrowHandler.bind(this);
    }
    getCurrentMonth() {
        const date = this.state.openedDate
            ? safeStartOfMonth(this.state.openedDate).toISOString()
            : safeStartOfMonth(this.props.openDate).toISOString();
        if (!this.isMonthExist(parseISO(date))) {
            for (const dateISO in this.monthPricesMap) {
                if (this.monthPricesMap.hasOwnProperty(dateISO)) {
                    return dateISO;
                }
            }
        }
        return date;
    }
    onItemCentered(startItemIndex, minDate) {
        const day = safeAddDays(minDate, startItemIndex);
        this.setState(() => ({
            openedDate: day
        }));
    }
    getCenteredDayIndex(minDate) {
        if (this.state.openedDate) {
            return differenceInDays(this.state.openedDate, minDate);
        }
        else if (this.props.backDate) {
            return differenceInDays(this.props.backDate, minDate);
        }
        else if (this.props.outDate) {
            return differenceInDays(this.props.outDate, minDate);
        }
        return differenceInDays(this.props.openDate, minDate);
    }
    isMonthExist(date) {
        const startOfMonth = safeStartOfMonth(date).toISOString();
        return this.monthPricesMap.hasOwnProperty(startOfMonth);
    }
    getContext() {
        var _a;
        const months = {};
        for (const date in this.props.prices) {
            if (this.props.prices.hasOwnProperty(date)) {
                const monthDate = safeStartOfMonth(date).toISOString(), price = (_a = this.props.prices[date]) === null || _a === void 0 ? void 0 : _a.price;
                if (price && Object.keys(price).length) {
                    if (months.hasOwnProperty(monthDate)) {
                        if (!months[monthDate].minPrice) {
                            months[monthDate].minPrice = {
                                amount: price.amount,
                                currency: price.currency
                            };
                        }
                        if (!months[monthDate].maxPrice) {
                            months[monthDate].maxPrice = {
                                amount: price.amount,
                                currency: price.currency
                            };
                        }
                        if (months[monthDate].minPrice.amount > price.amount) {
                            months[monthDate].minPrice.amount = price.amount;
                        }
                        if (months[monthDate].maxPrice.amount < price.amount) {
                            months[monthDate].maxPrice.amount = price.amount;
                        }
                    }
                    else {
                        months[monthDate] = {
                            minPrice: {
                                amount: price.amount,
                                currency: price.currency
                            },
                            maxPrice: {
                                amount: price.amount,
                                currency: price.currency
                            }
                        };
                    }
                }
                else if (!months.hasOwnProperty(monthDate)) {
                    months[monthDate] = {
                        minPrice: null,
                        maxPrice: null
                    };
                }
            }
        }
        this.monthPricesMap = months;
        return months;
    }
    getMonthsArray(monthMap) {
        const monthsArray = [];
        for (const month in monthMap) {
            if (monthMap.hasOwnProperty(month)) {
                monthsArray.push({
                    dateISO: month,
                    minPrice: monthMap[month].minPrice
                        ? {
                            amount: monthMap[month].minPrice.amount,
                            currency: monthMap[month].minPrice.currency
                        }
                        : null
                });
            }
        }
        return monthsArray;
    }
    sliderArrowHandler(direction, startItemIndex, itemsCount, itemsOutsideBoundary) {
        if (itemsOutsideBoundary === 0) {
            const nextMonth = addMonths(parseISO(this.getCurrentMonth()), direction === 'next' ? 1 : -1);
            if (this.isMonthExist(nextMonth)) {
                this.setState(() => {
                    this.props.onMonthChange && this.props.onMonthChange(nextMonth);
                    return {
                        openedDate: toDate(nextMonth)
                    };
                });
            }
            else {
                // get prices for previous month only after click on prev button
                this.setState(() => {
                    this.props.onPricesEnd(addMonths(nextMonth, -11), direction);
                    this.props.onMonthChange && this.props.onMonthChange(nextMonth);
                    return {
                        openedDate: toDate(nextMonth)
                    };
                });
            }
        }
    }
    mobileArrowHandler(direction, startItemIndex, minDate) {
        const date = safeAddDays(minDate, startItemIndex);
        this.setState({
            openedDate: toDate(date)
        });
    }
    onSelectChange(value) {
        this.setState(() => {
            this.props.onMonthChange && this.props.onMonthChange(parseISO(value));
            return {
                openedDate: parseISO(value)
            };
        });
    }
    isLongPrice(money) {
        var _a, _b;
        return ((_b = (_a = money === null || money === void 0 ? void 0 : money.amount) === null || _a === void 0 ? void 0 : _a.toString(10)) === null || _b === void 0 ? void 0 : _b.length) > 7;
    }
    isNextMonthExists() {
        const nextMonth = addMonths(parseISO(this.getCurrentMonth()), 1);
        const isNextMonthExists = this.isMonthExist(nextMonth);
        if (!isNextMonthExists && this.props.onPricesEnd) {
            this.props.onPricesEnd(parseISO(this.getCurrentMonth()), 'next');
        }
        return isNextMonthExists;
    }
    isPrevMonthExists() {
        return differenceInCalendarMonths(new Date(), parseISO(this.getCurrentMonth())) !== 0;
    }
    render() {
        var _a;
        const t = this.props.t;
        const { PriceGraph: { SliderStyles: sliderTheme, PriceGraphStyles: theme } } = this.props.theme;
        const context = {
            monthsPricesMap: this.getContext(),
            prices: this.props.prices
        };
        const currentMonth = this.getCurrentMonth(), maxPriceOfMonth = context.monthsPricesMap[currentMonth].maxPrice
            ? context.monthsPricesMap[currentMonth].maxPrice
            : { amount: 0, currency: null }, minPriceOfMonth = context.monthsPricesMap[currentMonth].minPrice
            ? context.monthsPricesMap[currentMonth].minPrice
            : { amount: 0, currency: null }, monthsArray = this.getMonthsArray(context.monthsPricesMap), minDate = safeStartOfMonth(monthsArray[0].dateISO);
        let centeredItem = 0;
        if (this.props.openDate) {
            centeredItem = differenceInDays(this.state.openedDate ? this.state.openedDate : this.props.openDate, minDate);
        }
        const selectedPrice = this.props.prices[this.state.openedDate.toISOString()];
        const onSelect = () => {
            this.props.onSelect(this.state.openedDate, this.props.routeType === RouteType.Return);
        };
        return (React.createElement("div", { className: theme.container },
            React.createElement("div", { className: theme.header },
                React.createElement("div", { className: theme.routeType }, t('Price for 1 direction')),
                React.createElement(MonthsList, { months: monthsArray, selectedMonth: this.getCurrentMonth(), onChange: this.onSelectChange })),
            React.createElement(MediaQuery, { maxWidth: +TABLET_MIN_WIDTH + 1 },
                React.createElement("div", { className: classnames(theme.graphHeader, {
                        [theme.graphHeader_longPrice]: this.isLongPrice(maxPriceOfMonth)
                    }) },
                    React.createElement("div", { className: theme.graphHeader__dates },
                        React.createElement("div", { className: classnames(theme.graphHeader__outDate, {
                                [theme.graphHeader__date_notActive]: this.props.routeType === RouteType.Return
                            }) },
                            !this.props.outDate &&
                                removeDotsFromDate(format(toDate(this.state.openedDate), 'd MMM, EEEEEE')),
                            this.props.outDate &&
                                removeDotsFromDate(format(toDate(this.props.routeType === RouteType.Return
                                    ? this.props.outDate
                                    : this.state.openedDate), 'd MMM, EEEEEE'))),
                        this.props.outDate && (React.createElement("div", { className: classnames(theme.graphHeader__backDate, {
                                [theme.graphHeader__date_notActive]: this.props.routeType === RouteType.Outbound
                            }) }, this.props.backDate &&
                            removeDotsFromDate(format(toDate(this.props.routeType === RouteType.Outbound
                                ? this.props.backDate
                                : this.state.openedDate), 'd MMM, EEEEEE')))),
                        React.createElement("div", { className: theme.graphHeader__priceWrapper },
                            t('from'),
                            ((_a = selectedPrice === null || selectedPrice === void 0 ? void 0 : selectedPrice.price) === null || _a === void 0 ? void 0 : _a.amount) && (React.createElement(Money, { moneyClassName: theme.graphHeader__money, money: selectedPrice === null || selectedPrice === void 0 ? void 0 : selectedPrice.price })))))),
            React.createElement("div", { className: classnames(theme.calendar, {
                    [theme.calendar_longPrice]: this.isLongPrice(maxPriceOfMonth)
                }) },
                React.createElement("div", { className: theme.daysWrapper },
                    React.createElement(Legends, { maxPrice: maxPriceOfMonth, minPrice: minPriceOfMonth }, ref => (React.createElement(ThemeProvider, { value: { Slider: { SliderTheme: sliderTheme } } },
                        React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH + 1, values: window['testMediaQueryValues'] },
                            React.createElement(Slider, { offset: SLIDER_STEP, innerRef: ref, wrapperClassName: theme.days, centeredItem: centeredItem, onRangeChange: this.sliderArrowHandler, hideDisabledLeftArrow: !this.isPrevMonthExists(), hideDisabledRightArrow: !this.isNextMonthExists() },
                                React.createElement(MonthsPrices.Provider, { value: context },
                                    React.createElement(Month, { initialDate: parseISO(this.getCurrentMonth()), routeType: this.props.routeType ? this.props.routeType : RouteType.Outbound, onSelect: this.props.onSelect, backDate: this.props.backDate, outDate: this.props.outDate })))),
                        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                            React.createElement(Slider, { offset: SLIDER_STEP, innerRef: ref, wrapperClassName: theme.days, leftStartItem: this.getCenteredDayIndex(minDate), onItemCentered: startItemIndex => this.onItemCentered(startItemIndex, minDate), onRangeChange: (direction, startItemIndex) => this.mobileArrowHandler(direction, startItemIndex, minDate), touchEvents: true, touchTransition: "by-items", minItemWidth: 17 },
                                React.createElement(MonthsPrices.Provider, { value: context }, monthsArray.map((date, index) => {
                                    return (React.createElement(Month, { key: index, initialDate: parseISO(date.dateISO), routeType: this.props.routeType
                                            ? this.props.routeType
                                            : RouteType.Outbound, onSelect: this.props.onSelect, backDate: this.props.backDate, outDate: this.props.outDate, focusedDate: this.state.openedDate.toISOString(), isMobileMode: true }));
                                }))))))))),
            selectedPrice && (React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                React.createElement("div", { className: theme.footer },
                    React.createElement(BaseButton, { variant: "smallPrimary", className: theme.button, onClick: onSelect }, t('Show flights')))))));
    }
}
export default createWithThemeDecorator(ThemeConsumer)(withTranslation('PriceGraph')(PriceGraph));
