import * as React from 'react';
import { useContext, useMemo, useRef } from 'react';
import Dialog from '../../../../../../Service/components/Dialog/Dialog';
import { useTranslation } from 'react-i18next';
import SeatMap from '../../../../../../SeatMap/components/Component';
import BaggagePage from '../../../../../../BaggagePage/components/BaggagePage';
import { useDispatch, useSelector } from 'react-redux';
import { getAdditionalBaggage, getAdditionalServices, getBaggage, getBrandIncludedBaggage, getIsOddOrderId, getMeals, getOverweightBaggage, getSeatMap, getSegmentsForServices, getSpecialEquipment, getTravellers, getUpgradeBaggage } from '../../../../../store/order/selectors';
import { setSplitTest, SplitTest } from '../../../../../../analytics';
import { MOBILE_MIN_WIDTH } from '../../../../../../utils';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../../../../theme';
import { MealWrapper } from '../../../../../../MealPage/components/MealWrapper';
import MealService from '../../../../../../MealService';
import { ServiceType } from '../../types';
import { getMealReduxCallbacks, getSeatReduxCallbacks } from '../../../../../utils';
import { getSelectedBaggage, getSelectedExtraSeats } from '../../../../../store/passengers/selectors';
import { ShowLoaderAfterBookingContext } from '../../../ServicePage/ServicePageCtx';
import SimpleLoader from '../../../../../../SimpleLoader';
import { isServicesSaving } from '../../../../../store/servicesIsSaving/selectors';
import { useConfig } from '../../../../../../context';
import Toolbar from '../../../../../../Toolbar';
import { OrderStatus } from '@websky/graphql';
import { MealViewMode } from '../../../../../../Meal/types';
import { getSelectedServicesState } from '../../../../../store/selectedServices/selectors';
import AvailableServicesCountContextProvider from '../../../../../../AdditionalServices/context';
import { useSeatMapToolbarProps } from '../../../../../../SeatMap/components/hooks';
const ServiceCardDialog = ({ onClose, openedModal, mealTotalPrice, selectedMeals, order, passengers, passengerLabels, analyticsMeta, seatsTotalPrice, baggageTotalPrice, segmentId, setMultipleSelectedServices }) => {
    const { t } = useTranslation('Checkout');
    const css = useTheme('Checkout').OrderPaidServiceCards;
    const seatMapCss = useTheme('SeatMap').ComponentStyles;
    const baggagePageCss = useTheme('BaggagePage').BaggagePage;
    const segments = useSelector(getSegmentsForServices);
    const dispatch = useDispatch();
    const isOddOrderId = useSelector(getIsOddOrderId);
    const baggage = useSelector(getBaggage);
    const meals = useSelector(getMeals);
    const additionalServices = useSelector(getAdditionalServices);
    const travellers = useSelector(getTravellers);
    const includedBaggage = useSelector(getBrandIncludedBaggage);
    const upgradeBaggage = useSelector(getUpgradeBaggage);
    const additionalBaggage = useSelector(getAdditionalBaggage);
    const specialBaggage = useSelector(getSpecialEquipment);
    const overweightBaggage = useSelector(getOverweightBaggage);
    const selectedBaggage = useSelector(getSelectedBaggage);
    const isOrderServicesSaving = useSelector(isServicesSaving);
    const selectedExtraSeats = useSelector(getSelectedExtraSeats);
    const showLoaderafterBooking = useContext(ShowLoaderAfterBookingContext);
    const selectedServices = useSelector(getSelectedServicesState);
    const seatMap = useSelector(getSeatMap);
    const { segmentIndex, passengerIndex, setSegment, setPassenger, onToolbarButtonClick } = useSeatMapToolbarProps(seatMap);
    const dialogRef = useRef();
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const { global: { maxBaggage, maxBaggagePerPassenger, dynamicBaggagePricing } } = useConfig();
    let simpleBaggageSelector = isOddOrderId && (segments.length === 1 || isMobile);
    if (typeof maxBaggage === 'number' || typeof maxBaggagePerPassenger === 'number') {
        simpleBaggageSelector = false;
    }
    setSplitTest(SplitTest.OneButtonBaggageSelector, simpleBaggageSelector);
    const fareFamiliesMap = new Map();
    order.flight.segmentGroups.forEach(segmentGroup => {
        const fareFamily = segmentGroup.fareFamily;
        if (!fareFamily) {
            return;
        }
        if (!fareFamiliesMap.has(fareFamily.id)) {
            fareFamiliesMap.set(fareFamily.id, {
                fareFamily,
                segmentIds: []
            });
        }
        segmentGroup.segments.forEach(segment => fareFamiliesMap.get(fareFamily.id).segmentIds.push(segment.id));
    });
    const mealCallbacks = useMemo(() => getMealReduxCallbacks(dispatch), [dispatch]);
    const seatMapCallbacks = useMemo(() => getSeatReduxCallbacks(passengers, setMultipleSelectedServices), [
        passengers,
        setMultipleSelectedServices
    ]);
    const baggageStyles = useMemo(() => ({ BaggagePage: { BaggagePage: { baggage: css.baggagePage__baggage } } }), []);
    const hideHeader = useMemo(() => {
        return !isMobile && (openedModal === ServiceType.Seats || openedModal === ServiceType.Meal);
    }, [isMobile, openedModal]);
    const disable = order.status === OrderStatus.Cancelled || !order.canAddServices;
    const initialSelectedMeals = React.useMemo(() => {
        return [...selectedMeals];
    }, [isOrderServicesSaving]);
    const formattedTravellers = useMemo(() => travellers.map(traveller => (Object.assign(Object.assign({}, traveller), { label: passengerLabels[traveller.id] }))), [travellers, passengerLabels]);
    return (React.createElement(Dialog, { dialogType: 'fullscreen', isOpen: !!openedModal, dialogHeader: t(openedModal), totalPrice: openedModal === ServiceType.Meal && !isMobile && mealTotalPrice, withoutControls: true, dialogContentClassName: cn({
            [css.baggage__dialogContent]: openedModal === ServiceType.Baggage
        }), hideHeader: hideHeader, onClose: onClose, ref: dialogRef, content: React.createElement(React.Fragment, null,
            showLoaderafterBooking && React.createElement(SimpleLoader, null),
            openedModal === ServiceType.Seats && (React.createElement(React.Fragment, null,
                React.createElement(SeatMap, Object.assign({ passengers: passengers, showRecommendations: false, seatMap: order.flight.seatMap, fullScreen: false, segmentIndex: segmentIndex, passengerIndex: passengerIndex, onProceed: onClose, isLoading: isOrderServicesSaving, onClose: onClose, setSegment: setSegment, setPassenger: setPassenger, selectedExtraSeats: selectedExtraSeats, isNewOrder: order.status === OrderStatus.New, dialogRef: dialogRef, className: seatMapCss.dialogWrapper, segmentId: segmentId }, seatMapCallbacks)),
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Toolbar, { totalPrice: seatsTotalPrice, onButtonClick: () => onToolbarButtonClick(onClose), onBackButtonClick: onClose })))),
            openedModal === ServiceType.Baggage && (React.createElement(ThemeProvider, { value: baggageStyles },
                React.createElement(BaggagePage, { simpleBaggageSelector: simpleBaggageSelector, baggage: [...baggage], segments: segments, passengers: passengers, passengerLabels: passengerLabels, includedBaggage: includedBaggage, upgradeBaggage: upgradeBaggage, additionalBaggage: additionalBaggage, specialBaggage: specialBaggage, overweightBaggage: overweightBaggage, selectedBaggage: selectedBaggage, isLoading: isOrderServicesSaving, hideBanner: isMobile, dynamicBaggagePricing: dynamicBaggagePricing, disabled: disable, className: baggagePageCss.fullscreenModal__content, analyticsMeta: analyticsMeta }),
                React.createElement(Toolbar, { totalPrice: baggageTotalPrice, onButtonClick: onClose, onBackButtonClick: onClose }))),
            openedModal === ServiceType.Meal && (React.createElement("div", { className: css.meal__dialogContent },
                React.createElement(MealService, { meals: meals, segments: segments, selectedMeals: selectedMeals, initialSelectedMeals: initialSelectedMeals, isLoading: isOrderServicesSaving, onMobileBack: onClose, onMobileProceed: onClose, onClose: onClose, segmentId: Number.isInteger(segmentId) ? segmentId.toString() : null, mealRenderer: (meal, segmentId, isSingleMeal, mode = MealViewMode.select) => (React.createElement(AvailableServicesCountContextProvider, { value: { order, selectedServices }, key: meal.id },
                        React.createElement(MealWrapper, Object.assign({ fullSize: isSingleMeal, key: meal.id, gdsServices: additionalServices.gdsServices, meal: meal, segmentId: segmentId, selectedMeals: selectedMeals, initialSelectedMeals: initialSelectedMeals, passengers: formattedTravellers, isLoading: isOrderServicesSaving, mode: mode }, mealCallbacks)))) }),
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Toolbar, { totalPrice: mealTotalPrice, onButtonClick: onClose, onBackButtonClick: onClose }))))) }));
};
export default ServiceCardDialog;
