import * as React from 'react';
import cn from 'classnames';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import FareGroup from '../../FareGroup';
import DesktopFlightInfo from '../../DesktopFlightInfo';
import Header from './Header/Header';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import { useDesktopFlight } from '../hooks';
initI18n('DesktopFlight');
const DesktopFlight = props => {
    const { Flight: css } = useTheme('DesktopFlight');
    const { t } = useTranslation();
    const { flightBlock, lastSelectedFare, activeTab, getFareGroupTabHandler, handleFlightInfoTab, filteredPrices, handleEconomyFareGroupsTab, economyFareGroups, businessFareGroups, getOnFareSelect } = useDesktopFlight(props);
    return (React.createElement("div", { ref: flightBlock, className: cn(css.flight, {
            [css.flight_open]: activeTab !== null
        }) },
        React.createElement(Header, { flight: props.flight, getOnFareClickHandler: getFareGroupTabHandler, onInfoClick: handleFlightInfoTab, activeTab: activeTab, singleFare: props.singleFare, prices: filteredPrices, selectedFares: props.selectedFares }),
        React.createElement(Collapse, { in: activeTab !== null, mountOnEnter: true },
            React.createElement("div", { className: css.flight__content },
                React.createElement(SwipeableViews, { index: activeTab, animateHeight: true },
                    React.createElement(DesktopFlightInfo, { flight: props.flight, onButtonClick: handleEconomyFareGroupsTab, buttonText: t('DesktopFlight:Select fare') }),
                    React.createElement("div", { className: css.fareGroups }, economyFareGroups.map((group, index) => {
                        var _a, _b;
                        return (React.createElement(FareGroup, { showUnavailableOptions: true, key: index, isDisabled: !group.prices || (lastSelectedFare && lastSelectedFare !== group.fareFamily.id), fare: group.fareFamily, onSelect: getOnFareSelect(group), price: group.prices && group.prices[0] ? group.prices[0].price : null, flightId: (_b = (_a = group.prices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.flight.id }));
                    })),
                    businessFareGroups && (React.createElement("div", { className: css.fareGroups }, businessFareGroups.map((group, index) => {
                        var _a, _b;
                        return (React.createElement(FareGroup, { showUnavailableOptions: true, key: index, isDisabled: !group.prices ||
                                (lastSelectedFare && lastSelectedFare !== group.fareFamily.id), fare: group.fareFamily, onSelect: getOnFareSelect(group), price: group.prices && group.prices[0] ? group.prices[0].price : null, flightId: (_b = (_a = group.prices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.flight.id }));
                    }))))))));
};
export default DesktopFlight;
