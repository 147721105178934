import * as React from 'react';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../theme';
import Segment from '../../../DesktopFlightRoute/components/Segment/Segment';
const SegmentInfo = props => {
    const { SegmentInfo: css } = useTheme('FlightSelect');
    const { segment, isSingleSegment, renderFlightInfo: flightInfoRenderer } = props;
    const routeInfoStyles = useMemo(() => ({
        DesktopFlightRoute: {
            SegmentStyles: {
                segment: cn(css.segment, { [css.multiple]: !isSingleSegment }),
                plane: css.plane,
                planeInfo: css.amenities,
                totalDuration: css.total_duration
            },
            StopsInfoStyles: {
                stop: css.stop
            }
        },
        MobileSegmentInfo: {
            MobileSegmentFlat: {
                city: css.city,
                flightNumber__route: css.route_flight,
                duration__text: css.duration,
                row_last: css.row_last,
                row_first: css.row_first,
                time_arr: css.time,
                time_dep: css.time
            }
        }
    }), []);
    const renderFlightInfo = useCallback(() => {
        var _a;
        return (React.createElement("div", { className: css.flight_number },
            React.createElement("div", { className: css.flight_number__inner }, (_a = segment.segment.marketingAirline) === null || _a === void 0 ? void 0 :
                _a.iata,
                "-",
                segment.segment.flightNumber)));
    }, [segment]);
    return (React.createElement(ThemeProvider, { value: routeInfoStyles },
        React.createElement(Segment, Object.assign({ renderFlightInfo: flightInfoRenderer !== null && flightInfoRenderer !== void 0 ? flightInfoRenderer : renderFlightInfo }, props))));
};
export default SegmentInfo;
