import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowBack } from '../../../../../../Icons';
import { useTheme } from '../../../../../../theme';
const Header = props => {
    const { Datepicker: theme } = useTheme('Exchange');
    const { t } = useTranslation('Exchange');
    return (React.createElement("div", { className: theme.header },
        React.createElement("div", { className: theme.header__backIcon, onClick: props.onBack }, ArrowBack),
        React.createElement("div", { className: theme.header__title }, t('Select new date'))));
};
export default Header;
