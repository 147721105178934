import * as React from 'react';
import { useMemo } from 'react';
import { LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useLocationsQuery } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { sortLocationsByName } from '../../utils';
import Location from '../Location/Location';
const Locations = ({ className, additionalGroups = [], departure, locationsSorting = sortLocationsByName, onSelect }) => {
    const { Locations: theme } = useTheme('CoreSchedule');
    const { t } = useTranslation('Schedule');
    const { data, loading } = useLocationsQuery({
        variables: {
            parameters: {
                additionalCities: [],
                additionalGroups,
                departureFrom: (departure === null || departure === void 0 ? void 0 : departure.iata) || null,
                query: '',
                userPosition: null,
                directOnly: true
            }
        }
    });
    const locations = useMemo(() => {
        let cities = [];
        if (data === null || data === void 0 ? void 0 : data.LocationSuggestions) {
            data.LocationSuggestions.forEach(location => {
                var _a;
                if ((_a = location.country) === null || _a === void 0 ? void 0 : _a.name) {
                    cities = [
                        ...cities,
                        ...location.cities.map(city => (Object.assign(Object.assign({}, city), { country: location.country.name })))
                    ];
                }
            });
        }
        return cities.sort(locationsSorting);
    }, [data === null || data === void 0 ? void 0 : data.LocationSuggestions, loading, locationsSorting]);
    const onClickHandler = (iata) => () => onSelect(iata);
    return (React.createElement(React.Fragment, null,
        loading && (React.createElement(LinearProgress, { classes: {
                root: theme.progress,
                barColorPrimary: theme.barColorPrimary,
                colorPrimary: theme.colorPrimary
            } })),
        locations.length > 0 && (React.createElement("div", { className: cn(theme.container, className) },
            React.createElement("div", { className: theme.title }, t(`Where are you flying ${departure ? 'to' : 'from'}`, { city: departure === null || departure === void 0 ? void 0 : departure.name })),
            React.createElement("div", { className: theme.locations }, locations.map(location => (React.createElement(Location, { key: `${location.country}-${location.name}`, className: theme.location, country: location.country, city: location.name, onClick: onClickHandler(location), disabled: loading }))))))));
};
export default Locations;
