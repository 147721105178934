import { __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../Modal';
import { useTheme } from '../../../../../theme';
import { useIsMobile } from '../../../../../utils';
import { BaseButton } from '../../../../../index';
const SureModal = (_a) => {
    var _b, _c;
    var { title, description, cancelText, confirmText, onConfirm, hideCancel } = _a, props = __rest(_a, ["title", "description", "cancelText", "confirmText", "onConfirm", "hideCancel"]);
    const { SureModal: css } = useTheme('SeatMap');
    const { t } = useTranslation('SeatMap');
    const isMobile = useIsMobile();
    return (React.createElement(Modal, Object.assign({ maxWidth: "xs", classes: Object.assign(Object.assign({}, props.classes), { scrollBody: cn(css.scrollBody, (_b = props.classes) === null || _b === void 0 ? void 0 : _b.scrollBody), paper: cn(css.paper, (_c = props.classes) === null || _c === void 0 ? void 0 : _c.paper) }), slideUp: isMobile }, props),
        title && React.createElement("div", { className: css.title }, title),
        React.createElement("div", { className: css.description }, description),
        React.createElement("div", { className: css.footer },
            !hideCancel && (React.createElement(BaseButton, { className: css.button, onClick: props.onClose }, cancelText || t('Cancel'))),
            React.createElement(BaseButton, { className: css.button, onClick: onConfirm }, confirmText || t('Confirm')))));
};
export default SureModal;
