import CheckoutDataLayer from './OrderDataLayer/CheckoutOrderDataLayer';
import dataLayerInstance from './DataLayerAnalytics';
import CheckinOrderDataLayer from './OrderDataLayer/CheckinOrderDataLayer';
import StubOrderDataLayer from './OrderDataLayer/StubOrderDataLayer';
import { OrderType } from './OrderDataLayer/types';
import SearchResultsDataLayer from './OrderDataLayer/SearchResultsDataLayer';
let orderDataLayer = new StubOrderDataLayer();
export const createDataLayer = (type) => {
    if ((orderDataLayer === null || orderDataLayer === void 0 ? void 0 : orderDataLayer.getOrderType()) !== type) {
        if (type === OrderType.Checkout) {
            orderDataLayer = new CheckoutDataLayer(dataLayerInstance);
        }
        else if (type === OrderType.Checkin) {
            orderDataLayer = new CheckinOrderDataLayer(dataLayerInstance);
        }
        else if (type === OrderType.SearchResults) {
            orderDataLayer = new SearchResultsDataLayer(dataLayerInstance);
        }
    }
};
export const getOrderDataLayer = (requiredType) => {
    if (!!requiredType && (orderDataLayer === null || orderDataLayer === void 0 ? void 0 : orderDataLayer.getOrderType()) !== requiredType) {
        createDataLayer(requiredType);
    }
    return orderDataLayer;
};
export default getOrderDataLayer;
