import { getBaggageDatalayerName, getServiceCategory } from '../utils';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import WebskyServicesAnalytics from '../WebskyServicesAnalytics';
import { isFareLockService } from '../../FareLock/utils';
import Base from './Base';
import { getHoldDatalayerItem } from './utils';
class Gds extends Base {
    constructor(orderBeforeChange, orderAfterChange) {
        super(orderBeforeChange, orderAfterChange);
        this.convertGdsServicesToDataLayerServices = (services, additionalServices, traveller, travellerIndex) => {
            const converted = [];
            services.forEach(service => {
                var _a, _b, _c, _d, _e;
                const id = service.serviceId;
                const additionalService = (_b = (_a = additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(s => s.id === id);
                switch (additionalService.type) {
                    case OrderAdditionalServiceGdsServiceServiceType.Baggage:
                        const baggageAdditionalService = additionalService;
                        const baggageService = service;
                        converted.push(...(_c = baggageService.segmentIds) === null || _c === void 0 ? void 0 : _c.map(segmentId => {
                            var _a, _b, _c;
                            return ({
                                name: getBaggageDatalayerName(baggageAdditionalService),
                                price: baggageAdditionalService.price.amount,
                                category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Baggage, baggageAdditionalService.baggageType),
                                segmentId,
                                count: baggageService.count,
                                isRecommend: (_c = (_b = (_a = traveller.preselectedServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => {
                                    return service.serviceId === baggageService.serviceId;
                                }),
                                travellersTypes: [traveller.type]
                            });
                        }));
                        break;
                    case OrderAdditionalServiceGdsServiceServiceType.Meal:
                        const mealAdditionalService = additionalService;
                        const mealService = service;
                        converted.push(...(_d = mealService.segmentIds) === null || _d === void 0 ? void 0 : _d.map(segmentId => {
                            var _a, _b, _c;
                            return ({
                                category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Meal),
                                name: mealAdditionalService.name,
                                price: mealAdditionalService.price.amount,
                                segmentId,
                                isRecommend: (_c = (_b = (_a = traveller.preselectedServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => {
                                    return service.serviceId === mealAdditionalService.id;
                                }),
                                count: 1,
                                travellersTypes: [traveller.type]
                            });
                        }));
                        break;
                    case OrderAdditionalServiceGdsServiceServiceType.Vip:
                    case OrderAdditionalServiceGdsServiceServiceType.BusinessLounge:
                        const vipAdditionalService = additionalService;
                        if (isFareLockService(vipAdditionalService)) {
                            if (travellerIndex === 0) {
                                converted.push(Object.assign({}, getHoldDatalayerItem(vipAdditionalService, [traveller])));
                            }
                        }
                        else {
                            (_e = service.segmentIds) === null || _e === void 0 ? void 0 : _e.forEach(segmentId => {
                                converted.push(Object.assign(Object.assign({}, WebskyServicesAnalytics.selectVip(vipAdditionalService, traveller.id, segmentId)), { travellersTypes: [traveller.type] }));
                            });
                        }
                        break;
                }
            });
            return converted;
        };
    }
    diff() {
        const added = [];
        const removed = [];
        this.orderAfterChange.travellers.forEach((travellerAfterChange, index) => {
            var _a, _b;
            const travellerBeforeChange = this.orderBeforeChange.travellers[index];
            const { added: gdsAdded, removed: gdsRemoved } = this.getTravellersServicesDiff((_a = travellerBeforeChange.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services, (_b = travellerAfterChange.services.gdsServices) === null || _b === void 0 ? void 0 : _b.services, service => service.serviceId);
            added.push(...this.convertGdsServicesToDataLayerServices(gdsAdded, this.orderAfterChange.additionalServices, travellerAfterChange, index));
            removed.push(...this.convertGdsServicesToDataLayerServices(gdsRemoved, this.orderBeforeChange.additionalServices, travellerBeforeChange, index));
        });
        return { added, removed };
    }
}
export default Gds;
