import { put, select, takeEvery } from 'redux-saga/effects';
import { nextStep, prevStep, setStep } from './actions';
import { getActiveStep } from './selectors';
import { getIsRefundVoluntary } from '../selectors';
import { StepType } from '../../types';
import { getIsDocUploadRequired } from '../refundInfo/selectors';
function* goToAttachFileOrCreateTicket(isVoluntary, isDocumentsRequired) {
    if (isVoluntary && isDocumentsRequired) {
        yield put(setStep({ isActive: true, type: StepType.AttachFiles, additionalText: '' }));
    }
    else {
        yield put(setStep({ isActive: true, type: StepType.CreateTicket, additionalText: '' }));
    }
}
function* goToAttachFilesOrReasons(isVoluntary, isDocumentsRequired) {
    if (isVoluntary && isDocumentsRequired) {
        yield put(setStep({ isActive: true, type: StepType.AttachFiles, additionalText: '' }));
    }
    else {
        yield put(setStep({ isActive: true, type: StepType.Reason, additionalText: '' }));
    }
}
function* onNextStep() {
    const currentStep = yield select(getActiveStep);
    const isVoluntary = yield select(getIsRefundVoluntary);
    const isDocUploadRequired = yield select(getIsDocUploadRequired);
    if (currentStep.type === StepType.AttachFiles) {
        yield goToAttachFileOrCreateTicket(isVoluntary, isDocUploadRequired);
    }
}
function* onPrevStep() {
    const currentStep = yield select(getActiveStep);
    const isVoluntary = yield select(getIsRefundVoluntary);
    const isDocUploadRequired = yield select(getIsDocUploadRequired);
    if (currentStep.type === StepType.AttachFiles) {
        yield goToAttachFilesOrReasons(isVoluntary, isDocUploadRequired);
    }
}
export function* manageSteps() {
    yield takeEvery(nextStep.toString(), onNextStep);
    yield takeEvery(prevStep.toString(), onPrevStep);
}
