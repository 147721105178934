import { createSelector } from 'reselect';
import { getCustomerData, getPassengers } from '../order/selectors';
import { TravellerFieldEnum } from '@websky/graphql';
import { PassengerType } from '../../../../enums';
import { getUserValue } from '../../../../utils';
export const getSelectedPassengerIds = (state) => state.passengers;
export const getFirstSelectedPassengerRequestFormInitialData = createSelector(getSelectedPassengerIds, getPassengers, getCustomerData, (selectedPassengerIds, passengers, customer) => {
    const firstSelectedPassengerValues = {
        name: '',
        email: '',
        phone: ''
    };
    for (const passenger of passengers) {
        if (selectedPassengerIds.includes(passenger.id) && passenger.type === PassengerType.Adult) {
            firstSelectedPassengerValues.name =
                getUserValue(passenger, TravellerFieldEnum.FirstName) || customer.name;
            firstSelectedPassengerValues.email =
                getUserValue(passenger, TravellerFieldEnum.Email) || customer.email;
            firstSelectedPassengerValues.phone =
                getUserValue(passenger, TravellerFieldEnum.Phone) || customer.phone;
            break;
        }
    }
    return firstSelectedPassengerValues;
});
