import { Locale } from './types';
import { Currency } from './enums';
import { LOCALES } from './utils';
export const RECENT_SEARCHES_KEY = 'RECENT_SEARCHES_KEY';
export const IS_CHECKIN_TRAVELLERS_DATA_FILLED = ' IS_CHECKIN_TRAVELLERS_DATA_FILLED';
export const SEARCH_FORM_STATE_KEY = 'SEARCH_FORM_STATE_KEY';
export const LOCALE_KEY = 'LOCALE_KEY';
export const CURRENCY_KEY = 'CURRENCY_KEY';
export const SEATS_RECOMMENDATION_SHOWN = 'SEATS_RECOMMENDATION_SHOWN';
export const BAGGAGE_RECOMMENDATION_SHOWN = 'BAGGAGE_RECOMMENDATION_SHOWN ';
export const MEAL_RECOMMENDATION_SHOWN = 'MEAL_RECOMMENDATION_SHOWN';
export const TEST_AUTHORIZATION_REFUSED = 'TEST_AUTHORIZATION_REFUSED';
export const GRAPHQL_KEY = 'hashed_value';
export const SHOW_RENDERERS_IN_CONSOLE = 'SHOW_RENDERERS_IN_CONSOLE';
export const FOREIGN_PAYMENT_KEY = 'FOREIGN_PAYMENT';
export const SHOW_TRANSLATION_KEYS = 'SHOW_TRANSLATION_KEYS';
export const UTM_MARKS_KEY = 'UTM_MARKS';
export const CUSTOM_SCHEMAS_URL = 'CUSTOM_SCHEMAS_URL';
export const SOCIAL_AUTH_REDIRECT_URL = 'SOCIAL_AUTH_REDIRECT_URL';
export const AUTH_DUPLICATES = 'AUTH_DUPLICATES';
export const IMPORTANT_INSURANCE_TOUCHED = 'IMPORTANT_INSURANCE_TOUCHED';
export const set = (key, value, storage = localStorage) => {
    if (typeof storage !== 'undefined') {
        if (value) {
            try {
                storage.setItem(key, JSON.stringify(value));
            }
            catch (e) {
                console.warn(e);
            }
        }
    }
};
export const get = (key, defaultValue, storage = localStorage) => {
    let result = defaultValue;
    if (typeof storage !== 'undefined') {
        const string = storage.getItem(key);
        if (string) {
            try {
                result = JSON.parse(string);
            }
            catch (e) {
                console.warn(e);
            }
        }
    }
    return result;
};
export const sessionStorageGet = (key, defaultValue) => {
    return get(key, defaultValue, sessionStorage);
};
export const sessionStorageSet = (key, value) => {
    return set(key, value, sessionStorage);
};
export const getShowRenderersInConsole = () => {
    return !!get(SHOW_RENDERERS_IN_CONSOLE);
};
export const getLocaleFromLocalStorage = () => {
    const locale = get(LOCALE_KEY);
    return LOCALES.has(locale) ? locale : Locale.Russian;
};
export const getShowTranslationKeysFromLocalStorage = () => get(SHOW_TRANSLATION_KEYS, false) === true;
export const getCurrency = () => {
    var _a, _b, _c, _d;
    return get(CURRENCY_KEY, ((_d = (_c = (_b = (_a = window.__webskyEngineConfig) === null || _a === void 0 ? void 0 : _a.SearchForm) === null || _b === void 0 ? void 0 : _b.selectableCurrencies) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.code) || Currency.RUB);
};
export const setLocaleToLocalStorage = (locale) => {
    set(LOCALE_KEY, locale);
};
export const setCurrency = (currency) => {
    const oldCurrency = getCurrency();
    set(CURRENCY_KEY, currency);
    dispatchEvent(new StorageEvent('storage', { key: CURRENCY_KEY, oldValue: oldCurrency, newValue: currency }));
};
export const getIsCheckinTravellersDataFilledFromLocalStorage = (orderId) => {
    return get(`${IS_CHECKIN_TRAVELLERS_DATA_FILLED}_${orderId}`);
};
export const setIsCheckinTravellersDataFilledToLocalStorage = (orderId, value) => {
    set(`${IS_CHECKIN_TRAVELLERS_DATA_FILLED}_${orderId}`, value);
};
export const removeFromSessionStorage = (key) => {
    sessionStorage.removeItem(key);
};
