import { CUSTOM_CONTENT, CUSTOM_CONTENT_DATA_READY } from './constants';
class CustomContent {
    constructor() {
        if (process.env.NODE_ENV === 'development') {
            this.debugMode = true;
        }
    }
    static debugEvent(data) {
        console.log(`${CUSTOM_CONTENT}: ${data.page}`, data);
    }
    sendCustomContentEvent(data) {
        const event = new CustomEvent(CUSTOM_CONTENT_DATA_READY, { detail: data });
        document.dispatchEvent(event);
        if (this.debugMode) {
            CustomContent.debugEvent(data);
        }
    }
}
const customContent = new CustomContent();
export default customContent;
