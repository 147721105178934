// @ts-ignore
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn';
const eraValues = {
    narrow: ['т.м.', 'м.'],
    abbreviated: ['то м.', 'м.'],
    wide: ['то милод', 'милодӣ']
};
const quarterValues = {
    narrow: ['1', '2', '3', '4'],
    abbreviated: ['Q1', 'Q2', 'Q3', 'Q4'],
    wide: ['1-ум семоҳа', '2-юм семоҳа', '3-юм семоҳа', '4-ум семоҳа']
};
// Note: in English, the names of days of the week and months are capitalized.
// If you are making a new locale based on this one, check if the same is true for the language you're working on.
// Generally, formatted dates should look like they are in the middle of a sentence,
// e.g. in Spanish language the weekdays and months should be in the lowercase.
const monthValues = {
    narrow: ['Я', 'Ф', 'М', 'А', 'М', 'И', 'И', 'А', 'С', 'О', 'Н', 'Д'],
    abbreviated: ['янв', 'фев', 'март', 'апр', 'май', 'июн', 'июл', 'авг', 'сент', 'окт', 'нояб', 'дек'],
    wide: [
        'январ',
        'феврал',
        'март',
        'апрел',
        'май',
        'июн',
        'июл',
        'август',
        'сентябр',
        'октябр',
        'ноябр',
        'декабр'
    ]
};
const formattingMonthValues = {
    narrow: ['Я', 'Ф', 'М', 'А', 'М', 'И', 'И', 'А', 'С', 'О', 'Н', 'Д'],
    abbreviated: [
        'янв.',
        'фев.',
        'мар.',
        'апр.',
        'майи',
        'июн.',
        'июл.',
        'авг.',
        'сент.',
        'окт.',
        'нояб.',
        'дек.'
    ],
    wide: [
        'январи',
        'феврали',
        'марти',
        'апрели',
        'майи',
        'июни',
        'июли',
        'августи',
        'сентябри',
        'октябри',
        'ноябри',
        'декабри'
    ]
};
const dayValues = {
    narrow: ['я', 'д', 'с', 'ч', 'п', 'ҷ', 'ш'],
    short: ['яш', 'дш', 'сш', 'чш', 'пш', 'ҷм', 'шб'],
    abbreviated: ['яшб', 'дшб', 'сшб', 'чшб', 'пшб', 'ҷум', 'шнб'],
    wide: ['якшанбе', 'душанбе', 'сешанбе', 'чоршанбе', 'панҷшанбе', 'ҷумъа', 'шанбе']
};
const dayPeriodValues = {
    narrow: {
        am: 'a',
        pm: 'p',
        midnight: 'нисфи шаб',
        noon: 'нисфи рӯз',
        morning: 'субҳ',
        afternoon: 'рӯз',
        evening: 'бегоҳ',
        night: 'шаб'
    },
    abbreviated: {
        am: 'AM',
        pm: 'PM',
        midnight: 'нисфи шаб',
        noon: 'нисфи рӯз',
        morning: 'субҳ',
        afternoon: 'рӯз',
        evening: 'бегоҳ',
        night: 'шаб'
    },
    wide: {
        am: 'a.m.',
        pm: 'p.m.',
        midnight: 'нисфи шаб',
        noon: 'нисфи рӯз',
        morning: 'субҳ',
        afternoon: 'рӯз',
        evening: 'бегоҳ',
        night: 'шаб'
    }
};
const formattingDayPeriodValues = {
    narrow: {
        am: 'a',
        pm: 'p',
        midnight: 'нисфишаб.',
        noon: 'нисфирӯз.',
        morning: 'саҳар.',
        afternoon: 'рӯз.',
        evening: 'бегоҳ.',
        night: 'шаб.'
    },
    abbreviated: {
        am: 'AM',
        pm: 'PM',
        midnight: 'нисфишаб.',
        noon: 'нисфирӯз.',
        morning: 'саҳар.',
        afternoon: 'рӯз.',
        evening: 'бегоҳ.',
        night: 'шаб.'
    },
    wide: {
        am: 'a.m.',
        pm: 'p.m.',
        midnight: 'нисфишабӣ',
        noon: 'нисфирӯзӣ',
        morning: 'саҳарӣ',
        afternoon: 'рӯзона',
        evening: 'бегоҳӣ',
        night: 'шабона'
    }
};
const ordinalNumber = (dirtyNumber, _dirtyOptions) => {
    const number = Number(dirtyNumber);
    // If ordinal numbers depend on context, for example,
    // if they are different for different grammatical genders,
    // use `options.unit`:
    //
    //   var options = dirtyOptions || {}
    //   var unit = String(options.unit)
    //
    // where `unit` can be 'year', 'quarter', 'month', 'week', 'date', 'dayOfYear',
    // 'day', 'hour', 'minute', 'second'
    const rem100 = number % 100;
    if (rem100 > 20 || rem100 < 10) {
        switch (rem100 % 10) {
            case 1:
                return number.toString() + '-ум'; // @ts-ignore
            case 2:
                return number.toString() + '-юм'; // @ts-ignore
            case 3:
                return number.toString() + '-юм'; // @ts-ignore
        }
    }
    return number.toString() + '-ум';
};
const localize = {
    ordinalNumber: ordinalNumber,
    era: buildLocalizeFn({
        values: eraValues,
        defaultWidth: 'wide'
    }),
    quarter: buildLocalizeFn({
        values: quarterValues,
        defaultWidth: 'wide',
        argumentCallback: (quarter) => Number(quarter) - 1
    }),
    month: buildLocalizeFn({
        values: monthValues,
        defaultWidth: 'wide',
        formattingValues: formattingMonthValues,
        defaultFormattingWidth: 'wide'
    }),
    day: buildLocalizeFn({
        values: dayValues,
        defaultWidth: 'wide'
    }),
    dayPeriod: buildLocalizeFn({
        values: dayPeriodValues,
        defaultWidth: 'wide',
        formattingValues: formattingDayPeriodValues,
        defaultFormattingWidth: 'wide'
    })
};
export default localize;
