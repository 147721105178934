import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { cityIn } from 'lvovich';
import { getDurationString } from '../../Results/utils';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import { ArrowForward } from '../../Icons';
initI18n('TransferInfo');
const TransferInfo = ({ className, segment, nextSegment, transferDuration, showStops }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { TransferInfo: css } = useTheme('TransferInfo');
    return (React.createElement("div", { className: cn(css.transfer, className) },
        transferDuration && (React.createElement("span", { className: css.transfer__layover },
            t('TransferInfo:layover'),
            " ",
            cityIn(segment.arrival.airport.city.name),
            ":\u00A0",
            getDurationString(transferDuration))),
        showStops && !!((_a = segment.stops) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement("span", { className: css.warning },
            t('DesktopFlightInfo:TechnicalLandingIn', {
                count: (_b = segment.stops) === null || _b === void 0 ? void 0 : _b.length
            }),
            ' ', (_c = segment.stops) === null || _c === void 0 ? void 0 :
            _c.map(stop => { var _a, _b; return cityIn((_b = (_a = stop === null || stop === void 0 ? void 0 : stop.airport) === null || _a === void 0 ? void 0 : _a.city) === null || _b === void 0 ? void 0 : _b.name); }).join(', '))),
        nextSegment && segment.arrival.terminal !== nextSegment.departure.terminal && (React.createElement("span", { className: css.warning },
            t('TransferInfo:Change'),
            " ",
            t('TransferInfo:Terminal'),
            " ",
            segment.arrival.terminal,
            React.createElement("span", { className: css.warning__icon }, ArrowForward),
            t('TransferInfo:Terminal'),
            " ",
            nextSegment.departure.terminal))));
};
export default TransferInfo;
