import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDifferentForReturnFlight } from '../../store/selector';
import BySegment from './BySegment/BySegment';
import AllFlight from './AllFlight/AllFlight';
import { getBaggageTab } from '../../store/selector';
const BaggageBody = props => {
    const isDifferentForReturnFlight = useSelector(getDifferentForReturnFlight);
    const baggageTab = useSelector(getBaggageTab);
    const filteredBaggage = useMemo(() => {
        if (baggageTab) {
            return props.upgradeBaggages.filter(baggage => baggage[0].rfics === baggageTab);
        }
        return props.upgradeBaggages;
    }, [props.upgradeBaggages, baggageTab]);
    const baggageProps = Object.assign(Object.assign({}, props), { upgradeBaggages: filteredBaggage, baggageTab });
    if (isDifferentForReturnFlight) {
        return React.createElement(BySegment, Object.assign({}, baggageProps));
    }
    else {
        return React.createElement(AllFlight, Object.assign({}, baggageProps));
    }
};
export default BaggageBody;
