import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { format } from '../../utils';
import { getAbsoluteDate } from '../../Engine/components/FastSearch/utils';
import { planeIcon } from '../Icons';
const BoardingPassDesk = ({ className, segment }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation('Segment');
    const { BoardingPassDesk: css } = useTheme('Segment');
    const currentDate = new Date();
    const fareFamily = (_a = segment.fareFamilies[0]) === null || _a === void 0 ? void 0 : _a.fareFamily;
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        React.createElement("div", { className: cn(css.row, css.header) },
            React.createElement("div", { className: css.col },
                React.createElement("div", { className: css.flightInfo },
                    React.createElement("span", { className: css.flightInfo__title }, t('Flight')),
                    React.createElement("span", { className: css.flightInfo__value },
                        segment.operatingAirline.iata,
                        "-",
                        segment.flightNumber)),
                React.createElement("div", { className: cn(css.flightInfo, css.flightInfo__departure) },
                    React.createElement("span", { className: css.flightInfo__title }, t('Departure')),
                    React.createElement("span", { className: css.flightInfo__value }, format(segment.departure.date, `${new Date(segment.departure.date).getFullYear() - currentDate.getFullYear() > 0
                        ? 'dd MMM yyyy'
                        : 'dd MMMM'}`)))),
            React.createElement("div", { className: css.col },
                React.createElement("div", { className: css.city },
                    React.createElement("span", { className: css.city__airport }, segment.departure.airport.name),
                    React.createElement("span", { className: css.city__info },
                        React.createElement("span", { className: css.city__iata }, segment.departure.airport.iata),
                        React.createElement("span", { className: css.city__time }, segment.departure.time.substr(0, 5)))),
                React.createElement("span", { className: css.plane__icon }, planeIcon),
                React.createElement("div", { className: css.city },
                    React.createElement("span", { className: css.city__airport }, segment.arrival.airport.name),
                    React.createElement("span", { className: css.city__info },
                        React.createElement("span", { className: css.city__iata }, segment.arrival.airport.iata),
                        React.createElement("span", { className: css.city__time }, segment.arrival.time.substr(0, 5)))))),
        React.createElement("div", { className: cn(css.row, css.footer) },
            React.createElement("div", { className: css.col },
                React.createElement("span", { className: css.airline__logo, style: {
                        backgroundImage: `url(${(_b = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _b === void 0 ? void 0 : _b.icon})`
                    } }),
                React.createElement("span", { className: css.airline__name },
                    ((_c = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _c === void 0 ? void 0 : _c.name) && fareFamily.airline.name + ', ', (_d = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.title) === null || _d === void 0 ? void 0 :
                    _d.toLowerCase())),
            React.createElement("div", { className: css.col },
                segment.departure.terminal && (React.createElement("span", { className: css.footer__item },
                    React.createElement("span", { className: css.footer__itemLabel }, t('Terminal')),
                    React.createElement("span", { className: css.footer__itemValue }, segment.departure.terminal))),
                React.createElement("span", { className: css.footer__item },
                    React.createElement("span", { className: css.footer__itemLabel }, t('Boarding')),
                    React.createElement("span", { className: css.footer__itemValue },
                        format(getAbsoluteDate(segment.checkinInfo.boardingOpenTime), 'HH:mm'),
                        " \u2014",
                        ' ',
                        format(getAbsoluteDate(segment.checkinInfo.boardingCloseTime), 'HH:mm')))))));
};
export default BoardingPassDesk;
