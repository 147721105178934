import { __awaiter } from "tslib";
import { useCallback, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAeroexpress, getServicesFallbackURL, shouldRedirectToFallback } from '../../Checkout/store/order/selectors';
import { useUpsaleWarning } from '../../Checkout/components/Checkout/Upsale/hooks';
import { OrderDocumentType, useOrderDocumentQuery } from '@websky/graphql';
import { downloadPDF } from '../../utils';
import { getAeroexpressPriceToPay } from '../../Checkout/store/orderPrice/selectors';
import { ModeContext } from '../../context';
import { Mode } from '../../types';
import { getHasSelectedAeroexpress } from '../../Modules/Checkin/store/order/selectors';
import { WxEvents } from '../../DataLayerAnalytics/types';
import { useToggleable } from '../../hooks';
import WebskyAnalyticsApollo from '../../DataLayerAnalytics/WebskyAnalyticsApollo';
export const useAeroexpressTicketsDownloader = (orderId) => {
    const { refetch: loadDocument } = useOrderDocumentQuery({ skip: true });
    return () => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield loadDocument({
            params: {
                orderId,
                documentType: OrderDocumentType.AeroexpressTicket
            }
        });
        if (data === null || data === void 0 ? void 0 : data.OrderDocument) {
            yield downloadPDF(data.OrderDocument, 'aeroexpress-tickets.pdf');
        }
    });
};
export const useAeroexpress = (updateServices, orderId) => {
    var _a, _b;
    const { t } = useTranslation('Aeroexpress');
    const { isOpen, open, close } = useToggleable(false);
    const downloadAeroexpress = useAeroexpressTicketsDownloader(orderId);
    const aeroexpress = useSelector(getAeroexpress);
    let hasSelectedServices;
    const mode = useContext(ModeContext);
    if (mode === Mode.Checkin) {
        hasSelectedServices = useSelector(getHasSelectedAeroexpress);
    }
    else {
        hasSelectedServices = ((_a = useSelector(getAeroexpressPriceToPay)) === null || _a === void 0 ? void 0 : _a.amount) > 0;
    }
    const availableServices = aeroexpress.trips;
    const selectedServices = (_b = aeroexpress.selectedTrips) !== null && _b !== void 0 ? _b : [];
    const hasConfirmedServices = selectedServices.some(selectedService => { var _a; return (_a = selectedService === null || selectedService === void 0 ? void 0 : selectedService.tickets) === null || _a === void 0 ? void 0 : _a.some(ticket => !!(ticket === null || ticket === void 0 ? void 0 : ticket.imageUrl)); });
    const [loadingState, setLoadingState] = useState(availableServices.reduce((loadingState, service) => {
        loadingState[service.id] = false;
        return loadingState;
    }, {}));
    const { showWarning, closeWarning, toggleWarningIfOrderConfirmed } = useUpsaleWarning();
    const shouldRedirect = useSelector(shouldRedirectToFallback);
    const servicesRedirectURL = useSelector(getServicesFallbackURL);
    const onSelect = useCallback((id) => __awaiter(void 0, void 0, void 0, function* () {
        const selectedServicesIds = selectedServices.map(service => service.id);
        setLoadingState(prev => (Object.assign(Object.assign({}, prev), { [id]: true })));
        if (selectedServicesIds.includes(id)) {
            selectedServicesIds.splice(selectedServicesIds.findIndex(serviceId => serviceId === id), 1);
            yield updateServices(selectedServicesIds);
        }
        else {
            selectedServicesIds.push(id);
            yield updateServices(selectedServicesIds);
        }
        setLoadingState(prev => (Object.assign(Object.assign({}, prev), { [id]: false })));
    }), [selectedServices]);
    const availableServicesCount = availableServices.length;
    let tickets = [];
    selectedServices.forEach(service => {
        var _a;
        if (service.tickets && ((_a = service.tickets) === null || _a === void 0 ? void 0 : _a.length)) {
            tickets = tickets.concat(service === null || service === void 0 ? void 0 : service.tickets.map(ticket => ticket.imageUrl));
        }
    });
    useEffect(() => {
        if (isOpen) {
            WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.AeroexpressList);
        }
    }, [isOpen]);
    const handleOpenModalClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (shouldRedirect) {
            toggleWarningIfOrderConfirmed();
            return;
        }
        if (hasConfirmedServices && availableServicesCount > 0) {
            yield downloadAeroexpress();
            return;
        }
        open();
    });
    const price = {
        amount: hasSelectedServices
            ? selectedServices.reduce((acc, curr) => +acc + +curr.price.amount, 0)
            : Math.min(...availableServices.map(service => service.price.amount)),
        currency: availableServices[0].price.currency
    };
    const setLoadingStateForAllTo = (value) => {
        setLoadingState(availableServices.reduce((loadingState, service) => {
            loadingState[service.id] = value;
            return loadingState;
        }, {}));
    };
    const onClear = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingStateForAllTo(true);
        yield updateServices([]);
        close();
        setLoadingStateForAllTo(false);
    });
    let buttonText = t('Add');
    if (hasConfirmedServices) {
        buttonText = t('Download tickets');
    }
    if (hasSelectedServices && !hasConfirmedServices) {
        buttonText = t(availableServicesCount === 1 ? 'Cancel' : 'Change');
    }
    const isLoading = Object.keys(loadingState).reduce((isLoading, key) => {
        return isLoading ? isLoading : loadingState[key];
    }, false);
    const onOpenHandler = () => {
        if (hasConfirmedServices) {
            downloadAeroexpress();
        }
        else {
            open();
        }
    };
    return {
        buttonText,
        isLoading,
        price,
        onClear,
        handleOpenModalClick,
        hasSelectedServices,
        showWarning,
        servicesRedirectURL,
        closeWarning,
        tickets,
        isOpen,
        open: onOpenHandler,
        close,
        loadingState,
        onSelect,
        handleDownloadTickets: downloadAeroexpress
    };
};
