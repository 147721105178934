import * as React from 'react';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import { useTranslation } from 'react-i18next';
import { Insurance as InsuranceIcon } from '../../../Icons';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import InsuranceSummary from '../../../ServiceSummary/services/InsuranceSummary';
import { CartContext } from '../../Cart';
import { CheckoutSteps } from '../../../MobileStepbar/types';
import { useCallback } from 'react';
const Insurance = ({ flight, travellers, services, separateInsurance, renderService, onServiceDelete }) => {
    var _a, _b;
    const { t } = useTranslation('Cart');
    const cartConfig = React.useContext(CartContext);
    if (!((_b = (_a = services.insurances) === null || _a === void 0 ? void 0 : _a.selectedInsurances) === null || _b === void 0 ? void 0 : _b.length) && !renderService) {
        return null;
    }
    const renderContent = useCallback((items) => {
        var _a;
        const group = {
            header: t('Insurances'),
            items,
            headerIcon: InsuranceIcon
        };
        const content = items.length ? (React.createElement(ServiceSummaryDivider, null,
            React.createElement(ServiceSummaryOption, { groupOption: separateInsurance ? { items } : group }))) : null;
        if (separateInsurance && renderService) {
            return renderService(CheckoutSteps.Insurance, { header: group.header, icon: group.headerIcon, totalPrice: (_a = cartConfig.totalPrices) === null || _a === void 0 ? void 0 : _a.insurance }, content);
        }
        return content;
    }, [separateInsurance, renderService]);
    return (React.createElement(InsuranceSummary, { flight: flight, travellers: travellers, services: services, travellersById: cartConfig.travellersById, onServiceDelete: onServiceDelete }, renderContent));
};
export default Insurance;
