import formatDistance from './formatDistance';
import formatLong from './formatLong';
import formatRelative from './formatRelative';
import localize from './localize';
import match from './match';
// TODO: после мержа https://github.com/date-fns/date-fns/pull/3012/ перейти на импорт из node_modules
const locale = {
    code: 'tg',
    formatDistance: formatDistance,
    formatLong: formatLong,
    formatRelative: formatRelative,
    localize: localize,
    match: match,
    options: {
        weekStartsOn: 1 /* Monday */,
        firstWeekContainsDate: 1
    }
};
export default locale;
