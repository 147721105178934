import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import Money from '../../Money/components/Money';
import { initI18n } from '../../utils';
import { BaseButton } from '../../index';
initI18n('AdditionalServiceWrapper');
const AdditionalServiceWrapper = ({ className, headerClassName, textClassName, contentClassName, wrapperClassName, footerClassName, children, header, subheader, text, totalPrice, isLoading, onConfirm, onCancel, onClear, readOnly }) => {
    const { AdditionalServiceWrapper: css } = useTheme('AdditionalServiceWrapper');
    const { t } = useTranslation('AdditionalServiceWrapper');
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: cn(css.header, headerClassName) },
            header,
            subheader && React.createElement("div", { className: css.subheader }, subheader)),
        React.createElement("div", { className: cn(css.content, contentClassName) },
            text && React.createElement("div", { className: cn(textClassName, css.content_text) }, text),
            React.createElement("div", { className: cn(wrapperClassName, css.content_wrapper) }, children),
            React.createElement("div", { className: cn(footerClassName, css.footer) },
                totalPrice && (React.createElement("div", { className: css.total },
                    React.createElement("span", { className: css.total__price },
                        t('Total'),
                        ":",
                        React.createElement(Money, { money: totalPrice })),
                    React.createElement(BaseButton, { className: css.total__clear, disabled: isLoading, variant: "text", onClick: onClear }, t('Clear')))),
                React.createElement("div", { className: css.controls },
                    !isLoading && onCancel && (React.createElement(BaseButton, { variant: "smallSecondary", className: cn(css.button, css.cancel), onClick: onCancel }, t('Cancel'))),
                    !readOnly && (React.createElement(BaseButton, { variant: "smallThird", className: css.button, classes: {
                            disabled: css.button_disabled
                        }, onClick: onConfirm, isLoading: isLoading, disabled: isLoading }, t('Confirm'))))))));
};
export default AdditionalServiceWrapper;
