import { useTranslation } from 'react-i18next';
import { useToggleable } from '../hooks';
import { emailRegex, initI18n } from '../utils';
import { useSaveClientInfoMutation } from '@websky/graphql';
import { useConfig } from '../context';
import { clearPhoneNumber } from '../PhoneInput/utils';
initI18n('CustomerContactsForm');
export const useCustomerContactsValidation = () => {
    const FFPRegex = useConfig().global.FFPRegex;
    const { t } = useTranslation('CustomerContactsForm');
    const phoneRegExes = [
        {
            pattern: '^\\+?(?!1+$|2+$|3+$|4+$|5+$|6+$|7+$|8+$|9+$|0+$)'
        },
        {
            pattern: '^\\+?[1-9]\\d+$'
        },
        {
            pattern: '^\\+?\\d{10,}$'
        },
        {
            pattern: '^\\+?\\d{10,18}$'
        },
        {
            pattern: '^\\+?(9\\d{10,}|[^9]\\d{9,})$'
        },
        {
            pattern: '^\\+?(7\\d{10}|[^7]\\d{9,})$'
        }
    ];
    const testEmail = (value) => {
        if (!value) {
            return t('Required');
        }
        else if (!emailRegex.test(value)) {
            return t('Please enter a valid email');
        }
        else {
            return undefined;
        }
    };
    const testPhone = (value) => {
        const cleanValue = clearPhoneNumber(value);
        const validated = phoneRegExes.filter(rule => !new RegExp(rule.pattern).test(cleanValue));
        if (!value) {
            return t('Required');
        }
        else if (validated.length) {
            return t('Please enter a valid phone');
        }
        else {
            return undefined;
        }
    };
    const testFFP = (value) => {
        if (!value) {
            return t('Required');
        }
        else if (!FFPRegex.test(value)) {
            return t('Please enter a valid FFP');
        }
        else {
            return undefined;
        }
    };
    return {
        testEmail,
        testPhone,
        testFFP
    };
};
export const useCustomerContactsForm = () => {
    const { isOpen: isContactsModalOpen, open: openContactsModal, close: closeContactsModal } = useToggleable(false);
    const [saveClientInfo, { loading }] = useSaveClientInfoMutation();
    return {
        isContactsModalOpen,
        openContactsModal,
        closeContactsModal,
        saveClientInfo,
        loading
    };
};
