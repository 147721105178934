import OrderDataLayer from './OrderDataLayer';
import { OrderStep } from '../types';
import { OrderType } from './types';
class CheckinOrderDataLayer extends OrderDataLayer {
    constructor(instance) {
        super(instance);
    }
    getOrderType() {
        return OrderType.Checkin;
    }
    getFlightSegments() {
        return this.getOrder().segments;
    }
    getOrderStatus() {
        return OrderStep.Checkin;
    }
}
export default CheckinOrderDataLayer;
