import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { useTheme } from '../../../theme';
import { Trans, useTranslation } from 'react-i18next';
import { getLabel } from '../../../PriceInMiles/utils';
const FfpInfoStatuses = props => {
    var _a;
    const { FfpInfo: css } = useTheme('LoyaltyInfo');
    const { t } = useTranslation('Loyalty');
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.progress_status },
            React.createElement(LinearProgress, { classes: {
                    root: css.progress,
                    colorPrimary: css.progress_bg,
                    barColorPrimary: css.progress_color
                }, variant: 'determinate', value: props.milesPercentage }),
            React.createElement("span", { className: css.progress_text },
                React.createElement(Trans, { i18nKey: 'Left {{miles}} for {{level}} status', t: t, values: {
                        miles: props.ffpInfo.milesForNextLevel.toLocaleString(),
                        milesLabel: getLabel(props.ffpInfo.milesForNextLevel, 'status'),
                        level: t(props.nextLevel)
                    } }))),
        React.createElement("div", { className: css.progress_fares },
            React.createElement(LinearProgress, { classes: {
                    root: css.progress,
                    colorPrimary: css.progress_bg,
                    barColorPrimary: css.progress_color
                }, variant: 'determinate', value: props.faresPercentage }),
            React.createElement("span", null,
                t('or'),
                " ",
                ((_a = props.ffpInfo) === null || _a === void 0 ? void 0 : _a.segmentsForNextLevel) > 1 ? props.ffpInfo.segmentsForNextLevel : 1,
                ' ',
                t('out of'),
                " ",
                props.ffpInfo.currentLevelMaxSegments,
                " ",
                t('flights')))));
};
export default FfpInfoStatuses;
