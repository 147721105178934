import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { Download } from '../../../../Icons';
import { BaseButton } from '../../../../index';
const DownloadInsurances = ({ selectedInsurances }) => {
    const theme = useTheme('AdditionalServices').DownloadInsurances;
    const { t } = useTranslation('Checkout');
    const confirmedInsurances = selectedInsurances.filter(insurance => !!insurance.policyUrl);
    return (React.createElement("div", { className: theme.wrapper }, confirmedInsurances.map((insurance, index) => (React.createElement("div", { key: index, className: theme.item },
        React.createElement("span", { className: theme.name }, insurance.passengerName.toLowerCase()),
        React.createElement("a", { href: insurance.policyUrl, target: "_blank" },
            React.createElement(BaseButton, { className: theme.button, variant: "optionAction" },
                t('Download'),
                Download)))))));
};
export default DownloadInsurances;
