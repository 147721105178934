import * as React from 'react';
import { getAirlinesList, getDurationString, getFlightDuration } from '../Results/utils';
import { getTransferShortLabel } from './utils';
import { format, removeDotsFromDate } from '../utils';
import { FlightSegmentStatus } from '@websky/graphql';
export const useMobileSegmentInfo = (componentProps) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const DEPARTURE_DATE_FORMAT = 'dd MMM, EEEEEE';
    const { segments, showFullInfo } = componentProps;
    const [detailsOpen, toggleDetails] = React.useState(false);
    const firstSegment = segments[0].segment;
    const lastSegment = segments[segments.length - 1].segment;
    const allAirlines = getAirlinesList(segments).map(airline => airline.name);
    const isNextDayArrival = firstSegment.departure.date !== lastSegment.arrival.date;
    const transferString = getTransferShortLabel(segments);
    const departureTime = firstSegment.departure.time.substr(0, 5);
    const departureDate = removeDotsFromDate(format(firstSegment.departure.date, DEPARTURE_DATE_FORMAT));
    const arrivalTime = lastSegment.arrival.time.substr(0, 5);
    const durationTime = getDurationString(getFlightDuration(segments, showFullInfo));
    const arrivalDate = removeDotsFromDate(format(lastSegment.arrival.date, DEPARTURE_DATE_FORMAT));
    const firstSegmentCity = (_g = (_d = (_c = (_b = (_a = firstSegment.departure) === null || _a === void 0 ? void 0 : _a.airport) === null || _b === void 0 ? void 0 : _b.city) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : (_f = (_e = firstSegment === null || firstSegment === void 0 ? void 0 : firstSegment.departure) === null || _e === void 0 ? void 0 : _e.airport) === null || _f === void 0 ? void 0 : _f.name) !== null && _g !== void 0 ? _g : firstSegment.departure.airport.iata;
    const lastSegmentCity = (_p = (_l = (_k = (_j = (_h = lastSegment.arrival) === null || _h === void 0 ? void 0 : _h.airport) === null || _j === void 0 ? void 0 : _j.city) === null || _k === void 0 ? void 0 : _k.name) !== null && _l !== void 0 ? _l : (_o = (_m = lastSegment === null || lastSegment === void 0 ? void 0 : lastSegment.arrival) === null || _m === void 0 ? void 0 : _m.airport) === null || _o === void 0 ? void 0 : _o.name) !== null && _p !== void 0 ? _p : lastSegment.arrival.airport.iata;
    const canceled = firstSegment.status === FlightSegmentStatus.Canceled;
    return {
        detailsOpen,
        toggleDetails,
        allAirlines,
        isNextDayArrival,
        transferString,
        firstSegment,
        lastSegment,
        departureTime,
        arrivalTime,
        durationTime,
        departureDate,
        firstSegmentCity,
        lastSegmentCity,
        arrivalDate,
        canceled
    };
};
