export const groupServicesByType = (refundServices) => {
    const servicesByType = new Map();
    refundServices.forEach(refundService => {
        const serviceType = refundService.service.type;
        servicesByType.set(serviceType, [
            ...(servicesByType.has(serviceType) ? servicesByType.get(serviceType) : []),
            refundService
        ]);
    });
    return servicesByType;
};
export const groupRefundServicesByPassenger = (refundServices) => {
    const serviceByPassenger = {};
    refundServices === null || refundServices === void 0 ? void 0 : refundServices.forEach(gdsInfo => {
        const service = gdsInfo.service;
        gdsInfo.gdsServiceProductsRefundInfo.forEach(product => {
            setProductToGroup(serviceByPassenger, product, service);
        });
    });
    return serviceByPassenger;
};
const setProductToGroup = (group, product, service) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const passengerId = product.product.passengerId, emdNumber = product.product.emdNumber;
    if (group.hasOwnProperty(passengerId)) {
        group[passengerId] = Object.assign(Object.assign({}, group[passengerId]), { [emdNumber]: {
                services: Object.assign(Object.assign({}, (((_a = group[passengerId][emdNumber]) === null || _a === void 0 ? void 0 : _a.services) || {})), { [service.id]: {
                        service: service,
                        products: [...(((_c = (_b = group[passengerId][emdNumber]) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c[service.id].products) || []), product],
                        sumToRefund: {
                            amount: (((_f = (_e = (_d = group[passengerId][emdNumber]) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e[service.id].sumToRefund) === null || _f === void 0 ? void 0 : _f.amount) || 0) +
                                ((_g = product.sumToRefund) === null || _g === void 0 ? void 0 : _g.amount),
                            currency: (_h = product.sumToRefund) === null || _h === void 0 ? void 0 : _h.currency
                        }
                    } })
            } });
    }
    else {
        group[passengerId] = {
            [emdNumber]: {
                services: {
                    [service.id]: {
                        service,
                        products: [product],
                        sumToRefund: product.sumToRefund
                    }
                }
            }
        };
    }
};
export const getProductsToExchange = (services, segmentIds = []) => {
    const emdMap = new Map();
    const productIdsByEmd = new Map();
    const productIds = [];
    services.forEach(service => {
        const isMatchBySegments = !segmentIds.length || segmentIds.find(segmentId => service.segmentIds.includes(segmentId));
        service.products.forEach(product => {
            if (product.emdNumber) {
                productIdsByEmd.set(product.emdNumber, [
                    ...(productIdsByEmd.get(product.emdNumber) || []),
                    parseInt(product.id)
                ]);
                if (isMatchBySegments) {
                    service.products.forEach(product => {
                        emdMap.set(product.emdNumber, product.emdNumber);
                    });
                }
            }
        });
    });
    emdMap.forEach(emd => {
        if (productIdsByEmd.has(emd)) {
            productIds.push(...productIdsByEmd.get(emd));
        }
    });
    return [...new Set(productIds)];
};
export const getPassengersSelectedProductIds = (travellers, passengerIds, segmentIds, isAllFlightToExchange) => {
    const services = [];
    passengerIds.forEach(passengerId => {
        var _a, _b, _c, _d, _e;
        const traveller = travellers.find(traveller => traveller.id === passengerId);
        if ((_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) {
            services.push(...(_d = (_c = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services);
        }
        if ((_e = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _e === void 0 ? void 0 : _e.seats) {
            traveller.services.seats.forEach(seat => {
                if (!seat.product) {
                    return;
                }
                services.push({
                    segmentIds: [seat.segment.id],
                    products: [seat.product]
                });
            });
        }
    });
    return getProductsToExchange(services, isAllFlightToExchange ? [] : segmentIds);
};
