import * as React from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import { useContext } from 'react';
import { OrderCardContext } from '../OrderCard';
const CommonItem = props => {
    const theme = useTheme('OrderCard').CommonItem;
    const { type } = useContext(OrderCardContext);
    return (React.createElement("div", { className: cn(theme.root, props.className, {
            [theme.account]: type === 'account',
            [theme.review]: type === 'review'
        }) },
        React.createElement("div", { className: cn(theme.payload, props.payloadClassName) },
            props.icon && React.createElement("div", { className: cn(theme.icon, props.iconClassName) }, props.icon),
            React.createElement("div", null, props.text)),
        props.buttons && React.createElement("div", { className: theme.buttons }, props.buttons)));
};
export default CommonItem;
