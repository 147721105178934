import * as React from 'react';
import CheckoutAdditionalService from '../../../../CheckoutAdditionalService';
import { useTranslation } from 'react-i18next';
import CommonContent from '../CommonContent';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
const BaggageRefundCard = props => {
    const { t } = useTranslation('Checkout');
    const theme = useTheme('RefundSummary').CommonContent;
    return (React.createElement(CheckoutAdditionalService, { header: t('Baggage'), description: t('Transportation of impressions no limitations'), isSelected: true, className: cn(theme.serviceCard, theme.serviceCard_baggage), addClick: () => null },
        React.createElement(CommonContent, Object.assign({}, props))));
};
export default BaggageRefundCard;
