import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { WarningRounded } from '../../../Icons';
import { useAeroexpressState } from './hooks';
import Disclaimer from '../../../Disclaimer/Disclaimer';
import AdditionalServiceWrapper from '../../../AdditionalServiceWrapper';
import AeroexpressSegment from '../AeroexpressSegment/AeroexpressSegment';
const AeroexpressPopup = ({ trips, selectedTrips, isLoading, onClose, onSubmit }) => {
    const { AeroexpressPopup: css } = useTheme('CheckoutAdditionalService');
    const { t } = useTranslation('Checkout');
    const { state, tripsByDirection, selectedTripItems, totalPrice, allPassengers, setTrip, clearTrips
    // toggleAllPassengers
     } = useAeroexpressState(trips, selectedTrips);
    const onSubmitHandler = () => {
        const selectedTripsIds = selectedTrips.map(trip => trip.id);
        const selectedTripsItemIds = selectedTripItems.map(trip => trip.id);
        if (JSON.stringify(selectedTripsIds.sort()) !== JSON.stringify(selectedTripsItemIds.sort())) {
            onSubmit(selectedTripsItemIds);
        }
        else {
            onClose();
        }
    };
    const onClear = () => {
        clearTrips();
        if (selectedTrips.length) {
            onSubmit([]);
        }
    };
    return (React.createElement(AdditionalServiceWrapper, { headerClassName: css.header, textClassName: css.text, contentClassName: css.content, footerClassName: css.footer, header: t('Aeroexpress'), text: React.createElement(React.Fragment, null,
            React.createElement("p", null, t('No worries about traffic jams, arrival at the airport strictly on schedule.')),
            React.createElement("p", null, t('Aeroexpress tickets are valid for 30 days.')),
            React.createElement("div", { className: css.info },
                React.createElement("div", { className: css.disclaimer },
                    React.createElement("a", { href: "https://aeroexpress.ru", target: "_blank" },
                        React.createElement(Disclaimer, { className: css.content_disclaimer, icon: WarningRounded }, t('Aeroexpress:View schedule')))))), totalPrice: totalPrice, isLoading: isLoading, onConfirm: onSubmitHandler, onClear: onClear, onCancel: onClose },
        React.createElement("div", { className: css.segments }, tripsByDirection.map(([direction, trips]) => (React.createElement(AeroexpressSegment, { key: direction, state: state, direction: direction, trips: trips, allPassengers: allPassengers, onChange: setTrip }))))));
};
export default AeroexpressPopup;
