import { PaximizerCommunicationSectionEnum } from '@websky/graphql';
import { setCommunicationCategory, decreaseUnreadCountByCategory, markAllAsReadInCategory, increaseUnreadCountByCategory } from './actions';
const initialState = {};
export const communicationsReducer = (state = initialState, action) => {
    if (action.type === setCommunicationCategory.toString()) {
        if (action.payload) {
            const result = {};
            action.payload.forEach(category => {
                // Todo: временно скрываем my_appeals
                if (category.id !== PaximizerCommunicationSectionEnum.my_appeals) {
                    result[category.id] = category;
                }
            });
            return result;
        }
    }
    else if (action.type === markAllAsReadInCategory.toString()) {
        const payload = action.payload;
        return Object.assign(Object.assign({}, state), { [payload]: Object.assign(Object.assign({}, state[payload]), { unreadMessagesCount: 0 }) });
    }
    else if (action.type === decreaseUnreadCountByCategory.toString()) {
        const payload = action.payload;
        if (payload) {
            return Object.assign(Object.assign({}, state), { [payload]: Object.assign(Object.assign({}, state[payload]), { unreadMessagesCount: state[payload].unreadMessagesCount - 1 }) });
        }
        return state;
    }
    else if (action.type === increaseUnreadCountByCategory.toString()) {
        const payload = action.payload;
        if (payload) {
            return Object.assign(Object.assign({}, state), { [payload]: Object.assign(Object.assign({}, state[payload]), { unreadMessagesCount: state[payload].unreadMessagesCount + 1 }) });
        }
        return state;
    }
    return initialState;
};
