import { compareAsc, toDate, parseISO } from 'date-fns';
export const safeAddDays = (date, days) => {
    const newDate = date instanceof Date ? toDate(date) : parseISO(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};
export const createDatesArray = (minDate, maxDate) => {
    let date = toDate(minDate);
    const datesArray = [];
    datesArray.push(minDate);
    while (compareAsc(maxDate, date) === 1) {
        date = safeAddDays(date, 1);
        datesArray.push(date);
    }
    return datesArray;
};
