import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Gateway from '../Gateway';
import { useTheme } from '../../../../theme';
import { PaymentType } from '../../../types';
import Button from '../../../../Button';
import { Money } from '../../../../Money';
const Cash = ({ children, isPreOpened, frameInfo, headerTitle, type, onCompletePayment, onClick }) => {
    const { Cash: css } = useTheme('PaymentForm');
    const { t } = useTranslation('PaymentForm');
    const onCompletePaymentHandler = useCallback(() => onCompletePayment({ transactionId: frameInfo.transactionId }), [
        onCompletePayment,
        frameInfo
    ]);
    const onClickGateway = useCallback(() => onClick({
        title: headerTitle,
        type: PaymentType.Cash,
        content: (React.createElement("div", { className: css.content },
            React.createElement(Button, { onClick: onCompletePaymentHandler },
                t('Pay'),
                React.createElement(Money, { money: frameInfo.totalPrice }))))
    }), [onCompletePayment, onClick, frameInfo]);
    useEffect(() => {
        if (isPreOpened) {
            onClickGateway();
        }
    }, [isPreOpened]);
    return React.createElement(Gateway, { header: children, onClick: onClickGateway, type: type, paymentType: PaymentType.Cash });
};
export default Cash;
