import * as React from 'react';
import { useMemo } from 'react';
import cn from 'classnames';
import { differenceInDays, isBefore, isSameDay, parse, parseISO, subDays, toDate } from 'date-fns';
import { createDatesArray } from '../utils';
import { Day } from './Day/Day';
import Slider from '../../Slider/components/Slider';
import { Currency } from '../../enums';
import { ThemeProvider, useTheme } from '../../theme';
import { getDateWithoutTimezone } from '../../Engine/components/FastSearch/utils';
import { useConfig } from '../../context';
import { API_DATE_FORMAT } from '../../utils';
import { useRenderers } from '../../renderProps';
const WeekCalendar = props => {
    var _a, _b, _c, _d;
    const prices = props.prices, pricesMap = new Map(), theme = useTheme('WeekCalendar').WeekCalendarStyles, sliderStyles = useTheme('WeekCalendar').SliderStyles, minPrice = {
        amount: prices.length && prices[0].price ? prices[0].price.amount : 0,
        currency: prices.length && prices[0].price ? prices[0].price.currency : Currency.USD
    };
    const calendarDaysCount = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.Engine.calendarDaysCount;
    let minDate = null;
    let maxDate = null;
    prices.map(price => {
        const dateTimestamp = new Date(price.date).getTime();
        if (minDate === null || dateTimestamp < minDate) {
            minDate = dateTimestamp;
        }
        if (maxDate === null || dateTimestamp > maxDate) {
            maxDate = dateTimestamp;
        }
        if (price.price && price.price.amount < minPrice.amount) {
            minPrice.amount = price.price.amount;
        }
        pricesMap.set(dateTimestamp, {
            date: price.date instanceof Date ? toDate(price.date) : parseISO(price.date),
            price: price.price
        });
    });
    const dates = createDatesArray(toDate(minDate), toDate(maxDate));
    const hideDisabledLeftArrow = prices.some(item => {
        if (item === null || item === void 0 ? void 0 : item.date) {
            const date = parse(item.date, API_DATE_FORMAT, new Date());
            return isBefore(date, new Date()) && !isSameDay(date, new Date());
        }
        return true;
    });
    const onRangeChange = (direction, startIndex, itemsCount) => {
        props.onRangeChange(direction === 'prev' ? 'decrease' : 'increase', dates[startIndex], itemsCount);
    };
    const centralDateTimestamp = props.centralDate.getTime();
    const selectedDatePriceIsMin = pricesMap.has(centralDateTimestamp) && ((_c = (_b = pricesMap.get(centralDateTimestamp)) === null || _b === void 0 ? void 0 : _b.price) === null || _c === void 0 ? void 0 : _c.amount) === minPrice.amount;
    /**
     * unique prices for this week
     */
    const samePriceAllWeek = useMemo(() => new Set([...pricesMap.values()]
        .filter(dateWithPrice => { var _a; return (_a = dateWithPrice === null || dateWithPrice === void 0 ? void 0 : dateWithPrice.price) === null || _a === void 0 ? void 0 : _a.amount; })
        .map(dateWithPrice => { var _a; return (_a = dateWithPrice === null || dateWithPrice === void 0 ? void 0 : dateWithPrice.price) === null || _a === void 0 ? void 0 : _a.amount; })).size === 1, [pricesMap]);
    const OverrideDay = (_d = useRenderers().CalendarDay) !== null && _d !== void 0 ? _d : Day;
    const compareSelectedDates = (date) => {
        return (props.centralDate &&
            props.centralDate.getDate() === getDateWithoutTimezone(date).getDate() &&
            props.centralDate.getMonth() === getDateWithoutTimezone(date).getMonth());
    };
    return prices.length ? (React.createElement("div", { className: theme.container },
        React.createElement(ThemeProvider, { value: { Slider: { SliderTheme: Object.assign({}, sliderStyles) } } },
            React.createElement(Slider, { maxItemsCount: calendarDaysCount, minItemWidth: 100, centeredItem: props.centralDate ? differenceInDays(props.centralDate, minDate) + 1 : 0, onRangeChange: props.onRangeChange && onRangeChange, hideDisabledLeftArrow: hideDisabledLeftArrow, hideDisabledRightArrow: false }, dates.map((date, index) => {
                var _a;
                const timestamp = date.getTime();
                const price = pricesMap.has(timestamp) ? pricesMap.get(timestamp) : null;
                const isSelected = compareSelectedDates(date);
                const isCheap = selectedDatePriceIsMin
                    ? isSelected
                    : !samePriceAllWeek && ((_a = price === null || price === void 0 ? void 0 : price.price) === null || _a === void 0 ? void 0 : _a.amount) === minPrice.amount;
                const isDisabled = props.minDate ? isBefore(date, props.minDate) : false;
                const isAvailable = (!isDisabled && props.isMilesSearch) || props.allowSelectWithoutPrice
                    ? !isBefore(date, subDays(new Date(), 1))
                    : !!(price === null || price === void 0 ? void 0 : price.price);
                return (React.createElement("div", { className: cn(theme.dayWrapper, {
                        [theme.dayWrapper_selected]: isSelected
                    }), key: index },
                    React.createElement(OverrideDay, { date: getDateWithoutTimezone(date), price: price && price.price, isAvailable: isAvailable, isCheap: isCheap, isSelected: isSelected, onSelect: props.onSelect, hidePrice: props.hidePrice || props.isMilesSearch })));
            }))))) : null;
};
WeekCalendar.displayName = 'WeekCalendar';
export default WeekCalendar;
