import * as SubscriptionsLanding from './SubscriptionsLanding.css';
import * as SubscriptionOffers from './SubscriptionOffers/components/SubscriptionOffers.css';
import * as PlansGroup from './PlansGroup/PlansGroup.css';
import * as PlansGroupCard from './PlansGroup/PlansGroupCard/PlansGroupCard.css';
import * as FaqsItem from './Faqs/FaqsItem/FaqsItem.css';
import * as SubscriptionBanner from './SubscriptionBanner/SubscriptionBanner.css';
import * as Header from './Header/Header.css';
export default {
    SubscriptionsLanding,
    SubscriptionOffers,
    PlansGroup,
    PlansGroupCard,
    FaqsItem,
    SubscriptionBanner,
    Header
};
