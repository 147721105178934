import * as React from 'react';
import { Locale } from '../../types';
import { useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '../../theme';
import { Button } from '../../index';
import { useTranslation } from 'react-i18next';
import FareConditions from './FareConditions/FareConditions';
import { useFareRulesQuery } from '@websky/graphql';
const FareConditionsWrapper = React.memo(({ orderId, flightId, language, segmentId, showTranslateTab, onLangChange }) => {
    const [segment, setSegment] = React.useState(segmentId ? segmentId : '');
    const [selectedLang, selectLang] = React.useState(Locale.Russian);
    const { data, loading, error, refetch } = useFareRulesQuery({
        variables: { flightId: flightId !== null && flightId !== void 0 ? flightId : orderId, orderId, language }
    });
    if (error) {
        console.log(error);
    }
    const fareRulesBySegmentsPassengersMap = {}, hasErrors = error || (!loading && !data.FareRules);
    const firstPassengerInList = 0;
    const { t } = useTranslation('FareConditions');
    const { FareConditions: css } = useTheme('FareConditions');
    if (!loading && !hasErrors) {
        data.FareRules.rulesForSegments.forEach(ruleForSegment => {
            ruleForSegment.segments.forEach(segment => {
                if (fareRulesBySegmentsPassengersMap.hasOwnProperty(segment.id)) {
                    fareRulesBySegmentsPassengersMap[segment.id].push(ruleForSegment);
                }
                else {
                    fareRulesBySegmentsPassengersMap[segment.id] = [ruleForSegment];
                }
            });
        });
        if (segment === '') {
            // select first available segment if there is default value
            setSegment(Object.keys(fareRulesBySegmentsPassengersMap)[0]);
        }
    }
    const tabsSegments = useMemo(() => Object.keys(fareRulesBySegmentsPassengersMap).map(fareRuleKey => {
        const fareRule = fareRulesBySegmentsPassengersMap[fareRuleKey];
        const segment = fareRule[firstPassengerInList].segments.filter(segment => segment.id === fareRuleKey)[0];
        return {
            id: segment.id,
            title: `${segment.departure.airport.city.name} — ${segment.arrival.airport.city.name}`,
            isDisabled: false
        };
    }), [fareRulesBySegmentsPassengersMap]);
    return (React.createElement(React.Fragment, null,
        loading && (React.createElement("div", { className: css.loaderWrp },
            React.createElement(CircularProgress, { classes: { colorPrimary: css.loader } }))),
        !loading && hasErrors && (React.createElement("div", { className: css.errorWrp },
            React.createElement("h6", { className: css.errorTitle }, t('Internal error')),
            error && React.createElement("p", { className: css.errorText }, error.message),
            React.createElement(Button, { size: "small", color: "primary", onClick: () => refetch({ flightId, language }) }, t('Try again')))),
        !loading && !hasErrors && (React.createElement(FareConditions, { fareRulesBySegmentsPassengersMap: fareRulesBySegmentsPassengersMap, fareRules: data, hasErrors: hasErrors, firstPassengerInList: firstPassengerInList, segment: segment, setSegment: setSegment, onLangChange: onLangChange, selectedLang: selectedLang, selectLang: selectLang, tabsSegments: tabsSegments, showTranslateTab: showTranslateTab }))));
});
export default FareConditionsWrapper;
