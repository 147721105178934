import * as ServiceWrapper from './components/ServiceWrapper/ServiceWrapper.css';
import * as CategoriesStyles from './components/Categories/Categories.css';
import * as List from './components/Categories/List/List.module.css';
import * as Warning from './components/Warning/Warning.module.css';
import { createTheme } from '../utils';
export const defaultTheme = {
    ServiceWrapper,
    CategoriesStyles,
    List,
    Warning
};
export const { useTheme } = createTheme(defaultTheme);
export default defaultTheme;
