import { __awaiter } from "tslib";
import { useEffect, useState } from 'react';
import { useCreateOrderMutation } from '@websky/graphql';
export const useCreateOrder = (params) => {
    const [order, setOrder] = useState(null);
    const [orderError, setOrderError] = useState(null);
    const [createOrder, { loading }] = useCreateOrderMutation();
    const loadOrder = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const data = yield createOrder({
                variables: {
                    params
                }
            });
            if (data.errors && data.errors.length) {
                setOrderError(data.errors[0]);
            }
            else if (data && data.data && data.data.CreateOrder) {
                setOrder(data.data.CreateOrder);
            }
        }
        catch (error) {
            setOrderError(error);
        }
    });
    useEffect(() => {
        loadOrder();
    }, [params]);
    return { loading, order, orderError };
};
