export const isFareGroupAvailable = (group, lastSelectedFare) => {
    var _a;
    let canCombineWithPreviousSelectedFare = true;
    if (lastSelectedFare) {
        const lastSelectedFareFlightsIds = lastSelectedFare.prices.map(price => price.flight.id);
        canCombineWithPreviousSelectedFare = (_a = group === null || group === void 0 ? void 0 : group.prices) === null || _a === void 0 ? void 0 : _a.some(price => lastSelectedFareFlightsIds.includes(price.flight.id));
    }
    return group.prices && canCombineWithPreviousSelectedFare;
};
export const findIndexFirstAvailableFareGroup = (fareGroups, lastSelectedFare) => {
    const index = fareGroups.findIndex(group => isFareGroupAvailable(group, lastSelectedFare));
    return index === -1 ? null : index;
};
export const roundAvailableSliderIndex = (sliderIndex, slidesToScroll) => {
    if (sliderIndex < slidesToScroll || !sliderIndex) {
        return 0;
    }
    let offset = 0;
    while ((sliderIndex - offset) % slidesToScroll !== 0) {
        offset++;
    }
    return sliderIndex - offset;
};
export const isFaresHasOneItem = (fares) => (fares === null || fares === void 0 ? void 0 : fares.length) === 1;
export const isShowFaresPagination = (fares) => (fares === null || fares === void 0 ? void 0 : fares.length) > 1;
