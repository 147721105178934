import * as React from 'react';
import { TravellerFieldEnum, TravellerVisaFieldEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import Field from './Field/Field';
import { getUserValue, initI18n } from '../../utils';
import { getInputType } from '../utils';
initI18n('VisaForm');
const VisaForm = props => {
    const { traveller } = props;
    const theme = useTheme('VisaForm').VisaForm;
    const passengerFirstName = getUserValue(traveller, TravellerFieldEnum.FirstName).toLowerCase(), passengerLastName = getUserValue(traveller, TravellerFieldEnum.LastName).toLowerCase(), passengerDateBirth = getUserValue(traveller, TravellerFieldEnum.BirthDate).toLowerCase();
    const fieldMap = new Map();
    traveller.visaValues.forEach(value => {
        fieldMap.set(value.type, value);
    });
    const groups = [
        [TravellerVisaFieldEnum.ApplicableCountry, TravellerVisaFieldEnum.Number],
        [TravellerVisaFieldEnum.BirthPlace, TravellerVisaFieldEnum.IssueDate],
        [TravellerVisaFieldEnum.IssuePlace]
    ];
    return (React.createElement("div", { className: theme.visaWrapper },
        React.createElement("div", { className: theme.passenger },
            React.createElement("div", { className: theme.passenger__name }, `${passengerFirstName} ${passengerLastName}`),
            React.createElement("div", { className: theme.passenger__birth }, passengerDateBirth)),
        React.createElement("div", null, groups.map((group, index) => (React.createElement("div", { className: theme.group, key: index }, group.map(key => {
            const field = fieldMap.get(key);
            return (React.createElement("div", { className: theme.group__field, key: key },
                React.createElement(Field, { key: field.name, field: field, fieldType: getInputType(field.type), travellerId: traveller.id })));
        })))))));
};
export default VisaForm;
