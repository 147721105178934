import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Money } from '../../../../../Money';
import { useTheme } from '../../../../../theme';
import BaggageComponent from '../../../../Baggage';
import { serviceForMultipleSegments } from '../../../hooks';
import { BaggageType } from '@websky/graphql';
import { getRelatedSegments, getActualPriceInBaggageGroup } from '../../../utils';
const HeaderBaggageItem = ({ services, allSegments }) => {
    const theme = useTheme('UpgradeBaggageDialog').BaggageDialogHeader;
    const { t } = useTranslation('IncludedBaggage');
    const multipleSegments = React.useMemo(() => serviceForMultipleSegments(services[0]), [services]);
    const price = React.useMemo(() => {
        var _a;
        if (allSegments || multipleSegments) {
            const pricesForPassengers = new Map();
            services.forEach(service => {
                if (service.canBeAdded) {
                    const segments = getRelatedSegments(service.segmentIds);
                    service.allowedPassengers.forEach(passengerId => {
                        pricesForPassengers.set(passengerId, (pricesForPassengers.get(passengerId) || 0) + service.price.amount * segments.size);
                    });
                }
            });
            const prices = Array.from(pricesForPassengers.values()), minPrice = (prices === null || prices === void 0 ? void 0 : prices.length) ? Math.min(...prices) : 0;
            return {
                currency: (_a = services[0]) === null || _a === void 0 ? void 0 : _a.price.currency,
                amount: minPrice,
                withFrom: prices.some(price => price > minPrice)
            };
        }
        return getActualPriceInBaggageGroup(services, multipleSegments);
    }, [services, allSegments, multipleSegments]);
    const isShort = [BaggageType.BaggageExcess].includes(services[0].type);
    return (React.createElement("div", { className: theme.baggage_wrapper, key: services[0].id },
        React.createElement(BaggageComponent, { baggage: services[0], selected: false, realIconSize: true, onlyIcon: false, short: isShort }),
        React.createElement("div", { className: theme.price_wrapper },
            price.withFrom && t('from'),
            React.createElement(Money, { money: {
                    currency: price.currency,
                    amount: price.amount
                } })),
        !allSegments && multipleSegments && (React.createElement("div", { className: theme.segmentNotice }, t('Added for all flights')))));
};
export default HeaderBaggageItem;
