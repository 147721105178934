import { eachDayOfInterval, endOfWeek, startOfWeek } from 'date-fns';
import { AviaPassengerType } from '@websky/graphql';
export const convertScheduleSearchParams = (parameters, date) => ({
    passengers: [
        {
            passengerType: AviaPassengerType.ADT,
            count: 1
        }
    ],
    segments: parameters.segments.map(segment => ({
        departure: {
            iata: segment.departure.iata
        },
        arrival: {
            iata: segment.arrival.iata
        },
        date
    }))
});
export const getWeekDatesIntervalOfDate = (date) => {
    return eachDayOfInterval({
        start: startOfWeek(date, { weekStartsOn: 1 }),
        end: endOfWeek(date, { weekStartsOn: 1 })
    });
};
export const sortLocationsByName = (a, b) => {
    if (a.name > b.name) {
        return 1;
    }
    else if (a.name < b.name) {
        return -1;
    }
    return 0;
};
