import * as React from 'react';
import { addDays, isSameDay, isSameMonth, isWeekend, startOfMonth, startOfWeek } from 'date-fns';
import { Day } from './Day/Day';
import { diffInDays, getSelectedDatesCount, getTimestamp, getWeeksInMonth, isDaySelected, timestampsCompare } from '../../../utils';
import { useTheme } from '../../../../theme';
import { RendererFunctionsContext } from '../../Datepicker';
import { WeeklyHeader } from './WeeklyHeader/WeeklyHeader';
export const Month = props => {
    const dates = [], startOfMonthDate = startOfMonth(props.date), startOfFirstWeek = startOfWeek(startOfMonthDate, { weekStartsOn: 1 }), today = new Date(), theme = useTheme('Datepicker').MonthStyles, renderersFunctions = React.useContext(RendererFunctionsContext), selectedDaysCount = getSelectedDatesCount(props.selectedDatesMap), hoveredDaysCount = diffInDays(props.minHoveredDateTimestamp, props.maxHoveredDateTimestamp);
    let currentDay = startOfMonthDate;
    if (startOfFirstWeek.getDate() !== startOfMonthDate.getDate()) {
        currentDay = startOfFirstWeek;
    }
    const weeksInMonth = getWeeksInMonth(startOfMonthDate);
    for (let weekIndex = 0; weekIndex < weeksInMonth; weekIndex++) {
        dates.push([]);
        for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
            const currentTimestamp = getTimestamp(currentDay);
            const isSelected = isDaySelected(currentDay, props.selectedDatesMap);
            const isHoverDatesNotEqual = props.minHoveredDateTimestamp !== props.maxHoveredDateTimestamp;
            const isEndPeriod = (selectedDaysCount > 1 && currentTimestamp === props.maxSelectedDateTimestamp) ||
                (selectedDaysCount === 1 && isSelected && currentTimestamp > props.minHoveredDateTimestamp);
            const isStartHover = props.minHoveredDateTimestamp &&
                currentTimestamp === props.minHoveredDateTimestamp &&
                isHoverDatesNotEqual &&
                currentTimestamp !== props.minSelectedDateTimestamp;
            const isEndHover = hoveredDaysCount > 0 &&
                currentTimestamp === props.maxHoveredDateTimestamp &&
                currentTimestamp !== props.maxSelectedDateTimestamp;
            const dayModel = {
                date: currentDay,
                isAvailable: currentTimestamp >= props.minDateTimestamp && currentTimestamp <= props.maxDateTimestamp,
                isToday: isSameDay(currentDay, today),
                isWeekend: isWeekend(currentDay) && !isEndPeriod,
                fromOtherMonth: !isSameMonth(currentDay, startOfMonthDate),
                isSelected,
                isStartPeriod: (selectedDaysCount > 1 && currentTimestamp === props.minSelectedDateTimestamp) ||
                    (selectedDaysCount === 1 &&
                        isSelected &&
                        isHoverDatesNotEqual &&
                        currentTimestamp < props.maxHoveredDateTimestamp),
                inPeriod: (selectedDaysCount > 1 &&
                    timestampsCompare(currentTimestamp, props.minSelectedDateTimestamp) &&
                    timestampsCompare(props.maxSelectedDateTimestamp, currentTimestamp)) ||
                    (currentTimestamp > props.minHoveredDateTimestamp &&
                        currentTimestamp < props.maxHoveredDateTimestamp),
                isEndPeriod,
                isStartHover,
                isEndHover,
                customClass: props.highlightingDates.hasOwnProperty(currentTimestamp)
                    ? props.highlightingDates[currentTimestamp]
                    : ''
            };
            dates[weekIndex].push(React.createElement(Day, { key: dayIndex, day: dayModel, onSelect: props.onSelect, onHover: props.onHover }));
            currentDay = addDays(currentDay, 1);
        }
    }
    return (React.createElement("div", { className: theme.month }, renderersFunctions.monthRenderer({
        monthHeader: props.monthHeader,
        daysByWeeks: dates,
        weekClassName: theme.week,
        weeklyHeader: React.createElement(WeeklyHeader, null)
    })));
};
