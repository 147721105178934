import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ExareReason, InfoMessageTypeEnum } from '@websky/graphql';
import { Form } from '../../../Checkout/components/Checkout/Form/Form';
import { NotIncluded } from '../../../Icons';
import { ThemeProvider, useTheme } from '../../../theme';
import { usePassengerForm } from './hooks';
import { getPassengerNames } from '../../../utils';
import { BaseButton } from '../../../BaseComponents';
import Notification from '../Notification/Notification';
import SimpleLoader from '../../../SimpleLoader';
import WarningModal from '../../../WarningModal';
const PassengerForm = ({ passenger, reason, onFillOrder, setReason }) => {
    const { t } = useTranslation('EditPassenger');
    const { PassengerForm: theme } = useTheme('EditPassenger');
    const { editablePassenger, requestError, isLoading, isTravellerDataLoading, allowSwapNames, onSubmit, onClearError } = usePassengerForm(passenger, onFillOrder, reason);
    const isDocumentReplacement = reason === ExareReason.DocumentReplacement;
    const onToggleChangeFiles = React.useCallback(() => {
        setReason(state => (!state ? ExareReason.DocumentReplacement : null));
    }, [setReason]);
    const renderToolbar = React.useCallback(({ valid, values, dirtyFields, errors }) => {
        var _a, _b, _c;
        const passengerValues = values.passengers[0];
        const editablePassengerNames = getPassengerNames(editablePassenger);
        const dirtyFieldsArray = Object.keys(dirtyFields !== null && dirtyFields !== void 0 ? dirtyFields : {}).filter(field => {
            const fieldPath = field.split('.');
            const fieldName = fieldPath[fieldPath.length - 1];
            if (fieldName === 'firstName' || fieldName === 'lastName' || fieldName === 'middleName') {
                return passengerValues[fieldName] !== editablePassengerNames[fieldName];
            }
            return !isDocumentReplacement || (isDocumentReplacement && !field.includes('changeFiles'));
        });
        const isDirty = dirtyFieldsArray.length > 0;
        const isValidAndDirty = valid && isDirty;
        const isDiffNames = editablePassengerNames.firstName !== passengerValues.firstName ||
            editablePassengerNames.lastName !== passengerValues.lastName;
        const hasChangeFiles = !isDocumentReplacement || (isDocumentReplacement && ((_b = (_a = passengerValues.files) === null || _a === void 0 ? void 0 : _a.changeFiles) === null || _b === void 0 ? void 0 : _b.length) > 0);
        const isDisabled = (!isValidAndDirty && !isDiffNames) || !hasChangeFiles || !!((_c = Object.keys(errors)) === null || _c === void 0 ? void 0 : _c.length);
        return (React.createElement("div", { className: theme.footer },
            React.createElement(BaseButton, { className: theme.button, variant: "action", isLoading: isLoading, disabled: isDisabled, type: "submit" }, t('Change'))));
    }, [editablePassenger, isLoading, isDocumentReplacement]);
    const formTheme = {
        PassengerForm: {
            Header: {
                header: theme.header__container,
                filled: theme.header__container_filled,
                number: theme.header__number
            },
            Group: {
                container: theme.group__container,
                identity: cn(theme.group__container__icon, theme.group__container_identity),
                documents: cn(theme.group__container__icon, theme.group__container_documents)
            }
        }
    };
    if (isTravellerDataLoading) {
        return React.createElement(SimpleLoader, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.container },
            React.createElement("div", { className: theme.messages },
                React.createElement(Notification, { icon: NotIncluded, status: InfoMessageTypeEnum.Warning, type: "context", title: t('Typo correction available'), infoMessage: {
                        text: t('To replace the document or change the surname, it is necessary to attach supporting documents.'),
                        type: InfoMessageTypeEnum.Warning,
                        code: 'warning'
                    } }),
                React.createElement(Notification, { status: InfoMessageTypeEnum.Info, type: "context", title: t('Attention please!'), infoMessage: {
                        text: t('Due to changes in the data in your order, the air ticket is being exchanged, and the airline cannot guarantee confirmation of previously purchased additional services. All services issued on one electronic document will be canceled, even if you make changes to part of the route. Refunds will be made by the airline according to the terms of the offer.'),
                        type: InfoMessageTypeEnum.Info,
                        code: 'info'
                    } })),
            React.createElement(ThemeProvider, { value: formTheme },
                React.createElement(Form, { passengerClassName: theme.passenger, customer: null, currentStep: null, passengers: [editablePassenger], renderToolbar: renderToolbar, onFormSubmit: onSubmit, setNextStep: () => { }, isChangeFiles: isDocumentReplacement, onToggleChangeFiles: onToggleChangeFiles, showContacts: false, allowSwapNames: allowSwapNames, isChangePassengers: true }))),
        React.createElement(WarningModal, { classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, title: t('Oops...'), content: (requestError === null || requestError === void 0 ? void 0 : requestError.message) || t('Refund:Unknown error'), isOpen: !!requestError, onClose: onClearError })));
};
export default PassengerForm;
