import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AiPredictionItemWrapper from '../AiPredictionItemWrapper/AiPredictionItemWrapper';
import { useTheme } from '../../../theme';
import { getDurationInSeconds } from '../../../Results/utils';
const AiPurchasePredictionMeal = ({ order, meal, onChange, onDisagree, onAgree }) => {
    const theme = useTheme('AIPurchasePredictionService').AiPredictionItemWrapper;
    const { t } = useTranslation('AIPurchasePredictionService');
    const time = useMemo(() => {
        let maxDurationInMinutes = 0;
        order.flight.segments.forEach(segment => {
            const inSeconds = getDurationInSeconds(segment.segment.duration);
            const inMinutes = inSeconds / 60;
            if (inMinutes > maxDurationInMinutes) {
                maxDurationInMinutes = inMinutes;
            }
        });
        return Math.floor(maxDurationInMinutes / 60);
    }, [order]);
    const headerTitle = time > 0
        ? t('YourFlightHours', { hours: time, plural: t('hours', { count: time }) })
        : t('Order your meals in advance');
    return (React.createElement(AiPredictionItemWrapper, { service: {
            price: meal.price,
            name: meal.description,
            renderHead: () => {
                return React.createElement("img", { src: meal.image, alt: meal.name, className: theme.predictionCard__meal });
            }
        }, buttonTitle: t('Continue without meal'), headerTitle: headerTitle, onDisagree: onDisagree, onAgree: onAgree, onChange: onChange }));
};
export default AiPurchasePredictionMeal;
