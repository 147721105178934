import * as React from 'react';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import { useTheme } from '../../../theme';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { CloseClear } from '../../../Icons';
const MobileStep = props => {
    const { isActive: isOpen, renderTitle, onCloseClick, className } = props;
    const { MobileStep: css } = useTheme('Refund');
    return (React.createElement(MediaQuery, { maxWidth: "mobile" },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn(css.header, className, { [css.active]: isOpen }) },
                props.icon && React.createElement("div", { className: css.icon }, props.icon),
                renderTitle(),
                onCloseClick && (React.createElement("div", { onClick: onCloseClick, className: css.close }, CloseClear))),
            React.createElement(Collapse, { in: isOpen, classes: { wrapperInner: cn(css.content, { [css.active]: isOpen }) } }, props.children))));
};
export default MobileStep;
