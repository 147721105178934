import { createStore as originalCreateStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { rootReducer } from './reducers';
import { get, SEARCH_FORM_STATE_KEY } from '../../cache';
import { getLocale } from '../../utils';
import memes from '../../memes';
export const createStore = memes((initialState = {}, defaultParameters) => {
    let defaultState = {};
    const middlewares = [];
    const searchFormState = get(SEARCH_FORM_STATE_KEY, {});
    if (searchFormState && searchFormState.state && searchFormState.locale === getLocale()) {
        defaultState = Object.assign(Object.assign({}, defaultState), { segments: searchFormState.state.segments.simpleRouteSegment });
    }
    if (defaultParameters === null || defaultParameters === void 0 ? void 0 : defaultParameters.segments) {
        defaultState = Object.assign(Object.assign({}, defaultState), { segments: defaultParameters.segments[0] });
    }
    if (initialState) {
        defaultState = Object.assign(Object.assign({}, defaultState), initialState);
    }
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(logger);
    }
    return originalCreateStore(rootReducer, defaultState, applyMiddleware(...middlewares));
});
