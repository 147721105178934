import * as DesktopFlightInfo from './components/DesktopFlightInfo.css';
import * as Segment from './components/Segment/Segment.css';
import * as Details from './components/Segment/Details/Details.css';
import * as Point from './components/Segment/Point/Point.css';
export default {
    DesktopFlightInfo,
    Segment,
    Details,
    Point
};
