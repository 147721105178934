import { __awaiter } from "tslib";
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAvailableInsuranceCodes, getOrder, getPriorityInsuranceProgram, getSelectedInsuranceCodes } from '../../../Checkout/store/order/selectors';
import { useToggleable } from '../../../hooks';
import { useInsuranceUpsaleConfig } from '../../hooks';
import { InsuranceGoal, reachGoal, TransportLegType } from '../../../analytics';
import { getTravellersTypes } from '../../../DataLayerAnalytics/utils';
export const useInsuranceUpsaleModal = ({ strategy, availableInsuranceCodes, selectedInsuranceCodes }) => {
    var _a;
    const order = useSelector(getOrder);
    const insuranceAvailableCodes = useSelector(getAvailableInsuranceCodes);
    const insuranceSelectedCodes = useSelector(getSelectedInsuranceCodes);
    const priorityInsurance = useSelector(getPriorityInsuranceProgram);
    const { isOpen: isUpsaleOpen, setOpen: setUpsaleOpen, open: openUpsaleModal, close: closeUpsaleModal } = useToggleable(false);
    const insuranceUpsaleConfig = useInsuranceUpsaleConfig();
    const availableCodes = useMemo(() => {
        return availableInsuranceCodes !== null && availableInsuranceCodes !== void 0 ? availableInsuranceCodes : insuranceAvailableCodes;
    }, [availableInsuranceCodes, insuranceAvailableCodes]);
    const selectedCodes = useMemo(() => {
        return selectedInsuranceCodes !== null && selectedInsuranceCodes !== void 0 ? selectedInsuranceCodes : insuranceSelectedCodes;
    }, [selectedInsuranceCodes !== null && selectedInsuranceCodes !== void 0 ? selectedInsuranceCodes : insuranceSelectedCodes]);
    const isNeedToShowInsuranceUpsale = useMemo(() => {
        if (!priorityInsurance) {
            return false;
        }
        if (!insuranceUpsaleConfig.getIsNeedToShow(strategy)) {
            return false;
        }
        // if the priority insurance is not present in the list, then we skip the step
        if (!availableCodes.includes(priorityInsurance.code)) {
            return false;
        }
        return !selectedCodes.includes(priorityInsurance.code);
    }, [strategy, priorityInsurance, availableCodes, selectedCodes, insuranceUpsaleConfig.getIsNeedToShow]);
    const from = order.flight.segments[0].segment.departure;
    const to = order.flight.segments[order.flight.segments.length - 1].segment.arrival;
    const flightNumber = `${(_a = order.flight.segments[0].segment.marketingAirline) === null || _a === void 0 ? void 0 : _a.iata}-${order.flight.segments[0].segment.flightNumber}`;
    const transportLeg = order.flight.segments.length > 1 ? TransportLegType.Transfer : TransportLegType.Direct;
    const passengerTypes = useMemo(() => getTravellersTypes(order.travellers), [order]);
    const analyticsFunnelPurchase = {
        airport_from: from.airport.iata,
        airport_to: to.airport.iata,
        date_start: from.date,
        date_end: to.date,
        passenger_type: passengerTypes,
        flight_number: flightNumber,
        avia_operator: window.location.hostname,
        transport_leg: transportLeg
    };
    const onCloseUpsaleModal = useCallback(() => {
        insuranceUpsaleConfig.onApplyStrategy(strategy);
        closeUpsaleModal();
    }, [strategy, insuranceUpsaleConfig.onApplyStrategy, closeUpsaleModal]);
    const onAgree = useCallback((callback) => __awaiter(void 0, void 0, void 0, function* () {
        reachGoal(InsuranceGoal.InsurancePopupYes, { funnel_purchase: analyticsFunnelPurchase });
        if (priorityInsurance) {
            // if the priority insurance is present in the list, then we skip the request for addition
            if (selectedCodes.includes(priorityInsurance.code)) {
                return;
            }
            onCloseUpsaleModal();
            yield (callback === null || callback === void 0 ? void 0 : callback(priorityInsurance.code ? [priorityInsurance.code] : undefined));
            return;
        }
        onCloseUpsaleModal();
    }), [priorityInsurance, selectedCodes, onCloseUpsaleModal]);
    const onDisagree = useCallback((callback) => __awaiter(void 0, void 0, void 0, function* () {
        reachGoal(InsuranceGoal.InsurancePopupNo, { funnel_purchase: analyticsFunnelPurchase });
        onCloseUpsaleModal();
        yield (callback === null || callback === void 0 ? void 0 : callback());
    }), [onCloseUpsaleModal]);
    const onContinue = useCallback((callback) => __awaiter(void 0, void 0, void 0, function* () {
        if (isNeedToShowInsuranceUpsale) {
            openUpsaleModal();
            return;
        }
        yield callback();
    }), [openUpsaleModal, isNeedToShowInsuranceUpsale]);
    return {
        // data
        priorityInsurance,
        isUpsaleOpen,
        isNeedToShowInsuranceUpsale,
        // methods
        setUpsaleOpen,
        onCloseUpsaleModal,
        onAgree,
        onDisagree,
        onContinue
    };
};
