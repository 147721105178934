export class Transliteration {
    static cyrillicToLatin(toTransl) {
        let answer = '';
        for (let i = 0; i < toTransl.length; i++) {
            if (this.CYRILLIC_DICTIONARY[toTransl[i]] === undefined) {
                answer += toTransl[i];
            }
            else {
                answer += this.CYRILLIC_DICTIONARY[toTransl[i]];
            }
        }
        return answer;
    }
}
Transliteration.CYRILLIC_DICTIONARY = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'i',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'kh',
    ц: 'ts',
    ч: 'ch',
    ш: 'sh',
    щ: 'shch',
    ь: '',
    ы: 'y',
    ъ: '',
    э: 'e',
    ю: 'iu',
    я: 'ia',
    А: 'A',
    Б: 'B',
    В: 'V',
    Г: 'G',
    Д: 'D',
    Е: 'E',
    Ё: 'E',
    Ж: 'Zh',
    З: 'Z',
    И: 'I',
    Й: 'I',
    К: 'K',
    Л: 'L',
    М: 'M',
    Н: 'N',
    О: 'O',
    П: 'P',
    Р: 'R',
    С: 'S',
    Т: 'T',
    У: 'U',
    Ф: 'F',
    Х: 'Kh',
    Ц: 'C',
    Ч: 'Ch',
    Ш: 'Sh',
    Щ: 'Shch',
    Ь: '',
    Ы: 'Y',
    Ъ: '',
    Э: 'E',
    Ю: 'Iu',
    Я: 'Ia'
};
