import { applyMiddleware, createStore as originalCreateStore } from 'redux';
import { rootReducer } from './reducers';
import memes from '../../memes';
export const createStore = memes(() => {
    const middleware = [];
    if (process.env.NODE_ENV === 'development') {
        middleware.push(require('redux-logger').default);
    }
    return originalCreateStore(rootReducer, applyMiddleware(...middleware));
});
