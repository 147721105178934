import { __rest } from "tslib";
import * as React from 'react';
import SegmentCompact from './SegmentCompact/SegmentCompact';
import SegmentCommon from './SegmentCommon/SegmentCommon';
const Segment = (_a) => {
    var { variant } = _a, props = __rest(_a, ["variant"]);
    if (variant === 'compact') {
        return React.createElement(SegmentCompact, Object.assign({}, props));
    }
    return React.createElement(SegmentCommon, Object.assign({}, props));
};
export default Segment;
