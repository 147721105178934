import * as React from 'react';
import cn from 'classnames';
import { BaseButton } from '../../../BaseComponents';
import { useTheme } from '../../../theme';
import { useOrderCardControls } from './hooks';
import { useTranslation } from 'react-i18next';
import CommonItem from '../CommonItem/CommonItem';
import Modal from '../../../Modal';
import CheckinRules from '../../../CheckinRules';
import { useConfig } from '../../../context';
const checkinIcon = (React.createElement("svg", { width: "21", height: "21", viewBox: "0 0 21 21", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.99977 0.875H15.7498C16.7123 0.875 17.4998 1.6625 17.4998 2.625V18.375C17.4998 19.3375 16.7123 20.125 15.7498 20.125H6.99977C6.03727 20.125 5.24977 19.3375 5.24977 18.375V15.75H6.99977V17.5H15.7498V3.5H6.99977V5.25H5.24977V2.625C5.24977 1.6625 6.03727 0.875 6.99977 0.875ZM3.02727 9.555L5.25852 11.7863L10.4298 6.5975L11.541 7.70875L5.24977 14L1.91602 10.6663L3.02727 9.555Z", fill: "currentColor" })));
const OrderCardControls = props => {
    const { loading, isRulesModalOpen, closeCheckinRulesModal, checkinRules, acceptRules, goToCheckin, isCheckinAvailable } = useOrderCardControls();
    const { global: { companyInfo: { iataCode } } } = useConfig();
    const { t } = useTranslation('OrderCard');
    const theme = useTheme('OrderCard').OrderCardControls;
    const isVisible = iataCode === 'N4' ? isCheckinAvailable : checkinRules;
    if (!isVisible) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(CommonItem, { className: theme.root, icon: checkinIcon, text: t('Online check-in started'), buttons: React.createElement(BaseButton, { variant: "optionAction", className: cn(theme.button, theme.button_checkin, { [theme.button_loading]: loading }), isLoading: loading, onClick: goToCheckin }, t('Check-in')) }),
        iataCode !== 'N4' && (React.createElement(Modal, { open: isRulesModalOpen, onClose: closeCheckinRulesModal, maxWidth: "md" }, checkinRules && React.createElement(CheckinRules, { onSumbit: acceptRules, rules: checkinRules })))));
};
export default OrderCardControls;
