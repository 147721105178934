import { __rest } from "tslib";
import * as React from 'react';
import { isImageValid } from './utils';
import { Log } from '../sentry';
const ImageWithFallback = (_a) => {
    var { src, fallbackSrc, alt = '' } = _a, props = __rest(_a, ["src", "fallbackSrc", "alt"]);
    const [isError, setIsError] = React.useState(false);
    const onLoad = () => {
        if (isError) {
            setIsError(true);
        }
    };
    const getImgRef = (img) => {
        if ((img === null || img === void 0 ? void 0 : img.complete) && (img.naturalWidth || img.naturalHeight)) {
            onLoad();
        }
    };
    React.useEffect(() => {
        let isCurrentSrc = true;
        isImageValid(src).then(isValid => {
            if (!isValid && isCurrentSrc) {
                setIsError(true);
                Log({
                    category: 'ImageWithFallback',
                    level: 'warning',
                    message: src
                });
            }
        });
        return () => {
            isCurrentSrc = false;
        };
    });
    React.useEffect(() => {
        onLoad();
    }, [src]);
    if (isError && !fallbackSrc) {
        return null;
    }
    return (React.createElement("img", Object.assign({ ref: getImgRef }, props, (isError && fallbackSrc ? { src: fallbackSrc } : { src: src, onLoad }), { alt: alt })));
};
export default ImageWithFallback;
