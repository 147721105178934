import * as React from 'react';
import classnames from 'classnames';
import { ThemeConsumer } from '../../theme';
import { createWithThemeDecorator } from '../../utils';
class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.sliderInsideRef = null;
        this.sliderRef = null;
        this.leftArrowRef = null;
        this.rightArrowRef = null;
        this.sliderOffset = 0;
        this.sliderWidth = 0;
        this.sliderWrapperWidth = 0;
        this.itemWidth = 1;
        this.itemsCount = 0;
        this.visibleItemsCount = 0;
        this.touchInitX = 0;
        this.touchBegin = false;
        this.itemsBeyondRightEdge = this.itemsBeyondRightEdge.bind(this);
        this.itemsBeyondLeftEdge = this.itemsBeyondLeftEdge.bind(this);
        this.startTouch = this.startTouch.bind(this);
        this.endTouch = this.endTouch.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.screenResize = this.screenResize.bind(this);
        this.slideRight = this.slideRight.bind(this);
        this.slideLeft = this.slideLeft.bind(this);
    }
    /**
     * Get items count on beyond the right edge
     */
    itemsBeyondRightEdge() {
        return Math.round((this.sliderWrapperWidth + (this.sliderOffset - this.sliderWidth)) / this.itemWidth);
    }
    /**
     * Get items count on beyond the left edge
     */
    itemsBeyondLeftEdge() {
        return Math.round(Math.abs(this.sliderOffset / this.itemWidth));
    }
    calculateSizes() {
        if (this.sliderRef && this.sliderInsideRef) {
            this.sliderWidth = this.sliderRef.offsetWidth;
            this.sliderWrapperWidth = this.sliderInsideRef.offsetWidth;
            if (this.props.maxItemsCount) {
                this.itemWidth = this.calculateItemWidth(this.props.maxItemsCount);
            }
            else {
                this.itemWidth = this.sliderInsideRef.offsetWidth / this.itemsCount;
            }
            this.visibleItemsCount = Math.round(this.sliderWidth / this.itemWidth);
        }
    }
    // Touch events
    startTouch(e) {
        var _a, _b;
        const { Slider: { SliderTheme: theme } } = this.props.theme;
        const inside = (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.inside_transition) === null || _a === void 0 ? void 0 : _a.split(' ')) !== null && _b !== void 0 ? _b : [];
        this.touchBegin = true;
        this.sliderInsideRef.classList.remove(...inside);
        this.touchInitX = e.touches[0].clientX;
    }
    endTouch() {
        var _a, _b;
        const { Slider: { SliderTheme: theme } } = this.props.theme;
        const insideTransition = (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.inside_transition) === null || _a === void 0 ? void 0 : _a.split(' ')) !== null && _b !== void 0 ? _b : [];
        this.touchBegin = false;
        this.sliderInsideRef.classList.add(...insideTransition);
        this.touchInitX = 0;
        this.offsetCorrection();
    }
    touchMove(e) {
        if (this.touchBegin) {
            e.preventDefault();
            const delta = e.touches[0].clientX - this.touchInitX;
            if (this.props.touchTransition === 'default') {
                this.setOffset(this.sliderOffset + delta);
                this.touchInitX = e.touches[0].clientX;
            }
            else if (this.props.touchTransition === 'by-items') {
                if (Math.abs(delta) > this.props.minItemWidth / 1.5) {
                    delta > 0 ? this.slideLeft(1) : this.slideRight(1);
                    this.touchInitX = e.touches[0].clientX;
                }
            }
        }
    }
    offsetCorrection() {
        if (this.sliderOffset > 0) {
            this.setOffset(0);
        }
        else if (this.itemsBeyondRightEdge() < 0) {
            this.setOffset(this.sliderWidth - this.sliderWrapperWidth);
        }
    }
    screenResize() {
        this.calculateSizes();
        if (this.props.leftStartItem) {
            this.setInLeftView(this.props.leftStartItem);
        }
        else if (this.props.centeredItem) {
            this.setInCenterView(this.props.centeredItem);
        }
        if (this.props.onItemCentered) {
            this.props.onItemCentered(Math.round(this.itemsBeyondLeftEdge()), this.visibleItemsCount, this.itemsBeyondRightEdge());
        }
    }
    calculateItemWidth(itemsCount) {
        const itemSize = Math.round(this.sliderWidth / itemsCount);
        if (this.props.minItemWidth && itemSize < this.props.minItemWidth) {
            return this.calculateItemWidth(itemsCount - 1);
        }
        this.setItemsWidth(itemSize);
        return itemSize;
    }
    setItemsWidth(size) {
        if (this.sliderInsideRef) {
            Array.from(this.sliderInsideRef.childNodes[0].childNodes).forEach(node => {
                node.style.width = `${size}px`;
            });
        }
    }
    componentDidMount() {
        this.itemsCount = this.sliderInsideRef.childNodes[0].childNodes.length;
        this.calculateSizes();
        window.addEventListener('resize', this.screenResize);
        if (this.props.touchEvents && this.sliderRef) {
            this.sliderRef.addEventListener('touchstart', this.startTouch);
            this.sliderRef.addEventListener('touchmove', this.touchMove);
            this.sliderRef.addEventListener('touchend', this.endTouch);
        }
        this.screenResize();
        this.arrowAvailabilityCheck();
    }
    componentDidUpdate() {
        if (this.props.leftStartItem >= 0) {
            this.setInLeftView(this.props.leftStartItem);
        }
        else if (this.props.centeredItem >= 0) {
            this.setInCenterView(this.props.centeredItem);
        }
        this.arrowAvailabilityCheck();
    }
    slideRight(neededOffset) {
        const itemsCount = this.itemsBeyondRightEdge(), itemsOffset = neededOffset
            ? neededOffset
            : this.props.offset
                ? this.props.offset
                : Math.round(this.visibleItemsCount / 2);
        let offset = this.sliderOffset;
        if (this.sliderInsideRef && itemsCount > 0) {
            offset -= this.itemWidth * (itemsCount > itemsOffset ? itemsOffset : itemsCount);
            this.setOffset(offset);
        }
        if (this.props.onRangeChange) {
            this.props.onRangeChange('next', Math.round(this.itemsBeyondLeftEdge()), this.visibleItemsCount, itemsCount);
        }
    }
    slideLeft(neededOffset) {
        const itemsCount = this.itemsBeyondLeftEdge(), itemsOffset = neededOffset
            ? neededOffset
            : this.props.offset
                ? this.props.offset
                : Math.round(this.visibleItemsCount / 2);
        let offset = this.sliderOffset;
        if (this.sliderInsideRef && itemsCount > 0) {
            offset += this.itemWidth * (itemsCount > itemsOffset ? itemsOffset : itemsCount);
            this.setOffset(offset);
        }
        if (this.props.onRangeChange) {
            this.props.onRangeChange('prev', Math.round(this.itemsBeyondLeftEdge()), this.visibleItemsCount, itemsCount);
        }
    }
    /**
     * Set item in center of view
     * @param {number} item
     */
    setInCenterView(item) {
        const itemsCountInView = Math.round(this.sliderWidth / this.itemWidth), itemsHalfCountInView = Math.round(itemsCountInView / 2), itemsCountBefore = item - itemsHalfCountInView;
        let offset = 0;
        if (itemsCountBefore > 0) {
            offset = itemsCountBefore;
            if (this.itemsCount - offset < itemsCountInView) {
                offset = this.itemsCount - itemsCountInView;
            }
        }
        this.setOffset(offset * this.itemWidth * -1);
    }
    setInLeftView(item) {
        this.setOffset(item * this.itemWidth * -1);
    }
    setOffset(offset) {
        if (this.sliderInsideRef) {
            this.sliderInsideRef.style.left = `${offset}px`;
        }
        this.sliderOffset = offset;
        this.arrowAvailabilityCheck();
    }
    arrowAvailabilityCheck() {
        var _a, _b;
        const { Slider: { SliderTheme: theme } } = this.props.theme;
        const hide = (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.hide) === null || _a === void 0 ? void 0 : _a.split(' ')) !== null && _b !== void 0 ? _b : [];
        if (!this.props.alwaysShowArrows) {
            if (this.props.hideDisabledLeftArrow && this.itemsBeyondLeftEdge() <= 0) {
                this.leftArrowRef.classList.add(...hide);
            }
            else {
                this.leftArrowRef.classList.remove(...hide);
            }
            if (this.props.hideDisabledRightArrow && this.itemsBeyondRightEdge() <= 0) {
                this.rightArrowRef.classList.add(...hide);
            }
            else {
                this.rightArrowRef.classList.remove(...hide);
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.screenResize);
        if (this.props.touchEvents && this.sliderRef) {
            this.sliderRef.removeEventListener('touchstart', this.startTouch);
            this.sliderRef.removeEventListener('touchmove', this.touchMove);
            this.sliderRef.removeEventListener('touchend', this.endTouch);
        }
    }
    render() {
        const { Slider: { SliderTheme: theme } } = this.props.theme;
        return (React.createElement("div", { className: theme.container },
            React.createElement("div", { className: classnames(theme.arrow, theme.arrow_next), ref: ref => (this.rightArrowRef = ref), onClick: () => this.slideRight() }),
            React.createElement("div", { className: classnames(theme.arrow, theme.arrow_prev), ref: ref => (this.leftArrowRef = ref), onClick: () => this.slideLeft() }),
            React.createElement("div", { ref: ref => (this.sliderRef = ref), className: theme.slider },
                React.createElement("div", { ref: ref => (this.sliderInsideRef = ref), className: classnames(theme.inside, theme.inside_transition) },
                    React.createElement("div", { ref: this.props.innerRef, className: classnames(theme.elementsWrapper, this.props.wrapperClassName) }, this.props.children)))));
    }
}
Slider.displayName = 'Slider';
export default createWithThemeDecorator(ThemeConsumer)(Slider);
