import { __awaiter } from "tslib";
import { useCallback, useEffect, useState } from 'react';
import { ApolloError } from 'apollo-boost';
import { Mode } from './RetrieveBooking';
import { useConfig } from '../../context';
import { useClarifyDocumentModal } from './ClarifyDocumentModal/hooks';
const onlyAirportCheckInError = 'Invalid sub status: OnlyAirportCheckIn';
export function useRetrieveBooking({ mode, initialId, initialSecret, submitWithInitialValues, onSubmit }) {
    const { global: { companyInfo: { iataCode } } } = useConfig();
    const clarifyDocumentModal = useClarifyDocumentModal();
    const [isLoading, setIsLoading] = useState(false);
    const [codeIsWrong, setCodeIsWrong] = useState(false);
    const [isBlockedForPayment, setIsBlockedForPayment] = useState(false);
    const [isOnlyAirportCheckin, setIsOnlyAirportCheckin] = useState(false);
    const isCheckoutMode = mode === Mode.Checkout;
    const isCheckinMode = mode === Mode.Checkin;
    const isErrorNotification = iataCode === 'A9' || iataCode === 'KV' || iataCode === 'F7';
    const isOrderNotFoundError = codeIsWrong && !isErrorNotification && !isOnlyAirportCheckin;
    useEffect(() => {
        if (submitWithInitialValues) {
            handleSubmit({ id: initialId, secret: initialSecret }, {});
        }
    }, [submitWithInitialValues]);
    const onSubmitForm = useCallback((values) => {
        var _a, _b;
        const id = values['id'];
        const secret = (_a = values['secret']) === null || _a === void 0 ? void 0 : _a.trim();
        const extraSecret = (_b = values['extraSecret']) === null || _b === void 0 ? void 0 : _b.trim();
        const payload = isCheckinMode ? { id, secret, extraSecret } : { id, secret, secret2fa: extraSecret };
        return onSubmit(payload);
    }, [onSubmit, isCheckinMode]);
    const handleError = useCallback((values, e) => {
        var _a;
        console.warn(e);
        if (e instanceof ApolloError &&
            ((_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a.some((error) => {
                var _a, _b;
                return ((_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.includes(onlyAirportCheckInError)) ||
                    ((_b = error === null || error === void 0 ? void 0 : error.debugMessage) === null || _b === void 0 ? void 0 : _b.includes(onlyAirportCheckInError));
            }))) {
            setIsOnlyAirportCheckin(true);
        }
        else if (e instanceof Error && clarifyDocumentModal.needToShowClarifyModal(e)) {
            clarifyDocumentModal.setSearchParams(values);
            clarifyDocumentModal.open();
        }
        else {
            setCodeIsWrong(true);
        }
    }, [setIsOnlyAirportCheckin, setCodeIsWrong, clarifyDocumentModal]);
    const handleSubmit = useCallback((values, form) => __awaiter(this, void 0, void 0, function* () {
        setCodeIsWrong(false);
        setIsLoading(true);
        setIsOnlyAirportCheckin(false);
        if (isCheckinMode && form.hasOwnProperty('getFieldState')) {
            // clear warning on submit
            form.getFieldState('secret').data.warning = null;
        }
        try {
            const result = yield onSubmitForm(values);
            if (result) {
                if (typeof result !== 'boolean') {
                    setIsBlockedForPayment(result.isBlockedForPayment);
                }
            }
            else {
                setCodeIsWrong(true);
            }
        }
        catch (e) {
            handleError(values, e);
        }
        setIsLoading(false);
    }), [onSubmitForm, handleError, isCheckinMode]);
    const handleClarifySubmit = useCallback((values) => __awaiter(this, void 0, void 0, function* () {
        try {
            const result = yield onSubmitForm(values);
            return !!result;
        }
        catch (e) {
            console.warn(e);
            return false;
        }
    }), [onSubmitForm]);
    return {
        // data
        isLoading,
        codeIsWrong,
        isCheckoutMode,
        isCheckinMode,
        isBlockedForPayment,
        isOnlyAirportCheckin,
        isErrorNotification,
        isOrderNotFoundError,
        isClarityDocumentOpen: clarifyDocumentModal.isOpen,
        searchParams: clarifyDocumentModal.searchParams,
        // methods
        handleSubmit,
        handleClarifySubmit,
        closeClarityDocument: clarifyDocumentModal.close
    };
}
