import { useUpgradeFlightClass as useStore } from '../store';
import { useSelector } from 'react-redux';
import { getOrder } from '../../Checkout/store/order/selectors';
import { useCallback, useMemo } from 'react';
import { useRefundSummary } from '../../RefundSummary';
import { useSelectors } from '../store/selectors';
import { Log } from '../../sentry';
export const useUpgradeFlightClass = ({ onBackClick, loading, onSaveServices, onContinueWithoutSelection }) => {
    const order = useSelector(getOrder);
    const { state, dispatch } = useStore(order);
    const selectors = useSelectors(state, order, onContinueWithoutSelection);
    const { gdsServicesRefund, loading: refundServicesLoading, hasSelectedProducts } = useRefundSummary(selectors.refundSummaryParams);
    const handleToggleSegment = segmentId => dispatch({ type: 'toggleSegment', payload: segmentId });
    const handleSetStep = step => dispatch({ type: 'setStep', payload: step });
    const handleGoToReview = useCallback(() => {
        handleSetStep('review');
    }, [state.selectedSegments]);
    const handleConfirm = useCallback(() => {
        const params = selectors.saveOrderServicesVariables;
        onSaveServices(params);
    }, [selectors.saveOrderServicesVariables]);
    const toolbarCallbacks = useMemo(() => {
        if (state.step === 'select') {
            let nextStep;
            if (state.selectedSegments.length > 0) {
                nextStep = handleGoToReview;
            }
            else if (state.selectedSegments.length === 0 && onContinueWithoutSelection) {
                nextStep = onContinueWithoutSelection;
            }
            else {
                Log({
                    level: 'warning',
                    message: 'can not calculate next step'
                });
                nextStep = () => null;
            }
            return {
                next: nextStep,
                back: onBackClick
            };
        }
        else {
            if (selectors.isAllowInstantSelect && hasSelectedProducts) {
                handleConfirm();
            }
            return {
                next: handleConfirm,
                back: () => handleSetStep('select')
            };
        }
    }, [state.step, handleGoToReview, handleConfirm, onContinueWithoutSelection]);
    return {
        order: order,
        step: state.step,
        transferDurationBySegments: selectors.transferDurationBySegments,
        refundServicesLoading: refundServicesLoading,
        gdsServicesRefund: gdsServicesRefund,
        toggleSegment: handleToggleSegment,
        service: state.service,
        flights: state.flights,
        selectedSegments: state.selectedSegments,
        nextButtonDisabled: selectors.nextButtonDisabled,
        isServicesSaving: loading,
        toolbarCallbacks,
        totalPrice: state.totalPrice
    };
};
