import * as React from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { parseDate } from '../../Loyalty/utils';
const MilesHistory = ({ className, records }) => {
    const { MilesHistory: css } = useTheme('MilesHistory');
    const { t } = useTranslation('Account');
    const numberWithSpaces = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return (React.createElement("div", { className: cn(className, css.container) },
        React.createElement("div", { className: css.title }, t('Operations history')),
        React.createElement("div", { className: css.wrapper },
            React.createElement("div", { className: css.list },
                React.createElement("div", { className: css.listHeader },
                    React.createElement("div", { className: css.listHeader_item }, t('Date')),
                    React.createElement("div", { className: css.listHeader_item }, t('Operation')),
                    React.createElement("div", { className: css.listHeader_item }, t('Balance')),
                    React.createElement("div", { className: css.listHeader_item }, t('Status miles'))),
                records.map(({ recordId, onTimeUTC, description, milesInc, milesDec, miles, statVol }) => (React.createElement("div", { className: css.listItem, key: recordId },
                    React.createElement("div", { className: css.listItem_col }, onTimeUTC && (React.createElement(React.Fragment, null,
                        React.createElement("span", null, format(parseDate(onTimeUTC), 'dd.MM.yy')),
                        React.createElement("br", null),
                        format(parseDate(onTimeUTC), 'HH:mm')))),
                    React.createElement("div", { className: css.listItem_col },
                        React.createElement("span", { className: cn({
                                [css.label_accrued]: milesInc > 0,
                                [css.label_spent]: milesDec > 0
                            }) }, milesInc > 0
                            ? `${t('Assessed')}:`
                            : milesDec > 0
                                ? `${t('Purchase for miles')}:`
                                : ''),
                        React.createElement("br", null),
                        description || ''),
                    React.createElement("div", { className: css.listItem_col },
                        React.createElement("span", { className: cn(css.number, {
                                [css.label_accrued]: milesInc > 0,
                                [css.label_spent]: milesDec > 0
                            }) }, milesInc > 0
                            ? `+${numberWithSpaces(milesInc)}`
                            : milesDec > 0
                                ? `-${numberWithSpaces(milesDec)}`
                                : ''),
                        React.createElement("div", { className: cn(css.balance, css.number) }, miles ? numberWithSpaces(miles) : '0')),
                    React.createElement("div", { className: css.listItem_col }, statVol ? numberWithSpaces(statVol) : '0'))))))));
};
export default MilesHistory;
