import * as React from 'react';
import { useTheme } from '../theme';
import { initI18n } from '../../utils';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
initI18n('Loader');
const Loader = props => {
    const css = useTheme().Loader, { t } = useTranslation('Loader');
    return (React.createElement("div", { className: cn(css.loader__content, props.className) },
        React.createElement("div", { className: css.loader },
            React.createElement("div", { className: cn(css.loader_spinner, props.spinnerClassName) })),
        ' ',
        !props.hideText && `${t('loading')}...`));
};
export default Loader;
