import { useMemo } from 'react';
import { parse } from 'date-fns';
import { API_DATE_FORMAT } from '../../../../utils';
import { useFlightsScheduleQuery } from '@websky/graphql';
import { isApolloErrorGuard } from './utils';
import { useConfig } from '../../../../context';
import { useTranslation } from 'react-i18next';
export const useAvailableDates = (segment) => {
    const { data: availableDates, refetch, error, loading } = useFlightsScheduleQuery({
        variables: {
            parameters: {
                departure: segment === null || segment === void 0 ? void 0 : segment.departure.iata,
                arrival: segment === null || segment === void 0 ? void 0 : segment.arrival.iata
            }
        },
        skip: !segment
    });
    const dates = useMemo(() => {
        if (availableDates === null || availableDates === void 0 ? void 0 : availableDates.FlightsSchedule.datesData) {
            return availableDates === null || availableDates === void 0 ? void 0 : availableDates.FlightsSchedule.datesData.filter(date => date === null || date === void 0 ? void 0 : date.date).map(date => parse(date.date, API_DATE_FORMAT, new Date()));
        }
        return [];
    }, [availableDates === null || availableDates === void 0 ? void 0 : availableDates.FlightsSchedule]);
    return {
        dates,
        isLoading: loading,
        error,
        refetch
    };
};
export const useHtmlDescriptionOfError = (errors, overrides) => {
    var _a, _b;
    const { Engine: { checkInURL, checkInEnabled } } = useConfig();
    const { t } = useTranslation('Exchange');
    let header = null, body = null;
    const error = isApolloErrorGuard(errors) ? (_b = (_a = errors.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message : errors === null || errors === void 0 ? void 0 : errors[0];
    if (!error) {
        return null;
    }
    if (overrides === null || overrides === void 0 ? void 0 : overrides[error]) {
        return overrides[error];
    }
    if (error.includes('Exchange operations with checked segments are not permitted')) {
        header = t(`It is not possible to exchange the ticket on which the check-in was made for the flight`);
        body = checkInEnabled
            ? `${t('If you wish to exchange your ticket, please cancel your flight check-in.')}
				${t(`You can do it yourself by going to <a href='{{checkInURL}}' target='_blank'>Online check-in</a>.`, { checkInURL })}
				${t('Either select unused ticket segments for exchange.')}`
            : `${t('If you wish to exchange your ticket, please cancel your flight check-in.')}
				${t('Either select unused ticket segments for exchange.')}`;
    }
    else if (error.includes('Exchange operations with used segments are not permitted')) {
        header = t(`It is not possible to exchange a used ticket`);
        body = t('The ticket has been flown on, you cannot exchange the used ticket');
    }
    else if (error.includes('Exchange operation allowed only in manual mode')) {
        header = t('Partially used ticket can be exchanged only in manual mode');
        body = t(`Contact the airline call-center to clarify the conditions of the exchange, as a part of the route was flown. Or select segments of an unused ticket for exchange.`);
    }
    else {
        return null;
    }
    return {
        header,
        body
    };
};
