import * as React from 'react';
import { useConfig } from '../context';
export const getVarioqubCounterScript = () => {
    const { global: { varioqubId } } = useConfig();
    if (!varioqubId) {
        return false;
    }
    const id = varioqubId; // default Varioqub or AC Varioqub
    return `(function(e, x, pe, r, i, me, nt){
		e[i]=e[i]||function(){(e[i].a=e[i].a||[]).push(arguments)},
		me=x.createElement(pe),me.async=1,me.src=r,nt=x.getElementsByTagName(pe)[0],nt.parentNode.insertBefore(me,nt)})
		(window, document, 'script', 'https://abt.s3.yandex.net/expjs/latest/exp.js', 'ymab');
		ymab('metrika.${id}', 'init',  function (resp) {
			window.dispatchEvent( new CustomEvent("onVarioqubData",{detail:{resp:resp}} ) );
		});`;
};
export const useVarioqub = () => {
    const { global: { varioqubId } } = useConfig();
    if (!varioqubId) {
        return null;
    }
    const [varioqub, setVarioqub] = React.useState(null);
    window.addEventListener('onVarioqubData', e => {
        var _a, _b;
        const flags = (_b = (_a = e.detail) === null || _a === void 0 ? void 0 : _a.resp) === null || _b === void 0 ? void 0 : _b.flags;
        const flagsMap = new Map(Object.entries(flags).map(([key, value = []]) => {
            if (key === 'SmartBox') {
                return [key, value[0] === 'True'];
            }
            else {
                return [key, value];
            }
        }));
        setVarioqub(flagsMap);
        window.removeEventListener('onVarioqubData', this);
    });
    return varioqub;
};
export const VarioqubContext = React.createContext(null);
