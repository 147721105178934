import * as React from 'react';
import { useForm } from 'react-final-form';
import { useEffect } from 'react';
const WindowScope = props => {
    const form = useForm();
    const changePassengerField = (fieldName, passengerId, newValue) => {
        if (props.fieldsMap.hasOwnProperty(fieldName) && !props.fieldsMap[fieldName].readonly && !props.disabled) {
            form.change(`passengers[${passengerId}].[${fieldName}]`, newValue);
            return true;
        }
        return false;
    };
    useEffect(() => {
        window.changePassengerField = changePassengerField;
        return () => {
            window.changePassengerField = null;
        };
    }, []);
    return React.createElement(React.Fragment, null, props.children);
};
export default WindowScope;
