import { useCheckinStart } from '../../../Checkout/components/Checkout/hooks';
export const useOrderCardControls = () => {
    const { isCheckinAvailable, goToCheckin, checkinCreationInProgress, checkinRules, isRulesModalOpen, closeCheckinRulesModal, acceptRules } = useCheckinStart();
    return {
        loading: checkinCreationInProgress,
        goToCheckin,
        checkinRules: isCheckinAvailable ? checkinRules : null,
        isCheckinAvailable,
        isRulesModalOpen,
        closeCheckinRulesModal,
        acceptRules
    };
};
