import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import Card from '@material-ui/core/Card';
import { InfoMessagePositionsEnum } from '@websky/graphql';
import * as icons from './icons';
import { useTheme } from '../../../theme';
import { useMobileSegmentFlatInfo } from './hooks';
import AircraftPopup from '../../../AircraftPopup';
import Point from '../../../DesktopFlightRoute/components/Segment/Point/Point';
import RouteArrow from '../../../DesktopFlightRoute/components/Segment/RouteArrow/RouteArrow';
import { getDurationString } from '../../../Results/utils';
import Amenities from '../../../Amenities';
import StopsInfo from '../../../DesktopFlightRoute/components/Segment/StopsInfo/StopsInfo';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../context';
import AircraftLivery from '../../../AircraftLivery';
import InfoMessages from '../../../InfoMessages';
export var MobileSegmentType;
(function (MobileSegmentType) {
    MobileSegmentType[MobileSegmentType["Recommended"] = 0] = "Recommended";
    MobileSegmentType[MobileSegmentType["Selected"] = 1] = "Selected";
})(MobileSegmentType || (MobileSegmentType = {}));
const MobileSegmentFlat = (props, { type }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { MobileSegmentFlat: theme } = useTheme('MobileSegmentInfo');
    const { t } = useTranslation('DesktopFlightInfo');
    const iataCode = useConfig().global.companyInfo.iataCode;
    const hasOperatingCarrier = ['A9', 'RM'].includes(iataCode);
    const { departureDate, arrivalDate, firstSegment, lastSegment, canceled } = useMobileSegmentFlatInfo(props);
    const operatingCarrier = hasOperatingCarrier && (firstSegment === null || firstSegment === void 0 ? void 0 : firstSegment.marketingAirline.iata) !== (firstSegment === null || firstSegment === void 0 ? void 0 : firstSegment.operatingAirline.iata)
        ? firstSegment.operatingAirline.name
        : null;
    const companyName = (_a = firstSegment === null || firstSegment === void 0 ? void 0 : firstSegment.operatingAirline) === null || _a === void 0 ? void 0 : _a.name;
    const companyIcon = (_b = firstSegment === null || firstSegment === void 0 ? void 0 : firstSegment.operatingAirline) === null || _b === void 0 ? void 0 : _b.icon;
    const renderAircraftButton = useCallback((onClick) => (React.createElement(AircraftLivery, { className: theme.image, returnClassName: theme.image_return, isReturnFlight: props.isReturnFlight, aircraft: firstSegment === null || firstSegment === void 0 ? void 0 : firstSegment.aircraft, onClick: onClick })), [firstSegment]);
    return (React.createElement(Card, { elevation: 0, className: cn(theme.card, props.className) }, (_d = (_c = props.renderCompanyName) === null || _c === void 0 ? void 0 : _c.call(props, { companyName, segment: props.segments[0] })) !== null && _d !== void 0 ? _d : (React.createElement("div", { className: theme.title },
        React.createElement("img", { src: companyIcon, alt: companyName }),
        companyName)),
        React.createElement("div", { className: cn(theme.segment, {
                [theme.canceled]: canceled && !props.multipleSegments
            }) },
            React.createElement(Point, { time: firstSegment.departure.time, airport: firstSegment.departure.airport, mobile: true, date: departureDate }),
            React.createElement(RouteArrow, { timeInRoute: getDurationString(firstSegment.duration), mobile: true, flightNumber: `${firstSegment.marketingAirline.iata}-${firstSegment.flightNumber}`, stopCount: props.stopCount }),
            React.createElement(Point, { time: lastSegment.arrival.time, airport: lastSegment.arrival.airport, mobile: true, arrival: true, date: arrivalDate })),
        React.createElement("div", { className: theme.icons },
            type === MobileSegmentType.Recommended && icons.recommendedIcon,
            type === MobileSegmentType.Selected && icons.selectedIcon),
        props.showAirplaneInfo && canceled && React.createElement("div", { className: theme.canceled__warning }, t('Canceled')),
        props.showAirplaneInfo && !canceled && (React.createElement("div", { className: theme.aircraft },
            React.createElement(AircraftPopup, { id: (_e = firstSegment.aircraft) === null || _e === void 0 ? void 0 : _e.id, operatingIata: firstSegment.operatingAirline.iata, name: (_f = firstSegment.aircraft) === null || _f === void 0 ? void 0 : _f.name, renderButton: renderAircraftButton }),
            React.createElement("div", { className: theme.aircraft__details },
                React.createElement("div", { className: theme.aircraft__name }, (_g = firstSegment.aircraft) === null || _g === void 0 ? void 0 : _g.name),
                ((_j = (_h = firstSegment.flightInfo) === null || _h === void 0 ? void 0 : _h.amenities) === null || _j === void 0 ? void 0 : _j.length) > 0 && (React.createElement(Amenities, { className: theme.aircraft__amenities, amenities: firstSegment.flightInfo.amenities }))),
            operatingCarrier && (React.createElement("div", { className: theme.aircraft__operatingCarrier },
                t('Operating carrier'),
                ": ",
                operatingCarrier)),
            (props.segments[0].transferDuration || ((_k = firstSegment.stops) === null || _k === void 0 ? void 0 : _k.length) > 0) && (React.createElement(React.Fragment, null,
                React.createElement(StopsInfo, { stopCityName: firstSegment.arrival.airport.city.name, stops: firstSegment.stops, transferDuration: props.segments[0].transferDuration, className: theme.stop }),
                React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.stopPoint, className: theme.stopPointInfoMessage, itemHtmlClassName: theme.stopPointInfoMessage__htmlMessage, messages: firstSegment.messages }))),
            props.totalDuration && React.createElement("div", { className: theme.totalDuration }, props.totalDuration)))));
};
export default MobileSegmentFlat;
