import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { format } from '../../../utils';
import { Money } from '../../../Money';
import AeroexpressPassenger from '../AeroexpressPassenger/AeroexpressPassenger';
import { isRoundTrip as getIsRoundTrip } from '../AeroexpressPopup/utils';
const AeroexpressSegment = ({ className, state, direction, trips, allPassengers, onChange }) => {
    const { AeroexpressSegment: css } = useTheme('CheckoutAdditionalService');
    const { t } = useTranslation('Checkout');
    const trip = trips[0];
    const isRoundTrip = getIsRoundTrip(trip);
    // TODO: uncomment this when php was ready
    // const passengerTypes = useMemo(() => {
    // 	const passengerTypesSet = new Set<string>();
    //
    // 	trips.forEach(trip => {
    // 		trip.fares?.forEach(fare => {
    // 			fare.passengers?.forEach(passenger => {
    // 				passengerTypesSet.add(passenger.type);
    // 			});
    // 		});
    // 	});
    //
    // 	return [...passengerTypesSet];
    // }, [trips]);
    const onChangeHandler = (trip) => onChange(direction, trip);
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.info },
                React.createElement("div", { className: css.date }, format(parse(trip.departureDate, 'HH:mm dd.MM.yyyy', new Date()), 'dd.MM.yyyy')),
                React.createElement("div", { className: css.direction },
                    [trip.fromStation.name, trip.toStation.name].filter(Boolean).join(' – '),
                    React.createElement("span", { className: css.type }, isRoundTrip ? t('And back') : t(direction)))),
            React.createElement("div", { className: css.prices }, trips.map(trip => (React.createElement("div", { key: trip.id, className: css.price },
                t(`Class_${trip.class}`),
                React.createElement(Money, { money: trip.price, moneyClassName: css.money })))))),
        React.createElement("div", { className: css.passengers },
            React.createElement(AeroexpressPassenger, { className: css.passenger, label: t('All passengers'), trips: trips, value: state[direction], onChange: onChangeHandler }))));
};
export default AeroexpressSegment;
