import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import cn from 'classnames';
import AdditionalServiceWrapper from '../../AdditionalServiceWrapper';
import InsuranceCard from './InsuranceCard/InsuranceCard';
import { useTheme } from '../../theme';
import { Money } from '../../Money';
import { MOBILE_MIN_WIDTH } from '../../utils';
import { Chevron } from '../../Icons';
import { useInsuranceState } from '../hooks';
import { analytics_addInsurance, analytics_removeInsurance } from '../../Insurance/utils';
import WebskyAnalyticsApollo from '../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { WxEvents } from '../../DataLayerAnalytics/types';
const InsurancePopup = ({ className, header, description, travellers, insurances, selectedInsurances, onClose, onSave, isLoading }) => {
    const { InsurancePopup: css } = useTheme('InsurancePopup');
    const { t } = useTranslation('Checkout');
    const [slider, setSlider] = useState();
    const { state, totalPrice, hasChanges, onToggle } = useInsuranceState(insurances, selectedInsurances);
    const oneInsurance = insurances.length === 1 ? insurances[0] : null;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const isSmall = !isMobile && insurances.length < 3;
    const sliderOptions = {
        className: cn(css.slider, {
            [css.small]: isSmall
        }),
        slidesToShow: isSmall ? insurances.length : 3.14,
        speed: 600,
        infinite: false,
        variableWidth: isSmall,
        arrows: false,
        responsive: [
            {
                breakpoint: MOBILE_MIN_WIDTH,
                settings: {
                    slidesToShow: 1.3
                }
            }
        ]
    };
    const getPolicyUrlByInsurance = React.useCallback((code) => {
        var _a;
        return (_a = selectedInsurances.find(selectedInsurance => selectedInsurance.insuranceProgram.code === code)) === null || _a === void 0 ? void 0 : _a.policyUrl;
    }, [selectedInsurances]);
    const onToggleHandler = (insurance) => () => {
        onToggle(insurance.code);
        const isSelected = selectedInsurances.find(selectedInsurance => { var _a; return ((_a = selectedInsurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.code) === insurance.code; });
        if (isSelected) {
            analytics_removeInsurance(insurance, travellers);
        }
        else {
            analytics_addInsurance(insurance, travellers);
        }
    };
    const onSaveHandler = () => {
        if (hasChanges) {
            onSave(state);
        }
        else {
            onClose();
        }
    };
    useEffect(() => {
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.InsurancesList, {
            servicesIds: oneInsurance ? [oneInsurance.code] : insurances.map(insurance => insurance.code)
        });
    }, []);
    return (React.createElement(AdditionalServiceWrapper, { className: className, headerClassName: css.header, textClassName: css.text, wrapperClassName: css.wrapper, contentClassName: css.content, footerClassName: css.footer, header: header, text: React.createElement("div", { dangerouslySetInnerHTML: { __html: description } }), onConfirm: onSaveHandler, onCancel: onClose, isLoading: isLoading },
        insurances.length > 3 && (React.createElement("div", { className: css.slider_buttons },
            React.createElement("div", { className: cn(css.slider_button, css.slider_button_prev), onClick: () => slider.slickPrev() }, Chevron),
            React.createElement("div", { className: css.slider_button, onClick: () => slider.slickNext() }, Chevron))),
        oneInsurance ? (React.createElement(InsuranceCard, { className: css.card, insurance: oneInsurance, policyUrl: getPolicyUrlByInsurance(oneInsurance.code), isSelected: state.includes(oneInsurance.code), onClick: onToggleHandler(oneInsurance) })) : (React.createElement(Slider, Object.assign({ ref: setSlider }, sliderOptions), insurances.map(insurance => (React.createElement(InsuranceCard, { insurance: insurance, policyUrl: getPolicyUrlByInsurance(insurance.code), isSelected: state.includes(insurance.code), onClick: onToggleHandler(insurance) }))))),
        totalPrice && (React.createElement("div", { className: css.total },
            t('Total'),
            ":",
            React.createElement(Money, { money: totalPrice })))));
};
export default InsurancePopup;
