import { __rest } from "tslib";
import * as React from 'react';
import Breadcrumbs from './Breadcrumbs';
import { useCallback } from 'react';
import { CloseClear } from '../../../Icons';
import { useTheme } from '../../../theme';
import { useConfig } from '../../../context';
const StepsBreadcrumbs = props => {
    const { onPassengerClick, onRefundInfoClick } = props, breadcrumbsOwn = __rest(props, ["onPassengerClick", "onRefundInfoClick"]);
    const { Breadcrumbs: css } = useTheme('Refund');
    const exareShowPassengersStep = useConfig().Engine.exareShowPassengersStep;
    const handlePassengersClick = useCallback(() => {
        if (props.onPassengerClick) {
            props.onPassengerClick();
        }
    }, [onPassengerClick]);
    const handleRefundInfoClick = useCallback(() => {
        if (props.onRefundInfoClick) {
            props.onRefundInfoClick();
        }
    }, [onRefundInfoClick]);
    const renderClickableArea = useCallback(type => {
        if (type === 'passenger' && onPassengerClick && exareShowPassengersStep) {
            return (React.createElement("div", { className: css.close, onClick: handlePassengersClick }, CloseClear));
        }
        if (type === 'refundInfo' && onRefundInfoClick) {
            return (React.createElement("div", { className: css.close, onClick: handleRefundInfoClick }, CloseClear));
        }
        return null;
    }, [props]);
    return React.createElement(Breadcrumbs, Object.assign({ renderAfter: renderClickableArea }, breadcrumbsOwn));
};
export default StepsBreadcrumbs;
