export const PASSENGER_FIRST_NAME = 'Alex';
export const PASSENGER_LAST_NAME = 'Brown';
export const PASSENGER_PASSPORT_NUMBER = '0123456789';
export const PASSENGER_RESERVATION_NUMBER = '2T7L5M';
export const PASSENGER_TICKET_NUMBER = '0123 4567 8901 2345';
export const PASSENGER_DATE_OF_SALE = '28 Sep. 2023';
export const PASSENGER_LOYALTY_CARD_NUMBER = '0123 4567 8901 2345';
export const AGENT_NAME = 'TKP';
export const AGENT_URL = 'www.airlines-site.com';
export const AGENT_PHONE_NUMBER = '+7 800 567 00 80';
export const AGENT_FORM_OF_PAYMENT = 'CCVIXXXXXXXXXXXX5574';
