import * as React from 'react';
import { useCallback } from 'react';
import Passenger from '../Passenger/Passenger';
import RefundPaper from '../RefundPaper/RefundPaper';
import { useTheme } from '../../../theme';
import { getSelectorsInfo, isAllPassengersSelector } from './utils';
const Passengers = props => {
    const { Passengers: css } = useTheme('Refund');
    const { isAllPassengersSelectorChecked, hasNotAllPassengersSelectorChecked } = getSelectorsInfo(props.passengers, props.travellersCount);
    const renderPassenger = useCallback((passenger) => {
        const disabled = isAllPassengersSelector(passenger, props.travellersCount)
            ? hasNotAllPassengersSelectorChecked
            : isAllPassengersSelectorChecked;
        return (React.createElement(Passenger, { cardProps: { className: css.passenger }, key: passenger.id, passenger: passenger, checked: passenger.selected, onPassengerSelect: props.onPassengerSelect, disabled: disabled }));
    }, [props.passengers]);
    return React.createElement(RefundPaper, { className: css.passengers }, props.passengers.map(renderPassenger));
};
export default Passengers;
