import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../../../theme';
import ConditionsModal from '../../../ConditionsModal/ConditionsModal';
const PassengersHeader = ({ className }) => {
    const { PassengersHeader: css } = useTheme('Checkin');
    const { t } = useTranslation('Checkin');
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.wrapper },
            React.createElement("div", { className: css.title }, t('Online check-in')),
            React.createElement(ConditionsModal, null))));
};
export default PassengersHeader;
