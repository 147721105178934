import { isDateFieldType, isNameFieldType } from '../PassengerForm/utils';
import { formatInvalidPassengerDate } from '../Passenger/utils';
export const getDifferentPassengerValues = (originalValues, changeValues) => {
    const originalValueMap = new Map(originalValues.map(value => [value.type, value.value]));
    const editableValueMap = new Map();
    changeValues.forEach(value => {
        if (!originalValueMap.has(value.type)) {
            return;
        }
        let originalValue = originalValueMap.get(value.type);
        let changeValue = value.value;
        if (isNameFieldType(value.type)) {
            if (!originalValue) {
                originalValue = '';
            }
            if (!changeValue) {
                changeValue = '';
            }
        }
        else if (isDateFieldType(value.type)) {
            originalValue = formatInvalidPassengerDate(value.type, originalValue);
        }
        if (originalValue !== changeValue) {
            editableValueMap.set(value.type, value);
        }
    });
    return editableValueMap;
};
