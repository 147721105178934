import * as React from 'react';
import { useTheme } from '../../../../../theme';
import { useConfig } from '../../../../../context';
const DummyWeekCalendar = () => {
    var _a;
    const { DummyWeekCalendar: css } = useTheme('Results');
    const calendarDaysCount = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.Engine.calendarDaysCount;
    return (React.createElement("div", { className: css.wrapper }, [...Array(calendarDaysCount).keys()].map(i => (React.createElement("div", { key: i, className: css.item },
        React.createElement("div", { className: css.item__head },
            React.createElement("span", null)),
        React.createElement("div", { className: css.item__middle },
            React.createElement("span", null)))))));
};
export default DummyWeekCalendar;
