import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import { addFlightIds, changeFlightId, removeFlightIds } from '../../../store/flightsToExchange/actions';
import { getOrder, getPassengers } from '../../../store/order/selectors';
import { getExchangeFlights, getFlightIdsToExchange, getIsExchangeFlightsSelected } from '../../../store/flightsToExchange/selectors';
import CommonSelect from '../CommonSelect/CommonSelect';
import { flightIcon } from '../icons';
import { ALL_FLIGHT } from '../../../store/flightsToExchange/reducers';
import { ExchangeStep } from '../../../types';
const Segments = ({ setStep, passengersPageURL }) => {
    const { t } = useTranslation('Exchange');
    const dispatch = useDispatch();
    const order = useSelector(getOrder);
    const passengers = useSelector(getPassengers);
    const selectedFlights = useSelector(getFlightIdsToExchange);
    const exchangeFlights = useSelector(getExchangeFlights);
    const isExchangeFlightsSelected = useSelector(getIsExchangeFlightsSelected);
    const history = useHistory();
    const onChange = React.useCallback((value, selected) => {
        var _a;
        const isAllFlightKey = value.key === ALL_FLIGHT;
        const isAllFlightFlights = selectedFlights.some(flight => flight.key === ALL_FLIGHT);
        if (isAllFlightKey) {
            if (selected) {
                dispatch(addFlightIds(exchangeFlights.map(flight => ({ key: flight.key }))));
            }
            else {
                dispatch(removeFlightIds(exchangeFlights.map(flight => flight.key)));
            }
        }
        else if (isAllFlightFlights) {
            if (value.date) {
                const firstFlight = (_a = selectedFlights.filter(flight => flight.date)) === null || _a === void 0 ? void 0 : _a[0];
                const flights = selectedFlights.map(flight => {
                    const isFirstFlight = flight.key === (firstFlight === null || firstFlight === void 0 ? void 0 : firstFlight.key);
                    if (flight.key === value.key) {
                        return Object.assign(Object.assign({}, flight), { date: value.date });
                    }
                    else if (flight.date && !isFirstFlight) {
                        return Object.assign(Object.assign({}, flight), { date: isAfter(value.date, flight.date) ? value.date : flight.date });
                    }
                    return flight;
                });
                dispatch(addFlightIds(flights));
            }
            else {
                const filterFlights = selectedFlights.filter(flight => flight.key !== value.key);
                if (selected) {
                    dispatch(addFlightIds(filterFlights));
                }
                else {
                    dispatch(addFlightIds([{ key: value.key }]));
                }
            }
        }
        else {
            if (value.date) {
                dispatch(changeFlightId(value));
            }
            else {
                if (selected) {
                    dispatch(addFlightIds([value]));
                }
                else {
                    dispatch(removeFlightIds([value.key]));
                }
            }
        }
    }, [selectedFlights, exchangeFlights]);
    const onNextHandler = () => {
        setStep(ExchangeStep.NewFlights);
    };
    const onBackHandler = () => {
        var _a;
        const isOnlyPassenger = passengers.length === 1;
        if (((_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange) && isOnlyPassenger) {
            setStep(ExchangeStep.Request);
        }
        else if (isOnlyPassenger) {
            history.push(passengersPageURL);
        }
        else {
            setStep(ExchangeStep.Passengers);
        }
    };
    return (React.createElement(CommonSelect, { icon: flightIcon, name: exchangeFlights.length > 1 ? t('Select flights') : t('Select a new date'), items: exchangeFlights, selectedKeys: selectedFlights, onChange: onChange, disableButton: !isExchangeFlightsSelected, onNextHandler: onNextHandler, onBackHandler: onBackHandler }));
};
export default Segments;
