import { __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../theme';
import CardHeader from '@material-ui/core/CardHeader';
const ModalHeading = (_a) => {
    var { heading, classes, headerClassName, headingClassName } = _a, props = __rest(_a, ["heading", "classes", "headerClassName", "headingClassName"]);
    const { ModalHeading: css } = useTheme();
    return (React.createElement(CardHeader, Object.assign({}, props, { classes: Object.assign(Object.assign({}, classes), { root: cn(css.header, headerClassName), title: cn(css.heading, headingClassName) }), title: React.createElement("span", null, heading) })));
};
export default ModalHeading;
