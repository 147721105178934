import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme } from '../../../theme';
import { useFileConverter } from './hooks';
import { useToggleable } from '../../../hooks';
import { AddCircle, Remove, Choosed } from '../../../Icons';
import Link from '../../../Link';
const AddFile = props => {
    const { t } = useTranslation('Refund');
    const { passenger } = props;
    const { isOpen: hasValue, close: setEmpty, open: setHasValue } = useToggleable(false);
    const { AddFile: css } = useTheme('Refund');
    const inputRef = useRef(null);
    const { toBase64 } = useFileConverter();
    const handleChange = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!inputRef.current) {
            return;
        }
        const { current: input } = inputRef;
        if (input.value) {
            try {
                const document = yield toBase64(input.files[0]);
                setHasValue();
                props.onLoad({
                    passengerId: passenger.id,
                    file: document
                });
            }
            catch (e) {
                input.value = null;
                if (props.onValidationError) {
                    props.onValidationError();
                }
            }
        }
    }), [props.onLoad, props.onClear, inputRef, hasValue]);
    const handleClear = useCallback((e) => {
        if (!inputRef.current || !hasValue) {
            return;
        }
        e.preventDefault();
        props.onClear(passenger.id);
        setEmpty();
    }, [hasValue, inputRef, props.onClear]);
    const handleOpenInput = useCallback(() => {
        if (!inputRef.current) {
            return;
        }
        inputRef.current.click();
    }, [inputRef, hasValue]);
    return (React.createElement(FormControlLabel, { className: cn(css.label, props.className), classes: { label: css.labelContent }, control: React.createElement("input", { ref: inputRef, className: css.input, onChange: handleChange, type: "file" }), label: React.createElement("span", { className: css.labelContent },
            React.createElement("span", { className: cn(css.icon, { [css.addIcon]: !hasValue, [css.addedIcon]: hasValue }) }, hasValue ? Choosed : AddCircle),
            React.createElement(Link, { action: handleOpenInput, className: css.link },
                props.passenger.name,
                ": ",
                t(props.documentType)),
            hasValue && (React.createElement("span", { className: css.removeIcon, onClick: handleClear }, Remove))) }));
};
export default AddFile;
