import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { planeIcon } from '../../../../BoardingPassDesk/Icons';
import { format } from '../../../../utils';
const SegmentCommon = ({ segment }) => {
    var _a, _b, _c, _d, _e;
    const { SegmentCommon: css } = useTheme('RetrieveBooking');
    const { t } = useTranslation('Segment');
    const currentDate = new Date();
    const fareFamily = segment.fareFamily;
    const currentSegment = segment.segments[0];
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: cn(css.header) },
            React.createElement("div", { className: css.flightInfo },
                React.createElement("div", { className: css.flightInfo__item },
                    React.createElement("span", { className: css.flightInfo__title }, t('Flight')),
                    React.createElement("span", { className: cn(css.flightInfo__value, css.flightInfo__valueBold) },
                        currentSegment.operatingAirline.iata,
                        " ",
                        currentSegment.flightNumber)),
                React.createElement("div", { className: css.flightInfo__item },
                    React.createElement("span", { className: css.flightInfo__title }, t('Departure')),
                    React.createElement("span", { className: css.flightInfo__value }, format(currentSegment.departure.date, `${new Date(currentSegment.departure.date).getFullYear() - currentDate.getFullYear() >
                        0
                        ? 'dd MMM yyyy'
                        : 'dd MMMM'}`)))),
            React.createElement("div", { className: css.cities },
                React.createElement("div", { className: css.city },
                    React.createElement("span", { className: css.city__airport }, currentSegment.departure.airport.name),
                    React.createElement("span", { className: css.city__info },
                        React.createElement("span", { className: css.city__iata }, currentSegment.departure.airport.iata),
                        React.createElement("span", { className: css.city__time }, currentSegment.departure.time.substr(0, 5)))),
                React.createElement("span", { className: css.plane__icon }, planeIcon),
                React.createElement("div", { className: css.city },
                    React.createElement("span", { className: css.city__airport }, currentSegment.arrival.airport.name),
                    React.createElement("span", { className: css.city__info },
                        React.createElement("span", { className: css.city__iata }, currentSegment.arrival.airport.iata),
                        React.createElement("span", { className: css.city__time }, currentSegment.arrival.time.substr(0, 5)))))),
        React.createElement("div", { className: css.footer },
            React.createElement("div", { className: css.footer__airline },
                React.createElement("span", null,
                    ((_a = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _a === void 0 ? void 0 : _a.icon) && (React.createElement("img", { className: css.airline__logo, alt: (_b = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _b === void 0 ? void 0 : _b.name, src: (_c = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _c === void 0 ? void 0 : _c.icon })),
                    React.createElement("span", { className: css.airline__name },
                        ((_d = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _d === void 0 ? void 0 : _d.name) && `${fareFamily.airline.name}, `, (_e = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.title) === null || _e === void 0 ? void 0 :
                        _e.toLowerCase()))),
            React.createElement("div", { className: css.footerItems },
                React.createElement("div", { className: css.footerItems__item },
                    React.createElement("span", { className: css.footerItems__title }, t('Terminal')),
                    React.createElement("span", { className: css.footerItems__value }, currentSegment.departure.terminal))))));
};
export default SegmentCommon;
