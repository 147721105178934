import { NASStatus } from '@websky/graphql';
import { useDispatch, useSelector } from 'react-redux';
import { getEveryPortSelectedServices, getEveryPortServicesByCategories } from '../../../Checkout/store/everyPortServices/selectors';
import { setEveryPortServiceAction } from '../../../Checkout/store/everyPortServices/actions';
import * as React from 'react';
import { getOrder } from '../../../Checkout/store/order/selectors';
import { getPassengerFirstName, getPassengerLastName } from '../../../Checkout/store/passengers/selectors';
import { findMinPrice } from './utils';
export const useEveryPort = (type, segmentId, readonly = false) => {
    var _a;
    const dispatch = useDispatch();
    const servicesMap = useSelector(getEveryPortServicesByCategories), services = servicesMap.get(type) || [];
    const selectedServicesByCategory = useSelector(getEveryPortSelectedServices);
    const selectedServices = (_a = selectedServicesByCategory === null || selectedServicesByCategory === void 0 ? void 0 : selectedServicesByCategory[type]) !== null && _a !== void 0 ? _a : [];
    const order = useSelector(getOrder);
    const onServiceSave = (payload) => {
        dispatch(setEveryPortServiceAction({
            category: type,
            services: [...payload]
        }));
    };
    const selectedSummary = React.useMemo(() => {
        const servicesTexts = [], selectedPassengerNames = [], totalPrice = {
            amount: 0,
            currency: null
        };
        let detailsLink = '';
        let rules = [];
        let modifyAvailable = !readonly;
        selectedServices.forEach(selectedService => {
            var _a;
            const service = services.find(service => service.id === selectedService.product.id);
            if (service && service.segmentId.toString() === segmentId) {
                servicesTexts.push({
                    name: service.name,
                    terminal: service.terminalName
                });
                if (selectedService.status !== NASStatus.New) {
                    modifyAvailable = false;
                }
                selectedService.travellerIds.forEach(travellerId => {
                    var _a, _b, _c, _d, _e;
                    const travellerFare = service.fares.find(fare => fare.id === selectedService.fare.id), travellerPrice = (_a = travellerFare.prices.find(price => price.traveller.id === travellerId)) === null || _a === void 0 ? void 0 : _a.price, traveller = order.travellers.find(traveller => traveller.id === travellerId), travellerStatus = (_c = (_b = selectedService.orders) === null || _b === void 0 ? void 0 : _b.find(order => order.travellers.id === travellerId)) === null || _c === void 0 ? void 0 : _c.status;
                    selectedPassengerNames.push({
                        name: `${(_d = getPassengerFirstName(traveller)) === null || _d === void 0 ? void 0 : _d.value} ${(_e = getPassengerLastName(traveller)) === null || _e === void 0 ? void 0 : _e.value}`,
                        status: travellerStatus
                    });
                    totalPrice.amount += travellerPrice === null || travellerPrice === void 0 ? void 0 : travellerPrice.amount;
                    totalPrice.currency = travellerPrice === null || travellerPrice === void 0 ? void 0 : travellerPrice.currency;
                });
            }
            if ((_a = service === null || service === void 0 ? void 0 : service.terms) === null || _a === void 0 ? void 0 : _a[0]) {
                detailsLink = service.terms[0].url;
                rules = [...service.terms];
            }
        });
        return {
            texts: servicesTexts,
            passengerNames: selectedPassengerNames,
            price: totalPrice,
            detailsLink,
            modifyAvailable,
            rules
        };
    }, [selectedServices, segmentId, order.status]);
    const availableSegments = React.useMemo(() => {
        return order.flight.segments
            .filter(segment => services.some(service => service.segmentId.toString() === segment.segment.id))
            .map(segment => segment.segment);
    }, [order, services]);
    const priceFrom = React.useMemo(() => {
        var _a;
        return services
            .filter(service => service.segmentId.toString() === segmentId)
            .reduce((minPrice, service) => {
            const minServicePrice = findMinPrice(service, order.travellers);
            if (minServicePrice.amount < minPrice.amount) {
                return minServicePrice;
            }
            return minPrice;
        }, (_a = services[0]) === null || _a === void 0 ? void 0 : _a.price);
    }, [services, segmentId]);
    return {
        services,
        onServiceSave,
        selectedSummary,
        availableSegments,
        selectedServices,
        priceFrom
    };
};
