import { __awaiter } from "tslib";
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApolloError } from 'apollo-boost';
import { useCheckinSaveOrderServicesMutation, useGetCheckinOrderQuery, useUpdateOrderMutation } from '@websky/graphql';
import { CartService } from '../../Cart/types';
import { getCheckinOrder, getHasRequestedOrderServices } from './store/order/selectors';
import { addService, removeService, saveServices } from './store/selectedServices/actions';
import { fillCheckinOrder, setCheckinOrder } from './store/order/actions';
import { useServiceRequest } from './utils';
import { CheckinStep } from './types';
import { useConditionalPolling } from '../../hooks';
export const useCheckinOrderServices = (onRefetchOrder, onRequestError, setStep) => {
    const dispatch = useDispatch();
    const order = useSelector(getCheckinOrder);
    const [saveOrderServices] = useCheckinSaveOrderServicesMutation();
    const { runServiceRequest } = useServiceRequest();
    const [isLoading, setIsLoading] = useState(false);
    const onRequest = useCallback((passengers) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            yield runServiceRequest(passengers);
            return onRefetchOrder();
        }
        catch (e) {
            const apolloError = e;
            if ((_a = apolloError === null || apolloError === void 0 ? void 0 : apolloError.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
                onRequestError(apolloError);
            }
            return null;
        }
    }), []);
    const onSaveServices = useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        try {
            const { data } = yield saveOrderServices({ variables: { params } });
            if (data.CheckinSaveOrderServices) {
                dispatch(setCheckinOrder(data.CheckinSaveOrderServices));
            }
            return data.CheckinSaveOrderServices;
        }
        catch (e) {
            const apolloError = e;
            if ((_b = apolloError === null || apolloError === void 0 ? void 0 : apolloError.graphQLErrors) === null || _b === void 0 ? void 0 : _b.length) {
                onRequestError(apolloError);
            }
            return null;
        }
    }), [dispatch]);
    const onSaveInsurance = useCallback((insuranceCode) => __awaiter(void 0, void 0, void 0, function* () {
        const insurances = order.additionalServices.insurances;
        const selectedInsurances = insurances === null || insurances === void 0 ? void 0 : insurances.selectedInsurances.filter(selected => selected.insuranceProgram.code !== insuranceCode).map(selected => selected.insuranceProgram);
        const selectedTravellers = (insurances === null || insurances === void 0 ? void 0 : insurances.travellerExtraValues) || [];
        const selectedPrograms = selectedInsurances.map(selected => selected.code);
        yield onSaveServices({
            id: order.id,
            insurances: [
                {
                    selectedPrograms,
                    selectedProgramsByTravellers: selectedTravellers.map(traveller => ({
                        travellerId: traveller.traveller.id,
                        programCodes: selectedPrograms,
                        travellerDocumentNumber: ''
                    }))
                }
            ]
        });
    }), [order]);
    const onSaveAeroexpress = useCallback((tripId) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d;
        const selectedTrips = ((_d = (_c = order.additionalServices.transfer) === null || _c === void 0 ? void 0 : _c.aeroexpress) === null || _d === void 0 ? void 0 : _d.selectedTrips) || [];
        yield onSaveServices({
            id: order.id,
            aeroexpress: {
                selectedTrips: selectedTrips.filter(trip => trip.id !== tripId).map(trip => trip.id)
            }
        });
    }), [order]);
    const onDeleteItem = useCallback((type, service) => __awaiter(void 0, void 0, void 0, function* () {
        var _e, _f;
        setIsLoading(true);
        const services = ((_f = (_e = order.additionalServices) === null || _e === void 0 ? void 0 : _e.gdsServices) === null || _f === void 0 ? void 0 : _f.services) || [];
        if ([CartService.Baggage, CartService.Meal, CartService.BusinessLounge].includes(type)) {
            const cartService = service;
            const gdsService = services.find(gdsService => gdsService.id === cartService.serviceId);
            const segmentIds = cartService.allSegments
                ? order.segments.map(segment => segment.id)
                : [cartService.segmentId];
            segmentIds.forEach(segmentId => {
                dispatch(removeService({
                    service: gdsService,
                    passengerId: cartService.passengerId,
                    segmentId
                }));
            });
            dispatch(saveServices());
        }
        else if (type === CartService.Seats) {
            const cartService = service;
            const saveTravellers = order.travellers.map(traveller => ({
                passengerId: traveller.id,
                seats: cartService.passengerId === traveller.id ? null : traveller.services.seats,
                setServices: null
            }));
            setStep(CheckinStep.Seats);
            yield onRequest(saveTravellers);
        }
        else if (type === CartService.Insurances) {
            const cartServiceInsurance = service;
            yield onSaveInsurance(cartServiceInsurance.code);
        }
        else if (type === CartService.Aeroexpress) {
            const cartServiceAeroexpress = service;
            yield onSaveAeroexpress(cartServiceAeroexpress.tripId);
        }
        setIsLoading(false);
    }), [order.additionalServices, dispatch]);
    return { isLoading, onDeleteItem };
};
export const useUpdateCheckinTravellerServices = () => {
    const dispatch = useDispatch();
    const order = useSelector(getCheckinOrder);
    const hasRequestedOrderServices = useSelector(getHasRequestedOrderServices);
    const [updateOrder] = useUpdateOrderMutation();
    const { refetch: fetchCheckinOrder } = useGetCheckinOrderQuery({ skip: true });
    const onUpdateOrder = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const updateOrderResponse = yield updateOrder({ variables: { id: order.id } });
        if ((_a = updateOrderResponse === null || updateOrderResponse === void 0 ? void 0 : updateOrderResponse.data) === null || _a === void 0 ? void 0 : _a.UpdateOrder) {
            const checkinOrderResponse = yield fetchCheckinOrder({ id: order.id });
            if ((_b = checkinOrderResponse === null || checkinOrderResponse === void 0 ? void 0 : checkinOrderResponse.data) === null || _b === void 0 ? void 0 : _b.CheckinOrder) {
                dispatch(fillCheckinOrder(checkinOrderResponse.data.CheckinOrder));
            }
        }
    }), [order === null || order === void 0 ? void 0 : order.id, updateOrder, fetchCheckinOrder]);
    useConditionalPolling(onUpdateOrder, hasRequestedOrderServices, {
        interval: 5000,
        progressiveIntervalPerQuery: true
    });
};
export function useOrderServices() {
    const dispatch = useDispatch();
    const order = useSelector(getCheckinOrder);
    const [saveOrderServices, saveOrderServicesResult] = useCheckinSaveOrderServicesMutation();
    const [requestError, setRequestError] = useState();
    // Effects
    useEffect(() => {
        var _a;
        if ((_a = saveOrderServicesResult === null || saveOrderServicesResult === void 0 ? void 0 : saveOrderServicesResult.data) === null || _a === void 0 ? void 0 : _a.CheckinSaveOrderServices) {
            dispatch(setCheckinOrder(saveOrderServicesResult.data.CheckinSaveOrderServices));
        }
    }, [saveOrderServicesResult === null || saveOrderServicesResult === void 0 ? void 0 : saveOrderServicesResult.data]);
    // Methods
    const onRequestError = useCallback((e) => {
        var _a;
        if (e instanceof ApolloError) {
            if ((_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
                setRequestError(e.graphQLErrors[0].message);
            }
        }
        else if (e instanceof Error) {
            setRequestError(e.message);
        }
        else {
            setRequestError('Unknown error');
        }
    }, [setRequestError]);
    const onClearError = useCallback(() => setRequestError(undefined), [setRequestError]);
    const onSaveServicesRequest = useCallback((params) => __awaiter(this, void 0, void 0, function* () {
        try {
            const { data } = yield saveOrderServices({ variables: { params } });
            return data === null || data === void 0 ? void 0 : data.CheckinSaveOrderServices;
        }
        catch (e) {
            onRequestError(e);
            return null;
        }
    }), [onRequestError]);
    const onAddServices = useCallback((services) => {
        services.forEach(service => {
            var _a, _b, _c;
            const relatedGdsService = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(gdsService => gdsService.id === service.serviceId);
            dispatch(addService({
                service: relatedGdsService,
                segmentId: service.segmentId,
                passengerId: service.passengerId
            }));
        });
        dispatch(saveServices());
    }, [dispatch, order.additionalServices]);
    const onRemoveServices = useCallback((services) => {
        services.forEach(service => {
            var _a, _b, _c;
            const relatedGdsService = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(gdsService => gdsService.id === service.serviceId);
            dispatch(removeService({
                service: relatedGdsService,
                segmentId: service.segmentId,
                passengerId: service.passengerId
            }));
        });
        dispatch(saveServices());
    }, [dispatch, order.additionalServices]);
    const onSaveServices = useCallback((services) => {
        const servicesToAdd = [];
        const servicesToRemove = [];
        services.forEach(service => {
            if (service.count > 0) {
                servicesToAdd.push(service);
            }
            else {
                servicesToRemove.push(service);
            }
        });
        if (servicesToAdd.length) {
            onAddServices(servicesToAdd);
        }
        if (servicesToRemove.length) {
            onRemoveServices(servicesToRemove);
        }
    }, [dispatch, onAddServices, onRemoveServices]);
    return {
        // data
        requestError,
        saveOrderServicesResult,
        isLoading: saveOrderServicesResult.loading,
        // methods
        saveOrderServices,
        onRequestError,
        onClearError,
        onSaveServicesRequest,
        onAddServices,
        onRemoveServices,
        onSaveServices
    };
}
