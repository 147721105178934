import * as React from 'react';
import StepbarRoot from '../../../../Stepbar/index';
import { useSelector } from 'react-redux';
import { getOrder } from '../../../store/order/selectors';
import Cart from '../../../../Cart/Cart';
import { Drawer } from '@material-ui/core';
import { useTheme } from '../../../../theme';
import { useCart } from './hooks';
import SimpleLoader from '../../../../SimpleLoader';
import { OrderStatus } from '@websky/graphql';
import StepbarPayload from './StepbarPayload/StepbarPayload';
import ErrorBoundary from '../../../../ErrorBoundary/ErrorBoundary';
import { useCartPrices } from '../../../../Cart/hooks';
const Stepbar = props => {
    var _a;
    const [isOpenCart, setOpenCart] = React.useState(false);
    const order = useSelector(getOrder);
    const cartPrices = useCartPrices();
    const openCart = () => setOpenCart(true);
    const closeCart = () => setOpenCart(false);
    const theme = useTheme('Checkout').Stepbar;
    const { onDeleteItem, loading } = useCart();
    const showCart = order.status !== OrderStatus.Cancelled;
    return (React.createElement(React.Fragment, null,
        React.createElement(StepbarRoot, { activeStep: props.activeStep, totalPrice: order.priceToPay.total, steps: order.status === OrderStatus.New || order.status === OrderStatus.Booked || props.alwaysShowSteps
                ? props.steps
                : {}, onCartClick: showCart && openCart, additional: React.createElement(StepbarPayload, { orderStatus: order.status, canAddServices: order.canAddServices, exchangeAvailable: (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.exchangeAvailability }), renderAdditionalStep: props.renderAdditionalStep }),
        showCart && (React.createElement(ErrorBoundary, { renderFallback: () => null },
            React.createElement(Drawer, { classes: { paper: theme.paper }, anchor: "right", open: isOpenCart, onClose: closeCart },
                loading && React.createElement(SimpleLoader, null),
                React.createElement(Cart, { flight: order.flight, travellers: order.travellers, customer: order.customer, services: order.additionalServices, prices: cartPrices, hideFlightPrice: props.hideFlightPrice, onClose: closeCart, onServiceDelete: onDeleteItem, showPassengers: order.status !== OrderStatus.New }))))));
};
export default Stepbar;
