import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import Switch from '../../../../../Switch';
import { useDispatch, useSelector } from 'react-redux';
import { isOfferHotels } from '../../../../store/offerHotels/selectors';
import { offerHotelsAction } from '../../../../store/offerHotels/actions';
const OfferHotels = props => {
    const { t } = useTranslation('SearchForm');
    const theme = useTheme('SearchForm').OfferHotels;
    const isOfferHotelsActive = useSelector(isOfferHotels), dispatch = useDispatch();
    const toggleOfferHotels = () => {
        dispatch(offerHotelsAction(!isOfferHotelsActive));
    };
    return (React.createElement(Switch, { mainClassName: theme.switchItem, isActive: isOfferHotelsActive, labelClassName: theme.toggle__label, label: t('Offer hotels'), activeClassName: theme.toggle_active, switchPosition: "right", onClick: toggleOfferHotels }));
};
export default OfferHotels;
