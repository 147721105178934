import { useCreateNewCompanionMutation, useSendPersonalDetailsMutation, useUpdateTravellerMutation } from '@websky/graphql';
export const useSubmitPersonalDetails = () => {
    const [sendPersonalDetails] = useSendPersonalDetailsMutation();
    const submit = (values) => {
        return sendPersonalDetails({
            variables: {
                params: Object.assign({}, values)
            }
        });
    };
    return {
        submit
    };
};
export const useCreateTraveller = () => {
    const [addNewCompanion] = useCreateNewCompanionMutation();
    const submit = (values) => {
        return addNewCompanion({
            variables: {
                params: {
                    middleName: values['middleName'],
                    citizenship: values['nationality'],
                    lastName: values['lastName'],
                    firstName: values['firstName'],
                    birthDate: values['birthDate'],
                    email: values['email'],
                    phone: values['phone'],
                    gender: values['gender'],
                    documents: values['documents'],
                    loyaltyCards: values['loyaltyCards']
                }
            }
        });
    };
    return {
        submit
    };
};
export const useUpdateTraveller = () => {
    const [updateTravellerProfile] = useUpdateTravellerMutation();
    const submit = (values, id) => {
        const { lastName, birthDate, citizenship, documents, email, firstName, gender, latinFirstName, latinLastName, latinMiddleName, loyaltyCards, middleName, phone } = values;
        return updateTravellerProfile({
            variables: {
                params: {
                    id: id,
                    newParameters: {
                        latinMiddleName,
                        latinFirstName,
                        lastName,
                        birthDate,
                        citizenship,
                        documents,
                        email,
                        firstName,
                        gender,
                        latinLastName,
                        loyaltyCards,
                        middleName,
                        phone
                    }
                }
            }
        });
    };
    return {
        submit
    };
};
