import * as React from 'react';
import { BaseButton, Money, SlideBottom } from '../../../../index';
import SeatInfo from '../../SeatsInfo/SeatInfo/SeatInfo';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { SeatsRecommendContext } from '../../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import { isFreeSeat } from '../../SeatMap/utils';
export const recommend = (React.createElement("svg", { width: "21", height: "20", viewBox: "0 0 21 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.9999 6.66683C18.9166 6.66683 19.6666 7.41683 19.6666 8.3335L19.6583 8.40016L19.6666 8.4085V10.0002C19.6666 10.2168 19.6249 10.4168 19.5499 10.6085L17.0333 16.4835C16.7833 17.0835 16.1916 17.5002 15.4999 17.5002H7.99992C7.08325 17.5002 6.33325 16.7502 6.33325 15.8335V7.50016C6.33325 7.04183 6.51658 6.62516 6.82492 6.32516L12.3083 0.833496L13.1916 1.7085C13.4166 1.9335 13.5583 2.25016 13.5583 2.59183L13.5333 2.8585L12.7416 6.66683H17.9999ZM4.66658 17.5002H1.33325V7.50016H4.66658V17.5002Z", fill: "currentColor" })));
const SeatsRecommendPopup = props => {
    var _a, _b, _c;
    const theme = useTheme('SeatMap').SeatsRecommendPopup;
    const { t } = useTranslation('SeatMap');
    const seatsMapRecommendContext = useContext(SeatsRecommendContext);
    const activeSeat = React.useMemo(() => {
        var _a, _b, _c;
        const seat = (_b = (_a = seatsMapRecommendContext.rowsMap) === null || _a === void 0 ? void 0 : _a[props.segmentId.toString()]) === null || _b === void 0 ? void 0 : _b.seats[props.passengerId];
        if (!seat) {
            return null;
        }
        const serviceIds = Object.keys(((_c = props.services[props.segmentId]) === null || _c === void 0 ? void 0 : _c[props.passengerId]) || {});
        const serviceId = serviceIds.find(serviceId => {
            const service = props.services[props.segmentId][props.passengerId][serviceId];
            return service.rfisc === seat.seat.rfisc;
        });
        return {
            seatService: serviceIds.length ? props.services[props.segmentId][props.passengerId][serviceId] : null,
            seat: seat.seat
        };
    }, [props.passengerId, props.segmentId]);
    const totalPrice = (_b = (_a = seatsMapRecommendContext.rowsMap) === null || _a === void 0 ? void 0 : _a[props.segmentId.toString()]) === null || _b === void 0 ? void 0 : _b.price;
    return seatsMapRecommendContext.isEnabled && activeSeat ? (React.createElement(React.Fragment, null,
        React.createElement(SlideBottom, { isOpen: seatsMapRecommendContext.isOpen, onClose: seatsMapRecommendContext.onDisagree, className: theme.slide, overlayClassName: theme.slide__wrapper },
            React.createElement("div", { className: theme.content },
                React.createElement("div", { className: theme.header },
                    React.createElement("div", { className: theme.main },
                        React.createElement("div", { className: theme.main__icon }, recommend),
                        t('Recommended seats')),
                    React.createElement("div", { className: theme.price__wrapper },
                        t('Total'),
                        ":",
                        React.createElement(Money, { money: totalPrice, moneyClassName: theme.money }))),
                props.passengers.length > 1 && (React.createElement("div", { className: theme.controls }, props.passengers.map((passenger, index) => {
                    var _a, _b, _c, _d;
                    const passengerSeat = (_d = (_c = (_b = (_a = seatsMapRecommendContext.rowsMap) === null || _a === void 0 ? void 0 : _a[props.segmentId.toString()]) === null || _b === void 0 ? void 0 : _b.seats) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.seat;
                    return (React.createElement("div", { className: cn(theme.control, {
                            [theme.control_selected]: passenger.id === props.passengerId,
                            [theme.control_free]: isFreeSeat(Object.assign(Object.assign({}, passengerSeat), { isAvailable: true, isExistent: true }))
                        }), key: index, onClick: () => props.setPassenger(index) }, index + 1));
                }))),
                activeSeat.seatService && (React.createElement("div", null,
                    React.createElement(SeatInfo, { seatService: activeSeat.seatService, preselectedSeat: activeSeat.seat, fullInfo: activeSeat.seat.price.amount !== 0 && !!activeSeat.seat.rfisc, isFreeSeat: activeSeat.seat.price.amount === 0 && !activeSeat.seat.rfisc, seatPrice: (_c = activeSeat.seat) === null || _c === void 0 ? void 0 : _c.price })))),
            React.createElement("div", { className: theme.actions },
                React.createElement(BaseButton, { variant: "smallSecondary", className: theme.cancel, onClick: seatsMapRecommendContext.onDisagree }, t('Choose myself')),
                React.createElement(BaseButton, { variant: "smallPrimary", onClick: seatsMapRecommendContext.onConfirm, className: theme.confirm }, t('Confirm')))))) : null;
};
export default SeatsRecommendPopup;
