import { createSelector } from 'reselect';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { getTotalPrice } from '../../../utils';
import { joinServicesByRfics } from '../utils';
const getState = (state) => state;
export const getServices = createSelector(getState, state => state.services);
export const getSelectedServices = createSelector(getState, state => state.selectedServices);
export const getPassengers = createSelector(getState, state => state.passengers);
export const getSegmentIds = createSelector(getState, state => state.segmentIds);
export const getAvailableSegmentIds = createSelector(getServices, baggage => {
    const availableSegmentIdsSet = new Set();
    baggage.forEach(baggage => {
        baggage.segmentIds.forEach(segmentIds => {
            segmentIds.forEach(segmentId => availableSegmentIdsSet.add(segmentId));
        });
    });
    return [...availableSegmentIdsSet];
});
export const createGetSelected = (segmentId, travellerId, servicesIds) => {
    return createSelector(getSelectedServices, items => {
        return items.filter(el => {
            return (el.passengerId === travellerId &&
                servicesIds.includes(el.serviceId) &&
                (!segmentId || el.segmentIds.includes(segmentId)));
        });
    });
};
export const getSelectedBaggage = createSelector([
    (state) => state,
    (_, segmentId, travellerId, servicesIds) => ({
        segmentId,
        travellerId,
        servicesIds
    })
], (state, params) => state.selectedServices.filter(el => {
    return (el.passengerId === params.travellerId &&
        params.servicesIds.includes(el.serviceId) &&
        (!params.segmentId || el.segmentIds.includes(params.segmentId)));
}));
export const getIsAllServicesCanAddedToEntireFlight = createSelector([getServices, getSegmentIds], (services, segmentIds) => {
    const groupedServices = joinServicesByRfics(services.filter(service => service.canBeAdded));
    if (!groupedServices.length) {
        return false;
    }
    return groupedServices.every(servicesWithSameRfisc => {
        const availableSegmentsSet = new Set();
        servicesWithSameRfisc.forEach(service => service.segmentIds.forEach(segmentGroup => segmentGroup.forEach(segmentId => availableSegmentsSet.add(segmentId))));
        return availableSegmentsSet.size === segmentIds.length;
    });
});
export const getDifferentForReturnFlight = createSelector([getState, getIsAllServicesCanAddedToEntireFlight], (state, isAllServicesCanAddForWholeFlight) => {
    if (isAllServicesCanAddForWholeFlight) {
        return state.differentForReturnFlight;
    }
    return true;
});
export const getPrice = createSelector(getServices, getSelectedServices, (services, items) => {
    if (items.every(item => !item.count)) {
        return null;
    }
    return getTotalPrice(items, item => {
        const serviceItem = services.find(service => item.serviceId === service.id);
        if (serviceItem) {
            return Object.assign(Object.assign({}, serviceItem.price), { amount: serviceItem.price.amount * item.count * item.segmentIds.length });
        }
        return null;
    });
});
export const getDisabled = createSelector(getState, state => state.disabled);
export const getMaxBaggageCount = createSelector(getState, state => state.maxBaggageCount);
export const getMaxBaggagePerPassengerCount = createSelector(getState, state => state.maxBaggagePerPassengerCount);
export const getSelectedServicesByPassengersMap = createSelector(getState, state => {
    const passengerMap = new Map();
    state.selectedServices.forEach(service => {
        if (passengerMap.has(service.passengerId)) {
            passengerMap.get(service.passengerId).push(service);
        }
        else {
            passengerMap.set(service.passengerId, [service]);
        }
    });
    return passengerMap;
});
export const getBaggageTab = createSelector(getState, state => state.baggageTab);
export const getPassenger = createSelector((state) => state.passengers, (_, passengerId) => passengerId, (passengers, passengerId) => passengers.find(p => p.id === passengerId));
export const getSelectedServiceToSave = (upgradableBaggage) => createSelector(getSelectedServices, selectedServices => {
    const servicesToSave = [];
    selectedServices.forEach(service => {
        const existsService = upgradableBaggage.find(baggage => service.serviceId === baggage.id);
        if (existsService) {
            const selectedService = {
                id: existsService.id,
                type: OrderAdditionalServiceGdsServiceServiceType.Baggage,
                price: existsService.price,
                confirmedCount: 0,
                name: existsService.name
            };
            servicesToSave.push({
                passengerId: service.passengerId,
                count: service.count,
                serviceId: service.serviceId,
                service: selectedService,
                segmentId: service.segmentIds[0],
                allowedSegments: [service.segmentIds]
            });
        }
    });
    return servicesToSave;
});
