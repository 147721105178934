import { all, getContext, select, takeLatest } from 'redux-saga/effects';
import { BAGGAGE_SAVE_SERVICES, SET_SERVICES } from './actions';
import { getSelectedServiceToSave } from './selector';
import { CHECKOUT_EXTERNAL_ACTION } from '../../../Checkout/store/selectedServices/sagas';
import { CHECKIN_EXTERNAL_ACTION } from '../../../Modules/Checkin/store/sagas';
import { setMultipleSelectedServices } from '../../../Checkout/store/selectedServices/actions';
import { setServicesWithSave } from '../../../Modules/Checkin/store/selectedServices/actions';
function* saveServicesWorker({ meta }) {
    const upgradableBaggage = yield getContext('upgradableBaggage');
    const selectedServices = yield select(getSelectedServiceToSave(upgradableBaggage));
    // Checkout actions
    window.dispatchEvent(new CustomEvent(CHECKOUT_EXTERNAL_ACTION, {
        detail: setMultipleSelectedServices(selectedServices, meta)
    }));
    // Checkin actions
    window.dispatchEvent(new CustomEvent(CHECKIN_EXTERNAL_ACTION, {
        detail: setServicesWithSave(selectedServices)
    }));
}
function* saveServicesSaga() {
    var _a;
    const config = yield getContext('config');
    const actions = [BAGGAGE_SAVE_SERVICES];
    if ((_a = config.global) === null || _a === void 0 ? void 0 : _a.dynamicBaggagePricing) {
        actions.push(SET_SERVICES);
    }
    yield takeLatest(actions, saveServicesWorker);
}
export default function* rootSaga() {
    yield all([saveServicesSaga()]);
}
