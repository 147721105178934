import * as React from 'react';
import { useEffect } from 'react';
import { useTheme } from '../../../../../../../theme';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import { AdditionalTravellerFieldEnum } from '../../../../../../../Passenger/types';
const Checkbox = props => {
    const theme = useTheme('PassengerForm').Accompanying;
    const { t } = useTranslation('Passenger');
    const checkboxField = props.accompanyingFields.find(field => field.type === AdditionalTravellerFieldEnum.AccompanyingCheck);
    const { input, meta } = useField(`customer.${checkboxField.name}`, {
        type: 'checkbox',
        initialValue: checkboxField.defaultValue,
        validate: value => {
            if (!value) {
                return t('Required');
            }
            return undefined;
        }
    });
    useEffect(() => {
        input.onBlur();
    }, [input.value]);
    return (React.createElement("div", { className: theme.checkbox },
        React.createElement(FormControlLabel, { control: React.createElement(MuiCheckbox, { color: 'primary', classes: {
                    root: theme.formControl__checkbox,
                    checked: theme.checkbox__checked
                }, checked: input.checked, inputProps: Object.assign({}, input) }), classes: {
                label: theme.formControl__label
            }, label: React.createElement("div", { dangerouslySetInnerHTML: {
                    __html: t('I confirm that the provided ticket number and the name of the accompanying passenger are correct and valid.')
                } }) }),
        React.createElement("div", { className: theme.error }, meta.submitFailed && meta.error)));
};
export default Checkbox;
