import * as React from 'react';
import cn from 'classnames';
import { CheckoutAdditionalService } from '../../../index';
import TransferSelectorModal from '../TransferSelectorModal/TransferSelectorModal';
import { useTransferSelector } from './model';
import TransferServiceSummary from '../TransferServiceSummary/TransferServiceSummary';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
const TransferSelector = ({ service, servicesState, isOpen, onClose, onOpen, segments, passengers, onChange, isSelected }) => {
    const { t } = useTranslation('Transfer');
    const { TransferSelector: theme } = useTheme('Transfer');
    const model = useTransferSelector(service, servicesState);
    return (React.createElement("div", null,
        React.createElement(CheckoutAdditionalService, { headerPriceClassName: theme.headerPrice, headerClassName: theme.header, headerTextClassName: theme.headerText, descriptionClassName: theme.description, dataRfisc: service.rfisc, buttonsClassName: theme.buttons, className: cn(theme.serviceCard, {
                [theme.active]: isSelected
            }), isSelected: isSelected, priceFrom: model.minPrice, hideFrom: true, priceTotal: model.totalPrice, header: service.name, description: service.description, addClick: () => onOpen(service.id), buttons: [
                {
                    text: t('Edit'),
                    action: () => onOpen(service.id)
                }
            ] },
            React.createElement(TransferServiceSummary, { service: service, segments: segments, servicesState: servicesState, passengers: passengers })),
        React.createElement(TransferSelectorModal, { service: service, servicesState: model.state, onChange: model.toggleService, passengers: passengers, segments: segments, isOpen: isOpen, totalPrice: model.totalPrice, onSubmit: () => {
                onChange(model.state); // сохраняем родительский стейт (дергаем фактическое добавление )
                onClose();
            }, onClose: () => {
                model.restoreState(); // сбрасываем локальный стейт
                onClose();
            }, onClear: () => {
                model.removeTransfer(); // Сбросить текущий трансфер
            } })));
};
export default TransferSelector;
