import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { planeIcon } from '../../../../BoardingPassDesk/Icons';
import { format } from '../../../../utils';
const SegmentCompact = ({ segment }) => {
    var _a, _b, _c;
    const { SegmentCompact: css } = useTheme('RetrieveBooking');
    const { t } = useTranslation('Segment');
    const fareFamily = segment.fareFamily;
    const firstSegment = segment.segments[0];
    const lastSegment = segment.segments[segment.segments.length - 1];
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.segmentInfo },
                React.createElement("div", { className: css.planeIcon }, planeIcon),
                React.createElement("div", null,
                    React.createElement("span", { className: css.segmentInfo__item },
                        firstSegment.departure.airport.city.name,
                        " \u2013 ",
                        lastSegment.arrival.airport.city.name),
                    React.createElement("span", { className: css.segmentInfo__item }, format(firstSegment.departure.date, 'dd MMM, yyyy')),
                    React.createElement("span", { className: css.segmentInfo__item },
                        firstSegment.departure.time.substr(0, 5),
                        " - ",
                        lastSegment.arrival.time.substr(0, 5),
                        ' '))),
            React.createElement("div", { className: css.fareFamily },
                ((_a = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _a === void 0 ? void 0 : _a.icon) && (React.createElement("div", { className: css.fareFamily__img, style: {
                        backgroundImage: `url(${fareFamily.airline.icon})`
                    } })),
                React.createElement("span", null,
                    ((_b = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _b === void 0 ? void 0 : _b.name) && `${fareFamily.airline.name}, `, (_c = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.title) === null || _c === void 0 ? void 0 :
                    _c.toLowerCase()))),
        React.createElement("div", { className: css.footer },
            React.createElement("div", { className: css.flightInfo },
                React.createElement("span", { className: css.flightInfo__item },
                    firstSegment.operatingAirline.iata,
                    " ",
                    firstSegment.flightNumber),
                firstSegment.departure.terminal && (React.createElement("span", { className: css.flightInfo__item },
                    t('Terminal'),
                    ": ",
                    firstSegment.departure.terminal))))));
};
export default SegmentCompact;
