import { __rest } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import OrderRefundStatus from '../../../../Refund/Components/OrderRefundStatus/OrderRefundStatus';
import Button from '../../../../Button';
import { useTheme } from '../../../../theme';
import { Chevron } from '../../../../Icons';
const ParentOrderStatus = (_a) => {
    var { parentOrderId } = _a, props = __rest(_a, ["parentOrderId"]);
    const { t } = useTranslation('Checkout');
    const { ParentOrderStatus: theme } = useTheme('Checkout');
    const history = useHistory();
    const renderTitle = useCallback(() => (React.createElement("span", null,
        t('Order created from'),
        " ",
        parentOrderId)), [parentOrderId]);
    const handleGoToOldBooking = useCallback(() => {
        history.push(`/booking/${parentOrderId}`);
        window.location.reload();
    }, [parentOrderId]);
    const renderStatus = useCallback(() => (React.createElement(Button, { onClick: handleGoToOldBooking },
        t('View old booking'),
        "\u00A0",
        parentOrderId,
        React.createElement("span", { className: theme.icon }, Chevron))), [parentOrderId]);
    return (React.createElement(OrderRefundStatus, Object.assign({}, props, { className: theme.container, status: null, renderTitle: renderTitle, renderStatus: renderStatus })));
};
export default ParentOrderStatus;
