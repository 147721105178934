import i18n from 'i18next';
import { format, isAfter, isBefore, isValid, parse, parseISO } from 'date-fns';
import { AdditionalTravellerFieldEnum, InputType } from './types';
import { API_DATE_FORMAT, DATE_REGEXP, decapitalizeFirstLetter, getPassengerTypeLabel } from '../utils';
import { TravellerFields } from '../TravellerForm/types';
import { TravellerFieldEnum } from '@websky/graphql';
import { clearPhoneNumber } from '../PhoneInput/utils';
import { levenshtein } from '../levenshtein';
export const fieldsArrayToMap = (fields) => {
    const map = {};
    fields.map(field => {
        if (!map.hasOwnProperty(field.name)) {
            map[field.name] = field;
        }
    });
    return map;
};
export const hideText = (text, divider = 1.5) => {
    if (!text) {
        return '';
    }
    const numOfVisibleChars = Math.floor(text.length / divider);
    const numOfHiddenChars = text.length - numOfVisibleChars;
    return text.split('').fill('*', 0, numOfHiddenChars).join('');
};
export const getAdditionalLabel = (values, fieldsMap, passengerType, supplierType) => {
    const labels = [];
    let personalInfo = '';
    if (values[TravellerFields.FirstName]) {
        personalInfo = values[TravellerFields.FirstName];
        if (values[TravellerFields.LastName]) {
            personalInfo += ` ${values[TravellerFields.LastName]}`;
        }
    }
    else {
        personalInfo = getPassengerTypeLabel(passengerType, supplierType);
    }
    if (values[TravellerFields.BirthDate]) {
        personalInfo += `, ${values[TravellerFields.BirthDate]}`;
    }
    labels.push(personalInfo);
    if (values.hasOwnProperty('loyaltyName') && fieldsMap['loyaltyName'].options) {
        const option = fieldsMap['loyaltyName'].validations[0].options.find(option => option.value === values['loyaltyName']);
        if (option) {
            labels.push(option.value);
        }
    }
    if (values.hasOwnProperty('nationality') && fieldsMap['nationality'].validations[0].options) {
        const option = fieldsMap['nationality'].validations[0].options.find(option => option.value === values['nationality']);
        if (option) {
            labels.push(option.label);
        }
    }
    if (values.hasOwnProperty('docType') &&
        values.hasOwnProperty('docNumber') &&
        fieldsMap['docType'].validations[0].options) {
        fieldsMap['docType'].validations.forEach(validation => {
            const option = validation.options.find(option => option.value === values['docType']);
            if (option) {
                labels.push(i18n.t(`Passenger:${option.label}`));
                labels.push(hideText(values['docNumber']));
            }
        });
    }
    return labels.join(' - ');
};
export const formatInvalidPassengerDate = (fieldType, value) => {
    if (fieldType === TravellerFieldEnum.BirthDate || fieldType === TravellerFieldEnum.DocExpiryDate) {
        const parsedDate = parse(value, API_DATE_FORMAT, new Date());
        return isValid(parsedDate) ? format(parsedDate, 'dd.MM.yyyy') : value;
    }
    return value;
};
export const parseDate = (date) => {
    const isInvalidDateFormat = date.match(DATE_REGEXP);
    const validDate = isInvalidDateFormat ? date.split('-').reverse().join('.') : date;
    return parse(validDate, 'dd.MM.yyyy', new Date());
};
export const validateDate = (value, rule) => {
    const date = parseDate(value);
    if (!isValid(date) || value.length !== 'dd.MM.yyyy'.length) {
        return `${i18n.t('Enter date in dd.mm.yyyy format')}`;
    }
    if (rule.maxDate && isAfter(date, parseISO(rule.maxDate))) {
        return `${i18n.t('Invalid date format')}`;
    }
    if (rule.minDate && isBefore(date, parseISO(rule.minDate))) {
        return `${i18n.t('Invalid date format')}`;
    }
};
/**
 * Finds acceptable validation rules based on all values on the form object.
 * Acceptable is a rule where all dependencies in `with` property are
 * contains in allValues and all values is equals.
 *
 * @param allValues
 * @param validations
 */
export const findAcceptableRules = (allValues, validations) => {
    let rules = [];
    validations.forEach(validation => {
        if (!validation.with) {
            rules.push(validation);
        }
        else if (validation.with.every(({ value, name }) => {
            var _a;
            const valueRegex = new RegExp(`^${value}$`);
            const valueByName = (_a = allValues[decapitalizeFirstLetter(name)]) !== null && _a !== void 0 ? _a : '';
            return valueRegex.test(valueByName);
        })) {
            rules = setValidation(rules, validation);
        }
    });
    return rules;
};
/**
 * In cases when allRules array contains a rule where `with` fields is greater than new `rule.with`, a new rule will be ignored.
 * Likewise, when `rule.with` fields is greater than some item in `allRules` array, old rule will be replaced with a `rule`.
 *
 * A is greater than B, when  A.length > B.length and A contains all items from B.
 * @param allRules validation rules array
 * @param newRule new rule to add
 */
const setValidation = (allRules, newRule) => {
    const newDependencies = newRule.with.map(option => option.name);
    let shouldToAdd = true;
    const rules = allRules.filter(_rule => {
        if (!_rule.with) {
            return true;
        }
        // Finding a rule is greater than a new
        if (_rule.with.length > newDependencies.length &&
            newDependencies.every(optionName => _rule.with.some(option => option.name === optionName))) {
            // Found a greater rule, ignore a new
            shouldToAdd = false;
        }
        if (newDependencies.length > _rule.with.length &&
            _rule.with.every(option => newDependencies.includes(option.name))) {
            // A new rule is greater than in array. Remove old rule
            return false;
        }
        return true;
    });
    if (shouldToAdd) {
        return [...rules, newRule];
    }
    return allRules;
};
export const isChangeValidationRule = (rule) => {
    return rule.maxEditDistance !== undefined;
};
export const validate = (value, allValues, inputType, validations = [], fieldType, defaultValue) => {
    var _a, _b;
    if (validations && validations.length) {
        const rules = findAcceptableRules(allValues, validations);
        for (const rule of rules) {
            const isEmptyString = typeof value === 'string' && !((_a = value === null || value === void 0 ? void 0 : value.trim()) === null || _a === void 0 ? void 0 : _a.length);
            const isEmptyObject = typeof value === 'object' && !((_b = value === null || value === void 0 ? void 0 : value.value.trim()) === null || _b === void 0 ? void 0 : _b.length);
            const isFalsy = !value;
            // Check if field is required.
            if (rule.required && (isEmptyString || isEmptyObject || isFalsy)) {
                return 'Required';
            }
            if (value && typeof value === 'string') {
                // Check that date is valid.
                if (inputType === InputType.Date) {
                    const error = validateDate(value, rule);
                    if (error) {
                        return error;
                    }
                }
                // Levenshtein distance check
                if (isChangeValidationRule(rule) && rule.maxEditDistance !== null && defaultValue) {
                    const distance = levenshtein(defaultValue, value);
                    if (distance > rule.maxEditDistance) {
                        return 'Exceeded the number of editable characters';
                    }
                }
                if (fieldType === AdditionalTravellerFieldEnum.OrganizationName ||
                    fieldType === AdditionalTravellerFieldEnum.PersonnelNumber) {
                    if (allValues['organizationName'] === allValues['personnelNumber']) {
                        return 'Check if the input is correct';
                    }
                }
                if (rule.regExp) {
                    const cleanValue = inputType === InputType.Phone ? clearPhoneNumber(value) : value;
                    const failedRule = rule.regExp.find(({ pattern }) => !new RegExp(pattern).test(cleanValue));
                    if (failedRule) {
                        return inputType === InputType.Phone ? 'Incorrect phone number' : failedRule.error;
                    }
                }
            }
        }
    }
};
export const fieldIsRequired = (allValues, validations = []) => {
    if (validations === null || validations === void 0 ? void 0 : validations.length) {
        for (const rule of validations) {
            if ((!rule.with ||
                rule.with.every(({ name, value }) => new RegExp(`^${value}$`).test(allValues[decapitalizeFirstLetter(name)]))) &&
                rule.required) {
                return true;
            }
        }
    }
    return false;
};
export const getRegExpMaxLength = (regexStr) => {
    var _a, _b;
    const maxRegex = /{\d+(,\d+)?}/g;
    return ((_b = (_a = regexStr.match(maxRegex)) === null || _a === void 0 ? void 0 : _a.reduce((total, match) => {
        var _a;
        const numbers = (_a = match.replace(/([{}])/g, '').split(',')) !== null && _a !== void 0 ? _a : [];
        const maxNumber = Number(numbers[numbers.length - 1]);
        return total + (!isNaN(maxNumber) ? maxNumber : 0);
    }, 0)) !== null && _b !== void 0 ? _b : null);
};
export const validateDocNumberLength = (value, allValues, validations = []) => {
    var _a, _b, _c, _d;
    if (!validations.length) {
        return true;
    }
    const rulePattern = (_d = (_c = (_b = (_a = findAcceptableRules(allValues, validations)) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.regExp) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.pattern;
    if (!rulePattern) {
        return true;
    }
    const regExpMaxLength = getRegExpMaxLength(rulePattern);
    if (!regExpMaxLength) {
        return true;
    }
    return value.length <= regExpMaxLength;
};
