import * as React from 'react';
import { useSelector } from 'react-redux';
import { getOrderStatus } from '../../../store/order/selectors';
const OrderStatusGuard = ({ statuses, children }) => {
    const status = useSelector(getOrderStatus);
    if (!statuses.includes(status)) {
        return null;
    }
    return React.createElement(React.Fragment, null, children);
};
export default OrderStatusGuard;
