import * as React from 'react';
import CheckoutAdditionalService from '../../../../CheckoutAdditionalService';
import { useTranslation } from 'react-i18next';
import CommonContent from '../CommonContent';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
const Meal = props => {
    const { t } = useTranslation('Checkout');
    const theme = useTheme('RefundSummary').CommonContent;
    return (React.createElement(CheckoutAdditionalService, { header: t('Meal'), description: t('Feel the taste in flight!'), className: cn(theme.serviceCard, theme.serviceCard_meal), isSelected: true, addClick: () => null },
        React.createElement(CommonContent, Object.assign({}, props))));
};
export default Meal;
