import { __awaiter } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import ModalConfirm from '../../../ModalConfirm';
const CancelModal = ({ passengerName, linkedPassengerNames, isOpen, onClose, onConfirm, onCancel }) => {
    const { t } = useTranslation('RegisteredPassenger');
    const { CancelModal: theme } = useTheme('RegisteredPassenger');
    const [isLoading, setIsLoading] = useState(false);
    const onConfirmHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield onConfirm();
        setIsLoading(false);
    });
    return (React.createElement(ModalConfirm, { isOpen: isOpen, isLoading: isLoading, onClose: onClose, onConfirm: onConfirmHandler, onCancel: onCancel, title: t('You are going to cancel online check-in for passenger {{passenger}}. Are you sure about this?', {
            passenger: passengerName
        }), classes: { title: theme.title } },
        React.createElement("div", { className: theme.content }, (linkedPassengerNames === null || linkedPassengerNames === void 0 ? void 0 : linkedPassengerNames.length) > 0 && (React.createElement("div", { className: cn(theme.text, theme.linked) },
            t('Please notice that online check-in will also be cancelled for the following passengers'),
            React.createElement("ul", null, linkedPassengerNames.map(name => (React.createElement("li", { key: name }, name === null || name === void 0 ? void 0 : name.toLowerCase())))))))));
};
export default CancelModal;
