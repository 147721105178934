import * as React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useIsMobile } from '../../../../utils';
import { getBaggageTab } from '../../store/selector';
import PassengerBaggageControl from './PassengerBaggageControl/PassengerBaggageControl';
const BaggagePassenger = props => {
    const theme = useTheme('UpgradeBaggageDialog').BaggagePassenger;
    const baggageTab = useSelector(getBaggageTab);
    const isMobile = useIsMobile();
    const isUnavailable = props.isAvailable === false;
    const services = React.useMemo(() => {
        if (isMobile && !baggageTab) {
            return [props.baggageByRfics.find(service => service[0].rfics === props.mobileSelectedServiceId)];
        }
        return props.baggageByRfics;
    }, [props.baggageByRfics, props.mobileSelectedServiceId, isMobile, baggageTab]);
    return (React.createElement("div", { className: cn(theme.root, { [theme.disabled]: isUnavailable }) },
        React.createElement("div", { className: cn(theme.row) },
            React.createElement("div", { className: theme.name_wrapper },
                React.createElement("div", { className: theme.number }, props.number),
                React.createElement("div", { className: theme.name }, props.name)),
            React.createElement("div", { className: theme.baggages }, services.map((baggageServices, index) => (React.createElement(PassengerBaggageControl, { key: index, baggageServices: baggageServices, passenger: props.passenger, segmentId: props.segmentId, isUnavailable: isUnavailable })))))));
};
export default BaggagePassenger;
