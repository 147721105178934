import { parseISO, parse } from 'date-fns';
const ISO_DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)\+(\d{2}):(\d{2})$/;
// TODO: remove this function when php will send the date ISO format
export const parseDate = (date) => {
    if (ISO_DATE_REGEX.test(date)) {
        return parseISO(date);
    }
    return parse(date, 'HH:mm dd.MM.yyyy', new Date());
};
export const formatError = (error) => {
    var _a;
    const errors = [
        { message: 'Неверные данные карты лояльности', translation: 'Incorrect card number or password' },
        { message: 'This auth type is already being used in another account' },
        { message: 'The use of a loyalty card is prohibited' }
    ];
    const graphqlErrors = error['graphQLErrors'];
    const graphqlError = errors.find(error => graphqlErrors.find(graphqlError => { var _a; return (_a = (graphqlError.message || graphqlError.debugMessage)) === null || _a === void 0 ? void 0 : _a.includes(error['message']); }));
    return graphqlError ? (_a = graphqlError.translation) !== null && _a !== void 0 ? _a : graphqlError.message : graphqlErrors[0].message;
};
export const getCorporateNumber = (number) => {
    return `70000${number}`;
};
export const getIsCorporateNumber = (number) => {
    return number === null || number === void 0 ? void 0 : number.startsWith('70000');
};
export const getCorporateCardNumberAsNormal = (number) => {
    return number === null || number === void 0 ? void 0 : number.replace('70000', '');
};
