import { __awaiter } from "tslib";
import * as React from 'react';
import { Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSetCheckinSetTravellersDataMutation } from '@websky/graphql';
import Modal from '../../../../../../../Modal';
import VisaFormComponent from '../../../../../../../VisaForm/components/VisaForm';
import Button from '../../../../../../../Button';
import { useTheme } from '../../../../../../../theme';
import { fillCheckinOrder } from '../../../../../store/order/actions';
import { getTravellersWithVisa, getCheckinOrderId } from '../../../../../store/order/selectors';
const VisaForm = ({ onVisaSubmitted }) => {
    const theme = useTheme('Checkin').VisaForm;
    const { t } = useTranslation('Checkin');
    const travellersWithVisa = useSelector(getTravellersWithVisa);
    const orderId = useSelector(getCheckinOrderId);
    const dispatch = useDispatch();
    const [checkinSetTravellersData] = useSetCheckinSetTravellersDataMutation();
    const [isLoading, setLoading] = React.useState(false);
    const sendTravellersData = (travellers) => __awaiter(void 0, void 0, void 0, function* () {
        const { data, errors } = yield checkinSetTravellersData({
            variables: {
                params: {
                    travellersData: travellers,
                    orderId: orderId
                }
            }
        });
        const results = !errors && (data === null || data === void 0 ? void 0 : data.CheckinSetTravellersData);
        if (results) {
            dispatch(fillCheckinOrder(results));
            setLoading(false);
            onVisaSubmitted();
        }
    });
    const onSubmit = React.useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const travellersData = travellersWithVisa.map(traveller => ({
            id: traveller.id,
            values: traveller.values.map(value => ({
                type: value.type,
                name: value.name,
                value: value.value
            })),
            visaValues: traveller.visaValues.map(value => ({
                type: value.type,
                name: value.name,
                value: values['travellers'][traveller.id][value.name]
            }))
        }));
        yield sendTravellersData(travellersData);
    }), travellersWithVisa);
    return (React.createElement(Modal, { maxWidth: "md", open: true, classes: {
            paper: theme.modal__paper
        } },
        React.createElement("div", { className: theme.wrapper },
            React.createElement("div", { className: theme.header }, t('Please, enter your visa details')),
            React.createElement(Form, { onSubmit: onSubmit, validateOnBlur: true }, ({ handleSubmit, valid }) => (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("div", null,
                    travellersWithVisa.map(traveller => (React.createElement(VisaFormComponent, { key: traveller.id, traveller: traveller }))),
                    React.createElement("div", { className: theme.buttons },
                        React.createElement(Button, { className: theme.skipVisaButton, variant: "text", onClick: onVisaSubmitted }, t('No visa required')),
                        React.createElement(Button, { disabled: !valid, className: theme.saveButton, type: "submit", variant: "flat", isLoading: isLoading }, t('Save'))))))))));
};
export default VisaForm;
