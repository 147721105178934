import { __rest } from "tslib";
import * as React from 'react';
import Switch from '../Switch';
import { useTheme } from '../../../theme';
import cn from 'classnames';
const SwitchV2 = (_a) => {
    var { label, variant = 'primary' } = _a, props = __rest(_a, ["label", "variant"]);
    const { SwitchV2: css } = useTheme('Switch');
    return (React.createElement(Switch, Object.assign({}, props, { label: label ? label : '', activeClassName: cn(css.active, props.activeClassName, {
            [css.primary]: variant === 'primary',
            [css.secondary]: variant === 'secondary'
        }), mainClassName: cn(css.main, props.activeClassName, {
            [css.primary]: variant === 'primary',
            [css.secondary]: variant === 'secondary'
        }), labelClassName: cn(css.label, props.labelClassName, {
            [css.primary]: variant === 'primary',
            [css.secondary]: variant === 'secondary'
        }) })));
};
export default SwitchV2;
