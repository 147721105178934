import { z } from 'zod';
import { StringOptional } from './schemaTypes';
export const FlightStatusSchema = z.object({
    engineURL: z.string(),
    appStoreURL: StringOptional,
    playMarketURL: StringOptional,
    accessToken: z.string(),
    stylesEn: z.string(),
    stylesRu: z.string()
});
