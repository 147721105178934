import * as React from 'react';
import { useContext } from 'react';
import { getPassengerValuesMap } from '../utils';
import { CartContext } from '../Cart';
import { Notification, Person } from '../../Icons';
import ServiceSummary from '../../ServiceSummary';
import ServiceSummaryOption from '../../ServiceSummary/components/ServiceSummaryOption';
import { useTranslation } from 'react-i18next';
import { TravellerFieldEnum } from '@websky/graphql';
import ServiceSummaryDivider from '../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import { convertGenderToCorrectValue, getUserValue } from '../../utils';
import { hideText } from '../../Passenger/utils';
import { CheckoutSteps } from '../../MobileStepbar/types';
const Passengers = ({ travellers, showPassengers, customer, renderService }) => {
    const cartContext = useContext(CartContext);
    const { t } = useTranslation('Cart'), { t: passengerT } = useTranslation('Passenger');
    const items = React.useMemo(() => {
        return travellers.map(traveller => {
            const valuesMap = getPassengerValuesMap(traveller);
            const docType = passengerT(valuesMap.get(TravellerFieldEnum.DocType));
            const docNumber = hideText(valuesMap.get(TravellerFieldEnum.DocNumber));
            const gender = valuesMap.get(TravellerFieldEnum.Gender);
            const birthDate = valuesMap.get(TravellerFieldEnum.BirthDate);
            const loyalty = valuesMap.get(TravellerFieldEnum.LoyaltyNumber);
            const main = [
                t(traveller.type),
                gender ? passengerT(convertGenderToCorrectValue(gender)) : null,
                birthDate
            ].filter(Boolean);
            const documents = [docType, docNumber].filter(Boolean);
            const loyaltyInfo = [passengerT('loyaltyName'), loyalty];
            const travellerValues = {
                header: cartContext.travellersById.get(traveller.id).name,
                headerIcon: Person,
                items: [
                    { id: `main_${traveller.id}`, name: main.join(' • ') },
                    { id: `document_${traveller.id}`, name: documents.join(' • ') }
                ]
            };
            if (loyalty) {
                travellerValues.items.push({
                    id: `loyalty_${traveller.id}`,
                    name: loyaltyInfo.join(' • ')
                });
            }
            return travellerValues;
        });
    }, [travellers]);
    const customerItems = React.useMemo(() => {
        if (customer === null || customer === void 0 ? void 0 : customer.values) {
            const email = getUserValue(customer, TravellerFieldEnum.Email), phone = getUserValue(customer, TravellerFieldEnum.Phone);
            const items = [];
            if (email) {
                items.push({
                    id: 'email',
                    name: email
                });
            }
            if (phone) {
                items.push({
                    id: 'phone',
                    name: phone
                });
            }
            return {
                header: t('Contacts'),
                headerIcon: Notification,
                items
            };
        }
        return null;
    }, [customer]);
    const group = {
        header: t('Passengers'),
        icon: Person
    };
    const content = (React.createElement(React.Fragment, null,
        React.createElement(ServiceSummaryDivider, null, items.map((item, index) => (React.createElement(ServiceSummaryOption, { groupOption: item, key: index })))),
        customerItems && (React.createElement(ServiceSummaryDivider, null,
            React.createElement(ServiceSummaryOption, { groupOption: customerItems })))));
    if (!showPassengers) {
        return null;
    }
    if (renderService) {
        return renderService(CheckoutSteps.Passengers, group, content);
    }
    return React.createElement(ServiceSummary, { group: group }, content);
};
export default Passengers;
