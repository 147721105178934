import * as React from 'react';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { CartService } from '../../Cart/types';
import CartItemOptionAdapter from '../../Cart/CartItemOptionAdapter/CartItemOptionAdapter';
import ServiceSummaryDivider from '../components/ServiceSummaryDivider/ServiceSummaryDivider';
import { groupServices } from '../../Cart/utils';
import { getActualProduct } from '../utils';
const VipSummary = props => {
    const onDeleteHandler = (serviceId, passengerId, segmentId, allSegments) => () => {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.BusinessLounge, {
            type: OrderAdditionalServiceGdsServiceServiceType.Vip,
            serviceId,
            passengerId,
            segmentId,
            allSegments
        });
    };
    const serviceFilter = React.useCallback((service) => {
        const vipService = service;
        return vipService.subType === props.serviceSubType;
    }, [props.serviceSubType]);
    const vipServices = React.useMemo(() => {
        const allSegmentsOptions = [];
        const otherSegmentsOptions = [];
        props.travellers.forEach(traveller => {
            var _a;
            const groupedService = groupServices(props.services, traveller, traveller.id, OrderAdditionalServiceGdsServiceServiceType.Vip, props.flight.segments, undefined, serviceFilter);
            Object.keys((_a = groupedService[traveller.id]) !== null && _a !== void 0 ? _a : {}).forEach(segment => {
                var _a, _b;
                const items = [];
                const isAllSegments = segment === 'allSegments';
                (_b = (_a = groupedService[traveller.id][segment]) === null || _a === void 0 ? void 0 : _a.additional) === null || _b === void 0 ? void 0 : _b.forEach(additional => {
                    var _a;
                    const pricesMap = new Set(((_a = additional.prices) === null || _a === void 0 ? void 0 : _a.map(price => price.amount)) || []);
                    const isDifferentPrices = pricesMap.size > 1;
                    const actualAmount = isDifferentPrices ? Math.min(...pricesMap) : additional.service.price.amount;
                    const actualProduct = getActualProduct(additional.products);
                    items.push({
                        id: additional.serviceId,
                        name: props.serviceName,
                        price: Object.assign(Object.assign({}, additional.service.price), { amount: actualAmount }),
                        priceFrom: isDifferentPrices,
                        status: actualProduct === null || actualProduct === void 0 ? void 0 : actualProduct.status,
                        onDelete: props.onServiceDelete
                            ? onDeleteHandler(additional.serviceId, traveller.id, segment, isAllSegments)
                            : null
                    });
                });
                if (!items.length) {
                    return;
                }
                if (isAllSegments) {
                    allSegmentsOptions.push({
                        items,
                        allSegments: true,
                        headerType: 'travellerName',
                        headerIcon: props.headerIcon,
                        traveller: props.travellersById.get(traveller.id)
                    });
                }
                else {
                    const selectedSegment = props.flight.segments.find(seg => seg.segment.id === segment);
                    otherSegmentsOptions.push({
                        items,
                        direction: selectedSegment.segment,
                        headerType: 'travellerName',
                        headerIcon: props.headerIcon,
                        traveller: props.travellersById.get(traveller.id)
                    });
                }
            });
        });
        return {
            allSegmentsOptions,
            otherSegmentsOptions
        };
    }, [props.flight, props.travellers, props.services, serviceFilter]);
    const content = React.useMemo(() => {
        if (!vipServices.allSegmentsOptions.length && !vipServices.otherSegmentsOptions.length) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            !!vipServices.allSegmentsOptions.length && (React.createElement(ServiceSummaryDivider, null, vipServices.allSegmentsOptions.map(group => (React.createElement(CartItemOptionAdapter, { option: group, key: group.header }))))),
            !!vipServices.otherSegmentsOptions.length && (React.createElement(ServiceSummaryDivider, null, vipServices.otherSegmentsOptions.map(group => (React.createElement(CartItemOptionAdapter, { option: group, key: group.header })))))));
    }, [vipServices]);
    return props.children(content);
};
export default VipSummary;
