import { __rest } from "tslib";
import { useCallback, useEffect, useMemo } from 'react';
import { useToggleable } from '../hooks';
import { WEBSKY_BAGGAGE_SERVICE_OPEN_EVENT } from '../Checkout/constants';
export const useBaggageToggleable = (baggage) => {
    const _a = useToggleable(false), { open } = _a, toggleableProps = __rest(_a, ["open"]);
    const availableBaggageTypes = useMemo(() => {
        return [...new Set(baggage.map(baggage => baggage.type))];
    }, [baggage]);
    const onEventHandler = useCallback((event) => {
        const { type } = event.detail;
        if (availableBaggageTypes.includes(type)) {
            open();
        }
    }, [availableBaggageTypes, open]);
    useEffect(() => {
        document.addEventListener(WEBSKY_BAGGAGE_SERVICE_OPEN_EVENT, onEventHandler);
        return () => document.removeEventListener(WEBSKY_BAGGAGE_SERVICE_OPEN_EVENT, onEventHandler);
    }, []);
    return Object.assign(Object.assign({}, toggleableProps), { open });
};
