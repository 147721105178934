import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { InfoMessageTypeEnum, useCheckinStartMutation, useResetOrderPaymentBlockMutation } from '@websky/graphql';
import { getCheckinHasCompleted, getCheckinOrder, getIsAllTravellersHavePreseating, getSegments } from '../../../../store/order/selectors';
import BoardingPassDesk from '../../../../../../BoardingPassDesk';
import BoardingPassMob from '../../../../../../BoardingPassMob';
import { useTheme } from '../../../../../../theme';
import MediaQuery from '../../../../../../MediaQuery/MediaQuery';
import { fillCheckinOrder } from '../../../../store/order/actions';
import Button from '../../../../../../Button';
import WarningModal from '../../../../../../WarningModal';
import SimpleLoader from '../../../../../../SimpleLoader';
import { NotIncluded, StatusMessage } from '../../../../../../index';
import Timelimit from './Timelimit/Timelimit';
import { MOBILE_MIN_WIDTH, seatNumberRegex } from '../../../../../../utils';
import { useCheckinCancelModal, useCheckinTraveller, useSegmentSelect } from './hooks';
import SelectSegmentsModal from './SelectSegmentsModal/SelectSegmentsModal';
import Modal from '../../../../../../Modal';
import VisaForm from './VisaForm/VisaForm';
import PassengersHeader from './PassengersHeader/PassengersHeader';
import Notification from '../../../../../../Notification';
import { OverrideComponent } from '../../../../../../renderProps';
import { hasSegmentBoardingPass } from './utils';
import CheckinAddDocumentsService from '../../CheckinAddDocuments/components/CheckinAddDocumentsService/CheckinAddDocumentsService';
import { useCheckinAddDocumentsService } from '../../CheckinAddDocuments/components/CheckinAddDocumentsService/hooks';
import PassengersContent from './PassengersContent/PassengersContent';
import Toolbar from '../../../../../../Toolbar';
const Passengers = ({ onCheckinStart, onRefetchOrder }) => {
    var _a, _b, _c;
    const { Passengers: css } = useTheme('Checkin');
    const { t } = useTranslation('Checkin');
    const order = useSelector(getCheckinOrder);
    const isConfirmed = useSelector(getCheckinHasCompleted);
    const segments = useSelector(getSegments);
    const isAllTravellersHavePreseating = useSelector(getIsAllTravellersHavePreseating);
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [displayVisaForm, setDisplayVisaForm] = useState(false);
    const [segmentsFailedBoardingPasses, setSegmentsFailedBoardingPasses] = useState(new Set());
    const [startCheckin] = useCheckinStartMutation();
    const [resetOrderPaymentBlock] = useResetOrderPaymentBlockMutation();
    let buttonIsDisabled = !order.travellers.length || isLoading;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const { handleAgree, showCheckinCancelModalIfNeeded, isCheckinCancelModalOpen } = useCheckinCancelModal();
    const { isOpen: isCheckinAddDocumentsOpen, setOpen: setCheckinAddDocumentsOpen, isNeedToOpen: isNeedToOpenAddDocumentsService, onDocumentsUpdated } = useCheckinAddDocumentsService(order.id);
    const isCheckinOnlyInAirport = useMemo(() => {
        return order.segments.some(segment => segment.checkinInfo.checkInSubStatus === 'OnlyAirportCheckIn');
    }, [order]);
    useEffect(() => {
        if (isCheckinOnlyInAirport) {
            setCheckinStartError({
                name: 'CheckinStartError',
                message: t('Checkin available only in airport')
            });
            buttonIsDisabled = true;
        }
    }, [isCheckinOnlyInAirport]);
    const closeErrorModal = () => setError(null);
    const setCheckinStartError = (error) => {
        const errorMessage = error.debugMessage || error.message;
        const invalidSeatError = 'Invalid seat number';
        if (errorMessage.includes(invalidSeatError)) {
            setError({
                error: Object.assign(Object.assign({}, error), { message: t(invalidSeatError, { seatNumber: errorMessage.match(seatNumberRegex)[0] }) }),
                content: t('An unexpected error has occurred during the start of the check-in process. Please try again later or contact a customer support service.')
            });
        }
        else {
            setError({
                error,
                content: t('An unexpected error has occurred during the start of the check-in process. Please try again later or contact a customer support service.')
            });
        }
    };
    const { onAddTraveller, onRemoveTraveller, onCancelCheckinPassenger } = useCheckinTraveller(showCheckinCancelModalIfNeeded, setError);
    const { onStart, isSegmentsSelectionOpen, onSelect, selectedSegments } = useSegmentSelect(setIsLoading, setCheckinStartError, onCheckinStart);
    const onStartCheckin = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setDisplayVisaForm(false);
        setIsLoading(true);
        onStart();
        if (segments.length > 1 && !selectedSegments.size) {
            setIsLoading(false);
            return;
        }
        try {
            const { data, errors } = yield startCheckin({
                variables: { id: order.id }
            });
            if (errors) {
                setCheckinStartError(errors[0]);
            }
            else if (data === null || data === void 0 ? void 0 : data.CheckinStart) {
                let result = data.CheckinStart;
                if (result.isBlockedForPayment) {
                    yield resetOrderPaymentBlock({ variables: { id: result.id } });
                    const order = yield onRefetchOrder();
                    if (order) {
                        result = order;
                    }
                }
                dispatch(fillCheckinOrder(result));
                if (!result.allTravellersHavePreseating) {
                    onCheckinStart();
                }
            }
        }
        catch (e) {
            setCheckinStartError(e);
        }
        setIsLoading(false);
    }), [order.id, segments, selectedSegments, dispatch]);
    const onCheckinStartHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        const travellersWithVisa = order.travellers.find(traveller => traveller.visaRequired && traveller.visaValues.length);
        if (isNeedToOpenAddDocumentsService) {
            setCheckinAddDocumentsOpen(true);
        }
        else if (travellersWithVisa) {
            setDisplayVisaForm(true);
        }
        else {
            yield onStartCheckin();
        }
    });
    const availableTimelimit = !isConfirmed &&
        ((_a = order.segments.find(segment => segment.checkinInfo.checkinAvailable && segment.checkinInfo.webCloseTime)) === null || _a === void 0 ? void 0 : _a.checkinInfo.webCloseTime);
    const getStatusMessageText = () => {
        const segmentsWithoutBoardingPasses = segments.filter(segment => !hasSegmentBoardingPass(segment, segmentsFailedBoardingPasses));
        const canDownloadBoardingPass = segmentsWithoutBoardingPasses.length < segments.length;
        if (segmentsWithoutBoardingPasses.length && canDownloadBoardingPass) {
            const flights = segmentsWithoutBoardingPasses.map(segment => `${segment.marketingAirline.iata}-${segment.flightNumber} ${segment.departure.airport.title} – ${segment.arrival.airport.title}`);
            return t('You can now download and print your boarding passes. You will need them at the airport.\n Please notice, for flight {{flight}} you must receive your boarding pass at the airport', { flight: flights.join(', '), count: segmentsWithoutBoardingPasses.length === 1 ? 1 : 2 });
        }
        else if (!segmentsWithoutBoardingPasses.length && canDownloadBoardingPass) {
            return t('You can now download and print your boarding passes. You will need them at the airport.');
        }
        else {
            return t('Passengers:Please notice, you must receive your boarding passes at the airport');
        }
    };
    return order ? (React.createElement("div", { className: css.wrapper },
        React.createElement(PassengersHeader, null),
        React.createElement(CheckinAddDocumentsService, { onDocumentsUpdated: onDocumentsUpdated, isOpen: isCheckinAddDocumentsOpen }),
        React.createElement("div", { className: css.content },
            React.createElement(WarningModal, { title: t('Oops...'), content: error === null || error === void 0 ? void 0 : error.content, onClose: closeErrorModal, onButtonClick: closeErrorModal, errorMessage: (_b = error === null || error === void 0 ? void 0 : error.error) === null || _b === void 0 ? void 0 : _b.message, buttonText: t('OK, thank you'), isOpen: !!error }),
            React.createElement(SelectSegmentsModal, { open: isSegmentsSelectionOpen, onSegmentSelect: onSelect, selectedSegments: selectedSegments, onConfirm: onStart }),
            displayVisaForm && React.createElement(VisaForm, { onVisaSubmitted: onStartCheckin }),
            (!!((_c = order.warnings) === null || _c === void 0 ? void 0 : _c.length) || isAllTravellersHavePreseating) && (React.createElement("div", { className: css.notifications },
                order.warnings.map((warning, index) => (React.createElement(Notification, { key: index, className: css.notification, iconClassName: css.notification__icon, titleClassName: css.notification__title, textClassName: css.notification__text, title: warning.title, status: warning.type, image: warning.image, infoMessage: warning, type: 'context' }))),
                React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutPassengersNotifications: () => null } }),
                isAllTravellersHavePreseating && (React.createElement(Notification, { className: css.notification, iconClassName: css.notification__icon, titleClassName: css.notification__title, textClassName: css.notification__text, contextClassName: css.notification__context, title: t('Attention'), icon: NotIncluded, status: InfoMessageTypeEnum.Warning, type: "context", infoMessage: {
                        text: t('Seats have already been assigned by the airline and check-in is only available at the counter at the airport'),
                        type: InfoMessageTypeEnum.Warning,
                        code: 'notification'
                    } })))),
            React.createElement(OverrideComponent, { componentProps: {}, component: { CheckinOrderAdditionalBanner: () => null } }),
            React.createElement("div", { className: css.segments },
                !isMobile && availableTimelimit && (React.createElement(Timelimit, { timelimit: availableTimelimit, info: `(${t('airport local time')})` })),
                isConfirmed && (React.createElement(StatusMessage, { type: "success", title: t('Check-in Successful'), text: getStatusMessageText() })),
                React.createElement(MediaQuery, { minWidth: "mobile" }, order.segments.map((segment, index) => (React.createElement(BoardingPassDesk, { key: index, className: css.segment, segment: segment })))),
                !isConfirmed && (React.createElement(MediaQuery, { maxWidth: "mobile" }, order.segments.map((segment, index) => (React.createElement(BoardingPassMob, { key: index, className: css.segment, segment: segment })))))),
            React.createElement(PassengersContent, { segmentsFailedBoardingPasses: segmentsFailedBoardingPasses, onAddTraveller: onAddTraveller, onRemoveTraveller: onRemoveTraveller, onCheckinCancel: onCancelCheckinPassenger, setIsLoading: setIsLoading, setSegmentsFailedBoardingPasses: setSegmentsFailedBoardingPasses }),
            React.createElement(Modal, { open: isCheckinCancelModalOpen, onClose: handleAgree, maxWidth: 'sm', classes: { scrollBody: css.checkinCancelModal } },
                React.createElement("div", { className: css.checkinCancelModal__content },
                    React.createElement("div", { className: css.checkinCancelModal__text }, t('Checkin canceled, you will be redirected to the beginning', {})),
                    React.createElement("div", { className: css.checkinCancelModal__buttons },
                        React.createElement(Button, { onClick: handleAgree }, t('OK')))))),
        !isConfirmed && !isAllTravellersHavePreseating && !isCheckinOnlyInAirport && (React.createElement(Toolbar, { totalPrice: null, buttonDisabled: buttonIsDisabled, onButtonClick: onCheckinStartHandler })),
        isLoading && React.createElement(SimpleLoader, null))) : null;
};
export default Passengers;
