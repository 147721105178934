import { __awaiter } from "tslib";
import { StepsEnum } from '../../../../types';
import { useInsurancePageWrapper } from '../../../../InsurancePage/hooks';
export const useCheckoutInsuranceRoute = ({ goToNextStep, steps }) => {
    var _a;
    const insurancePageWrapper = useInsurancePageWrapper({ strategy: 'checkout-insurance-step' });
    const handleNextStep = () => __awaiter(void 0, void 0, void 0, function* () {
        yield insurancePageWrapper.saveOrderServicesCallback(goToNextStep);
    });
    const handleAgree = () => __awaiter(void 0, void 0, void 0, function* () {
        if (insurancePageWrapper.priorityInsurance) {
            yield insurancePageWrapper.handleAgree();
            goToNextStep();
            return;
        }
        insurancePageWrapper.handleAgree();
    });
    const handleDisagree = () => __awaiter(void 0, void 0, void 0, function* () {
        yield insurancePageWrapper.handleDisagree();
        goToNextStep();
    });
    const isCanGoBack = ((_a = steps.find(step => step.type === StepsEnum.Passengers)) === null || _a === void 0 ? void 0 : _a.disabled) === false;
    return {
        // methods
        handleNextStep,
        handleAgree,
        handleDisagree,
        setInsurancePriceToPay: insurancePageWrapper.setInsurancePriceToPay,
        setSaveOrderServicesParams: insurancePageWrapper.setSaveOrderServicesParams,
        setIsNeedToSendSaveReq: insurancePageWrapper.setIsNeedToSendSaveReq,
        onDownloadNasInsurance: insurancePageWrapper.onDownloadNasInsurance,
        // data
        insurancePriceToPay: insurancePageWrapper.insurancePriceToPay,
        orderPriceToPay: insurancePageWrapper.orderPriceToPay,
        priorityInsurance: insurancePageWrapper.priorityInsurance,
        isUpsaleOpen: insurancePageWrapper.isUpsaleOpen,
        isLoading: insurancePageWrapper.isLoading,
        isShowImportantInsurancesError: insurancePageWrapper.isShowImportantInsurancesError,
        isCanGoBack
    };
};
