import * as React from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import BaggageItem from './BaggageItem/BaggageItem';
import Slider from 'react-slick';
import { MOBILE_MIN_WIDTH } from '../../../utils';
const BaggageList = ({ items, className, slidesToShow }) => {
    const { BaggageList: css } = useTheme('BaggageList');
    const slidesToShowCount = slidesToShow !== null && slidesToShow !== void 0 ? slidesToShow : 1;
    return (React.createElement(Slider, { slidesToShow: Math.min(slidesToShowCount, items.length), arrows: true, infinite: false, variableWidth: false, dots: true, className: cn(css.root, className), responsive: [
            {
                breakpoint: MOBILE_MIN_WIDTH,
                settings: {
                    slidesToShow: 1
                }
            }
        ] }, items.map((item, i) => (React.createElement(BaggageItem, Object.assign({}, item, { key: i }))))));
};
export default BaggageList;
