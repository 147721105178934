import { AeroexpressClass, AeroexpressDirection } from '@websky/graphql';
export const ROUND_TRIP_CLASSES = [
    AeroexpressClass.roundtrip,
    AeroexpressClass.roundTrip,
    AeroexpressClass.business_roundtrip,
    AeroexpressClass.couple_roundtrip,
    AeroexpressClass.family_roundtrip
];
export const isRoundTrip = (trip) => {
    var _a, _b;
    return ((ROUND_TRIP_CLASSES.includes(trip.class) || ((_a = trip.fromStation) === null || _a === void 0 ? void 0 : _a.direction) === AeroexpressDirection.Roundtrip) &&
        ((_b = trip.segments) === null || _b === void 0 ? void 0 : _b.length) > 1);
};
