import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Link from '../../../Link/Link';
import ListTransition from './ListTransition/ListTransition';
import { useTheme } from '../../../theme';
const SEND_CODE_TIMEOUT = 60;
const Resend = props => {
    const theme = useTheme('Contacts').ResendStyles, [codeSending, setCodeSending] = React.useState(false), [codeSent, setCodeSent] = React.useState(false);
    const [isTimerRun, setTimerRun] = React.useState(true), [sendCodeRequire, setSendCodeRequire] = React.useState(false);
    const timeRef = React.useRef(null);
    const { t } = useTranslation();
    const sendAgainClick = (e) => {
        e.preventDefault();
        if (isTimerRun) {
            setSendCodeRequire(true);
        }
        else {
            props.sendCode();
            setCodeSending(true);
        }
    };
    React.useEffect(() => {
        if (isTimerRun) {
            setTimeout(() => {
                const startTimer = () => {
                    let time = SEND_CODE_TIMEOUT;
                    return setInterval(() => {
                        time--;
                        if (timeRef.current) {
                            timeRef.current.textContent = time.toString();
                        }
                    }, 1000);
                };
                const timer = startTimer();
                if (timeRef.current) {
                    timeRef.current.textContent = SEND_CODE_TIMEOUT.toString();
                }
                setTimeout(() => {
                    clearInterval(timer);
                    setTimerRun(false);
                }, SEND_CODE_TIMEOUT * 1000);
            });
        }
        else if (sendCodeRequire) {
            props.sendCode();
            setCodeSending(true);
        }
    }, [isTimerRun]);
    React.useEffect(() => {
        if (props.codeSent) {
            setCodeSending(false);
            setCodeSent(true);
            setTimeout(() => {
                setCodeSent(false);
                setSendCodeRequire(false);
                setTimerRun(true);
            }, 2000);
        }
    }, [props.codeSent]);
    const getCode = () => {
        if (isTimerRun) {
            return 1;
        }
        else if (codeSending) {
            return 2;
        }
        else if (codeSent) {
            return 3;
        }
        return 0;
    };
    return (React.createElement("div", { className: cn(theme.sendAgain, props.className) },
        props.onBack && (React.createElement("span", { className: theme.back, onClick: props.onBack }, t('Contacts:Back'))),
        React.createElement(ListTransition, { visible: getCode() },
            React.createElement("div", { className: cn(theme.item, theme.label, props.labelClassName) },
                t('Contacts:Didnt get the code? '),
                React.createElement(Link, { className: theme.sendAgain__link, action: sendAgainClick }, t('Contacts:Resend it'))),
            React.createElement("div", { className: cn(theme.item, theme.timer) },
                t('Contacts:You can get the new code in'),
                React.createElement("span", { className: theme.timer__time, ref: timeRef }, SEND_CODE_TIMEOUT)),
            React.createElement("div", { className: cn(theme.item, theme.sending) }, t('Contacts:Sending message...')),
            React.createElement("div", { className: cn(theme.item, theme.sent) }, t('Contacts:Code sent')))));
};
export default Resend;
