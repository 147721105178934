import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { initI18n } from '../../../utils';
import { BaseButton } from '../../../index';
initI18n('ServiceRecommendation');
const Base = ({ onConfirm, onChange, isMobileFullScreen, isFreeService, children }) => {
    const { t } = useTranslation('ServiceRecommendation');
    const { Base: css } = useTheme('ServiceRecommendation');
    return (React.createElement("div", { className: cn(css.wrapper, { [css.wrapper_mobileFullScreen]: isMobileFullScreen }) },
        children,
        React.createElement("div", { className: css.footer },
            React.createElement(BaseButton, { variant: "smallSecondary", className: cn(css.button, css.button_cancel), onClick: onChange }, t('Change')),
            React.createElement(BaseButton, { size: "large", variant: "smallThird", className: css.button, onClick: onConfirm }, t('Confirm')))));
};
export default Base;
