import * as React from 'react';
import { useTranslation } from 'react-i18next';
import StepTitle from '../StepTitle/StepTitle';
import Passengers from '../Passengers/Passengers';
import StepsButtons from '../StepsButtons/StepsButtons';
import { useTheme } from '../../../theme';
import MobileStep from '../MobileStep/MobileStep';
import { Person } from '../../../Icons';
import { useCallback, useEffect } from 'react';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getPassengers } from '../../store/passengers/selectors';
import { StepType } from '../../types';
import { togglePassenger } from '../../store/passengers/actions';
import { useSteps } from '../Refund/hooks';
import cn from 'classnames';
import { getAviaOrderTravellers } from '../../store/aviaOrder/selectors';
import { useConfig } from '../../../context';
import { nextStep } from '../../store/steps/actions';
const PassengersPage = props => {
    const { Refund: css } = useTheme('Refund');
    const { t } = useTranslation('Refund');
    const dispatch = useDispatch();
    const passengers = useSelector(getPassengers);
    const aviaOrderTravellers = useSelector(getAviaOrderTravellers);
    const { currentStep, canGoToNextStep, goToNextStep, isCurrentActiveStepAfter, handleMobileCloseClick } = useSteps();
    const handlePassengerSelect = useCallback(({ id }) => dispatch(togglePassenger(id)), [passengers]);
    const { Engine: { exareShowPassengersStep } } = useConfig();
    useEffect(() => {
        if (!exareShowPassengersStep) {
            dispatch(nextStep());
        }
    }, [exareShowPassengersStep]);
    const isPassengersStepActive = currentStep.type === StepType.Passengers;
    const isPassengersStepActuallyActive = !props.skipPassengersStep && isPassengersStepActive;
    const isPassengersStepPassed = isCurrentActiveStepAfter(StepType.Passengers);
    const selectedPassengers = React.useMemo(() => passengers.filter(passenger => passenger.selected), [passengers]);
    const renderMobileTitle = useCallback(() => {
        return (React.createElement(React.Fragment, null,
            !!selectedPassengers.length ? React.createElement(React.Fragment, null,
                t('For'),
                "\u00A0") : React.createElement(React.Fragment, null, t('Select passengers')),
            selectedPassengers.map(passenger => passenger.name).join(', ')));
    }, [passengers]);
    useEffect(() => {
        if (props.skipPassengersStep && isPassengersStepActive) {
            batch(() => {
                passengers.forEach(passenger => {
                    // won`t toggle if passenger already selected
                    if (passengers.length !== selectedPassengers.length) {
                        dispatch(togglePassenger(passenger.id));
                    }
                });
            });
            goToNextStep();
        }
    }, [props.skipPassengersStep, isPassengersStepActive]);
    const renderContent = useCallback(() => isPassengersStepActuallyActive && (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(StepTitle, { className: css.stepTitle, icon: Person, title: t('Select passengers') })),
        React.createElement(Passengers, { passengers: passengers, onPassengerSelect: handlePassengerSelect, travellersCount: aviaOrderTravellers.length }),
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(StepsButtons, { className: css.buttons, position: 'center', nextButton: {
                    children: t('Continue'),
                    disabled: !canGoToNextStep,
                    onClick: goToNextStep
                } })))), [props]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: 'mobile' }, renderContent()),
        React.createElement(MobileStep, { isActive: isPassengersStepActuallyActive, renderTitle: renderMobileTitle, icon: Person, onCloseClick: props.skipPassengersStep ? null : handleMobileCloseClick(StepType.Passengers), className: cn(css.mobileStep, {
                [css.passed]: isPassengersStepPassed
            }) }, renderContent())));
};
export default PassengersPage;
