import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { ExareStatus } from '@websky/graphql';
import { ArrowForward, Included } from '../../../Icons';
import { Button } from '../../../index';
import { useHistory } from 'react-router-dom';
const REJECTED_STATUSES = [ExareStatus.Rejected, ExareStatus.Refused, ExareStatus.TimelimitExpired];
const WAITING_STATUSES = [ExareStatus.NeedConfirm];
const PROCESSING_STATUSES = [ExareStatus.InProcess, ExareStatus.AwaitingPayment];
const SUCCESS_STATUSES = [ExareStatus.Finished];
const OrderRefundStatus = props => {
    var _a;
    const { t: refundTranslate } = useTranslation('Refund');
    const t = (_a = props.customTranslate) !== null && _a !== void 0 ? _a : refundTranslate;
    const { OrderRefundStatus: css } = useTheme('Refund');
    const history = useHistory();
    const cssStatusClassModifier = cn({
        [css.rejected]: REJECTED_STATUSES.includes(props.status),
        [css.processing]: PROCESSING_STATUSES.includes(props.status),
        [css.waiting]: WAITING_STATUSES.includes(props.status),
        [css.success]: SUCCESS_STATUSES.includes(props.status)
    });
    const renderTitle = useCallback(() => {
        if (props.renderTitle) {
            return props.renderTitle(props.status);
        }
        switch (props.status) {
            case ExareStatus.InProcess:
            case ExareStatus.Rejected:
            case ExareStatus.Refused:
            case ExareStatus.AwaitingPayment:
            case ExareStatus.NeedConfirm:
                return t('Request for refund');
            case ExareStatus.Finished:
                return (React.createElement(React.Fragment, null,
                    React.createElement("i", { className: cn(css.icon, cssStatusClassModifier) }, Included),
                    [
                        t('Successfully created new booking'),
                        props.splittedOrderId,
                        props.passengersNames ? `${t('For').toLowerCase()} ${props.passengersNames}` : null
                    ]
                        .filter(Boolean)
                        .join(' ')));
            default:
                return null;
        }
    }, [props.status, props.renderTitle]);
    const goToSplittedBooking = useCallback(() => {
        history.push(`/booking/${props.splittedOrderId}`);
        window.location.reload();
    }, [props.splittedOrderId]);
    const renderStatus = useCallback(() => {
        if (props.renderStatus) {
            return props.renderStatus(props.status);
        }
        switch (props.status) {
            case ExareStatus.AwaitingPayment:
            case ExareStatus.NeedConfirm:
            case ExareStatus.InProcess:
            case ExareStatus.TimelimitExpired:
            case ExareStatus.Rejected:
                return (React.createElement(React.Fragment, null,
                    t(props.status),
                    props.onGoToTicket && (React.createElement(Button, { onClick: props.onGoToTicket, className: cn(css.button, cssStatusClassModifier) }, ArrowForward))));
            case ExareStatus.Refused:
                return t(props.status);
            case ExareStatus.Finished:
                if (!props.splittedOrderId) {
                    return null;
                }
                return React.createElement(Button, { onClick: goToSplittedBooking }, t('Open'));
            default:
                return null;
        }
    }, [props.status, props.renderStatus, props.splittedOrderId]);
    if (!props.show) {
        return null;
    }
    return (React.createElement("div", { className: cn(css.status, cssStatusClassModifier, props.className) },
        React.createElement("div", { className: cn(css.title, cssStatusClassModifier) }, renderTitle()),
        React.createElement("div", { className: css.right }, renderStatus())));
};
export default OrderRefundStatus;
