import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { Money } from '../../../Money';
import Link from '../../../Link';
import { initI18n } from '../../../utils';
import { BaseButton } from '../../../BaseComponents';
import { CloseClear } from '../../../Icons';
initI18n('InsurancePage');
export const InsurancePrice = ({ className, contentClassName, selected, price, variant, onClick, onRemove, hideForAllPassengersTextBecauseSinglePassenger }) => {
    const { t } = useTranslation('InsurancePage');
    const { InsurancePrice: theme } = useTheme('InsurancePage');
    return (React.createElement("div", { className: cn(theme.wrapper, {
            [theme.mostPopular]: variant === 'mostPopular',
            [theme.bottomPrice]: variant === 'bottomPrice',
            [theme.forAllPassengers]: !hideForAllPassengersTextBecauseSinglePassenger
        }, className) },
        variant === 'mostPopular' && (React.createElement("div", { className: cn(theme.content, contentClassName) },
            !hideForAllPassengersTextBecauseSinglePassenger && (React.createElement("span", { className: theme.light }, t('For all passengers'))),
            React.createElement("span", { className: cn(theme.bold) },
                React.createElement(Money, { money: price, moneyClassName: theme.bold })))),
        variant === 'bottomPrice' && (React.createElement("div", { className: cn(theme.contentFlex, contentClassName, hideForAllPassengersTextBecauseSinglePassenger ? theme.priceOnly : null) },
            !hideForAllPassengersTextBecauseSinglePassenger && (React.createElement("span", { className: theme.light }, t('For all passengers'))),
            React.createElement("span", { className: theme.bold },
                React.createElement(Money, { money: price, moneyClassName: theme.bold })))),
        variant === 'regular' && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.content },
                selected
                    ? onRemove && (React.createElement(Link, { action: onRemove, className: cn(theme.light, theme.link) },
                        CloseClear,
                        t('Remove')))
                    : onClick && (React.createElement(BaseButton, { variant: "optionAction", className: theme.button, onClick: onClick }, t('Add'))),
                React.createElement("div", { className: theme.price },
                    !hideForAllPassengersTextBecauseSinglePassenger && (React.createElement("span", { className: cn(theme.light, theme.label) }, t('For all passengers'))),
                    React.createElement(Money, { money: price, moneyClassName: cn(theme.bold, theme.money) })))))));
};
