import { AviaPassengerType } from '@websky/graphql';
export const isInfant = (passenger) => {
    const infantTypes = [AviaPassengerType.INF, AviaPassengerType.INS];
    return (infantTypes.includes(passenger === null || passenger === void 0 ? void 0 : passenger.code) ||
        infantTypes.includes(passenger === null || passenger === void 0 ? void 0 : passenger.parentCode));
};
export const canBeIncrease = (passenger, config, passengers, totalCount, maxPassengersCount = 9) => {
    if (totalCount >= maxPassengersCount) {
        return false;
    }
    const passengerCount = passengers[passenger.code];
    if (passenger.maxCount) {
        return passengerCount < passenger.maxCount;
    }
    if (!passenger.isAdult) {
        const adultCount = config.reduce((count, current) => count + (current.isAdult ? passengers[current.code] || 0 : 0), 0);
        if (isInfant(passenger) && passengerCount >= adultCount) {
            return false;
        }
        else if (adultCount <= 0) {
            if (passenger.maxCountWithoutAdult && passengerCount < passenger.maxCountWithoutAdult) {
                return true;
            }
            return false;
        }
    }
    return true;
};
export const canBeDecrease = (passenger, config, passengers) => {
    const passengerCount = passengers[passenger.code];
    const passengersRequireAdult = config.filter(pass => !pass.isAdult &&
        passengers[pass.code] &&
        (!pass.maxCountWithoutAdult || passengers[pass.code] > pass.maxCountWithoutAdult));
    if (passengerCount <= 0) {
        return false;
    }
    else if (passenger.isAdult && config.some(type => !type.isAdult && passengers[type.code] > 0)) {
        const adultsCount = config.reduce((count, current) => count + (current.isAdult ? passengers[current.code] || 0 : 0), 0);
        if (adultsCount <= 1 && passengersRequireAdult.length) {
            return false;
        }
    }
    return true;
};
export const isRoutesMatch = (route, segment) => {
    var _a, _b;
    return ((route.arrival === '*' || ((_a = segment.arrival) === null || _a === void 0 ? void 0 : _a.iata) === route.arrival) &&
        (route.departure === '*' || ((_b = segment.departure) === null || _b === void 0 ? void 0 : _b.iata) === route.departure));
};
