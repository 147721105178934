import * as React from 'react';
import { useMemo } from 'react';
import { MinFlightPrice } from './MinFlightPrice/MinFlightPrice';
import { getMinPrices } from '../../../Results/utils';
import { SelectedFlightPrice } from './SelectedFlightPrice/SelectedFlightPrice';
import { getSeatsLeftCount } from './utils';
const FlightPrice = ({ best, singleFlight, isSelected, flight, selectedFares, preselectedFlightClass, canceled, onClear, hideOnlyPrice, showSeatsLeftForAllFares }) => {
    const [price, originalPrice, priceInMiles, hasSubsidizedFare] = useMemo(() => {
        var _a, _b, _c, _d, _e;
        const minPrice = getMinPrices(flight.pricesForFareGroups, true, selectedFares, false, preselectedFlightClass);
        const minOriginalPrice = getMinPrices(flight.pricesForFareGroups, true, selectedFares, true, preselectedFlightClass);
        return [
            minPrice[0].price,
            ((_b = (_a = minOriginalPrice[0]) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.amount) > ((_d = (_c = minPrice[0]) === null || _c === void 0 ? void 0 : _c.price) === null || _d === void 0 ? void 0 : _d.amount) && ((_e = minOriginalPrice[0]) === null || _e === void 0 ? void 0 : _e.price),
            minPrice[0].priceInMiles,
            flight.pricesForFareGroups.some(group => { var _a; return (_a = group.prices) === null || _a === void 0 ? void 0 : _a.some(price => { var _a; return (_a = price.flight) === null || _a === void 0 ? void 0 : _a.subsidized; }); })
        ];
    }, [flight, preselectedFlightClass]);
    const seatsLeft = !isSelected ? getSeatsLeftCount(flight) : null;
    const [fareFamily, selectedFare] = useMemo(() => {
        if (!isSelected) {
            return [null, null];
        }
        const fareFamily = flight.pricesForFareGroups[0].fareFamily;
        return [fareFamily, selectedFares[0]];
    }, [flight, selectedFares, isSelected]);
    if (selectedFare) {
        return (React.createElement(SelectedFlightPrice, { fareFamily: fareFamily, selectedFare: selectedFare, canceled: canceled, priceInMiles: priceInMiles, onClear: onClear, hideOnlyPrice: hideOnlyPrice, subsidized: hasSubsidizedFare }));
    }
    else {
        return (React.createElement(MinFlightPrice, { singleFlight: singleFlight, best: best, originalPrice: originalPrice, price: price, priceInMiles: priceInMiles, hideOnlyPrice: hideOnlyPrice, subsidized: hasSubsidizedFare, seatsLeft: seatsLeft, flightMessages: flight.segments[0].segment.messages }));
    }
};
export default FlightPrice;
