import { __awaiter } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { ExareStatus, useUpdateOrderMutation } from '@websky/graphql';
import { getOrder, getTravellers } from '../Checkout/store/order/selectors';
export var PaymentResult;
(function (PaymentResult) {
    PaymentResult["Success"] = "success";
    PaymentResult["Failed"] = "failed";
})(PaymentResult || (PaymentResult = {}));
export const useEditPassenger = (refetchOrder, returnToOrder, passengerId) => {
    const order = useSelector(getOrder);
    const travellers = useSelector(getTravellers);
    const [updateOrder] = useUpdateOrderMutation();
    const history = useHistory();
    const match = useRouteMatch();
    const baseUrl = match.url;
    const requestUrl = `${baseUrl}/request`;
    const paymentUrl = `${baseUrl}/payment`;
    const successUrl = `${baseUrl}/payment/success`;
    const failedUrl = `${baseUrl}/payment/failed`;
    const [changePaxOrder, setChangePaxOrder] = useState(() => {
        var _a, _b, _c;
        if ((_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastChangeTravellerDataOrder) {
            const changeOrder = order.exareInfo.lastChangeTravellerDataOrder;
            if (((_c = (_b = changeOrder.travellers) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) === passengerId) {
                return changeOrder;
            }
        }
        return null;
    });
    useEffect(() => {
        var _a, _b;
        const isInProcessOrAwaitingPayment = [ExareStatus.InProcess, ExareStatus.AwaitingPayment].includes((_b = (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastChangeTravellerDataOrder) === null || _b === void 0 ? void 0 : _b.status);
        if (isInProcessOrAwaitingPayment) {
            onRefetchOrder();
        }
    }, []);
    const [reason, setReason] = useState(null);
    const [changeTravellers, setChangeTravellers] = React.useState(null);
    const [prices, setPrices] = React.useState(null);
    const [paymentStatus, setPaymentStatus] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const isRefused = (changePaxOrder === null || changePaxOrder === void 0 ? void 0 : changePaxOrder.status) === ExareStatus.Refused;
    const isFinished = (changePaxOrder === null || changePaxOrder === void 0 ? void 0 : changePaxOrder.status) === ExareStatus.Finished;
    const isAwaitingPayment = (changePaxOrder === null || changePaxOrder === void 0 ? void 0 : changePaxOrder.status) === ExareStatus.AwaitingPayment;
    const isPassengerRouteAvailable = !changePaxOrder || isRefused || isFinished;
    const isRequestRouteAvailable = (!!changePaxOrder || !!(changeTravellers === null || changeTravellers === void 0 ? void 0 : changeTravellers.length)) && !(isRefused || isFinished);
    const isPaymentRouteAvailable = !!changePaxOrder && isAwaitingPayment;
    const passenger = React.useMemo(() => {
        var _a;
        if ((_a = changePaxOrder === null || changePaxOrder === void 0 ? void 0 : changePaxOrder.travellers) === null || _a === void 0 ? void 0 : _a.length) {
            return travellers.find(traveller => traveller.id === changePaxOrder.travellers[0].id);
        }
        return travellers.find(traveller => traveller.id === passengerId);
    }, [travellers, passengerId, changePaxOrder]);
    const onRefetchOrder = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        setIsLoading(true);
        const order = yield refetchOrder();
        const lastChangeTravellerOrder = (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastChangeTravellerDataOrder;
        if (lastChangeTravellerOrder && ((_c = (_b = lastChangeTravellerOrder.travellers) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) === passengerId) {
            setChangePaxOrder(lastChangeTravellerOrder);
        }
        setIsLoading(false);
    });
    const onReturnToOrder = (refetch) => __awaiter(void 0, void 0, void 0, function* () {
        var _d;
        if (((_d = order.exareInfo) === null || _d === void 0 ? void 0 : _d.lastChangeTravellerDataOrder) || changePaxOrder || refetch) {
            setIsLoading(true);
            if (refetch) {
                yield updateOrder({
                    variables: { id: order.id }
                });
            }
            yield refetchOrder();
            yield new Promise(resolve => setTimeout(resolve, 100));
            setIsLoading(false);
        }
        returnToOrder();
    });
    const onGoToForm = () => {
        setChangePaxOrder(null);
        history.push(baseUrl);
    };
    const onGoToRequest = (refetch) => __awaiter(void 0, void 0, void 0, function* () {
        if (refetch) {
            yield onRefetchOrder();
        }
        history.push(requestUrl);
    });
    const onGoToPayment = () => {
        history.push(paymentUrl);
    };
    const onFillOrder = (travellers, prices) => {
        if (changePaxOrder) {
            setChangePaxOrder(null);
        }
        if (travellers) {
            setChangeTravellers(travellers);
        }
        if (prices) {
            setPrices(prices);
        }
        onGoToRequest();
    };
    return {
        order,
        travellers,
        urls: {
            baseUrl,
            requestUrl,
            paymentUrl,
            successUrl,
            failedUrl
        },
        availableRoutes: {
            passenger: isPassengerRouteAvailable,
            request: isRequestRouteAvailable,
            payment: isPaymentRouteAvailable
        },
        passenger,
        changePaxOrder,
        changeTravellers,
        prices,
        paymentStatus,
        reason,
        isLoading,
        onRefetchOrder,
        onReturnToOrder,
        onFillOrder,
        onGoToForm,
        onGoToRequest,
        onGoToPayment,
        setChangePaxOrder,
        setPaymentStatus,
        setReason
    };
};
