import { __awaiter } from "tslib";
import * as React from 'react';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { deleteIcon } from '../../Icons';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DeleteAccountDialog from '../DeleteAccountDialog/DeleteAccountDialog';
import { SlideBottom } from '../../../index';
import { useRemoveAccountMutation } from '@websky/graphql';
import { ButtonBase } from '@material-ui/core';
const DeleteAccount = props => {
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const theme = useTheme('Account').Account, { t } = useTranslation('Account');
    const [removeAccount] = useRemoveAccountMutation();
    const onRemoveAccount = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const res = yield removeAccount();
            if (res.errors && res.errors.length) {
                props.onError(res.errors[0]);
            }
            else if (res.data.RemoveAccount.result) {
                window.location.reload();
            }
        }
        catch (error) {
            props.onError(error);
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonBase, { onClick: () => {
                setOpenDeleteDialog(true);
            }, className: theme.deleteButton },
            deleteIcon,
            t('Delete my account')),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dialog, { maxWidth: 'md', classes: { paper: theme.account__deleteModal }, open: isOpenDeleteDialog },
                React.createElement(DeleteAccountDialog, { onClose: () => {
                        setOpenDeleteDialog(false);
                    }, onRemoveAccount: onRemoveAccount }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: isOpenDeleteDialog, onClose: () => setOpenDeleteDialog(false) },
                React.createElement(DeleteAccountDialog, { onClose: () => setOpenDeleteDialog(false), onRemoveAccount: onRemoveAccount })))));
};
export default DeleteAccount;
