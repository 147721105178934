import { useMemo } from 'react';
const getPercent = (total, value) => {
    return (total / value) * 100;
};
export const useLoyaltyInfo = (ffpInfo) => {
    const nextLevel = useMemo(() => {
        var _a, _b;
        const availableStatusIndex = (_a = ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.availableLevels) === null || _a === void 0 ? void 0 : _a.indexOf(ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.currentLevel);
        if (availableStatusIndex < ((_b = ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.availableLevels) === null || _b === void 0 ? void 0 : _b.length) - 1) {
            return ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.availableLevels[availableStatusIndex + 1];
        }
        return null;
    }, [ffpInfo]);
    let milesPercentage = 100;
    if ((ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.numberOfMiles) < (ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.currentLevelMaxMiles)) {
        milesPercentage = getPercent(ffpInfo.numberOfMiles, ffpInfo.currentLevelMaxMiles);
    }
    let faresPercentage = 0;
    if ((ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.segmentsForNextLevel) < (ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.currentLevelMaxSegments)) {
        faresPercentage = getPercent(ffpInfo.segmentsForNextLevel, ffpInfo.currentLevelMaxSegments);
    }
    return {
        nextLevel,
        milesPercentage,
        faresPercentage
    };
};
