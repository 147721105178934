import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OrderStatus } from '@websky/graphql';
import { Mode } from '../../types';
import { useIataCode, useToggleable } from '../../hooks';
import { ModeContext } from '../../context';
import { getOrder } from '../../Checkout/store/order/selectors';
import { getCheckinOrder } from '../../Modules/Checkin/store/order/selectors';
import { useConsentsCheckbox } from './Checkbox/Checkbox.hook';
import { isFlightFareM2 } from '../../utils';
const UNAVAILABLE_IATAS = ['A9', 'RM', 'DV', 'JI', '5G', 'SZ', '2S', 'WZ'];
const SOGAZ_INSURANCE_CODES = ['gen_trans_sogaz_avia_rw'];
export const useConsents = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { t } = useTranslation('Consents');
    const { isOpen: isFareModalOpen, toggle: toggleFareModal, close: closeFareModal } = useToggleable(false);
    const mode = useContext(ModeContext);
    const iataCode = useIataCode();
    let order;
    let isIncludeTransferTariff = false;
    if (mode === Mode.Checkin) {
        order = useSelector(getCheckinOrder);
    }
    else {
        order = useSelector(getOrder);
        isIncludeTransferTariff = (_a = order.flight.fares) === null || _a === void 0 ? void 0 : _a.some(fare => isFlightFareM2(fare));
    }
    const services = order.priceToPay;
    const isNeedToShowGeneralRules = ((_b = services.total) === null || _b === void 0 ? void 0 : _b.amount) > 0;
    const isNeedToShowInsurancesRules = ((_c = services.insurance) === null || _c === void 0 ? void 0 : _c.amount) > 0;
    const insurancesProductsLinks = useMemo(() => {
        var _a, _b, _c;
        if (!((_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances) === null || _b === void 0 ? void 0 : _b.selectedInsurances) === null || _c === void 0 ? void 0 : _c.length)) {
            return '';
        }
        const linksMap = new Map();
        order.additionalServices.insurances.selectedInsurances.forEach(insurance => {
            var _a;
            const code = insurance.insuranceProgram.code;
            if (!linksMap.has(code)) {
                const insuranceName = t('agreement_additionalInsuranceName', {
                    name: insurance.insuranceProgram.name
                });
                const formatInsurance = t('agreement_additionalInsuranceRules', {
                    link: `<a href='${(_a = insurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.urlToOffer}' target="_blank">${insuranceName}</a>`
                });
                linksMap.set(code, `<span>${formatInsurance}</span>`);
            }
        });
        return [...linksMap.values()].join('');
    }, [(_e = (_d = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _d === void 0 ? void 0 : _d.insurances) === null || _e === void 0 ? void 0 : _e.selectedInsurances]);
    const isAlphaInsuranceSelected = (_g = (_f = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _f === void 0 ? void 0 : _f.insurances) === null || _g === void 0 ? void 0 : _g.selectedInsurances.some(insurance => { var _a; return !SOGAZ_INSURANCE_CODES.includes((_a = insurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.code); }), isSogazInsuranceSelected = (_j = (_h = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _h === void 0 ? void 0 : _h.insurances) === null || _j === void 0 ? void 0 : _j.selectedInsurances.some(insurance => { var _a; return SOGAZ_INSURANCE_CODES.includes((_a = insurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.code); });
    const isNeedToShowGdsRules = [
        services === null || services === void 0 ? void 0 : services.vip,
        services === null || services === void 0 ? void 0 : services.businessLounge,
        services === null || services === void 0 ? void 0 : services.seats,
        services === null || services === void 0 ? void 0 : services.meals,
        services === null || services === void 0 ? void 0 : services.baggage
    ].some(price => (price === null || price === void 0 ? void 0 : price.amount) > 0);
    const interpolatedRulesLink = t('agreement_interpolation', {
        generalRules: isNeedToShowGeneralRules ? t('agreement_generalRules') : '',
        insurancesRules: isNeedToShowInsurancesRules
            ? t('agreement_insurancesRules', {
                insurancesProductsLinks,
                // will be ignored unless custom translate contains aliases below (WZ theme)
                alphaGeneralRules: isAlphaInsuranceSelected ? t('agreement_insurancesRules_alpha') : '',
                sogazGeneralRules: isSogazInsuranceSelected ? t('agreement_insurancesRules_sogaz') : ''
            })
            : '',
        additionalServiceRules: isNeedToShowGdsRules ? t('agreement_gdsRules') : ''
    });
    const rules = interpolatedRulesLink ? t('agreement_rules', { links: interpolatedRulesLink }) : '';
    const transferRules = t('agreement_transferRules', { links: t('agreement_transferLink') });
    const isNeedToShowRules = useMemo(() => {
        const availableOrderStatus = order.status !== OrderStatus.Cancelled;
        const availableByIata = !UNAVAILABLE_IATAS.includes(iataCode);
        return availableOrderStatus && availableByIata && !!rules;
    }, [iataCode, rules, order.status]);
    const isNeedToShowTransferRules = useMemo(() => {
        return order.status === OrderStatus.Booked && isIncludeTransferTariff && !!transferRules;
    }, [transferRules, isIncludeTransferTariff, order.status]);
    const commonConsentsCheckbox = useConsentsCheckbox({ needConfirm: isNeedToShowRules });
    const transferConsentsCheckbox = useConsentsCheckbox({ needConfirm: isIncludeTransferTariff });
    const onClick = (e) => {
        const target = e.target;
        if (target.hasAttribute('data-type')) {
            e.preventDefault();
            const linkType = target.getAttribute('data-type');
            if (linkType === 'termsOfTariff') {
                toggleFareModal();
            }
        }
    };
    const onConfirm = useCallback(() => {
        if (commonConsentsCheckbox.isNeedConfirm) {
            commonConsentsCheckbox.onConfirm();
        }
        if (transferConsentsCheckbox.isNeedConfirm) {
            transferConsentsCheckbox.onConfirm();
        }
    }, [
        commonConsentsCheckbox.isNeedConfirm,
        commonConsentsCheckbox.onConfirm,
        transferConsentsCheckbox.isNeedConfirm,
        transferConsentsCheckbox.onConfirm
    ]);
    return {
        // data
        order,
        rules,
        transferRules,
        isNeedToShowRules,
        isNeedToShowTransferRules,
        isFareModalOpen,
        // checkboxes
        commonConsentsCheckbox,
        transferConsentsCheckbox,
        // methods
        onClick,
        onConfirm: commonConsentsCheckbox.isNeedConfirm || transferConsentsCheckbox.isNeedConfirm ? onConfirm : null,
        toggleFareModal,
        closeFareModal
    };
};
