import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import flatten from 'lodash/flatten';
import { isCheckoutOrderGuard } from '../../typeguards';
export const upgradeClassStateFactory = (order) => {
    var _a, _b, _c;
    const state = {
        orderId: order.id,
        service: null,
        selectedSegments: [],
        step: 'select',
        travellersIds: [],
        flights: []
    };
    const orderCurrency = order.price.total.currency;
    state.service = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
        service.subType === GdsServiceVipSubType.Upgrade);
    if (isCheckoutOrderGuard(order)) {
        state.flights = order.flight.segmentGroups.map(group => {
            return Object.assign(Object.assign({}, group), { segments: group.segments.filter(segment => {
                    return (flatten(state.service.allowedSegments).includes(segment.id) && segment.subStatus !== 'Upgrade');
                }), flightId: order.flight.id });
        });
    }
    else {
        state.flights = order.segments;
    }
    state.travellersIds = order.travellers.map(traveller => traveller.id);
    state.totalPrice = {
        amount: 0,
        currency: orderCurrency
    };
    return state;
};
