import { useMemo } from 'react';
import { OrderStatus } from '@websky/graphql';
import { CheckoutSteps } from './types';
import { CartService } from '../Cart/types';
import { convertCartServiceProps, isCheckoutOrder as getIsCheckoutOrder } from './utils';
const CART_SERVICE_MAP = {
    [CheckoutSteps.Flights]: CartService.Flights,
    [CheckoutSteps.Seats]: CartService.Seats,
    [CheckoutSteps.Baggage]: CartService.Baggage,
    [CheckoutSteps.Meal]: CartService.Meal,
    [CheckoutSteps.Extras]: CartService.Extra,
    [CheckoutSteps.Passengers]: CartService.Passengers,
    [CheckoutSteps.Insurance]: CartService.Insurances
};
export const useMobileStepbar = (order, stepbarConfig) => {
    const isCheckoutOrder = getIsCheckoutOrder(order);
    const isBookedOrConfirmed = useMemo(() => {
        return order.status === OrderStatus.Booked || order.status === OrderStatus.Confirmed;
    }, [order.status]);
    const availableSteps = useMemo(() => {
        return Object.values(stepbarConfig !== null && stepbarConfig !== void 0 ? stepbarConfig : {}).filter(step => !step.isHidden);
    }, [stepbarConfig]);
    const [cartServices, additionalSteps] = useMemo(() => {
        const cartServicesSet = new Set();
        const additionalStepsSet = new Set();
        availableSteps.forEach(step => {
            const cartService = CART_SERVICE_MAP[step.type];
            if (cartService !== undefined) {
                cartServicesSet.add(cartService);
            }
            else {
                additionalStepsSet.add(step.type);
            }
        });
        if (isBookedOrConfirmed) {
            if (!cartServicesSet.has(CartService.Passengers) && additionalStepsSet.has(CheckoutSteps.Order)) {
                cartServicesSet.add(CartService.Passengers);
                additionalStepsSet.delete(CheckoutSteps.Order);
            }
            if (isCheckoutOrder) {
                cartServicesSet.add(CartService.Extra);
            }
        }
        return [[...cartServicesSet], [...additionalStepsSet]];
    }, [order, availableSteps, isBookedOrConfirmed, isCheckoutOrder]);
    const cartServiceProps = useMemo(() => convertCartServiceProps(order), [order]);
    return {
        cartServiceProps,
        cartServices,
        availableSteps,
        additionalSteps,
        isBookedOrConfirmed,
        isCheckoutOrder
    };
};
