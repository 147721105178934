import * as React from 'react';
import { isDemo as isDemoInstance } from '../../utils';
const IsDemo = () => {
    React.useEffect(() => {
        if (isDemoInstance) {
            window.document.body.setAttribute('data-demo-wx', 'true');
        }
    }, []);
    return null;
};
export default IsDemo;
