import { TravellerFieldEnum } from '@websky/graphql';
import { AdditionalTravellerFieldEnum } from '../../../../Passenger/types';
import i18n from 'i18next';
import { overridedIcons } from '../../../../Icons';
export var FieldsGroupType;
(function (FieldsGroupType) {
    FieldsGroupType["Identity"] = "Identity";
    FieldsGroupType["Documents"] = "Documents";
    FieldsGroupType["Loyalty"] = "Loyalty";
    FieldsGroupType["Contacts"] = "Contacts";
    FieldsGroupType["Changes"] = "Changes";
    FieldsGroupType["Other"] = "Other";
    FieldsGroupType["Accompanying"] = "accompanying";
})(FieldsGroupType || (FieldsGroupType = {}));
export const getGroupIcon = (type) => {
    switch (type) {
        case FieldsGroupType.Identity:
            return overridedIcons['Person'];
        case FieldsGroupType.Documents:
            return overridedIcons['Passport'];
        case FieldsGroupType.Loyalty:
            return overridedIcons['Star'];
        case FieldsGroupType.Other:
            return overridedIcons['DisabledMan'];
        default:
            return null;
    }
};
export const getGroupsMap = (fields, passengerId, isChangePassengers) => {
    const groups = {
        [FieldsGroupType.Identity]: [],
        [FieldsGroupType.Documents]: [],
        [FieldsGroupType.Contacts]: [],
        [FieldsGroupType.Accompanying]: [],
        [FieldsGroupType.Loyalty]: [],
        [FieldsGroupType.Changes]: [],
        [FieldsGroupType.Other]: []
    };
    fields.forEach(originalField => {
        const field = Object.assign(Object.assign({}, originalField), { name: `passengers[${passengerId}].${originalField.name}`, placeholder: i18n.t(`Passenger:${originalField.placeholder}`) });
        switch (field.type) {
            case TravellerFieldEnum.LastName:
            case TravellerFieldEnum.FirstName:
            case TravellerFieldEnum.MiddleName:
            case TravellerFieldEnum.BirthDate:
            case TravellerFieldEnum.Gender:
                groups[FieldsGroupType.Identity].push(field);
                break;
            case TravellerFieldEnum.Nationality:
            case TravellerFieldEnum.DocType:
            case TravellerFieldEnum.DocNumber:
            case TravellerFieldEnum.DocExpiryDate:
                groups[FieldsGroupType.Documents].push(field);
                break;
            case TravellerFieldEnum.LoyaltyNumber:
            case TravellerFieldEnum.LoyaltyAirline:
                groups[FieldsGroupType.Loyalty].push(field);
                break;
            case TravellerFieldEnum.Phone:
            case TravellerFieldEnum.Email:
                groups[FieldsGroupType.Contacts].push(field);
                break;
            case TravellerFieldEnum.LinkedTraveller:
                groups[FieldsGroupType.Accompanying].push(field);
                break;
            case AdditionalTravellerFieldEnum.ChangeTravellerFile:
                groups[FieldsGroupType.Changes].push(field);
                break;
            default:
                groups[FieldsGroupType.Other].push(field);
        }
    });
    let groupsArray = [];
    for (const group in groups) {
        if (groups.hasOwnProperty(group) && groups[group].length) {
            groupsArray.push({
                type: group,
                fields: groups[group]
            });
        }
    }
    if (isChangePassengers) {
        groupsArray = groupsArray.filter(group => ![FieldsGroupType.Loyalty, FieldsGroupType.Other].includes(group.type));
    }
    return groupsArray;
};
