import { REMOVE_VOUCHER, SET_VOUCHER } from './actions';
const initialState = {
    code: '',
    status: null,
    money: null
};
export const vouchersReducer = (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_VOUCHER:
            return initialState;
        case SET_VOUCHER:
            return action.payload;
        default:
            return state;
    }
};
