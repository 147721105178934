import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import cn from 'classnames';
import { useToggleable } from '../../../../hooks';
import { useTheme } from '../../../../theme';
import { Money } from '../../../../Money';
import { Expand } from '../../../../Icons';
const Receipt = ({ className, totalText, totalPrice, detailPrices }) => {
    const { t } = useTranslation('EditPassenger');
    const { Receipt: theme } = useTheme('EditPassenger');
    const { isOpen, toggle } = useToggleable(false);
    const hasDetailPrices = detailPrices && detailPrices.length > 0;
    const onToggle = () => {
        if (hasDetailPrices) {
            toggle();
        }
    };
    return (React.createElement("div", { className: cn(theme.container, className) },
        React.createElement("div", { className: cn(theme.summary, {
                [theme.summary_collapsible]: hasDetailPrices,
                [theme.summary_expand]: isOpen
            }), onClick: onToggle },
            React.createElement("div", { className: theme.summary__title },
                totalText || t('Total to pay'),
                React.createElement(Money, { moneyClassName: theme.summary__money, money: totalPrice })),
            hasDetailPrices && React.createElement("div", { className: theme.summary__arrow }, Expand)),
        hasDetailPrices && (React.createElement(Collapse, { in: isOpen, mountOnEnter: true, unmountOnExit: true },
            React.createElement("div", { className: theme.details }, detailPrices.map((price, index) => (React.createElement("div", { className: theme.details__row, key: index },
                React.createElement("span", null, price.title),
                React.createElement(Money, { moneyClassName: theme.details__money, money: price.price })))))))));
};
export default Receipt;
