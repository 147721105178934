import { createSelector } from 'reselect';
import { currentCurrency } from '../../../utils';
import { getSelectedServices } from '../selectedServices/selectors';
import { getBaggageDelivery, getBusinessLounge, getCapsuleHotel } from '../order/selectors';
const zeroPrice = {
    amount: 0,
    currency: currentCurrency
};
export const calculatePrice = (services, selectedServices) => {
    const totalAmount = selectedServices.reduce((totalAmount, passengerServices) => {
        var _a, _b;
        let passengerAmount = 0;
        (_b = (_a = passengerServices === null || passengerServices === void 0 ? void 0 : passengerServices.gdsServices) === null || _a === void 0 ? void 0 : _a.setServices) === null || _b === void 0 ? void 0 : _b.forEach(selectedService => {
            var _a;
            const service = services.find(service => service.id === selectedService.serviceId);
            if (service) {
                passengerAmount += (((_a = service.price) === null || _a === void 0 ? void 0 : _a.amount) || 0) * selectedService.count;
            }
        });
        return passengerAmount + totalAmount;
    }, 0);
    return totalAmount > 0
        ? {
            amount: totalAmount,
            currency: currentCurrency
        }
        : null;
};
export const getState = (state) => state.orderPrice;
export const getPrice = createSelector(getState, ({ price }) => price);
export const getMealsPrice = createSelector(getPrice, ({ meals }) => meals !== null && meals !== void 0 ? meals : zeroPrice);
export const getSeatsPrice = createSelector(getPrice, ({ seats }) => seats !== null && seats !== void 0 ? seats : zeroPrice);
export const getBaggagePrice = createSelector(getPrice, ({ baggage }) => baggage !== null && baggage !== void 0 ? baggage : zeroPrice);
export const getTotalPrice = createSelector(getPrice, ({ total }) => total !== null && total !== void 0 ? total : zeroPrice);
export const getAeroexpressPrice = createSelector(getPrice, ({ aeroexpress }) => aeroexpress !== null && aeroexpress !== void 0 ? aeroexpress : zeroPrice);
export const getInsurancePrice = createSelector(getPrice, ({ insurance }) => insurance !== null && insurance !== void 0 ? insurance : zeroPrice);
export const getBusinessLoungePrice = createSelector(getPrice, ({ businessLounge }) => businessLounge !== null && businessLounge !== void 0 ? businessLounge : zeroPrice);
export const getCalculatedBusinessLoungePrice = createSelector(getBusinessLounge, getSelectedServices, calculatePrice);
export const getCalculatedCapsuleHotelPrice = createSelector(getCapsuleHotel, getSelectedServices, calculatePrice);
export const getCalculatedBaggageDeliveryPrice = createSelector(getBaggageDelivery, getSelectedServices, calculatePrice);
export const getPriceToPay = createSelector(getState, ({ priceToPay }) => {
    const priceToPayWithZeroPrice = {};
    Object.keys(priceToPay).forEach(key => {
        var _a;
        priceToPayWithZeroPrice[key] = (_a = priceToPay[key]) !== null && _a !== void 0 ? _a : zeroPrice;
    });
    return priceToPayWithZeroPrice;
});
export const getSeatsPriceToPay = createSelector(getPriceToPay, ({ seats }) => seats !== null && seats !== void 0 ? seats : zeroPrice);
export const getChargePriceToPay = createSelector(getPriceToPay, ({ charge }) => charge !== null && charge !== void 0 ? charge : zeroPrice);
export const getAeroexpressPriceToPay = createSelector(getPriceToPay, ({ aeroexpress }) => aeroexpress !== null && aeroexpress !== void 0 ? aeroexpress : zeroPrice);
export const getInsurancePriceToPay = createSelector(getPriceToPay, ({ insurance }) => insurance !== null && insurance !== void 0 ? insurance : zeroPrice);
export const getBaggagePriceToPay = createSelector(getPriceToPay, ({ baggage }) => baggage !== null && baggage !== void 0 ? baggage : zeroPrice);
export const getMealsPriceToPay = createSelector(getPriceToPay, ({ meals }) => meals !== null && meals !== void 0 ? meals : zeroPrice);
export const getAirticketPriceToPay = createSelector(getPriceToPay, ({ airticket }) => airticket !== null && airticket !== void 0 ? airticket : zeroPrice);
export const getTotalPriceToPay = createSelector(getPriceToPay, ({ total }) => total !== null && total !== void 0 ? total : zeroPrice);
export const getCalculatedVipServicesPrice = (services) => createSelector(getSelectedServices, selectedServices => calculatePrice(services, selectedServices));
