import { combineReducers } from 'redux';
import passengers from './passengers/reducer';
import mode from './mode/reducers';
import steps from './steps/reducer';
import reason from './reason/reducer';
import documents from './documents/reducer';
import customer from './customer/reducer';
import flightId from './flightId/reducer';
import orderId from './orderId/reducer';
import refundInfo from './refundInfo/reducer';
import aviaOrder from './aviaOrder/reducer';
export default combineReducers({
    passengers,
    mode,
    steps,
    reason,
    documents,
    customer,
    flightId,
    orderId,
    refundInfo,
    aviaOrder
});
