import { addDays, format, getDate, getDaysInMonth, parse, parseISO } from 'date-fns';
import { PassengerType } from '../enums';
import { Locale } from '../types';
import { getAirport, getDateWithoutTimezone } from '../Engine/components/FastSearch/utils';
import { getCurrency } from '../cache';
import { getLocale } from '../utils';
import { AviaPassengerType } from '@websky/graphql';
export const SPECIAL_CATEGORIES = [
    PassengerType.Teenager_GCF,
    PassengerType.YoungAdult,
    PassengerType.RetireeF,
    PassengerType.RetireeM,
    PassengerType.Teenager,
    PassengerType.Teenager_F7,
    PassengerType.Retiree_F7,
    PassengerType.Adult_D2,
    PassengerType.Child_D2,
    PassengerType.Infant_D2,
    PassengerType.InfantWithSeat_D2,
    PassengerType.Retiree_D2,
    PassengerType.AdultWithChild_D2,
    PassengerType.Family_D2,
    PassengerType.Teenager_D2
];
export const setFieldDataMutator = (args, state) => {
    const [name, data] = args;
    const field = state.fields[name];
    if (field) {
        field.data = Object.assign(Object.assign({}, field.data), data);
    }
};
export const convertPassengersSearchParameters = (passengers, passengerConfig) => {
    const result = {};
    passengers.forEach(passenger => {
        if (passenger.extendedPassengerType) {
            if (passengerConfig && !passengerConfig.hasOwnProperty(passenger.extendedPassengerType)) {
                return;
            }
            result[passenger.extendedPassengerType] = passenger.count;
            if (passenger.count > 0 &&
                !passengers.find(otherPassenger => otherPassenger.passengerType === passenger.passengerType &&
                    !otherPassenger.extendedPassengerType)) {
                result[passenger.passengerType] = 0;
            }
        }
        else {
            result[passenger.passengerType] = passenger.count;
        }
    });
    return result;
};
export const convertSegmentsSearchParameters = (segments) => {
    const simpleRouteSegment = {
        departure: null,
        arrival: null,
        date: null,
        dateBack: null
    };
    const isRT = segments.length === 2 &&
        segments[0].arrival.iata === segments[1].departure.iata &&
        segments[0].departure.iata === segments[1].arrival.iata;
    const isMultiCity = segments.length > 1 && !isRT;
    if (!isMultiCity) {
        simpleRouteSegment.departure = getAirport(segments[0].departure);
        simpleRouteSegment.arrival = getAirport(segments[0].arrival);
        simpleRouteSegment.date = getDateWithoutTimezone(new Date(segments[0].date));
        simpleRouteSegment.dateBack = null;
        if (isRT) {
            simpleRouteSegment.dateBack = getDateWithoutTimezone(new Date(segments[1].date));
        }
    }
    return {
        isMultiCity,
        simpleRouteSegment,
        multiCitySegments: isMultiCity
            ? segments.map(segment => ({
                departure: segment.departure,
                arrival: segment.arrival,
                date: getDateWithoutTimezone(new Date(segment.date)),
                dateBack: null
            }))
            : []
    };
};
export const createSearchParameters = (passengersState, segmentsState, passengersConfig, promoCode, redeemMiles) => {
    const passengers = [];
    for (const passengerType in passengersState) {
        if (passengersState.hasOwnProperty(passengerType) && passengersState[passengerType]) {
            const config = passengersConfig[passengerType];
            passengers.push({
                passengerType: (config === null || config === void 0 ? void 0 : config.parentCode)
                    ? config.parentCode
                    : passengerType,
                extendedPassengerType: (config === null || config === void 0 ? void 0 : config.parentCode) ? passengerType : null,
                count: passengersState[passengerType]
            });
        }
    }
    return {
        promotionCode: promoCode,
        ffpMode: redeemMiles,
        currency: getCurrency(),
        segments: (segmentsState.isMultiCity
            ? segmentsState.multiCitySegments.sort((a, b) => a.date && b.date ? a.date.getTime() - b.date.getTime() : 0)
            : segmentsState.simpleRouteSegment.dateBack
                ? [
                    {
                        departure: segmentsState.simpleRouteSegment.departure,
                        arrival: segmentsState.simpleRouteSegment.arrival,
                        date: segmentsState.simpleRouteSegment.date
                    },
                    {
                        departure: segmentsState.simpleRouteSegment.arrival,
                        arrival: segmentsState.simpleRouteSegment.departure,
                        date: segmentsState.simpleRouteSegment.dateBack
                    }
                ]
                : [segmentsState.simpleRouteSegment]).map(segment => {
            let date;
            try {
                date = format(segment.date, 'yyyy-MM-dd');
            }
            catch (e) { }
            return {
                date: date !== null && date !== void 0 ? date : null,
                departure: {
                    iata: segment.departure ? segment.departure.iata : ''
                },
                arrival: {
                    iata: segment.arrival ? segment.arrival.iata : ''
                }
            };
        }),
        passengers
    };
};
export const daysLeftBeforeMonthEnd = (from) => {
    return getDaysInMonth(from) - getDate(from) + 1;
};
export const getMinPricesRequestVariables = (locations, passengers, date, replaceLocations = false) => {
    let segments;
    const passengerTypesToSend = [
        AviaPassengerType.ADT,
        AviaPassengerType.CLD,
        AviaPassengerType.INF,
        AviaPassengerType.INS
    ];
    if (replaceLocations) {
        segments = {
            date: format(new Date(), 'yyyy-MM-dd'),
            departure: { iata: locations.arrival && locations.arrival.iata },
            arrival: { iata: locations.departure && locations.departure.iata }
        };
    }
    else {
        segments = {
            date: format(new Date(), 'yyyy-MM-dd'),
            departure: { iata: locations.departure && locations.departure.iata },
            arrival: { iata: locations.arrival && locations.arrival.iata }
        };
    }
    return {
        passengers: passengers.filter(passenger => passengerTypesToSend.includes(passenger.passengerType)),
        daysCount: 365,
        segments: [segments]
    };
};
export const getPrices = (flightsMinPricesResponse) => {
    const pricesObject = {};
    if (flightsMinPricesResponse && flightsMinPricesResponse.FlightsMinPricesInPeriod) {
        flightsMinPricesResponse.FlightsMinPricesInPeriod.datesWithLowestPrices.forEach(minPrice => {
            var _a;
            if (minPrice) {
                pricesObject[parse(minPrice.date, 'yyyy-MM-dd', new Date()).toISOString()] = {
                    price: Object.assign({}, minPrice.price),
                    hasFlight: !!((_a = minPrice === null || minPrice === void 0 ? void 0 : minPrice.info) === null || _a === void 0 ? void 0 : _a[0])
                };
            }
        });
    }
    return pricesObject;
};
export const createFastSearchURL = (parameters) => {
    let result = '';
    parameters.segments.forEach(segment => {
        result += `${segment.departure.iata}${segment.arrival.iata}${format(parseISO(segment.date), 'yyyyMMdd')}`;
    });
    parameters.passengers.forEach(({ passengerType, extendedPassengerType, count }) => {
        if (count) {
            result += `${extendedPassengerType ? extendedPassengerType : passengerType}${count}`;
        }
    });
    if (parameters.promotionCode) {
        result += `PROMO${parameters.promotionCode}`;
    }
    if (parameters.ffpMode) {
        result += 'PAYBYMILES';
    }
    if (parameters.currency) {
        result += parameters.currency;
    }
    return result;
};
const OSTROVOK_MAX_ADULTS = 6;
const OSTROVOK_MAX_CHILDS = 4;
const getChildGuests = (passengers) => {
    return passengers.reduce((ages, passenger) => {
        switch (passenger.passengerType) {
            case AviaPassengerType.INF:
            case AviaPassengerType.INS:
                return [...ages, ...new Array(passenger.count).fill(2)];
            case AviaPassengerType.CLD:
                return [...ages, ...new Array(passenger.count).fill(12)];
        }
        return ages;
    }, []);
};
const ostrovokSupplierLink = (data) => {
    const url = new URL(data.baseUrl);
    const formatDate = (date) => {
        return format(date, 'dd.MM.yyyy');
    };
    const childGuests = getChildGuests(data.passengers), adultsCount = data.passengersCount - childGuests.length;
    let guestsParam = '';
    if (adultsCount > 0 && adultsCount <= OSTROVOK_MAX_ADULTS && childGuests.length <= OSTROVOK_MAX_CHILDS) {
        guestsParam = adultsCount.toString();
        if (childGuests.length > 0) {
            guestsParam += `and${childGuests.join('.')}`;
        }
    }
    url.searchParams.set('q', data.destination.codes[0].code.toString());
    url.searchParams.set('dates', [formatDate(data.startDate), formatDate(data.endDate)].join('-'));
    if (!!guestsParam) {
        url.searchParams.set('guests', guestsParam);
    }
    if (data.utm) {
        url.searchParams.set('utm_source', data.utm);
    }
    url.searchParams.set('lang', getOstrovokISOLocale(data.iataCode));
    return url.toString();
};
export const oneTwoTripSupplierLink = (data, additionalId) => {
    const apiURl = new URL('https://www.onetwotrip.com/_hotels/api/comparer');
    const formatDate = (date) => {
        return format(date, 'yyyy-MM-dd');
    };
    const childs = getChildGuests(data.passengers);
    apiURl.searchParams.set('iata', data.destination.iata);
    apiURl.searchParams.set('date_start', formatDate(data.startDate));
    apiURl.searchParams.set('date_end', formatDate(data.endDate));
    apiURl.searchParams.set('adults', (data.passengersCount - childs.length).toString());
    if (!!childs.length) {
        apiURl.searchParams.set('children', getChildGuests(data.passengers).join(','));
    }
    if (data.utm) {
        apiURl.searchParams.set('utm_source', data.utm);
    }
    return fetch(apiURl.toString())
        .then(response => {
        return response.json();
    })
        .then(data => {
        var _a;
        if (data.result.options[0].url) {
            return (_a = data.result.options[0].url.match(/(\/search\/.+)/)) === null || _a === void 0 ? void 0 : _a[0];
        }
        throw 'Incorrect response';
    })
        .then(parsedUrl => {
        const url = new URL(data.baseUrl);
        url.searchParams.set(additionalId, parsedUrl);
        return url.toString();
    })
        .catch(() => {
        return new URL(data.baseUrl).toString();
    });
};
export const generateOfferHotelLink = (baseUrl, segments, passengersCount, passengers, iataCode, supplierType = 'OSTROVOK', additionalId, utm) => {
    const hotelsData = {
        baseUrl,
        destination: null,
        startDate: null,
        endDate: null,
        passengersCount,
        passengers,
        iataCode,
        utm
    };
    try {
        const lastSegment = segments[segments.length - 1];
        hotelsData.destination = lastSegment.arrival;
        hotelsData.startDate = lastSegment.date;
        hotelsData.endDate = lastSegment.dateBack;
        if (!hotelsData.endDate) {
            hotelsData.endDate = addDays(hotelsData.startDate, 1);
        }
        if (supplierType === 'OSTROVOK') {
            return Promise.resolve(ostrovokSupplierLink(hotelsData));
        }
        else if (supplierType === 'ONETWOTRIP') {
            return oneTwoTripSupplierLink(hotelsData, additionalId);
        }
    }
    catch (e) {
        return null;
    }
    return null;
};
export const getOstrovokISOLocale = (iataCode) => {
    const locale = getLocale();
    if (iataCode === 'RM' && locale === Locale.Armenian) {
        return Locale.Russian;
    }
    switch (locale) {
        case Locale.Chinese:
            return 'zh_CN';
        default:
            return locale;
    }
};
