import * as React from 'react';
import { memo, useMemo } from 'react';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../../theme';
import Autocomplete from '../../../../Autocomplete';
import { useNationality } from './hooks';
const Nationality = memo(({ label, field, fieldState, onChange, className, error, onBlur, disabled, variant, options, autofocus }) => {
    const { Input: inputCss } = useTheme('Input');
    const model = useNationality({ options, onChange, field, fieldState });
    const autocompleteTheme = useMemo(() => ({
        Autocomplete: {
            Autocomplete: {
                option: inputCss.optionLabel,
                disabled: inputCss.disabled,
                inner_input: inputCss.input
            }
        }
    }), []);
    return (React.createElement(ThemeProvider, { value: autocompleteTheme },
        React.createElement(Autocomplete, { name: field.name, onFocus: model.handleFocus, label: label, variant: variant, autofocus: autofocus, callOnSelectOn: 'mouseDown', onClickOutsideProps: {
                outsideClickIgnoreClass: 'MuiInputBase-input'
            }, inputValue: model.value, onSelect: model.handleSelect, onBlur: model.handleBlur, onInputChange: model.handleChange, options: model.options, className: cn(inputCss.nationality, className), error: error, disabled: disabled })));
});
export default Nationality;
