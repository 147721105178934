export function getSelectedInsuranceSaveCodes(saveOrderServicesParams, availableInsuranceCodes) {
    var _a, _b;
    const selectedSaveServicesCodesSet = new Set();
    if ((_b = (_a = saveOrderServicesParams === null || saveOrderServicesParams === void 0 ? void 0 : saveOrderServicesParams.params) === null || _a === void 0 ? void 0 : _a.insurances) === null || _b === void 0 ? void 0 : _b.length) {
        saveOrderServicesParams === null || saveOrderServicesParams === void 0 ? void 0 : saveOrderServicesParams.params.insurances.forEach(insurance => {
            const selectedCodes = insurance.selectedPrograms;
            selectedCodes.forEach(code => {
                // filter by available insurance programs
                if (!(availableInsuranceCodes === null || availableInsuranceCodes === void 0 ? void 0 : availableInsuranceCodes.length) || availableInsuranceCodes.includes(code)) {
                    selectedSaveServicesCodesSet.add(code);
                }
            });
        });
    }
    return [...selectedSaveServicesCodesSet];
}
