import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import OrderRefundStatus from '../../../../../Refund/Components/OrderRefundStatus/OrderRefundStatus';
import { ExareStatus } from '@websky/graphql';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../index';
import { ChevronRightIcon } from '../../../../../AircraftPopup/Icons';
import { useTheme } from '../../../../../theme';
const ExchangeCheckoutStatus = props => {
    const { status, onGoToTicket, passengersNames, splittedOrderId } = props;
    const { ExchangeCheckoutStatus: theme } = useTheme('Exchange');
    const history = useHistory();
    const { t } = useTranslation('Exchange');
    const titleRenderer = useCallback(() => {
        switch (status) {
            case ExareStatus.InProcess:
            case ExareStatus.Refused:
            case ExareStatus.Rejected:
            case ExareStatus.TimelimitExpired:
                return t('Request for changes');
            case ExareStatus.Finished:
                return (React.createElement("span", null, [
                    t('Successfully create new booking'),
                    splittedOrderId,
                    passengersNames ? `${t('for')} ${passengersNames}` : null
                ]
                    .filter(Boolean)
                    .join(' ')));
            default:
                return t('Request for changes');
        }
    }, [status, passengersNames, splittedOrderId]);
    const handleGoToNewBooking = useCallback(() => {
        history.push(`/booking/${splittedOrderId}`);
        window.location.reload();
    }, [splittedOrderId]);
    const renderStatus = status === ExareStatus.Finished && splittedOrderId
        ? useCallback(() => {
            return (React.createElement(Button, { onClick: handleGoToNewBooking },
                t('View new booking'),
                "\u00A0",
                splittedOrderId,
                React.createElement("span", { className: theme.icon }, ChevronRightIcon)));
        }, [status, splittedOrderId, handleGoToNewBooking])
        : null;
    return (React.createElement(OrderRefundStatus, Object.assign({}, props, { customTranslate: t, renderTitle: titleRenderer, onGoToTicket: onGoToTicket, renderStatus: renderStatus })));
};
export default ExchangeCheckoutStatus;
