import { createTheme } from '../utils';
import * as QuickSearchForm from './components/QuickSearchForm.css';
import * as Summary from './components/Summary/Summary.css';
import * as DummySummary from './components/DummySummary/DummySummary.css';
export const defaultTheme = {
    QuickSearchForm,
    Summary,
    DummySummary
};
export default defaultTheme;
export const { useTheme } = createTheme(defaultTheme);
