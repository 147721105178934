import * as React from 'react';
import MuiSelect from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
export const Select = props => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [value, setValue] = React.useState(props.value ? props.value : '');
    const { t } = useTranslation('Passenger');
    const { Input: inputCss } = useTheme('Input');
    const { Select: css } = useTheme('Passenger');
    const variant = props.variant || 'filled';
    const onChange = (e) => {
        var _a;
        if (typeof e.target.value === 'string') {
            const selected = e.target.value;
            setValue(selected);
            (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, e.target.value, e);
        }
    };
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    useEffect(() => {
        if ((props.options.length === 1 || props.selectFirstOptionByDefault) && !props.value) {
            const optionValue = props.options[0].key;
            const changeEvent = {
                target: {
                    value: optionValue
                }
            };
            setValue(optionValue);
            props === null || props === void 0 ? void 0 : props.onChange(optionValue, changeEvent);
        }
    }, [props.options, props.selectFirstOptionByDefault, props.value]);
    return (React.createElement(FormControl, { className: cn(props.className), error: !!props.error, variant: variant },
        React.createElement(InputLabel, { htmlFor: `select-${props.name}`, classes: {
                root: cn(inputCss.textField__root, (_a = props.classes) === null || _a === void 0 ? void 0 : _a.label, css.label, {
                    [inputCss.textField_standard]: variant === 'standard'
                }),
                focused: cn(inputCss.textField, inputCss.textField_focused, (_b = props.classes) === null || _b === void 0 ? void 0 : _b.label_focused),
                error: cn(inputCss.label_error, (_c = props.classes) === null || _c === void 0 ? void 0 : _c.label_error),
                shrink: inputCss.label_shrink,
                outlined: inputCss.textField_outlined,
                filled: inputCss.textField_filled
            } }, props.label),
        React.createElement(MuiSelect, { renderValue: props.renderValue, value: value, name: props.name, MenuProps: props.menuProps, input: React.createElement(Input, { classes: {
                    disabled: cn(inputCss.disabled, (_d = props.classes) === null || _d === void 0 ? void 0 : _d.input_disabled),
                    // underline: css.select,
                    root: cn(css.input, inputCss.input, inputCss.real_input, {
                        [inputCss.input_filled]: variant === 'filled',
                        [inputCss.input_outline]: variant === 'outlined',
                        [inputCss.input_standard]: variant === 'standard',
                        [css.input_standard]: variant === 'standard',
                        [css.input_readonly]: props.readonly
                    }, (_e = props.classes) === null || _e === void 0 ? void 0 : _e.input),
                    error: cn(css.error, (_f = props.classes) === null || _f === void 0 ? void 0 : _f.input_error),
                    focused: cn(inputCss.input_focused, (_g = props.classes) === null || _g === void 0 ? void 0 : _g.input_focused),
                    underline: inputCss.underline,
                    input: cn(css.real_input, { [css.real_input_noLabel]: !props.label })
                }, name: props.name, id: `select-${props.name}`, disableUnderline: variant !== 'standard', readOnly: props.readonly }), classes: {
                icon: css.icon
            }, disabled: props.disabled, onChange: onChange, onBlur: props.onBlur, readOnly: props.readonly }, props.options.map(option => {
            if (props.renderOption) {
                return props.renderOption(option);
            }
            else {
                return (React.createElement(MenuItem, { key: option.key, value: option.key, className: css.option }, props.translateValue ? t(option.value) : option.value));
            }
        })),
        props.error && React.createElement(FormHelperText, { classes: { root: css.helperText } }, props.error)));
};
