import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCheckinOrder, getCheckinSegments, getDeprecatedPassengersState, makeGetGdsServicesByType } from '../../../Modules/Checkin/store/order/selectors';
import { checkoutSelectedServicesStateFactory } from '../../../Checkout/utils';
import { addService, removeService, saveServices } from '../../../Modules/Checkin/store/selectedServices/actions';
import { getSelectedServices } from '../../../Modules/Checkin/store/selectedServices/selectors';
import { getIsServicesSaving } from '../../../Modules/Checkin/store/servicesLoading/selectors';
import { useTranslation } from 'react-i18next';
import { getPassengerPricesMap } from '../../../CheckoutAdditionalService/utils';
import { Money } from '../../../Money';
import { getPassengerTypeLabel } from '../../../utils';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
export const CheckinBusinessLoungeAdapter = ({ children, onClose }) => {
    var _a;
    const { t } = useTranslation('Checkin');
    const passengers = useSelector(getDeprecatedPassengersState);
    const businessLoungeSelector = makeGetGdsServicesByType(OrderAdditionalServiceGdsServiceServiceType.BusinessLounge);
    const businessLoungeServices = useSelector(businessLoungeSelector);
    const checkinSelectedServices = useSelector(getSelectedServices);
    const segments = useSelector(getCheckinSegments);
    const order = useSelector(getCheckinOrder);
    const isLoading = useSelector(getIsServicesSaving);
    const dispatch = useDispatch();
    let minPrice = null;
    const totalPrice = (_a = order === null || order === void 0 ? void 0 : order.price) === null || _a === void 0 ? void 0 : _a.businessLounge;
    minPrice = businessLoungeServices
        .filter(service => service.price.amount !== 0)
        .reduce((minPrice, current) => {
        if (!minPrice) {
            return current.price;
        }
        if (minPrice.amount > current.price.amount) {
            return current.price;
        }
        else {
            return minPrice;
        }
    }, minPrice);
    const onSave = (selectedServices) => {
        var _a, _b;
        for (const selectedVipService of selectedServices) {
            const relatedCheckinOrderService = businessLoungeServices.find(businessLoungeService => businessLoungeService.id === selectedVipService.serviceId);
            const isSelected = !!((_b = (_a = checkinSelectedServices === null || checkinSelectedServices === void 0 ? void 0 : checkinSelectedServices[selectedVipService.passengerId]) === null || _a === void 0 ? void 0 : _a[selectedVipService.serviceId]) === null || _b === void 0 ? void 0 : _b[selectedVipService.segmentIds[0]]);
            if (selectedVipService.count === 1) {
                if (!isSelected) {
                    dispatch(addService({
                        segmentId: selectedVipService.segmentIds[0],
                        service: relatedCheckinOrderService,
                        passengerId: selectedVipService.passengerId
                    }));
                }
            }
            else {
                dispatch(removeService({
                    segmentId: selectedVipService.segmentIds[0],
                    service: relatedCheckinOrderService,
                    passengerId: selectedVipService.passengerId
                }));
            }
        }
        dispatch(saveServices());
        onClose();
    };
    const renderSubheader = () => {
        const passengersMap = getPassengerPricesMap(passengers, businessLoungeServices);
        return (React.createElement(React.Fragment, null, passengersMap.map(passenger => (React.createElement("span", null,
            getPassengerTypeLabel(passenger.type, passenger.supplierType),
            ":",
            ' ',
            React.createElement(Money, { money: passenger.price }))))));
    };
    const childrenProps = {
        passengers,
        onSave,
        onClose,
        subheader: renderSubheader(),
        header: t('Business lounge'),
        totalPrice: totalPrice,
        minPrice: minPrice,
        loading: isLoading,
        services: businessLoungeServices,
        segments: segments,
        selectedServices: checkoutSelectedServicesStateFactory(order)
    };
    return children(childrenProps);
};
export default CheckinBusinessLoungeAdapter;
