import * as React from 'react';
import { Flight } from '../../../../index';
import { useTheme } from '../../../../theme';
import MediaQuery from 'react-responsive';
import { TABLET_MIN_WIDTH } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { OverrideComponent } from '../../../../renderProps';
import { checkoutSegmentGroupToFlightPropsAdapter } from '../../../../Flight/adapters';
const SelectedFlight = ({ flight, confirmed, canceled, orderId, subsidized, hidePrice }) => {
    const theme = useTheme('Checkout').CheckoutStyles;
    const { t } = useTranslation('Checkout');
    return (React.createElement("div", { className: theme.selectedFlight__container },
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement("div", { className: theme.selectedFlight__header }, t(flight.segmentGroups.length > 1 ? 'Flights' : 'Flight'))),
        flight.segmentGroups.map((group, index) => {
            return (React.createElement(OverrideComponent, { key: index, componentProps: Object.assign({ key: index, hidePrice }, checkoutSegmentGroupToFlightPropsAdapter(orderId, flight, flight.segmentGroups.find(segmentGroup => segmentGroup.groupId === group.groupId), confirmed, subsidized)), component: {
                    renderCheckoutSelectedFlight: Flight
                } }));
        })));
};
export default SelectedFlight;
