import { getServiceCategory } from '../utils';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import Base from './Base';
class Seat extends Base {
    constructor(orderBeforeChange, orderAfterChange) {
        super(orderBeforeChange, orderAfterChange);
        this.convertSeatServicesToDataLayerServices = (services, order, traveller) => {
            const converted = [];
            services.forEach(seatService => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                const seatInfo = (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(service => { var _a, _b; return service.id === ((_b = (_a = seatService.seat) === null || _a === void 0 ? void 0 : _a.seatService) === null || _b === void 0 ? void 0 : _b.id); });
                converted.push({
                    name: (_d = (_c = seatService.seat) === null || _c === void 0 ? void 0 : _c.number) !== null && _d !== void 0 ? _d : 'seat',
                    price: (_f = (_e = seatInfo === null || seatInfo === void 0 ? void 0 : seatInfo.price) === null || _e === void 0 ? void 0 : _e.amount) !== null && _f !== void 0 ? _f : (_h = (_g = seatService.seat) === null || _g === void 0 ? void 0 : _g.price) === null || _h === void 0 ? void 0 : _h.amount,
                    category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Seat),
                    segmentId: (_j = seatService.segment) === null || _j === void 0 ? void 0 : _j.id,
                    isRecommend: (_k = traveller.preselectedServices) === null || _k === void 0 ? void 0 : _k.seats.some(seat => {
                        var _a, _b, _c, _d;
                        return (((_a = seat.seat) === null || _a === void 0 ? void 0 : _a.number) === ((_b = seatService.seat) === null || _b === void 0 ? void 0 : _b.number) && ((_c = seat.segment) === null || _c === void 0 ? void 0 : _c.id) === ((_d = seatService.segment) === null || _d === void 0 ? void 0 : _d.id));
                    }),
                    count: 1,
                    travellersTypes: [traveller.type]
                });
            });
            return converted;
        };
    }
    diff() {
        const added = [];
        const removed = [];
        this.orderAfterChange.travellers.forEach((travellerAfterChange, index) => {
            const { added: seatsAdded, removed: seatsRemoved } = this.getTravellersServicesDiff(this.orderBeforeChange.travellers[index].services.seats, travellerAfterChange.services.seats, service => { var _a; return (_a = service.seat) === null || _a === void 0 ? void 0 : _a.number; });
            added.push(...this.convertSeatServicesToDataLayerServices(seatsAdded, this.orderAfterChange, travellerAfterChange));
            removed.push(...this.convertSeatServicesToDataLayerServices(seatsRemoved, this.orderAfterChange, travellerAfterChange));
        });
        return { added, removed };
    }
}
export default Seat;
