import { useMemo, useReducer } from 'react';
import { parse } from 'date-fns';
import { API_DATE_FORMAT, format, getTotalPrice } from '../../../utils';
const reducer = (state, action) => {
    switch (action.type) {
        case 'change':
            const changeState = Object.assign({}, state);
            action.payload.segmentIds.forEach(segmentId => {
                changeState[segmentId] = Object.assign(Object.assign({}, changeState[segmentId]), { [action.payload.travellerId]: action.payload.serviceId });
            });
            return changeState;
        case 'clear':
            const clearState = Object.assign({}, state);
            for (const segmentId in clearState) {
                for (const passengerId in clearState[segmentId]) {
                    clearState[segmentId][passengerId] = null;
                }
            }
            return clearState;
        default:
            return state;
    }
};
export const useAnimalState = (services, segments, travellers) => {
    const initialState = useMemo(() => {
        const state = {};
        segments.forEach(segment => {
            const segmentId = segment.id;
            services.forEach(service => {
                const allowedSegmentsSet = new Set(service.allowedSegments.reduce((acc, ids) => [...acc, ...ids], []));
                if (allowedSegmentsSet.has(segmentId)) {
                    travellers.forEach(traveller => {
                        var _a, _b, _c, _d;
                        const selectedService = (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(selectedService => selectedService.serviceId === service.id &&
                            selectedService.segmentIds.includes(segmentId));
                        state[segmentId] = Object.assign(Object.assign({}, state[segmentId]), { [traveller.id]: (_d = selectedService === null || selectedService === void 0 ? void 0 : selectedService.serviceId) !== null && _d !== void 0 ? _d : null });
                    });
                }
            });
        });
        return state;
    }, [services, segments, travellers]);
    const [state, dispatch] = useReducer(reducer, initialState);
    const servicesBySegment = useMemo(() => {
        const servicesMap = new Map();
        segments.forEach(segment => {
            const segmentId = segment.id;
            services.forEach(service => {
                const allowedSegmentMap = new Map(service.allowedSegments.map(ids => [ids[0], ids]));
                if (allowedSegmentMap.has(segmentId)) {
                    const allowedSegments = allowedSegmentMap.get(segmentId);
                    let direction = `${segment.departure.airport.city.name} – ${segment.arrival.airport.city.name}`;
                    let date = format(parse(segment.departure.date, API_DATE_FORMAT, new Date()), 'dd MMM');
                    if (allowedSegments.length > 1) {
                        const firstSegmentId = allowedSegments[0];
                        const lastSegmentId = allowedSegments[allowedSegments.length - 1];
                        const firstSegment = segments.find(segment => segment.id === firstSegmentId);
                        const lastSegment = segments.find(segment => segment.id === lastSegmentId);
                        direction = `${firstSegment.departure.airport.city.name} – ${lastSegment.arrival.airport.city.name}`;
                        date = format(parse(firstSegment.departure.date, API_DATE_FORMAT, new Date()), 'dd MMM');
                    }
                    servicesMap.set(direction, {
                        service,
                        segmentId,
                        date,
                        segmentIds: allowedSegments,
                        serviceId: service.id,
                        price: service.price
                    });
                }
            });
        });
        return [...servicesMap.entries()];
    }, [services, segments, travellers]);
    const [selectedServices, servicesToSave] = useMemo(() => {
        var _a;
        let selectedServices = [];
        let servicesToSave = [];
        for (const segmentId in state) {
            for (const passengerId in state[segmentId]) {
                const serviceId = state[segmentId][passengerId];
                const initialServiceId = initialState[segmentId][passengerId];
                const actualServiceId = serviceId !== null && serviceId !== void 0 ? serviceId : initialServiceId;
                const service = (_a = servicesBySegment.find(([_, service]) => service.segmentId === segmentId && service.serviceId === actualServiceId)) === null || _a === void 0 ? void 0 : _a[1];
                if (service) {
                    const selectedService = {
                        serviceId: actualServiceId,
                        segmentId,
                        passengerId,
                        service: service.service,
                        segmentIds: service.segmentIds,
                        count: !!serviceId ? 1 : 0
                    };
                    if (serviceId !== initialServiceId) {
                        servicesToSave = [...servicesToSave, selectedService];
                    }
                    else if (serviceId) {
                        selectedServices = [...selectedServices, selectedService];
                    }
                }
            }
        }
        return [selectedServices, servicesToSave];
    }, [initialState, state, segments, servicesBySegment]);
    const hasChanges = useMemo(() => JSON.stringify(initialState) !== JSON.stringify(state), [initialState, state]);
    const totalPrice = useMemo(() => {
        const filterServices = [...selectedServices, ...servicesToSave].filter(service => service.count > 0);
        if (filterServices.length) {
            return getTotalPrice(filterServices, service => (Object.assign(Object.assign({}, service.service.price), { amount: service.service.price.amount * service.segmentIds.length })));
        }
        return null;
    }, [selectedServices, servicesToSave]);
    const onChange = (payload) => {
        dispatch({ type: 'change', payload });
    };
    const onClear = () => {
        dispatch({ type: 'clear' });
    };
    return {
        state,
        servicesBySegment,
        selectedServices,
        servicesToSave,
        hasChanges,
        totalPrice,
        onChange,
        onClear
    };
};
