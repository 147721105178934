import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { Included } from '../../../Icons';
import { Money } from '../../../Money';
import { useRenderers } from '../../../renderProps';
import { BaseButton } from '../../../index';
const InsuranceCard = ({ className, insurance, policyUrl, isSelected, onClick }) => {
    var _a;
    const { InsuranceCard: css } = useTheme('InsurancePopup');
    const { t } = useTranslation('Checkout');
    const { renderInsuranceCardOptions } = useRenderers();
    const isConfirmed = !!policyUrl;
    const onDownload = () => {
        if (policyUrl) {
            window.open(policyUrl);
        }
    };
    return (React.createElement("div", { className: cn(className, css.wrapper, {
            [css.active]: isSelected,
            [css.confirmed]: isConfirmed
        }) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.header_info },
                React.createElement("span", { className: css.header_rate }, insurance.name),
                React.createElement("span", { className: css.header_description }, t('price for all persons'))),
            React.createElement("span", { className: css.header_price },
                React.createElement(Money, { money: insurance.totalPrice }),
                isSelected && Included)),
        React.createElement("div", { className: css.content },
            React.createElement("ul", null, (_a = renderInsuranceCardOptions === null || renderInsuranceCardOptions === void 0 ? void 0 : renderInsuranceCardOptions(insurance.options)) !== null && _a !== void 0 ? _a : insurance.options.map(({ name }, index) => (React.createElement("li", { key: index, dangerouslySetInnerHTML: { __html: name } })))),
            policyUrl ? (React.createElement(BaseButton, { className: css.button, onClick: onDownload, variant: "optionEdit" }, t('Download'))) : isSelected ? (React.createElement(BaseButton, { className: css.button_reject, onClick: onClick, variant: "smallSecondary" }, t('Reject'))) : (React.createElement(BaseButton, { className: css.button, onClick: onClick, variant: "optionEdit" }, t('Select'))))));
};
export default InsuranceCard;
