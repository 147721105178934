import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ExareStatus, InsuranceType } from '@websky/graphql';
import { getSelectedFares } from '../../../store/newFlights/selectors';
import Notice from '../../../../../RefundSummary/components/Notice/Notice';
import CreateRequestWrapper from '../CreateRequest/CreateRequestWrapper';
import { setLastExchange } from '../../../store/order/actions';
import { getOrder, getSelectedInsurancesByType, isAutoMode } from '../../../store/order/selectors';
import SelectedFlights from './SelectedFlights/SelectedFlights';
import RefundSummary from '../../../../../RefundSummary/components/RefundSummary';
import { getSelectedPassengerIds } from '../../../store/passengers/selectors';
import { getFlightIdsToExchange } from '../../../store/flightsToExchange/selectors';
import { useRefundSummary } from '../../../../../RefundSummary';
import { getIsAllFlights, getRefundableNasServices } from '../../../store/selectors';
import AirportServices from '../AirportServices/AirportServices';
const Request = ({ goToPayment, isNewRequest, goToFlights, setExchangeSuccess, refreshOrder, clearSelectedFares, flightId, isLoading, exchangePrices, refreshOrderAfterExchange, reloadOrder, returnToOrder }) => {
    const { t } = useTranslation('Exchange');
    const dispatch = useDispatch();
    const order = useSelector(getOrder);
    const selectedPassengers = useSelector(getSelectedPassengerIds);
    const selectedSegmentItems = useSelector(getFlightIdsToExchange);
    const selectedSegmentIds = selectedSegmentItems.map(i => i.key);
    const isAllFlightToExchange = useSelector(getIsAllFlights);
    const selectedFlight = useSelector(getSelectedFares);
    const selectedVskInsurances = useSelector(getSelectedInsurancesByType(InsuranceType.Vsk));
    const autoMode = useSelector(isAutoMode);
    const refundableNasServices = useSelector(getRefundableNasServices);
    const needToRefundNasServices = refundableNasServices.length > 0;
    const firstExarePrice = selectedFlight === null || selectedFlight === void 0 ? void 0 : selectedFlight[0].exarePrice;
    const priceToPay = firstExarePrice === null || firstExarePrice === void 0 ? void 0 : firstExarePrice.priceToPay;
    const { gdsServicesRefund, loading } = useRefundSummary({
        orderId: order.id,
        passengers: order.travellers,
        selectedPassengerIds: selectedPassengers,
        selectedSegmentIds: selectedSegmentIds,
        allFlightToExchange: isAllFlightToExchange
    });
    const setExchangeOrder = (order, openRequest) => {
        dispatch(setLastExchange(order));
        if (order.status === ExareStatus.AwaitingPayment) {
            if (!openRequest) {
                goToPayment();
            }
        }
        else if (order.status === ExareStatus.Finished) {
            if (priceToPay === null || priceToPay === void 0 ? void 0 : priceToPay.amount) {
                setExchangeSuccess();
            }
            else {
                refreshOrder();
            }
        }
        else {
            clearSelectedFares();
        }
    };
    const prices = React.useMemo(() => {
        var _a, _b;
        if (exchangePrices) {
            return exchangePrices;
        }
        if (autoMode && firstExarePrice) {
            return {
                priceToPay,
                charges: {
                    amount: +(((_a = firstExarePrice.agencyChargesForExare) === null || _a === void 0 ? void 0 : _a.amount) || 0) +
                        +(((_b = firstExarePrice.exchangeAirlinePenalty) === null || _b === void 0 ? void 0 : _b.amount) || 0),
                    currency: priceToPay === null || priceToPay === void 0 ? void 0 : priceToPay.currency
                }
            };
        }
        return null;
    }, [autoMode, selectedFlight, exchangePrices]);
    return (React.createElement("div", null,
        React.createElement(SelectedFlights, { selectedFlights: selectedFlight.map(flight => (Object.assign(Object.assign({}, flight.fare.flightInfo), { segmentGroups: order.flight.segmentGroups, fares: [] }))), prices: prices, goToFlights: goToFlights }),
        React.createElement(RefundSummary, { order: order, gdsServicesRefund: gdsServicesRefund, insurancesRefund: selectedVskInsurances, isLoading: loading, notification: selectedVskInsurances.length > 0 && (React.createElement(Notice, { type: "notice", header: t('Note'), text: t('When the exchange is performed, VSK insurance policies will be canceled') })) }),
        !autoMode && (React.createElement(Notice, { type: "notice", header: t('Note'), text: t('The possibility and cost of changes will be calculated by the operator after sending the application. The answer will be sent to your E-mail and is also available on the order page.') })),
        React.createElement(Notice, { type: "reject", header: t('Exchange restrictions'), text: t('After submitting the request, the seats you have selected will be canceled.') }),
        needToRefundNasServices && React.createElement(AirportServices, { onCancel: returnToOrder, reloadOrder: reloadOrder }),
        !needToRefundNasServices && (React.createElement(CreateRequestWrapper, { onOrderCreated: setExchangeOrder, flightId: flightId, isLoading: loading || isLoading, refreshOrder: refreshOrderAfterExchange }))));
};
export default Request;
