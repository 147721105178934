import * as React from 'react';
import { useField } from 'react-final-form';
import Input from '../../../../../Input';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
const Fields = props => {
    const { t } = useTranslation('Loyalty');
    const theme = useTheme('Loyalty').LoyaltyConnect;
    const numberField = useField('loyaltyNumber', {
        type: 'text',
        validate: value => {
            if (!value || (value && value.length < 4)) {
                return t('Please enter your card number');
            }
            return undefined;
        }
    });
    const passwordField = useField('loyaltyPassword', {
        type: 'password',
        validate: value => {
            if (!value || (value && value.length < 4)) {
                return t('Enter password');
            }
            return undefined;
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.group__field },
            React.createElement(Input, { label: t('Card number'), absoluteHintPosition: true, value: numberField.input.value, TextFieldProps: Object.assign(Object.assign({}, numberField.input), { helperText: ((numberField.meta.error && numberField.meta.touched) || numberField.meta.submitFailed) &&
                        (numberField.meta.error || numberField.meta.submitError), error: ((numberField.meta.error && numberField.meta.touched) || numberField.meta.submitFailed) &&
                        (!!numberField.meta.error || !!numberField.meta.submitError) }) })),
        React.createElement("div", { className: theme.group__field },
            React.createElement(Input, { label: t('Password'), value: passwordField.input.value, absoluteHintPosition: true, TextFieldProps: Object.assign(Object.assign({}, passwordField.input), { helperText: ((passwordField.meta.modified && passwordField.meta.touched) ||
                        passwordField.meta.submitFailed) &&
                        passwordField.meta.error, error: ((passwordField.meta.error && passwordField.meta.touched) ||
                        passwordField.meta.submitFailed) &&
                        (!!passwordField.meta.error || !!passwordField.meta.submitError) }) }))));
};
export default Fields;
