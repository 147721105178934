import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import RecaptchaPage from './RecaptchaPage';
import { SHOW_RECAPTCHA, initI18n } from './utils';
import { APOLLO_ERRORS_EVENT } from './apolloErrorLink';
import WarningModal from './WarningModal';
import { useTranslation } from 'react-i18next';
import { ApolloErrorType } from './types';
initI18n('GuardedApolloProvider');
export const GuardedApolloProvider = ({ children, client }) => {
    const [recaptchaIsVisible, setRecaptchaIsVisible] = useState(false);
    const [apolloErrors, setApolloErrors] = useState('');
    const [apolloErrorType, setApolloErrorType] = useState();
    const onRecaptchaEvent = useCallback(() => setRecaptchaIsVisible(true), []);
    const { t } = useTranslation('GuardedApolloProvider');
    const onApolloErrors = (e) => {
        setApolloErrors(e.detail.message);
        if (e.detail.type) {
            setApolloErrorType(e.detail.type);
        }
    };
    const onCloseWarningPopup = useCallback(() => {
        setApolloErrors('');
    }, []);
    useEffect(() => {
        document.addEventListener(SHOW_RECAPTCHA, onRecaptchaEvent);
        document.addEventListener(APOLLO_ERRORS_EVENT, onApolloErrors);
        return () => {
            document.removeEventListener(SHOW_RECAPTCHA, onRecaptchaEvent);
            document.removeEventListener(APOLLO_ERRORS_EVENT, onApolloErrors);
        };
    });
    return (React.createElement(ApolloProvider, { client: client },
        children,
        recaptchaIsVisible && createPortal(React.createElement(RecaptchaPage, null), document.body),
        React.createElement(WarningModal, { title: t('Ops...'), content: t('Unfortunately, something went wrong. Please try again or contact with us'), isOpen: !!apolloErrors, errorMessage: t(apolloErrors), buttonText: t('Close'), onButtonClick: onCloseWarningPopup, hideContentText: apolloErrorType === ApolloErrorType.PnrBlocked })));
};
