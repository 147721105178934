import * as React from 'react';
import { useTransferService } from './model';
import TransferSelector from '../TransferSelector/TransferSelector';
import { useTheme } from '../../../theme';
const TransferService = ({ order, onChange, activeSegment, onStateChanged }) => {
    const model = useTransferService(order, onChange, onStateChanged, activeSegment);
    const { TransferService: css } = useTheme('Transfer');
    return (React.createElement("div", { className: css.container }, model.activeSegmentServices.map(service => {
        return (React.createElement(TransferSelector, { isSelected: model.selectedServices.has(service.id), key: service.id, passengers: model.passengersById, onChange: model.onChangeService, segments: model.segmentsById, service: service, servicesState: model.services, isOpen: model.activeService === service.id, onOpen: model.setActiveService, onClose: () => model.setActiveService(null) }));
    })));
};
export default TransferService;
