import * as React from 'react';
import cn from 'classnames';
import { useEffect } from 'react';
import { useTheme } from '../../../../theme';
export const Switch = props => {
    const [selectedOption, setSelectedOption] = React.useState(props.value ? props.value : '');
    const { Switch: theme } = useTheme('Passenger');
    const selectOption = (event) => {
        setSelectedOption(event.target.value);
        props.onChange && props.onChange(event.target.value, event);
        props.onBlur && props.onBlur();
    };
    useEffect(() => {
        setSelectedOption(props.value || '');
    }, [props.value]);
    return (React.createElement("div", { className: cn(theme.container, props.className, {
            [theme.disabled]: props.disabled
        }) },
        props.options.map((option, index) => (React.createElement("label", { key: index, "data-test-id": "element", className: cn(theme.element, {
                [theme.element_active]: selectedOption === option.key,
                [theme.element_error]: props.error
            }) },
            React.createElement("input", { key: index, type: "radio", name: props.name, value: option.key, onChange: selectOption, checked: selectedOption === option.key, disabled: props.disabled }),
            option.icon))),
        props.error && React.createElement("p", { className: theme.error }, props.error),
        React.createElement("input", { type: "radio", name: props.name, value: "", onChange: selectOption, checked: selectedOption === '', style: { display: 'none' }, disabled: props.disabled }),
        React.createElement("select", { style: { display: 'none' }, name: props.name, value: selectedOption, onChange: () => { } }, props.options.map((option, index) => (React.createElement("option", { key: index, value: option.key }, option.value))))));
};
