import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import { LoginType, useGetAccountInfoQuery, useRemoveAuthMethodMutation } from '@websky/graphql';
import { useConfig } from '../../../context';
import { Statuses } from '../../types';
import Loader from '../../../Loader';
import WarningModal from '../../../WarningModal';
import AuthMethod from './AuthMethod/AuthMethod';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useEmailDuplication } from '../../hooks';
import { getIsCorporateNumber, getCorporateCardNumberAsNormal } from '../../../Loyalty/utils';
initI18n('Account');
const AuthMethods = () => {
    var _a, _b;
    const css = useTheme('Account').Account, { t } = useTranslation('Account'), [authStatus, setAuthStatus] = useState(Statuses.default), [userSettingsError, setUserSettingsError] = useState(null);
    const { global: { authMethods } } = useConfig();
    const accountInfo = useGetAccountInfoQuery({
        fetchPolicy: 'no-cache'
    });
    const [removeMethod] = useRemoveAuthMethodMutation();
    const refetchAccountInfo = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield accountInfo.refetch();
        return data === null || data === void 0 ? void 0 : data.CurrentUser;
    }), [accountInfo]);
    const clearUserSettingsError = useCallback(() => {
        setUserSettingsError(null);
    }, []);
    const onRemoveAuthMethod = (id) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data, errors } = yield removeMethod({
                variables: {
                    params: id
                }
            });
            if (errors === null || errors === void 0 ? void 0 : errors.length) {
                setUserSettingsError(errors[0]);
            }
            else if (data.DeleteAuthMethod && data.DeleteAuthMethod.result) {
                yield refetchAccountInfo();
            }
        }
        catch (error) {
            setUserSettingsError(error);
        }
    });
    const [accountPhone, accountEmail, accountFFP] = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const phone = (_c = (_b = (_a = accountInfo.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.authMethods) === null || _c === void 0 ? void 0 : _c.find(authM => authM.loginType === LoginType.Phone && !!authM.value && authM.confirmed), email = (_f = (_e = (_d = accountInfo.data) === null || _d === void 0 ? void 0 : _d.CurrentUser) === null || _e === void 0 ? void 0 : _e.authMethods) === null || _f === void 0 ? void 0 : _f.find(authM => authM.loginType === LoginType.Email && !!authM.value && authM.confirmed), ffp = (_j = (_h = (_g = accountInfo.data) === null || _g === void 0 ? void 0 : _g.CurrentUser) === null || _h === void 0 ? void 0 : _h.authMethods) === null || _j === void 0 ? void 0 : _j.find(authM => authM.loginType === LoginType.FFP && !!authM.value && authM.confirmed);
        return [phone, email, ffp];
    }, [accountInfo]);
    const emailDuplication = useEmailDuplication((_a = accountInfo.data) === null || _a === void 0 ? void 0 : _a.CurrentUser);
    const isRemovableMethod = React.useMemo(() => {
        return [accountPhone, accountEmail, accountFFP].filter(method => method === null || method === void 0 ? void 0 : method.confirmed).length > 1;
    }, [accountPhone, accountEmail, accountFFP]);
    if (accountInfo.loading || emailDuplication.isLoading) {
        return (React.createElement("div", { className: css.loader__wrapper },
            React.createElement(Loader, null)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.account__content },
            authMethods.email && (React.createElement(AuthMethod, { id: accountEmail === null || accountEmail === void 0 ? void 0 : accountEmail.id, className: css.account__field, status: authStatus, type: LoginType.Email, value: accountEmail === null || accountEmail === void 0 ? void 0 : accountEmail.value, setStatus: setAuthStatus, refetchInfo: refetchAccountInfo, confirmed: accountEmail === null || accountEmail === void 0 ? void 0 : accountEmail.confirmed, isRemovable: isRemovableMethod, onDelete: onRemoveAuthMethod, autoConnectValue: emailDuplication.connectEmail, onConnectResult: emailDuplication.onConnectResult })),
            authMethods.phone && (React.createElement(AuthMethod, { id: accountPhone === null || accountPhone === void 0 ? void 0 : accountPhone.id, className: css.account__field, status: authStatus, type: LoginType.Phone, value: formatPhoneNumberIntl(`+${accountPhone === null || accountPhone === void 0 ? void 0 : accountPhone.value}`), setStatus: setAuthStatus, refetchInfo: refetchAccountInfo, confirmed: accountPhone === null || accountPhone === void 0 ? void 0 : accountPhone.confirmed, isRemovable: isRemovableMethod, onDelete: onRemoveAuthMethod })),
            authMethods.ffp && (React.createElement(AuthMethod, { id: accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.id, className: css.account__field, status: authStatus, type: LoginType.FFP, value: getIsCorporateNumber(accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.value)
                    ? getCorporateCardNumberAsNormal(accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.value)
                    : accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.value, setStatus: setAuthStatus, refetchInfo: refetchAccountInfo, confirmed: accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.confirmed, isRemovable: isRemovableMethod, onDelete: onRemoveAuthMethod, autoConnectValue: emailDuplication.connectFfp, autoConnectCode: (_b = emailDuplication.authDuplicates) === null || _b === void 0 ? void 0 : _b.code, onConnectResult: emailDuplication.onConnectResult }))),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), errorMessage: userSettingsError ? userSettingsError.message : '', onClose: clearUserSettingsError, onButtonClick: clearUserSettingsError, buttonText: t('OK, thank you'), isOpen: !!userSettingsError })));
};
export default AuthMethods;
