import * as React from 'react';
import cn from 'classnames';
import MultiCity from './MultiCity/MultiCity';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import SearchFormPromoCode from './PromoCode/PromoCode';
import PayByMiles from './PayByMiles/PayByMiles';
import OfferHotels from './OfferHotels/OfferHotels';
import { PromoCodeStatus } from '../../../../PromoCode/types';
import { useRenderers } from '../../../../renderProps';
const Bottom = React.memo(props => {
    const { Bottom: css, SearchForm } = useTheme('SearchForm');
    const { promoCode } = props;
    const { SearchForm: { enableComplexRoute, proMode, payByMiles, offerHotels } } = useConfig();
    const BottomRight = useRenderers().SearchFormBottomRight;
    const isControlsAvailable = (enableComplexRoute && !proMode) || offerHotels;
    return (React.createElement("div", { className: cn(SearchForm.row, css.bottom) },
        props.renderBefore,
        isControlsAvailable && (React.createElement("div", { className: css.controls },
            enableComplexRoute && !proMode && React.createElement(MultiCity, null),
            offerHotels && React.createElement(OfferHotels, null))),
        props.renderPromoCode ? (props.renderPromoCode()) : (React.createElement(SearchFormPromoCode, { promoCode: promoCode, status: promoCode ? PromoCodeStatus.Provided : undefined })),
        payByMiles && React.createElement(PayByMiles, null),
        BottomRight && React.createElement(BottomRight, null)));
});
export default Bottom;
