import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useRestrictionsConditionForKV } from '../hooks';
const SeatAdditionalInfo = ({ className, restrictions, permissions, additional }) => {
    const { SeatsInfoStyles: theme } = useTheme('SeatMap');
    const { t } = useTranslation('SeatMap');
    const filterRestrictions = React.useMemo(() => restrictions === null || restrictions === void 0 ? void 0 : restrictions.filter(restriction => !!t(`Restriction_${restriction}`)), [restrictions]);
    if (!(filterRestrictions === null || filterRestrictions === void 0 ? void 0 : filterRestrictions.length) && !(permissions === null || permissions === void 0 ? void 0 : permissions.length) && !(additional === null || additional === void 0 ? void 0 : additional.length)) {
        return null;
    }
    return (React.createElement("div", { className: cn(theme.additionalInfo, className) },
        (additional === null || additional === void 0 ? void 0 : additional.length) > 0 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.additionalInfo__title },
                t('Information for the place'),
                ":"),
            React.createElement("ul", null, additional.map(info => (React.createElement("li", { key: info, dangerouslySetInnerHTML: { __html: info } })))))),
        (filterRestrictions === null || filterRestrictions === void 0 ? void 0 : filterRestrictions.length) > 0 && useRestrictionsConditionForKV() && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.additionalInfo__title },
                t('This seat is forbidden for'),
                ":"),
            React.createElement("ul", null, filterRestrictions.map(restriction => (React.createElement("li", { key: restriction, dangerouslySetInnerHTML: { __html: t(`Restriction_${restriction}`) } })))))),
        (permissions === null || permissions === void 0 ? void 0 : permissions.length) > 0 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.additionalInfo__title },
                t('This seat is allowed for'),
                ":"),
            React.createElement("ul", null, permissions.map(permission => (React.createElement("li", { key: permission, dangerouslySetInnerHTML: { __html: t(`Restriction_${permission}`) } }))))))));
};
export default SeatAdditionalInfo;
