import * as React from 'react';
import cn from 'classnames';
import Card from '@material-ui/core/Card';
import { Time } from '../../../Icons';
import { ExareStatus } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
const STATUS_TO_ICONS_MAP = new Map([
    [ExareStatus.NeedConfirm, Time],
    [ExareStatus.InProcess, Time],
    [ExareStatus.Finished, Time],
    [ExareStatus.Refused, Time],
    [ExareStatus.Rejected, Time],
    [ExareStatus.AwaitingPayment, Time],
    [ExareStatus.TimelimitExpired, Time]
]);
const WAITING_STATUSES = [ExareStatus.NeedConfirm, ExareStatus.AwaitingPayment, ExareStatus.InProcess];
const REJECTED_STATUSES = [
    ExareStatus.Rejected,
    ExareStatus.Refused,
    ExareStatus.TimelimitExpired,
    ExareStatus.TimelimitExpired
];
const PROCESSING_STATUSES = [ExareStatus.AwaitingPayment];
const Status = props => {
    const { t } = useTranslation('Exchange');
    const { status } = props;
    const { Status: css } = useTheme('Refund');
    return (React.createElement(Card, { className: cn(css.status, props.className, {
            [css.waiting]: WAITING_STATUSES.includes(status),
            [css.processing]: PROCESSING_STATUSES.includes(status),
            [css.rejected]: REJECTED_STATUSES.includes(status)
        }), elevation: 0, square: true },
        React.createElement("i", { className: css.icon }, STATUS_TO_ICONS_MAP.get(status)),
        React.createElement("span", null, t(status))));
};
export default Status;
