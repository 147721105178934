import { useEffect, useReducer } from 'react';
import { upgradeClassStateFactory } from './factory';
import { reducer } from './reducer';
export const useUpgradeFlightClass = (order) => {
    const [state, dispatch] = useReducer(reducer, order, upgradeClassStateFactory);
    const countTotalPriceEffect = () => {
        const servicePrice = state.service.price;
        const newPrice = Object.assign({}, servicePrice);
        newPrice.amount = servicePrice.amount * state.selectedSegments.length;
        dispatch({
            type: 'setTotalPrice',
            payload: newPrice
        });
    };
    useEffect(countTotalPriceEffect, [state.selectedSegments, dispatch]);
    return {
        state,
        dispatch
    };
};
