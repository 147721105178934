import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { getSegments, getTabs, seatMapAdapter } from '../../utils';
import { initI18n, MOBILE_MIN_WIDTH, scrollTo, TABLET_MIDDLE_WIDTH } from '../../../utils';
import { useTranslation } from 'react-i18next';
import SeatMapServiceContent from './Content/SeatMapServiceContent';
import { useTheme } from '../../../theme';
import Passengers from '../Passengers/Passengers';
import SeatsInfo from '../SeatsInfo/SeatsInfo';
import cn from 'classnames';
import { isSeatOccupied } from '../SeatMap/Seat/utils';
import { ModeContext } from '../../../context';
import { Mode } from '../../../types';
import SeatsRecommendation from '../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import SeatsRecommendPopup from '../SeatsRecommend/SeatsRecommendPopup/SeatsRecommendPopup';
import SegmentTabs from '../../../SegmentTabs/SegmentTabs';
import { AviaPassengerType, OrderAdditionalServiceGdsServiceServiceType, SeatMapPermissions } from '@websky/graphql';
import { isFreeSeat } from '../SeatMap/utils';
import { useToggleable } from '../../../hooks';
import { WxEvents } from '../../../DataLayerAnalytics/types';
import DoubleSeat from '../Passengers/DoubleSeat/DoubleSeat';
import { useExtraSeat } from '../../hooks';
import { OverrideComponent } from '../../../renderProps';
import WebskyAnalyticsApollo from '../../../DataLayerAnalytics/WebskyAnalyticsApollo';
initI18n('SeatMap');
const Component = props => {
    var _a, _b, _c, _d, _e;
    const { segmentIndex, setSegment, passengerIndex, setPassenger } = props;
    const { isOpen: hasFreeSeats, open: setHasFreeSeats } = useToggleable(false);
    const { isOpen: hasSeatsWithAnimals, setOpen: setHasSeatsWithAnimals } = useToggleable(false);
    const seatMapRef = React.createRef();
    const mode = useContext(ModeContext);
    const { t } = useTranslation('SeatMap');
    const filteredPassengers = props.passengers.filter(passenger => !(passenger.type === AviaPassengerType.INF)), passengerId = filteredPassengers[passengerIndex].id;
    const isReturnFlight = ((_b = (_a = props.segments) === null || _a === void 0 ? void 0 : _a[segmentIndex]) === null || _b === void 0 ? void 0 : _b.groupId) === '1';
    const amenities = (_d = (_c = props.segments) === null || _c === void 0 ? void 0 : _c[segmentIndex]) === null || _d === void 0 ? void 0 : _d.amenities;
    const segments = React.useMemo(() => {
        const segmentId = segmentIndex.toString();
        const currentPassenger = props.passengers.find(passenger => passenger.id === passengerId);
        // collect passengers seats by segment, so we can find out if double seats are selected
        // key is the seat number, value is the seats themselves
        const selectedSeatsBySegmentMap = new Map();
        props.passengers.forEach(passenger => {
            var _a;
            if ((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.length) {
                const seatsBySegment = passenger.seats.filter(seat => seat.segmentId === segmentId);
                seatsBySegment.forEach(seat => {
                    selectedSeatsBySegmentMap.set(seat.number, seatsBySegment);
                });
            }
        });
        return getSegments(seatMapAdapter(props.seatMaps), currentPassenger, props.passengers, selectedSeatsBySegmentMap, amenities, props.isNewOrder);
    }, [props.seatMaps, props.passengers, props.isNewOrder, passengerId, segmentIndex]);
    const servicesCount = (_e = segments[segmentIndex].services) === null || _e === void 0 ? void 0 : _e.length;
    const [servicesMap, allServicesMap] = React.useMemo(() => {
        var _a, _b, _c;
        const allServicesMap = {};
        const canAddedServices = {};
        const seatServices = (_c = (_b = (_a = props.seatMaps.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Seat)) !== null && _c !== void 0 ? _c : [];
        seatServices.forEach(service => {
            service.allowedSegments.forEach(allowedSegments => {
                allowedSegments.forEach(allowedSegment => {
                    if (!allServicesMap.hasOwnProperty(allowedSegment)) {
                        allServicesMap[allowedSegment] = {};
                        canAddedServices[allowedSegment] = {};
                    }
                    service.allowedPassengers.forEach(allowedPassengers => {
                        allowedPassengers.forEach(allowedPassenger => {
                            if (!allServicesMap[allowedSegment].hasOwnProperty(allowedPassenger)) {
                                allServicesMap[allowedSegment][allowedPassenger] = {};
                                canAddedServices[allowedSegment][allowedPassenger] = {};
                            }
                            if (service.canBeAdded) {
                                canAddedServices[allowedSegment][allowedPassenger][service.id] = service;
                            }
                            allServicesMap[allowedSegment][allowedPassenger][service.id] = service;
                        });
                    });
                });
            });
        });
        return [canAddedServices, allServicesMap];
    }, [props.seatMaps]);
    const theme = useTheme('SeatMap').ComponentStyles;
    React.useEffect(() => {
        scrollTo(0);
    }, []);
    const isTablet = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    const onCancel = React.useCallback((passengerId) => {
        var _a;
        const actualPassengerId = passengerId !== null && passengerId !== void 0 ? passengerId : passengerIndex.toString();
        props.onSelect((_a = filteredPassengers[actualPassengerId]) === null || _a === void 0 ? void 0 : _a.id, [], segmentIndex);
    }, [props.onSelect, segmentIndex, passengerIndex]);
    const segmentTabs = React.useMemo(() => getTabs(segments, props.passengers, servicesMap).map(item => {
        const { value, header, isAvailable, seatsCount } = item;
        return {
            id: value.toString(),
            title: header,
            isDisabled: !isAvailable,
            countSeatsSelected: seatsCount
        };
    }), [props.passengers]);
    const selectNextPassengerWithoutSeat = () => {
        const currentSegmentId = segments[segmentIndex].segmentInfo.id;
        const nextPassengerWithoutSeat = filteredPassengers
            .filter(passenger => passenger.id !== passengerId)
            .find(passenger => !passenger.seats || !passenger.seats.some(seat => seat.segmentId === currentSegmentId));
        if (nextPassengerWithoutSeat) {
            const nextPassengerWithoutSeatIndex = filteredPassengers.findIndex(passenger => nextPassengerWithoutSeat.id === passenger.id);
            setPassenger(nextPassengerWithoutSeatIndex);
        }
    };
    const onSelectNextFlight = React.useCallback(() => {
        if (segmentIndex + 1 < props.seatMaps.segments.length) {
            setSegment(segmentIndex + 1);
            setPassenger(0);
        }
    }, [segmentIndex, props.seatMaps.segments]);
    const onSelectPrevFlight = React.useCallback(() => {
        if (segmentIndex > 0) {
            setSegment(segmentIndex - 1);
            setPassenger(0);
        }
    }, [segmentIndex]);
    const onClear = React.useCallback(() => {
        props.clearOnSegment(segmentIndex);
    }, [segmentIndex, props.clearOnSegment, props.passengers]);
    const onSelect = React.useCallback((seats, passengerId) => __awaiter(void 0, void 0, void 0, function* () {
        const currentPassenger = filteredPassengers.find(passenger => passenger.id === passengerId);
        const isOccupied = seats.some(seat => isSeatOccupied(seat, currentPassenger, segments[segmentIndex].segmentInfo.id));
        if (!isOccupied) {
            props.onSelect(passengerId, seats, segmentIndex);
            selectNextPassengerWithoutSeat();
        }
        else {
            // onCancel();
            props.onCancelSeat(passengerId, seats, segmentIndex);
        }
    }), [props.onSelect, segmentIndex, filteredPassengers]);
    const segmentId = segments[segmentIndex].segmentInfo.id;
    const hideControls = React.useMemo(() => segments.every(segment => segment.isSeatsAvailableInCheckinOnly) && mode !== Mode.Checkin, [segments]);
    /**
     * Объединил получение инфы о наличии мест с животными или бесплатными местами
     * если на сегменте находятся оба таких места, цикл прекращается
     * в противном случае, проверят до конца
     */
    React.useEffect(() => {
        var _a;
        for (const deck of segments[segmentIndex].decks) {
            for (const row of deck.rows) {
                for (const seat of row.seats) {
                    if (hasSeatsWithAnimals && hasFreeSeats) {
                        break;
                    }
                    if (!hasSeatsWithAnimals && ((_a = seat.permissions) === null || _a === void 0 ? void 0 : _a.includes(SeatMapPermissions.WithAnimals))) {
                        setHasSeatsWithAnimals(true);
                    }
                    if (!hasFreeSeats && isFreeSeat(seat)) {
                        setHasFreeSeats();
                    }
                }
            }
        }
    }, [segments, segmentIndex, hasFreeSeats]);
    React.useEffect(() => {
        if (isTablet) {
            setPassenger(0);
        }
    }, [segmentIndex]);
    const hasSelectedSeats = useMemo(() => {
        var _a, _b;
        return !!((_b = (_a = props.passengers.find(passenger => passenger.id === passengerId)) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.length);
    }, [props.passengers, passengerId]);
    const passengersIdsWithExtraSeats = useMemo(() => {
        const filteredPassengers = isTablet
            ? props.passengers
            : props.passengers.filter(passenger => passenger.id === passengerId);
        return filteredPassengers
            .filter(passenger => {
            var _a;
            return (_a = props.selectedExtraSeats) === null || _a === void 0 ? void 0 : _a.find(extraSeat => extraSeat.passengerId === passenger.id && extraSeat.segmentIds.includes(segmentIndex.toString()));
        })
            .map(passenger => passenger.id);
    }, [props.passengers, props.selectedExtraSeats, passengerId, segmentIndex, isTablet]);
    const { showDoubleSeats, extraSeatService, toggleExtraSeatService } = useExtraSeat(segments, passengerId, segmentIndex);
    const toggleExtraSeatServiceHandler = useCallback((active) => {
        if (hasSelectedSeats) {
            props.onPassengerClear(passengerId);
        }
        toggleExtraSeatService(active, passengerId);
    }, [passengerId, hasSelectedSeats, props.onPassengerClear]);
    const renderDoubleSeats = React.useCallback(() => {
        if (showDoubleSeats) {
            return (React.createElement(DoubleSeat, { description: !isTablet ? extraSeatService.description || '' : null, isActive: passengersIdsWithExtraSeats.length > 0, hasSeatsByPassenger: hasSelectedSeats, showInfo: segments.length > 1, toggleExtraSeat: toggleExtraSeatServiceHandler }));
        }
        return null;
    }, [
        showDoubleSeats,
        extraSeatService,
        passengersIdsWithExtraSeats,
        toggleExtraSeatService,
        hasSelectedSeats,
        segments,
        isTablet
    ]);
    const onChangePassenger = (passengerId) => {
        var _a;
        const currentIndex = filteredPassengers.findIndex(passenger => passenger.id === passengerId);
        if (currentIndex !== -1) {
            setPassenger(currentIndex);
        }
        else {
            const dIndex = +passengerId - +filteredPassengers[passengerIndex].id;
            const nextPassengers = filteredPassengers.filter(passenger => dIndex > 0 ? passenger.id >= passengerId : passenger.id <= passengerId);
            const nextPassengerId = (_a = nextPassengers[dIndex > 0 ? 0 : nextPassengers.length - 1]) === null || _a === void 0 ? void 0 : _a.id;
            const nextPassengerIndex = nextPassengerId
                ? filteredPassengers.findIndex(passenger => passenger.id === nextPassengerId)
                : 0;
            setPassenger(nextPassengerIndex);
        }
    };
    const renderPassengers = React.useCallback(() => {
        var _a;
        return (React.createElement(OverrideComponent, { componentProps: {
                hideDisclaimer: hideControls,
                hideNextButton: hideControls,
                hidePassengers: hideControls,
                services: allServicesMap,
                onProceed: props.onProceed,
                seatMapRef,
                passengers: props.passengers,
                segmentId,
                passengerId,
                onSelectNextFlight,
                segmentIndex,
                segments,
                hasSeatsWithAnimals,
                onChange: onChangePassenger,
                onCancel,
                onClear,
                renderDoubleSeats,
                hasSelectedExtraSeats: !!props.selectedExtraSeats.length,
                passengersIdsWithExtraSeats: passengersIdsWithExtraSeats,
                toggleExtraSeat: showDoubleSeats ? toggleExtraSeatServiceHandler : null,
                isNewOrder: props.isNewOrder,
                isDialog: !!((_a = props.dialogRef) === null || _a === void 0 ? void 0 : _a.current)
            }, component: {
                SeatMapPassengers: Passengers
            } }));
    }, [
        props.passengers,
        segmentIndex,
        passengerId,
        hasSeatsWithAnimals,
        renderDoubleSeats,
        passengersIdsWithExtraSeats,
        toggleExtraSeatServiceHandler,
        props.selectedExtraSeats,
        props.isNewOrder,
        props.dialogRef
    ]);
    const renderSeatsInfo = React.useCallback(() => {
        if (segments[segmentIndex].services) {
            return props.renderSeatsInfo ? (props.renderSeatsInfo(segments[segmentIndex].services, segmentIndex)) : (React.createElement(SeatsInfo, { hasFreeSeats: hasFreeSeats, seatsInfo: segments[segmentIndex].services, amenities: amenities, segmentId: segmentIndex.toString(), services: servicesMap, passengerId: passengerId }));
        }
        return null;
    }, [segments, segmentIndex, hasFreeSeats, props.renderSeatsInfo, passengerId]);
    const renderTabs = React.useCallback(() => (React.createElement(SegmentTabs, { classNameTab: theme.segmentTabs_tab, activeClassName: theme.segmentTabs_active, segmentsClassName: theme.segmentTabs, title: t('Choose your seat'), activeSegment: segmentIndex.toString(), segments: segmentTabs, onSegmentClick: segment => setSegment(parseInt(segment)), sticky: true, onClose: props.onClose, center: true })), [segmentTabs, segmentIndex, setSegment]);
    const seatMapRecommendEnabled = React.useMemo(() => {
        return props.seatRecommendEnabled && !hideControls;
    }, [props.seatRecommendEnabled, hideControls]);
    useEffect(() => {
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.SeatsList, { segmentId: segmentIndex.toString() });
    }, [segmentIndex]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, renderTabs()),
        React.createElement("div", { className: cn(theme.wrapper, props.className, {
                [theme.wrapper_threeServices]: servicesCount === 3,
                [theme.wrapper_fourServices]: servicesCount === 4
            }) },
            React.createElement(SeatsRecommendation, { orderId: props.orderId, segments: props.segments, travellers: props.travellers, fillOrder: props.fillOrder, runServiceRequest: props.runServiceRequest, segmentIndex: segmentIndex, passengerId: passengerId, isEnabled: seatMapRecommendEnabled, setSeat: props.setSeatCallback, confirmRecommendedSeatsWithoutDialog: props.confirmRecommendedSeatsWithoutDialog, seatMap: props.seatMaps },
                React.createElement(OverrideComponent, { componentProps: {
                        allSeatServices: allServicesMap,
                        services: servicesMap,
                        fullScreen: props.fullScreen,
                        segments: segments,
                        onSelect: onSelect,
                        passengers: filteredPassengers,
                        seatMapRef: seatMapRef,
                        onSelectNextFlight: onSelectNextFlight,
                        passengerId: passengerId,
                        segmentIndex: segmentIndex,
                        swipeBetweenSegments: true,
                        activeDoubleSeats: passengersIdsWithExtraSeats.includes(passengerId),
                        renderPassengers: renderPassengers,
                        renderSeatsInfo: renderSeatsInfo,
                        onProceed: props.onProceed,
                        onBack: onSelectPrevFlight,
                        onClear: onClear,
                        dialogRef: props.dialogRef,
                        isReturnFlight: isReturnFlight
                    }, component: { SeatMapServiceContent } }),
                React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
                    React.createElement(SeatsRecommendPopup, { passengerId: passengerId, segmentId: segmentIndex, services: servicesMap, setPassenger: setPassenger, passengers: filteredPassengers }))))));
};
export default Component;
