export const initialStateFactory = (service, state) => {
    const singleServiceState = {};
    for (const [passengerId] of Object.keys(state)) {
        singleServiceState[passengerId] = {};
        for (const [segmentId] of Object.keys(state[passengerId])) {
            singleServiceState[passengerId][segmentId] = state[passengerId][segmentId].find(stateService => stateService.service.id === service.id);
        }
    }
    return singleServiceState;
};
