import React from 'react';
import { CommunicationDetailPageWrapper } from '../CommunicationDetailPageWrapper/CommunicationDetailPageWrapper';
import { PaximizerCommunicationSectionEnum, useGetPaxMyPromoCodeTeasersContentQuery } from '@websky/graphql';
import { useRouteMatch } from 'react-router-dom';
import { useCommunicationDetail, useQueryParams } from '../../hooks';
export const PromoCodesDetailPage = () => {
    var _a, _b, _c;
    const { params } = useRouteMatch();
    const query = useQueryParams();
    const { data, loading, error } = useGetPaxMyPromoCodeTeasersContentQuery({
        variables: {
            messageId: params.detailId,
            locatorId: (_a = query.get('locator')) !== null && _a !== void 0 ? _a : undefined
        }
    });
    useCommunicationDetail(PaximizerCommunicationSectionEnum.my_promo_codes, data === null || data === void 0 ? void 0 : data.PaximizerCommunicationMyPromoCodeContent);
    return (React.createElement(CommunicationDetailPageWrapper, { loading: loading, error: !!(error === null || error === void 0 ? void 0 : error.graphQLErrors.length), title: (_b = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationMyPromoCodeContent) === null || _b === void 0 ? void 0 : _b.title, body: (_c = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationMyPromoCodeContent) === null || _c === void 0 ? void 0 : _c.body }));
};
