import { isSameMonth, toDate, parseISO } from 'date-fns';
export const getProgressLevel = (price, maxPrice) => {
    if (maxPrice === 0) {
        return 0;
    }
    return Math.round((price / maxPrice) * 100);
};
export const safeAddDays = (date, days) => {
    const newDate = date instanceof Date ? toDate(date) : parseISO(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};
export const safeStartOfMonth = (date) => {
    const newDate = date instanceof Date ? toDate(date) : parseISO(date);
    newDate.setDate(1);
    return newDate;
};
export const getDaysFromMonth = (initialDate) => {
    let currentDate = safeStartOfMonth(initialDate);
    const dates = [];
    while (isSameMonth(initialDate, currentDate)) {
        dates.push(currentDate);
        currentDate = safeAddDays(currentDate, 1);
    }
    return dates;
};
