import { createSelector } from 'reselect';
import { getAllServices, getConfirmedServices } from '../order/selectors';
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
export const getSelectedServices = (state) => state.selectedServices;
export const getSelectedMeals = createSelector(getSelectedServices, getConfirmedServices, getAllServices, (selectedServices, confirmedServices, allServices) => {
    var _a, _b, _c;
    const result = [];
    const mealsMap = new Map();
    allServices.forEach(service => {
        if (service.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
            mealsMap.set(service.id, true);
        }
    });
    // 	_===__
    // 	//-==;=_~
    // 	||('   ~)      ___      __ __ __------_
    // 	__----\|    _-_____////     --__---         -_
    // 	/ _----_---_==__   |_|     __=-                \
    // 	   / |  _______     ----_    -=__                  |
    // 	   |  \_/      -----___| |       =-_              _/
    // 	|           \ \     \\\\      __ ---__       _ -
    // 	|            \ /     ^^^         ---  -------
    // 		\_         _|-
    // 		 \_________/
    // 	_/   -----  -_.
    // 	/_/|  || ||   _/--__
    // 	/  |_      _-       --_
    // 	/     ------            |
    // 	/      __------____/     |
    // 	|      /           /     /
    // 	/      /            |     |
    // 	(     /              |____|
    // 	/\__/                 |  |
    // 	(  /                  |  /-__
    // \  |                  (______)
    // \\\)
    //
    // HA-DOU-KEN!!!!!!!!!!
    for (const passengerId in selectedServices) {
        if (selectedServices.hasOwnProperty(passengerId)) {
            const passengerServices = selectedServices[passengerId];
            for (const mealId in passengerServices) {
                if (passengerServices.hasOwnProperty(mealId) && mealsMap.has(mealId)) {
                    const servicesBySegments = passengerServices[mealId];
                    for (const segmentId in servicesBySegments) {
                        if (servicesBySegments.hasOwnProperty(segmentId)) {
                            const count = servicesBySegments[segmentId];
                            if (count > 0) {
                                result.push({
                                    passengerId,
                                    segmentId,
                                    count,
                                    mealId,
                                    confirmedCount: (_c = (_b = (_a = confirmedServices[passengerId]) === null || _a === void 0 ? void 0 : _a[mealId]) === null || _b === void 0 ? void 0 : _b[segmentId]) !== null && _c !== void 0 ? _c : 0
                                });
                            }
                        }
                    }
                }
            }
        }
    }
    return result;
});
export const getSelectedBaggage = createSelector(getSelectedServices, getConfirmedServices, getAllServices, (selectedServices, confirmedServices, allServices) => {
    var _a, _b, _c;
    const result = [];
    const map = new Map();
    allServices.forEach(service => {
        if (service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage) {
            map.set(service.id, true);
        }
    });
    for (const passengerId in selectedServices) {
        if (selectedServices.hasOwnProperty(passengerId)) {
            const passengerServices = selectedServices[passengerId];
            const selectedBaggage = {};
            for (const baggageId in passengerServices) {
                if (passengerServices.hasOwnProperty(baggageId) && map.has(baggageId)) {
                    const servicesBySegments = passengerServices[baggageId];
                    for (const segmentId in servicesBySegments) {
                        if (servicesBySegments.hasOwnProperty(segmentId)) {
                            const count = servicesBySegments[segmentId];
                            if (count > 0) {
                                if (!selectedBaggage.hasOwnProperty(baggageId)) {
                                    selectedBaggage[baggageId] = {};
                                }
                                if (!selectedBaggage[baggageId].hasOwnProperty(segmentId)) {
                                    selectedBaggage[baggageId][segmentId] = {};
                                }
                                selectedBaggage[baggageId][segmentId] = {
                                    id: baggageId,
                                    serviceId: baggageId,
                                    segmentIds: [segmentId],
                                    count,
                                    confirmedCount: (_c = (_b = (_a = confirmedServices[passengerId]) === null || _a === void 0 ? void 0 : _a[baggageId]) === null || _b === void 0 ? void 0 : _b[segmentId]) !== null && _c !== void 0 ? _c : 0
                                };
                            }
                        }
                    }
                }
            }
            result[passengerId] = selectedBaggage;
        }
    }
    return result;
});
export const getSelectedExtraSeats = createSelector(getSelectedServices, getConfirmedServices, getAllServices, (selectedServices, confirmedServices, allServices) => {
    let result = [];
    const extraSeatsSet = new Set(allServices
        .filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
        (service === null || service === void 0 ? void 0 : service.subType) ===
            GdsServiceVipSubType.ExtraSeat)
        .map(service => service.id));
    for (const passengerId in selectedServices) {
        if (selectedServices.hasOwnProperty(passengerId)) {
            const passengerServices = selectedServices[passengerId];
            for (const extraSeatId in passengerServices) {
                if (passengerServices.hasOwnProperty(extraSeatId) && extraSeatsSet.has(extraSeatId)) {
                    const service = allServices.find(service => service.id === extraSeatId);
                    if (service) {
                        result = [
                            ...result,
                            Object.assign(Object.assign({}, service), { passengerId })
                        ];
                    }
                }
            }
        }
    }
    return result;
});
