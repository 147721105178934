import { __awaiter, __rest } from "tslib";
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Modal from '../../../Modal';
import Input from '../../../Input';
import Button from '../../../Button/Button';
import { useTheme } from '../../../theme';
import { useRetrieveBookingValidation } from '../../hooks';
const ClarifyDocumentModal = (_a) => {
    var { onSubmit, searchParams } = _a, props = __rest(_a, ["onSubmit", "searchParams"]);
    const { t } = useTranslation('RetrieveBooking');
    const { ClarifyDocumentModal: css } = useTheme('RetrieveBooking');
    const [isLoading, setIsLoading] = useState(false);
    const [ticketIsWrong, setTicketIsWrong] = useState(false);
    const { ticketNumberValidation, ticketNumberFormat } = useRetrieveBookingValidation();
    const handleSubmit = ({ extraSecret }) => __awaiter(void 0, void 0, void 0, function* () {
        setTicketIsWrong(false);
        setIsLoading(true);
        const result = yield onSubmit(Object.assign(Object.assign({}, searchParams), { extraSecret }));
        if (result) {
            props.onClose();
        }
        else {
            setTicketIsWrong(true);
        }
        setIsLoading(false);
    });
    return (React.createElement(Modal, Object.assign({}, props, { maxWidth: "sm" }),
        React.createElement("div", { className: css.content },
            React.createElement("h4", { className: css.header }, t('Enter ticket number')),
            React.createElement("p", { className: css.message },
                t('Please enter ticket number for passenger you would like to check-in'),
                "."),
            React.createElement(Form, { onSubmit: handleSubmit }, ({ handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit, className: css.form },
                React.createElement(Field, { format: ticketNumberFormat, name: "extraSecret", validate: (value) => {
                        if (!value) {
                            return t('Required field');
                        }
                        return ticketNumberValidation(value);
                    }, render: ({ input, meta }) => (React.createElement(Input, { disabled: isLoading, absoluteHintPosition: true, label: t('Ticket number'), TextFieldProps: Object.assign(Object.assign({}, input), { helperText: (ticketIsWrong && t('Passenger not found')) ||
                                (((meta.modified && meta.touched) || meta.submitFailed) &&
                                    (meta.error || meta.submitError)), error: ticketIsWrong ||
                                (((meta.modified && meta.touched) || meta.submitFailed) &&
                                    (!!meta.error || !!meta.submitError)) }) })) }),
                React.createElement(Button, { type: "submit", isLoading: isLoading, className: css.submit }, t('Open'))))))));
};
export default ClarifyDocumentModal;
