import * as React from 'react';
import cn from 'classnames';
import Wings from './Wings/Wings';
import { useTheme } from '../../../theme';
import Row from './Row/Row';
import { getRowsWithDifferentClasses } from './utils';
import { useContext } from 'react';
import { SeatsRecommendContext } from '../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import SeatMapError from './SeatMapError/SeatMapError';
import { useConfig } from '../../../context';
import { isSegmentSeatMapTooWide } from '../SeatMapService/Content/utils';
const SeatMap = React.memo(props => {
    const theme = useTheme('SeatMap').SeatMapStyles;
    const { global: { seatMap: { splitByCabin } } } = useConfig();
    const seatMapRef = React.useRef(null);
    const rows = props.segment.decks[0].rows;
    const isSeatMapTooWide = isSegmentSeatMapTooWide(props.segment);
    const isSeatMapSmall = rows.length <= 20 && props.passengers.length > 2;
    const seatRecommendContext = useContext(SeatsRecommendContext);
    const rowsWithDifferentClasses = React.useMemo(() => getRowsWithDifferentClasses(rows, splitByCabin, props.isNewServicePredicate), [rows, props.isNewServicePredicate]);
    return (React.createElement("div", { className: cn(theme.container, { [theme.container_small]: isSeatMapSmall }), ref: props.seatMapRef },
        React.createElement(SeatMapError, null),
        seatRecommendContext.isOpen && React.createElement("div", { className: theme.map__overlay }),
        React.createElement("div", { className: cn(theme.seatMap__wrapper, { [theme.seatMap__wrapper_withoutWings]: false }), ref: seatMapRef },
            React.createElement("div", { className: cn(theme.seatMap, {
                    [theme.seatMap_wide]: isSeatMapTooWide
                }) },
                React.createElement(Wings, null, rows.map((row, rowIndex) => {
                    const isNewServiceClass = rowsWithDifferentClasses.has(row.number);
                    return (React.createElement(Row, { allSeatServices: props.allSeatServices, services: props.services, key: row.number, isSeatMapWide: isSeatMapTooWide, onSelect: props.onSelect, passengers: props.passengers, nextRow: rows[rowIndex + 1], passengerId: props.passengerId, segmentId: props.segment.segmentInfo.id, lastRow: rows.length - 1, rowIndex: rowIndex, row: row, newClassType: isNewServiceClass, onClear: props.onClear, rows: rows, activeDoubleSeats: props.activeDoubleSeats, rowsWithDifferentClasses: rowsWithDifferentClasses }));
                }))))));
}, (prevProps, nextProps) => !nextProps.isActive);
export default SeatMap;
