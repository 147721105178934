import { createSelector } from 'reselect';
import { StepType } from '../../types';
import { getHasSelectedPassengers } from '../passengers/selectors';
import { getSelectedReason } from '../reason/selectors';
import { getIsAllDocumentsProvided } from '../documents/selectors';
export const getSteps = (state) => state.steps;
export const getActiveStep = createSelector(getSteps, steps => steps.find(step => step.isActive));
export const getCanGoToNextStep = createSelector(getActiveStep, getHasSelectedPassengers, getSelectedReason, getIsAllDocumentsProvided, (activeStep, hasSelectedPassengers, selectedReason, isAllDocumentsProvided) => {
    switch (activeStep.type) {
        case StepType.Passengers:
            return hasSelectedPassengers;
        case StepType.Reason:
            return !!selectedReason;
        case StepType.AttachFiles:
            return isAllDocumentsProvided;
        default:
            return true;
    }
});
