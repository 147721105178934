import { __awaiter } from "tslib";
import * as React from 'react';
import { useMemo, useState } from 'react';
import cn from 'classnames';
import { Form } from 'react-final-form';
import { useTheme } from '../../../theme';
import { FormStages, TravellerFields } from '../../types';
import { useTranslation } from 'react-i18next';
import Preferences from '../Preferences/Preferences';
import { TravellerTypes } from '../../../Traveller/types';
import { clearPhoneNumber } from '../../../PhoneInput/utils';
import { successLoadingIcon as successIcon } from '../../Icons';
import { DeleteIcon, Tooltip } from '../../../index';
import { formatInvalidPassengerDate, validate } from '../../../Passenger/utils';
import { ButtonBase } from '@material-ui/core';
import { citizenshipMutator, convertDocuments, docTypeMutator, documentMutator, documentsMutator, fixEmptyFieldDocumentMutator, fixEmptyFieldMutator, getInputType } from '../../utils';
import { TravellerFieldEnum } from '@websky/graphql';
import DocumentsSection from '../DocumentsSection/DocumentsSection';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import PersonalInfoSection from '../PersonalInfoSection/PersonalInfoSection';
import ContactsSection from '../ContactsSection/ContactsSection';
import Button from '../../../BaseComponents/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { initialTravellerDocument } from '../../consts';
import { convertGenderToCorrectValue } from '../../../utils';
const SET_STAGE_TIMEOUT = 500;
const ERROR_TIMEOUT = 5000;
const TravellerForm = ({ traveller, type, showPreferences, primaryCountryCodes, onSubmit, onDelete, onClose }) => {
    const { TravellerForm: css } = useTheme('TravellerForm');
    const { t: tTraveller } = useTranslation('Traveller');
    const { t: tPassenger } = useTranslation('Passenger');
    const [stage, setStage] = useState(FormStages.Default);
    const [openedErrorTooltip, setOpenedErrorTooltip] = useState(false);
    const fieldValuesObject = useMemo(() => {
        var _a;
        const values = {};
        for (const field of traveller.values) {
            if (field.type === TravellerFieldEnum.Gender) {
                values[field.name] = convertGenderToCorrectValue(field.value);
            }
            else {
                values[field.name] = formatInvalidPassengerDate(field.type, field.value);
            }
        }
        if ((_a = traveller.documents) === null || _a === void 0 ? void 0 : _a.length) {
            values[TravellerFields.Documents] = [...convertDocuments(traveller.documents)];
        }
        else {
            values[TravellerFields.Documents] = [initialTravellerDocument];
        }
        return values;
    }, [traveller]);
    const defaultFormValues = useMemo(() => {
        var _a;
        const res = {};
        traveller.values.forEach(v => (res[v.name] = v));
        (_a = traveller.documents[0]) === null || _a === void 0 ? void 0 : _a.values.forEach(v => (res[v.name] = v));
        return res;
    }, [traveller]);
    const onPreInnerSubmit = (event, handleSubmit, form, hasValidationErrors) => {
        if (!hasValidationErrors) {
            handleSubmit(event).then(res => {
                if (res) {
                    setStage(FormStages.Success);
                    if (type !== TravellerTypes.PersonalDetails) {
                        setTimeout(() => {
                            form.reset();
                            setStage(FormStages.Default);
                        }, SET_STAGE_TIMEOUT);
                    }
                    else {
                        setTimeout(() => {
                            setStage(FormStages.Default);
                        }, SET_STAGE_TIMEOUT);
                    }
                }
                else {
                    setStage(FormStages.Default);
                    setOpenedErrorTooltip(true);
                    setTimeout(() => {
                        setOpenedErrorTooltip(false);
                    }, ERROR_TIMEOUT);
                }
            });
        }
        else {
            handleSubmit(event);
        }
    };
    const onInnerSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setStage(FormStages.Loading);
        const data = yield onSubmit({
            firstName: values.firstName,
            lastName: values.lastName,
            middleName: values.middleName,
            citizenship: values.nationality,
            birthDate: values.birthDate,
            gender: values.gender,
            email: values.email,
            phone: values.phone && clearPhoneNumber(values.phone),
            documents: values.documents.map(doc => ({
                type: doc.docType,
                number: doc.docNumber,
                expiryDate: doc.docExpiryDate,
                citizenship: doc.citizenship,
                firstName: doc.firstName,
                lastName: doc.lastName,
                middleName: doc.middleName
            }))
        });
        if (data && onClose) {
            setTimeout(onClose, 500);
        }
        return data;
    });
    const validateForm = (allValues) => {
        const documents = [];
        function validateValues(values) {
            var _a;
            const valuesErrors = {};
            for (const docFieldItem of Object.entries(values)) {
                const fieldName = docFieldItem[0];
                const inputType = getInputType(fieldName);
                const inputValue = docFieldItem[1];
                const validationRules = (_a = defaultFormValues[fieldName]) === null || _a === void 0 ? void 0 : _a.validationRules;
                if (validationRules) {
                    const error = validate(inputValue, values, inputType, validationRules);
                    if (error) {
                        valuesErrors[fieldName] = tPassenger(error);
                    }
                }
            }
            return valuesErrors;
        }
        const commonFieldsErrors = validateValues(allValues);
        allValues.documents.forEach(doc => {
            const docErrors = validateValues(doc);
            documents.push(docErrors);
        });
        return Object.assign(Object.assign({}, commonFieldsErrors), { documents });
    };
    const renderLoading = (button) => {
        return stage === FormStages.Loading ? (React.createElement("div", { className: css.loader },
            React.createElement(CircularProgress, { size: "100%", classes: { root: css.circularProgress } }))) : stage === FormStages.Success ? (React.createElement("div", { className: cn(css.loader, css.success) },
            React.createElement("div", { className: css.success__icon },
                successIcon,
                " "))) : (button);
    };
    const onCancel = React.useCallback((form) => {
        onClose();
        form.reset();
    }, [onClose]);
    return (React.createElement("div", { className: css.travellerForm },
        React.createElement(Form, { initialValues: fieldValuesObject, onSubmit: onInnerSubmit, validate: validateForm, mutators: {
                documentsMutator,
                documentMutator,
                docTypeMutator,
                citizenshipMutator,
                fixEmptyFieldMutator,
                fixEmptyFieldDocumentMutator
            }, render: ({ handleSubmit, form, hasValidationErrors, values }) => (React.createElement("form", { onSubmit: event => onPreInnerSubmit(event, handleSubmit, form, hasValidationErrors) },
                React.createElement("div", { className: css.travellerForm__content },
                    React.createElement(SectionWrapper, { title: tTraveller('Documents') },
                        React.createElement(DocumentsSection, { documents: values.documents, traveller: traveller, primaryCountryCodes: primaryCountryCodes })),
                    React.createElement(SectionWrapper, { title: tTraveller('Personal info') },
                        React.createElement(PersonalInfoSection, null)),
                    React.createElement(SectionWrapper, { title: tTraveller('Contacts') },
                        React.createElement("p", { className: css.description }, tTraveller('With the help of this data, we will be able to inform the fellow traveler about changes in booking, flight status, check-in time, etc. Optional data.')),
                        React.createElement(ContactsSection, null)),
                    showPreferences && (React.createElement(Preferences, { Meal: ['Vegetarian menu', 'same another'], Seats: ['Window seat', 'Center seat'] })),
                    React.createElement("div", { className: cn(css.travellerForm__contentControls) },
                        type === TravellerTypes.CompanionFilled && (React.createElement(ButtonBase, { className: css.removeButton, onClick: onDelete },
                            DeleteIcon,
                            tTraveller('Remove passengers data'))),
                        React.createElement("div", { className: css.travellerForm__actions }, renderLoading(React.createElement(React.Fragment, null,
                            onClose && (React.createElement(Button, { variant: "smallSecondary", className: css.cancelButton, onClick: () => onCancel(form) }, tTraveller('Cancel'))),
                            React.createElement(Tooltip, { title: tTraveller('An error occurred while executing the request, please try again'), open: openedErrorTooltip },
                                React.createElement(Button, { className: css.saveButton, variant: "smallPrimary", type: "submit" }, tTraveller('Save')))))))))) })));
};
export default TravellerForm;
