import { useSelector } from 'react-redux';
import { getOrderId, getTravellers, getIsNew } from '../../../../store/order/selectors';
import { useOrderSubsidyInfoQuery } from '@websky/graphql';
import { useCallback, useMemo, useState } from 'react';
export const useSubsidies = () => {
    const orderId = useSelector(getOrderId), travellers = useSelector(getTravellers), isNewOrder = useSelector(getIsNew), isSubsidiesAvailable = travellers.every(traveller => { var _a, _b; return !!((_b = (_a = traveller.subsidyInfo) === null || _a === void 0 ? void 0 : _a.subsidies) === null || _b === void 0 ? void 0 : _b.length); }), [subsidyTravellers, setSubsidyTravellers] = useState(travellers.map(traveller => ({
        traveller,
        subsidyId: null,
        failedSegment: null,
        rejectMessage: null,
        isSubsidyApplied: false,
        subsidies: []
    })));
    const [loading, setLoading] = useState(false);
    const { refetch } = useOrderSubsidyInfoQuery({
        skip: true
    });
    const isSubsidiesRejected = useMemo(() => {
        return subsidyTravellers.some(traveller => traveller.isSubsidyRejected);
    }, [subsidyTravellers]);
    const checkSubsidy = useCallback((traveller) => {
        setLoading(true);
        return new Promise(resolve => {
            refetch({
                id: orderId,
                travellers: [
                    Object.assign({}, traveller)
                ]
            })
                .then(({ data }) => {
                var _a;
                let failedSegment = null, rejectMessage = null, isSubsidyApplied = false, newSubsidyTraveller = {
                    values: traveller.values,
                    id: traveller.id
                };
                const subsidies = [];
                if (data.OrderSubsidyInfo) {
                    isSubsidyApplied = true;
                    for (const segment of data.OrderSubsidyInfo.segments) {
                        const travellerWithSubsidy = segment.travellers.find(passenger => passenger.traveller.id === traveller.id);
                        newSubsidyTraveller = travellerWithSubsidy.traveller;
                        if (!!((_a = travellerWithSubsidy === null || travellerWithSubsidy === void 0 ? void 0 : travellerWithSubsidy.subsidyInfo) === null || _a === void 0 ? void 0 : _a.rejectMessage)) {
                            rejectMessage = travellerWithSubsidy.subsidyInfo.rejectMessage;
                            failedSegment = segment.segment;
                            isSubsidyApplied = false;
                            break;
                        }
                        subsidies.push({
                            segmentId: segment.segment.id,
                            subsidyId: travellerWithSubsidy.subsidyInfo.subsidyId
                        });
                    }
                }
                setSubsidyTravellers(subsidyTravellers => subsidyTravellers.map(subsidyTraveller => {
                    if (subsidyTraveller.traveller.id === traveller.id) {
                        return {
                            traveller: Object.assign({}, newSubsidyTraveller),
                            failedSegment,
                            rejectMessage,
                            subsidyId: traveller.subsidyId,
                            isSubsidyApplied,
                            isSubsidyRejected: !isSubsidyApplied,
                            subsidies
                        };
                    }
                    return subsidyTraveller;
                }));
                resolve({
                    isSubsidyApplied,
                    failedSegment,
                    rejectMessage,
                    traveller: Object.assign({}, newSubsidyTraveller)
                });
            })
                .catch(error => { })
                .finally(() => setLoading(false));
        });
    }, []);
    return useMemo(() => {
        return {
            travellers: subsidyTravellers,
            isLoading: loading,
            isSubsidiesAvailable,
            checkSubsidy,
            isSubsidiesRejected,
            canCheckSubsidies: isNewOrder && isSubsidiesAvailable && !isSubsidiesRejected
        };
    }, [subsidyTravellers, loading, isSubsidiesAvailable, checkSubsidy, isSubsidiesRejected, isNewOrder]);
};
