import { useMemo, useReducer } from 'react';
import { isRoundTrip } from './utils';
import { useToggleable } from '../../../hooks';
import { getTotalPrice } from '../../../utils';
import { parse } from 'date-fns';
const reducer = (state, action) => {
    if (action.type === 'set') {
        if (isRoundTrip(action.value)) {
            let newState = Object.assign({}, state);
            for (const key in newState) {
                newState = Object.assign(Object.assign({}, newState), { [key]: null });
            }
            return Object.assign(Object.assign({}, newState), { [action.key]: action.value });
        }
        else {
            let newState = Object.assign({}, state);
            for (const key in newState) {
                if (newState[key] && isRoundTrip(newState[key])) {
                    newState = Object.assign(Object.assign({}, newState), { [key]: null });
                }
            }
            return Object.assign(Object.assign({}, newState), { [action.key]: action.value });
        }
    }
    else if (action.type === 'clear') {
        let clearState = Object.assign({}, state);
        for (const key in clearState) {
            clearState = Object.assign(Object.assign({}, clearState), { [key]: null });
        }
        return clearState;
    }
    return state;
};
export const useAeroexpressState = (trips, selectedTrips) => {
    const initialState = useMemo(() => {
        let state = {};
        trips.forEach(trip => {
            var _c;
            const key = trip.fromStation.direction;
            if (!state[key]) {
                const selectedTrip = (_c = selectedTrips.find(selectedTrip => selectedTrip.id === trip.id)) !== null && _c !== void 0 ? _c : null;
                state = Object.assign(Object.assign({}, state), { [key]: selectedTrip });
            }
        });
        return state;
    }, [trips, selectedTrips]);
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isOpen: allPassengers, toggle: toggleAllPassengers } = useToggleable(false);
    const tripsByDirection = useMemo(() => {
        const tripsByDirectionMap = new Map();
        trips.forEach(trip => {
            var _c;
            const key = trip.fromStation.direction;
            tripsByDirectionMap.set(key, [...((_c = tripsByDirectionMap.get(key)) !== null && _c !== void 0 ? _c : []), trip]);
        });
        return [...tripsByDirectionMap.entries()].sort(([_a, tripsA], [_b, tripsB]) => {
            const tripA = tripsA[0];
            const tripB = tripsB[0];
            if (isRoundTrip(tripA) || isRoundTrip(tripB)) {
                return 1;
            }
            const departureDateA = (tripA === null || tripA === void 0 ? void 0 : tripA.departureDate)
                ? parse(tripsA[0].departureDate, 'HH:mm dd.MM.yyyy', new Date())
                : null;
            const departureDateB = (tripB === null || tripB === void 0 ? void 0 : tripB.departureDate)
                ? parse(tripsB[0].departureDate, 'HH:mm dd.MM.yyyy', new Date())
                : null;
            return departureDateA > departureDateB ? 1 : -1;
        });
    }, [trips]);
    const selectedTripItems = useMemo(() => {
        let selectedItems = [];
        Object.keys(state).forEach(key => {
            const item = state[key];
            if (item) {
                selectedItems = [...selectedItems, item];
            }
        });
        return selectedItems;
    }, [state, trips]);
    const totalPrice = useMemo(() => {
        if (selectedTripItems.length) {
            return getTotalPrice(selectedTripItems, item => item.price);
        }
        return null;
    }, [selectedTripItems]);
    const setTrip = (key, trip) => {
        dispatch({ type: 'set', key, value: trip });
    };
    const clearTrips = () => {
        dispatch({ type: 'clear' });
    };
    return {
        state,
        tripsByDirection,
        selectedTripItems,
        totalPrice,
        allPassengers,
        setTrip,
        clearTrips,
        toggleAllPassengers
    };
};
