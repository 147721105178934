export const reducer = (state, action) => {
    switch (action.type) {
        case 'setTotalPrice': {
            return Object.assign(Object.assign({}, state), { totalPrice: action.payload });
        }
        case 'toggleSegment':
            if (state.selectedSegments.includes(action.payload)) {
                return Object.assign(Object.assign({}, state), { selectedSegments: state.selectedSegments.filter(segment => segment !== action.payload) });
            }
            else {
                return Object.assign(Object.assign({}, state), { selectedSegments: state.selectedSegments.concat([action.payload]) });
            }
        case 'setStep':
            return Object.assign(Object.assign({}, state), { step: action.payload });
    }
};
