import { format, parse, parseISO } from 'date-fns';
import { TravellerDocumentFields, TravellerFields } from './types';
import { InputType } from '../Passenger/types';
import { initialTravellerDocument } from './consts';
import { TravellerFieldEnum } from '@websky/graphql';
import { getUserValue } from '../utils';
export const convertDocuments = (documentsList) => {
    return documentsList.map(document => {
        const firstName = getUserValue(document, TravellerFieldEnum.FirstName);
        const lastName = getUserValue(document, TravellerFieldEnum.LastName);
        const middleName = getUserValue(document, TravellerFieldEnum.MiddleName);
        const docNumber = getUserValue(document, TravellerFieldEnum.DocNumber);
        const docType = getUserValue(document, TravellerFieldEnum.DocType);
        const options = document.values
            .find(value => value.name === 'docType')
            .validationRules[0].options.map(option => option.value);
        let docExpiryDate = null;
        const docExpiryDateField = getUserValue(document, TravellerFieldEnum.DocExpiryDate);
        if (docExpiryDateField) {
            try {
                docExpiryDate = format(parseISO(docExpiryDateField), 'dd.MM.yyyy');
            }
            catch (e) {
                try {
                    docExpiryDate = format(parse(docExpiryDateField, 'dd.MM.yyyy', new Date()), 'dd.mm.yyyy');
                }
                catch (e) {
                    docExpiryDate = format(parseISO(docExpiryDateField), 'dd-MM-yyyy');
                }
            }
        }
        const citizenship = getUserValue(document, TravellerFieldEnum.Citizenship);
        return {
            firstName,
            lastName,
            middleName,
            docNumber,
            docType,
            docExpiryDate,
            citizenship,
            options
        };
    });
};
export const getInputType = (fieldName) => {
    if (fieldName === TravellerFields.BirthDate) {
        return InputType.Date;
    }
    if (fieldName === TravellerFields.Gender) {
        return InputType.Switch;
    }
    if (fieldName === TravellerDocumentFields.Nationality) {
        return InputType.Switch;
    }
    if (fieldName === TravellerFields.Phone) {
        return InputType.Phone;
    }
    return InputType.Text;
};
export const documentsMutator = (params, state, utils) => {
    const documents = state.formState.values[TravellerFields.Documents];
    if (params[0].type === 'add') {
        utils.changeValue(state, TravellerFields.Documents, () => [
            ...state.formState.values[TravellerFields.Documents],
            initialTravellerDocument
        ]);
    }
    else if (params[0].type === 'remove') {
        const updatedDocuments = documents.filter((_, index) => index !== params[0].index);
        utils.changeValue(state, TravellerFields.Documents, () => updatedDocuments);
    }
};
export const documentMutator = (params, state, utils) => {
    const firstDocument = state.formState.values[TravellerFields.Documents][0];
    const fieldName = params[0].fieldName;
    utils.changeValue(state, fieldName, () => firstDocument[fieldName]);
};
export const docTypeMutator = (params, state, utils) => {
    const documents = state.formState.values[TravellerFields.Documents];
    utils.changeValue(state, TravellerFields.Documents, () => documents.map((document, index) => {
        if (index === params[0].index) {
            return Object.assign(Object.assign({}, document), { docExpiryDate: '' });
        }
        return document;
    }));
};
export const citizenshipMutator = (params, state, utils) => {
    const documents = state.formState.values[TravellerFields.Documents];
    utils.changeValue(state, TravellerFields.Documents, () => documents.map((document, index) => {
        if (index === params[0].index) {
            return Object.assign(Object.assign({}, document), { docType: '' });
        }
        return document;
    }));
    utils.changeValue(state, TravellerFields.Nationality, () => documents[0].citizenship);
};
export const fixEmptyFieldMutator = (params, state, utils) => {
    const fieldName = params[0].fieldName;
    if (!(fieldName in state.formState.values)) {
        utils.changeValue(state, fieldName, () => (state.formState.values[fieldName] = ''));
    }
};
export const fixEmptyFieldDocumentMutator = (params, state, utils) => {
    const doc = state.formState.values[TravellerFields.Documents][params[0].index];
    const fieldName = params[0].fieldName;
    if (!(fieldName in doc)) {
        utils.changeValue(state, fieldName, () => (doc[fieldName] = ''));
    }
};
