import { __rest } from "tslib";
import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import { useTheme } from '../../theme';
import PaginationItem from '@mui/material/PaginationItem';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../utils';
const siblingCountMap = {
    mobile: 0,
    tablet: 1,
    desktop: 2
};
const WxPagination = props => {
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const isTablet = useMediaQuery({ maxWidth: TABLET_MIN_WIDTH });
    const { WxPagination: css } = useTheme('WxPagination');
    const { variant = 'text', pages, onHandleChange, children } = props, rest = __rest(props, ["variant", "pages", "onHandleChange", "children"]);
    const handleChange = (event, value) => {
        onHandleChange(value);
    };
    const siblingCount = React.useMemo(() => {
        if (isMobile) {
            return siblingCountMap.mobile;
        }
        if (isTablet) {
            return siblingCountMap.tablet;
        }
        return siblingCountMap.desktop;
    }, [isMobile, isTablet]);
    return (React.createElement(Pagination, Object.assign({ className: props.className, classes: {
            root: css.root,
            ul: css.ul
        }, count: pages, page: props.page, onChange: handleChange, siblingCount: siblingCount, renderItem: item => (React.createElement(PaginationItem, Object.assign({ classes: {
                icon: css.icon,
                disabled: css.icon,
                root: css.button,
                page: css.page,
                selected: css.selected,
                previousNext: css.button
            } }, item))) }, rest)));
};
export default WxPagination;
