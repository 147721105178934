import * as React from 'react';
import { useTheme } from '../../../../../../../theme';
import { flightIcon, passengerIcon } from '../../../icons';
import cn from 'classnames';
const closeIcon = (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M12.6673 4.2735L11.7273 3.3335L8.00065 7.06016L4.27398 3.3335L3.33398 4.2735L7.06065 8.00016L3.33398 11.7268L4.27398 12.6668L8.00065 8.94016L11.7273 12.6668L12.6673 11.7268L8.94065 8.00016L12.6673 4.2735Z", fill: "currentColor" })));
const Chip = ({ label, onClick, type, className, readonly }) => {
    const theme = useTheme('Exchange').Chip;
    return (React.createElement("div", { className: cn(theme.chip, className, {
            [theme.chip_readonly]: readonly
        }), onClick: onClick },
        type === 'flight' ? flightIcon : passengerIcon,
        label,
        !readonly && React.createElement("div", { className: theme.close }, closeIcon)));
};
export default Chip;
