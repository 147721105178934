import React from 'react';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import AiPurchasePredictionMeal from '../AiPurchasePredictionMeal/AiPurchasePredictionMeal';
import AiPurchasePredictionBaggage from '../AiPurchasePredictionBaggage/AiPurchasePredictionBaggage';
const AiPurchasePredictionItem = ({ order, service, onChange, onDisagree, onAgree }) => {
    const onAgreeAddServiceToOrder = (service) => {
        const segments = [];
        service.allowedSegments.forEach(segment => {
            segment.forEach(subSegment => {
                segments.push(subSegment);
            });
        });
        onAgree({
            passengerId: service.allowedPassengers[0][0],
            segmentId: service.allowedSegments[0][0],
            allowedSegments: service.allowedSegments,
            segmentIds: segments,
            serviceId: service.id,
            service: {
                id: service.id,
                type: service.type,
                price: service.price
            }
        });
    };
    if (service.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
        const meal = service;
        return (React.createElement(AiPurchasePredictionMeal, { order: order, meal: meal, onDisagree: onDisagree, onChange: () => onChange({ type: meal.type }), onAgree: () => onAgreeAddServiceToOrder(meal) }));
    }
    if (service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage) {
        const baggage = service;
        return (React.createElement(AiPurchasePredictionBaggage, { item: baggage, onDisagree: onDisagree, onChange: () => onChange({ type: baggage.type, subType: baggage.baggageType }), onAgree: () => onAgreeAddServiceToOrder(baggage) }));
    }
    return React.createElement(React.Fragment, null);
};
export default AiPurchasePredictionItem;
