export const CisCountries = ['AM', 'AZ', 'BY', 'MD', 'TJ', 'KZ', 'KG', 'UZ', 'RU'];
export const initialTravellerDocument = {
    firstName: '',
    lastName: '',
    middleName: '',
    docType: null,
    docNumber: '',
    docExpiryDate: '',
    citizenship: ''
};
