import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import Subscribe from '../../Checkout/components/Checkout/Upsale/Subscribe/Subscribe';
import Aeroexpress from '../../Aeroexpress';
import { AdditionalServiceType } from '../types';
import { useTheme } from '../../theme';
import { DisplayMode } from './types';
import CheckinMultiInsurance from '../../Insurance/CheckinInsurance/CheckinInsurance';
import Insurance from './Insurance/Insurance';
import { ModeContext, useConfig } from '../../context';
import { Mode } from '../../types';
import Business from './Business/Business';
import { makeGetGdsServicesByType } from '../../Modules/Checkin/store/order/selectors';
import { isCheckinOrderServicesResponse, isOrderServicesResponse } from '../../typeguards';
// #fixme: connect to redux
export default function AdditionalServices({ displayMode, isUpsaleVisible = false, orderId, additionalServices, onSetOrder, travellers, inMobileStepbar, className, saveOrderServices, isBlockForPayment }) {
    var _a, _b, _c, _d, _e;
    const theme = useTheme('AdditionalServices').AdditionalServicesStyles;
    const mode = useContext(ModeContext);
    const [isLoading, setIsLoading] = useState(false);
    const updateServices = (type, codes) => __awaiter(this, void 0, void 0, function* () {
        setIsLoading(true);
        const params = {
            id: orderId
        };
        if (type === AdditionalServiceType.Insurance) {
            params.insurances = [
                ...params.insurances,
                {
                    selectedPrograms: codes
                }
            ];
        }
        if (type === AdditionalServiceType.Transfer) {
            params.aeroexpress = Object.assign(Object.assign({}, params.aeroexpress), { selectedTrips: codes });
        }
        try {
            const data = yield saveOrderServices(params);
            if (data && isOrderServicesResponse(data)) {
                onSetOrder(data.SaveOrderServices);
            }
            else if (data && isCheckinOrderServicesResponse(data)) {
                onSetOrder(data.CheckinSaveOrderServices);
            }
            else {
                throw new Error('Не удалось добавить сервис');
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setIsLoading(false);
        }
    });
    const updateInsurances = updateServices.bind(null, AdditionalServiceType.Insurance);
    const updateTransfer = updateServices.bind(null, AdditionalServiceType.Transfer);
    const { global: { companyInfo: { multiInsurance: multiInsurance } } } = useConfig();
    const renderInsurance = useCallback(() => {
        var _a, _b, _c;
        const selectedInsurances = ((_a = additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.selectedInsurances)
            ? additionalServices.insurances.selectedInsurances
            : [];
        const selectedProgram = ((_b = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.insurances) === null || _b === void 0 ? void 0 : _b.insurancePrograms.find(program => selectedInsurances.find(insurace => program.code === insurace.insuranceProgram.code))) || ((_c = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.insurances) === null || _c === void 0 ? void 0 : _c.insurancePrograms.slice(0, 1)[0]);
        const singleInsurance = (React.createElement(Insurance, { displayMode: !inMobileStepbar && displayMode, insurance: selectedProgram, selectedInsurances: selectedInsurances, onClick: updateInsurances, isMobileStepbar: inMobileStepbar === 'insurance', isBlockForPayment: isBlockForPayment }));
        const multiInsuranceElement = (React.createElement(CheckinMultiInsurance, { travellers: travellers, insurances: additionalServices.insurances, displayMode: !inMobileStepbar && displayMode, isMobileStepbar: inMobileStepbar === 'insurance' }));
        if (inMobileStepbar === 'insurance') {
            if (multiInsurance && mode === Mode.Checkin) {
                return multiInsuranceElement;
            }
            else {
                return React.createElement("span", null, singleInsurance);
            }
        }
        else {
            if (multiInsurance) {
                return multiInsuranceElement;
            }
            else {
                return singleInsurance;
            }
        }
    }, [additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.insurances, inMobileStepbar, multiInsurance, isBlockForPayment]);
    const businessLoungeSelector = makeGetGdsServicesByType(OrderAdditionalServiceGdsServiceServiceType.BusinessLounge);
    const businessLoungeServices = useSelector(businessLoungeSelector);
    return (React.createElement(React.Fragment, null,
        inMobileStepbar === 'insurance' && renderInsurance(),
        inMobileStepbar === 'aeroexpress' && !!((_b = (_a = additionalServices.transfer) === null || _a === void 0 ? void 0 : _a.aeroexpress) === null || _b === void 0 ? void 0 : _b.trips.length) && (React.createElement(Aeroexpress, { isMobileStepbar: true, orderId: orderId, aeroexpress: additionalServices.transfer.aeroexpress, travellers: travellers, updateServices: updateTransfer, isLoading: isLoading, isBlockForPayment: isBlockForPayment })),
        !inMobileStepbar && (React.createElement("div", { className: cn(theme.services, className, {
                [theme.services_horizontal]: displayMode === DisplayMode.Horizontal
            }) },
            !!((_e = (_d = (_c = additionalServices.transfer) === null || _c === void 0 ? void 0 : _c.aeroexpress) === null || _d === void 0 ? void 0 : _d.trips) === null || _e === void 0 ? void 0 : _e.length) && (React.createElement(Aeroexpress, { displayMode: displayMode, orderId: orderId, aeroexpress: additionalServices.transfer.aeroexpress, travellers: travellers, updateServices: updateTransfer, isLoading: isLoading })),
            renderInsurance(),
            (businessLoungeServices === null || businessLoungeServices === void 0 ? void 0 : businessLoungeServices.length) > 0 && React.createElement(Business, null),
            isUpsaleVisible && React.createElement(Subscribe, null)))));
}
