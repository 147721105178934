import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ExareStatus, InfoMessageTypeEnum } from '@websky/graphql';
import { ThemeProvider, useTheme } from '../../../../theme';
import { Included, InfoDetails, NotIncluded } from '../../../../Icons';
import { capitalizeFirstLetter, format, removeDotsFromDate } from '../../../../utils';
import { getPassengerName } from '../../../../Modules/Exchange/components/Exchange/utils';
import { getDifferentPassengerValues } from '../../../../EditPassenger/utils';
import Notification from '../../../../Notification';
const ChangePaxOrderStatus = ({ order, lastChangePaxOrder, onGoToTicket }) => {
    const { t } = useTranslation('Checkout');
    const { ChangePaxOrderStatus: theme } = useTheme('Checkout');
    const notificationTheme = {
        Notification: {
            Notification: {
                wrapper: theme.container,
                context: theme.context,
                info: theme.info,
                warning: theme.warning,
                icon: theme.icon,
                title: theme.title,
                text: theme.text,
                action: theme.action
            }
        }
    };
    const changeOriginalPassengers = React.useMemo(() => {
        return order.travellers.filter(traveller => lastChangePaxOrder.travellers.find(changeTraveller => changeTraveller.id === traveller.id));
    }, [order.travellers, lastChangePaxOrder.travellers]);
    const changePassengersNames = React.useMemo(() => changeOriginalPassengers.map(getPassengerName).map(capitalizeFirstLetter).join(', '), [changeOriginalPassengers]);
    const changeFields = React.useMemo(() => {
        if (changeOriginalPassengers.length && lastChangePaxOrder.travellers.length) {
            const differentValues = getDifferentPassengerValues(changeOriginalPassengers[0].values, lastChangePaxOrder.travellers[0].values);
            return [...differentValues.keys()]
                .map(type => t(`EditPassenger:${type}`))
                .join(', ')
                .toLowerCase();
        }
        return '';
    }, [changeOriginalPassengers, lastChangePaxOrder.travellers]);
    const infoMessage = React.useMemo(() => {
        const isAutoMode = lastChangePaxOrder.automaticMode;
        const formatDate = removeDotsFromDate(format(new Date(lastChangePaxOrder.createDate), 'dd MMM, yyyy HH:mm'));
        const timelimitDate = removeDotsFromDate(format(new Date(lastChangePaxOrder.timelimit), 'dd MMM, yyyy HH:mm'));
        switch (lastChangePaxOrder.status) {
            case ExareStatus.Finished:
                return {
                    icon: Included,
                    status: InfoMessageTypeEnum.Promo,
                    title: t('Changes have been made to passenger data for {{passengers}}', {
                        passengers: changePassengersNames
                    }),
                    text: '',
                    hideAction: true
                };
            case ExareStatus.InProcess:
            case ExareStatus.AwaitingPayment:
            case ExareStatus.NeedConfirm:
                let text = t('Expect confirmation shortly. Request date: {{date}}', {
                    nsSeparator: '|',
                    date: formatDate
                });
                if ((lastChangePaxOrder.status === ExareStatus.AwaitingPayment || isAutoMode) &&
                    lastChangePaxOrder.status !== ExareStatus.InProcess) {
                    text = t('Waiting for payment until {{date}}', { date: timelimitDate });
                }
                else if (lastChangePaxOrder.status === ExareStatus.NeedConfirm) {
                    text = t('Awaiting confirmation. Request date: {{date}}', { nsSeparator: '|', date: formatDate });
                }
                return {
                    icon: InfoDetails,
                    status: InfoMessageTypeEnum.Info,
                    title: t('We have received your request for a {{fields}} change for {{passengers}}', {
                        fields: changeFields,
                        passengers: changePassengersNames
                    }),
                    text
                };
            case ExareStatus.Refused:
            case ExareStatus.Rejected:
            case ExareStatus.TimelimitExpired:
                return {
                    icon: NotIncluded,
                    status: InfoMessageTypeEnum.Warning,
                    title: t('Request for a {{fields}} change for {{passengers}} has been declined', {
                        fields: changeFields,
                        passengers: changePassengersNames
                    }),
                    text: '',
                    hideAction: lastChangePaxOrder.status !== ExareStatus.TimelimitExpired
                };
        }
    }, [lastChangePaxOrder, changeFields, changePassengersNames]);
    return (React.createElement(ThemeProvider, { value: notificationTheme },
        React.createElement(Notification, { icon: infoMessage.icon, status: infoMessage.status, type: "context", title: infoMessage.title, infoMessage: {
                text: infoMessage.text,
                type: infoMessage.status,
                code: 'change-status'
            }, actionText: !infoMessage.hideAction ? t('View ticket') : null, onClickAction: !infoMessage.hideAction ? onGoToTicket : null })));
};
export default ChangePaxOrderStatus;
