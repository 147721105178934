import * as React from 'react';
import { OrderStatus } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../../utils';
initI18n('OrderCard');
const OrderStatusText = ({ status }) => {
    const { t } = useTranslation('OrderCard');
    switch (status) {
        case OrderStatus.Confirmed:
            return React.createElement(React.Fragment, null, t('Confirmed'));
        case OrderStatus.Booked:
            return React.createElement(React.Fragment, null, t('Booked'));
        case OrderStatus.Cancelled:
            return React.createElement(React.Fragment, null, t('Cancelled'));
        case OrderStatus.Problematic:
            return React.createElement(React.Fragment, null, t('Problematic'));
        default:
            return null;
    }
};
export default OrderStatusText;
