import * as React from 'react';
export const usePagination = (arrayItems, itemsOnPage) => {
    const [page, setPage] = React.useState(1);
    const handleChangePage = (page) => {
        setPage(page);
    };
    const itemsCount = Array.isArray(arrayItems) ? arrayItems === null || arrayItems === void 0 ? void 0 : arrayItems.length : arrayItems;
    const pagesCount = Math.ceil(itemsCount / itemsOnPage);
    const itemsPerPage = {
        startValue: itemsOnPage * (page - 1),
        endValue: page * itemsOnPage
    };
    const paginationAvailable = pagesCount && pagesCount > 1;
    return { itemsPerPage, paginationAvailable, pagesCount, handleChangePage, page };
};
