import { __decorate } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import Dropdown from '../../Dropdown/Dropdown';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Input from '../../Input';
import { withTheme } from '../withThemeDecorator';
// TODO: rewrite with functional component
// @ts-ignore
let Autocomplete = class Autocomplete extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onClose = this.onClose.bind(this);
        this.renderGroup = this.renderGroup.bind(this);
        this.renderOption = this.renderOption.bind(this);
        this.renderDropdown = this.renderDropdown.bind(this);
    }
    onFocus(event) {
        var _a, _b;
        (_b = (_a = this.props).onFocus) === null || _b === void 0 ? void 0 : _b.call(_a, event);
        this.setState({
            isOpen: true
        });
    }
    onBlur(event) {
        setTimeout(() => {
            this.setState({
                isOpen: false
            });
        }, 200);
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }
    onClose() {
        this.setState({
            isOpen: false
        });
    }
    getOnSelect(item) {
        return () => {
            this.props.onSelect(item);
            this.onClose();
        };
    }
    renderInput() {
        const { Autocomplete: { Autocomplete: css } } = this.props.theme;
        const { inputValue, onInputChange } = this.props;
        const inputProps = {
            type: 'text',
            className: css.input,
            value: inputValue,
            onChange: onInputChange,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            spellCheck: false,
            autoComplete: 'off',
            autoCorrect: 'off'
        };
        // Custom renderer.
        if (this.props.inputRenderer) {
            return this.props.inputRenderer(this.state.isOpen, this.props.isLoading, inputProps);
        }
        return (React.createElement(Input, { label: this.props.label, value: inputValue, name: this.props.name || '', className: css.input, autoFocus: this.props.autofocus, disabled: this.props.disabled, variant: this.props.variant, TextFieldProps: {
                error: !!this.props.error,
                helperText: this.props.error,
                onBlur: this.onBlur,
                onFocus: this.onFocus,
                autoComplete: inputProps.autoComplete,
                InputProps: {
                    onChange: onInputChange,
                    endAdornment: this.props.endAdornment || '',
                    autoComplete: inputProps.autoComplete,
                    className: css.inner_input
                }
            }, absoluteHintPosition: true }));
    }
    renderGroup(group, key) {
        const { Autocomplete: { Autocomplete: css } } = this.props.theme;
        // Custom renderer.
        if (this.props.groupRenderer) {
            return this.props.groupRenderer(group, key, this.renderOption);
        }
        return (!!group.options.length && (React.createElement("div", { key: key, className: css.group },
            React.createElement("div", { className: css.group__label }, group.label),
            React.createElement("div", { className: css.group__options }, group.options.map(this.renderOption)))));
    }
    renderOption(option, key) {
        const { Autocomplete: { Autocomplete: css } } = this.props.theme;
        // Custom renderer.
        if (this.props.optionRenderer) {
            return this.props.optionRenderer(option, key, this.getOnSelect(option.value));
        }
        if (this.props.callOnSelectOn === 'mouseDown') {
            return (React.createElement(MenuItem, { key: key, className: css.option, onMouseDown: this.getOnSelect(option.value) }, option.label));
        }
        return (React.createElement(MenuItem, { key: key, className: css.option, onClick: this.getOnSelect(option.value) }, option.label));
    }
    renderDropdown() {
        const { dropdownRenderer, options, onClickOutsideProps, onInputChange, onSelect } = this.props;
        const { Autocomplete: { Autocomplete: css } } = this.props.theme;
        // Custom renderer.
        if (dropdownRenderer) {
            return dropdownRenderer({
                options,
                isOpen: this.state.isOpen,
                onClose: this.onClose,
                onInputChange,
                onSelect,
                renderGroup: this.renderGroup,
                renderOption: this.renderOption
            });
        }
        return (React.createElement(Dropdown, Object.assign({}, onClickOutsideProps, { isOpen: options.length && this.state.isOpen, onClose: this.onClose }),
            React.createElement(Paper, { className: css.options, "data-test-id": "options" }, options.map((item, index) => 'value' in item ? this.renderOption(item, index) : this.renderGroup(item, index)))));
    }
    render() {
        const { className, innerRef } = this.props;
        const { Autocomplete: { Autocomplete: css } } = this.props.theme;
        return (React.createElement("div", { ref: innerRef, className: cn(css.autocomplete, className) },
            this.renderInput(),
            this.renderDropdown()));
    }
};
Autocomplete.displayName = 'Autocomplete';
Autocomplete = __decorate([
    withTheme
], Autocomplete);
export default Autocomplete;
