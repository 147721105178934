import * as React from 'react';
import { useContext } from 'react';
import { CartContext } from '../Cart';
import ServiceSummary from '../../ServiceSummary';
import { Meal } from '../../Icons';
import { useTranslation } from 'react-i18next';
import MealSummary from '../../ServiceSummary/services/MealSummary';
import { CheckoutSteps } from '../../MobileStepbar/types';
const Meals = ({ services, travellers, flight, onServiceDelete, renderService }) => {
    var _a;
    const cartConfig = useContext(CartContext);
    const { t } = useTranslation('Cart');
    const group = {
        header: t('Meal'),
        icon: Meal,
        totalPrice: (_a = cartConfig.totalPrices) === null || _a === void 0 ? void 0 : _a.meal
    };
    return (React.createElement(MealSummary, { flight: flight, travellers: travellers, services: services, travellersById: cartConfig.travellersById, onServiceDelete: onServiceDelete }, content => {
        var _a;
        return (_a = renderService === null || renderService === void 0 ? void 0 : renderService(CheckoutSteps.Meal, group, content)) !== null && _a !== void 0 ? _a : (React.createElement(ServiceSummary, { group: group }, content));
    }));
};
export default Meals;
