import { useTranslation } from 'react-i18next';
const PNR_OR_TICKET_NUMBER_REGEX = /(^[0-9БВГДКЛМНПРСТФХЦЖШA-Z]{6,6}$)|(^[0-9A-Z]{3}[0-9]{10}$)/;
const TICKET_NUMBER_REGEX = /(^[0-9A-Z]{3}[0-9]{10}$)/;
const ORDER_NUMBER = /^\d{1,13}$/;
const PASSENGER_LAST_NAME_LATIN_REGEX = /^[- a-zA-Z]{1,41}$/;
const PASSENGER_LAST_NAME_RU_REGEX = /^[- а-яА-ЯЁё]{1,41}$/;
export const isPnrOrTicketNumber = (value) => PNR_OR_TICKET_NUMBER_REGEX.test(value);
export const isTicketNumber = (value) => TICKET_NUMBER_REGEX.test(value);
export const isOrderNumber = (value) => ORDER_NUMBER.test(value);
export const isCorrectLatingLastname = (value) => PASSENGER_LAST_NAME_LATIN_REGEX.test(value);
export const isCorrectCyrillicLastName = (value) => PASSENGER_LAST_NAME_RU_REGEX.test(value);
export const isSingleWord = (value) => value.split(' ').length === 1;
export const useRetrieveBookingValidation = (validationType) => {
    const { t } = useTranslation('RetrieveBooking');
    const validateByOrderNumber = (value) => {
        if (!isOrderNumber(value)) {
            return t('Enter your book code or ticket number');
        }
    };
    const ticketNumberValidation = (value) => {
        if (validationType === 'ticketNumber') {
            if (!isTicketNumber(value)) {
                return t('Enter your ticket number');
            }
        }
        else {
            if (!isPnrOrTicketNumber(value)) {
                return t('Enter your book code or ticket number');
            }
        }
    };
    const lastNameValidation = (value) => {
        const splittedValue = (value === null || value === void 0 ? void 0 : value.trim().split(' ')) || [];
        if (!splittedValue.every(isCorrectCyrillicLastName) && !splittedValue.every(isCorrectLatingLastname)) {
            return t('Incorrect value');
        }
        else {
            return undefined;
        }
    };
    const ticketNumberFormat = (value) => {
        return value === null || value === void 0 ? void 0 : value.split(' ').join('').slice(0, 13).toUpperCase();
    };
    const requiredValidation = (value) => {
        if (!(value === null || value === void 0 ? void 0 : value.length)) {
            return t('Required field');
        }
    };
    const requiredValidationWrapper = (validateFn) => (value) => {
        return requiredValidation(value) || validateFn(value);
    };
    return {
        ticketNumberValidation,
        lastNameValidation,
        validateByOrderNumber,
        ticketNumberFormat,
        requiredValidation,
        requiredValidationWrapper
    };
};
