import { useCallback } from 'react';
export const usePassengerNames = () => {
    const getPassengerName = useCallback((passenger) => {
        var _a;
        let childrenName = '';
        if ((_a = passenger.children) === null || _a === void 0 ? void 0 : _a.length) {
            childrenName += `, ${passenger.children.map(children => children.name).join(', ')}`;
        }
        return passenger.name + childrenName;
    }, []);
    return { getPassengerName };
};
