import { all, put, take, takeLatest } from 'redux-saga/effects';
import { FILL_CHECKIN_ORDER, setCheckinOrder } from './order/actions';
import { setSelectedServices } from './selectedServices/actions';
import { saveServicesSaga } from './selectedServices/sagas';
import getOrderDataLayer from '../../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../../DataLayerAnalytics/OrderDataLayer/types';
import { createExternalSagaActionChannel } from '../../../utils';
export const CHECKIN_EXTERNAL_ACTION = 'CHECKIN_EXTERNAL_ACTION';
export function* fillOrderSagaWorker(action) {
    getOrderDataLayer(OrderType.Checkin).setOrder(action.payload);
    yield put(setCheckinOrder(action.payload));
    yield put(setSelectedServices(action.payload.travellers));
}
export function* fillOrderSaga() {
    yield takeLatest(FILL_CHECKIN_ORDER, fillOrderSagaWorker);
}
function* externalSaga() {
    const channel = yield createExternalSagaActionChannel(CHECKIN_EXTERNAL_ACTION);
    while (true) {
        const action = yield take(channel);
        yield put(action);
    }
}
export function* watchExternalActionsSaga() {
    yield externalSaga();
}
export function* rootSaga() {
    yield all([fillOrderSaga(), saveServicesSaga(), watchExternalActionsSaga()]);
}
