import * as React from 'react';
import { Skeleton } from '@mui/material';
import cn from 'classnames';
import { useTheme } from '../../../theme';
const CardExtraContentSkeleton = ({ className }) => {
    const { CardExtraContent: theme } = useTheme('CardExtra');
    return (React.createElement("div", { className: cn(theme.root, className) },
        React.createElement(Skeleton, { variant: "rectangular", height: 270 })));
};
export default CardExtraContentSkeleton;
