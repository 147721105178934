import React, { useMemo } from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { LoginInputType } from '../../../types';
import Input from '../../../../Input';
import { detectInputType } from '../../../utils';
import { parsePhone } from '../../../../PhoneInput/utils';
const LoginInput = ({ input, meta }) => {
    const notValid = !!meta.error && meta.touched;
    const { BaseComponent: baseCss } = useTheme('LoginPage');
    const { t } = useTranslation('LoginPage');
    const value = useMemo(() => {
        let value = input.value;
        const type = detectInputType(value);
        if (type === LoginInputType.Phone) {
            const parserValue = parsePhone(value);
            value = parserValue ? parserValue : value;
        }
        return value;
    }, [input.value]);
    return (React.createElement(Input, { label: t('Email / Phone / Card number'), autoComplete: "on", TextFieldProps: Object.assign(Object.assign({}, input), { value: value, error: notValid, helperText: notValid ? meta.error : '', InputProps: {
                classes: {
                    input: baseCss.input
                }
            } }) }));
};
export default LoginInput;
