import { createAction } from 'redux-actions';
export const SET_SERVICE = 'SET_SERVICE';
export const ADD_SERVICE = 'ADD_SERVICE';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';
export const SET_SELECTED_SERVICES = 'SET_SELECTED_SERVICES';
export const SAVE_SERVICES = 'SAVE_SERVICES';
export const CLEAR_SERVICE = 'CLEAR_SERVICE';
export const EQUALIZE_PASSENGER_BAGGAGE = 'EQUALIZE_PASSENGER_BAGGAGE';
export const REPLACE_SERVICE = 'REPLACE_SERVICE';
export const SET_SERVICES_WITH_SAVE = 'SET_SERVICES_WITH_SAVE';
export const setServices = createAction(SET_SERVICE);
export const addService = createAction(ADD_SERVICE);
export const removeService = createAction(REMOVE_SERVICE);
export const clearService = createAction(CLEAR_SERVICE);
export const setSelectedServices = createAction(SET_SELECTED_SERVICES);
export const saveServices = createAction(SAVE_SERVICES, () => undefined, (_, meta) => meta);
export const setServicesWithSave = createAction(SET_SERVICES_WITH_SAVE);
export const replaceService = createAction(REPLACE_SERVICE);
export const equalizePassengerBaggage = createAction(EQUALIZE_PASSENGER_BAGGAGE);
