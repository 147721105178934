import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { MobileSegmentFlat } from '../../MobileSegmentInfo';
import { format, initI18n } from '../../utils';
import Money from '../../Money';
import { MobileSegmentType } from '../../MobileSegmentInfo/components/MobileSegmentFlat/MobileSegmentFlat';
import PriceInMiles from '../../PriceInMiles/PriceInMiles';
import Modal from '../../Modal';
import DesktopFlightModalContentSelected from '../../DesktopFlight/components/DesktopFlightModalContentSelected/DesktopFlightModalContentSelected';
import { useDesktopFlight } from '../../DesktopFlight/hooks';
import { getLastSearchFlight } from '../../FareGroup/utils';
initI18n('MobileSummaryFlight');
const closeIcon = (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z", fill: "currentColor" })));
const MobileSummaryFlight = ({ flight, selectedFares, isReturnFlight, fareFamily, onChange, price, useFlatMobileSegment = false, canceled, priceInMiles, orderId }) => {
    var _a;
    const { t } = useTranslation('MobileSummaryFlight');
    const { MobileSummaryFlight: css } = useTheme('MobileSummaryFlight');
    const [isOpen, setIsOpen] = React.useState(false);
    const { lastSelectedFare: lastSelectedFareId } = useDesktopFlight({ flight, selectedFares });
    const lastSelectedFare = useMemo(() => getLastSearchFlight((flight === null || flight === void 0 ? void 0 : flight.pricesForFareGroups) || [], lastSelectedFareId), [
        flight,
        lastSelectedFareId
    ]);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    return (React.createElement("div", { className: cn(css.wrapper, { [css.wrapper_flat]: useFlatMobileSegment }) },
        !useFlatMobileSegment && (React.createElement("div", { className: css.header },
            t(isReturnFlight ? 'Returning' : 'Departing'),
            ' ',
            React.createElement("span", { className: css.leg__date },
                "\u2022 ",
                format(flight.segments[0].segment.departure.date, 'd LLL, eeee')))),
        React.createElement("div", { className: cn(css.body, { [css.body_flat]: useFlatMobileSegment, [css.body_canceled]: canceled }), onClick: openModal },
            React.createElement(MobileSegmentFlat, { segments: flight.segments, type: MobileSegmentType.Selected })),
        React.createElement("div", { className: cn(css.footer, { [css.footer_flat]: useFlatMobileSegment }) },
            !useFlatMobileSegment && (React.createElement(React.Fragment, null,
                React.createElement("span", null, fareFamily.title),
                React.createElement("span", { className: css.footer__price },
                    React.createElement(Money, { money: price }),
                    React.createElement("span", { className: css.closer, onClick: onChange }, closeIcon)))),
            useFlatMobileSegment && (React.createElement(React.Fragment, null,
                React.createElement("span", null, fareFamily.title),
                canceled ? (React.createElement("span", { className: css.canceled }, t('Canceled'))) : (React.createElement("span", { className: cn({ [css.footer__price_flat]: useFlatMobileSegment }) },
                    priceInMiles > 0 ? (React.createElement(PriceInMiles, { amount: priceInMiles, moneyClassName: css.price__miles, currencyClassName: css.currency })) : (React.createElement(Money, { money: price })),
                    priceInMiles > 0 && (React.createElement("div", { className: css.miles__taxes },
                        t('taxes and fees'),
                        ": ",
                        React.createElement(Money, { money: price })))))))),
        React.createElement(Modal, { classes: {
                paper: css.paper
            }, open: isOpen, slideUp: true, onClose: closeModal, closeButtonRenderer: () => null },
            React.createElement(DesktopFlightModalContentSelected, { flightId: (_a = flight.pricesForFareGroups.find(price => (price === null || price === void 0 ? void 0 : price.fareFamily.id) === (lastSelectedFare === null || lastSelectedFare === void 0 ? void 0 : lastSelectedFare.id))) === null || _a === void 0 ? void 0 : _a.prices[0].flight.id, flight: flight, lastSelectedFare: lastSelectedFare, onClose: closeModal, orderId: orderId }))));
};
export default MobileSummaryFlight;
