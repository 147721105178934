import * as React from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { MealRecommendation as MealRecommendationContent } from '../../index';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import Modal from '../../../Modal';
import { fillOrder } from '../../../Checkout/store/order/actions';
import { MotivationGoal, reachGoal } from '../../../analytics';
const Component = ({ recommendedMeals, onConfirm, onDisagree, segments, passengers }) => {
    const [isOpen, setIsOpen] = useState(true);
    const close = useCallback(() => setIsOpen(false), []);
    const passengerIds = new Set();
    const segmentIds = new Set();
    // Resulting recommended meal object.
    let meal;
    recommendedMeals.forEach(recommendedMeal => {
        // Find out on which segments this meal is available.
        recommendedMeal.segmentIds.forEach(segmentId => segmentIds.add(segmentId));
        // Find out which passengers can acquire this meal.
        passengerIds.add(recommendedMeal.travellerId);
        if (!meal) {
            meal = Object.assign(Object.assign({}, recommendedMeal.meal), { price: {
                    amount: 0,
                    currency: recommendedMeal.meal.price.currency
                } });
        }
        // Calculate total price of the preselected meal services.
        meal.price.amount +=
            recommendedMeal.meal.price.amount * recommendedMeal.count * recommendedMeal.segmentIds.length;
    });
    const handleOnDisagree = useCallback(() => {
        close();
        onDisagree();
        reachGoal(MotivationGoal.RefuseProposedMeal);
    }, []);
    const handleOnConfirm = useCallback(() => {
        close();
        onConfirm(recommendedMeals);
        reachGoal(MotivationGoal.ConfirmProposedMeal);
    }, [recommendedMeals, fillOrder]);
    // Warn user if not all passengers are able to acquire this particular meal.
    let availableForPassengers = '';
    if (passengerIds.size !== passengers.length) {
        availableForPassengers = [...passengerIds]
            .map(passengerId => passengers.find(({ id }) => id === passengerId).name)
            .join(', ');
    }
    // Warn user if this meal is available only on some particular segments.
    let availableForSegments = '';
    if (segmentIds.size !== segments.length) {
        availableForSegments = [...segmentIds]
            .sort()
            .map(segmentId => {
            const segment = segments[segmentId];
            return `${segment.departure.airport.name} — ${segment.arrival.airport.name}`;
        })
            .join(', ');
    }
    return meal ? (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: "mobile" },
            React.createElement(Modal, { maxWidth: "sm", open: isOpen, onClose: handleOnDisagree },
                React.createElement(MealRecommendationContent, { availableForPassengers: availableForPassengers, availableForSegments: availableForSegments, meal: meal, onChange: handleOnDisagree, onConfirm: handleOnConfirm }))),
        React.createElement(MediaQuery, { maxWidth: "mobile" },
            React.createElement(Modal, { fullScreen: true, open: isOpen, onClose: handleOnDisagree },
                React.createElement(MealRecommendationContent, { availableForPassengers: availableForPassengers, availableForSegments: availableForSegments, isMobileFullScreen: true, meal: meal, onChange: handleOnDisagree, onConfirm: handleOnConfirm }))))) : null;
};
export default Component;
