import { parse, isAfter, isBefore } from 'date-fns';
import { TravellerFieldEnum } from '@websky/graphql';
import { getUserValue } from '../../../utils';
export const filterProfileByBirthDate = (profile, validations) => {
    if (validations === null || validations === void 0 ? void 0 : validations.length) {
        const profileBirthDate = getUserValue(profile, TravellerFieldEnum.BirthDate);
        const validationsWithDates = validations.filter(validation => validation.minDate && validation.maxDate);
        if (profileBirthDate && validationsWithDates.length) {
            const parseProfileBirthDate = parse(profileBirthDate, 'dd.MM.yyyy', new Date());
            return validationsWithDates.some(validation => {
                const minDate = new Date(validation.minDate);
                const maxDate = new Date(validation.maxDate);
                return !isAfter(parseProfileBirthDate, maxDate) && !isBefore(parseProfileBirthDate, minDate);
            });
        }
    }
    return true;
};
