import { SET_LOCATION, SWITCH_LOCATION } from './actions';
const initialState = {
    departure: null,
    arrival: null
};
export const segmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOCATION:
            return Object.assign(Object.assign({}, state), { [action.payload.locationType]: action.payload.location });
        case SWITCH_LOCATION:
            return {
                departure: state.arrival,
                arrival: state.departure
            };
        default:
            return state;
    }
};
