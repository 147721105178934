const TagManager = require('react-gtm-module');
export const initTagManager = () => {
    var _a, _b;
    if (window && !!window['__webskyEngineConfig']) {
        const config = window['__webskyEngineConfig'];
        if (!window['tagManagerCreated'] && !!((_a = config.global) === null || _a === void 0 ? void 0 : _a.gtmId)) {
            try {
                TagManager.initialize({
                    gtmId: config.global.gtmId,
                    dataLayer: {
                        userProject: (_b = config.global.companyInfo) === null || _b === void 0 ? void 0 : _b.iataCode
                    }
                });
                window['tagManagerCreated'] = true;
            }
            catch (e) {
                console.error(e);
            }
        }
    }
};
