import * as React from 'react';
import { useFfpPassbookLazyQuery } from '@websky/graphql';
import { useTheme } from '../../../../theme';
import { downloadPkpass, getAppleWalletIconUrl } from '../../../../utils';
import SimpleLoader from '../../../../SimpleLoader';
const AddToAppleWallet = () => {
    const { FfpInfo: css } = useTheme('LoyaltyInfo');
    const [ffpPassbook, { data, loading }] = useFfpPassbookLazyQuery({ fetchPolicy: 'no-cache' });
    const appleWalletIconUrl = getAppleWalletIconUrl();
    const addToAppleWallet = () => {
        ffpPassbook();
    };
    React.useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.FfpPassbook) {
            downloadPkpass(data === null || data === void 0 ? void 0 : data.FfpPassbook, `loyalty_card.pkpass`);
        }
    }, [data]);
    return (React.createElement(React.Fragment, null,
        loading && React.createElement(SimpleLoader, null),
        React.createElement("img", { className: css.addToAppleWallet, src: appleWalletIconUrl, alt: "Add to Apple Wallet", onClick: addToAppleWallet })));
};
export default AddToAppleWallet;
