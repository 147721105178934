import { __awaiter } from "tslib";
import { useEffect } from 'react';
import { LoginType, OauthServiceType, useAuthenticateMutation } from '@websky/graphql';
import { useLocation } from 'react-router-dom';
import { prevSocialAuthKeyFactory, tinkoffCallbackUrlSuffix } from './utils';
import { sessionStorageGet } from '../../cache';
import { captureErrorSentry } from '../../utils';
export const useTinkoffCallback = (onSuccessFullAuth) => {
    const location = useLocation();
    const [authenticate, { loading }] = useAuthenticateMutation();
    const auth = (state, code) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield authenticate({
                variables: {
                    params: {
                        loginType: LoginType.TinkoffID,
                        secureCode: JSON.stringify({ state, code })
                    }
                }
            });
        }
        finally {
            const prevKey = prevSocialAuthKeyFactory(OauthServiceType.TinkoffID);
            const prevUrl = sessionStorageGet(prevKey);
            if (prevUrl) {
                window.location.href = prevUrl;
            }
            else {
                captureErrorSentry('Social auth callback url has not been found in the session storage');
            }
        }
    });
    useEffect(() => {
        if (location.pathname.includes(tinkoffCallbackUrlSuffix)) {
            const searchParams = new URL(window.location.href).searchParams;
            const code = searchParams.get('code');
            const state = searchParams.get('state');
            if (code && state) {
                auth(state, code).then(onSuccessFullAuth);
            }
        }
    }, []);
    return {
        isLoading: loading
    };
};
