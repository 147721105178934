import * as React from 'react';
import { useTheme } from '../../theme';
import InsuranceCard from '../InsuranceCard/InsuranceCard';
import SpecialDocument from './SpecialDocument/SpecialDocument';
const InsuranceInner = props => {
    const theme = useTheme('Insurance').InsuranceStyles;
    const documentsIsRequired = React.useMemo(() => {
        return props.passengers.some(passenger => passenger.isRequired);
    }, [props.passengers]);
    return (React.createElement("div", { className: theme.wrapper },
        React.createElement("div", { className: theme.insurances }, props.insurances.map(insurance => (React.createElement(InsuranceCard, { insurance: insurance, selected: props.selectedInsurances.some(item => item.code === insurance.code), dispatcher: props.dispatcher })))),
        props.documentNeeds && documentsIsRequired && props.selectedInsurances.length > 0 && (React.createElement("div", { className: theme.documents },
            React.createElement(SpecialDocument, { passengers: props.passengers, documentError: props.documentError, dispatcher: props.dispatcher })))));
};
export default InsuranceInner;
