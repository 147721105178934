import { useState } from 'react';
import { useToggleable } from '../hooks';
export const useTransitTooltip = () => {
    const { open, isOpen, close } = useToggleable(false);
    const [timerId, setTimerId] = useState(null);
    const openTransitTooltipHandler = () => {
        clearTimeout(timerId);
        open();
    };
    const closeTransitTooltipHandler = () => {
        const timerId = setTimeout(() => {
            close();
        }, 150);
        setTimerId(timerId);
    };
    return {
        isOpen,
        open: openTransitTooltipHandler,
        close: closeTransitTooltipHandler
    };
};
