import { __awaiter } from "tslib";
import { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { PaymentStatusEnum, useOrderServicesWithPurchasePredictionQuery } from '@websky/graphql';
import { getIsPriceFreezeProductSelected, getOrderId, getPaymentStatus } from '../Checkout/store/order/selectors';
import { useConfig } from '../context';
import { getServiceWithMaxPrediction as getServiceWithMaxPredictionUtil } from './utils';
import { VarioqubContext } from '../Varioqub/varioqub';
import { StepsEnum } from '../types';
export const useAiPurchasePredictionService = () => {
    const orderId = useSelector(getOrderId);
    const isPriceFreezeProductSelected = useSelector(getIsPriceFreezeProductSelected);
    const paymentStatus = useSelector(getPaymentStatus);
    const varioqubData = useContext(VarioqubContext);
    const [service, setService] = useState(null);
    const [isAddedService, setIsAddedService] = useState(false);
    const { global: { purchasePredictionThreshold, purchasePredictionThresholdMax } } = useConfig();
    const { refetch: getOrderWithPurchasePrediction } = useOrderServicesWithPurchasePredictionQuery({
        skip: true,
        fetchPolicy: 'no-cache'
    });
    const onClearService = useCallback(() => setService(null), [setService]);
    const onDisagree = useCallback((callback) => __awaiter(void 0, void 0, void 0, function* () {
        onClearService();
        yield (callback === null || callback === void 0 ? void 0 : callback());
    }), [onClearService]);
    const onChange = useCallback((payload, callback) => {
        onClearService();
        callback === null || callback === void 0 ? void 0 : callback(payload);
    }, [onClearService]);
    const onAgree = useCallback((service, callback) => __awaiter(void 0, void 0, void 0, function* () {
        yield (callback === null || callback === void 0 ? void 0 : callback(service));
        setIsAddedService(true);
        onClearService();
    }), [setIsAddedService, onClearService]);
    const onContinue = useCallback((currentStepType, callback) => __awaiter(void 0, void 0, void 0, function* () {
        const blockSmartBoxPopup = isPriceFreezeProductSelected && paymentStatus === PaymentStatusEnum.None;
        if ((varioqubData === null || varioqubData === void 0 ? void 0 : varioqubData.get('SmartBox')) && !blockSmartBoxPopup && currentStepType === StepsEnum.Order) {
            const { data } = yield getOrderWithPurchasePrediction({ id: orderId });
            if (data === null || data === void 0 ? void 0 : data.Order) {
                const serviceWithMaxPrediction = getServiceWithMaxPredictionUtil(data.Order.additionalServices);
                const probability = serviceWithMaxPrediction === null || serviceWithMaxPrediction === void 0 ? void 0 : serviceWithMaxPrediction.aiPurchasePrediction[0].probability;
                if (probability >= purchasePredictionThreshold &&
                    (!purchasePredictionThresholdMax || probability <= purchasePredictionThresholdMax)) {
                    setService(serviceWithMaxPrediction);
                    return;
                }
            }
        }
        yield (callback === null || callback === void 0 ? void 0 : callback());
    }), [
        orderId,
        paymentStatus,
        isPriceFreezeProductSelected,
        purchasePredictionThreshold,
        getOrderWithPurchasePrediction,
        varioqubData
    ]);
    return {
        // data
        service,
        isOpen: !!service,
        isAddedService,
        // methods
        onClearService,
        onDisagree,
        onChange,
        onAgree,
        onContinue
    };
};
