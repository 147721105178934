import * as React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import FareConditions from './FareConditionsWrapper';
import { useGraphQLClient } from '../../graphql-client';
import { Locale } from '../../types';
import { getLocale } from '../../utils';
const LOCALE_TO_LANG_MAP = new Map([
    [Locale.English, 'en'],
    [Locale.Russian, 'ru'],
    [Locale.Kazakh, 'ru'],
    [Locale.Belarusian, 'ru']
]);
const Component = props => {
    const client = useGraphQLClient();
    const engineLang = getLocale();
    const [currentLang, changeLangTo] = React.useState(() => {
        if (LOCALE_TO_LANG_MAP.has(engineLang)) {
            return LOCALE_TO_LANG_MAP.get(engineLang);
        }
        else {
            return Locale.English;
        }
    });
    const handleChange = lang => {
        if (LOCALE_TO_LANG_MAP.has(lang)) {
            changeLangTo(lang);
        }
        else {
            changeLangTo(Locale.English);
        }
    };
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(FareConditions, Object.assign({ onLangChange: handleChange, language: currentLang }, props))));
};
export default Component;
