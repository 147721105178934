import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { useIsMobile } from '../../utils';
import { useToggleable } from '../../hooks';
import SimpleLoader from '../../SimpleLoader';
import Modal from '../../Modal';
import Button from '../../Button';
const SelectSegments = ({ className, segments, title, onClick, renderTrigger, renderButton, renderSegment }) => {
    var _a;
    const { SelectSegments: css } = useTheme('SelectSegments');
    const { t } = useTranslation('SelectSegments');
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, open, close } = useToggleable(false);
    const isMobile = useIsMobile();
    const content = React.useMemo(() => {
        return (React.createElement("div", { className: css.content },
            React.createElement("h3", { className: css.title }, title),
            renderSegment
                ? segments.map(renderSegment)
                : segments.map(segment => (React.createElement("div", { key: segment.id, className: css.segment },
                    React.createElement("div", { className: css.routes },
                        React.createElement("span", { className: css.iata },
                            segment.departure.airport.iata,
                            " - ",
                            segment.arrival.airport.iata),
                        React.createElement("span", { className: css.cities },
                            segment.departure.airport.name,
                            " - ",
                            segment.arrival.airport.name)),
                    React.createElement("div", { className: css.controls }, renderButton(segment, close)))))));
    }, [segments, title, renderSegment, renderButton]);
    const onClickHandler = useCallback((...args) => __awaiter(void 0, void 0, void 0, function* () {
        if (segments.length > 1) {
            open();
        }
        else {
            setIsLoading(true);
            yield onClick(...args);
            setIsLoading(false);
        }
    }), [segments, onClick, open, isLoading]);
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(SimpleLoader, null), (_a = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger({ onClick: onClickHandler, isLoading })) !== null && _a !== void 0 ? _a : (React.createElement(Button, { className: className, onClick: onClickHandler, isLoading: isLoading }, t('Download'))),
        React.createElement(Modal, { classes: {
                scrollBody: css.scrollBody,
                paper: css.paper
            }, open: isOpen, onClose: close, maxWidth: "md", slideUp: isMobile }, content)));
};
export default SelectSegments;
