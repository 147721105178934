export const isAllPassengersSelector = (passenger, travellersCount) => {
    var _a;
    return ((_a = passenger.children) === null || _a === void 0 ? void 0 : _a.length) === travellersCount - 1;
};
export const getSelectorsInfo = (passengers, travellersCount) => {
    let isAllPassengersSelectorChecked = false;
    let hasNotAllPassengersSelectorChecked = false;
    passengers.forEach(passenger => {
        if (isAllPassengersSelector(passenger, travellersCount)) {
            if (passenger.selected) {
                isAllPassengersSelectorChecked = true;
            }
        }
        else {
            if (passenger.selected) {
                hasNotAllPassengersSelectorChecked = true;
            }
        }
    });
    return {
        isAllPassengersSelectorChecked,
        hasNotAllPassengersSelectorChecked
    };
};
