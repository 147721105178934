import { applyMiddleware } from 'redux';
import { createStore as originalCreateStore } from 'redux';
import { rootReducer } from './reducers';
import memes from '../../../memes';
export const createStore = memes(() => {
    const middlewares = [];
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(require('redux-logger').default);
    }
    const store = originalCreateStore(rootReducer, applyMiddleware(...middlewares));
    return store;
});
