import { __awaiter } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useSocialAuthQuery, OauthServiceType } from '@websky/graphql';
import { useTheme } from '../../theme';
import { useConfig } from '../../context';
import { useTinkoffCallback } from './hooks';
import { getBackRedirectUrl, setSocialAuthRedirectUrl } from './utils';
import { icons } from '../../Account/components/SocialAuth/icons';
import SimpleLoader from '../../SimpleLoader';
const SocialAuth = ({ variant = 'common', onClick }) => {
    const { global: { socialAuthMethods } } = useConfig();
    const { SocialAuth: css } = useTheme('SocialAuth');
    const socialAuth = useSocialAuthQuery({ skip: true });
    const redirectToService = (service) => __awaiter(void 0, void 0, void 0, function* () {
        if (onClick) {
            // Useful for analytics goals.
            onClick(service);
        }
        const { data } = yield socialAuth.refetch({
            params: {
                service,
                backRedirectUrl: getBackRedirectUrl(service)
            }
        });
        setSocialAuthRedirectUrl(service);
        if (data.OauthRedirectUrl.message) {
            window.open(data.OauthRedirectUrl.message, '_top');
        }
    });
    const { isLoading: isTinkoffLoading } = useTinkoffCallback();
    return (React.createElement("div", { className: css.wrapper, "data-social-auth": true },
        isTinkoffLoading && React.createElement(SimpleLoader, null),
        socialAuthMethods
            .filter(method => method.isAvailable)
            .map(method => (React.createElement(ButtonBase, { key: method.service, "data-oauth-type": method.service, className: cn(css.button, {
                [css.button_square]: variant === 'square',
                [css.button_light]: method.service === OauthServiceType.Vkontakte ||
                    method.service === OauthServiceType.Facebook ||
                    method.service === OauthServiceType.TinkoffID,
                [css.button_facebook]: method.service === OauthServiceType.Facebook,
                [css.button_google]: method.service === OauthServiceType.Google
            }), onClick: () => redirectToService(OauthServiceType[method.service]) }, icons[method.service])))));
};
export default SocialAuth;
