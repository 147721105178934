export const getSeatsLeftCount = (flight) => {
    var _a;
    return (_a = flight === null || flight === void 0 ? void 0 : flight.segments) === null || _a === void 0 ? void 0 : _a.reduce((minValue, segment) => {
        const seatsLeft = segment.segment.lowestPriceClassSeatsLeft;
        if (!Number.isInteger(seatsLeft)) {
            return minValue;
        }
        if (minValue === null) {
            return seatsLeft;
        }
        else {
            return seatsLeft < minValue ? seatsLeft : minValue;
        }
    }, null);
};
