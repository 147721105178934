import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPassengers } from '../../../store/order/selectors';
import { getSelectedPassengerIds } from '../../../store/passengers/selectors';
import { addPassengerIds, removePassengerIds, setPassengerIds } from '../../../store/passengers/actions';
import CommonSelect from '../CommonSelect/CommonSelect';
import { passengerIcon } from '../icons';
import { getPassengerName } from '../utils';
import { ExchangeStep } from '../../../types';
const Passengers = ({ setStep, skipPassengersStep }) => {
    const { t } = useTranslation('Exchange');
    const dispatch = useDispatch();
    const passengers = useSelector(getPassengers);
    const selectedPassengerIds = useSelector(getSelectedPassengerIds);
    useEffect(() => {
        if (passengers.length === 1) {
            dispatch(setPassengerIds([passengers[0].id]));
            setStep(ExchangeStep.Reasons);
        }
        else if (skipPassengersStep && passengers.length) {
            dispatch(addPassengerIds(passengers.map(passenger => passenger.id)));
            setStep(ExchangeStep.Segments);
        }
    }, [skipPassengersStep, passengers]);
    const selectedPassengerIdsExchange = React.useMemo(() => {
        return selectedPassengerIds.map(id => ({ key: id }));
    }, [selectedPassengerIds]);
    const passengersAsItems = React.useMemo(() => {
        const passengersWithoutLink = passengers
            .filter(passenger => !passenger.linkedTraveller)
            .map(passenger => ({
            value: getPassengerName(passenger),
            key: passenger.id,
            ids: [passenger.id]
        }));
        passengers
            .filter(passengers => passengers.linkedTraveller)
            .map(passenger => {
            const parent = passengersWithoutLink.find(parent => parent.key === passenger.linkedTraveller.id);
            parent.value += `, ${getPassengerName(passenger)}`;
            parent.ids.push(passenger.id);
        });
        return passengersWithoutLink;
    }, [passengers]);
    const onChangeHandler = (value, selected) => {
        const passenger = passengersAsItems.find(passenger => passenger.key === value.key);
        if (selected) {
            dispatch(addPassengerIds(passenger.ids));
        }
        else {
            dispatch(removePassengerIds(passenger.ids));
        }
    };
    const onNextHandler = () => setStep(ExchangeStep.Reasons);
    return (React.createElement(CommonSelect, { name: t('Select passengers'), items: passengersAsItems, onChange: onChangeHandler, onNextHandler: onNextHandler, selectedKeys: selectedPassengerIdsExchange, icon: passengerIcon }));
};
export default Passengers;
