import i18n from 'i18next';
import { capitalizeFirstLetter } from '../../../../../utils';
import { useSteps } from '../../../utils';
import { CheckinStep } from '../../../types';
import { CheckoutSteps } from '../../../../../MobileStepbar/types';
export const useStepsToStepbarStepsAdapter = (steps) => {
    const { setStep } = useSteps();
    const stebBarSteps = {};
    steps.forEach(step => {
        stebBarSteps[step] = {
            label: step === CheckinStep.Passengers
                ? i18n.t('Checkin:Passengers')
                : i18n.t(`Checkin:${capitalizeFirstLetter(step)}`),
            onClick: () => setStep(step),
            type: step
        };
    });
    stebBarSteps['boardingPass'] = {
        label: i18n.t('Checkin:Boarding pass'),
        type: CheckoutSteps.BoardingPass
    };
    return stebBarSteps;
};
export const useGetGoBack = (currentStep) => {
    const { getPreviousStepEnum, previousStep } = useSteps();
    return getPreviousStepEnum(currentStep) !== CheckinStep.Passengers ? () => previousStep(currentStep) : undefined;
};
