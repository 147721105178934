import * as React from 'react';
import ServiceSummary from '../../ServiceSummary';
import { overridedIcons } from '../../Icons';
import { CartContext } from '../Cart';
import { useTranslation } from 'react-i18next';
import BaggageSummary from '../../ServiceSummary/services/BaggageSummary';
import { CheckoutSteps } from '../../MobileStepbar/types';
const Baggage = ({ flight, travellers, services, onServiceDelete, renderService }) => {
    var _a;
    const cartConfig = React.useContext(CartContext);
    const { t } = useTranslation('Cart');
    const group = {
        header: t('Baggage'),
        icon: overridedIcons['CheckedBaggage'],
        totalPrice: (_a = cartConfig.totalPrices) === null || _a === void 0 ? void 0 : _a.baggage
    };
    return (React.createElement(BaggageSummary, { flight: flight, travellers: travellers, services: services, travellersById: cartConfig.travellersById, onServiceDelete: onServiceDelete }, content => {
        var _a;
        return (_a = renderService === null || renderService === void 0 ? void 0 : renderService(CheckoutSteps.Baggage, group, content)) !== null && _a !== void 0 ? _a : (React.createElement(ServiceSummary, { group: group }, content));
    }));
};
export default Baggage;
