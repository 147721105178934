import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
import * as icons from '../../Icons';
const MobileHeader = ({ onBack, classes, children }) => {
    const { MobileHeader: css } = useTheme('MobileHeader');
    return (React.createElement("div", { className: cn(css.header, classes === null || classes === void 0 ? void 0 : classes.header) },
        onBack && (React.createElement("span", { className: cn(css.backBatton, classes === null || classes === void 0 ? void 0 : classes.backButton), onClick: onBack }, icons.ArrowBack)),
        typeof children === 'function' ? children() : children));
};
export default MobileHeader;
