import * as React from 'react';
import { Field, Form } from 'react-final-form';
import Input from '../Input';
import PhoneInput from '../PhoneInput';
import { BaseButton } from '../BaseComponents';
import { initI18n } from '../utils';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { useCustomerContactsValidation } from './hooks';
initI18n('CustomerContactsForm');
const CustomerContactsForm = props => {
    var _a, _b;
    const { t } = useTranslation('CustomerContactsForm');
    const { CustomerContactsForm: theme } = useTheme('CustomerContactsForm');
    const { testEmail, testPhone } = useCustomerContactsValidation();
    const initialValues = {
        email: ((_a = props.initialValues) === null || _a === void 0 ? void 0 : _a.email) || '',
        phone: ((_b = props.initialValues) === null || _b === void 0 ? void 0 : _b.phone) || ''
    };
    return (React.createElement(Form, { initialValues: initialValues, onSubmit: props.onContactsSubmit }, ({ handleSubmit, values, hasValidationErrors }) => (React.createElement("form", { onSubmit: handleSubmit, className: theme.form },
        React.createElement(Field, { name: "email", type: "email", validate: testEmail }, field => (React.createElement(Input, Object.assign({ inputClassName: theme.email__input, className: theme.email }, field, { label: t('Email'), absoluteHintPosition: true, TextFieldProps: Object.assign(Object.assign({}, field.input), { onChange: e => field.input.onChange(e), error: field.meta.touched && field.meta.error, helperText: field.meta.touched && field.meta.error }) })))),
        React.createElement(Field, { name: "phone", validate: testPhone }, field => (React.createElement(React.Fragment, null,
            React.createElement(PhoneInput, Object.assign({}, field.input, { className: theme.phone__input, textFieldsProps: Object.assign(Object.assign({}, field.input), { onChange: e => field.input.onChange(e), error: field.meta.touched && field.meta.error, label: t('Phone'), helperText: field.meta.touched && field.meta.error }) }))))),
        React.createElement(BaseButton, { isLoading: props.isLoading, variant: "optionAction", className: theme.button, type: 'submit' }, t('Done'))))));
};
export default CustomerContactsForm;
