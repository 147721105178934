import * as React from 'react';
import { useTheme } from '../theme';
import { infoIcon } from './Icons';
import MediaQuery from '../MediaQuery/MediaQuery';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { initI18n } from '../utils';
initI18n('Warnings');
const Warnings = ({ warnings, children, className, textClassName, iconClassName, iconTextClassName, contentClassName }) => {
    const css = useTheme('Warnings').WarningsStyles;
    const { t } = useTranslation('Warnings');
    return (React.createElement("div", { className: cn(css.wrapper, className) }, children ? (React.createElement("div", { className: cn(css.warning, contentClassName) },
        React.createElement("div", { className: cn(css.warning__icon, iconClassName) },
            infoIcon,
            React.createElement(MediaQuery, { maxWidth: 'mobile' },
                React.createElement("span", { className: cn(css.icon__text, iconTextClassName) }, t('Clarification')))),
        React.createElement("div", { className: cn(css.warning__text, textClassName) }, children))) : (warnings.map((warning, index) => (React.createElement("div", { key: index, className: cn(css.warning, contentClassName) },
        React.createElement("div", { className: cn(css.warning__icon, iconClassName) },
            infoIcon,
            React.createElement(MediaQuery, { maxWidth: 'mobile' },
                React.createElement("span", { className: cn(css.icon__text, iconTextClassName) }, t('Clarification')))),
        React.createElement("div", { className: cn(css.warning__text, textClassName) }, warning)))))));
};
export default Warnings;
