import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { BaseButton } from '../BaseComponents';
import { initI18n, useIsMobile } from '../utils';
import AddPassengerForm from './components/AddPassengerForm/AddPassengerForm';
import { useToggleable } from '../hooks';
import { Add, InfoDetails } from '../Icons';
import ModalX from '../BaseComponents/ModalX';
import ConditionsModal from '../Modules/Checkin/components/Checkin/ConditionsModal/ConditionsModal';
initI18n('AddPassenger');
const AddPassenger = ({ onSubmit }) => {
    const { t } = useTranslation('AddPassenger');
    const css = useTheme('AddPassenger').AddPassenger;
    const { isOpen, open, close } = useToggleable(false);
    const isMobile = useIsMobile();
    return (React.createElement("div", { className: css.wrapper },
        (!isOpen || isMobile) && (React.createElement(BaseButton, { variant: "smallNeitral", className: css.addButton, startIcon: !isMobile ? React.createElement("div", { className: css.addButton__icon }, Add) : null, onClick: open }, t('Add passenger'))),
        isOpen && !isMobile && (React.createElement("div", { className: css.form__wrapper },
            React.createElement(AddPassengerForm, { onSubmit: onSubmit, onSuccess: close }))),
        React.createElement(ModalX, { keepMounted: false, title: React.createElement("div", { className: css.form__title },
                t('Add passenger'),
                React.createElement(ConditionsModal, { renderTrigger: ({ onClick }) => (React.createElement(BaseButton, { variant: "text", className: css.conditionButton, onClick: onClick, startIcon: InfoDetails }, t('CheckinConditions:Check-in conditions'))) })), isOpen: isOpen && isMobile, onClose: close },
            React.createElement("div", { className: css.form__wrapper },
                React.createElement(AddPassengerForm, { onSubmit: onSubmit, onSuccess: close, inputVariant: "underline" })))));
};
export default AddPassenger;
