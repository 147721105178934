import { __awaiter } from "tslib";
import { useGetUserProfileQuery } from '@websky/graphql';
import { currentUserFactory } from './utils';
import { useConfig } from '../context';
const EVENT_NAME = 'webskyCurrentUser';
export const useCurrentUser = (skip) => {
    var _a, _b;
    const currentUserInGlobalScope = (_b = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.currentUserInGlobalScope;
    const onCompleted = (data) => {
        if (!(data === null || data === void 0 ? void 0 : data.CurrentUser)) {
            return;
        }
        const userData = currentUserFactory(data.CurrentUser);
        const event = new CustomEvent(EVENT_NAME, {
            detail: userData
        });
        window[EVENT_NAME] = userData;
        document.dispatchEvent(event);
    };
    const { data, refetch, loading } = useGetUserProfileQuery({
        fetchPolicy: 'no-cache',
        skip: skip !== null && skip !== void 0 ? skip : !currentUserInGlobalScope,
        onCompleted
    });
    const reinitialize = () => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield refetch();
        onCompleted(data === null || data === void 0 ? void 0 : data.data);
    });
    return {
        data,
        loading,
        reinitialize
    };
};
