import * as React from 'react';
import { useSelector } from 'react-redux';
import Money from '../../Money';
import { useTheme } from '../../theme';
import { BaseButton } from '../../BaseComponents';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../utils';
import InsuranceOptions from './InsuranceOptions/InsuranceOptions';
import { useTranslation } from 'react-i18next';
import { getCheckinOrder } from '../../Modules/Checkin/store/order/selectors';
import { ModeContext } from '../../context';
import { Mode } from '../../types';
import { getOrder } from '../../Checkout/store/order/selectors';
import { analytics_addInsurance, analytics_removeInsurance } from '../../Insurance/utils';
const InsuranceCard = props => {
    const theme = useTheme('Insurance').InsuranceCardStyles;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const mode = React.useContext(ModeContext);
    const order = mode === Mode.Booking ? useSelector(getOrder) : useSelector(getCheckinOrder);
    const onClick = () => {
        if (props.selected) {
            analytics_removeInsurance(props.insurance, order.travellers);
        }
        else {
            analytics_addInsurance(props.insurance, order.travellers);
        }
        props.dispatcher({
            type: props.selected ? 'remove' : 'add',
            payload: props.insurance
        });
    };
    const { t } = useTranslation('AdditionalServiceCard');
    return (React.createElement("div", { className: cn(theme.insurance, { [theme.selected]: props.selected }) },
        React.createElement("div", { className: theme.insurance__header },
            props.insurance.name,
            !isMobile && React.createElement(Money, { money: props.insurance.totalPrice, moneyClassName: theme.insurance__money })),
        React.createElement("div", { className: theme.insurance__content },
            React.createElement(InsuranceOptions, { insurance: props.insurance }),
            React.createElement(BaseButton, { variant: "action", className: cn(theme.insurance__button, {
                    [theme.insurance__button_selected]: props.selected
                }), onClick: onClick },
                t(props.selected ? 'Selected' : 'Select'),
                isMobile && React.createElement(Money, { money: props.insurance.totalPrice, moneyClassName: theme.insurance__money })))));
};
export default InsuranceCard;
