import { isAfter, isEqual, parseISO } from 'date-fns';
import { Sorting } from '../../../enums';
import { getDurationInSeconds, getFlightDuration } from '../../../utils';
import { FlightClass } from '@websky/graphql';
export const getFlightMinPrice = (flight) => {
    let result = null;
    flight.pricesForFareGroups.forEach(group => {
        if (group.prices && group.prices.length) {
            if (group.prices[0].price && (result === null || result > group.prices[0].price.amount)) {
                result = group.prices[0].price.amount;
            }
        }
    });
    return result;
};
export const getIataCompare = (flight, iata) => {
    var _a, _b;
    return ((_b = (_a = flight.segments[0].segment) === null || _a === void 0 ? void 0 : _a.operatingAirline) === null || _b === void 0 ? void 0 : _b.iata) === iata ? -1 : 1;
};
export const sortFlights = (flights, sorting, iata, sortResultPreferCurrentAirline) => {
    switch (sorting) {
        case Sorting.BestFlight:
            return flights;
        case Sorting.Price:
            return flights.sort((a, b) => {
                const aPrice = getFlightMinPrice(a);
                const bPrice = getFlightMinPrice(b);
                const priceCompare = aPrice === bPrice ? 0 : aPrice > bPrice ? 1 : -1;
                if (sortResultPreferCurrentAirline) {
                    const aIata = getIataCompare(a, iata);
                    const bIata = getIataCompare(b, iata);
                    if (aIata === bIata) {
                        return priceCompare;
                    }
                    return aIata > bIata ? 1 : -1;
                }
                return priceCompare;
            });
        case Sorting.Departure:
            return flights.sort((a, b) => {
                const aDate = parseISO(`${a.segments[0].segment.departure.date}T${a.segments[0].segment.departure.time}`);
                const bDate = parseISO(`${b.segments[0].segment.departure.date}T${b.segments[0].segment.departure.time}`);
                if (isEqual(aDate, bDate)) {
                    return 0;
                }
                return isAfter(aDate, bDate) ? 1 : -1;
            });
        case Sorting.Arrival:
            return flights.sort((a, b) => {
                const aDate = parseISO(`${a.segments[a.segments.length - 1].segment.arrival.date}T${a.segments[a.segments.length - 1].segment.arrival.time}`);
                const bDate = parseISO(`${b.segments[b.segments.length - 1].segment.arrival.date}T${b.segments[b.segments.length - 1].segment.arrival.time}`);
                if (isEqual(aDate, bDate)) {
                    return 0;
                }
                return isAfter(aDate, bDate) ? 1 : -1;
            });
        case Sorting.FlightTime:
            return flights.sort((a, b) => {
                const aPrice = getDurationInSeconds(getFlightDuration(a.segments));
                const bPrice = getDurationInSeconds(getFlightDuration(b.segments));
                if (aPrice === bPrice) {
                    return 0;
                }
                return aPrice > bPrice ? 1 : -1;
            });
        default:
            return flights;
    }
};
// Check that all rows has available flights, according to previously selected flight id.
export const filterFlightsBySelectedFares = (flights, selectedFares) => flights.filter(flight => flight.pricesForFareGroups.some(({ prices }) => {
    if (!prices) {
        return false;
    }
    if (selectedFares.length) {
        const lastSelectedFare = selectedFares[selectedFares.length - 1];
        const selectedMap = {};
        lastSelectedFare.prices.forEach(price => (selectedMap[price.flight.id] = price.flight.id));
        return prices.some(price => selectedMap.hasOwnProperty(price.flight.id));
    }
    else {
        return true;
    }
}));
export const filterFlightsByType = (flights, directOnly = false, businessClass = false) => {
    let filteredFlights = flights;
    if (directOnly) {
        filteredFlights = filteredFlights.filter(flight => flight.segments.length <= 1);
    }
    if (businessClass) {
        filteredFlights = filteredFlights.filter(flight => flight.pricesForFareGroups.some(fare => fare.fareFamily.category === FlightClass.Business));
    }
    return filteredFlights;
};
