import * as React from 'react';
import cn from 'classnames';
import { Bike, Choosed, PetInHold, Serfing, SmallPet, MediumPet, BigPet, SnowBoard, SportEquipment, overridedIcons } from '../../../../../Icons';
import { useTheme } from '../../../../../theme';
import { useRenderers } from '../../../../../renderProps';
import { useMemo } from 'react';
import { BaggageType } from '@websky/graphql';
const HeaderEquipmentItem = ({ className, rfics, icon, baggageType, isActive, isSelected, onClick }) => {
    const { HeaderEquipmentItem: css } = useTheme('UpgradeBaggageDialog');
    const typesByRfics = {
        '0EF': SnowBoard,
        '0EG': SnowBoard,
        '0EH': SnowBoard,
        '0EC': Bike,
        '0EI': Serfing,
        '0DD': SnowBoard,
        '0BT': overridedIcons.PetInHold,
        '021': SmallPet,
        '0BS': MediumPet,
        '0AY': BigPet
    };
    const { renderHeaderEquipmentIcon } = useRenderers();
    const iconByBaggageType = {
        [BaggageType.PetInCabin]: PetInHold
    };
    const equipmentIcon = useMemo(() => {
        if (icon) {
            return icon;
        }
        else if (renderHeaderEquipmentIcon) {
            return renderHeaderEquipmentIcon(rfics, baggageType);
        }
        else if (baggageType && (iconByBaggageType === null || iconByBaggageType === void 0 ? void 0 : iconByBaggageType[baggageType])) {
            return iconByBaggageType[baggageType];
        }
        else if (rfics && (typesByRfics === null || typesByRfics === void 0 ? void 0 : typesByRfics[rfics])) {
            return typesByRfics[rfics];
        }
        else {
            return SportEquipment;
        }
    }, [icon, rfics, baggageType, typesByRfics, iconByBaggageType, renderHeaderEquipmentIcon]);
    return (React.createElement("div", { className: cn(className, css.wrapper, {
            [css.wrapper_pointer]: !!onClick,
            [css.active]: isActive
        }), onClick: onClick },
        isSelected && React.createElement("div", { className: css.selected }, Choosed),
        equipmentIcon));
};
export default HeaderEquipmentItem;
