import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import { useTheme } from '../theme';
import { amenitiesIcons, leafIcon } from './icons';
const Amenities = React.memo(({ amenities, isEco, filterAvailable = true, className }) => {
    const { Amenities: css } = useTheme('Amenities');
    const { t } = useTranslation();
    if (!(amenities === null || amenities === void 0 ? void 0 : amenities.length)) {
        return null;
    }
    let filterAmenities = amenities.filter(amenity => amenitiesIcons === null || amenitiesIcons === void 0 ? void 0 : amenitiesIcons[amenity.type]);
    if (filterAvailable) {
        filterAmenities = filterAmenities.filter(amenity => amenity.available);
    }
    if (!filterAmenities.length) {
        return null;
    }
    filterAmenities = [...filterAmenities].sort((a, b) => a.available === b.available ? 0 : a.available && b.available ? -1 : 1);
    return (React.createElement("div", { className: cn(css.amenities, className) },
        filterAmenities.map(amenity => (React.createElement("span", { key: amenity.type, className: cn(css.amenities__item, {
                [css.amenities__item_unavailable]: !amenity.available
            }) },
            React.createElement(Tooltip, { key: amenity.type, title: amenity.available
                    ? amenity.description
                    : t('DesktopFlightInfo:Service is unavailable on this flight'), enterTouchDelay: 0 }, amenitiesIcons[amenity.type])))),
        isEco && (React.createElement(Tooltip, { title: t('DesktopFlightInfo:Eco-friendly') },
            React.createElement("span", { className: css.amenities__item }, leafIcon)))));
});
export default Amenities;
