import * as React from 'react';
import { useTheme } from '../../../../theme';
const Details = ({ items }) => {
    const { Details: css } = useTheme('DesktopFlightInfo');
    const groups = [];
    const chunkSize = 3;
    for (let i = 0, max = items.length; i < max; i += chunkSize) {
        groups.push(items.slice(i, i + chunkSize));
    }
    return (React.createElement("div", null, groups.map((group, index) => (React.createElement("ul", { key: index, className: css.group }, group.map((item, index) => (React.createElement("li", { key: index, className: css.item },
        React.createElement("span", { className: css.label }, item.label),
        React.createElement("span", { className: css.value }, item.value)))))))));
};
export default Details;
