import { __awaiter } from "tslib";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Modal from '../../../Modal';
import { ResendTicketsStages } from '../../../ResendTicketsForm/types';
import ResendTicketsForm from '../../../ResendTicketsForm';
import { useIsMobile } from '../../../utils';
import Button from '../../../Button';
const SendToEmail = ({ className, onResendTickets, changeLoadingStatus, renderTrigger }) => {
    var _a;
    const css = useTheme('RegisteredPassenger').SendToEmail;
    const { t } = useTranslation('RegisteredPassenger');
    const [isOpen, setIsOpen] = useState(false);
    const [resendStage, setResendStage] = useState(ResendTicketsStages.Default);
    const toggleModal = () => {
        setIsOpen(prevState => !prevState);
    };
    const resendTicketsHandler = ({ email }) => __awaiter(void 0, void 0, void 0, function* () {
        changeLoadingStatus && setResendStage(ResendTicketsStages.Loading);
        try {
            changeLoadingStatus && setResendStage(ResendTicketsStages.Loading);
            yield onResendTickets(email);
            setTimeout(() => {
                toggleModal();
                changeLoadingStatus && setResendStage(ResendTicketsStages.Default);
            }, 1500);
        }
        catch (e) {
            changeLoadingStatus && setResendStage(ResendTicketsStages.Error);
            setTimeout(() => {
                changeLoadingStatus && setResendStage(ResendTicketsStages.Default);
            }, 2500);
        }
    });
    const isMobile = useIsMobile();
    const onCloseModal = resendStage !== ResendTicketsStages.Loading ? toggleModal : null;
    return (React.createElement(React.Fragment, null, (_a = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger(toggleModal)) !== null && _a !== void 0 ? _a : (React.createElement(Button, { className: cn(css.text, className), onClick: toggleModal }, t('Send to email'))),
        React.createElement(Modal, { open: isOpen, onClose: onCloseModal, maxWidth: "md", classes: { paper: css.paper, scrollBody: css.scrollBody }, slideUp: isMobile },
            React.createElement(ResendTicketsForm, { status: resendStage, onSubmit: resendTicketsHandler, onCancel: toggleModal }))));
};
export default SendToEmail;
