import * as React from 'react';
import { useMemo } from 'react';
import QRCode from 'qrcode.react';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { useTheme } from '../theme';
import { initI18n } from '../utils';
import { hasSegmentBoardingPass } from '../Modules/Checkin/components/Checkin/Order/Passengers/utils';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Controls from './components/Controls/Controls';
import SelectedService from '../Modules/Checkin/components/Checkin/SelectedService/SelectedService';
initI18n('RegisteredPassenger');
const RegisteredPassenger = ({ name, retryLink, onBoardingPassDownload, onAppleWalletPassDownload, barcodes, linkedPassengers, services, seats, onCheckinCancel, onTicketsResend, isCheckedIn, segments, segmentsFailedBoardingPasses, setSegmentsFailedBoardingPasses }) => {
    const css = useTheme('RegisteredPassenger').RegisteredPassenger;
    const segmentsWithBoardingPasses = segments.filter(segment => hasSegmentBoardingPass(segment, segmentsFailedBoardingPasses));
    const canDownloadBoardingPass = !!segmentsWithBoardingPasses.length;
    const servicesByTypes = useMemo(() => {
        const servicesByTypesMap = new Map();
        services.forEach(service => {
            var _a;
            let type = service.type;
            if (service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage) {
                const baggageService = service;
                type = baggageService.baggageType;
            }
            servicesByTypesMap.set(type, [...((_a = servicesByTypesMap.get(type)) !== null && _a !== void 0 ? _a : []), service]);
        });
        return [...servicesByTypesMap.values()];
    }, [services]);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement(Header, { className: css.header, name: name, seats: seats, isCheckedIn: isCheckedIn, controls: isCheckedIn ? (React.createElement(Controls, { segments: segmentsWithBoardingPasses, canDownloadBoardingPass: canDownloadBoardingPass, onBoardingPassDownload: onBoardingPassDownload, onAppleWalletPassDownload: onAppleWalletPassDownload, setSegmentsFailedBoardingPasses: setSegmentsFailedBoardingPasses })) : null }),
        isCheckedIn && (React.createElement("div", { className: css.content },
            servicesByTypes.length > 0 && (React.createElement("div", { className: css.services }, servicesByTypes.map((services, index) => (React.createElement(SelectedService, { key: index, className: css.services__item, services: services }))))),
            barcodes &&
                barcodes.map((barcode, index) => {
                    var _a, _b, _c, _d;
                    return (barcode && (React.createElement("div", { className: css.barcode, key: barcode },
                        React.createElement(QRCode, { value: barcode, size: 200, level: "M", renderAs: "svg" }),
                        React.createElement("div", { className: css.barcode__direction },
                            React.createElement("div", null, (_b = (_a = segments[index].departure) === null || _a === void 0 ? void 0 : _a.airport) === null || _b === void 0 ? void 0 : _b.iata),
                            React.createElement("div", null, "-"),
                            React.createElement("div", null, (_d = (_c = segments[index].arrival) === null || _c === void 0 ? void 0 : _c.airport) === null || _d === void 0 ? void 0 : _d.iata)))));
                }))),
        React.createElement(Footer, { className: css.footer, isCheckedIn: isCheckedIn, canDownloadBoardingPass: canDownloadBoardingPass, segments: segments, retryLink: retryLink, passengerName: name, linkedPassengers: linkedPassengers, onCheckinCancel: onCheckinCancel, onTicketsResend: onTicketsResend })));
};
export default RegisteredPassenger;
