import * as React from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { getIsNotNew, getIsBookedOrConfirmed, isRoundTrip as getIsRoundTrip } from '../../../store/order/selectors';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { DesktopSummaryFlight } from '../../../../SummaryFlight';
import { FlightClass } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
const charterFareFamilyMock = {
    infoImage: '',
    description: '',
    title: 'charter',
    airline: { iata: null, icon: null, logo: null, name: 'null' },
    category: FlightClass.Economy,
    isRecommended: false,
    id: null,
    marker: '',
    options: [],
    promo: ''
};
const Flights = ({ order, backToResults }) => {
    const css = useTheme('Checkout').CheckoutStyles;
    const { t } = useTranslation();
    const isRoundTrip = useSelector(getIsRoundTrip);
    const isNotNew = useSelector(getIsNotNew);
    const isBookedOrConfirmed = useSelector(getIsBookedOrConfirmed);
    return (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, order.flight.segmentGroups.map((group, index) => {
        var _a;
        return (React.createElement(DesktopSummaryFlight, { key: index, orderId: order.id, flight: {
                id: order.flight.id,
                segments: group.segments.map(groupSegment => order.flight.segments.find(segment => segment.segment.id === groupSegment.id))
            }, price: group.price, fareFamily: (_a = group === null || group === void 0 ? void 0 : group.fareFamily) !== null && _a !== void 0 ? _a : Object.assign(Object.assign({}, charterFareFamilyMock), { title: t('DesktopSummaryFlight:Charter') }), className: cn(css.flight, {
                [css.flight_noMargin]: isNotNew
            }), onChange: isBookedOrConfirmed ? null : backToResults, isReturnFlight: index === 1 && isRoundTrip }));
    })));
};
export default Flights;
