import * as Base from './components/Base/Base.css';
import * as Seats from './components/Seats/Seats.css';
import * as Meal from './components/Meal/Meal.css';
import * as Table from './components/Seats/Table/Table.css';
export default {
    Base,
    Seats,
    Meal,
    Table
};
