import * as React from 'react';
import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CheckoutAdditionalService from '../..';
import { useTheme } from '../../../theme';
import { getMilesForOrder, getIsFFPUsed } from '../../../Checkout/store/order/selectors';
import icons from '../../icons';
import { useConfig } from '../../../context';
import LoyaltyConnect from '../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import { getUserInfo } from '../../../Checkout/utils';
import { getI18nCountVariation } from '../../../utils';
import { useConfigRedirects } from '../../../hooks';
const LoyaltyCard = ({ userInfo, refetchUser }) => {
    const { t } = useTranslation('Checkout');
    const { Loyalty: css } = useTheme('CheckoutAdditionalService');
    const [isLoyaltyConnect, setIsLoyaltyConnect] = useState(false);
    const isFFPProvidedToOrder = useSelector(getIsFFPUsed);
    const milesBonus = useSelector(getMilesForOrder);
    const { global: { companyInfo: { loyaltyProgramAccountUrl, loyaltyProgramName } } } = useConfig();
    const { onGoToAccountSettings } = useConfigRedirects();
    const openLoyaltyConnect = () => setIsLoyaltyConnect(true);
    const closeLoyaltyConnect = () => setIsLoyaltyConnect(false);
    const handleViewStatusClick = useCallback(() => {
        const loyaltyUrl = loyaltyProgramAccountUrl !== null && loyaltyProgramAccountUrl !== void 0 ? loyaltyProgramAccountUrl : '/account/loyalty';
        window.open(loyaltyUrl, '_blank');
    }, [isFFPProvidedToOrder]);
    const user = useMemo(() => getUserInfo(userInfo), [userInfo]);
    const onLoyaltyCardSaved = () => {
        closeLoyaltyConnect();
        refetchUser === null || refetchUser === void 0 ? void 0 : refetchUser();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckoutAdditionalService, { icon: icons.ffpIcon, className: css.loyalty, header: t(loyaltyProgramName), description: t('Start earning miles now to flight cheaper'), addButtonText: t('Register'), isSelected: !!(userInfo === null || userInfo === void 0 ? void 0 : userInfo.userFfpInfo), addClick: openLoyaltyConnect, buttons: [{ text: t('View status'), action: handleViewStatusClick }] }, milesBonus > 0 ? (React.createElement(React.Fragment, null,
            React.createElement("p", { className: css.loyalty__awardedInfo }, t('{{number}} {{miles}} will be awarded', {
                number: milesBonus,
                miles: t(`miles_${getI18nCountVariation(milesBonus)}`)
            })),
            React.createElement("p", null, t('Miles will be credited to your account after the flight.')))) : (React.createElement("p", { className: css.loyalty__awardedInfo }, t('Miles are not credited for this flight.')))),
        React.createElement(LoyaltyConnect, { isOpen: isLoyaltyConnect, userInfo: user, authType: "authenticate", onLoyaltyCardSaved: onLoyaltyCardSaved, onClose: closeLoyaltyConnect, onGoToSettings: onGoToAccountSettings })));
};
export default LoyaltyCard;
