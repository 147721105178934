import * as Inner from './components/Exchange/Inner/Inner.css';
import * as CommonSelect from './components/Exchange/CommonSelect/CommonSelect.css';
import * as Choice from './components/Exchange/Choice/Choice.css';
import * as CreateRequest from './components/Exchange/CreateRequest/CreateRequest.css';
import * as SearchFlights from './components/Exchange/SearchFlights/SearchFlights.css';
import * as Chip from './components/Exchange/Inner/Selections/Chip/Chip.css';
import * as Selections from './components/Exchange/Inner/Selections/Selections.css';
import * as Calendar from './components/Exchange/SearchFlights/Calendar/Calendar.css';
import * as RequestForm from './components/Exchange/RequestForm/RequestForm.css';
import * as Disclaimer from './components/Exchange/Disclaimer/Disclaimer.css';
import * as Sort from './components/Exchange/SearchFlights/Sort/Sort.css';
import * as Payment from './components/Exchange/Payment/Payment.css';
import * as RequestControls from './components/Exchange/RequestForm/RequestControls/RequestControls.css';
import * as Dialog from './components/Exchange/Dialog/Dialog.css';
import * as SelectedFlights from './components/Exchange/Request/SelectedFlights/SelectedFlights.css';
import * as ExchangeCheckoutStatus from './components/Exchange/ExchangeCheckoutStatus/ExchangeCheckoutStatus.css';
import * as Datepicker from './components/Exchange/Datepicker/Datepicker.css';
export default {
    Inner,
    CommonSelect,
    Choice,
    CreateRequest,
    SearchFlights,
    Chip,
    Selections,
    Calendar,
    RequestForm,
    Disclaimer,
    Sort,
    Payment,
    RequestControls,
    Dialog,
    SelectedFlights,
    ExchangeCheckoutStatus,
    Datepicker
};
