import * as React from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { BabyCarriage } from '../../../Icons';
import { useBaggageToggleable } from '../../hooks';
import UpgradeBaggageDialog from '../../UpgradeBaggageDialog/components/UpgradeBaggageDialog';
import BaggageCard from '../../BaggageCard';
import { BaggageType } from '@websky/graphql';
import HeaderEquipmentItem from '../../UpgradeBaggageDialog/components/BaggageDialogHeader/HeaderEquipmentItem/HeaderEquipmentItem';
import { getSportsEquipmentMinPrice } from '../../../Checkout/store/order/selectors';
import { useSelectedServicesBySegments } from '../../UpgradeBaggageDialog/hooks';
import { useRenderers } from '../../../renderProps';
import cn from 'classnames';
import { BaggageContext } from '../../../Baggage/context';
import BaggageDirection from '../../BaggageList/components/BaggageDirection/BaggageDirection';
const SpecialEquipment = ({ className, passengers, passengerLabels, segments, services, selectedServices, isLoading, disabled, dynamicBaggagePricing, analyticsMeta }) => {
    var _a, _b, _c, _d;
    const { SpecialEquipment: css } = useTheme('SpecialEquipment');
    const { t } = useTranslation('IncludedBaggage');
    const isReadOnly = (_a = useContext(BaggageContext)) === null || _a === void 0 ? void 0 : _a.isReadOnly;
    const minPrice = useSelector(getSportsEquipmentMinPrice);
    const { isOpen, open, close } = useBaggageToggleable(services);
    const { selectedItems, hasSelected, totalPrice } = useSelectedServicesBySegments(services, segments, selectedServices);
    const OverrideBaggageCard = (_d = (_c = (_b = useRenderers().Baggage) === null || _b === void 0 ? void 0 : _b.SpecialEqupment) === null || _c === void 0 ? void 0 : _c.BaggageCard) !== null && _d !== void 0 ? _d : BaggageCard;
    return (React.createElement(React.Fragment, null,
        React.createElement(OverrideBaggageCard, { className: cn(className, css.card), classNameIcon: css.card__icon, icon: BabyCarriage, title: t('Equipment'), description: t('Need to bring sports, music or baby equipments?'), hasSelected: hasSelected, fromPrice: minPrice, totalPrice: totalPrice, onAdd: open, onEdit: open, readOnly: isReadOnly }, hasSelected && (React.createElement("div", { className: css.flights }, selectedItems.map((selectedService, index) => (React.createElement("div", { className: css.flight, key: index },
            React.createElement(HeaderEquipmentItem, { className: css.flight_icon, rfics: selectedService.service.rfics, baggageType: selectedService.service.type, isActive: true }),
            React.createElement("div", { className: css.flight_count },
                "x",
                selectedService.count),
            React.createElement("span", { className: css.flight_info },
                selectedService.service.name,
                ",",
                ' ',
                React.createElement(BaggageDirection, { className: css.flight_direction, directions: selectedService.directions, allSegments: selectedService.allSegments })))))))),
        React.createElement(UpgradeBaggageDialog, { className: className, open: isOpen, onClose: close, passengers: passengers, passengerLabels: passengerLabels, segments: segments, includedBaggage: [], upgradeBaggage: services, selectedServices: selectedServices, headerProps: {
                header: t('Equipment'),
                description: ''
            }, disabled: isLoading || disabled, baggageType: BaggageType.SportingEquipment, dynamicBaggagePricing: dynamicBaggagePricing, isLoading: isLoading, analyticsMeta: analyticsMeta })));
};
export default SpecialEquipment;
