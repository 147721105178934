import { __awaiter } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import Button from '../../../../Button';
import Dialog from '../../../../Modules/Exchange/components/Exchange/Dialog/Dialog';
const Controls = ({ className, onRefuse, onGoToPayment, onGoToNewRequest, onConfirm }) => {
    const { t } = useTranslation('EditPassenger');
    const { Controls: theme } = useTheme('EditPassenger');
    const [isLoading, setIsLoading] = React.useState(false);
    const onLoadingRequest = React.useCallback((request) => () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield request();
        setIsLoading(false);
    }), [setIsLoading]);
    return (React.createElement("div", { className: cn(theme.container, className) }, !isLoading ? (React.createElement(React.Fragment, null,
        onRefuse && !isLoading && (React.createElement(Dialog, { text: t('The change request was deleted. Want to create a new request?'), acceptText: t('Yes'), cancelText: t('No'), onAccept: onLoadingRequest(onRefuse), onCancel: () => { } }, ({ open }) => (React.createElement(Button, { className: cn(theme.button, theme.button_delete), onClick: open }, t('Delete request'))))),
        onGoToPayment && (React.createElement(Button, { className: theme.button, onClick: onGoToPayment }, t('Payment change'))),
        onGoToNewRequest && (React.createElement(Button, { className: theme.button, onClick: onGoToNewRequest }, t('Create new request'))),
        onConfirm && (React.createElement(Button, { className: theme.button, onClick: onLoadingRequest(onConfirm) }, t('Confirm'))))) : (React.createElement(Button, { className: theme.button, isLoading: true }))));
};
export default Controls;
