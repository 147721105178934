import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useToggleable } from '../../../hooks';
import { concatPassengerNames, getPassengerNames, useIsMobile } from '../../../utils';
import { useSelectCancelSegments } from '../SelectCancelSegments/hooks';
import SendToEmail from '../SendToEmail/SendToEmail';
import { BaseButton } from '../../../BaseComponents';
import CancelModal from '../CancelModal/CancelModal';
import Tooltip from '../../../Tooltip';
import SelectSegments from '../../../SelectSegments';
import SelectCancelSegments from '../SelectCancelSegments/SelectCancelSegments';
import { Mail, Refundable } from '../../../Icons';
const Footer = ({ className, isCheckedIn, canDownloadBoardingPass, segments, linkedPassengers, retryLink, passengerName, onCheckinCancel, onTicketsResend }) => {
    const { Footer: css } = useTheme('RegisteredPassenger');
    const { t } = useTranslation('RegisteredPassenger');
    const { isOpen: isOpenCancelModal, close: closeCancelModal, toggle: toggleCancelModal } = useToggleable(false);
    const isMobile = useIsMobile();
    const [ticketResendEmail, setTicketResendEmail] = useState(null);
    const [ticketResendLoading, setTicketResendLoading] = useState(false);
    const { isSelectSegmentsModalOpen, selectSegmentToCancel, openSelectSegmentsModal: openSelectSegmentsToCancelModal, onConfirm: onConfirmCancel, selectedSegmentsToCancel, closeSelectSegmentsModal: closeSelectSegmentsToCancelModal, isCancelAvailableForMultipleSegments } = useSelectCancelSegments(onCheckinCancel);
    const toggleCancelModalHandler = useCallback(() => {
        if (isCancelAvailableForMultipleSegments) {
            openSelectSegmentsToCancelModal();
        }
        // cancel only for one segment
        if (!isCancelAvailableForMultipleSegments) {
            toggleCancelModal();
        }
    }, [isOpenCancelModal, isCancelAvailableForMultipleSegments]);
    const linkedPassengersNames = useMemo(() => {
        if (!linkedPassengers) {
            return [];
        }
        return linkedPassengers.map(passenger => {
            const { lastName, firstName, middleName } = getPassengerNames(passenger);
            return concatPassengerNames(lastName, firstName, middleName);
        });
    }, [linkedPassengers]);
    const cancelAvailable = useMemo(() => segments.some(segment => { var _a; return (_a = segment.checkinInfo) === null || _a === void 0 ? void 0 : _a.cancelAvailable; }), [segments]);
    const onTicketsResendHandler = (email, segmentId, onClose) => __awaiter(void 0, void 0, void 0, function* () {
        setTicketResendLoading(true);
        try {
            yield onTicketsResend(email, segmentId);
        }
        catch (e) {
            console.error(e);
        }
        onClose === null || onClose === void 0 ? void 0 : onClose();
        setTicketResendLoading(false);
    });
    const onResendTicketsHandler = (onClick) => (email, segmentId) => __awaiter(void 0, void 0, void 0, function* () {
        setTicketResendEmail(email);
        yield onClick(email, segmentId);
    });
    const onCheckinCancelHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        yield onCheckinCancel([]);
        closeCancelModal();
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(css.container, className, {
                [css.disabled]: !isCheckedIn
            }) }, isCheckedIn ? (React.createElement(React.Fragment, null,
            cancelAvailable && (React.createElement(BaseButton, { variant: "text", className: cn(css.button, css.cancel), onClick: toggleCancelModalHandler },
                React.createElement("div", { className: css.button__icon }, Refundable),
                t('Cancel check-in'))),
            canDownloadBoardingPass && onTicketsResend && (React.createElement(SelectSegments, { title: t('Select flight for download'), segments: segments, onClick: onTicketsResendHandler, renderTrigger: ({ onClick }) => (React.createElement(SendToEmail, { changeLoadingStatus: segments.length === 1, onResendTickets: onResendTicketsHandler(onClick), renderTrigger: onClick => (React.createElement(BaseButton, { variant: "text", className: css.button, onClick: onClick },
                        React.createElement("div", { className: css.button__icon }, Mail),
                        t('Send to email'))) })), renderButton: (segment, onClose) => {
                    const canDownloadBoardingPass = segment.checkinInfo.airportHasDcs;
                    return canDownloadBoardingPass ? (React.createElement(BaseButton, { className: css.sendToEmailButton, onClick: () => onTicketsResendHandler(ticketResendEmail, segment.id, onClose), isLoading: ticketResendLoading },
                        React.createElement("div", { className: css.button__icon }, Mail),
                        isMobile ? t('Send') : t('Send to email'))) : (React.createElement(Tooltip, { title: t('Registration available only at airport') },
                        React.createElement(BaseButton, { className: cn(css.sendToEmailButton, css.sendToEmailButton_disabled), onClick: () => null },
                            React.createElement("div", { className: css.button__icon }, Mail),
                            isMobile ? t('Send') : t('Send to email'))));
                } })))) : (React.createElement(NavLink, { to: retryLink, target: "_blank" },
            React.createElement(BaseButton, { variant: isMobile && !isCheckedIn ? 'optionAction' : 'text', className: css.button }, t('Retry check-in'))))),
        React.createElement(CancelModal, { passengerName: passengerName, linkedPassengerNames: linkedPassengersNames, isOpen: isOpenCancelModal, onClose: closeCancelModal, onConfirm: onCheckinCancelHandler, onCancel: closeCancelModal }),
        React.createElement(SelectCancelSegments, { onConfirm: onConfirmCancel, onClose: closeSelectSegmentsToCancelModal, onSegmentSelect: selectSegmentToCancel, selectedSegments: new Set(selectedSegmentsToCancel.map(segment => segment.id)), open: isSelectSegmentsModalOpen })));
};
export default Footer;
