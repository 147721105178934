import { addMonths, addYears, endOfYear, getDay, getDaysInMonth, startOfYear } from 'date-fns';
/**
 * Is the date was selected?
 * @param {Date} date
 * @param {SelectedTimestampDaysMap} selectedTimestampDays
 * @return {boolean}
 */
export const isDaySelected = (date, selectedTimestampDays) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return selectedTimestampDays.hasOwnProperty(newDate.getTime());
};
/**
 * Return timestamp the beginning of the day (00:00:00).
 * @param {Date} date
 * @return {number}
 */
export const getTimestamp = (date) => {
    const tmp = new Date(date);
    return tmp.setHours(0, 0, 0, 0);
};
/**
 * Parsing array of selected days. And looking min & max values of the array.
 * @param {Date[]} dates
 */
export const parseSelectedDays = (dates) => {
    const selectedTimestampDays = {};
    dates.map(date => {
        const newDate = new Date(date), time = newDate.setHours(0, 0, 0, 0);
        if (selectedTimestampDays.hasOwnProperty(time)) {
            selectedTimestampDays[time].count++;
        }
        else {
            selectedTimestampDays[time] = {
                count: 1
            };
        }
    });
    return selectedTimestampDays;
};
/**
 * Convert dates map array to plain map with timestamps
 */
export const parseHighlightedDates = (highlightedDates = {}) => {
    const highlightedDatesMap = {};
    for (const classNameGroup in highlightedDates) {
        if (highlightedDates.hasOwnProperty(classNameGroup)) {
            highlightedDates[classNameGroup].map(date => {
                const newDate = new Date(date.getTime());
                const timestamp = newDate.setHours(0, 0, 0, 0);
                if (highlightedDatesMap.hasOwnProperty(timestamp)) {
                    highlightedDatesMap[timestamp] += ` ${classNameGroup}`;
                }
                else {
                    highlightedDatesMap[timestamp] = classNameGroup;
                }
            });
        }
    }
    return highlightedDatesMap;
};
/**
 * Return true when first date is after second date
 *
 * @param {number} time1
 * @param {number} time2
 * @return {boolean}
 */
export const timestampsCompare = (time1, time2) => {
    if ((time1 >= 0 && time2 >= 0) || (time1 < 0 && time2 < 0)) {
        return time1 > time2;
    }
    return time1 > 0 && time2 < 0;
};
/**
 * Get all available dates with one year interval.
 * Example: ['2000-01-01', '2001-01-01', ... '2020-01-01']
 * @return {Date[]}
 */
export const getAllYears = (minTimestamp, maxTimestamp) => {
    const years = [startOfYear(new Date(minTimestamp))];
    let nextYear = startOfYear(new Date(minTimestamp));
    while (true) {
        nextYear = addYears(nextYear, 1);
        years.push(nextYear);
        if (endOfYear(nextYear).getTime() >= maxTimestamp) {
            return years;
        }
    }
};
/**
 * Is the date month available with min & max dates restrictions ?
 * @param {Date} date
 * @param {number} offset - month offset
 * @param {Date} minDate
 * @param {Date} maxDate
 * @return {boolean}
 */
export const isMonthAvailable = (date, offset = 0, minDate, maxDate) => {
    const newDate = addMonths(date, offset), time = new Date(newDate.getFullYear(), newDate.getMonth()).getTime(), minTimestamp = getTimestamp(minDate), maxTimestamp = getTimestamp(maxDate);
    newDate.setHours(0, 0, 0, 0);
    if (time >= minTimestamp && time <= maxTimestamp) {
        return true;
    }
    return (time < minTimestamp &&
        newDate.getFullYear() === minDate.getFullYear() &&
        newDate.getMonth() === minDate.getMonth());
};
/**
 * Checking that selected date satisfies the min and max dates.
 * @param {Date} date
 * @param {number} minTimestamp
 * @param {number} maxTimestamp
 * @return {Date}
 */
export const correctDate = (date, minTimestamp, maxTimestamp) => {
    const time = date.getTime();
    let suggestDate = date;
    if (timestampsCompare(minTimestamp, time)) {
        suggestDate = new Date(minTimestamp);
    }
    else if (timestampsCompare(time, maxTimestamp)) {
        suggestDate = new Date(maxTimestamp);
    }
    return suggestDate;
};
/**
 * Get array with dates
 * @param {Date} focusedDate
 * @param {number} monthCount
 * @return {Date[]}
 */
export const getMonthsList = (focusedDate, monthCount) => {
    const dates = [];
    for (let monthIndex = 0; monthIndex < monthCount; monthIndex++) {
        dates.push(addMonths(focusedDate, monthIndex));
    }
    return dates;
};
/**
 * Get selected dates count
 * @param {SelectedTimestampDaysMap} datesMap
 * @return {number}
 */
export const getSelectedDatesCount = (datesMap) => {
    let count = 0;
    for (const date in datesMap) {
        if (datesMap.hasOwnProperty(date)) {
            count += datesMap[date].count;
        }
    }
    return count;
};
export const diffInDays = (timestamp1, timestamp2) => {
    return Math.round(Math.abs((timestamp1 - timestamp2) / (24 * 60 * 60 * 1000)));
};
export const getWeeksInMonth = (startOfMonthDate) => {
    // change of the first working day to Monday
    const daysOffset = (getDay(startOfMonthDate) + 6) % 7;
    const daysInMonth = getDaysInMonth(startOfMonthDate);
    return Math.ceil((daysInMonth + daysOffset) / 7);
};
