import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderDocumentType, useOrderDocumentQuery } from '@websky/graphql';
import { transferLogo } from '../../../../../../../../Aeroexpress/icons';
import { Button, FileDownload } from '../../../../../../../../index';
import { useTheme } from '../../../../../../../../theme';
import { captureErrorSentry, downloadPDF } from '../../../../../../../../utils';
const DownloadAeroexpress = props => {
    const { orderId, setLoading } = props;
    const { t } = useTranslation('Aeroexpress');
    const { DownloadAeroexpress: css } = useTheme('Checkin');
    const { refetch: getDocuments } = useOrderDocumentQuery({ skip: true });
    const handleDownloadDocuments = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const { data } = yield getDocuments({
                params: { orderId: orderId, documentType: OrderDocumentType.AeroexpressTicket }
            });
            if (data === null || data === void 0 ? void 0 : data.OrderDocument) {
                downloadPDF(data === null || data === void 0 ? void 0 : data.OrderDocument, 'aeroexpress.pdf');
            }
        }
        catch (e) {
            captureErrorSentry(`Error while loading checkin aeroexpress tickets`);
        }
        finally {
            setLoading(false);
        }
    }), [getDocuments, orderId, props.travellers]);
    return (React.createElement("div", { className: css.download },
        React.createElement("span", { className: css.logo }, transferLogo),
        React.createElement(Button, { onClick: handleDownloadDocuments, className: css.button },
            React.createElement("span", { className: css.downloadIcon }, FileDownload),
            t('Download tickets'))));
};
export default DownloadAeroexpress;
