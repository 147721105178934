import { __awaiter } from "tslib";
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { LoginType, useAuthenticateMutation, useExistentEmailByFfpQuery, useSendSecureCodeMutation } from '@websky/graphql';
import SubmitButton from '../SubmitButton/SubmitButton';
import Resend from '../../../../Contacts/components/Resend/Resend';
import Input from '../../../../Input';
import { useTheme } from '../../../../theme';
import { STAY_LOGGED_IN } from '../../../../utils';
import { clearPhoneNumber } from '../../../../PhoneInput/utils';
import { AUTH_DUPLICATES, sessionStorageSet } from '../../../../cache';
import { Steps } from '../../../types';
const CodeForm = ({ login, loginType, stayLoggedIn, onSuccess, onLoginFromEmailOrFfp, setStep }) => {
    const { CodeForm: css, BaseComponent: baseCss } = useTheme('LoginPage');
    const { t } = useTranslation('LoginPage');
    const [incorrectCode, setIncorrectCode] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [authenticate] = useAuthenticateMutation();
    const [sendCode, { loading: codeSending }] = useSendSecureCodeMutation();
    const { refetch: userExistentEmailByFfp } = useExistentEmailByFfpQuery({
        skip: true
    });
    const resend = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setCodeSent(false);
        const result = yield sendCode({
            variables: {
                params: {
                    login: loginType === LoginType.Phone ? clearPhoneNumber(login) : login,
                    loginType: loginType
                }
            }
        });
        if (result) {
            setCodeSent(true);
        }
    }), [login, loginType]);
    const validate = useCallback((values) => {
        const errors = {};
        if (!values.code) {
            errors['code'] = t('Required');
        }
        return errors;
    }, []);
    const onAuthorize = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield authenticate({
                variables: {
                    params: {
                        login: loginType === LoginType.Phone ? clearPhoneNumber(login) : login,
                        loginType: loginType,
                        secureCode: values.code,
                        oneSession: !stayLoggedIn
                    }
                }
            });
            setIncorrectCode(false);
            onSuccess();
        }
        catch (_a) {
            setIncorrectCode(true);
        }
    }), [login, loginType, stayLoggedIn, onSuccess, authenticate]);
    const onUserExistentEmailByFfp = useCallback((code) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c;
        setIncorrectCode(false);
        try {
            const { data } = yield userExistentEmailByFfp({ login, password: code });
            return (_c = (_b = data === null || data === void 0 ? void 0 : data.ExistentEmailByFfp) === null || _b === void 0 ? void 0 : _b.email) !== null && _c !== void 0 ? _c : '';
        }
        catch (_d) {
            setIncorrectCode(true);
            return null;
        }
    }), [login, userExistentEmailByFfp]);
    const onSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        setIncorrectCode(false);
        if (stayLoggedIn) {
            localStorage.setItem(STAY_LOGGED_IN, 'true');
        }
        else {
            localStorage.removeItem(STAY_LOGGED_IN);
        }
        if (loginType === LoginType.FFP) {
            const existentEmailByFfp = yield onUserExistentEmailByFfp(values.code);
            if (existentEmailByFfp !== null) {
                const authDuplicates = {
                    login,
                    isExistDuplicate: !!existentEmailByFfp,
                    code: values.code
                };
                sessionStorageSet(AUTH_DUPLICATES, authDuplicates);
                if (existentEmailByFfp) {
                    // change step to login type selector
                    onLoginFromEmailOrFfp(existentEmailByFfp, values.code);
                    return;
                }
            }
        }
        yield onAuthorize(values);
        setIsLoading(false);
    }), [login, loginType, stayLoggedIn, onAuthorize, onUserExistentEmailByFfp]);
    const message = useMemo(() => {
        switch (loginType) {
            case LoginType.Email:
                return t('An e-mail with code has been sent to you.');
            case LoginType.Phone:
                return t('Code sent to your phone.');
            default:
                return null;
        }
    }, [loginType]);
    const onBack = () => {
        setStep(Steps.LogIn);
    };
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.title }, t(`Enter code_${loginType}`)),
        React.createElement("div", { className: css.message, dangerouslySetInnerHTML: { __html: message } }),
        React.createElement(Form, { validate: validate, onSubmit: onSubmit, render: ({ handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("div", { className: css.row },
                    React.createElement("div", { className: css.input_wrapper },
                        React.createElement(Field, { name: 'code', render: ({ input, meta }) => {
                                const error = incorrectCode ? t('Please, check the code') : meta.error;
                                const notValid = !!error && meta.touched;
                                return (React.createElement(Input, { label: t(`Verification code_${loginType}`), TextFieldProps: Object.assign(Object.assign({}, input), { onChange: ev => {
                                            if (incorrectCode) {
                                                setIncorrectCode(false);
                                            }
                                            input.onChange(ev);
                                        }, error: notValid, helperText: notValid ? error : null, InputProps: {
                                            classes: {
                                                input: baseCss.input
                                            }
                                        } }) }));
                            } })),
                    React.createElement("div", { className: css.button_wrapper },
                        React.createElement(SubmitButton, { loading: isLoading }, t(`Continue_${loginType}`)))))) }),
        loginType !== LoginType.FFP && (React.createElement(Resend, { className: css.resend, labelClassName: css.resend__label, sendCode: resend, codeSent: codeSent, codeSending: codeSending, onBack: onBack }))));
};
export default CodeForm;
