import * as React from 'react';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import DesktopFlightInfo from '../../DesktopFlightInfo';
import { getUniqueValidOptions } from '../../Results/utils';
import Tooltip from '../../Tooltip/Tooltip';
import Link from '../../Link';
import Header from './Header/Header';
import { FareFamilyOptionsType } from '@websky/graphql';
import { initI18n } from '../../utils';
import Modal from '../../Modal';
import CompareFares from '../../CompareFares';
import FareConditions from '../../FareConditions/components/Component';
import { useTheme } from '../../theme';
initI18n('DesktopSummaryFlight');
export const fareIcons = {
    [FareFamilyOptionsType.CarryOn]: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 5C11.305 5 10.6337 5.10128 10 5.28988V5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5V5.28988C13.3663 5.10128 12.695 5 12 5ZM7 7.10102C7.57412 6.51513 8.2504 6.02973 9 5.67363V5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V5.67363C15.7496 6.02973 16.4259 6.51513 17 7.10102V3L18 3V8.39241C18.6348 9.44596 19 10.6803 19 12V16H17V12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12V16H5V12C5 10.6803 5.36518 9.44596 6 8.39241L6 3L7 3L7 7.10102ZM19 17H16.0909H7.90909H5C4.44772 17 4 17.4477 4 18V21C4 21.5523 4.44772 22 5 22H6C6.08369 22 6.16497 21.9897 6.24266 21.9704C6.34058 21.9898 6.44183 22 6.54545 22H17.4545C17.5582 22 17.6594 21.9898 17.7573 21.9704C17.835 21.9897 17.9163 22 18 22H19C19.5523 22 20 21.5523 20 21V18C20 17.4477 19.5523 17 19 17ZM16 16V12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12V16H16ZM7 18V19H15V20H16V19H17V18H7Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.Baggage]: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.46488 6.97739H7.1378C6.30875 6.97739 5.63655 7.67829 5.63672 8.54314V19.4904C5.63672 20.3552 6.30892 21.0563 7.13798 21.0563H7.33954V22.1929C7.33954 22.4019 7.50168 22.5714 7.70246 22.5714H9.41907C9.61925 22.5714 9.78199 22.4021 9.78199 22.1929V21.0563H15.219V22.1929C15.219 22.4019 15.3813 22.5714 15.5819 22.5714H17.2987C17.4989 22.5714 17.6614 22.4021 17.6614 22.1929V21.0563H17.8627C18.6916 21.0563 19.364 20.3554 19.364 19.4904V8.54305C19.364 7.67838 18.6918 6.9773 17.8627 6.9773H16.5357V2.79305C16.5357 1.80448 15.7643 1 14.8165 1H10.1841C9.23604 1 8.46488 1.80448 8.46488 2.79305V6.97739ZM15.3898 6.9773H9.61127V2.79305C9.61127 2.46353 9.86839 2.19537 10.1843 2.19537H14.8167C15.1327 2.19537 15.3898 2.46353 15.3898 2.79305V6.9773ZM8.71008 9.64143C8.24592 9.64143 7.86964 10.0177 7.86964 10.4819C7.86964 10.946 8.24592 11.3223 8.71008 11.3223H16.4105C16.8747 11.3223 17.251 10.946 17.251 10.4819C17.251 10.0177 16.8747 9.64143 16.4105 9.64143H8.71008ZM8.7101 13.0033C8.24593 13.0033 7.86965 13.3796 7.86965 13.8437C7.86965 14.3079 8.24593 14.6842 8.7101 14.6842H16.4106C16.8747 14.6842 17.251 14.3079 17.251 13.8437C17.251 13.3796 16.8747 13.0033 16.4106 13.0033H8.7101ZM7.86965 17.2054C7.86965 16.7413 8.24593 16.365 8.7101 16.365H16.4106C16.8747 16.365 17.251 16.7413 17.251 17.2054C17.251 17.6696 16.8747 18.0459 16.4106 18.0459H8.7101C8.24593 18.0459 7.86965 17.6696 7.86965 17.2054Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.SeatRegistration]: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.2972 18.331L10.1522 20.5572H5.74396V22.239H11.1004L11.1972 22.286L11.2214 22.239H16.371V20.5572H12.0864L13.2314 18.331H16.7123C17.7853 18.331 18.6552 17.4854 18.6552 16.4423C18.6552 15.3992 17.7853 14.5536 16.7123 14.5536H9.73241L7.29962 7.35146V3.17934C7.29962 2.37039 6.62503 1.7146 5.79288 1.7146C4.96072 1.7146 4.28613 2.37039 4.28613 3.17934V7.35146L7.56418 18.331H11.2972ZM21.4901 7.34691C21.7604 7.07655 21.7604 6.6382 21.4901 6.36784C21.2197 6.09748 20.7814 6.09748 20.511 6.36784L16.2863 10.5926L14.6329 8.93927C14.3626 8.6689 13.9242 8.6689 13.6539 8.93927C13.3835 9.20963 13.3835 9.64797 13.6539 9.91834L16.2863 12.5507L21.4901 7.34691Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.Refundable]: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.28951 2.69238L3.23047 6.69238L7.28951 10.6924V7.69238H13.378C16.1801 7.69238 18.4518 9.93096 18.4518 12.6924C18.4518 15.4538 16.1802 17.6924 13.378 17.6924H7.28949V19.6924H13.378C17.301 19.6924 20.4813 16.5584 20.4813 12.6924C20.4813 8.82639 17.301 5.69238 13.378 5.69238H7.28951V2.69238Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.Exchangeable]: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.58619 3.69238H7.8466V5.27475H7.1543H5.53891C4.77421 5.27475 4.1543 5.89467 4.1543 6.65937V8.27475V10.4176V19.3187C4.1543 20.0834 4.77421 20.7033 5.53891 20.7033H18.426C19.1907 20.7033 19.8106 20.0834 19.8106 19.3187V10.4176V8.27475V6.65937C19.8106 5.89467 19.1907 5.27475 18.426 5.27475H16.8106H16.5447V3.69238H14.8051V5.27475H9.58619V3.69238ZM18.8875 10.4176H5.07737V19.3187C5.07737 19.5736 5.28401 19.7802 5.53891 19.7802H18.426C18.6809 19.7802 18.8875 19.5736 18.8875 19.3187V10.4176ZM10.4991 14.497H9.95885V15.3374H10.5161C10.7358 15.3374 10.9079 15.3887 11.0325 15.4914C11.1571 15.5941 11.2194 15.7682 11.2194 16.0137C11.2194 16.1856 11.1594 16.3273 11.0393 16.439C10.9193 16.5483 10.7584 16.603 10.5568 16.603C10.3779 16.603 10.2273 16.5483 10.105 16.439C9.98264 16.3296 9.92148 16.2001 9.92148 16.0506H8.76968C8.76968 16.3251 8.84103 16.5707 8.98373 16.7872C9.12643 17.0015 9.33595 17.17 9.6123 17.2928C9.8909 17.4133 10.1922 17.4736 10.5161 17.4736C11.0687 17.4736 11.515 17.343 11.8547 17.0818C12.1945 16.8207 12.3644 16.4724 12.3644 16.0372C12.3644 15.7559 12.2896 15.5204 12.1401 15.3307C11.9906 15.1387 11.7845 14.9959 11.5218 14.9021C11.7709 14.7861 11.9601 14.6354 12.0892 14.4501C12.2205 14.2626 12.2862 14.064 12.2862 13.8541C12.2862 13.4211 12.1288 13.0807 11.814 12.8329C11.4991 12.5852 11.0665 12.4613 10.5161 12.4613C10.208 12.4613 9.92488 12.5182 9.66666 12.632C9.4107 12.7436 9.21024 12.8999 9.06528 13.1008C8.92031 13.3017 8.84783 13.5305 8.84783 13.7872H9.99283C9.99283 13.6577 10.0483 13.5494 10.1593 13.4624C10.2726 13.3753 10.4051 13.3318 10.5568 13.3318C10.7426 13.3318 10.8864 13.3832 10.9883 13.4858C11.0903 13.5863 11.1412 13.7169 11.1412 13.8776C11.1412 14.2905 10.9272 14.497 10.4991 14.497ZM14.3316 17.4066H15.48V12.5316H15.3747L13.2036 13.2715V14.1287L14.3316 13.8039V17.4066Z", fill: "currentColor" })))
};
const DesktopSummaryFlight = ({ flight, isReturnFlight, fareFamily, price, onChange, isOpenByDefault = false, className, orderId, isWidePrice }) => {
    const { t } = useTranslation('DesktopSummaryFlight');
    const { SummaryFlight: css } = useTheme('DesktopSummaryFlight');
    const [isOpen, setIsOpen] = useState(isOpenByDefault);
    const firstSegment = flight.segments[0];
    const lastSegment = flight.segments[flight.segments.length - 1];
    const [isFareConditionsOpen, openFareConditions] = React.useState(false);
    const [isModalOpen, openModal] = React.useState(false);
    const openFareConditionsHandler = e => {
        e.preventDefault();
        openFareConditions(true);
    };
    const handleCompareClick = e => {
        e.preventDefault();
        openModal(true);
    };
    useEffect(() => {
        setIsOpen(isOpenByDefault);
    }, [isOpenByDefault]);
    const handleOnChange = onChange
        ? () => {
            onChange();
            setIsOpen(false);
        }
        : null;
    const onHeaderClick = () => setIsOpen(!isOpen);
    return (React.createElement("div", { className: cn(css.flight, className, {
            [css.flight_open]: isOpen
        }) },
        React.createElement(Header, { firstSegment: firstSegment, lastSegment: lastSegment, onClick: onHeaderClick, isOpen: isOpen, fareFamily: fareFamily, price: price, isReturnFlight: isReturnFlight, isWidePrice: isWidePrice }),
        React.createElement(Collapse, { in: isOpen, mountOnEnter: true },
            React.createElement("div", { className: css.info },
                React.createElement(DesktopFlightInfo, { flight: flight, onButtonClick: handleOnChange, buttonText: t('Change flight'), renderPreFooter: () => (React.createElement("div", { className: css.features },
                        React.createElement("div", { className: css.features__items }, getUniqueValidOptions(fareFamily.options, true, true).map((option, index) => fareIcons.hasOwnProperty(option.type) && (React.createElement(Tooltip, { key: index, title: option.description ? option.description : option.title },
                            React.createElement("div", { className: css.feature },
                                React.createElement("span", { className: css.feature__icon }, fareIcons[option.type]),
                                React.createElement("span", { className: css.feature__text },
                                    option.title,
                                    " ",
                                    option.value)))))),
                        React.createElement("div", { className: css.features__links },
                            React.createElement(Link, { action: openFareConditionsHandler }, t('Fare rules')),
                            fareFamily.title !== t('Charter') && (React.createElement(Link, { action: handleCompareClick }, t('Baggage info')))))) }))),
        React.createElement(Modal, { open: isFareConditionsOpen, onClose: () => openFareConditions(false) },
            React.createElement(FareConditions, { segmentId: flight.segments[0].segment.id, flightId: flight.id, orderId: orderId })),
        React.createElement(Modal, { open: isModalOpen, onClose: () => openModal(false), isRounded: true },
            React.createElement(CompareFares, { selectedFare: fareFamily.title, flightId: flight.id, orderId: orderId }))));
};
export default DesktopSummaryFlight;
