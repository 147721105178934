import i18n from 'i18next';
import { LocationType } from '../types';
import { LocationGroupType } from '@websky/graphql';
export const filterGroupsForDirectionsDialog = (groups) => {
    return groups.filter(item => 'type' in item && item.type === LocationGroupType.Country);
};
export const parseLocationGroups = (groups, query, locationType) => {
    // In case of no-results.
    if (groups && !groups.length) {
        return !!query.trim()
            ? [
                {
                    label: `${i18n.t('LocationAutocomplete:Sorry, we do not fly')} ${locationType === LocationType.Departure
                        ? i18n.t('LocationAutocomplete:from')
                        : i18n.t('LocationAutocomplete:to')}`,
                    options: [
                        {
                            label: query,
                            value: null
                        }
                    ]
                }
            ]
            : [];
    }
    return groups.map(group => {
        let label;
        const options = group.cities.map(city => ({
            label: city.name,
            value: city
        }));
        switch (group.type) {
            case LocationGroupType.Country:
                label = group.country.name;
                break;
            case LocationGroupType.Nearest:
                label = i18n.t('LocationAutocomplete:Nearby airports');
                break;
            case LocationGroupType.Popular:
                label = i18n.t('LocationAutocomplete:Popular airports');
                break;
            case LocationGroupType.AdditionalCities:
                label = i18n.t('LocationAutocomplete:Recent searches');
                break;
        }
        return {
            label,
            options,
            type: group.type
        };
    });
};
