import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import DesktopFlightInfo from '../../../DesktopFlightInfo';
import Header from '../Header/Header';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import { useDesktopFlightWithPopup } from './hooks';
import Modal from '../../../Modal';
import DesktopFlightModalContent from '../DesktopFlightModalContent/DesktopFlightModalContent';
import { useMemo } from 'react';
import { OverrideComponent } from '../../../renderProps';
initI18n('DesktopFlight');
const DesktopFlightPopup = props => {
    const { Flight: css, DesktopFlightPopup: popupCss } = useTheme('DesktopFlight');
    const { t } = useTranslation();
    const { flightBlock, lastSelectedFare, activeTab, getFareGroupTabHandler, handleFlightInfoTab, filteredPrices, handleEconomyFareGroupsTab, isModalOpen, closeModal, businessFareGroups, economyFareGroups, milesFareGroups, getOnFareSelect, upgradeOptions } = useDesktopFlightWithPopup(props);
    const flightModalContentProps = useMemo(() => {
        var _a;
        return ({
            lastSelectedFare: (_a = props.flight.pricesForFareGroups.find(fareGroup => { var _a; return fareGroup && ((_a = fareGroup.fareFamily) === null || _a === void 0 ? void 0 : _a.id) === lastSelectedFare; })) === null || _a === void 0 ? void 0 : _a.fareFamily,
            milesFares: milesFareGroups,
            upgradeOptions: upgradeOptions,
            flight: props.flight,
            economyFares: economyFareGroups,
            businessFares: businessFareGroups,
            getOnFareSelect: getOnFareSelect
        });
    }, [props.flight, upgradeOptions, economyFareGroups, businessFareGroups, getOnFareSelect]);
    return (React.createElement("div", { ref: flightBlock, className: cn(css.flight, {
            [css.flight_open]: activeTab !== null
        }) },
        React.createElement(Header, { flight: props.flight, getOnFareClickHandler: getFareGroupTabHandler, onInfoClick: handleFlightInfoTab, activeTab: activeTab, singleFare: props.singleFare, prices: filteredPrices, selectedFares: props.selectedFares }),
        React.createElement(Collapse, { in: activeTab !== null, mountOnEnter: true },
            React.createElement("div", { className: css.flight__content },
                React.createElement(DesktopFlightInfo, { flight: props.flight, onButtonClick: handleEconomyFareGroupsTab, buttonText: t('DesktopFlight:Select fare') }))),
        React.createElement(Modal, { open: isModalOpen, onClose: closeModal, isRounded: true, classes: {
                paper: popupCss.paper
            }, closeButtonRenderer: () => null },
            React.createElement(OverrideComponent, { componentProps: flightModalContentProps, component: { FlightDesktopFlightModalContent: DesktopFlightModalContent } }))));
};
export default DesktopFlightPopup;
