import * as React from 'react';
import { Field } from '../../../../../Passenger/components/Field/Field';
import { FieldsGroupType } from '../utils';
import LoyaltyGroup from './LoyaltyGroup/LoyaltyGroup';
import ChangeGroup from './ChangeGroup/ChangeGroup';
import { TravellerFieldEnum } from '@websky/graphql';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
import { OverrideComponent } from '../../../../../renderProps';
import Disabilities from '../../Disabilities/Disabilities';
const Group = props => {
    if (props.groupType === FieldsGroupType.Changes) {
        return React.createElement(ChangeGroup, Object.assign({}, props));
    }
    else if (props.groupType === FieldsGroupType.Loyalty) {
        return React.createElement(LoyaltyGroup, Object.assign({}, props));
    }
    else if (props.groupType === FieldsGroupType.Other) {
        return React.createElement(Disabilities, Object.assign({}, props));
    }
    const theme = useTheme('PassengerForm').Group;
    const birthDateField = React.useMemo(() => props.fields.find(field => field.type === TravellerFieldEnum.BirthDate), [props.fields]);
    return (React.createElement("div", { className: cn(theme.container, {
            [theme.identity]: props.groupType === FieldsGroupType.Identity,
            [theme.documents]: props.groupType === FieldsGroupType.Documents,
            [theme.withIcons]: !!props.groupIcon
        }) },
        props.groupIcon && React.createElement("div", { className: theme.icon }, props.groupIcon),
        props.fields.map((field, index) => {
            return (React.createElement(OverrideComponent, { componentProps: {
                    disabled: field.readonly || props.disabled,
                    field,
                    birthDateField,
                    passengerId: props.passengerId,
                    onTravellerSelect: props.onTravellerSelect,
                    selectedProfileId: props.selectedProfileId,
                    passengerType: props.passengerType,
                    passengerDocType: props.passengerDocType,
                    className: cn({
                        [theme.field_sex]: field.type === TravellerFieldEnum.Gender,
                        [theme.field_birthdate]: field.type === TravellerFieldEnum.BirthDate,
                        [theme.field_middleName]: field.type === TravellerFieldEnum.MiddleName
                    }),
                    loadTravellers: props.isAuthorized,
                    autofocus: props.autofocus && index === 0,
                    onSwap: props.onSwap
                }, component: {
                    PassengerFormField: Field
                }, key: `${field.name}_${index}` }));
        })));
};
export default Group;
