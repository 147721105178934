import { DataLayerEvent, Recommendation } from '../types';
class OrderDataLayer {
    constructor(instance) {
        this.dataLayer = instance;
    }
    pushToDataLayer(eventType, services, itemListName = null, params = {}) {
        if (this.isInitialized() && this.dataLayer) {
            try {
                this.dataLayer.pushToDataLayer(eventType, services
                    .reduce((newServices, service) => {
                    if (service.forAllSegments) {
                        return [
                            ...newServices,
                            ...this.getFlightSegments().map((segment, key) => (Object.assign(Object.assign({}, service), { price: key === 0 ? service.price : 0, segmentId: segment.id })))
                        ];
                    }
                    return [...newServices, service];
                }, [])
                    .map(service => this.getEcommerceItem(service)), params, itemListName);
            }
            catch (e) {
                console.error(e);
            }
        }
    }
    isInitialized() {
        return this.initialized;
    }
    setOrderStatus() {
        this.dataLayer.setCommonParams({
            item_category2: this.getOrderStatus()
        });
    }
    setOrder(order) {
        this.order = order;
        this.setOrderStatus();
        this.initialized = true;
    }
    getOrder() {
        return this.order;
    }
    getEcommerceItem(service) {
        var _a, _b;
        const segmentIds = service.segmentIds ? service.segmentIds : [(_a = service.segmentId) !== null && _a !== void 0 ? _a : '0'], segments = this.getFlightSegments().filter(segment => segmentIds.includes(segment.id)), firstSegment = segments[0];
        const flightDestinations = segments.map(segment => segment.departure.airport.iata);
        flightDestinations.push(segments[segments.length - 1].arrival.airport.iata);
        return {
            item_brand: service.name,
            item_category: service.category,
            item_id: `${firstSegment.marketingAirline.iata}-${firstSegment.flightNumber}`,
            item_name: flightDestinations.join('-'),
            item_category3: service.isRecommend ? Recommendation.Recommended : Recommendation.NoRecommended,
            price: service.price,
            item_category4: firstSegment.departure.date,
            item_category5: service.travellersTypes || [],
            quantity: (_b = service.count) !== null && _b !== void 0 ? _b : null
        };
    }
    purchaseOrder(transactionId, services) {
        this.pushToDataLayer(DataLayerEvent.Purchase, services, null, {
            transaction_id: transactionId
        });
    }
    setViewItem(service) {
        this.pushToDataLayer(DataLayerEvent.ViewItem, [service]);
    }
    beginCheckout(services) {
        this.pushToDataLayer(DataLayerEvent.Checkout, services);
    }
    setServicesViewItemList(itemListName, services) {
        if (!!services.length) {
            this.pushToDataLayer(DataLayerEvent.ViewItemList, services, itemListName);
        }
    }
    addServicesToCart(services) {
        if (services.length) {
            this.pushToDataLayer(DataLayerEvent.AddToCart, services);
        }
    }
    removeServicesFromCart(services) {
        if (services.length) {
            this.pushToDataLayer(DataLayerEvent.RemoveFromCart, services);
        }
    }
}
export default OrderDataLayer;
