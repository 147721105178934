import { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
export const useSwipeDown = ({ onClose, isMobile, isOpen, scroll }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [delta, setDelta] = useState(0);
    const [swipeFlag, setSwipeFlag] = useState(onClose && isMobile);
    const paperRef = useRef(null);
    const swipeBlockRef = useRef(null);
    const swipeFxRef = useRef(null);
    const contentRef = useRef(null);
    const swipeableConfig = {
        delta: 30,
        preventScrollOnSwipe: true,
        trackTouch: true,
        trackMouse: true,
        rotationAngle: 0,
        swipeDuration: Infinity,
        touchEventOptions: { passive: false } // options for touch listeners (*See Details*)
    };
    const setInitialStyles = () => {
        if (paperRef.current) {
            paperRef.current.style.transform = 'translateY(0)';
        }
        if (swipeFxRef.current) {
            swipeFxRef.current.style.transform = 'scale(1)';
        }
    };
    const onScroll = (e) => {
        setIsScrolled(e.target.scrollTop > 0);
    };
    useEffect(() => {
        var _a;
        if (contentRef.current && isOpen && onClose) {
            setInitialStyles();
            (_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', onScroll);
            handlers.ref(scroll === 'body' ? swipeBlockRef.current : paperRef.current);
        }
        return () => { var _a; return (_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', onScroll); };
    }, [isOpen, isMobile, onClose, contentRef.current, swipeBlockRef.current, paperRef.current]);
    const handlers = useSwipeable(Object.assign({ onSwipedDown: () => {
            if (!swipeFlag || !isMobile || !onClose) {
                return;
            }
            if (delta > 70) {
                onClose();
            }
        }, onSwipeStart: data => {
            setSwipeFlag(!(contentRef.current.contains(data.event.target) && isScrolled && onClose));
        }, onTouchStartOrOnMouseDown: data => {
            setSwipeFlag(!(contentRef.current.contains(data.event.target) && isScrolled && onClose));
        }, onTouchEndOrOnMouseUp: () => {
            if (!swipeFlag || !isMobile || !onClose) {
                return;
            }
            if (delta < 70) {
                setInitialStyles();
            }
            setSwipeFlag(!(isScrolled && onClose));
        }, onSwiping: data => {
            if (!swipeFlag || !isMobile || !onClose) {
                return;
            }
            setDelta(data.deltaY);
            if (data.deltaY > 0) {
                if (paperRef.current) {
                    paperRef.current.style.transform = `translateY(${data.deltaY}px)`;
                }
                if (swipeFxRef.current) {
                    swipeFxRef.current.style.transform =
                        data.deltaY <= 70 ? `scale(${1 - data.deltaY / 70})` : 'scale(0)';
                }
            }
        } }, swipeableConfig));
    return { handlers, contentRef, swipeBlockRef, swipeFxRef, paperRef };
};
