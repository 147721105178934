import * as React from 'react';
import { useState } from 'react';
import { IconButton, Menu as MUIMenu } from '@material-ui/core';
import cn from 'classnames';
import { useTheme } from '../../../theme';
const More = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM10 18C10 16.9 10.9 16 12 16C13.1 16 14 16.9 14 18C14 19.1 13.1 20 12 20C10.9 20 10 19.1 10 18Z", fill: "currentColor" })));
const Menu = ({ className, children }) => {
    const { Menu: css } = useTheme('LoyaltyInfo');
    const [anchorEl, setAnchorEl] = useState(null);
    const onClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onClose = () => {
        setAnchorEl(null);
    };
    const onClickMenu = (onClick) => (_) => {
        onClick();
        onClose();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { className: cn(css.button, className), onClick: onClick }, More),
        React.createElement(MUIMenu, { classes: {
                paper: css.paper
            }, anchorEl: anchorEl, open: !!anchorEl, onClose: onClose }, children(onClickMenu))));
};
export default Menu;
