import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExchangeType } from '../../../store/exchangeType/selectors';
import SearchResultsAdapter from '../SearchResultsAdapter/SearchResultsAdapter';
import { getFlightsToExchange, getNewFlightSearchParams, getSelectedPassengers } from '../../../store/selectors';
import { addFare, clearFare } from '../../../store/newFlights/actions';
import SearchFlightsComponent from '../SearchFlights/SearchFlights';
import { getOrder } from '../../../store/order/selectors';
import { ExchangeStep } from '../../../types';
import { getSelectedReason } from '../../../store/reason/selectors';
import { Reasons } from '../../../store/types';
const SearchFlights = props => {
    const dispatch = useDispatch();
    const exchangeType = useSelector(getExchangeType);
    const searchParams = useSelector(getNewFlightSearchParams);
    const order = useSelector(getOrder);
    const passengers = useSelector(getSelectedPassengers);
    const flightToExchange = useSelector(getFlightsToExchange);
    const selectedReason = useSelector(getSelectedReason);
    const [searchParamsState, setSearchParams] = React.useState(searchParams);
    React.useEffect(() => {
        setSearchParams(searchParams);
    }, [searchParams]);
    const onSelectFare = (flight, leg, legsCount) => {
        dispatch(addFare(flight));
        if (leg === legsCount - 1) {
            props.setStep(ExchangeStep.Request);
        }
    };
    const exchangeParams = React.useMemo(() => {
        return {
            orderId: order.id,
            passengers: passengers.map(passenger => parseInt(passenger.id)),
            segmentParams: flightToExchange.flightsByIds.map((flight, key) => ({
                segmentGroupId: flight.groupId,
                date: searchParamsState.segments[key].date
            })),
            isCompelled: selectedReason === Reasons.Unvoluntary
        };
    }, [order, passengers, flightToExchange, searchParamsState]);
    const onClearFares = () => dispatch(clearFare());
    return (React.createElement(SearchResultsAdapter, { exchangeType: exchangeType, exchangeParams: exchangeParams, onSelect: onSelectFare, setSearchParams: setSearchParams, setLoading: props.setLoading, clearFares: onClearFares }, (data, onSelect) => {
        var _a, _b, _c, _d, _e, _f;
        return (React.createElement(SearchFlightsComponent, { legs: (_b = (_a = data.results) === null || _a === void 0 ? void 0 : _a.GetExchangeVariants) === null || _b === void 0 ? void 0 : _b.flightDirections[data.currentLeg].legs, loading: data.loading, currentLeg: data.currentLeg, onFareSelect: onSelect, searchParams: searchParamsState, startNewSearch: searchParameters => {
                setSearchParams(searchParameters);
            }, resultsSearchParams: (_d = (_c = data.results) === null || _c === void 0 ? void 0 : _c.GetExchangeVariants) === null || _d === void 0 ? void 0 : _d.searchParameters, prices: (_f = (_e = data.results) === null || _e === void 0 ? void 0 : _e.GetExchangeVariants) === null || _f === void 0 ? void 0 : _f.exchangeVariantPriceInfo, clearFares: data.clearFares, error: data.error }));
    }));
};
export default SearchFlights;
