import { __rest } from "tslib";
import * as React from 'react';
import Button from '@mui/material/Button';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { ChevronLeft, Chevron, ArrowForward } from '../../../../Icons';
const TurnButton = (_a) => {
    var { variant = 'goTo', classes, children, disabled, disableElevation = true } = _a, restProps = __rest(_a, ["variant", "classes", "children", "disabled", "disableElevation"]);
    const { TurnButton: theme } = useTheme('BaseComponents');
    const icon = variant === 'goTo' ? ArrowForward : variant === 'prev' ? ChevronLeft : Chevron;
    return (React.createElement(Button, Object.assign({ variant: "contained", classes: Object.assign(Object.assign({}, classes), { root: cn(theme.root, theme[variant], classes === null || classes === void 0 ? void 0 : classes.root), disabled: cn(theme.disabled, classes === null || classes === void 0 ? void 0 : classes.disabled) }), disabled: disabled, disableElevation: disableElevation }, restProps), icon));
};
export default TurnButton;
