import { LoginInputType } from './types';
import { emailRegex } from '../utils';
import { isCorrectPhoneNumber } from '../PhoneInput/utils';
export const detectInputType = (value) => {
    if (emailRegex.test(value)) {
        return LoginInputType.Email;
    }
    else if (isCorrectPhoneNumber(value)) {
        return LoginInputType.Phone;
    }
    else {
        return LoginInputType.Text;
    }
};
export const getLoginTypeValue = (loginTypes, loginType) => {
    var _a;
    return (_a = loginTypes.find(({ type }) => type === loginType)) === null || _a === void 0 ? void 0 : _a.value;
};
