import { InfoMessagePositionsEnum } from '@websky/graphql';
import { createAction, handleActions } from 'redux-actions';
const setInfoMessages = createAction('SET_INFO_MESSAGES');
const infoMessagesReducer = handleActions({
    [setInfoMessages.toString()](state, action) {
        var _a;
        const newState = { byDirections: {} };
        const { payload } = action;
        for (const [directionIndex, direction] of payload.flightDirections.entries()) {
            for (const leg of direction.legs) {
                if (!newState.byDirections[directionIndex]) {
                    newState.byDirections[directionIndex] = {};
                }
                for (const segment of leg.segments) {
                    newState.byDirections[directionIndex][segment.segment.flightNumber] = [];
                    for (const message of segment.segment.messages) {
                        if ((_a = message.positions) === null || _a === void 0 ? void 0 : _a.some(position => [
                            InfoMessagePositionsEnum.upsaleEconomy,
                            InfoMessagePositionsEnum.flight,
                            InfoMessagePositionsEnum.flightPrice
                        ].includes(position))) {
                            newState.byDirections[directionIndex][segment.segment.flightNumber].push(message);
                        }
                    }
                }
            }
        }
        return newState;
    }
}, {
    byDirections: {}
});
export default infoMessagesReducer;
export { setInfoMessages };
