import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useTheme } from '../../../theme';
import { createFastSearchURL } from '../../../SearchForm/utils';
const PromoNotification = props => {
    const { PromoNotification: theme } = useTheme('Notification');
    const { infoMessage: { title, text, image }, searchParams } = props;
    const textRef = useRef(null);
    useEffect(() => {
        if (!textRef.current) {
            return null;
        }
        const anchorElement = textRef.current.querySelector('a');
        const url = new URL(anchorElement.href);
        let actualSearchParams = searchParams;
        if (actualSearchParams.segments.length > 1) {
            const segmentLength = actualSearchParams.segments.length;
            // parse url iatas
            const urlIatas = decodeURI(url.hash).replace(/^#\/search\//gm, '');
            const [departureIata, arrivalIata] = [urlIatas.slice(0, 3), urlIatas.slice(3, 6)];
            // prepare RT search params by url iatas
            const lastSegment = actualSearchParams.segments[segmentLength - 1];
            actualSearchParams = Object.assign(Object.assign({}, actualSearchParams), { segments: [
                    ...actualSearchParams.segments.slice(0, segmentLength - 1),
                    Object.assign(Object.assign({}, lastSegment), { departure: Object.assign(Object.assign({}, lastSegment.departure), { iata: arrivalIata }), arrival: Object.assign(Object.assign({}, lastSegment.arrival), { iata: departureIata }) })
                ] });
        }
        // remove current iata codes from fast search url
        const fastSearchUrlData = createFastSearchURL(actualSearchParams).slice(6);
        url.hash += fastSearchUrlData;
        anchorElement.href = url.toString();
    }, [textRef, searchParams]);
    return (React.createElement("div", { className: theme.promoNotificationWrapper },
        React.createElement("div", { className: theme.content, style: { backgroundImage: `url(${image})` } },
            React.createElement("h5", { className: theme.title }, title),
            React.createElement("p", { ref: textRef, dangerouslySetInnerHTML: { __html: text }, className: theme.text }))));
};
export default PromoNotification;
