import { getTotalPrice } from '../../utils';
export const getAnimalTotalPrice = (services, travellers, withoutUnconfirmed = false) => {
    const selectedServicePrices = [];
    services.forEach(service => {
        const allowedPassengerIds = new Set(service.allowedPassengers.reduce((acc, ids) => [...acc, ...ids], []));
        travellers === null || travellers === void 0 ? void 0 : travellers.forEach(traveller => {
            var _a, _b, _c;
            if (allowedPassengerIds.has(traveller.id)) {
                (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(selectedService => {
                    if (selectedService.serviceId === service.id &&
                        (!withoutUnconfirmed || (withoutUnconfirmed && selectedService.confirmedCount === 0))) {
                        selectedServicePrices.push(Object.assign(Object.assign({}, service.price), { amount: service.price.amount * selectedService.segmentIds.length }));
                    }
                });
            }
        });
    });
    return getTotalPrice(selectedServicePrices, price => price);
};
