import { createSelector } from 'reselect';
import { getOrder } from '../order/selectors';
import { NASProductType } from '@websky/graphql';
export const getEveryPortSelectedServices = (state) => state.everyPort.selectedServices;
export const getEveryPortLoungeService = createSelector(getOrder, order => {
    return order.additionalServices.nas.services.filter(service => service.type === NASProductType.Lounge);
});
export const getEveryPortServicesByCategories = createSelector(getEveryPortLoungeService, services => {
    const servicesMap = new Map();
    services.forEach(service => {
        servicesMap.set(service.category, [...(servicesMap.get(service.category) || []), service]);
    });
    return servicesMap;
});
export const getEveryPortSelectedServicesArray = createSelector(getEveryPortSelectedServices, selectedServices => {
    const services = [];
    Object.values(selectedServices).forEach(servicesByCategory => {
        servicesByCategory.forEach(service => {
            services.push({
                id: service.product.id,
                additionalServices: service.additionalServices,
                travellerIds: service.travellerIds
            });
        });
    });
    return services;
});
