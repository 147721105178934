import { createActions } from 'redux-actions';
export const TOGGLE_MULTI_CITY = 'TOGGLE_MULTI_CITY';
export const ADD_SEGMENT = 'ADD_SEGMENT';
export const REMOVE_SEGMENT = 'REMOVE_SEGMENT';
export const SET_DATE = 'SET_DATE';
export const SET_DATE_MULTI_CITY = 'SET_DATE_MULTI_CITY';
export const CLEAR_DATES = 'CLEAR_DATES';
export const SET_LOCATION = 'SET_LOCATION';
export const SWAP_LOCATIONS = 'SWAP_LOCATIONS';
export const SWAP_MULTI_LOCATIONS = 'SWAP_MULTI_LOCATIONS';
export const SET_SEGMENTS = 'SET_SEGMENTS';
export const SET_MULTI_CITY = 'SET_MULTI_CITY';
export const SET_MULTI_CITY_ROUTE = 'SET_MULTI_CITY_ROUTE';
export const { toggleMultiCity, addSegment, removeSegment, setDate, setDateMultiCity, clearDates, setLocation, swapLocations, swapMultiLocations, setSegments, setMultiCity } = createActions({
    [SET_DATE]: (date, isBackDate = false) => ({ date, isBackDate }),
    [SET_DATE_MULTI_CITY]: (date, segmentIndex) => ({ date, segmentIndex }),
    [TOGGLE_MULTI_CITY]: null,
    [SET_MULTI_CITY]: (value) => value,
    [ADD_SEGMENT]: null,
    [CLEAR_DATES]: null,
    [REMOVE_SEGMENT]: (segmentIndex) => segmentIndex,
    [SET_LOCATION]: (location, type, segmentIndex) => ({
        location,
        type,
        segmentIndex
    }),
    [SWAP_LOCATIONS]: null,
    [SWAP_MULTI_LOCATIONS]: (segmentIndex) => segmentIndex,
    [SET_SEGMENTS]: (segments) => segments
});
