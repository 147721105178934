import EditPassenger from './components/EditPassenger.css';
import PassengerForm from './components/PassengerForm/PassengerForm.css';
import PassengerTicket from './components/PassengerTicket/PassengerTicket.css';
import Receipt from './components/PassengerTicket/Receipt/Receipt.css';
import Controls from './components/PassengerTicket/Controls/Controls.css';
import Notification from './components/Notification/Notification.css';
export default {
    EditPassenger,
    PassengerForm,
    PassengerTicket,
    Receipt,
    Controls,
    Notification
};
