import { __decorate } from "tslib";
import * as React from 'react';
import { createDatesArray } from '../../utils';
import { format } from 'date-fns';
import { HeaderType } from '../PriceMatrix';
import { withTheme } from '../../theme';
import classnames from 'classnames';
let Outbound = class Outbound extends React.Component {
    fill() {
        return createDatesArray(this.props.minDate, this.props.maxDate);
    }
    arrowClick(dir) {
        this.props.onArrowClick && this.props.onArrowClick(this.props.type, dir);
    }
    render() {
        const dates = this.fill(), style = this.props.theme.OutboundStyles;
        return (React.createElement("div", { className: classnames(style.container, {
                [style.container_row]: this.props.type === HeaderType.Return,
                [style.container_col]: this.props.type === HeaderType.Outbound
            }) },
            React.createElement("div", { className: style.nav },
                React.createElement("div", { className: style.label }, this.props.type === HeaderType.Outbound ? 'Outbound' : 'Return'),
                React.createElement("div", { className: style.arrows },
                    React.createElement("div", { className: style.arrow_prev, onClick: () => this.arrowClick(this.props.type === HeaderType.Return ? 'prev' : 'next') }),
                    React.createElement("div", { className: style.arrow_next, onClick: () => this.arrowClick(this.props.type === HeaderType.Return ? 'next' : 'prev') }))),
            React.createElement("div", { className: style.wrapper, ref: this.props.innerRef }, dates.map((date, index) => {
                return (React.createElement("div", { key: index, className: style.day },
                    React.createElement("span", null, format(date, 'd MMM')),
                    React.createElement("span", { className: style.day_dow }, format(date, 'EEE'))));
            }))));
    }
};
Outbound = __decorate([
    withTheme
], Outbound);
export { Outbound };
