import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
const Point = ({ location, showAirportModal }) => {
    const { Point: css } = useTheme('DesktopFlightInfo');
    const { t } = useTranslation();
    return (React.createElement("div", { className: css.point },
        React.createElement("span", null, location.time.substr(0, 5)),
        React.createElement("span", null, location.airport.iata),
        showAirportModal ? (React.createElement("span", { className: css.link }, location.airport.name)) : (React.createElement("span", { className: css.airportName }, location.airport.name)),
        location.terminal && (React.createElement("span", { className: css.terminal },
            "\u2022 ",
            t('DesktopFlightInfo:Terminal'),
            " ",
            location.terminal))));
};
export default Point;
