import * as React from 'react';
import { useEffect, useMemo } from 'react';
import PromoLoader from '../../../PromoLoader';
import { useCreateOrder } from './hooks';
const CreateOrder = props => {
    const { flightsIds: flightIdsString, redirectOnFail, onSuccess } = props;
    const flightIds = flightIdsString.split('/');
    const createOrderParams = useMemo(() => {
        return flightIds.length > 1 ? { flightIds } : { flightId: flightIds[0] };
    }, [flightIdsString]);
    const { loading, order, orderError } = useCreateOrder(createOrderParams);
    useEffect(() => {
        if (order) {
            onSuccess(order, true);
        }
    }, [order]);
    if (orderError) {
        return redirectOnFail;
    }
    return loading ? React.createElement(PromoLoader, null) : null;
};
export default CreateOrder;
