import { __awaiter } from "tslib";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableServices, getExistGdsServicesOrInsurances, getHasAvailableSeats, getHasRequestedOrderServices, getIsBooked, getIsPriceFreezeProductSelected, getIsReadonly, getIsRedirectFromMetasearch, getIsTravellersDataFilled, getOrder, getPaymentIsRequired, getShowServicesBeforeBooking } from '../../store/order/selectors';
import { OrderGoal, reachGoal } from '../../../analytics';
import { downloadPDF, getCheckInUrl, MOBILE_MIN_WIDTH } from '../../../utils';
import { useConfig } from '../../../context';
import { OrderDocumentType, useCheckinCreateOrderMutation, useCheckinRulesQuery, useOrderDocumentQuery, useResendItineraryReceiptMutation, OrderStatus, TravellerFieldEnum, useUpdateOrderMutation, useResetOrderPaymentBlockMutation, useGetCheckinOrderQuery } from '@websky/graphql';
import { useConditionalPolling, useToggleable } from '../../../hooks';
import { ResendTicketsStages } from '../../../ResendTicketsForm/types';
import { getSteps, removeStepsWhenOrderHasPassengersData, removeStepsWhenRedirectFromMetasearch } from '../../steps';
import { StepsEnum } from '../../../types';
import { fillOrder } from '../../store/order/actions';
import { useIsCheckinAvailable } from '../../hooks';
import { tinkoffCallbackUrlSuffix } from '../../../SocialAuth/components/utils';
import { WEBSKY_SERVICE_OPEN_EVENT } from '../../constants';
import { getQueryPopupGoal } from '../../../DataLayerAnalytics/utils';
import { getQueryServicesParams, getUrlWithServicesParams } from '../../utils';
export class OrderIsArchivedTicketDownloadError extends Error {
    constructor() {
        super('Your order was archived. Please contact with client support to get a receipt');
        Object.setPrototypeOf(this, OrderIsArchivedTicketDownloadError.prototype);
    }
}
export const useDownloadOrderDocument = () => {
    const order = useSelector(getOrder);
    const [_error, setError] = useState(null);
    const { isOpen: loading, open: setLoading, close: setNotLoading } = useToggleable(false);
    const { isOpen: warningIsOpen, open: warningOpen, close: warningClose } = useToggleable(false);
    const { refetch: refetchDocuments, data, error } = useOrderDocumentQuery({
        skip: true
    });
    const handleDownload = useCallback((passengerId, documentType) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (loading) {
            return;
        }
        try {
            if (order.isArchived) {
                throw new OrderIsArchivedTicketDownloadError();
            }
            setLoading();
            reachGoal(OrderGoal.ItineraryReceiptDownload);
            const data = yield refetchDocuments({
                params: {
                    orderId: order.id,
                    documentType: documentType !== null && documentType !== void 0 ? documentType : OrderDocumentType.All,
                    travellerId: passengerId
                }
            });
            if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.OrderDocument) {
                downloadPDF(data.data.OrderDocument, `${getOrderDocumentFileName(documentType)}.pdf`);
            }
        }
        catch (er) {
            if (er instanceof Error) {
                setError(er);
            }
            warningOpen();
        }
        finally {
            setNotLoading();
        }
    }), [order.status]);
    return {
        handleDownload,
        document: data === null || data === void 0 ? void 0 : data.OrderDocument,
        loading,
        error: error || _error,
        warningIsOpen,
        warningClose
    };
};
const getOrderDocumentFileName = (type) => {
    switch (type) {
        case OrderDocumentType.AeroexpressTicket:
            return 'aeroexpress';
        case OrderDocumentType.EMD:
            return 'emd';
        case OrderDocumentType.NASTicket:
            return 'airport_services';
        case OrderDocumentType.EveryTravelTicket:
            return 'tour_voucher';
        default:
            return 'tickets';
    }
};
export const useCheckinStart = () => {
    var _a;
    const order = useSelector(getOrder);
    const { isOpen: isRulesModalOpen, open, close } = useToggleable(false);
    const [checkinOrderId, setCheckinOrderId] = useState();
    const [checkinCreationInProgress, setCheckinCreationInProgress] = useState(false);
    const isCheckinAvailable = useIsCheckinAvailable(order);
    const [createCheckin] = useCheckinCreateOrderMutation();
    const [resetOrderPaymentBlock] = useResetOrderPaymentBlockMutation();
    const { refetch: getCheckinOrder } = useGetCheckinOrderQuery({ skip: true, fetchPolicy: 'no-cache' });
    const { Engine: { checkInEnabled, checkInURL }, global: { companyInfo: { iataCode }, reserveEngines } } = useConfig();
    const rulesData = useCheckinRulesQuery({
        variables: {
            id: order.id
        },
        skip: !checkInEnabled || !checkInURL || !isCheckinAvailable || iataCode === 'N4'
    });
    const goToCheckin = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d;
        const traveller = order.travellers[0];
        const ticket = ((_c = (_b = traveller === null || traveller === void 0 ? void 0 : traveller.tickets) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.number) || order.locator;
        const lastName = (_d = traveller.values.find(value => value.type === TravellerFieldEnum.LastName)) === null || _d === void 0 ? void 0 : _d.value;
        const orderId = order.id;
        if ((reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.redirectToWebsky1) && (reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin)) {
            window.open(getCheckInUrl(checkInURL, orderId, ticket, lastName, reserveEngines), '_blank');
        }
        else {
            setCheckinCreationInProgress(true);
            setCheckinOrderId('');
            let checkinOrderCreated = false;
            try {
                const { data, errors } = yield createCheckin({
                    variables: {
                        params: { aviaOrderId: order.id }
                    }
                });
                if (!errors && data && data.CheckinCreateOrder) {
                    const orderId = data.CheckinCreateOrder.id;
                    let result = data.CheckinCreateOrder;
                    if (data.CheckinCreateOrder.isBlockedForPayment) {
                        yield resetOrderPaymentBlock({ variables: { id: orderId } });
                        const { data, errors } = yield getCheckinOrder({ id: orderId });
                        if (data.CheckinOrder && !errors) {
                            result = data.CheckinOrder;
                        }
                    }
                    setCheckinOrderId(result.id);
                    checkinOrderCreated = true;
                    if (checkInEnabled && checkInURL) {
                        if (iataCode === 'N4') {
                            window.open(getCheckInUrl(checkInURL, result.id), '_blank');
                        }
                        else {
                            open();
                        }
                    }
                }
            }
            catch (e) {
                console.warn('Could not create check-in order. Proceed with passenger credentials.');
            }
            setCheckinCreationInProgress(false);
            if (!checkinOrderCreated) {
                window.open(getCheckInUrl(checkInURL, orderId, ticket, lastName), '_blank');
            }
        }
    });
    const acceptRules = useCallback(() => {
        if (checkinOrderId) {
            window.open(getCheckInUrl(checkInURL, checkinOrderId), '_blank');
        }
        close();
    }, [checkinOrderId, isRulesModalOpen, close]);
    return {
        isCheckinAvailable,
        acceptRules,
        goToCheckin,
        checkinRules: (_a = rulesData === null || rulesData === void 0 ? void 0 : rulesData.data) === null || _a === void 0 ? void 0 : _a.CheckinRules,
        isRulesModalOpen,
        checkinCreationInProgress,
        closeCheckinRulesModal: close
    };
};
export const useResendTickets = (orderId) => {
    const UNAUTHORIZED_CODE = '403';
    const CLOSE_POPUP_AFTER_SUCCESSFUL_RESEND_AFTER = 1500;
    const RETURN_DEFAULT_STATUS_AFTER_SUCCESSFUL_RESEND_AFTER = 2000;
    const [resendStage, setResendStage] = useState(ResendTicketsStages.Default);
    const { isOpen, open, close } = useToggleable(false);
    const [resendEmail, { data, loading, error }] = useResendItineraryReceiptMutation();
    const isUnauthorizedError = useMemo(() => resendStage === ResendTicketsStages.Error && (error === null || error === void 0 ? void 0 : error.message.includes(UNAUTHORIZED_CODE)), [resendStage]);
    const handleResendEmail = useCallback((email) => {
        setResendStage(ResendTicketsStages.Loading);
        resendEmail({
            variables: {
                params: {
                    email,
                    orderId
                }
            }
        });
    }, [isOpen, resendStage, orderId, resendEmail]);
    const handleWarningModalClose = () => {
        setResendStage(ResendTicketsStages.Default);
    };
    useEffect(() => {
        var _a;
        const resendResult = data === null || data === void 0 ? void 0 : data.ResendItineraryReceipt;
        if (error || ((_a = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length)) {
            setResendStage(ResendTicketsStages.Error);
            return;
        }
        if (!loading && !error && resendResult) {
            setResendStage(ResendTicketsStages.Success);
            setTimeout(close, CLOSE_POPUP_AFTER_SUCCESSFUL_RESEND_AFTER);
            setTimeout(() => {
                setResendStage(ResendTicketsStages.Default);
            }, RETURN_DEFAULT_STATUS_AFTER_SUCCESSFUL_RESEND_AFTER);
        }
    }, [data, loading, error]);
    return {
        openModal: open,
        closeModal: close,
        isModalOpen: isOpen,
        isUnauthorizedError,
        resendStage,
        handleWarningModalClose,
        handleResendEmail,
        loading
    };
};
export const usePromoCodesData = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { isOpen, setOpen, close } = useToggleable(false);
    return {
        data,
        setData,
        isOpen,
        setOpen,
        close,
        loading,
        setLoading
    };
};
const SYSTEM_PAGES = ['payment/successfull', 'payment/failed', 'refund', 'exchange', 'change-passenger-docs'];
export const useSteps = () => {
    const order = useSelector(getOrder);
    const availableServices = useSelector(getAvailableServices), { global: { stepsAvailable, servicesAvailable } } = useConfig();
    const isPaymentRequired = useSelector(getPaymentIsRequired), hasAvailableSeats = useSelector(getHasAvailableSeats), showServicesBeforeBooking = useSelector(getShowServicesBeforeBooking), isReadonly = useSelector(getIsReadonly), isTravellersDataFilled = useSelector(getIsTravellersDataFilled), isPriceFreezeProductSelected = useSelector(getIsPriceFreezeProductSelected), isBooked = useSelector(getIsBooked);
    const stepsRef = useRef();
    const location = useLocation(), match = useRouteMatch(), baseUrl = match.url;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const isRedirectFromMetasearch = useSelector(getIsRedirectFromMetasearch);
    const steps = useMemo(() => {
        const steps = getSteps({
            order,
            availableServices,
            isPaymentRequired,
            hasAvailableSeats,
            showServicesBeforeBooking,
            configStepAvailable: stepsAvailable,
            configServicesAvailable: servicesAvailable
        });
        if (isMobile && isRedirectFromMetasearch) {
            return removeStepsWhenRedirectFromMetasearch(steps);
        }
        if (!isPriceFreezeProductSelected &&
            isBooked &&
            isTravellersDataFilled &&
            !location.pathname.includes('passengers')) {
            return removeStepsWhenOrderHasPassengersData(steps);
        }
        return steps;
    }, [
        order,
        availableServices,
        isPaymentRequired,
        isReadonly,
        hasAvailableSeats,
        showServicesBeforeBooking,
        stepsAvailable,
        isMobile,
        isRedirectFromMetasearch,
        isPriceFreezeProductSelected,
        isBooked,
        isTravellersDataFilled
    ]);
    useEffect(() => {
        stepsRef.current = steps;
    }, [steps]);
    const getFirstAvailableStep = useCallback(() => {
        if (order.status !== OrderStatus.New) {
            return steps.find(step => step.type === StepsEnum.Order);
        }
        return steps.find(step => !step.hidden);
    }, [order.status, steps]);
    const currentStep = useMemo(() => {
        return steps.find(step => location.pathname === `${baseUrl}/${step.url}` ||
            location.pathname === `${baseUrl}/${step.url}${tinkoffCallbackUrlSuffix}`);
    }, [steps, location.pathname]);
    const isSystemPage = SYSTEM_PAGES.some(path => location.pathname.includes(path));
    if ((!currentStep || currentStep.hidden) && !isSystemPage) {
        const currentStepIndex = steps.findIndex(step => location.pathname === `${baseUrl}/${step.url}`), nextAvailableStep = currentStepIndex === -1
            ? getFirstAvailableStep()
            : steps.find((step, index) => !step.hidden && index > currentStepIndex);
        setTimeout(() => {
            var _a, _b;
            const step = (_a = nextAvailableStep === null || nextAvailableStep === void 0 ? void 0 : nextAvailableStep.url) !== null && _a !== void 0 ? _a : (_b = getFirstAvailableStep()) === null || _b === void 0 ? void 0 : _b.url;
            let bookingURL = `/booking/${order.id}/${step}`;
            if (step === 'order') {
                bookingURL = getUrlWithServicesParams(bookingURL, location);
            }
            window.location.hash = bookingURL;
        });
    }
    const nextStep = useCallback(() => {
        const currentPath = window.location.hash;
        const currentStepIndex = stepsRef.current.findIndex(step => currentPath.search(step.url) >= 0), nextStep = stepsRef.current.find((step, index) => !step.hidden && index > currentStepIndex);
        if (nextStep) {
            window.location.hash = `/booking/${order.id}/${nextStep.url}`;
        }
    }, [window, steps, stepsRef]);
    const prevStep = useCallback(() => {
        const currentPath = window.location.hash;
        const currentStepIndex = steps.findIndex(step => currentPath.search(step.url) >= 0);
        for (let i = currentStepIndex - 1; i >= 0; i--) {
            if (!steps[i].hidden) {
                window.location.hash = `/booking/${order.id}/${steps[i].url}`;
                return;
            }
        }
    }, [window, steps]);
    const setStep = useCallback((stepType) => {
        const nextStep = steps.findIndex(step => step.type === stepType);
        window.location.hash = `/booking/${order.id}/${steps[nextStep].url}`;
    }, []);
    return {
        steps: steps.filter(step => !step.hidden),
        currentStep,
        nextStep,
        prevStep,
        setStep
    };
};
export const useUpdateTravellerServices = () => {
    const dispatch = useDispatch();
    const order = useSelector(getOrder);
    const hasRequestedOrderServices = useSelector(getHasRequestedOrderServices);
    const [updateOrder] = useUpdateOrderMutation();
    const onUpdateOrder = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield updateOrder({ variables: { id: order.id } });
        if (data === null || data === void 0 ? void 0 : data.UpdateOrder) {
            dispatch(fillOrder(data.UpdateOrder));
        }
    }), [order === null || order === void 0 ? void 0 : order.id, updateOrder]);
    useConditionalPolling(onUpdateOrder, hasRequestedOrderServices, {
        interval: 5000,
        progressiveIntervalPerQuery: true
    });
};
export const useQueryControlledServicesModal = () => {
    const history = useHistory();
    const location = useLocation();
    const existGdsServicesOrInsurances = useSelector(getExistGdsServicesOrInsurances);
    useEffect(() => {
        const { openServiceType, serviceSubType, insuranceCode } = getQueryServicesParams(location);
        if (openServiceType && existGdsServicesOrInsurances) {
            const event = new CustomEvent(WEBSKY_SERVICE_OPEN_EVENT, {
                detail: {
                    service: {
                        type: openServiceType,
                        subType: serviceSubType,
                        insuranceCode
                    },
                    meta: {
                        custom_ecommerce_name: getQueryPopupGoal(openServiceType, serviceSubType, insuranceCode)
                    },
                    callback: () => __awaiter(void 0, void 0, void 0, function* () { })
                }
            });
            history.replace({ search: '' });
            setTimeout(() => document.dispatchEvent(event));
        }
    }, [existGdsServicesOrInsurances, location]);
};
export const useServiceEventToggleable = ({ services, serviceType, serviceSubType, onOpen }) => {
    const onEventHandler = (event) => {
        const { type, subType } = event.detail.service;
        if (serviceType !== type || !services.length) {
            return;
        }
        if (serviceSubType) {
            if (serviceSubType !== subType) {
                return;
            }
            const serviceSubTypeSet = new Set();
            services.forEach(service => {
                if (service === null || service === void 0 ? void 0 : service.baggageType) {
                    // BaggageService
                    const baggageService = service;
                    serviceSubTypeSet.add(baggageService.baggageType);
                }
                else if (service === null || service === void 0 ? void 0 : service.subType) {
                    // Vip service
                    const vipService = service;
                    serviceSubTypeSet.add(vipService.subType);
                }
            });
            if (!serviceSubTypeSet.size || !serviceSubTypeSet.has(subType)) {
                return;
            }
        }
        onOpen();
    };
    useEffect(() => {
        document.addEventListener(WEBSKY_SERVICE_OPEN_EVENT, onEventHandler);
        return () => document.removeEventListener(WEBSKY_SERVICE_OPEN_EVENT, onEventHandler);
    }, []);
};
