import { __awaiter } from "tslib";
import { useReadCommunicationMessageQuery } from '@websky/graphql';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NOTIFICATIONS_PER_PAGE } from './constants';
import { CategoryContext } from './components/CommunicationBase';
import { useDispatch } from 'react-redux';
import { decreaseUnreadCountByCategory, increaseUnreadCountByCategory } from './store/actions';
import { ApolloError } from 'apollo-boost';
import { useLocation } from 'react-router-dom';
export const useInfinityScrollPagination = (refetch, loading) => {
    const [hasMore, setHasMore] = useState(true);
    const [items, setItems] = useState([]);
    const [pageLoaded, setPageLoaded] = useState(0);
    const [total, setTotal] = useState(0);
    const resetAll = () => {
        setPageLoaded(0);
        setItems([]);
        setHasMore(true);
    };
    const loadMore = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (loading || !hasMore) {
            return;
        }
        const res = yield refetch({ offset: items.length, count: NOTIFICATIONS_PER_PAGE });
        setTotal(res.total);
        if (res.items.length === 0 || res.total === res.items.length) {
            setHasMore(false);
        }
        if (res.items.length) {
            setItems([...items, ...res.items]);
        }
        setPageLoaded(pageLoaded + 1);
    }), [items]);
    return {
        items,
        hasMore,
        total,
        loadMore,
        pageLoaded,
        resetAll
    };
};
export const useCommunicationList = (sectionType, refetchRequest, loading) => {
    const dispatch = useDispatch();
    const { appliedReadAll } = useContext(CategoryContext);
    const { refetch: readMessage } = useReadCommunicationMessageQuery({ skip: true });
    const [errorMessage, setErrorMessage] = useState('');
    const [readIds, setReadIds] = useState({});
    const refetchWrapper = (variables) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            return yield refetchRequest(variables);
        }
        catch (e) {
            onError(e);
            return { total: 0, items: [] };
        }
    });
    const onError = (e) => {
        var _a, _b;
        let message = 'Unknown error';
        if (e instanceof ApolloError && ((_b = (_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message)) {
            message = e.graphQLErrors[0].message;
        }
        else if (e instanceof Error && e.message) {
            message = e.message;
        }
        setErrorMessage(message);
    };
    const { loadMore, hasMore, items, pageLoaded, total } = useInfinityScrollPagination(refetchWrapper, loading);
    const markAsRead = (messageId) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(decreaseUnreadCountByCategory(sectionType));
        setReadIds(Object.assign(Object.assign({}, readIds), { [messageId]: true }));
        const { data } = yield readMessage({
            messageId: messageId,
            sectionId: sectionType
        });
        if (!data.PaximizerCommunicationReadMessage) {
            setReadIds(Object.assign(Object.assign({}, readIds), { [messageId]: false }));
            dispatch(increaseUnreadCountByCategory(sectionType));
        }
    });
    const communicationsList = useMemo(() => {
        return items.map(item => {
            return Object.assign(Object.assign({}, item), { read: appliedReadAll ? true : item.read });
        });
    }, [items, appliedReadAll]);
    return {
        readIds,
        markAsRead,
        items: communicationsList,
        loadMore,
        errorMessage,
        pagination: {
            pageLoaded,
            hasMore,
            total
        }
    };
};
export const useCommunicationDetail = (sectionType, data) => {
    const dispatch = useDispatch();
    const { refetch: readMessage } = useReadCommunicationMessageQuery({
        variables: {
            messageId: data === null || data === void 0 ? void 0 : data.id,
            sectionId: sectionType
        },
        skip: true
    });
    useEffect(() => {
        if (data && !data.read) {
            setReadMessageRequest();
        }
    }, [data === null || data === void 0 ? void 0 : data.read]);
    const setReadMessageRequest = () => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield readMessage();
        if (res) {
            dispatch(decreaseUnreadCountByCategory(sectionType));
        }
    });
};
export function useQueryParams() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}
