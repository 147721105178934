import i18n from 'i18next';
import * as React from 'react';
import memes from '../memes';
import { getDateWithoutTimezone } from '../Engine/components/FastSearch/utils';
import { AviaPassengerType, FareFeaturePaymentType, FlightClass, FlightSegmentStatus } from '@websky/graphql';
/**
 * Sum up multiple duration objects.
 */
export const sumDuration = (...durations) => {
    const result = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    };
    durations.forEach(duration => {
        if (duration) {
            result.days += duration.days ? parseInt(duration.days.toString()) : 0;
            result.hours += duration.hours ? parseInt(duration.hours.toString()) : 0;
            result.minutes += duration.minutes ? parseInt(duration.minutes.toString()) : 0;
            result.seconds += duration.seconds ? parseInt(duration.seconds.toString()) : 0;
        }
    });
    // Fix seconds
    if (result.seconds >= 60) {
        result.minutes += Math.floor(result.seconds / 60);
        result.seconds = result.seconds % 60;
    }
    // Fix minutes
    if (result.minutes >= 60) {
        result.hours += Math.floor(result.minutes / 60);
        result.minutes = result.minutes % 60;
    }
    // Fix hours
    if (result.hours >= 24) {
        result.days += Math.floor(result.hours / 24);
        result.hours = result.hours % 24;
    }
    return result;
};
/**
 * Get info string for given duration object.
 *
 * ex: '1d 12h 20m'
 */
export const getDurationString = (duration) => {
    const result = [];
    if (duration.days) {
        result.push(duration.days.toString() + i18n.t('d'));
    }
    if (duration.hours) {
        result.push(duration.hours.toString() + i18n.t('h'));
    }
    if (duration.minutes) {
        result.push(duration.minutes.toString() + i18n.t('m'));
    }
    if (duration.seconds) {
        result.push(duration.seconds.toString() + i18n.t('s'));
    }
    return result.join(' ');
};
/**
 * Convert duration object into the number of seconds.
 */
export const getDurationInSeconds = (inputDuration) => {
    // Add missing duration elements (seconds, minutes, etc).
    const duration = sumDuration(inputDuration);
    return duration.seconds + duration.minutes * 60 + duration.hours * 60 * 60 + duration.days * 24 * 60 * 60;
};
/**
 * Get total flight duration.
 */
export const getFlightDuration = (segments, withoutTransfers = false) => {
    let duration = {};
    segments
        .filter(({ segment }) => segment.status !== FlightSegmentStatus.Canceled)
        .forEach(({ segment, transferDuration }) => {
        if (!withoutTransfers) {
            duration = sumDuration(duration, transferDuration);
        }
        duration = sumDuration(duration, segment.duration);
    });
    return duration;
};
/**
 * Get total flight transfers duration.
 */
export const getFlightTransfersDuration = (flight) => {
    let duration = {};
    flight.segments.forEach(segment => {
        duration = sumDuration(duration, segment.transferDuration);
    });
    return duration;
};
/**
 * Break up money string into pieces grouped by thousands.
 *
 * '3500' -> ['3', '500']
 */
export const splitNumber = (money) => {
    let result = [];
    if (money.length < 4) {
        result.push(money);
    }
    else {
        const firstPart = money.slice(0, -3);
        if (firstPart.length > 3) {
            result = splitNumber(firstPart);
        }
        else {
            result.push(firstPart);
        }
        result.push(money.slice(-3));
    }
    return result;
};
export const splitMoney = (money, fractionDigits, min = 10000) => {
    const stringMoney = ((money ^ 0) === money ? money : money.toFixed(fractionDigits || 2)).toString(10);
    // Do not process values under 10k.
    if (money < min) {
        return [stringMoney];
    }
    const parts = stringMoney.split('.');
    let result;
    if (parts.length) {
        if (parts.length === 1) {
            result = splitNumber(stringMoney);
        }
        else {
            const integerParts = splitNumber(parts[0]);
            const lastElement = integerParts.pop();
            // In case of decimal values like: '1123.18'.
            result = [...integerParts, `${lastElement}.${parts[1]}`];
        }
    }
    return result;
};
/**
 * Extract list of min prices on given groups.
 */
export const getMinPrices = (prices, singleFare, selectedFares = [], findOriginalPrice, preselectedFlightClass) => {
    var _a, _b, _c, _d;
    let result;
    let filteredPrices = [...prices];
    let minEconomyPrice = null;
    let economyFareFamily = null;
    let minBusinessPrice = null;
    let businessFareFamily = null;
    let economyPriceInMiles = null;
    let businessPriceInMiles = null;
    let hasSubsidyFare = false;
    const lastSelectedFare = (_a = selectedFares === null || selectedFares === void 0 ? void 0 : selectedFares[selectedFares.length - 1]) !== null && _a !== void 0 ? _a : null;
    const lastSelectedFareFlightIds = (_b = lastSelectedFare === null || lastSelectedFare === void 0 ? void 0 : lastSelectedFare.prices.map(price => price.flight.id)) !== null && _b !== void 0 ? _b : [];
    const lastSelectedFlightId = (_d = (_c = lastSelectedFare === null || lastSelectedFare === void 0 ? void 0 : lastSelectedFare.flightInfo) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null;
    if (preselectedFlightClass) {
        filteredPrices = filteredPrices.filter(price => price.fareFamily.category === preselectedFlightClass);
    }
    if (lastSelectedFare) {
        filteredPrices = filteredPrices.filter(price => { var _a; return (_a = price.prices) === null || _a === void 0 ? void 0 : _a.some(p => lastSelectedFareFlightIds.includes(p.flight.id)); });
    }
    if (lastSelectedFlightId && !filteredPrices.length) {
        filteredPrices = filteredPrices.filter(price => { var _a; return (_a = price.prices) === null || _a === void 0 ? void 0 : _a.some(p => p.flight.id === lastSelectedFlightId); });
    }
    if (filteredPrices.length) {
        filteredPrices === null || filteredPrices === void 0 ? void 0 : filteredPrices.forEach(price => {
            var _a, _b, _c;
            if (price.fareFamily && ((_a = price.prices) === null || _a === void 0 ? void 0 : _a.length)) {
                let actualPrice = findOriginalPrice ? price.prices[0].originalPrice : price.prices[0].price;
                if (lastSelectedFareFlightIds.length) {
                    const priceBySelectedFareGroup = price.prices.find(price => lastSelectedFareFlightIds.includes(price.flight.id));
                    if (priceBySelectedFareGroup) {
                        if (findOriginalPrice) {
                            actualPrice = priceBySelectedFareGroup.originalPrice;
                        }
                        else {
                            actualPrice = priceBySelectedFareGroup.price;
                        }
                    }
                }
                if (price.fareFamily && price.fareFamily.category === FlightClass.Business && !singleFare) {
                    if (!minBusinessPrice || minBusinessPrice.amount > (actualPrice === null || actualPrice === void 0 ? void 0 : actualPrice.amount)) {
                        minBusinessPrice = actualPrice;
                        businessFareFamily = price.fareFamily;
                        if ((_b = price.prices[0]) === null || _b === void 0 ? void 0 : _b.priceInMiles) {
                            businessPriceInMiles = price.prices[0].priceInMiles;
                        }
                    }
                }
                else if (!minEconomyPrice || minEconomyPrice.amount > (actualPrice === null || actualPrice === void 0 ? void 0 : actualPrice.amount)) {
                    minEconomyPrice = actualPrice;
                    economyFareFamily = price.fareFamily;
                    if ((_c = price.prices[0]) === null || _c === void 0 ? void 0 : _c.priceInMiles) {
                        economyPriceInMiles = price.prices[0].priceInMiles;
                    }
                    if (!findOriginalPrice && !hasSubsidyFare && price.prices.some(price => { var _a; return (_a = price.flight) === null || _a === void 0 ? void 0 : _a.subsidized; })) {
                        hasSubsidyFare = true;
                    }
                }
            }
        });
    }
    result = [
        {
            type: FlightClass.Economy,
            price: minEconomyPrice,
            priceInMiles: economyPriceInMiles,
            fareFamily: economyFareFamily,
            hasSubsidyFare
        }
    ];
    if (!singleFare) {
        result = [
            ...result,
            {
                type: FlightClass.Business,
                price: minBusinessPrice,
                priceInMiles: businessPriceInMiles,
                fareFamily: businessFareFamily,
                hasSubsidyFare
            }
        ];
    }
    return result;
};
export const getFlightMinPrice = (legs) => {
    let minPrice = null;
    legs.forEach(leg => {
        const legMinPrices = getMinPrices(leg.pricesForFareGroups);
        legMinPrices.forEach(legMinPrice => {
            if (!minPrice || (legMinPrice.price && legMinPrice.price.amount < minPrice.amount)) {
                minPrice = legMinPrice.price;
            }
        });
    });
    return minPrice;
};
/**
 * Extract unique list of airlines on given segments.
 */
export const getAirlinesList = (segments) => {
    const set = new Set();
    const result = [];
    (segments instanceof Array ? segments : [segments]).forEach(({ segment }) => {
        if (!set.has(segment.marketingAirline.iata)) {
            result.push(segment.marketingAirline);
            set.add(segment.marketingAirline.iata);
        }
        if (!set.has(segment.operatingAirline.iata)) {
            result.push(segment.operatingAirline);
            set.add(segment.operatingAirline.iata);
        }
    });
    return result;
};
/**
 * 1. Remove fare groups with the wrong service class
 * 2. Remove fare groups without any prices
 * 3. Filter each fare group prices according to previously selected fares (array intersection by flight ID).
 *
 * #fixme: test me please
 */
export const filterFareGroups = memes((fareGroups, selectedFares, serviceClass, filterPrices) => fareGroups
    .filter(group => group.fareFamily && group.fareFamily.category === serviceClass && (!filterPrices || group.prices))
    .map(group => {
    if (group.prices && selectedFares.length) {
        const lastSelectedFare = selectedFares[selectedFares.length - 1];
        const selectedMap = {};
        lastSelectedFare.prices.forEach(price => (selectedMap[price.flight.id] = price.flight.id));
        return Object.assign(Object.assign({}, group), { prices: group.prices.filter(price => selectedMap.hasOwnProperty(price.flight.id)) });
    }
    else {
        return group;
    }
}));
/**
 * Get info string about transfers on given segments.
 *
 * ex: '1h 15m MOW'
 */
export const getTransfersInfo = (flight) => {
    let result = '';
    if (flight.segments.length === 2) {
        result = `${getDurationString(getFlightTransfersDuration(flight))} ${flight.segments[0].segment.arrival.airport.iata}`;
    }
    else {
        result = flight.segments
            .slice(0, flight.segments.length - 1)
            .map(({ segment }) => segment.arrival.airport.iata)
            .join(', ');
    }
    return result;
};
export const getUniqueValidOptions = (options, freeOnly = false, uniqueOnly = false, showUnavailable = false) => {
    const map = {};
    return options.filter(option => {
        if (freeOnly && option.availability !== FareFeaturePaymentType.Free) {
            return false;
        }
        if (option.availability === FareFeaturePaymentType.NotAvailable && !showUnavailable) {
            return false;
        }
        else if (uniqueOnly && map.hasOwnProperty(option.type)) {
            return false;
        }
        else {
            map[option.type] = true;
            return true;
        }
    });
};
// #fixme: rename
export const convertSearchParameters = (inputParameters, promoCode) => {
    return {
        promotionCode: promoCode,
        currency: inputParameters.currency,
        passengers: inputParameters.passengers.map(passenger => ({
            passengerType: passenger.passengerType,
            count: passenger.count,
            extendedPassengerType: passenger.extendedPassengerType
        })),
        segments: inputParameters.segments.map(segment => ({
            date: segment.date,
            departure: {
                iata: segment.departure.iata
            },
            arrival: {
                iata: segment.arrival.iata
            }
        })),
        ffpMode: inputParameters.ffpMode
    };
};
export const createLegs = ({ segments }) => {
    return segments.map((segment, index) => ({
        id: index,
        departure: segment.departure,
        arrival: segment.arrival,
        date: getDateWithoutTimezone(new Date(segment.date)),
        isReturnLeg: segments.length === 2 &&
            index === 1 &&
            segments[0].arrival.iata === segment.departure.iata &&
            segments[0].departure.iata === segment.arrival.iata
    }));
};
export const createLegsFromFlightDirections = (directions) => {
    return directions.map((direction, key) => {
        const { departure } = direction.legs[0].segments[0].segment, { arrival } = direction.legs[0].segments[direction.legs[0].segments.length - 1].segment;
        return {
            id: key,
            departure: departure.airport,
            arrival: arrival.airport,
            date: getDateWithoutTimezone(new Date(direction.legs[0].segments[0].segment.departure.date)),
            isReturnLeg: directions.length === 2 &&
                key === 1 &&
                directions[0].legs[0].segments[directions[0].legs[0].segments.length - 1].segment.arrival.airport
                    .iata === departure.airport.iata &&
                directions[0].legs[0].segments[0].segment.departure.airport.iata === arrival.airport.iata
        };
    });
};
export const SearchPassengersContext = React.createContext([
    {
        count: 1,
        passengerType: AviaPassengerType.ADT,
        extendedPassengerType: null
    }
]);
