import * as React from 'react';
import Sortings from '../../../../../../Results/components/Results/FlightsList/Sortings/Sortings';
import { ThemeContext, ThemeProvider, useTheme } from '../../../../../../theme';
import { mergeCSS } from '../../../../../../utils';
const Sort = props => {
    const resultsTheme = React.useContext(ThemeContext);
    const theme = useTheme('Exchange').Sort;
    const exchangeTheme = {
        Results: {
            Sortings: Object.assign(Object.assign({}, resultsTheme.Results.Sortings), { title: theme.title, sortBy: theme.sortBy, sortings: theme.sortings, currentSorting: theme.currentSorting })
        }
    };
    const mergedTheme = mergeCSS({
        Results: {
            Sortings: Object.assign({}, resultsTheme.Results.Sortings)
        }
    }, exchangeTheme);
    return (React.createElement(ThemeProvider, { value: mergedTheme },
        React.createElement(Sortings, Object.assign({}, props))));
};
export default Sort;
