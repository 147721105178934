import i18n from 'i18next';
import { isAfter, parse } from 'date-fns';
import { TravellerFieldEnum, DocTypes } from '@websky/graphql';
import { concatPassengerNames, getPassengerTypeLabel } from '../utils';
import { hideText } from '../Passenger/utils';
export const collectPassengerInfo = (traveller) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    let travellerName;
    const travellerMainInfo = [];
    const travellerAdditionalInfo = [];
    const travellerLoyaltyInfo = [];
    const travellerValues = new Map(traveller.values.map(params => [params.type, params]));
    if (travellerValues.has(TravellerFieldEnum.LastName)) {
        travellerName = concatPassengerNames((_a = travellerValues.get(TravellerFieldEnum.LastName)) === null || _a === void 0 ? void 0 : _a.value, (_b = travellerValues.get(TravellerFieldEnum.FirstName)) === null || _b === void 0 ? void 0 : _b.value, (_c = travellerValues.get(TravellerFieldEnum.MiddleName)) === null || _c === void 0 ? void 0 : _c.value);
    }
    else {
        travellerName = getPassengerTypeLabel(traveller.type, traveller.supplierTravellerType);
    }
    if (travellerValues.has(TravellerFieldEnum.Gender)) {
        const genderValue = travellerValues.get(TravellerFieldEnum.Gender).value;
        const genderName = (_f = (_e = (_d = travellerValues
            .get(TravellerFieldEnum.Gender)) === null || _d === void 0 ? void 0 : _d.validationRules) === null || _e === void 0 ? void 0 : _e[0].options.find(({ value }) => value === genderValue)) === null || _f === void 0 ? void 0 : _f.label;
        if (genderName) {
            travellerMainInfo.push(i18n.t(`OrderCard:Gender_${genderName}`));
        }
    }
    if (travellerValues.has(TravellerFieldEnum.BirthDate)) {
        travellerMainInfo.push(travellerValues.get(TravellerFieldEnum.BirthDate).value);
    }
    const nationalityValue = travellerValues.has(TravellerFieldEnum.Nationality)
        ? travellerValues.get(TravellerFieldEnum.Nationality).value
        : null;
    if (nationalityValue) {
        const nationalityName = (_j = (_h = (_g = travellerValues
            .get(TravellerFieldEnum.Nationality)) === null || _g === void 0 ? void 0 : _g.validationRules) === null || _h === void 0 ? void 0 : _h[0].options.find(({ value }) => value === nationalityValue)) === null || _j === void 0 ? void 0 : _j.label;
        if (nationalityName) {
            travellerMainInfo.push(nationalityName);
        }
    }
    if (travellerValues.has(TravellerFieldEnum.DocType)) {
        const docType = travellerValues.get(TravellerFieldEnum.DocType).value;
        const docNumber = (_k = travellerValues.get(TravellerFieldEnum.DocNumber)) === null || _k === void 0 ? void 0 : _k.value;
        const isPassport = docType === DocTypes.Passport;
        const isBirthRegDocument = docType === DocTypes.BirthRegDocument;
        const isHideDocExpiryDate = isPassport || isBirthRegDocument;
        if (docNumber) {
            travellerAdditionalInfo.push(`${i18n.t(`Passenger:${docType}`)}: ${hideText(docNumber)}`);
        }
        if (!isHideDocExpiryDate && ((_l = travellerValues.get(TravellerFieldEnum.DocExpiryDate)) === null || _l === void 0 ? void 0 : _l.value)) {
            const expireDate = travellerValues.get(TravellerFieldEnum.DocExpiryDate).value;
            travellerAdditionalInfo.push(`${i18n.t('OrderCard:Expired date')}: ${expireDate}`);
        }
    }
    if (travellerValues.has(TravellerFieldEnum.LoyaltyNumber) &&
        ((_m = travellerValues.get(TravellerFieldEnum.LoyaltyNumber)) === null || _m === void 0 ? void 0 : _m.value)) {
        const loyaltyNumber = travellerValues.get(TravellerFieldEnum.LoyaltyNumber).value;
        travellerLoyaltyInfo.push(`${i18n.t('Passenger:loyaltyName')}: ${loyaltyNumber}`);
    }
    return {
        travellerName,
        mainInfo: travellerMainInfo,
        additionalInfo: travellerAdditionalInfo,
        loyaltyInfo: travellerLoyaltyInfo
    };
};
// ключ - кто сопровождает, значение - кого сопровождают
export const getAdultAccompaniedMap = (travellers) => {
    const passengerAccompaniedMap = new Map();
    travellers.forEach(traveller => {
        var _a, _b;
        if ((_a = traveller.linkedTraveller) === null || _a === void 0 ? void 0 : _a.id) {
            passengerAccompaniedMap.set(traveller.linkedTraveller.id, [
                ...((_b = passengerAccompaniedMap.get(traveller.linkedTraveller.id)) !== null && _b !== void 0 ? _b : []),
                traveller
            ]);
        }
    });
    return passengerAccompaniedMap;
};
export const isTravellerOlderThanMinDate = (traveller, relinkingPassenger) => {
    var _a, _b, _c, _d;
    const minBirthDate = (_c = (_b = (_a = relinkingPassenger.values.find(value => value.type === TravellerFieldEnum.LinkedTraveller)) === null || _a === void 0 ? void 0 : _a.validationRules) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.minDate;
    const travellerBirthDate = (_d = traveller.values.find(value => value.type === TravellerFieldEnum.BirthDate)) === null || _d === void 0 ? void 0 : _d.value;
    if (!minBirthDate || !travellerBirthDate) {
        return true;
    }
    return !isAfter(parse(travellerBirthDate, 'dd.MM.yyyy', new Date()), new Date(new Date(minBirthDate).setHours(0, 0, 0, 0)));
};
