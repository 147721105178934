import { initI18n } from '../../../utils';
import * as React from 'react';
import { useGraphQLClient } from '../../../graphql-client';
import { ApolloProvider } from '@apollo/react-hooks';
import BaseComponent from './BaseComponent/BaseComponent';
initI18n('LoginPage');
export const Component = props => {
    const client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(BaseComponent, Object.assign({}, props))));
};
export default Component;
