import * as React from 'react';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTheme } from '../theme';
import { minusIcon, plusIcon } from './Icons';
const Counter = props => {
    var _a, _b, _c, _d;
    const { Counter: theme } = useTheme('Counter');
    const [antiBlinkingValue, setAntiBlinkingValue] = useState(0);
    const canInc = React.useMemo(() => {
        return (props.maxValue === undefined || props.value < props.maxValue) && !props.readonly;
    }, [props.value, props.maxValue, props.readonly]);
    const canDec = React.useMemo(() => {
        return (props.value > 0 && props.minValue === undefined) || props.value > props.minValue;
    }, [props.value, props.minValue]);
    useEffect(() => {
        if (typeof props.value !== 'undefined' && antiBlinkingValue !== props.value) {
            setAntiBlinkingValue(props.value);
        }
    }, [props.value]);
    return (React.createElement("div", { className: cn(theme.counter, (_a = props.classes) === null || _a === void 0 ? void 0 : _a.root) },
        React.createElement("button", { type: "button", className: cn(theme.button, (_b = props.classes) === null || _b === void 0 ? void 0 : _b.minus, {
                [theme.button_disabled]: !canDec || props.disabled,
                [theme.button_readonly]: !canDec &&
                    (props.readonly || (props.showOnlyIncreaseButtonWhenValueIsNull && props.value === 0))
            }), onClick: canDec ? props.onDecrease : () => { } }, minusIcon),
        React.createElement("div", { className: cn(theme.value, props.valueClassName, (_c = props.classes) === null || _c === void 0 ? void 0 : _c.value, {
                [theme.value_zero]: props.value === 0,
                [theme.value_hidden]: props.showOnlyIncreaseButtonWhenValueIsNull && props.value === 0
            }) }, antiBlinkingValue),
        React.createElement("button", { type: "button", className: cn(theme.button, (_d = props.classes) === null || _d === void 0 ? void 0 : _d.plus, {
                [theme.button_disabled]: !canInc || props.disabled,
                [theme.button_readonly]: props.readonly
            }), onClick: canInc ? props.onIncrease : () => { } }, plusIcon)));
};
export default Counter;
