import { z } from 'zod';
import { OauthServiceType, BaggageType } from '@websky/graphql';
import { Locale, StepsEnum } from '../types';
import { BooleanOptional, NumberOptional, StringOptional } from './schemaTypes';
export const UpsaleStrategySchema = z
    .enum(['checkout-insurance-step', 'checkout-order-service', 'checkout-before-payment'])
    .nullish();
export const LoyaltyInfoLinkSchema = z.object({
    url: z.string(),
    title: z.string()
});
const LoyaltyInfoSchema = z.object({
    redirectTo: StringOptional,
    links: z.array(LoyaltyInfoLinkSchema).optional()
});
const SocialAuthMethodSchema = z.object({
    service: z.nativeEnum(OauthServiceType),
    isAvailable: z.boolean()
});
export const CompanyInfoSchema = z.object({
    iataCode: z.string(),
    privacyPolicy: StringOptional,
    facebook: StringOptional,
    instagram: StringOptional,
    vk: StringOptional,
    twitter: StringOptional,
    webskySearchOrder: StringOptional,
    exareRulesURL: StringOptional,
    exarePrivacyPolicyURL: StringOptional,
    legal: StringOptional,
    meal: StringOptional,
    seat: StringOptional,
    baggage: StringOptional,
    insurance: StringOptional,
    loyaltyProgramName: StringOptional,
    loyaltyProgramRegistrationUrl: StringOptional,
    loyaltyProgramAccountUrl: StringOptional,
    loyalty: LoyaltyInfoSchema.optional(),
    multiInsurance: BooleanOptional,
    specialDocumentForInsurance: BooleanOptional,
    passengerConductRules: StringOptional,
    baggageAnimalsURL: StringOptional,
    subsidyRulesUrl: StringOptional,
    subsidyFaresConditionURL: StringOptional,
    transferM2ConditionURL: StringOptional,
    farelockURL: StringOptional,
    transferBaggageUrl: StringOptional,
    hotelsURL: StringOptional
});
const ServicesAvailableSchema = z.object({
    animal: BooleanOptional
});
const StepsAvailableSchema = z.record(z.nativeEnum(StepsEnum), BooleanOptional);
const SeatMapConfigSchema = z.object({
    splitByCabin: BooleanOptional,
    showRecommendedSeats: BooleanOptional
});
const UpsaleServicesItemSchema = z.object({
    rfisc: z.string(),
    description: StringOptional,
    icon: z.any().optional(),
    title: StringOptional,
    extraTitle: StringOptional,
    extraTitleShowDates: z.array(z.string()).optional()
});
const UpsaleServicesConfigSchema = z.record(z.array(UpsaleServicesItemSchema)).optional();
export const ReserveEnginesSchema = z
    .object({
    redirectToWebsky1: BooleanOptional,
    checkin: StringOptional,
    avia: StringOptional,
    siteUrl: StringOptional
})
    .superRefine((data, ctx) => {
    var _a, _b;
    if (data.redirectToWebsky1) {
        // Check if neither `checkin` nor `avia` is provided
        const isCheckinEmpty = !((_a = data.checkin) === null || _a === void 0 ? void 0 : _a.trim());
        const isAviaEmpty = !((_b = data.avia) === null || _b === void 0 ? void 0 : _b.trim());
        if (isCheckinEmpty && isAviaEmpty) {
            ctx.addIssue({
                code: 'custom',
                path: ['checkin'],
                message: 'Either Checkin or Avia must be provided when redirectToWebsky1 is true.'
            });
        }
    }
});
const baggageByTypeLimitSchema = z.object({
    baggageType: z.nativeEnum(BaggageType),
    maxBaggagePerPassenger: z.number().int().nonnegative()
});
const baggageLimitSchema = z.object({
    baggageByTypeLimit: z.array(baggageByTypeLimitSchema).optional(),
    maxBaggagePerPassenger: z.number().int().nonnegative().optional()
});
export const baggageConfigSchema = z.object({
    baggageLimit: baggageLimitSchema.optional()
});
const AdditionalResourcesSchema = z.object({
    svgs: z.array(z.string().url()).optional(),
    scripts: z.array(z.string().url()).optional(),
    extraScripts: z.array(z.string().url()).optional()
});
export const GlobalConfigSchema = z.object({
    socialAuthMethods: z.array(SocialAuthMethodSchema).optional(),
    authMethods: z
        .object({
        email: BooleanOptional,
        phone: BooleanOptional,
        ffp: BooleanOptional
    })
        .optional(),
    yandexMetrikaId: z.union([z.string(), z.array(z.string())]).optional(),
    varioqubId: StringOptional,
    findOrderURL: StringOptional,
    gtmId: StringOptional,
    showIsActualizationResultOk: z.boolean(),
    allowPromoCodes: BooleanOptional,
    showZeroCharges: BooleanOptional,
    maxBaggage: NumberOptional,
    dynamicBaggagePricing: BooleanOptional,
    maxBaggagePerPassenger: NumberOptional,
    baggage: baggageConfigSchema.optional(),
    seatMap: SeatMapConfigSchema.optional(),
    insurance: z
        .object({
        upsaleStrategy: z.array(UpsaleStrategySchema).nonempty(),
        allowImplicitConfirm: BooleanOptional,
        multiplePriorityInsurances: BooleanOptional,
        insurancePriorities: z.array(z.string()).optional()
    })
        .optional(),
    checkoutAdditionalService: z.any().optional(),
    locale: z.nativeEnum(Locale).optional(),
    apiUrl: StringOptional,
    page404Url: StringOptional,
    i18n: z.any().optional(),
    companyInfo: CompanyInfoSchema.optional(),
    FFPRegex: z.instanceof(RegExp).optional(),
    reserveEngines: ReserveEnginesSchema.optional(),
    regex: z
        .object({
        PNR_OR_TICKET_NUMBER_REGEX: z.instanceof(RegExp).optional(),
        TICKET_NUMBER_REGEX: z.instanceof(RegExp).optional(),
        ORDER_NUMBER: z.instanceof(RegExp).optional(),
        PASSENGER_LAST_NAME_LATIN_REGEX: z.instanceof(RegExp).optional(),
        PASSENGER_LAST_NAME_RU_REGEX: z.instanceof(RegExp).optional()
    })
        .optional(),
    servicesAvailable: ServicesAvailableSchema.optional(),
    stepsAvailable: StepsAvailableSchema.optional(),
    upsaleServices: UpsaleServicesConfigSchema.optional(),
    roundMoneyAmount: BooleanOptional,
    currentUserInGlobalScope: BooleanOptional,
    purchasePredictionThreshold: NumberOptional,
    purchasePredictionThresholdMax: NumberOptional,
    additionalResources: AdditionalResourcesSchema.optional()
});
