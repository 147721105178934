import { createSearchParameters } from '../utils';
import { getPromoCode } from './promoCode/selectors';
import { getPassengersConfig } from './passengersConfig/selectors';
import { Currency } from '../../enums';
const getPassengers = (state) => state.passengers;
const getSegments = (state) => state.segments;
export const getRedeemMiles = (state) => state === null || state === void 0 ? void 0 : state.redeemMiles;
export const getSearchParameters = (state) => {
    const searchParams = createSearchParameters(getPassengers(state), getSegments(state), getPassengersConfig(state), getPromoCode(state), getRedeemMiles(state));
    if (state.foreignCard) {
        searchParams.currency = Currency.KZT;
    }
    return searchParams;
};
