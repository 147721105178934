import cn from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { isMultiCity } from '../../../store/segments/selectors';
import Passengers from '../Passengers/Passengers';
import StartSearch from '../StartSearch/StartSearch';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import Currency from '../Currency/Currency';
import { useIsWithSelectableCurrencies } from '../hooks';
import { OverrideComponent } from '../../../../renderProps';
const AdvancedPassengers = React.lazy(() => import('../AdvancedPassengers/AdvancedPassengers'));
const Controls = React.memo(props => {
    const { Controls: css } = useTheme('SearchForm');
    const { SearchForm: { proMode } } = useConfig();
    const withSelectableCurrencies = useIsWithSelectableCurrencies();
    return (React.createElement("div", { className: cn(css.controls, {
            [css.controls_multiCity]: props.isMultiCity,
            [css.controls_advanced]: proMode,
            [css.controls_withCurrency]: withSelectableCurrencies
        }) },
        proMode ? (React.createElement(React.Suspense, { fallback: "Loading..." },
            React.createElement(AdvancedPassengers, null))) : (React.createElement(OverrideComponent, { componentProps: {}, component: { SearchFormPassengers: Passengers } })),
        withSelectableCurrencies && (React.createElement(OverrideComponent, { componentProps: {}, component: { SearchFormCurrency: Currency } })),
        React.createElement(StartSearch, { mode: props.isMultiCity ? 'text' : 'icon' })));
});
const mapStateToProps = (state) => {
    return {
        isMultiCity: isMultiCity(state)
    };
};
export default connect(mapStateToProps)(Controls);
