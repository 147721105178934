import * as React from 'react';
import CommonItem from '../CommonItem/CommonItem';
import { Date } from '../../../Icons';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '../../../BaseComponents';
import { useTheme } from '../../../theme';
const OrderChange = ({ exchangeAvailable, refundAvailable, onClick }) => {
    const { t } = useTranslation('OrderCard');
    const theme = useTheme('OrderCard').OrderChange;
    return (React.createElement(CommonItem, { icon: Date, text: t('Changed your plans?'), buttons: React.createElement(React.Fragment, null,
            exchangeAvailable && (React.createElement(BaseButton, { variant: "optionEdit", onClick: () => onClick('exchange'), className: theme.button, "data-exchange-button": true }, t('Exchange'))),
            refundAvailable && (React.createElement(BaseButton, { variant: "optionEdit", onClick: () => onClick('refund'), className: theme.button, "data-refund-button": true }, t('Refund')))) }));
};
export default OrderChange;
