const MAX_MB_SIZE = 5;
const AVAILABLE_FORMATS = ['.jpg', '.jpeg', '.pdf', '.doc', '.docx', '.png'];
export const useFileValidation = () => {
    const validate = (file) => {
        if (file.size / 1000000 > MAX_MB_SIZE) {
            return false;
        }
        return AVAILABLE_FORMATS.some(availableFormat => file.name.toLowerCase().includes(availableFormat));
    };
    return {
        validate
    };
};
export const useFileConverter = () => {
    const { validate } = useFileValidation();
    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        if (!validate(file)) {
            reject('Wrong format');
        }
        reader.onload = () => {
            const replacedResult = reader.result.replace(/^data.+base64,/gm, '');
            resolve({ name: file.name, content: replacedResult });
        };
        reader.onerror = () => reject('error while converting file to base64');
    });
    return {
        toBase64
    };
};
