import { AviaPassengerType, TravellerFieldEnum } from '@websky/graphql';
export const getTravellerInfo = (customer, travellers, travellerIds) => {
    const customerValues = new Map(customer.values.map(v => [v.type, v.value]));
    const customerPhone = customerValues.get(TravellerFieldEnum.Phone);
    const customerEmail = customerValues.get(TravellerFieldEnum.Email);
    if ((customerPhone === null || customerPhone === void 0 ? void 0 : customerPhone.length) || (customerEmail === null || customerEmail === void 0 ? void 0 : customerEmail.length)) {
        const travellerInfo = {
            name: '',
            phone: customerPhone,
            email: customerEmail
        };
        const firstTraveller = getFilteredTravellers(travellers, travellerIds)[0];
        if (firstTraveller) {
            const firstTravellerValues = new Map(firstTraveller.values.map(v => [v.type, v.value]));
            travellerInfo.name = getTravellerName(firstTravellerValues.get(TravellerFieldEnum.FirstName), firstTravellerValues.get(TravellerFieldEnum.LastName));
        }
        return travellerInfo;
    }
    return getValidTravellerInfo(travellers, travellerIds);
};
function getFilteredTravellers(travellers, travellerIds) {
    return travellers.filter(t => travellerIds.includes(t.id) && t.type === AviaPassengerType.ADT);
}
/** return first traveller, which contain phone or email*/
function getValidTravellerInfo(travellers, travellerIds) {
    var _a;
    const returnValue = {
        name: '',
        phone: '',
        email: ''
    };
    const filledFields = [
        TravellerFieldEnum.FirstName,
        TravellerFieldEnum.LastName,
        TravellerFieldEnum.Phone,
        TravellerFieldEnum.Email
    ];
    const targetFields = [TravellerFieldEnum.Phone, TravellerFieldEnum.Email];
    const travellerFields = new Map();
    let travellerIsValid = false;
    for (const traveller of getFilteredTravellers(travellers, travellerIds)) {
        travellerFields.clear();
        for (const value of traveller.values) {
            if (filledFields.includes(value.type)) {
                travellerFields.set(value.type, value.value);
                if (targetFields.includes(value.type) && ((_a = value.value) === null || _a === void 0 ? void 0 : _a.length)) {
                    travellerIsValid = true;
                }
                if (travellerFields.size === filledFields.length) {
                    break;
                }
            }
        }
        if (travellerIsValid) {
            returnValue.name = getTravellerName(travellerFields.get(TravellerFieldEnum.FirstName), travellerFields.get(TravellerFieldEnum.LastName));
            returnValue.phone = travellerFields.get(TravellerFieldEnum.Phone);
            returnValue.email = travellerFields.get(TravellerFieldEnum.Email);
            break;
        }
    }
    return returnValue;
}
function getTravellerName(firstName, lastName) {
    return `${lastName} ${firstName}`;
}
