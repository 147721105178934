import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import WarningModal from '../../../WarningModal';
export const CommunicationPageWrapper = ({ pagination, type, errorMessage, children }) => {
    const { Communication: css } = useTheme('Communications');
    const { t } = useTranslation('Communications');
    if (errorMessage) {
        return (React.createElement(WarningModal, { isOpen: !!errorMessage, title: t('Oops'), content: t('Something went wrong'), errorMessage: errorMessage }));
    }
    else if (pagination.total === 0 && !pagination.hasMore) {
        return React.createElement("p", { className: css.empty }, t(`Empty_${type}`));
    }
    return React.createElement(React.Fragment, null, children);
};
