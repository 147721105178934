import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { HandLuggageExcess, LargeBaggageExcessIcon, LargeBaggageIcon, LargeCarryOnIcon, MiddleBaggageExcessIcon, MiddleBaggageIcon, SmallBaggageExcessIcon, SmallBaggageIcon, SmallCarryOnIcon } from '../../Icons';
import { useBaggageSize } from '../../hooks';
import { BaggageSize } from '../../types';
import { BaggageType } from '@websky/graphql';
import { useTheme } from '../../../../theme';
const defaultIcons = new Map([
    [
        BaggageType.CheckedBaggage,
        new Map([
            [BaggageSize.small, SmallBaggageIcon],
            [BaggageSize.middle, MiddleBaggageIcon],
            [BaggageSize.large, LargeBaggageIcon]
        ])
    ],
    [
        BaggageType.CarryOn,
        new Map([
            [BaggageSize.small, SmallCarryOnIcon],
            [BaggageSize.large, LargeCarryOnIcon]
        ])
    ],
    [
        BaggageType.HandLuggageExcess,
        new Map([
            [BaggageSize.small, SmallCarryOnIcon],
            [BaggageSize.large, HandLuggageExcess]
        ])
    ],
    [
        BaggageType.BaggageExcess,
        new Map([
            [BaggageSize.small, SmallBaggageExcessIcon],
            [BaggageSize.middle, MiddleBaggageExcessIcon],
            [BaggageSize.large, LargeBaggageExcessIcon]
        ])
    ]
]);
const BaggageIcon = ({ baggage, className, realSize, onlyIcon, iconsMap = defaultIcons }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { BaggageIcon: css } = useTheme('Baggage');
    const size = useBaggageSize(baggage);
    const isCarryOn = baggage.type === BaggageType.CarryOn, isHandLuggageExcess = baggage.type === BaggageType.HandLuggageExcess;
    return (React.createElement("div", { className: cn(css.root, className, {
            [css.checked_baggage]: baggage.type === BaggageType.CheckedBaggage,
            [css.carry_on]: isCarryOn,
            [css.hand_luggage_excess]: isHandLuggageExcess,
            [css.small]: size === BaggageSize.small,
            [css.middle]: size === BaggageSize.middle,
            [css.large]: size === BaggageSize.large,
            [css.real_size]: realSize,
            [css === null || css === void 0 ? void 0 : css[baggage.type]]: css === null || css === void 0 ? void 0 : css[baggage.type]
        }) },
        React.createElement("div", { className: css.wrapper }, (_b = (_a = iconsMap.get(baggage.type)) === null || _a === void 0 ? void 0 : _a.get(size)) !== null && _b !== void 0 ? _b : (_c = defaultIcons.get(baggage.type)) === null || _c === void 0 ? void 0 : _c.get(size),
            !onlyIcon && (React.createElement("div", { className: cn(css.size) },
                baggage.value.prefix && React.createElement("div", { className: css.prefix }, baggage.value.prefix),
                React.createElement("div", { className: css.value }, baggage.value.amount),
                React.createElement("div", { className: css.measurement }, t(baggage.value.measurement)))))));
};
export default BaggageIcon;
