import * as React from 'react';
import { useMemo } from 'react';
import { BaggageList } from '../../../BaggageList';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { convertBaggageOnBaggageItem } from '../../utils';
import { Included as IncludedIcon } from '../../../../Icons';
const Included = ({ className, segments, includedBaggage, sliderToShow }) => {
    const { IncludedBaggage: css } = useTheme('IncludedBaggage');
    const { t } = useTranslation('IncludedBaggage');
    const items = useMemo(() => {
        return includedBaggage.map(baggage => convertBaggageOnBaggageItem(baggage, segments));
    }, [includedBaggage, segments]);
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: css.header },
            React.createElement("h2", null,
                React.createElement("span", null, IncludedIcon),
                t('Included'))),
        React.createElement("div", { className: css.body },
            React.createElement(BaggageList, { items: items, slidesToShow: sliderToShow }))));
};
export default Included;
