import classnames from 'classnames';
import * as React from 'react';
import { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { addSegment, removeSegment } from '../../../store/segments/actions';
import { canAddSegment, canRemoveSegment, isMultiCity, getSegments } from '../../../store/segments/selectors';
import Segment from './Segment/Segment';
import Actions from './Actions/Actions';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import { useForm } from 'react-final-form';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
const Segments = memo(({ segments, isMultiCity, canAddSegment, canRemoveSegment, addSegment, removeSegment }) => {
    const { Segments: css } = useTheme('SearchForm');
    const { pauseValidation } = useForm();
    const showAddSegment = isMultiCity && canAddSegment;
    const showRemoveSegment = canRemoveSegment;
    const showActionsBlock = showAddSegment || showRemoveSegment;
    const alternative = useConfig().SearchForm.mobileFormType !== 'common';
    const proMode = useConfig().SearchForm.proMode;
    const removeLastSegment = useCallback(() => removeSegment(segments.length - 1), [segments]);
    const onAddSegmentHandler = () => {
        addSegment();
        pauseValidation();
    };
    return (React.createElement("div", { className: classnames(css.segments, {
            [css.segments_multiCity]: isMultiCity,
            [css.alternative]: alternative
        }) },
        React.createElement("div", null, segments.map((segment, index) => (React.createElement(Segment, { key: index, segmentId: index })))),
        !proMode && showActionsBlock && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Actions, { isAlternativeView: alternative, canRemoveSegment: canRemoveSegment, onAddSegment: onAddSegmentHandler, removeLastSegment: removeLastSegment, showAddSegment: showAddSegment, showRemoveSegment: showRemoveSegment })))));
}, (prevProps, nextProps) => prevProps.isMultiCity === nextProps.isMultiCity && prevProps.segments.length === nextProps.segments.length);
const mapStateToProps = (state) => {
    return {
        isMultiCity: isMultiCity(state),
        canAddSegment: canAddSegment(state),
        canRemoveSegment: canRemoveSegment(state),
        segments: getSegments(state)
    };
};
const mapDispatchToProps = {
    addSegment,
    removeSegment
};
export default connect(mapStateToProps, mapDispatchToProps)(Segments);
