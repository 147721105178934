import * as React from 'react';
import { TravellerFieldEnum } from '@websky/graphql';
import { TravellerDocumentFields, TravellerFields } from '../TravellerForm/types';
import { getActualPassengerValues, isDateFieldType } from './utils';
import { getUserValue } from '../utils';
import { formatInvalidPassengerDate } from '../Passenger/utils';
export const reducer = (state, action) => {
    if (action.type === 'fill') {
        return Object.assign(Object.assign({}, state), { passengers: state.passengers.map((passenger, index) => {
                if (index === action.payload.id) {
                    const values = Object.assign(Object.assign({}, passenger), { profileId: action.payload.profileId });
                    for (const key in values) {
                        let value = values[key];
                        const isDocField = [
                            TravellerDocumentFields.DocExpiryDate,
                            TravellerDocumentFields.DocNumber
                        ].includes(key);
                        if (action.payload.values.get(key) || isDocField) {
                            value = action.payload.values.get(key);
                        }
                        values[key] = value;
                    }
                    return values;
                }
                return passenger;
            }) });
    }
    else if (action.type === 'swap') {
        return Object.assign(Object.assign({}, state), { passengers: state.passengers.map((passenger, index) => {
                if (index === action.payload.id) {
                    return Object.assign(Object.assign({}, passenger), { [TravellerFields.FirstName]: passenger[TravellerFields.LastName], [TravellerFields.LastName]: passenger[TravellerFields.FirstName] });
                }
                return passenger;
            }) });
    }
    return state;
};
export const createInitialState = (passengers, customer) => {
    let customerValues = {
        email: '',
        phone: '',
        accompanyingPersonName: '',
        accompanyingPersonTicketNumber: '',
        accompanyingCheck: false
    };
    if (customer) {
        customerValues = Object.assign(Object.assign({}, customerValues), { email: getUserValue(customer, TravellerFieldEnum.Email), phone: getUserValue(customer, TravellerFieldEnum.Phone) });
    }
    const passengerValues = passengers.map(passenger => {
        var _a;
        const values = {
            type: passenger.type,
            disabilities: { enabled: false, values: {} },
            files: { changeFiles: [] }
        };
        (_a = passenger.values) === null || _a === void 0 ? void 0 : _a.forEach(value => {
            var _a, _b, _c, _d;
            let actualValue = value.value;
            // Nationality
            const isNationality = value.type === TravellerFieldEnum.Nationality;
            const validationOption = (_c = (_b = (_a = value.validationRules) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.options) === null || _c === void 0 ? void 0 : _c.find(option => option.value === value.value);
            if (isNationality && !validationOption && ((_d = value.validationRules) === null || _d === void 0 ? void 0 : _d.length)) {
                actualValue = value.validationRules[0].options[0].value;
            }
            // Date
            if (isDateFieldType(value.type)) {
                actualValue = formatInvalidPassengerDate(value.type, actualValue);
            }
            values[value.name] = actualValue;
        });
        return values;
    });
    return {
        customer: customerValues,
        passengers: passengerValues
    };
};
export const usePassengerForm = (passengers, customer, isInternationalFlight, isFreezeOrder) => {
    const [state, dispatch] = React.useReducer(reducer, createInitialState(passengers, customer));
    const selectedProfileIdsByPassenger = React.useMemo(() => {
        const selectedProfileIdsByPassengerMap = new Map();
        if (state.passengers.length) {
            state.passengers.forEach((passenger, index) => {
                if (passenger.profileId) {
                    selectedProfileIdsByPassengerMap.set(index, passenger.profileId);
                }
            });
        }
        return selectedProfileIdsByPassengerMap;
    }, [state]);
    // TODO: fix passenger index to real passenger id
    const onFillValues = React.useCallback((profile, id, document) => {
        if (profile) {
            const actualPassengerValues = getActualPassengerValues(passengers, profile, id, document, isInternationalFlight, isFreezeOrder);
            dispatch({ type: 'fill', payload: { id, profileId: profile.id, values: actualPassengerValues } });
        }
    }, [state, passengers, isInternationalFlight, isFreezeOrder, dispatch]);
    const onSwapNames = React.useCallback((id) => dispatch({ type: 'swap', payload: { id } }), [state]);
    return { state, selectedProfileIdsByPassenger, onFillValues, onSwapNames };
};
