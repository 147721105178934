import * as React from 'react';
import { useTheme } from '../../../theme';
import Link from '../../../Link';
import * as icons from '../../../Icons';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
initI18n('UpgradeFlightClass');
const UpgradeInfo = ({ className, handleGoToOffer, service }) => {
    const { UpgradeInfo: css } = useTheme('UpgradeFlightClass');
    const { t } = useTranslation('UpgradeFlightClass');
    return (React.createElement("div", { className: cn(css.root, className) },
        React.createElement("div", { className: css.icon__wrapper },
            React.createElement("div", { className: css.icon }, icons.ArrowForward)),
        React.createElement("div", { className: css.text__wrapper },
            React.createElement("div", { className: css.text__title }, t('Get upgraded now!')),
            React.createElement("div", { className: css.text__main, dangerouslySetInnerHTML: { __html: service.description } }),
            React.createElement("div", { className: css.text__more }, handleGoToOffer && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css.text__more__icon }, icons.InfoDetails),
                React.createElement("div", null,
                    React.createElement(Link, { action: () => {
                            handleGoToOffer === null || handleGoToOffer === void 0 ? void 0 : handleGoToOffer();
                        } }, t('More details')))))))));
};
export default UpgradeInfo;
