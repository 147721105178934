import { LOCK_FORM, UNLOCK_FORM } from './actions';
export const initialFormIsLockedState = false;
// #todo: add tests
export const formIsLockedReducer = (state = initialFormIsLockedState, action) => {
    switch (action.type) {
        case UNLOCK_FORM:
            return false;
        case LOCK_FORM:
            return true;
    }
    return state;
};
