import React from 'react';
import { NotificationCard } from '../../NotificationCard/NotificationCard';
import { Discount as DiscountIcon } from '../../../../Icons';
import Button from '../../../../Button';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import CopyCode from '../../../../PaymentForm/components/CopyCode/CopyCode';
import { Choosed as ChoosedIcon, NotIncluded as NotIncludedIcon } from '../../../../Icons';
import { format } from '../../../../utils';
import cn from 'classnames';
import { differenceInDays } from 'date-fns';
import { NOTIFICATION_DATE_FORMAT, NOTIFICATION_DATE_FORMAT_WITH_YEAR } from '../../../constants';
import { fromUTCToLocalTimeZone, isSameYearAsCurrent } from '../../../utils';
import { useConfig } from '../../../../context';
export const PromoCodeCard = props => {
    const { PromoCodeCard: css } = useTheme('Communications');
    const { t } = useTranslation('Communications');
    const { Account: { engineURL } } = useConfig();
    const isExpiredCode = props.endDate && new Date(props.endDate) < new Date();
    let statusTextCode = '';
    if (props.usedAt) {
        statusTextCode = t('Used');
    }
    else if (isExpiredCode) {
        statusTextCode = t('Expired');
    }
    else {
        statusTextCode = t('Can be used until');
    }
    let date;
    if (props.usedAt) {
        date = fromUTCToLocalTimeZone(props.usedAt);
    }
    else if (props.endDate) {
        date = fromUTCToLocalTimeZone(props.endDate);
    }
    const notificationDateFormat = isSameYearAsCurrent(date)
        ? NOTIFICATION_DATE_FORMAT
        : NOTIFICATION_DATE_FORMAT_WITH_YEAR;
    const threeDaysBeforeExpired = () => {
        const newDate = new Date();
        const endDate = new Date(props.endDate);
        const difference = differenceInDays(endDate, newDate);
        return difference <= 3;
    };
    const onClick = (e) => {
        e.preventDefault();
        window.location.href = `${engineURL}#/PROMO${props.code}`;
    };
    return (React.createElement(NotificationCard, { isRead: props.isRead, markAsRead: props.markAsRead },
        React.createElement("div", { className: css.promoCode },
            React.createElement("div", null,
                props.title && React.createElement("div", { className: css.promoCode__title }, props.title),
                React.createElement("div", { className: cn(css.promoCode__row, css.promoCode__row_code, css.promoCode__row_mb) },
                    React.createElement(CopyCode, { code: props.code, className: css.promoCode__value }),
                    date && (React.createElement("span", { className: cn(css.promoCode__info, {
                            [css.promoCode__info_expired]: threeDaysBeforeExpired()
                        }) },
                        statusTextCode,
                        " ",
                        format(date, notificationDateFormat)))),
                React.createElement("div", { className: cn(css.promoCode__row, css.promoCode__row_params) },
                    props.isPersonal && (React.createElement("span", { className: cn(css.promoCode__info, css.promoCode__info_flex) },
                        t('Personal'),
                        " ",
                        ChoosedIcon)),
                    React.createElement("span", { className: cn(css.promoCode__info, css.promoCode__info_flex) },
                        t('Reusable'),
                        " ",
                        NotIncludedIcon)),
                !isExpiredCode && (React.createElement(Button, { variant: "flat", className: css.promoCode__btn, onClick: onClick }, t('Book with promo code')))),
            React.createElement("span", { className: css.promoCode__icon }, DiscountIcon))));
};
