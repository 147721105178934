import * as React from 'react';
import { addMonths } from 'date-fns';
import { useTheme } from '../../../theme';
import { getAllYears, getMonthsList, getTimestamp, isMonthAvailable } from '../../utils';
import { Month } from './Month/Month';
import { MonthHeader } from './Month/MonthHeader/MonthHeader';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import classNames from 'classnames';
export const MonthsWrapper = props => {
    var _a;
    const [focusedDate, setFocusedDate] = React.useState(props.initialDate);
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const headerRef = React.useRef(null);
    React.useEffect(() => {
        if (isMobile && props.getParentRef) {
            const parent = props.getParentRef().current;
            if (parent &&
                headerRef.current &&
                (parent.hasOwnProperty('scroll') || typeof parent.scroll === 'function')) {
                parent.scroll({
                    behavior: 'auto',
                    top: headerRef.current.offsetTop
                });
            }
        }
    }, []);
    const theme = useTheme('Datepicker').MonthsWrapperStyles, yearsArray = React.useMemo(() => getAllYears(getTimestamp(props.minDate), getTimestamp(props.maxDate)), [
        props.minDate,
        props.maxDate
    ]);
    let minSelectedDatesTimestamp = Infinity, maxSelectedDatesTimestamp = 0;
    let minHoveredDatesTimestamp = Infinity, maxHoveredDatesTimestamp = 0;
    const setMonth = (date) => {
        setFocusedDate(date);
    };
    const setCurrentDate = (date, monthOffset = 0) => {
        let newDate = new Date(parseInt(date.toString()));
        if (monthOffset !== 0) {
            newDate = addMonths(newDate, monthOffset);
        }
        setMonth(newDate);
    };
    for (const selectedDate in props.selectedDates) {
        if (props.selectedDates.hasOwnProperty(selectedDate)) {
            const timestamp = parseInt(selectedDate);
            if (timestamp < minSelectedDatesTimestamp) {
                minSelectedDatesTimestamp = timestamp;
            }
            if (timestamp > maxSelectedDatesTimestamp) {
                maxSelectedDatesTimestamp = timestamp;
            }
        }
    }
    for (const hoverDateIndex in props.hoverDates) {
        if (props.hoverDates[hoverDateIndex]) {
            const timestamp = props.hoverDates[hoverDateIndex].getTime();
            if (timestamp < minHoveredDatesTimestamp) {
                minHoveredDatesTimestamp = timestamp;
            }
            if (timestamp > maxHoveredDatesTimestamp) {
                maxHoveredDatesTimestamp = timestamp;
            }
        }
    }
    const monthsToScroll = (_a = props.monthsToScroll) !== null && _a !== void 0 ? _a : 1;
    const renderMonth = (date, index, addRef) => {
        return (React.createElement(Month, { key: index, date: date, selectedDatesMap: props.selectedDates, minDateTimestamp: getTimestamp(props.minDate), maxDateTimestamp: getTimestamp(props.maxDate), minSelectedDateTimestamp: minSelectedDatesTimestamp, maxSelectedDateTimestamp: maxSelectedDatesTimestamp, minHoveredDateTimestamp: minHoveredDatesTimestamp, maxHoveredDateTimestamp: maxHoveredDatesTimestamp, highlightingDates: props.highlightedDates, onSelect: props.onSelect, onHover: props.onHover, monthHeader: React.createElement("div", { ref: addRef && focusedDate.getMonth && focusedDate.getMonth() === date.getMonth()
                    ? headerRef
                    : null },
                React.createElement(MonthHeader, { setCurrentMonth: setCurrentDate, years: yearsArray, monthIndex: index, currentDate: date, maxDate: props.maxDate, minDate: props.minDate })) }));
    };
    return (React.createElement("div", { className: classNames(theme.wrapper, props.frameless ? theme.frameless : null) },
        React.createElement("nav", null,
            isMonthAvailable(focusedDate, -1, props.minDate, props.maxDate) && (React.createElement("div", { className: theme.nav_prev, onClick: () => setMonth(addMonths(focusedDate, -monthsToScroll)) }, "\u2190")),
            isMonthAvailable(focusedDate, props.monthCount, props.minDate, props.maxDate) && (React.createElement("div", { className: theme.nav_next, onClick: () => setMonth(addMonths(focusedDate, monthsToScroll)) }, "\u2192"))),
        React.createElement("div", { className: theme.monthsList }, getMonthsList(focusedDate ? focusedDate : props.minDate, props.monthCount).map((date, index) => renderMonth(date, index, isMobile)))));
};
