import * as React from 'react';
import SpecialDocumentField from './SpecialDocumentField/SpecialDocumentField';
const SpecialDocument = props => {
    const regex = props.passengers[0].regex;
    const setValue = (id, value) => {
        props.dispatcher({
            type: 'set_document',
            payload: {
                id,
                value,
                isValid: !regex || new RegExp(regex.pattern).test(value)
            }
        });
    };
    return (React.createElement("div", null, props.passengers
        .filter(doc => doc.isRequired)
        .map(passenger => (React.createElement(SpecialDocumentField, { passenger: passenger, setValue: setValue, key: passenger.id, documentError: props.documentError })))));
};
export default SpecialDocument;
