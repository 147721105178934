import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import DesktopFlightModalHeader from '../DesktopFlightModalHeader/DesktopFlightModalHeader';
import FlightInfoSegment from '../FlightInfoSegment/FlightInfoSegment';
import { getNextSegment } from '../../utils';
import { getDurationString, getFlightDuration } from '../../../Results/utils';
import SelectedFareGroup from '../SelectedFareGroup/SelectedFareGroup';
const DesktopFlightModalContentSelected = ({ className, flight, lastSelectedFare, flightId, onClose, orderId, hideConditionsButton, isReturnFlight, isSelectorInModal = true, hidePrice }) => {
    var _a, _b;
    const { DesktopFlightModalContentSelected: css } = useTheme('DesktopFlight');
    const { t } = useTranslation('FlightInfoModalContent');
    const price = ((_b = (_a = flight.pricesForFareGroups) === null || _a === void 0 ? void 0 : _a[0].prices) === null || _b === void 0 ? void 0 : _b[0].price) || null;
    return (React.createElement("div", { className: cn(className, { [css.container_dropdown]: !isSelectorInModal }) },
        isSelectorInModal && React.createElement(DesktopFlightModalHeader, { title: t('You choose'), onClose: onClose }),
        React.createElement("div", { className: cn(css.content, { [css.content__center]: !lastSelectedFare }) },
            lastSelectedFare && (React.createElement(SelectedFareGroup, { className: cn(css.flight, { [css.flight_thin]: flight.segments.length > 1 }), flightId: flightId, orderId: orderId, fare: lastSelectedFare, price: hidePrice ? null : price, hideConditionsButton: hideConditionsButton })),
            React.createElement("div", { className: cn(css.segments, {
                    [css.segments__big]: flight.segments.length > 1
                }) }, flight.segments.map((segment, index) => (React.createElement("div", { className: css.segment__flight, key: index },
                React.createElement(FlightInfoSegment, { className: css.segment__flightWrapper, cardClassName: css.segment__card, transferClassName: css.segment__transfer, segment: segment, nextSegment: segment.transferDuration ? getNextSegment(flight.segments, index) : null, isMobile: true, isReturnFlight: isReturnFlight }),
                flight.segments.length > 1 && flight.segments.length - 1 === index && (React.createElement("div", { className: css.time },
                    t('Total duration'),
                    ": ",
                    getDurationString(getFlightDuration(flight.segments)))))))))));
};
export default DesktopFlightModalContentSelected;
