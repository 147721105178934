import * as React from 'react';
import Tabs from '../Tabs/Tabs';
import { SearchForm } from '../index';
import Checkin from './Checkin/Checkin';
import Booking from './Booking/Booking';
import { Fade } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { initI18n, MOBILE_MIN_WIDTH } from '../utils';
import { useMediaQuery } from 'react-responsive';
initI18n('Forms');
const Forms = () => {
    const [tab, setTab] = React.useState(0);
    const theme = useTheme('Forms').Forms;
    const { t } = useTranslation('Forms');
    const tabs = [
        {
            header: t('Search'),
            value: 0
        },
        {
            header: t('My booking'),
            value: 2
        },
        {
            header: t('Checkin'),
            value: 1
        }
    ];
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    return (React.createElement("div", null,
        React.createElement(Tabs, { tabs: tabs, onChange: setTab, selected: tab, className: theme.tabs, tabHeaderClassName: theme.tabLabel, selectedTabClassName: theme.tabSelected, indicatorClassName: theme.indicator, rootTabsClassName: theme.tabs__container, variant: isMobile ? 'scrollable' : 'standard' }),
        tab === 0 && (React.createElement(Fade, { in: tab === 0 },
            React.createElement("div", { className: theme.container },
                React.createElement(SearchForm, null)))),
        tab === 1 && (React.createElement(Fade, { in: tab === 1 },
            React.createElement("div", { className: theme.container },
                React.createElement(Checkin, null)))),
        tab === 2 && (React.createElement(Fade, { in: tab === 2 },
            React.createElement("div", { className: theme.container },
                React.createElement(Booking, null))))));
};
export default Forms;
