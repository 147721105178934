import * as React from 'react';
export const SmallCarryOnIcon = (React.createElement("svg", { className: "carryOn_small", width: "76", height: "67", viewBox: "0 0 76 67", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { className: "carryOn__innerFill", opacity: "0.2", x: "6", y: "16.6667", width: "64", height: "44", rx: "6", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.2864 10.72V4.02C25.2864 1.79982 27.0772 0 29.2864 0H46.5737C48.7829 0 50.5737 1.79981 50.5737 4.02V10.72H46.5737V4.02H29.2864V10.72H25.2864ZM10 12.7301H66C70.4183 12.7301 74 16.3297 74 20.7701V56.95C74 61.3904 70.4183 64.99 66 64.99H10C5.58172 64.99 2 61.3904 2 56.95V20.7701C2 16.3297 5.58172 12.7301 10 12.7301ZM0 20.7701C0 15.2196 4.47715 10.7201 10 10.7201H66C71.5229 10.7201 76 15.2196 76 20.7701V56.95C76 62.5005 71.5229 67 66 67H10C4.47715 67 0 62.5005 0 56.95V20.7701Z", fill: "currentColor" })));
export const LargeCarryOnIcon = (React.createElement("svg", { className: "carryOn_large", width: "87", height: "77", viewBox: "0 0 87 77", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { className: "carryOn__innerFill", opacity: "0.2", x: "6.78955", y: "19.4619", width: "72.4211", height: "49.94", rx: "6.81", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M28.7 12.1672V4.5627C28.7 2.04279 30.7327 0 33.24 0H52.8612C55.3686 0 57.4012 2.04279 57.4012 4.56269V12.1672H52.8612V4.56269H33.24V12.1672H28.7ZM11.35 14.4486H74.91C79.9247 14.4486 83.99 18.5342 83.99 23.574V64.6383C83.99 69.6781 79.9247 73.7636 74.91 73.7636H11.35C6.33525 73.7636 2.27 69.6781 2.27 64.6383V23.574C2.27 18.5342 6.33525 14.4486 11.35 14.4486ZM0 23.574C0 17.2742 5.08157 12.1673 11.35 12.1673H74.91C81.1784 12.1673 86.26 17.2742 86.26 23.574V64.6383C86.26 70.938 81.1784 76.045 74.91 76.045H11.35C5.08157 76.045 0 70.938 0 64.6383V23.574Z", fill: "currentColor" })));
export const SmallBaggageIcon = (React.createElement("svg", { className: "baggage_small", width: "70", height: "120", viewBox: "0 0 70 120", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { className: "baggage__innerFill", opacity: "0.2", fillRule: "evenodd", clipRule: "evenodd", d: "M12.9534 29.5386C9.86908 29.5386 7.36876 32.0389 7.36876 35.1232V39.5373C7.36876 42.6216 9.86908 45.1219 12.9534 45.1219H56.7836C59.8679 45.1219 62.3682 42.6216 62.3682 39.5373V35.1232C62.3682 32.0389 59.8679 29.5386 56.7836 29.5386H12.9534ZM12.9528 48.7885C9.86848 48.7885 7.36816 51.2888 7.36816 54.3731V100.954C7.36816 104.038 9.86848 106.539 12.9528 106.539H56.783C59.8673 106.539 62.3676 104.038 62.3676 100.954V54.3731C62.3676 51.2888 59.8673 48.7885 56.783 48.7885H12.9528Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M48.4846 22.2393V4.90578C48.4846 2.19639 46.2922 0 43.5877 0H26.715C24.0105 0 21.8181 2.19639 21.8181 4.90578V22.2393H8.56957C3.83673 22.2393 0 26.0993 0 30.8608V105.355C0 110.116 3.83673 113.976 8.56957 113.976H61.4302C66.163 113.976 69.9997 110.116 69.9997 105.355V30.8608C69.9997 26.0993 66.163 22.2393 61.4302 22.2393H48.4846ZM44.8483 22.2393V7.35891H25.4544V22.2393H44.8483ZM54.847 9.71442C54.4663 9.71442 54.1078 9.89432 53.8788 10.2002L51.1554 13.838C50.8302 14.2724 50.8302 14.8709 51.1554 15.3053L53.8788 18.9431C54.1078 19.249 54.4664 19.4289 54.847 19.4289H68.7879C69.4573 19.4289 70 18.883 70 18.2095V10.9339C70 10.2604 69.4573 9.71442 68.7879 9.71442H54.847ZM62.727 12.1434H61.5149V17.0007H62.727V12.1434ZM60.6059 12.1435H58.1816V17.0007H60.6059V12.1435ZM63.939 12.1434H65.1511V17.0007H63.939V12.1434ZM8.56957 24.6677H61.4302C64.8299 24.6677 67.586 27.4405 67.586 30.8608V105.355C67.586 108.775 64.8299 111.548 61.4302 111.548H8.56957C5.16983 111.548 2.41378 108.775 2.41378 105.355V30.8608C2.41378 27.4405 5.16982 24.6677 8.56957 24.6677ZM12.8945 115.829V115.83C12.8945 118.133 14.7502 120 17.0393 120C19.3283 120 21.184 118.133 21.184 115.83V115.829H12.8945ZM48.8159 115.83V115.829H57.1054V115.83C57.1054 118.133 55.2497 120 52.9606 120C50.6716 120 48.8159 118.133 48.8159 115.83Z", fill: "currentColor" })));
export const MiddleBaggageIcon = (React.createElement("svg", { className: "baggage_middle", width: "78", height: "134", viewBox: "0 0 78 134", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { className: "baggage__innerFill", opacity: "0.2", fillRule: "evenodd", clipRule: "evenodd", d: "M14.4334 32.9849C10.9966 32.9849 8.21051 35.7709 8.21051 39.2077V44.1634C8.21051 47.6002 10.9966 50.3863 14.4334 50.3863H63.2727C66.7095 50.3863 69.4956 47.6002 69.4956 44.1634V39.2077C69.4956 35.7709 66.7095 32.9849 63.2727 32.9849H14.4334ZM14.4328 54.4806C10.996 54.4806 8.20996 57.2666 8.20996 60.7034V112.745C8.20996 116.182 10.996 118.968 14.4328 118.968H63.2722C66.7089 118.968 69.495 116.182 69.495 112.745V60.7034C69.495 57.2666 66.7089 54.4806 63.2722 54.4806H14.4328Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M54.0257 24.7809V5.46644C54.0257 2.44741 51.5828 0 48.5692 0H29.7681C26.7546 0 24.3116 2.44741 24.3116 5.46644V24.7809H9.54895C4.27521 24.7809 0 29.0821 0 34.3878V117.395C0 122.701 4.27522 127.002 9.54895 127.002H68.4508C73.7245 127.002 77.9997 122.701 77.9997 117.395V34.3878C77.9997 29.0821 73.7245 24.7809 68.4508 24.7809H54.0257ZM49.9738 24.7809V8.19993H28.3635V24.7809H49.9738ZM61.1152 10.8246C60.6911 10.8246 60.2916 11.0251 60.0364 11.366L57.0017 15.4195C56.6393 15.9035 56.6393 16.5705 57.0017 17.0545L60.0364 21.1081C60.2916 21.4489 60.6911 21.6494 61.1152 21.6494H76.6494C77.3953 21.6494 78 21.041 78 20.2906V12.1835C78 11.433 77.3953 10.8246 76.6494 10.8246H61.1152ZM69.8958 13.5312H68.5452V18.9436H69.8958V13.5312ZM67.5322 13.5313H64.8309V18.9437H67.5322V13.5313ZM71.2463 13.5312H72.597V18.9436H71.2463V13.5312ZM9.54895 27.4869H68.4508C72.239 27.4869 75.3101 30.5765 75.3101 34.3878V117.395C75.3101 121.207 72.239 124.296 68.4508 124.296H9.54895C5.76066 124.296 2.68964 121.207 2.68964 117.395V34.3878C2.68964 30.5765 5.76066 27.4869 9.54895 27.4869ZM14.3682 129.067V129.068C14.3682 131.634 16.4359 133.714 18.9866 133.714C21.5373 133.714 23.605 131.634 23.605 129.068V129.067H14.3682ZM54.3947 129.068V129.067H63.6316V129.068C63.6316 131.634 61.5638 133.714 59.0132 133.714C56.4625 133.714 54.3947 131.634 54.3947 129.068Z", fill: "currentColor" })));
export const LargeBaggageIcon = (React.createElement("svg", { className: "baggage_large", width: "86", height: "147", viewBox: "0 0 86 147", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { className: "baggage__innerFill", opacity: "0.2", fillRule: "evenodd", clipRule: "evenodd", d: "M15.7817 35.9385C12.0075 35.9385 8.94786 38.9981 8.94786 42.7723V48.0644C8.94786 51.8386 12.0075 54.8982 15.7817 54.8982H68.8991C72.6733 54.8982 75.7329 51.8386 75.7329 48.0644V42.7723C75.7329 38.9981 72.6733 35.9385 68.8991 35.9385H15.7817ZM15.7811 59.3593C12.0069 59.3593 8.94727 62.4189 8.94727 66.1931V122.788C8.94727 126.562 12.0069 129.622 15.7811 129.622H68.8985C72.6727 129.622 75.7323 126.562 75.7323 122.788V66.1931C75.7323 62.4189 72.6727 59.3593 68.8985 59.3593H15.7811Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M59.3298 27.2138V6.00312C59.3298 2.68769 56.647 0 53.3376 0H32.6906C29.3812 0 26.6984 2.68769 26.6984 6.00312V27.2138H10.4864C4.69493 27.2138 0 31.9373 0 37.7639V128.921C0 134.748 4.69494 139.471 10.4864 139.471H75.171C80.9625 139.471 85.6574 134.748 85.6574 128.921V37.7639C85.6574 31.9372 80.9625 27.2138 75.171 27.2138H59.3298ZM54.8801 27.2138V9.00497H31.1481V27.2138H54.8801ZM67.1153 11.8874C66.6495 11.8874 66.2108 12.1075 65.9305 12.4818L62.5979 16.9333C62.2 17.4649 62.2 18.1973 62.5979 18.7289L65.9305 23.1804C66.2108 23.5547 66.6495 23.7749 67.1153 23.7749H84.1745C84.9937 23.7749 85.6578 23.1068 85.6578 22.2826V13.3796C85.6578 12.5555 84.9937 11.8874 84.1745 11.8874H67.1153ZM76.758 14.8597H75.2747V20.8034H76.758V14.8597ZM74.1623 14.8597H71.1958V20.8035H74.1623V14.8597ZM78.241 14.8597H79.7243V20.8034H78.241V14.8597ZM10.4864 30.1855H75.171C79.3312 30.1855 82.7037 33.5784 82.7037 37.7639V128.921C82.7037 133.106 79.3312 136.499 75.171 136.499H10.4864C6.32623 136.499 2.9537 133.106 2.9537 128.921V37.7639C2.9537 33.5784 6.32622 30.1855 10.4864 30.1855ZM15.7788 141.738V141.739C15.7788 144.557 18.0496 146.842 20.8506 146.842C23.6517 146.842 25.9225 144.557 25.9225 141.739V141.738H15.7788ZM59.735 141.739V141.738H69.8787V141.739C69.8787 144.557 67.608 146.842 64.8069 146.842C62.0058 146.842 59.735 144.557 59.735 141.739Z", fill: "currentColor" })));
export const HandLuggageExcess = (React.createElement("svg", { className: "handLuggageExcess", width: "80", height: "140", viewBox: "0 0 80 140", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M18.398 44.853L15.6953 82.353C15.3196 87.5649 19.4466 92 24.672 92H56.828C62.0534 92 66.1804 87.5649 65.8047 82.353L63.102 44.853C62.7628 40.1457 58.8448 36.5 54.1253 36.5H27.3747C22.6552 36.5 18.7372 40.1457 18.398 44.853Z", stroke: "currentColor", strokeWidth: "3" }),
    React.createElement("path", { d: "M23.6524 47.597L21.431 80.597C21.1978 84.0615 23.9451 87 27.4175 87H53.5825C57.0549 87 59.8022 84.0615 59.569 80.597L57.3476 47.597C57.1356 44.4469 54.5185 42 51.3612 42H29.6388C26.4815 42 23.8644 44.4469 23.6524 47.597Z", fill: "currentColor", opacity: "0.2" }),
    React.createElement("path", { d: "M27 92V88C27 84.6863 29.6863 82 33 82H49C52.3137 82 55 84.6863 55 88V92", stroke: "currentColor", strokeWidth: "3" }),
    React.createElement("path", { d: "M24 37L26.5296 66.5124C26.7957 69.6161 29.3926 72 32.5077 72H49.4923C52.6074 72 55.2043 69.6161 55.4704 66.5124L58 37", stroke: "currentColor", strokeWidth: "3" }),
    React.createElement("path", { d: "M34 37V35C34 31.6863 36.6863 29 40 29H42C45.3137 29 48 31.6863 48 35V37", stroke: "currentColor", strokeWidth: "3" }),
    React.createElement("path", { d: "M38 73V74C38 75.6569 39.3431 77 41 77V77C42.6569 77 44 75.6569 44 74V73", stroke: "currentColor", strokeWidth: "3.5" }),
    React.createElement("path", { d: "M14.0481 34.2693L6.12311 26.3444M6.12311 26.3444L6.12311 32.4405M6.12311 26.3444L12.2192 26.3444", stroke: "currentColor", strokeWidth: "1.73077" }),
    React.createElement("path", { d: "M14.0481 95.7194L6.12311 103.644M6.12311 103.644L6.12311 97.5483M6.12311 103.644L12.2192 103.644", stroke: "currentColor", strokeWidth: "1.73077" }),
    React.createElement("path", { d: "M66.9519 34.2693L74.8769 26.3444M74.8769 26.3444L74.8769 32.4405M74.8769 26.3444L68.7808 26.3444", stroke: "currentColor", strokeWidth: "1.73077" }),
    React.createElement("path", { d: "M66.9519 95.7194L74.8769 103.644M74.8769 103.644L74.8769 97.5483M74.8769 103.644L68.7808 103.644", stroke: "currentColor", strokeWidth: "1.73077" })));
export const SmallBaggageExcessIcon = (React.createElement("svg", { className: "baggageExcess_small", width: "57", height: "56", viewBox: "0 0 57 56", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { style: { mixBlendMode: 'multiply' } },
        React.createElement("rect", { width: "56", height: "56", transform: "translate(0.333252)", fill: "white" }),
        React.createElement("circle", { className: "baggageExcess__circleFill", cx: "28.3333", cy: "28", r: "28", fill: "currentColor", opacity: "0.2" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M28.3332 4.99316C40.9855 4.99316 51.2423 15.2499 51.2423 27.9023H47.7178L28.3332 27.9023V8.51764C17.6273 8.51764 8.94855 17.1964 8.94855 27.9023H5.42407C5.42407 15.2499 15.6808 4.99316 28.3332 4.99316Z", fill: "currentColor" }),
        React.createElement("mask", { id: "path-3-outside-1", maskUnits: "userSpaceOnUse", x: "17.7844", y: "9.79794", width: "26.2146", height: "29.1348", fill: "black" },
            React.createElement("rect", { fill: "white", x: "17.7844", y: "9.79794", width: "26.2146", height: "29.1348" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M29.8009 22.8219L36.9904 14.6133L32.3869 24.5066C33.8421 26.2427 34.0634 28.7914 32.7626 30.788C31.1688 33.2344 27.8936 33.9256 25.4472 32.3318C23.0008 30.738 22.3096 27.4628 23.9034 25.0164C25.2041 23.0198 27.6249 22.1923 29.8009 22.8219Z" })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M29.8009 22.8219L36.9904 14.6133L32.3869 24.5066C33.8421 26.2427 34.0634 28.7914 32.7626 30.788C31.1688 33.2344 27.8936 33.9256 25.4472 32.3318C23.0008 30.738 22.3096 27.4628 23.9034 25.0164C25.2041 23.0198 27.6249 22.1923 29.8009 22.8219Z", fill: "currentColor" }),
        React.createElement("path", { d: "M36.9904 14.6133L38.8037 15.4571L35.4859 13.2956L36.9904 14.6133ZM29.8009 22.8219L29.2451 24.7431L30.4672 25.0967L31.3055 24.1396L29.8009 22.8219ZM32.3869 24.5066L30.5736 23.6628L30.0369 24.8163L30.8542 25.7914L32.3869 24.5066ZM35.4859 13.2956L28.2964 21.5042L31.3055 24.1396L38.4949 15.9311L35.4859 13.2956ZM34.2002 25.3504L38.8037 15.4571L35.1771 13.7696L30.5736 23.6628L34.2002 25.3504ZM30.8542 25.7914C31.7599 26.872 31.8939 28.4575 31.0869 29.6963L34.4384 31.8797C36.2328 29.1253 35.9244 25.6134 33.9197 23.2218L30.8542 25.7914ZM31.0869 29.6963C30.096 31.2172 28.0598 31.6469 26.5389 30.656L24.3555 34.0075C27.7274 36.2043 32.2416 35.2516 34.4384 31.8797L31.0869 29.6963ZM26.5389 30.656C25.018 29.6652 24.5883 27.629 25.5791 26.1081L22.2276 23.9247C20.0309 27.2966 20.9836 31.8108 24.3555 34.0075L26.5389 30.656ZM25.5791 26.1081C26.3862 24.8693 27.8906 24.3512 29.2451 24.7431L30.3568 20.9007C27.3591 20.0334 24.0221 21.1702 22.2276 23.9247L25.5791 26.1081Z", fill: "white", mask: "url(#path-3-outside-1)" }))));
export const MiddleBaggageExcessIcon = (React.createElement("svg", { className: "baggageExcess_middle", width: "56", height: "56", viewBox: "0 0 56 56", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { style: { mixBlendMode: 'multiply' } },
        React.createElement("rect", { width: "56", height: "56", fill: "white" }),
        React.createElement("circle", { className: "baggageExcess__circleFill", cx: "28", cy: "28", r: "28", fill: "currentColor", opacity: "0.2" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M27.9999 4.99316C40.6522 4.99316 50.909 15.2499 50.909 27.9023H47.3845H27.9999V8.51764C17.2941 8.51764 8.6153 17.1964 8.6153 27.9023H5.09082C5.09082 15.2499 15.3476 4.99316 27.9999 4.99316Z", fill: "currentColor" }),
        React.createElement("mask", { id: "path-3-outside-1", maskUnits: "userSpaceOnUse", x: "17.3312", y: "11.2064", width: "28.1625", height: "26.5442", fill: "black" },
            React.createElement("rect", { fill: "white", x: "17.3312", y: "11.2064", width: "28.1625", height: "26.5442" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M30.495 22.9677L39.986 17.5836L32.3822 25.4098C33.1847 27.5282 32.5546 30.0077 30.669 31.4648C28.3587 33.2501 25.0385 32.8245 23.2531 30.5142C21.4678 28.2038 21.8934 24.8836 24.2037 23.0983C26.0893 21.6412 28.6475 21.6568 30.495 22.9677Z" })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M30.495 22.9677L39.986 17.5836L32.3822 25.4098C33.1847 27.5282 32.5546 30.0077 30.669 31.4648C28.3587 33.2501 25.0385 32.8245 23.2531 30.5142C21.4678 28.2038 21.8934 24.8836 24.2037 23.0983C26.0893 21.6412 28.6475 21.6568 30.495 22.9677Z", fill: "currentColor" }),
        React.createElement("path", { d: "M39.986 17.5836L41.022 18.5902L39.2733 16.3272L39.986 17.5836ZM30.495 22.9677L29.6591 24.1457L30.4085 24.6774L31.2077 24.2241L30.495 22.9677ZM32.3822 25.4098L31.3462 24.4033L30.7059 25.0623L31.0314 25.9216L32.3822 25.4098ZM24.2037 23.0983L23.3205 21.9553L24.2037 23.0983ZM39.2733 16.3272L29.7823 21.7113L31.2077 24.2241L40.6988 18.84L39.2733 16.3272ZM33.4182 26.4164L41.022 18.5902L38.9501 16.5771L31.3462 24.4033L33.4182 26.4164ZM31.0314 25.9216C31.6151 27.4623 31.1545 29.2641 29.7858 30.3218L31.5522 32.6077C33.9547 30.7512 34.7543 27.5942 33.7329 24.8981L31.0314 25.9216ZM29.7858 30.3218C28.1067 31.6193 25.6936 31.31 24.3961 29.6309L22.1102 31.3974C24.3833 34.339 28.6107 34.8808 31.5522 32.6077L29.7858 30.3218ZM24.3961 29.6309C23.0985 27.9518 23.4079 25.5388 25.087 24.2412L23.3205 21.9553C20.3789 24.2285 19.8371 28.4558 22.1102 31.3974L24.3961 29.6309ZM25.087 24.2412C26.4556 23.1836 28.3154 23.1923 29.6591 24.1457L31.3309 21.7897C28.9796 20.1213 25.7229 20.0988 23.3205 21.9553L25.087 24.2412Z", fill: "white", mask: "url(#path-3-outside-1)" }))));
export const LargeBaggageExcessIcon = (React.createElement("svg", { width: "57", height: "56", viewBox: "0 0 57 56", fill: "none", className: "baggage_popup_large_baggage_icon baggageExcess_large", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { style: { mixBlendMode: 'multiply' } },
        React.createElement("rect", { width: "56", height: "56", transform: "translate(0.666504)", fill: "white" }),
        React.createElement("circle", { className: "baggageExcess__circleFill", cx: "28.6665", cy: "28", r: "28", fill: "currentColor", opacity: "0.2" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M28.6664 4.99316C41.3188 4.99316 51.5755 15.2499 51.5755 27.9023H48.051H28.6664V8.51764C17.9606 8.51764 9.2818 17.1964 9.2818 27.9023H5.75732C5.75732 15.2499 16.0141 4.99316 28.6664 4.99316Z", fill: "currentColor" }),
        React.createElement("mask", { id: "path-3-outside-1", maskUnits: "userSpaceOnUse", x: "21.1333", y: "20.6152", width: "24", height: "15", fill: "black" },
            React.createElement("rect", { fill: "white", x: "21.1333", y: "20.6152", width: "24", height: "15" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.3311 26.3588L44.1333 27.9019L33.3311 29.4451C32.6708 31.6121 30.6561 33.1887 28.2732 33.1887C25.3534 33.1887 22.9864 30.8217 22.9864 27.9019C22.9864 24.9822 25.3534 22.6152 28.2732 22.6152C30.6561 22.6152 32.6708 24.1918 33.3311 26.3588Z" })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.3311 26.3588L44.1333 27.9019L33.3311 29.4451C32.6708 31.6121 30.6561 33.1887 28.2732 33.1887C25.3534 33.1887 22.9864 30.8217 22.9864 27.9019C22.9864 24.9822 25.3534 22.6152 28.2732 22.6152C30.6561 22.6152 32.6708 24.1918 33.3311 26.3588Z", fill: "currentColor" }),
        React.createElement("path", { d: "M44.1333 27.9019L44.3376 29.3319L44.3376 26.472L44.1333 27.9019ZM33.3311 26.3588L31.9494 26.7798L32.2172 27.6588L33.1268 27.7887L33.3311 26.3588ZM33.3311 29.4451L33.1268 28.0152L32.2172 28.1451L31.9494 29.0241L33.3311 29.4451ZM28.2732 33.1887L28.2732 31.7442L28.2732 33.1887ZM28.2732 22.6152L28.2732 21.1708L28.2732 22.6152ZM44.3376 26.472L33.5354 24.9288L33.1268 27.7887L43.929 29.3319L44.3376 26.472ZM33.5354 30.875L44.3376 29.3319L43.929 26.472L33.1268 28.0152L33.5354 30.875ZM31.9494 29.0241C31.4691 30.6001 30.0029 31.7442 28.2732 31.7442L28.2732 34.6331C31.3093 34.6331 33.8725 32.624 34.7128 29.8661L31.9494 29.0241ZM28.2732 31.7442C26.1511 31.7442 24.4309 30.024 24.4309 27.9019L21.542 27.9019C21.542 31.6195 24.5556 34.6331 28.2732 34.6331L28.2732 31.7442ZM24.4309 27.9019C24.4309 25.7799 26.1511 24.0597 28.2732 24.0597L28.2732 21.1708C24.5556 21.1708 21.542 24.1844 21.542 27.9019L24.4309 27.9019ZM28.2732 24.0597C30.0029 24.0597 31.4691 25.2038 31.9494 26.7798L34.7128 25.9377C33.8725 23.1799 31.3093 21.1708 28.2732 21.1708L28.2732 24.0597Z", fill: "white", mask: "url(#path-3-outside-1)" }))));
