import i18n from 'i18next';
import { addDays, parse } from 'date-fns';
import { isRoundTrip } from './components/AeroexpressPopup/utils';
import { format } from '../utils';
export const getPassengerPricesMap = (travellers, services, isMinPrice) => {
    const pricesByPassengerMap = new Map();
    services.forEach(service => {
        const allowedPassengerIds = new Set(service.allowedPassengers.reduce((acc, ids) => [...acc, ...ids], []));
        allowedPassengerIds.forEach(passengerId => {
            var _a;
            const traveller = travellers.find(traveller => traveller.id === passengerId);
            if (traveller) {
                const type = traveller.type;
                const supplierType = traveller.supplierTravellerType;
                const priceByPassenger = pricesByPassengerMap.get(type);
                const condition = isMinPrice
                    ? !priceByPassenger || ((_a = priceByPassenger === null || priceByPassenger === void 0 ? void 0 : priceByPassenger.price) === null || _a === void 0 ? void 0 : _a.amount) > service.price.amount
                    : !priceByPassenger;
                if (condition) {
                    pricesByPassengerMap.set(type, { type, supplierType, price: service.price });
                }
            }
        });
    });
    return [...pricesByPassengerMap.values()];
};
export const getAeroexpressTripLabel = (trip) => {
    const departureDate = parse(trip.departureDate, 'HH:mm dd.MM.yyyy', new Date());
    let label = format(departureDate, 'dd.MM.yyyy');
    if (isRoundTrip(trip)) {
        label += ` - ${i18n.t('Aeroexpress:To')} ${format(addDays(departureDate, 30), 'dd.MM.yyyy')}`;
    }
    return label;
};
export const getVipServicesToDelete = (payload, travellers) => {
    var _a, _b, _c;
    const removeServices = [];
    const commonRemoveService = {
        serviceId: payload.serviceId,
        passengerId: payload.passengerId,
        count: 0
    };
    if (payload.allSegments) {
        const travellerServicesById = (_c = (_b = (_a = travellers.find(traveller => traveller.id === payload.passengerId)) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services;
        if (travellerServicesById === null || travellerServicesById === void 0 ? void 0 : travellerServicesById.length) {
            travellerServicesById.forEach(service => {
                if (service.serviceId === payload.serviceId) {
                    removeServices.push(Object.assign(Object.assign({}, commonRemoveService), { segmentIds: service.segmentIds }));
                }
            });
        }
    }
    else {
        removeServices.push(Object.assign(Object.assign({}, commonRemoveService), { segmentIds: [payload.segmentId] }));
    }
    return removeServices;
};
