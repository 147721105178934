import { __awaiter } from "tslib";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '../../../theme';
import { nextStep, prevStep, setStep } from '../../store/steps/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveStep, getCanGoToNextStep, getSteps } from '../../store/steps/selectors';
import { isActiveStepAfter } from './utils';
import { setRefundInfo } from '../../store/refundInfo/actions';
import { usecreateRefundOrderMutation, usegetLastRefundQuery, usegetRefundDocumentsQuery } from '@websky/graphql';
import { useConfig } from '../../../context';
import { useTranslation } from 'react-i18next';
export const useRefundInfo = (options) => {
    var _a;
    const [docs, setDocs] = useState({});
    const [prices, setPrices] = useState(null);
    const dispatch = useDispatch();
    const isFirstRequest = useRef(true);
    const { data, loading, error, refetch: refetchQuery } = usegetRefundDocumentsQuery(Object.assign(Object.assign({}, options), { variables: Object.assign(Object.assign({}, options === null || options === void 0 ? void 0 : options.variables), { includePrices: isFirstRequest.current ? false : (_a = options.variables) === null || _a === void 0 ? void 0 : _a.includePrices }) }));
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.GetRefundInfo) {
            dispatch(setRefundInfo(data === null || data === void 0 ? void 0 : data.GetRefundInfo));
            isFirstRequest.current = false;
        }
    }, [data, dispatch]);
    const reasons = useMemo(() => {
        var _a, _b, _c;
        if (!data) {
            return [];
        }
        const filterReasonsWithoutDocs = (_a = data === null || data === void 0 ? void 0 : data.GetRefundInfo) === null || _a === void 0 ? void 0 : _a.confirmationDocsAreRequired;
        return (_c = (_b = data === null || data === void 0 ? void 0 : data.GetRefundInfo) === null || _b === void 0 ? void 0 : _b.unvoluntaryReasonsInfo) === null || _c === void 0 ? void 0 : _c.map(reason => {
            var _a;
            if (filterReasonsWithoutDocs) {
                if (!((_a = reason === null || reason === void 0 ? void 0 : reason.docs) === null || _a === void 0 ? void 0 : _a.length)) {
                    return null;
                }
            }
            return {
                title: reason.reason.toString(),
                value: reason.reason
            };
        }).filter(Boolean);
    }, [data]);
    useEffect(() => {
        var _a, _b;
        (_b = (_a = data === null || data === void 0 ? void 0 : data.GetRefundInfo) === null || _a === void 0 ? void 0 : _a.unvoluntaryReasonsInfo) === null || _b === void 0 ? void 0 : _b.forEach(reason => {
            var _a, _b;
            docs[reason.reason.toString()] = (_a = reason === null || reason === void 0 ? void 0 : reason.docs) === null || _a === void 0 ? void 0 : _a[0];
            setDocs(Object.assign(Object.assign({}, docs), { [reason.reason.toString()]: (_b = reason === null || reason === void 0 ? void 0 : reason.docs) === null || _b === void 0 ? void 0 : _b[0] }));
        });
    }, [reasons]);
    useEffect(() => {
        var _a;
        if (!prices && ((_a = data === null || data === void 0 ? void 0 : data.GetRefundInfo) === null || _a === void 0 ? void 0 : _a.prices)) {
            setPrices(data.GetRefundInfo.prices);
        }
    }, [data]);
    const refetch = (params) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const data = yield refetchQuery(params);
        dispatch(setRefundInfo((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.GetRefundInfo));
        return data;
    });
    return {
        data,
        loading,
        error,
        refetch,
        reasons,
        docks: docs,
        prices
    };
};
export const useMobileStepsPositionClassNames = () => {
    const { Refund: css } = useTheme('Refund');
    const setClassesToPassedSteps = () => {
        const passedSteps = Array.from(document.getElementsByClassName(css.passed));
        const firstPassedStep = passedSteps[0];
        passedSteps.forEach((passedStep, index) => {
            if (index > 0) {
                firstPassedStep.classList.add(css.withoutBottomBorderRadius);
            }
            if (index !== 0) {
                passedStep.classList.add(css.withoutTopBorderRadius);
                passedStep.classList.add(css.withTopBorder);
                if (index !== passedSteps.length - 1) {
                    passedStep.classList.add(css.withoutBottomBorderRadius);
                }
            }
        });
    };
    return { setClassesToPassedSteps };
};
export const useCreateRefundOrder = () => {
    return usecreateRefundOrderMutation({ fetchPolicy: 'no-cache' });
};
export const useLastRefundQuery = (orderId) => {
    var _a, _b;
    if (!orderId) {
        return null;
    }
    const { loading, data } = usegetLastRefundQuery({
        variables: { orderId },
        fetchPolicy: 'no-cache'
    });
    return {
        loading,
        data: (_b = (_a = data === null || data === void 0 ? void 0 : data.Order) === null || _a === void 0 ? void 0 : _a.exareInfo) === null || _b === void 0 ? void 0 : _b.lastRefund
    };
};
export const useSteps = () => {
    const dispatch = useDispatch();
    const currentStep = useSelector(getActiveStep);
    const canGoToNextStep = useSelector(getCanGoToNextStep);
    const steps = useSelector(getSteps);
    const goToNextStep = useCallback(() => dispatch(nextStep()), [dispatch, currentStep]);
    const goToPrevStep = useCallback(() => dispatch(prevStep()), [dispatch, currentStep]);
    const isCurrentActiveStepAfter = isActiveStepAfter.bind(null, steps);
    const handleMobileCloseClick = useCallback((stepType) => {
        const isStepPassed = isCurrentActiveStepAfter(stepType);
        return isStepPassed
            ? () => dispatch(setStep({
                type: stepType,
                isActive: true,
                additionalText: ''
            }))
            : null;
    }, [currentStep]);
    return {
        currentStep,
        goToNextStep,
        goToPrevStep,
        canGoToNextStep,
        isCurrentActiveStepAfter,
        handleMobileCloseClick
    };
};
export const useHtmlDescriptionOfError = (error) => {
    const { Engine: { checkInURL, checkInEnabled } } = useConfig();
    const { t } = useTranslation('Refund');
    let header = null, body = null;
    const message = typeof error === 'string' ? error : error === null || error === void 0 ? void 0 : error.message;
    if (!(message === null || message === void 0 ? void 0 : message.length)) {
        return null;
    }
    if (message.includes('Refund operations with checked segments are not permitted')) {
        header = t('It is not possible to refund a ticket that has been checked-in for a flight');
        body = `${t('If you wish to make a refund, please cancel your flight check-in.')} ${checkInEnabled
            ? t(`You can do it yourself by going to <a href='{{link}}' target='_blank'>Online Registration section</a>.`, { link: checkInURL })
            : ''}`;
    }
    else if (message.includes('Refund operations with used segments are not permitted')) {
        header = t('It is not possible to refund a used ticket');
        body = t('The ticket has been flown on, you cannot return a used ticket.');
    }
    else if (message.includes('Refund operation allowed only in manual mode')) {
        header = t('To make an exchange please contact a customer support service');
    }
    else {
        return null;
    }
    return {
        header,
        body
    };
};
