import * as React from 'react';
import { useCallback, useState } from 'react';
import MediaQuery from '../../../../../MediaQuery/MediaQuery';
import Modal from '../../../../../Modal';
import LoginForm from '../../../../../LoginPage/components/LoginForm/Component';
import { reachGoal, UserGoal } from '../../../../../analytics';
import { useTheme } from '../../../../../theme';
const Login = props => {
    const [isLoginPageOpened, setLoginPageOpened] = useState(false);
    const [preselectedLogin, setPreselectedLogin] = React.useState('');
    const theme = useTheme('Contacts').ContactsStyles;
    const onOpenLogin = (preselectedLogin) => {
        setPreselectedLogin(preselectedLogin);
        setLoginPageOpened(true);
    };
    const onSuccessfulLogin = useCallback((isRegistration = false) => {
        setLoginPageOpened(false);
        reachGoal(isRegistration ? UserGoal.Registration : UserGoal.Login);
        if (props.refetchUser) {
            props.refetchUser();
        }
    }, [props.refetchUser]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { maxWidth: "mobile" },
            React.createElement(Modal, { open: isLoginPageOpened, onClose: () => setLoginPageOpened(false), bottomDrawer: true, isRounded: true, className: theme.footer__slideBottom },
                React.createElement("div", { className: theme.footer__slideBottom },
                    React.createElement(LoginForm, { login: preselectedLogin, onSuccess: onSuccessfulLogin })))),
        React.createElement(MediaQuery, { minWidth: "mobile" },
            React.createElement(Modal, { open: isLoginPageOpened, onClose: () => setLoginPageOpened(false), maxWidth: "sm", classes: {
                    paper: theme.login__modal
                } },
                React.createElement(LoginForm, { login: preselectedLogin, onSuccess: onSuccessfulLogin }))),
        props.children({
            openLogin: onOpenLogin,
            userInfo: props.userInfo,
            refetchUser: props.refetchUser,
            isLoginFormOpen: isLoginPageOpened
        })));
};
export default Login;
