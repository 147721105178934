import { addDays, compareAsc, toDate } from 'date-fns';
export const createDatesArray = (minDate, maxDate) => {
    let date = toDate(minDate);
    const datesArray = [];
    datesArray.push(minDate);
    while (compareAsc(maxDate, date) === 1) {
        date = addDays(date, 1);
        datesArray.push(date);
    }
    return datesArray;
};
