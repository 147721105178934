import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ThanksBanner from '../ThanksBanner/ThanksBanner';
import { getCroppedError, MOBILE_MIN_WIDTH } from '../../../../utils';
import { Form } from '../Form/Form';
import { StepType } from '../../../types';
import { ThemeProvider, useTheme } from '../../../../theme';
import { setPassengerValues as setPassengerValuesAction } from '../../../store/passengers/actions';
import Actualization from '../Actualization/Actualization';
import { fillOrder as fillOrderAction } from '../../../store/order/actions';
import { getOrder, getAnimalServices, getIsFareLockConfirmed, getIsFareLockSelected, getIsTravellersDataFilled, getIsBooked, getIsBookedOrConfirmed, getIsCancelled, getTravellersValueNames, getIsConfirmed, getIsConfirmedOrCancelled, getIsNew, getIsReadonly, getCustomerSubscribed } from '../../../store/order/selectors';
import { checkInternationalFlight, prepareBookingRequest, getOrderPromoCodeInfoParams, getWithValidPromoCodesFormValues } from '../../../utils';
import WarningModal from '../../../../WarningModal';
import Warnings from '../../../../Warnings';
import { OrderGoal, reachGoal } from '../../../../analytics';
import FooterMobile from '../../../../widgets/navigation/FooterMobile';
import { Aeroexpress, Baggage, Loyalty, Meal, Seats, Animal } from '../../../../CheckoutAdditionalService/components';
import { useCheckoutServicesCardsAvailability } from '../../../../CheckoutAdditionalService/components/hooks';
import PassengersToolbar from '../Form/PassengersToolbar/PassengersToolbar';
import { PromoMessages } from '../../../../PromoMessage/PromoMessage';
import SimpleLoader from '../../../../SimpleLoader';
import Business from '../../../../CheckoutAdditionalService/components/Business/Business';
import OrderCard from '../../../../OrderCard';
import { useDownloadOrderDocument, usePromoCodesData } from '../hooks';
import { useFareLock } from '../../../../FareLock/hooks';
import FareLock from '../../../../FareLock';
import SelectedFlight from '../SelectedFlight/SelectedFlight';
import { WarningRounded } from '../../../../Icons';
import PriorityBoarding from '../../../../CheckoutAdditionalService/components/PriorityBoarding/PriorityBoarding';
import Flights from '../Flights/Flights';
import CapsuleHotel from '../../../../CheckoutAdditionalService/components/CapsuleHotel/CapsuleHotel';
import { OverrideComponent } from '../../../../renderProps';
import { useBookOrderMutation, useOrderPromoCodeInfoQuery, FlightSegmentStatus, useCheckinInfoQuery, OrderStatus, InfoMessagePositionsEnum } from '@websky/graphql';
import { useConfig } from '../../../../context';
import PromoCodes from '../PromoCodes/PromoCodes';
import { nextStep } from '../../../../Refund/store/steps/actions';
import InfoMessages from '../../../../InfoMessages';
import OrderExareStatus from '../OrderExareStatus/OrderExareStatus';
import { pnrBlockedRegExp } from '../../../../apolloErrorLink';
import OrderInsuranceBlockSlot from '../OrderInsuranceBlockSlot/OrderInsuranceBlockSlot';
const Passengers = ({ showRefundButton, setBookingError, bookingError, backToResults, onSetOrder, onOrderBooked, currentStep, showServiceCards = true, goToPrevStep, goToExchange, goToChangePassenger, showExchangeButton, goToRefund, userInfo, refetchUser, orderSubsidyState, setStep, setNextStep, steps }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const css = useTheme('Checkout').CheckoutStyles;
    const resultsCss = useTheme('Results').Results;
    const { t } = useTranslation('Checkout');
    const [innerFormValues, setInnerFormValues] = useState(null);
    const [showActualizationResults, setShowActualizationResults] = useState(false);
    const [bookOrder, { loading: isBooking }] = useBookOrderMutation();
    const promoCodesData = usePromoCodesData();
    // const [cancelOrder] = useMutation<CancelOrderResponse, CancelOrderVariables>(CancelOrderQuery.CancelOrder);
    const servicesAvailability = useCheckoutServicesCardsAvailability();
    const order = useSelector(getOrder);
    const isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    const noAvailableServices = Object.keys(servicesAvailability).every(key => servicesAvailability[key] === false);
    const isFareLockSelected = useSelector(getIsFareLockSelected);
    const isFareLockConfirmed = useSelector(getIsFareLockConfirmed);
    const animalServices = useSelector(getAnimalServices);
    const travellersMap = useSelector(getTravellersValueNames);
    const isCancelled = useSelector(getIsCancelled);
    const isReadonly = useSelector(getIsReadonly);
    const isBooked = useSelector(getIsBooked);
    const isBookedOrConfirmed = useSelector(getIsBookedOrConfirmed);
    const isNew = useSelector(getIsNew);
    const isConfirmed = useSelector(getIsConfirmed);
    const isConfirmedOrCancelled = useSelector(getIsConfirmedOrCancelled);
    const customerSubscribed = useSelector(getCustomerSubscribed);
    const iataCode = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.global.companyInfo.iataCode;
    const hasAnimal = iataCode === 'N4';
    const dispatch = useDispatch();
    const setPassengerValues = (index, passengerValues) => dispatch(setPassengerValuesAction(index, passengerValues));
    const fillOrder = (order) => dispatch(fillOrderAction(order));
    const detailsStyles = useMemo(() => ({
        DesktopSummaryFlight: {
            SummaryFlight: {
                flight: css.desktopFlight__flight
            }
        },
        Passenger: {
            PassengerStyles: {
                passenger: css.form__passenger,
                header__text: css.form__passengerHeaderText
            }
        }
    }), [order.id]);
    const { data: getCheckinInfoResponse } = useCheckinInfoQuery({
        skip: !isConfirmedOrCancelled,
        variables: {
            params: {
                aviaOrderId: order.id
            }
        }
    });
    const { refetch: refetchPromoCodeInfo } = useOrderPromoCodeInfoQuery({ skip: true });
    const clearError = () => setBookingError(null);
    const checkPromoCodes = (formValues) => __awaiter(void 0, void 0, void 0, function* () {
        var _j;
        const promoCodeInfoParams = getOrderPromoCodeInfoParams(order, formValues);
        if (!promoCodeInfoParams) {
            return null;
        }
        let result = null;
        try {
            promoCodesData.setLoading(true);
            const { data, errors } = yield refetchPromoCodeInfo(promoCodeInfoParams);
            if (!errors) {
                result = {
                    promoCodesInfo: data.OrderPromoCodeInfo
                };
            }
            else {
                result = {
                    error: (_j = errors[0]) === null || _j === void 0 ? void 0 : _j.message
                };
            }
        }
        catch (e) {
            result = {
                error: e.message
            };
        }
        finally {
            promoCodesData.setLoading(false);
        }
        return result;
    });
    const setBookingErrorHandler = (errors = []) => {
        var _a, _b;
        if (!errors.some(error => pnrBlockedRegExp.test((error === null || error === void 0 ? void 0 : error.message) || ''))) {
            setBookingError(Object.assign(Object.assign({}, errors[0]), { message: (_b = (_a = errors[0]) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Unknown error' }));
        }
    };
    const startBooking = (formValues) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            reachGoal(OrderGoal.Book);
            const { data, errors } = yield bookOrder({
                variables: prepareBookingRequest(order.id, formValues, orderSubsidyState)
            });
            if (errors && errors.length) {
                setBookingErrorHandler(errors);
                reachGoal(OrderGoal.BookingError, {
                    error: errors[0]
                });
                return null;
            }
            else if (data && data.BookOrder) {
                onOrderBooked(data.BookOrder);
                return data.BookOrder;
            }
            return null;
        }
        catch (error) {
            const apolloError = error;
            order.travellers.forEach((traveller, index) => {
                const passengerValues = traveller.values.map(value => {
                    return {
                        name: value.name,
                        type: value.type,
                        value: formValues['passengers'][index][value.name]
                    };
                });
                setPassengerValues(index, passengerValues);
            });
            setBookingErrorHandler(apolloError === null || apolloError === void 0 ? void 0 : apolloError.graphQLErrors);
            reachGoal(OrderGoal.BookingError, {
                error
            });
            return null;
        }
    });
    const onFormSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        order.travellers.forEach((traveller, index) => {
            const passengerValues = traveller.values.map(value => {
                return {
                    name: value.name,
                    type: value.type,
                    value: values['passengers'][index][value.name]
                };
            });
            setPassengerValues(index, passengerValues);
        });
        setShowActualizationResults(true);
        setInnerFormValues(values);
        const promoCodesInfoResult = yield checkPromoCodes(values);
        if (!promoCodesInfoResult) {
            if (order.status === OrderStatus.New) {
                yield startBooking(values);
            }
            else {
                nextStep();
            }
        }
        else {
            promoCodesData.setData(promoCodesInfoResult);
            promoCodesData.setOpen(true);
        }
    }), [order.travellers, order.flight.segments, order.travellers, orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.travellers, order.status]);
    const promoCodesSubmitCallBack = () => __awaiter(void 0, void 0, void 0, function* () {
        promoCodesData.close();
        const withValidPromoCodesValues = getWithValidPromoCodesFormValues(innerFormValues, promoCodesData.data.promoCodesInfo, travellersMap);
        yield startBooking(withValidPromoCodesValues);
    });
    const promoCodesCancelCallback = () => __awaiter(void 0, void 0, void 0, function* () {
        promoCodesData.setData(null);
        promoCodesData.close();
    });
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    useEffect(() => {
        // if there is no available services in mobile, redirect to payment
        if (Object.keys(servicesAvailability).every(key => servicesAvailability[key] === false) &&
            isBooked &&
            isMobile) {
            setStep(StepType.Payment);
        }
    }, [isBooked]);
    const handlePriceChangedConfirmed = useCallback(() => {
        startBooking(innerFormValues);
    }, [startBooking, innerFormValues]);
    const { handleChangeFareLock, isSelected, price, fareLockService } = useFareLock(order, fillOrder);
    const renderFlights = useCallback(() => React.createElement(Flights, { order: order, backToResults: backToResults }), []);
    const renderForm = useCallback((inModal) => {
        if (inModal) {
            return (React.createElement(OverrideComponent, { componentProps: {
                    showToolbar: false,
                    showServices: false,
                    currentStep,
                    customer: order.customer,
                    passengers: order.travellers,
                    onFormSubmit,
                    isInternationalFlight: checkInternationalFlight(order.flight),
                    userInfo,
                    refetchUser,
                    inModal: true,
                    orderSubsidyState: orderSubsidyState,
                    backToResults: backToResults,
                    setNextStep
                }, component: {
                    CheckoutForm: Form
                } }));
        }
        else {
            if (isBooked) {
                if (isFareLockSelected && !isFareLockConfirmed) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                            isBooked && (React.createElement(FooterMobile, { onBack: goToPrevStep, onContinue: () => setStep(StepType.Payment) })),
                            isConfirmed && (React.createElement(PassengersToolbar, { setNextStep: setNextStep, currentStep: currentStep }))),
                        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, isBookedOrConfirmed && (isTravellersDataFilled || isFareLockSelected) && (React.createElement(PassengersToolbar, { setNextStep: setNextStep, currentStep: currentStep })))));
                }
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css.form },
                        React.createElement(OverrideComponent, { componentProps: {
                                showServices: false,
                                showContacts: false,
                                showForm: !isTravellersDataFilled,
                                currentStep,
                                customer: order.customer,
                                passengers: order.travellers,
                                onFormSubmit,
                                isInternationalFlight: checkInternationalFlight(order.flight),
                                userInfo,
                                refetchUser,
                                orderSubsidyState: orderSubsidyState,
                                backToResults: backToResults,
                                setNextStep
                            }, component: {
                                CheckoutForm: Form
                            } }))));
            }
            if (isNew || isConfirmed) {
                return (React.createElement(React.Fragment, null,
                    isNew && !isMobile && renderFlights(),
                    React.createElement("div", { className: css.form },
                        React.createElement(OverrideComponent, { componentProps: {
                                showContacts: isNew || (isConfirmed && !isMobile),
                                showForm: isNew || !isTravellersDataFilled,
                                showServices: false,
                                currentStep,
                                customer: order.customer,
                                passengers: order.travellers,
                                onFormSubmit,
                                isInternationalFlight: checkInternationalFlight(order.flight),
                                userInfo,
                                refetchUser,
                                orderSubsidyState: orderSubsidyState,
                                backToResults: backToResults,
                                setNextStep
                            }, component: {
                                CheckoutForm: Form
                            } }))));
            }
            return null;
        }
    }, [
        order.status,
        order.customer,
        order.travellers,
        isFareLockSelected,
        isFareLockConfirmed,
        currentStep,
        isTravellersDataFilled,
        userInfo,
        refetchUser,
        orderSubsidyState,
        setNextStep,
        customerSubscribed
    ]);
    const canceledFlights = React.useMemo(() => {
        return order.flight.segmentGroups.filter(group => group.segments.some(segment => segment.status === FlightSegmentStatus.Canceled));
    }, [order.flight]);
    const { loading: isDocumentsDownload, handleDownload, error: docError, warningIsOpen: docWarningIsOpen, warningClose: docWarningClose } = useDownloadOrderDocument();
    const isLoading = isBooking || isDocumentsDownload || promoCodesData.loading;
    let modalWarningContent = t('An unexpected error has occurred during the booking process. Please try again later or contact a customer support service.');
    let modalErrorMessage = bookingError ? t(getCroppedError(bookingError.message)) : '';
    if ((bookingError === null || bookingError === void 0 ? void 0 : bookingError.message) === 'Не удалось создать заказ с детьми без сопровождения совершеннолетними пассажирами' ||
        (bookingError === null || bookingError === void 0 ? void 0 : bookingError.message) === 'The child is too young to fly without an adult') {
        modalWarningContent = t('Placing an order with a child not accompanied by an adult is prohibited.');
        modalErrorMessage = t('The child is too young to fly without an adult');
    }
    if (modalErrorMessage.includes('НЕТ ВОЗМОЖНОСТИ ПОЛУЧИТЬ МЕСТА')) {
        modalErrorMessage = t('НЕТ ВОЗМОЖНОСТИ ПОЛУЧИТЬ МЕСТА');
    }
    return (React.createElement("div", { className: cn(css.checkout, {
            [css.checkout_margined]: isCancelled
        }) },
        isLoading ? React.createElement(SimpleLoader, null) : null,
        React.createElement(WarningModal, { title: t('Oops...'), content: modalWarningContent, errorMessage: modalErrorMessage, onClose: clearError, onButtonClick: clearError, buttonText: t('OK, thank you'), isOpen: !!bookingError }),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An unexpected error has occurred during the tickets downloading process. Please try again later or contact a customer support service.'), errorMessage: docError ? getCroppedError(docError.message) : '', onClose: docWarningClose, onButtonClick: docWarningClose, buttonText: t('OK, thank you'), isOpen: !!docWarningIsOpen }),
        React.createElement("div", null,
            isConfirmed && React.createElement(ThanksBanner, null),
            isConfirmed && canceledFlights.length > 0 && (React.createElement("div", { className: css.notice },
                WarningRounded,
                ' ',
                t('Attention, times have changed for flight {{route}}', {
                    route: `${canceledFlights[0].segments[0].departure.airport.city.name} - ${canceledFlights[0].segments[canceledFlights[0].segments.length - 1].arrival.airport.city
                        .name}`
                }))),
            !isReadonly && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { className: css.passengers__header },
                    React.createElement("h1", { className: css.passengers__header__title }, t('Passengers'))))),
            React.createElement(OrderExareStatus, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger }),
            isConfirmedOrCancelled && (React.createElement("div", { className: css.paidOrderWrapper },
                React.createElement(OrderCard, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger, onDownloadClick: handleDownload, order: order, isCheckinOpen: (_b = getCheckinInfoResponse === null || getCheckinInfoResponse === void 0 ? void 0 : getCheckinInfoResponse.CheckinInfo) === null || _b === void 0 ? void 0 : _b.isAvailable, type: 'review' }))),
            React.createElement("div", { className: css.summary__wrp },
                isBooked && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement("h3", { className: css.summary__header }, t('Review your trip')))),
                !!((_c = order.messages) === null || _c === void 0 ? void 0 : _c.length) && (React.createElement("div", { className: css.messages },
                    React.createElement(InfoMessages, { messages: order.messages, position: InfoMessagePositionsEnum.default, className: resultsCss.messages, itemHtmlClassName: resultsCss.messages__htmlMessage, itemClassName: css.messages_item }),
                    React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutPassengersNotifications: () => null } }))),
                !isConfirmed && isFareLockSelected && isBooked && (React.createElement("div", { className: css.fareLockWrapper },
                    React.createElement(FareLock, { freezeUntil: fareLockService.freezeUntil, price: price, onChange: handleChangeFareLock, isActive: isSelected, confirmed: isSelected || isFareLockConfirmed, addedToOrder: true }))),
                isBooked && (React.createElement("div", { className: css.summary },
                    React.createElement(OrderCard, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger, order: order, isCheckinOpen: ((_d = getCheckinInfoResponse === null || getCheckinInfoResponse === void 0 ? void 0 : getCheckinInfoResponse.CheckinInfo) === null || _d === void 0 ? void 0 : _d.isAvailable) || true, type: 'review' })))),
            (isBookedOrConfirmed || isConfirmedOrCancelled) && (React.createElement(OverrideComponent, { component: { CheckoutSelectedFlight: SelectedFlight }, componentProps: {
                    orderId: order.id,
                    flight: order.flight,
                    subsidized: order.hasSubsidizedFares,
                    confirmed: isConfirmedOrCancelled,
                    canceled: isCancelled
                } })),
            showServiceCards && isBookedOrConfirmed && !noAvailableServices && isTravellersDataFilled && (React.createElement(React.Fragment, null, Object.values(servicesAvailability).includes(true) && (React.createElement("div", { className: css.checkoutServices },
                servicesAvailability.Seat && (React.createElement(Seats, { isCheckinAvailable: (_e = getCheckinInfoResponse === null || getCheckinInfoResponse === void 0 ? void 0 : getCheckinInfoResponse.CheckinInfo) === null || _e === void 0 ? void 0 : _e.isAvailable })),
                servicesAvailability.Baggage && React.createElement(Baggage, null),
                servicesAvailability.Meal && React.createElement(Meal, null),
                servicesAvailability.Insurance && (React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutInsurance: OrderInsuranceBlockSlot } })),
                servicesAvailability.Aeroexpress && React.createElement(Aeroexpress, null),
                ((_f = order === null || order === void 0 ? void 0 : order.ffpInfo) === null || _f === void 0 ? void 0 : _f.ffpSupported) && (React.createElement(Loyalty, { userInfo: userInfo, refetchUser: refetchUser })),
                servicesAvailability.BusinessLounge && React.createElement(Business, null),
                servicesAvailability.PriorityBoarding && React.createElement(PriorityBoarding, null),
                servicesAvailability.BusinessLounge && React.createElement(CapsuleHotel, null),
                hasAnimal && animalServices.length > 0 && React.createElement(Animal, null))))),
            isNew && (React.createElement(ThemeProvider, { value: {
                    PromoMessage: {
                        PromoMessage: {
                            wrapper: css.promoMessageWrapper
                        }
                    }
                } },
                React.createElement(PromoMessages, null))),
            React.createElement(ThemeProvider, { value: detailsStyles }, renderForm()),
            !isBookedOrConfirmed && order.warnings && !!order.warnings.length && (React.createElement(Warnings, { warnings: order.warnings }))),
        !isBookedOrConfirmed && (React.createElement(Actualization, { startBooking: handlePriceChangedConfirmed, orderId: order.id, open: showActualizationResults, onClose: () => setShowActualizationResults(false), onSetOrder: onSetOrder, actualizationInfo: order.actualizationResult, backToResults: backToResults, showOkMessage: false })),
        React.createElement(PromoCodes, { isOpen: promoCodesData === null || promoCodesData === void 0 ? void 0 : promoCodesData.isOpen, onSubmit: promoCodesSubmitCallBack, onCancel: promoCodesCancelCallback, promoCodesInfo: (_g = promoCodesData === null || promoCodesData === void 0 ? void 0 : promoCodesData.data) === null || _g === void 0 ? void 0 : _g.promoCodesInfo, formValues: innerFormValues, error: (_h = promoCodesData === null || promoCodesData === void 0 ? void 0 : promoCodesData.data) === null || _h === void 0 ? void 0 : _h.error })));
};
export default Passengers;
