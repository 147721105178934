import * as React from 'react';
import cn from 'classnames';
import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
const DirectionsDialog = ({ groups, isOpen, onClose, onSelect }) => {
    const { DirectionsDialog: css } = useTheme('LocationAutocomplete');
    const { t } = useTranslation('LocationAutocomplete');
    const [selectedGroup, selectGroup] = useState(groups[0]);
    const getOnGroupSelect = (group) => () => selectGroup(group);
    const getOnLocationSelect = (location) => () => {
        onSelect(location);
        onClose();
    };
    return (React.createElement(Dialog, { open: isOpen, disableRestoreFocus: true, keepMounted: true, onClose: onClose, fullWidth: true, maxWidth: "md", classes: {
            root: css.root,
            paper: css.paper
        } },
        React.createElement("div", { className: css.dialog },
            React.createElement("div", { className: css.countries },
                React.createElement("div", { className: css.label }, t('Pick a country')),
                React.createElement("div", { className: css.countries__list }, groups.map((group, index) => (React.createElement("div", { key: index, "data-country": group.label, className: cn(css.country, {
                        [css.country_active]: group.label === selectedGroup.label
                    }), onClick: getOnGroupSelect(group) }, group.label))))),
            React.createElement("div", { className: css.cities },
                React.createElement("div", { className: css.label }, t('Pick a city')),
                React.createElement("div", null, selectedGroup.options.map(({ value, label }, index) => {
                    return (React.createElement("div", { key: index, className: css.city, onClick: getOnLocationSelect(value) },
                        React.createElement("div", null,
                            React.createElement("div", null, label),
                            value.stopPointsCount > 0 && (React.createElement("div", { className: css.stops },
                                value.stopPointsCount,
                                ' ',
                                t('Stop', {
                                    count: value.stopPointsCount
                                })))),
                        React.createElement("span", { className: css.iata }, value.iata)));
                }))),
            React.createElement("div", { className: css.closer, onClick: onClose },
                React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { d: "M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z", fill: "#ffffff" }))))));
};
export default DirectionsDialog;
