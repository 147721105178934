import * as React from 'react';
import { captureErrorSentry } from '../utils';
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null
        };
    }
    static getDerivedStateFromError(error) {
        return {
            error: error
        };
    }
    componentDidCatch(error, errorInfo) {
        captureErrorSentry(`${error === null || error === void 0 ? void 0 : error.name}: ${error === null || error === void 0 ? void 0 : error.message}`);
    }
    render() {
        const { children, renderFallback } = this.props;
        const { error } = this.state;
        if (error) {
            return renderFallback(error);
        }
        return children;
    }
}
