import { __awaiter } from "tslib";
import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { UserSettings } from '../../types';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useSetUserSettingsMutation } from '@websky/graphql';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loader from '../../../Loader';
const MailSettings = ({ onError, userSettings, userEmail, refetchUserSettings }) => {
    const [setSettings] = useSetUserSettingsMutation();
    const [isLoading, setLoading] = React.useState(false);
    const theme = useTheme('Account').Account, { t } = useTranslation('Account');
    const setUserSettings = (settingName, value) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const res = yield setSettings({
                variables: {
                    params: [
                        {
                            name: settingName,
                            value: value ? 'true' : 'false'
                        }
                    ]
                }
            });
            if (res.errors && res.errors.length) {
                onError(res.errors[0]);
            }
        }
        catch (error) {
            onError(error);
        }
    });
    const isChecked = (userSettings === null || userSettings === void 0 ? void 0 : userSettings.find(set => set.name === UserSettings.Subscribe).value) === 'true';
    const onChange = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        yield setUserSettings(UserSettings.Subscribe, !isChecked);
        yield refetchUserSettings();
        setLoading(false);
    });
    return (React.createElement("div", { className: theme.account__offers },
        React.createElement("div", { className: theme.account__subscription },
            React.createElement("div", { className: theme.subscription__title }, t('Email for notification')),
            React.createElement("div", { className: theme.subscription__value }, userEmail)),
        React.createElement(FormControlLabel, { control: isLoading ? (React.createElement(Loader, { className: theme.subscription__loader })) : (React.createElement(Checkbox, { classes: {
                    root: theme.checkbox,
                    checked: theme.checked,
                    colorSecondary: theme.colorSecondary
                } })), onClick: onChange, disabled: isLoading, checked: isChecked, label: !isLoading && t('I want to receive news and special offers'), classes: {
                label: theme.mui__label
            } })));
};
export default MailSettings;
