import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../theme';
import { useCallback } from 'react';
const Link = ({ action, className, children, target }) => {
    const { Link: css } = useTheme('Link');
    const onClick = typeof action === 'function'
        ? useCallback((e) => {
            e.preventDefault();
            action(e);
        }, [action])
        : null;
    return (React.createElement("a", { target: target, className: cn(css.link, className), href: typeof action === 'string' ? action : '#', onClick: onClick }, children));
};
export default Link;
