import { useMemo } from 'react';
import { OrderStatus } from './types';
import { concatPassengerNames, format, getPassengerNames, removeDotsFromDate } from '../utils';
import { OrderStatus as OrderStatusQraphql } from '@websky/graphql';
import { isSameMonth, isSameYear } from 'date-fns';
export const usePassengers = (order) => {
    return useMemo(() => {
        var _a;
        return (_a = order.travellers) === null || _a === void 0 ? void 0 : _a.map(traveller => {
            const { lastName, firstName, middleName } = getPassengerNames(traveller);
            return concatPassengerNames(lastName, firstName, middleName);
        }).join(', ');
    }, [order.travellers]);
};
export const useStatus = (order, isCheckinAvailable, type) => {
    return useMemo(() => {
        if (isCheckinAvailable && type === 'account') {
            return OrderStatus.OnlineCheckIn;
        }
        else if (order.status === OrderStatusQraphql.Booked) {
            return OrderStatus.WaitPayment;
        }
        else if (order.status === OrderStatusQraphql.Confirmed) {
            return OrderStatus.Confirmed;
        }
        return null;
    }, [order.status, isCheckinAvailable]);
};
export const getReadablePeriod = (order) => {
    const dates = [];
    order.flight.segmentGroups.forEach(group => {
        const departureDate = new Date(group.segments[0].departure.date);
        dates.push(departureDate);
    });
    return getReadablePeriodFromDates(dates);
};
export const getReadablePeriodFromDates = (dates) => {
    const readableDates = []; // FIFO стек
    let withNotSameYear = false;
    let withNotSameMonth = false;
    let lastDate = dates.shift(); // получаем первую дату
    while (dates.length) {
        // пока стек не опустеет
        // если несколько дат
        const nextDate = dates.shift(); // получаем следующую дату для сравнения
        if (isSameYear(lastDate, nextDate) && !withNotSameYear) {
            if (isSameMonth(lastDate, nextDate) && !withNotSameMonth) {
                // если у дат одинаковые месяцы и год и дата на предудущей итерации у дат был одинаковый месяц и год,
                // то от первой даты записываем только день
                readableDates.push(format(lastDate, 'd'));
            }
            else {
                // если у дат одинаковый год, то от первой даты записываем только день и месяц
                readableDates.push(format(lastDate, 'd MMM'));
                // сохраняем флаг, что у дат разные месяцы (для следующей итерации).
                // если уже withNotSameMonth = true, то инвертируем
                withNotSameMonth = !withNotSameMonth;
            }
        }
        else {
            // если у дат разные года, то записываем первую дату полностью
            readableDates.push(removeDotsFromDate(format(lastDate, 'd MMM, yyyy')));
            withNotSameYear = !withNotSameYear;
            withNotSameMonth = !withNotSameMonth;
        }
        lastDate = nextDate;
    }
    // последнюю (или единственную, если кол-во дат = 1) дату  записываем полностью
    readableDates.push(removeDotsFromDate(format(lastDate, 'd MMM, yyyy')));
    return readableDates.join(' - ');
};
