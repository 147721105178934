import classnames from 'classnames';
import * as React from 'react';
import { useTheme } from '../../../../../theme';
import { RendererFunctionsContext } from '../../../Datepicker';
export const Day = (props) => {
    const theme = useTheme('Datepicker').DayStyles;
    return (React.createElement("div", { className: classnames(theme.day, props.day.customClass, {
            [theme.day_fromOtherMonth]: props.day.fromOtherMonth,
            [theme.day_selected]: props.day.isSelected && !props.day.fromOtherMonth,
            [theme.day_startPeriod]: props.day.isStartPeriod,
            [theme.day_inPeriod]: props.day.inPeriod,
            [theme.day_endPeriod]: props.day.isEndPeriod,
            [theme.day_notAvailable]: !props.day.isAvailable,
            [theme.day_weekend]: props.day.isWeekend,
            [theme.day_today]: props.day.isToday,
            [theme.day_highlighted]: props.day.customClass,
            [theme.day_startHover]: props.day.isStartHover,
            [theme.day_endHover]: props.day.isEndHover
        }), onMouseOver: () => {
            if (props.day.isAvailable && props.onHover) {
                props.onHover(props.day.date);
            }
        }, onMouseOut: () => {
            if (props.day.isAvailable && props.onHover) {
                props.onHover(null);
            }
        }, onClick: () => {
            props.day.isAvailable ? props.onSelect(props.day.date) : null;
        } },
        React.createElement(RendererFunctionsContext.Consumer, null, data => {
            if (props.day.fromOtherMonth) {
                return null;
            }
            return data.dayRenderer(props.day.date, theme.day__inside);
        })));
};
