import { __awaiter } from "tslib";
import React from 'react';
import { useTheme } from '../../../theme';
import { PromoCodeCard } from './PromoCodeCard/PromoCodeCard';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { PaximizerCommunicationSectionEnum, useGetPaxMyPromoCodeTeasersQuery } from '@websky/graphql';
import InfiniteScroll from 'react-infinite-scroller';
import { useCommunicationList } from '../../hooks';
import Loader from '../../../Loader';
import { CommunicationPageWrapper } from '../CommunicationPageWrapper/CommunicationPageWrapper';
export const PromoCodesPage = () => {
    const { Communication: css } = useTheme('Communications');
    const { path } = useRouteMatch();
    const { loading, refetch } = useGetPaxMyPromoCodeTeasersQuery({ skip: true });
    const loadPromoCodes = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield refetch({
            offset: values.offset,
            count: values.count
        });
        const notification = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationMyPromoCodeTeasers;
        return {
            items: (notification === null || notification === void 0 ? void 0 : notification.items.length) ? notification.items : [],
            total: (notification === null || notification === void 0 ? void 0 : notification.total) || 0
        };
    });
    const { readIds, markAsRead, items: promoCodes, loadMore, pagination, errorMessage } = useCommunicationList(PaximizerCommunicationSectionEnum.my_promo_codes, loadPromoCodes, loading);
    return (React.createElement(CommunicationPageWrapper, { type: PaximizerCommunicationSectionEnum.my_promo_codes, pagination: { total: pagination.total, hasMore: pagination.hasMore }, errorMessage: errorMessage },
        React.createElement(InfiniteScroll, { loadMore: loadMore, hasMore: pagination.hasMore, loader: React.createElement(Loader, { key: "promocodes-loader" }), pageStart: pagination.pageLoaded },
            React.createElement("div", { className: css.list }, promoCodes.map(item => (React.createElement(Link, { key: item.id, to: item.locator ? `${path}/${item.id}?locator=${item.locator}` : `${path}/${item.id}` },
                React.createElement(PromoCodeCard, { title: item.title, code: item.code, endDate: item.endDate, isRead: item.read || readIds[item.id], isReusable: item.isReusable, isPersonal: item.isPersonal, usedAt: item.usedAt, markAsRead: () => markAsRead(item.id) }))))))));
};
