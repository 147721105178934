import * as React from 'react';
import { StepsEnum } from '../types';
import { CheckinStep } from '../Modules/Checkin/types';
import { overridedIcons } from '../Icons';
export const arrowIcon = (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { opacity: "0.4" },
        React.createElement("path", { d: "M9 3L7.9425 4.0575L12.1275 8.25H3V9.75H12.1275L7.9425 13.9425L9 15L15 9L9 3Z", fill: "white" }))));
export const stepIcons = {
    [StepsEnum.Flights]: overridedIcons['Plane'],
    [StepsEnum.Seats]: overridedIcons['ServicesSeatSelectionIcon'],
    [StepsEnum.Baggage]: overridedIcons['BaggageCheckedInIcon'],
    [StepsEnum.Meal]: overridedIcons['Meal'],
    [StepsEnum.Passengers]: overridedIcons['Person'],
    [StepsEnum.Insurance]: overridedIcons['Umbrella'],
    [StepsEnum.Order]: overridedIcons['File'],
    [StepsEnum.Payment]: overridedIcons['Payment'],
    [StepsEnum.Extras]: overridedIcons['SeatSelection'],
    [StepsEnum.BoardingPass]: overridedIcons['Booking'],
    [CheckinStep.Passengers]: overridedIcons['Plane'],
    [CheckinStep.Seats]: overridedIcons['SeatSelection'],
    [CheckinStep.Baggage]: overridedIcons['BaggageCheckedInIcon'],
    [CheckinStep.Meal]: overridedIcons['Meal'],
    [CheckinStep.Extras]: overridedIcons['Umbrella'],
    [CheckinStep.Upgrade]: overridedIcons['Ticket'],
    [CheckinStep.Payment]: overridedIcons['Payment']
};
