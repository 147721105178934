import { __awaiter } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCheckinUpdateTravellersMutation } from '@websky/graphql';
import CheckinAddDocuments from '../CheckinAddDocumentsContent/CheckinAddDocuments';
import { getCheckinOrderId, getTravellers } from '../../../../../store/order/selectors';
import Dialog from '../../../../../../../Service/components/Dialog/Dialog';
import { prepareBookingRequest } from '../../../../../../../Checkout/utils';
import { fillCheckinOrder } from '../../../../../store/order/actions';
import SimpleLoader from '../../../../../../../SimpleLoader';
import { initI18n } from '../../../../../../../utils';
import { useTheme } from '../../../../../../../theme';
initI18n('CheckinAddDocuments');
const CheckinAddDocumentsService = ({ isOpen, onDocumentsUpdated }) => {
    const { t } = useTranslation('CheckinAddDocuments');
    const { CheckinAddDocumentsService: theme } = useTheme('Checkin');
    const dispatch = useDispatch();
    const travellers = useSelector(getTravellers);
    const orderId = useSelector(getCheckinOrderId);
    const [updateTravellersValues, { loading, data: updateTravellersResponse }] = useCheckinUpdateTravellersMutation();
    const handleSubmit = (passengers) => __awaiter(void 0, void 0, void 0, function* () {
        const req = prepareBookingRequest(orderId, { customer: {}, passengers });
        yield updateTravellersValues({
            variables: {
                params: {
                    orderId,
                    travellersData: req.params.travellers
                }
            }
        });
    });
    useEffect(() => {
        if (updateTravellersResponse) {
            dispatch(fillCheckinOrder(updateTravellersResponse.CheckinSetTravellersData));
            onDocumentsUpdated();
        }
    }, [updateTravellersResponse]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { dialogHeader: t('Add documents'), onClose: null, isOpen: isOpen, dialogType: 'fullscreen', dialogCloseClassName: theme.closeClassName, content: React.createElement(React.Fragment, null,
                loading && React.createElement(SimpleLoader, null),
                React.createElement(CheckinAddDocuments, { travellers: travellers, onSubmit: handleSubmit })) })));
};
export default CheckinAddDocumentsService;
