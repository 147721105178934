import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
const Point = ({ className, time, airport, arrival, thin, mobile, date }) => {
    const theme = useTheme('DesktopFlightRoute').PointStyles;
    return (React.createElement("div", { className: cn(theme.point, {
            [theme.point_arrival]: arrival,
            [theme.point_thin]: thin,
            [theme.mobile]: mobile
        }, className) },
        React.createElement("div", { className: theme.date__container },
            date && React.createElement("div", { className: theme.date }, date),
            React.createElement("div", { className: theme.time }, time),
            React.createElement("div", { className: theme.airport },
                airport.city.name ? airport.city.name : airport.name,
                " (",
                airport.iata,
                ")"))));
};
export default Point;
