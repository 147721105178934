import * as React from 'react';
import BaggagePassenger from '../../BaggagePassenger/BaggagePassenger';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
const AllFlight = props => {
    const commonTheme = useTheme('UpgradeBaggageDialog').UpgradeBaggageDialog;
    return (React.createElement("div", { className: cn(commonTheme.body, { [commonTheme.withTabs]: !!props.baggageTab }) }, props.passengers.map((passenger, i) => {
        const label = props.passengerLabels[passenger.id];
        const number = i + 1;
        return (React.createElement(BaggagePassenger, { name: label, number: number, key: passenger.id, includedSegment: true, baggageByRfics: props.upgradeBaggages, mobileSelectedServiceId: props.mobileSelectedServiceId, passenger: passenger }));
    })));
};
export default AllFlight;
