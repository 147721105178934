import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import StepTitle from '../StepTitle/StepTitle';
import ReasonForm from '../ReasonForm/ReasonForm';
import StepsButtons from '../StepsButtons/StepsButtons';
import MobileStep from '../MobileStep/MobileStep';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { useTheme } from '../../../theme';
import { overridedIcons, Refundable } from '../../../Icons';
import { StepType, Unvoluntary } from '../../types';
import { useSteps } from '../Refund/hooks';
import { selectReason } from '../../store/reason/actions';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { getSelectedReason } from '../../store/reason/selectors';
import PromoLoader from '../../../PromoLoader';
const Reasons = props => {
    const { t } = useTranslation('Refund');
    const { Refund: css } = useTheme('Refund');
    const { currentStep, canGoToNextStep, goToNextStep, goToPrevStep, isCurrentActiveStepAfter, handleMobileCloseClick } = useSteps();
    const dispatch = useDispatch();
    const selectedReason = useSelector(getSelectedReason);
    const isReasonsStepActive = currentStep.type === StepType.Reason;
    const renderMobileTitle = useCallback(() => (React.createElement(React.Fragment, null, selectedReason === Unvoluntary.Unvoluntary ? (React.createElement("span", null, currentStep.type === StepType.Reason
        ? t('Choose the reason for refund')
        : t('Voluntary refund'))) : (React.createElement(React.Fragment, null,
        React.createElement("span", null, currentStep.type === StepType.Reason ? (t('Choose the reason for refund')) : (React.createElement(React.Fragment, null,
            t('Involuntary refund'),
            React.createElement("p", { className: css.reasonDescr }, t(selectedReason === null || selectedReason === void 0 ? void 0 : selectedReason.toString()))))))))), [currentStep.type, selectedReason]);
    const reasons = useMemo(() => {
        var _a;
        return [
            {
                title: t('Voluntary refund'),
                reasons: [
                    {
                        title: t('my plans have changed'),
                        checked: selectedReason === Unvoluntary.Unvoluntary.toString(),
                        value: Unvoluntary.Unvoluntary
                    }
                ]
            },
            ((_a = props.reasonsGroups) === null || _a === void 0 ? void 0 : _a.length) && {
                title: t('Involuntary refund'),
                reasons: props.reasonsGroups.map(reason => ({
                    checked: selectedReason === reason.title,
                    value: reason.value,
                    title: t(reason.title)
                }))
            }
        ].filter(Boolean);
    }, [selectedReason, props.reasonsGroups]);
    const renderContent = useCallback(() => isReasonsStepActive && (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(StepTitle, { className: css.stepTitle, icon: overridedIcons['Choosed'], title: t('Choose the reason for refund') })),
        React.createElement(ReasonForm, { reasons: reasons, onReasonSelect: reason => dispatch(selectReason(reason)) }),
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(StepsButtons, { className: css.buttons, position: 'center', backButton: { children: t('Back'), onClick: goToPrevStep }, nextButton: {
                    children: t('Continue'),
                    onClick: goToNextStep,
                    disabled: !canGoToNextStep
                } })))), [props, isReasonsStepActive]);
    if (props.isLoading) {
        return React.createElement(PromoLoader, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: 'mobile' }, renderContent()),
        currentStep.type !== StepType.Passengers && (React.createElement(MobileStep, { icon: Refundable, onCloseClick: handleMobileCloseClick(StepType.Reason), isActive: currentStep.type === StepType.Reason, className: cn(css.mobileStep, {
                [css.passed]: isCurrentActiveStepAfter(StepType.Reason)
            }), renderTitle: renderMobileTitle }, renderContent()))));
};
export default Reasons;
