import { __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useTheme } from '../../../theme';
const ActionButton = (_a) => {
    var { size = 'common', white } = _a, props = __rest(_a, ["size", "white"]);
    const { ActionButton: css, WhiteButton: whiteButtonStyles } = useTheme('Button');
    const { children, className, onClick } = props, restProps = __rest(props, ["children", "className", "onClick"]);
    return (React.createElement(ButtonBase, Object.assign({ type: "button" }, restProps, { className: cn(css.button, {
            [css.button_small]: size === 'small',
            [whiteButtonStyles.button]: !!white
        }, className), onClick: restProps.disabled ? null : onClick }), children));
};
export default ActionButton;
