import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { checkIconBig } from '../../Icons';
import { useTranslation } from 'react-i18next';
const List = ({ categories, selectedCategory, onSelect }) => {
    const { List: css } = useTheme('MealService');
    const { t } = useTranslation('MealService');
    return (React.createElement("div", { className: css.wrapper }, categories.map(item => (React.createElement("div", { key: item.title, title: t(item.title), className: cn(css.item, {
            [css.item_selected]: selectedCategory === item.id
        }), onClick: () => onSelect(item.id) },
        selectedCategory === item.id && React.createElement("span", { className: css.item__icon }, checkIconBig),
        t(item.title))))));
};
export default List;
