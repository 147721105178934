import * as React from 'react';
import MUITabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import cn from 'classnames';
import { useTheme } from '../theme';
const Tabs = props => {
    const { Tabs: theme } = useTheme('Tabs');
    return (React.createElement("div", { ref: props.tabsWrp, className: cn(theme.container, props.className, { [theme.container_rounded]: props.style === 'rounded' }) },
        React.createElement(MUITabs, { scrollButtons: props.scrollButtons, value: props.selected, classes: {
                root: cn(theme.tab, props.rootTabsClassName, { [theme.tab_rounded]: props.style === 'rounded' }),
                indicator: cn(theme.tab__indicator, props.indicatorClassName),
                flexContainer: cn(props.flexContainerClassName, {
                    [theme.tabs__container_center]: props.tabsAlign === 'center',
                    [theme.tabs__container_right]: props.tabsAlign === 'right'
                }),
                scroller: theme.scroller
            }, variant: props.variant, onChange: (e, value) => props.onChange(value) }, props.tabs.map(tab => (React.createElement(Tab, { disabled: tab.disabled, disableRipple: props.style !== 'rounded', value: tab.value, key: tab.value, classes: {
                root: cn(theme.tab, props.tabClassName, { [theme.tab_rounded]: props.style === 'rounded' }),
                selected: cn(theme.tab_selected, props.selectedTabClassName)
            }, label: React.createElement("div", { className: theme.tab__content },
                React.createElement("div", { title: tab.header, className: cn(theme.route, props.tabHeaderClassName) }, tab.header),
                props.style !== 'rounded' && (React.createElement("div", { className: cn(theme.desc, props.tabLabelClassName) },
                    React.createElement("span", { className: theme.seats__count }, tab.label)))) }))))));
};
export default Tabs;
