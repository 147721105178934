import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { BaggageItem } from '../../../BaggageList';
import { convertBaggageOnBaggageItem } from '../../../AdditionalBaggage/utils';
import { useBaggageSize } from '../../../../Baggage/Baggage/hooks';
import { BaggageSize } from '../../../../Baggage/Baggage/types';
const Upgrade = props => {
    const { IncludedBaggage: cssIncluded } = useTheme('IncludedBaggage');
    const { t } = useTranslation('IncludedBaggage');
    if (!props.upgradeServices[0]) {
        return null;
    }
    const upgradeItems = useMemo(() => {
        return props.upgradeServices.map(services => {
            return convertBaggageOnBaggageItem(services, props.selectedServices, props.segments);
        });
    }, [props.upgradeServices, props.selectedServices, props.segments]);
    return (React.createElement("div", { className: cn(props.className) },
        React.createElement("div", { className: cssIncluded.header }, t('Upgrade')),
        React.createElement("div", { className: cssIncluded.body }, upgradeItems.map(item => {
            const size = useBaggageSize(item.baggage[0]);
            return React.createElement(BaggageItem, Object.assign({ realIconSize: size !== BaggageSize.small, key: item.baggage[0].id }, item));
        }))));
};
export default Upgrade;
