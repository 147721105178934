import { ADD_PASSENGER, REMOVE_PASSENGER, SET_PASSENGERS, CLEAR_PASSENGERS } from './actions';
export const MAX_PASSENGERS_COUNT = 9;
const defaultState = {
    ADT: 1,
    PDA: 0,
    CLD: 0,
    INF: 0,
    INS: 0
};
export const passengersReducer = (state = defaultState, { payload, type }) => {
    switch (type) {
        case ADD_PASSENGER: {
            const { passengerType, modifyCount = 1 } = payload;
            return Object.assign(Object.assign({}, state), {
                [passengerType]: state[passengerType] = (state[passengerType] || 0) + modifyCount
            });
        }
        case REMOVE_PASSENGER: {
            const { passengerType, modifyCount = 1 } = payload;
            return Object.assign(Object.assign({}, state), { [passengerType]: state[passengerType] -= modifyCount });
        }
        case SET_PASSENGERS:
            return Object.assign(Object.assign({}, state), payload);
        case CLEAR_PASSENGERS:
            for (const passengerType in state) {
                state[passengerType] = 0;
            }
            return Object.assign({}, state);
        default:
            return state;
    }
};
