import * as React from 'react';
import { ForgotPasswordState } from '../ForgotPassword';
import { useField } from 'react-final-form';
import Input from '../../../../../../Input';
import { useTheme } from '../../../../../../theme';
import { useTranslation } from 'react-i18next';
import { emailRegex } from '../../../../../../utils';
const Fields = ({ state }) => {
    const { t } = useTranslation('Loyalty');
    const emailField = useField('email', {
        validate: value => {
            if (state === ForgotPasswordState.Email) {
                if (!value) {
                    return t('Enter your email');
                }
                else if (!emailRegex.test(value)) {
                    return t('Account:Please enter a valid email');
                }
                else {
                    return undefined;
                }
            }
            return undefined;
        },
        type: 'text'
    });
    const cardNumberField = useField('cardNumber', {
        validate: value => {
            if (!value && state === ForgotPasswordState.Code) {
                return t('Required');
            }
            return undefined;
        },
        type: 'text'
    });
    const code = useField('code', {
        validate: value => {
            if (!value && state === ForgotPasswordState.Code) {
                return t('Required');
            }
            return undefined;
        },
        type: 'text'
    });
    const theme = useTheme('Loyalty').ForgotPassword;
    return (React.createElement("div", null,
        React.createElement("div", { hidden: state !== ForgotPasswordState.Email },
            React.createElement(Input, { label: t('Enter your email'), className: theme.field, TextFieldProps: Object.assign(Object.assign({}, emailField.input), { helperText: ((emailField.meta.modified && emailField.meta.touched) || emailField.meta.submitFailed) &&
                        emailField.meta.error, error: ((emailField.meta.modified && emailField.meta.touched) || emailField.meta.submitFailed) &&
                        !!emailField.meta.error }) })),
        React.createElement("div", { hidden: state !== ForgotPasswordState.Code },
            React.createElement(Input, { label: t('Verification code'), className: theme.field, TextFieldProps: Object.assign(Object.assign({}, code.input), { helperText: ((code.meta.modified && code.meta.touched) || code.meta.submitFailed) && code.meta.error, error: ((code.meta.modified && code.meta.touched) || code.meta.submitFailed) && !!code.meta.error }) }),
            React.createElement(Input, { label: t('Card number'), className: theme.field, TextFieldProps: Object.assign(Object.assign({}, cardNumberField.input), { helperText: ((cardNumberField.meta.modified && cardNumberField.meta.touched) ||
                        cardNumberField.meta.submitFailed) &&
                        cardNumberField.meta.error, error: ((cardNumberField.meta.modified && cardNumberField.meta.touched) ||
                        cardNumberField.meta.submitFailed) &&
                        !!cardNumberField.meta.error }) }))));
};
export default Fields;
