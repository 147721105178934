import * as React from 'react';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import Button from '../../../../Button/Button';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
const PriceChanged = props => {
    const theme = useTheme('Checkout').PriceChangedStyles;
    const isNewPriceLower = props.newPrice.amount < props.oldPrice.amount;
    const { t } = useTranslation();
    return (React.createElement("div", { className: cn(theme.wrapper, { [theme.price_lower]: isNewPriceLower }) },
        React.createElement("div", { className: theme.header },
            React.createElement("span", null, t(isNewPriceLower
                ? 'Checkout:Wow, the price has changed to'
                : 'Checkout:Sorry, the price has changed to')),
            React.createElement(Money, { moneyClassName: theme.price, money: props.newPrice })),
        isNewPriceLower && (React.createElement("div", { className: theme.old_price },
            React.createElement("span", null, t('Checkout:old price')),
            React.createElement(Money, { moneyClassName: cn(theme.price, theme.crossed), money: props.oldPrice }))),
        React.createElement("div", { className: theme.desc }, t('Checkout:We recommend buying your tickets now to avoid another price increase.')),
        React.createElement("div", { className: theme.buttons },
            React.createElement(Button, { onClick: props.onChangeFlight, className: theme.changeFlight }, t('Checkout:Change flight')),
            React.createElement(Button, { onClick: props.bookFlight }, t('Checkout:Continue')))));
};
export default PriceChanged;
