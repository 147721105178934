import React from 'react';
import { useTheme } from '../../../theme';
import { Dialog } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { initI18n } from '../../../utils';
import classNames from 'classnames';
import { GrowTransition, SlideUpTransition } from '../Transitions';
import { useSwipeDown } from '../hooks';
import CloseButton from '../../../BaseComponents/Button/components/CloseButton/CloseButton';
initI18n('ModalX');
const ModalX = ({ size = 'small', classes, children, isOpen = false, onClose, scroll = 'paper', title, footer, ignoreClose, noPadding, keepMounted = true }) => {
    const theme = useTheme('BaseComponents').ModalX;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const closeButton = onClose ? (React.createElement(CloseButton, { onClick: onClose, variant: "closePopup", classes: { root: classNames(theme.closeButton, classes === null || classes === void 0 ? void 0 : classes.closeButton) } })) : null;
    const { handlers, contentRef, swipeBlockRef, swipeFxRef, paperRef } = useSwipeDown({
        scroll,
        onClose,
        isMobile,
        isOpen
    });
    const dialogHandlers = scroll === 'body' ? null : !ignoreClose ? Object.assign({}, handlers) : null;
    const swipeBlockHandlers = scroll === 'body' ? (!ignoreClose ? Object.assign({}, handlers) : null) : null;
    return (React.createElement(Dialog, Object.assign({ keepMounted: keepMounted, disableAutoFocus: true }, dialogHandlers, { fullScreen: size === 'fullscreen', scroll: scroll, fullWidth: true, TransitionComponent: isMobile || size === 'fullscreen' ? SlideUpTransition : GrowTransition, PaperProps: {
            ref: paperRef
        }, open: isOpen, onClose: ignoreClose ? null : onClose, classes: {
            root: classNames(theme.root, classes === null || classes === void 0 ? void 0 : classes.root),
            container: theme.dialog_container,
            paper: classNames(theme.paper, theme[size], classes === null || classes === void 0 ? void 0 : classes.paper, size === 'fullscreen' ? theme.paper__fullScreen : null, noPadding ? theme.paper__noPadding : null),
            paperScrollBody: theme.paper_paperScrollBody
        } }),
        isMobile && onClose && !ignoreClose && (React.createElement("div", Object.assign({ ref: swipeBlockRef, className: classNames(theme.swipeBlock, classes === null || classes === void 0 ? void 0 : classes.swipeBlock) }, swipeBlockHandlers),
            React.createElement("div", { className: theme.swipeBlock_line, ref: swipeFxRef }))),
        !ignoreClose && closeButton,
        React.createElement("div", { className: theme.top_wrapper, onMouseDown: e => e.stopPropagation() },
            title,
            React.createElement("div", { className: theme.content, ref: contentRef }, children)),
        footer));
};
export default ModalX;
