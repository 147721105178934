import React from 'react';
import { CategoryCard } from './CategoryCard/CategoryCard';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import MediaQuery from '../../../MediaQuery/MediaQuery';
export const CategoriesPage = props => {
    const css = useTheme('Communications').Communication;
    const { t } = useTranslation('Communications');
    const { url } = useRouteMatch();
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: 'tablet' },
            React.createElement("h1", { className: css.title }, t('Communications'))),
        React.createElement("div", { className: css.list }, props.categories.map((item, index) => (React.createElement(Link, { key: item.id, to: `${url}/${item.path}` },
            React.createElement(CategoryCard, { title: t(item.title), icon: item.icon, unreadCount: item.unreadMessagesCount, lastMessage: item.lastMessage })))))));
};
