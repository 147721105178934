import * as React from 'react';
import { useContext } from 'react';
import cn from 'classnames';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { usePassengers } from '../../hooks';
import { OrderCardContext } from '../OrderCard';
import { Passengers } from '../../../Icons';
import { useToggleable } from '../../../hooks';
import CommonItem from '../CommonItem/CommonItem';
import Modal from '../../../Modal';
import PassengersDetails from '../../../PassengersDetails';
const OrderPassengers = ({ className, goToChangePassenger }) => {
    var _a;
    const { OrderPassengers: css } = useTheme('OrderCard');
    const { t } = useTranslation('OrderCard');
    const { order, type } = useContext(OrderCardContext);
    const passengers = usePassengers(order);
    const { isOpen, open, close } = useToggleable(false);
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    return (React.createElement(React.Fragment, null,
        React.createElement(CommonItem, { icon: (type === 'review' || isMobile) && Passengers, type: type, text: React.createElement(React.Fragment, null,
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    t('Passengers'),
                    ": "),
                passengers), className: cn(css.root, className, {
                [css.account]: type === 'account'
            }), buttons: type === 'review' && (React.createElement("span", { className: css.control, onClick: open }, t('Details'))) }),
        type === 'review' && (React.createElement(Modal, { classes: {
                paper: css.paper
            }, open: isOpen, bottomDrawer: isMobile, onClose: close, closeButtonRenderer: () => null, maxWidth: 'sm' },
            React.createElement(PassengersDetails, { orderId: order.id, orderStatus: order.status, travellers: order.travellers, changeTravellersAvailability: (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.changeTravellerDataAvailability, goToChangePassenger: goToChangePassenger, onClose: close })))));
};
export default OrderPassengers;
