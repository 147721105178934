import { Checkbox } from '@material-ui/core';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { AirplaneIcon } from '../icons';
import { format, initI18n } from '../../utils';
initI18n('SelectSegment');
const SelectableSegment = props => {
    var _a, _b, _c;
    const { segment, selected, isLoading, isDisabled, onChange } = props;
    const { t } = useTranslation('SelectSegment');
    const { SelectableSegmentMobile: css } = useTheme('SelectableSegment');
    const handleSelect = useCallback(() => {
        if (!isLoading && !isDisabled) {
            onChange(segment);
        }
    }, [segment, selected, isLoading, isDisabled, onChange]);
    return (React.createElement("div", { onClick: handleSelect, className: cn(css.wrapper, {
            [css.wrapper_disabled]: isDisabled
        }) },
        React.createElement("div", { className: css.flightInfo_mobile },
            React.createElement("div", { className: css.flightInfo__time },
                React.createElement("span", null, format(segment.departure.date, 'dd MMM yyyy')),
                React.createElement("span", null, segment.departure.time.substr(0, 5))),
            React.createElement("div", { className: css.flightInfo__iatas },
                React.createElement("span", null, segment.departure.airport.iata),
                React.createElement("span", { className: css.planeIcon },
                    React.createElement(AirplaneIcon, null)),
                React.createElement("span", null, segment.arrival.airport.iata)),
            React.createElement("div", { className: css.flightInfo__cities },
                React.createElement("span", null, segment.departure.airport.city.name),
                React.createElement("span", null, segment.arrival.airport.city.name)),
            React.createElement("div", { className: css.mobileSegment__bottom },
                React.createElement("div", null,
                    t('Flight'),
                    ": ", (_c = (_b = (_a = segment === null || segment === void 0 ? void 0 : segment.fareFamilies) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.fareFamily) === null || _c === void 0 ? void 0 :
                    _c.title,
                    "\u00A0",
                    segment.flightNumber),
                React.createElement("div", null,
                    React.createElement("label", { onClick: handleSelect },
                        t('Select'),
                        ":\u00A0",
                        React.createElement(Checkbox, { color: "primary", classes: {
                                colorPrimary: css.checkbox,
                                checked: css.checkbox_checked
                            }, checked: selected, onChange: handleSelect, disabled: isLoading || isDisabled })))))));
};
export default SelectableSegment;
