import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { isCheckoutOrderGuard } from '../../../../typeguards';
export const initialStateFactory = (order) => {
    var _a, _b, _c, _d, _e;
    const state = {
        activeService: null,
        services: {}
    };
    const transferServices = [];
    for (const service of (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) {
        if (service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
            service.subType ===
                GdsServiceVipSubType.GroundTransportation) {
            transferServices.push(service);
        }
    }
    if (isCheckoutOrderGuard(order)) {
        for (const transferService of transferServices) {
            for (const serviceSegmentGroup of transferService.allowedSegments) {
                for (const segmentId of serviceSegmentGroup) {
                    for (const allowedPassengerGroup of transferService.allowedPassengers) {
                        for (const allowedPassenger of allowedPassengerGroup) {
                            const traveller = order.travellers.find(traveller => traveller.id === allowedPassenger);
                            const travellerTransferService = (_e = (_d = (_c = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.find(travellerService => travellerService.serviceId === transferService.id);
                            if (!state.services[allowedPassenger]) {
                                state.services[allowedPassenger] = {};
                            }
                            if (!state.services[allowedPassenger][segmentId]) {
                                state.services[allowedPassenger][segmentId] = [
                                    {
                                        service: transferService,
                                        selected: (travellerTransferService === null || travellerTransferService === void 0 ? void 0 : travellerTransferService.count) > 0,
                                        confirmed: (travellerTransferService === null || travellerTransferService === void 0 ? void 0 : travellerTransferService.confirmedCount) > 0
                                    }
                                ];
                            }
                            else {
                                state.services[allowedPassenger][segmentId].push({
                                    service: transferService,
                                    selected: (travellerTransferService === null || travellerTransferService === void 0 ? void 0 : travellerTransferService.count) > 0,
                                    confirmed: (travellerTransferService === null || travellerTransferService === void 0 ? void 0 : travellerTransferService.confirmedCount) > 0
                                });
                            }
                        }
                    }
                }
            }
        }
    }
    else {
    }
    return state;
};
