import { produce } from 'immer';
import { initialStateFactory } from './initialStateFactory';
export const useMethods = (state, setState, service, parentState) => {
    const onToggle = (passengerId, segmentId) => {
        setState(produce(state, draft => {
            draft[passengerId][segmentId].selected = !draft[passengerId][segmentId].selected;
            return draft;
        }));
    };
    const restoreState = () => setState(initialStateFactory(service, parentState));
    const removeTransfer = () => {
        setState(produce(state, (draft) => {
            Object.keys(draft).map(passengerId => {
                Object.keys(draft[passengerId]).map(segmentId => {
                    if (draft[passengerId][segmentId] && !draft[passengerId][segmentId].confirmed) {
                        draft[passengerId][segmentId].selected = false;
                    }
                });
            });
        }));
    };
    return { onToggle, removeTransfer, restoreState };
};
