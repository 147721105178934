import * as React from 'react';
import cn from 'classnames';
import Step from './Step/Step';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import { useAvailableSteps } from '../hooks';
import Money from '../../Money';
import { Chevron, ShoppingCart } from '../../Icons';
initI18n('Stepbar');
const Stepbar = ({ steps, activeStep = 0, onCartClick, totalPrice, additional, renderAdditiotalStep, className }) => {
    const { Stepbar: css } = useTheme('Stepbar');
    const availableSteps = useAvailableSteps(steps);
    const stepsArray = [];
    for (const key in availableSteps) {
        if (availableSteps.hasOwnProperty(key)) {
            stepsArray.push(availableSteps[key]);
        }
    }
    return (React.createElement("div", { className: cn(className, css.stepbar) },
        React.createElement("div", { className: css.inner },
            React.createElement("div", { className: css.content },
                additional,
                stepsArray
                    .filter(step => !step.isHidden)
                    .map((step, index) => (React.createElement(Step, { key: index, id: index + 1, step: step, isPassed: index < activeStep, isActive: index === activeStep, isDisabled: step.isDisabled }))),
                renderAdditiotalStep && renderAdditiotalStep()),
            onCartClick && (React.createElement("div", { onClick: onCartClick, className: css.cart },
                ShoppingCart,
                React.createElement(Money, { money: totalPrice }),
                Chevron)))));
};
export default Stepbar;
