import * as React from 'react';
import { useTheme } from '../theme';
import { BaseButton } from '../BaseComponents';
import { timeIcon } from '../PaymentTimeLimit/Icons';
const PaymentTimeLimitPage = ({ onClick, title, text, buttonText }) => {
    const theme = useTheme('PaymentTimeLimit').PaymentTimeLimitPage;
    return (React.createElement("div", { className: theme.wrapper },
        React.createElement("div", { className: theme.timeImage }, timeIcon),
        React.createElement("div", { className: theme.text },
            React.createElement("span", null, title),
            React.createElement("span", null, text)),
        React.createElement(BaseButton, { className: theme.button, variant: "action", onClick: onClick }, buttonText)));
};
export default PaymentTimeLimitPage;
