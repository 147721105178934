import * as RegisteredPassenger from './RegisteredPassenger.css';
import * as CancelModal from './components/CancelModal/CancelModal.css';
import * as SendToEmail from './components/SendToEmail/SendToEmail.css';
import * as SelectCancelSegmentsModal from './components/SelectCancelSegments/SelectCancelSegments.css';
import * as Header from './components/Header/Header.css';
import * as Footer from './components/Footer/Footer.css';
import * as Controls from './components/Controls/Controls.css';
export default {
    RegisteredPassenger,
    CancelModal,
    SendToEmail,
    SelectCancelSegmentsModal,
    Header,
    Footer,
    Controls
};
