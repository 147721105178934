import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Money } from '../../../Money';
import { useTheme } from '../../../theme';
import { expandIcon } from '../../Icons';
const StepbarHeader = ({ className, currentStep, steps, totalPrice, isOpen, onToggle }) => {
    var _a;
    const { t } = useTranslation('MobileStepbar');
    const { StepbarHeader: css } = useTheme('MobileStepbar');
    return (React.createElement("div", { className: cn(className, css.header, { [css.header_zeroPrice]: totalPrice.amount === 0 }), onClick: onToggle },
        currentStep >= 0 && ((_a = steps[currentStep]) === null || _a === void 0 ? void 0 : _a.label) && (React.createElement("div", { className: css.stepIndex },
            currentStep + 1,
            "/",
            steps.length,
            ":",
            React.createElement("span", { className: css.stepIndex__label }, steps[currentStep].label))),
        React.createElement("div", { className: css.price },
            React.createElement("div", { className: css.price__wrapper },
                React.createElement("span", { className: css.price_title }, t('Total')),
                React.createElement(Money, { money: totalPrice })),
            React.createElement("span", { className: cn(css.expand, { [css.expand_open]: isOpen }) }, expandIcon))));
};
export default StepbarHeader;
