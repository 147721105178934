import React from 'react';
import { initI18n } from '../../../../../../utils';
import ModalConfirm from '../../../../../../ModalConfirm';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../../theme';
initI18n('Checkout');
const PassengersBlockedModal = ({ isOpen, onClose, onCancel, onConfirm }) => {
    const { t } = useTranslation('SearchForm');
    const { PassengersBlockedModal: theme } = useTheme('SearchForm');
    return (React.createElement(ModalConfirm, { classes: theme, isOpen: isOpen, onClose: onClose, onConfirm: onConfirm, onCancel: onCancel, title: t('Restrictions'), confirmBtn: t('Continue'), cancelBtn: t('Cancel') },
        React.createElement("div", { className: theme.contentWrapper }, t('When using miles, bookings for some categories will not be available'))));
};
export default PassengersBlockedModal;
