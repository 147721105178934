import * as React from 'react';
import { useSelector } from 'react-redux';
import { ModeContext, useConfig } from '../context';
import { getTravellers, getSelectedAnimalServicesPrice, getSelectedGroundTransportationServicesPrice, getSelectedPriorityBoardingPrice, getSelectedSmsNotificationsPrice } from '../Checkout/store/order/selectors';
import { getCalculatedBaggageDeliveryPrice, getPriceToPay } from '../Checkout/store/orderPrice/selectors';
import { Mode } from '../types';
import { useContext } from 'react';
import { getPriceToPay as checkinGetPriceToPay, getTravellers as checkinGetTravellers } from '../Modules/Checkin/store/order/selectors';
export const useCartPrices = () => {
    const { servicesAvailable } = useConfig().global;
    const mode = useContext(ModeContext);
    let priceToPay;
    let travellers;
    let animalSelectedPrice;
    let transferSelectedPrice;
    let prioritySelectedPrice;
    let baggageDeliveryPrice;
    let smsSelectedPrice;
    if (mode === Mode.Checkin) {
        priceToPay = useSelector(checkinGetPriceToPay);
        travellers = useSelector(checkinGetTravellers);
    }
    else {
        priceToPay = useSelector(getPriceToPay);
        travellers = useSelector(getTravellers);
        animalSelectedPrice = useSelector(getSelectedAnimalServicesPrice);
        transferSelectedPrice = useSelector(getSelectedGroundTransportationServicesPrice);
        prioritySelectedPrice = useSelector(getSelectedPriorityBoardingPrice);
        baggageDeliveryPrice = useSelector(getCalculatedBaggageDeliveryPrice);
        smsSelectedPrice = useSelector(getSelectedSmsNotificationsPrice);
    }
    return React.useMemo(() => {
        // Baggage price calculation
        let baggagePrice = Object.assign({}, priceToPay.baggage);
        // [Animals]
        if (servicesAvailable.animal && animalSelectedPrice && animalSelectedPrice.amount <= baggagePrice.amount) {
            baggagePrice = Object.assign(Object.assign({}, baggagePrice), { amount: baggagePrice.amount - animalSelectedPrice.amount });
        }
        if (baggagePrice.amount === 0) {
            baggagePrice = null;
        }
        // Vip services calculation
        let vipPrice = Object.assign({}, priceToPay.vip);
        [transferSelectedPrice, prioritySelectedPrice, smsSelectedPrice].forEach(vipServicePrice => {
            if (vipServicePrice && vipServicePrice.amount <= vipPrice.amount) {
                vipPrice = Object.assign(Object.assign({}, vipPrice), { amount: vipPrice.amount - vipServicePrice.amount });
            }
        });
        if (vipPrice.amount === 0) {
            vipPrice = null;
        }
        return Object.assign(Object.assign({}, priceToPay), { vip: vipPrice, baggage: baggagePrice, animals: animalSelectedPrice, transfer: transferSelectedPrice, priorityBoarding: prioritySelectedPrice, baggageDelivery: baggageDeliveryPrice, sms: smsSelectedPrice });
    }, [priceToPay, travellers, animalSelectedPrice, transferSelectedPrice, prioritySelectedPrice, smsSelectedPrice]);
};
