import memes from '../memes';
export const getTemperaturePrefix = memes((temperature) => {
    if (Math.round(temperature) > 0) {
        return '+';
    }
    else if (Math.round(temperature) < 0) {
        return '-';
    }
    return '';
});
