import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import { Details, NotIncluded, Time } from '../../../Icons';
import { useTheme } from '../../../theme';
const Note = props => {
    const { title, content, type, icon, iconColor } = props;
    const { Note: css } = useTheme('Refund');
    const renderIcon = useCallback(() => {
        if (!type && !icon) {
            return null;
        }
        if (icon) {
            return icon;
        }
        if (type === 'info') {
            return Details;
        }
        if (type === 'restriction') {
            return NotIncluded;
        }
        if (type === 'timelimit') {
            return Time;
        }
        return icon;
    }, [type, icon]);
    let noteType = css.info;
    switch (type) {
        case 'restriction':
            noteType = css.restriction;
            break;
        case 'timelimit':
            noteType = css.timelimit;
            break;
    }
    return (React.createElement("div", { className: cn(css.note, props.className, noteType) },
        React.createElement("span", { className: cn(css.icon, {
                [css.red]: iconColor === 'red',
                [css.gray]: iconColor === 'gray'
            }) }, renderIcon()),
        React.createElement("h6", { className: css.title }, title),
        React.createElement("p", { className: css.text }, content)));
};
export default Note;
