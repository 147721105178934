import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../theme';
import { useTranslation } from 'react-i18next';
import MediaQuery from '../MediaQuery/MediaQuery';
import Disclaimer from './сomponents/Disclaimer/Disclaimer';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
const AdditionalServiceBanner = ({ service, disclaimer }) => {
    const css = useTheme('AdditionalServiceBanner').AdditionalServiceBannerStyles;
    const { t } = useTranslation();
    return (React.createElement("div", { className: cn(css.wrapper, {
            [css.baggageBanner]: service === 'baggage',
            [css.mealBanner]: service === 'meal',
            [css.seatBanner]: service === 'seat',
            [css.extrasBanner]: service === 'extras'
        }) },
        React.createElement("div", { className: css.content },
            React.createElement("div", { className: css.text },
                React.createElement(MediaQuery, { minWidth: 'tablet' }, t(service === 'baggage'
                    ? 'BaggagePage:Transportation of impressions no limitations'
                    : service === 'seat'
                        ? 'SeatMap:The top ones get the top seats. Be on top!'
                        : service === 'meal'
                            ? 'MealPage:Feel the taste in flight'
                            : 'Checkin:Anything else for your trip?')),
                React.createElement(MediaQuery, { maxWidth: 'tablet' }, t(service === 'baggage'
                    ? 'BaggagePage:Baggage'
                    : service === 'seat'
                        ? 'SeatMap:Seats'
                        : service === 'meal'
                            ? 'MealPage:Meal'
                            : 'Checkin:Anything else for your trip?')),
                service !== 'extras' && (React.createElement(MediaQuery, { minWidth: "mobile" }, !!disclaimer ? (React.createElement(Tooltip, { title: disclaimer, placement: "bottom", classes: { tooltip: css.tooltip } },
                    React.createElement("div", { className: css.disclaimer__wrapper },
                        React.createElement(Disclaimer, { service: service })))) : (React.createElement(Disclaimer, { service: service })))))),
        React.createElement("div", { className: css.image }),
        React.createElement("div", { className: css.whiteLines },
            React.createElement("div", { className: css.whiteLine_wide }),
            React.createElement("div", { className: css.whiteLine_narrow }))));
};
export default AdditionalServiceBanner;
