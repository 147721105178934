import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import cn from 'classnames';
import AdditionalService from '../../../AdditionalServiceCard/components/AdditionalService';
import { useTheme } from '../../../theme';
import { alfaInsuranceLogo, UmbrellaIcon } from './logo';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { SlideBottom } from '../../../index';
import DialogContent from './DialogContent/DialogContent';
import Money from '../../../Money';
import { DisplayMode } from '../types';
import { useUpsaleWarning } from '../../../Checkout/components/Checkout/Upsale/hooks';
import { getServicesFallbackURL, shouldRedirectToFallback } from '../../../Checkout/store/order/selectors';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import { getTotalPrice, initI18n } from '../../../utils';
initI18n('Checkout');
const Insurance = ({ displayMode, insurance, selectedInsurances = [], onClick, isMobileStepbar, isBlockForPayment }) => {
    const [confirmationPopupIsOpen, setConfirmationPopupIsOpen] = useState(false);
    const [addingIsInProgress, setAddingIsInProgress] = useState(false);
    const [refusingIsInProgress, setRefusingIsInProgress] = useState(false);
    const isHorizontal = displayMode === DisplayMode.Horizontal;
    const { t } = useTranslation('Checkout');
    const { Insurance: css } = useTheme('AdditionalServices');
    const { showWarning, closeWarning, toggleWarningIfOrderConfirmed } = useUpsaleWarning();
    const shouldRedirect = useSelector(shouldRedirectToFallback);
    const servicesRedirectURL = useSelector(getServicesFallbackURL);
    const closeConfirmationPopup = useCallback(() => !refusingIsInProgress && setConfirmationPopupIsOpen(false), []);
    const openConfirmationPopup = useCallback(() => {
        if (shouldRedirect) {
            toggleWarningIfOrderConfirmed();
            return;
        }
        setConfirmationPopupIsOpen(true);
    }, [shouldRedirect]);
    const onInsuranceAdd = () => __awaiter(void 0, void 0, void 0, function* () {
        setAddingIsInProgress(true);
        try {
            yield onClick([...selectedInsurances.map(({ insuranceProgram }) => insuranceProgram.code), insurance.code]);
        }
        catch (e) {
            console.error(e);
        }
        setAddingIsInProgress(false);
    });
    const onInsuranceRemove = () => __awaiter(void 0, void 0, void 0, function* () {
        setRefusingIsInProgress(true);
        try {
            yield onClick([]);
            closeConfirmationPopup();
            setTimeout(() => setRefusingIsInProgress(false), 500);
        }
        catch (e) {
            console.error(e);
            setRefusingIsInProgress(false);
        }
    });
    const activeInsurance = selectedInsurances &&
        selectedInsurances.find(({ insuranceProgram }) => insuranceProgram.code === insurance.code);
    const totalInsurancePrice = useMemo(() => getTotalPrice(selectedInsurances, insurance => insurance.passengerPrice), [selectedInsurances]);
    const openPolicy = () => window.open(activeInsurance.policyUrl, '_blank');
    const handleInsuranceClick = useCallback(() => {
        if (isBlockForPayment) {
            return;
        }
        if (activeInsurance) {
            if (activeInsurance.policyUrl) {
                openPolicy();
            }
            else {
                openConfirmationPopup();
            }
        }
        else {
            onInsuranceAdd();
        }
    }, [activeInsurance, isBlockForPayment]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        !isMobileStepbar && (React.createElement(AdditionalService, { horizontal: displayMode === DisplayMode.Horizontal, service: {
                header: 'Insurance',
                price: insurance.totalPrice,
                subheader: insurance.name,
                icon: UmbrellaIcon,
                content: insurance.description,
                logo: isHorizontal ? null : React.createElement("div", { className: css.logo }, alfaInsuranceLogo),
                active: !!activeInsurance
            }, isLoading: addingIsInProgress, buttonText: t(activeInsurance ? (activeInsurance.policyUrl ? 'Download policy' : 'Cancel') : 'Add'), onClick: handleInsuranceClick })),
        isMobileStepbar && (React.createElement(React.Fragment, null, activeInsurance ? (React.createElement("div", { className: css.stepbar__actions, onClick: handleInsuranceClick },
            React.createElement(Money, { money: totalInsurancePrice }),
            !isBlockForPayment && (React.createElement("i", { className: cn({
                    [css.stepbar__action_openPolicy]: activeInsurance.policyUrl,
                    [css.stepbar__action_close]: !activeInsurance.policyUrl
                }) })))) : (!isBlockForPayment && React.createElement("span", { onClick: handleInsuranceClick }, t('Add'))))),
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(Dialog, { open: confirmationPopupIsOpen, onClose: closeConfirmationPopup, classes: { paper: css.popup } },
                React.createElement(DialogContent, { closeConfirmationPopup: closeConfirmationPopup, onInsuranceRemove: onInsuranceRemove, refusingIsInProgress: refusingIsInProgress }))),
        React.createElement(MediaQuery, { maxWidth: 'mobile' },
            React.createElement(SlideBottom, { onClose: closeConfirmationPopup, isOpen: confirmationPopupIsOpen },
                React.createElement("div", { className: css.slide__content },
                    React.createElement(DialogContent, { closeConfirmationPopup: closeConfirmationPopup, onInsuranceRemove: onInsuranceRemove, refusingIsInProgress: refusingIsInProgress }))))));
};
export default Insurance;
