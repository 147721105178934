import { __rest } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { formatPhone } from '../utils';
import Input from '../../Input';
const PhoneInput = React.forwardRef((_a, ref) => {
    var { onChange } = _a, props = __rest(_a, ["onChange"]);
    const [value, setValue] = useState(props.value || '');
    const onChangeHandler = (e) => {
        const newValue = e.target.value;
        const phoneValue = formatPhone(newValue);
        if (value !== phoneValue) {
            setValue(phoneValue);
            onChange === null || onChange === void 0 ? void 0 : onChange(e);
        }
    };
    return React.createElement(Input, Object.assign({ type: "tel" }, props, { value: value, onChange: onChangeHandler, ref: ref }));
});
export default PhoneInput;
