import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { ArrowForward } from '../../../Icons';
import FullscreenDialogClose from '../../../FullscreenDialog/components/Close/Close';
const DesktopFlightModalHeader = ({ className, title, onClose }) => {
    const { DesktopFlightModalHeader: css } = useTheme('DesktopFlight');
    const { t } = useTranslation('FlightInfoModalContent');
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.wrapper },
            React.createElement("div", { className: css.arrow, onClick: onClose }, ArrowForward),
            React.createElement("div", { className: css.title }, title),
            React.createElement(FullscreenDialogClose, { text: t('Back'), onClose: onClose, closeClassName: css.close }))));
};
export default DesktopFlightModalHeader;
