import { __rest } from "tslib";
import * as React from 'react';
import Modal from '../Modal';
import ActionButton from '../Button/components/ActionButton/ActionButton';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { useUpsaleServicesModal } from './hooks';
import { useIsMobile } from '../utils';
const UpsaleServicesModal = props => {
    var _a, _b, _c, _d;
    const { defaultIcon, onRefuseServices, travellers, additionalServices, serviceType, descriptionRender, onAgreeServices } = props, restProps = __rest(props, ["defaultIcon", "onRefuseServices", "travellers", "additionalServices", "serviceType", "descriptionRender", "onAgreeServices"]);
    const { t } = useTranslation('Checkout');
    const css = useTheme('UpsaleModal').UpsaleModal;
    const isMobile = useIsMobile();
    const { isOpen, upsaleServices, agreeHandler, refuseHandler } = useUpsaleServicesModal(travellers, additionalServices, serviceType, onRefuseServices, onAgreeServices);
    const upsaleServicesIdx = 0;
    if (!upsaleServices[upsaleServicesIdx]) {
        return null;
    }
    const icon = (_b = (_a = upsaleServices[upsaleServicesIdx]) === null || _a === void 0 ? void 0 : _a.icon) !== null && _b !== void 0 ? _b : defaultIcon;
    const extraTitleShowDates = (_c = upsaleServices[upsaleServicesIdx]) === null || _c === void 0 ? void 0 : _c.extraTitleShowDates;
    return (React.createElement(Modal, Object.assign({ classes: {
            scrollBody: css.scrollBody,
            paper: css.paper
        }, open: isOpen, maxWidth: 'sm' }, restProps, { onClose: refuseHandler, slideUp: isMobile, extraTitle: new Date(extraTitleShowDates[0]).getTime() <= Date.now() &&
            Date.now() <= new Date(extraTitleShowDates[1]).getTime() &&
            t((_d = upsaleServices[upsaleServicesIdx]) === null || _d === void 0 ? void 0 : _d.extraTitle) }),
        React.createElement("div", { className: css.container },
            icon && React.createElement("div", { className: css.icon }, icon),
            React.createElement("div", { className: css.description }, descriptionRender(upsaleServices[upsaleServicesIdx].service, upsaleServices[upsaleServicesIdx].description)),
            React.createElement("div", { className: css.buttons },
                React.createElement(ActionButton, { className: css.refuseBtn, onClick: refuseHandler }, t('Refuse')),
                React.createElement(ActionButton, { className: css.agreeBtn, onClick: agreeHandler }, t('Agree'))))));
};
export default UpsaleServicesModal;
