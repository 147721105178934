import { __awaiter } from "tslib";
import * as React from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import { CategoriesPage } from './CategoriesPage/CategoriesPage';
import { NotificationsPage } from './NotificationsPage/NotificationsPage';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { initI18n } from '../../utils';
import { PersonBox, Chat as ChatIcon, StarBold, Tag, NotificationBold } from '../../Icons';
import { AppealsPage } from './Appeals/AppealsPage';
import { PromotionsPage } from './Promotions/PromotionsPage';
import { OrdersPage } from './Orders/OrdersPage';
import { PromoCodesPage } from './PromoCodes/PromoCodesPage';
import { Chat } from './Chat/Chat';
import { PaximizerCommunicationSectionEnum, useGetPaxSectionsQuery, useReadCommunicationSectionMessageQuery } from '@websky/graphql';
import { OrderDetailPage } from './Orders/OrderDetailPage';
import { PromotionsDetailPage } from './Promotions/PromotionsDetailPage';
import { PromoCodesDetailPage } from './PromoCodes/PromoCodesDetailPage';
import Loader from '../../Loader';
import { AppealDetailPage } from './Appeals/AppealDetailPage';
import { useDispatch, useSelector } from 'react-redux';
import { getCommunicationCategory } from '../store/selectors';
import { markAllAsReadInCategory } from '../store/actions';
initI18n('Communications');
const CATEGORIES_CONFIG = {
    [PaximizerCommunicationSectionEnum.my_appeals]: {
        listComponent: React.createElement(AppealsPage, null),
        icon: PersonBox,
        detailComponent: React.createElement(AppealDetailPage, null),
        path: 'my-appeals'
    },
    [PaximizerCommunicationSectionEnum.chat]: {
        listComponent: React.createElement(Chat, null),
        icon: ChatIcon,
        path: 'chat'
    },
    [PaximizerCommunicationSectionEnum.promotions]: {
        listComponent: React.createElement(PromotionsPage, null),
        icon: StarBold,
        detailComponent: React.createElement(PromotionsDetailPage, null),
        path: 'promotions'
    },
    [PaximizerCommunicationSectionEnum.order_notifications]: {
        listComponent: React.createElement(OrdersPage, null),
        icon: NotificationBold,
        detailComponent: React.createElement(OrderDetailPage, null),
        path: 'orders'
    },
    [PaximizerCommunicationSectionEnum.my_promo_codes]: {
        listComponent: React.createElement(PromoCodesPage, null),
        icon: Tag,
        detailComponent: React.createElement(PromoCodesDetailPage, null),
        path: 'promo-codes'
    }
};
export const CategoryContext = createContext(null);
const CommunicationBase = () => {
    const categoryDictionary = useSelector(getCommunicationCategory);
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const [isLoadingRead, setIsLoadingRead] = useState(false);
    const { refetch: readAll } = useReadCommunicationSectionMessageQuery({
        skip: true
    });
    const { loading: loadingCategories } = useGetPaxSectionsQuery({
        variables: {
            withLastMessage: true
        }
    });
    const [appliedReadAll, setAppliedReadAll] = useState(false);
    useEffect(() => {
        setAppliedReadAll(false);
    }, [path]);
    const markAllAsRead = (id) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoadingRead(true);
        const response = yield readAll({
            sectionId: id
        });
        if (response) {
            dispatch(markAllAsReadInCategory(id));
            setAppliedReadAll(true);
        }
        setIsLoadingRead(false);
    });
    const categories = useMemo(() => {
        return Object.values(categoryDictionary).map(category => {
            const el = CATEGORIES_CONFIG[category.id];
            return Object.assign(Object.assign({}, category), { id: category.id, path: el.path, icon: el.icon || '', listComponent: el.listComponent, detailComponent: el.detailComponent });
        });
    }, [categoryDictionary]);
    return (React.createElement(Switch, null,
        loadingCategories && React.createElement(Loader, null),
        React.createElement(Route, { exact: true, path: path, render: () => React.createElement(CategoriesPage, { categories: categories }) }),
        React.createElement(CategoryContext.Provider, { value: {
                appliedReadAll
            } },
            React.createElement(Route, { path: `${path}/:categoryId`, render: () => (React.createElement(NotificationsPage, { categories: categories, loadingRead: isLoadingRead, markAllAsRead: markAllAsRead })) }))));
};
export default withRouter(CommunicationBase);
