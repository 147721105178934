import { __awaiter } from "tslib";
import { useCheckinSaveOrderServicesMutation } from '@websky/graphql';
import * as React from 'react';
import { UpgradeFlightClass } from '../../../../../../UpgradeFlightClass';
import { useSteps } from '../../../../utils';
import { CheckinStep } from '../../../../types';
import PassengersHeader from '../Passengers/PassengersHeader/PassengersHeader';
import { Log } from '../../../../../../sentry';
import { useTheme } from '../../../../../../theme';
import { PAYMENT_BACK_REDIRECT_PARAM } from '../../constants';
export const Upgrade = () => {
    const { goToNextStep, setStep } = useSteps();
    const { Checkin: css } = useTheme('Checkin');
    const [saveServices, { loading }] = useCheckinSaveOrderServicesMutation();
    const handleSave = (variables) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield saveServices({
                variables: {
                    params: variables.params
                }
            });
            setStep(CheckinStep.Payment, new URLSearchParams([[PAYMENT_BACK_REDIRECT_PARAM, CheckinStep.Baggage]]).toString());
        }
        catch (e) {
            console.error(e);
            Log({
                level: 'error',
                message: 'error while saving upgrade service'
            });
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(PassengersHeader, null),
        React.createElement(UpgradeFlightClass, { contentClassName: css.upgrade, loading: loading, onSaveServices: handleSave, onContinueWithoutSelection: () => goToNextStep(CheckinStep.Upgrade) })));
};
