import * as React from 'react';
import AdditionalServiceCard from '../../../../AdditionalServiceCard/components/AdditionalService';
import icon from '../icon';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
const BusinessAdditionalService = props => {
    var _a, _b, _c, _d;
    const { t } = useTranslation('Checkin');
    const { Business: css } = useTheme('AdditionalServices');
    const isActive = !!((_a = props === null || props === void 0 ? void 0 : props.totalPrice) === null || _a === void 0 ? void 0 : _a.amount);
    return (React.createElement(AdditionalServiceCard, { buttonText: isActive ? t('Change') : t('Add'), service: {
            headClassName: css.head,
            icon,
            logo: null,
            active: !!((_b = props === null || props === void 0 ? void 0 : props.totalPrice) === null || _b === void 0 ? void 0 : _b.amount),
            price: ((_c = props.totalPrice) === null || _c === void 0 ? void 0 : _c.amount) ? props.totalPrice : props.minPrice,
            header: t('Business lounge'),
            renderContent: () => { var _a; return React.createElement("span", { className: css.description }, (_a = props.services[0]) === null || _a === void 0 ? void 0 : _a.description); },
            subheader: (_d = props.services[0]) === null || _d === void 0 ? void 0 : _d.name
        }, onClick: props.onOpen, isLoading: props.loading, horizontal: false }));
};
export default BusinessAdditionalService;
