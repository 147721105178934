import * as React from 'react';
import cn from 'classnames';
import Button from '../../Button';
import { useTheme } from '../../../theme';
const TabButton = props => {
    const { TabButton: css } = useTheme('TabButton');
    return (React.createElement(Button, Object.assign({ variant: 'outline', classes: {
            root: cn(css.button, {
                [css.active]: props.active,
                [css.uppercase]: props.uppercase,
                [css.icon]: props.icon,
                [css.small]: props.size === 'small',
                [css.common]: !props.size,
                [css.large]: props.size === 'large',
                [css.inverted]: props.inverted
            }, props.className),
            disabled: css.disabled
        } }, props), props.children));
};
export default TabButton;
