import { __rest } from "tslib";
import * as React from 'react';
import Button from '@mui/material/Button';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { Choosed } from '../../../../Icons';
import Loader from '../Loader/Loader';
const IconButton = (_a) => {
    var { classes, children, isSuccess, isLoading, disabled, disableElevation = true } = _a, restProps = __rest(_a, ["classes", "children", "isSuccess", "isLoading", "disabled", "disableElevation"]);
    const { IconButton: theme } = useTheme('BaseComponents');
    return (React.createElement(Button, Object.assign({ variant: "contained", classes: Object.assign(Object.assign({}, classes), { root: cn(theme.root, classes === null || classes === void 0 ? void 0 : classes.root, {
                [theme.success]: isSuccess,
                [theme.loading]: isLoading
            }) }), disabled: disabled || isLoading, disableElevation: disableElevation }, restProps), isLoading ? React.createElement(Loader, { classes: { colorPrimary: theme.colorPrimary } }) : isSuccess ? Choosed : children));
};
export default IconButton;
