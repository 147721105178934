import * as React from 'react';
import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import RefundPaper from '../RefundPaper/RefundPaper';
import AddFile from '../AddFile/AddFile';
import { useTheme } from '../../../theme';
import { useToggleable } from '../../../hooks';
const AddFiles = props => {
    const { t } = useTranslation('Refund');
    const { AddFiles: css } = useTheme('Refund');
    const initialState = {};
    Object.entries(props.selectedDocuments).forEach(([passengerId, documents]) => {
        let index = 1;
        const passengersIds = props.passengers.map(passenger => passenger.id);
        if (!passengersIds.includes(passengerId)) {
            return;
        }
        initialState[passengerId] = {};
        documents.forEach((document, index) => {
            initialState[passengerId][index + 1] = document;
        });
        while (index !== 6) {
            if (!initialState[passengerId][index]) {
                initialState[passengerId][index] = null;
            }
            index++;
        }
    });
    const [state, setState] = useState(initialState);
    const { isOpen: hasErrors, open: setHasErrors, setOpen: setErrorsStateTo } = useToggleable(false);
    const convertStateToRedux = useCallback(() => {
        const documentsState = {};
        Object.entries(state).forEach(([passenger, documentsMap]) => {
            documentsState[passenger] = Object.values(state[passenger]).map(value => value);
        });
        return documentsState;
    }, [state]);
    const handleAddFile = useCallback((passengerId, fileInputKey, file) => {
        const passengerState = Object.assign({}, state[passengerId]);
        setErrorsStateTo(false);
        setState(Object.assign(Object.assign({}, state), { [passengerId]: Object.assign(Object.assign({}, passengerState), { [fileInputKey]: file }) }));
    }, [state, props.passengers]);
    const handleClear = useCallback((passengerId, fileInputIndex) => {
        const passengerState = Object.assign({}, state[passengerId]);
        setState(Object.assign(Object.assign({}, state), { [passengerId]: Object.assign(Object.assign({}, passengerState), { [fileInputIndex]: null }) }));
    }, [state, props.passengers]);
    useEffect(() => {
        props.onFilesLoaded(convertStateToRedux());
    }, [state]);
    const renderFileInput = useCallback((passenger, fileInputKey) => (React.createElement(AddFile, { onValidationError: setHasErrors, key: `${passenger}_${fileInputKey}`, passenger: passenger, documentType: props.docsFilesMap[props.refundReason], className: css.file, onLoad: loaded => handleAddFile(passenger.id, fileInputKey, loaded.file), onClear: () => handleClear(passenger.id, fileInputKey) })), [props.passengers, props.refundReason, props.onFilesLoaded, state]);
    const renderFileInputs = useCallback((passenger) => {
        const inputsToRender = [];
        let hasEmpty = false;
        if (!state[passenger.id]) {
            return null;
        }
        for (const [key, value] of Object.entries(state[passenger.id])) {
            if (value === null && !hasEmpty) {
                hasEmpty = true;
                inputsToRender.push(renderFileInput(passenger, key));
            }
            if (value !== null) {
                inputsToRender.push(renderFileInput(passenger, key));
            }
        }
        return inputsToRender;
    }, [renderFileInput, state]);
    return (React.createElement(Paper, { elevation: 0, square: true, className: css.wrapper },
        React.createElement(RefundPaper, { className: css.files }, props.passengers.map(renderFileInputs)),
        React.createElement(Typography, { className: cn(css.info, { [css.hasErrors]: hasErrors }) }, t('File format: jpg, pdf. File size: no more than 5MB', { nsSeparator: '|' }))));
};
export default AddFiles;
