import { __awaiter } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import TravelCompanions from '../../../TravelCompanions';
import { renderType } from '../../../TravelCompanions/components/TravelCompanions';
import AccountMethods from '../AuthMethods/AuthMethods';
import SocialAuth from '../SocialAuth';
import { useConfig } from '../../../context';
import { OauthServiceType, LoginType, useGetUserSettingsQuery, useRemoveAuthMethodMutation, useSocialAuthQuery } from '@websky/graphql';
import { getBackRedirectUrl, setSocialAuthRedirectUrl } from '../../../SocialAuth/components/utils';
import { useTinkoffCallback } from '../../../SocialAuth/components/hooks';
import DeleteAccount from '../DeleteAccount/DeleteAccount';
import MailSettings from '../MailSettings/MailSettings';
import cn from 'classnames';
const Settings = ({ currentUser, setUserSettingsError, refetchAccountInfo }) => {
    var _a, _b, _c, _d, _e;
    const { Account: theme } = useTheme('AccountModule');
    const { t } = useTranslation('Account');
    const { global: { socialAuthMethods } } = useConfig();
    const isSocialAccountsEnabled = socialAuthMethods.some(socialAuth => socialAuth.isAvailable === true);
    const oAuth = useSocialAuthQuery({
        skip: true
    });
    const [removeMethod] = useRemoveAuthMethodMutation();
    const userSettings = useGetUserSettingsQuery();
    const userEmail = (_a = currentUser.userProfile.values.find(item => item.name === 'email')) === null || _a === void 0 ? void 0 : _a.value;
    const isSubscriptionCheckboxVisible = !!((_d = (_c = (_b = userSettings === null || userSettings === void 0 ? void 0 : userSettings.data) === null || _b === void 0 ? void 0 : _b.UserSettings) === null || _c === void 0 ? void 0 : _c.find(setting => (setting === null || setting === void 0 ? void 0 : setting.name) === 'Subscribe')) === null || _d === void 0 ? void 0 : _d.value) && !!userEmail;
    const socialServices = [
        LoginType.Google,
        LoginType.Facebook,
        LoginType.TinkoffID,
        LoginType.Vkontakte
    ];
    const onRemoveAuthMethod = (id) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data, errors } = yield removeMethod({
                variables: {
                    params: id
                }
            });
            if (errors === null || errors === void 0 ? void 0 : errors.length) {
                setUserSettingsError(errors[0]);
            }
            else if (data.DeleteAuthMethod && data.DeleteAuthMethod.result) {
                refetchAccountInfo();
            }
        }
        catch (error) {
            setUserSettingsError(error);
        }
    });
    const oAuthRedirect = (buttonName) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data, errors } = yield oAuth.refetch({
                params: {
                    service: OauthServiceType[buttonName],
                    backRedirectUrl: getBackRedirectUrl(OauthServiceType[buttonName])
                }
            });
            setSocialAuthRedirectUrl(OauthServiceType[buttonName]);
            if (errors && errors.length) {
                setUserSettingsError(errors[0]);
            }
            else if (data.OauthRedirectUrl.result) {
                window.open(data.OauthRedirectUrl.message, '_top');
            }
        }
        catch (error) {
            setUserSettingsError(error);
        }
    });
    const activityStatuses = React.useMemo(() => {
        return currentUser === null || currentUser === void 0 ? void 0 : currentUser.authMethods.filter(item => socialServices.includes(item.loginType)).map(item => {
            return {
                service: item.loginType,
                status: item.confirmed,
                id: item.id,
                userName: currentUser === null || currentUser === void 0 ? void 0 : currentUser.userProfile.values.filter(item => item.name === 'firstName')[0].value
                // Будет нормальный ответ из авторизации, сейчас интерфейс не подходящий
            };
        });
    }, [currentUser]);
    useTinkoffCallback(refetchAccountInfo);
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", { className: theme.route__title }, t('Profile')),
        React.createElement(TravelCompanions, { format: renderType.Passengers }),
        React.createElement("div", { className: cn(theme.container, theme.container_authMethods) },
            React.createElement("div", { className: theme.container__title }, t('Login settings')),
            React.createElement("div", { className: theme.container__content },
                React.createElement(AccountMethods, null),
                isSocialAccountsEnabled && (React.createElement(SocialAuth, { onDelete: onRemoveAuthMethod, onAuthRedirect: oAuthRedirect, socialAuthMethods: socialAuthMethods, authMethods: activityStatuses })))),
        isSubscriptionCheckboxVisible && (React.createElement("div", { className: cn(theme.container, theme.container_mailSettings) },
            React.createElement("div", { className: theme.container__title }, t('Mail settings')),
            React.createElement("div", { className: theme.container__content },
                React.createElement(MailSettings, { onError: () => null, userSettings: (_e = userSettings === null || userSettings === void 0 ? void 0 : userSettings.data) === null || _e === void 0 ? void 0 : _e.UserSettings, userEmail: userEmail, refetchUserSettings: () => __awaiter(void 0, void 0, void 0, function* () { return yield (userSettings === null || userSettings === void 0 ? void 0 : userSettings.refetch()); }) })))),
        React.createElement("div", { className: theme.footer },
            React.createElement(DeleteAccount, { onError: () => null }))));
};
export default Settings;
