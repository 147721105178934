import { __rest } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import cn from 'classnames';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { useFareGroupsCompareTableQuery } from '@websky/graphql';
import { CompareState } from '../types';
import { chevronIcon } from '../Icons';
import { initI18n, TABLET_MIN_WIDTH } from '../../utils';
import { BaseButton, Link } from '../../index';
import ModalHeading from '../../ModalHeading';
import Cell from './Cell/Cell';
import { useTheme } from '../../theme';
import MediaQuery from '../../MediaQuery/MediaQuery';
import { useCompareFaresModel } from './CompareFares.hook';
import { OverrideComponent } from '../../renderProps';
initI18n('CompareFares');
const CompareFares = ({ notes, code, selectedFare, flightId, orderId, selectedFareName }) => {
    var _a, _b;
    let compareFaresSlidesCount, fullCompareFaresSlidesCount, isImagesPresentInResponse;
    const headerRef = React.useRef();
    const model = useCompareFaresModel(orderId);
    const { data: compareTable, loading, error } = useFareGroupsCompareTableQuery({
        variables: model.compareTableVariables,
        skip: !model.compareTableVariables.flightId && !model.compareTableVariables.orderId
    });
    const { CompareFares: css } = useTheme('CompareFares');
    if (compareTable) {
        isImagesPresentInResponse =
            !!(compareTable === null || compareTable === void 0 ? void 0 : compareTable.FareGroupsCompareTable) && !!((_b = (_a = compareTable === null || compareTable === void 0 ? void 0 : compareTable.FareGroupsCompareTable.header) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.image);
    }
    const [compareState, onCompareStateChange] = React.useState(CompareState.CompareFares);
    if (!loading && !error) {
        const fareGroupsCount = compareTable === null || compareTable === void 0 ? void 0 : compareTable.FareGroupsCompareTable.header.length;
        compareFaresSlidesCount = fareGroupsCount < 5 ? fareGroupsCount : 5;
        fullCompareFaresSlidesCount = fareGroupsCount < 3 ? fareGroupsCount : 3;
    }
    const isFullFareConditions = compareState === CompareState.FullFareConditions;
    const { t } = useTranslation('CompareFares');
    const topSliderRef = useRef();
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliders = [];
    React.useEffect(() => {
        var _a;
        if ((_a = compareTable === null || compareTable === void 0 ? void 0 : compareTable.FareGroupsCompareTable) === null || _a === void 0 ? void 0 : _a.header) {
            const index = compareTable === null || compareTable === void 0 ? void 0 : compareTable.FareGroupsCompareTable.header.findIndex(group => (group === null || group === void 0 ? void 0 : group.title) === selectedFareName);
            setCurrentSlide(index > 0 ? index : 0);
        }
    }, [compareTable, selectedFareName]);
    const resetSlidersIndex = useCallback(() => {
        var _a;
        (_a = topSliderRef.current) === null || _a === void 0 ? void 0 : _a.slickGoTo(0);
        sliders.forEach(slider => slider.slickGoTo(0));
        setCurrentSlide(0);
    }, [topSliderRef, sliders, currentSlide]);
    const handleCompareStateChange = e => {
        var _a;
        e.preventDefault();
        resetSlidersIndex();
        (_a = headerRef === null || headerRef === void 0 ? void 0 : headerRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
        onCompareStateChange(isFullFareConditions ? CompareState.CompareFares : CompareState.FullFareConditions);
    };
    const NextArrow = (_a) => {
        var { currentSlide, slideCount } = _a, restProps = __rest(_a, ["currentSlide", "slideCount"]);
        return (React.createElement("div", Object.assign({ className: cn(css.chevron, css.chevron_next) }, restProps), chevronIcon));
    };
    const PrevArrow = (_a) => {
        var { currentSlide, slideCount } = _a, restProps = __rest(_a, ["currentSlide", "slideCount"]);
        return (React.createElement("div", Object.assign({ className: cn(css.chevron, css.chevron_next) }, restProps), chevronIcon));
    };
    const sliderSettings = {
        arrows: false,
        speed: 250,
        swipe: false,
        slidesToShow: isFullFareConditions ? fullCompareFaresSlidesCount : compareFaresSlidesCount,
        infinite: false,
        initialSlide: currentSlide,
        responsive: [
            {
                breakpoint: TABLET_MIN_WIDTH,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const topSliderSettings = Object.assign(Object.assign({}, sliderSettings), { arrows: true, prevArrow: React.createElement(PrevArrow, null), nextArrow: React.createElement(NextArrow, null), afterChange: currentSlide => {
            setCurrentSlide(currentSlide);
        } });
    useEffect(() => {
        sliders.forEach(slider => slider.slickGoTo(currentSlide));
        if (topSliderRef.current) {
            topSliderRef.current.slickGoTo(currentSlide);
        }
    }, [currentSlide, topSliderRef]);
    const contentTableRef = React.useRef();
    const selectedFareRef = React.useRef();
    setTimeout(() => {
        var _a;
        const contentTableHeight = (_a = contentTableRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight;
        const slickList = document.querySelector('.slick-list');
        if (contentTableHeight && selectedFareRef.current && slickList.clientHeight) {
            slickList.style.paddingBottom = `${contentTableHeight}px`;
            slickList.style.marginBottom = `-${contentTableHeight}px`;
            selectedFareRef.current.style.height = `${contentTableHeight}px`;
        }
    }, 0);
    return (React.createElement(Paper, { classes: { root: css.compareFares } },
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(ModalHeading, { heading: t('Compare fare families') })),
        React.createElement("div", { className: css.container, ref: headerRef },
            loading && (React.createElement("div", { className: css.loaderWrp },
                React.createElement(CircularProgress, { classes: { colorPrimary: css.loader } }))),
            error && (React.createElement("div", null,
                React.createElement("h6", { className: css.errorTitle }, t('Internal error')),
                React.createElement("p", { className: css.errorText }, error.message),
                React.createElement(BaseButton, { variant: "optionAction" }, t('Label')))),
            !loading && !error && (React.createElement(React.Fragment, null,
                React.createElement("div", { id: "content__table", ref: contentTableRef },
                    React.createElement("div", { className: css.row },
                        React.createElement("div", { className: cn(css.title, css.headerTitle) },
                            React.createElement("div", { className: css.arrows })),
                        React.createElement("div", { className: cn(css.row, css.slider, {
                                [css.slider_fullConditions]: isFullFareConditions,
                                [css.slider_withoutImages]: !isImagesPresentInResponse
                            }) },
                            React.createElement("div", { className: cn(css.slider) },
                                React.createElement(Slider, Object.assign({}, topSliderSettings, { ref: topSliderRef }), compareTable === null || compareTable === void 0 ? void 0 : compareTable.FareGroupsCompareTable.header.map((fare, index) => (React.createElement("div", { key: fare.title },
                                    React.createElement("div", { className: css.headingWrp },
                                        fare.title === selectedFare && (React.createElement("div", { className: css.selectedFare, ref: selectedFareRef })),
                                        React.createElement("div", { "data-fareIndex": index + 1, className: cn(css.fareHeadingItem, {
                                                [css.fareHeadingItem_isKey]: fare.serviceClass === 'Business',
                                                [css.fareHeadingItem_isFirstClass]: fare.serviceClass === 'First'
                                            }) },
                                            React.createElement("span", { className: css.fareHeadingItem__title }, fare.title ? ` ${fare.title}` : '')),
                                        !isFullFareConditions && (fare === null || fare === void 0 ? void 0 : fare.image) && (React.createElement("img", { className: css.image, src: fare.image, alt: t(fare.title) })))))))))),
                    React.createElement("div", { className: css.col }, compareTable === null || compareTable === void 0 ? void 0 : compareTable.FareGroupsCompareTable.groups.map(group => {
                        return (React.createElement("div", { className: css.col, key: group.title },
                            React.createElement("div", { className: css.groupTitle }, t(group.title)),
                            group.rows.map(row => {
                                var _a;
                                return (React.createElement("div", { key: row.title, className: cn(css.tableRow, {
                                        [css.tableRow_fullDescr]: isFullFareConditions
                                    }) },
                                    React.createElement("div", { className: css.title }, row.title),
                                    React.createElement("div", { className: css.slider },
                                        React.createElement(Slider, Object.assign({ ref: ref => sliders.push(ref) }, sliderSettings), (_a = row.cells) === null || _a === void 0 ? void 0 : _a.map((cell, index) => (React.createElement(OverrideComponent, { key: (cell === null || cell === void 0 ? void 0 : cell.title) || index, componentProps: {
                                                cell,
                                                isFullFareCondition: isFullFareConditions
                                            }, component: { Cell } })))))));
                            })));
                    }))),
                isFullFareConditions && notes && (React.createElement("div", null,
                    React.createElement("h6", { className: css.notesHeading }, t(notes.heading)),
                    React.createElement("ul", { className: css.notesList }, notes.notesList.map((note, index) => (React.createElement("li", { key: index, className: css.notesListItem },
                        index + 1,
                        ". ",
                        t(note))))))),
                React.createElement(Link, { action: handleCompareStateChange, className: css.link },
                    !isFullFareConditions && (React.createElement("span", { className: css.link__wrapper },
                        t('View full fare conditions'),
                        React.createElement("span", { className: css.linkIcon }, chevronIcon))),
                    isFullFareConditions && (React.createElement("span", { className: css.link__wrapper },
                        React.createElement("span", { className: cn(css.linkIcon, { [css.linkIcon_return]: isFullFareConditions }) }, chevronIcon),
                        t('Go back')))))))));
};
export default CompareFares;
