import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoMessageTypeEnum } from '@websky/graphql';
import Button from '../../../../../../BaseComponents/Button';
import ModalX from '../../../../../../BaseComponents/ModalX';
import Notification from '../../../../../../Notification';
import { useTheme } from '../../../../../../theme';
import PlaneSpinner from '../../../../../../PlaneSpinner';
import { hasRequestedServices, REMOVABLE_STATUSES } from './utils';
import Services from './Services/Services';
const UnconfirmedServicesModal = ({ open, unconfirmedServices, order, onRemove, isServicesSaving }) => {
    const { t } = useTranslation('Checkin');
    const theme = useTheme('Checkin').UnconfirmedServicesModal;
    if (!open) {
        return null;
    }
    const statusesAvailableForRemove = Array.from(unconfirmedServices.keys())
        .filter(status => REMOVABLE_STATUSES.includes(status))
        .map(status => t(status.toLowerCase()))
        .join(', ');
    const removeServices = () => {
        const servicesToRemove = [];
        unconfirmedServices.forEach((statusServices, status) => {
            if (REMOVABLE_STATUSES.includes(status)) {
                Array.from(statusServices.entries()).map(([passengerId, segmentServices]) => Array.from(segmentServices.entries()).map(([segmentId, segmentServices]) => {
                    segmentServices.forEach(service => {
                        servicesToRemove.push({
                            service: {
                                id: service.serviceId
                            },
                            segmentId,
                            passengerId
                        });
                    });
                }));
            }
        });
        onRemove(servicesToRemove);
    };
    return (React.createElement(ModalX, { isOpen: true, classes: {
            paper: theme.modal__paper
        }, title: React.createElement("div", { className: theme.modal__header }, t('Unconfirmed or Canceled Services')) },
        React.createElement("div", { className: theme.modal__content },
            React.createElement(Notification, { status: InfoMessageTypeEnum.Warning, type: "context", infoMessage: {
                    text: t('Some services are pending, rejected, or canceled. You can remove them.'),
                    type: InfoMessageTypeEnum.Warning,
                    code: 'warning'
                }, textClassName: theme.notification__text }),
            React.createElement(Services, { order: order, unconfirmedServices: unconfirmedServices }),
            hasRequestedServices(unconfirmedServices) && (React.createElement(React.Fragment, null,
                React.createElement("div", null, t(`Hold on a moment, we're verifying your services.`)),
                React.createElement(PlaneSpinner, { className: theme.loader }))),
            statusesAvailableForRemove && (React.createElement(Button, { className: theme.removeServices, variant: 'smallSecondary', onClick: removeServices, isLoading: isServicesSaving }, t(`Remove {{services}} services`, { services: statusesAvailableForRemove }))))));
};
export default UnconfirmedServicesModal;
