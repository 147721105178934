import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { getSelectedServices } from './selectors';
import { ADD_SERVICE, REMOVE_SERVICE, SAVE_SERVICES, SET_SERVICE, DELETE_ALL_TYPES_ON_SEGMENT, SET_MULTIPLE_SELECTED_SERVICES } from './actions';
import { createClient } from '../../../graphql-client';
import { getIsBookedOrConfirmed, getOrderId, getOrder } from '../order/selectors';
import { fillOrder } from '../order/actions';
import { setOrderPrice } from '../orderPrice/actions';
import { setServicesIsSaving, setServicesSaved } from '../servicesIsSaving/actions';
import { OrderDocument, SaveOrderServicesDocument, OrderPriceQueryDocument } from '@websky/graphql';
import { createExternalSagaActionChannel } from '../../../utils';
export const CHECKOUT_EXTERNAL_ACTION = 'CHECKOUT_EXTERNAL_ACTION';
function priceBreakdownRequest(services, orderId) {
    const client = createClient();
    return client.mutate({
        mutation: OrderPriceQueryDocument,
        variables: {
            params: {
                id: orderId,
                services: services
            }
        }
    });
}
function saveSelectedServicesRequest(services, orderId, context) {
    const client = createClient();
    return client.mutate({
        mutation: SaveOrderServicesDocument,
        variables: {
            params: {
                id: orderId,
                services: services
            }
        },
        context
    });
}
function actualOrderRequest(orderId) {
    const client = createClient();
    return new Promise(resolve => {
        client
            .mutate({
            mutation: OrderDocument,
            variables: {
                id: orderId
            }
        })
            .then(response => {
            var _a;
            if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.Order) {
                resolve(response.data.Order);
            }
            else {
                resolve(null);
            }
        })
            .catch(() => {
            resolve(null);
        });
    });
}
function* getActualOrder(orderId) {
    const actualOrder = yield call(actualOrderRequest, orderId);
    if (actualOrder) {
        return actualOrder;
    }
    const previousOrderState = yield select(getOrder);
    return previousOrderState;
}
function* saveServiceWorker(action) {
    var _a, _b, _c, _d, _e, _f;
    const orderId = yield select(getOrderId);
    const selectedServices = yield select(getSelectedServices);
    const isBookedOrConfirmedOrder = yield select(getIsBookedOrConfirmed);
    yield put(setServicesIsSaving());
    try {
        if (!isBookedOrConfirmedOrder) {
            const orderPrice = yield call(priceBreakdownRequest, selectedServices, orderId);
            if (!((_a = orderPrice.data) === null || _a === void 0 ? void 0 : _a.OrderPrice) || ((_b = orderPrice.errors) === null || _b === void 0 ? void 0 : _b.length)) {
                throw new Error(((_c = orderPrice.errors[0]) === null || _c === void 0 ? void 0 : _c.message) || 'Unknown error');
            }
            yield put(setOrderPrice(orderPrice.data.OrderPrice));
        }
        const saveServices = yield call(saveSelectedServicesRequest, selectedServices, orderId, action.meta);
        if (!((_d = saveServices.data) === null || _d === void 0 ? void 0 : _d.SaveOrderServices) || ((_e = saveServices.errors) === null || _e === void 0 ? void 0 : _e.length)) {
            throw new Error(((_f = saveServices.errors[0]) === null || _f === void 0 ? void 0 : _f.message) || 'Unknown error');
        }
        yield put(fillOrder(saveServices.data.SaveOrderServices));
    }
    catch (_g) {
        const actualOrder = yield call(getActualOrder, orderId);
        yield put(fillOrder(actualOrder));
    }
    yield put(setServicesSaved());
}
export function* saveServicesSaga() {
    yield takeLatest([
        ADD_SERVICE,
        REMOVE_SERVICE,
        SAVE_SERVICES,
        SET_SERVICE,
        DELETE_ALL_TYPES_ON_SEGMENT,
        SET_MULTIPLE_SELECTED_SERVICES
    ], saveServiceWorker);
}
function* externalSaga() {
    const channel = yield createExternalSagaActionChannel(CHECKOUT_EXTERNAL_ACTION);
    while (true) {
        const action = yield take(channel);
        yield put(action);
    }
}
export function* watchExternalActionsSaga() {
    yield externalSaga();
}
