import * as React from 'react';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Autocomplete from '../../Autocomplete';
import { parseLocationGroups } from '../utils';
import MobileDialog from './MobileDialog/MobileDialog';
import { getUserLocation, initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import { LocationType } from '../../types';
import OptionComponent from './Option/Option';
import Dropdown from './Dropdown/Dropdown';
import Group from './Group/Group';
import { ThemeProvider, useTheme } from '../../theme';
import { useConfig } from '../../context';
import { LocationAdditionalGroupType, LocationGroupType, useLocationsQuery } from '@websky/graphql';
initI18n('LocationAutocomplete');
const optionRenderer = (option, key, onClick) => (React.createElement(OptionComponent, { key: key, option: option, onClick: onClick }));
const optionGroupRenderer = (group, key, optionRenderer) => React.createElement(Group, { key: key, group: group, optionRenderer: optionRenderer });
const LocationAutocomplete = forwardRef((props, ref) => {
    const { Autocomplete: theme } = useTheme('LocationAutocomplete');
    const [query, setQuery] = useState('');
    const [lastSuggestion, setLastSuggestion] = useState();
    const [delayLoading, setDelayLoading] = useState(false);
    const [coordinates, setCoordinates] = useState(null);
    const { recentSearches = [], locations = {} } = props;
    const { SearchForm: { requestCoordintates } } = useConfig();
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const autoCompleteTheme = useMemo(() => ({
        Autocomplete: {
            Autocomplete: {
                autocomplete: theme.autocomplete,
                input: theme.input
            }
        }
    }), []);
    if ((isMobile ? requestCoordintates.mobile : requestCoordintates.desktop) && !coordinates) {
        getUserLocation(setCoordinates);
    }
    const { data, loading } = useLocationsQuery({
        variables: {
            parameters: {
                additionalCities: recentSearches.length ? recentSearches.map(location => location.iata) : [],
                additionalGroups: [
                    LocationAdditionalGroupType.Nearest,
                    LocationAdditionalGroupType.AdditionalCities,
                    LocationAdditionalGroupType.Popular
                ],
                query,
                userPosition: coordinates,
                departureFrom: props.type !== LocationType.Departure && locations.departure ? locations.departure.iata : null
            }
        }
    });
    useEffect(() => {
        const nearestAirport = data &&
            data.LocationSuggestions &&
            data.LocationSuggestions.find(location => location.type === LocationGroupType.Nearest);
        if (props.type === LocationType.Departure && !props.locations.departure && nearestAirport) {
            props.onSelect(nearestAirport.cities[0]);
        }
    }, [props.type]);
    useEffect(() => {
        var _a, _b;
        if (props.onNearestAirports &&
            ((_a = lastSuggestion === null || lastSuggestion === void 0 ? void 0 : lastSuggestion.LocationSuggestions) === null || _a === void 0 ? void 0 : _a.some(location => location.type === LocationGroupType.Nearest))) {
            const locations = [];
            (_b = lastSuggestion.LocationSuggestions.filter(location => location.type === LocationGroupType.Nearest)) === null || _b === void 0 ? void 0 : _b.forEach(location => {
                location.cities.forEach(city => locations.push(city));
            });
            props.onNearestAirports(locations);
        }
    }, [lastSuggestion]);
    const onInputChange = (event) => setQuery(event.target.value);
    useEffect(() => {
        setDelayLoading(true);
        if (query.length >= 0 && data && data.LocationSuggestions && data.LocationSuggestions.length) {
            setLastSuggestion(data);
        }
        else if (query.length > 0 && (!data || !data.LocationSuggestions || !data.LocationSuggestions.length)) {
            setTimeout(() => {
                setDelayLoading(false);
                setLastSuggestion(null);
            }, 700);
        }
        else {
            setDelayLoading(false);
        }
    }, [data]);
    const onSelect = (airport) => {
        setQuery('');
        if (props.onSelect) {
            props.onSelect(airport);
        }
    };
    const onBlur = (event) => {
        var _a;
        setQuery('');
        (_a = props === null || props === void 0 ? void 0 : props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, event);
    };
    const handleOptions = (data) => {
        return parseLocationGroups(data && data.LocationSuggestions ? data.LocationSuggestions : [], query, props.type);
    };
    const dropdownRenderer = (dropdownProps) => (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dropdown, Object.assign({}, dropdownProps, { isOpen: dropdownProps.isOpen, hasInputData: !!query.trim(), onClickOutsideProps: props.onClickOutsideProps, onSelect: onSelect }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(MobileDialog, Object.assign({}, dropdownProps, { type: props.type })))));
    return (React.createElement(ThemeProvider, { value: autoCompleteTheme },
        React.createElement(Autocomplete, Object.assign({ innerRef: ref }, props, { isLoading: loading, onSelect: onSelect, options: loading || delayLoading ? handleOptions(lastSuggestion) : handleOptions(data), inputValue: query, onInputChange: onInputChange, optionRenderer: optionRenderer, groupRenderer: optionGroupRenderer, dropdownRenderer: dropdownRenderer, onBlur: onBlur }))));
});
export default LocationAutocomplete;
