import { isAfter, isBefore } from 'date-fns';
export const isSelectedOnlyReturnDate = (selectedDates) => !selectedDates[0] && Boolean(selectedDates[1]);
export const isSelectedOnlyDepartureDate = (selectedDates) => selectedDates[0] && !selectedDates[1];
export const isDatesInReversedOrder = (selectedDates) => selectedDates[0] && selectedDates[1] && isAfter(selectedDates[0], selectedDates[1]);
export const isHoverDateBetweenSelectedDates = (hoverDate, selectedDates) => {
    if (!selectedDates[0] || !selectedDates[1]) {
        return false;
    }
    return isAfter(hoverDate, selectedDates[0]) && isBefore(hoverDate, selectedDates[1]);
};
export const getMinMaxPrice = (prices) => {
    var _a;
    let minPrice = null;
    let maxPrice = null;
    if (prices) {
        for (const value of Object.values(prices)) {
            if (value.price) {
                if (!minPrice || value.price.amount < minPrice) {
                    minPrice = value.price.amount;
                }
                if (!maxPrice || ((_a = value.price) === null || _a === void 0 ? void 0 : _a.amount) > maxPrice) {
                    maxPrice = value.price.amount;
                }
            }
        }
    }
    return [minPrice, maxPrice];
};
