import * as React from 'react';
import { useCallback, useState } from 'react';
import { memo } from 'react';
import MediaQuery from 'react-responsive';
import { useFormState } from 'react-final-form';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import * as theme from '../Passenger.css';
import { Money } from '../../../Money';
import { fieldsArrayToMap, getAdditionalLabel } from '../../utils';
import memes from '../../../memes';
const memoizedGetAdditionalLabel = memes(getAdditionalLabel);
const Header = memo(({ passengerId, label, passengerType, supplierType, servicesTotalPrice, hasServices, setOpen, fields, isReadonly, isOpen }) => {
    const { t } = useTranslation('Passenger');
    const { values: formValues } = useFormState();
    const fieldsMap = fieldsArrayToMap(fields);
    const [values] = useState(formValues.passengers[passengerId]);
    const onHeaderClick = useCallback(() => isReadonly && hasServices && setOpen(!isOpen), [
        isReadonly,
        hasServices,
        isOpen
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: cn(theme.header, {
                    [theme.header_notInteractive]: !isReadonly || !hasServices
                }), onClick: onHeaderClick },
                React.createElement("div", { className: cn(theme.header__text, { [theme.header__text_notpassed]: !isReadonly }) }, label),
                isReadonly && (React.createElement("div", { className: theme.header__additional },
                    React.createElement("span", null, memoizedGetAdditionalLabel(values, fieldsMap, passengerType, supplierType)))),
                servicesTotalPrice && (React.createElement("div", { className: theme.header__services },
                    React.createElement("div", { className: theme.services__label }, t('Onboard services')),
                    React.createElement(Money, { moneyClassName: cn(theme.services__price, {
                            [theme.services__price_null]: servicesTotalPrice.amount === 0
                        }), money: servicesTotalPrice }))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: theme.header },
                React.createElement("div", { className: theme.header__text }, label)))));
});
export default Header;
