import { createSelector } from 'reselect';
import i18n from 'i18next';
import { getPassengersConfig } from '../passengersConfig/selectors';
export const getPassengersState = (state) => state.passengers;
/**
 * Create an array of passengers info for displaying in the dropdown menu.
 */
export const getPassengers = createSelector(getPassengersState, passengers => {
    const result = [];
    for (const type in passengers) {
        if (passengers.hasOwnProperty(type)) {
            result.push({
                type: type,
                count: passengers[type] || 0
            });
        }
    }
    return result;
});
export const getPassengersForRequest = createSelector(getPassengers, getPassengersConfig, (passengers, config) => {
    const passengersForRequest = [];
    for (const passenger of passengers) {
        const passengerConfig = config[passenger.type];
        if (!config[passenger.type]) {
            continue;
        }
        passengersForRequest.push({
            passengerType: (passengerConfig === null || passengerConfig === void 0 ? void 0 : passengerConfig.parentCode)
                ? passengerConfig.parentCode
                : passenger.type,
            extendedPassengerType: (passengerConfig === null || passengerConfig === void 0 ? void 0 : passengerConfig.parentCode) ? passenger.type : null,
            count: passenger.count
        });
    }
    return passengersForRequest;
});
/**
 * Count a total number of the selected passengers.
 */
export const getTotalPassengersCount = createSelector(getPassengers, (passengers) => passengers.reduce((total, passenger) => total + passenger.count, 0));
/**
 * Create a title for displaying total passengers number.
 */
export const getPassengersTitle = createSelector(getTotalPassengersCount, totalCount => `${totalCount} ${i18n.t('SearchForm:passenger', {
    count: totalCount
})}`);
export const getPassengersErrors = createSelector(getPassengers, getPassengersConfig, getTotalPassengersCount, (state, passengersConfig, totalPassCount) => {
    // no empty
    if (totalPassCount === 0) {
        return { error: 'Please select passengers' };
    }
    const passengersWithRequiredOneOf = Object.values(passengersConfig).filter(passengerConfig => { var _a; return (_a = passengerConfig.requiredWithOneOf) === null || _a === void 0 ? void 0 : _a.length; });
    // all required selected
    const selectedPassengers = state.filter(statePassenger => statePassenger.count);
    const selectedPassengersTypes = selectedPassengers.map(({ type }) => type);
    for (const requiredOneOfPass of passengersWithRequiredOneOf) {
        // passenger with required selected
        if (selectedPassengersTypes.includes(requiredOneOfPass.code)) {
            const requiredPassengers = requiredOneOfPass.requiredWithOneOf;
            const isSomeOfRequiredSelected = requiredPassengers.some(requiredPassengerCode => selectedPassengersTypes.includes(requiredPassengerCode));
            if (!isSomeOfRequiredSelected) {
                return { error: `required passengers`, interpolateData: requiredPassengers };
            }
        }
    }
    return undefined;
});
export const getHasPassengersErrors = createSelector(getPassengersErrors, errors => errors !== undefined);
