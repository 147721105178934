import LoginForm from './components/LoginForm/LoginForm/LoginForm.css';
import LoginTypeSelectorForm from './components/LoginForm/LoginTypeSelectorForm/LoginTypeSelectorForm.css';
import LoginTypeSelector from './components/LoginForm/LoginTypeSelectorForm/LoginTypeSelector/LoginTypeSelector.css';
import RegistrationForm from './components/LoginForm/RegistrationForm/RegistrationForm.css';
import CodeForm from './components/LoginForm/CodeForm/CodeForm.css';
import Welcome from './components/LoginForm/Welcome/Welcome.css';
import SubmitButton from './components/LoginForm/SubmitButton/SubmitButton.css';
import BaseComponent from './components/LoginForm/BaseComponent/BaseComponent.css';
import StayLoggedIn from './components/LoginForm/LoginForm/StayLoggedIn/StayLoggedIn.css';
export default {
    LoginForm,
    LoginTypeSelectorForm,
    LoginTypeSelector,
    RegistrationForm,
    CodeForm,
    Welcome,
    SubmitButton,
    BaseComponent,
    StayLoggedIn
};
