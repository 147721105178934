import classnames from 'classnames';
import * as React from 'react';
import { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { clearDates, removeSegment, setDate, setDateMultiCity, setLocation, swapLocations, swapMultiLocations, addSegment } from '../../../../store/segments/actions';
import { canRemoveSegment, getSelectedDates, isMultiCity, makeGetSegment } from '../../../../store/segments/selectors';
import { LocationType } from '../../../../../types';
import Datepicker from './Datepicker/Datepicker';
import LocationField from './Location/Location';
import { addRecentSearch } from '../../../../store/recentSearches/actions';
import { getRecentSearches } from '../../../../store/recentSearches/selectors';
import { useConfig } from '../../../../../context';
import { useTheme } from '../../../../../theme';
import { getPassengersForRequest } from '../../../../store/passengers/selectors';
import { Add, Minus } from '../../../../../Icons';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import { OverrideComponent } from '../../../../../renderProps';
const datesAreNotEqual = (prevDates, nextDates) => {
    // Quick check.
    if (prevDates.length !== nextDates.length) {
        return true;
    }
    // Slow check.
    const numOfDates = prevDates.length;
    for (let i = 0; i < numOfDates; i++) {
        if (prevDates[i] !== nextDates[i]) {
            return true;
        }
    }
    return false;
};
const Segment = memo(({ isMultiCity, recentSearches, setDate, setDateMultiCity, swapLocations, swapMultiLocations, setLocation, segmentId, segment, selectedDates, clearDates, addRecentSearch, removeSegment, canRemoveSegment, passengers, addSegment }) => {
    const { Segment: css } = useTheme('SearchForm');
    const alternative = useConfig().SearchForm.mobileFormType !== 'common';
    const showPricesInCalendar = useConfig().SearchForm.showPricesInDatepicker;
    const proMode = useConfig().SearchForm.proMode;
    const onRemoveSegment = useCallback(() => removeSegment(segmentId), [segmentId]);
    const onDateChange = useCallback((date, isBackDate = false) => {
        if (isMultiCity) {
            setDateMultiCity(date, segmentId);
        }
        else {
            setDate(date, isBackDate);
            if (!isBackDate) {
                // Reset back date.
                setDate(null, true);
            }
        }
    }, [segmentId, isMultiCity]);
    const onSetLocation = useCallback((location, type) => {
        if (type === LocationType.Departure && location.iata === (segment.arrival && segment.arrival.iata)) {
            setLocation(null, LocationType.Arrival);
        }
        if (isMultiCity) {
            setLocation(location, type, segmentId);
        }
        else {
            setLocation(location, type);
        }
        addRecentSearch(location);
    }, [segmentId, isMultiCity, segment]);
    const onSwapHandler = useCallback(() => {
        if (isMultiCity) {
            swapMultiLocations(segmentId);
        }
        else {
            swapLocations();
        }
    }, [isMultiCity, segmentId, swapLocations, swapMultiLocations]);
    return (React.createElement("div", { className: classnames(css.segment, { [css.alternative]: alternative, [css.segment_advanced]: proMode }) },
        React.createElement("div", { className: classnames(css.wrapper, { [css.withMultiCity]: isMultiCity }) },
            React.createElement("div", { className: `${css.cell} ${css.cell_location}` },
                React.createElement("div", { className: css.departureInputWrapper },
                    React.createElement(OverrideComponent, { componentProps: {
                            type: LocationType.Departure,
                            segmentId,
                            locations: {
                                departure: segment.departure,
                                arrival: segment.arrival
                            },
                            setLocation: onSetLocation,
                            onSwap: onSwapHandler,
                            recentSearches,
                            withMultiCity: isMultiCity
                        }, component: { SearchFormLocation: LocationField } }))),
            React.createElement("div", { className: `${css.cell} ${css.cell_location} ${css.cell_location_arrival}` },
                React.createElement(OverrideComponent, { componentProps: {
                        type: LocationType.Arrival,
                        segmentId,
                        locations: {
                            departure: segment.departure,
                            arrival: segment.arrival
                        },
                        setLocation: onSetLocation,
                        recentSearches,
                        withMultiCity: isMultiCity
                    }, component: { SearchFormLocation: LocationField } })),
            React.createElement("div", { className: classnames(css.cell, css.cell_date, {
                    [css.cell_fixed]: isMultiCity
                }) },
                React.createElement(OverrideComponent, { componentProps: {
                        valueClassName: css.datepicker_value,
                        passengers,
                        dateTo: segment.date,
                        dateBack: segment.dateBack,
                        selectedDates,
                        onDateChange,
                        withDateBack: !isMultiCity,
                        showPrices: showPricesInCalendar,
                        clearDates,
                        segmentId,
                        locations: {
                            departure: segment.departure,
                            arrival: segment.arrival
                        }
                    }, component: {
                        SearchFormSegmentDatepicker: Datepicker
                    } }))),
        isMultiCity && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { onClick: segmentId === 0 ? addSegment : onRemoveSegment, className: css.control }, segmentId === 0 ? Add : Minus)))));
}, (prevProps, nextProps) => prevProps.segmentId === nextProps.segmentId &&
    prevProps.segment === nextProps.segment &&
    !datesAreNotEqual(prevProps.selectedDates, nextProps.selectedDates) &&
    prevProps.isMultiCity === nextProps.isMultiCity &&
    prevProps.canRemoveSegment === nextProps.canRemoveSegment);
const mapStateToProps = (state, ownProps) => {
    const getSegment = makeGetSegment(ownProps.segmentId);
    return (state, ownProps) => {
        return Object.assign(Object.assign({}, ownProps), { selectedDates: getSelectedDates(state), isMultiCity: isMultiCity(state), canRemoveSegment: canRemoveSegment(state), segment: getSegment(state), recentSearches: getRecentSearches(state), passengers: getPassengersForRequest(state) });
    };
};
const mapDispatchToProps = {
    removeSegment,
    setDate,
    setDateMultiCity,
    clearDates,
    setLocation,
    swapLocations,
    swapMultiLocations,
    addRecentSearch,
    addSegment
};
export default connect(mapStateToProps, mapDispatchToProps)(Segment);
