import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
const PROMO_ALIAS = 'Buy now and save 20-30%';
const PromoMessage = ({ className }) => {
    const { PromoMessage: css } = useTheme('AdditionalBaggage');
    const { t, i18n } = useTranslation('AdditionalBaggage');
    const message = t(PROMO_ALIAS);
    const existMessage = i18n.exists(PROMO_ALIAS, { ns: 'AdditionalBaggage' });
    if (!message || !existMessage) {
        return null;
    }
    return React.createElement("div", { className: cn(css.root, className) }, message);
};
export default PromoMessage;
