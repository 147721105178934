import { createSelector } from 'reselect';
import memes from '../../../memes';
import { sum } from '../../../utils';
export const getSelectedFares = (state) => state.selectedFares;
const memoizedSum = memes(sum);
export const getTotalPrice = createSelector([getSelectedFares], (selectedFares) => {
    const lastSelectedFare = selectedFares[selectedFares.length - 1];
    const flightId = lastSelectedFare === null || lastSelectedFare === void 0 ? void 0 : lastSelectedFare.flightInfo.id;
    const money = selectedFares.map(({ prices }) => prices.find(price => price.flight.id === flightId).price);
    return memoizedSum(...money);
});
export const getCreateOrderUrl = createSelector([getSelectedFares], (selectedFares) => {
    return `#/results/create-order/${selectedFares.map(selectedFare => selectedFare.flightInfo.id).join('/')}`;
});
