import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { groupRefundServicesByPassenger, groupServicesByType } from '../utils';
import Baggage from './Services/Baggage/Baggage';
import Meal from './Services/Meal/Meal';
import { useTheme } from '../../theme';
import Seat from './Services/Seat/Seat';
import Notice from '../components/Notice/Notice';
import Stub from './Services/Stub/Stub';
import Insurance from './Services/Insurance/Insurance';
import { useCallback } from 'react';
import { isCheckoutOrderGuard } from '../../typeguards';
import { OverrideComponent } from '../../renderProps';
const RefundSummary = ({ className, order, gdsServicesRefund, insurancesRefund, notification, isLoading, customWarningMessage }) => {
    const { t } = useTranslation('Exchange');
    const groupServices = React.useMemo(() => {
        var _a, _b;
        if ((_a = gdsServicesRefund === null || gdsServicesRefund === void 0 ? void 0 : gdsServicesRefund.GetGdsServicesRefundInfo) === null || _a === void 0 ? void 0 : _a.gdsServicesRefundInfo) {
            const servicesByType = groupServicesByType((_b = gdsServicesRefund === null || gdsServicesRefund === void 0 ? void 0 : gdsServicesRefund.GetGdsServicesRefundInfo) === null || _b === void 0 ? void 0 : _b.gdsServicesRefundInfo);
            const refundServicesByType = new Map();
            servicesByType.forEach((value, key) => {
                refundServicesByType.set(key, groupRefundServicesByPassenger(value));
            });
            return refundServicesByType;
        }
        return null;
    }, [gdsServicesRefund]);
    const getServiceCardByType = (refundServices, type) => {
        const props = {
            passengers: order.travellers,
            services: refundServices,
            flight: isCheckoutOrderGuard(order) ? order.flight : order.segments
        };
        switch (type) {
            case OrderAdditionalServiceGdsServiceServiceType.Baggage:
                return React.createElement(Baggage, Object.assign({}, props));
            case OrderAdditionalServiceGdsServiceServiceType.Meal:
                return React.createElement(Meal, Object.assign({}, props));
            case OrderAdditionalServiceGdsServiceServiceType.Seat:
                return React.createElement(Seat, Object.assign({}, props));
        }
        return null;
    };
    const renderServicesCard = React.useMemo(() => {
        const services = [];
        groupServices === null || groupServices === void 0 ? void 0 : groupServices.forEach((value, key) => {
            if (Object.keys(value).length > 0) {
                services.push(getServiceCardByType(value, key));
            }
        });
        if ((insurancesRefund === null || insurancesRefund === void 0 ? void 0 : insurancesRefund.length) && isCheckoutOrderGuard(order)) {
            services.push(React.createElement(OverrideComponent, { componentProps: {
                    order,
                    services: insurancesRefund
                }, component: { RefundSummaryInsurance: Insurance } }));
        }
        if (services.length) {
            return services.map((component, key) => {
                return React.createElement(React.Fragment, { key: key }, component);
            });
        }
        return null;
    }, [groupServices, insurancesRefund]);
    const theme = useTheme('RefundSummary').RefundSummary;
    const WarningMessage = useCallback(() => {
        if (customWarningMessage) {
            return customWarningMessage;
        }
        return (React.createElement("div", null,
            React.createElement("div", { className: theme.notifications },
                React.createElement(Notice, { type: 'error', header: t('Exchange restrictions'), text: t('All services issued on one electronic document will be canceled, even if you change itinerary partial. Money refund will be carried out by the airline in accordance with the terms of the Puplic Offer') }),
                notification),
            React.createElement("div", { className: cn(theme.selectedServices, className) }, renderServicesCard)));
    }, [isLoading, notification, renderServicesCard, customWarningMessage]);
    return (React.createElement(React.Fragment, null,
        isLoading && (React.createElement("div", { className: cn(theme.container, className) },
            React.createElement(Stub, null),
            React.createElement(Stub, null),
            React.createElement(Stub, null))),
        !isLoading && !!renderServicesCard && React.createElement(WarningMessage, null)));
};
export default RefundSummary;
