import { __awaiter } from "tslib";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LoginType, useAccountExistenceQuery, useGetUserSettingsQuery } from '@websky/graphql';
import { UserSettings } from './types';
import { getUserSettingsByName } from './utils';
import { AUTH_DUPLICATES, removeFromSessionStorage, sessionStorageGet } from '../cache';
import { Log } from '../sentry';
export const useUserSettings = (options) => {
    const { data, loading, refetch } = useGetUserSettingsQuery(options);
    return {
        userSettings: {
            travellerId: getUserSettingsByName(data === null || data === void 0 ? void 0 : data.UserSettings, UserSettings.TravellerID),
            termOfUse: getUserSettingsByName(data === null || data === void 0 ? void 0 : data.UserSettings, UserSettings.TermOfUse),
            subscribed: getUserSettingsByName(data === null || data === void 0 ? void 0 : data.UserSettings, UserSettings.Subscribe) === 'true'
        },
        refetchUserSettings: refetch,
        loading
    };
};
export const useEmailDuplication = (accountInfo) => {
    const emailIsChecked = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const { refetch: checkAccountExistence } = useAccountExistenceQuery({ skip: true });
    const authDuplicates = sessionStorageGet(AUTH_DUPLICATES);
    const { emailMethod, ffpMethod, ffpEmail } = useMemo(() => {
        var _a, _b, _c;
        const authMethods = (_a = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.authMethods) !== null && _a !== void 0 ? _a : [];
        return {
            emailMethod: authMethods.find(method => method.loginType === LoginType.Email),
            ffpMethod: authMethods.find(method => method.loginType === LoginType.FFP),
            ffpEmail: (_c = (_b = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.userFfpInfo) === null || _b === void 0 ? void 0 : _b.registerEmail) !== null && _c !== void 0 ? _c : null
        };
    }, [accountInfo]);
    const isNeedConnectFfp = useMemo(() => {
        if (!authDuplicates || !authDuplicates.isExistDuplicate || isLoading) {
            return false;
        }
        return !ffpMethod || (ffpMethod && !ffpMethod.confirmed);
    }, [ffpMethod, authDuplicates, isLoading]);
    const isNeedConnectEmail = useMemo(() => {
        if (!authDuplicates || authDuplicates.isExistDuplicate || isLoading) {
            return false;
        }
        return !emailMethod || (emailMethod && !emailMethod.confirmed);
    }, [emailMethod, authDuplicates, isLoading]);
    const connectFfp = isNeedConnectFfp ? authDuplicates === null || authDuplicates === void 0 ? void 0 : authDuplicates.login : null;
    const connectEmail = isNeedConnectEmail ? ffpEmail : null;
    const onCheckEmailExistence = useCallback((email) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsLoading(true);
        const { data } = yield checkAccountExistence({
            params: {
                loginType: LoginType.Email,
                login: email
            }
        });
        if ((_a = data === null || data === void 0 ? void 0 : data.AccountExistence) === null || _a === void 0 ? void 0 : _a.result) {
            removeFromSessionStorage(AUTH_DUPLICATES);
        }
        emailIsChecked.current = true;
        setIsLoading(false);
    }), [emailIsChecked, checkAccountExistence]);
    const onConnectResult = useCallback((result) => {
        const method = isNeedConnectFfp ? 'fpp card' : 'email';
        if (result) {
            Log({ level: 'info', message: `successful linking the ${method}` });
        }
        else {
            Log({ level: 'error', message: `unsuccessful linking the ${method}` });
        }
        removeFromSessionStorage(AUTH_DUPLICATES);
    }, [isNeedConnectFfp]);
    useEffect(() => {
        if (authDuplicates && (emailMethod === null || emailMethod === void 0 ? void 0 : emailMethod.confirmed) && (ffpMethod === null || ffpMethod === void 0 ? void 0 : ffpMethod.confirmed)) {
            removeFromSessionStorage(AUTH_DUPLICATES);
        }
        // Before opening the form to bind the email to the account, we send a request
        // to check if the email exists. If it is already used in another account,
        // we do not open the modal window (clear session storage).
        if (!emailIsChecked.current && !(authDuplicates === null || authDuplicates === void 0 ? void 0 : authDuplicates.isExistDuplicate) && isNeedConnectEmail && connectEmail) {
            onCheckEmailExistence(connectEmail);
        }
    }, [authDuplicates]);
    return {
        // data
        authDuplicates,
        isLoading,
        isNeedConnectFfp,
        isNeedConnectEmail,
        connectFfp,
        connectEmail,
        // methods
        onConnectResult
    };
};
