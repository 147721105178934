import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { TravellerFieldEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import { convertGenderToCorrectValue, decapitalizeFirstLetter, initI18n } from '../../utils';
import { hideText } from '../../Passenger/utils';
import { Person } from '../../Icons';
import Button from '../../Button';
initI18n('SelectedPassenger');
const SelectedPassenger = ({ className, passenger: passengerValues, services, onDelete }) => {
    const css = useTheme('SelectedPassenger').SelectedPassenger;
    const { t } = useTranslation('SelectedPassenger');
    const passenger = {};
    const nationalities = new Map();
    passengerValues.values.forEach(value => {
        const fieldName = decapitalizeFirstLetter(value.type);
        if (value.type === TravellerFieldEnum.Gender) {
            passenger[fieldName] = convertGenderToCorrectValue(value.value);
        }
        else {
            passenger[fieldName] = value.value;
        }
        if (value.type === TravellerFieldEnum.Nationality) {
            value.validationRules[0].options.forEach(nationality => {
                nationalities.set(nationality.value, nationality.label);
            });
        }
    });
    const onDeleteHandler = () => {
        onDelete === null || onDelete === void 0 ? void 0 : onDelete(passengerValues);
    };
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.passenger },
            React.createElement("div", { className: css.passengerContent },
                React.createElement("div", { className: css.icon }, Person),
                React.createElement("div", { className: css.passengerName },
                    passenger.firstName.toLowerCase(),
                    " ",
                    passenger.lastName.toLowerCase()),
                React.createElement("div", { className: css.passengerInfo },
                    React.createElement("div", { className: css.passengerInfo__item },
                        React.createElement("span", null, passenger.birthDate),
                        passenger.gender && (React.createElement(React.Fragment, null,
                            ", ",
                            React.createElement("span", null, t(convertGenderToCorrectValue(passenger.gender)))))),
                    React.createElement("div", { className: css.passengerInfo__item },
                        React.createElement("span", null,
                            t('Citizenship'),
                            ": "),
                        React.createElement("span", null,
                            nationalities.get(passenger.nationality),
                            ", "),
                        React.createElement("span", null,
                            t(passenger.docType),
                            ": "),
                        ' ',
                        React.createElement("span", null,
                            passenger.docNumber ? hideText(passenger.docNumber) : '-',
                            ","),
                        ' ',
                        React.createElement("span", null,
                            t('Expiry date'),
                            ": "),
                        " ",
                        React.createElement("span", null, passenger.docExpiryDate)),
                    React.createElement("div", { className: css.passengerInfo__item },
                        React.createElement("span", null,
                            t('Ticket'),
                            ": "),
                        React.createElement("span", null, passengerValues.tickets.map(({ number }) => number).join(', '))),
                    passenger.loyaltyNumber && (React.createElement("div", { className: css.passengerInfo__item },
                        t('Frequent Flyer Number'),
                        ": ",
                        passenger.loyaltyNumber)))),
            onDelete && (React.createElement(Button, { variant: "text", className: css.button, onClick: onDeleteHandler }, t('Remove passenger')))),
        services && React.createElement("div", { className: css.services }, services)));
};
export default SelectedPassenger;
