import { __rest } from "tslib";
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
const Loader = (_a) => {
    var { classes, size = 19 } = _a, restProps = __rest(_a, ["classes", "size"]);
    const { Loader: theme } = useTheme('BaseComponents');
    return (React.createElement(CircularProgress, Object.assign({ classes: Object.assign(Object.assign({}, classes), { colorPrimary: cn(theme.colorPrimary, classes === null || classes === void 0 ? void 0 : classes.colorPrimary) }), size: size }, restProps)));
};
export default Loader;
