import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { InfoDetails } from '../../Icons';
import { useTransitTooltip } from '../hooks';
import Tooltip from '../../Tooltip';
import { useConfig } from '../../context';
import MediaQuery from '../../MediaQuery/MediaQuery';
import { ClickAwayListener } from '@mui/material';
const TransitTooltip = () => {
    const { t } = useTranslation('FlightPrice');
    const { TransitTooltip: css } = useTheme('TransitTooltip');
    const { global: { companyInfo: { transferM2ConditionURL } } } = useConfig();
    const { open: openTransitTooltip, isOpen: isTransitTooltipOpen, close: closeTransitTooltip } = useTransitTooltip();
    return (React.createElement(Tooltip, { open: isTransitTooltipOpen, titleClassName: css.transitTooltip__title, popperClassName: css.transitTooltip, title: React.createElement("div", { onClick: e => e.stopPropagation(), onMouseOver: openTransitTooltip, onMouseLeave: closeTransitTooltip },
            React.createElement("div", null, t('Special conditions for flight delays and ticket refunds')),
            transferM2ConditionURL && (React.createElement("a", { href: transferM2ConditionURL, onClick: e => e.stopPropagation(), target: "_blank" },
                React.createElement("div", null, t('Full conditions'))))) },
        React.createElement("div", null,
            React.createElement(MediaQuery, { minWidth: 'tablet' },
                React.createElement("a", { href: transferM2ConditionURL || null, onClick: e => e.stopPropagation(), target: "_blank", className: css.transitTooltip__text, onMouseOver: openTransitTooltip, onMouseLeave: closeTransitTooltip },
                    t('Transit tariff'),
                    " ",
                    InfoDetails)),
            React.createElement(MediaQuery, { maxWidth: 'tablet' },
                React.createElement(ClickAwayListener, { onClickAway: closeTransitTooltip },
                    React.createElement("div", { className: css.transitTooltip__text, onClick: openTransitTooltip },
                        t('Transit tariff'),
                        " ",
                        InfoDetails))))));
};
export default TransitTooltip;
