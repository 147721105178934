import { z } from 'zod';
// Examples:
// https://apps.apple.com/id/app/websky-app/id1567545689
// https://play.google.com/store/apps/details?id=aero.websky.demo
// https://websky.tech
// https://google.com
const defaultUrlRegex = /(https:\/\/(websky|google))|(websky-app|websky.demo)/;
const defaultUrlMessage = 'The string must not include default url';
export const BooleanOptional = z.boolean().nullish();
export const NumberOptional = z.number().nullish();
export const StringOptional = z
    .string()
    .refine(value => !defaultUrlRegex.test(value), { message: defaultUrlMessage })
    .nullish();
export const StringRequired = z.string().refine(value => value.length && !defaultUrlRegex.test(value), {
    message: `The string must not be empty. ${defaultUrlMessage}`
});
