import * as React from 'react';
import { NASProductCategory } from '@websky/graphql';
import { useEveryPort } from '../../../CheckoutAdditionalService/components/EveryPortLounge/everyPort.hooks';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
const NasSummary = () => {
    const model = useEveryPort(NASProductCategory.lounge, '0');
    return (React.createElement(ServiceSummaryDivider, null, model.selectedSummary.passengerNames.map(passengerName => (React.createElement(ServiceSummaryOption, { key: passengerName.name, groupOption: {
            header: passengerName.name,
            allSegments: false,
            items: model.selectedSummary.texts.map(text => ({
                id: text.name,
                name: `${text.name}`,
                header: text
            }))
        } })))));
};
export default NasSummary;
