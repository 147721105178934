import * as React from 'react';
import * as css from './Segment.css';
import TransferInfo from '../../../TransferInfo';
import MobileSegmentInfo from '../../../MobileSegmentInfo';
const Segment = ({ segment, transferDuration, nextSegment }) => {
    return (React.createElement("div", { className: css.segment },
        React.createElement(MobileSegmentInfo, { showFullInfo: true, segments: [
                {
                    transferDuration: transferDuration,
                    segment
                }
            ] }),
        transferDuration && nextSegment && (React.createElement(TransferInfo, { className: css.transfer, segment: segment, nextSegment: nextSegment, transferDuration: transferDuration }))));
};
export default Segment;
