import * as RetrieveBooking from './components/RetrieveBooking.css';
import * as ClarifyDocumentModal from './components/ClarifyDocumentModal/ClarifyDocumentModal.css';
import * as SegmentCommon from './components/Segment/SegmentCommon/SegmentCommon.css';
import * as SegmentCompact from './components/Segment/SegmentCompact/SegmentCompact.css';
import * as MobileSegment from './components/MobileSegment/MobileSegment.css';
export default {
    RetrieveBooking,
    ClarifyDocumentModal,
    SegmentCommon,
    SegmentCompact,
    MobileSegment
};
