import * as React from 'react';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Link from '../../../Link';
import { Mail } from '../../../Icons';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import Modal from '../../../Modal';
import ResendTicketsForm from '../../../ResendTicketsForm/components/ResendTicketsForm';
import { OrderCardContext } from '../OrderCard';
import { useResendTickets } from '../../../Checkout/components/Checkout/hooks';
import { hideText } from '../../../Passenger/utils';
import CommonItem from '../CommonItem/CommonItem';
import { SlideBottom } from '../../../index';
import { getUserValue } from '../../../utils';
import { TravellerFieldEnum } from '@websky/graphql';
import WarningModal from '../../../WarningModal';
const OrderTickets = props => {
    const { t } = useTranslation('OrderCard');
    const { OrderTickets: css } = useTheme('OrderCard');
    const { order } = useContext(OrderCardContext);
    const email = useMemo(() => getUserValue(order.customer, TravellerFieldEnum.Email), [order.id]);
    const { resendStage, handleWarningModalClose, handleResendEmail, isModalOpen, isUnauthorizedError, closeModal, openModal } = useResendTickets(order.id);
    return (React.createElement(React.Fragment, null,
        React.createElement(CommonItem, { icon: Mail, text: t(!email ? 'Send tickets to the email?' : 'Tickets have been sent to {{email}}', {
                email: hideText(email)
            }), className: cn(css.wrapper, props.className), buttons: React.createElement(Link, { className: css.link, action: openModal }, t('Resend it')) }),
        React.createElement(MediaQuery, { minWidth: "mobile" },
            React.createElement(Modal, { open: isModalOpen, closeButtonRenderer: () => null, maxWidth: "sm" },
                React.createElement(ResendTicketsForm, { onCancel: closeModal, defaultEmail: email, onSubmit: ({ email }) => handleResendEmail(email), status: resendStage }))),
        React.createElement(MediaQuery, { maxWidth: "mobile" },
            React.createElement(SlideBottom, { isOpen: isModalOpen, onClose: closeModal },
                React.createElement(ResendTicketsForm, { onCancel: closeModal, defaultEmail: email, onSubmit: ({ email }) => handleResendEmail(email), status: resendStage }))),
        React.createElement(WarningModal, { isOpen: isUnauthorizedError, title: t('Error'), content: t('To submit documents, log in to your account or use the email provided during the booking.'), buttonText: t('Close'), onButtonClick: handleWarningModalClose, onClose: handleWarningModalClose })));
};
export default OrderTickets;
