import * as React from 'react';
import { useCallback } from 'react';
import { FormSpy, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import LocationAutocomplete from '../../../LocationAutocomplete';
import Value from '../Value/Value';
import Tooltip from '../../../Tooltip';
import { LocationType } from '../../types';
import { useIsMobile } from '../../../utils';
import { CloseClear } from '../../../Icons';
const Location = ({ className, inputClassName, placeholder, locations, onSelect, onClear, type }) => {
    const { Location: theme } = useTheme('ScheduleForm');
    const { t } = useTranslation('ScheduleForm');
    const location = locations[type];
    const isMobile = useIsMobile();
    const renderInput = useCallback(({ input }) => (isOpen, isLoading, inputProps) => {
        const onChange = (e) => {
            var _a;
            input.onChange(e);
            (_a = inputProps === null || inputProps === void 0 ? void 0 : inputProps.onChange) === null || _a === void 0 ? void 0 : _a.call(inputProps, e);
        };
        const onFocus = (e) => {
            var _a;
            input.onFocus(e);
            (_a = inputProps === null || inputProps === void 0 ? void 0 : inputProps.onFocus) === null || _a === void 0 ? void 0 : _a.call(inputProps, e);
        };
        const onBlur = (e) => {
            var _a;
            input === null || input === void 0 ? void 0 : input.onBlur(e);
            (_a = inputProps === null || inputProps === void 0 ? void 0 : inputProps.onBlur) === null || _a === void 0 ? void 0 : _a.call(inputProps, e);
        };
        const fieldProps = Object.assign(Object.assign(Object.assign({}, input), inputProps), { onChange,
            onFocus,
            onBlur });
        const fieldClassName = cn(theme.input, inputClassName);
        const fieldPlaceholder = !location ? placeholder : null;
        return (React.createElement("div", { className: theme.input_wrapper },
            isMobile ? (React.createElement("div", { className: fieldClassName, onClick: inputProps.onFocus }, fieldPlaceholder)) : (React.createElement("input", Object.assign({}, fieldProps, { className: fieldClassName, placeholder: !isOpen ? fieldPlaceholder : null, autoComplete: "one-time-code" }))),
            React.createElement(Value, { location: location, value: inputProps.value, placeholder: t(type === LocationType.Departure
                    ? 'From, enter city or country'
                    : 'To, enter city or country'), isOpen: isOpen && !isMobile, isLoading: isLoading }),
            location && (React.createElement("div", { className: theme.clear, onClick: () => {
                    onClear === null || onClear === void 0 ? void 0 : onClear(type);
                } }, CloseClear))));
    }, [className, location, isMobile]);
    const onSelectHandler = useCallback((location) => onSelect(location, type), [onSelect, type]);
    const validate = useCallback((value, allValues, meta) => {
        const data = meta.data;
        if (!data || !data[type]) {
            return t('Please select {{type}} airport', {
                type: t(type)
            });
        }
        if (data && data.arrival && data.departure && data.arrival.iata === data.departure.iata) {
            return t('Please select different airports');
        }
    }, [type]);
    return (React.createElement(FormSpy, { subscription: {
            submitFailed: true
        } }, formState => (React.createElement(Field, { name: type, validate: validate, subscription: {
            data: true,
            error: true
        }, render: field => {
            if (location !== null || typeof field.meta.data[type] !== 'undefined') {
                formState.form.mutators.setField(field.input.name, locations);
            }
            return (React.createElement(Tooltip, { title: field.meta.error, open: !!field.meta.error && formState.submitFailed },
                React.createElement(LocationAutocomplete, { className: className, type: type, locations: locations, onSelect: onSelectHandler, inputRenderer: renderInput(field) })));
        } }))));
};
export default Location;
