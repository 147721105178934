import { __awaiter } from "tslib";
import { useState, useCallback } from 'react';
import { useFindOrderMutation, useFindGroupOrderMutation, useGetUserProfileQuery } from '@websky/graphql';
import { useSecureAccess } from '../../../../SecureAccess/SecureAccess.hook';
import { ApolloError } from 'apollo-boost';
export function useFindOrder({ onSuccess }) {
    const { data: userProfile, loading: userProfileLoading, refetch: getUserProfile } = useGetUserProfileQuery({
        fetchPolicy: 'no-cache'
    });
    const [findOrderMutation, { loading: findOrderLoading }] = useFindOrderMutation();
    const [findGroupOrderMutation, { loading: findGroupOrderLoading }] = useFindGroupOrderMutation();
    const secureAccess = useSecureAccess();
    const [orderPreview, setOrderPreview] = useState(null);
    const [groupOrderParams, setGroupOrderParams] = useState(null);
    const [groupOrderResponse, setGroupOrderResponse] = useState(null);
    const getIsAuthorizedUser = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!userProfileLoading) {
            return !!(userProfile === null || userProfile === void 0 ? void 0 : userProfile.CurrentUser);
        }
        try {
            const { data } = yield getUserProfile();
            return !!(data === null || data === void 0 ? void 0 : data.CurrentUser);
        }
        catch (_a) {
            return false;
        }
    }), [userProfile, userProfileLoading, getUserProfile]);
    const onHideOrderPreview = useCallback(() => {
        setOrderPreview(null);
    }, [setOrderPreview]);
    const onFindOrder = useCallback((params) => __awaiter(this, void 0, void 0, function* () {
        var _b;
        onHideOrderPreview();
        const isAuthorized = yield getIsAuthorizedUser();
        try {
            const { data } = yield findOrderMutation({
                variables: {
                    params: Object.assign(Object.assign({}, params), { saveInProfile: false })
                }
            });
            if (data === null || data === void 0 ? void 0 : data.FindOrder) {
                if (isAuthorized) {
                    setOrderPreview(data.FindOrder);
                }
                else {
                    onSuccess(data.FindOrder);
                }
                return true;
            }
            else {
                return false;
            }
        }
        catch (e) {
            if (e instanceof ApolloError && ((_b = e.graphQLErrors) === null || _b === void 0 ? void 0 : _b.length)) {
                const errors = e.graphQLErrors;
                const isGroupOrder = errors.some(error => {
                    var _a, _b;
                    return (((_a = error === null || error === void 0 ? void 0 : error.debugMessage) === null || _a === void 0 ? void 0 : _a.includes('group order')) ||
                        ((_b = error === null || error === void 0 ? void 0 : error.message) === null || _b === void 0 ? void 0 : _b.includes('group order')));
                });
                if (secureAccess.isNeedToOpenSecureAccessModal(errors)) {
                    secureAccess.open();
                    secureAccess.setSubmitParams({ params: { id: params.id, secret: params.secret } });
                }
                if (isGroupOrder) {
                    secureAccess.close();
                    const groupOrder = yield onFindGroupOrder({
                        id: params.id,
                        secret: params.secret,
                        travellersDataForSplit: []
                    });
                    if (groupOrder) {
                        setGroupOrderParams({ id: params.id, secret: params.secret });
                        setGroupOrderResponse(groupOrder);
                        return true;
                    }
                    secureAccess.close();
                }
            }
            return false;
        }
    }), [
        findOrderMutation,
        setGroupOrderParams,
        setGroupOrderResponse,
        getIsAuthorizedUser,
        onHideOrderPreview,
        secureAccess.isNeedToOpenSecureAccessModal,
        secureAccess.setSubmitParams,
        secureAccess.open,
        secureAccess.close
    ]);
    const onFindGroupOrder = useCallback((params) => __awaiter(this, void 0, void 0, function* () {
        try {
            const { data, errors } = yield findGroupOrderMutation({
                variables: { params }
            });
            if (secureAccess.isNeedToOpenSecureAccessModal(errors)) {
                secureAccess.setSubmitParams({
                    params: {
                        id: params.id,
                        secret: params.secret,
                        travellersDataForSplit: params.travellersDataForSplit
                    }
                });
                secureAccess.open();
            }
            if (data === null || data === void 0 ? void 0 : data.FindGroupOrder) {
                return data.FindGroupOrder;
            }
            return null;
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }), [
        findGroupOrderMutation,
        secureAccess.isNeedToOpenSecureAccessModal,
        secureAccess.setSubmitParams,
        secureAccess.open
    ]);
    const onAcceptGroupOrder = useCallback((travellersDataForSplit) => onFindGroupOrder({
        id: groupOrderParams.id,
        secret: groupOrderParams.secret,
        travellersDataForSplit
    }), [onFindGroupOrder, groupOrderParams]);
    const onSubmitSecureAccess = useCallback((secret2fa) => __awaiter(this, void 0, void 0, function* () {
        if (secureAccess.isFindGroupOrderVariablesGuard(secureAccess.submitParams)) {
            yield onFindGroupOrder({
                id: secureAccess.submitParams.params.id,
                secret: secureAccess.submitParams.params.secret,
                travellersDataForSplit: secureAccess.submitParams.params.travellersDataForSplit
                // secret2fa
            });
        }
        else {
            yield onFindOrder({
                id: secureAccess.submitParams.params.id,
                secret: secureAccess.submitParams.params.secret,
                secret2fa
            });
        }
        secureAccess.setShowError(true);
    }), [onFindOrder, onFindGroupOrder, secureAccess]);
    const onResendSecureAccess = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (secureAccess.isFindGroupOrderVariablesGuard(secureAccess.submitParams)) {
            yield onFindGroupOrder({
                id: secureAccess.submitParams.params.id,
                secret: secureAccess.submitParams.params.secret,
                travellersDataForSplit: secureAccess.submitParams.params.travellersDataForSplit
            });
        }
        else {
            yield onFindOrder({
                id: secureAccess.submitParams.params.id,
                secret: secureAccess.submitParams.params.secret
            });
        }
    }), [onFindOrder, onFindGroupOrder, secureAccess]);
    return {
        // data
        orderPreview,
        secureAccess,
        groupOrderParams,
        groupOrderResponse,
        isLoading: findOrderLoading || findGroupOrderLoading,
        isAuthorized: !!(userProfile === null || userProfile === void 0 ? void 0 : userProfile.CurrentUser),
        // methods
        setGroupOrderParams,
        setGroupOrderResponse,
        onFindOrder,
        onFindGroupOrder,
        onAcceptGroupOrder,
        onSubmitSecureAccess,
        onResendSecureAccess,
        onHideOrderPreview
    };
}
