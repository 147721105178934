import * as React from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BaggageCard from '../../BaggageCard';
import { Overweight as OverweightIcon } from '../../../Icons';
import { useTheme } from '../../../theme';
import { getOverweightMinPrice } from '../../../Checkout/store/order/selectors';
import { BaggageType } from '@websky/graphql';
import UpgradeBaggageDialog from '../../UpgradeBaggageDialog/components/UpgradeBaggageDialog';
import { useBaggageToggleable } from '../../hooks';
import { useSelectedServicesBySegments } from '../../UpgradeBaggageDialog/hooks';
import HeaderEquipmentItem from '../../UpgradeBaggageDialog/components/BaggageDialogHeader/HeaderEquipmentItem/HeaderEquipmentItem';
import { BaggageContext } from '../../context';
import { initI18n } from '../../../utils';
import BaggageDirection from '../../BaggageList/components/BaggageDirection/BaggageDirection';
initI18n('Aircraft');
initI18n('BaggageService');
const OverweightBaggage = ({ className, passengers, passengerLabels, segments, services, selectedServices, isLoading, disabled, dynamicBaggagePricing, analyticsMeta }) => {
    var _a;
    const { OverweightBaggage: css } = useTheme('OverweightBaggage');
    const { t } = useTranslation('AdditionalBaggage');
    const { isOpen, open, close } = useBaggageToggleable(services);
    const isReadOnly = (_a = useContext(BaggageContext)) === null || _a === void 0 ? void 0 : _a.isReadOnly;
    const overweightMinPrice = useSelector(getOverweightMinPrice);
    const { selectedItems, hasSelected, totalPrice } = useSelectedServicesBySegments(services, segments, selectedServices);
    return (React.createElement(React.Fragment, null,
        React.createElement(BaggageCard, { className: className, classNameIcon: css.icon, icon: OverweightIcon, title: t('Overweight'), description: t('Need to add another 10-30 kg to your total checked baggage?'), fromPrice: overweightMinPrice, totalPrice: totalPrice, hasSelected: hasSelected, onEdit: open, onAdd: open, readOnly: isReadOnly }, hasSelected && (React.createElement("div", { className: css.flights }, selectedItems.map((selectedService, index) => (React.createElement("div", { className: css.flight, key: index },
            React.createElement(HeaderEquipmentItem, { className: css.flight_icon, icon: OverweightIcon, isActive: true }),
            React.createElement("div", { className: css.flight_count },
                "x",
                selectedService.count),
            React.createElement("span", { className: css.flight_info },
                selectedService.service.value
                    ? `${t('Aircraft:Up to')} ${selectedService.service.value.amount} ${t(`BaggageService:${selectedService.service.value.measurement}`)}`
                    : '',
                ",",
                ' ',
                React.createElement(BaggageDirection, { className: css.flight_direction, directions: selectedService.directions, allSegments: selectedService.allSegments })))))))),
        React.createElement(UpgradeBaggageDialog, { open: isOpen, onClose: close, passengers: passengers, passengerLabels: passengerLabels, segments: segments, includedBaggage: [], upgradeBaggage: services, selectedServices: selectedServices, headerProps: {
                header: t('Overweight'),
                description: ''
            }, disabled: isLoading || disabled, baggageType: BaggageType.BaggageExcess, dynamicBaggagePricing: dynamicBaggagePricing, isLoading: isLoading, analyticsMeta: analyticsMeta })));
};
export default OverweightBaggage;
