import * as React from 'react';
import { useCallback, useContext, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useTheme } from '../../../theme';
import { BaseButton, Money } from '../../../index';
import SeatMapDisclaimer from '../SeatMapDisclaimer/SeatMapDisclaimer';
import Passenger from './Passenger/Passenger';
import Controls from '../SeatMapService/Controls/Controls';
import { SeatsRecommendContext } from '../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import Notification from '../../../Notification';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { TABLET_MIDDLE_WIDTH } from '../../../utils';
import { Currency } from '../../../enums';
import { getSeatPrice } from '../../utils';
import Slider from './Slider/Slider';
export const filledPersonIcon = (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.666 9.33335C18.666 11.9117 16.5777 14 13.9993 14C11.421 14 9.33268 11.9117 9.33268 9.33335C9.33268 6.75502 11.421 4.66669 13.9993 4.66669C16.5777 4.66669 18.666 6.75502 18.666 9.33335ZM4.66602 19.8334C4.66602 16.73 10.8843 15.1667 13.9993 15.1667C17.1143 15.1667 23.3327 16.73 23.3327 19.8334V23.3334H4.66602V19.8334Z", fill: "currentColor" })));
export const recommend = (React.createElement("svg", { width: "21", height: "20", viewBox: "0 0 21 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.9999 6.66683C18.9166 6.66683 19.6666 7.41683 19.6666 8.3335L19.6583 8.40016L19.6666 8.4085V10.0002C19.6666 10.2168 19.6249 10.4168 19.5499 10.6085L17.0333 16.4835C16.7833 17.0835 16.1916 17.5002 15.4999 17.5002H7.99992C7.08325 17.5002 6.33325 16.7502 6.33325 15.8335V7.50016C6.33325 7.04183 6.51658 6.62516 6.82492 6.32516L12.3083 0.833496L13.1916 1.7085C13.4166 1.9335 13.5583 2.25016 13.5583 2.59183L13.5333 2.8585L12.7416 6.66683H17.9999ZM4.66658 17.5002H1.33325V7.50016H4.66658V17.5002Z", fill: "currentColor" })));
const Passengers = props => {
    const { PassengersStyles: theme, ComponentStyles: seatMapTheme } = useTheme('SeatMap');
    const { t, i18n } = useTranslation('SeatMap');
    const segmentsCount = props.segments.length;
    const segmentSeats = useMemo(() => {
        var _a, _b, _c, _d;
        return (_d = (_c = (_b = (_a = props.segments[props.segmentIndex]) === null || _a === void 0 ? void 0 : _a.decks) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.rows) === null || _d === void 0 ? void 0 : _d.reduce((acc, row) => [...acc, ...row.seats], []);
    }, [props.segments, props.segmentIndex]);
    const afterChange = useCallback((currentSlide) => props.onChange(props.passengers[currentSlide].id), [
        props.passengers,
        props.onChange
    ]);
    const sliderSettings = {
        className: theme.slider,
        slidesToShow: 1.15,
        infinite: false,
        arrows: false,
        dots: false,
        afterChange
    };
    const seatRecommendContext = useContext(SeatsRecommendContext);
    const isTablet = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    const isDesktopRecommendationOpen = !isTablet && seatRecommendContext.isOpen; // only for desktop
    const hasRecommendSeats = (seatRecommendContext === null || seatRecommendContext === void 0 ? void 0 : seatRecommendContext.rowsMap) &&
        Object.keys(seatRecommendContext === null || seatRecommendContext === void 0 ? void 0 : seatRecommendContext.rowsMap).length > 0 &&
        seatRecommendContext.availableSeatsCount > 0;
    const hasRemovableSeats = props.passengers.some(passenger => { var _a; return (_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.some(selectedSeat => !(selectedSeat === null || selectedSeat === void 0 ? void 0 : selectedSeat.isConfirmed) && selectedSeat.segmentId === props.segmentId); });
    const renderPassengersForRecommend = () => {
        var _a;
        const rowsMap = seatRecommendContext.rowsMap;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.recommend__wrapper },
                React.createElement("div", { className: theme.recommend__label },
                    recommend,
                    t('Recommend')),
                props.passengers.length > 1 && React.createElement("div", { className: theme.together }, t('Fly together!')),
                React.createElement("div", null, props.passengers.map(passenger => {
                    var _a, _b;
                    const selectedSeat = (_b = (_a = rowsMap[props.segmentId]) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b[passenger.id];
                    if (!selectedSeat) {
                        return null;
                    }
                    return (React.createElement(Passenger, { passenger: passenger, key: passenger.id, isActive: passenger.id === props.passengerId, seats: [selectedSeat.seat], seatsPrice: selectedSeat.seat.price, segmentsCount: segmentsCount, onClick: props.onChange, onClear: props.onCancel, recommend: true, isNewOrder: props.isNewOrder }));
                })),
                React.createElement("div", { className: theme.recommend__price },
                    t('Total'),
                    ": ",
                    React.createElement(Money, { money: (_a = rowsMap[props.segmentId]) === null || _a === void 0 ? void 0 : _a.price }))),
            React.createElement("div", { className: theme.passengers__buttons },
                React.createElement(BaseButton, { variant: "smallSecondary", onClick: seatRecommendContext.onDisagree, classes: { root: cn(theme.passengers__button, theme.passengers__button_clear) } }, t('Choose myself')),
                React.createElement(BaseButton, { variant: "smallPrimary", classes: {
                        root: cn(theme.passengers__button)
                    }, onClick: seatRecommendContext.onConfirm }, t('Confirm')))));
    };
    const renderPassengersList = () => {
        return props.passengers.map((passenger, index) => {
            var _a, _b, _c, _d;
            const selectedSeatsPrice = {
                amount: 0,
                currency: Currency.RUB
            };
            const selectedSeats = ((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.filter(seat => seat.segmentId === props.segmentId)) || [];
            for (const seat of selectedSeats) {
                const actualSeat = ((_b = seat.seatServices) === null || _b === void 0 ? void 0 : _b.length) ? seat : segmentSeats.find(s => s.number === seat.number);
                if ((_c = actualSeat === null || actualSeat === void 0 ? void 0 : actualSeat.seatServices) === null || _c === void 0 ? void 0 : _c.length) {
                    const seatServicesPrice = getSeatPrice(actualSeat);
                    seat.price = seatServicesPrice;
                    selectedSeatsPrice.amount += seatServicesPrice.amount;
                    selectedSeatsPrice.currency = seatServicesPrice.currency;
                }
            }
            return (React.createElement(Passenger, { passenger: passenger, key: passenger.id, isActive: passenger.id === props.passengerId, isActiveDoubleSeat: (_d = props.passengersIdsWithExtraSeats) === null || _d === void 0 ? void 0 : _d.includes(passenger.id), seats: selectedSeats, seatsPrice: selectedSeatsPrice, segmentsCount: segmentsCount, onClick: props.onChange, onClear: props.onCancel, isNewOrder: props.isNewOrder, toggleExtraSeat: props.toggleExtraSeat }));
        });
    };
    const renderPassengers = () => {
        var _a;
        if (props.hidePassengers) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            renderPassengersList(),
            props.renderDoubleSeats && (React.createElement("div", { className: theme.passengers__doubleSeat }, props.renderDoubleSeats())), (_a = props.renderFooterBefore) === null || _a === void 0 ? void 0 :
            _a.call(props),
            React.createElement("div", { className: theme.passengers__footer },
                seatRecommendContext.isEnabled && hasRecommendSeats && !props.hasSelectedExtraSeats && (React.createElement("div", { className: theme.recommend, onClick: seatRecommendContext.onOpen },
                    React.createElement("div", { className: theme.recommend__icon }, recommend),
                    t('Show recommended seats'))),
                React.createElement(Controls, { segmentId: props.segmentIndex, segmentsCount: segmentsCount, onClear: props.onClear, onProceed: props.onProceed, clearEnable: hasRemovableSeats, onSelectNextFlight: props.onSelectNextFlight }),
                props.hasSeatsWithAnimals && (React.createElement("div", { className: theme.notifications },
                    React.createElement(Notification, { className: theme.notification, status: InfoMessageTypeEnum.Promo, type: "context", infoMessage: {
                            text: t('The passenger can select a suitable seat marked for passengers with pets on the map. The airline has the right to transfer a passenger with an animal.'),
                            type: InfoMessageTypeEnum.Promo,
                            code: 'pet-disclaimer'
                        } }))),
                !props.hideDisclaimer && (React.createElement(SeatMapDisclaimer, null, i18n.exists('SeatMap:Disclaimer') ? (React.createElement("span", { className: theme.disclaimer, dangerouslySetInnerHTML: { __html: t('SeatMap:Disclaimer') } })) : (React.createElement(React.Fragment, null,
                    React.createElement("p", null, t('Pay attention!')),
                    React.createElement("p", null, t('The airline reserves the right to replace the aircraft.')),
                    React.createElement("p", null, t('In the event of an aircraft replacement, passengers who have paid for the “seat selection” service will be provided with seats on the plane that are similar in price and comfort, while maintaining the following parameters')),
                    React.createElement("p", null, t('- Cost of services;')),
                    React.createElement("p", null, t('- Location of the place relative to the window / aisle;')),
                    React.createElement("p", null,
                        t('- Joint seating, if one was originally selected;'),
                        " "),
                    React.createElement("p", null, t('- Placement in the front row of the cabin, if one was originally selected.')))))))));
    };
    return (React.createElement("div", { id: "passengers", className: cn(theme.passengers, {
            [theme.passengers_recommend]: seatRecommendContext.isOpen,
            [seatMapTheme.passengers_dialog]: props.isDialog
        }) }, isDesktopRecommendationOpen ? (renderPassengersForRecommend()) : (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: TABLET_MIDDLE_WIDTH + 1 }, renderPassengers()),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
            React.createElement(Slider, { passengers: props.passengers, segmentId: props.segmentId, passengerId: props.passengerId, settings: sliderSettings }, renderPassengersList()))))));
};
export default Passengers;
