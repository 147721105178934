import { TravellerDocumentFields } from '../../../types';
import { TravellerFieldEnum } from '@websky/graphql';
import { CisCountries } from '../../../consts';
export const generateCountriesMap = (traveller, primaryCountryCodes) => {
    const map = new Map();
    const countries = traveller === null || traveller === void 0 ? void 0 : traveller.values.find(field => field.name === TravellerFieldEnum.Nationality.toLowerCase()).validationRules[0].options;
    const filteredCISCountries = countries === null || countries === void 0 ? void 0 : countries.filter(country => CisCountries.includes(country.value));
    if (primaryCountryCodes === null || primaryCountryCodes === void 0 ? void 0 : primaryCountryCodes.length) {
        const primaryCountries = countries === null || countries === void 0 ? void 0 : countries.filter(country => primaryCountryCodes.includes(country.value)).reverse();
        primaryCountries === null || primaryCountries === void 0 ? void 0 : primaryCountries.forEach((country, index) => map.set(country.label, index));
    }
    const position = (primaryCountryCodes === null || primaryCountryCodes === void 0 ? void 0 : primaryCountryCodes.length) || 0;
    filteredCISCountries === null || filteredCISCountries === void 0 ? void 0 : filteredCISCountries.forEach((country, index) => {
        if (!map.has(country.label)) {
            map.set(country.label, position + index);
        }
    });
    return map;
};
export const getCitizenships = (traveller, primaryCountryCodes) => {
    var _a;
    const cisCountriesMap = generateCountriesMap(traveller, primaryCountryCodes);
    const sortCountries = ({ label: a }, { label: b }) => {
        if (cisCountriesMap.has(a) && !cisCountriesMap.has(b)) {
            return -1;
        }
        else if (cisCountriesMap.has(a) && cisCountriesMap.has(b)) {
            return cisCountriesMap.get(a) - cisCountriesMap.get(b);
        }
        return 0;
    };
    return (_a = traveller === null || traveller === void 0 ? void 0 : traveller.values.find(field => field.name === TravellerFieldEnum.Nationality.toLowerCase()).validationRules[0].options) === null || _a === void 0 ? void 0 : _a.sort(sortCountries);
};
export const getDocumentTypes = (citizenship, validators) => {
    if (!validators) {
        return [];
    }
    const docType = validators.values.find(value => value.name === TravellerDocumentFields.DocType);
    const documentsForCitizenship = docType === null || docType === void 0 ? void 0 : docType.validationRules.find(rule => {
        var _a;
        return (_a = rule.with) === null || _a === void 0 ? void 0 : _a.find(item => item.name === TravellerDocumentFields.Citizenship && item.value === citizenship);
    });
    if (documentsForCitizenship) {
        return documentsForCitizenship.options.map(item => item.value);
    }
    else {
        return docType === null || docType === void 0 ? void 0 : docType.validationRules.find(rule => !rule.with).options.map(item => item.value);
    }
};
export const getAvailabilityDocumentExpireDate = (documentType, validators) => {
    var _a, _b;
    const docExpireValidations = (_b = (_a = validators === null || validators === void 0 ? void 0 : validators.values) === null || _a === void 0 ? void 0 : _a.find(value => value.name === TravellerDocumentFields.DocExpiryDate)) === null || _b === void 0 ? void 0 : _b.validationRules, docTypeRule = docExpireValidations === null || docExpireValidations === void 0 ? void 0 : docExpireValidations.find(validation => { var _a; return (_a = validation.with) === null || _a === void 0 ? void 0 : _a.find(item => item.name === TravellerDocumentFields.DocType && item.value === documentType); });
    return !(docTypeRule === null || docTypeRule === void 0 ? void 0 : docTypeRule.required);
};
