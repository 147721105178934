import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Money from '../../Money';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import { useRenderers } from '../../renderProps';
import { BaseButton } from '../../index';
import { useMemo } from 'react';
initI18n('CheckoutAdditionalService');
const CheckoutAdditionalService = props => {
    var _a, _b, _c, _d, _e, _f;
    const theme = useTheme('CheckoutAdditionalService').CheckoutAdditionalService;
    const { t } = useTranslation('CheckoutAdditionalService');
    const { renderCheckoutAdditionalServiceHeader } = useRenderers();
    const description = useMemo(() => {
        if (React.isValidElement(props.description)) {
            return props.description;
        }
        else {
            return React.createElement("div", { dangerouslySetInnerHTML: { __html: props.description } });
        }
    }, [props.description, props.isSelected]);
    return (React.createElement("div", { className: cn(theme.service, props.className, { [theme.service_active]: props.isSelected }), style: props.style, "data-insurance-code": props.insuranceCode, "data-rfisc": props.dataRfisc }, (_a = renderCheckoutAdditionalServiceHeader === null || renderCheckoutAdditionalServiceHeader === void 0 ? void 0 : renderCheckoutAdditionalServiceHeader({
        header: props.header,
        icon: props.icon,
        priceFrom: props.priceFrom,
        priceTotal: props.priceTotal,
        isSelected: props.isSelected,
        isLoading: props.isLoading
    })) !== null && _a !== void 0 ? _a : (React.createElement(React.Fragment, null,
        props.isExclusive && !props.isSelected && (React.createElement("span", { className: cn(theme.exclusivePlate, props.exclusivePlateClassName) }, t('Exclusive'))),
        React.createElement("div", { className: cn(theme.header, props.headerClassName) },
            React.createElement("div", { className: cn(theme.header__text, props.headerTextClassName) },
                props.isSelected && props.icon && React.createElement("div", { className: theme.icon }, props.icon),
                props.header),
            React.createElement("div", { className: cn(theme.header__price, props.headerPriceClassName) }, props.isSelected ? (props.priceTotal ? (React.createElement(Money, { money: props.priceTotal })) : null) : props.priceFrom ? (React.createElement(React.Fragment, null,
                !props.hideFrom && ((_b = props.priceFrom) === null || _b === void 0 ? void 0 : _b.amount) !== 0 && t('from'),
                ((_c = props.priceFrom) === null || _c === void 0 ? void 0 : _c.amount) === 0 ? (props.showFromZeroIfZeroPrice ? (React.createElement("span", null,
                    t('from'),
                    React.createElement(Money, { money: props.priceFrom }))) : (React.createElement(React.Fragment, null,
                    "\u00A0",
                    t('SeatMap:Free')))) : (React.createElement(Money, { money: props.priceFrom })))) : null)))),
        React.createElement("div", { className: theme.main },
            React.createElement("div", { className: cn(theme.content, props.descriptionClassName) }, props.isSelected ? props.children : description), (_e = (_d = props.renderControls) === null || _d === void 0 ? void 0 : _d.call(props, {
            buttons: props.buttons,
            priceFrom: props.priceFrom,
            priceTotal: props.priceTotal,
            addButtonText: props.addButtonText,
            showAddButton: props.showAddButton,
            isSelected: props.isSelected,
            isLoading: props.isLoading,
            addClick: props.addClick
        })) !== null && _e !== void 0 ? _e : (React.createElement("div", { className: cn(theme.buttons, props.buttonsClassName) },
            props.isSelected && ((_f = props.buttons) === null || _f === void 0 ? void 0 : _f.length) > 0 && (React.createElement(React.Fragment, null, props.buttons.map(button => {
                var _a;
                return (React.createElement(BaseButton, { key: button.text, className: button.className, isLoading: button.isLoading, onClick: () => button.action(), variant: (_a = button.variant) !== null && _a !== void 0 ? _a : 'text' }, button.text));
            }))),
            !props.isSelected && props.showAddButton !== false && (React.createElement(BaseButton, { isLoading: props.isLoading, variant: "optionAction", className: theme.add, onClick: props.addClick }, props.addButtonText ? t(props.addButtonText) : t('Add')))))),
        props.endAdornment && props.endAdornment));
};
export default CheckoutAdditionalService;
