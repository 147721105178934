import * as React from 'react';
import Money from '../../../Money';
import { useTheme } from '../../../theme';
import { Currency } from '../../../enums';
import { useTranslation } from 'react-i18next';
import { TravellerFieldEnum } from '@websky/graphql';
import { getUserValue } from '../../../utils';
const CommonContent = ({ passengers, services, getServiceName, flight }) => {
    const theme = useTheme('RefundSummary').CommonContent;
    const { t } = useTranslation('Exchange');
    const getDefaultServiceName = (service, productId, passengerId, count) => {
        const traveller = passengers.find(passenger => passenger.id === passengerId);
        const selectedService = traveller.services.gdsServices.services.find(service => service.products.some(product => product.id === productId));
        const segments = selectedService.segmentIds.map(segmentId => {
            let segment;
            if (Array.isArray(flight)) {
                // checkin segments array
                segment = flight.find(segment => segment.id === segmentId);
            }
            else {
                segment = flight.segments.find(segment => segment.segment.id === segmentId).segment;
            }
            return `${segment.departure.airport.iata} - ${segment.arrival.airport.iata}`;
        });
        return `${segments.join(',')}, ${count} x ${service.name}`;
    };
    const passengersServices = React.useMemo(() => {
        const passengersServices = [];
        Object.keys(services).forEach(passengerId => {
            const passenger = passengers.find(passenger => passenger.id === passengerId), firstName = getUserValue(passenger, TravellerFieldEnum.FirstName), lastName = getUserValue(passenger, TravellerFieldEnum.LastName);
            const passengerServices = [];
            Object.keys(services[passengerId]).forEach(emd => {
                Object.keys(services[passengerId][emd].services).forEach(serviceId => {
                    var _a, _b, _c, _d;
                    const service = services[passengerId][emd].services[serviceId];
                    passengerServices.push({
                        serviceId,
                        name: getServiceName
                            ? getServiceName(service.service, (_b = (_a = service.products[0]) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.id, passengerId, service.products.length)
                            : getDefaultServiceName(service.service, (_d = (_c = service.products[0]) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.id, passengerId, service.products.length),
                        count: service.products.length,
                        totalRefund: service.sumToRefund
                    });
                });
            });
            passengersServices.push({
                passengerId,
                passengerName: `${lastName} ${firstName}`.toLowerCase(),
                services: passengerServices
            });
        });
        return passengersServices;
    }, [passengers, services]);
    const totalSumToRefund = React.useMemo(() => {
        const total = {
            amount: 0,
            currency: Currency.RUB
        };
        passengersServices.forEach(passengerService => {
            passengerService.services.forEach(service => {
                total.amount += service.totalRefund.amount;
                total.currency = service.totalRefund.currency;
            });
        });
        return total;
    }, [passengersServices]);
    return (React.createElement("div", { className: theme.content },
        React.createElement("div", { className: theme.services }, passengersServices.map(passengerServices => {
            return (React.createElement("div", { className: theme.passenger, key: passengerServices.passengerId },
                React.createElement("div", { className: theme.passengerName }, passengerServices.passengerName),
                React.createElement("div", null, passengerServices.services.map(service => {
                    return (React.createElement("div", { className: theme.service, key: service.serviceId },
                        React.createElement("div", { className: theme.name }, service.name),
                        React.createElement("div", null,
                            React.createElement(Money, { moneyClassName: theme.price, money: service.totalRefund }))));
                }))));
        })),
        React.createElement("div", { className: theme.totalRefund },
            t('Refund'),
            ": ",
            React.createElement(Money, { money: totalSumToRefund }))));
};
export default CommonContent;
