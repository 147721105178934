import * as React from 'react';
import { BaseButton } from '../../../../BaseComponents';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import FullscreenPopup from './FullscreenPopup/FullscreenPopup';
import cn from 'classnames';
const PopupWrapper = props => {
    const { t } = useTranslation('Passenger');
    const theme = useTheme('PassengerForm').PopupWrapper;
    if (props.inline) {
        return React.createElement(React.Fragment, null, props.children);
    }
    return (React.createElement(FullscreenPopup, { open: props.open },
        React.createElement("div", { className: theme.fields }, props.children),
        React.createElement("div", { className: theme.controls },
            React.createElement(BaseButton, { variant: "smallSecondary", onClick: props.onClose, className: theme.cancel }, t('Cancel')),
            React.createElement(BaseButton, { variant: "smallPrimary", onClick: props.onClose, disabled: !props.correct, className: cn(theme.confirm, { [theme.disabled]: !props.correct }) }, t('Confirm')))));
};
export default PopupWrapper;
