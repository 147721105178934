import { SET_LOCATION, SET_MULTI_CITY, SET_SEGMENTS, SWAP_LOCATIONS, SWAP_MULTI_LOCATIONS } from './actions';
import { ADD_SEGMENT, CLEAR_DATES, REMOVE_SEGMENT, SET_DATE, SET_DATE_MULTI_CITY, TOGGLE_MULTI_CITY } from './actions';
export const MIN_SEGMENTS_NUMBER = 1;
export const MAX_SEGMENTS_NUMBER = 6;
export const defaultState = {
    simpleRouteSegment: {
        departure: null,
        arrival: null,
        date: null,
        dateBack: null
    },
    multiCitySegments: [],
    isMultiCity: false
};
export const segmentsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case TOGGLE_MULTI_CITY:
            const multiCitySegments = [];
            if (!state.isMultiCity && !state.multiCitySegments.length) {
                multiCitySegments.push(Object.assign(Object.assign({}, state.simpleRouteSegment), { dateBack: null }));
            }
            return Object.assign(Object.assign({}, state), { isMultiCity: !state.isMultiCity, multiCitySegments: [...multiCitySegments] });
        case SET_MULTI_CITY:
            const multiCitySegmentsArray = state.multiCitySegments;
            const value = action.payload;
            if (value && !state.multiCitySegments.length) {
                multiCitySegmentsArray.push(Object.assign(Object.assign({}, state.simpleRouteSegment), { dateBack: null }));
            }
            return Object.assign(Object.assign({}, state), { isMultiCity: value, multiCitySegments: [...multiCitySegmentsArray] });
        case ADD_SEGMENT:
            const numOfSegments = state.multiCitySegments.length;
            const newMultiCitySegments = [];
            if (numOfSegments < MAX_SEGMENTS_NUMBER) {
                // Create a new segment object and use the airport of arrival from the previous segment
                // as the departure airport of the new segment.
                newMultiCitySegments.push({
                    departure: state.multiCitySegments[numOfSegments - 1]
                        ? state.multiCitySegments[numOfSegments - 1].arrival
                        : null,
                    arrival: null,
                    date: null,
                    dateBack: null
                });
            }
            return Object.assign(Object.assign({}, state), { multiCitySegments: [...state.multiCitySegments, ...newMultiCitySegments] });
        case REMOVE_SEGMENT:
            if (state.multiCitySegments.length > MIN_SEGMENTS_NUMBER) {
                const previousMultiCitySegments = [...state.multiCitySegments];
                previousMultiCitySegments.splice(action.payload, 1);
                return Object.assign(Object.assign({}, state), { multiCitySegments: [...previousMultiCitySegments] });
            }
            return Object.assign({}, state);
        case SET_DATE:
            if (action.payload.isBackDate) {
                return Object.assign(Object.assign({}, state), { simpleRouteSegment: Object.assign(Object.assign({}, state.simpleRouteSegment), { dateBack: action.payload.date }) });
            }
            else {
                return Object.assign(Object.assign({}, state), { simpleRouteSegment: Object.assign(Object.assign({}, state.simpleRouteSegment), { date: action.payload.date }) });
            }
        case SET_DATE_MULTI_CITY:
            const oldMultiCitySegments = [...state.multiCitySegments];
            const currentMultiCitySegment = oldMultiCitySegments[action.payload.segmentIndex];
            oldMultiCitySegments[action.payload.segmentIndex] = Object.assign(Object.assign({}, currentMultiCitySegment), { date: action.payload.date });
            return Object.assign(Object.assign({}, state), { multiCitySegments: oldMultiCitySegments });
        case CLEAR_DATES:
            const newDateState = {
                date: state.simpleRouteSegment.date,
                dateBack: state.simpleRouteSegment.dateBack
            };
            if (state.simpleRouteSegment.dateBack) {
                newDateState.dateBack = null;
            }
            else if (state.simpleRouteSegment.date) {
                newDateState.date = null;
            }
            return Object.assign(Object.assign({}, state), { simpleRouteSegment: Object.assign(Object.assign({}, state.simpleRouteSegment), newDateState) });
        case SET_LOCATION:
            // Is simple route
            if (typeof action.payload.segmentIndex === 'undefined') {
                return Object.assign(Object.assign({}, state), { simpleRouteSegment: Object.assign(Object.assign({}, state.simpleRouteSegment), { [action.payload.type]: action.payload.location }) });
            }
            else {
                const currentMultiCitySegment = Object.assign(Object.assign({}, state.multiCitySegments[action.payload.segmentIndex]), { [action.payload.type]: Object.assign({}, action.payload.location) });
                const oldMultiCitySegments = [...state.multiCitySegments];
                oldMultiCitySegments[action.payload.segmentIndex] = Object.assign({}, currentMultiCitySegment);
                return Object.assign(Object.assign({}, state), { multiCitySegments: [...oldMultiCitySegments] });
            }
        case SWAP_LOCATIONS:
            return Object.assign(Object.assign({}, state), { simpleRouteSegment: Object.assign(Object.assign({}, state.simpleRouteSegment), { arrival: state.simpleRouteSegment.departure ? Object.assign({}, state.simpleRouteSegment.departure) : null, departure: state.simpleRouteSegment.arrival ? Object.assign({}, state.simpleRouteSegment.arrival) : null }) });
        case SWAP_MULTI_LOCATIONS:
            return Object.assign(Object.assign({}, state), { multiCitySegments: state.multiCitySegments.map((segment, index) => {
                    if (index === action.payload) {
                        return Object.assign(Object.assign({}, segment), { departure: segment.arrival ? Object.assign({}, segment.arrival) : null, arrival: segment.departure ? Object.assign({}, segment.departure) : null });
                    }
                    return segment;
                }) });
        case SET_SEGMENTS:
            return action.payload;
        default:
            return state;
    }
};
