import * as React from 'react';
import { Flight } from '../../Icons';
import { API_DATE_FORMAT, format, removeDotsFromDate } from '../../utils';
import ServiceSummary from '../../ServiceSummary';
import ServiceSummaryOption from '../../ServiceSummary/components/ServiceSummaryOption';
import { parse } from 'date-fns';
import { useContext } from 'react';
import { CartContext } from '../Cart';
import { useTranslation } from 'react-i18next';
import ServiceSummaryDivider from '../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import { CheckoutSteps } from '../../MobileStepbar/types';
const Flights = ({ flight, renderService, hideFlightPrice }) => {
    var _a;
    const cartContext = useContext(CartContext);
    const { t } = useTranslation('Cart');
    const groupOptions = React.useMemo(() => {
        return flight.segmentGroups.map(segment => {
            var _a;
            const firstSegment = segment.segments[0], lastSegment = segment.segments[segment.segments.length - 1];
            const departureDate = parse(firstSegment.departure.date, API_DATE_FORMAT, new Date());
            return {
                header: `${firstSegment.departure.airport.city.name} — ${lastSegment.arrival.airport.city.name}`,
                headerIcon: Flight,
                items: [
                    {
                        id: 'date',
                        name: `${removeDotsFromDate(format(departureDate, 'dd MMM, EEEEEE'))} • ${firstSegment.departure.time} - ${lastSegment.arrival.time}`
                    },
                    {
                        id: 'fare',
                        name: (_a = segment.fareFamily) === null || _a === void 0 ? void 0 : _a.title,
                        price: !hideFlightPrice ? segment.price : undefined
                    }
                ]
            };
        });
    }, [flight]);
    const group = {
        header: t('Flights'),
        icon: Flight,
        totalPrice: !hideFlightPrice ? (_a = cartContext.totalPrices) === null || _a === void 0 ? void 0 : _a.flights : undefined
    };
    const content = (React.createElement(React.Fragment, null,
        React.createElement(ServiceSummaryDivider, null, groupOptions.map(groupOption => (React.createElement(ServiceSummaryOption, { groupOption: groupOption, key: groupOption.header }))))));
    if (!groupOptions.length) {
        return null;
    }
    if (renderService) {
        return renderService(CheckoutSteps.Flights, group, content);
    }
    return React.createElement(ServiceSummary, { group: group }, content);
};
export default Flights;
