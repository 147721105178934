import { put, takeEvery } from 'redux-saga/effects';
import { ADD_RECENT_SEARCH, setRecentSearches } from './actions';
import { get, set } from '../../../cache';
import { RECENT_SEARCHES_KEY } from '../../../cache';
function* worker({ payload }) {
    let searches = get(RECENT_SEARCHES_KEY, []);
    searches = searches.filter(location => location.iata !== payload.iata);
    searches.unshift(payload);
    searches = searches.slice(0, 3);
    set(RECENT_SEARCHES_KEY, searches);
    yield put(setRecentSearches(searches));
}
export default function* recentSearchesSagas() {
    yield takeEvery(ADD_RECENT_SEARCH, worker);
}
