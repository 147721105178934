import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../../../theme';
import { REMOVABLE_STATUSES } from '../utils';
import { getTravellerName } from '../../../../../../../utils';
const Services = ({ order, unconfirmedServices }) => {
    const { t } = useTranslation('Checkin');
    const theme = useTheme('Checkin').UnconfirmedServicesModal;
    const getPassengerName = (passengerId) => {
        return getTravellerName(order.travellers.find(traveller => traveller.id === passengerId));
    };
    const getSegmentIataCodes = (segmentId) => {
        var _a, _b, _c, _d;
        const segment = order.segments.find(segment => segment.id === segmentId);
        const departure = (_b = (_a = segment === null || segment === void 0 ? void 0 : segment.departure) === null || _a === void 0 ? void 0 : _a.airport) === null || _b === void 0 ? void 0 : _b.iata;
        const arrival = (_d = (_c = segment === null || segment === void 0 ? void 0 : segment.arrival) === null || _c === void 0 ? void 0 : _c.airport) === null || _d === void 0 ? void 0 : _d.iata;
        return `${departure} — ${arrival}`;
    };
    return (React.createElement(React.Fragment, null, Array.from(unconfirmedServices.entries()).map(([status, services]) => (React.createElement("div", { key: status, className: cn(theme.services, { [theme.status_accent]: REMOVABLE_STATUSES.includes(status) }) },
        React.createElement("div", { className: theme.status }, t(`${status} services`)),
        Array.from(services.entries()).map(([passengerId, segmentServices]) => (React.createElement("div", { key: passengerId, className: theme.passenger },
            React.createElement("div", { className: theme.passenger__name },
                getPassengerName(passengerId),
                ":"),
            React.createElement("div", { className: theme.passenger__segment }, Array.from(segmentServices.entries()).map(([segmentId, segmentServices]) => {
                const services = segmentServices
                    .map(service => service.count ? `${service.count}x ${service.name}` : service.name)
                    .join(', ');
                return (React.createElement("div", { key: segmentId },
                    getSegmentIataCodes(segmentId),
                    ", ",
                    services));
            }))))))))));
};
export default Services;
