import * as React from 'react';
import { forwardRef, memo } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import LocationAutocompleteComponent from './LocationAutocomplete';
import { useGraphQLClient } from '../../graphql-client';
const LocationAutocomplete = memo(forwardRef((props, ref) => {
    const client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(LocationAutocompleteComponent, Object.assign({ ref: ref }, props))));
}), (prevProps, nextProps) => {
    for (const prop in nextProps) {
        // Dirty hacks.
        if (nextProps.hasOwnProperty(prop) && prop !== 'inputRenderer') {
            if (nextProps[prop] !== prevProps[prop]) {
                return false;
            }
        }
    }
    return true;
});
export default LocationAutocomplete;
