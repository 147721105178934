import * as React from 'react';
import { useContext } from 'react';
import { OrderCardContext } from '../OrderCard';
import CommonItem from '../CommonItem/CommonItem';
import { Watch } from '../../../Icons';
import WaitPaymentText from '../OrderStatus/OrderStatusText/WaitPaymentText/WaitPaymentText';
import { OrderStatus } from '@websky/graphql';
import { useTheme } from '../../../theme';
import cn from 'classnames';
const PaymentStatus = props => {
    const { order, type } = useContext(OrderCardContext);
    const theme = useTheme('OrderCard').PaymentStatus;
    return (order.status === OrderStatus.Booked && (React.createElement(CommonItem, { className: cn(theme.root, {
            [theme.account]: type === 'account'
        }), iconClassName: theme.icon, payloadClassName: theme.payload, icon: Watch, text: React.createElement(WaitPaymentText, null) })));
};
export default PaymentStatus;
