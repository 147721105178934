import { ADD_PASSENGER_IDS, REMOVE_PASSENGER_IDS, SET_PASSENGER_IDS } from './actions';
export const passengersReducer = (state = [], action) => {
    if (action.type === ADD_PASSENGER_IDS) {
        return [...state, ...action.payload];
    }
    if (action.type === REMOVE_PASSENGER_IDS) {
        return state.filter(id => action.payload.indexOf(id) === -1);
    }
    if (action.type === SET_PASSENGER_IDS) {
        return action.payload;
    }
    return state;
};
