import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ModeContext, useConfig } from '../../../context';
import { Mode } from '../../../types';
import { getOrder } from '../../../Checkout/store/order/selectors';
import { getCheckinOrder } from '../../../Modules/Checkin/store/order/selectors';
import { useInsuranceState } from '../../store';
import * as React from 'react';
import { scrollTo } from '../../../utils';
import WebskyAnalyticsApollo from '../../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { WxEvents } from '../../../DataLayerAnalytics/types';
import { analytics_addInsurance, analytics_removeInsurance } from '../../../Insurance/utils';
import { useImportantInsurancesTouched } from '../../hooks';
export const useInsurancePage = ({ availablePrograms, onTotalInsurancePriceChange, onSaveOrderServicesParamsChange, onServicesChanged }) => {
    const insurancePriorities = useConfig().global.insurance.insurancePriorities;
    const mode = useContext(ModeContext);
    const order = mode === Mode.Booking ? useSelector(getOrder) : useSelector(getCheckinOrder);
    React.useEffect(() => {
        var _a, _b;
        scrollTo(0);
        if ((_b = (_a = orderWithAvailableInsurances.additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b.length) {
            WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.InsurancesList, {
                servicesIds: orderWithAvailableInsurances.additionalServices.insurances.insurancePrograms.map(insurance => insurance.code)
            });
        }
    }, []);
    const orderWithAvailableInsurances = useMemo(() => {
        var _a, _b, _c;
        if (!(availablePrograms === null || availablePrograms === void 0 ? void 0 : availablePrograms.length)) {
            return order;
        }
        const selectedInsurances = (_c = (_b = (_a = order.additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.selectedInsurances) === null || _b === void 0 ? void 0 : _b.filter(insurance => availablePrograms.some(availableInsurance => availableInsurance.code === insurance.insuranceProgram.code))) !== null && _c !== void 0 ? _c : [];
        return Object.assign(Object.assign({}, order), { additionalServices: Object.assign(Object.assign({}, order.additionalServices), { insurances: Object.assign(Object.assign({}, order.additionalServices.insurances), { insurancePrograms: availablePrograms, selectedInsurances }) }) });
    }, [order, availablePrograms]);
    const { state, initialStateChanged, dispatch, selectedProgramsCodes, insurancesWithExcludedMainPrograms, mainPrograms, availableInsurances, selectedInsurances, saveOrderServicesParams, totalPrice } = useInsuranceState(orderWithAvailableInsurances);
    const importantInsurancesTouched = useImportantInsurancesTouched();
    const doNotRenderForAllPassengersText = useMemo(() => {
        return order.travellers.length === 1;
    }, [order.travellers]);
    const prioritizedInsurances = useMemo(() => insurancePriorities.length > 0
        ? insurancesWithExcludedMainPrograms.sort((first, second) => {
            if (insurancePriorities.indexOf(first.code) === -1) {
                return 1;
            }
            if (insurancePriorities.indexOf(second.code) === -1) {
                return -1;
            }
            return insurancePriorities.indexOf(first.code) - insurancePriorities.indexOf(second.code);
        })
        : insurancesWithExcludedMainPrograms, [insurancePriorities, insurancesWithExcludedMainPrograms]);
    const toggle = (code) => {
        const program = state.availableInsurances[code];
        const isSelected = Boolean(state.selectedInsurances[code]);
        if (isSelected) {
            analytics_removeInsurance(program, order.travellers);
        }
        else {
            analytics_addInsurance(program, order.travellers);
        }
        dispatch({
            type: isSelected ? 'removeInsurance' : 'selectInsurance',
            payload: program
        });
    };
    const select = (code) => {
        const program = state.availableInsurances[code];
        importantInsurancesTouched.onTouchInsurance(code);
        analytics_addInsurance(program, order.travellers);
        dispatch({
            type: 'selectInsurance',
            payload: program
        });
    };
    const remove = (code) => {
        const program = state.availableInsurances[code];
        importantInsurancesTouched.onTouchInsurance(code);
        if (importantInsurancesTouched.getIsTouched(code)) {
            analytics_removeInsurance(program, order.travellers);
        }
        dispatch({
            type: 'removeInsurance',
            payload: program
        });
    };
    const getProductState = useCallback((code) => {
        if (state.confirmedInsurances[code]) {
            return 'confirmed';
        }
        else if (state.selectedInsurances[code]) {
            return 'selected';
        }
        else {
            return 'available';
        }
    }, [state]);
    const getPolicyUrl = useCallback((code) => {
        var _a;
        return (_a = state.confirmedInsurances[code]) === null || _a === void 0 ? void 0 : _a.policyUrl;
    }, [state]);
    // child-parent
    useEffect(() => {
        onServicesChanged === null || onServicesChanged === void 0 ? void 0 : onServicesChanged(initialStateChanged);
    }, [initialStateChanged, onServicesChanged]);
    useEffect(() => {
        onSaveOrderServicesParamsChange === null || onSaveOrderServicesParamsChange === void 0 ? void 0 : onSaveOrderServicesParamsChange(saveOrderServicesParams);
    }, [onSaveOrderServicesParamsChange, saveOrderServicesParams]);
    useEffect(() => {
        onTotalInsurancePriceChange === null || onTotalInsurancePriceChange === void 0 ? void 0 : onTotalInsurancePriceChange(totalPrice);
    }, [onTotalInsurancePriceChange, totalPrice]);
    return {
        selectedProgramsCodes,
        doNotRenderForAllPassengersText,
        insurancesWithExcludedMainPrograms: prioritizedInsurances,
        availableInsurances,
        selectedInsurances,
        getProductState,
        getPolicyUrl,
        toggle,
        select,
        remove,
        mainPrograms,
        isLoading: state.isLoading
    };
};
