import { __rest } from "tslib";
import cn from 'classnames';
import * as React from 'react';
import { useContext, useRef } from 'react';
import MediaQuery from 'react-responsive';
import { Field, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import LocationAutocomplete from '../../../../../../LocationAutocomplete';
import { LocationType, WebskyEventType } from '../../../../../../types';
import { emitEvent, MOBILE_MIN_WIDTH, useIsMobile } from '../../../../../../utils';
import Value from './Value/Value';
import Tooltip from '../../../../../../Tooltip/Tooltip';
import { ConfigContext } from '../../../../../../context';
import { useTheme } from '../../../../../../theme';
import { OverrideComponent } from '../../../../../../renderProps';
import { LocationIcon } from '../../../../../../Icons';
export const IGNORE_CLICKOUTSIDE_CLASS = 'ignore_clickoutside';
const switcherIcon = (React.createElement("svg", { width: "11", height: "15", viewBox: "0 0 11 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.99 15L0 11L3.99 7V10H11V12H3.99V15ZM0 3H7.01V0L11 4L7.01 8L7.01 5H0L0 3Z", fill: "currentColor" })));
const verticalSwitcherIcon = (React.createElement("svg", { width: "14", height: "18", viewBox: "0 0 14 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 3.99L4 0L8 3.99H5V11H3V3.99H0ZM11 7V14.01H14L10 18L6 14.01H9V7H11Z", fill: "white" })));
let lastVisibleError = '';
export default React.memo(({ type, className = '', setLocation, locations, onSwap, recentSearches, segmentId, withMultiCity, renderInput }) => {
    const { Segment, Location: css } = useTheme('SearchForm');
    const { t } = useTranslation('SearchForm');
    const alternative = useContext(ConfigContext).SearchForm.mobileFormType !== 'common';
    const location = locations[type];
    const additionalProps = {
        outsideClickIgnoreClass: IGNORE_CLICKOUTSIDE_CLASS
    };
    const isMobile = useIsMobile();
    const autocompleteRef = useRef();
    const inputRef = React.useRef();
    const validate = (value, allValues, meta) => {
        const data = meta.data;
        if (!data || !data[type]) {
            return t('Please select {{type}} airport', {
                type: t(type)
            });
        }
        if (data && data.arrival && data.departure && data.arrival.iata === data.departure.iata) {
            return t('Please select different airports');
        }
    };
    const onChange = (value) => {
        setLocation(value, type);
    };
    const onNearestAirportHandler = (locations) => {
        if (type === LocationType.Departure && segmentId === 0) {
            emitEvent(autocompleteRef === null || autocompleteRef === void 0 ? void 0 : autocompleteRef.current, {
                type: WebskyEventType.NearestAirportsLoaded,
                data: locations.map(location => ({
                    iata: location.iata,
                    name: location.name
                }))
            });
        }
    };
    const getRenderInput = (fieldState) => (isOpen, isLoading, inputProps) => {
        const { onChange, onFocus, onBlur } = inputProps, restProps = __rest(inputProps, ["onChange", "onFocus", "onBlur"]);
        const finalProps = restProps;
        finalProps.name = fieldState.input.name;
        if (inputProps.onBlur) {
            finalProps.onBlur = (event) => {
                onBlur(event);
                fieldState.input.onBlur(event);
            };
        }
        if (inputProps.onFocus) {
            finalProps.onFocus = (event) => {
                onFocus(event);
                fieldState.input.onFocus(event);
            };
        }
        if (inputProps.onChange) {
            finalProps.onChange = (event) => {
                onChange(event);
                fieldState.input.onChange(event);
            };
        }
        if (renderInput) {
            return renderInput({ inputProps: finalProps, isOpen, isLoading });
        }
        const autocompleteFocus = (event) => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
            inputProps.onFocus(event);
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
                React.createElement("input", Object.assign({}, finalProps, { className: cn(IGNORE_CLICKOUTSIDE_CLASS, Segment.input, className, css.input, {
                        [css.input_withoutPlaceholder]: !!location,
                        [css.input_departure]: type === LocationType.Departure,
                        [css.input_arrival]: type === LocationType.Arrival
                    }), ref: inputRef, placeholder: t(type === LocationType.Departure ? 'From' : 'To'), autoComplete: "one-time-code" }))),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { onClick: inputProps.onFocus, className: cn(Segment.input, className, css.input, {
                        [css.input_withoutPlaceholder]: !!location,
                        [css.input_departure]: type === LocationType.Departure,
                        [css.input_arrival]: type === LocationType.Arrival
                    }) },
                    !location && React.createElement("div", { className: css.input_icon }, LocationIcon),
                    React.createElement("span", { className: Segment.input_text }, t(type === LocationType.Departure ? 'From' : 'To')))),
            onSwap && (!isOpen || !isLoading) && !(isMobile && withMultiCity) && (React.createElement("button", { type: "button", className: cn(css.switcher, {
                    [css.switcher_highlighted]: isOpen,
                    [css.switcher_alternative]: alternative
                }), tabIndex: -1, onClick: onSwap },
                React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 }, switcherIcon),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, alternative ? switcherIcon : verticalSwitcherIcon))),
            React.createElement(OverrideComponent, { componentProps: {
                    className: Segment.location__value,
                    type,
                    isOpen,
                    isLoading,
                    location,
                    inputValue: inputProps.value,
                    withMultiCity,
                    onClick: autocompleteFocus
                }, component: {
                    SearchFormSegmentValue: Value
                } })));
    };
    return (React.createElement(FormSpy, { subscription: {
            submitFailed: true
        } }, formState => (React.createElement(Field, { name: `${type}_${segmentId}`, validate: validate, subscription: {
            data: true,
            error: true
        } }, fieldState => {
        if (location !== null || typeof fieldState.meta.data[type] !== 'undefined') {
            formState.form.mutators.setFieldData(fieldState.input.name, locations);
        }
        if (fieldState.meta.error) {
            lastVisibleError = fieldState.meta.error;
        }
        return (React.createElement(Tooltip, { title: lastVisibleError, open: !!fieldState.meta.error && formState.submitFailed },
            React.createElement(LocationAutocomplete, { type: type, locations: locations, className: cn(css.autocomplete, {
                    [css.autocomplete_alternative]: alternative
                }), onSelect: onChange, recentSearches: recentSearches, inputRenderer: getRenderInput(fieldState), onClickOutsideProps: additionalProps, ref: autocompleteRef, onNearestAirports: onNearestAirportHandler })));
    }))));
});
