import * as ContactsStyles from './components/Contacts.css';
import * as ResendStyles from './components/Resend/Resend.css';
import * as LoginBlock from './components/LoginBlock/LoginBlock.css';
import * as ListTransitionStyles from './components/Resend/ListTransition/ListTransition.css';
export default {
    ContactsStyles,
    ResendStyles,
    ListTransitionStyles,
    LoginBlock
};
