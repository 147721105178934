import { useMemo, useReducer, useRef } from 'react';
import produce from 'immer';
import { insuranceInitialStateFactory, saveOrderServicesParamsFactory } from './utils';
import { getCurrency } from '../../cache';
const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'setInsuranceState':
            return payload;
        case 'setLoading': {
            return produce(state, draft => {
                draft.isLoading = payload;
                return draft;
            });
        }
        case 'selectInsurance':
            return produce(state, draft => {
                draft.selectedInsurances[payload.code] = {
                    passengerPrice: payload.price,
                    insuranceProgram: payload
                };
                return draft;
            });
        case 'removeInsurance': {
            return produce(state, draft => {
                draft.selectedInsurances[payload.code] = null;
                return draft;
            });
        }
        default:
            return state;
    }
};
export const useInsuranceState = (order) => {
    const initialSelectedPrograms = useRef([]);
    const [state, dispatch] = useReducer(reducer, order, order => {
        const initialState = insuranceInitialStateFactory(order);
        initialSelectedPrograms.current = Object.values(initialState.selectedInsurances)
            .filter(Boolean)
            .map(({ insuranceProgram: { code } }) => code);
        return initialState;
    });
    // selectors
    const availableInsurances = useMemo(() => Object.values(state.availableInsurances), [state]);
    const selectedInsurances = useMemo(() => Object.values(state.selectedInsurances).filter(Boolean), [state]);
    const availableProgramsCodes = useMemo(() => availableInsurances.map(({ code }) => code), [state]);
    const selectedProgramsCodes = useMemo(() => selectedInsurances.map(({ insuranceProgram: { code } }) => code), [
        state
    ]);
    const mainPrograms = useMemo(() => {
        const insurances = Object.values(state.availableInsurances);
        // if there is only one insurance, display it as the main one
        if (insurances.length === 1) {
            return insurances;
        }
        return Object.values(state.availableInsurances).filter(({ important }) => important);
    }, [state]);
    const insurancesWithExcludedMainPrograms = useMemo(() => availableInsurances.filter(({ code }) => !mainPrograms.some(program => program.code === code)), [state, mainPrograms]);
    const saveOrderServicesParams = useMemo(() => {
        const selectedInsuranceProgramsCodes = selectedInsurances.map(({ insuranceProgram: { code } }) => code);
        return saveOrderServicesParamsFactory(state.orderTravellers, selectedInsuranceProgramsCodes);
    }, [state, selectedInsurances]);
    const totalPrice = useMemo(() => {
        return selectedInsurances.reduce((totalPrice, current) => {
            return {
                amount: totalPrice.amount += current.insuranceProgram.totalPrice.amount,
                currency: current.insuranceProgram.totalPrice.currency
            };
        }, {
            amount: 0,
            currency: getCurrency()
        });
    }, [selectedInsurances]);
    const initialStateChanged = useMemo(() => {
        return JSON.stringify(initialSelectedPrograms.current) !== JSON.stringify(selectedProgramsCodes);
    }, [state]);
    return {
        state,
        availableInsurances,
        insurancesWithExcludedMainPrograms,
        selectedInsurances,
        availableProgramsCodes,
        selectedProgramsCodes,
        mainPrograms,
        saveOrderServicesParams,
        totalPrice,
        initialStateChanged,
        dispatch
    };
};
