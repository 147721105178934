import * as React from 'react';
import { useCallback, useMemo } from 'react';
import Slider from 'react-slick';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../theme';
import { useFareGroupsMap } from '../hooks';
import FareSelect from './FareSelect/FareSelect';
import { useDesktopFlightWithPopup } from '../../DesktopFlight/components/DesktopFlightPopup/hooks';
import Modal from '../../Modal';
import DesktopFlightModalContent from '../../DesktopFlight/components/DesktopFlightModalContent/DesktopFlightModalContent';
import SegmentInfo from './SegmentInfo/SegmentInfo';
import TotalDuration from '../../DesktopFlightRoute/components/TotalDuration/TotalDuration';
const FlightSelect = props => {
    var _a;
    const { renderRouteInfo, renderSlide: slideRenderer, renderSlider: sliderRenderer, renderFlightInfo, renderPlaneInfo } = props;
    const { DesktopFlightPopup: popupCss } = useTheme('DesktopFlight');
    const { FlightSelect: css } = useTheme('FlightSelect');
    // hook from <DesktopFlightWithPopup /> component which provide all needed data
    const { getOnFareSelect, flight, lastSelectedFare, businessFareGroups, economyFareGroups, milesFareGroups, openModal, isModalOpen, closeModal, upgradeOptions } = useDesktopFlightWithPopup(props);
    const lastSegment = flight.segments[flight.segments.length - 1].segment;
    const { fareGroupMap: fareGroupsMap, nonEmptyFlightClassesCount } = useFareGroupsMap(flight, props.selectedFares);
    const sliderSettings = useMemo(() => ({
        slidesToShow: nonEmptyFlightClassesCount,
        slidesToScroll: 2,
        adaptiveHeight: true
    }), []);
    const modalContentStyles = useMemo(() => ({
        DesktopFlight: {
            ModalContent: {
                fareGroup__button_right: css.modal__button_right
            }
        }
    }), []);
    const renderTotalDuration = useCallback(() => React.createElement(TotalDuration, { lastSegment: lastSegment, flight: flight, separator: "" }), [lastSegment, flight]);
    const renderInfo = renderRouteInfo !== null && renderRouteInfo !== void 0 ? renderRouteInfo : useCallback((segments) => {
        return segments.map((segment, key) => (React.createElement(SegmentInfo, { isSingleSegment: !!flight.segments.length, segment: segment, key: segment.segment.id, first: key === 0, last: key === flight.segments.length - 1, stops: key > 0 ? flight.segments[key - 1].segment.stops : [], singleFlight: false, totalDuration: key === flight.segments.length - 1 && renderTotalDuration(), nextDay: key >= 1 && flight.segments[key - 1].segment.departure.date !== segment.segment.arrival.date, renderFlightInfo: renderFlightInfo, multipleSegments: flight.segments.length > 1, renderPlaneInfo: renderPlaneInfo, stopsSeparator: "" })));
    }, [renderRouteInfo, renderFlightInfo, renderPlaneInfo, flight]);
    const renderSlide = slideRenderer !== null && slideRenderer !== void 0 ? slideRenderer : useCallback((fareFamilies, flightClass) => {
        return (React.createElement(FareSelect, { onOpenModal: openModal, key: flightClass, fares: fareFamilies, onSelect: getOnFareSelect }));
    }, [props.flight, props.selectedFares]);
    // https://github.com/palantir/tslint/issues/4364#issuecomment-455564451
    /* tslint:disable-next-line:no-inferred-empty-object-type */
    const renderSlider = useCallback(() => {
        const slides = [];
        fareGroupsMap.forEach((value, key) => {
            if (!value.length) {
                return;
            }
            slides.push(renderSlide(value, key));
        });
        if (sliderRenderer) {
            return sliderRenderer({
                flight,
                orderId: props.orderId,
                isSelected: props.isSelected,
                slides
            });
        }
        return (React.createElement("div", { className: css.slider__wrapper },
            React.createElement(Slider, Object.assign({}, sliderSettings), slides)));
    }, [props.flight, props.selectedFares, sliderRenderer, flight, props.orderId, props.isSelected]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(css.flight, props.className) },
            React.createElement("div", { className: cn(css.segments, props.segmentsClassName) }, renderInfo(flight.segments)),
            renderSlider()),
        React.createElement(ThemeProvider, { value: modalContentStyles },
            React.createElement(Modal, { isRounded: true, scroll: 'body', fullScreen: false, open: isModalOpen, onClose: closeModal, classes: { paper: popupCss.paper, scrollBody: popupCss.scrollBody }, closeButtonRenderer: () => null },
                React.createElement(DesktopFlightModalContent, { lastSelectedFare: (_a = props.flight.pricesForFareGroups.find(fareGroup => fareGroup.fareFamily && fareGroup.fareFamily.id === lastSelectedFare)) === null || _a === void 0 ? void 0 : _a.fareFamily, flight: flight, economyFares: economyFareGroups, businessFares: businessFareGroups, milesFares: milesFareGroups, getOnFareSelect: getOnFareSelect, upgradeOptions: upgradeOptions })))));
};
export default FlightSelect;
