import * as React from 'react';
import { Provider } from 'react-redux';
import { useGetPaxSectionsQuery } from '@websky/graphql';
import { createStore } from '../store';
import { setCommunicationCategory } from '../store/actions';
import { useCallback, useEffect } from 'react';
import { getUnreadTotal } from '../store/selectors';
import { useConfig } from '../../context';
const COMMUNICATION_UNREAD_TOTAL_EVENT = 'webskyCommunicationUnreadTotal';
const CommunicationUnreadTotalProvider = ({ children }) => {
    const store = createStore();
    const { Account: { showCommunications } } = useConfig();
    useGetPaxSectionsQuery({
        variables: { withLastMessage: true },
        skip: !showCommunications,
        onCompleted: data => {
            var _a;
            if ((_a = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationSections) === null || _a === void 0 ? void 0 : _a.sections) {
                store.dispatch(setCommunicationCategory(data.PaximizerCommunicationSections.sections));
                const state = store.getState();
                const communicationUnreadTotal = getUnreadTotal(state);
                onUnreadTotalEvent(communicationUnreadTotal);
            }
        }
    });
    const onUnreadTotalEvent = useCallback((communicationUnreadTotal) => {
        const event = new CustomEvent(COMMUNICATION_UNREAD_TOTAL_EVENT, {
            detail: { communicationUnreadTotal }
        });
        document.dispatchEvent(event);
    }, []);
    useEffect(() => {
        store.subscribe(() => {
            const state = store.getState();
            const communicationUnreadTotal = getUnreadTotal(state);
            onUnreadTotalEvent(communicationUnreadTotal);
        });
    }, [store]);
    return React.createElement(Provider, { store: store }, children);
};
export default CommunicationUnreadTotalProvider;
