import * as React from 'react';
import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Autocomplete, Sort } from '../../../../index';
import { TravellerFieldEnum } from '@websky/graphql';
import { ThemeProvider, useTheme } from '../../../../theme';
import { getAutocompleteLabel as initialGetAutocompleteLabel } from '../../../../utils';
import { filterProfileByBirthDate } from '../utils';
import { useRenderers } from '../../../../renderProps';
const LastName = memo(({ label, field, birthDateField, fieldState, onTravellerSelect, onChange, className, error, loadTravellers, onBlur, disabled, travellerProfiles, refetchTravellers, variant, autofocus, onSwap }) => {
    const { LastName: theme } = useTheme('Passenger');
    const { Input: inputCss } = useTheme('Input');
    const { getCustomAutocompletePassengerLabel } = useRenderers();
    const getAutocompleteLabel = getCustomAutocompletePassengerLabel || initialGetAutocompleteLabel;
    const isLastName = field.type === TravellerFieldEnum.LastName;
    const options = useMemo(() => {
        var _a;
        if ((_a = travellerProfiles === null || travellerProfiles === void 0 ? void 0 : travellerProfiles.TravellerProfiles) === null || _a === void 0 ? void 0 : _a.length) {
            return travellerProfiles.TravellerProfiles.filter(traveller => {
                const filterByBirthDate = filterProfileByBirthDate(traveller, birthDateField === null || birthDateField === void 0 ? void 0 : birthDateField.validations);
                const isFilledLastNameAndFirstName = traveller.values.some(value => (value.type === TravellerFieldEnum.LastName ||
                    value.type === TravellerFieldEnum.FirstName) &&
                    !value.value);
                return filterByBirthDate && !isFilledLastNameAndFirstName;
            });
        }
        return [];
    }, [travellerProfiles === null || travellerProfiles === void 0 ? void 0 : travellerProfiles.TravellerProfiles, birthDateField]);
    const onChangeHandler = useCallback((event) => {
        const value = event.target.value;
        if (loadTravellers) {
            // clearTimeout(debounceTimeout);
            // debounceTimeout = window.setTimeout(() => travellerProfiles.refetch({ search: value }), 500);
            refetchTravellers(value);
            if (onChange) {
                onChange(field.name, value);
            }
        }
        fieldState.input.onChange(value);
        onBlur && onBlur();
    }, [onChange, fieldState]);
    const autocompleteTheme = useMemo(() => ({
        Autocomplete: {
            Autocomplete: {
                disabled: inputCss.disabled,
                inner_input: inputCss.input
            }
        }
    }), []);
    return (React.createElement(ThemeProvider, { value: autocompleteTheme },
        React.createElement("div", { className: cn(theme.field, {
                [theme.field_swapRight]: onSwap && isLastName,
                [theme.field_swapLeft]: onSwap && !isLastName
            }) },
            React.createElement(Autocomplete, { name: field.name, label: label, variant: variant, autofocus: autofocus, inputValue: fieldState.input.value, onSelect: travellerId => {
                    onTravellerSelect(travellerProfiles.TravellerProfiles.find(traveller => traveller.id === travellerId));
                    if (onBlur) {
                        onBlur();
                    }
                }, onInputChange: onChangeHandler, className: className, error: error, options: options
                    ? options.map(traveller => ({
                        label: getAutocompleteLabel(traveller),
                        value: traveller.id
                    }))
                    : [], disabled: disabled }),
            onSwap && isLastName && (React.createElement("div", { className: theme.swap, onClick: onSwap }, Sort)))));
});
export default LastName;
