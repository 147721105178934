import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import Amenities from '../../../../Amenities';
import { useTranslation } from 'react-i18next';
import { WarningRounded } from '../../../../Icons';
import { useConfig } from '../../../../context';
import { useSeatAdditionalInfo } from '../hooks';
import SeatAdditionalInfo from '../SeatAdditionalInfo/SeatAdditionalInfo';
import { SeatComfort } from '@websky/graphql';
import SeatPrice from '../../SeatPrice/SeatPrice';
const SeatInfo = ({ seatService, hideImage, fullInfo, amenities, seat, segmentId, preselectedSeat, isFreeSeat, seatPrice, services, passengerId, dataColorNum }) => {
    var _a, _b;
    const theme = useTheme('SeatMap').SeatsInfoStyles;
    const { t } = useTranslation('SeatMap');
    const iataCode = useConfig().global.companyInfo.iataCode;
    const showSeatPriceNotServicePrice = ['DV', 'A9', 'RM'].includes(iataCode);
    const { restrictions, permissions, additional, withInfo } = useSeatAdditionalInfo(seat);
    const servicePrice = React.useMemo(() => {
        var _a, _b;
        const actualService = (_b = (_a = services === null || services === void 0 ? void 0 : services[segmentId]) === null || _a === void 0 ? void 0 : _a[passengerId]) === null || _b === void 0 ? void 0 : _b[seatService.rfisc];
        if (actualService && !showSeatPriceNotServicePrice) {
            return actualService.price;
        }
        return seatService === null || seatService === void 0 ? void 0 : seatService.price;
    }, [showSeatPriceNotServicePrice, services, segmentId, passengerId]);
    const renderFeatures = () => {
        var _a;
        if ((_a = seatService === null || seatService === void 0 ? void 0 : seatService.parameters) === null || _a === void 0 ? void 0 : _a.length) {
            return (React.createElement("div", { className: theme.features }, seatService.parameters.map((param, index) => {
                return (React.createElement("div", { className: theme.feature, key: index },
                    param.title,
                    ": ",
                    param.value));
            })));
        }
        return null;
    };
    let seatImage;
    if (!isFreeSeat && (seatService === null || seatService === void 0 ? void 0 : seatService.infoBySegments)) {
        seatImage = (_a = seatService.infoBySegments.find(item => item.segment.id === segmentId)) === null || _a === void 0 ? void 0 : _a.image;
    }
    const seatDescription = fullInfo ? renderFeatures() : (_b = seatService === null || seatService === void 0 ? void 0 : seatService.description) === null || _b === void 0 ? void 0 : _b.trim();
    return (React.createElement("div", { className: cn(theme.seat, {
            [theme.seat_economy]: (seatService === null || seatService === void 0 ? void 0 : seatService.comfort) === SeatComfort.Standart,
            [theme.seat_preferred]: (seatService === null || seatService === void 0 ? void 0 : seatService.comfort) === SeatComfort.Preferred,
            [theme.seat_business]: (seatService === null || seatService === void 0 ? void 0 : seatService.comfort) === SeatComfort.Comfort,
            [theme.seat_free]: isFreeSeat
        }), key: (seatService === null || seatService === void 0 ? void 0 : seatService.id) || (seat === null || seat === void 0 ? void 0 : seat.number) + segmentId, "data-rfisc": seatService === null || seatService === void 0 ? void 0 : seatService.rfisc, "data-color-num": dataColorNum },
        !isFreeSeat && (seatImage || (seatService === null || seatService === void 0 ? void 0 : seatService.serviceImage)) && !hideImage && (React.createElement("img", { className: theme.image, src: seatImage || (seatService === null || seatService === void 0 ? void 0 : seatService.serviceImage), alt: seatService === null || seatService === void 0 ? void 0 : seatService.name })),
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: cn(theme.title, { [theme.title_withDescription]: seatDescription }) },
                (preselectedSeat === null || preselectedSeat === void 0 ? void 0 : preselectedSeat.number) && React.createElement("span", null, preselectedSeat === null || preselectedSeat === void 0 ? void 0 : preselectedSeat.number),
                isFreeSeat ? (seat === null || seat === void 0 ? void 0 : seat.number) : (React.createElement(React.Fragment, null, seatService === null || seatService === void 0 ? void 0 :
                    seatService.name,
                    React.createElement("div", { className: theme.description }, seatDescription))))),
        React.createElement("div", { className: theme.seat__info },
            fullInfo && (amenities === null || amenities === void 0 ? void 0 : amenities.length) > 0 ? React.createElement(Amenities, { amenities: amenities }) : renderFeatures(),
            React.createElement("div", { className: theme.price__wrapper }, isFreeSeat ? (t('Free seat')) : (React.createElement(SeatPrice, { className: theme.seatPrice, moneyClassName: theme.price, money: seatPrice !== null && seatPrice !== void 0 ? seatPrice : servicePrice, priceFrom: !seatPrice })))),
        seat && withInfo && (React.createElement("div", { className: theme.note },
            React.createElement("div", { className: theme.note__header },
                WarningRounded,
                " ",
                t('Note')),
            React.createElement(SeatAdditionalInfo, { restrictions: restrictions, permissions: permissions, additional: additional })))));
};
export default SeatInfo;
