import { TravellerFieldEnum } from '@websky/graphql';
import { API_DATE_FORMAT, format, getUserValue } from '../../../../utils';
import { parse } from 'date-fns';
import i18n from 'i18next';
export const getPassengerName = (passenger) => {
    const firstName = getUserValue(passenger, TravellerFieldEnum.FirstName), lastName = getUserValue(passenger, TravellerFieldEnum.LastName);
    return `${lastName} ${firstName}`;
};
export const getSelectedFlightsInfo = (flights) => {
    const segmentsInfo = flights.map(flight => {
        var _a, _b;
        if ((_b = (_a = flight === null || flight === void 0 ? void 0 : flight.fare) === null || _a === void 0 ? void 0 : _a.flightInfo) === null || _b === void 0 ? void 0 : _b.segments) {
            return flight.fare.flightInfo.segments
                .map(segment => {
                var _a, _b;
                const flightDate = format(parse(segment.segment.departure.date, API_DATE_FORMAT, new Date()), 'dd.MM.yyyy');
                return i18n.t('Exchange:{{segmentId}}. {{depDate}}, {{airlineCode}}{{flightNumber}}. {{depIata}}({{depTime}}) -> {{arrIata}}({{arrTime}}). Тариф {{tariff}}', {
                    segmentId: parseInt(segment.segment.id) + 1,
                    depDate: flightDate,
                    airlineCode: segment.segment.marketingAirline.iata,
                    flightNumber: segment.segment.flightNumber,
                    depIata: (_b = (_a = segment.segment.departure) === null || _a === void 0 ? void 0 : _a.airport) === null || _b === void 0 ? void 0 : _b.iata,
                    depTime: segment.segment.departure.time,
                    arrIata: segment.segment.arrival.airport.iata,
                    arrTime: segment.segment.arrival.time,
                    tariff: flight.fare.fareName
                });
            })
                .join('\n');
        }
        return '';
    });
    return segmentsInfo.join('\n');
};
export const exchangeFlightsWithPriceToPayAdapter = (legs, prices, currentLeg) => {
    return legs.map(flight => (Object.assign(Object.assign({}, flight), { pricesForFareGroups: flight.pricesForFareGroups.map(pricesForFareGroup => {
            var _a;
            return (Object.assign(Object.assign({}, pricesForFareGroup), { prices: (_a = pricesForFareGroup.prices) === null || _a === void 0 ? void 0 : _a.map(farePrice => {
                    var _a;
                    return (Object.assign(Object.assign({}, farePrice), { price: currentLeg < 1
                            ? ((_a = prices.find(price => price.flightId === farePrice.flight.id)) === null || _a === void 0 ? void 0 : _a.priceToPay) || null
                            : null }));
                }) }));
        }) })));
};
export const isApolloErrorGuard = (error) => {
    return !!(error === null || error === void 0 ? void 0 : error.graphQLErrors);
};
