import { __rest } from "tslib";
import * as React from 'react';
import { Form, Field } from 'react-final-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import RefundPaper from '../RefundPaper/RefundPaper';
import PhoneInput from '../../../PhoneInput';
import { FormControlLabel } from '@material-ui/core';
import { useRequestFormValidation } from './hooks';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import Input from '../../../Input';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { getSelectedPassengers } from '../../store/passengers/selectors';
import InfoLinksLabel from '../../../InfoLinksLabel';
import { getAviaOrderTravellers, getCustomer } from '../../store/aviaOrder/selectors';
import { getTravellerInfo } from '../../store/aviaOrder/utils';
import { getOrder, getSelectedInsurancesByType } from '../../store/selectors';
import { InsuranceType } from '@websky/graphql';
import Note from '../Note/Note';
import { RefundSummary } from '../../../RefundSummary';
const RequestForm = props => {
    const { t } = useTranslation('Refund');
    const { RequestForm: css } = useTheme('Refund');
    const { validateEmail, validateAgreeButton, validateComments, validateName } = useRequestFormValidation();
    const order = useSelector(getOrder);
    const selectedPassengers = useSelector(getSelectedPassengers);
    const travellers = useSelector(getAviaOrderTravellers);
    const refundState = useSelector(state => state);
    const customer = useSelector(getCustomer);
    const selectedVskInsurances = useSelector(getSelectedInsurancesByType(InsuranceType.Vsk));
    const travellerInfo = React.useMemo(() => {
        return getTravellerInfo(customer, travellers, selectedPassengers.map(p => p.id));
    }, [customer, travellers, selectedPassengers]);
    return (React.createElement("div", { className: css.wrapper },
        props.renderPrice && props.renderPrice(),
        React.createElement("div", { className: css.notes }, props.renderNotes && props.renderNotes()),
        selectedVskInsurances.length > 0 && (React.createElement(RefundSummary, { className: css.selectedServices, order: order, insurancesRefund: selectedVskInsurances, notification: React.createElement(Note, { className: css.note, title: t('Note'), iconColor: 'gray', type: 'info', content: t('When the refund is performed, VSK insurance policies will be canceled') }) })),
        React.createElement(Form, { onSubmit: props.onSubmit }, ({ handleSubmit, valid, errors }) => (React.createElement(React.Fragment, null,
            React.createElement(RefundPaper, { className: cn(css.form, props.className) },
                React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(FormGroup, null,
                        React.createElement(FormLabel, { className: css.formLabel }, t('Create request')),
                        React.createElement(Field, { name: 'name', initialValue: travellerInfo.name, type: 'string', validate: validateName, render: ({ input, meta }) => (React.createElement(Input, { className: css.formName, absoluteHintPosition: true, TextFieldProps: Object.assign(Object.assign({}, input), { classes: { root: css.control }, error: !!(meta.touched && meta.error), helperText: meta.touched && t(meta.error) }), label: t('Your name') })) }),
                        React.createElement(Field, { name: 'email', initialValue: travellerInfo.email, type: 'string', validate: validateEmail, render: ({ input, meta }) => (React.createElement(Input, { className: css.formEmail, TextFieldProps: Object.assign(Object.assign({}, input), { classes: { root: css.control }, error: !!(meta.error && meta.touched), helperText: meta.touched && t(meta.error) }), label: t('Your email') })) }),
                        React.createElement(Field, { name: 'phone', type: 'string', initialValue: travellerInfo.phone, render: ({ input, meta }) => (React.createElement(PhoneInput, Object.assign({ className: cn(css.control, css.formTel) }, input, { textFieldsProps: {
                                    error: !!(meta.error && meta.touched),
                                    helperText: meta.touched && t(meta.error),
                                    label: t('Your phone'),
                                    fullWidth: true,
                                    onChange: (event) => {
                                        input.onChange(event);
                                    }
                                } }))) }),
                        React.createElement(Field, { name: 'comments', validate: validateComments, type: 'string', render: ({ input, meta }) => (React.createElement(Input, { className: css.formComment, TextFieldProps: Object.assign(Object.assign({}, input), { classes: { root: css.control }, error: !!(meta.error && meta.touched), helperText: meta.touched && t(meta.error) }), label: t('Your comments') })) })))),
            React.createElement(Field, { name: 'agree', type: 'checkbox', validate: validateAgreeButton, render: ({ input }) => {
                    const { type } = input, restInputProps = __rest(input, ["type"]);
                    return (React.createElement(FormControlLabel, { className: css.agree, control: React.createElement(Checkbox, Object.assign({ classes: { root: css.checkbox, checked: cn(css.checkbox, css.active) } }, restInputProps)), label: React.createElement(InfoLinksLabel, { order: refundState, type: 'refund' }) }));
                } }),
            props.renderButtons && props.renderButtons(valid, handleSubmit))))));
};
export default RequestForm;
