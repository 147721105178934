import { __awaiter } from "tslib";
import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import Gateway from '../Gateway';
import { useTheme } from '../../../../theme';
import { PaymentType } from '../../../types';
import Button from './Button/Button';
const googlePayIcon = require('../../../images/google-pay.svg');
const allowedCardNetworks = ['MASTERCARD', 'VISA'];
const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];
const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: allowedCardNetworks
    }
};
const googlePayBaseConfiguration = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [baseCardPaymentMethod]
};
const tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
        gateway: 'example',
        gatewayMerchantId: 'gatewayMerchantId'
    }
};
const cardPaymentMethod = {
    type: 'CARD',
    tokenizationSpecification: tokenizationSpecification,
    parameters: {
        allowedCardNetworks: allowedCardNetworks,
        allowedAuthMethods: allowedCardAuthMethods,
        billingAddressRequired: true,
        billingAddressParameters: {
            format: 'FULL',
            phoneNumberRequired: true
        }
    }
};
const merchantInfo = {
    merchantId: '01234567890123456789',
    merchantName: 'Example Merchant Name'
};
const GooglePay = memo(({ price, onClick, onSuccess, onFail, isPreOpened }) => {
    const { GooglePay: css } = useTheme('PaymentForm');
    const { t } = useTranslation('PaymentForm');
    const [button, setButton] = useState(null);
    const [googlePayClient, setGooglePayClient] = useState(null);
    const handleOnClick = useCallback(() => {
        onClick({
            type: PaymentType.Gpay,
            title: 'Google Pay',
            description: t('In the next step you will be taken to a Google Pay page.'),
            content: React.createElement(Button, { button: button })
        });
    }, [onClick, button]);
    // For some strange reason, Helment+script onLoad requires global function name.
    window['onGooglePayLoaded'] = () => {
        const googlePayClient = new google.payments.api.PaymentsClient({
            environment: 'TEST'
        });
        setGooglePayClient(googlePayClient);
    };
    const onPaymentClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const transactionInfo = {
            totalPriceStatus: 'FINAL',
            totalPrice: price.amount.toString(10),
            currencyCode: price.currency
        };
        const paymentDataRequest = Object.assign(Object.assign({}, googlePayBaseConfiguration), { allowedPaymentMethods: [cardPaymentMethod], transactionInfo: transactionInfo, merchantInfo: merchantInfo });
        try {
            const paymentData = yield googlePayClient.loadPaymentData(paymentDataRequest);
            if (onSuccess) {
                onSuccess();
            }
            console.log(paymentData);
        }
        catch (err) {
            console.error(err);
            if (onFail) {
                onFail();
            }
        }
    });
    const checkIfGooglePayAvailable = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield googlePayClient.isReadyToPay(googlePayBaseConfiguration);
            if (response.result) {
                const button = googlePayClient.createButton({
                    buttonColor: 'default',
                    buttonType: 'long',
                    onClick: onPaymentClick
                });
                setButton(button);
            }
        }
        catch (err) {
            console.error('Error determining readiness to use Google Pay: ', err);
        }
    });
    useEffect(() => {
        if (googlePayClient) {
            checkIfGooglePayAvailable();
        }
    }, [googlePayClient]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, { async: true },
            React.createElement("script", { src: "https://pay.google.com/gp/p/js/pay.js", onLoad: 'onGooglePayLoaded()' })),
        button && (React.createElement(Gateway, { onClick: handleOnClick, header: React.createElement("img", { src: googlePayIcon, className: css.logo, alt: "Google Pay" }), type: "Google Pay", paymentType: PaymentType.Gpay }))));
});
export default GooglePay;
