import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CartService } from '../../types';
import { Aeroexpress as AeroexpressIcon } from '../../../Icons';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import { getAeroexpressTripLabel } from '../../../CheckoutAdditionalService/utils';
const Aeroexpress = ({ services, onServiceDelete }) => {
    const { t } = useTranslation('Cart');
    const onDeleteHandler = (tripId) => {
        onServiceDelete(CartService.Aeroexpress, {
            tripId
        });
    };
    const items = React.useMemo(() => {
        var _a, _b, _c;
        if ((_c = (_b = (_a = services.transfer) === null || _a === void 0 ? void 0 : _a.aeroexpress) === null || _b === void 0 ? void 0 : _b.selectedTrips) === null || _c === void 0 ? void 0 : _c.length) {
            return services.transfer.aeroexpress.selectedTrips.map(trip => {
                const label = `${getAeroexpressTripLabel(trip)}, ${t(`Checkout:Class_${trip.class}`)}`;
                return {
                    id: trip.id,
                    name: label,
                    price: trip.price,
                    onDelete: () => onDeleteHandler(trip.id)
                };
            });
        }
        return [];
    }, [services]);
    if (!items.length) {
        return null;
    }
    return (React.createElement(ServiceSummaryDivider, null,
        React.createElement(ServiceSummaryOption, { groupOption: {
                header: t('Aeroexpress'),
                headerIcon: AeroexpressIcon,
                items
            } })));
};
export default Aeroexpress;
