import * as React from 'react';
import { useContext, useEffect } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import SwipableViews from 'react-swipeable-views';
import { TABLET_MIDDLE_WIDTH, TABLET_MIN_WIDTH } from '../../../../utils';
import cn from 'classnames';
import Airplane from '../../Airplane/Airplane';
import SeatMap from '../../SeatMap/SeatMap';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { BaseButton, SlideBottom } from '../../../../index';
import memes from '../../../../memes';
import { ModeContext } from '../../../../context';
import { Mode } from '../../../../types';
import Controls from '../Controls/Controls';
import { SeatsRecommendContext } from '../../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import { OverrideComponent } from '../../../../renderProps';
import { isPassengersHeightExceedViewport, isSegmentSeatMapTooWide } from './utils';
import InfoMessages from '../../../../InfoMessages';
import { InfoMessagePositionsEnum } from '@websky/graphql';
const SeatMapServiceContent = React.memo(props => {
    const currentSegment = props.segments[props.segmentIndex];
    const unavailableMessageRef = React.useRef();
    const isSeatMapAvailable = memes((segmentIndex) => props.segments[segmentIndex].decks.length > 0);
    const isMobile = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    const theme = useTheme('SeatMap').ComponentStyles;
    const { t } = useTranslation('SeatMap');
    const mode = useContext(ModeContext);
    const [seatsOpen, setSeatsOpen] = React.useState(false);
    const seatRecommendContext = useContext(SeatsRecommendContext);
    const canShowSeatMap = (segment) => {
        if (typeof segment !== 'undefined' && mode !== Mode.Checkin) {
            return !props.segments[segment].isSeatsAvailableInCheckinOnly;
        }
        return true;
    };
    const availableOnCheckinMessage = (React.createElement("div", { className: theme.unavailable, ref: unavailableMessageRef },
        currentSegment && (React.createElement("img", { alt: "company icon", className: theme.unavailable__img, src: currentSegment.segmentInfo.operatingAirline.icon })),
        React.createElement("p", { className: theme.unavailable__title },
            React.createElement("p", { className: theme.unavailable__header }, t('SeatMap:Less than 24 hours left before the flight')),
            t('SeatMap:You can select seats using the online registration service after issuing tickets')),
        props.segmentIndex + 1 < props.segments.length && (React.createElement(BaseButton, { variant: "optionAction", onClick: props.onSelectNextFlight, className: theme.unavailable__button }, t('SeatMap:Next flight')))));
    const unavailableSeatMapMessage = (React.createElement("div", { className: theme.unavailable, ref: unavailableMessageRef },
        currentSegment && (React.createElement("img", { alt: "company icon", className: theme.unavailable__img, src: currentSegment.segmentInfo.operatingAirline.icon })),
        React.createElement("p", { className: theme.unavailable__title }, t('SeatMap:Unfortunately, seat selection on this flight is not available.')),
        props.segmentIndex + 1 < props.segments.length && (React.createElement(BaseButton, { variant: "optionAction", onClick: props.onSelectNextFlight, className: theme.unavailable__button }, t('SeatMap:Next flight')))));
    useEffect(() => {
        var _a;
        if (!isSeatMapAvailable(props.segmentIndex)) {
            (_a = unavailableMessageRef === null || unavailableMessageRef === void 0 ? void 0 : unavailableMessageRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            });
        }
    });
    const fixPassengersOnScroll = () => {
        const element = document.getElementById('passengers');
        const passengersContainer = document.getElementById('passengers-container');
        if (element && passengersContainer) {
            const elementRect = element.getBoundingClientRect();
            const passengersRect = passengersContainer.getBoundingClientRect();
            if (elementRect.top < (isMobile ? 0 : 8) && !isPassengersHeightExceedViewport(elementRect)) {
                element.classList.add(theme.header_fixed);
            }
            else if (elementRect.top < passengersRect.top || isPassengersHeightExceedViewport(elementRect)) {
                element.classList.remove(theme.header_fixed);
            }
        }
    };
    useEffect(() => {
        var _a, _b;
        const element = document.getElementById('passengers');
        const scrollElement = (_b = (_a = props.dialogRef) === null || _a === void 0 ? void 0 : _a.current) !== null && _b !== void 0 ? _b : window;
        scrollElement.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        if (element) {
            element.classList.remove(theme.header_fixed);
            scrollElement.addEventListener('scroll', fixPassengersOnScroll);
        }
        return () => scrollElement.removeEventListener('scroll', fixPassengersOnScroll);
    }, [props.segmentIndex, isMobile]);
    const seatsRecommendContext = useContext(SeatsRecommendContext);
    const isActiveDoubleSeat = props.activeDoubleSeats && !seatRecommendContext.isOpen;
    const isSeatMapTooWide = isSegmentSeatMapTooWide(currentSegment);
    return (React.createElement(React.Fragment, null,
        canShowSeatMap(props.segmentIndex) && isSeatMapAvailable(props.segmentIndex) && (React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
            React.createElement("div", { id: "header", className: cn(theme.header_mobile, {
                    [theme.header_mobile_overlay]: seatsRecommendContext.isOpen
                }) },
                React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.seat, className: theme.infoMessage, itemHtmlClassName: theme.infoMessage__htmlMessage }),
                React.createElement(Airplane, { aircraft: currentSegment.segmentInfo.aircraft, routeTime: currentSegment.segmentInfo.duration, sunInfo: currentSegment.sunSide, onSeatsDetailsClick: () => setSeatsOpen(true), segment: currentSegment, isReturnFlight: props.isReturnFlight }),
                React.createElement("div", { id: "passengers-container", className: cn(theme.header_passengers, {
                        [theme.header_passengers_wide]: isSeatMapTooWide
                    }) }, props.renderPassengers())),
            React.createElement(Controls, { segmentId: props.segmentIndex, segmentsCount: props.segments.length, onProceed: props.onProceed, onSelectNextFlight: props.onSelectNextFlight, onBack: props.onBack }))),
        React.createElement(MediaQuery, { minWidth: TABLET_MIDDLE_WIDTH + 1 },
            React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.seat, className: theme.infoMessage, itemHtmlClassName: theme.infoMessage__htmlMessage })),
        React.createElement("div", { className: theme.map },
            React.createElement(MediaQuery, { minWidth: TABLET_MIDDLE_WIDTH + 1 }, canShowSeatMap(props.segmentIndex) && isSeatMapAvailable(props.segmentIndex) && (React.createElement("div", { className: cn(theme.helpers, { [theme.helpers_recommend]: seatsRecommendContext.isOpen }) },
                React.createElement("div", null,
                    React.createElement(Airplane, { aircraft: currentSegment.segmentInfo.aircraft, routeTime: currentSegment.segmentInfo.duration, sunInfo: currentSegment.sunSide, onSeatsDetailsClick: () => setSeatsOpen(true), className: theme.airplane, isReturnFlight: props.isReturnFlight, segment: currentSegment }),
                    React.createElement("div", { id: "passengers-container", className: theme.passengers_container }, props.renderPassengers())),
                React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
                    React.createElement("div", { className: theme.right }, React.createElement(React.Fragment, null,
                        props.renderSeatsInfo(),
                        React.createElement(OverrideComponent, { componentProps: { segmentIndex: props.segmentIndex }, component: { seatsInfoAdditionalSlot: () => null } }))))))),
            React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                React.createElement(SlideBottom, { isOpen: seatsOpen, onClose: () => setSeatsOpen(false), className: theme.seatsInfo, header: t('Seat details') },
                    React.createElement("div", { className: theme.seatsInfo_content },
                        React.createElement(React.Fragment, null, props.renderSeatsInfo()),
                        React.createElement(OverrideComponent, { componentProps: { segmentIndex: props.segmentIndex }, component: { seatsInfoAdditionalSlot: () => null } })))),
            props.swipeBetweenSegments ? (React.createElement(SwipableViews, { disabled: true, animateHeight: true, index: props.segmentIndex }, props.segments.map((segment, index) => (React.createElement("div", { key: segment.segmentInfo.id }, isSeatMapAvailable(index) && canShowSeatMap(index) ? (React.createElement(SeatMap, { allSeatServices: props.allSeatServices, services: props.services, seatMapRef: props.seatMapRef, isActive: props.segmentIndex === index, segment: segment, onSelect: props.onSelect, passengers: props.passengers, passengerId: props.passengerId, activeDoubleSeats: isActiveDoubleSeat, isNewServicePredicate: props.isNewServicePredicate, onClear: props.onClear })) : segment.isSeatsAvailableInCheckinOnly && mode !== Mode.Checkin ? (availableOnCheckinMessage) : (unavailableSeatMapMessage)))))) : isSeatMapAvailable(props.segmentIndex) && canShowSeatMap(props.segmentIndex) ? (React.createElement(SeatMap, { allSeatServices: props.allSeatServices, services: props.services, seatMapRef: props.seatMapRef, isActive: true, segment: props.segments[props.segmentIndex], onSelect: props.onSelect, passengers: props.passengers, passengerId: props.passengerId, activeDoubleSeats: isActiveDoubleSeat, isNewServicePredicate: props.isNewServicePredicate })) : props.segments[props.segmentIndex].isSeatsAvailableInCheckinOnly && mode !== Mode.Checkin ? (availableOnCheckinMessage) : (unavailableSeatMapMessage))));
});
export default SeatMapServiceContent;
