import { getServiceCategory, getTravellersTypes } from './utils';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { DataLayerItemsListName, ItemCategory } from './types';
import { getRelatedSegments } from '../Baggage/UpgradeBaggageDialog/utils';
class WebskyServicesAnalytics {
    static insurances(services, travellers) {
        return services === null || services === void 0 ? void 0 : services.map(service => ({
            category: ItemCategory.Insurance,
            name: service.name,
            price: service.totalPrice.amount,
            count: 1,
            forAllSegments: true,
            travellersTypes: getTravellersTypes(travellers)
        }));
    }
    static selectedInsurances(services, travellers) {
        const insurancesMap = new Map();
        services === null || services === void 0 ? void 0 : services.forEach(service => {
            insurancesMap.set(service.insuranceProgram.code, service);
        });
        const dataLayer = [];
        const travellersTypes = travellers ? getTravellersTypes(travellers) : [];
        insurancesMap.forEach((service, code) => {
            var _a, _b;
            dataLayer.push({
                category: ItemCategory.Insurance,
                name: (_a = service.insuranceProgram) === null || _a === void 0 ? void 0 : _a.name,
                price: (_b = service.insuranceProgram) === null || _b === void 0 ? void 0 : _b.totalPrice.amount,
                count: 1,
                forAllSegments: true,
                travellersTypes
            });
        });
        return dataLayer;
    }
    static aeroexpress(services, travellers) {
        try {
            return services.reduce((newServices, service) => {
                var _a;
                const segmentIds = (_a = service.segments) === null || _a === void 0 ? void 0 : _a.map(segment => segment.id);
                return [
                    ...newServices,
                    ...segmentIds.map(segmentId => ({
                        category: ItemCategory.Aeroexpress,
                        name: `${service.toStation.direction}-${service.class}`,
                        price: service.price.amount,
                        count: 1,
                        segmentId,
                        travellersTypes: getTravellersTypes(travellers)
                    }))
                ];
            }, []);
        }
        catch (e) {
            return [];
        }
    }
    static baggage(services, travellers) {
        return services.reduce((newServices, serivce) => {
            const segmentIds = [...(getRelatedSegments(serivce.segmentIds) || [])];
            return [
                ...newServices,
                ...segmentIds.map(segmentId => {
                    var _a;
                    return ({
                        category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Baggage, serivce.type),
                        name: `${serivce.name}${((_a = serivce.value) === null || _a === void 0 ? void 0 : _a.amount) ? `-${serivce.value.amount}${serivce.value.measurement}` : ''}`,
                        price: serivce.price.amount,
                        segmentId,
                        count: 1,
                        isRecommend: serivce.isRecommended,
                        travellersTypes: getTravellersTypes(travellers)
                    });
                })
            ];
        }, []);
    }
    static seat(service, segmentId, isRecommend, travellerType) {
        return {
            name: service.number,
            price: service.price.amount,
            category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Seat),
            segmentId,
            isRecommend,
            count: 1,
            travellersTypes: travellerType ? [travellerType] : []
        };
    }
    static seats(seatMap, travellers) {
        const items = [];
        try {
            if (seatMap.availableSeatsCount > 0) {
                seatMap.decks.forEach(deck => deck.rows.forEach(row => {
                    row.seats.forEach(seat => {
                        var _a;
                        if (seat.isAvailable) {
                            items.push({
                                category: ItemCategory.Seat,
                                name: seat.number,
                                price: (_a = seat.price) === null || _a === void 0 ? void 0 : _a.amount,
                                segmentId: seatMap.segmentsInfo.id,
                                isRecommend: false,
                                count: 1,
                                travellersTypes: getTravellersTypes(travellers)
                            });
                        }
                    });
                }));
            }
        }
        catch (e) { }
        return items;
    }
    static vip(services, travllers) {
        var _a;
        const items = [];
        const category = getServiceCategory((_a = services === null || services === void 0 ? void 0 : services[0]) === null || _a === void 0 ? void 0 : _a.type);
        services.forEach(service => {
            service.allowedPassengers.forEach(passenger => {
                var _a, _b;
                items.push({
                    category,
                    name: (+passenger[0] + 1).toString(),
                    price: service.price.amount,
                    segmentId: (_b = (_a = service.allowedSegments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b[0],
                    count: 1,
                    travellersTypes: getTravellersTypes(travllers)
                });
            });
        });
        return items;
    }
    static selectVip(service, passengerId, segmentId) {
        return {
            name: (+passengerId + 1).toString(),
            price: service.price.amount,
            segmentId,
            count: 1,
            category: getServiceCategory(service.type)
        };
    }
    static flight(services, travellers) {
        return services.map(flight => {
            var _a, _b;
            return ({
                category: ItemCategory.Flight,
                name: (_b = (_a = flight.fareFamily) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : 'NA',
                price: flight.price.amount,
                segmentIds: flight.segments.map(segment => segment.id),
                travellersTypes: getTravellersTypes(travellers),
                count: 1
            });
        });
    }
    static convertGdsServiceToDataLayer(service, selectedService, travellerType) {
        var _a;
        return {
            category: getServiceCategory(service.type, service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage
                ? service.baggageType
                : null),
            name: service.name,
            price: service.price.amount,
            segmentId: (_a = selectedService.segmentIds) === null || _a === void 0 ? void 0 : _a[0],
            count: selectedService.count,
            travellersTypes: [travellerType]
        };
    }
    static getPriceFreezeServices(order) {
        var _a, _b, _c, _d, _e;
        const priceFreezeService = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(service => service.isPriceFreeze);
        if (priceFreezeService &&
            order.travellers.some(traveller => {
                var _a, _b, _c;
                return (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(selectedService => selectedService.serviceId === priceFreezeService.id);
            })) {
            return [
                {
                    name: 'hold',
                    category: ItemCategory.PriceFreeze,
                    forAllSegments: true,
                    price: (((_d = priceFreezeService.price) === null || _d === void 0 ? void 0 : _d.amount) || 0) *
                        ((_e = order.travellers.filter(traveller => {
                            var _a, _b, _c;
                            return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => service.serviceId === priceFreezeService.id);
                        })) === null || _e === void 0 ? void 0 : _e.length),
                    count: 1
                }
            ];
        }
        return [];
    }
    static getVipItemListName(service) {
        if ((service === null || service === void 0 ? void 0 : service.type) === OrderAdditionalServiceGdsServiceServiceType.BusinessLounge) {
            return DataLayerItemsListName.Business;
        }
        return null;
    }
    static getCheckoutSelectedServices(order, skipConfirmedServices = true) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        try {
            if (order.isPriceFreezeProductSelected) {
                const priceFreezeService = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(service => service.isPriceFreeze);
                if (order.travellers.some(traveller => {
                    var _a, _b, _c;
                    return (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => service.serviceId === priceFreezeService.id && !service.confirmedCount);
                })) {
                    return WebskyServicesAnalytics.getPriceFreezeServices(order);
                }
            }
            return [
                ...(((_d = order.priceToPay.airticket) === null || _d === void 0 ? void 0 : _d.amount) > 0
                    ? this.flight((_e = order.flight) === null || _e === void 0 ? void 0 : _e.segmentGroups, order.travellers)
                    : []),
                ...this.getAllSelectedServicesInOrder((_f = order.additionalServices) === null || _f === void 0 ? void 0 : _f.gdsServices, order.travellers, (_g = order.additionalServices) === null || _g === void 0 ? void 0 : _g.insurances, (_h = order.additionalServices) === null || _h === void 0 ? void 0 : _h.transfer, skipConfirmedServices)
            ];
        }
        catch (error) {
            console.log(error);
            return [];
        }
    }
    static getCheckinSelectedServices(order) {
        var _a, _b, _c;
        return [
            ...this.getAllSelectedServicesInOrder((_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices, order.travellers.map(traveller => (Object.assign(Object.assign({}, traveller), { needOthsSsr: false, fares: [] }))), (_b = order.additionalServices) === null || _b === void 0 ? void 0 : _b.insurances, (_c = order.additionalServices) === null || _c === void 0 ? void 0 : _c.transfer)
        ];
    }
    static getAllSelectedServicesInOrder(gdsServices, passengers, insurances, transfer, skipConfirmedServices = true) {
        var _a, _b, _c;
        const itemList = [];
        const servicesMap = (_b = (_a = gdsServices === null || gdsServices === void 0 ? void 0 : gdsServices.services) === null || _a === void 0 ? void 0 : _a.reduce((map, service) => {
            map.set(service.id, service);
            return map;
        }, new Map())) !== null && _b !== void 0 ? _b : new Map();
        passengers.forEach(passenger => {
            var _a, _b, _c, _d, _e;
            if (passenger.services) {
                (_c = (_b = (_a = passenger.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(selectedService => {
                    const service = servicesMap.get(selectedService.serviceId);
                    if ((!skipConfirmedServices || selectedService.count > selectedService.confirmedCount) &&
                        !!service) {
                        itemList.push(this.convertGdsServiceToDataLayer(service, Object.assign(Object.assign({}, selectedService), { count: !skipConfirmedServices
                                ? selectedService.count
                                : selectedService.count - selectedService.confirmedCount }), passenger.type));
                    }
                });
                (_e = (_d = passenger.services) === null || _d === void 0 ? void 0 : _d.seats) === null || _e === void 0 ? void 0 : _e.filter(seat => !skipConfirmedServices || !seat.isConfirmed).forEach(selectedSeat => {
                    var _a, _b, _c, _d;
                    itemList.push(this.seat(selectedSeat.seat, (_a = selectedSeat.segment) === null || _a === void 0 ? void 0 : _a.id, (_d = (_c = (_b = passenger.preselectedServices) === null || _b === void 0 ? void 0 : _b.seats) === null || _c === void 0 ? void 0 : _c.some(preselected => {
                        var _a, _b, _c, _d;
                        return ((_a = preselected.seat) === null || _a === void 0 ? void 0 : _a.number) === ((_b = selectedSeat.seat) === null || _b === void 0 ? void 0 : _b.number) &&
                            ((_c = preselected.segment) === null || _c === void 0 ? void 0 : _c.id) === ((_d = selectedSeat.segment) === null || _d === void 0 ? void 0 : _d.id);
                    })) !== null && _d !== void 0 ? _d : false, passenger.type));
                });
            }
        });
        if (insurances === null || insurances === void 0 ? void 0 : insurances.selectedInsurances) {
            itemList.push(...this.selectedInsurances(insurances.selectedInsurances.filter(insurance => !skipConfirmedServices || !insurance.policyUrl), passengers));
        }
        if ((_c = transfer === null || transfer === void 0 ? void 0 : transfer.aeroexpress) === null || _c === void 0 ? void 0 : _c.selectedTrips) {
            itemList.push(...this.aeroexpress(transfer.aeroexpress.selectedTrips.filter(trip => { var _a; return !skipConfirmedServices || !((_a = trip.tickets) === null || _a === void 0 ? void 0 : _a.length); }), passengers));
        }
        return itemList;
    }
}
export default WebskyServicesAnalytics;
