import * as React from 'react';
import cn from 'classnames';
import { memo } from 'react';
import { useTheme } from '../../theme';
const OfferCard = memo(({ image, title, text, wide, renderFooter, inLanding = false }) => {
    const { OfferCard: css } = useTheme('OfferCard');
    return (React.createElement("div", { className: cn(css.card, {
            [css.card_wide]: wide,
            [css.card_inLanding]: inLanding
        }) },
        React.createElement("div", { className: css.image, style: { backgroundImage: image && `url(${image})` } }),
        React.createElement("div", { className: css.body },
            React.createElement("div", { className: css.body__inner },
                React.createElement("h2", { className: css.title }, title),
                React.createElement("div", { className: css.text }, text)),
            renderFooter && renderFooter())));
});
OfferCard.displayName = 'OfferCard';
export default OfferCard;
