import * as React from 'react';
import { CartService } from '../../Cart/types';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import CartItemOptionAdapter from '../../Cart/CartItemOptionAdapter/CartItemOptionAdapter';
import { groupSeats } from '../../Cart/utils';
import { overridedIcons } from '../../Icons';
import ServiceSummaryDivider from '../components/ServiceSummaryDivider/ServiceSummaryDivider';
import { getTotalPrice } from '../../utils';
const SeatSummary = props => {
    const onDeleteHandler = (travellerId, serviceId, segmentId) => {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Seats, {
            type: OrderAdditionalServiceGdsServiceServiceType.Seat,
            serviceId,
            passengerId: travellerId,
            segmentId
        });
    };
    const seats = React.useMemo(() => {
        return props.travellers
            .filter(traveller => { var _a; return !!((_a = traveller.services.seats) === null || _a === void 0 ? void 0 : _a.length); })
            .map(traveller => {
            const seats = groupSeats(traveller), options = [];
            Object.values(seats[traveller.id]).forEach(seat => {
                if (seat.additional) {
                    seat.additional.forEach(seat => {
                        var _a, _b, _c;
                        const segment = props.segmentsById.get((_a = seat.segment) === null || _a === void 0 ? void 0 : _a.id);
                        let price = seat.seat.price;
                        if ((_b = seat.seat.seatServices) === null || _b === void 0 ? void 0 : _b.length) {
                            price = getTotalPrice(seat.seat.seatServices, service => service.price);
                        }
                        if (props.segmentId && props.segmentId !== segment.id) {
                            return;
                        }
                        options.push({
                            id: seat.seat.number,
                            name: `${segment.iataDirection}, ${seat.seat.number}`,
                            price,
                            // included when onRequest is false (onRequest: true - seat is not fully confirmed)
                            included: seat.isConfirmed && !seat.onRequest,
                            status: (_c = seat.product) === null || _c === void 0 ? void 0 : _c.status,
                            onDelete: () => onDeleteHandler(traveller.id, seat.seat.number, seat.segment.id)
                        });
                    });
                }
            });
            return {
                items: options,
                traveller: props.travellersById.get(traveller.id),
                headerType: 'travellerName',
                headerIcon: overridedIcons['SeatSelection']
            };
        });
    }, [props.travellers, props.segmentId]);
    const content = React.useMemo(() => {
        if (!seats.length) {
            return null;
        }
        return (React.createElement(ServiceSummaryDivider, null, seats.map(seat => (React.createElement(CartItemOptionAdapter, { option: seat, key: seat.header })))));
    }, [seats]);
    return props.children(content);
};
export default SeatSummary;
