import { isBefore, isToday, isValid, parse } from 'date-fns';
export const tryParseDate = (input) => {
    const regex = /(?<day>[0-9]{2})\.?(?<month>[0-9]{2})\.?(?<year>[0-9]{2,4})?/;
    const parseString = regex.exec(input);
    const date = parseString === null || parseString === void 0 ? void 0 : parseString.groups['day'], month = parseString === null || parseString === void 0 ? void 0 : parseString.groups['month'], year = parseString === null || parseString === void 0 ? void 0 : parseString.groups['year'];
    let newDate = null;
    if (date && month) {
        if (!year) {
            newDate = parse(`${date}.${month}`, 'dd.MM', new Date());
        }
        else if (year.length === 2) {
            newDate = parse(`${date}.${month}.${year}`, 'dd.MM.yy', new Date());
        }
        else if (year.length === 4) {
            newDate = parse(`${date}.${month}.${year}`, 'dd.MM.yyyy', new Date());
        }
    }
    if (isValid(newDate) && (isBefore(new Date(), newDate) || isToday(newDate))) {
        return newDate;
    }
    return null;
};
