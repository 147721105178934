import * as React from 'react';
import ServiceSummary from '../../ServiceSummary';
import { useTranslation } from 'react-i18next';
import { overridedIcons } from '../../Icons';
import Aeroexpress from './Extra/Aeroexpress';
import Insurance from './Extra/Insurance';
import { useContext, useMemo } from 'react';
import { CartContext } from '../Cart';
import BusinessLounge from './Extra/BusinessLounge';
import Animal from './Extra/Animal';
import { getTotalPrice } from '../../utils';
import { CheckoutSteps } from '../../MobileStepbar/types';
import TransferExtraCart from './Extra/Transfer';
import PriorityBoarding from './Extra/PriorityBoarding';
import BaggageDelivery from './Extra/BaggageDelivery';
import Sms from './Extra/Sms';
const Extra = props => {
    const { t } = useTranslation('Cart');
    const { totalPrices } = useContext(CartContext);
    const prices = useMemo(() => {
        if (props.separateInsurance) {
            return [
                totalPrices.aeroexpress,
                totalPrices.businessLounge,
                totalPrices.animals,
                totalPrices.priorityBoarding,
                totalPrices.baggageDelivery,
                totalPrices.sms
            ];
        }
        return [
            totalPrices.aeroexpress,
            totalPrices.businessLounge,
            totalPrices.insurance,
            totalPrices.animals,
            totalPrices.transfer,
            totalPrices.priorityBoarding,
            totalPrices.baggageDelivery,
            totalPrices.sms
        ];
    }, [totalPrices, props.separateInsurance]);
    const extraTotalPrice = useMemo(() => getTotalPrice(prices, price => price), [prices]);
    const hasExtraServices = useMemo(() => prices.some(price => price === null || price === void 0 ? void 0 : price.amount), [prices]);
    const group = {
        header: t('Extra'),
        icon: overridedIcons['SeatSelection'],
        totalPrice: extraTotalPrice
    };
    const content = hasExtraServices ? (React.createElement(React.Fragment, null,
        totalPrices.aeroexpress !== null && React.createElement(Aeroexpress, Object.assign({}, props)),
        totalPrices.priorityBoarding !== null && React.createElement(PriorityBoarding, Object.assign({}, props)),
        totalPrices.transfer !== null && React.createElement(TransferExtraCart, Object.assign({}, props)),
        totalPrices.insurance !== null && !props.separateInsurance && React.createElement(Insurance, Object.assign({}, props)),
        totalPrices.businessLounge !== null && React.createElement(BusinessLounge, Object.assign({}, props)),
        totalPrices.animals !== null && React.createElement(Animal, Object.assign({}, props)),
        totalPrices.baggageDelivery !== null && React.createElement(BaggageDelivery, Object.assign({}, props)),
        totalPrices.sms !== null && React.createElement(Sms, Object.assign({}, props)))) : null;
    if (props.renderService) {
        return props.renderService(CheckoutSteps.Extras, group, content);
    }
    if (!content) {
        return null;
    }
    return React.createElement(ServiceSummary, { group: group }, content);
};
export default Extra;
