import * as React from 'react';
import { useContext } from 'react';
import { getShowRenderersInConsole } from './cache';
const merge = require('lodash.merge');
const RenderersContext = React.createContext({});
export const RenderersProvider = React.memo(({ value, children }) => {
    const currentRenderers = useContext(RenderersContext);
    return React.createElement(RenderersContext.Provider, { value: merge(currentRenderers, value) }, children);
});
export const { Consumer: RenderersConsumer } = RenderersContext;
export { RenderersContext };
export const useRenderers = () => useContext(RenderersContext);
export const OverrideComponent = (props) => {
    const rerenders = useContext(RenderersContext);
    const componentName = Object.keys(props.component)[0];
    if (rerenders.hasOwnProperty(componentName) && rerenders[componentName]) {
        const Component = rerenders[componentName];
        if (getShowRenderersInConsole()) {
            console.log(`%c Override component ${componentName} `, 'background: #222; color: #bada55; font-size: 16px');
        }
        return React.createElement(Component, Object.assign({}, props.componentProps));
    }
    const Component = props.component[componentName];
    return React.createElement(Component, Object.assign({}, props.componentProps));
};
