import { Measurement } from '../BaggageService/types';
import { BaggageType, FareFamilyOptionsType, FareFeaturePaymentType } from '@websky/graphql';
export const parseIncludedBaggage = (fareFamiliesMap, passengers) => {
    const includedBaggage = [];
    fareFamiliesMap.forEach(({ segmentIds, fareFamily }) => {
        fareFamily.options
            .filter(option => (option.type === FareFamilyOptionsType.CarryOn || option.type === FareFamilyOptionsType.Baggage) &&
            option.availability === FareFeaturePaymentType.Free)
            .forEach(option => {
            const splittedValue = option.value.split('x'), value = splittedValue.length === 2 ? splittedValue[1] : option.value;
            includedBaggage.push({
                id: null,
                segmentIds: [segmentIds.map(segmentId => segmentId)],
                includedSegments: segmentIds.map(segmentId => segmentId),
                type: option.type === 'CarryOn' ? BaggageType.CarryOn : BaggageType.CheckedBaggage,
                name: option.title,
                included: parseInt(splittedValue[0]),
                price: null,
                value: {
                    amount: parseInt(value),
                    measurement: Measurement.Kilograms
                },
                size: option.size,
                allowedPassengers: passengers,
                canBeAdded: false,
                rfics: ''
            });
        });
    });
    return includedBaggage;
};
export const getPriceToPay = (selectedServices, services) => {
    const priceToPay = {
        amount: 0,
        currency: null
    };
    let isAdded = false;
    services.forEach(service => {
        const addedService = selectedServices.filter(selected => selected.serviceId === service.id);
        addedService.forEach(addedService => {
            isAdded = true;
            priceToPay.amount += service.price.amount * addedService.count * addedService.segmentIds.length;
            priceToPay.currency = service.price.currency;
        });
    });
    return isAdded ? priceToPay : null;
};
export const getMinPrice = (services) => {
    const minPrice = {
        amount: null,
        currency: null
    };
    services.forEach(service => {
        service.segmentIds.forEach(segmentIds => {
            const minPriceForService = service.price.amount * segmentIds.length;
            if (!minPrice.amount || minPriceForService < minPrice.amount) {
                minPrice.amount = minPriceForService;
                minPrice.currency = service.price.currency;
            }
        });
    });
    return minPrice;
};
