import { __awaiter } from "tslib";
import * as React from 'react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { AviaPassengerType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import Seats from '../routes/Seats';
import { getIsSkipSeatSelectionAllowed, getSeatMap, getTravellers } from '../../../../store/order/selectors';
import { useServiceRequest } from '../../../../utils';
import { checkAllSeatsAreSelected, getSeatPrice } from '../../../../../../SeatMap/utils';
import { getCroppedError, getLinkedTravellersMap, MOBILE_MIN_WIDTH } from '../../../../../../utils';
import Modal from '../../../../../../Modal';
import SuggestionModalContent from '../../../../../../SuggestionModalContent/SuggestionModalContent';
import { SeatIcon } from '../../../../../../SuggestionModalContent/icons';
import { SlideBottom } from '../../../../../../index';
import WarningModal from '../../../../../../WarningModal';
import SimpleLoader from '../../../../../../SimpleLoader';
const SeatsReducer = (state, actionVar) => {
    if (actionVar.type && actionVar.type === 'SET_STATE') {
        return Object.assign({}, actionVar.payload);
    }
    const action = actionVar;
    const seatId = `${action.passengerId}_${action.segmentId}`;
    return Object.assign(Object.assign({}, state), { [seatId]: action.seats });
};
const PreselectedSeats = props => {
    const travellers = useSelector(getTravellers);
    const seatMap = useSelector(getSeatMap);
    const isSkipSeatSelectionAllowed = useSelector(getIsSkipSeatSelectionAllowed);
    const [checkinError, setCheckinError] = useState(null);
    const [requiredSeatsModalIsVisible, setRequiredSeatsModalIsVisible] = useState(false);
    const [state, dispatch] = React.useReducer(SeatsReducer, {});
    const [isLoading, setIsLoading] = useState(false);
    const { runServiceRequest } = useServiceRequest();
    const recommendedSeatsPlaced = useRef(false);
    const { t } = useTranslation('Checkin');
    const closeRequiredSeatsModal = () => setRequiredSeatsModalIsVisible(false);
    const closeWarningModal = () => setCheckinError(null);
    React.useEffect(() => {
        if (!recommendedSeatsPlaced.current) {
            travellers.forEach(traveller => {
                var _a, _b;
                (_b = (_a = traveller.preselectedServices) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.forEach(seat => {
                    var _a;
                    const seatId = `${traveller.id}_${seat.segment.id}`;
                    if (!state[seatId] &&
                        !((_a = traveller.services.seats) === null || _a === void 0 ? void 0 : _a.some(selected => selected.segment.id === seat.segment.id))) {
                        setSeatCallback([
                            {
                                number: seat.seat.number,
                                row: seat.seat.row,
                                price: seat.seat.price,
                                letter: seat.seat.letter,
                                service: null,
                                rfisc: null,
                                isAvailable: null,
                                isAisle: null,
                                isExistent: null,
                                isSmoking: null,
                                isRecline: null,
                                seatService: null,
                                seatServices: null,
                                services: [],
                                restrictions: null,
                                permissions: []
                            }
                        ], traveller.id, seat.segment.id);
                    }
                });
            });
            recommendedSeatsPlaced.current = true;
        }
    }, [travellers, recommendedSeatsPlaced, state]);
    React.useEffect(() => {
        const newState = {};
        travellers.forEach(traveller => {
            var _a, _b;
            (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.map(seat => {
                var _a;
                const key = `${traveller.id}_${(_a = seat.segment) === null || _a === void 0 ? void 0 : _a.id}`;
                newState[key] = [...(newState[key] || []), seat];
            });
        });
        dispatch({
            type: 'SET_STATE',
            payload: newState
        });
    }, [travellers]);
    const setSeatCallback = ([seat], passengerId, segmentId) => {
        if (seat) {
            travellers.map(traveller => {
                var _a, _b;
                if (state && ((_b = (_a = state[`${traveller.id}_${segmentId}`]) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.seat.number) === seat.number) {
                    dispatch({
                        passengerId: traveller.id,
                        segmentId,
                        seats: []
                    });
                }
            });
            // WEBSKY-2988: for preselected seats in checkin
            setTimeout(() => dispatch({
                seats: [
                    {
                        isConfirmed: false,
                        onRequest: false,
                        isChangeable: true,
                        seat: seat,
                        row: parseInt(seat.row),
                        letter: seat.letter,
                        segment: {
                            id: segmentId
                        },
                        product: null,
                        type: null
                    }
                ],
                passengerId: passengerId,
                segmentId
            }));
        }
        else {
            checkTravellerClearSeatMiddleware({
                passengerId,
                segmentId,
                seats: []
            }, dispatch);
        }
    };
    const setClearSeatCallback = (seats, passengerId, segmentId) => {
        checkTravellerClearSeatMiddleware({
            passengerId,
            segmentId,
            seats: []
        }, dispatch);
    };
    /*
     Если пользователь удаляет место, которое есть в заказе, то отправляем запрос на удаление,
     если нет, то добавляем во внутренний стейт
     */
    const checkTravellerClearSeatMiddleware = (action, next) => {
        var _a, _b, _c;
        const { seats, passengerId, segmentId } = action;
        const seat = seats[0];
        const travellerSeat = (_c = (_b = (_a = travellers
            .find(traveller => traveller.id === passengerId)) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.seats) === null || _c === void 0 ? void 0 : _c.find(seat => seat.segment.id === segmentId);
        if (!seat && travellerSeat) {
            const travellersWithSeats = travellers.map(traveller => {
                return {
                    passengerId: traveller.id,
                    seats: passengerId === traveller.id ? null : traveller.services.seats,
                    setServices: null
                };
            });
            onRequest(travellersWithSeats).then(() => {
                setIsLoading(false);
            });
        }
        else {
            next({
                seats,
                passengerId,
                segmentId
            });
        }
    };
    const setOnClearSegmentCallback = (segmentId) => {
        travellers.map(traveller => {
            checkTravellerClearSeatMiddleware({
                passengerId: traveller.id,
                segmentId: segmentId.toString(),
                seats: []
            }, dispatch);
        });
    };
    const travellersWithSeats = React.useMemo(() => {
        return travellers.map(traveller => {
            const passengerSeats = [];
            seatMap.segments.forEach(segment => {
                if (!!state[`${traveller.id}_${segment.segmentsInfo.id}`]) {
                    state[`${traveller.id}_${segment.segmentsInfo.id}`].forEach(seat => {
                        passengerSeats.push(seat);
                    });
                }
            });
            return Object.assign(Object.assign({}, traveller), { services: Object.assign(Object.assign({}, traveller.services), { seats: passengerSeats }), needOthsSsr: null });
        });
    }, [travellers, state]);
    const passengers = React.useMemo(() => {
        const linkedPassengersIdsMap = getLinkedTravellersMap(travellersWithSeats);
        return travellersWithSeats.map(traveller => {
            var _a, _b;
            const passenger = {
                id: traveller.id,
                values: traveller.values,
                type: traveller.type,
                seats: (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.map(seat => {
                    var _a, _b;
                    return {
                        id: (_b = (_a = seat.seat) === null || _a === void 0 ? void 0 : _a.seatService) === null || _b === void 0 ? void 0 : _b.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Seat,
                        deckId: '0',
                        letter: seat.letter,
                        row: seat.row.toString(),
                        number: seat.seat.number,
                        price: seat.seat.price,
                        segmentId: seat.segment.id,
                        rfisc: seat.seat.rfisc,
                        isConfirmed: seat.isConfirmed,
                        isChangeable: seat.isChangeable,
                        seatServices: seat.seat.seatServices
                    };
                }),
                seatRestrictions: traveller.seatRestrictions,
                infantPassengerId: traveller.type === AviaPassengerType.ADT && (linkedPassengersIdsMap.get(traveller.id) || null)
            };
            return passenger;
        });
    }, [travellersWithSeats]);
    const onRequest = React.useCallback((passengers) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const order = yield runServiceRequest(passengers);
            return order;
        }
        catch (e) {
            setCheckinError(e);
            console.error(e);
        }
        setIsLoading(false);
    }), [runServiceRequest]);
    const onProceed = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const allRequiredSeatsAreSelected = checkAllSeatsAreSelected(passengers, seatMap.segments.filter(segment => segment.availableSeatsCount > 0).length);
        if (isSkipSeatSelectionAllowed || (allRequiredSeatsAreSelected && !requiredSeatsModalIsVisible)) {
            setIsLoading(true);
            const passengers = travellersWithSeats.map(travellerWithSeats => {
                return {
                    passengerId: travellerWithSeats.id,
                    seats: travellerWithSeats.services.seats,
                    setServices: null
                };
            });
            const isEverySeatServiceConfirmed = passengers
                .filter(passenger => travellersWithSeats.find(traveller => traveller.id === passenger.passengerId).type !== 'INF')
                .every(passenger => {
                var _a, _b, _c;
                return ((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.every(seat => seat.isConfirmed)) &&
                    ((_b = passenger.seats) === null || _b === void 0 ? void 0 : _b.length) >= ((_c = seatMap.segments) === null || _c === void 0 ? void 0 : _c.length);
            });
            // если все места со статусом confirmed, переходим на следующую страницу
            // т.к. отправка запроса CheckinSaveOrderServices не требуется
            if (isEverySeatServiceConfirmed) {
                yield props.onProceed();
                setIsLoading(false);
            }
            else {
                const services = yield onRequest(passengers);
                if (services && services.travellers) {
                    const allRequiredSeatsAreSelected = services.travellers.every(traveller => {
                        var _a;
                        return (traveller.type === 'INF' ||
                            ((_a = traveller.services.seats) === null || _a === void 0 ? void 0 : _a.length) >=
                                services.seatMap.segments.filter(segment => segment.availableSeatsCount > 0).length);
                    });
                    if (allRequiredSeatsAreSelected || isSkipSeatSelectionAllowed) {
                        yield props.onProceed();
                        setIsLoading(false);
                    }
                    else {
                        setRequiredSeatsModalIsVisible(true);
                        setIsLoading(false);
                    }
                }
            }
        }
        else {
            setRequiredSeatsModalIsVisible(true);
            setIsLoading(false);
        }
    }), [travellersWithSeats]);
    const seatsPriceToPay = React.useMemo(() => {
        const price = {
            amount: 0,
            currency: null
        };
        passengers.forEach(passenger => {
            passenger.seats.forEach(seat => {
                var _a;
                if ((seat === null || seat === void 0 ? void 0 : seat.isConfirmed) === false) {
                    price.amount += getSeatPrice(seat).amount;
                    if ((_a = seat.price) === null || _a === void 0 ? void 0 : _a.currency) {
                        price.currency = seat.price.currency;
                    }
                }
            });
        });
        return price;
    }, [passengers]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Seats, { onProceed: onProceed, passengers: passengers, travellers: travellersWithSeats, setSeatCallback: setSeatCallback, setClearSeatCallback: setClearSeatCallback, isLoading: isLoading, setIsLoading: setIsLoading, seatsPriceToPay: seatsPriceToPay, onSegmentClear: setOnClearSegmentCallback }),
        React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
            React.createElement(Modal, { maxWidth: "sm", open: requiredSeatsModalIsVisible, onClose: closeRequiredSeatsModal, scroll: "paper" },
                React.createElement(SuggestionModalContent, { text: t('To complete online check-in you must choose seats for all passengers on all flight directions'), icon: SeatIcon, agreeButtonText: 'OK', onAgree: closeRequiredSeatsModal }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: requiredSeatsModalIsVisible, onClose: closeRequiredSeatsModal },
                React.createElement(SuggestionModalContent, { insideSlideBottom: true, icon: SeatIcon, text: t('To complete online check-in you must choose seats for all passengers on all flight directions'), agreeButtonText: 'OK', onAgree: closeRequiredSeatsModal }))),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred during the check-in process. Please try again later or contact a customer support service.'), errorMessage: t(getCroppedError(checkinError === null || checkinError === void 0 ? void 0 : checkinError.message)), onClose: closeWarningModal, onButtonClick: closeWarningModal, buttonText: t('OK, thank you'), isOpen: !!checkinError }),
        isLoading && React.createElement(SimpleLoader, null)));
};
export default PreselectedSeats;
