import * as React from 'react';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useTranslation } from 'react-i18next';
import { Money } from '../../Money';
import { Currency } from '../../enums';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import { arrowIcon } from '../../Passengers/icons';
import { FlightClass } from '@websky/graphql';
initI18n('FlightPrice');
const FlightPrice = ({ variant = 'common', isActive = false, serviceClass = FlightClass.Economy, onClick, price, className, singleFare }) => {
    const { FlightPrice: css } = useTheme('FlightPrice');
    const { t } = useTranslation('FlightPrice');
    return (React.createElement(ButtonBase, { onClick: onClick, disableRipple: variant === 'arrowed' || !price, className: cn(css.wrapper, {
            [css.wrapper_arrowed]: variant === 'arrowed',
            [css.wrapper_flat]: variant === 'flat',
            [css.wrapper_economy]: serviceClass === FlightClass.Economy,
            [css.wrapper_business]: serviceClass === FlightClass.Business,
            [css.wrapper_active]: isActive,
            [css.wrapper_disabled]: !price,
            [css.wrapper_singleFare]: singleFare
        }, className) },
        React.createElement("div", { className: css.titles },
            React.createElement("div", { className: cn(css.title, {
                    [css.title_singleFare]: singleFare
                }) }, t(serviceClass)),
            React.createElement("div", { className: cn(css.prefix, {
                    [css.prefix_noSeats]: !price,
                    [css.prefix_singleFare]: singleFare
                }) }, price ? t('from') : t('NO SEATS'))),
        price && (React.createElement("div", { className: cn(css.price, {
                [css.price_RUB]: price.currency === Currency.RUB,
                [css.price_arrowed]: variant === 'arrowed',
                [css.price_singleFare]: singleFare
            }) },
            React.createElement(Money, { money: price }),
            React.createElement("span", { className: css.price__icon }, arrowIcon)))));
};
export default FlightPrice;
