import { __awaiter } from "tslib";
import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { CheckinDocumentType, useCheckinDocumentsQuery, useCheckinSendDocumentMutation } from '@websky/graphql';
import { getTravellerName, getTravellerRetryLink } from '../../../../../utils';
import Passenger from '../../../../../../../RegisteredPassenger/RegisteredPassenger';
import { getAllServices, getCheckinOrderId } from '../../../../../store/order/selectors';
import { downloadPDF, downloadPkpass, MOBILE_MIN_WIDTH } from '../../../../../../../utils';
import { CheckinGoal, reachGoal } from '../../../../../../../analytics';
const RegisteredPassenger = memo(({ passenger, linkedPassengers, onCheckinCancel, segments, segmentsFailedBoardingPasses, setSegmentsFailedBoardingPasses }) => {
    var _a, _b, _c;
    const isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
    const [barcodes, setBarcodes] = useState();
    const orderId = useSelector(getCheckinOrderId);
    const services = useSelector(getAllServices);
    const { refetch: loadBoardingPass } = useCheckinDocumentsQuery({ skip: true, fetchPolicy: 'no-cache' });
    const [sendDocument] = useCheckinSendDocumentMutation();
    const getBarcode = () => __awaiter(void 0, void 0, void 0, function* () {
        const promises = segments.map(segment => {
            return loadBoardingPass({
                params: {
                    orderId,
                    travellerId: passenger.id,
                    documentType: CheckinDocumentType.QrCode,
                    segmentId: segment.id
                }
            });
        });
        const results = yield Promise.all(promises);
        setBarcodes(results.map(result => { var _a, _b; return (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.CheckinDocuments) === null || _b === void 0 ? void 0 : _b.content; }));
    });
    const onBoardingPassDownload = useCallback((segmentId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield loadBoardingPass({
                params: {
                    orderId,
                    segmentId: segmentId !== null && segmentId !== void 0 ? segmentId : segments[0].id,
                    travellerId: passenger.id,
                    documentType: CheckinDocumentType.BoardingPass
                }
            });
            // Show error warning modal
            if (data === null || data === void 0 ? void 0 : data.CheckinDocuments) {
                downloadPDF(data.CheckinDocuments.content, `boarding_pass_${passenger.id}_${segmentId !== null && segmentId !== void 0 ? segmentId : segments[0].id}.pdf`);
                return true;
            }
            return false;
        }
        catch (e) {
            console.error(e);
            return false;
        }
    }), [orderId]);
    const onTicketsResend = useCallback((email, segmentId) => __awaiter(void 0, void 0, void 0, function* () {
        var _d;
        try {
            yield sendDocument({
                variables: {
                    params: {
                        documentType: CheckinDocumentType.BoardingPass,
                        email,
                        orderId,
                        segmentId: segmentId !== null && segmentId !== void 0 ? segmentId : (_d = segments[0]) === null || _d === void 0 ? void 0 : _d.id,
                        travellerId: passenger.id
                    }
                }
            });
            reachGoal(CheckinGoal.ResendTicket);
            return true;
        }
        catch (e) {
            console.error(e);
            return false;
        }
    }), [orderId]);
    const onAppleWalletPassDownload = useCallback((segmentId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield loadBoardingPass({
                params: {
                    orderId,
                    segmentId: segmentId !== null && segmentId !== void 0 ? segmentId : segments[0].id,
                    travellerId: passenger.id,
                    documentType: CheckinDocumentType.AppleWallet
                }
            });
            // Show error warning modal
            if (data === null || data === void 0 ? void 0 : data.CheckinDocuments) {
                reachGoal(CheckinGoal.AppleWalletPassDownload);
                downloadPkpass(data.CheckinDocuments.content, `boarding_pass_${passenger.id}.pkpass`);
                return true;
            }
            return false;
        }
        catch (e) {
            console.error(e);
            return false;
        }
    }), [orderId]);
    useEffect(() => {
        if (!barcodes && isMobile) {
            getBarcode();
        }
    }, [barcodes]);
    useEffect(() => {
        setSegmentsFailedBoardingPasses(new Set());
    }, []);
    return (React.createElement(Passenger, { retryLink: getTravellerRetryLink(passenger), segments: segments, name: getTravellerName(passenger), services: (_c = (_b = (_a = passenger === null || passenger === void 0 ? void 0 : passenger.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services.map(service => (Object.assign(Object.assign({}, services === null || services === void 0 ? void 0 : services.find(({ id }) => id === service.serviceId)), { count: service.count })))) !== null && _c !== void 0 ? _c : [], linkedPassengers: linkedPassengers, seats: passenger.services.seats, onBoardingPassDownload: onBoardingPassDownload, onAppleWalletPassDownload: onAppleWalletPassDownload, onTicketsResend: onTicketsResend, barcodes: barcodes, onCheckinCancel: onCheckinCancel.bind(null, passenger.id), isCheckedIn: passenger.isConfirmed, segmentsFailedBoardingPasses: segmentsFailedBoardingPasses, setSegmentsFailedBoardingPasses: setSegmentsFailedBoardingPasses }));
});
export default RegisteredPassenger;
