import React, { useState, useEffect } from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import { Female, Male } from '../../../Icons';
const defaultOptions = [
    {
        key: 'Male',
        value: 'Male',
        icon: Male
    },
    {
        key: 'Female',
        value: 'Female',
        icon: Female
    }
];
const SexSelector = ({ className, disabled, activeSex, options = defaultOptions, onChange, onBlur, error, helperText }) => {
    const { SexSelector: css } = useTheme('BaseComponents');
    const [sex, setSex] = useState(activeSex);
    useEffect(() => {
        if (activeSex !== sex) {
            setSex(activeSex);
        }
    }, [activeSex]);
    const onChangeHandler = (e) => {
        setSex(e.target.value);
        onChange === null || onChange === void 0 ? void 0 : onChange(e.target.value);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
    };
    return (React.createElement("div", { className: cn(css.sexSelector, className) },
        React.createElement("div", { className: css.sexSelector__container }, options.map(option => (React.createElement("label", { className: cn(css.label, {
                [css.error]: error,
                [css.disabled]: disabled,
                [css.checked]: option.key === sex
            }), key: option.key },
            React.createElement("div", { className: css.icon }, option.icon),
            React.createElement("input", { id: option.key, type: "radio", className: cn(css.input), checked: option.key === sex, value: option.key, disabled: disabled, onChange: onChangeHandler }))))),
        error && React.createElement("div", { className: css.sexSelector__helperText }, helperText)));
};
export default SexSelector;
