import * as React from 'react';
import cn from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useTheme } from '../../../theme';
const Reason = props => {
    const { Reason: css } = useTheme('Refund');
    return (React.createElement(FormControlLabel, { className: cn(css.label, { [css.active]: props.checked }, props.className), checked: props.checked, value: props.value, control: React.createElement(Radio, { classes: { root: cn(css.radio), checked: cn(css.radio, css.active) } }), label: props.label }));
};
export default Reason;
