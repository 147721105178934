import { __awaiter } from "tslib";
import * as React from 'react';
import { useGetAviaOrdersListQuery } from '@websky/graphql';
import NextFlight from '../NextFlight';
import ProfileControls from '../../../ProfileControls';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import UserCard from '../../../UserCard/components/UserCard';
import { TravellerTypes } from '../../../Traveller/types';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH, redirectToOrder } from '../../../utils';
import { findUpcomingFlight } from './utils';
import { useConfig } from '../../../context';
const HomePage = ({ currentUser }) => {
    const { Account: css } = useTheme('AccountModule');
    const { t } = useTranslation('Account');
    const history = useHistory();
    const { Account: { engineURL } } = useConfig();
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const { data: orders, refetch } = useGetAviaOrdersListQuery({ fetchPolicy: 'no-cache' });
    const upcomingOrder = React.useMemo(() => findUpcomingFlight(orders === null || orders === void 0 ? void 0 : orders.AviaOrdersList), [orders === null || orders === void 0 ? void 0 : orders.AviaOrdersList]);
    const onOrderClickHandler = (orderId) => {
        redirectToOrder(engineURL, orderId);
    };
    const onDeleted = () => __awaiter(void 0, void 0, void 0, function* () { return yield refetch(); });
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", { className: css.route__title }, t('My office')),
        React.createElement(UserCard, { className: css.homeUserCard, type: TravellerTypes.PersonalDetails, profile: currentUser, onClick: () => history.push('/account/settings'), editText: !isMobile ? t('Edit') : null, showArrow: true }),
        upcomingOrder && (React.createElement(NextFlight, { order: upcomingOrder, onClick: onOrderClickHandler, onDeleted: onDeleted })),
        React.createElement(ProfileControls, null)));
};
export default HomePage;
