import * as React from 'react';
import { useContext } from 'react';
import { Collapse } from '@mui/material';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { DisabilitiesContext } from './context';
import { useToggleable } from '../../../../hooks';
import { Field, useFormState } from 'react-final-form';
import Switch from '../Switch/Switch';
import List from './List/List';
import { validateDisabilities } from './utils';
import cn from 'classnames';
const Disabilities = ({ groupIcon }) => {
    var _a, _b;
    const theme = useTheme('PassengerForm').Disabilities;
    const groupTheme = useTheme('PassengerForm').Group;
    const { t } = useTranslation('Passenger');
    const { availableDisabilities, passengerId } = useContext(DisabilitiesContext);
    const { values: { passengers }, errors, submitFailed } = useFormState();
    const disabilities = passengers[passengerId].disabilities;
    const { isOpen, toggle } = useToggleable(disabilities === null || disabilities === void 0 ? void 0 : disabilities.enabled);
    const fieldNameBasePath = `passengers.${passengerId}.disabilities`;
    if (!(availableDisabilities === null || availableDisabilities === void 0 ? void 0 : availableDisabilities.length)) {
        return null;
    }
    return (React.createElement("div", { className: theme.container },
        React.createElement(Field, { name: `${fieldNameBasePath}.enabled`, initialValue: isOpen, type: 'checkbox', key: JSON.stringify(disabilities.values), validate: (value, allValues) => {
                const error = validateDisabilities(allValues['passengers'][passengerId]);
                return error ? t(error) : null;
            }, render: ({ input, meta }) => (React.createElement("div", { className: theme.switchWrapper },
                groupIcon && React.createElement("div", { className: cn(groupTheme.icon, theme.icon) }, groupIcon),
                React.createElement(Switch, { label: t('Passenger with disabilities'), isActive: isOpen, onClick: () => {
                        toggle();
                        input.onChange(Object.assign(Object.assign({}, new Event('change')), { target: {
                                value: !isOpen
                            } }));
                    }, className: theme.switchControl }),
                meta.submitFailed && meta.error && (React.createElement("div", { className: theme.switchControl__errorText }, meta.error)))) }),
        React.createElement(Collapse, { in: isOpen },
            React.createElement(List, { availableDisabilities: availableDisabilities, passengerId: passengerId, passengerDisabilitiesState: disabilities, fieldNameBasePath: fieldNameBasePath, isError: submitFailed && !!((_b = (_a = errors['passengers']) === null || _a === void 0 ? void 0 : _a[passengerId]) === null || _b === void 0 ? void 0 : _b['disabilities']) }))));
};
export default Disabilities;
