import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../theme';
import SegmentTabComponent from './components/SegmentTab/SegmentTab';
import FullscreenDialogClose from '../FullscreenDialog/components/Close/Close';
const SegmentTabs = props => {
    const css = useTheme('SegmentTabs').SegmentTabs;
    return (React.createElement("div", { className: cn(css.wrapper, props.wrapperClassName, {
            [css.wrapper_sticky]: props.sticky,
            [css.wrapper_center]: props.center,
            [css.wrapper_withClose]: props.onClose
        }) },
        React.createElement("div", { id: "segment-tabs", className: css.inner },
            React.createElement("div", { className: cn(css.title, props.titleClassName) }, props.title),
            React.createElement("div", { className: cn(css.segments, props.segmentsClassName) },
                React.createElement("div", { className: css.alignmentStub }),
                props.segments.map((segment, index) => {
                    var _a;
                    return (React.createElement(SegmentTabComponent, { key: segment.id, index: index + 1, scrollToActive: props.scrollToActive, isActive: segment.id === props.activeSegment, isCompact: (_a = props.isCompact) !== null && _a !== void 0 ? _a : props.segments.length > 2, onClick: props.onSegmentClick.bind(null, segment.id), segment: segment, className: cn(props.classNameTab, {
                            [props.activeClassName]: segment.id === props.activeSegment
                        }), countSeatsSelected: segment.countSeatsSelected }));
                }),
                React.createElement("div", { className: css.alignmentStub })),
            props.onClose && React.createElement(FullscreenDialogClose, { onClose: props.onClose, closeClassName: css.close }))));
};
export default SegmentTabs;
