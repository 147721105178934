import * as React from 'react';
import { BaseButton, IconButton } from '../../../../index';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
const DialogContent = ({ closeConfirmationPopup, onInsuranceRemove, refusingIsInProgress }) => {
    const css = useTheme('AdditionalServices').Insurance;
    const { t } = useTranslation('Checkout');
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", { className: css.popup__title }, t('Are you sure you are ready to remove insurance?')),
        React.createElement("p", { className: css.popup__text }, t('Inflight insurance or baggage insurance is your travel confidence.')),
        React.createElement("div", { className: css.popup__buttons },
            !refusingIsInProgress && (React.createElement(BaseButton, { onClick: onInsuranceRemove, disabled: refusingIsInProgress }, t('Cancel'))),
            !refusingIsInProgress && (React.createElement(BaseButton, { onClick: closeConfirmationPopup, disabled: refusingIsInProgress }, t('Insure'))),
            refusingIsInProgress && React.createElement(IconButton, { isLoading: refusingIsInProgress }))));
};
export default DialogContent;
