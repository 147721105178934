import { useMemo } from 'react';
import { useToggleable } from '../hooks';
export const usePaymentMethodsError = (errors) => {
    var _a;
    const { isOpen, setOpen, close } = useToggleable(false);
    useMemo(() => !!errors && setOpen(true), [errors]);
    const errorMessage = (_a = errors === null || errors === void 0 ? void 0 : errors.graphQLErrors.find(item => {
        var _a;
        return (_a = item.message) === null || _a === void 0 ? void 0 : _a.startsWith('Sirena2_');
    })) === null || _a === void 0 ? void 0 : _a.message;
    return {
        isOpen,
        errorMessage,
        close
    };
};
