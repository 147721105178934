import { __rest } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import RadioMUI from '@material-ui/core/Radio';
const RadioButton = (_a) => {
    var { className } = _a, restProps = __rest(_a, ["className"]);
    const { RadioButton: css } = useTheme('BaseComponents');
    return (React.createElement(RadioMUI, Object.assign({ className: cn(css.radioButton, className), classes: {
            checked: css.checked,
            disabled: css.disabled
        } }, restProps)));
};
export default RadioButton;
