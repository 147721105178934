import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Money as MoneyComponent } from '../../../Money';
import { MealViewMode } from '../../types';
import { initI18n, useIsDesktop, useIsMobile } from '../../../utils';
import { useTheme } from '../../../theme';
import Modal from '../../../Modal';
import Details from '../Details/Details';
import CloseIcon from '../../../CloseIcon/components/CloseIcon';
import { OverrideComponent } from '../../../renderProps';
import { useToggleable } from '../../../hooks';
initI18n('Meal');
const Meal = ({ meal, marker, oldPrice, counters = [], onIncrease, onDecrease, onClear, onConfirm, readonly, fullSize, isLoading, isCountChanged, onAddForAll, onPopupOpened, mode, onClose }) => {
    const theme = useTheme('Meal').Meal;
    const { t } = useTranslation('Meal');
    const totalNumberOfMeals = counters.reduce((total, counter) => counter.count + total, 0);
    const isMobile = useIsMobile();
    const isDesktop = useIsDesktop();
    const { isOpen, open, close } = useToggleable(false);
    const openModal = useCallback(() => {
        onPopupOpened === null || onPopupOpened === void 0 ? void 0 : onPopupOpened(meal);
        open();
    }, [meal, onPopupOpened]);
    const closeModal = useCallback(() => {
        onClose === null || onClose === void 0 ? void 0 : onClose(meal);
        close();
    }, [meal, onClose]);
    const onConfirmHandler = useCallback(() => {
        onConfirm(meal);
        closeModal();
    }, [meal, onConfirm]);
    const onClearHandler = useCallback((meal) => {
        onClear(meal);
        close();
    }, [meal, onClear]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(theme.meal, {
                [theme.meal_fullSize]: fullSize && isDesktop
            }), onClick: openModal },
            React.createElement("div", { className: theme.header },
                React.createElement("div", { className: theme.image, style: meal.icon ? { backgroundImage: `url(${meal.icon})` } : {} }, (!fullSize || !isDesktop) && marker && React.createElement("div", { className: theme.marker }, marker)),
                (!fullSize || !isDesktop) && (React.createElement("div", { className: theme.title },
                    React.createElement("div", { className: cn(theme.name, { [theme.hasWeight]: true }) }, meal.name)))),
            React.createElement("div", { className: theme.footer },
                fullSize && isDesktop && marker && React.createElement("div", { className: theme.marker }, marker),
                fullSize && isDesktop && (React.createElement("div", { className: theme.title },
                    React.createElement("div", { className: theme.name }, meal.name))),
                React.createElement("div", { className: theme.footer__icons },
                    !!(oldPrice === null || oldPrice === void 0 ? void 0 : oldPrice.amount) && React.createElement(MoneyComponent, { moneyClassName: theme.oldPrice, money: oldPrice }),
                    !!meal.price.amount ? (React.createElement(MoneyComponent, { moneyClassName: theme.price, money: meal.price })) : (React.createElement("span", { className: theme.price }, t('Free'))),
                    totalNumberOfMeals > 0 && React.createElement("div", { className: theme.count },
                        "x",
                        totalNumberOfMeals)))),
        React.createElement(Modal, { open: isOpen && mode !== MealViewMode.confirmed, maxWidth: "sm", fullScreen: isMobile, scroll: isMobile ? 'paper' : 'body', slideUp: isMobile, onClose: closeModal, disableAutoFocus: true, classes: {
                scrollPaper: theme.popup__scrollPaper,
                root: theme.popup__root,
                paper: theme.popup
            }, closeButtonRenderer: onClick => (React.createElement("div", { className: theme.popup__close },
                React.createElement(CloseIcon, { onClick: onClick }))) },
            React.createElement(OverrideComponent, { componentProps: {
                    onAddForAll: onAddForAll,
                    marker: marker,
                    meal: meal,
                    oldPrice: oldPrice,
                    counters: counters,
                    onDecrease: onDecrease,
                    onIncrease: onIncrease,
                    onClear: onClearHandler,
                    readonly: readonly,
                    isLoading: isLoading,
                    isCountChanged: isCountChanged,
                    onSubmit: onConfirmHandler
                }, component: { MealDetails: Details } }))));
};
export default Meal;
