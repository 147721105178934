import * as React from 'react';
import { useContext } from 'react';
import PriceBlock from './PriceBlock/PriceBlock';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { BaggageContext } from '../../../Baggage/context';
import { BaseButton } from '../../../index';
const BaggageTotalPriceBlock = ({ min, total, onClickButton }) => {
    var _a;
    const { BaggageTotalPriceBlock: css } = useTheme('BaggageTotalPriceBlock');
    const { t } = useTranslation('AdditionalBaggage');
    const isReadOnly = (_a = useContext(BaggageContext)) === null || _a === void 0 ? void 0 : _a.isReadOnly;
    const edit = (total === null || total === void 0 ? void 0 : total.amount) !== undefined;
    return (React.createElement("div", { className: css.root },
        React.createElement(PriceBlock, { className: css.price, total: total, min: min }),
        React.createElement(BaseButton, { className: cn(css.control, {
                [css.control__edit]: edit && !isReadOnly
            }), onClick: onClickButton, variant: edit ? 'optionEdit' : 'optionAction' }, isReadOnly ? t('Checkout:More details') : t(edit ? 'Edit' : 'Add'))));
};
export default BaggageTotalPriceBlock;
