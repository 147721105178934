import { __rest } from "tslib";
import * as React from 'react';
import { Checkbox as MUICheckbox } from '@material-ui/core';
import cn from 'classnames';
import { useTheme } from '../../../theme';
const Checkbox = React.forwardRef((_a, ref) => {
    var { className, required } = _a, restProps = __rest(_a, ["className", "required"]);
    const { Checkbox: css } = useTheme('BaseComponents');
    return (React.createElement(MUICheckbox, Object.assign({ ref: ref, classes: {
            root: cn(css.checkbox, className, { [css.required]: required }),
            checked: css.checked,
            disabled: css.disabled,
            indeterminate: css.indeterminate
        } }, restProps)));
});
export default Checkbox;
