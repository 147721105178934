import { applyMiddleware, createStore as originalCreateStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import sagaMiddlewareFactory from 'redux-saga';
import { upgradeModalReducer } from './reducers';
import { detectDifferentForReturnFlight } from '../utils';
import memes from '../../../memes';
import rootSaga from './sagas';
import { useConfig } from '../../../context';
export const createStore = memes((services, segments, passengers, selectedServices, maxBaggageCount, maxBaggagePerPassengerCount, baggageTab, disabled = false, upgradableBaggage = []) => {
    const saga = sagaMiddlewareFactory({
        context: {
            config: useConfig(),
            upgradableBaggage
        }
    });
    const middleware = [saga];
    if (process.env.NODE_ENV === 'development') {
        middleware.push(require('redux-logger').default);
    }
    const store = originalCreateStore(enableBatching(upgradeModalReducer), {
        services,
        selectedServices: selectedServices,
        differentForReturnFlight: detectDifferentForReturnFlight(segments, selectedServices),
        disabled,
        maxBaggagePerPassengerCount,
        maxBaggageCount,
        baggageTab,
        passengers,
        segmentIds: segments.map(segment => segment.id)
    }, applyMiddleware(...middleware));
    saga.run(rootSaga);
    return store;
});
