export const segmentGroupToResultsLeg = (checkoutFlight, group) => {
    return {
        segments: group.segments.map(segment => {
            return checkoutFlight.segments.find(flightSegment => flightSegment.segment.id === segment.id);
        }),
        pricesForFareGroups: [
            {
                fareFamily: Object.assign({}, group.fareFamily),
                prices: [
                    {
                        originalPrice: group.price,
                        price: group.price,
                        priceInMiles: group.priceInMiles,
                        flight: {
                            fares: [],
                            canBePaidByMiles: false,
                            id: checkoutFlight.id,
                            subsidized: false
                        }
                    }
                ]
            }
        ]
    };
};
export const segmentGroupToSelectedFareAdapter = (flightId, group) => {
    var _a, _b;
    return [
        {
            fareGroupId: ((_a = group.fareGroup) === null || _a === void 0 ? void 0 : _a.id) || ((_b = group.fareFamily) === null || _b === void 0 ? void 0 : _b.id),
            prices: [
                {
                    flight: {
                        fares: [],
                        id: flightId
                    },
                    priceInMiles: group.priceInMiles,
                    price: group.price
                }
            ]
        }
    ];
};
export const checkoutSegmentGroupToFlightPropsAdapter = (orderId, flight, group, confirmed, canceled) => {
    // console.log('checkoutSegmentGroupToFlightPropsAdapter-------------', orderId, flight, group, confirmed, canceled);
    return {
        orderId,
        isSelected: true,
        flight: segmentGroupToResultsLeg(flight, group),
        // price:flight.seatMap.additionalServices.gdsServices.services,
        selectedFares: segmentGroupToSelectedFareAdapter(flight.id, group),
        canceled: canceled,
        paid: confirmed,
        isPreOpenAvailable: false,
        isPreOpen: false,
        isReturnFlight: group.groupId === '1'
    };
};
