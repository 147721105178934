import * as React from 'react';
import { BaseButton } from '../../../../index';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
const Controls = props => {
    const { t } = useTranslation('SeatMap');
    const theme = useTheme('SeatMap').Controls;
    return (React.createElement("div", { className: theme.controls },
        props.segmentId > 0 && props.onBack && (React.createElement(BaseButton, { variant: "action", className: cn(theme.button, theme.back), onClick: props.onBack }, t('Back'))),
        props.segmentId + 1 !== props.segmentsCount ? (React.createElement(BaseButton, { variant: "action", className: theme.button, onClick: props.onSelectNextFlight }, t('Next flight'))) : (React.createElement(BaseButton, { variant: "action", className: theme.button, onClick: props.onProceed, "data-next-button": true }, t('Continue')))));
};
export default Controls;
