import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { initI18n } from '../../../utils';
import { BaseButton } from '../../../BaseComponents';
import { ArrowBack } from '../../../Icons';
initI18n('MobileToolbar');
const FooterMobile = ({ onBack, onContinue, backButtonText, continueButtonText, disabled, className, classes, left, right }) => {
    const { FooterMobile: css } = useTheme('FooterMobile');
    const { t } = useTranslation('MobileToolbar');
    return (React.createElement("div", { className: cn(css.wrapper, className, classes === null || classes === void 0 ? void 0 : classes.wrapper) },
        (left !== null && left !== void 0 ? left : !!onBack) ? (React.createElement(BaseButton, { variant: "smallSecondary", className: cn(css.button, css.button_back, classes === null || classes === void 0 ? void 0 : classes.backButton, {
                [css.button_empty]: !backButtonText
            }), onClick: onBack }, !!backButtonText ? backButtonText : ArrowBack)) : null,
        (right !== null && right !== void 0 ? right : typeof onContinue !== 'undefined') ? (React.createElement(BaseButton, { type: onContinue ? 'button' : 'submit', variant: "smallPrimary", className: cn(css.button, css.button_continue, classes === null || classes === void 0 ? void 0 : classes.continueButton), disabled: disabled, onClick: onContinue, "data-next-button": true }, !!continueButtonText ? continueButtonText : t('Continue'))) : null));
};
export default FooterMobile;
