import { __awaiter } from "tslib";
import { useCallback, useEffect, useState } from 'react';
import { PaymentType } from '../../../../PaymentForm/types';
import { sessionStorageGet, UTM_MARKS_KEY } from '../../../../cache';
import { PaymentResultStatus } from '../Checkout';
import { PAYMENT_KEY_TO_TYPE_MAP } from './Payment';
import { OrderStatus, usePaymentMethodsQuery, useSetOrderStatusMutation } from '@websky/graphql';
import { useIataCode } from '../../../../hooks';
import { useCompletePayment } from '../../../../PaymentForm/components/hooks';
export var PaymentResult;
(function (PaymentResult) {
    PaymentResult["Success"] = "success";
    PaymentResult["Fail"] = "fail";
})(PaymentResult || (PaymentResult = {}));
const PAYMENT_METHOD_UNAVAILABLE = 'Payment method is not available';
export const useCheckoutPayment = ({ orderId, order, onSuccess, redirectPathname, onPaymentMethodsLoaded, orderPaymentResult }) => {
    const [paymentResult, setPaymentResult] = useState();
    const [testPaymentInProgress, setTestPaymentInProgress] = useState(false);
    const [selectedPaymentPage, setSelectedPaymentPage] = useState(null);
    const selectedPaymentType = selectedPaymentPage === null || selectedPaymentPage === void 0 ? void 0 : selectedPaymentPage.type;
    const showPaymentCardsEvenForSingleMethod = ['2S'].includes(useIataCode());
    const openFrameInNewTab = ['2S'].includes(useIataCode());
    const [requestError, setRequestError] = useState(null);
    const onRequestError = (error) => {
        const graphQLErrors = error.graphQLErrors;
        if (graphQLErrors.length) {
            const paymentError = graphQLErrors.find(error => {
                var _a, _b;
                return ((_a = error.message) === null || _a === void 0 ? void 0 : _a.includes(PAYMENT_METHOD_UNAVAILABLE)) ||
                    ((_b = error.debugMessage) === null || _b === void 0 ? void 0 : _b.includes(PAYMENT_METHOD_UNAVAILABLE));
            });
            if (paymentError) {
                setRequestError(paymentError);
            }
        }
    };
    const onClearError = () => {
        setRequestError(null);
    };
    const baseUrl = window.location.href.replace(window.location.hash, `#${redirectPathname}`);
    const utmMarks = sessionStorageGet(UTM_MARKS_KEY);
    const utmParameters = Object.keys(utmMarks || {}).length ? utmMarks : null;
    const { data, loading } = usePaymentMethodsQuery({
        fetchPolicy: 'no-cache',
        variables: {
            withCard: selectedPaymentType === PaymentType.Card,
            withCredit: selectedPaymentType === PaymentType.Credit,
            withGpay: selectedPaymentType === PaymentType.Gpay,
            withItransfer: selectedPaymentType === PaymentType.Itransfer,
            withKaspi: selectedPaymentType === PaymentType.Kaspi,
            withQiwi: selectedPaymentType === PaymentType.Qiwi,
            withSbol: selectedPaymentType === PaymentType.Sbol,
            withFps: selectedPaymentType === PaymentType.Fps,
            parameters: {
                orderId: orderId,
                backRedirectUrl: `${baseUrl}/successfull`,
                backRedirectUrlOnFail: `${baseUrl}/failed`,
                utm: utmParameters
            }
        },
        skip: orderPaymentResult === PaymentResultStatus.Success,
        onError: onRequestError
    });
    const testPaymentEnabled = process.env.TEST_PAYMENT_ENABLED === 'true';
    const [runTestPayment] = useSetOrderStatusMutation();
    const { completePayment, completePaymentLoading } = useCompletePayment({
        orderId,
        onSuccess: () => setPaymentResult(PaymentResult.Success),
        onFail: () => setPaymentResult(PaymentResult.Fail)
    });
    const onTestPayment = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setTestPaymentInProgress(true);
            const { errors } = yield runTestPayment({
                variables: {
                    orderId: orderId,
                    status: OrderStatus.Confirmed
                }
            });
            if (errors) {
                setPaymentResult(PaymentResult.Fail);
            }
            else {
                setPaymentResult(PaymentResult.Success);
            }
            setTestPaymentInProgress(false);
        }
        catch (error) {
            setPaymentResult(PaymentResult.Fail);
            setTestPaymentInProgress(false);
        }
    });
    const onPaymentComplete = useCallback(() => {
        onSuccess(true);
    }, [onSuccess]);
    const closePaymentResult = () => {
        if (paymentResult === PaymentResult.Success) {
            onPaymentComplete();
        }
        else {
            setPaymentResult(null);
        }
    };
    useEffect(() => {
        if (!loading && (data === null || data === void 0 ? void 0 : data.PaymentMethods)) {
            // select payment page if only one available
            // filter out null values
            const availablePaymentMethods = Object.entries(data === null || data === void 0 ? void 0 : data.PaymentMethods).filter(([name, paymentMethod]) => typeof paymentMethod === 'object' && !!paymentMethod && (name !== 'cash' || testPaymentEnabled));
            if (availablePaymentMethods.length === 1 && !showPaymentCardsEvenForSingleMethod) {
                const availablePaymentMethodKeyValue = availablePaymentMethods[0];
                const paymentType = PAYMENT_KEY_TO_TYPE_MAP.get(availablePaymentMethodKeyValue[0]);
                if (paymentType) {
                    setSelectedPaymentPage({ type: paymentType, content: null, title: '' });
                }
            }
            if (onPaymentMethodsLoaded) {
                if (selectedPaymentPage) {
                    onPaymentMethodsLoaded();
                }
            }
        }
    }, [loading, data]);
    const handleSelectPaymentMethod = useCallback((page) => {
        if (!selectedPaymentPage) {
            setSelectedPaymentPage(page);
        }
    }, [selectedPaymentPage]);
    return {
        // methods
        closePaymentResult,
        onTestPayment,
        setPaymentResult,
        onPaymentComplete,
        onCompletePayment: completePayment,
        onClearError,
        // data
        orderPaymentResult,
        data,
        paymentResult,
        loading,
        completePaymentLoading,
        selectedPaymentPage,
        handleSelectPaymentMethod,
        testPaymentEnabled,
        testPaymentInProgress,
        openFrameInNewTab,
        requestError
    };
};
