import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { Add, Chevron, ProfileIcon } from '../../Icons';
import { TravellerTypes } from '../../Traveller/types';
import { useRenderers } from '../../renderProps';
import { getPassengerNames } from '../../utils';
const UserCard = ({ className, profile, editText, editLink, inline, headerClassName, confirmContacts, type, onClick, showArrow }) => {
    const { UserCard: css } = useTheme('UserCard');
    const { t } = useTranslation('Account');
    const { getCustomUserCardHeaderLabel } = useRenderers();
    const { firstName, lastName, middleName } = getPassengerNames(profile);
    const headerLabel = React.useMemo(() => {
        if (getCustomUserCardHeaderLabel) {
            return getCustomUserCardHeaderLabel(profile, type);
        }
        if (type === TravellerTypes.CompanionEmpty) {
            return t('Add companion');
        }
        if (firstName && lastName) {
            return `${lastName} ${firstName} ${middleName !== null && middleName !== void 0 ? middleName : ''}`;
        }
        return t('Welcome!');
    }, [type, firstName, lastName, middleName]);
    const avatarIcon = React.useMemo(() => {
        if (type === TravellerTypes.CompanionEmpty) {
            return Add;
        }
        if (firstName && lastName) {
            return (React.createElement("span", null,
                lastName.charAt(0).toUpperCase(),
                firstName.charAt(0).toUpperCase()));
        }
        return ProfileIcon;
    }, [type, firstName, lastName]);
    return (React.createElement("div", { className: cn(className, css.wrapper, {
            [css.inline]: inline,
            [css.companion]: type === TravellerTypes.CompanionEmpty || type === TravellerTypes.CompanionFilled,
            [css.empty]: type === TravellerTypes.CompanionEmpty
        }), onClick: onClick ? onClick : () => { } },
        React.createElement("div", { className: cn(css.header, headerClassName) },
            React.createElement("div", { className: css.header_content },
                React.createElement("div", { className: css.avatar }, avatarIcon),
                React.createElement("div", { className: css.info },
                    React.createElement("div", { className: css.name }, headerLabel),
                    confirmContacts && React.createElement("div", { className: css.notification }, t('Please confirm your contacts'))),
                (!!editText || showArrow) && (React.createElement("span", { className: css.edit },
                    editText,
                    " ",
                    showArrow && Chevron))))));
};
export default UserCard;
