import { __awaiter } from "tslib";
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSegments } from '../../../Modules/Checkin/store/order/selectors';
import { useToggleable } from '../../../hooks';
export const useSelectCancelSegments = (onCheckinCancel) => {
    const segments = useSelector(getSegments);
    const { isOpen: isSelectSegmentsModalOpen, open: openSelectSegmentsModal, close: closeSelectSegmentsModal } = useToggleable(false);
    const [selectedSegmentsToCancel, setSelectedSegmentsToCancel] = useState([]);
    const selectSegmentToCancel = useCallback((segment) => {
        const selectedSegmentIndex = selectedSegmentsToCancel.findIndex(selectedSegment => selectedSegment.id === segment.id);
        if (selectedSegmentIndex !== -1) {
            setSelectedSegmentsToCancel(state => {
                const newState = state.slice();
                newState.splice(selectedSegmentIndex, 1);
                return newState;
            });
        }
        else {
            setSelectedSegmentsToCancel(state => state.concat(segment));
        }
    }, [selectedSegmentsToCancel, setSelectedSegmentsToCancel]);
    const handleCancelSegments = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield onCheckinCancel(selectedSegmentsToCancel.map(segment => segment.id));
        closeSelectSegmentsModal();
    }), [selectedSegmentsToCancel, onCheckinCancel]);
    const segmentsWithCancellationAvailable = useMemo(() => {
        return segments.filter(segment => segment.checkinInfo.cancelAvailable);
    }, [segments]);
    const isCancelAvailableForMultipleSegments = useMemo(() => {
        return segments.length > 1;
    }, [segments]);
    return {
        isCancelAvailableForMultipleSegments,
        segmentsWithCancellationAvailable,
        openSelectSegmentsModal,
        closeSelectSegmentsModal,
        isSelectSegmentsModalOpen,
        selectedSegmentsToCancel,
        setSelectedSegmentsToCancel,
        selectSegmentToCancel,
        onConfirm: handleCancelSegments
    };
};
