import { handleAction, handleActions } from 'redux-actions';
import { setStep, nextStep, prevStep, setSteps } from './actions';
import { StepType } from '../../types';
export const initialState = [
    {
        isActive: true,
        type: StepType.Passengers,
        additionalText: null
    },
    {
        isActive: false,
        type: StepType.Reason,
        additionalText: null
    },
    {
        isActive: false,
        type: StepType.AttachFiles,
        additionalText: null
    },
    {
        isActive: false,
        type: StepType.CreateTicket,
        additionalText: null
    },
    {
        isActive: false,
        type: StepType.Ticket,
        additionalText: null
    }
];
const handleSetSteps = handleAction(setSteps, (state, { payload }) => [...payload], initialState);
const handleSetStep = handleAction(setStep, (state, { payload }) => {
    return state.map(step => (Object.assign(Object.assign({}, step), { isActive: step.type === payload.type })));
}, initialState);
const handleNextStep = handleAction(nextStep, state => {
    const currentActiveIndex = state.findIndex(step => step.isActive);
    const nextStepIndex = currentActiveIndex + 1;
    const nextStep = state[nextStepIndex];
    return handleSetStep(state, setStep(nextStep));
}, initialState);
const handlePrevStep = handleAction(prevStep, state => {
    const currentActiveIndex = state.findIndex(step => step.isActive);
    const prevStepIndex = currentActiveIndex - 1;
    const prevStep = state[prevStepIndex];
    return handleSetStep(state, setStep(prevStep));
}, initialState);
export default handleActions({
    [setSteps.toString()]: handleSetSteps,
    [setStep.toString()]: handleSetStep,
    [nextStep.toString()]: handleNextStep,
    [prevStep.toString()]: handlePrevStep
}, initialState);
