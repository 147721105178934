import * as React from 'react';
import Link from '../../../Link';
import { infoIcon } from '../../../Warnings/Icons';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../context';
const Disclaimer = ({ service }) => {
    const css = useTheme('AdditionalServiceBanner').AdditionalServiceBannerStyles;
    const { t } = useTranslation();
    const { global: { companyInfo } } = useConfig();
    let link;
    switch (service) {
        case 'baggage':
            link = companyInfo.baggage && `${companyInfo.baggage}`;
            break;
        case 'meal':
            link = companyInfo.meal && `${companyInfo.meal}`;
            break;
        case 'seat':
            link = companyInfo.seat && `${companyInfo.seat}`;
            break;
    }
    return link ? (React.createElement("div", { className: css.disclaimer },
        React.createElement(Link, { target: "_blank", action: link },
            React.createElement("span", { className: css.disclaimer__icon }, infoIcon),
            service === 'meal' && t('MealService:Terms & conditions'),
            service === 'baggage' && t('BaggagePage:Baggage rules'),
            service === 'seat' && t('SeatMap:Terms & conditions')))) : null;
};
export default Disclaimer;
