import * as React from 'react';
import { Radio } from '@mui/material';
import cn from 'classnames';
import { useTheme } from '../../../theme';
const AeroexpressPassenger = ({ className, label, trips, value, onChange }) => {
    const { AeroexpressPassenger: css } = useTheme('CheckoutAdditionalService');
    const onChangeHandler = (selectedTrip) => (_, checked) => {
        var _a;
        if (checked) {
            const tripByClass = (_a = trips.find(trip => trip.id === selectedTrip.id)) !== null && _a !== void 0 ? _a : null;
            onChange(tripByClass);
        }
        else {
            onChange(null);
        }
    };
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.label }, label),
        React.createElement("div", { className: css.controls }, trips.map((trip, index) => (React.createElement("div", { key: `${trip.id}-${index}`, className: css.control },
            React.createElement(Radio, { value: trip.class, checked: trip.id === (value === null || value === void 0 ? void 0 : value.id), onChange: onChangeHandler(trip) })))))));
};
export default AeroexpressPassenger;
