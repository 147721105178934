import { __rest } from "tslib";
import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import { useSeatAdditionalInfo } from '../../../SeatsInfo/hooks';
import SeatAdditionalInfo from '../../../SeatsInfo/SeatAdditionalInfo/SeatAdditionalInfo';
import SeatPrice from '../../../SeatPrice/SeatPrice';
const SeatTooltip = React.memo((_a) => {
    var { seat } = _a, props = __rest(_a, ["seat"]);
    const theme = useTheme('SeatMap').SeatTooltipStyles;
    const { t } = useTranslation('SeatMap');
    const { restrictions, permissions, additional, withInfo } = useSeatAdditionalInfo(seat);
    const renderTooltip = (props) => {
        var _a, _b;
        return (React.createElement(Tooltip, { PopperProps: { 'data-rfics': seat.rfisc }, classes: {
                tooltip: cn(theme.tooltip, {
                    [theme.tooltip_withInfo]: withInfo,
                    [theme.tooltip_freeSeat]: props.isFreeSeat,
                    [theme.tooltip_recommend]: props.isRecommend
                }),
                popper: theme.popper,
                tooltipPlacementBottom: theme.tooltip_bottom
            }, open: props.isTooltipOpened, onOpen: () => props.onToggle(true), onClose: () => props.onToggle(false), placement: "top", title: React.createElement("div", null,
                React.createElement("div", { className: theme.container },
                    React.createElement("div", { className: theme.number }, props.seat.number),
                    React.createElement("div", { className: theme.details },
                        props.isFreeSeat ? (React.createElement("span", null, t('Free seat'))) : (React.createElement(SeatPrice, { moneyClassName: theme.details__price, money: props.seatPrice })),
                        !props.hideSeatName && ((_a = props.service) === null || _a === void 0 ? void 0 : _a.name) && (React.createElement("span", { className: theme.details__seatName }, (_b = props.service) === null || _b === void 0 ? void 0 : _b.name)))),
                withInfo && (React.createElement(SeatAdditionalInfo, { className: theme.info, restrictions: restrictions, permissions: permissions, additional: additional }))) }, props.children));
    };
    return props.isAvailable && seat.isAvailable && seat.isExistent
        ? renderTooltip(Object.assign({}, Object.assign({ seat }, props)))
        : props.children;
});
export default SeatTooltip;
