import * as React from 'react';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { getProgressLevel } from '../../utils';
import { Money as MoneyComponent } from '../../../Money';
import { createWithThemeDecorator } from '../../../utils';
import { ThemeConsumer } from '../../../theme';
class Legends extends React.Component {
    componentDidMount() {
        if (this.childrenRef) {
            this.legendsWrapperRef.style.height = `${this.childrenRef.offsetHeight}px`;
        }
    }
    renderLine(price, level, className, specialText) {
        const theme = this.props.theme.PriceGraph.LegendsStyles;
        return (React.createElement("div", { className: classnames(theme.line, className), style: {
                bottom: `${level}%`
            } },
            React.createElement("div", { className: theme.label },
                React.createElement("span", { className: theme.label__text }, specialText && React.createElement("span", null, specialText)),
                React.createElement(MoneyComponent, { moneyClassName: theme.money, money: {
                        amount: price.amount,
                        currency: price.currency
                    } }))));
    }
    render() {
        const { maxPrice, minPrice, theme: { PriceGraph: { LegendsStyles: theme } } } = this.props;
        const averagePrice = {
            amount: Math.round(maxPrice.amount / 2),
            currency: maxPrice.currency
        };
        // Show price lines only if the difference between prices is noticeable.
        const averageLineIsVisible = (averagePrice.amount - minPrice.amount) / averagePrice.amount > 0.4;
        const maxLineIsVisible = (maxPrice.amount - minPrice.amount) / maxPrice.amount > 0.2;
        const isMinAndMaxPricesEqual = minPrice.amount === maxPrice.amount;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.legends, ref: ref => (this.legendsWrapperRef = ref) },
                (maxLineIsVisible || isMinAndMaxPricesEqual) &&
                    this.renderLine(this.props.maxPrice, 100, theme.line_max),
                averageLineIsVisible && this.renderLine(averagePrice, 50),
                !isMinAndMaxPricesEqual &&
                    this.renderLine(this.props.minPrice, getProgressLevel(this.props.minPrice.amount, this.props.maxPrice.amount), theme.line_min)),
            this.props.children(ref => (this.childrenRef = ref))));
    }
}
export default createWithThemeDecorator(ThemeConsumer)(withTranslation('PriceGraph')(Legends));
