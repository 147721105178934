import { __awaiter } from "tslib";
import * as React from 'react';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import WeekCalendarComponent from '../../../../../WeekCalendar';
import DummyWeekCalendar from '../DummyWeekCalendar/DummyWeekCalendar';
import { getSegmentsWithOffset, sortSegments } from '../utils';
import { API_DATE_FORMAT } from '../../../../../utils';
import { getDateWithoutTimezone } from '../../../../../Engine/components/FastSearch/utils';
import { useConfig } from '../../../../../context';
import { useMinPricesQuery } from '@websky/graphql';
import { subsHalfCalendarDaysCount } from '../../utils';
const WeekCalendar = ({ searchParameters, legId, startNewSearch, hidePrice, alternativePrices, minDate, allowSelectWithoutPrice }) => {
    var _a, _b;
    // This date will be in the center of the calendar.
    const centralDate = getDateWithoutTimezone(searchParameters.segments[legId].date);
    const [isLoading, setIsLoading] = useState(false);
    const [prices, setPrices] = useState(new Map());
    const calendarDaysCount = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.Engine.calendarDaysCount;
    const daysOffset = Math.floor(calendarDaysCount / 2);
    const [calendarPage, setCalendarPage] = React.useState(0);
    const { refetch } = useMinPricesQuery({
        skip: true,
        context: {
            currency: searchParameters.currency
        }
    });
    const loadMinPrices = (variables) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const { data } = yield refetch(variables);
            if (data && data.FlightsMinPricesInPeriod && data.FlightsMinPricesInPeriod.datesWithLowestPrices.length) {
                setPrices(prevPrices => new Map(prevPrices.set(legId, data.FlightsMinPricesInPeriod.datesWithLowestPrices)));
            }
            else {
                console.log('Error while loading new prices');
            }
            setIsLoading(false);
        }
        catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    });
    React.useEffect(() => {
        if (legId > 0 || calendarPage !== 0) {
            let offset = daysOffset * -1;
            if (calendarPage !== 0) {
                offset += calendarDaysCount * calendarPage;
            }
            const segments = getSegmentsWithOffset(searchParameters.segments.map((segment, segmentIndex) => {
                if (legId !== segmentIndex) {
                    return subsHalfCalendarDaysCount(segment, calendarDaysCount);
                }
                return segment;
            }), legId, offset);
            loadMinPrices({
                params: Object.assign(Object.assign({}, searchParameters), { segments, daysCount: calendarDaysCount, directionId: legId })
            });
        }
        else if (alternativePrices) {
            setPrices(prevPrices => new Map(prevPrices.set(legId, alternativePrices)));
        }
    }, [legId, calendarPage, alternativePrices]);
    React.useEffect(() => {
        setCalendarPage(0);
    }, [legId]);
    const loadNewDates = useCallback((type) => __awaiter(void 0, void 0, void 0, function* () {
        setCalendarPage(oldValue => oldValue + (type === 'increase' ? 1 : -1));
    }), []);
    const onDateClick = useCallback((date) => {
        const segments = [...searchParameters.segments];
        segments[legId] = Object.assign(Object.assign({}, segments[legId]), { date: format(date, API_DATE_FORMAT) });
        startNewSearch(Object.assign(Object.assign({}, searchParameters), { segments: sortSegments(legId, date, segments) }));
    }, [searchParameters, legId]);
    return isLoading || !alternativePrices ? (React.createElement(DummyWeekCalendar, null)) : (React.createElement(WeekCalendarComponent, { prices: (_b = prices.get(legId)) !== null && _b !== void 0 ? _b : [], centralDate: centralDate, onSelect: onDateClick, onRangeChange: loadNewDates, hidePrice: hidePrice, isMilesSearch: searchParameters === null || searchParameters === void 0 ? void 0 : searchParameters.ffpMode, minDate: minDate, allowSelectWithoutPrice: allowSelectWithoutPrice }));
};
export default WeekCalendar;
