import React, { useMemo } from 'react';
import { useTheme } from '../../../../theme';
import { WelcomeIcon } from '../../../Icons';
import { useTranslation } from 'react-i18next';
import { TravellerFieldEnum, useGetAccountInfoQuery } from '@websky/graphql';
import { getUserValue } from '../../../../utils';
const Welcome = () => {
    var _a;
    const { Welcome: css } = useTheme('LoginPage');
    const { t } = useTranslation('LoginPage');
    const currentUser = useGetAccountInfoQuery();
    const name = useMemo(() => {
        var _a;
        let name = null;
        if ((_a = currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) {
            name = getUserValue(currentUser.data.CurrentUser.userProfile, TravellerFieldEnum.FirstName);
        }
        return name;
    }, [(_a = currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser]);
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.title },
            React.createElement("span", { className: css.icon_wrapper }, WelcomeIcon),
            name ? `${t('Welcome back,')} ${name}!` : t('Welcome back!'))));
};
export default Welcome;
