import * as React from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import AirplaneInfo from '../AirplaneInfo/AirplaneInfo';
import { SunSides } from '@websky/graphql';
import { getDurationString } from '../../../Results/utils';
import { initI18n, MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../../utils';
import i18n from 'i18next';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import AircraftPopup from '../../../AircraftPopup';
import Link from '../../../Link';
import { useCallback } from 'react';
import { InfoDetails } from '../../../Icons';
import Amenities from '../../../Amenities';
import AircraftLivery from '../../../AircraftLivery';
initI18n('SeatMap');
const Airplane = ({ aircraft, sunInfo, routeTime, onSeatsDetailsClick, segment, className, isReturnFlight }) => {
    var _a, _b, _c;
    const theme = useTheme('SeatMap').AirplaneStyles;
    const duration = routeTime ? getDurationString(routeTime) : null;
    const isNoneSide = sunInfo === SunSides.NoneSide;
    const { t } = useTranslation('SeatMap');
    const labels = {
        '': '',
        [SunSides.LeftSide]: i18n.t('SeatMap:90% on the left side'),
        [SunSides.RightSide]: i18n.t('SeatMap:90% on the right side')
    };
    const renderButton = useCallback((onClick) => (React.createElement("div", { className: theme.airplane__name },
        React.createElement(Link, { action: onClick }, aircraft.name))), [aircraft]);
    return (React.createElement("div", { className: cn(theme.airplane, className, {
            [theme.airplane_noInfo]: !(aircraft === null || aircraft === void 0 ? void 0 : aircraft.name) && !(aircraft === null || aircraft === void 0 ? void 0 : aircraft.icon)
        }) },
        segment && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: theme.segment },
                segment.segmentInfo.departure.airport.city.name,
                ' — ',
                segment.segmentInfo.arrival.airport.city.name))),
        React.createElement("div", { className: theme.airplane__info },
            React.createElement(AircraftLivery, { aircraft: aircraft, className: theme.airplane__image, isReturnFlight: isReturnFlight }),
            React.createElement("div", { className: theme.airplane__info_wrapper },
                aircraft && (React.createElement(AircraftPopup, { id: aircraft.id, operatingIata: (_b = (_a = segment === null || segment === void 0 ? void 0 : segment.segmentInfo) === null || _a === void 0 ? void 0 : _a.operatingAirline) === null || _b === void 0 ? void 0 : _b.iata, name: aircraft.name, renderButton: renderButton })),
                ((_c = segment === null || segment === void 0 ? void 0 : segment.amenities) === null || _c === void 0 ? void 0 : _c.length) > 0 && (React.createElement(Amenities, { className: theme.airplane__amenities, amenities: segment.amenities })))),
        React.createElement("div", { className: cn(theme.details, {
                [theme.details_one]: isNoneSide
            }) },
            React.createElement(AirplaneInfo, { text: duration, type: "time" }),
            !isNoneSide && React.createElement(AirplaneInfo, { text: labels[sunInfo], type: "sun" })),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement("div", { className: theme.seatsDetails, onClick: onSeatsDetailsClick },
                React.createElement("div", { className: theme.seatsDetails_icon }, InfoDetails),
                t('Seat details')))));
};
export default Airplane;
