import { __awaiter } from "tslib";
import * as React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { LoginType, useAuthenticateMutation, useSendPersonalDetailsMutation } from '@websky/graphql';
import { useTheme } from '../../../../../theme';
import { useConfig } from '../../../../../context';
import { useToggleable } from '../../../../../hooks';
import Fields from '../Fields/Fields';
import Link from '../../../../../Link';
import Switch from '../../../../../PassengerForm/components/components/Switch/Switch';
import { formatError, getCorporateNumber } from '../../../../utils';
import { BaseButton as Button, IconButton } from '../../../../../BaseComponents';
const Login = props => {
    const { t } = useTranslation('Loyalty');
    const css = useTheme('Loyalty').LoyaltyConnect;
    const [updateUserProfile] = useSendPersonalDetailsMutation();
    const [authenticate] = useAuthenticateMutation();
    const { global: { companyInfo: { iataCode } } } = useConfig();
    const isB2 = iataCode === 'B2';
    const { isOpen: isCorporateCard, toggle: onToggleCorporateCard } = useToggleable(false);
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        // [WEBSKY-5248] workaround for B2, adding a prefix for corporate cards
        const loyaltyNumber = (isB2 && isCorporateCard
            ? getCorporateNumber(data['loyaltyNumber'])
            : data['loyaltyNumber']).trim();
        if (props.queryType === 'authenticate') {
            try {
                const res = yield authenticate({
                    variables: {
                        params: {
                            loginType: LoginType.FFP,
                            login: loyaltyNumber,
                            secureCode: data['loyaltyPassword']
                        }
                    }
                });
                if (!!((_a = res.data) === null || _a === void 0 ? void 0 : _a.Authenticate) && !res.errors) {
                    props === null || props === void 0 ? void 0 : props.onLoyaltyCardSaved();
                }
            }
            catch (error) {
                const errorMessage = formatError(error);
                return {
                    loyaltyNumber: t(errorMessage),
                    loyaltyPassword: errorMessage
                };
            }
        }
        else {
            try {
                const res = yield updateUserProfile({
                    variables: {
                        params: {
                            loyaltyCards: [
                                {
                                    airlineCode: iataCode,
                                    cardNumber: loyaltyNumber,
                                    pinCode: data['loyaltyPassword']
                                }
                            ]
                        }
                    }
                });
                if (((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.UpdateUserProfile) && !(res === null || res === void 0 ? void 0 : res.errors)) {
                    props === null || props === void 0 ? void 0 : props.onLoyaltyCardSaved();
                    return undefined;
                }
            }
            catch (error) {
                const errorMessage = formatError(error);
                return {
                    loyaltyNumber: t(errorMessage),
                    loyaltyPassword: errorMessage
                };
            }
        }
        return undefined;
    });
    return (React.createElement("div", { className: css.loyaltyConnect },
        React.createElement("div", { className: css.header }, t('Log in to LightJet Club')),
        React.createElement("div", null,
            React.createElement(Form, { onSubmit: onSubmit }, ({ handleSubmit, submitting, submitSucceeded }) => (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("div", { className: css.group },
                    React.createElement(Fields, null)),
                isB2 && (React.createElement("div", { className: css.group },
                    React.createElement(Switch, { switchPosition: "left", className: css.switch__root, labelClassName: css.switch__label, label: t('Passenger:Corporate card'), isActive: isCorporateCard, onClick: onToggleCorporateCard }))),
                React.createElement("div", { className: css.buttons__wrapper },
                    React.createElement("div", { className: css.actions },
                        props.showRegisterAction && (React.createElement(Link, { action: props.toCreateAccount }, t('Not a member yet? Join for free!'))),
                        React.createElement(Link, { action: props.toResetPassword }, t('Forgot password?'))),
                    submitSucceeded ? (React.createElement(IconButton, { isSuccess: submitSucceeded })) : (React.createElement(Button, { variant: "action", type: "submit", isLoading: submitting }, t('Connect'))))))))));
};
export default Login;
