import { TravellerFieldEnum } from '@websky/graphql';
import { API_DATE_FORMAT, format, getUserValue } from '../utils';
import { parse } from 'date-fns';
export const getAvailableSegments = (segments, passengers, servicesMap) => {
    let availableSegments = [];
    const servicesSegmentIds = Object.keys(servicesMap);
    segments.forEach(segment => {
        if (servicesSegmentIds.includes(segment.id)) {
            let passengersArray = [];
            const passengerIds = Object.keys(servicesMap[segment.id]);
            const direction = [segment.departure.airport.city.name, segment.arrival.airport.city.name].join(' – ');
            passengers.forEach(passenger => {
                if (passengerIds.includes(passenger.id)) {
                    const firstName = getUserValue(passenger, TravellerFieldEnum.FirstName), lastName = getUserValue(passenger, TravellerFieldEnum.LastName);
                    const name = [firstName === null || firstName === void 0 ? void 0 : firstName.toLowerCase(), lastName === null || lastName === void 0 ? void 0 : lastName.toLowerCase()].filter(Boolean).join(' ');
                    passengersArray = [
                        ...passengersArray,
                        {
                            passengerId: passenger.id,
                            segmentId: segment.id,
                            serviceId: servicesMap[segment.id][passenger.id],
                            name
                        }
                    ];
                }
            });
            availableSegments = [
                ...availableSegments,
                {
                    segmentId: segment.id,
                    passengers: passengersArray,
                    direction,
                    departure: segment.departure.airport.city.name,
                    arrival: segment.arrival.airport.city.name,
                    date: format(parse(segment.departure.date, API_DATE_FORMAT, new Date()), 'dd MMM')
                }
            ];
        }
    });
    return availableSegments;
};
export const computeServicesMap = (services, selectedServices) => {
    const servicesMap = {};
    for (const passengerId in selectedServices) {
        for (const serviceId in selectedServices[passengerId]) {
            const includeService = services.some(service => service.id === serviceId);
            if (!includeService) {
                continue;
            }
            selectedServices[passengerId][serviceId].forEach(selectedService => {
                selectedService.segments.forEach(segmentId => {
                    servicesMap[segmentId] = Object.assign(Object.assign({}, servicesMap[segmentId]), { [passengerId]: serviceId });
                });
            });
        }
    }
    services.forEach(service => {
        const segmentIds = new Set(service.allowedSegments.reduce((acc, ids) => [...acc, ...ids], []));
        const passengerIds = new Set(service.allowedPassengers.reduce((acc, ids) => [...acc, ...ids], []));
        segmentIds.forEach(segmentId => {
            passengerIds.forEach(passengerId => {
                var _a;
                const existServiceId = (_a = servicesMap[segmentId]) === null || _a === void 0 ? void 0 : _a[passengerId];
                if (!existServiceId || existServiceId !== service.id) {
                    servicesMap[segmentId] = Object.assign(Object.assign({}, servicesMap[segmentId]), { [passengerId]: service.id });
                }
            });
        });
    });
    return servicesMap;
};
export const getNewSelectedServices = (serviceMap, selectedServicesState, allSegments) => {
    const selectedServices = [];
    const selectedSegments = Object.keys(selectedServicesState);
    const availableSegments = Object.keys(serviceMap);
    if (allSegments) {
        const firstSegment = selectedSegments[0];
        const selectedPassengers = (selectedServicesState === null || selectedServicesState === void 0 ? void 0 : selectedServicesState[firstSegment])
            ? Object.keys(selectedServicesState[firstSegment])
            : [];
        selectedPassengers.forEach(passenger => {
            availableSegments.forEach(segmentId => {
                selectedServices.push({
                    count: selectedServicesState[firstSegment][passenger],
                    serviceId: serviceMap[firstSegment][passenger],
                    segmentIds: [segmentId],
                    passengerId: passenger
                });
            });
        });
    }
    else {
        availableSegments.forEach(segment => {
            const passengers = Object.keys(serviceMap[segment]);
            passengers.forEach(passenger => {
                var _a;
                if ((_a = selectedServicesState[segment]) === null || _a === void 0 ? void 0 : _a.hasOwnProperty(passenger)) {
                    selectedServices.push({
                        serviceId: serviceMap[segment][passenger],
                        segmentIds: [segment],
                        count: selectedServicesState[segment][passenger],
                        passengerId: passenger
                    });
                }
            });
        });
    }
    return selectedServices;
};
export const getIsDifferentServicesOrPassengers = (servicesMap) => {
    var _a, _b, _c;
    const serviceIdsBySegmentMap = new Map();
    const passengerIdsBySegmentMap = new Map();
    if (((_a = Object.keys(servicesMap !== null && servicesMap !== void 0 ? servicesMap : {})) === null || _a === void 0 ? void 0 : _a.length) > 1) {
        for (const segmentId in servicesMap) {
            for (const passengerId in servicesMap[segmentId]) {
                const serviceId = servicesMap[segmentId][passengerId];
                const serviceIds = [...((_b = serviceIdsBySegmentMap.get(segmentId)) !== null && _b !== void 0 ? _b : [])];
                const passengerIds = [...((_c = passengerIdsBySegmentMap.get(segmentId)) !== null && _c !== void 0 ? _c : [])];
                serviceIdsBySegmentMap.set(segmentId, new Set([...serviceIds, serviceId]));
                passengerIdsBySegmentMap.set(segmentId, new Set([...passengerIds, passengerId]));
            }
        }
        const serviceIdsBySegment = [...serviceIdsBySegmentMap.values()];
        const isDifferentServices = serviceIdsBySegment.some(serviceIds => JSON.stringify([...serviceIds]) !== JSON.stringify([...serviceIdsBySegment[0]]));
        if (isDifferentServices) {
            return true;
        }
        const passengerIdsBySegment = [...passengerIdsBySegmentMap.values()];
        const isDifferentPassengers = passengerIdsBySegment.some(passengerIds => JSON.stringify([...passengerIds]) !== JSON.stringify([...passengerIdsBySegment[0]]));
        if (isDifferentPassengers) {
            return true;
        }
    }
    return false;
};
export const getVipServiceStateKeys = (state) => {
    const keys = new Set();
    for (const segmentId in state) {
        for (const passengerId in state[segmentId]) {
            const serviceCount = state[segmentId][passengerId];
            keys.add(`${segmentId}-${passengerId}-${serviceCount}`);
        }
    }
    return keys;
};
