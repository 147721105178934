import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Collapse } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { CheckoutSteps } from '../types';
import { ThemeProvider, useTheme } from '../../theme';
import { useCartPrices } from '../../Cart/hooks';
import { useToggleable } from '../../hooks';
import { useMobileStepbar } from '../hooks';
import Cart from '../../Cart/Cart';
import StepbarHeader from './StepbarHeader/StepbarHeader';
import StepbarServiceSummary from './StepbarServiceSummary/StepbarServiceSummary';
import Dropdown from '../../Dropdown';
import { stepIcons } from '../../Stepbar/Icons';
const MobileStepbar = ({ alwaysOpen = false, order, currentStep, stepbarConfig, hideFlightPrice, onServiceDelete }) => {
    const { MobileStepbarStyles: css } = useTheme('MobileStepbar');
    const location = useLocation();
    const { isOpen, toggle, close } = useToggleable(alwaysOpen);
    const cartPrices = useCartPrices();
    const { cartServiceProps, cartServices, availableSteps, additionalSteps, isBookedOrConfirmed, isCheckoutOrder } = useMobileStepbar(order, stepbarConfig);
    useEffect(() => {
        close();
    }, [location.pathname]);
    const onClickStep = useCallback((onClick) => () => {
        close();
        onClick();
    }, [isOpen]);
    const renderService = useCallback((type, group, content) => {
        var _a, _b;
        let actualType = type;
        let serviceSummary = group;
        let serviceClick;
        const isExtras = actualType === CheckoutSteps.Extras;
        // hide extras for checkout order
        if (isExtras && isCheckoutOrder && !content) {
            return null;
        }
        const isPassengers = actualType === CheckoutSteps.Passengers;
        const isOrderBookedOrConfirmedStep = isPassengers && isCheckoutOrder && isBookedOrConfirmed;
        // after booking, the Passenger step essentially becomes the Order step, so we replace the type and information
        if (isOrderBookedOrConfirmedStep && !additionalSteps.includes(CheckoutSteps.Order)) {
            actualType = CheckoutSteps.Order;
            serviceSummary = Object.assign(Object.assign({}, serviceSummary), { header: (_b = (_a = stepbarConfig[CheckoutSteps.Order]) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : serviceSummary.header, icon: stepIcons[CheckoutSteps.Order] });
        }
        const stepIndex = availableSteps.findIndex(step => step.type === actualType);
        const stepByType = availableSteps[stepIndex];
        if (stepByType === null || stepByType === void 0 ? void 0 : stepByType.onClick) {
            serviceClick = onClickStep(stepByType.onClick);
        }
        if (isOrderBookedOrConfirmedStep && serviceClick) {
            serviceClick = null;
        }
        const isPassed = stepIndex < currentStep;
        const isActive = stepIndex === currentStep;
        const isSelected = isPassengers ? (isPassed || isActive) && !!content : !!content;
        return (React.createElement(StepbarServiceSummary, { type: actualType, group: serviceSummary, isPassed: isPassed, isActive: isActive, isSelected: isSelected, onClick: serviceClick }, content));
    }, [currentStep, availableSteps, additionalSteps, isBookedOrConfirmed, isCheckoutOrder]);
    const renderServices = useCallback(() => {
        if (additionalSteps.length) {
            return additionalSteps.map(additionalStep => {
                const stepIndex = availableSteps.findIndex(step => step.type === additionalStep);
                const stepByType = availableSteps[stepIndex];
                return (React.createElement(StepbarServiceSummary, { key: additionalStep, type: additionalStep, group: {
                        header: stepByType.label,
                        icon: stepIcons[additionalStep]
                    }, isPassed: stepIndex < currentStep, isActive: stepIndex === currentStep, isSelected: false }));
            });
        }
        return null;
    }, [currentStep, availableSteps, additionalSteps]);
    return (React.createElement(Dropdown, { isOpen: true, onClose: close },
        React.createElement("div", { className: css.stepbar },
            React.createElement(StepbarHeader, { currentStep: currentStep, steps: availableSteps, totalPrice: cartPrices.total, isOpen: isOpen, onToggle: !alwaysOpen ? toggle : null }),
            React.createElement(Collapse, { in: isOpen },
                React.createElement(ThemeProvider, { value: {
                        Cart: {
                            Cart: { wrapper: css.stepbar__cartWrapper }
                        }
                    } },
                    React.createElement(Cart, Object.assign({}, cartServiceProps, { prices: cartPrices, cartServices: cartServices, renderService: renderService, renderServices: renderServices, onServiceDelete: onServiceDelete, hideFlightPrice: hideFlightPrice, showPassengers: true, hideToolbar: true })))))));
};
export default MobileStepbar;
