import * as React from 'react';
import cn from 'classnames';
import { Chevron, CloseClear } from '../../../../../Icons';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../../Tooltip';
import SeatPrice from '../../../SeatPrice/SeatPrice';
const DesktopPassenger = ({ className, activeClassName, passengerName, passengerAvatarText, seatNumbers, price, onClick, isActive, isDisabled, onClearSeat, recommend, changeable, isFreeSeat, showSelect = true, tooltipTitle }) => {
    const theme = useTheme('SeatMap').Passenger;
    const { t } = useTranslation('SeatMap');
    const passenger = (React.createElement("div", { className: cn(className, theme.item, {
            [theme.item_active]: isActive,
            [theme.item_recommend]: recommend,
            [theme.item_disabled]: isDisabled,
            [theme.item_withSeat]: !!(seatNumbers === null || seatNumbers === void 0 ? void 0 : seatNumbers.length),
            [activeClassName]: isActive
        }), onClick: onClick },
        React.createElement("div", { className: theme.main },
            React.createElement("div", { className: theme.avatar }, passengerAvatarText),
            React.createElement("div", { className: theme.passengerName }, passengerName)),
        !isDisabled && (React.createElement(React.Fragment, null, seatNumbers.length > 0 ? (React.createElement("div", { className: theme.payload },
            React.createElement("div", { className: theme.numbers }, seatNumbers.join(', ')),
            isFreeSeat ? (React.createElement("span", { className: theme.freeSeat__text }, t('Free seat'))) : (price && (React.createElement(SeatPrice, { className: theme.seatPrice, moneyClassName: theme.money, money: price }))),
            recommend ? (React.createElement("div", null, Chevron)) : (changeable && (React.createElement("div", { className: theme.clear, onClick: onClearSeat }, CloseClear))))) : (showSelect && React.createElement("div", { className: cn(theme.payload, theme.select) }, t('Select')))))));
    if (tooltipTitle) {
        return React.createElement(Tooltip, { title: tooltipTitle }, passenger);
    }
    return passenger;
};
export default DesktopPassenger;
