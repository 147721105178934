import { isCheckoutOrderGuard } from '../typeguards';
import { TransportLegType } from '../analytics';
import { DataLayerEvent, ItemCategory } from './types';
import { BaggageType, OrderAdditionalServiceGdsServiceServiceType, RouteType } from '@websky/graphql';
export const createEvent = (eventName, params, ecommerce) => {
    let event = initEvent(eventName);
    event = setParamsToEvent(event, params);
    return setEcommerceItems(event, ecommerce);
};
export const initEvent = (eventName) => {
    if (!!eventName) {
        return {
            event: eventName,
            ecommerce: null
        };
    }
    return {
        ecommerce: null
    };
};
export const setParamsToEvent = (dataLayer, params) => {
    if (params) {
        return Object.assign(Object.assign({}, dataLayer), params);
    }
    return dataLayer;
};
export const setEcommerceItems = (dataLayer, ecommerce) => {
    if (ecommerce) {
        return Object.assign(Object.assign({}, dataLayer), { ecommerce: Object.assign({}, ecommerce) });
    }
    return dataLayer;
};
export const getServiceCategory = (serviceType, baggageType) => {
    if (serviceType === OrderAdditionalServiceGdsServiceServiceType.Baggage &&
        (baggageType === BaggageType.PetInHandCarrier ||
            baggageType === BaggageType.PetInHold ||
            baggageType === BaggageType.PetInCabin)) {
        return ItemCategory.Pets;
    }
    switch (serviceType) {
        case OrderAdditionalServiceGdsServiceServiceType.Meal:
            return ItemCategory.Meal;
        case OrderAdditionalServiceGdsServiceServiceType.Baggage:
            return ItemCategory.Baggage;
        case OrderAdditionalServiceGdsServiceServiceType.Seat:
            return ItemCategory.Seat;
        case OrderAdditionalServiceGdsServiceServiceType.BusinessLounge:
            return ItemCategory.Business;
        default:
            return null;
    }
};
export const getBaggageDatalayerName = (service) => {
    var _a, _b;
    const splittedWeight = (_b = (_a = service.baggageWeight) === null || _a === void 0 ? void 0 : _a.split(' ')) !== null && _b !== void 0 ? _b : [];
    const [amount, measurement] = splittedWeight.length === 2 ? splittedWeight : [null, null];
    return `${service.name}${amount ? `-${amount}${measurement}` : ''}`;
};
export const animalBaggageTypes = [BaggageType.PetInCabin, BaggageType.PetInHandCarrier, BaggageType.PetInHold];
export const getTravellersTypes = (travellers) => {
    const types = {};
    travellers === null || travellers === void 0 ? void 0 : travellers.forEach(traveller => {
        if (traveller.type) {
            if (types[traveller.type]) {
                types[traveller.type]++;
            }
            else {
                types[traveller.type] = 1;
            }
        }
    });
    return Object.entries(types).map(([type, count]) => `${count === 1 ? '' : count}${type}`);
};
const getFunnelDateEnd = (flight, routeType) => {
    if (routeType === RouteType.OW) {
        return null;
    }
    return flight.segmentGroups[flight.segmentGroups.length - 1].segments[0].departure.date;
};
export const getFunnelParameters = (order) => {
    var _a;
    const isCheckoutOrder = isCheckoutOrderGuard(order);
    const segments = isCheckoutOrder ? order.flight.segments : order.segments;
    const segment = isCheckoutOrder ? order.flight.segments[0].segment : order.segments[0];
    const from = segment.departure;
    const to = segment.arrival;
    const transportLeg = segments.length > 1 ? TransportLegType.Transfer : TransportLegType.Direct;
    const flightNumber = `${(_a = segment.operatingAirline) === null || _a === void 0 ? void 0 : _a.iata}-${segment.flightNumber}`;
    const routeType = !isCheckoutOrder ? null : order.flight.segmentGroups.length > 1 ? RouteType.RT : RouteType.OW;
    return {
        airport_from: from.airport.iata,
        airport_to: to.airport.iata,
        date_start: from.date,
        date_end: isCheckoutOrder ? getFunnelDateEnd(order.flight, routeType) : null,
        passenger_type: getTravellersTypes(order.travellers),
        route_type: routeType,
        flight_number: flightNumber,
        avia_operator: window.location.hostname,
        transport_leg: transportLeg,
        payment_type: null,
        auth_type: null,
        register_type: null,
        search_id: null
    };
};
export const getAiPurchasePopupGoal = (serviceType, action) => {
    const serviceTypeGoal = serviceType ? `_${getServiceCategory(serviceType)}` : '';
    const actionGoal = action ? `_${action}` : '';
    return `${DataLayerEvent.PurchaseShowPopup}${serviceTypeGoal}${actionGoal}`;
};
export const getQueryPopupGoal = (serviceType, subType, insuranceCode) => {
    return [DataLayerEvent.QueryShowPopup, serviceType, subType !== null && subType !== void 0 ? subType : insuranceCode]
        .filter(Boolean)
        .map(v => v.trim().toLowerCase())
        .join('_');
};
