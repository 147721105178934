import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useForm } from 'react-final-form';
import { toggleMultiCity } from '../../../../store/segments/actions';
import { isMultiCity } from '../../../../store/segments/selectors';
import { useTheme } from '../../../../../theme';
const MultiCity = memo(props => {
    const { t } = useTranslation('SearchForm');
    const { MultiCity: css } = useTheme('SearchForm');
    const onMultiCityChange = () => props.toggleMultiCity();
    const form = useForm();
    const onChange = () => {
        onMultiCityChange();
        form.reset();
    };
    return (React.createElement("div", { className: css.multiCity },
        React.createElement("label", { className: css.label },
            React.createElement("input", { className: css.input, type: "checkbox", onChange: onChange, checked: props.isMultiCity }),
            React.createElement("span", { className: css.caption }, t(props.isMultiCity ? 'Set simple route' : 'Set multi city route')))));
});
const mapStateToProps = (state) => {
    return {
        isMultiCity: isMultiCity(state)
    };
};
const mapDispatchToProps = {
    toggleMultiCity
};
export default connect(mapStateToProps, mapDispatchToProps)(MultiCity);
