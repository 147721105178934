import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoginForm from '../LoginForm/LoginForm';
import LoginTypeSelectorForm from '../LoginTypeSelectorForm/LoginTypeSelectorForm';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import CodeForm from '../CodeForm/CodeForm';
import Welcome from '../Welcome/Welcome';
import WarningModal from '../../../../WarningModal';
import { Steps } from '../../../types';
import { LoginType } from '@websky/graphql';
const BaseComponent = ({ login: initialLogin, onSuccess }) => {
    const { t } = useTranslation('LoginPage');
    const firstLogin = useRef(null);
    const [loginData, setLoginData] = useState({ login: initialLogin !== null && initialLogin !== void 0 ? initialLogin : '', loginType: undefined });
    const [step, setStep] = useState(Steps.LogIn);
    const [error, setError] = useState(null);
    const [stayLoggedIn, setStayLogged] = useState(false);
    const onLogIn = useCallback((data) => {
        setLoginData(state => {
            var _a;
            return ({
                login: data.login,
                loginType: data.type,
                email: (_a = state.email) !== null && _a !== void 0 ? _a : undefined
            });
        });
        firstLogin.current = data.needRegistration;
        if (data.type === 'FFP') {
            setStep(Steps.EnterCode);
        }
        else {
            if (data.needRegistration) {
                setStep(Steps.Registration);
            }
            else {
                setStep(Steps.EnterCode);
            }
        }
    }, []);
    const onRegistration = useCallback(() => {
        setStep(Steps.EnterCode);
    }, [loginData]);
    const onLoginFromEmailOrFfp = useCallback((email, code) => {
        setLoginData(state => (Object.assign(Object.assign({}, state), { email, code })));
        setStep(Steps.SelectEmailOrFFP);
    }, []);
    const onVerified = useCallback(() => {
        setStep(Steps.Welcome);
        setTimeout(() => {
            if (onSuccess) {
                onSuccess(firstLogin.current);
            }
            else {
                window.location.reload();
            }
        }, 1500);
    }, [onSuccess]);
    const clearError = () => {
        setError(null);
        if (step === Steps.Registration) {
            setStep(Steps.LogIn);
        }
    };
    const renderForm = () => {
        switch (step) {
            case Steps.LogIn:
                return (React.createElement(LoginForm, { setStayLoggedIn: setStayLogged, stayLoggedIn: stayLoggedIn, initialData: { login: loginData.login }, onSuccess: onLogIn, onError: setError }));
            case Steps.SelectEmailOrFFP:
                return (React.createElement(LoginTypeSelectorForm, { loginTypes: [
                        { type: LoginType.Email, value: loginData.email },
                        { type: LoginType.FFP, value: loginData.login }
                    ], login: loginData.login, code: loginData.code, stayLoggedIn: stayLoggedIn, onSuccess: onVerified, setLoginData: setLoginData, setStep: setStep }));
            case Steps.Registration:
                return (React.createElement(RegistrationForm, { login: loginData.login, loginType: loginData.loginType, onSuccess: onRegistration, onError: setError }));
            case Steps.EnterCode:
                return (React.createElement(CodeForm, { login: loginData.login, loginType: loginData.loginType, stayLoggedIn: stayLoggedIn, onLoginFromEmailOrFfp: onLoginFromEmailOrFfp, onSuccess: onVerified, setStep: setStep }));
            case Steps.Welcome:
                return React.createElement(Welcome, null);
            default:
                return null;
        }
    };
    return (React.createElement(React.Fragment, null,
        renderForm(),
        React.createElement(WarningModal, { isOpen: !!error, onClose: clearError, title: t(error), content: t('Unfortunately, something went wrong. Please try again or contact with us') })));
};
export default BaseComponent;
