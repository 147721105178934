import { ADD_SERVICE, REMOVE_SERVICE, ADD_SERVICE_NS, REMOVE_SERVICE_NS, DELETE_ALL_TYPES_ON_SEGMENT, DELETE_ALL_TYPES_ON_SEGMENT_NS, SET_ALL_SELECTED_SERVICES, SET_MULTIPLE_SELECTED_SERVICES, SET_MULTIPLE_SELECTED_SERVICES_NS, SET_SERVICE } from './actions';
import { getRelatedSegments } from '../../../Modules/Checkin/utils';
import { setsAreEqual } from '../../../utils';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { useSelector } from 'react-redux';
export const getNewCount = (currentCount, countToDelete, confirmedCount) => {
    const result = currentCount - countToDelete;
    return confirmedCount > result ? confirmedCount : result;
};
const getConfirmedCount = (servicePayload) => {
    if (servicePayload.type === OrderAdditionalServiceGdsServiceServiceType.Seat) {
        return servicePayload.isConfirmed ? 1 : 0;
    }
    else {
        return servicePayload.confirmedCount
            ? servicePayload.confirmedCount
            : 0;
    }
};
const pushServiceToState = (passengerState, service) => {
    const segmentIds = service.allowedSegments
        ? getRelatedSegments(service.allowedSegments, service.segmentId)
        : new Set([service.segmentId]);
    return Object.assign(Object.assign({}, passengerState), { [service.serviceId]: [
            ...(passengerState && passengerState[service.serviceId] ? passengerState[service.serviceId] : []),
            {
                segments: [...segmentIds],
                servicePayload: service.service,
                number: service.count >= 0 ? service.count : 1
            }
        ] });
};
const setServicesByPassengers = (state, services) => {
    let newState = Object.assign({}, state);
    services.forEach(service => {
        newState = Object.assign(Object.assign({}, newState), { [service.passengerId]: mergeServicesWithSameId(newState[service.passengerId] || {}, service, true) });
    });
    return newState;
};
export function useGetState() {
    return useSelector(state => state);
}
export const selectedServicesReducer = (state = {}, action) => {
    if (action.type === ADD_SERVICE || action.type === ADD_SERVICE_NS) {
        const payload = action.payload;
        if (isServiceIdExists(state, payload)) {
            return Object.assign(Object.assign({}, state), { [payload.passengerId]: Object.assign({}, mergeServicesWithSameId(state[payload.passengerId], payload)) });
        }
        else {
            return Object.assign(Object.assign({}, state), { [payload.passengerId]: Object.assign({}, pushServiceToState(state[payload.passengerId], payload)) });
        }
    }
    else if (action.type === REMOVE_SERVICE || action.type === REMOVE_SERVICE_NS) {
        const payload = action.payload;
        return Object.assign(Object.assign({}, state), { [payload.passengerId]: Object.assign({}, removeService(state[payload.passengerId], payload)) });
    }
    else if (action.type === SET_SERVICE) {
        const payload = action.payload;
        return Object.assign(Object.assign({}, state), { [payload.passengerId]: Object.assign({}, mergeServicesWithSameId(state[payload.passengerId], payload, true)) });
    }
    else if (action.type === SET_ALL_SELECTED_SERVICES) {
        const payload = action.payload;
        return payload;
    }
    else if (action.type === SET_MULTIPLE_SELECTED_SERVICES || action.type === SET_MULTIPLE_SELECTED_SERVICES_NS) {
        const payload = action.payload;
        return setServicesByPassengers(state, payload);
    }
    else if (action.type === DELETE_ALL_TYPES_ON_SEGMENT || action.type === DELETE_ALL_TYPES_ON_SEGMENT_NS) {
        // Удаляем все услуги с заданным типом у всех пассажиров на заданном сегменте или на всех сегментах
        const payload = action.payload;
        let newState = state;
        for (const passengerId in state) {
            if (state.hasOwnProperty(passengerId)) {
                for (const passengerServiceId in state[passengerId]) {
                    if (state[passengerId].hasOwnProperty(passengerServiceId)) {
                        newState = Object.assign(Object.assign({}, newState), { [passengerId]: Object.assign(Object.assign({}, newState[passengerId]), { [passengerServiceId]: newState[passengerId][passengerServiceId].map(service => {
                                    if (service.servicePayload.id === payload.serviceId) {
                                        if (payload.deleteOnAllSegments) {
                                            return Object.assign(Object.assign({}, service), { number: 0 });
                                        }
                                        if (service.segments.includes(payload.segmentId)) {
                                            return Object.assign(Object.assign({}, service), { number: 0 });
                                        }
                                        return Object.assign({}, service);
                                    }
                                    return service;
                                }) }) });
                    }
                }
            }
        }
        return newState;
    }
    return state;
};
const isServiceIdExists = (state, service) => {
    var _a;
    return !!((_a = state[service.passengerId]) === null || _a === void 0 ? void 0 : _a[service.serviceId]);
};
const mergeServicesWithSameId = (passengerServices, newService, setCountOfServices) => {
    const existServices = passengerServices[newService.serviceId];
    const relatedSegments = newService.allowedSegments
        ? getRelatedSegments(newService.allowedSegments, newService.segmentId)
        : new Set([newService.segmentId]);
    // Находим услугу, у которой такой же набор сегментов.
    const sameServiceIndex = existServices === null || existServices === void 0 ? void 0 : existServices.findIndex(service => {
        return setsAreEqual(new Set(service.segments), relatedSegments);
    });
    // если такая услуга есть, то увеличиваем кол-во на 1
    if (sameServiceIndex >= 0) {
        return Object.assign(Object.assign({}, passengerServices), { [newService.serviceId]: passengerServices[newService.serviceId].map((service, index) => {
                var _a;
                if (index === sameServiceIndex) {
                    return Object.assign(Object.assign({}, service), { number: setCountOfServices
                            ? (_a = newService.count) !== null && _a !== void 0 ? _a : service.number + (newService.count >= 0 ? newService.count : 1)
                            : service.number + (newService.count >= 0 ? newService.count : 1) });
                }
                return service;
            }) });
    }
    else {
        // если нет, то добавляем как новую
        return Object.assign({}, pushServiceToState(passengerServices, newService));
    }
};
const removeService = (passengerState, service) => {
    var _a;
    const existServicesIndex = (_a = passengerState[service.serviceId]) === null || _a === void 0 ? void 0 : _a.findIndex(existService => {
        return existService.segments.includes(service.segmentId);
    });
    if (existServicesIndex >= 0) {
        const existService = passengerState[service.serviceId][existServicesIndex];
        if (existService.number > 0) {
            return Object.assign(Object.assign({}, passengerState), { [service.serviceId]: passengerState[service.serviceId].map((state, index) => {
                    if (index === existServicesIndex) {
                        return Object.assign(Object.assign({}, state), { number: getNewCount(state.number, service.count ? service.count : 1, getConfirmedCount(state.servicePayload)) });
                    }
                    return state;
                }) });
        }
    }
    return passengerState;
};
