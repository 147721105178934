import * as React from 'react';
import RequestComponent from '../Request/Request';
import RequestForm from './RequestForm';
import { useSelector } from 'react-redux';
import { isOpenedExchange } from '../../../store/order/selectors';
import { ExchangeStep } from '../../../types';
import { OverrideComponent } from '../../../../../renderProps';
const Request = ({ successPayment, clearSelectedFares, setExchangeSuccess, setStep, refreshOrder, newFlightId, goToOrder }) => {
    const openedExchange = useSelector(isOpenedExchange);
    const goToPayment = () => {
        setStep(ExchangeStep.Payment);
    };
    const goToNewRequest = () => {
        setStep(ExchangeStep.Passengers);
    };
    const goToFlights = () => {
        clearSelectedFares();
        setStep(ExchangeStep.NewFlights);
    };
    return newFlightId && !successPayment && !openedExchange ? (React.createElement(OverrideComponent, { componentProps: {
            goToPayment,
            goToFlights,
            setExchangeSuccess,
            refreshOrder,
            clearSelectedFares,
            flightId: newFlightId
        }, component: { renderExchangeRequest: RequestComponent } })) : (React.createElement(RequestForm, { goToNewRequest: goToNewRequest, goToPayment: goToPayment, goToOrder: goToOrder }));
};
export default Request;
