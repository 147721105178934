import * as React from 'react';
import RequestForm from '../RequestForm/RequestForm';
import PromoLoader from '../../../PromoLoader';
import { useSteps } from '../Refund/hooks';
import { StepType } from '../../types';
import Price from '../Price/Price';
import Notes from '../Notes/Notes';
import StepsButtons from '../StepsButtons/StepsButtons';
import { useCallback } from 'react';
import { saveCustomer } from '../../store/customer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getRefundSeatReleaseMode, isNasServiceRefundRequired } from '../../store/selectors';
import { ExareSeatReleaseMode } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import cn from 'classnames';
const RequestFormPage = props => {
    const { Refund: css } = useTheme('Refund');
    const { t } = useTranslation('Refund');
    const { isLoading, prices } = props;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const dispatch = useDispatch();
    const { currentStep, goToNextStep, goToPrevStep } = useSteps();
    const seatReleaseMode = useSelector(getRefundSeatReleaseMode);
    const needToRefundNas = useSelector(isNasServiceRefundRequired);
    const handleSaveCustomer = useCallback(({ comments, email, name, phone }) => {
        dispatch(saveCustomer({ comments, name, email, phone }));
        goToNextStep();
    }, [goToNextStep]);
    const isActive = currentStep.type === StepType.CreateTicket;
    const showSeatsWillBeCanceledNotice = seatReleaseMode === ExareSeatReleaseMode.Off;
    const renderNotes = useCallback(() => {
        return React.createElement(Notes, { isAutoMode: showSeatsWillBeCanceledNotice, showReturnMessage: false });
    }, [showSeatsWillBeCanceledNotice]);
    if (isLoading && isActive) {
        return React.createElement(PromoLoader, null);
    }
    return (React.createElement(React.Fragment, null, isActive && (React.createElement(RequestForm, { onSubmit: handleSaveCustomer, isNasServiceRefundRequired: needToRefundNas, renderPrice: !isLoading && prices
            ? () => (React.createElement(Price, { className: cn(css.prices, {
                    [css.wide]: needToRefundNas
                }), price: prices }))
            : null, renderNotes: renderNotes, onBackToOrder: props.onBackToOrder, onNasServicesSuccessfullyRefunded: props.onNasServicesSuccessfullyRefunded, renderButtons: (valid, handleSubmit) => (React.createElement(StepsButtons, { className: css.buttons, position: 'center', backButton: isMobile
                ? null
                : { children: t('Back'), onClick: goToPrevStep, className: css.backButton }, nextButton: {
                children: t(`Refund_${currentStep.type}`),
                onClick: handleSubmit,
                disabled: !valid
            } })) }))));
};
export default RequestFormPage;
