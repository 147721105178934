import { createSelector } from 'reselect';
import { MAX_SEGMENTS_NUMBER, MIN_SEGMENTS_NUMBER } from './reducers';
const getSegmentsState = (state) => state.segments;
export const isMultiCity = createSelector(getSegmentsState, state => state.isMultiCity);
export const getSimpleRouteSegment = createSelector(getSegmentsState, state => state.simpleRouteSegment);
export const getMultiCitySegments = createSelector(getSegmentsState, state => state.multiCitySegments);
export const canAddSegment = createSelector(isMultiCity, getMultiCitySegments, (isMultiCity, segments) => isMultiCity && segments.length < MAX_SEGMENTS_NUMBER);
export const canRemoveSegment = createSelector(isMultiCity, getMultiCitySegments, (isMultiCity, segments) => isMultiCity && segments.length > MIN_SEGMENTS_NUMBER);
export const getSegments = createSelector(isMultiCity, getSimpleRouteSegment, getMultiCitySegments, (isMultiCity, simpleRouteSegments, multiCitySegments) => (isMultiCity ? multiCitySegments : [simpleRouteSegments]));
export const makeGetSegment = (index) => {
    return createSelector(getSegments, segments => segments[index]);
};
export const getSelectedDates = createSelector(isMultiCity, getSimpleRouteSegment, getMultiCitySegments, (isMultiCity, simpleRouteSegments, multiCitySegments) => {
    const result = [];
    if (isMultiCity) {
        multiCitySegments.forEach(segment => {
            if (segment.date) {
                result.push(segment.date);
            }
        });
    }
    else {
        const segment = simpleRouteSegments;
        if (segment.date || segment.dateBack) {
            result.push(segment.date || null);
            result.push(segment.dateBack || null);
        }
    }
    return result;
});
