import * as React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Radio } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { parse } from 'date-fns';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import Datepicker from '../Datepicker/Datepicker';
import { ALL_FLIGHT } from '../../../store/flightsToExchange/reducers';
import { API_DATE_FORMAT } from '../../../../../utils';
import { getNewFlightSearchParams } from '../../../store/selectors';
const Choice = ({ items, selectedKeys, controlType = 'checkbox', onChange }) => {
    const theme = useTheme('Exchange').Choice;
    const searchParameters = useSelector(getNewFlightSearchParams);
    const searchParams = useSelector(getNewFlightSearchParams);
    const { isAllFlight, selectedIds, firstUnselectedItem } = React.useMemo(() => {
        var _a;
        let isAllFlight = false;
        let selectedItems = [];
        selectedKeys.forEach(selectedKey => {
            if (!isAllFlight && selectedKey.key === ALL_FLIGHT) {
                isAllFlight = true;
            }
            else {
                selectedItems = [...selectedItems, selectedKey];
            }
        });
        const selectedIds = selectedKeys.map(item => item.key);
        const firstUnselectedItem = isAllFlight ? (_a = selectedItems.filter(item => !item.date)) === null || _a === void 0 ? void 0 : _a[0] : selectedItems[0];
        return { isAllFlight, selectedIds, firstUnselectedItem };
    }, [selectedKeys]);
    const filterDates = React.useMemo(() => {
        var _a;
        return (_a = items.filter(({ key, date }) => key !== ALL_FLIGHT && date)) === null || _a === void 0 ? void 0 : _a.map(({ date }) => date);
    }, [items]);
    const selectedDates = React.useMemo(() => {
        return searchParams.segments.map(segment => parse(segment.date, API_DATE_FORMAT, new Date()));
    }, [searchParams]);
    const onChangeHandler = React.useCallback((item) => (_, checked) => onChange({ key: item.key }, checked), [onChange]);
    const onChangeDate = React.useCallback((key, checked) => (date) => onChange({ key, date }, checked), [onChange]);
    const ControlComponent = controlType === 'checkbox' ? Checkbox : Radio;
    return (React.createElement("div", { className: theme.container }, items.map((item, index) => {
        var _a, _b, _c;
        const isSelected = selectedIds.includes(item.key);
        const isLastSegment = items.length > 1 && index === items.length - 1;
        const selectedDate = (_a = selectedKeys.find(selectedItem => selectedItem.key === item.key)) === null || _a === void 0 ? void 0 : _a.date;
        let segment = (_b = searchParameters.segments) === null || _b === void 0 ? void 0 : _b[0];
        if (isAllFlight && isSelected) {
            segment = (_c = searchParameters.segments) === null || _c === void 0 ? void 0 : _c[item.key];
        }
        let minDate = new Date();
        if (isLastSegment) {
            if (isAllFlight && selectedDates.length) {
                minDate = selectedDates[0];
            }
            else if (filterDates.length) {
                minDate = filterDates[0];
            }
        }
        return (React.createElement(FormControlLabel, { key: item.key, control: React.createElement(ControlComponent, { name: item.key, classes: {
                    checked: theme.button_checked,
                    root: theme.button
                }, checked: isSelected }), label: React.createElement(React.Fragment, null,
                item.value,
                item.date && (React.createElement(Datepicker, { key: selectedIds.join(''), className: theme.labelDate, segment: segment, date: selectedDate, minDate: minDate, selectedDates: selectedDates, isActive: isSelected && (firstUnselectedItem === null || firstUnselectedItem === void 0 ? void 0 : firstUnselectedItem.key) === item.key, onSelect: onChangeDate(item.key, isSelected) }))), onChange: onChangeHandler(item), checked: isSelected, classes: {
                root: cn(theme.label, {
                    [theme.label_selected]: isSelected
                }),
                label: theme.labelInner
            } }));
    })));
};
export default Choice;
