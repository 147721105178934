import { useCallback, useState } from 'react';
import { PromoCodeStatus } from './types';
export const usePromoCode = (initialState) => {
    const [isFormOpen, setFormOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(initialState !== null && initialState !== void 0 ? initialState : PromoCodeStatus.Empty);
    const setLoading = useCallback(() => {
        setIsLoading(true);
    }, [isLoading]);
    const setNotLoading = useCallback(() => {
        setIsLoading(false);
    }, [isLoading]);
    const openForm = useCallback(() => {
        setFormOpen(true);
    }, [isFormOpen]);
    const closeForm = useCallback(() => {
        setFormOpen(false);
    }, [isFormOpen]);
    return {
        isFormOpen,
        setFormOpen,
        isLoading,
        setIsLoading,
        setLoading,
        setNotLoading,
        openForm,
        closeForm,
        status,
        setStatus
    };
};
