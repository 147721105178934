import * as React from 'react';
import { useTheme } from '../../../theme';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useMemo, useState } from 'react';
import cn from 'classnames';
import { Link, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isActionRoute, isPathRoute } from '../../utils';
import { Badge } from '../../../Badge/Badge';
const MobileMenu = ({ routes, selectedRoute, userInfo }) => {
    const [isOpenedMenu, setOpenedMenu] = useState(false);
    const css = useTheme('Sidebar').MobileMenu;
    const { t } = useTranslation('Account');
    const title = useMemo(() => { var _a; return (_a = routes.find(item => (item === null || item === void 0 ? void 0 : item.pathname) === selectedRoute)) === null || _a === void 0 ? void 0 : _a.title; }, [
        routes,
        selectedRoute
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.wrapper, onClick: () => setOpenedMenu(!isOpenedMenu) },
            React.createElement("span", { className: cn(css.button, {
                    [css.button_opened]: isOpenedMenu
                }) }),
            title && React.createElement("span", { className: css.title }, t(title))),
        React.createElement(Collapse, { in: isOpenedMenu, classes: { wrapperInner: css.content, root: css.root } },
            React.createElement("div", { className: css.user },
                React.createElement("div", { className: css.user__info },
                    React.createElement("span", null,
                        userInfo.firstName,
                        " ",
                        userInfo.lastName))),
            React.createElement(List, { className: 'list' }, routes.map((route, index) => {
                let isSelected = false;
                if (isPathRoute(route)) {
                    const match = matchPath(selectedRoute, {
                        path: route.pathname,
                        exact: !route.hasChild
                    });
                    isSelected = !!match;
                }
                const item = (React.createElement(ListItem, { button: true, selected: isSelected, classes: {
                        root: css.menu__item,
                        selected: css.menu__item_selected
                    } },
                    React.createElement(ListItemIcon, { className: css.icon },
                        React.createElement(SvgIcon, { viewBox: '0 0 20 20' }, route.icon)),
                    React.createElement(ListItemText, { className: css.item__text, primary: t(route.title) }),
                    route.badgeContent > 0 && React.createElement(Badge, { value: route.badgeContent, className: css.badge })));
                if (isActionRoute(route)) {
                    return (React.createElement("div", { key: index, onClick: route.action }, item));
                }
                else if (route.isRedirect) {
                    return (React.createElement("a", { key: index, href: route.pathname, target: "_blank" }, item));
                }
                else {
                    return (React.createElement(Link, { key: index, to: route.pathname, onClick: () => setOpenedMenu(false) }, item));
                }
            })))));
};
export default MobileMenu;
