import * as React from 'react';
import { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import Base from '../..';
import { Money } from '../../../Money';
import Link from '../../../Link';
const Meal = ({ meal, onConfirm, onChange, availableForPassengers, availableForSegments, isMobileFullScreen }) => {
    const [detailsAreOpen, setDetailsAreOpen] = useState(false);
    const { t } = useTranslation('ServiceRecommendation');
    const { Meal: css } = useTheme('ServiceRecommendation');
    const isFree = !meal.price || !meal.price.amount;
    const toggleDetails = (e) => {
        setDetailsAreOpen(!detailsAreOpen);
        e.preventDefault();
    };
    return (React.createElement(Base, { onConfirm: onConfirm, onChange: onChange, isFreeService: isFree, isMobileFullScreen: isMobileFullScreen },
        React.createElement("div", null,
            React.createElement("div", { className: css.image, style: { backgroundImage: `url(${meal.imageURL})` } }),
            React.createElement("div", { className: css.details },
                React.createElement("div", { className: css.details__header },
                    React.createElement("h2", { className: css.title }, meal.name),
                    React.createElement("div", { className: css.price },
                        !isFree ? (React.createElement(Money, { moneyClassName: css.price__money, money: meal.price })) : (React.createElement("span", { className: css.price__money }, t('FREE'))),
                        React.createElement(Link, { action: toggleDetails }, t('Details')))),
                React.createElement(Collapse, { in: detailsAreOpen },
                    React.createElement("div", { className: css.details__body },
                        meal.description,
                        meal.allergens && meal.allergens.length ? (React.createElement("div", { className: css.allergens },
                            React.createElement("div", { className: css.allergens__header }, t('Allergens')),
                            React.createElement("div", null, meal.allergens.join(', ')))) : null))),
            React.createElement("div", { className: css.text },
                t('We offer you this meal'),
                isFree && ` ${t('for FREE')}`,
                ' ',
                availableForSegments ? (React.createElement(React.Fragment, null,
                    t('on flight directions'),
                    " ",
                    React.createElement("em", null, availableForSegments))) : (t('on all flight directions')),
                ",",
                ' ',
                availableForPassengers ? (React.createElement(React.Fragment, null,
                    t('for passengers'),
                    " ",
                    React.createElement("em", null, availableForPassengers))) : (t('for all passengers')),
                "."))));
};
export default Meal;
