import * as React from 'react';
import { format } from '../../../../utils';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import ImageWithFallback from '../../../../ImageWithFallback';
import { OverrideComponent } from '../../../../renderProps';
import { useFlightIconFallbackSrc } from './hooks';
const FlightInfo = ({ segment, singleFlight, nextDay }) => {
    var _a, _b;
    const theme = useTheme('DesktopFlightRoute').SegmentStyles;
    const fallbackSrc = useFlightIconFallbackSrc();
    return (React.createElement("div", { className: cn(theme.flightInfo, {
            [theme.flightInfo__singleFlight]: singleFlight,
            [theme.flightInfo__nextDay]: nextDay
        }) },
        React.createElement("div", { className: theme.flightInfo__container },
            React.createElement("div", { className: cn(theme.flightInfo__icon) },
                React.createElement(OverrideComponent, { componentProps: {
                        src: (_a = segment.operatingAirline) === null || _a === void 0 ? void 0 : _a.icon,
                        fallbackSrc: fallbackSrc,
                        alt: (_b = segment.operatingAirline) === null || _b === void 0 ? void 0 : _b.name
                    }, component: {
                        renderFlightInfoIcon: ImageWithFallback
                    } })),
            React.createElement("div", { className: theme.flightInfo__date }, format(segment.departure.date, 'dd MMM, eeeeee').replace('.', ''))),
        React.createElement("div", { className: theme.flightInfo__airline }, segment.operatingAirline.name)));
};
export default FlightInfo;
