import { applyMiddleware } from 'redux';
import { createStore as originalCreateStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import memes from '../../memes';
import rootReducer from './reducers';
import rootSaga from './sagas';
export const createStore = memes((initialState) => {
    const saga = createSagaMiddleware();
    const middlewares = [saga];
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(require('redux-logger').default);
    }
    const store = originalCreateStore(rootReducer, initialState, applyMiddleware(...middlewares));
    saga.run(rootSaga);
    // @ts-ignore
    return store;
});
