const randomHeaderGenerator = () => {
    const key = 'DIUtseuqer'.split('').reverse().join('');
    // tslint:disable-next-line:binary-expression-operand-order
    const value = 
    // tslint:disable-next-line:binary-expression-operand-order
    2161571 * Math.floor(Math[String.fromCharCode(114, 97, 110, 100, 111, 109)]() * 40345678) + 12345678901234;
    return {
        [key]: value
    };
};
export default randomHeaderGenerator;
