import * as React from 'react';
import { overridedIcons } from '../../../../../../index';
import { Field } from '../../../../../../Passenger/components/Field/Field';
import { useTheme } from '../../../../../../theme';
import { useTranslation } from 'react-i18next';
import Checkbox from './Checkbox/Checkbox';
import { AdditionalTravellerFieldEnum } from '../../../../../../Passenger/types';
const Accompanying = props => {
    var _a;
    const theme = useTheme('PassengerForm').Accompanying;
    const { t } = useTranslation('Passenger');
    if (!((_a = props.accompanyingFields) === null || _a === void 0 ? void 0 : _a.length)) {
        return null;
    }
    return (React.createElement("div", { className: theme.accompanying },
        React.createElement("div", { className: theme.accompanying__header }, t('Booking details of the accompanying passenger')),
        React.createElement("div", { className: theme.accompanying__info },
            overridedIcons['InfoDetails'],
            React.createElement("div", null, t('Fill in the fields with the booking details of the accompanying passenger over 18 years of age. Please note that the flight dates, flight and class of service for both passengers must be the same.'))),
        React.createElement("div", { className: theme.fields }, props.accompanyingFields
            .filter(field => field.type !== AdditionalTravellerFieldEnum.AccompanyingCheck)
            .map((field, index) => (React.createElement(Field, { disabled: field.readonly || props.disabled, key: `${field.name}_${index}`, field: Object.assign(Object.assign({}, field), { name: `customer.${field.name}` }), passengerId: props.passengerId, passengerType: props.passengerType })))),
        React.createElement(Checkbox, { accompanyingFields: props.accompanyingFields })));
};
export default Accompanying;
