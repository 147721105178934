import * as React from 'react';
import { useSelector } from 'react-redux';
import { getNasServices } from '../../store/refundInfo/selectors';
import { getState } from '../../store/aviaOrder/selectors';
import NasRefundServices from '../../../NasRefundServices/NasRefundServices';
const AirportServices = ({ onSuccessfullyRefunded, onRefuse }) => {
    const nasServices = useSelector(getNasServices);
    const order = useSelector(getState);
    return (React.createElement("div", null,
        React.createElement(NasRefundServices, { services: nasServices.services, selectedServices: nasServices.selectedServices, onSuccessfullyRefunded: onSuccessfullyRefunded, onCancel: onRefuse, order: order, travellersToRefund: order.travellers.map(traveller => traveller.id) })));
};
export default AirportServices;
