import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { parse, format, isAfter, isSameDay } from 'date-fns';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { getSegments, hasSelectedSegments } from '../../store/segments/selectors';
import { API_DATE_FORMAT } from '../../../utils';
import { OverrideComponent } from '../../../renderProps';
import { getWeekDatesIntervalOfDate } from '../../utils';
import { InfoDetails } from '../../../Icons';
import DateSwitcher from '../DateSwitcher/DateSwitcher';
import ScheduleFlight from '../ScheduleFlight/ScheduleFlight';
import Locations from '../Locations/Locations';
import Disclaimer from '../../../Disclaimer/Disclaimer';
import ResultsError from '../../../ResultsError';
const Results = ({ className, schedule, availableDates = [], onStartSearch, onSelect, onChangeDate }) => {
    const { Results: theme } = useTheme('CoreSchedule');
    const { t, i18n } = useTranslation('Schedule');
    const segments = useSelector(getSegments);
    const hasSegments = useSelector(hasSelectedSegments);
    const startDate = parse(segments.date, API_DATE_FORMAT, new Date());
    const filteredFlights = useMemo(() => {
        var _a, _b;
        const availableDays = getWeekDatesIntervalOfDate(startDate)
            .filter(date => isSameDay(date, new Date()) || isAfter(date, new Date()))
            .map(date => format(date, 'EEEE'));
        if ((_b = (_a = schedule === null || schedule === void 0 ? void 0 : schedule.flightDirections[0]) === null || _a === void 0 ? void 0 : _a.legs) === null || _b === void 0 ? void 0 : _b.length) {
            return schedule.flightDirections[0].legs.filter(flight => flight.weekdays.some(day => availableDays.includes(day)));
        }
        return [];
    }, [schedule === null || schedule === void 0 ? void 0 : schedule.flightDirections, startDate]);
    return (React.createElement("div", { className: cn(theme.container, className) },
        React.createElement("div", { className: theme.content },
            !schedule && !hasSegments && (React.createElement(OverrideComponent, { componentProps: { departure: segments.departure, onSelect }, component: { ScheduleLocations: Locations } })),
            schedule && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: theme.controls },
                    React.createElement(DateSwitcher, { date: startDate, availableDates: availableDates, onChangeDate: onChangeDate })),
                filteredFlights.length > 0 ? (React.createElement("div", { className: theme.flights }, filteredFlights.map((flight, index) => (React.createElement(ScheduleFlight, { key: index, startDate: startDate, flight: flight, onClickDate: onStartSearch }))))) : (React.createElement(ResultsError, { className: theme.error, header: t(`We did not find flights to the selected cities`), body: t('Please select another city or change your search. If you would like to purchase a ticket for a specific city, please contact customer service.') })),
                i18n.exists('Schedule:LocalTime', { fallbackLng: i18n.language }) && (React.createElement(Disclaimer, { className: theme.disclaimer, icon: InfoDetails },
                    React.createElement("span", { className: theme.disclaimer__text }, t('LocalTime')))))))));
};
export default Results;
