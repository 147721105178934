import * as React from 'react';
import cn from 'classnames';
import { Link } from '../index';
import { useTheme } from '../theme';
import * as icons from './icons';
import { useConfig } from '../context';
import { PromoMessageIcons } from './types';
import { initI18n } from '../utils';
import { useTranslation } from 'react-i18next';
initI18n('PromoMessage');
const PromoMessage = props => {
    const { PromoMessage: theme } = useTheme('PromoMessage');
    const { t } = useTranslation('PromoMessage');
    let icon;
    switch (props.icon) {
        case PromoMessageIcons.TimeIsMoney:
            icon = icons.TimeIsMoneyIcon;
            break;
        default:
            break;
    }
    return (React.createElement("div", { className: cn(props.className, theme.wrapper) },
        React.createElement("div", { className: theme.inner },
            React.createElement("div", { className: theme.icon }, icon),
            React.createElement("h6", { className: theme.title }, t(props.title)),
            React.createElement("p", { className: theme.content },
                React.createElement("div", { className: theme.content__text, dangerouslySetInnerHTML: { __html: t(props.content) } }),
                props.link && (React.createElement("div", { className: theme.links },
                    React.createElement(Link, { className: theme.link, target: '_blank', action: props.link.url }, t(props.link.text))))))));
};
export const PromoMessages = ({ className }) => {
    var _a, _b, _c, _d, _e;
    const config = useConfig();
    if (!((_b = (_a = config.Engine) === null || _a === void 0 ? void 0 : _a.promoMessages) === null || _b === void 0 ? void 0 : _b.length)) {
        return null;
    }
    return (React.createElement("div", { className: className }, (_e = (_d = (_c = config.Engine) === null || _c === void 0 ? void 0 : _c.promoMessages) === null || _d === void 0 ? void 0 : _d.map(promoMessage => React.createElement(PromoMessage, Object.assign({}, promoMessage)))) !== null && _e !== void 0 ? _e : null));
};
export default PromoMessage;
