import * as React from 'react';
import { InfoMessageTypeEnum } from '@websky/graphql';
import Passenger from './Passenger/Passenger';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import Toolbar from '../../../../Toolbar';
import WarningModal from '../../../../WarningModal';
import Segment from '../../../../RetrieveBooking/components/Segment/Segment';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import MobileSegment from '../../../../RetrieveBooking/components/MobileSegment/MobileSegment';
import Notification from '../../../../Notification';
import { isCorrectCyrillicLastName, isCorrectLatingLastname, isTicketNumber } from '../../../../RetrieveBooking/hooks';
const reducer = (state, action) => {
    const isPassengerExists = state.find(passenger => passenger.id === action.id);
    if (isPassengerExists) {
        return state.map(passenger => (passenger.id === action.id ? Object.assign({}, action) : Object.assign({}, passenger)));
    }
    else {
        return [
            ...state,
            Object.assign(Object.assign({}, action), { id: state.length.toString() })
        ];
    }
};
const init = (groupOrderParams) => {
    if (isTicketNumber(groupOrderParams.id) &&
        (isCorrectLatingLastname(groupOrderParams.secret) || isCorrectCyrillicLastName(groupOrderParams.secret))) {
        return [
            {
                id: '0',
                passengerName: groupOrderParams.secret,
                passengerId: groupOrderParams.id,
                selected: true
            }
        ];
    }
    return [];
};
const GroupOrder = props => {
    var _a, _b;
    const [state, dispatch] = React.useReducer(reducer, props.groupOrderParams, init);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const { t } = useTranslation('Checkout');
    const theme = useTheme('Checkout').GroupOrder;
    const onChangeHandler = (passenger, selected) => {
        dispatch(Object.assign(Object.assign({}, passenger), { selected }));
    };
    const onAddPassengerHandler = (lastName, id, internalId) => {
        return new Promise(resolve => {
            dispatch({
                passengerId: id,
                passengerName: lastName,
                selected: true,
                id: internalId
            });
            resolve(true);
        });
    };
    const selectedPassengers = state.filter(passenger => passenger.selected);
    const onNextClickHandler = () => {
        const passengers = selectedPassengers.map(passenger => {
            return {
                id: passenger.id,
                lastName: passenger.passengerName,
                ticketNumber: passenger.passengerId
            };
        });
        if (passengers.length) {
            setLoading(true);
            props
                .onAccept(passengers)
                .then(order => {
                var _a, _b;
                if ((_a = order.splitOrder) === null || _a === void 0 ? void 0 : _a.id) {
                    props.onSuccess(order.splitOrder);
                }
                if (((_b = order === null || order === void 0 ? void 0 : order.notMatchedTravellersData) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                    order.notMatchedTravellersData.forEach(passenger => {
                        dispatch({
                            passengerId: passenger.ticketNumber,
                            passengerName: passenger.lastName,
                            error: true,
                            selected: true,
                            id: passenger.id
                        });
                    });
                }
                setLoading(false);
            })
                .catch(errors => {
                var _a;
                setError(((_a = errors === null || errors === void 0 ? void 0 : errors[0]) === null || _a === void 0 ? void 0 : _a.message) || 'Error while FindGroupOrder');
                setLoading(false);
            });
        }
    };
    return (React.createElement("div", { className: theme.groupOrder },
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.container }, t('Search my booking'))),
        React.createElement("div", { className: cn(theme.container, theme.notifications) },
            React.createElement(Notification, { className: theme.notification__item, status: InfoMessageTypeEnum.Warning, type: "context", infoMessage: {
                    text: t('Specify all passengers for whom you need to open an order for correct display.'),
                    type: InfoMessageTypeEnum.Warning,
                    code: 'warning'
                } })),
        ((_b = (_a = props.groupOrder.flight) === null || _a === void 0 ? void 0 : _a.segmentGroups) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement("div", { className: cn(theme.container, theme.flight) }, props.groupOrder.flight.segmentGroups.map((segment, index) => (React.createElement(React.Fragment, { key: segment.groupId },
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement(Segment, { variant: 'common', segment: segment })),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement(MobileSegment, { segment: segment, className: theme.segment }))))))),
        React.createElement("div", { className: cn(theme.container, theme.passengers) },
            state.map(passenger => {
                return (React.createElement(Passenger, { passenger: passenger, key: passenger.passengerId, onSelect: onChangeHandler, onAddPassenger: onAddPassengerHandler }));
            }),
            React.createElement(Passenger, { type: "add", isOpen: !state.length, onAddPassenger: onAddPassengerHandler })),
        React.createElement(Toolbar, { totalPrice: null, isLoading: loading, buttonDisabled: !selectedPassengers.length, onButtonClick: onNextClickHandler }),
        React.createElement(WarningModal, { isOpen: !!error, onClose: () => setError(null), title: t('Oops..'), content: t('An unexpected error has occurred during the tickets downloading process. Please try again later or contact a customer support service.'), errorMessage: error })));
};
export default GroupOrder;
