import { CLEAR, DIFFERENT_FOR_RETURN_FLIGHT, DISABLE, SET_ALL_SERVICES, SET_SERVICES, SET_BAGGAGE_TAB } from './actions';
import { getSelectedServicesByType } from './utils';
const initialState = {
    services: null,
    selectedServices: [],
    differentForReturnFlight: false,
    disabled: false,
    maxBaggageCount: 3,
    maxBaggagePerPassengerCount: null,
    baggageTab: null,
    passengers: null,
    segmentIds: []
};
export const upgradeModalReducer = (state = initialState, action) => {
    if (action.type !== DISABLE && state.disabled) {
        return state;
    }
    switch (action.type) {
        case CLEAR:
            return Object.assign(Object.assign({}, state), { selectedServices: state.selectedServices.map(selectedService => {
                    var _a, _b, _c;
                    return Object.assign(Object.assign({}, selectedService), { 
                        // значение сбрасываем на кол-во оплаченных позиций либо на 0
                        count: state.services.find(service => service.id === selectedService.serviceId)
                            ? (_c = (_b = (_a = state.passengers
                                .find(p => p.id === selectedService.passengerId)) === null || _a === void 0 ? void 0 : _a.services.find(s => s.serviceId === selectedService.serviceId &&
                                s.segmentIds.some(s => selectedService.segmentIds.includes(s)))) === null || _b === void 0 ? void 0 : _b.confirmedCount) !== null && _c !== void 0 ? _c : 0
                            : selectedService.count });
                }) });
        case DIFFERENT_FOR_RETURN_FLIGHT:
            const newValue = !state.differentForReturnFlight;
            if (newValue) {
                return Object.assign(Object.assign({}, state), { differentForReturnFlight: newValue });
            }
            else {
                const minCountByServiceMap = new Map();
                state.selectedServices.forEach(service => {
                    const key = `${service.serviceId}-${service.passengerId}`;
                    const count = minCountByServiceMap.get(key);
                    minCountByServiceMap.set(key, count !== undefined ? (service.count < count ? service.count : count) : service.count);
                });
                return Object.assign(Object.assign({}, state), { selectedServices: state.selectedServices.map(selectedService => {
                        var _a;
                        const key = `${selectedService.serviceId}-${selectedService.passengerId}`;
                        const hasService = state.services.find(service => service.id === selectedService.serviceId);
                        return Object.assign(Object.assign({}, selectedService), { count: hasService ? (_a = minCountByServiceMap.get(key)) !== null && _a !== void 0 ? _a : 0 : selectedService.count });
                    }), differentForReturnFlight: newValue });
            }
        case SET_SERVICES:
            const selectedServices = getSelectedServicesByType(state, action);
            return Object.assign(Object.assign({}, state), { selectedServices });
        case SET_BAGGAGE_TAB:
            return Object.assign(Object.assign({}, state), { baggageTab: action.payload });
        case SET_ALL_SERVICES:
            return Object.assign(Object.assign({}, state), { selectedServices: [...action.payload.map(service => (Object.assign({}, service)))] });
        case DISABLE:
            return Object.assign(Object.assign({}, state), { disabled: action.payload });
    }
    return state;
};
