import * as React from 'react';
import classnames from 'classnames';
import { Money } from '../../../Money';
import { format } from '../../../utils';
import { useTheme } from '../../../theme';
export const Day = props => {
    const day = format(props.date, 'd MMM'), formattedDate = format(props.date, 'EEEEEE');
    const theme = useTheme('WeekCalendar').DayStyles;
    return (React.createElement("div", { className: classnames(theme.day, { [theme.day_notAvailable]: !props.isAvailable }, { [theme.day_selected]: props.isSelected }, { [theme.day_best]: props.isCheap }, { [theme.day_hidePrice]: props.hidePrice }), onClick: () => {
            props.isAvailable && props.onSelect && props.onSelect(props.date);
        } },
        React.createElement("div", { className: theme.date }, day),
        React.createElement("div", { className: theme.date__dow },
            React.createElement("div", { className: theme.date__info },
                React.createElement("span", null, formattedDate))),
        !props.hidePrice && (React.createElement("div", { className: theme.price__wrapper }, props.isAvailable && (React.createElement(Money, { moneyClassName: theme.price, money: props.price, roundType: props.moneyRoundType }))))));
};
