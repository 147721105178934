import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Money } from '../../../../Money';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
const PriceBlock = ({ className, min, total }) => {
    const { BaggageTotalPriceBlock: css } = useTheme('BaggageTotalPriceBlock');
    const { t } = useTranslation('AdditionalBaggage');
    if ((total === null || total === void 0 ? void 0 : total.amount) !== undefined) {
        return (React.createElement("div", { className: cn(className, css.price__selected) },
            t('total'),
            " ",
            React.createElement(Money, { money: total, roundType: "ceil" })));
    }
    else {
        return (React.createElement("div", { className: className },
            t('from'),
            " ",
            React.createElement(Money, { money: min })));
    }
};
export default PriceBlock;
