import * as React from 'react';
import cn from 'classnames';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import { WarningRounded, Included, ArrowForward } from '../../Icons';
import { BaseButton } from '../../index';
const Notification = ({ className, iconClassName, contentClassName, titleClassName, textClassName, contextClassName, globalClassName, htmlClassName, title, image, icon, status, type, infoMessage, actionClassName, actionText, actionVariant = 'optionEdit', onClickAction, renderMessagesWithoutWrapper }) => {
    const { Notification: css } = useTheme('Notification');
    const isSuccess = ![InfoMessageTypeEnum.Info, InfoMessageTypeEnum.Warning, InfoMessageTypeEnum.Promo].includes(status);
    const isHtmlType = infoMessage.type === InfoMessageTypeEnum.HtmlContent;
    const isContextType = type === 'context';
    const isGlobalType = type === 'global';
    if (renderMessagesWithoutWrapper) {
        return React.createElement("div", { className: htmlClassName, dangerouslySetInnerHTML: { __html: infoMessage.text } });
    }
    return (React.createElement("div", { className: cn(css.wrapper, className, {
            [css.context]: !isHtmlType && isContextType,
            [css.global]: !isHtmlType && isGlobalType,
            [css.success]: isSuccess && !isHtmlType,
            [css.info]: status === InfoMessageTypeEnum.Info,
            [css.warning]: status === InfoMessageTypeEnum.Warning,
            [contextClassName]: isContextType && contextClassName,
            [globalClassName]: isGlobalType && globalClassName
        }), "data-code": infoMessage.code },
        React.createElement("div", { className: css.wrapper__inner }, isHtmlType ? (React.createElement("div", { className: htmlClassName, dangerouslySetInnerHTML: { __html: infoMessage.text } })) : (React.createElement(React.Fragment, null,
            image ? (React.createElement("img", { className: css.image, src: image, alt: "" })) : (React.createElement("div", { className: cn(css.icon, iconClassName) }, icon ? icon : isSuccess ? Included : WarningRounded)),
            React.createElement("div", { className: cn(css.content, contentClassName) },
                title && (React.createElement("div", { className: cn(css.title, titleClassName), dangerouslySetInnerHTML: { __html: title } })),
                React.createElement("div", { className: cn(css.text, textClassName), dangerouslySetInnerHTML: { __html: infoMessage.text } }))))),
        actionText && !isHtmlType && (React.createElement(BaseButton, { variant: actionVariant, className: cn(css.action, actionClassName), onClick: onClickAction },
            actionText,
            ArrowForward))));
};
export default Notification;
