import * as React from 'react';
import { useField } from 'react-final-form';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { PromoCodeFieldType } from '../../types';
import Input from '../../../Input';
import Tooltip from '../../../Tooltip';
import { useTheme } from '../../../theme';
import { useIsMobile } from '../../../utils';
const Field = ({ className, name, type, label, validate }) => {
    const { Field: css } = useTheme('PromoCode');
    const isMobile = useIsMobile();
    const field = useField(name, {
        type,
        validate
    });
    if (type === PromoCodeFieldType.Checkbox) {
        return (React.createElement(FormControlLabel, { control: React.createElement(Tooltip, { placement: isMobile ? 'top-start' : 'top', classes: { popper: css.tooltip_popper }, open: field.meta.submitFailed && !field.input.value, title: field.meta.error },
                React.createElement(Checkbox, { color: "primary", classes: {
                        root: css.checkbox,
                        checked: css.checkbox_checked
                    }, checked: field.input.checked, inputProps: Object.assign({}, field.input) })), label: label }));
    }
    return (React.createElement(Input, { label: label, value: field.input.value, TextFieldProps: Object.assign(Object.assign({}, field.input), { error: !!field.meta.error && field.meta.touched, helperText: field.meta.touched && field.meta.error }), className: className, absoluteHintPosition: true }));
};
export default Field;
