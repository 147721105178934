import * as FlightInfoCard from './components/FlightInfoCard.css';
import * as FlightInfoCardHint from './components/FlightInfoCardHint/FlightInfoCardHint.css';
import * as FlightInfoCardTabs from './components/FlightInfoCardTabs/FlightInfoCardTabs.css';
import * as FlightInfoCardPoint from './components/FlightInfoCardPoint/FlightInfoCardPoint.css';
import * as FlightInfoCardHeader from './components/FlightInfoCardHeader/FlightInfoCardHeader.css';
import * as FlightInfoCardControls from './components/FlightInfoCardControls/FlightInfoCardControls.css';
import * as SegmentServices from './components/SegmentServices/SegmentServices.css';
import TicketsModal from './components/TicketsModal/TicketsModal.css';
export default {
    FlightInfoCard,
    FlightInfoCardHint,
    FlightInfoCardTabs,
    FlightInfoCardPoint,
    FlightInfoCardHeader,
    FlightInfoCardControls,
    SegmentServices,
    TicketsModal
};
