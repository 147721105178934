import * as React from 'react';
import cn from 'classnames';
import { InsurancePageContext } from '../../InsurancePageContext';
import { useInsurancePage } from './InsurancePage.hook';
import { InsuranceProduct } from '../InsuranceProduct/InsuranceProduct';
import { Card } from '../Card/Card';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import SimpleLoader from '../../../SimpleLoader';
export const InsurancePage = ({ className, availablePrograms, onTotalInsurancePriceChange, onSaveOrderServicesParamsChange, onServicesChanged, onDownloadNasInsurance, isShowImportantInsurancesError }) => {
    const { InsurancePage: theme } = useTheme('InsurancePage');
    const { t } = useTranslation('InsurancePage');
    const model = useInsurancePage({
        availablePrograms,
        onSaveOrderServicesParamsChange,
        onTotalInsurancePriceChange,
        onServicesChanged
    });
    return (React.createElement(InsurancePageContext.Provider, { value: model },
        React.createElement(Card, { className: cn(theme.wrapper, className) },
            React.createElement(Card, { className: theme.header },
                React.createElement("p", { className: theme.subheader }, t('To make your travels memorable only with pleasant excitement, take out an insurance policy that will protect you during your trip.'))),
            React.createElement(Card, { className: theme.content },
                model.mainPrograms.length > 0 && (React.createElement(Card, { className: theme.mainInsurancesList }, model.mainPrograms.map(importantProgram => (React.createElement(InsuranceProduct, { key: importantProgram.code, hideForAllPassengersTextBecauseSinglePassenger: model.doNotRenderForAllPassengersText, productState: model.getProductState(importantProgram.code), policyUrl: model.getPolicyUrl(importantProgram.code), onSelect: model.select, onRemove: model.remove, onToggle: model.toggle, onDownloadVskInsurance: onDownloadNasInsurance, insuranceProgram: importantProgram, isShowImportantInsurancesError: isShowImportantInsurancesError, popular: true }))))),
                model.insurancesWithExcludedMainPrograms.length > 0 && (React.createElement(Card, { className: theme.insurancesList }, model.insurancesWithExcludedMainPrograms.map(insurance => (React.createElement(InsuranceProduct, { hideForAllPassengersTextBecauseSinglePassenger: model.doNotRenderForAllPassengersText, key: insurance.code, productState: model.getProductState(insurance.code), policyUrl: model.getPolicyUrl(insurance.code), onSelect: model.select, onRemove: model.remove, onToggle: model.toggle, onDownloadVskInsurance: onDownloadNasInsurance, insuranceProgram: insurance, popular: false })))))),
            model.isLoading && React.createElement(SimpleLoader, null))));
};
