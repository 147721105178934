export const isCheckoutOrder = (order) => {
    return order.flight !== undefined;
};
export const isCheckinOrder = (order) => {
    return order.segments !== undefined;
};
export const convertCartServiceProps = (order) => {
    if (isCheckoutOrder(order)) {
        return {
            flight: order.flight,
            customer: order.customer,
            travellers: order.travellers,
            services: order.additionalServices
        };
    }
    return {
        flight: {
            seatMap: order.seatMap,
            segments: order.segments.map(segment => ({
                segment: Object.assign(Object.assign({}, segment), { stops: [], messages: [] }),
                transferDuration: null
            })),
            segmentGroups: [],
            fares: []
        },
        customer: order.customer,
        travellers: order.travellers.map(passenger => (Object.assign(Object.assign({}, passenger), { services: Object.assign(Object.assign({}, passenger.services), { brandIncludedServices: { services: [] } }), supplierTravellerType: null, needOthsSsr: null, subsidyInfo: null, fares: null }))),
        services: order.additionalServices
    };
};
