import { __awaiter } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import ContactsForm from '../../../../../Contacts/components/Contacts';
import { unlockForm } from '../../../../store/formIsLocked/actions';
import { isFormLocked } from '../../../../store/formIsLocked/selectors';
import { InputType } from '../../../../../Passenger/types';
import { getOrderId, getIsReadonly, getIsRedirectFromMetasearch } from '../../../../store/order/selectors';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import { reachGoal, SplitTestGoal } from '../../../../../analytics';
import MobileAuthForm from '../../../../../MobileAuthForm';
import { get, set, TEST_AUTHORIZATION_REFUSED } from '../../../../../cache';
import { fillOrder } from '../../../../store/order/actions';
import { LoginType, TravellerFieldEnum } from '@websky/graphql';
import Modal from '../../../../../Modal';
import { useRenderers } from '../../../../../renderProps';
// #fixme: a lot of state objects
const Contacts = props => {
    const loginType = LoginType.Email;
    const [customerFormIsOpen, setCustomerFormIsOpen] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const getUserInfo = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const data = yield ((_a = props.refetchUser) === null || _a === void 0 ? void 0 : _a.call(props));
        if (data && data.CurrentUser) {
            props.unlockForm();
        }
        else {
            // Show customer auth form if user is not authorized yet.
            const showCustomerForm = isMobile && !get(`${TEST_AUTHORIZATION_REFUSED}_${props.orderId}`) && !props.isReadonly;
            if (showCustomerForm) {
                // Somehow it doesn't work without timeout. The form gets re-rendered.
                setTimeout(() => setCustomerFormIsOpen(true), 200);
            }
        }
    });
    const closeCustomerFormWithRefused = () => {
        setCustomerFormIsOpen(false);
        set(`${TEST_AUTHORIZATION_REFUSED}_${props.orderId}`, true);
        reachGoal(SplitTestGoal.RefusedToAuthorize);
    };
    const closeCustomerFormWithAgreed = () => {
        var _a;
        setCustomerFormIsOpen(false);
        reachGoal(SplitTestGoal.AgreedToAuthorize);
        (_a = props.refetchUser) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const agreeToAuthorize = () => {
        reachGoal(SplitTestGoal.AgreedToAuthorize);
    };
    useEffect(() => {
        if (!props.isReadonly) {
            getUserInfo();
        }
    }, [props.isReadonly]);
    const email = props.customer.values.find(({ type }) => type === TravellerFieldEnum.Email);
    const phone = props.customer.values.find(({ type }) => type === TravellerFieldEnum.Phone);
    const open = !props.isReadonly || props.isInputsReadonly;
    let ContactsWidget = useRenderers()
        .CheckoutContacts;
    if (!ContactsWidget) {
        ContactsWidget = ContactsForm;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ContactsWidget, { open: open, isInputsReadonly: props.isInputsReadonly, autofocus: props.autofocus, isAuthorized: !!props.userInfo, toggleOpen: () => { }, submitForm: props.submitForm, unlockForm: props.unlockForm, isFormLocked: props.isFormLocked, onLoginOpen: props.onLoginOpen, loginType: loginType, showSubscribeCheckbox: props.customer.showSubscribeCheckbox, email: {
                name: `customer.${email.name}`,
                type: email.type,
                input: InputType.Text,
                defaultValue: email.value,
                validations: email.validationRules
            }, phone: {
                name: `customer.${phone.name}`,
                type: phone.type,
                input: InputType.Phone,
                defaultValue: phone.value,
                validations: phone.validationRules
            } }),
        !props.userInfo && !props.isRedirectFromMetasearch && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: customerFormIsOpen, onClose: closeCustomerFormWithRefused, bottomDrawer: true, isRounded: true },
                React.createElement(MobileAuthForm, { onSuccess: closeCustomerFormWithAgreed, onReject: closeCustomerFormWithRefused, onSocialAuthClick: agreeToAuthorize }))))));
};
const mapStateToProps = (state) => {
    return {
        orderId: getOrderId(state),
        isReadonly: getIsReadonly(state),
        isRedirectFromMetasearch: getIsRedirectFromMetasearch(state),
        isFormLocked: isFormLocked(state)
    };
};
const mapDispatchToProps = {
    unlockForm,
    fillOrder
};
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
