import { __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import Modal from '../../Modal';
import SuggestionModalContent from '../../SuggestionModalContent/SuggestionModalContent';
const DoubleSeatsModal = (_a) => {
    var { classes } = _a, props = __rest(_a, ["classes"]);
    const { DoubleSeatsModal: css } = useTheme('DoubleSeatsModal');
    const { t } = useTranslation('Checkout');
    return (React.createElement(Modal, Object.assign({ maxWidth: "xs" }, props, { classes: Object.assign(Object.assign({}, classes), { root: cn(css.container, classes === null || classes === void 0 ? void 0 : classes.root) }) }),
        React.createElement(SuggestionModalContent, { icon: null, agreeButtonText: t('Confirm'), text: t('You must select seats to proceed to the next step'), onAgree: props.onClose })));
};
export default DoubleSeatsModal;
