import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCombinedNumber, getOppositeDirection } from './utils';
import { addServiceAction, removeServiceAction } from '../Checkout/store/selectedServices/actions';
import { getExtraSeatServices } from '../Modules/Checkin/store/order/selectors';
import { findPassengerWithThisSeat } from './components/SeatMap/Seat/utils';
export const useDoubleSeat = (activeDoubleSeats, segmentId, seats, passengers, onSelect) => {
    const [combineWith, setCombineWith] = useState(null);
    const [prevSeatNumber, setPrevSeatNumber] = useState(null);
    const selectedDoubleSeatsBySegments = useMemo(() => {
        const doubleSeatsBySegments = new Map();
        passengers.forEach(passenger => {
            var _a;
            if (((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.length) > 1) {
                passenger.seats.forEach(selectedSeat => {
                    const seatsNumbers = passenger.seats
                        .filter(seat => seat.segmentId === selectedSeat.segmentId)
                        .map(seat => seat.number);
                    if (seatsNumbers.length > 1) {
                        const existSegment = doubleSeatsBySegments.get(selectedSeat.segmentId);
                        if (existSegment) {
                            seatsNumbers.map(number => existSegment.set(number, seatsNumbers));
                            doubleSeatsBySegments.set(selectedSeat.segmentId, existSegment);
                        }
                        else {
                            doubleSeatsBySegments.set(selectedSeat.segmentId, new Map(seatsNumbers.map(number => [number, seatsNumbers])));
                        }
                    }
                });
            }
        });
        return doubleSeatsBySegments;
    }, [passengers]);
    const onSelectHandler = useCallback((seat, passengerId) => {
        var _a, _b;
        const seatOccupiedBy = findPassengerWithThisSeat(seat, passengers, segmentId), seatOccupiedByPassengerId = seatOccupiedBy ? seatOccupiedBy.id : passengerId, doubleSeats = (_b = (_a = selectedDoubleSeatsBySegments === null || selectedDoubleSeatsBySegments === void 0 ? void 0 : selectedDoubleSeatsBySegments.get(segmentId)) === null || _a === void 0 ? void 0 : _a.get(seat.number)) !== null && _b !== void 0 ? _b : [];
        if (seatOccupiedByPassengerId !== passengerId) {
            onSelect((doubleSeats === null || doubleSeats === void 0 ? void 0 : doubleSeats.length) ? seats.filter(seat => doubleSeats.includes(seat.number)) : [seat], seatOccupiedByPassengerId);
        }
        else if (activeDoubleSeats) {
            const combinedNumber = getCombinedNumber(seat, combineWith !== null && combineWith !== void 0 ? combineWith : seat.combinedWith);
            const combinedSeat = seats.find(seat => seat.number === combinedNumber);
            if (combinedSeat) {
                onSelect([seat, combinedSeat], seatOccupiedByPassengerId);
            }
            else {
                onSelect([seat], seatOccupiedByPassengerId);
            }
        }
        else {
            onSelect([seat], seatOccupiedByPassengerId);
        }
    }, [seats, onSelect, activeDoubleSeats, combineWith, selectedDoubleSeatsBySegments]);
    const onMouseEnter = useCallback((seat, segmentId) => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (activeDoubleSeats && seat.combinedWith) {
            const [firstCombineWithNumber, firstCombineWithDirection] = (_a = Object.entries(seat.combinedWith)) === null || _a === void 0 ? void 0 : _a[0];
            const doubleSeats = (_b = selectedDoubleSeatsBySegments.get(segmentId)) === null || _b === void 0 ? void 0 : _b.get(seat.number);
            const prevDirection = (_c = seat.combinedWith) === null || _c === void 0 ? void 0 : _c[prevSeatNumber];
            if (doubleSeats === null || doubleSeats === void 0 ? void 0 : doubleSeats.length) {
                const combinedSeatNumber = (_d = doubleSeats === null || doubleSeats === void 0 ? void 0 : doubleSeats.filter(doubleSeat => doubleSeat !== seat.number)) === null || _d === void 0 ? void 0 : _d[0];
                const combinedDirection = (_e = seat.combinedWith) === null || _e === void 0 ? void 0 : _e[combinedSeatNumber];
                setCombineWith({
                    [seat.number]: getOppositeDirection(combinedDirection),
                    [combinedSeatNumber]: combinedDirection
                });
            }
            else if (prevSeatNumber &&
                seat.number !== prevSeatNumber &&
                prevDirection &&
                !((_f = selectedDoubleSeatsBySegments.get(segmentId)) === null || _f === void 0 ? void 0 : _f.has(firstCombineWithNumber))) {
                setCombineWith({
                    [seat.number]: getOppositeDirection(prevDirection),
                    [prevSeatNumber]: prevDirection
                });
            }
            else if (!((_g = selectedDoubleSeatsBySegments.get(segmentId)) === null || _g === void 0 ? void 0 : _g.has(firstCombineWithNumber))) {
                setCombineWith({
                    [seat.number]: getOppositeDirection(firstCombineWithDirection),
                    [firstCombineWithNumber]: firstCombineWithDirection
                });
            }
            setPrevSeatNumber(seat.number);
        }
    }, [activeDoubleSeats, combineWith, selectedDoubleSeatsBySegments]);
    const onMouseLeave = useCallback(() => setCombineWith(null), [combineWith]);
    const combinedSeat = useMemo(() => {
        var _a;
        if (combineWith) {
            const firstCombineWithNumber = (_a = Object.keys(combineWith)) === null || _a === void 0 ? void 0 : _a[1];
            return seats.find(seat => seat.number === firstCombineWithNumber);
        }
        return null;
    }, [seats, combineWith]);
    return {
        combineWith,
        prevSeatNumber,
        selectedDoubleSeatsBySegments,
        combinedSeat,
        setCombineWith,
        setPrevSeatNumber,
        onSelectHandler,
        onMouseEnter,
        onMouseLeave
    };
};
export const useExtraSeat = (segments, passengerId, segmentIndex) => {
    const dispatch = useDispatch();
    const extraSeatServices = useSelector(getExtraSeatServices);
    const extraSeatService = useMemo(() => {
        let actualService = null;
        const servicesByPassenger = extraSeatServices.filter(service => new Set(service.allowedPassengers.reduce((acc, ids) => [...acc, ...ids], [])).has(passengerId));
        servicesByPassenger.forEach(service => {
            if (service.canBeAdded && (!actualService || service.id > actualService.id)) {
                actualService = service;
            }
        });
        return actualService;
    }, [extraSeatServices, passengerId]);
    const toggleExtraSeatService = useCallback((active, passengerId) => {
        if (active && extraSeatService) {
            dispatch(addServiceAction({
                segmentId: segments[0].segmentInfo.id,
                segmentIds: segments.map(segment => segment.segmentInfo.id),
                allowedSegments: extraSeatService.allowedSegments,
                passengerId: passengerId,
                serviceId: extraSeatService.id,
                service: {
                    id: extraSeatService.id,
                    type: extraSeatService.type
                }
            }));
        }
        else if (!active && extraSeatService) {
            segments.forEach(segment => {
                dispatch(removeServiceAction({
                    segmentId: segment.segmentInfo.id,
                    passengerId: passengerId,
                    serviceId: extraSeatService.id,
                    service: {
                        id: extraSeatService.id,
                        type: extraSeatService.type
                    },
                    count: 0
                }));
            });
        }
    }, [extraSeatService, segments, dispatch]);
    const showDoubleSeats = useMemo(() => {
        var _a;
        const hasDoubleSeats = (_a = segments === null || segments === void 0 ? void 0 : segments[segmentIndex].decks) === null || _a === void 0 ? void 0 : _a.some(deck => deck.rows.some(row => row.seats.some((seat) => seat.combinedWith && Object.keys(seat.combinedWith).length > 0)));
        return !!extraSeatService && hasDoubleSeats;
    }, [segments, segmentIndex, extraSeatService]);
    return {
        showDoubleSeats,
        extraSeatService,
        toggleExtraSeatService
    };
};
