import { __awaiter } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useField } from 'react-final-form';
import cn from 'classnames';
import mime from 'mime';
import { useTheme } from '../../../../../../theme';
import { useToggleable } from '../../../../../../hooks';
import { useFileConverter } from '../../../../../../Refund/Components/AddFile/hooks';
import { AttachFile, CloseClear, Included } from '../../../../../../Icons';
import Switch from '../../../../../../Switch';
const ChangeGroup = props => {
    const { t } = useTranslation('Passenger');
    const { Group: groupTheme, ChangeGroup: theme } = useTheme('PassengerForm');
    const { isOpen, toggle } = useToggleable(props.isChangeFiles);
    const { toBase64 } = useFileConverter();
    const field = props.fields[0];
    const fieldState = useField(`${field.name}.changeFiles`, {
        type: field.type,
        validate: value => {
            if (isOpen && !value.length) {
                return t('Attach files');
            }
            return undefined;
        }
    });
    const inputFiles = fieldState.input.value;
    const isFilled = (inputFiles === null || inputFiles === void 0 ? void 0 : inputFiles.length) > 0;
    const showError = (fieldState.meta.modified && fieldState.meta.touched) || fieldState.meta.submitFailed;
    const onToggle = () => {
        var _a;
        (_a = props.onToggleChangeFiles) === null || _a === void 0 ? void 0 : _a.call(props, !isOpen);
        toggle();
    };
    const onChange = (e) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if ((_a = e.target.files) === null || _a === void 0 ? void 0 : _a.length) {
            try {
                const files = yield Promise.all(Array.from(e.target.files).map((file) => __awaiter(void 0, void 0, void 0, function* () {
                    const name = file.name;
                    const document = yield toBase64(file);
                    return {
                        name,
                        content: document.content,
                        size: file.size
                    };
                })));
                fieldState.input.onChange([...inputFiles, ...files]);
                fieldState.input.onBlur();
            }
            catch (err) {
                e.target.value = null;
                console.log(err);
            }
        }
    });
    const onClickPreview = (file) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        const mimeType = mime.getType(file.name);
        const fileContent = `data:${mimeType};base64,${file.content}`;
        const win = window.open();
        win.document.write(`<iframe src="${fileContent}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>`);
    };
    const onRemoveFile = (index) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        fieldState.input.onChange(inputFiles.filter((_, fileIndex) => fileIndex !== index));
        fieldState.input.onBlur();
    };
    return (React.createElement("div", { className: cn(groupTheme.container, theme.container) },
        React.createElement("div", { className: theme.wrapper },
            React.createElement(Switch, { mainClassName: theme.toggle, activeClassName: theme.toggle_active, labelClassName: theme.toggle__label, label: React.createElement("div", { className: theme.label },
                    t('Replacement of passport based on age or change of surname upon marriage or divorce'),
                    React.createElement("div", { className: theme.description }, t('Attach supporting document'))), isActive: isOpen, onClick: onToggle })),
        React.createElement(Collapse, { in: isOpen, mountOnEnter: true, unmountOnExit: true },
            React.createElement("div", { className: theme.collapse },
                React.createElement(FormControlLabel, { classes: { root: theme.control, label: theme.control__label }, control: React.createElement("input", { className: theme.input, onChange: onChange, type: "file", accept: "image/jpeg, application/pdf", multiple: true }), label: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: theme.collapse__icon }, AttachFile),
                        React.createElement("div", { className: theme.collapse__content },
                            React.createElement("div", { className: theme.collapse__contentInfo },
                                React.createElement("div", { className: theme.collapse__title },
                                    React.createElement("div", { className: theme.collapse__titleWrapper },
                                        isFilled && (React.createElement("div", { className: theme.collapse__titleIcon }, Included)),
                                        t('Attach a photocopy of the certificate'))),
                                React.createElement("div", { className: theme.collapse__format }, t('File format: jpg, pdf. File size: no more than 5MB', {
                                    nsSeparator: '|'
                                }))),
                            isFilled && (React.createElement("div", { className: theme.collapse__files }, inputFiles.map((file, index) => (React.createElement("div", { key: index, className: theme.collapse__filesItem, onClick: onClickPreview(file) },
                                React.createElement("span", null, file.name),
                                React.createElement("div", { className: theme.collapse__filesItemSize },
                                    Math.ceil(file.size / 1024),
                                    " Kb"),
                                React.createElement("div", { className: theme.collapse__filesItemIcon, onClick: onRemoveFile(index) }, CloseClear)))))),
                            showError && (React.createElement("div", { className: theme.collapse__error }, fieldState.meta.error || fieldState.meta.submitError)))) })))));
};
export default ChangeGroup;
