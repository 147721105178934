import * as React from 'react';
export const seatIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.2963 18.331L10.1512 20.5572H5.74298V22.239H11.0995L11.1962 22.286L11.2204 22.239H16.3701V20.5572H12.0854L13.2304 18.331H16.7113C17.7844 18.331 18.6542 17.4854 18.6542 16.4423C18.6542 15.3992 17.7844 14.5536 16.7113 14.5536H9.73143L7.29864 7.35146V3.17934C7.29864 2.37039 6.62405 1.7146 5.7919 1.7146C4.95975 1.7146 4.28516 2.37039 4.28516 3.17934V7.35146L7.56321 18.331H11.2963ZM13.7955 6.34328L16.1818 8.53731L22.2045 3L23 3.73134L16.1818 10L13 7.07463L13.7955 6.34328Z", fill: "currentColor" })));
export const passengerIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8ZM14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8ZM12 13C9.33 13 4 14.34 4 17V20H20V17C20 14.34 14.67 13 12 13ZM6 17.01V18H18V17C17.8 16.29 14.7 15 12 15C9.3 15 6.2 16.29 6 17.01Z", fill: "currentColor" })));
export const downloadIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z", fill: "currentColor" })));
export const carryonIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.3593 5.59485H18.9192C20.0689 5.59485 21.0042 6.51659 21.0042 7.6495V8.33438H26.8049L24.1834 16.0848C24.0884 16.365 23.8231 16.553 23.5237 16.553H19.6142V15.8681C19.6142 15.4896 19.3033 15.1832 18.9192 15.1832H13.3593C12.9752 15.1832 12.6643 15.4896 12.6643 15.8681V16.553H8.75481C8.45545 16.553 8.19013 16.365 8.09511 16.0848L5.47387 8.33438H11.2743V7.6495C11.2743 6.51659 12.2097 5.59485 13.3593 5.59485ZM12.6643 7.6495V8.33438H19.6142V7.6495C19.6142 7.27156 19.3027 6.96461 18.9192 6.96461H13.3593C12.9758 6.96461 12.6643 7.27156 12.6643 7.6495ZM23.524 17.9227H19.6145V18.6076C19.6145 18.986 19.3035 19.2925 18.9195 19.2925H13.3595C12.9755 19.2925 12.6645 18.986 12.6645 18.6076V17.9227H8.75504C7.85644 17.9227 7.06172 17.3582 6.77721 16.5181L4.27832 9.12926V24.0866C4.27832 25.2195 5.21366 26.1413 6.36329 26.1413H25.9157C27.0653 26.1413 28.0007 25.2195 28.0007 24.0866V9.1298L25.5016 16.5181C25.2173 17.3582 24.4226 17.9227 23.524 17.9227ZM14.0548 16.553H18.2247V17.9228H14.0548V16.553Z", fill: "currentColor" })));
export const baggageIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.2865 6.00016H9.51707C8.41166 6.00016 7.51539 6.93469 7.51563 8.08782V25.9872C7.51563 27.1403 8.4119 28.0751 9.5173 28.0751H9.78606V29.5906C9.78606 29.8692 10.0022 30.0953 10.27 30.0953H12.5588C12.8257 30.0953 13.0427 29.8696 13.0427 29.5906V28.0751H20.292V29.5906C20.292 29.8692 20.5084 30.0953 20.7759 30.0953H23.0649C23.3318 30.0953 23.5486 29.8696 23.5486 29.5906V28.0751H23.817C24.9222 28.0751 25.8187 27.1406 25.8187 25.9872V8.0877C25.8187 6.93481 24.9224 6.00004 23.817 6.00004H22.0477V3.72411C22.0477 2.40601 21.0191 1.33337 19.7553 1.33337H13.5788C12.3147 1.33337 11.2865 2.40601 11.2865 3.72411V6.00016ZM20.5197 6.00004H12.815V3.72411C12.815 3.28474 13.1578 2.9272 13.5791 2.9272H19.7556C20.1769 2.9272 20.5197 3.28474 20.5197 3.72411V6.00004ZM10.0005 11C10.0005 10.4478 10.4482 10 11.0005 10H22.3338C22.8861 10 23.3338 10.4478 23.3338 11C23.3338 11.5523 22.8861 12 22.3338 12H11.0005C10.4482 12 10.0005 11.5523 10.0005 11ZM10.0005 15C10.0005 14.4478 10.4482 14 11.0005 14H22.3338C22.8861 14 23.3338 14.4478 23.3338 15C23.3338 15.5523 22.8861 16 22.3338 16H11.0005C10.4482 16 10.0005 15.5523 10.0005 15ZM11.0005 18C10.4482 18 10.0005 18.4478 10.0005 19C10.0005 19.5523 10.4482 20 11.0005 20H22.3338C22.8861 20 23.3338 19.5523 23.3338 19C23.3338 18.4478 22.8861 18 22.3338 18H11.0005ZM10.0005 23C10.0005 22.4478 10.4482 22 11.0005 22H22.3338C22.8861 22 23.3338 22.4478 23.3338 23C23.3338 23.5523 22.8861 24 22.3338 24H11.0005C10.4482 24 10.0005 23.5523 10.0005 23Z", fill: "currentColor" })));
export const mealIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.9997 1.33337V6.66671H30.6663L28.4663 28.7067C28.333 29.8 27.413 30.6667 26.293 30.6667H24.0263L23.9997 20C23.9997 14.36 19.8663 10.88 14.973 9.74671L14.6663 6.66671H21.333V1.33337H23.9997ZM2.67967 30.6534C1.93301 30.6534 1.33301 30.0534 1.33301 29.3067V28H21.3463V29.3067C21.3463 30.0534 20.7463 30.6534 19.9997 30.6534H2.67967ZM11.333 11.9867C6.33301 11.9867 1.33301 14.6667 1.33301 20H21.333C21.333 14.6667 16.333 11.9867 11.333 11.9867ZM21.333 25.3334V22.6667H1.33301V25.3334H21.333Z", fill: "currentColor" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "1", y: "1", width: "30", height: "30" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.9997 1.33337V6.66671H30.6663L28.4663 28.7067C28.333 29.8 27.413 30.6667 26.293 30.6667H24.0263L23.9997 20C23.9997 14.36 19.8663 10.88 14.973 9.74671L14.6663 6.66671H21.333V1.33337H23.9997ZM2.67967 30.6534C1.93301 30.6534 1.33301 30.0534 1.33301 29.3067V28H21.3463V29.3067C21.3463 30.0534 20.7463 30.6534 19.9997 30.6534H2.67967ZM11.333 11.9867C6.33301 11.9867 1.33301 14.6667 1.33301 20H21.333C21.333 14.6667 16.333 11.9867 11.333 11.9867ZM21.333 25.3334V22.6667H1.33301V25.3334H21.333Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export const downloadArrowIcon = (React.createElement("svg", { width: "10", height: "12", viewBox: "0 0 10 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.99967 4H9.66634L4.99967 8.66667L0.333008 4H2.99967V0H6.99967V4ZM0.333008 11.3333V10H9.66634V11.3333H0.333008Z", fill: "currentColor" })));
export const sportBaggageIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.8267 2.9472C24.2234 3.16721 24.75 3.6289 25.0051 4.58394C25.7201 7.25571 23.9083 12.8576 20.4965 18.5228C19.2398 20.6063 17.8181 22.6063 16.3714 24.3097C15.918 22.513 14.8596 21.1963 14.0429 20.5563L14.0413 20.5546C13.4763 20.1096 12.7446 19.9279 12.0179 20.0712C11.3128 20.2112 10.7011 20.6479 10.3361 21.2746L8.66939 24.1614C8.27105 24.8531 8.22771 25.7015 8.55272 26.4315C8.8794 27.1632 9.54443 27.6966 10.3245 27.8566C10.8545 27.9682 11.0062 28.3799 11.0478 28.6416C11.0511 28.7161 11.0622 28.789 11.0735 28.8633L11.0745 28.8699C10.4461 29.1649 9.85111 29.3316 9.30775 29.3316C8.8994 29.3316 8.51939 29.24 8.17271 29.0499C7.77603 28.8316 7.24934 28.3682 6.99433 27.4149C6.2793 24.7431 8.09104 19.1429 11.5045 13.4776C15.4013 7.00904 20.8999 1.32214 23.8267 2.9472ZM6.99416 4.58393C7.24917 3.62889 7.77586 3.16721 8.17088 2.94887C9.84095 2.02716 12.3194 3.45222 14.8628 6.03233C13.1161 7.99574 11.461 10.3175 10.076 12.6176C9.77594 13.1143 9.48093 13.6393 9.18925 14.1677C7.32584 10.1442 6.46414 6.56068 6.99416 4.58393ZM21.9249 19.3812C22.2249 18.8845 22.5199 18.3595 22.8133 17.8312C24.675 21.8547 25.535 25.4381 25.0067 27.4149C24.7517 28.3683 24.225 28.8299 23.8283 29.05C23.4816 29.2416 23.0999 29.3316 22.6916 29.3316C21.1299 29.3316 19.1548 28.0116 17.138 25.9665C19.4981 23.2914 21.3365 20.3579 21.9249 19.3812ZM13.016 21.868C12.826 21.7197 12.5793 21.6613 12.3443 21.7047C12.1077 21.7513 11.9026 21.898 11.781 22.1063L10.1142 24.9931C9.9809 25.2248 9.9659 25.5065 10.0759 25.7498C10.1859 25.9932 10.4043 26.1715 10.6659 26.2248C12.4976 26.6083 12.6972 28.3914 12.7088 28.4952L12.7093 28.4999C12.7093 28.8933 12.986 29.2333 13.3694 29.315C13.4294 29.3266 13.486 29.3333 13.5427 29.3333C13.8661 29.3333 14.1677 29.145 14.3044 28.8383C15.8578 25.3381 14.1411 22.748 13.016 21.868Z", fill: "currentColor" })));
