const isSelectedItems = (values) => Object.values(values !== null && values !== void 0 ? values : {}).some(isChecked => isChecked);
export const validateDisabilities = (passengerValues) => {
    if (typeof passengerValues['disabilities'] !== 'object') {
        return null;
    }
    const hasSelectedItems = isSelectedItems(passengerValues['disabilities']['values']);
    if (passengerValues['disabilities']['enabled'] && !hasSelectedItems) {
        return 'Select at least one service from the list below';
    }
    return null;
};
