import * as React from 'react';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import { useTransferServiceSummary } from './model';
const TransferServiceSummary = ({ servicesState, segments, passengers, service }) => {
    const model = useTransferServiceSummary(service, segments, passengers, servicesState);
    return (React.createElement(React.Fragment, null, model.serviceSummaryOptions.map(summaryGroup => {
        return React.createElement(ServiceSummaryOption, { key: summaryGroup.header, groupOption: summaryGroup });
    })));
};
export default TransferServiceSummary;
