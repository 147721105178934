import * as DayStyles from './components/Month/Day/Day.css';
import * as MonthsListStyles from './components/MonthsList/MonthsList.css';
import * as LegendsStyles from './components/Legends/Legends.css';
import * as PriceGraphStyles from './components/PriceGraph.css';
import * as SliderStyles from './components/Slider/Slider.css';
export const defaultTheme = {
    PriceGraphStyles,
    DayStyles,
    MonthsListStyles,
    LegendsStyles,
    SliderStyles
};
export default defaultTheme;
