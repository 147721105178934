import { NASStatus, usenasServicesRefundMutation } from '@websky/graphql';
import { useMemo } from 'react';
export const useAirportServicesRefund = (orderId) => {
    const [request, { data, loading }] = usenasServicesRefundMutation();
    const refundedServiceIds = useMemo(() => {
        var _a;
        return (_a = data === null || data === void 0 ? void 0 : data.NASServicesRefund) === null || _a === void 0 ? void 0 : _a.filter(service => service.status === NASStatus.Refunded).map(service => service.serviceId);
    }, [data]);
    const refundedWithError = useMemo(() => {
        var _a;
        return (_a = data === null || data === void 0 ? void 0 : data.NASServicesRefund) === null || _a === void 0 ? void 0 : _a.filter(service => !!service.error);
    }, [data]);
    const refundServices = (services) => {
        request({
            variables: {
                params: {
                    orderId,
                    services: services.map(service => ({
                        id: service.serviceId,
                        travellerIds: service.passengerIds
                    }))
                }
            }
        });
    };
    return {
        refund: refundServices,
        loading,
        refundedServiceIds,
        refundedWithError
    };
};
