import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertScheduleSearchParams } from '../../utils';
import { LocationType } from '../../../types';
import { setLocation, switchLocation } from '../../store/segments/actions';
import { getSegments } from '../../store/segments/selectors';
import ScheduleForm from '../../../ScheduleForm';
import Results from '../Results/Results';
const Schedule = ({ schedule, availableDates = [], onStartSearch, onScheduleSearch }) => {
    const dispatch = useDispatch();
    const segments = useSelector(getSegments);
    const onSubmitHandler = useCallback((parameters) => onScheduleSearch(parameters, null, !!segments.date), [segments, onScheduleSearch]);
    const onStartSearchHandler = useCallback((date) => onStartSearch(convertScheduleSearchParams(schedule.searchParameters, date), false), [onStartSearch, schedule === null || schedule === void 0 ? void 0 : schedule.searchParameters]);
    const onSelect = useCallback((locations) => (location, locationType) => {
        var _a;
        const type = locationType || (segments.departure ? LocationType.Arrival : LocationType.Departure);
        dispatch(setLocation({ location, locationType: type }));
        if (locations && type === LocationType.Arrival && ((_a = segments.departure) === null || _a === void 0 ? void 0 : _a.iata)) {
            onScheduleSearch({
                departure: segments.departure.iata,
                arrival: location.iata
            });
        }
    }, [segments, onScheduleSearch]);
    const onClear = useCallback(() => (locationType) => {
        const type = locationType || (segments.departure ? LocationType.Arrival : LocationType.Departure);
        dispatch(setLocation({ location: null, locationType: type }));
    }, [segments, onScheduleSearch]);
    const onChangeDateHandler = useCallback((date) => {
        onScheduleSearch({
            departure: segments.departure.iata,
            arrival: segments.arrival.iata
        }, date);
    }, [segments, onScheduleSearch]);
    const onSwitch = () => dispatch(switchLocation());
    return (React.createElement(React.Fragment, null,
        React.createElement(ScheduleForm, { initialState: { segments }, onSubmit: onSubmitHandler, onSelect: onSelect(), onSwitch: onSwitch, onClear: onClear() }),
        React.createElement(Results, { schedule: schedule, onStartSearch: onStartSearchHandler, availableDates: availableDates, onSelect: onSelect(true), onChangeDate: onChangeDateHandler })));
};
export default Schedule;
