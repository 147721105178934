export const useMethods = (dispatch) => {
    const setActiveService = (serviceId) => dispatch({ type: 'setActiveService', payload: serviceId });
    const toggleService = (passengerId, segmentId, service) => {
        dispatch({
            type: 'toggleTransfer',
            payload: {
                service,
                passengerId: passengerId,
                segmentId: segmentId
            }
        });
    };
    const setServiceState = (serviceState) => {
        dispatch({
            type: 'setService',
            payload: serviceState
        });
    };
    return {
        setActiveService,
        toggleService,
        setServiceState
    };
};
