import { createSelector } from 'reselect';
import { getSelectedPassengers } from '../passengers/selectors';
import { getSelectedReason } from '../reason/selectors';
import { getReasonsInfo } from '../refundInfo/selectors';
const getState = (state) => state.documents;
export const getDocuments = createSelector(getState, state => state);
export const getIsAllDocumentsProvided = createSelector(getSelectedPassengers, getState, (selectedPassengers, documents) => selectedPassengers.every(selectedPassenger => { var _a; return (_a = documents[selectedPassenger.id]) === null || _a === void 0 ? void 0 : _a.some(Boolean); }));
export const getRefundFiles = createSelector(getState, getSelectedPassengers, getSelectedReason, getReasonsInfo, (documentsState, selectedPassengers, selectedReason, reasons) => {
    return Array.prototype.concat.apply([], selectedPassengers.map((passenger) => {
        var _a;
        return (_a = documentsState[passenger.id]) === null || _a === void 0 ? void 0 : _a.filter(Boolean).map(document => {
            var _a;
            return ({
                content: document.content,
                originalFileName: document.name,
                type: (_a = reasons === null || reasons === void 0 ? void 0 : reasons.find(reason => reason.reason === selectedReason)) === null || _a === void 0 ? void 0 : _a.docs[0],
                passengerId: passenger.id
            });
        });
    }));
});
