import { useState } from 'react';
import { initialStateFactory } from './initialStateFactory';
import { useMethods } from './methods';
import { useVariables } from './variables';
export * from './types';
export const useTransferSelector = (service, parentState) => {
    const [state, setState] = useState(initialStateFactory(service, parentState));
    const methods = useMethods(state, setState, service, parentState);
    const variables = useVariables(state, service);
    return {
        state,
        setState,
        toggleService: methods.onToggle,
        restoreState: methods.restoreState,
        removeTransfer: methods.removeTransfer,
        totalPrice: variables.totalPrice,
        minPrice: variables.minPrice
    };
};
