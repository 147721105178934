import { __rest } from "tslib";
import * as React from 'react';
import Helmet from 'react-helmet';
import { getVarioqubCounterScript } from './Varioqub/varioqub';
import { useConfig } from './context';
const Head = (_a) => {
    var { includeSlickStyles = true } = _a, props = __rest(_a, ["includeSlickStyles"]);
    const { global: { varioqubId } } = useConfig();
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, Object.assign({}, props),
            varioqubId && (React.createElement("script", { type: "text/javascript", id: "varioqub" }, getVarioqubCounterScript())),
            React.createElement("link", { rel: "stylesheet", href: "https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css" }),
            includeSlickStyles && (React.createElement("link", { rel: "stylesheet", type: "text/css", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" })),
            includeSlickStyles && (React.createElement("link", { rel: "stylesheet", type: "text/css", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" })))));
};
export default Head;
