import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { initI18n, useIsMobile } from '../../utils';
import { AeroexpressIcon, transferLogo } from '../icons';
import Modal from '../../Modal';
import AdditionalServiceCard from '../../AdditionalServiceCard/components/AdditionalService';
import CardContent from './CardContent/CardContent';
import { useTheme } from '../../theme';
import { Money } from '../../index';
import Warning from '../../Checkout/components/Checkout/Upsale/Warning/Warning';
import { DisplayMode } from '../../AdditionalServices/components/types';
import { useAeroexpress } from './hooks';
import AeroexpressPopup from '../../CheckoutAdditionalService/components/AeroexpressPopup/AeroexpressPopup';
import Close from '../../CheckoutAdditionalService/components/Close/Close';
initI18n('Aeroexpress');
const Aeroexpress = ({ displayMode, isMobileStepbar, updateServices, orderId, aeroexpress, travellers, isLoading, isBlockForPayment }) => {
    var _a, _b;
    const { Aeroexpress: css } = useTheme('Aeroexpress');
    const { t } = useTranslation('Aeroexpress');
    const isMobile = useIsMobile();
    const isHorizontal = displayMode === DisplayMode.Horizontal;
    const { hasSelectedServices, tickets, buttonText, price, onClear, showWarning, isOpen, open, close, servicesRedirectURL, closeWarning } = useAeroexpress(updateServices, orderId);
    const service = {
        header: 'Transfer',
        subheader: t('Fast and convenient'),
        logo: isHorizontal ? null : React.createElement("div", { className: css.transferIcon }, transferLogo),
        price: price,
        active: hasSelectedServices,
        icon: AeroexpressIcon,
        content: isHorizontal ? (React.createElement(CardContent, { travellers: travellers, selectedServices: [] })) : (React.createElement(CardContent, { travellers: travellers, selectedServices: aeroexpress.selectedTrips }))
    };
    const renderClose = (onClick) => React.createElement(Close, { className: css.close, onClick: onClick });
    return (React.createElement(React.Fragment, null,
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        isMobileStepbar ? (React.createElement("span", null, isLoading ? (React.createElement(CircularProgress, { size: 20, color: "inherit", classes: { root: css.stepbar__progress } })) : ((_a = aeroexpress.selectedTrips) === null || _a === void 0 ? void 0 : _a.length) ? (React.createElement("span", { className: css.stepbar__actions },
            React.createElement(Money, { money: price }),
            !isBlockForPayment && (React.createElement("i", { onClick: ((_b = aeroexpress.selectedTrips) === null || _b === void 0 ? void 0 : _b.length) ? open : onClear, className: cn({
                    [css.stepbar__action_downloadTickets]: tickets.length,
                    [css.stepbar__action_close]: !tickets.length
                }) })))) : (!isBlockForPayment && React.createElement("span", { onClick: open }, t('Add'))))) : (React.createElement(AdditionalServiceCard, { horizontal: displayMode === DisplayMode.Horizontal, service: service, isLoading: isLoading, buttonText: buttonText, onClick: open })),
        React.createElement(Modal, { maxWidth: undefined, classes: { scrollBody: css.aeroexpress__scrollBody, paper: css.aeroexpress_paper }, slideUp: isMobile, fullScreen: isMobile, open: isOpen, onClose: close, closeButtonRenderer: renderClose },
            React.createElement(AeroexpressPopup, { trips: aeroexpress.trips, selectedTrips: aeroexpress.selectedTrips, isLoading: isLoading, onSubmit: updateServices, onClose: close }))));
};
export default Aeroexpress;
