import { ADD_SERVICE, SET_SELECTED_SERVICES, REMOVE_SERVICE, CLEAR_SERVICE, EQUALIZE_PASSENGER_BAGGAGE, REPLACE_SERVICE, SET_SERVICE } from './actions';
import { getRelatedSegments } from '../../utils';
export const setServicesReducer = (state, services) => {
    let newState = Object.assign({}, state);
    services.forEach(service => {
        var _a, _b, _c;
        newState = Object.assign(Object.assign({}, newState), { [service.passengerId]: Object.assign(Object.assign({}, ((_a = newState[service.passengerId]) !== null && _a !== void 0 ? _a : {})), { [service.serviceId]: Object.assign(Object.assign({}, ((_c = (_b = newState[service.passengerId]) === null || _b === void 0 ? void 0 : _b[service.serviceId]) !== null && _c !== void 0 ? _c : {})), { [service.segmentId]: service.count }) }) });
    });
    return newState;
};
export const addServiceReducer = (state, { passengerId, service, segmentId, count = 1 }) => {
    var _a;
    // List of passengers for which given service is ALLOWED to be added.
    const allowedPassengers = new Set(service.allowedPassengers.map(group => group[0]));
    // List of segments for which given service MUST be added.
    // Sometimes service should be added to a multiple segments at the same time.
    // This happens when service is tightly bound to a whole trip itself (to a "leg" in terms of Nemo),
    // rather than to a particular segment.
    const segmentsToAdd = getRelatedSegments(service.allowedSegments, segmentId);
    if (allowedPassengers.has(passengerId) && segmentsToAdd.size > 0) {
        // Deep copy of the state object.
        const result = Object.assign(Object.assign({}, state), { [passengerId]: Object.assign(Object.assign({}, state[passengerId]), { [service.id]: Object.assign({}, (_a = state[passengerId]) === null || _a === void 0 ? void 0 : _a[service.id]) }) });
        segmentsToAdd.forEach(segmentId => {
            var _a;
            const serviceCount = (_a = result[passengerId][service.id][segmentId]) !== null && _a !== void 0 ? _a : 0;
            result[passengerId][service.id][segmentId] = serviceCount + count;
        });
        return result;
    }
    return state;
};
export const removeServiceReducer = (state, { passengerId, service, segmentId }) => {
    var _a, _b, _c;
    // Check if we have any selected services.
    const couldBeDecreased = ((_b = (_a = state[passengerId]) === null || _a === void 0 ? void 0 : _a[service.id]) === null || _b === void 0 ? void 0 : _b[segmentId]) > 0;
    if (couldBeDecreased) {
        // List of segments for which given service MUST be decreased.
        // Sometimes service should be decreased for multiple segments at the same time.
        // This happens when service is tightly bound to a whole trip itself (to a "leg" in terms of Nemo),
        // rather than to a particular segment.
        const segmentsToDecrease = getRelatedSegments(service.allowedSegments, segmentId);
        if (segmentsToDecrease.size > 0) {
            // Deep copy of the state object.
            const result = Object.assign(Object.assign({}, state), { [passengerId]: Object.assign(Object.assign({}, state[passengerId]), { [service.id]: Object.assign({}, (_c = state[passengerId]) === null || _c === void 0 ? void 0 : _c[service.id]) }) });
            segmentsToDecrease.forEach(segmentId => {
                var _a, _b;
                const serviceCount = (_b = (_a = result[passengerId]) === null || _a === void 0 ? void 0 : _a[service.id]) === null || _b === void 0 ? void 0 : _b[segmentId];
                if (serviceCount > 0) {
                    result[passengerId][service.id][segmentId] = serviceCount - 1;
                }
            });
            return result;
        }
    }
    return state;
};
export const setSelectedServicesReducer = (passengers) => {
    const result = {};
    passengers.forEach(passenger => {
        var _a, _b;
        const services = (_b = (_a = passenger === null || passenger === void 0 ? void 0 : passenger.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services;
        const passengerId = passenger.id;
        if (services) {
            services.forEach(({ segmentIds, serviceId, count }) => {
                if (!result.hasOwnProperty(passengerId)) {
                    result[passengerId] = {};
                }
                if (!result[passengerId].hasOwnProperty(serviceId)) {
                    result[passengerId][serviceId] = {};
                }
                segmentIds.forEach(segmentId => {
                    if (!result[passengerId][serviceId].hasOwnProperty(segmentId)) {
                        result[passengerId][serviceId][segmentId] = 0;
                    }
                    result[passengerId][serviceId][segmentId] += count;
                });
            });
        }
    });
    return result;
};
export const clearServiceReducer = (state, { passengerId, service, segmentId }) => {
    var _a, _b, _c;
    // Check if we have any selected services.
    const couldBecleared = ((_b = (_a = state[passengerId]) === null || _a === void 0 ? void 0 : _a[service.id]) === null || _b === void 0 ? void 0 : _b[segmentId]) > 0;
    if (couldBecleared) {
        // List of segments for which given service MUST be decreased.
        // Sometimes service should be decreased for multiple segments at the same time.
        // This happens when service is tightly bound to a whole trip itself (to a "leg" in terms of Nemo),
        // rather than to a particular segment.
        const segmentsToClear = getRelatedSegments(service.allowedSegments, segmentId);
        if (segmentsToClear.size > 0) {
            // Deep copy of the state object.
            const result = Object.assign(Object.assign({}, state), { [passengerId]: Object.assign(Object.assign({}, state[passengerId]), { [service.id]: Object.assign({}, (_c = state[passengerId]) === null || _c === void 0 ? void 0 : _c[service.id]) }) });
            segmentsToClear.forEach(segmentId => {
                result[passengerId][service.id][segmentId] = 0;
            });
            return result;
        }
    }
    return state;
};
export const equalizePassengerBaggageReducer = (state, { passengerId, baggageList, segmentsList }) => {
    if (state.hasOwnProperty(passengerId)) {
        const result = Object.assign(Object.assign({}, state), { [passengerId]: Object.assign({}, state[passengerId]) });
        const passengerServices = result[passengerId];
        for (const serviceId in passengerServices) {
            if (passengerServices.hasOwnProperty(serviceId) && baggageList.has(serviceId)) {
                const servicesBySegments = passengerServices[serviceId];
                let maxServiceCount = 0;
                // Find the max count of selected services.
                for (const segmentId in servicesBySegments) {
                    if (servicesBySegments.hasOwnProperty(segmentId) &&
                        maxServiceCount < servicesBySegments[segmentId]) {
                        maxServiceCount = servicesBySegments[segmentId];
                    }
                }
                // Set found max service count for all segments.
                segmentsList.forEach(segmentId => (result[passengerId][serviceId][segmentId] = maxServiceCount));
            }
        }
        return result;
    }
    return state;
};
export const replaceServiceReducer = (state, { newService, oldService, passengerId, segmentsList }) => {
    if (state.hasOwnProperty(passengerId)) {
        let hasOldServices = false;
        const result = Object.assign(Object.assign({}, state), { [passengerId]: Object.assign({}, state[passengerId]) });
        if (result[passengerId].hasOwnProperty(oldService.id)) {
            hasOldServices = true;
        }
        if (!result[passengerId].hasOwnProperty(newService.id)) {
            result[passengerId][newService.id] = {};
        }
        segmentsList.forEach(segmentId => {
            // Remove old services.
            if (hasOldServices && result[passengerId][oldService.id].hasOwnProperty(segmentId)) {
                result[passengerId][oldService.id][segmentId] = 0;
            }
            const segmentIsAllowed = getRelatedSegments(newService.allowedSegments, segmentId).size > 0;
            if (segmentIsAllowed) {
                // Add new services.
                result[passengerId][newService.id][segmentId] = 1;
            }
        });
        return result;
    }
    return state;
};
export const selectedServicesReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_SERVICE:
            return setServicesReducer(state, action.payload);
        case ADD_SERVICE:
            return addServiceReducer(state, action.payload);
        case REMOVE_SERVICE:
            return removeServiceReducer(state, action.payload);
        case CLEAR_SERVICE:
            return clearServiceReducer(state, action.payload);
        case SET_SELECTED_SERVICES:
            return setSelectedServicesReducer(action.payload);
        case REPLACE_SERVICE:
            return replaceServiceReducer(state, action.payload);
        case EQUALIZE_PASSENGER_BAGGAGE:
            return equalizePassengerBaggageReducer(state, action.payload);
    }
    return state;
};
