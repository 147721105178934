const defaultIsEqual = (a, b) => a === b;
export const memes = (fn, isEqual = defaultIsEqual) => {
    let lastArgs = [];
    let lastResult;
    let calledOnce = false;
    const isNewArgEqualToLast = (newArg, index) => isEqual(newArg, lastArgs[index]);
    return function (...newArgs) {
        if (calledOnce && newArgs.length === lastArgs.length && newArgs.every(isNewArgEqualToLast)) {
            return lastResult;
        }
        lastResult = fn(...newArgs);
        calledOnce = true;
        lastArgs = newArgs;
        return lastResult;
    };
};
export default memes;
