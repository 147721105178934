import { createSelector } from 'reselect';
// import { getBaggageServices, getIsInsuranceSelected, getIsTransferSelected, getServices } from '../order/selectors';
import { getIsInsuranceSelected, getIsTransferSelected } from '../order/selectors';
import { ServiceType } from '../../components/Checkout/OrderPaidServiceCards/types';
import { getPassengerStateCompatible } from '../selectedServices/selectors';
import { capitalizeFirstLetter, getPassengerLabel, getPassengerNames } from '../../../utils';
import { getExtraSeatServices } from '../../../Modules/Checkin/store/order/selectors';
import { OrderAdditionalServiceGdsServiceServiceType, TravellerFieldEnum as OldTravellerFieldEnum, TravellerFieldEnum as NewTravellerFieldEnum } from '@websky/graphql';
// A workaround to fix cyclic dependencies. Fix this in the future.
const getOrder = (state) => state.order;
const getServices = createSelector(getOrder, order => { var _a, _b; return (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) !== null && _b !== void 0 ? _b : []; });
const getBaggageServices = createSelector(getServices, (services) => services.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage));
// Workaround end
export const getPassengerState = (state) => state.passengers;
export const getPassengerLabels = createSelector(getPassengerState, (passengers) => {
    const result = {};
    const passengersMap = {};
    passengers.forEach(passenger => {
        var _a;
        const passengerType = (_a = passenger.supplierTravellerType) !== null && _a !== void 0 ? _a : passenger.type;
        if (!passengersMap.hasOwnProperty(passengerType)) {
            passengersMap[passengerType] = 0;
        }
        const passengerIndex = ++passengersMap[passengerType];
        const { firstName, lastName } = getPassengerNames(passenger);
        if (firstName && lastName) {
            result[passenger.id] = [lastName, firstName].map(capitalizeFirstLetter).join(' ');
        }
        else {
            result[passenger.id] = `${getPassengerLabel(passenger)} ${passengerIndex}`;
        }
    });
    return result;
});
export const getPassengerLastName = (passenger) => {
    var _a;
    return (_a = passenger.values) === null || _a === void 0 ? void 0 : _a.find(({ type, value }) => type === NewTravellerFieldEnum.LastName && value);
};
export const getPassengerFirstName = (passenger) => {
    var _a;
    return (_a = passenger.values) === null || _a === void 0 ? void 0 : _a.find(({ value, type }) => type === NewTravellerFieldEnum.FirstName && value);
};
export const getPassengerMiddleName = (passenger) => {
    var _a;
    return (_a = passenger.values) === null || _a === void 0 ? void 0 : _a.find(({ value, type }) => type === NewTravellerFieldEnum.MiddleName && value);
};
export const getPassengerNationalities = createSelector(getPassengerState, (passengers) => {
    const result = new Map();
    const nationalityField = passengers[0].values.find(field => field.type === OldTravellerFieldEnum.Nationality);
    nationalityField &&
        nationalityField.validationRules &&
        nationalityField.validationRules[0].options.forEach(option => {
            if (!result.hasOwnProperty(option.value)) {
                result[option.value] = option.label;
            }
        });
    return result;
});
export const makeHasAnySelectedBaggage = (passengerId) => createSelector(getPassengerStateCompatible, getBaggageServices, (passengers, baggageServices) => {
    return passengers[passengerId].services.some(selectedService => baggageServices.some(baggage => baggage.id === selectedService.serviceId));
});
export const createSelectedBaggage = (passengers, baggageServices) => {
    const result = [];
    passengers.forEach(({ id, services }) => {
        const selectedBaggage = {};
        services.forEach(selectedService => {
            if (baggageServices.some(baggage => baggage.id === selectedService.serviceId)) {
                selectedService.segmentIds.forEach(segmentId => {
                    if (!selectedBaggage.hasOwnProperty(selectedService.serviceId)) {
                        selectedBaggage[selectedService.serviceId] = {};
                    }
                    if (selectedBaggage[selectedService.serviceId].hasOwnProperty(segmentId)) {
                        selectedBaggage[selectedService.serviceId][segmentId].count += selectedService.count;
                    }
                    else {
                        selectedBaggage[selectedService.serviceId][segmentId] = Object.assign({}, selectedService);
                    }
                });
            }
        });
        result[id] = selectedBaggage;
    });
    return result;
};
export const makeHasSelectedService = (type) => {
    return createSelector(getServices, getIsInsuranceSelected, getIsTransferSelected, getPassengerStateCompatible, (orderServices, isInsuranceSelected, isTransferSelected, passengers) => {
        return passengers.some(passenger => passenger.services.some(travellerService => {
            switch (type) {
                case ServiceType.Baggage:
                    return orderServices.find(orderService => orderService.id === travellerService.serviceId &&
                        orderService.type === OrderAdditionalServiceGdsServiceServiceType.Baggage);
                case ServiceType.Meal:
                    return orderServices.find(orderService => orderService.id === travellerService.serviceId &&
                        orderService.type === OrderAdditionalServiceGdsServiceServiceType.Meal);
                case ServiceType.Seats:
                    return orderServices.find(orderService => orderService.id === travellerService.serviceId &&
                        orderService.type === OrderAdditionalServiceGdsServiceServiceType.Seat);
                case ServiceType.Insurance:
                    return isInsuranceSelected;
                case ServiceType.Transfer:
                    return isTransferSelected;
            }
        }));
    });
};
export const getSelectedBaggage = createSelector(getPassengerStateCompatible, getBaggageServices, createSelectedBaggage);
export const createSelectedExtraSeats = (passengers, extraSeatServices) => {
    let selectedExtraSeats = [];
    const extraSeatIds = extraSeatServices.map(service => service.id);
    passengers.forEach(passenger => {
        var _a;
        (_a = passenger.services) === null || _a === void 0 ? void 0 : _a.forEach(service => {
            if (extraSeatIds.includes(service.serviceId)) {
                selectedExtraSeats = [
                    ...selectedExtraSeats,
                    Object.assign(Object.assign({}, service), { passengerId: passenger.id })
                ];
            }
        });
    });
    return selectedExtraSeats;
};
export const getSelectedExtraSeats = createSelector(getPassengerStateCompatible, getExtraSeatServices, createSelectedExtraSeats);
