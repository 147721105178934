import * as React from 'react';
import { ButtonBase } from '@material-ui/core';
import { Add } from '../../../Icons';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useDefaultTravellerFieldsQuery } from '@websky/graphql';
import DocumentFieldsItem from './DocumentFieldsItem/DocumentFieldsItem';
import { useForm } from 'react-final-form';
const DocumentsSection = ({ traveller, documents, primaryCountryCodes }) => {
    var _a, _b;
    const { DocumentsSection: css } = useTheme('TravellerForm');
    const { t: tTraveller } = useTranslation('Traveller');
    const form = useForm();
    const { data: documentsValidations } = useDefaultTravellerFieldsQuery();
    const documentValidators = (_b = (_a = documentsValidations === null || documentsValidations === void 0 ? void 0 : documentsValidations.DefaultTravellerProfile) === null || _a === void 0 ? void 0 : _a.documents) === null || _b === void 0 ? void 0 : _b[0];
    const addDocumentHandler = () => form.mutators.documentsMutator({
        type: 'add'
    });
    const removeDocumentHandler = (id) => {
        form.mutators.documentsMutator({
            type: 'remove',
            index: id
        });
    };
    const documentFieldsItems = documents.map((document, index) => (React.createElement(DocumentFieldsItem, { key: index, id: index, document: document, validators: documentValidators, traveller: traveller, primaryCountryCodes: primaryCountryCodes, onRemove: documents.length > 1 ? removeDocumentHandler : undefined })));
    return (React.createElement("div", { className: css.documentsSection },
        React.createElement("div", { className: css.documentsSection__fields }, !!documents.length ? (documentFieldsItems) : (React.createElement("div", { className: css.documentsSection__emptyWarning }, tTraveller('You need to add a document')))),
        React.createElement("div", { className: css.documentsSection__controls },
            React.createElement("div", { className: css.documentsSection__addFields, onClick: addDocumentHandler },
                React.createElement(ButtonBase, { classes: {
                        root: css.buttonAdd
                    } }, Add),
                tTraveller('Add document')))));
};
export default DocumentsSection;
