import { ApolloLink } from 'apollo-boost';
const ignoredErrors = [new RegExp('NUMBER OF SERVICES MISMATCH WITH SSR SEAT')];
const ignoredErrorsLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        var _a;
        if (!!((_a = response === null || response === void 0 ? void 0 : response.errors) === null || _a === void 0 ? void 0 : _a.length)) {
            let ignoredErrorsCount = 0;
            response.errors.forEach(error => {
                if (ignoredErrors.some(regError => regError.test(error.message))) {
                    ignoredErrorsCount++;
                }
            });
            if (response.errors.length === ignoredErrorsCount) {
                delete response.errors;
            }
        }
        return response;
    });
});
export default ignoredErrorsLink;
