import OrderCard from './components/OrderCard.css';
import OrderPreview from './components/OrderPreview/OrderPreview.css';
import OrderStatus from './components/OrderStatus/OrderStatus.css';
import OrderBooking from './components/OrderBooking/OrderBooking.css';
import OrderPassengers from './components/OrderPassengers/OrderPassengers.css';
import OrderChange from './components/OrderChange/OrderChange.css';
import * as OrderCardControls from './components/OrderCardControls/OrderCardControls.css';
import * as OrderTickets from './components/OrderTickets/OrderTickets.css';
import * as CommonItem from './components/CommonItem/CommonItem.css';
import * as PaymentStatus from './components/PaymentStatus/PaymentStatus.css';
import PassengersModal from './components/OrderPassengers/PassengersModal/PassengersModal.css';
import DeleteOrderModal from './components/DeleteOrderModal/DeleteOrderModal.css';
export default {
    OrderCard,
    OrderPreview,
    OrderStatus,
    OrderBooking,
    OrderPassengers,
    OrderCardControls,
    OrderTickets,
    PassengersModal,
    CommonItem,
    PaymentStatus,
    OrderChange,
    DeleteOrderModal
};
