import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { format } from '../../utils';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import Status from './Status/Status';
const TicketRequest = ({ className, breadcrumbsClassName, requestId, createdDate, breadcrumbs, status }) => {
    const { t } = useTranslation('Refund');
    const { TicketRequest: theme } = useTheme('TicketRequest');
    const hasOrderInfoRow = requestId && createdDate && status;
    return (React.createElement("div", { className: cn(theme.container, className) },
        hasOrderInfoRow && (React.createElement("div", { className: theme.row },
            React.createElement("div", { className: cn(theme.col, theme.request) }, t('Request #{{requestId}}', { requestId })),
            React.createElement("div", { className: cn(theme.col, theme.date) }, format(createdDate, 'dd MMM yyyy HH:mm')),
            React.createElement("div", { className: cn(theme.col, theme.status) },
                React.createElement(Status, { status: status })))),
        React.createElement("div", { className: cn(theme.row, breadcrumbsClassName) },
            React.createElement("div", { className: theme.col },
                React.createElement(Breadcrumbs, { breadcrumbs: breadcrumbs })))));
};
export default TicketRequest;
