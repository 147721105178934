import { useMemo } from 'react';
export const useVariables = (state, service) => {
    const totalPrice = useMemo(() => {
        var _a, _b;
        const price = Object.assign({}, service.price);
        price.amount = 0;
        for (const [passengerId] of Object.keys(state)) {
            for (const [segmentId] of Object.keys(state[passengerId])) {
                if (((_a = state[passengerId][segmentId]) === null || _a === void 0 ? void 0 : _a.selected) && !((_b = state[passengerId][segmentId]) === null || _b === void 0 ? void 0 : _b.confirmed)) {
                    price.amount += service.price.amount;
                }
            }
        }
        return price;
    }, [state]);
    return {
        totalPrice,
        minPrice: service.price
    };
};
