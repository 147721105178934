import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
const Disabilities = props => {
    const { PassengersDetails: css } = useTheme('PassengersDetails');
    const { t } = useTranslation('OrderCard');
    if (!props.disabilities.length) {
        return null;
    }
    return (React.createElement("div", { className: css.disabilities },
        React.createElement("div", { className: css.disabilities__header }, t('Additional information')),
        props.disabilities.map(disability => (React.createElement("div", { key: disability.id },
            React.createElement("span", null, disability.id),
            React.createElement("span", null, "\u00A0\u2014\u00A0"),
            React.createElement("span", null, disability.name))))));
};
export default Disabilities;
