import * as React from 'react';
import CommonCard from '../../CheckoutAdditionalService/components/EveryPortLounge/CommonCard/CommonCard';
import { NASProductCategory, NASStatus } from '@websky/graphql';
import { getNasServiceCardParams } from '../../CheckoutAdditionalService/components/EveryPortLounge/utils';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { Money } from '../../Money';
import Notice from '../../RefundSummary/components/Notice/Notice';
const NasServiceCard = ({ type, serviceName, passengerNames, ticketsNumber, travellers, totalPrice, error }) => {
    const params = getNasServiceCardParams(type);
    const { t } = useTranslation('Refund');
    const { t: aasT } = useTranslation('ServiceAggregation');
    const theme = useTheme('CheckoutAdditionalService');
    const coverClassName = React.useMemo(() => {
        if (type === NASProductCategory.vip) {
            return theme.Vip.vip;
        }
        if (type === NASProductCategory.lounge) {
            return theme.Business.business_aggregator;
        }
        if (type === NASProductCategory.meet_and_greet) {
            return theme.MeetAndGreet.meet_and_greet;
        }
        if (type === NASProductCategory.fast_track) {
            return theme.FastTrack.fast_track_aggregator;
        }
        return null;
    }, [theme, type]);
    const renderHeaderPrice = () => {
        return (React.createElement("span", null,
            t('{{count}} tickets', { count: ticketsNumber }),
            ": ",
            React.createElement(Money, { money: totalPrice })));
    };
    return (React.createElement(CommonCard, { header: t(params.title), description: t(params.description), coverClassName: coverClassName, isSelected: true, isModifyAvailable: false, icons: params.icons, totalPrice: totalPrice, travellers: travellers, serviceType: type, selectedServicesText: [serviceName], selectedServicesPassengerNames: passengerNames.map(name => ({
            name,
            status: NASStatus.Confirmed
        })), selectedServicesDetailsLink: null, hideButtons: true, renderHeaderPrice: renderHeaderPrice },
        !error && aasT('Entrance with boarding ticket'),
        !!error && React.createElement(Notice, { type: "notice", header: t('Not refunded'), text: t(error) })));
};
export default NasServiceCard;
