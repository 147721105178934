const hasCountPropGuard = (object) => {
    return object.hasOwnProperty('count');
};
class BaseDiff {
    constructor(orderBeforeChange, orderAfterChange) {
        this.getTravellersServicesDiff = (beforeChange, afterChange, getId) => {
            const added = [];
            const removed = [];
            const beforeChangeMap = new Map();
            beforeChange === null || beforeChange === void 0 ? void 0 : beforeChange.forEach(service => beforeChangeMap.set(getId(service), service));
            const afterChangeMap = new Map();
            afterChange === null || afterChange === void 0 ? void 0 : afterChange.forEach(service => afterChangeMap.set(getId(service), service));
            afterChange === null || afterChange === void 0 ? void 0 : afterChange.forEach(afterChangeService => {
                const beforeChangeService = beforeChangeMap.get(getId(afterChangeService));
                if (!beforeChangeService) {
                    added.push(afterChangeService);
                }
                else if (hasCountPropGuard(afterChangeService) && hasCountPropGuard(beforeChangeService)) {
                    const after = afterChangeService;
                    const before = beforeChangeService;
                    if (after.count > before.count) {
                        added.push(Object.assign(Object.assign({}, after), { count: after.count - before.count }));
                    }
                    else if (after.count < before.count) {
                        removed.push(Object.assign(Object.assign({}, before), { count: before.count - after.count }));
                    }
                }
            });
            beforeChange === null || beforeChange === void 0 ? void 0 : beforeChange.forEach(beforeChangeService => {
                const serviceAfterChange = afterChangeMap.get(getId(beforeChangeService));
                if (!serviceAfterChange) {
                    removed.push(beforeChangeService);
                }
            });
            return { added, removed };
        };
        this.orderBeforeChange = orderBeforeChange;
        this.orderAfterChange = orderAfterChange;
    }
}
export default BaseDiff;
