import { __awaiter } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useCreateRefundOrder } from '../Refund/hooks';
import PromoLoader from '../../../PromoLoader';
const CreateTicket = props => {
    const { params } = props;
    const [createRefundMutation] = useCreateRefundOrder();
    const [isLoading, setIsLoading] = useState(true);
    const createRefund = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { data } = yield createRefundMutation({ variables: { params } });
            if (data === null || data === void 0 ? void 0 : data.CreateRefund) {
                props.onCreate(data.CreateRefund);
            }
        }
        catch (e) {
            const error = e;
            (_a = props.onError) === null || _a === void 0 ? void 0 : _a.call(props, error.graphQLErrors);
        }
        finally {
            setIsLoading(false);
        }
    });
    useEffect(() => {
        createRefund();
    }, []);
    if (isLoading) {
        return React.createElement(PromoLoader, null);
    }
    return null;
};
export default CreateTicket;
