import * as React from 'react';
import { parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { TemperatureUnits } from '@websky/graphql';
import { useTheme } from '../../theme';
import { getTemperaturePrefix } from '../../Weather/utils';
import { DepartureIcon, ArrivalIcon, DetailsIcon } from './Icons';
import { weatherIcons } from '../../Weather/icons';
import { format, initI18n } from '../../utils';
initI18n('Location');
const Location = ({ type, airport, date, time, terminal, weather, onDetailsClick, className }) => {
    var _a, _b, _c;
    const { Location: css } = useTheme('Location');
    const { t } = useTranslation('Location');
    const flightDate = parse(date, 'yyyy-MM-dd', new Date());
    const weatherIcon = (weather === null || weather === void 0 ? void 0 : weather.type) && weatherIcons[weather.type];
    const directionIcon = type === 'departure' ? DepartureIcon : ArrivalIcon;
    const image = (_b = (_a = airport.images) === null || _a === void 0 ? void 0 : _a.panorama) === null || _b === void 0 ? void 0 : _b.url;
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.airport },
                image && React.createElement("div", { className: css.direction }, directionIcon),
                React.createElement("div", { className: css.airport__iata }, airport.iata),
                React.createElement("div", { className: css.airport__name }, (_c = airport.city) === null || _c === void 0 ? void 0 :
                    _c.name,
                    ",",
                    React.createElement("br", null),
                    airport.title)),
            React.createElement("div", { className: css.bottom },
                React.createElement("span", { className: css.when },
                    React.createElement("span", null,
                        time,
                        ","),
                    React.createElement("span", { className: css.date }, format(flightDate, 'dd MMM'))),
                terminal && (React.createElement("span", { className: css.terminal },
                    t('Terminal'),
                    " ",
                    React.createElement("span", { className: css.terminal__code }, terminal))))),
        React.createElement("div", { className: css.footer, style: image ? { backgroundImage: `url(${image})` } : {} },
            !image && React.createElement("div", { className: css.footer__direction }, directionIcon),
            weather && (React.createElement("div", { className: css.weather },
                weatherIcon,
                React.createElement("span", { className: css.weather__value },
                    getTemperaturePrefix(weather.temperature),
                    Math.abs(Math.round(weather.temperature)),
                    "\u00B0",
                    weather.measurement === TemperatureUnits.Fahrenheit && 'F'))),
            onDetailsClick && (React.createElement("div", { className: css.details, onClick: () => onDetailsClick(airport.id) },
                t('Details'),
                " ",
                DetailsIcon)))));
};
export default Location;
