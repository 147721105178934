import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFlightsToExchange, getSelectedPassengers } from '../../../../store/selectors';
import Chip from './Chip/Chip';
import { useTheme } from '../../../../../../theme';
import { getPassengerName } from '../../utils';
import { ExchangeStep } from '../../../../types';
import { getPassengers } from '../../../../store/order/selectors';
import { getSelectedReason } from '../../../../store/reason/selectors';
import { useConfig } from '../../../../../../context';
const Selections = ({ setStep }) => {
    const { t } = useTranslation('Exchange');
    const location = useLocation();
    const exareShowPassengersStep = useConfig().Engine.exareShowPassengersStep;
    const passengers = useSelector(getPassengers);
    const isOnlyPassenger = passengers.length === 1;
    const selectedPassengers = useSelector(getSelectedPassengers);
    const selectedSegments = useSelector(getFlightsToExchange);
    const selectedReason = useSelector(getSelectedReason);
    const theme = useTheme('Exchange').Selections;
    const toPassengers = () => {
        if (!isOnlyPassenger) {
            setStep(ExchangeStep.Passengers);
        }
    };
    const toSegments = () => setStep(ExchangeStep.Segments);
    const toReasons = () => setStep(ExchangeStep.Reasons);
    const isPassengerPage = location.pathname.indexOf('passenger') >= 0;
    const isFlightPage = location.pathname.indexOf('segments') >= 0;
    const isReasonsPage = location.pathname.indexOf('reasons') >= 0;
    return (React.createElement("div", { className: theme.wrapper },
        !isPassengerPage &&
            selectedPassengers.map(passenger => (React.createElement(Chip, { label: getPassengerName(passenger), onClick: toPassengers, key: `p_${passenger.id}`, readonly: isOnlyPassenger || !exareShowPassengersStep, type: "passenger" }))),
        !isReasonsPage && (React.createElement(Chip, { className: theme.reasons, label: t(selectedReason), onClick: toReasons, type: "passenger" })),
        !isFlightPage &&
            !isPassengerPage &&
            selectedSegments.flightsByIds.map(segment => {
                return (React.createElement(Chip, { label: `${segment.segments[0].departure.airport.city.name} - ${segment.segments[segment.segments.length - 1].arrival.airport.city.name}`, onClick: toSegments, key: `s_${segment.groupId}`, type: "flight" }));
            })));
};
export default Selections;
