import * as React from 'react';
import Gateway from '../Gateway';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { useCallback, useEffect, useMemo } from 'react';
import SimpleLoader from '../../../../SimpleLoader';
import { useToggleable } from '../../../../hooks';
import { useConfig } from '../../../../context';
import { OverrideComponent } from '../../../../renderProps';
const Iframe = ({ type, frameInfo, onClick, isPreOpened = false, children, headerTitle, gatewayTitle, onPaymentFrameLoaded, isLoading, variant, onStartRedirecting, openFrameInNewTab }) => {
    var _a;
    const { Card: css } = useTheme('PaymentForm');
    const { t } = useTranslation('PaymentForm');
    const { global: { companyInfo: { iataCode } } } = useConfig();
    const frameRef = React.useRef();
    // Ipad fix
    const isIpad = navigator.userAgent.indexOf('iPad') !== -1;
    const { toggle: changeFrameState, isOpen: frameChanged } = useToggleable(false);
    (_a = frameRef === null || frameRef === void 0 ? void 0 : frameRef.current) === null || _a === void 0 ? void 0 : _a.setAttribute('allowpaymentrequest', '');
    useEffect(() => {
        let interval = undefined;
        if (frameRef.current) {
            interval = setInterval(() => {
                var _a;
                try {
                    if (!isIpad && ((_a = frameRef === null || frameRef === void 0 ? void 0 : frameRef.current) === null || _a === void 0 ? void 0 : _a.contentWindow)) {
                        if (frameRef.current.contentWindow.location.hash) {
                            clearInterval(interval);
                            window.location = frameRef.current.contentWindow.location;
                        }
                    }
                }
                catch (_b) { }
            }, 100);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [frameRef, isLoading, frameInfo.frameUrl, frameChanged]);
    const iframeSandbox = useMemo(() => {
        if (iataCode === 'DV') {
            return null;
        }
        return 'allow-downloads allow-same-origin allow-popups allow-top-navigation allow-forms allow-scripts allow-payment-request';
    }, [iataCode]);
    const handleOnClick = useCallback(() => {
        if (isIpad || (variant === 'page' && !!frameInfo.frameUrl)) {
            onStartRedirecting === null || onStartRedirecting === void 0 ? void 0 : onStartRedirecting();
            window.location.href = frameInfo.frameUrl;
        }
        else {
            onClick({
                type,
                title: isPreOpened ? t('Payment') : headerTitle !== null && headerTitle !== void 0 ? headerTitle : t('Pay by credit or debit card'),
                content: isLoading ? (React.createElement(SimpleLoader, null)) : (frameInfo === null || frameInfo === void 0 ? void 0 : frameInfo.frameUrl) ? (React.createElement("iframe", { onLoad: () => {
                        onPaymentFrameLoaded === null || onPaymentFrameLoaded === void 0 ? void 0 : onPaymentFrameLoaded();
                        changeFrameState();
                    }, ref: frameRef, src: frameInfo.frameUrl, sandbox: iframeSandbox, className: css.iframe, height: frameInfo.height })) : null
            });
        }
    }, [onClick, frameInfo, variant, iframeSandbox]);
    useEffect(() => {
        if (openFrameInNewTab && (frameInfo === null || frameInfo === void 0 ? void 0 : frameInfo.frameUrl)) {
            window.location.href = frameInfo.frameUrl;
        }
    }, [openFrameInNewTab, frameInfo]);
    useEffect(() => {
        if (isPreOpened) {
            handleOnClick();
        }
    }, [isPreOpened]);
    return (React.createElement(OverrideComponent, { componentProps: {
            onClick: handleOnClick,
            header: children,
            type: gatewayTitle !== null && gatewayTitle !== void 0 ? gatewayTitle : t('Credit or debit card'),
            paymentType: type
        }, component: { Gateway } }));
};
export default Iframe;
