import IMask from 'imask';
import { Mask } from './mask';
export function clearPhone(value) {
    return value.replace(/[-()\s]/g, '');
}
export function formatPhone(value) {
    const phoneValue = clearPhone(value);
    let mask = IMask.createMask(Mask.International);
    if (phoneValue.startsWith('79') ||
        phoneValue.startsWith('+79') ||
        phoneValue.startsWith('89') ||
        phoneValue.startsWith('+89')) {
        mask = IMask.createMask(Mask.RU);
    }
    return mask.resolve(phoneValue);
}
