import * as React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { createStore } from '../store';
import SearchForm from './SearchForm/SearchForm';
import { useGraphQLClient } from '../../graphql-client';
import { useConfig } from '../../context';
import { OverrideComponent } from '../../renderProps';
import ExternalLocaleResources from '../../ExternalLocaleResources/ExternalLocaleResources';
import Analytics from '../../Engine/components/Analytics';
import { bootstrap } from '../../shared/lib/bootstrap';
let store = null;
const Component = props => {
    const client = useGraphQLClient();
    bootstrap(useConfig());
    const { SearchForm: { proMode, passengersConfig, offerHotels } } = useConfig();
    if (!store) {
        store = createStore(props.defaultParameters, passengersConfig, proMode, offerHotels);
    }
    return (React.createElement(Provider, { store: store },
        React.createElement(ApolloProvider, { client: client },
            React.createElement(ExternalLocaleResources, null),
            React.createElement(Analytics, null),
            React.createElement(OverrideComponent, { component: { SearchFormWithStore: SearchForm }, componentProps: props }))));
};
export { store };
export default Component;
