import { __rest } from "tslib";
import React, { useCallback } from 'react';
import cn from 'classnames';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '../../../theme';
import Input from '../../Input';
const Dropdown = (_a) => {
    var { classes, InputProps, renderInput } = _a, restProps = __rest(_a, ["classes", "InputProps", "renderInput"]);
    const { Dropdown: theme } = useTheme('BaseComponents');
    const renderInputHandler = useCallback((params) => {
        var _a;
        const _b = InputProps !== null && InputProps !== void 0 ? InputProps : {}, { classes: inputClasses } = _b, restInputProps = __rest(_b, ["classes"]);
        return ((_a = renderInput === null || renderInput === void 0 ? void 0 : renderInput(params)) !== null && _a !== void 0 ? _a : (React.createElement(Input, Object.assign({ classes: Object.assign(Object.assign({}, inputClasses), { labelIn: cn(theme.labelIn, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.labelIn), withoutLabel: cn(theme.withoutLabel, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.withoutLabel), outlined: cn(theme.outlined, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.outlined) }) }, params, restInputProps))));
    }, [renderInput, InputProps]);
    return (React.createElement(Autocomplete, Object.assign({ classes: Object.assign(Object.assign({}, classes), { root: cn(theme.root, classes === null || classes === void 0 ? void 0 : classes.root), input: cn(theme.input, classes === null || classes === void 0 ? void 0 : classes.input), paper: cn(theme.paper, classes === null || classes === void 0 ? void 0 : classes.paper), listbox: cn(theme.listbox, classes === null || classes === void 0 ? void 0 : classes.listbox), option: cn(theme.option, classes === null || classes === void 0 ? void 0 : classes.option), endAdornment: cn(theme.endAdornment, classes === null || classes === void 0 ? void 0 : classes.endAdornment), popupIndicator: cn(theme.popupIndicator, classes === null || classes === void 0 ? void 0 : classes.popupIndicator) }), renderInput: renderInputHandler }, restProps)));
};
export default Dropdown;
