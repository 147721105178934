import memes from '../../../memes';
import { SeatComfort } from '@websky/graphql';
import { getSeatPrice } from '../../utils';
const isSelectedSeatGuard = (seat) => {
    return seat.hasOwnProperty('type') || seat.hasOwnProperty('deckId') || seat.hasOwnProperty('isConfirmed');
};
export const isFreeSeat = (seat) => {
    const seatPrice = getSeatPrice(seat);
    if (isSelectedSeatGuard(seat)) {
        return !seat.rfisc && (seatPrice === null || seatPrice === void 0 ? void 0 : seatPrice.amount) === 0;
    }
    else {
        return (seatPrice === null || seatPrice === void 0 ? void 0 : seatPrice.amount) === 0 && !seat.rfisc && seat.isAvailable && seat.isExistent;
    }
};
export const getRowService = memes((seats) => seats[0].isExistent && !seats[0].isAisle && seats[0].seatService);
export const cabinCodeComfortMap = new Map([
    ['C', SeatComfort.Comfort],
    ['Y', SeatComfort.Standard]
]);
const getRowComfort = (row) => {
    var _c;
    if (!((_c = row === null || row === void 0 ? void 0 : row.seats) === null || _c === void 0 ? void 0 : _c.length)) {
        return null;
    }
    const comfortCountMap = new Map();
    // Collect the count of available comfort levels by rows
    // to return the most frequently occurring comfort
    row.seats.forEach(seat => {
        var _c, _d;
        if (((_c = seat.seatService) === null || _c === void 0 ? void 0 : _c.comfort) && !seat.isAisle) {
            const comfort = seat.seatService.comfort;
            comfortCountMap.set(comfort, ((_d = comfortCountMap.get(comfort)) !== null && _d !== void 0 ? _d : 0) + (seat.isAvailable ? 1 : 0));
        }
    });
    if (comfortCountMap.size > 1) {
        // Returns the most frequently occurring comfort
        return [...comfortCountMap.entries()].sort(([_a, aCount], [_b, bCount]) => bCount - aCount)[0][0];
    }
    return [...comfortCountMap.keys()][0];
};
export const getRowsWithDifferentClasses = memes((rows, splitByCabin, isNewServicePredicate) => {
    const rowsWithDifferentClassesMap = new Map();
    const rowComfort = getRowComfort(rows[0]);
    if (rowComfort) {
        rowsWithDifferentClassesMap.set(rows[0].number, rowComfort);
    }
    rows.forEach((nextRow, index) => {
        var _c, _d;
        const prevRow = rows[index - 1];
        if (prevRow) {
            const lastRowNumber = [...rowsWithDifferentClassesMap.keys()].pop();
            const lastRowComfort = rowsWithDifferentClassesMap.get(lastRowNumber);
            const prevRowComfort = (_c = getRowComfort(prevRow)) !== null && _c !== void 0 ? _c : lastRowComfort;
            const rowComfort = (_d = getRowComfort(nextRow)) !== null && _d !== void 0 ? _d : lastRowComfort;
            const diffComfort = prevRowComfort !== rowComfort;
            if (isNewServicePredicate) {
                if (isNewServicePredicate(prevRow, nextRow)) {
                    rowsWithDifferentClassesMap.set(nextRow.number, rowComfort);
                }
            }
            else {
                if (splitByCabin) {
                    const diffCabinCode = prevRow.cabinCode !== nextRow.cabinCode;
                    if (diffCabinCode) {
                        rowsWithDifferentClassesMap.set(nextRow.number, cabinCodeComfortMap.get(nextRow.cabinCode));
                    }
                }
                else if (diffComfort) {
                    rowsWithDifferentClassesMap.set(nextRow.number, rowComfort);
                }
            }
        }
    });
    return rowsWithDifferentClassesMap;
});
export const getMinPriceOfServiceClass = (rows, firstIdxOfNewClass, comfort, rowsWithDifferentClasses) => {
    var _c;
    let minPrice = null;
    let rowIdx = firstIdxOfNewClass;
    let isNextClass = false;
    while (rowIdx < rows.length && !isNextClass) {
        const row = rows[rowIdx];
        row.parts.forEach(part => {
            var _c;
            if (((_c = part.service.seatService) === null || _c === void 0 ? void 0 : _c.comfort) === comfort &&
                (!minPrice || part.service.minPrice.amount < minPrice.amount)) {
                minPrice = part.service.minPrice;
            }
        });
        rowIdx++;
        isNextClass = rowsWithDifferentClasses.has((_c = rows[rowIdx]) === null || _c === void 0 ? void 0 : _c.number);
    }
    return minPrice;
};
export const getActualService = (services, part) => {
    var _c, _d, _e, _f;
    // пробуем найти по максимальному количеству однотипных сервисов
    const serviceComfortCount = new Map();
    for (const seat of part.seats.values()) {
        if (seat.seatService) {
            let countItem = serviceComfortCount.get(seat.seatService.comfort);
            if (!countItem) {
                countItem = { serviceId: seat.seatService.id, count: 0 };
                serviceComfortCount.set(seat.seatService.comfort, countItem);
            }
            countItem.count++;
        }
    }
    let maxCount = 0;
    let serviceId = null;
    serviceComfortCount.forEach(item => {
        if (item.count > maxCount) {
            maxCount = item.count;
            serviceId = item.serviceId;
        }
    });
    if (serviceId) {
        if (services === null || services === void 0 ? void 0 : services.hasOwnProperty(serviceId)) {
            return (_c = services[serviceId]) === null || _c === void 0 ? void 0 : _c.comfort;
        }
        else {
            return (_e = (_d = part.seats.find(seat => { var _c; return ((_c = seat.seatService) === null || _c === void 0 ? void 0 : _c.id) === serviceId; })) === null || _d === void 0 ? void 0 : _d.seatService) === null || _e === void 0 ? void 0 : _e.comfort;
        }
    }
    // тогда по первому существующему
    const firstExistService = part.seats.find(seat => seat.seatService);
    if (firstExistService === null || firstExistService === void 0 ? void 0 : firstExistService.seatService) {
        return (_f = services[firstExistService.seatService.id]) === null || _f === void 0 ? void 0 : _f.comfort;
    }
    return null;
};
