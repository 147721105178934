import * as React from 'react';
import { useCallback } from 'react';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import Switch from '../../../Switch';
import { Money as MoneyComponent } from '../../../Money';
import cn from 'classnames';
const SwitchBlock = ({ price, isActive, className, onChange, renderSwitch }) => {
    var _a;
    const { SwitchBlock: css } = useTheme('FareLock');
    const { t } = useTranslation('FareLock');
    const onClick = useCallback(() => {
        if (onChange) {
            onChange(!isActive);
        }
    }, [isActive, onChange]);
    return (React.createElement("div", { className: cn(css.root, className) },
        React.createElement("div", { className: css.switch_wrapper }, (_a = renderSwitch === null || renderSwitch === void 0 ? void 0 : renderSwitch({ isActive, onClick })) !== null && _a !== void 0 ? _a : (React.createElement(Switch, { labelClassName: css.switch_label, mainClassName: css.switch_control, activeClassName: css.switch_active, label: t('Lock fare'), isActive: isActive, switchPosition: 'right', onClick: onClick }))),
        React.createElement("div", { className: css.price_wrapper },
            React.createElement("div", { className: css.price_label }, t('only')),
            React.createElement("div", { className: css.price },
                React.createElement(MoneyComponent, { money: price })))));
};
export default SwitchBlock;
