import { SET_LOADING_OFF, SET_LOADING_ON } from './actions';
export default (state, action) => {
    switch (action.type) {
        case SET_LOADING_ON:
            return true;
        case SET_LOADING_OFF:
            return true;
        default:
            return false;
    }
};
