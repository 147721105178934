import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import AiPredictionItemWrapper from '../AiPredictionItemWrapper/AiPredictionItemWrapper';
import { convertBaggageObject } from '../../../Checkout/types';
import BaggageIcon from '../../../Baggage/Baggage/components/BaggageIcon/BaggageIcon';
const AiPurchasePredictionBaggage = ({ item, onChange, onDisagree, onAgree }) => {
    const theme = useTheme('AIPurchasePredictionService').AiPredictionItemWrapper;
    const { t } = useTranslation('AIPurchasePredictionService');
    const baggage = useMemo(() => {
        return convertBaggageObject(item);
    }, [item]);
    return (React.createElement(AiPredictionItemWrapper, { headerTitle: t('Looks like you forgot to add your baggage'), buttonTitle: t('Continue without baggage'), service: {
            price: baggage.price,
            name: baggage.name,
            renderHead: () => {
                return React.createElement(BaggageIcon, { className: theme.predictionCard__baggage, realSize: true, baggage: baggage });
            },
            renderDesc: () => {
                return React.createElement("span", null, baggage.description);
            }
        }, onDisagree: onDisagree, onAgree: onAgree, onChange: onChange }));
};
export default AiPurchasePredictionBaggage;
