import { useMemo } from 'react';
import { isCheckoutOrderGuard } from '../../typeguards';
import { isCheckinFlightGuard } from './utils';
export const useSelectors = (state, order, onContinueWithoutSelection) => {
    const saveOrderServicesVariables = useMemo(() => {
        const service = state.service;
        const travellers = state.travellersIds;
        const variables = {
            params: {
                id: state.orderId,
                services: travellers.map(travellerId => ({
                    travellerId: travellerId,
                    gdsServices: {
                        addServices: state.selectedSegments.map(segmentId => ({
                            segmentIds: [segmentId],
                            count: 1,
                            serviceId: service.id
                        }))
                    }
                }))
            }
        };
        return variables;
    }, [state.selectedSegments]);
    const transferDurationBySegments = useMemo(() => {
        const transferDurationBySegmentId = {};
        if (isCheckoutOrderGuard(order)) {
            for (const segment of order.flight.segments) {
                transferDurationBySegmentId[segment.segment.id] = segment.transferDuration;
            }
            return transferDurationBySegmentId;
        }
        else {
            return {};
        }
    }, [order]);
    const refundSummaryParams = useMemo(() => {
        return {
            orderId: order.id,
            passengers: order.travellers,
            selectedPassengerIds: state.service.allowedPassengers.map(group => group[0]),
            selectedSegmentIds: state.selectedSegments,
            allFlightToExchange: isCheckoutOrderGuard(order)
                ? order.flight.segments.length === state.selectedSegments.length
                : order.segments.length === state.selectedSegments.length
        };
    }, [order, state]);
    const nextButtonDisabled = useMemo(() => {
        if (onContinueWithoutSelection) {
            return false;
        }
        return state.selectedSegments.length === 0;
    }, [onContinueWithoutSelection, state.selectedSegments]);
    const isAllowInstantSelect = useMemo(() => {
        var _a;
        return !isCheckinFlightGuard(state.flights[0])
            ? state.flights.length === 1 && ((_a = state.flights[0].segments) === null || _a === void 0 ? void 0 : _a.length) === 1
            : false;
    }, [order]);
    return {
        transferDurationBySegments,
        saveOrderServicesVariables,
        refundSummaryParams,
        nextButtonDisabled,
        isAllowInstantSelect
    };
};
