import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PromoLoader from '../../../PromoLoader';
import { useRestoreOrderMutation } from '@websky/graphql';
const RestoreOrderComponent = props => {
    const { orderId, token } = useParams();
    const history = useHistory();
    const [restoreOrder] = useRestoreOrderMutation();
    useEffect(() => {
        // call restore order once if there is same id/token
        restoreOrder({ variables: { accessToken: token, id: orderId } })
            .then(({ data }) => {
            if (data === null || data === void 0 ? void 0 : data.RestoreOrder) {
                props.onOrderReceived(data.RestoreOrder, false);
            }
            else {
                history.push(`/find/${orderId}`);
            }
        })
            .catch(() => {
            history.push(`/find/${orderId}`);
        });
    }, [orderId, token]);
    return React.createElement(PromoLoader, null);
};
export default RestoreOrderComponent;
