import { createTheme } from '../utils';
import * as PriceMatrixStyles from './components/PriceMatrix.css';
import * as MatrixStyles from './components/Matrix/Matrix.css';
import * as OutboundStyles from './components/Outbound/Outbound.css';
export const defaultTheme = {
    PriceMatrixStyles,
    MatrixStyles,
    OutboundStyles
};
export const { Provider, withTheme } = createTheme(defaultTheme);
