import { createSelector } from 'reselect';
import { useConfig } from '../../../context';
import { useIataCode } from '../../../hooks';
export const getIsForeignCard = (state) => state.foreignCard;
export const getModifiedEngineURL = createSelector(getIsForeignCard, useConfig, useIataCode, (isForeignCard, config, iata) => {
    if (iata === 'WZ') {
        if (!isForeignCard) {
            return config.SearchForm.engineURL;
        }
        else {
            return config.SearchForm.engineURL.replace('.com', '.kz');
        }
    }
    return config.SearchForm.engineURL;
});
