import { GdsServiceProductStatus } from '@websky/graphql';
export const getActualProduct = (products) => {
    let actualProduct = null;
    products === null || products === void 0 ? void 0 : products.forEach(product => {
        if (!actualProduct || (actualProduct === null || actualProduct === void 0 ? void 0 : actualProduct.id) < product.id) {
            actualProduct = product;
        }
    });
    return actualProduct;
};
export const pickRequestedService = (item) => item.status === GdsServiceProductStatus.Requested;
export const omitRequestedService = (item) => !pickRequestedService(item);
export const getBaggageName = (service, count) => {
    return `${count}x ${service.name}${service.baggageWeight ? `, ${service.baggageWeight}` : ''}`;
};
