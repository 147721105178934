import { useToggleable } from '../hooks';
import { useState } from 'react';
export const useSecureAccess = () => {
    const { isOpen, close, open } = useToggleable(false);
    const [submitParams, setSubmitParams] = useState();
    const [showEror, setShowError] = useState(false);
    // @ts-ignore
    const setSecret2fa = (secret2fa) => setSubmitParams(params => ({ params: Object.assign(Object.assign({}, params), { secret2fa }) }));
    const isNeedToOpenSecureAccessModal = (errors) => {
        if (!(errors === null || errors === void 0 ? void 0 : errors.length)) {
            return false;
        }
        return errors.some(responseError => {
            return [responseError.message, responseError['debugMessage']].includes('PNR is locked. Try to unlock with data from e-mail');
        });
    };
    const isFindGroupOrderVariablesGuard = (params) => {
        return 'travellersDataForSplit' in params.params;
    };
    return {
        isOpen,
        open,
        close,
        submitParams,
        setSubmitParams,
        setSecret2fa,
        isNeedToOpenSecureAccessModal,
        isFindGroupOrderVariablesGuard,
        showEror,
        setShowError
    };
};
