import * as React from 'react';
import { ExareStatus } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../Button/Button';
import { useTheme } from '../../../../../../theme';
import Dialog from '../../Dialog/Dialog';
const RequestControls = ({ order, deleteRequest, goToNewExchange, goToPayment }) => {
    const { t } = useTranslation('Exchange');
    const theme = useTheme('Exchange').RequestControls;
    return (React.createElement("div", { className: theme.container },
        order.refuseAllowed && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.text }, t('If you change your mind, or want to create a new request with different parameters, delete this request')),
            React.createElement(Dialog, { text: t('The change request was deleted. Want to create a new request?'), acceptText: t('Yes'), onAccept: () => deleteRequest(order), cancelText: t('No'), iconClassName: theme.dialog__deleteIcon, onCancel: () => deleteRequest(order, true) }, ({ open }) => (React.createElement(Button, { variant: "flat", size: "large", className: theme.refuse, onClick: open }, t('Delete request')))))),
        order.status === ExareStatus.AwaitingPayment && (React.createElement(React.Fragment, null,
            React.createElement("div", null),
            React.createElement(Button, { variant: "flat", size: "large", className: theme.button, onClick: goToPayment }, t('Payment exchange')))),
        (order.status === ExareStatus.Rejected ||
            order.status === ExareStatus.Refused ||
            order.status === ExareStatus.Finished) && (React.createElement(Button, { variant: "flat", size: "large", className: theme.button, onClick: goToNewExchange }, t('Add new request')))));
};
export default RequestControls;
