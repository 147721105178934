import { createSelector } from 'reselect';
import { getOrder } from '../order/selectors';
import { NASProductType } from '@websky/graphql';
export const getEveryPortSelectedServices = (state) => state.everyPort.selectedServices;
export const getEveryPortLoungeService = createSelector(getOrder, order => {
    var _a, _b, _c;
    return (_c = (_b = (_a = order.additionalServices.nas) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.filter(service => service.type === NASProductType.Lounge)) !== null && _c !== void 0 ? _c : [];
});
export const getEveryPortServicesByCategories = createSelector(getEveryPortLoungeService, services => {
    const servicesMap = new Map();
    services.forEach(service => {
        servicesMap.set(service.category, [...(servicesMap.get(service.category) || []), service]);
    });
    return servicesMap;
});
export const getEveryPortSelectedServicesArray = createSelector(getEveryPortSelectedServices, selectedServices => {
    const services = [];
    Object.values(selectedServices).forEach(servicesByCategory => {
        servicesByCategory.forEach(service => {
            services.push({
                id: service.product.id,
                additionalServices: service.additionalServices,
                travellerIds: service.travellerIds
            });
        });
    });
    return services;
});
