import { ADD_FLIGHT_IDS, CHANGE_FLIGHT_ID, REMOVE_FLIGHT_IDS } from './actions';
export const ALL_FLIGHT = 'ALL_FLIGHT';
const initialState = [];
export const flightsToExchangeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_FLIGHT_IDS:
            return [...action.payload];
        case REMOVE_FLIGHT_IDS:
            return state.filter(exchangeFlight => !action.payload.find(key => key === exchangeFlight.key));
        case CHANGE_FLIGHT_ID:
            return state.map(exchangeFlight => {
                if (exchangeFlight.key === action.payload.key) {
                    return Object.assign(Object.assign({}, exchangeFlight), action.payload);
                }
                return exchangeFlight;
            });
        default:
            return state;
    }
};
