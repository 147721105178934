import { getOrder, getSegments } from './order/selectors';
import { getSelectedPassengerIds } from './passengers/selectors';
import { createSelector } from 'reselect';
import { getFlightIdsToExchange } from './flightsToExchange/selectors';
import { NASStatus } from '@websky/graphql';
import { ALL_FLIGHT } from './flightsToExchange/reducers';
import { format } from 'date-fns';
import { API_DATE_FORMAT } from '../../../utils';
export const getSelectedPassengers = createSelector([getOrder, getSelectedPassengerIds], (order, ids) => {
    if (order) {
        return order.travellers.filter(traveller => ids.indexOf(traveller.id) !== -1);
    }
    return [];
});
export const getFlightsToExchange = createSelector([getSegments, getFlightIdsToExchange], (segments, ids) => {
    var _a;
    if (((_a = ids === null || ids === void 0 ? void 0 : ids[0]) === null || _a === void 0 ? void 0 : _a.key) === ALL_FLIGHT) {
        return {
            flightsByIds: segments,
            flightsToExchange: ids.filter(({ key }) => key !== 'ALL_FLIGHT')
        };
    }
    return {
        flightsByIds: segments.filter(segment => ids.find(({ key }) => key === segment.groupId)),
        flightsToExchange: ids
    };
});
export const getIsAllFlights = createSelector(getFlightIdsToExchange, ids => {
    const flightIds = ids.map(({ key }) => key);
    return flightIds.includes(ALL_FLIGHT);
});
export const isNewExchangeSelector = createSelector([getFlightsToExchange, getSelectedPassengerIds], (flights, passengers) => {
    return !!flights.flightsByIds.length && !!passengers.length;
});
export const getNewFlightSearchParams = createSelector([getOrder, getFlightsToExchange, getSelectedPassengers], (order, flightsToExchange, passengers) => {
    var _a, _b;
    const newSegmentParams = flightsToExchange.flightsByIds.map((flight, key) => {
        var _a;
        let date = flight.segments[0].departure.date;
        if ((_a = flightsToExchange.flightsToExchange[key]) === null || _a === void 0 ? void 0 : _a.date) {
            date = format(flightsToExchange.flightsToExchange[key].date, API_DATE_FORMAT);
        }
        return {
            date,
            departure: {
                iata: flight.segments[0].departure.airport.iata
            },
            arrival: {
                iata: flight.segments[flight.segments.length - 1].arrival.airport.iata
            }
        };
    });
    const newPassengerParams = [];
    const passengersMap = new Map();
    passengers.forEach(passenger => {
        if (passengersMap.has(passenger.type)) {
            passengersMap.set(passenger.type, passengersMap.get(passenger.type) + 1);
        }
        else {
            passengersMap.set(passenger.type, 1);
        }
    });
    passengersMap.forEach((count, type) => {
        newPassengerParams.push({
            passengerType: type,
            count
        });
    });
    const newAviaSearchParams = {
        segments: newSegmentParams,
        passengers: newPassengerParams,
        currency: (_b = (_a = order.price) === null || _a === void 0 ? void 0 : _a.total) === null || _b === void 0 ? void 0 : _b.currency
    };
    return newAviaSearchParams;
});
export const getRefundableNasServices = createSelector(getOrder, getSelectedPassengerIds, getFlightIdsToExchange, (order, passengers, segmentIds) => {
    var _a;
    const selectedServices = [];
    (_a = order.additionalServices.nas) === null || _a === void 0 ? void 0 : _a.selectedServices.forEach(selected => {
        const product = order.additionalServices.nas.services.find(service => service.id === selected.product.id);
        if (selected.orders.some(order => order.isRefundable && order.status === NASStatus.Confirmed)) {
            selectedServices.push(Object.assign(Object.assign({}, selected), { product }));
        }
    });
    return selectedServices;
});
