import { useContext, useMemo } from 'react';
import { AvailableServicesCountContext } from './context';
import { ModeContext } from '../context';
import { Mode } from '../types';
const calculateSelectedCheckoutServices = (selectedServices, serviceIdToGdsTypeMap, currentServiceId) => {
    const selectedCountByGdsType = {};
    Object.entries(selectedServices).forEach(([travellerId, servicesObj]) => {
        Object.entries(servicesObj).forEach(([serviceId, services]) => {
            services.forEach(service => {
                if (service.servicePayload.id === currentServiceId) {
                    return;
                }
                const serviceGdsType = serviceIdToGdsTypeMap.get(serviceId);
                service.segments.forEach(segmentId => {
                    var _a, _b, _c;
                    selectedCountByGdsType[travellerId] = Object.assign(Object.assign({}, (selectedCountByGdsType[travellerId] || {})), { [segmentId]: Object.assign(Object.assign({}, (((_a = selectedCountByGdsType[travellerId]) === null || _a === void 0 ? void 0 : _a[segmentId]) || {})), { [serviceGdsType]: (((_c = (_b = selectedCountByGdsType[travellerId]) === null || _b === void 0 ? void 0 : _b[segmentId]) === null || _c === void 0 ? void 0 : _c[serviceGdsType]) || 0) +
                                service.number }) });
                });
            });
        });
    });
    return selectedCountByGdsType;
};
const calculateSelectedCheckinServices = (selectedServices, serviceIdToGdsTypeMap, currentServiceId) => {
    const selectedCountByGdsType = {};
    Object.entries(selectedServices).forEach(([travellerId, travelerServices]) => {
        Object.entries(travelerServices).forEach(([serviceId, servicesBySegments]) => {
            Object.entries(servicesBySegments).forEach(([segmentId, number]) => {
                var _a, _b, _c;
                if (serviceId === currentServiceId) {
                    return;
                }
                const serviceGdsType = serviceIdToGdsTypeMap.get(serviceId);
                selectedCountByGdsType[travellerId] = Object.assign(Object.assign({}, (selectedCountByGdsType[travellerId] || {})), { [segmentId]: Object.assign(Object.assign({}, (((_a = selectedCountByGdsType[travellerId]) === null || _a === void 0 ? void 0 : _a[segmentId]) || {})), { [serviceGdsType]: (((_c = (_b = selectedCountByGdsType[travellerId]) === null || _b === void 0 ? void 0 : _b[segmentId]) === null || _c === void 0 ? void 0 : _c[serviceGdsType]) || 0) + number }) });
            });
        });
    });
    return selectedCountByGdsType;
};
export const useAvailableCountForTravellerOnSegment = (serviceId, travellerId, segmentId) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { order, selectedServices } = useContext(AvailableServicesCountContext);
    const isBookingMode = useContext(ModeContext) === Mode.Booking;
    if (!order || !selectedServices) {
        console.warn('empty order or selectedServices');
        return undefined;
    }
    const gdsService = (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(service => service.id === serviceId);
    const gdsServiceMaxCountPerPassenger = gdsService.maxServiceCountPerPassenger;
    const gdsType = gdsService === null || gdsService === void 0 ? void 0 : gdsService.gdsType;
    const serviceIdToGdsTypeMap = useMemo(() => {
        var _a, _b;
        const serviceIdToGdsTypeMap = new Map();
        (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.forEach(service => {
            serviceIdToGdsTypeMap.set(service.id, service.gdsType);
        });
        return serviceIdToGdsTypeMap;
    }, [(_c = order.additionalServices.gdsServices) === null || _c === void 0 ? void 0 : _c.services]);
    const selectedCountByGdsType = useMemo(() => {
        return isBookingMode
            ? calculateSelectedCheckoutServices(selectedServices, serviceIdToGdsTypeMap, serviceId)
            : calculateSelectedCheckinServices(selectedServices, serviceIdToGdsTypeMap, serviceId);
    }, [selectedServices, serviceIdToGdsTypeMap, serviceId]);
    const maxCountForTravellerOnSegment = (_f = (_e = (_d = order.additionalServices.gdsServices) === null || _d === void 0 ? void 0 : _d.servicesRestrictionsByType) === null || _e === void 0 ? void 0 : _e.find(restriction => restriction.gdsType === gdsType)) === null || _f === void 0 ? void 0 : _f.maxCountForTravellerOnSegment;
    if (maxCountForTravellerOnSegment !== undefined) {
        return maxCountForTravellerOnSegment - (((_h = (_g = selectedCountByGdsType[travellerId]) === null || _g === void 0 ? void 0 : _g[segmentId]) === null || _h === void 0 ? void 0 : _h[gdsType]) || 0);
    }
    else if (typeof gdsServiceMaxCountPerPassenger === 'number') {
        return gdsServiceMaxCountPerPassenger;
    }
    else {
        return undefined;
    }
};
