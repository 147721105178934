import { getTravellersTypes } from '../utils';
import { ItemCategory } from '../types';
export const getHoldDatalayerItem = (freezeService, travellers) => {
    return {
        name: 'hold',
        category: ItemCategory.PriceFreeze,
        price: freezeService.price.amount * freezeService.allowedPassengers.length,
        forAllSegments: true,
        count: 1,
        travellersTypes: getTravellersTypes(travellers)
    };
};
