import { useCallback, useState } from 'react';
export const useClarifyDocumentModal = () => {
    const [isOpen, setOpen] = useState(false);
    const [searchParams, setSearchParams] = useState(null);
    const close = useCallback(() => {
        setOpen(false);
    }, [isOpen]);
    const open = useCallback(() => {
        setOpen(true);
    }, [isOpen]);
    const needToShowClarifyModal = (error) => {
        return error.message.includes('There are more than one passengers with family');
    };
    return { isOpen, open, close, needToShowClarifyModal, searchParams, setSearchParams };
};
