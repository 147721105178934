export var CheckinStep;
(function (CheckinStep) {
    CheckinStep["Passengers"] = "";
    CheckinStep["Meal"] = "meal";
    CheckinStep["Upgrade"] = "upgrade";
    CheckinStep["Baggage"] = "baggage";
    CheckinStep["Seats"] = "seats";
    CheckinStep["Payment"] = "payment";
    CheckinStep["Extras"] = "extras";
})(CheckinStep || (CheckinStep = {}));
