import * as React from 'react';
import { Field } from '../../../../../../Passenger/components/Field/Field';
import { useTheme } from '../../../../../../theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useConfig } from '../../../../../../context';
import Link from '../../../../../../Link';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';
import Switch from '../../../../../../PassengerForm/components/components/Switch/Switch';
const LoyaltyGroup = props => {
    var _a;
    const [open, setOpen] = React.useState(!!((_a = props.fields[0]) === null || _a === void 0 ? void 0 : _a.defaultValue));
    const groupTheme = useTheme('PassengerForm').Group;
    const theme = useTheme('PassengerForm').LoyaltyGroup;
    const { t } = useTranslation('Passenger');
    const config = useConfig().global.companyInfo;
    const form = useForm();
    useEffect(() => {
        if (!!props.fields[0].defaultValue) {
            setOpen(true);
        }
    }, [props.fields]);
    useEffect(() => {
        if (!open) {
            form.change(props.fields[0].name, '');
        }
    }, [open]);
    const onToggle = () => {
        setOpen(!open);
    };
    return (React.createElement("div", { className: cn(groupTheme.container, theme.container_loyalty, { [theme.withIcons]: !!props.groupIcon }) },
        props.groupIcon && React.createElement("div", { className: groupTheme.icon }, props.groupIcon),
        React.createElement("div", { className: cn(theme.loyalty_switch, { [theme.loyalty_switch_open]: open }) },
            React.createElement(Switch, { label: t('I have a loyalty card'), isActive: open, onClick: onToggle, isDisabled: props.fields[0].readonly || props.disabled }),
            !open && (React.createElement("p", { className: theme.loyalty__desc },
                t(`Don't have a card yet?`),
                ' ',
                React.createElement(Link, { target: "_blank", action: props.onSignUpLoyaltyProgram
                        ? props.onSignUpLoyaltyProgram
                        : config.loyaltyProgramRegistrationUrl },
                    React.createElement("span", { dangerouslySetInnerHTML: { __html: t('Register now') } }))))),
        React.createElement("div", { className: theme.loyalty, style: { display: open ? 'block' : 'none' } },
            React.createElement(Field, { field: props.fields[0], passengerId: props.passengerId, passengerType: props.passengerType, disabled: props.fields[0].readonly || props.disabled }))));
};
export default LoyaltyGroup;
