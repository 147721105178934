import * as React from 'react';
import { memo } from 'react';
import { useTheme } from '../../../theme';
const Group = memo(({ group, optionRenderer }) => {
    const { Group: css } = useTheme('LocationAutocomplete');
    return (React.createElement("div", { className: css.group },
        group.label && React.createElement("label", { className: css.group__label }, group.label),
        group.options.map(optionRenderer)));
});
export default Group;
