import * as React from 'react';
import { useTheme } from '../../theme';
import cn from 'classnames';
import ImageWithFallback from '../../ImageWithFallback';
const AircraftLivery = ({ aircraft, isReturnFlight, className, returnClassName, onClick, fallbackAircraft = 'https://cdn.websky.aero/content/default-content/images/Aircrafts/738/738.png' }) => {
    const css = useTheme('AircraftLivery').AircraftLivery;
    const aircraftIcon = isReturnFlight && (aircraft === null || aircraft === void 0 ? void 0 : aircraft.iconRt) ? aircraft.iconRt : aircraft === null || aircraft === void 0 ? void 0 : aircraft.icon;
    const isReturnPlane = isReturnFlight && !(aircraft === null || aircraft === void 0 ? void 0 : aircraft.iconRt);
    return (React.createElement("div", { className: cn(css.plane, className, {
            [css.plane_return]: isReturnPlane,
            [returnClassName]: returnClassName && isReturnPlane
        }), onClick: onClick },
        React.createElement(ImageWithFallback, { src: aircraftIcon, fallbackSrc: fallbackAircraft, alt: aircraft === null || aircraft === void 0 ? void 0 : aircraft.name })));
};
export default AircraftLivery;
