import * as React from 'react';
import { useContext } from 'react';
import { CartService } from '../../types';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { CartContext } from '../../Cart';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import { useTranslation } from 'react-i18next';
import { BusinessLounge as BusinessLoungeIcon } from '../../../Icons';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
const BusinessLounge = ({ travellers, services, onServiceDelete }) => {
    var _a, _b;
    const businessLoungeServiceIds = (_b = (_a = services.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.BusinessLounge).map(service => service.id);
    const cartContext = useContext(CartContext);
    const { t } = useTranslation('Cart');
    const onDeleteHandler = (travellerId, serviceId, segmentId) => {
        onServiceDelete(CartService.BusinessLounge, {
            passengerId: travellerId,
            serviceId,
            segmentId
        });
    };
    const items = React.useMemo(() => {
        const items = [];
        travellers.forEach(traveller => {
            var _a, _b, _c, _d;
            const travellerName = (_a = cartContext.travellersById.get(traveller.id)) === null || _a === void 0 ? void 0 : _a.name;
            (_d = (_c = (_b = traveller.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.forEach(service => {
                if (businessLoungeServiceIds.includes(service.serviceId)) {
                    const gdsService = services.gdsServices.services.find(gdsService => gdsService.id === service.serviceId);
                    service.segmentIds.forEach(segment => {
                        var _a;
                        const segmentDirection = (_a = cartContext.segmentsById.get(segment)) === null || _a === void 0 ? void 0 : _a.iataDirection;
                        items.push({
                            id: `${traveller.id}_${service.serviceId}_${segment}`,
                            name: `${segmentDirection}, ${travellerName}`,
                            price: gdsService.price,
                            included: service.confirmedCount === service.count,
                            onDelete: () => onDeleteHandler(traveller.id, service.serviceId, segment)
                        });
                    });
                }
            });
        });
        return items;
    }, [businessLoungeServiceIds, travellers]);
    if (!items.length) {
        return null;
    }
    return (React.createElement(ServiceSummaryDivider, null,
        React.createElement(ServiceSummaryOption, { groupOption: {
                header: t('Business lounge'),
                headerIcon: BusinessLoungeIcon,
                items
            } })));
};
export default BusinessLounge;
