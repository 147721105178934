import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '../../../theme';
import { ExareStatus } from '@websky/graphql';
import Price from '../Price/Price';
import MobileStep from '../MobileStep/MobileStep';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import StepsButtons from '../StepsButtons/StepsButtons';
import RefundInfoModal from '../Modals/RefundInfoModal/RefundInfoModal';
import { Document as DocumentIcon, Person, Refundable } from '../../../Icons';
import { getPassengerFirstName, getPassengerLastName } from '../../../Checkout/store/passengers/selectors';
import { capitalizeFirstLetter, getPassengerNames, initI18n } from '../../../utils';
import { Unvoluntary } from '../../types';
import { useToggleable } from '../../../hooks';
import TicketRequest from '../../../TicketRequest';
import Notes from '../Notes/Notes';
import Note from '../Note/Note';
import { useHtmlDescriptionOfError } from '../Refund/hooks';
initI18n('Exchange');
const Ticket = props => {
    var _a;
    const { refundOrder: refundOrder, isLoading, isAutoMode, renderNotes: renderNotesFromProps } = props;
    const { isOpen: isModalOpen } = useToggleable(false);
    const { isOpen: rejectModalOpen, open: openRejectModal, close: closeRejectModal } = useToggleable(false);
    const { t } = useTranslation('Refund');
    const { Ticket: css } = useTheme('Refund');
    const handleConfirm = useCallback(() => {
        if (isLoading) {
            return;
        }
        props.onConfirm(refundOrder.id);
    }, [refundOrder, isLoading]);
    const handleReject = useCallback(() => {
        if (isLoading) {
            return;
        }
        props.onReject(refundOrder.id);
    }, [refundOrder, isLoading]);
    const renderHeader = useCallback(() => {
        var _a;
        let breadcrumbs = [];
        const travellerNames = refundOrder.travellers.map(traveller => {
            const { lastName, firstName } = getPassengerNames(traveller);
            return [lastName, firstName].map(capitalizeFirstLetter).join(' ');
        });
        breadcrumbs = [...breadcrumbs, ...travellerNames.map(name => ({ title: name }))];
        const reason = (_a = refundOrder.reason) !== null && _a !== void 0 ? _a : Unvoluntary.Unvoluntary;
        let reasonText = reason === Unvoluntary.Unvoluntary ? t('Voluntary refund') : t('Involuntary refund');
        if (reason !== Unvoluntary.Unvoluntary) {
            reasonText += ` - ${t(refundOrder.reason)}`;
        }
        breadcrumbs = [...breadcrumbs, { title: reasonText }];
        return (React.createElement(React.Fragment, null,
            React.createElement(TicketRequest, { className: css.refund__request, breadcrumbsClassName: css.breadcrumbs, requestId: refundOrder.id, status: refundOrder.status, createdDate: new Date(refundOrder.createDate), breadcrumbs: breadcrumbs }),
            React.createElement(MediaQuery, { maxWidth: 'mobile' },
                React.createElement(MobileStep, { className: css.step, isActive: false, renderTitle: () => (React.createElement(React.Fragment, null,
                        t('For'),
                        "\u00A0",
                        travellerNames.join(', '))), icon: Person }),
                React.createElement(MobileStep, { className: css.step, isActive: false, renderTitle: () => (React.createElement("span", { className: css.reasonHeader }, !refundOrder.isCompelled ? (t('Voluntary refund')) : (React.createElement(React.Fragment, null,
                        t('Involuntary refund'),
                        React.createElement("p", { className: css.reasonDescr }, t(refundOrder.reason.toString())))))), icon: Refundable }))));
    }, [refundOrder]);
    const renderPrices = useCallback(() => React.createElement(Price, { className: css.price, price: refundOrder.prices }), [refundOrder]);
    const renderComments = useCallback(() => refundOrder.userComment ? (React.createElement(Paper, { square: true, elevation: 0, className: css.comments },
        React.createElement("h6", { className: css.commentsTitle }, t('Your comments')),
        React.createElement("p", { className: css.commentsText }, refundOrder.userComment))) : null, [refundOrder]);
    const renderFile = useCallback((file) => (React.createElement("div", { className: css.file, key: Math.random() },
        React.createElement("span", { className: css.fileIcon }, DocumentIcon),
        capitalizeFirstLetter(getPassengerFirstName(refundOrder.travellers.find(traveller => traveller.id === file.passengerId)).value.toLowerCase()),
        "\u00A0",
        capitalizeFirstLetter(getPassengerLastName(refundOrder.travellers.find(traveller => traveller.id === file.passengerId)).value.toLowerCase()),
        ":\u00A0",
        t(file.type))), [refundOrder]);
    const renderFiles = useCallback(() => {
        if (!refundOrder.loadedFiles.length) {
            return null;
        }
        return (React.createElement(Paper, { square: true, elevation: 0, className: css.files },
            React.createElement("h6", { className: css.filesTitle }, t('Files')),
            React.createElement("div", { className: css.filesContent }, refundOrder.loadedFiles.map(renderFile))));
    }, [refundOrder]);
    const renderButtons = useCallback(() => {
        const status = refundOrder.status, refuseAllowed = refundOrder.refuseAllowed, needConfirm = status === ExareStatus.NeedConfirm, newRequestAllowed = status === ExareStatus.Refused || status === ExareStatus.Rejected || ExareStatus.Finished;
        let nextButtonProps = {};
        if (needConfirm) {
            nextButtonProps = {
                disabled: isLoading,
                children: t('Confirm'),
                className: cn(css.confirm, css.button),
                onClick: handleConfirm
            };
        }
        else if (newRequestAllowed) {
            nextButtonProps = {
                children: t('Add new request'),
                className: cn(css.confirm, css.button),
                onClick: props.onNewRequest
            };
        }
        return (React.createElement("div", { className: css.buttons },
            React.createElement(StepsButtons, { position: 'right', nextButton: nextButtonProps, backButton: refuseAllowed
                    ? {
                        disabled: isLoading,
                        children: t(needConfirm ? 'Reject' : 'Delete request'),
                        className: cn(css.button, css.reject),
                        onClick: openRejectModal
                    }
                    : null })));
    }, [refundOrder.status, refundOrder.refuseAllowed]);
    const renderRejectModal = () => (React.createElement(RefundInfoModal, { open: rejectModalOpen, onAgree: handleReject, onDisagree: closeRejectModal, renderText: () => t('The request was deleted. Want to create a new request?'), renderAgreeText: () => t('Yes'), renderDisagreeText: () => t('No') }));
    const renderModals = useCallback(() => {
        const status = refundOrder.status;
        switch (status) {
            default:
                return null;
        }
    }, [refundOrder, isModalOpen, handleConfirm]);
    const renderNotes = useCallback(() => {
        var _a, _b;
        const isZeroPrice = ((_b = (_a = refundOrder.prices) === null || _a === void 0 ? void 0 : _a.priceToPay) === null || _b === void 0 ? void 0 : _b.amount) && Math.abs(refundOrder.prices.priceToPay.amount) <= 0;
        const showReturnMessage = !isZeroPrice && refundOrder.status === ExareStatus.Finished;
        if (renderNotesFromProps) {
            renderNotesFromProps(isAutoMode);
        }
        return React.createElement(Notes, { isAutoMode: isAutoMode, showReturnMessage: showReturnMessage });
    }, [isAutoMode, refundOrder]);
    const htmlDescriptionOfWarning = useHtmlDescriptionOfError((_a = refundOrder.warnings) === null || _a === void 0 ? void 0 : _a[0]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Paper, { square: true, elevation: 0, className: css.ticket },
            renderHeader(),
            renderComments(),
            renderFiles()),
        !(refundOrder.status === ExareStatus.InProcess && !refundOrder.automaticMode) && (React.createElement(Paper, { square: true, elevation: 0, className: cn(css.prices) }, renderPrices())),
        refundOrder.status !== ExareStatus.Rejected && refundOrder.status !== ExareStatus.Refused && renderNotes(),
        htmlDescriptionOfWarning && (React.createElement(Note, { title: htmlDescriptionOfWarning.header, type: 'info', iconColor: 'gray', content: React.createElement("div", { dangerouslySetInnerHTML: { __html: htmlDescriptionOfWarning.body } }) })),
        renderButtons(),
        renderModals(),
        renderRejectModal()));
};
export default Ticket;
