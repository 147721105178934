import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../../theme';
import { PhoneSuccess } from '../../../../../../Icons';
import { initI18n } from '../../../../../../utils';
initI18n('CheckinConditions');
const Conditions = ({ className }) => {
    const { Conditions: css } = useTheme('Checkin');
    const { t, i18n } = useTranslation('CheckinConditions');
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.title }, t('Check-in conditions')),
        React.createElement("div", { className: css.icon }, PhoneSuccess),
        React.createElement("ul", { className: css.conditions }, [...Array(10)].map((_, index) => {
            const key = `Rules_${index}`;
            const isExistTranslate = i18n.exists(key, { ns: 'CheckinConditions' });
            if (!isExistTranslate) {
                return null;
            }
            return React.createElement("li", { key: index, dangerouslySetInnerHTML: { __html: t(key) } });
        }))));
};
export default Conditions;
