const result = {
    __schema: {
        types: [
            {
                kind: 'UNION',
                name: 'AutocompleteLocation',
                possibleTypes: [
                    {
                        name: 'Airport'
                    },
                    {
                        name: 'MetropolitanArea'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'IAirline',
                possibleTypes: [
                    {
                        name: 'Airline'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'IExareOrder',
                possibleTypes: [
                    {
                        name: 'ChangeTravellerDataOrder'
                    },
                    {
                        name: 'ExareExchangeOrder'
                    },
                    {
                        name: 'ExareGdsServicesRefundOrder'
                    },
                    {
                        name: 'ExareRefundOrder'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'IExarePriceDetailsPriceElement',
                possibleTypes: [
                    {
                        name: 'ExarePriceDetailsPriceElement'
                    },
                    {
                        name: 'ExarePriceDetailsTaxElement'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'IFlight',
                possibleTypes: [
                    {
                        name: 'Flight'
                    },
                    {
                        name: 'OrderFlight'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'IFlightFare',
                possibleTypes: [
                    {
                        name: 'FlightFare'
                    },
                    {
                        name: 'FlightFareM2'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'IGdsServiceInfo',
                possibleTypes: [
                    {
                        name: 'OrderAdditionalServiceGdsServiceBaggage'
                    },
                    {
                        name: 'OrderAdditionalServiceGdsServiceBaseInfo'
                    },
                    {
                        name: 'OrderAdditionalServiceGdsServiceMeal'
                    },
                    {
                        name: 'OrderAdditionalServiceGdsServicePriceFreeze'
                    },
                    {
                        name: 'OrderAdditionalServiceGdsServiceSeat'
                    },
                    {
                        name: 'OrderAdditionalServiceGdsServiceVip'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'IOrderAdditionalService',
                possibleTypes: [
                    {
                        name: 'OrderAdditionalServiceGdsService'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'IPaginationEdge',
                possibleTypes: [
                    {
                        name: 'AviaOrderPaginationEdge'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'IPaymentMethod',
                possibleTypes: [
                    {
                        name: 'CardGatewayInfo'
                    },
                    {
                        name: 'CashOneclickpayGatewayInfo'
                    },
                    {
                        name: 'GatewayPaymentCode'
                    },
                    {
                        name: 'GooglePayGatewayInfo'
                    },
                    {
                        name: 'InvoiceOneclickpayGatewayInfo'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'IProfile',
                possibleTypes: [
                    {
                        name: 'TravellerProfile'
                    },
                    {
                        name: 'UserProfile'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'ITraveller',
                possibleTypes: [
                    {
                        name: 'CheckinTraveller'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'ITravellerDocument',
                possibleTypes: [
                    {
                        name: 'TravellerDocument'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'ITravellerParameter',
                possibleTypes: [
                    {
                        name: 'ChangeTravellerDataTravellerParameter'
                    },
                    {
                        name: 'Contact'
                    },
                    {
                        name: 'ProfileParameter'
                    },
                    {
                        name: 'TravellerParameter'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'ITrip',
                possibleTypes: [
                    {
                        name: 'AeroexpressSelectedTrip'
                    },
                    {
                        name: 'AeroexpressTrip'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'IValidationRule',
                possibleTypes: [
                    {
                        name: 'ChangeTravellerDataValidationRule'
                    },
                    {
                        name: 'ProfileValidationRule'
                    },
                    {
                        name: 'ValidationRule'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'IWithParameter',
                possibleTypes: [
                    {
                        name: 'ProfileWithParameter'
                    },
                    {
                        name: 'WithParameter'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'Location',
                possibleTypes: [
                    {
                        name: 'Airport'
                    },
                    {
                        name: 'MetropolitanArea'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'PaximizerCommunicationNotificationContent',
                possibleTypes: [
                    {
                        name: 'PaximizerCommunicationMyPromoCodeContent'
                    },
                    {
                        name: 'PaximizerCommunicationOrderNotificationContent'
                    },
                    {
                        name: 'PaximizerCommunicationPromotionContent'
                    }
                ]
            },
            {
                kind: 'INTERFACE',
                name: 'ServiceHasTimeline',
                possibleTypes: [
                    {
                        name: 'AeroexpressSelectedTrip'
                    },
                    {
                        name: 'AeroexpressTrip'
                    },
                    {
                        name: 'InsuranceProgram'
                    },
                    {
                        name: 'NASProduct'
                    },
                    {
                        name: 'OrderAdditionalServiceGdsServiceBaggage'
                    },
                    {
                        name: 'OrderAdditionalServiceGdsServiceBaseInfo'
                    },
                    {
                        name: 'OrderAdditionalServiceGdsServiceMeal'
                    },
                    {
                        name: 'OrderAdditionalServiceGdsServicePriceFreeze'
                    },
                    {
                        name: 'OrderAdditionalServiceGdsServiceSeat'
                    },
                    {
                        name: 'OrderAdditionalServiceGdsServiceVip'
                    }
                ]
            }
        ]
    }
};
export default result;
