import { __awaiter } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import { BaseButton as Button } from '../../../BaseComponents';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
const SureModalContent = ({ className, title, description, onSuccess, onClose }) => {
    const { SureModalContent: css } = useTheme('SureModal');
    const { t } = useTranslation('Loyalty');
    const [isLoading, setLoading] = useState(false);
    const onSuccessHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        yield onSuccess();
        setLoading(false);
        setTimeout(onClose, 500);
    });
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: css.header }, title),
        React.createElement("div", { className: css.text }, description),
        React.createElement("div", { className: css.footer },
            !isLoading && (React.createElement(Button, { variant: "secondary", className: cn(css.button, css.button_cancel), onClick: onClose }, t('No'))),
            React.createElement(Button, { variant: "action", className: css.button, isLoading: isLoading, onClick: onSuccessHandler }, t('Yes')))));
};
export default SureModalContent;
