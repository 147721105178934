import { __rest } from "tslib";
import * as React from 'react';
import { useFormState } from 'react-final-form';
import { DateField } from '../Date/Date';
import { Collapse } from '@material-ui/core';
const DocExpiryDate = (_a) => {
    var { checkFieldIsRequired } = _a, props = __rest(_a, ["checkFieldIsRequired"]);
    const { values } = useFormState();
    const isRequired = checkFieldIsRequired(values);
    const label = isRequired ? `${props.label}*` : props.label;
    const disabled = !isRequired || props.disabled;
    const needToDisplay = React.useMemo(() => {
        return isRequired || !!props.value;
    }, [isRequired, props.value]);
    if (props.disabled && !!props.value) {
        return React.createElement(DateField, Object.assign({}, props, { disabled: true, label: label }));
    }
    else {
        return (React.createElement(Collapse, { className: props.className, in: needToDisplay },
            React.createElement(DateField, Object.assign({}, props, { disabled: disabled, label: label }))));
    }
};
export default DocExpiryDate;
