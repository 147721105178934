import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { getUniqueValidOptions } from '../../Results/utils';
import { Money } from '../../Money';
import Tooltip from '../../Tooltip';
import { icons as defaultOptionsIcons, infoIcon, payment as paymentIcons } from '../icons';
import CompareFares from '../../CompareFares';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import { useTheme } from '../../theme';
import RecommendedBadge from './RecommendedBadge/RecommendedBadge';
import Dialog from '../../Service/components/Dialog/Dialog';
import Modal from '../../Modal';
import * as Icons from '../../Icons';
import Link from '../../Link';
import Notification from '../../Notification';
import FareGroupIcon from './FareGroupIcon/FareGroupIcon';
import { useCallback } from 'react';
import { useRenderers } from '../../renderProps';
import { useConfig } from '../../context';
import { FareFeaturePaymentType, FlightClass, InfoMessageTypeEnum } from '@websky/graphql';
import FareButton from '../../widgets/Flights/FareButton/components/FareButton';
initI18n('FareGroup');
const FareGroup = props => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { fare, onSelect, price, originalPrice, variant = 'blue', isDisabled = false, excludeRepeatableKeyOptions = true, showUnavailableOptions = false, upgradeOption, onUpgrade, flightId, className, isSelected, titleClassName, optionsClassName, optionClassName, keyFeatureClassName } = props;
    const { FareGroup: css } = useTheme('FareGroup');
    const iataCode = useConfig().global.companyInfo.iataCode;
    const isY7 = iataCode === 'Y7';
    const options = getUniqueValidOptions(fare.options, false, false, showUnavailableOptions);
    const { renderFareGroupOptionIcon } = useRenderers();
    const [isModalOpen, openModal] = React.useState(false);
    const { t } = useTranslation('FareGroup');
    const icons = (_a = props.iconsForKeyFeatures) !== null && _a !== void 0 ? _a : defaultOptionsIcons;
    const payment = (_b = props.iconsForPaymentTypes) !== null && _b !== void 0 ? _b : paymentIcons;
    const handleOnSelect = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!isDisabled && onSelect) {
            onSelect();
        }
    };
    const { renderFareGroupOptionValue } = useRenderers();
    const renderOptionValue = useCallback((availability) => {
        if (renderFareGroupOptionValue) {
            return renderFareGroupOptionValue(availability);
        }
        return payment[availability];
    }, [payment, renderFareGroupOptionValue]);
    const renderOptionItem = (option, index) => {
        const [isTooltipOpened, setTooltipOpened] = React.useState(false);
        const openTooltip = (state) => {
            var _a;
            setTooltipOpened(state);
            if (props.setTooltipsOpened) {
                (_a = props.setTooltipsOpened) === null || _a === void 0 ? void 0 : _a.call(props, state);
            }
        };
        return (React.createElement("li", { key: index, "data-option-type": option.type, className: cn(css.option, optionClassName, {
                [css.option_unavailable]: option.availability === FareFeaturePaymentType.NotAvailable,
                [css.option_free]: option.availability === FareFeaturePaymentType.Free
            }) },
            React.createElement(FareGroupIcon, { optionType: option.type }),
            React.createElement("span", { className: css.option__title }, option.title),
            React.createElement(Tooltip, { title: option.description ? option.description : option.title, tooltipClassName: css.option__tooltip, enterTouchDelay: 0, open: props.isTooltipsOpened ? props.isTooltipsOpened && isTooltipOpened : isTooltipOpened, onOpen: () => openTooltip(true), onClose: () => openTooltip(false) },
                React.createElement("span", { className: css.option__value }, option.value && !isY7 ? option.value : renderOptionValue(option.availability)))));
    };
    const renderHeader = (props) => {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn(css.title, titleClassName) },
                fare.title,
                !fare.description && (React.createElement("span", { onClick: () => openModal(true), className: css.info }, infoIcon)),
                fare.description && (React.createElement(Tooltip, { title: fare.description, enterTouchDelay: 0 },
                    React.createElement("span", { onClick: () => openModal(true), className: css.info }, infoIcon)))),
            React.createElement("div", { className: css.keyFeatures }, options.map((option, index) => option.isKeyOption &&
                icons.hasOwnProperty(option.type) && (React.createElement(Tooltip, { key: index, title: option.description ? `${option.title} - ${option.description}` : option.title, enterTouchDelay: 0 },
                React.createElement("div", { "data-option-type": option.type, className: cn(css.keyFeature, keyFeatureClassName, {
                        [css.keyFeature_unavailable]: option.availability === FareFeaturePaymentType.NotAvailable,
                        [css.keyFeature_paid]: !props.disableClassForHeaderPayFeature &&
                            option.availability === FareFeaturePaymentType.Pay
                    }) },
                    React.createElement("span", { className: css.keyFeature__icon }, !!option.iconUrl ? (React.createElement("img", { src: option.iconUrl, className: css.keyFeature__icon_external })) : renderFareGroupOptionIcon ? (renderFareGroupOptionIcon(option.type)) : (icons[option.type])),
                    React.createElement("span", { className: css.keyFeature__value }, option.value ? option.value : payment[option.availability]))))))));
    };
    const renderOptions = (props) => {
        return (React.createElement("ul", { className: cn(css.options, optionsClassName) }, options.map((option, index) => excludeRepeatableKeyOptions
            ? !option.isKeyOption && renderOptionItem(option, index)
            : renderOptionItem(option, index))));
    };
    const renderUpgradeOption = (props) => upgradeOption && (React.createElement("div", { className: css.upgradeOffer, onClick: onUpgrade },
        upgradeOption.upgradeOffer,
        React.createElement(Link, { action: () => null, className: css.upgradeOffer__link },
            t('Choose'),
            " ",
            upgradeOption.canUpgradeTo.fareFamily.title,
            Icons.Chevron)));
    const renderButton = (props) => (React.createElement(FareButton, { className: css.button, price: price, originalPrice: originalPrice, priceInMiles: props.priceInMiles, disabled: isDisabled, onClick: handleOnSelect, "data-tariff": fare.title }));
    return (React.createElement("div", { "data-fareId": fare.id, className: cn(className, css.fareGroup, {
            [css.fareGroup_unavailable]: isDisabled,
            [css.fareGroup_Economy]: fare.category === FlightClass.Economy,
            [css.fareGroup_Business]: fare.category === FlightClass.Business,
            [css.fareGroup_white]: variant === 'white',
            [css.fareGroup_recommended]: fare.isRecommended
        }) },
        fare.isRecommended && !isDisabled && !isSelected && React.createElement(RecommendedBadge, null),
        React.createElement("div", null,
            ((_c = props.renderProps) === null || _c === void 0 ? void 0 : _c.renderHeader) ? props.renderProps.renderHeader(props) : renderHeader(props),
            ((_d = props.renderProps) === null || _d === void 0 ? void 0 : _d.renderOptions) ? props.renderProps.renderOptions(props) : renderOptions(props)),
        ((_e = props.renderProps) === null || _e === void 0 ? void 0 : _e.renderButton) ? props.renderProps.renderButton(props) : renderButton(props),
        React.createElement("div", { className: css.upgradeOptionWrapper }, ((_f = props.renderProps) === null || _f === void 0 ? void 0 : _f.renderUpgradeOption)
            ? props.renderProps.renderUpgradeOption(props)
            : renderUpgradeOption(props)),
        ((_g = props.renderProps) === null || _g === void 0 ? void 0 : _g.renderRulesButton) && props.renderProps.renderRulesButton(props),
        props.priceInMiles && !props.disabledForMiles && (React.createElement("div", { className: css.taxes },
            t('Taxes'),
            ":",
            React.createElement(Money, { money: props.price }))),
        props.disabledForMiles && (React.createElement(Notification, { className: css.miles_notification, type: 'context', status: InfoMessageTypeEnum.Info, infoMessage: {
                text: t('Not enough miles on your card'),
                type: InfoMessageTypeEnum.Info,
                code: ''
            } })),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dialog, { isOpen: isModalOpen, dialogType: "fullscreen", dialogHeaderClassName: css.dialog__header, dialogContentClassName: css.dialog__content, dialogHeader: t('Compare fare families'), onClose: () => openModal(false), content: React.createElement(CompareFares, { code: fare.airline.iata, flightId: flightId }), mountOnEnter: true })),
        React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
            React.createElement(Modal, { open: isModalOpen, onClose: () => openModal(false) },
                React.createElement(CompareFares, { code: fare.airline.iata, flightId: flightId })))));
};
export default FareGroup;
