import { __rest } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { InsurancePage } from '../InsurancePage/InsurancePage';
import FullscreenDialogClose from '../../../FullscreenDialog/components/Close/Close';
import Toolbar from '../../../Toolbar';
export const InsurancePageModal = (_a) => {
    var { className, totalPrice, onConfirm, onClose } = _a, props = __rest(_a, ["className", "totalPrice", "onConfirm", "onClose"]);
    const { t } = useTranslation('InsurancePage');
    const { InsurancePageModal: theme } = useTheme('InsurancePage');
    return (React.createElement("div", { className: cn(className, theme.container) },
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.header__wrapper },
                React.createElement("div", { className: theme.title }, t('Choice of insurance')),
                React.createElement(FullscreenDialogClose, { text: t('Back to services'), onClose: onClose }))),
        React.createElement(InsurancePage, Object.assign({}, props)),
        React.createElement(Toolbar, { innerClassName: theme.footer, totalPrice: totalPrice, onButtonClick: onConfirm, onBackButtonClick: onClose })));
};
