import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
const Step = ({ step, isActive = false, isPassed = false, isDisabled = false, id }) => {
    const { Step: css } = useTheme('Stepbar');
    const isClickable = isPassed && !isDisabled && !!step.onClick;
    return (React.createElement("div", { className: cn(css.step, {
            [css.step_active]: isActive,
            [css.step_passed]: isPassed,
            [css.step_clickable]: isClickable,
            [css.step_disabled]: isDisabled
        }), onClick: isClickable ? step.onClick : null },
        step.icon && React.createElement("span", { className: css.icon }, step.icon),
        React.createElement("span", { className: css.title },
            React.createElement("span", { className: css.id }, id),
            React.createElement("span", { className: css.label }, step.label))));
};
export default Step;
