import OrderDataLayer from './OrderDataLayer';
class StubOrderDataLayer extends OrderDataLayer {
    constructor() {
        super(null);
    }
    getOrderType() {
        return null;
    }
    getFlightSegments() {
        return [];
    }
    getOrderStatus() {
        return undefined;
    }
    isInitialized() {
        return false;
    }
    setOrder(order) {
        // Not implemented
    }
}
export default StubOrderDataLayer;
