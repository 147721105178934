import { useMemo } from 'react';
import { GdsServiceVipSubType } from '@websky/graphql';
import { getPassengerLabel } from '../../../../utils';
import { isCheckoutOrderGuard } from '../../../../typeguards';
export const useVariables = (order, state, activeSegment) => {
    const transferServices = useMemo(() => {
        var _a, _b, _c, _d;
        return ((_d = (_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.filter(service => service.subType ===
            GdsServiceVipSubType.GroundTransportation)) !== null && _d !== void 0 ? _d : []);
    }, [order.additionalServices]);
    const notNullPriceServices = useMemo(() => {
        return transferServices.filter(transferService => { var _a; return ((_a = transferService === null || transferService === void 0 ? void 0 : transferService.price) === null || _a === void 0 ? void 0 : _a.amount) !== 0; });
    }, [transferServices]);
    const segmentsById = useMemo(() => {
        const segmentsById = {};
        if (isCheckoutOrderGuard(order)) {
            order.flight.segments.forEach(segment => {
                segmentsById[segment.segment.id] = {
                    city: segment.segment.departure.airport.city.name,
                    date: segment.segment.departure.date
                };
            });
        }
        return segmentsById;
    }, [order]);
    const passengersById = useMemo(() => {
        const passengersById = {};
        let number = 1;
        if (isCheckoutOrderGuard(order)) {
            order.travellers.forEach(traveller => {
                passengersById[traveller.id] = {
                    number: number.toString(10),
                    fullName: getPassengerLabel(traveller)
                };
                number++;
            });
        }
        return passengersById;
    }, [order]);
    const selectedServicesIds = useMemo(() => {
        const selectedServicesIds = new Set();
        for (const [passengerId] of Object.keys(state.services)) {
            for (const [segmentId] of Object.keys(state.services[passengerId])) {
                state.services[passengerId][segmentId].forEach(service => {
                    if (service === null || service === void 0 ? void 0 : service.selected) {
                        selectedServicesIds.add(service.service.id);
                    }
                });
            }
        }
        return selectedServicesIds;
    }, [state.services]);
    const activeSegmentServices = useMemo(() => {
        if (!activeSegment) {
            return notNullPriceServices;
        }
        return notNullPriceServices.filter(service => {
            const allowedSegments = Array.prototype.concat.apply([], service.allowedSegments);
            return allowedSegments.includes(activeSegment);
        });
    }, [notNullPriceServices, activeSegment]);
    const initialState = useMemo(() => {
        return JSON.stringify(state);
    }, []);
    const currentState = useMemo(() => {
        return JSON.stringify(state);
    }, [state]);
    const stateChanged = useMemo(() => {
        return initialState !== currentState;
    }, [initialState, currentState]);
    return {
        transferServices,
        segmentsById,
        passengersById,
        selectedServicesIds,
        activeSegmentServices,
        stateChanged
    };
};
