import { __rest } from "tslib";
import * as React from 'react';
import { useContext } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { OrderStatus as OrderStatusEnum } from '@websky/graphql';
import OrderStatusText from './OrderStatusText/OrderStatusText';
import { OrderCardContext } from '../OrderCard';
import { useTranslation } from 'react-i18next';
import CommonItem from '../CommonItem/CommonItem';
import { overridedIcons } from '../../../Icons';
const OrderStatus = (_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    const { OrderStatus: css } = useTheme('OrderCard');
    const { t } = useTranslation('OrderCard');
    const { order, type, onTransactionsClick } = useContext(OrderCardContext);
    const isBookedOrConfirmed = order.status === OrderStatusEnum.Booked || order.status === OrderStatusEnum.Confirmed;
    const isCancelled = order.status === OrderStatusEnum.Cancelled || order.status === OrderStatusEnum.Problematic;
    const buttons = (React.createElement("div", { className: css.transactions_wrapper },
        React.createElement("span", { className: css.transactions, onClick: onTransactionsClick }, t('Transactions'))));
    buttons;
    const getIcon = () => {
        if (isBookedOrConfirmed) {
            return overridedIcons['Included'];
        }
        else if (isCancelled) {
            return overridedIcons['NotIncluded'];
        }
        return null;
    };
    return (React.createElement(CommonItem, Object.assign({ icon: getIcon(), type: type, iconClassName: css.icon, text: React.createElement(OrderStatusText, { status: order.status }), buttons: null, className: cn(css.root, className, {
            [css.confirmed]: isBookedOrConfirmed,
            [css.canceled]: isCancelled,
            [css.type_review]: type === 'review',
            [css.type_account]: type === 'account'
        }) }, props)));
};
export default OrderStatus;
