import * as CommunicationDetailPage from './components/CommunicationDetailPageWrapper/CommunicationDetailPageWrapper.css';
import * as Communication from './components/CommunicationBase.css';
import * as CategoryCard from './components/CategoriesPage/CategoryCard/CategoryCard.css';
import * as Notifications from './components/NotificationsPage/NotificationsPage.css';
import * as NotificationCard from './components/NotificationCard/NotificationCard.css';
import * as PromotionCard from './components/Promotions/PromotionCard/PromotionCard.css';
import * as AppealCard from './components/Appeals/AppealCard/AppealCard.css';
import * as OrderCard from './components/Orders/OrderCard/OrderCard.css';
import * as PromoCodeCard from './components/PromoCodes/PromoCodeCard/PromoCodeCard.css';
import * as UnreadDot from './components/NotificationCard/UnreadDot/UnreadDot.css';
export default {
    Communication,
    CategoryCard,
    Notifications,
    NotificationCard,
    PromotionCard,
    OrderCard,
    AppealCard,
    PromoCodeCard,
    CommunicationDetailPage,
    UnreadDot
};
