import * as React from 'react';
import { useCallback } from 'react';
import { useTheme } from '../../../theme';
import ParentCounter from '../../../Counter/Counter';
import cn from 'classnames';
const BaggageCounter = props => {
    const { BaggageCounter: css } = useTheme('BaggageCounter');
    const value = props.value || 0;
    const handlerIncrease = useCallback(() => {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, typeof value === 'number' ? value + 1 : 1);
    }, [props.onChange, value, props.disabled]);
    const handlerDecrease = useCallback(() => {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, typeof value === 'number' ? value - 1 : 1);
    }, [props.onChange, value, props.disabled]);
    return (React.createElement(ParentCounter, { classes: {
            root: cn(css.root, props.className, {
                [css.disabled]: props.disabled
            }),
            plus: css.plus,
            minus: cn(css.minus, {
                [css.hidden]: !props.value
            }),
            value: cn(css.value, {
                [css.hidden]: !props.value
            })
        }, value: value, onIncrease: handlerIncrease, onDecrease: handlerDecrease, maxValue: props.max, minValue: props.min, disabled: props.disabled }));
};
export default BaggageCounter;
