import { __rest } from "tslib";
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme } from '../../../theme';
import { usePassengerNames } from './hooks';
const Passenger = props => {
    var _a;
    const { Passenger: css } = useTheme('Refund');
    const _b = (_a = props === null || props === void 0 ? void 0 : props.checkboxProps) !== null && _a !== void 0 ? _a : {}, { checked } = _b, restCheckboxProps = __rest(_b, ["checked"]);
    const { passenger, disabled } = props;
    const { getPassengerName } = usePassengerNames();
    const handleSelect = useCallback((e) => {
        if (disabled) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        props.onPassengerSelect(passenger);
    }, [props.checked, passenger, disabled]);
    const passengerName = useMemo(() => getPassengerName(passenger), [passenger]);
    return (React.createElement(Card, Object.assign({}, props.cardProps, { elevation: 0 }),
        React.createElement(CardActionArea, { onClick: handleSelect, className: cn(css.passenger, { [css.active]: props.checked }), classes: { focusHighlight: cn(css.passenger, css.active) } },
            React.createElement(FormControlLabel, { onClick: handleSelect, className: cn(css.label, { [css.active]: props.checked }), disabled: disabled, control: React.createElement(Checkbox, Object.assign({ size: 'small', color: 'primary' }, restCheckboxProps, { checked: props.checked, classes: { root: css.checkbox, checked: cn(css.checkbox, css.active) } })), label: passengerName }))));
};
export default Passenger;
