import { createSelector } from 'reselect';
import { AviaPassengerType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { getSeatPrice } from '../../../SeatMap/utils';
import { getLinkedTravellersMap } from '../../../utils';
const getPassengersState = (state) => state.passengers;
export const getSelectedServicesState = (state) => state.selectedServices;
export const getSelectedServices = createSelector([getSelectedServicesState, getPassengersState], (state, passengers) => {
    const passengersServices = [];
    passengers.forEach(passenger => {
        if (state[passenger.id]) {
            const passengerServices = state[passenger.id];
            const seatsServices = [], gdsServices = [];
            for (const serviceId in passengerServices) {
                if (passengerServices.hasOwnProperty(serviceId) && passengerServices[serviceId] !== null) {
                    const services = passengerServices[serviceId];
                    services.forEach(service => {
                        switch (service.servicePayload.type) {
                            case OrderAdditionalServiceGdsServiceServiceType.Seat:
                                const seatServicePayload = service.servicePayload;
                                if (service.number > 0) {
                                    service.segments.forEach(segmentId => {
                                        seatsServices.push({
                                            segmentId,
                                            deckId: seatServicePayload.deckId,
                                            letter: seatServicePayload.letter,
                                            row: parseInt(seatServicePayload.row),
                                            type: seatServicePayload.seatType
                                        });
                                    });
                                }
                                break;
                            case OrderAdditionalServiceGdsServiceServiceType.Baggage:
                            case OrderAdditionalServiceGdsServiceServiceType.Meal:
                            case OrderAdditionalServiceGdsServiceServiceType.BusinessLounge:
                            case OrderAdditionalServiceGdsServiceServiceType.Vip:
                            case OrderAdditionalServiceGdsServiceServiceType.Misc:
                                gdsServices.push({
                                    segmentIds: service.segments,
                                    serviceId: serviceId,
                                    count: service.number
                                });
                                break;
                            default:
                                gdsServices.push({
                                    segmentIds: service.segments,
                                    serviceId: serviceId,
                                    count: service.number
                                });
                                break;
                        }
                    });
                }
            }
            passengersServices.push({
                travellerId: passenger.id,
                gdsServices: {
                    setServices: gdsServices
                },
                seats: seatsServices
            });
        }
    });
    return passengersServices;
});
export const getHasSelectedSeats = createSelector(getSelectedServices, selectedServices => selectedServices === null || selectedServices === void 0 ? void 0 : selectedServices.some(travellerServices => { var _a; return ((_a = travellerServices.seats) === null || _a === void 0 ? void 0 : _a.length) > 0; }));
export const getPassengerStateCompatible = createSelector([getPassengersState, getSelectedServicesState], (passengers, selectedServices) => {
    const linkedPassengersIdsMap = getLinkedTravellersMap(passengers);
    const passengerState = passengers.map(passenger => {
        const passengerServices = selectedServices[passenger.id];
        const passengersServices = [], seats = [];
        for (const serviceId in passengerServices) {
            if (passengerServices.hasOwnProperty(serviceId) && passengerServices[serviceId]) {
                const services = passengerServices[serviceId];
                services.forEach(service => {
                    if (service.number > 0) {
                        switch (service.servicePayload.type) {
                            case OrderAdditionalServiceGdsServiceServiceType.Seat:
                                const seatServicePayload = service.servicePayload;
                                const price = getSeatPrice(seatServicePayload);
                                service.segments.forEach(segmentId => {
                                    seats.push(Object.assign(Object.assign({}, seatServicePayload), { price,
                                        segmentId }));
                                });
                                break;
                            case OrderAdditionalServiceGdsServiceServiceType.Baggage:
                            case OrderAdditionalServiceGdsServiceServiceType.Meal:
                                passengersServices.push({
                                    segmentIds: service.segments,
                                    serviceId: serviceId,
                                    count: service.number,
                                    confirmedCount: service.servicePayload.confirmedCount
                                });
                                break;
                            case OrderAdditionalServiceGdsServiceServiceType.Vip:
                                passengersServices.push({
                                    segmentIds: service.segments,
                                    serviceId: serviceId,
                                    count: service.number,
                                    confirmedCount: service.servicePayload.confirmedCount
                                });
                        }
                    }
                });
            }
        }
        return {
            id: passenger.id,
            type: passenger.type,
            values: passenger.values,
            services: passengersServices,
            seatRestrictions: passenger.seatRestrictions,
            infantPassengerId: passenger.type === AviaPassengerType.ADT && (linkedPassengersIdsMap.get(passenger.id) || null),
            supplierTravellerType: passenger.supplierTravellerType,
            seats
        };
    });
    return passengerState;
});
