import * as React from 'react';
import { memo, useCallback } from 'react';
import MealComponent from '../../Meal';
import { MealViewMode } from '../../Meal/types';
import memes from '../../memes';
import WebskyAnalyticsApollo from '../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { WxEvents } from '../../DataLayerAnalytics/types';
import { calculateMaxServiceCount } from '../../shared/lib/calculateMaxServiceCount';
import { getFilterMeals } from '../utils';
const reduceSelectedMealsCount = memes((total, selectedMeal, mealId, passengerId, segmentId, mode) => {
    if (!selectedMeal.confirmedCount) {
        selectedMeal.confirmedCount = 0;
    }
    let count = total;
    if (mealId === selectedMeal.mealId &&
        segmentId === selectedMeal.segmentId &&
        passengerId === selectedMeal.passengerId) {
        count = total + selectedMeal.count - selectedMeal.confirmedCount;
        if (mode === MealViewMode.confirmed) {
            count = total + selectedMeal.confirmedCount;
        }
    }
    return count;
});
export const MealWrapper = memo(({ selectedMeals, initialSelectedMeals, meal, segmentId, passengers, gdsServices, onMealAdd, onMealRemove, onMealConfirm, onMealAddForPassengers, onMealClear, onMealOpen, readonly = false, mode = MealViewMode.select, fullSize, isRecommend, isLoading }) => {
    const isCountChanged = React.useMemo(() => {
        const mealId = meal.id;
        const selectedMeal = getFilterMeals(selectedMeals, mealId, segmentId);
        const initialMeal = getFilterMeals(initialSelectedMeals, mealId, segmentId);
        return JSON.stringify(selectedMeal) !== JSON.stringify(initialMeal);
    }, [selectedMeals]);
    const onMealAddHandler = useCallback((meal, passengerId, count = 1) => {
        onMealAdd(meal, segmentId, passengerId, count);
    }, [segmentId, onMealAdd]);
    const onMealRemoveHandler = useCallback((meal, passengerId, count = 1) => {
        onMealRemove(meal, segmentId, passengerId, count);
    }, [segmentId, onMealRemove]);
    const onMealAddForPassengerHandler = useCallback((meal) => {
        onMealAddForPassengers(meal, segmentId, meal.allowedPassengers, 1);
    }, [segmentId, onMealAddForPassengers]);
    const onMealClearHandler = useCallback((meal) => {
        onMealClear(meal, segmentId);
        onMealConfirm === null || onMealConfirm === void 0 ? void 0 : onMealConfirm(meal, segmentId);
    }, [segmentId, onMealConfirm]);
    const onConfirmHandler = useCallback((meal) => {
        if (!isCountChanged) {
            return;
        }
        onMealConfirm === null || onMealConfirm === void 0 ? void 0 : onMealConfirm(meal, segmentId);
    }, [segmentId, isCountChanged, onMealConfirm]);
    const onCloseHandler = useCallback((meal) => {
        if (!isCountChanged) {
            return;
        }
        onMealClear(meal, segmentId);
        initialSelectedMeals.forEach(initialSelectedMeal => {
            if (initialSelectedMeal.segmentId === segmentId && meal.id === initialSelectedMeal.mealId) {
                onMealAddHandler(meal, initialSelectedMeal.passengerId, initialSelectedMeal.count);
            }
        });
    }, [segmentId, isCountChanged, initialSelectedMeals, onMealClear]);
    const onPopupOpened = () => {
        onMealOpen === null || onMealOpen === void 0 ? void 0 : onMealOpen();
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.MealItem, { servicesIds: [meal.id], segmentId });
    };
    return (React.createElement(MealComponent, { key: `${meal.id}_${segmentId}`, meal: meal, fullSize: fullSize, onIncrease: (meal, passenger) => onMealAddHandler(meal, passenger.id), onDecrease: (meal, passenger) => onMealRemoveHandler(meal, passenger.id), onAddForAll: onMealAddForPassengerHandler, onClear: onMealClearHandler, onConfirm: onConfirmHandler, onClose: onCloseHandler, readonly: readonly || !meal.canBeAdded, onPopupOpened: onPopupOpened, isCountChanged: isCountChanged, counters: passengers
            .filter(passenger => meal.allowedPassengers.some(passengerId => passengerId === passenger.id))
            .map(passenger => {
            const count = selectedMeals.reduce((total, selectedMeal) => reduceSelectedMealsCount(total, selectedMeal, meal.id, passenger.id, segmentId, mode), 0);
            return {
                passenger: {
                    id: passenger.id,
                    name: passenger.label
                },
                count,
                minAvailable: 0,
                maxAvailable: calculateMaxServiceCount(passengers, gdsServices, meal.id, count, segmentId)
            };
        }), isLoading: isLoading, mode: mode }));
});
