import * as React from 'react';
import ModalX from '../../../BaseComponents/ModalX';
import { Money } from '../../../Money';
import Button from '../../../BaseComponents/Button';
import Checkbox from '../../../BaseComponents/Checkbox';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
import { Divider } from '@mui/material';
initI18n('Transfer');
const TransferSelectorModal = ({ service, passengers, segments, isOpen, onClose, servicesState, onClear, onChange, totalPrice, onSubmit }) => {
    const { t } = useTranslation('Transfer');
    const { TransferSelectorModal: css } = useTheme('Transfer');
    const Header = () => {
        return (React.createElement("div", null,
            React.createElement("div", { className: css.serviceName }, service.name),
            React.createElement("div", { className: css.serviceDescription }, service.description)));
    };
    const Footer = () => {
        return (React.createElement("div", { className: css.footer },
            React.createElement("div", { className: css.total },
                React.createElement("span", { className: css.price },
                    t('Total'),
                    ":",
                    React.createElement(Money, { money: totalPrice })),
                React.createElement(Divider, { className: css.divider, orientation: "vertical" }),
                React.createElement("div", { className: css.clear },
                    React.createElement("span", { onClick: onClear }, t('Clear')))),
            React.createElement("div", { className: css.footerControls },
                React.createElement(Button, { variant: "secondary", onClick: onClose, classes: { root: css.cancel } }, t('Cancel')),
                React.createElement(Button, { variant: "action", onClick: onSubmit, classes: { root: css.confirm } }, t('Confirm')))));
    };
    return (React.createElement(ModalX, { isOpen: isOpen, onClose: onClose, title: React.createElement(Header, null), footer: React.createElement(Footer, null), classes: { paper: css.paper } },
        React.createElement("div", { className: css.content }, service.allowedSegments.map((segmentGroup, index) => {
            return (React.createElement(React.Fragment, { key: index }, segmentGroup.map(segmentId => {
                return (React.createElement("div", { key: segmentId },
                    React.createElement("div", { className: css.heading },
                        React.createElement("div", { className: css.date }, segments[segmentId].date),
                        React.createElement("div", { className: css.city }, segments[segmentId].city),
                        React.createElement("div", { className: css.cost }, t('Cost'))),
                    service.allowedPassengers.map((passengerGroup, index) => {
                        return passengerGroup.map(passengerId => {
                            const passengerService = servicesState[passengerId][segmentId];
                            if (!passengerService) {
                                return null;
                            }
                            const disabled = passengerService.confirmed;
                            return (React.createElement("div", { className: css.passenger, key: passengerId, onClick: () => disabled ? null : onChange(passengerId, segmentId) },
                                React.createElement(Checkbox, { className: css.checkbox, disabled: disabled, checked: passengerService.selected || passengerService.confirmed }),
                                React.createElement("div", { className: css.passengerNumber }, passengers[passengerId].number),
                                React.createElement("div", { className: css.passengerName }, passengers[passengerId].fullName),
                                React.createElement(Money, { moneyClassName: css.passengerPrice, money: service.price })));
                        });
                    })));
            })));
        }))));
};
export default TransferSelectorModal;
