import * as React from 'react';
import cn from 'classnames';
import Step from './Step/Step';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import { useAvailableSteps } from '../hooks';
import Money from '../../Money';
import { Chevron, ShoppingCart } from '../../Icons';
import { stepIcons } from '../Icons';
initI18n('Stepbar');
const Stepbar = ({ steps, activeStep = 0, onCartClick, totalPrice, additional, content, renderAdditionalStep, className }) => {
    const { Stepbar: css } = useTheme('Stepbar');
    const availableSteps = useAvailableSteps(steps);
    const stepsArray = [];
    for (const key in availableSteps) {
        if (availableSteps.hasOwnProperty(key)) {
            stepsArray.push(Object.assign(Object.assign({}, availableSteps[key]), { icon: stepIcons[key] }));
        }
    }
    return (React.createElement("div", { className: cn(className, css.stepbar) },
        React.createElement("div", { className: css.inner },
            React.createElement("div", { className: css.content },
                additional, content !== null && content !== void 0 ? content : (React.createElement(React.Fragment, null,
                stepsArray
                    .filter(step => !step.isHidden)
                    .map((step, index) => (React.createElement(Step, { key: index, id: index + 1, step: step, "data-step": step.type, isPassed: index < activeStep, isActive: index === activeStep, isDisabled: step.isDisabled }))),
                renderAdditionalStep && renderAdditionalStep()))),
            onCartClick && (React.createElement("div", { onClick: onCartClick, className: css.cart },
                ShoppingCart,
                React.createElement(Money, { money: totalPrice }),
                Chevron)))));
};
export default Stepbar;
