import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Choice from '../Choice/Choice';
import Button from '../../../../../Button/Button';
import { useTheme } from '../../../../../theme';
export const CommonSelect = props => {
    const theme = useTheme('Exchange').CommonSelect;
    const { t } = useTranslation('Exchange');
    const history = useHistory();
    const onBackHandler = () => {
        if (props.onBackHandler) {
            props.onBackHandler();
        }
        else {
            history.goBack();
        }
    };
    return (React.createElement("div", { className: theme.container },
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.icon }, props.icon),
            props.name),
        React.createElement(Choice, { items: props.items, selectedKeys: props.selectedKeys, controlType: props.controlType, onChange: props.onChange }),
        React.createElement("div", { className: theme.buttons },
            !props.hideBackButton && (React.createElement(Button, { variant: "flat", onClick: onBackHandler, classes: {
                    root: theme.back
                } }, t('Back'))),
            React.createElement(Button, { onClick: props.onNextHandler, variant: "flat", classes: {
                    root: theme.button
                }, disabled: !props.selectedKeys.length || props.disableButton, "data-next-button": true }, t('Next')))));
};
export default CommonSelect;
