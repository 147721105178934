import * as React from 'react';
import cn from 'classnames';
import Button from '../../../../../Button';
import { useTheme } from '../../../../../theme';
import { Add, Minus } from '../../../../../Icons';
const Actions = React.memo(({ canRemoveSegment, showAddSegment, showRemoveSegment, onAddSegment, removeLastSegment, isAlternativeView }) => {
    const { Actions: css } = useTheme('SearchForm');
    return (React.createElement("div", { className: cn(css.actions, {
            [css.actions_alternative]: isAlternativeView
        }) },
        React.createElement(Button, { className: cn(css.addSegment, {
                [css.addSegment_alternative]: isAlternativeView
            }), onClick: showAddSegment ? onAddSegment : null, disabled: !showAddSegment }, Add),
        canRemoveSegment && (React.createElement(Button, { className: cn(css.removeSegment, {
                [css.removeSegment_alternative]: isAlternativeView
            }), onClick: removeLastSegment, disabled: !showRemoveSegment }, Minus))));
});
export default Actions;
