import { __awaiter } from "tslib";
import * as React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import TravellerForm from '../../TravellerForm';
import { TravellerTypes } from '../types';
import { useTheme } from '../../theme';
import { getUserValue, initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import UserCard from '../../UserCard/components/UserCard';
import MediaQuery from 'react-responsive';
import Modal from '../../Modal';
import { ChevronLeft } from '../../Icons';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { TravellerFieldEnum } from '@websky/graphql';
import { OverrideComponent } from '../../renderProps';
initI18n('Traveller');
const Traveller = props => {
    const css = useTheme('Traveller').Traveller;
    const [open, setOpen] = React.useState(false);
    const expandIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z", fill: "#9A9A9A" })));
    const { t } = useTranslation('Account');
    const userLabel = React.useMemo(() => {
        if (props.type === TravellerTypes.CompanionEmpty) {
            return t('Add companion');
        }
        const firstName = getUserValue(props.traveller, TravellerFieldEnum.FirstName), lastName = getUserValue(props.traveller, TravellerFieldEnum.LastName);
        return `${lastName} ${firstName}`;
    }, [props.type, props.traveller]);
    const travellerFormProps = {
        onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
            return yield props.onSubmit(Object.assign({}, values));
        }),
        onDelete: () => {
            return props.onDelete(props.traveller.id);
        },
        type: props.type,
        traveller: props.traveller && props.traveller.id !== '0' ? props.traveller : Object.assign(Object.assign({}, props.traveller), { documents: [] })
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Accordion, { className: css.wrapper, expanded: props.redirectToPassengersPage || props.expanded, classes: {
                    expanded: css.expanded
                }, onChange: props.handleChange },
                React.createElement(AccordionSummary, { expandIcon: expandIcon, classes: {
                        expandIcon: css.expandIcon
                    }, className: cn({ [css.header__redirect]: props.redirectToPassengersPage }, css.header) },
                    React.createElement(UserCard, { profile: props.traveller, editText: props.type === TravellerTypes.PersonalDetails ? t('Edit') : null, inline: true, type: props.type, className: css.userCard, headerClassName: css.summary })),
                !props.redirectToPassengersPage && (React.createElement(AccordionDetails, { className: css.content__traveller },
                    React.createElement(OverrideComponent, { componentProps: Object.assign(Object.assign({}, travellerFormProps), { onClose: props.handleChange }), component: { TravellerForm } }))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(UserCard, { profile: props.traveller, type: props.type, className: css.userCard, headerClassName: css.summary, onClick: () => (props.redirectToPassengersPage ? props.handleChange() : setOpen(true)) }),
            React.createElement(Modal, { open: open, onClose: () => setOpen(false), closeButtonRenderer: onClick => {
                    return (React.createElement("div", { className: css.popup__header, onClick: onClick },
                        ' ',
                        ChevronLeft,
                        " ",
                        userLabel));
                } },
                React.createElement(OverrideComponent, { componentProps: Object.assign(Object.assign({}, travellerFormProps), { onClose: () => setOpen(false) }), component: { TravellerForm } })))));
};
export default Traveller;
