import { format, startOfWeek } from 'date-fns';
import { SET_LOCATION, SWITCH_LOCATION } from './actions';
import { API_DATE_FORMAT } from '../../../utils';
const initialState = {
    departure: null,
    arrival: null,
    date: format(startOfWeek(new Date(), { weekStartsOn: 1 }), API_DATE_FORMAT)
};
export const segmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOCATION:
            return Object.assign(Object.assign({}, state), { [action.payload.locationType]: action.payload.location });
        case SWITCH_LOCATION:
            return Object.assign(Object.assign({}, state), { departure: state.arrival, arrival: state.departure });
        default:
            return state;
    }
};
