import * as React from 'react';
import { useCallback } from 'react';
export const usePagination = initialIndex => {
    const [paginationIndex, setPaginationIndex] = React.useState(initialIndex !== null && initialIndex !== void 0 ? initialIndex : 0);
    const handleChange = useCallback((index) => {
        setPaginationIndex(index);
    }, [paginationIndex]);
    return {
        currentIndex: paginationIndex,
        handleChange
    };
};
