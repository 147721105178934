import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route, useHistory } from 'react-router-dom';
import { getSelectedPassengersWithChildrenIds } from '../../store/passengers/selectors';
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute';
import PassengersPage from '../Pages/Passengers';
import ReasonsPage from '../Pages/Reasons';
import RequestFormPage from '../Pages/Request';
import AddFilesPage from '../Pages/AddFiles';
import StepsButtons from '../StepsButtons/StepsButtons';
import CreateTicket from '../CreateTicket/CreateTicket';
import Ticket from '../Ticket/Ticket';
import { useHtmlDescriptionOfError, useMobileStepsPositionClassNames, useRefundInfo } from './hooks';
import { getSelectedReason } from '../../store/reason/selectors';
import { createGetCreateRefundParams, isAutoRefundMode } from '../../store/selectors';
import { getActiveStep, getCanGoToNextStep } from '../../store/steps/selectors';
import { nextStep, setStep } from '../../store/steps/actions';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import Wrapper from '../Wrapper/Wrapper';
import { StepType, Unvoluntary } from '../../types';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import GetLastRefundTicket from '../GetLastRefundTicket/GetLastRefundTicket';
import { useToggleable } from '../../../hooks';
import RefundInfoModal from '../Modals/RefundInfoModal/RefundInfoModal';
import PromoLoader from '../../../PromoLoader';
import { getIsAutoModeAllowed } from '../../store/aviaOrder/selectors';
import { getIsDocUploadRequired } from '../../store/refundInfo/selectors';
import { useConfirmRefundOrderMutation, useRefuseRefundOrderMutation } from '@websky/graphql';
import WarningModal from '../../../WarningModal';
import SimpleLoader from '../../../SimpleLoader';
import { pnrBlockedRegExp } from '../../../apolloErrorLink';
import { useConfig } from '../../../context';
const errorsRequireRedirectToOrder = [
    'Refund operations with used segments are not permitted',
    'THIS IS A MANUAL OPERATION - SET PENALTY MANUALLY'
];
initI18n('Refund');
const Refund = props => {
    const { t } = useTranslation('Refund');
    const { Refund: css } = useTheme('Refund');
    const { isOpen: allowAccessToNewlyCreatedRefund, open: allowOpenNewTicket } = useToggleable(false);
    const { isOpen: successConfirmModalOpen, open: openSuccessConfirmModal, close: closeSuccessConfirmModal } = useToggleable(false);
    const currentStep = useSelector(getActiveStep);
    const selectedReason = useSelector(getSelectedReason);
    const isAutoMode = useSelector(isAutoRefundMode);
    const selectedPassengersWithChildrenIds = useSelector(getSelectedPassengersWithChildrenIds);
    const canGoToNextStep = useSelector(getCanGoToNextStep);
    const getRefundParamsSelector = createGetCreateRefundParams(props.orderId);
    const baseUrl = `/booking/${props.orderId}/refund`;
    const history = useHistory();
    const isAutoModeAllowed = useSelector(getIsAutoModeAllowed);
    const isDocumentUploadRequired = useSelector(getIsDocUploadRequired);
    const [refundOrder, setRefundOrder] = useState(null);
    const [requestError, setRequestError] = useState(null);
    const [onClearError, setOnClearError] = useState(null);
    const [reloadOrderLoading, setReloadOrderLoading] = useState(false);
    const createRefundParams = useSelector(getRefundParamsSelector);
    const { isOpen: isEmptyPassengersModal, open: openEmptyPassengersModal, close: closeEmptyPassengersModal } = useToggleable(false);
    const { Engine: { exareShowPassengersStep } } = useConfig();
    const dispatch = useDispatch();
    const handleReturnToOrder = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setReloadOrderLoading(true);
        yield props.onReloadCheckoutOrder();
        setReloadOrderLoading(true);
        document.location.hash = `#/booking/${props.orderId}/order`;
    }), [document.location]);
    const handleCloseConfirmModal = useCallback(() => {
        closeSuccessConfirmModal();
        handleReturnToOrder();
    }, [closeSuccessConfirmModal]);
    const goToNextStep = useCallback(() => dispatch(nextStep()), [dispatch, currentStep]);
    const handleCreateNewRequest = useCallback(() => {
        history.push(`${baseUrl}/new`);
        dispatch(setStep({ additionalText: '', type: StepType.Passengers, isActive: true }));
    }, []);
    const [refuseRefund, { loading: refuseLoading }] = useRefuseRefundOrderMutation({ variables: { id: null } });
    const [confirmRefund, { loading: confirmLoading }] = useConfirmRefundOrderMutation({ variables: { id: null } });
    const { setClassesToPassedSteps } = useMobileStepsPositionClassNames();
    const htmlDescriptionOfError = useHtmlDescriptionOfError(requestError);
    const hasPnrBlockedError = (errors) => {
        return errors === null || errors === void 0 ? void 0 : errors.some(error => pnrBlockedRegExp.test((error === null || error === void 0 ? void 0 : error.message) || ''));
    };
    const onRequestError = (errors = []) => {
        const debugMessage = errors[0]['debugMessage'] || errors[0]['message'];
        if (debugMessage === 'There is already an open request for a refund.') {
            history.push(`${baseUrl.replace('new/', '')}/`);
        }
        else if (debugMessage === 'This operation is not available') {
            history.push(`${baseUrl.replace('new', '')}/`);
        }
        else if (!hasPnrBlockedError(errors)) {
            setRequestError(errors[0]);
        }
    };
    const clearError = () => {
        setRequestError(null);
        onClearError === null || onClearError === void 0 ? void 0 : onClearError();
    };
    const clearErrorAndGoOrder = () => {
        clearError();
        handleReturnToOrder();
    };
    const handleRefuse = useCallback((id) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { data, errors } = yield refuseRefund({ variables: { id } });
            if (errors === null || errors === void 0 ? void 0 : errors.length) {
                onRequestError(errors);
            }
            else if (data === null || data === void 0 ? void 0 : data.RefuseExare) {
                handleCreateNewRequest();
            }
        }
        catch (e) {
            if ((_a = e === null || e === void 0 ? void 0 : e.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
                onRequestError(e.graphQLErrors);
            }
            console.log(e);
        }
    }), []);
    const handleConfirm = useCallback((id) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        try {
            const { data, errors } = yield confirmRefund({ variables: { id } });
            if (errors === null || errors === void 0 ? void 0 : errors.length) {
                onRequestError(errors);
            }
            else if (data === null || data === void 0 ? void 0 : data.ConfirmExare) {
                setRefundOrder(data.ConfirmExare);
                openSuccessConfirmModal();
            }
        }
        catch (e) {
            if ((_b = e === null || e === void 0 ? void 0 : e.graphQLErrors) === null || _b === void 0 ? void 0 : _b.length) {
                onRequestError(e.graphQLErrors);
            }
            console.log(e);
        }
    }), []);
    useEffect(() => setClassesToPassedSteps, [currentStep.type]);
    const { reasons, docks: docsToFilesMap, prices, loading: pricesLoading, refetch } = useRefundInfo({
        variables: {
            includePrices: false,
            isCompelled: selectedReason !== Unvoluntary.Unvoluntary,
            orderId: props.orderId,
            passengers: selectedPassengersWithChildrenIds
        },
        skip: currentStep.type !== StepType.Reason,
        onCompleted: data => {
            var _a;
            if ((data === null || data === void 0 ? void 0 : data.GetRefundInfo) && !((_a = data === null || data === void 0 ? void 0 : data.GetRefundInfo) === null || _a === void 0 ? void 0 : _a.unvoluntaryReasonsInfo.length)) {
                dispatch(setStep({ additionalText: '', type: StepType.CreateTicket, isActive: true }));
            }
        },
        onError: error => {
            onRequestError(error === null || error === void 0 ? void 0 : error.graphQLErrors);
        }
    });
    const fetchReasons = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield refetch({
                includePrices: isAutoModeAllowed && selectedReason === Unvoluntary.Unvoluntary,
                isCompelled: selectedReason !== Unvoluntary.Unvoluntary,
                orderId: props.orderId,
                passengers: selectedPassengersWithChildrenIds
            });
        }
        catch (e) {
            const error = e;
            onRequestError(error === null || error === void 0 ? void 0 : error.graphQLErrors);
            console.log(e);
        }
    });
    useEffect(() => {
        if (currentStep.type === StepType.CreateTicket) {
            if (selectedPassengersWithChildrenIds.length) {
                fetchReasons();
            }
            else {
                openEmptyPassengersModal();
            }
        }
    }, [currentStep]);
    const closeEmptyModalAndGoPassengers = useCallback(() => {
        closeEmptyPassengersModal();
        dispatch(setStep({ additionalText: '', type: StepType.Passengers, isActive: false }));
    }, [closeEmptyPassengersModal, dispatch]);
    const isErrorRequireRedirectToOrder = requestError && errorsRequireRedirectToOrder.includes(requestError.message);
    const isLoading = pricesLoading || confirmLoading || refuseLoading;
    return (React.createElement(React.Fragment, null,
        reloadOrderLoading && React.createElement(SimpleLoader, null),
        React.createElement(ProtectedRoute, { isAccessible: !!props.refundOrder || allowAccessToNewlyCreatedRefund, path: '/booking/:orderId/refund', exact: true, redirectTo: `${baseUrl}/new`, render: () => (React.createElement(Wrapper, { onClose: handleReturnToOrder, hideBreadcrumbs: true }, confirmLoading || refuseLoading ? (React.createElement(PromoLoader, null)) : (React.createElement(GetLastRefundTicket, { orderId: props.orderId }, lastRefundOrder => {
                allowOpenNewTicket();
                return (React.createElement(React.Fragment, null,
                    React.createElement(Ticket, { isLoading: confirmLoading || refuseLoading, onNewRequest: handleCreateNewRequest, isAutoMode: isAutoMode, refundOrder: refundOrder !== null && refundOrder !== void 0 ? refundOrder : lastRefundOrder, onConfirm: handleConfirm, onReject: handleRefuse }),
                    React.createElement(RefundInfoModal, { onAgree: handleCloseConfirmModal, renderText: () => t('Successful refund. Confirmation and detalization have been sent to your e-mail'), renderAgreeText: () => t('Ok'), open: successConfirmModalOpen })));
            })))) }),
        React.createElement(Route, { path: '/booking/:orderId/refund/new', exact: true, render: () => (React.createElement(Wrapper, { onClose: handleReturnToOrder, className: css.wrapper },
                React.createElement(PassengersPage, { skipPassengersStep: !exareShowPassengersStep }),
                React.createElement(ReasonsPage, { isLoading: pricesLoading, reasonsGroups: reasons }),
                isDocumentUploadRequired && React.createElement(AddFilesPage, { docsToFilesMap: docsToFilesMap }),
                React.createElement(RequestFormPage, { isLoading: pricesLoading, prices: isAutoModeAllowed && selectedReason === Unvoluntary.Unvoluntary && prices }),
                !isLoading &&
                    currentStep.type !== StepType.CreateTicket &&
                    currentStep.type !== StepType.Ticket && (React.createElement(MediaQuery, { maxWidth: 'mobile' },
                    React.createElement(StepsButtons, { className: css.buttons, position: 'center', nextButton: {
                            children: t('Continue'),
                            onClick: goToNextStep,
                            disabled: !canGoToNextStep
                        } }))),
                currentStep.type === StepType.Ticket && createRefundParams && (React.createElement(CreateTicket, { onCreate: refundOrder => {
                        setRefundOrder(refundOrder);
                        allowOpenNewTicket();
                        history.push(baseUrl);
                    }, onError: errors => {
                        onRequestError(errors);
                        setOnClearError(() => history.push(baseUrl));
                    }, params: createRefundParams })))) }),
        React.createElement(WarningModal, { classes: {
                scrollBody: css.scrollBody,
                paper: css.paper
            }, title: t('Oops...'), content: (htmlDescriptionOfError === null || htmlDescriptionOfError === void 0 ? void 0 : htmlDescriptionOfError.header) ? (React.createElement("div", { className: css.warningModalContent, dangerouslySetInnerHTML: { __html: htmlDescriptionOfError.header } })) : ((requestError === null || requestError === void 0 ? void 0 : requestError.message) || t('Unknown error')), errorMessage: (htmlDescriptionOfError === null || htmlDescriptionOfError === void 0 ? void 0 : htmlDescriptionOfError.body) && (React.createElement("div", { className: css.warningModalContent, dangerouslySetInnerHTML: { __html: htmlDescriptionOfError.body } })), isOpen: !!requestError, onClose: !isErrorRequireRedirectToOrder ? clearError : null, buttonText: isErrorRequireRedirectToOrder ? t('Back to order') : null, onButtonClick: isErrorRequireRedirectToOrder ? clearErrorAndGoOrder : null }),
        React.createElement(WarningModal, { classes: {
                scrollBody: css.scrollBody,
                paper: css.paper
            }, title: t('Oops...'), content: t('No passengers selected for ticket refund. Please select at least one passenger to proceed.'), isOpen: isEmptyPassengersModal, buttonText: t('Go to passenger selection'), onButtonClick: closeEmptyModalAndGoPassengers })));
};
export default Refund;
