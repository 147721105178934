import { __awaiter } from "tslib";
import { useCompletePaymentMutation } from '@websky/graphql';
export const useCompletePayment = ({ orderId, onSuccess, onFail }) => {
    const [completePaymentMutation, { loading: completePaymentLoading }] = useCompletePaymentMutation();
    const completePayment = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield completePaymentMutation({
                variables: Object.assign({ orderId, secureCode: null }, values)
            });
            if (data === null || data === void 0 ? void 0 : data.CompletePayment.result) {
                onSuccess();
                return true;
            }
            else {
                onFail();
                return false;
            }
        }
        catch (_a) {
            onFail();
            return false;
        }
    });
    return {
        completePayment,
        completePaymentLoading
    };
};
