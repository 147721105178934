import OrderDataLayer from './OrderDataLayer';
import { OrderType } from './types';
import { OrderStep } from '../types';
class SearchResultsDataLayer extends OrderDataLayer {
    constructor(instance) {
        super(instance);
    }
    init(currency, promocode) {
        this.dataLayer.init({
            coupon: promocode,
            currency
        });
    }
    getOrderType() {
        return OrderType.SearchResults;
    }
    getFlightSegments() {
        return [];
    }
    getOrderStatus() {
        return OrderStep.Booking;
    }
    getDataLayer() {
        return this.dataLayer;
    }
}
export default SearchResultsDataLayer;
