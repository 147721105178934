import * as React from 'react';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { iataWithAdditionalQueryForServices, useAdditionalQueryForServices, useAdditonalQueryForSeatMap, useServicePageProps } from '../hooks';
import { useUpsaleWarning } from '../../../Checkout/components/Checkout/Upsale/hooks';
import { ServiceType } from '../../../Checkout/components/Checkout/OrderPaidServiceCards/types';
import ServicePage from '../../../Checkout/components/Checkout/ServicePage/ServicePage';
import ServiceCardDialog from '../../../Checkout/components/Checkout/OrderPaidServiceCards/components/ServiceCardDialog/ServiceCardDialog';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import { useSelector } from 'react-redux';
import { getSeatsMinPrice, getServicesFallbackURL, getIsAllSegmentsAvailableOnlyOnCheckin, shouldRedirectToFallback, getOrder } from '../../../Checkout/store/order/selectors';
import { useTranslation } from 'react-i18next';
import { getSeatsPrice } from '../../../Checkout/store/orderPrice/selectors';
import { useTheme } from '../../../theme';
import icons from '../../icons';
import { getPassengerStateCompatible } from '../../../Checkout/store/selectedServices/selectors';
import { useRenderers } from '../../../renderProps';
import { getSeatsWithDoubleSeats } from '../../../SeatMap/utils';
import SeatSummary from '../../../ServiceSummary/services/SeatSummary';
import { getTravellersMap } from '../../../utils';
import { getSegmentsById } from '../../../Cart/utils';
import { useCart } from '../../../Checkout/components/Checkout/Stepbar/hooks';
import { useIataCode } from '../../../hooks';
import SimpleLoader from '../../../SimpleLoader';
const SeatsAdditionalService = ({ isCheckinAvailable }) => {
    var _a;
    const { t } = useTranslation('Checkout');
    const minPrice = useSelector(getSeatsMinPrice);
    const seatsIssuedPrice = useSelector(getSeatsPrice);
    const servicePageProps = useServicePageProps();
    const shouldRedirect = useSelector(shouldRedirectToFallback);
    const servicesRedirectURL = useSelector(getServicesFallbackURL);
    const isAllSegmentsAvailableOnCheckin = useSelector(getIsAllSegmentsAvailableOnlyOnCheckin);
    const { showWarning, closeWarning, toggleWarningIfOrderConfirmed } = useUpsaleWarning();
    const toggleSeatsModal = () => {
        if (iataWithAdditionalQueryForServices.includes(iataCode)) {
            loadAndDispatchAdditionalServices();
        }
        servicePageProps.toggleModal(ServiceType.Seats);
    };
    const handleClick = shouldRedirect ? toggleWarningIfOrderConfirmed : toggleSeatsModal;
    const order = useSelector(getOrder);
    const passengers = useSelector(getPassengerStateCompatible);
    const { Seats: css } = useTheme('CheckoutAdditionalService');
    const iataCode = useIataCode();
    const { onDeleteItem } = useCart();
    const allSeatsConfirmed = passengers.every(passenger => {
        const seatsWithDoubleSeats = getSeatsWithDoubleSeats(passenger.seats);
        return passenger.seats.every(seat => seat.isConfirmed || seatsWithDoubleSeats.has(seat.number));
    });
    const { isLoading: isSeatMapLoading, loadAndDispatch: loadAndDispatchSeatMap } = useAdditonalQueryForSeatMap();
    const { isLoading: isAdditionalServicesLoading, loadAndDispatch: loadAndDispatchAdditionalServices } = useAdditionalQueryForServices({
        onCompleted: () => {
            loadAndDispatchSeatMap();
        }
    });
    const buttons = [
        {
            text: t(allSeatsConfirmed ? 'More details' : 'Change'),
            action: toggleSeatsModal,
            buttonType: 'change'
        }
    ];
    const { CheckoutAdditionalService: CustomCheckoutAdditionalService } = useRenderers();
    const ServiceWrapper = (_a = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _a !== void 0 ? _a : CheckoutAdditionalService;
    const isDV = useIataCode() === 'DV';
    const isServicesLoading = isAdditionalServicesLoading || isSeatMapLoading;
    return (React.createElement(React.Fragment, null,
        React.createElement(SeatSummary, { travellers: order.travellers, travellersById: getTravellersMap(order.travellers), segmentsById: getSegmentsById(order.flight.segments), onServiceDelete: onDeleteItem }, content => (React.createElement(ServiceWrapper, { showFromZeroIfZeroPrice: isDV, className: css.seats, showAddButton: !isAllSegmentsAvailableOnCheckin, icon: icons.seatIcon, addClick: showWarning ? toggleWarningIfOrderConfirmed : handleClick, header: t('Seats'), description: isAllSegmentsAvailableOnCheckin
                ? isCheckinAvailable
                    ? t('Seat selection is available in the online registration service')
                    : t('You can select seats using the online registration service after issuing tickets')
                : t('The top ones get the top seats!'), priceTotal: seatsIssuedPrice.amount ? seatsIssuedPrice : null, buttons: buttons, priceFrom: !isAllSegmentsAvailableOnCheckin ? minPrice : null, isSelected: !!content }, content))),
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        isServicesLoading && React.createElement(SimpleLoader, null),
        React.createElement(ServicePage, null, childrenProps => (React.createElement(ServiceCardDialog, Object.assign({ order: servicePageProps.order, openedModal: isServicesLoading ? null : servicePageProps.openedModal, onClose: servicePageProps.toggleModal.bind(null, null), passengers: servicePageProps.passengers, passengerLabels: servicePageProps.passengerLabels }, childrenProps))))));
};
export default SeatsAdditionalService;
