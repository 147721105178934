import { __awaiter } from "tslib";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useAddTravellersDataMutation, useSaveOrderServicesMutation } from '@websky/graphql';
import { useToggleable } from '../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setPassengerValues } from '../Checkout/store/passengers/actions';
import { getIsPriceFreezeProductSelected, getIsTravellersDataFilled, getOrder } from '../Checkout/store/order/selectors';
import { getPassengerState } from '../Checkout/store/passengers/selectors';
import { isFareLockService } from './utils';
export const useFareLock = (order, onOrderLoaded, setLoading) => {
    var _a, _b, _c;
    const { isOpen: isSelected, toggle, open, close } = useToggleable(false);
    const fareLockService = useMemo(() => { var _a; return (_a = order === null || order === void 0 ? void 0 : order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services.find(isFareLockService); }, [
        order
    ]);
    const [serviceRequest, { loading }] = useSaveOrderServicesMutation();
    useEffect(() => {
        if (setLoading) {
            setLoading(loading);
        }
    }, [loading]);
    const handleChangeFareLock = useCallback(() => {
        const travellerServices = [];
        toggle();
        if (loading) {
            return;
        }
        order.travellers.forEach(traveller => {
            const gdsServices = {};
            const allowedPassengers = Array.prototype.concat.apply([], fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.allowedPassengers);
            if (!allowedPassengers.includes(traveller.id)) {
                return;
            }
            if (!isSelected) {
                gdsServices.addServices = [
                    {
                        count: 1,
                        segmentIds: Array.prototype.concat.apply([], fareLockService.allowedSegments),
                        serviceId: fareLockService.id
                    }
                ];
            }
            else {
                gdsServices.setServices = [
                    {
                        count: 0,
                        segmentIds: Array.prototype.concat.apply([], fareLockService.allowedSegments),
                        serviceId: fareLockService.id
                    }
                ];
            }
            travellerServices.push({
                travellerId: traveller.id,
                gdsServices
            });
        });
        serviceRequest({
            variables: {
                params: {
                    id: order.id,
                    services: travellerServices
                }
            }
        }).then(data => {
            var _a;
            if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.SaveOrderServices) {
                onOrderLoaded(data.data.SaveOrderServices);
            }
        });
    }, [isSelected, order, fareLockService, serviceRequest, loading]);
    const isAddedToOrder = (_a = order === null || order === void 0 ? void 0 : order.travellers) === null || _a === void 0 ? void 0 : _a.some(traveller => { var _a, _b, _c; return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => (service === null || service === void 0 ? void 0 : service.serviceId) === (fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.id)); });
    useEffect(() => {
        if (isAddedToOrder) {
            open();
        }
        else {
            close();
        }
    }, [order]);
    const isConfirmed = (_b = order === null || order === void 0 ? void 0 : order.travellers) === null || _b === void 0 ? void 0 : _b.some(traveller => {
        var _a, _b, _c;
        return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => (service === null || service === void 0 ? void 0 : service.serviceId) === (fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.id) && (service === null || service === void 0 ? void 0 : service.confirmedCount));
    });
    return {
        handleChangeFareLock,
        isSelected,
        isConfirmed,
        toggle,
        loading,
        isAddedToOrder,
        fareLockService,
        price: {
            amount: ((_c = fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.price) === null || _c === void 0 ? void 0 : _c.amount) *
                (fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.allowedPassengers.length) *
                Array.prototype.concat.apply([], fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.allowedSegments).length,
            currency: fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.price.currency
        }
    };
};
export const useUpdateDocumentsFreezeView = () => {
    const [state, setState] = useState('review');
    const toAddDocuments = () => setState('update');
    const toReview = () => setState('review');
    return {
        mode: state,
        toAddDocuments,
        toReview
    };
};
export const useFareLockBookingPage = () => { };
/**
 *
 * get formState and call SetTravellersData mutation with new values
 */
export const useUpdateTravellers = () => {
    const { values, valid } = useFormState();
    const isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    const passengers = useSelector(getPassengerState);
    const isPriceFreezeSelected = useSelector(getIsPriceFreezeProductSelected);
    const dispatch = useDispatch();
    const order = useSelector(getOrder);
    useEffect(() => {
        if (!valid) {
            return;
        }
        order.travellers.forEach((traveller, index) => {
            const passengerValues = traveller.values.map(value => {
                return {
                    name: value.name,
                    type: value.type,
                    value: values['passengers'][index][value.name]
                };
            });
            dispatch(setPassengerValues(index, passengerValues));
        });
    }, [values, valid, order.travellers]);
    const [setTravellersData, { loading, data }] = useAddTravellersDataMutation();
    const handleSubmit = useCallback((passengersValues) => __awaiter(void 0, void 0, void 0, function* () {
        if (!isPriceFreezeSelected || isTravellersDataFilled) {
            return null;
        }
        yield setTravellersData({
            variables: {
                variables: {
                    orderId: order.id,
                    travellersData: passengersValues
                }
            }
        });
    }), [passengers, isPriceFreezeSelected, isTravellersDataFilled]);
    return {
        handleSubmit,
        loading,
        data,
        valid
    };
};
