import memes from '../../../../memes';
const getSeatsNumbers = (selectedSeatMap) => selectedSeatMap.map(({ seat }) => (seat ? seat.number : ''));
export const isNextSeatMapEqualsToPrev = (prevSeatMap, nextSeatMap) => {
    const prevSeats = getSeatsNumbers(prevSeatMap);
    const nextSeats = getSeatsNumbers(nextSeatMap);
    if (prevSeats.length !== nextSeats.length) {
        return false;
    }
    // this approach will be better than reduce
    for (const [index, number] of Object.entries(prevSeats)) {
        if (nextSeats[index] !== number) {
            return false;
        }
    }
    return true;
};
export const isSeatOccupied = memes((seat, passenger, segmentId) => passenger.seats &&
    passenger.seats.some(selectedSeat => selectedSeat.segmentId === segmentId && `${selectedSeat.row}${selectedSeat.letter}` === seat.number));
export const isOccupiedByOtherPassenger = memes((seat, passengers, passengerId, segmentId) => {
    const otherPassengers = passengers.filter(passenger => passenger.id !== passengerId);
    return otherPassengers
        .map(passenger => passenger.seats)
        .filter(Boolean)
        .some(otherPassengerSeat => otherPassengerSeat.some(otherSeat => otherSeat.segmentId === segmentId && `${otherSeat.row}${otherSeat.letter}` === seat.number));
});
export const findPassengerWithThisSeat = memes((seat, passengers, segmentId) => passengers.find(passenger => passenger.seats &&
    passenger.seats
        .filter(seat => seat.segmentId === segmentId)
        .some(selectedSeat => `${selectedSeat.row}${selectedSeat.letter}` === seat.number)));
