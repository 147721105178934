import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import SvgIcon from '@material-ui/core/SvgIcon';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { airportInfoIcon, weatherIcons } from '../icons';
import { getTemperaturePrefix } from '../utils';
import { TemperatureUnits } from '@websky/graphql';
const Weather = React.memo(({ airport, weatherInfo, className }) => {
    var _a, _b, _c;
    const { Weather: theme } = useTheme('Weather');
    const cityImage = (_c = (_b = (_a = airport === null || airport === void 0 ? void 0 : airport.city) === null || _a === void 0 ? void 0 : _a.images) === null || _b === void 0 ? void 0 : _b.panorama) === null || _c === void 0 ? void 0 : _c.url;
    const weatherIcon = (weatherInfo === null || weatherInfo === void 0 ? void 0 : weatherInfo.type) && weatherIcons[weatherInfo.type];
    const hasImage = !!cityImage;
    return (React.createElement(Card, { classes: { root: cn(theme.card, className, { [theme.card_withoutPanorama]: !hasImage }) } },
        React.createElement(CardActionArea, { classes: { focusHighlight: theme.card__focusHighlight } },
            hasImage && (React.createElement(CardMedia, { classes: { root: theme.card__panorama }, image: cityImage, title: airport.name })),
            React.createElement("div", { className: theme.card__content },
                weatherInfo && (React.createElement(Card, { classes: { root: theme.card__weather } },
                    weatherIcon && (React.createElement(SvgIcon, { classes: { root: theme.card__weatherIcon } }, weatherIcon)),
                    React.createElement("div", { className: theme.card__temperature },
                        getTemperaturePrefix(weatherInfo.temperature),
                        Math.abs(Math.round(weatherInfo.temperature)),
                        "\u00B0",
                        weatherInfo.measurement === TemperatureUnits.Fahrenheit && 'F'))),
                React.createElement("div", { className: cn(theme.card__airportName, { [theme.card__airportName_withIATA]: !hasImage }) },
                    !hasImage && React.createElement("div", { className: theme.card__airportIATA }, airport.iata),
                    React.createElement("span", { className: theme.card__airportLink },
                        airport.name,
                        React.createElement("span", { className: theme.card__chevronIcon }, airportInfoIcon)))))));
}, (oldProps, newProps) => {
    return oldProps.weatherInfo && newProps.weatherInfo && oldProps.weatherInfo.type === newProps.weatherInfo.type;
});
export default Weather;
