import { __awaiter } from "tslib";
import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useRequestFfpMilesForOrderMutation } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { initI18n } from '../../../utils';
import Button from '../../../Button';
import { format, isAfter, subMonths } from 'date-fns';
import Input from '../../../Input';
import { isTicketNumber } from '../../../RetrieveBooking/hooks';
import { Datepicker } from '../../../Datepicker';
import { ThemeProvider } from '../../../theme';
initI18n('MilesRecovery');
const MilesRecoveryContent = ({ className, onClose, datepickerSettings = { minDate: null, maxDate: null } }) => {
    const { MilesRecoveryContentStyles: theme } = useTheme('MilesRecovery');
    const { t } = useTranslation('MilesRecovery');
    const [requestFfpMiles] = useRequestFfpMilesForOrderMutation();
    const [success, setSuccessMessage] = React.useState('');
    const [error, setError] = React.useState('');
    const currentDate = new Date();
    const conditions = ['OnlyCompletedFlights', 'MoreThanSixMonthsFlights'];
    const validate = (values) => {
        const errors = {};
        if (!values.ticketNumber) {
            errors.ticketNumber = t('Required');
        }
        else if (!isTicketNumber(values.ticketNumber)) {
            errors.ticketNumber = t('Enter your ticket number');
        }
        if (!values.flightNumber) {
            errors.flightNumber = t('Required');
        }
        if (!values.flightDate) {
            errors.flightDate = t('Pick a flight date');
        }
        else if (isAfter(values.flightDate, currentDate)) {
            errors.flightDate = t('Invalid date');
        }
        return errors;
    };
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            const request = yield requestFfpMiles({
                variables: {
                    flightNumber: values.flightNumber,
                    flightDate: format(values.flightDate, 'dd.MM.yyyy'),
                    ticketNumber: values.ticketNumber
                }
            });
            if ((_b = (_a = request === null || request === void 0 ? void 0 : request.data) === null || _a === void 0 ? void 0 : _a.RequestFfpMilesForOrder) === null || _b === void 0 ? void 0 : _b.result) {
                setSuccessMessage(request.data.RequestFfpMilesForOrder.message);
                setTimeout(onClose, 1500);
            }
            else {
                setError(t('Order not found'));
            }
        }
        catch (_c) {
            setError(t('Order not found'));
        }
    });
    const datePickerStyles = React.useMemo(() => ({
        Datepicker: {
            MonthsWrapperStyles: {
                wrapper: theme.month_wrapper,
                monthHeader: theme.monthHeader,
                nav_prev: theme.month_nav_prev,
                nav_next: theme.month_nav_next
            },
            MonthHeaderStyles: {
                monthHeader__select: theme.monthHeader__select
            },
            WeeklyHeaderStyles: {
                day: theme.weeklyHeader_dayOfWeekName
            },
            DayStyles: {
                day: theme.datePicker_day,
                day_notAvailable: theme.datePicker_day_notAvailable,
                day_selected: theme.datePicker_day_selected
            }
        }
    }), []);
    const textFieldPropsFactory = (field) => (Object.assign(Object.assign({}, field.input), { onChange: field.input.onChange, error: field.meta.submitFailed && !!field.meta.error, helperText: field.meta.submitFailed && field.meta.error }));
    const datepickerProps = React.useMemo(() => {
        var _a, _b;
        return ({
            minDate: (_a = datepickerSettings.minDate) !== null && _a !== void 0 ? _a : subMonths(currentDate, 6),
            maxDate: (_b = datepickerSettings.maxDate) !== null && _b !== void 0 ? _b : currentDate
        });
    }, [datepickerSettings]);
    return (React.createElement("div", { className: cn(theme.wrapper, className) },
        React.createElement("div", { className: theme.header }, t('Miles recovery')),
        success && React.createElement("div", { className: theme.success }, success),
        !success && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.about }, t('Please note that miles recovery is possible')), conditions === null || conditions === void 0 ? void 0 :
            conditions.map((condition, index) => (React.createElement("li", { className: theme.about__item, key: index }, t(condition)))),
            React.createElement(Form, { onSubmit: onSubmit, validate: validate, render: ({ handleSubmit, submitting, errors, submitFailed }) => {
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement("div", { className: theme.form },
                            React.createElement(ThemeProvider, { value: datePickerStyles },
                                React.createElement(Field, { type: "text", name: "flightDate", render: field => (React.createElement(Datepicker, Object.assign({}, datepickerProps, { openDate: currentDate, selectedDates: [field.input.value], onSelect: (date) => field.input.onChange(date) }))) })),
                            React.createElement("div", { className: theme.fields },
                                React.createElement(Field, { type: "text", name: "ticketNumber", render: field => (React.createElement(Input, Object.assign({}, field.input, { name: field.input.name, label: t('Ticket number'), absoluteHintPosition: true, TextFieldProps: Object.assign(Object.assign({}, textFieldPropsFactory(field)), { onChange: event => {
                                                setError(null);
                                                field.input.onChange(event.target.value);
                                            }, error: !!error ||
                                                !!(field.meta.submitFailed && field.meta.error), helperText: error || (field.meta.submitFailed && field.meta.error) }) }))) }),
                                React.createElement(Field, { type: "number", name: "flightNumber", absoluteHintPosition: true, render: field => (React.createElement(Input, Object.assign({}, field.input, { inputClassName: theme.input_number, name: field.input.name, label: t('Flight number'), TextFieldProps: Object.assign({}, textFieldPropsFactory(field)) }))) }),
                                React.createElement("div", { className: theme.error }, submitFailed && errors.flightDate),
                                React.createElement("div", { className: theme.buttons },
                                    React.createElement(Button, { className: theme.buttonSubmit, isLoading: submitting, type: "submit" }, t('Create')))))));
                } })))));
};
export default MilesRecoveryContent;
