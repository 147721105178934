import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { memo, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Tabs from '../../../Tabs/Tabs';
import Warnings from '../../../Warnings';
import { Link } from '../../../index';
import { useConfig } from '../../../context';
import OrderCard from '../../../OrderCard';
import WxPagination from '../../../WxPagination';
import { usePagination } from '../../../WxPagination/utils';
import { useTheme } from '../../../theme';
import ProfileControls from '../../../ProfileControls';
import { OrderStatus, SortOrder, SortAttribute, useGetOrdersListPaginatedQuery, OrderFlownStatus } from '@websky/graphql';
import SimpleLoader from '../../../SimpleLoader';
import { TABLET_MIN_WIDTH, redirectToOrder } from '../../../utils';
const ORDERS_PER_PAGE = 4;
const Orders = memo(() => {
    var _a, _b, _c, _d, _e;
    const { Account: css } = useTheme('AccountModule');
    const { t } = useTranslation('Account');
    const { Account: { engineURL }, global: { companyInfo } } = useConfig();
    const isTablet = useMediaQuery({ maxWidth: TABLET_MIN_WIDTH });
    const tabs = [
        {
            header: t('Next fligths'),
            value: 0,
            orderFlownStatus: OrderFlownStatus.Active
        },
        {
            header: t('Archive'),
            value: 1,
            orderFlownStatus: OrderFlownStatus.Flown
        }
    ];
    const [totalOrdersCount, setTotalOrdersCount] = useState(ORDERS_PER_PAGE);
    const [activeTab, setActiveTab] = React.useState(tabs[0]);
    const { pagesCount, paginationAvailable, handleChangePage, page } = usePagination(totalOrdersCount, ORDERS_PER_PAGE);
    const { data: ordersData, loading: ordersLoading, refetch: refetchOrders } = useGetOrdersListPaginatedQuery({
        variables: {
            pagination: {
                pageSize: ORDERS_PER_PAGE,
                pageNum: page
            },
            selectionParams: {
                sortParameters: {
                    sortOrder: SortOrder.DESC,
                    byAttribute: SortAttribute.Id
                },
                filterParameters: {
                    bookingStatus: [OrderStatus.Booked, OrderStatus.Confirmed, OrderStatus.Cancelled],
                    orderFlownStatus: activeTab.orderFlownStatus
                }
            }
        },
        fetchPolicy: 'no-cache'
    });
    const onTabChange = (value) => {
        handleChangePage(1);
        setActiveTab(() => tabs.find(tab => tab.value === value));
    };
    useEffect(() => {
        var _a, _b, _c, _d;
        if ((_b = (_a = ordersData === null || ordersData === void 0 ? void 0 : ordersData.AviaOrdersListPaginated) === null || _a === void 0 ? void 0 : _a.pageInfo) === null || _b === void 0 ? void 0 : _b.totalPages) {
            const totalPages = (_d = (_c = ordersData === null || ordersData === void 0 ? void 0 : ordersData.AviaOrdersListPaginated) === null || _c === void 0 ? void 0 : _c.pageInfo) === null || _d === void 0 ? void 0 : _d.totalPages;
            const totalOrdersCount = totalPages ? totalPages * ORDERS_PER_PAGE : null;
            setTotalOrdersCount(totalOrdersCount);
        }
    }, [(_b = (_a = ordersData === null || ordersData === void 0 ? void 0 : ordersData.AviaOrdersListPaginated) === null || _a === void 0 ? void 0 : _a.pageInfo) === null || _b === void 0 ? void 0 : _b.totalPages]);
    const onOrderClickHandler = (orderId) => {
        redirectToOrder(engineURL, orderId);
    };
    const orders = (_e = (_d = (_c = ordersData === null || ordersData === void 0 ? void 0 : ordersData.AviaOrdersListPaginated) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.map(edge => edge.node)) !== null && _e !== void 0 ? _e : [];
    const noFlights = !orders.length && !ordersLoading;
    const isActiveFlights = activeTab.orderFlownStatus === OrderFlownStatus.Active;
    const isFlownFlights = activeTab.orderFlownStatus === OrderFlownStatus.Flown;
    return (React.createElement("div", { className: css.orders__wrapper },
        ordersLoading && React.createElement(SimpleLoader, null),
        companyInfo.webskySearchOrder && (React.createElement(Warnings, { className: css.warning },
            React.createElement("span", null,
                t('Orders that were booked on a previous version of the site are available'),
                "\u00A0",
                React.createElement(Link, { className: css.link, action: `${companyInfo.webskySearchOrder}`, target: '_blank' }, t('this link'))))),
        React.createElement("div", { className: css.orders__header },
            React.createElement("h1", { className: cn(css.route__title, css.orders__title) }, t('Orders')),
            React.createElement(Tabs, { tabs: tabs, onChange: onTabChange, selected: activeTab.value, className: css.tabs, flexContainerClassName: css.tabs__flexContainer, tabClassName: css.tab, tabHeaderClassName: css.tabLabel, selectedTabClassName: css.tabSelected, indicatorClassName: css.tabIndicator, rootTabsClassName: css.tabs__container, variant: isTablet ? 'fullWidth' : 'standard', tabsAlign: isTablet ? 'center' : 'left' })),
        noFlights && isActiveFlights && (React.createElement("div", { className: css.noFlightsTitle }, t(`You don't have any upcoming flights`))),
        noFlights && isFlownFlights && React.createElement("div", { className: css.noFlightsTitle }, t(`You have no flights flown`)),
        orders.map(order => {
            return (React.createElement(OrderCard, { key: order.id, order: order, isCheckinOpen: false, type: 'account', onClick: onOrderClickHandler, onDeleted: refetchOrders }));
        }),
        React.createElement(ProfileControls, { className: css.profileControls, onRefetchOrders: refetchOrders }),
        !!paginationAvailable && (React.createElement(WxPagination, { className: css.pagination, page: page, pages: pagesCount, onHandleChange: handleChangePage }))));
});
export default Orders;
