import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { BaseButton } from '../../BaseComponents';
import Modal from '../../Modal';
import AddBooking, { FormType } from '../../AddBooking';
import { reachGoal, UserGoal } from '../../analytics';
import { useConfig } from '../../context';
import { getCheckInUrl, MOBILE_MIN_WIDTH } from '../../utils';
import { SlideBottom } from '../../index';
import { useCheckinInfoQuery, useFindOrderMutation } from '@websky/graphql';
const ProfileControls = ({ className, onRefetchOrders }) => {
    const { ProfileControls: css } = useTheme('ProfileControls');
    const { t } = useTranslation('Account');
    const { Account: { engineURL, openCheckinPageOnProfileControlsClick }, Engine: { checkInURL }, global: { companyInfo: { iataCode }, reserveEngines } } = useConfig();
    const [order, setOrder] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [addBookingModalType, setAddBookingModalType] = useState(null);
    const onSetAddBookingModalType = useCallback((type) => () => {
        if (type === FormType.Checkin && openCheckinPageOnProfileControlsClick) {
            window.open(checkInURL);
            return;
        }
        setAddBookingModalType(type);
    }, [setAddBookingModalType, openCheckinPageOnProfileControlsClick, checkInURL]);
    const onClearAddBookingModalType = useCallback(() => {
        if (addBookingModalType === FormType.Booking) {
            setOrder(null);
        }
        setAddBookingModalType(null);
    }, [addBookingModalType, setAddBookingModalType]);
    const [unlockOrder] = useFindOrderMutation();
    const { refetch } = useCheckinInfoQuery({ skip: true });
    const onBookingSubmit = useCallback(({ lastName, ticket, saveInProfile = false }) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const { data, errors } = yield unlockOrder({
                variables: {
                    params: {
                        id: ticket,
                        secret: lastName,
                        saveInProfile
                    }
                }
            });
            const result = !errors && data && data.FindOrder;
            if (result) {
                onRefetchOrders === null || onRefetchOrders === void 0 ? void 0 : onRefetchOrders();
                reachGoal(UserGoal.BindOrder);
                setOrder(result);
                setIsLoading(false);
                return true;
            }
            else {
                setIsLoading(false);
                setOrder(null);
                return { ticket: t('RetrieveBooking:Order not found') };
            }
        }
        catch (e) {
            return {
                ticket: t('An error occurred while executing the request. Please try again later or contact a customer support service.')
            };
        }
        finally {
            setIsLoading(false);
        }
    }), []);
    const onCheckinSubmit = useCallback(({ lastName, ticket }) => __awaiter(void 0, void 0, void 0, function* () {
        if ((reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.redirectToWebsky1) && (reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin)) {
            window.open(getCheckInUrl(checkInURL, '', ticket, lastName, reserveEngines));
            return true;
        }
        else {
            setIsLoading(true);
            try {
                const { data, errors } = yield refetch({
                    params: {
                        id: ticket,
                        secret: lastName
                    }
                });
                const result = !errors && data && data.CheckinInfo;
                if (result.isAvailable) {
                    onClearAddBookingModalType();
                    window.open(getCheckInUrl(checkInURL, '', ticket, lastName));
                    return true;
                }
                else {
                    return { ticket: t('RetrieveBooking:Order not found') };
                }
            }
            catch (e) {
                return {
                    ticket: t('An error occurred while executing the request. Please try again later or contact a customer support service.')
                };
            }
            finally {
                setIsLoading(false);
            }
        }
    }), []);
    const openSearchFlights = () => window.open(engineURL);
    const onClearOrder = () => {
        setOrder(null);
    };
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: css.img, style: {
                backgroundImage: `url(https://cdn.websky.aero/content/frontend/images/aircraftImages/${iataCode.toUpperCase()}/default.png)`
            } }),
        React.createElement("div", { className: css.actions },
            React.createElement(BaseButton, { variant: "smallNeitral", onClick: openSearchFlights }, t('Search flights')),
            React.createElement(BaseButton, { variant: "smallNeitral", onClick: onSetAddBookingModalType(FormType.Booking) }, t('I have a booking')),
            React.createElement(BaseButton, { variant: "smallNeitral", onClick: onSetAddBookingModalType(FormType.Checkin) }, t('Check-in'))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { classes: {
                    paper: css.paper,
                    paperWidthLg: css.paper_width_lg,
                    paperScrollBody: css.paper_scroll_body
                }, open: !!addBookingModalType },
                React.createElement(AddBooking, { order: order, isLoading: isLoading, type: addBookingModalType, onClose: onClearAddBookingModalType, onSubmit: addBookingModalType === FormType.Booking ? onBookingSubmit : onCheckinSubmit, onClearOrder: onClearOrder }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: !!addBookingModalType, onClose: onClearAddBookingModalType },
                React.createElement(AddBooking, { order: order, isLoading: isLoading, type: addBookingModalType, onClose: onClearAddBookingModalType, onSubmit: addBookingModalType === FormType.Booking ? onBookingSubmit : onCheckinSubmit, onClearOrder: onClearOrder })))));
};
export default ProfileControls;
