import React from 'react';
import cn from 'classnames';
import { CloseClear } from '../../../Icons';
import { useTheme } from '../../../theme';
const Close = ({ className, onClick }) => {
    const { Close: theme } = useTheme('CheckoutAdditionalService');
    return (React.createElement("div", { className: cn(theme.close, className), onClick: onClick }, CloseClear));
};
export default Close;
export const closeRender = (onClick) => {
    return React.createElement(Close, { onClick: onClick });
};
