import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { useTheme } from '../../../theme';
const Preferences = props => {
    const css = useTheme('TravellerForm').Preferences, [seatSelect, setSeatSelect] = useState(props.Seats && props.Seats[0]), [mealSelect, setMealSelect] = useState(props.Meal && props.Meal[0]), { t } = useTranslation('Traveller');
    return (React.createElement("div", { className: css.preferences },
        React.createElement("h1", { className: css.title }, t('FLIGHT PREFERENCES')),
        React.createElement("div", { className: css.selects },
            React.createElement(Field, { name: 'preferences.seats', defaultValue: seatSelect, render: ({ input }) => (React.createElement(FormControl, null,
                    React.createElement(InputLabel, { classes: {
                            root: css.label,
                            focused: css.focused
                        } }, t('Seats')),
                    React.createElement(Select, Object.assign({}, input, { value: seatSelect, onChange: (event) => {
                            if (typeof event.target.value === 'string') {
                                setSeatSelect(event.target.value);
                                input.onChange(event);
                            }
                        } }), props.Seats &&
                        props.Seats.map(seat => (React.createElement(MenuItem, { key: seat, value: seat }, seat)))))) }),
            React.createElement(Field, { name: 'preferences.meal', defaultValue: mealSelect, render: ({ input }) => (React.createElement(FormControl, null,
                    React.createElement(InputLabel, { classes: {
                            root: css.label,
                            focused: css.focused
                        } }, t('Meal')),
                    React.createElement(Select, { value: mealSelect, onChange: (event) => {
                            if (typeof event.target.value === 'string') {
                                setMealSelect(event.target.value);
                                input.onChange(event);
                            }
                        } }, props.Meal &&
                        props.Meal.map(meal => (React.createElement(MenuItem, { key: meal, value: meal }, meal)))))) }))));
};
export default Preferences;
