import { useSelector } from 'react-redux';
import { getIsTravellerDataFilled } from '../../../../../store/order/selectors';
import { useToggleable } from '../../../../../../../hooks';
import { getIsCheckinTravellersDataFilledFromLocalStorage, setIsCheckinTravellersDataFilledToLocalStorage } from '../../../../../../../cache';
export const useCheckinAddDocumentsService = (orderId) => {
    const isTravellersDataFilled = useSelector(getIsTravellerDataFilled);
    const { isOpen, setOpen } = useToggleable(false);
    const onDocumentsUpdated = () => {
        setOpen(false);
        setIsCheckinTravellersDataFilledToLocalStorage(orderId, true);
    };
    let isNeedToOpen = false;
    if (!!getIsCheckinTravellersDataFilledFromLocalStorage(orderId)) {
        isNeedToOpen = false;
    }
    else {
        isNeedToOpen = !isTravellersDataFilled;
    }
    return {
        setDataFilled: setIsCheckinTravellersDataFilledToLocalStorage,
        onDocumentsUpdated,
        isNeedToOpen,
        isOpen,
        setOpen,
        isTravellersDataFilled
    };
};
