import * as React from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import Contacts from './Contacts/Contacts';
import Passengers from './Passengers/Passengers';
import PassengersToolbar from './PassengersToolbar/PassengersToolbar';
import scrollToFirstErrorDecorator from './scrollToFirstErrorDecorator';
import { scrollTo } from '../../../../utils';
import { getIsPriceFreezeProductSelected } from '../../../store/order/selectors';
import { useSelector } from 'react-redux';
import Login from './Login/Login';
import { usePassengerForm } from '../../../../PassengerForm/hooks';
import FormStateWrapper from './FormStateWrapper/FormStateWrapper';
import { useToggleable } from '../../../../hooks';
import CheckDataModal from './CheckDataModal/CheckDataModal';
export const Form = memo(({ passengerClassName, currentStep, passengers, customer, onFormSubmit, isInternationalFlight, showForm = true, showContacts = true, showServices = true, showToolbar = true, userInfo, refetchUser, inModal = false, isChangePassengers, allowSwapNames, orderSubsidyState, backToResults, setNextStep, passengersFormFirst, showCheckDataModal, renderToolbar, isChangeFiles, onToggleChangeFiles }) => {
    const formRef = useRef();
    const isFreezeOrder = useSelector(getIsPriceFreezeProductSelected);
    React.useEffect(() => {
        scrollTo(0);
    }, []);
    const { state: initialValues, selectedProfileIdsByPassenger, onFillValues, onSwapNames } = usePassengerForm(passengers, customer, isInternationalFlight, isFreezeOrder);
    const { isOpen, open, close } = useToggleable(false);
    const [values, setValues] = useState(null);
    const onConfirm = useCallback(() => {
        onFormSubmit(values);
        close();
    }, [onFormSubmit, values]);
    const onFormSubmitModal = useCallback((values) => {
        setValues(values);
        open();
    }, [open, setValues, values]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FinalForm, { onSubmit: showCheckDataModal ? onFormSubmitModal : onFormSubmit, decorators: [scrollToFirstErrorDecorator], validateOnBlur: true, initialValues: initialValues, keepDirtyOnReinitialize: true, subscription: { submitErrors: true } }, ({ handleSubmit }) => (React.createElement(Login, { refetchUser: refetchUser, userInfo: userInfo }, ({ userInfo, refetchUser, openLogin, isLoginFormOpen }) => (React.createElement("form", { ref: formRef, onSubmit: handleSubmit, noValidate: true },
            showContacts && (React.createElement(Contacts, { userInfo: userInfo, refetchUser: refetchUser, submitForm: handleSubmit, customer: customer, onLoginOpen: openLogin })),
            showForm && (React.createElement(Passengers, { className: passengerClassName, isAuthorized: !!(userInfo === null || userInfo === void 0 ? void 0 : userInfo.userProfile), isChangePassengers: isChangePassengers, onFill: onFillValues, fields: passengers, orderSubsidies: orderSubsidyState, backToResults: backToResults, selectedProfileIdsByPassenger: selectedProfileIdsByPassenger, showLoginForm: () => openLogin(''), userInfo: userInfo, refetchUser: refetchUser, isLoginFormOpen: isLoginFormOpen, isChangeFiles: isChangeFiles, onSwap: allowSwapNames ? onSwapNames : null, onToggleChangeFiles: onToggleChangeFiles })),
            showToolbar && (React.createElement(React.Fragment, null, renderToolbar ? (React.createElement(FormStateWrapper, { formRef: formRef }, renderToolbar)) : (React.createElement(PassengersToolbar, { disableBooking: orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.isSubsidiesRejected, formRef: formRef, currentStep: currentStep, loading: orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.isLoading, setNextStep: setNextStep }))))))))),
        showCheckDataModal && (React.createElement(CheckDataModal, { isOpen: isOpen, onClose: close, onConfirm: onConfirm, onCancel: close }))));
});
