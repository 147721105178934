import { handleAction } from 'redux-actions';
import { SET_PRICE } from './action';
import { Currency } from '../../../enums';
export const initialPriceBreakdownState = {
    totalPrice: {
        amount: 0,
        currency: Currency.USD
    },
    servicePrice: {
        amount: 0,
        currency: Currency.USD
    },
    upsalePrice: {
        amount: 0,
        currency: Currency.USD
    }
};
export const priceBreakdownReducer = handleAction(SET_PRICE, (state, action) => {
    if (action.payload) {
        return Object.assign(Object.assign({}, state), action.payload);
    }
    return state;
}, initialPriceBreakdownState);
