import * as React from 'react';
import onClickOutside from 'react-onclickoutside';
class Dropdown extends React.Component {
    handleClickOutside(e) {
        if (e.offsetX <= document.body.clientWidth) {
            this.props.onClose(e);
        }
    }
    render() {
        // Do not remove dropdown content from the DOM.
        // Necessary in case of nested dropdowns (autocomplete dropdown + directions popup);
        if (this.props.keepMounted) {
            const styles = {};
            if (!this.props.isOpen) {
                styles.display = 'none';
            }
            return React.createElement("div", { style: styles }, this.props.children);
        }
        return this.props.isOpen ? this.props.children : null;
    }
}
Dropdown.defaultProps = {
    keepMounted: false
};
export default onClickOutside(Dropdown);
