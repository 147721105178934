import WebskyServicesAnalytics from '../WebskyServicesAnalytics';
import Base from './Base';
class Aeroexpress extends Base {
    constructor(orderBeforeChange, orderAfterChange) {
        super(orderBeforeChange, orderAfterChange);
    }
    diff() {
        var _a, _b, _c, _d;
        const added = [];
        const removed = [];
        const { added: aeroexpressAdded, removed: aeroexpressRemoved } = this.getTravellersServicesDiff((_b = (_a = this.orderBeforeChange.additionalServices.transfer) === null || _a === void 0 ? void 0 : _a.aeroexpress) === null || _b === void 0 ? void 0 : _b.selectedTrips, (_d = (_c = this.orderAfterChange.additionalServices.transfer) === null || _c === void 0 ? void 0 : _c.aeroexpress) === null || _d === void 0 ? void 0 : _d.selectedTrips, service => service.id);
        added.push(...WebskyServicesAnalytics.aeroexpress(aeroexpressAdded, this.orderAfterChange.travellers));
        removed.push(...WebskyServicesAnalytics.aeroexpress(aeroexpressRemoved, this.orderAfterChange.travellers));
        return { added, removed };
    }
}
export default Aeroexpress;
