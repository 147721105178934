import * as React from 'react';
import CommonSummary from './CommonSummary';
import { Transfer } from '../../Icons';
import { getActualProduct } from '../utils';
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { CartService } from '../../Cart/types';
const TransferSummary = props => {
    const onDeleteHandler = (travellerId, serviceId, segmentId, allSegments) => {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Extra, {
            type: OrderAdditionalServiceGdsServiceServiceType.Vip,
            passengerId: travellerId,
            segmentId,
            serviceId,
            allSegments
        });
    };
    return (React.createElement(CommonSummary, { groupType: OrderAdditionalServiceGdsServiceServiceType.Vip, serviceFilterPredicate: service => service.subType ===
            GdsServiceVipSubType.GroundTransportation, flight: props.flight, travellers: props.travellers, services: props.services, onServiceDelete: console.log, travellersById: props.travellersById, serviceOptionGroupFactory: ({ items, travellerId }) => {
            const allSegmentsItems = items.filter(item => item.segment === 'allSegments');
            const segmentsItems = items.filter(item => item.segment !== 'allSegments');
            const bySegmentMap = {};
            for (const segmentItem of segmentsItems) {
                if (bySegmentMap[segmentItem.segment]) {
                    bySegmentMap[segmentItem.segment].push(segmentItem);
                }
                else {
                    bySegmentMap[segmentItem.segment] = [segmentItem];
                }
            }
            const groups = [];
            if (allSegmentsItems.length) {
                groups.push({
                    traveller: props.travellersById.get(travellerId),
                    allSegments: true,
                    headerType: 'travellerName',
                    items: allSegmentsItems
                });
            }
            if (segmentsItems.length) {
                for (const [segmentId, value] of Object.entries(bySegmentMap)) {
                    groups.push({
                        traveller: props.travellersById.get(travellerId),
                        direction: props.flight.segments.find(segment => segment.segment.id === segmentId).segment,
                        allSegments: false,
                        headerType: 'travellerName',
                        items: value
                    });
                }
            }
            return groups;
        }, serviceOptionItemFactory: ({ selectedService, key, travellerId }) => {
            const actualProduct = getActualProduct(selectedService.products);
            return {
                name: `${selectedService.service.name}`,
                segment: key,
                id: selectedService.service.id,
                included: selectedService.count === selectedService.confirmedCount,
                price: key === 'allSegments'
                    ? Object.assign(Object.assign({}, selectedService.service.price), { amount: selectedService.service.price.amount * props.flight.segments.length }) : selectedService.service.price,
                icon: Transfer,
                status: actualProduct === null || actualProduct === void 0 ? void 0 : actualProduct.status,
                onDelete: () => onDeleteHandler(travellerId, selectedService.serviceId, key, key === 'allSegments')
            };
        } }, children => props.children(children)));
};
export default TransferSummary;
