import * as React from 'react';
import Money from '../Money';
import { useTheme } from '../theme';
const ServiceSummary = ({ group, children }) => {
    var _a;
    const { ServiceSummary: css } = useTheme('ServiceSummary');
    if (React.Children.toArray(children).every(children => !children)) {
        return null;
    }
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.header },
            React.createElement("span", { className: css.header__number }),
            React.createElement("span", { className: css.header__icon }, group.icon),
            ' ',
            React.createElement("span", { className: css.header__title }, group.header),
            ((_a = group.totalPrice) === null || _a === void 0 ? void 0 : _a.amount) > 0 && (React.createElement("span", { className: css.header__price },
                React.createElement(Money, { money: group.totalPrice })))),
        React.createElement("div", { className: css.content }, children)));
};
export default ServiceSummary;
