import { OauthServiceType } from '@websky/graphql';
import { sessionStorageSet, SOCIAL_AUTH_REDIRECT_URL } from '../../cache';
export const tinkoffCallbackUrlSuffix = '/tinkoff/callback';
export const getBackRedirectUrl = (serviceType) => {
    switch (serviceType) {
        case OauthServiceType.TinkoffID:
            return `${window.location.href}${tinkoffCallbackUrlSuffix}`;
        default:
            return window.location.href;
    }
};
export const prevSocialAuthKeyFactory = (serviceType) => `${SOCIAL_AUTH_REDIRECT_URL}_${serviceType}`;
export const setSocialAuthRedirectUrl = (serviceType) => {
    sessionStorageSet(prevSocialAuthKeyFactory(serviceType), location.href);
};
