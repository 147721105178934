import { useMobileSegmentInfo } from '../../hooks';
import { usePagination } from '../../../Pagination/hooks';
import { getTransferInfo } from '../../utils';
import i18n from 'i18next';
import { initI18n } from '../../../utils';
initI18n('MobileSegmentInfo');
export const useMobileSegmentFlatInfo = componentProps => {
    const { showAirplaneInfo } = componentProps;
    let flightInfo;
    if (showAirplaneInfo) {
        flightInfo = componentProps.segments.map(segment => {
            var _a, _b, _c;
            return ({
                aircraftName: (_a = segment.segment.aircraft) === null || _a === void 0 ? void 0 : _a.name,
                flightNumber: `${segment.segment.marketingAirline.iata}-${segment.segment.flightNumber}`,
                imageUrl: (_b = segment.segment.aircraft) === null || _b === void 0 ? void 0 : _b.icon,
                id: (_c = segment.segment.aircraft) === null || _c === void 0 ? void 0 : _c.id
            });
        });
    }
    const mobileSegmentInfo = useMobileSegmentInfo(componentProps);
    const pagination = usePagination(0);
    const transferInfo = getTransferInfo(componentProps.segments);
    const transferString = transferInfo.transfersCount
        ? `${transferInfo.transfersCount} ${i18n.t('MobileSegmentInfo:stop')}: ${transferInfo.iata.join(', ')}`
        : i18n.t('MobileSegmentInfo:Nonstop');
    return Object.assign(Object.assign(Object.assign({}, mobileSegmentInfo), pagination), { aircrafts: flightInfo !== null && flightInfo !== void 0 ? flightInfo : [], transferInfo: getTransferInfo(componentProps.segments), transferString });
};
