import * as React from 'react';
import cn from 'classnames';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { BaseButton, IconButton } from '../../BaseComponents';
import Input from '../../Input';
import { emailRegex } from '../../utils';
import { ResendTicketsStages } from '../types';
const ResendTicketsForm = ({ className, hideHeader, buttonClassName, defaultEmail, status, onCancel, onSubmit }) => {
    const { ResendTicketsForm: css } = useTheme('ResendTicketsForm');
    const { t } = useTranslation('RegisteredPassenger');
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        !hideHeader && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.title }, t('Send all documents to email')))),
        React.createElement(Form, { onSubmit: onSubmit, initialValues: { email: defaultEmail }, render: ({ handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(Field, { name: 'email', validate: (value) => {
                        if (!value) {
                            return t('Required field');
                        }
                        if (!emailRegex.test(value)) {
                            return t('Please enter a valid email');
                        }
                    }, render: ({ input, meta }) => (React.createElement(Input, Object.assign({}, input, { variant: "filled", label: t('Email'), TextFieldProps: {
                            error: !!meta.error && meta.touched,
                            helperText: !!meta.error && meta.touched ? meta.error : '',
                            onChange: (event) => {
                                input.onChange(event);
                            }
                        } }))) }),
                React.createElement("div", { className: css.footer },
                    status === ResendTicketsStages.Default && onCancel && (React.createElement(BaseButton, { variant: "smallSecondary", className: css.cancel, onClick: onCancel }, t('Cancel'))),
                    status === ResendTicketsStages.Success ? (React.createElement(IconButton, { isSuccess: true })) : (React.createElement(BaseButton, { className: cn(css.submit, buttonClassName), variant: "smallBrand", type: 'submit', isLoading: status === ResendTicketsStages.Loading }, t('Send')))))) })));
};
export default ResendTicketsForm;
