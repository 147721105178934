import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import { Money } from '../../../../../Money';
import { clear } from '../../../store/actions';
const PriceBlock = ({ price, hideClear }) => {
    const dispatch = useDispatch();
    const { BaggageFooter: css } = useTheme('UpgradeBaggageDialog');
    const { t } = useTranslation('IncludedBaggage');
    const handlerClick = () => dispatch(clear());
    return (React.createElement("div", { className: css.price_block },
        React.createElement("div", { className: css.price_wrapper },
            t('Total'),
            ": ",
            React.createElement(Money, { money: price, roundType: "ceil" })),
        !hideClear && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.price_block__delimiter }),
            React.createElement("div", null,
                React.createElement("span", { className: css.clear, onClick: handlerClick }, t('Clear')))))));
};
export default PriceBlock;
