import { __rest } from "tslib";
import * as React from 'react';
import RadioMUI from '@material-ui/core/Radio';
import cn from 'classnames';
import { useTheme } from '../../theme';
const Radio = React.forwardRef((_a, ref) => {
    var { classes, disableRipple = true } = _a, props = __rest(_a, ["classes", "disableRipple"]);
    const { Radio: css } = useTheme('Radio');
    return (React.createElement(RadioMUI, Object.assign({}, props, { classes: Object.assign(Object.assign({}, classes), { root: cn(classes === null || classes === void 0 ? void 0 : classes.root, css.root, css.additional_class), checked: cn(classes === null || classes === void 0 ? void 0 : classes.checked, css.checked), disabled: cn(classes === null || classes === void 0 ? void 0 : classes.disabled, css.disabled) }), disableRipple: disableRipple, ref: ref })));
});
export default Radio;
