import { __awaiter } from "tslib";
import * as React from 'react';
import { useField, useForm } from 'react-final-form';
import { DateField } from '../../../../../../Passenger/components/Field/Date/Date';
import { Select } from '../../../../../../Passenger/components/Field/Select/Select';
import { InputType } from '../../../../../../Passenger/types';
import { validate } from '../../../../../../Passenger/utils';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../../Input';
import PhoneInput from '../../../../../../PhoneInput';
import Dropdown from '../../../../../../BaseComponents/Dropdown';
import GenderSwitch from '../GenderSwitch/GenderSwitch';
const Field = props => {
    const { t } = useTranslation('Passenger');
    const form = useForm();
    const field = useField(props.name, {
        type: 'text',
        validate: (value, allValues) => {
            return t(validate(value, allValues, props.type, props.validationRules));
        }
    });
    const isError = field.meta.error && field.meta.touched;
    const error = isError ? field.meta.error : undefined;
    if (props.type === InputType.Phone) {
        return (React.createElement(PhoneInput, { value: field.input.value, textFieldsProps: Object.assign(Object.assign({}, field.input), { onChange: field.input.onChange, name: field.input.name, error: isError, helperText: error, label: t(props.label) }) }));
    }
    if (props.type === InputType.Switch) {
        return (React.createElement(GenderSwitch, { value: field.input.value, onChange: field.input.onChange, onBlur: field.input.onBlur, error: error }));
    }
    if (props.type === InputType.Date) {
        return (React.createElement(DateField, { label: t(props.label), value: field.input.value, onChange: field.input.onChange, onBlur: field.input.onBlur, error: error }));
    }
    if (props.type === InputType.Select) {
        let val = field.input.value;
        if (props.name === 'docType') {
            const isExist = props.options.some(option => option.value === val);
            if (!isExist) {
                val = null;
            }
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Select, { options: props.options, name: props.name, label: t(props.label), selectFirstOptionByDefault: props.name === 'docType', onChange: (key, e) => __awaiter(void 0, void 0, void 0, function* () {
                    if (props.name === 'country') {
                        form.change('docType', null);
                    }
                    field.input.onChange(e);
                }), onBlur: field.input.onBlur, value: val, error: error, translateValue: props.translateValue })));
    }
    if (props.type === InputType.Autocomplete) {
        return (React.createElement(Dropdown, { InputProps: Object.assign(Object.assign({}, field.input), { label: t(props.label), error: isError, helperText: error }), options: props.options.map(option => ({ label: option.key, value: option.value })), disabled: props.disabled, onChange: (_, value) => {
                field.input.onChange(value || null);
            }, onInputChange: (_, value, reason) => props.onAutocompleteInputChange(value, reason), 
            // disable inner filter
            filterOptions: (options, _) => options, noOptionsText: field.input.value ? t('Nothing was found for your request') : t('Start typing') }));
    }
    return (React.createElement(Input, { label: t(props.label), value: field.input.value, name: field.input.name, TextFieldProps: Object.assign(Object.assign({}, field.input), { error: isError, helperText: error, disabled: props.disabled }) }));
};
export default Field;
