import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Switch } from '../../index';
import { useTheme } from '../../theme';
import SimpleLoader from '../../SimpleLoader';
import { initI18n } from '../../utils';
import { computeServicesMap, getAvailableSegments, getIsDifferentServicesOrPassengers, getNewSelectedServices } from '../utils';
import Control from './Control/Control';
import { useToggleable } from '../../hooks';
import SegmentCollapse from '../../AdditionalServiceWrapper/components/SegmentCollapse/SegmentCollapse';
import Money from '../../Money';
import { useVipServiceState } from '../hooks';
import { useMemo } from 'react';
import WebskyAnalyticsApollo from '../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { WxEvents } from '../../DataLayerAnalytics/types';
import { BaseButton } from '../../index';
initI18n('VipServiceSelect');
const VipServiceSelect = ({ className, headerClassName, passengers, services, segments, header, headerIcon, loading, showFromPrice, showTotalPrice, onSave, selectedServices, onClose, subheader, type = 'switch', renderPassenger, readOnly }) => {
    const { VipServiceSelect: theme } = useTheme('VipServiceSelect');
    const { t } = useTranslation('VipServiceSelect');
    const servicesMap = useMemo(() => computeServicesMap(services, selectedServices), [services, selectedServices]);
    const availableSegments = useMemo(() => getAvailableSegments(segments, passengers, servicesMap), [
        segments,
        passengers,
        servicesMap
    ]);
    const isDifferentServicesOrPassengers = getIsDifferentServicesOrPassengers(servicesMap);
    const { state, minPrice, totalPrice, isDifferentState, dispatch } = useVipServiceState(passengers, services, selectedServices);
    const { isOpen: differentSegment, toggle: toggleDifferentSegment } = useToggleable(availableSegments.length > 1 || isDifferentServicesOrPassengers);
    const differentSegmentToggle = () => {
        if (differentSegment) {
            passengers.forEach(passenger => {
                availableSegments.forEach(segment => {
                    dispatch({
                        count: 0,
                        passengerId: passenger.id,
                        segmentId: segment.segmentId
                    });
                });
            });
        }
        toggleDifferentSegment();
    };
    const onSaveHandler = () => {
        if (isDifferentState) {
            const newServices = getNewSelectedServices(servicesMap, state, !differentSegment);
            return onSave(newServices);
        }
        else {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
    };
    React.useEffect(() => {
        var _a;
        if (!!(services === null || services === void 0 ? void 0 : services.length)) {
            WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.VipList, { subType: (_a = services === null || services === void 0 ? void 0 : services[0]) === null || _a === void 0 ? void 0 : _a.subType });
        }
    }, []);
    const onSetCount = (passenger) => (count) => {
        const segments = [
            ...(differentSegment ? passenger.segmentId : availableSegments.map(segment => segment.segmentId))
        ];
        segments.forEach(segmentId => {
            dispatch({
                count,
                passengerId: passenger.passengerId,
                segmentId: segmentId
            });
        });
    };
    const renderPassengerHandler = (passenger) => {
        var _a, _b, _c, _d, _e, _f;
        const count = (_b = (_a = state[passenger.segmentId]) === null || _a === void 0 ? void 0 : _a[passenger.passengerId]) !== null && _b !== void 0 ? _b : 0;
        const confirmedCount = (_f = (_e = (_d = (_c = selectedServices === null || selectedServices === void 0 ? void 0 : selectedServices[passenger.passengerId]) === null || _c === void 0 ? void 0 : _c[passenger.serviceId]) === null || _d === void 0 ? void 0 : _d.find(selectedVip => selectedVip === null || selectedVip === void 0 ? void 0 : selectedVip.segments.includes(passenger.segmentId))) === null || _e === void 0 ? void 0 : _e.servicePayload) === null || _f === void 0 ? void 0 : _f.confirmedCount;
        const isConfirmed = confirmedCount > 0;
        const canBeAdded = services.find(service => service.id === passenger.serviceId).canBeAdded;
        const isCanModify = canBeAdded || count > confirmedCount;
        if (renderPassenger) {
            return renderPassenger({ passenger, type, count, isConfirmed, onSetCount });
        }
        return (React.createElement(Control, { key: passenger.passengerId, type: type, count: count, disabled: isConfirmed || !isCanModify, label: React.createElement("div", { className: theme.passenger },
                React.createElement("div", { className: theme.number }, parseInt(passenger.passengerId) + 1),
                passenger.name), onSetCount: onSetCount(passenger) }));
    };
    const getDirectionName = (segments) => {
        return [segments[0].departure, segments[segments.length - 1].arrival].join(' – ');
    };
    return (React.createElement("div", { className: cn(theme.container, className) },
        loading && React.createElement(SimpleLoader, null),
        header && (React.createElement("div", { className: cn(theme.header, headerClassName) },
            React.createElement("div", { className: theme.header__info },
                header,
                showFromPrice && minPrice && (React.createElement("div", { className: theme.price_from },
                    t('from'),
                    React.createElement(Money, { money: minPrice })))),
            headerIcon && React.createElement("div", { className: theme.header__icon }, headerIcon))),
        subheader && React.createElement("div", { className: theme.subheader }, subheader),
        React.createElement("div", { className: theme.content }, (differentSegment ? availableSegments : availableSegments.slice(0, 1)).map(segment => (React.createElement(SegmentCollapse, { key: segment.segmentId, className: theme.segment, date: segment.date, info: getDirectionName(differentSegment ? [segment] : availableSegments), isActive: true }, segment.passengers.map(renderPassengerHandler))))),
        (availableSegments.length > 1 || (showTotalPrice && totalPrice)) && (React.createElement("div", { className: theme.footer },
            availableSegments.length > 1 && (React.createElement("div", { className: theme.switch__wrapper },
                React.createElement(Switch, { switchPosition: "right", label: t('Different for other flight?'), labelClassName: theme.onlySegment, isActive: differentSegment, mainClassName: theme.onlySegment__switch, activeClassName: theme.switch__container_active, disableClassName: theme.switch__container_disable, isDisabled: isDifferentServicesOrPassengers, onClick: differentSegmentToggle }))),
            showTotalPrice && totalPrice && (React.createElement("div", { className: theme.total },
                t('Total'),
                ":",
                React.createElement(Money, { money: totalPrice }))))),
        React.createElement("div", { className: theme.controls },
            React.createElement(BaseButton, { variant: "smallSecondary", className: theme.cancel, onClick: onClose }, t('Cancel')),
            !readOnly && (React.createElement(BaseButton, { onClick: onSaveHandler, variant: "smallThird", className: theme.confirm }, t('Confirm'))))));
};
export default VipServiceSelect;
