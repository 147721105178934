import { useDesktopFlight } from '../../hooks';
import { useToggleable } from '../../../hooks';
import { initI18n } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { FareFamilyOptionsType, FareFeaturePaymentType } from '@websky/graphql';
const OFFER_UPGRADE_IF_UNAVAILABLE = [
    FareFamilyOptionsType.Exchangeable,
    FareFamilyOptionsType.Baggage,
    FareFamilyOptionsType.Refundable
];
initI18n('DesktopFlight');
export const useDesktopFlightWithPopup = (props, defaultModalState = false) => {
    const { isOpen: isModalOpen, open: openModal, close: closeModal } = useToggleable(defaultModalState);
    const { isOpen: isComparisonOpen, setOpen: setIsComparisonOpen } = useToggleable(false);
    const upgradeOptions = new Map();
    const desktopFlightData = useDesktopFlight(props);
    const { t } = useTranslation('DesktopFlight');
    // wrap openModal with function, which implement interface of getOnFareClickHandler
    // but call openModal instead of previous logic
    const handleModalOpen = serviceClass => openModal;
    props.flight.pricesForFareGroups.forEach((priceForFareGroup, index) => {
        var _a, _b, _c, _d;
        if (!priceForFareGroup.prices) {
            return;
        }
        const nextFare = props.flight.pricesForFareGroups[index + 1];
        if (!nextFare || desktopFlightData.lastSelectedFare) {
            return;
        }
        // collect all unavailable options on current fare
        const fareUnavailableServicesTypes = (_c = (_b = (_a = priceForFareGroup.fareFamily) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.filter(option => option.availability === FareFeaturePaymentType.NotAvailable)) === null || _c === void 0 ? void 0 : _c.map(option => option.type);
        // collect all available options on next fare
        // options which affected, listed in OFFER_UPGRADE_IF_UNAVAILABLE array
        const availableOptionsOnNextFare = Array.from(new Set((_d = nextFare.fareFamily) === null || _d === void 0 ? void 0 : _d.options.filter(option => option.availability !== FareFeaturePaymentType.NotAvailable &&
            (fareUnavailableServicesTypes === null || fareUnavailableServicesTypes === void 0 ? void 0 : fareUnavailableServicesTypes.includes(option.type)) &&
            OFFER_UPGRADE_IF_UNAVAILABLE.includes(option.type)).map(option => option.type)));
        if (availableOptionsOnNextFare.length) {
            const upgradeOffer = t(`Need_${availableOptionsOnNextFare[0] === FareFamilyOptionsType.Baggage ? `m` : `f`} `) +
                availableOptionsOnNextFare
                    .map((availableOptionOnNextSegment, index) => {
                    switch (availableOptionOnNextSegment) {
                        case FareFamilyOptionsType.Baggage:
                            return t('baggage');
                        case FareFamilyOptionsType.Exchangeable:
                            return t('the ability to exchange ticket');
                        case FareFamilyOptionsType.Refundable:
                            return t('the ability to refund ticket');
                        default:
                            return '';
                    }
                })
                    .join(t('and'));
            const upgradeOption = {
                canUpgradeTo: nextFare,
                optionsAvailableOnNextFare: availableOptionsOnNextFare,
                upgradeOffer
            };
            upgradeOptions.set(priceForFareGroup.fareFamily.id, upgradeOption);
        }
    });
    return Object.assign(Object.assign({}, desktopFlightData), { isModalOpen,
        openModal, getFareGroupTabHandler: handleModalOpen, closeModal,
        upgradeOptions,
        isComparisonOpen,
        setIsComparisonOpen });
};
