import * as React from 'react';
import { useEffect, useRef } from 'react';
const Button = ({ button }) => {
    const buttonHandler = useRef();
    useEffect(() => {
        if (buttonHandler) {
            buttonHandler.current.appendChild(button);
        }
    }, [buttonHandler, button]);
    return React.createElement("div", { ref: buttonHandler });
};
export default Button;
