import { SET_EVERY_TRAVEL_CART } from './actions';
const initialState = {
    cartId: null
};
export const everyTravelReducer = (state = initialState, action) => {
    if (action.type === SET_EVERY_TRAVEL_CART) {
        return Object.assign(Object.assign({}, state), { cartId: action.payload.cartId });
    }
    return state;
};
