import * as React from 'react';
import { useTheme } from '../../../theme';
import { Grid } from '@mui/material';
import { Field, useForm } from 'react-final-form';
import { TravellerFields } from '../../types';
import Input from '../../../BaseComponents/Input';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { useTranslation } from 'react-i18next';
import SexSelector from '../../../BaseComponents/SexSelector';
import { DateMaskedInput } from '../../../DateMaskedInput/DateMaskedInput';
const PersonalInfoSection = () => {
    const { PersonalInfoSection: css } = useTheme('TravellerForm');
    const { t: tDocumentCardForm } = useTranslation('DocumentCardForm');
    const { t: tTraveller } = useTranslation('Traveller');
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const variant = isMobile ? 'underline' : 'labelIn';
    const form = useForm();
    return (React.createElement("div", { className: css.personalInfoSection },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: TravellerFields.BirthDate, render: ({ input, meta }) => (React.createElement(Input, Object.assign({}, input, { className: css.input, variant: variant, label: `${tTraveller('birthDate')}*`, error: meta.error && meta.touched, helperText: meta.touched && tDocumentCardForm(meta.error), InputProps: {
                            inputComponent: DateMaskedInput
                        }, onChange: (event) => {
                            input.onChange(event);
                            form.mutators.fixEmptyFieldMutator({ fieldName: TravellerFields.BirthDate });
                        } }))) })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: TravellerFields.Gender, render: ({ input, meta }) => (React.createElement(SexSelector, { activeSex: input.value, onChange: input.onChange, error: meta.error && meta.touched, helperText: meta.touched && tDocumentCardForm(meta.error) })) })))));
};
export default PersonalInfoSection;
