import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { InsuranceType } from '@websky/graphql';
import { Banner } from '../Banner/Banner';
import { Card } from '../Card/Card';
import { useInsuranceProduct } from './InsuranceProduct.hook';
import { useTheme } from '../../../theme';
import { IconButton } from '../../../index';
import { Add, Download } from '../../../Icons';
import { InsurancePrice } from '../InsurancePrice/InsurancePrice';
import { SelectForm } from '../SelectForm/SelectForm';
import Link from '../../../Link';
import { InfoIcon } from '../../../FlightCard/components/Icons';
import { OptionsList } from '../OptionsList/OptionsList';
export const InsuranceProduct = ({ onToggle, insuranceProgram, productState, className, popular, policyUrl, onSelect, onRemove, onDownloadVskInsurance, hideForAllPassengersTextBecauseSinglePassenger, isShowImportantInsurancesError }) => {
    var _a;
    const { t } = useTranslation('InsurancePage');
    const { InsuranceProduct: theme } = useTheme('InsurancePage');
    const [isLoading, setIsLoading] = useState(false);
    const model = useInsuranceProduct(insuranceProgram, onToggle, popular, productState);
    const onDownloadVskInsuranceHandler = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield (onDownloadVskInsurance === null || onDownloadVskInsurance === void 0 ? void 0 : onDownloadVskInsurance());
        setIsLoading(false);
    }), [onDownloadVskInsurance]);
    const infoLink = useMemo(() => {
        const isVskInsurance = insuranceProgram.type === InsuranceType.Vsk;
        const isConfirmedAndCanDownload = model.isConfirmed && (policyUrl || isVskInsurance);
        if (!insuranceProgram.urlToOffer && !isConfirmedAndCanDownload) {
            return null;
        }
        return (React.createElement("div", { className: theme.linksWrapper },
            insuranceProgram.urlToOffer && (React.createElement(Link, { className: theme.moreInfo, action: insuranceProgram.urlToOffer, target: '_blank' },
                React.cloneElement(InfoIcon, {
                    className: theme.infoIcon
                }),
                t('More details'))),
            isConfirmedAndCanDownload && (React.createElement(Link, { className: cn(theme.moreInfo, { [theme.moreInfo_loading]: isLoading }), action: isVskInsurance ? onDownloadVskInsuranceHandler : policyUrl, target: !isVskInsurance ? '_blank' : undefined },
                React.cloneElement(Download, {
                    className: theme.infoIcon
                }),
                t('Download police')))));
    }, [
        insuranceProgram.urlToOffer,
        insuranceProgram.type,
        policyUrl,
        model.isConfirmed,
        onDownloadVskInsuranceHandler,
        isLoading
    ]);
    return (React.createElement(Card, { className: cn(theme.border, theme.shadow, {
            [theme.popular]: popular,
            [theme.confirmed]: model.isConfirmed
        }, className) },
        React.createElement(Banner, { className: theme.banner, code: insuranceProgram.code, popular: popular }),
        React.createElement("div", { className: theme.content },
            model.showIconButton && (React.createElement(IconButton, { onClick: model.handleToggle, isSuccess: model.isSelected || model.isConfirmed, className: cn(theme.selectButton, {
                    [theme.confirmed]: model.isConfirmed
                }) }, Add)),
            React.createElement("div", { className: theme.contentWrapper },
                React.createElement("div", { className: theme.title }, insuranceProgram.name),
                insuranceProgram.description && (React.createElement("div", { className: theme.description, dangerouslySetInnerHTML: { __html: insuranceProgram.description } })),
                !!((_a = insuranceProgram.options) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement(OptionsList, { className: theme.options, options: insuranceProgram.options.map(({ name }) => name) }))),
            React.createElement("div", { className: theme.footer },
                !popular && infoLink,
                popular && !model.isConfirmed && (React.createElement(React.Fragment, null,
                    React.createElement(SelectForm, { className: theme.formControl, onSelect: () => onSelect(insuranceProgram.code), onRemove: () => onRemove(insuranceProgram.code), selected: model.isSelected, hideForAllPassengersTextBecauseSinglePassenger: hideForAllPassengersTextBecauseSinglePassenger, isTouched: model.isTouched, isError: model.isError && isShowImportantInsurancesError }),
                    React.createElement("div", { className: theme.footerPrice },
                        popular && hideForAllPassengersTextBecauseSinglePassenger && infoLink,
                        React.createElement(InsurancePrice, { className: theme.bottomPrice, selected: model.isSelected, price: insuranceProgram.totalPrice, variant: 'bottomPrice', hideForAllPassengersTextBecauseSinglePassenger: hideForAllPassengersTextBecauseSinglePassenger })))),
                !popular && (React.createElement(InsurancePrice, { className: theme.footerPrice, selected: model.isSelected, price: insuranceProgram.totalPrice, variant: popular ? 'mostPopular' : 'regular', onClick: !model.isConfirmed ? model.handleToggle : undefined, onRemove: model.handleToggle, hideForAllPassengersTextBecauseSinglePassenger: hideForAllPassengersTextBecauseSinglePassenger })),
                popular && !hideForAllPassengersTextBecauseSinglePassenger && infoLink))));
};
