import { applyMiddleware, createStore as originalCreateStore } from 'redux';
import sagaMiddlewareFactory from 'redux-saga';
import { enableBatching } from 'redux-batched-actions';
import { rootReducer } from './reducers';
import rootSaga from './sagas';
import memes from '../../memes';
export const createStore = memes(() => {
    const saga = sagaMiddlewareFactory();
    const middleware = [saga];
    if (process.env.NODE_ENV === 'development') {
        middleware.push(require('redux-logger').default);
    }
    const store = originalCreateStore(enableBatching(rootReducer), applyMiddleware(...middleware));
    saga.run(rootSaga);
    return store;
});
