import * as React from 'react';
import { useCallback } from 'react';
import Amenities from '../../../Amenities';
import { FlightSegmentStatus } from '@websky/graphql';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import FlightInfo from './FlightInfo/FlightInfo';
import MediaQuery from 'react-responsive';
import { TABLET_MIN_WIDTH } from '../../../utils';
import StopsInfo from './StopsInfo/StopsInfo';
import Point from './Point/Point';
import RouteArrow from './RouteArrow/RouteArrow';
import { getDurationString } from '../../../Results/utils';
import { useTranslation } from 'react-i18next';
import { InfoMessagePositionsEnum } from '@websky/graphql';
import { useConfig } from '../../../context';
import AircraftLivery from '../../../AircraftLivery';
import InfoMessages from '../../../InfoMessages';
const Segment = ({ segment: segmentWithTransfer, className, first, last, stops = [], totalDuration, nextDay, renderFlightInfo, multipleSegments, renderPlaneInfo, stopsSeparator, isReturnFlight }) => {
    const { segment, transferDuration } = segmentWithTransfer;
    const canceled = segment.status === FlightSegmentStatus.Canceled;
    const iataCode = useConfig().global.companyInfo.iataCode;
    const hasOperatingCarrier = ['A9', 'RM'].includes(iataCode);
    const operationCarrier = hasOperatingCarrier && segment.marketingAirline.iata !== segment.operatingAirline.iata
        ? segment.operatingAirline.name
        : null;
    const theme = useTheme('DesktopFlightRoute').SegmentStyles;
    const { t } = useTranslation('DesktopFlightInfo');
    const renderPlaneInfoHandler = useCallback(() => {
        var _a, _b;
        if (renderPlaneInfo) {
            return renderPlaneInfo(segment);
        }
        return (React.createElement("div", { className: theme.planeInfo },
            React.createElement("div", { className: theme.aircraft }, segment.aircraft.name),
            ((_b = (_a = segment === null || segment === void 0 ? void 0 : segment.flightInfo) === null || _a === void 0 ? void 0 : _a.amenities) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement("div", { className: theme.amenities },
                React.createElement(Amenities, { amenities: segment.flightInfo.amenities })))));
    }, [segment, renderPlaneInfo]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(theme.segment, {
                [theme.segment_additional]: !first,
                [theme.canceled]: canceled,
                [theme.oneStop]: stops.length === 1
            }, className) },
            renderFlightInfo ? (renderFlightInfo({ segment, nextDay, singleFlight: false })) : (React.createElement(FlightInfo, { segment: segment, nextDay: nextDay })),
            React.createElement("div", { className: theme.route__info },
                React.createElement(Point, { time: segment.departure.time, airport: segment.departure.airport, thin: !first }),
                React.createElement(RouteArrow, { timeInRoute: getDurationString(segment.duration), flightNumber: `${segment.marketingAirline.iata}-${segment.flightNumber}` }),
                React.createElement(Point, { time: segment.arrival.time, airport: segment.arrival.airport, arrival: true, thin: !last }),
                (transferDuration || stops.length > 0) && !canceled && (React.createElement(StopsInfo, { stops: stops, stopCityName: segment.arrival.airport.city.name, transferDuration: transferDuration, separator: stopsSeparator })),
                last && multipleSegments && !stops.length && (React.createElement("div", { className: theme.totalDuration },
                    React.createElement("span", null, totalDuration)))),
            React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH },
                React.createElement(React.Fragment, null, !canceled ? (React.createElement("div", { className: theme.planeContainer }, segment.aircraft && (React.createElement(React.Fragment, null,
                    React.createElement(AircraftLivery, { className: theme.plane, returnClassName: theme.plane_return, aircraft: segment.aircraft, isReturnFlight: isReturnFlight }),
                    renderPlaneInfoHandler(),
                    operationCarrier && (React.createElement("div", { className: theme.operatingCarrier },
                        t('Operating carrier'),
                        ": ",
                        operationCarrier)))))) : (React.createElement("div", { className: theme.canceled__warning }, t('Canceled')))))),
        !last && multipleSegments && (React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.stopPoint, className: theme.stopPointInfoMessage, itemHtmlClassName: theme.stopPointInfoMessage__htmlMessage, messages: segment.messages })),
        last && multipleSegments && React.createElement("div", { className: theme.totalDuration__decorator })));
};
export default Segment;
