import * as React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '../../../index';
const EmptyDatesOverlay = props => {
    const { SearchFormDatepickerStyles: css } = useTheme('SearchFormDatepicker');
    const { t } = useTranslation('SearchFormDatepicker');
    const { onClick } = props;
    return (React.createElement(Backdrop, { open: true, classes: { root: css.overlay } },
        React.createElement("h5", { className: css.overlay__title },
            t('Oops'),
            " :("),
        React.createElement("p", { className: css.overlay__msg }, t('Something went wrong, please check your internet connection or come back later')),
        React.createElement(BaseButton, { variant: "optionAction", classes: { root: css.overlay }, onClick: onClick }, t('Ok'))));
};
export default EmptyDatesOverlay;
