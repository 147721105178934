import React from 'react';
import { getProductsToExchange } from './utils';
import { useGetServicesRefundInfoQuery } from '@websky/graphql';
export function useRefundSummary({ orderId, allFlightToExchange, selectedSegmentIds, selectedPassengerIds, passengers }) {
    const services = [];
    selectedPassengerIds.forEach(passengerId => {
        var _a, _b, _c, _d, _e;
        const passenger = passengers.find(p => p.id === passengerId);
        if ((_b = (_a = passenger === null || passenger === void 0 ? void 0 : passenger.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) {
            services.push(...(_d = (_c = passenger === null || passenger === void 0 ? void 0 : passenger.services) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services);
        }
        if ((_e = passenger === null || passenger === void 0 ? void 0 : passenger.services) === null || _e === void 0 ? void 0 : _e.seats) {
            passenger.services.seats.forEach(seat => {
                if (!seat.product) {
                    return;
                }
                services.push({
                    segmentIds: [seat.segment.id],
                    products: [seat.product]
                });
            });
        }
    });
    const selectedProductIds = React.useMemo(() => {
        return getProductsToExchange(services, allFlightToExchange ? [] : selectedSegmentIds);
    }, [services, selectedSegmentIds, allFlightToExchange]);
    const { data, loading } = useGetServicesRefundInfoQuery({
        variables: {
            params: {
                orderId,
                productIds: selectedProductIds
            }
        },
        skip: !selectedProductIds.length
    });
    return {
        gdsServicesRefund: data,
        loading,
        hasSelectedProducts: !selectedProductIds.length
    };
}
