import i18n from 'i18next';
import { GdsServiceProductStatus } from '@websky/graphql';
const UNCONFIRMED_STATUSES = [
    GdsServiceProductStatus.Rejected,
    GdsServiceProductStatus.Canceled,
    GdsServiceProductStatus.Requested
];
export const getUnconfirmedServices = (order) => {
    var _a, _b, _c;
    const servicesByIdMap = new Map();
    (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(service => {
        servicesByIdMap.set(service.id, service);
    });
    const unconfirmedServicesMap = new Map();
    order.travellers.forEach(traveller => {
        var _a, _b, _c;
        (_b = (_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.forEach(service => {
            var _a;
            (_a = service.segmentIds) === null || _a === void 0 ? void 0 : _a.forEach(segmentId => {
                const hasUnconfirmedProduct = service.products.some(product => UNCONFIRMED_STATUSES.includes(product.status));
                if (hasUnconfirmedProduct) {
                    service.products.forEach(product => {
                        var _a, _b, _c;
                        const status = product.status;
                        const travellerSegmetServices = ((_a = unconfirmedServicesMap.get(status)) === null || _a === void 0 ? void 0 : _a.get(traveller.id)) ||
                            new Map();
                        const segmentServices = travellerSegmetServices.get(segmentId) || [];
                        segmentServices.push({
                            name: (_c = (_b = servicesByIdMap.get(service.serviceId)) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '',
                            serviceId: service.serviceId,
                            count: service.count - service.confirmedCount
                        });
                        travellerSegmetServices.set(segmentId, segmentServices);
                        const statusServicesMap = unconfirmedServicesMap.get(status) || new Map();
                        statusServicesMap.set(traveller.id, travellerSegmetServices);
                        unconfirmedServicesMap.set(status, statusServicesMap);
                    });
                }
            });
        });
        (_c = traveller.services.seats) === null || _c === void 0 ? void 0 : _c.forEach(seat => {
            var _a, _b, _c, _d, _e, _f, _g;
            const segmentId = (_a = seat.segment) === null || _a === void 0 ? void 0 : _a.id;
            if (UNCONFIRMED_STATUSES.includes((_b = seat.product) === null || _b === void 0 ? void 0 : _b.status)) {
                const status = (_c = seat.product) === null || _c === void 0 ? void 0 : _c.status;
                const travellerSegmetServices = ((_d = unconfirmedServicesMap.get(status)) === null || _d === void 0 ? void 0 : _d.get(traveller.id)) || new Map();
                const segmentServices = travellerSegmetServices.get(segmentId) || [];
                segmentServices.push({
                    name: `${i18n.t('Checkin:Seat')} ${(_e = seat.seat) === null || _e === void 0 ? void 0 : _e.number}`,
                    serviceId: (_g = (_f = seat.seat) === null || _f === void 0 ? void 0 : _f.seatService) === null || _g === void 0 ? void 0 : _g.id,
                    count: null
                });
                travellerSegmetServices.set(segmentId, segmentServices);
                const statusMap = unconfirmedServicesMap.get(status) || new Map();
                statusMap.set(traveller.id, travellerSegmetServices);
                unconfirmedServicesMap.set(status, statusMap);
            }
        });
    });
    return unconfirmedServicesMap;
};
export const hasRequestedServices = (unconfirmedServices) => unconfirmedServices.has(GdsServiceProductStatus.Requested);
export const REMOVABLE_STATUSES = [GdsServiceProductStatus.Canceled, GdsServiceProductStatus.Rejected];
