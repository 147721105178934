import * as React from 'react';
import Flight from '../../../../../../Flight';
import Money from '../../../../../../Money';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../../theme';
import cn from 'classnames';
const checkIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0)" },
        React.createElement("circle", { cx: "16", cy: "16", r: "16", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.5526 20.7309L25.8734 10.4101L24.1056 8.64237L13.7848 18.9632L8.73469 13.9131L6.96693 15.6809L12.017 20.7309L12.0169 20.7311L13.7847 22.4988L13.7848 22.4987L13.7852 22.4992L15.553 20.7314L15.5526 20.7309Z", fill: "white" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("path", { d: "M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z", fill: "white" })))));
const SelectedFlights = ({ selectedFlights, type, prices, goToFlights, hideHeader }) => {
    const { t } = useTranslation('Exchange');
    const theme = useTheme('Exchange').SelectedFlights;
    const priceForFlight = React.useMemo(() => {
        var _a, _b, _c;
        if (prices) {
            return {
                amount: ((_a = prices.priceToPay) === null || _a === void 0 ? void 0 : _a.amount) - ((_b = prices.charges) === null || _b === void 0 ? void 0 : _b.amount),
                currency: (_c = prices.priceToPay) === null || _c === void 0 ? void 0 : _c.currency
            };
        }
        return null;
    }, [prices]);
    return (React.createElement("div", { className: cn({ [theme.container_flat]: type === 'flat' }) },
        !hideHeader && (React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.header__name },
                React.createElement("div", { className: theme.header__icon }, checkIcon),
                t('You’ve chosen'),
                ' '),
            React.createElement("div", { className: theme.change, onClick: goToFlights }, t('Change flight')))),
        React.createElement("div", { className: theme.flight__container },
            React.createElement("div", { className: theme.flights }, selectedFlights.map((flight, index) => (React.createElement(Flight, { flight: {
                    pricesForFareGroups: [],
                    segments: flight.segments
                }, isPreOpenAvailable: false, isPreOpen: false, hidePrice: true, readonly: true, key: flight.id, isReturnFlight: flight.segmentGroups[index].groupId === '1' })))),
            !!(priceForFlight === null || priceForFlight === void 0 ? void 0 : priceForFlight.amount) && (React.createElement("div", { className: theme.price },
                React.createElement(Money, { moneyClassName: theme.money, money: priceForFlight })))),
        prices && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.charge },
                React.createElement("div", null, t('Exchange penalty')),
                React.createElement(Money, { moneyClassName: cn(theme.money, theme.charge__money), money: prices.charges })),
            React.createElement("div", { className: theme.charge },
                React.createElement("div", null),
                React.createElement("div", { className: theme.total },
                    t('Total'),
                    ":",
                    React.createElement(Money, { moneyClassName: cn(theme.money, theme.total__money), money: prices.priceToPay })))))));
};
export default SelectedFlights;
