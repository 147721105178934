import { createTheme } from '../utils';
import * as TravellerForm from './components/TravellerForm/TravellerForm.css';
import * as Preferences from './components/Preferences/Preferences.css';
import * as SectionWrapper from './components/SectionWrapper/SectionWrapper.css';
import * as DocumentsSection from './components/DocumentsSection/DocumentsSection.css';
import * as DocumentFieldsItem from './components/DocumentsSection/DocumentFieldsItem/DocumentFieldsItem.css';
import * as PersonalInfoSection from './components/PersonalInfoSection/PersonalInfoSection.css';
import * as ContactsSection from './components/ContactsSection/ContactsSection.css';
export const defaultTheme = {
    TravellerForm,
    Preferences,
    SectionWrapper,
    DocumentsSection,
    DocumentFieldsItem,
    PersonalInfoSection,
    ContactsSection
};
export default defaultTheme;
export const { useTheme } = createTheme(defaultTheme);
