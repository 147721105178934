import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import ModalConfirm from '../../../../../ModalConfirm';
const successIcon = (React.createElement("svg", { width: "36", height: "36", viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("circle", { cx: "18", cy: "18", r: "18", fill: "#5FCA43" }),
    React.createElement("path", { d: "M11.1738 18.5147L15.5796 23.25L25.3488 12.75", stroke: "white", strokeWidth: "2.1", strokeLinecap: "round" })));
const Dialog = props => {
    const [isOpen, setOpen] = React.useState(props.open);
    const [isLoading, setLoading] = React.useState(false);
    const open = () => setOpen(true);
    const close = () => setOpen(false);
    const onAccept = () => {
        const accept = props.onAccept();
        if (typeof accept === 'object' && typeof accept['then'] === 'function') {
            setLoading(true);
            accept.then(close);
        }
        else {
            close();
        }
    };
    const onCancel = () => {
        props.onCancel();
        close();
    };
    const theme = useTheme('Exchange').Dialog;
    return (React.createElement(React.Fragment, null,
        props.children &&
            props.children({
                open,
                close,
                isOpen
            }),
        React.createElement(ModalConfirm, { size: "small", isOpen: isOpen, onCancel: props.cancelText ? onCancel : null, isLoading: isLoading, cancelBtn: props.cancelText, onClose: close, confirmBtn: props.acceptText, onConfirm: onAccept },
            React.createElement("div", { className: theme.content },
                React.createElement("div", { className: cn(theme.icon, props.iconClassName) }, successIcon),
                React.createElement("div", null, props.text)))));
};
export default Dialog;
