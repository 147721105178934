import * as React from 'react';
import { useMemo, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import { useTheme } from '../../theme';
import { FieldGroupType } from '../types';
import { Field } from './Field/Field';
import { initI18n } from '../../utils';
import Header from './Header/Header';
import MediaQuery from '../../MediaQuery/MediaQuery';
import Link from '../../Link';
import { useConfig } from '../../context';
import { hideText } from '../utils';
import Notification from '../../Notification';
import { InfoMessageTypeEnum, TravellerFieldEnum } from '@websky/graphql';
initI18n('Passenger');
const Passenger = props => {
    const groups = {
        [FieldGroupType.Identity]: [],
        [FieldGroupType.Loyalty]: [],
        [FieldGroupType.Citizenship]: [],
        [FieldGroupType.Contacts]: [],
        [FieldGroupType.Other]: []
    };
    const [isOpen, setOpen] = useState(true);
    const { t } = useTranslation('Passenger');
    const companyInfo = useConfig().global.companyInfo;
    const theme = useTheme('Passenger').PassengerStyles;
    // passanger card or account card
    const hasLoyaltyCard = useMemo(() => {
        var _a;
        const loyaltyFieldValue = (_a = props.fields.find(field => field.type === TravellerFieldEnum.LoyaltyNumber)) === null || _a === void 0 ? void 0 : _a.defaultValue;
        return loyaltyFieldValue && loyaltyFieldValue.length > 0;
    }, [props.fields]);
    if (!props.isReadonly) {
        props.fields.forEach(originalField => {
            var _a;
            const fieldName = `passengers[${props.id}].${originalField.name}`;
            const field = Object.assign(Object.assign({}, originalField), { name: fieldName, placeholder: t(originalField.placeholder), defaultValue: ((_a = props.inputsMask) === null || _a === void 0 ? void 0 : _a.includes(originalField.type))
                    ? hideText(originalField.defaultValue, 2.1)
                    : originalField.defaultValue });
            switch (field.type) {
                case TravellerFieldEnum.LastName:
                case TravellerFieldEnum.FirstName:
                case TravellerFieldEnum.MiddleName:
                case TravellerFieldEnum.BirthDate:
                case TravellerFieldEnum.Gender:
                    groups[FieldGroupType.Identity].push(field);
                    break;
                case TravellerFieldEnum.Nationality:
                case TravellerFieldEnum.DocType:
                case TravellerFieldEnum.DocNumber:
                case TravellerFieldEnum.DocExpiryDate:
                    groups[FieldGroupType.Citizenship].push(field);
                    break;
                case TravellerFieldEnum.LoyaltyNumber:
                case TravellerFieldEnum.LoyaltyAirline:
                    groups[FieldGroupType.Loyalty].push(field);
                    break;
                case TravellerFieldEnum.Phone:
                case TravellerFieldEnum.Email:
                    groups[FieldGroupType.Contacts].push(field);
                    break;
                default:
                    groups[FieldGroupType.Other].push(field);
            }
        });
    }
    const fieldGroups = [
        { type: FieldGroupType.Identity, fields: groups[FieldGroupType.Identity] },
        { type: FieldGroupType.Citizenship, fields: groups[FieldGroupType.Citizenship] },
        { type: FieldGroupType.Loyalty, fields: groups[FieldGroupType.Loyalty] },
        { type: FieldGroupType.Contacts, fields: groups[FieldGroupType.Contacts] },
        { type: FieldGroupType.Other, fields: groups[FieldGroupType.Other] }
    ];
    const renderFieldGroup = (group) => {
        return (!!group.fields.length && (React.createElement("div", { key: group.type, className: cn(theme.form, {
                [theme.form_identy]: group.type === FieldGroupType.Identity,
                [theme.form_loyalty]: group.type === FieldGroupType.Loyalty,
                [theme.form_citizenship]: group.type === FieldGroupType.Citizenship,
                [theme.form_contacts]: group.type === FieldGroupType.Contacts,
                [theme.form_other]: group.type === FieldGroupType.Other
            }) },
            React.createElement(MediaQuery, { maxWidth: "mobile" },
                React.createElement("span", { className: theme.group__title }, t(group.type))),
            group.fields.map((field, index) => (React.createElement(Field, { disabled: props.isInputsReadonly || field.readonly, key: `${field.name}_${index}`, field: field, className: cn(theme.form__field, {
                    [theme.form__field_gender]: field.type === TravellerFieldEnum.Gender,
                    [theme.form__field_birthday]: field.type === TravellerFieldEnum.BirthDate
                }), passengerId: props.id, onTravellerSelect: props.onFill, passengerType: props.passengerType, loadTravellers: !props.isReadonly && props.isAuthorized }))),
            group.type === FieldGroupType.Loyalty && !props.isInputsReadonly && !hasLoyaltyCard && (React.createElement("div", { className: cn(theme.form__field, theme.form__field_loyaltyCardHint) },
                React.createElement(Link, { target: "_blank", action: companyInfo.loyaltyProgramRegistrationUrl }, t(`Don't have a card yet? Register now.`)))))));
    };
    return (React.createElement("div", { className: cn(props.className, theme.passenger, {
            [theme.short]: !isOpen,
            [theme.passenger_readonly]: props.isReadonly,
            [theme.withoutServices]: props.isReadonly && !props.services
        }) },
        React.createElement(Header, { setOpen: setOpen, isOpen: isOpen, isReadonly: props.isReadonly, passengerId: props.id, fields: props.fields, passengerType: props.passengerType, supplierType: props.supplierType, hasServices: !!props.services, servicesTotalPrice: props.servicesTotalPrice, label: props.label }),
        props.services ? (React.createElement(Collapse, { in: isOpen, mountOnEnter: true },
            React.createElement("div", { className: theme.form__container },
                !props.isReadonly && fieldGroups.map(renderFieldGroup),
                props.services))) : (!props.isReadonly && (React.createElement(Collapse, { in: isOpen, mountOnEnter: true },
            React.createElement("div", { className: theme.form__container },
                props.withSubsidies && props.noSubsidyAvailable && (React.createElement(Notification, { status: InfoMessageTypeEnum.Warning, type: "context", title: props.subsidyRejectMessage, titleClassName: theme.notification__title, infoMessage: {
                        title: props.subsidyRejectMessage,
                        text: t('A subsidized fare requires all passengers on the booking to have benefits.'),
                        type: InfoMessageTypeEnum.Warning,
                        code: 'subsidy'
                    } })),
                fieldGroups.map(renderFieldGroup)))))));
};
export default Passenger;
