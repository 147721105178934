import * as React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Input from '../../../../BaseComponents/Input';
import { MOBILE_MIN_WIDTH, initI18n } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { DocTypes } from '@websky/graphql';
import { useTheme } from '../../../../theme';
import { InputType } from '../../../../Passenger/types';
import { validate } from '../../../../Passenger/utils';
import Dropdown from '../../../../BaseComponents/Dropdown';
import { useMediaQuery } from 'react-responsive';
import { TravellerDocumentFields, TravellerFields } from '../../../types';
import { Grid } from '@mui/material';
import { getAvailabilityDocumentExpireDate, getDocumentTypes, getCitizenships } from './utils';
import { RemoveIcon } from '../../../Icons';
import { DateMaskedInput } from '../../../../DateMaskedInput/DateMaskedInput';
import { ButtonBase } from '@material-ui/core';
initI18n('DocumentCardForm');
const DocumentFieldsItem = ({ id, traveller, primaryCountryCodes, validators, onRemove }) => {
    var _a, _b, _c, _d;
    const { DocumentFieldsItem: css } = useTheme('TravellerForm');
    const { t: tDocumentCardForm } = useTranslation('DocumentCardForm');
    const { t: tPassenger } = useTranslation('Passenger');
    const { t: tTraveller } = useTranslation('Traveller');
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const formState = useFormState();
    const form = useForm();
    const variant = isMobile ? 'underline' : 'labelIn';
    const selectedDocType = (_b = (_a = formState.values[TravellerFields.Documents]) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b[TravellerDocumentFields.DocType];
    const selectedCitizenship = (_d = (_c = formState.values[TravellerFields.Documents]) === null || _c === void 0 ? void 0 : _c[id]) === null || _d === void 0 ? void 0 : _d[TravellerDocumentFields.Citizenship];
    const citizenships = getCitizenships(traveller, primaryCountryCodes);
    const documentTypes = getDocumentTypes(selectedCitizenship, validators);
    const isDocExpireDateHidden = getAvailabilityDocumentExpireDate(selectedDocType, validators);
    const isBirthRegDocument = selectedDocType === DocTypes.BirthRegDocument;
    const onRemoveHandler = () => {
        onRemove === null || onRemove === void 0 ? void 0 : onRemove(id);
    };
    const docTypeValidate = (value) => {
        if (!value) {
            return 'Required';
        }
        return undefined;
    };
    const docNumberValidate = (value, allValues) => {
        let error = undefined;
        const validation = validators === null || validators === void 0 ? void 0 : validators.values.find(validation => validation.name === TravellerDocumentFields.DocNumber);
        error = validate(value, Object.assign(Object.assign({}, allValues[TravellerFields.Documents][id]), { citizenships: formState.values[TravellerFields.Documents][id][TravellerDocumentFields.Citizenship] }), InputType.Text, validation === null || validation === void 0 ? void 0 : validation.validationRules);
        return error;
    };
    const docExpireDateValidate = (value, allValues) => {
        let error = undefined;
        const validation = validators === null || validators === void 0 ? void 0 : validators.values.find(validation => validation.name === TravellerDocumentFields.DocExpiryDate);
        error = validate(value, Object.assign(Object.assign({}, allValues[TravellerFields.Documents][id]), { nationality: formState.values[TravellerFields.Documents][id][TravellerDocumentFields.Nationality] }), InputType.Date, validation === null || validation === void 0 ? void 0 : validation.validationRules);
        return error;
    };
    return (React.createElement("div", { className: css.documentFieldsItem },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: `documents[${id}].${TravellerDocumentFields.LastName}`, render: ({ input, meta }) => {
                        const onChange = (event) => {
                            input.onChange(event);
                            form.mutators.fixEmptyFieldDocumentMutator({
                                fieldName: TravellerDocumentFields.LastName,
                                index: id
                            });
                            form.mutators.documentMutator({
                                fieldName: TravellerDocumentFields.LastName,
                                index: id
                            });
                        };
                        return (React.createElement(Input, Object.assign({}, input, { className: css.input, variant: variant, label: `${tTraveller('lastName')}*`, error: meta.error && meta.touched, helperText: meta.touched && tDocumentCardForm(meta.error), onChange: onChange })));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: `documents[${id}].${TravellerDocumentFields.FirstName}`, render: ({ input, meta }) => {
                        const onChange = (event) => {
                            input.onChange(event);
                            form.mutators.fixEmptyFieldDocumentMutator({
                                fieldName: TravellerDocumentFields.FirstName,
                                index: id
                            });
                            form.mutators.documentMutator({
                                fieldName: TravellerDocumentFields.FirstName,
                                index: id
                            });
                        };
                        return (React.createElement(Input, Object.assign({}, input, { className: css.input, variant: variant, label: `${tTraveller('firstName')}*`, error: meta.error && meta.touched, helperText: meta.touched && tDocumentCardForm(meta.error), onChange: onChange })));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: `documents[${id}].${TravellerDocumentFields.MiddleName}`, render: ({ input, meta }) => {
                        const onChange = (event) => {
                            input.onChange(event);
                            form.mutators.fixEmptyFieldDocumentMutator({
                                fieldName: TravellerDocumentFields.MiddleName,
                                index: id
                            });
                            form.mutators.documentMutator({
                                fieldName: TravellerDocumentFields.MiddleName,
                                index: id
                            });
                        };
                        return (React.createElement(Input, Object.assign({}, input, { className: css.input, variant: variant, label: `${tTraveller('middleName')}`, error: meta.error && meta.touched, helperText: meta.touched && tDocumentCardForm(meta.error), onChange: onChange })));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: `documents[${id}].${TravellerDocumentFields.Citizenship}`, render: ({ input, meta }) => {
                        const options = citizenships
                            .sort((a, b) => b.priority - a.priority)
                            .map(option => ({
                            label: tPassenger(option.label),
                            value: option.value
                        }));
                        const selectedOpt = citizenships.find(({ value }) => value === input.value);
                        const onChange = (_, value) => {
                            const option = value;
                            input.onChange(option === null ? '' : option.value);
                            form.mutators.citizenshipMutator({ index: id });
                        };
                        return (React.createElement(Dropdown, { classes: { inputRoot: css.dropdown }, options: options, value: selectedOpt, onChange: onChange, disableClearable: true, isOptionEqualToValue: (opt, value) => opt.value === value.value, InputProps: Object.assign(Object.assign({}, input), { variant, label: tTraveller('nationality'), error: meta.error && meta.touched, helperText: meta.touched && tTraveller(meta.error) }) }));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: `documents[${id}].${TravellerDocumentFields.DocType}`, validate: docTypeValidate, render: ({ input, meta }) => {
                        const options = documentTypes.map(document => ({
                            label: tPassenger(document),
                            value: document
                        }));
                        const selectedOpt = options.find(({ value }) => value === input.value);
                        const onChange = (_, value) => {
                            const option = value;
                            input.onChange(option === null ? '' : option.value);
                            form.mutators.docTypeMutator({ index: id });
                        };
                        return (React.createElement(Dropdown, { classes: { inputRoot: css.dropdown }, options: options, value: selectedOpt, onChange: onChange, disableClearable: true, InputProps: Object.assign(Object.assign({}, input), { variant, label: tDocumentCardForm('Document type'), error: meta.error && meta.touched, helperText: meta.touched && tPassenger(meta.error) }) }));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: `documents[${id}].${TravellerDocumentFields.DocNumber}`, validate: docNumberValidate, render: ({ input, meta }) => (React.createElement(Input, Object.assign({}, input, { className: css.input, variant: variant, label: `${tDocumentCardForm('Document number')}*`, error: meta.error && (meta.modified || meta.submitFailed), helperText: (meta.modified || meta.submitFailed) && tPassenger(meta.error), onChange: (event) => {
                            if (isBirthRegDocument) {
                                event.target.value = event.target.value.toUpperCase();
                            }
                            input.onChange(event);
                            form.mutators.fixEmptyFieldDocumentMutator({
                                fieldName: TravellerDocumentFields.DocNumber,
                                index: id
                            });
                        } }))) })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4, hidden: isDocExpireDateHidden },
                React.createElement(Field, { name: `documents[${id}].${TravellerDocumentFields.DocExpiryDate}`, validate: docExpireDateValidate, render: ({ input, meta }) => (React.createElement(Input, Object.assign({}, input, { className: css.input, variant: variant, label: `${tDocumentCardForm('Expiry date')}*`, helperText: meta.touched && tPassenger(meta.error), error: meta.error && meta.touched, onChange: (event) => {
                            input.onChange(event);
                        }, InputProps: {
                            inputComponent: DateMaskedInput
                        } }))) }))),
        onRemove && (React.createElement("div", { className: css.documentFieldsItem__controls },
            React.createElement("div", { className: css.documentFieldsItem__remove, onClick: onRemoveHandler }, isMobile ? (tDocumentCardForm('Remove')) : (React.createElement(ButtonBase, { classes: {
                    root: css.buttonRemove
                } }, RemoveIcon)))))));
};
export default DocumentFieldsItem;
