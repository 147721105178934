import * as React from 'react';
import { useCallback, useState } from 'react';
import cn from 'classnames';
import ErrorBoundary from '../../../../../ErrorBoundary/ErrorBoundary';
import { Button } from '../../../../../index';
import WarningModal from '../../../../../WarningModal';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { useConfig } from '../../../../../context';
import { CheckinFatalError, CheckinUnavailableSeatMapError } from './CheckinErrors';
const CheckinErrorBoundary = props => {
    const { t } = useTranslation('Checkin');
    const { CheckinErrorBoundary: css } = useTheme('Checkin');
    const [checkinError, setCheckinError] = useState(null);
    // const oldEngineURL
    const { global: { locale, reserveEngines } } = useConfig();
    const checkinUrl = (reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin)
        ? `${reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin}?lastName=${props.lastName}&ticketNumber=${props.ticketNumber}&lang=${locale}`
        : null;
    const fallbackRenderer = useCallback(error => {
        if (error instanceof CheckinFatalError) {
            let errorText = t((reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin)
                ? `But don't worry, all of our systems have been duplicated several times. You can register on the backup version of the site.`
                : 'An unexpected error has occurred during the start of the check-in process. Please try again later or contact a customer support service.');
            if (error instanceof CheckinUnavailableSeatMapError) {
                errorText = error.message;
            }
            setCheckinError(errorText);
        }
        else {
            return null;
        }
        return (React.createElement(WarningModal, { className: css.modal, isOpen: !!checkinError, title: t('Oops, something went wrong'), content: React.createElement("div", null,
                React.createElement("div", { dangerouslySetInnerHTML: { __html: checkinError } }),
                React.createElement("div", { className: css.buttons },
                    (reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.siteUrl) && (React.createElement(Button, { className: css.button, onClick: () => window.open(reserveEngines.siteUrl) }, t('Go to home'))),
                    React.createElement(Button, { className: css.button, onClick: () => window.location.reload() }, t('Try again')),
                    checkinUrl && (React.createElement(Button, { className: cn(css.button, css.active), onClick: () => window.open(checkinUrl, '_blank') }, t('Go'))))) }));
    }, [checkinError, setCheckinError]);
    return React.createElement(ErrorBoundary, { renderFallback: fallbackRenderer }, props.children);
};
export default CheckinErrorBoundary;
