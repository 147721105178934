import * as React from 'react';
import MediaQuery from 'react-responsive';
import { TABLET_MIN_WIDTH } from '../utils';
import { DesktopSummaryFlight, MobileSummaryFlight } from '.';
const Flight = ({ flight, price, fareFamily, isOpenByDefault, isReturnFlight, onChange, className, orderId, isWidePrice }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
            React.createElement(DesktopSummaryFlight, { flight: flight, price: price, fareFamily: fareFamily, isOpenByDefault: isOpenByDefault, isReturnFlight: isReturnFlight, onChange: onChange, className: className, orderId: orderId, isWidePrice: isWidePrice })),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement(MobileSummaryFlight, { useFlatMobileSegment: true, flight: flight, price: price, fareFamily: fareFamily, isOpenByDefault: isOpenByDefault, isReturnFlight: isReturnFlight, onChange: onChange, orderId: orderId }))));
};
export default Flight;
