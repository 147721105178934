import { useState } from 'react';
export const useSeatMapToolbarProps = (seatMap) => {
    const [segmentIndex, setSegment] = useState(0);
    const [passengerIndex, setPassenger] = useState(0);
    const onToolbarButtonClick = (callback) => {
        if (segmentIndex + 1 < seatMap.segments.length) {
            setSegment(segmentIndex + 1);
            setPassenger(0);
            return;
        }
        callback();
    };
    return {
        segmentIndex,
        passengerIndex,
        setSegment,
        setPassenger,
        onToolbarButtonClick
    };
};
