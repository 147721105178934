import { reachGoal } from '../analytics';
import { DataLayerEvent } from './types';
const DATA_LAYER_KEY = 'dataLayer';
const EMPTY_DATA_LAYER_EVENT = {
    ecommerce: null
};
export class DataLayerAnalytics {
    constructor(initParams = {}) {
        this.commonParams = {};
        this.setCommonParams = (params) => {
            this.commonParams = Object.assign(Object.assign({}, this.commonParams), params);
        };
        this.initParams = initParams;
        if (process.env.NODE_ENV === 'development') {
            this.debugMode = true;
        }
    }
    static debugEvent(eventName, event) {
        console.log(`DATA_LAYER: ${eventName}`, event);
    }
    static createInstance() {
        return new DataLayerAnalytics();
    }
    sendDataLayerEvent(event) {
        window[DATA_LAYER_KEY] = window[DATA_LAYER_KEY] || [];
        window[DATA_LAYER_KEY].push(event);
        if (this.debugMode) {
            DataLayerAnalytics.debugEvent((event === null || event === void 0 ? void 0 : event.event) || 'Empty', event);
        }
    }
    pushEmptyToDataLayer() {
        this.sendDataLayerEvent(EMPTY_DATA_LAYER_EVENT);
    }
    sendVisitParameters(event) {
        const visitParameters = {
            main: {
                [window.location.hostname]: {
                    [event.event]: {
                        ecommerce: {}
                    }
                }
            },
            extra: {
                ecommerce: JSON.stringify(event.ecommerce)
            }
        };
        reachGoal(event.event, visitParameters);
    }
    pushToDataLayer(event, ecommerceItems = null, params = {}, itemsListName) {
        var _a, _b;
        const eventObj = {
            event,
            ecommerce: Object.assign({ item_list_name: itemsListName, coupon: (_a = this.initParams.coupon) !== null && _a !== void 0 ? _a : null, currency: (_b = this.initParams.currency) !== null && _b !== void 0 ? _b : null, items: ecommerceItems.map(item => (Object.assign(Object.assign({}, this.commonParams), item))) }, params)
        };
        this.addValueToEvent(eventObj);
        this.pushEmptyToDataLayer();
        this.sendDataLayerEvent(eventObj);
        this.sendVisitParameters(eventObj);
    }
    init(params) {
        this.initParams = params;
    }
    addValueToEvent(eventObj) {
        var _a;
        switch (eventObj.event) {
            case DataLayerEvent.AddToCart:
            case DataLayerEvent.ViewItem:
            case DataLayerEvent.Checkout:
            case DataLayerEvent.Purchase:
            case DataLayerEvent.RemoveFromCart:
                if (!((_a = eventObj === null || eventObj === void 0 ? void 0 : eventObj.ecommerce) === null || _a === void 0 ? void 0 : _a.items)) {
                    return eventObj;
                }
                let value = 0;
                for (const item of eventObj.ecommerce.items) {
                    if (item.price && item.quantity) {
                        value += item.price * item.quantity;
                    }
                }
                eventObj.ecommerce.value = value;
                return eventObj;
            default:
                return eventObj;
        }
    }
}
const instance = DataLayerAnalytics.createInstance();
export default instance;
