import { splitMoney } from '../Results/utils';
export const getFormattedAmount = (money, multiplyBy = 1, roundType, splitMoneyMin = 10000) => {
    let amount = money === null || money === void 0 ? void 0 : money.amount;
    if (amount === 0) {
        return '0';
    }
    if (!amount) {
        return '';
    }
    switch (roundType) {
        case 'ceil':
            amount = Math.ceil(amount);
            break;
        case 'floor':
            amount = Math.floor(amount);
            break;
        case 'round':
            amount = Math.round(amount);
            break;
    }
    amount *= multiplyBy;
    return splitMoney(amount, 2, splitMoneyMin).join(' ');
};
