import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import CheckoutAdditionalService from '../..';
import { useTheme, ThemeProvider } from '../../../theme';
import Modal from '../../../Modal';
import { getCapsuleHotel, getSegments } from '../../../Checkout/store/order/selectors';
import { getCalculatedCapsuleHotelPrice } from '../../../Checkout/store/orderPrice/selectors';
import { useOrderService } from '../hooks';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import VipServiceSelect from '../../../VipServiceSelect';
import Close from '../Close/Close';
import { useIsCanModifyServices } from '../../hooks';
const CapsuleHotel = () => {
    const { CapsuleHotel: theme } = useTheme('CheckoutAdditionalService');
    const { t } = useTranslation('Checkout');
    const segments = useSelector(getSegments);
    const capsuleHotelServices = useSelector(getCapsuleHotel);
    const price = useSelector(getCalculatedCapsuleHotelPrice);
    // TODO: refactor this when php sends pictures
    const slides = [
        'https://icdn.lenta.ru/images/2019/12/29/16/20191229162041144/pwa_list_rect_1280_35b06e2bd3403fb2d4748e36b1e25189.png',
        'https://icdn.lenta.ru/images/2019/12/29/16/20191229162041144/pwa_list_rect_1280_35b06e2bd3403fb2d4748e36b1e25189.png',
        'https://icdn.lenta.ru/images/2019/12/29/16/20191229162041144/pwa_list_rect_1280_35b06e2bd3403fb2d4748e36b1e25189.png'
    ];
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const { isSelected, selectedServices, onOpen, onClose, setOpened, onSaveHandler, passengers, opened, renderPassenger } = useOrderService(capsuleHotelServices, OrderAdditionalServiceGdsServiceServiceType.Vip);
    const checkoutAdditionalServiceTheme = React.useMemo(() => ({
        CheckoutAdditionalService: {
            CheckoutAdditionalService: {
                main: theme.main
            }
        }
    }), []);
    const isCanModifyServices = useIsCanModifyServices(capsuleHotelServices);
    if (capsuleHotelServices.length === 0) {
        return null;
    }
    const sliderOptions = {
        className: theme.slider,
        dotsClass: theme.dots,
        slidesToShow: 1,
        speed: 600,
        arrows: false,
        infinite: false,
        dots: true
    };
    const renderClose = (onClick) => React.createElement(Close, { onClick: onClick });
    return (React.createElement(React.Fragment, null,
        React.createElement(ThemeProvider, { value: checkoutAdditionalServiceTheme },
            React.createElement(CheckoutAdditionalService, { header: t('Capsule hotel'), description: capsuleHotelServices[0].description, className: theme.capsuleHotel, priceFrom: price, priceTotal: price, isSelected: isSelected, addClick: onOpen, descriptionClassName: theme.descriptions, buttons: [
                    {
                        action: () => setOpened(true),
                        text: isCanModifyServices ? t('Change') : t('More details')
                    }
                ], addButtonText: !isCanModifyServices ? t('More details') : null }, capsuleHotelServices[0].description)),
        React.createElement(Modal, { classes: {
                root: theme.modal_root,
                paper: theme.paper,
                scrollBody: theme.modal_scrollBody
            }, maxWidth: "md", slideUp: isMobile, open: opened, closeButtonRenderer: renderClose, onClose: onClose },
            React.createElement(VipServiceSelect, { header: t('Capsule hotel'), headerClassName: theme.header, subheader: React.createElement(React.Fragment, null,
                    slides.length > 0 && (React.createElement(Slider, Object.assign({}, sliderOptions), slides.map((image, index) => (React.createElement("div", { className: theme.slide, key: index },
                        React.createElement("img", { src: image, alt: "" })))))),
                    capsuleHotelServices[0].description), passengers: passengers, services: capsuleHotelServices, segments: segments, selectedServices: selectedServices, type: "checkbox", onSave: onSaveHandler, onClose: onClose, renderPassenger: renderPassenger, readOnly: !isCanModifyServices, showTotalPrice: true, showFromPrice: true }))));
};
export default CapsuleHotel;
