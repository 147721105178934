import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
const Pagination = props => {
    const { Pagination: theme } = useTheme('Pagination');
    const renderItem = useCallback((item, index) => {
        if (props.renderItem) {
            return props.renderItem(index, props.currentIndex === index, props.onChange);
        }
        else {
            return (React.createElement("div", { onClick: () => (props.onChange ? props.onChange(index) : null), "data-active": props.currentIndex === index, className: cn(theme.item, { [theme.item_active]: props.currentIndex === index }), key: index }));
        }
    }, [props.itemsCount, props.currentIndex, props.renderItem]);
    return (React.createElement("div", { className: cn(theme.pagination, props.className) }, new Array(props.itemsCount).fill(null).map(renderItem)));
};
export default Pagination;
