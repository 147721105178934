import { __awaiter } from "tslib";
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTheme } from '../../../theme';
import { CommunicationPageWrapper } from '../CommunicationPageWrapper/CommunicationPageWrapper';
import { PromotionCard } from './PromotionCard/PromotionCard';
import Loader from '../../../Loader';
import InfiniteScroll from 'react-infinite-scroller';
import { useGetPaxPromotionTeasersQuery } from '@websky/graphql';
import { useCommunicationList } from '../../hooks';
import { PaximizerCommunicationSectionEnum } from '@websky/graphql';
export const PromotionsPage = () => {
    const { Communication: css } = useTheme('Communications');
    const { path } = useRouteMatch();
    const { loading, refetch } = useGetPaxPromotionTeasersQuery({
        skip: true
    });
    const loadPromotions = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { data } = yield refetch({
            offset: values.offset,
            count: values.count
        });
        const notification = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationPromotionTeasers;
        return {
            total: (notification === null || notification === void 0 ? void 0 : notification.total) || 0,
            items: ((_a = notification === null || notification === void 0 ? void 0 : notification.items) === null || _a === void 0 ? void 0 : _a.length) ? notification.items : []
        };
    });
    const { readIds, markAsRead, items: promoCodes, pagination, loadMore, errorMessage } = useCommunicationList(PaximizerCommunicationSectionEnum.promotions, loadPromotions, loading);
    return (React.createElement(CommunicationPageWrapper, { type: PaximizerCommunicationSectionEnum.promotions, pagination: { total: pagination.total, hasMore: pagination.hasMore }, errorMessage: errorMessage },
        React.createElement(InfiniteScroll, { loadMore: loadMore, hasMore: pagination.hasMore, loader: React.createElement(Loader, { key: "promotion-loader" }), pageStart: pagination.pageLoaded },
            React.createElement("div", { className: css.list }, promoCodes.map(item => (React.createElement(Link, { key: item.id, to: `${path}/${item.id}` },
                React.createElement(PromotionCard, { title: item.title, date: item.date, isRead: item.read || readIds[item.id], preview: item.image, desc: item.text, markAsRead: () => markAsRead(item.id) }))))))));
};
