import * as React from 'react';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
import Amenities from '../../Amenities';
import { initI18n } from '../../utils';
import { Time } from '../../Icons';
import AircraftPopup from '../../AircraftPopup';
import { useIataCode } from '../../hooks';
import ImageWithFallback from '../../ImageWithFallback';
import { useRenderers } from '../../renderProps';
initI18n('FlightCard');
const FlightCard = ({ airline, flightNumber, duration, aircraft, amenities, statistics, className, markers, isReturnFlight }) => {
    const { FlightCard: css } = useTheme('FlightCard');
    const hasAmenities = (amenities === null || amenities === void 0 ? void 0 : amenities.length) > 0;
    const iataCode = useIataCode();
    const hideAmenitiesIfAllUnable = ['WZ', 'ZF', 'RM', 'A9']; // в темах этих ак внутри Amenities не отображем элементы с available: false
    const hasAvailableAmenity = (amenities === null || amenities === void 0 ? void 0 : amenities.some(amenity => amenity.available)) || (markers === null || markers === void 0 ? void 0 : markers.eco);
    const hideAmenities = !hasAmenities || (!hasAvailableAmenity && hideAmenitiesIfAllUnable.includes(iataCode));
    const renderButton = useCallback((onClick) => (React.createElement("div", { className: cn(css.aircraft__name, {
            [css.aircraft__name_active]: !!(aircraft === null || aircraft === void 0 ? void 0 : aircraft.id),
            [css.aircraft__name_withNoAmenities]: hideAmenities
        }), onClick: (aircraft === null || aircraft === void 0 ? void 0 : aircraft.id) ? onClick : null },
        React.createElement("span", { className: css.aircraft__nameTitle }, aircraft === null || aircraft === void 0 ? void 0 : aircraft.name))), [aircraft, statistics, hideAmenities]);
    const { renderFLightCardCarrierName } = useRenderers();
    const renderAirlineName = useMemo(() => {
        if (renderFLightCardCarrierName) {
            return renderFLightCardCarrierName({ airline, aircraft, flightNumber, duration });
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement("span", { className: css.airline__number }, flightNumber),
                React.createElement("span", { className: css.airline__name }, airline.name)));
        }
    }, [airline.name]);
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.airline }, renderAirlineName),
            React.createElement("div", { className: css.flight },
                Time,
                duration)),
        React.createElement("div", { className: cn(css.aircraft, { [css.aircraft_return]: isReturnFlight }) },
            React.createElement(ImageWithFallback, { className: css.aircraft__image, src: aircraft === null || aircraft === void 0 ? void 0 : aircraft.icon, fallbackSrc: 'https://cdn.websky.aero/content/default-content/images/Aircrafts/738/738.png', alt: aircraft === null || aircraft === void 0 ? void 0 : aircraft.name })),
        React.createElement("div", { className: css.footer },
            React.createElement(AircraftPopup, { id: aircraft === null || aircraft === void 0 ? void 0 : aircraft.id, operatingIata: airline.iata, name: aircraft === null || aircraft === void 0 ? void 0 : aircraft.name, renderButton: renderButton }),
            !hideAmenities && React.createElement(Amenities, { className: css.amenities, amenities: amenities, isEco: markers === null || markers === void 0 ? void 0 : markers.eco }))));
};
export default FlightCard;
