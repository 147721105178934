import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DocTypes, ExareReason, ExareReasonDocs, TravellerFieldEnum, useChangeTravelerDataInfoQuery, useChangeTravellerDataPriceQuery } from '@websky/graphql';
import { getOrder } from '../../../Modules/Exchange/store/order/selectors';
import { getUserValue } from '../../../utils';
import { formatInvalidPassengerDate } from '../../../Passenger/utils';
import { isDateFieldType } from '../../../PassengerForm/utils';
export const usePassengerForm = (passenger, onFillOrder, reason) => {
    const order = useSelector(getOrder);
    const [requestError, setRequestError] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const { data, loading: isTravellerDataLoading } = useChangeTravelerDataInfoQuery({
        fetchPolicy: 'no-cache',
        variables: {
            parameters: {
                orderId: order.id,
                travellerId: passenger.id,
                reason
            }
        }
    });
    const { refetch: changeTravellerDataPrice } = useChangeTravellerDataPriceQuery({ skip: true });
    const editableFields = useMemo(() => {
        var _a, _b, _c, _d;
        const editableFieldsMap = new Map();
        if ((_b = (_a = data === null || data === void 0 ? void 0 : data.ChangeTravellerDataInfo) === null || _a === void 0 ? void 0 : _a.travellers) === null || _b === void 0 ? void 0 : _b.length) {
            const changeTravellerById = (_d = (_c = data === null || data === void 0 ? void 0 : data.ChangeTravellerDataInfo) === null || _c === void 0 ? void 0 : _c.travellers) === null || _d === void 0 ? void 0 : _d.find(traveller => traveller.id === passenger.id);
            if (changeTravellerById) {
                changeTravellerById.values.forEach(value => {
                    if (isDateFieldType(value.type)) {
                        editableFieldsMap.set(value.type, Object.assign(Object.assign({}, value), { value: formatInvalidPassengerDate(value.type, value.value) }));
                    }
                    else {
                        editableFieldsMap.set(value.type, value);
                    }
                });
            }
        }
        return editableFieldsMap;
    }, [data === null || data === void 0 ? void 0 : data.ChangeTravellerDataInfo, passenger]);
    const editablePassenger = useMemo(() => {
        const values = passenger.values.map(value => {
            const editableField = editableFields.get(value.type);
            return Object.assign(Object.assign({}, value), { validationRules: (editableField === null || editableField === void 0 ? void 0 : editableField.validationRules) || value.validationRules, isEditable: editableFields.has(value.type) });
        });
        return Object.assign(Object.assign({}, passenger), { values });
    }, [passenger, editableFields]);
    const allowSwapNames = useMemo(() => {
        var _a, _b;
        const firstNameEditable = (_a = editablePassenger.values.find(value => value.type === TravellerFieldEnum.FirstName)) === null || _a === void 0 ? void 0 : _a.isEditable;
        const lastNameEditable = (_b = editablePassenger.values.find(value => value.type === TravellerFieldEnum.LastName)) === null || _b === void 0 ? void 0 : _b.isEditable;
        return firstNameEditable && lastNameEditable;
    }, [editablePassenger]);
    const onRequestError = useCallback((e) => {
        const error = e;
        const graphQLErrors = error.graphQLErrors;
        if (graphQLErrors === null || graphQLErrors === void 0 ? void 0 : graphQLErrors.length) {
            setRequestError(graphQLErrors[0]);
        }
    }, []);
    const onClearError = useCallback(() => setRequestError(null), []);
    const onSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        setIsLoading(true);
        try {
            const valuesState = values;
            const valuesPassenger = valuesState.passengers[0];
            const passengerFiles = ((_a = valuesPassenger === null || valuesPassenger === void 0 ? void 0 : valuesPassenger.files) === null || _a === void 0 ? void 0 : _a.changeFiles) || [];
            const changedDocType = getUserValue(editablePassenger, TravellerFieldEnum.DocType);
            const isPassport = changedDocType === DocTypes.Passport;
            const isAutoMode = !!((_d = (_c = (_b = order.exareInfo) === null || _b === void 0 ? void 0 : _b.changeTravellerDataAvailability) === null || _c === void 0 ? void 0 : _c.find(traveller => traveller.id === editablePassenger.id)) === null || _d === void 0 ? void 0 : _d.autoMode);
            const travellers = [
                {
                    id: editablePassenger.id,
                    values: editablePassenger.values
                        .filter(value => value.isEditable)
                        .map(value => {
                        let actualValue = valuesPassenger[value.name];
                        // workaround to prevent the document expiration date from changing when switching the document type select
                        if (value.type === TravellerFieldEnum.DocExpiryDate && isPassport && !actualValue) {
                            actualValue = getUserValue(editablePassenger, TravellerFieldEnum.DocExpiryDate);
                        }
                        return {
                            name: value.name,
                            type: value.type,
                            value: actualValue
                        };
                    }),
                    files: passengerFiles.map(file => ({
                        content: file.content,
                        originalFileName: file.name,
                        type: ExareReasonDocs.Other
                    })),
                    isCompelled: reason === ExareReason.DocumentReplacement,
                    reason
                }
            ];
            if (isAutoMode) {
                const { data } = yield changeTravellerDataPrice({
                    parameters: {
                        orderId: order.id,
                        travellers: travellers.map(traveller => ({
                            id: traveller.id,
                            values: traveller.values,
                            isCompelled: traveller.isCompelled
                        }))
                    }
                });
                onFillOrder(travellers, data.ChangeTravellerDataPrice);
            }
            else {
                onFillOrder(travellers);
            }
        }
        catch (e) {
            onRequestError(e);
        }
        setIsLoading(true);
    }), [order, editablePassenger, onFillOrder, onRequestError, changeTravellerDataPrice]);
    return {
        // data
        editablePassenger,
        requestError,
        isLoading,
        isTravellerDataLoading,
        allowSwapNames,
        // methods
        onSubmit,
        onClearError
    };
};
