import * as React from 'react';
import RequestFormComponent from '../RequestForm/RequestForm';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder } from '../../../store/order/selectors';
import { useRefuseExchangeMutation } from '@websky/graphql';
import { setLastExchange } from '../../../store/order/actions';
const RequestForm = ({ goToNewRequest, goToPayment, goToOrder }) => {
    const dispatch = useDispatch();
    const order = useSelector(getOrder);
    const [deleteMutation] = useRefuseExchangeMutation();
    const deleteRequest = (order, redirectToOrder) => {
        return deleteMutation({
            variables: {
                id: order.id
            }
        }).then(data => {
            var _a;
            if ((_a = data.data) === null || _a === void 0 ? void 0 : _a.RefuseExare) {
                dispatch(setLastExchange(data.data.RefuseExare));
                if (redirectToOrder) {
                    goToOrder();
                }
                return true;
            }
            return false;
        });
    };
    return (React.createElement(RequestFormComponent, { order: order.exareInfo.lastExchange, deleteRequest: deleteRequest, goToNewExchange: goToNewRequest, goToPayment: goToPayment }));
};
export default RequestForm;
