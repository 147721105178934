import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SAVE_SERVICES, saveServices, SET_SERVICES_WITH_SAVE, setServices } from './actions';
import { getSelectedServices } from './selectors';
import { CheckinSaveOrderServicesDocument } from '@websky/graphql';
import { createClient } from '../../../../graphql-client';
import { getBaggageServices, getCheckinOrderId } from '../order/selectors';
import { fillCheckinOrder } from '../order/actions';
import { createServicesForRequest } from '../../utils';
import { setLoadingOff, setLoadingOn } from '../servicesLoading/actions';
function runRequest(services) {
    const client = createClient();
    return client.mutate({
        mutation: CheckinSaveOrderServicesDocument,
        variables: {
            params: services
        }
    });
}
function* setServicesWithSave({ payload }) {
    yield put(setServices(payload));
    yield put(saveServices());
}
function* saveServicesWorker(action) {
    var _a, _b;
    const orderId = yield select(getCheckinOrderId);
    const selectedServices = yield select(getSelectedServices);
    const baggageServices = yield select(getBaggageServices);
    yield put(setLoadingOn());
    const { data, errors } = yield call(runRequest, {
        id: orderId,
        services: createServicesForRequest(selectedServices, baggageServices)
    });
    if (errors) {
        yield put(setLoadingOff());
        throw errors;
    }
    else if (data === null || data === void 0 ? void 0 : data.CheckinSaveOrderServices) {
        yield put(fillCheckinOrder(data.CheckinSaveOrderServices));
        yield put(setLoadingOff());
        (_b = (_a = action.meta) === null || _a === void 0 ? void 0 : _a.resolve) === null || _b === void 0 ? void 0 : _b.call(_a, data.CheckinSaveOrderServices);
    }
}
export function* saveServicesSaga() {
    yield takeLatest(SET_SERVICES_WITH_SAVE, setServicesWithSave);
    yield takeLatest(SAVE_SERVICES, saveServicesWorker);
}
