import * as React from 'react';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
import { Slide } from '@material-ui/core';
const FullscreenPopup = props => {
    const theme = useTheme('PassengerForm').FullscreenPopup;
    React.useEffect(() => {
        window.document.body.style.overflow = props.open ? 'hidden' : 'auto';
    }, [props.open]);
    return (React.createElement(Slide, { direction: "up", in: props.open },
        React.createElement("div", { className: cn(theme.popup, { [theme.visible]: props.open }) }, props.children)));
};
export default FullscreenPopup;
