import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../../theme';
import CheckoutAdditionalService from '../../../../CheckoutAdditionalService';
import ServiceSummaryOption from '../../../../ServiceSummary/components/ServiceSummaryOption';
import Money from '../../../../Money';
const InsuranceRefundCard = props => {
    const { t } = useTranslation('Checkout');
    const { CommonContent: theme } = useTheme('RefundSummary');
    const { items, totalPrice } = React.useMemo(() => {
        const items = [];
        const totalPrice = {
            currency: props.services[0].totalPrice.currency,
            amount: 0
        };
        props.services.forEach(service => {
            items.push({
                id: service.code,
                name: service.name,
                price: service.totalPrice
            });
            totalPrice.amount += service.totalPrice.amount;
        });
        return { items, totalPrice };
    }, [props.services]);
    return (React.createElement(CheckoutAdditionalService, { header: t('Insurance'), description: t('Transportation of impressions no limitations'), className: cn(theme.serviceCard, theme.serviceCard_insurance), isSelected: true, addClick: () => null },
        React.createElement(ThemeProvider, { value: {
                ServiceSummary: {
                    ServiceSummaryOption: {
                        option__price: theme.price
                    }
                }
            } },
            React.createElement(ServiceSummaryOption, { groupOption: { items } })),
        React.createElement("div", { className: theme.totalRefund },
            t('Refund'),
            ": ",
            React.createElement(Money, { money: totalPrice }))));
};
export default InsuranceRefundCard;
