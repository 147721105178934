import { __rest } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Base from '../Base/Base';
import Table from './Table/Table';
import { Money as MoneyComponent } from '../../../Money';
import { useTheme } from '../../../theme';
const Seats = (_a) => {
    var { rows, onChange, onConfirm } = _a, props = __rest(_a, ["rows", "onChange", "onConfirm"]);
    const { t } = useTranslation('ServiceRecommendation');
    const { Seats: css } = useTheme('ServiceRecommendation');
    const totalPrice = rows.reduce((result, row) => (Object.assign(Object.assign({}, (result ? result : row.price)), { amount: result ? result.amount + row.price.amount : row.price.amount })), null);
    return (React.createElement(Base, Object.assign({ onConfirm: onConfirm, onChange: onChange }, props),
        React.createElement("div", { className: cn(css.header, css.header_mobileFullScreen) },
            React.createElement("h1", null, t('Seats recommendation'))),
        React.createElement("div", { className: css.text }, t('To save your time, we have selected for you the best available seats on the plane.')),
        React.createElement("div", { className: css.body }, rows.length > 1 ? (React.createElement(Table, { rows: rows, totalPrice: totalPrice })) : (React.createElement("div", { className: css.summary },
            React.createElement("div", { className: css.summary__seats }, !!rows.length && rows[0].seats),
            React.createElement("div", { className: css.summary__price }, totalPrice.amount ? (React.createElement(React.Fragment, null,
                t('Total'),
                ":",
                React.createElement(MoneyComponent, { money: totalPrice }))) : (t('FREE'))))))));
};
export default Seats;
