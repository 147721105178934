import * as React from 'react';
import { ActionButton } from '../../../../Button';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
const SubmitButton = ({ loading, children }) => {
    const { SubmitButton: css } = useTheme('LoginPage');
    return (React.createElement(ActionButton, { disabled: loading, type: 'submit', className: cn(css.root, {
            [css.loading]: loading
        }) },
        React.createElement("span", { className: css.content }, children)));
};
export default SubmitButton;
