import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AeroexpressDirection } from '@websky/graphql';
import { CartService } from '../../Cart/types';
import CartItemOptionAdapter from '../../Cart/CartItemOptionAdapter/CartItemOptionAdapter';
import ServiceSummaryDivider from '../components/ServiceSummaryDivider/ServiceSummaryDivider';
import { getAeroexpressTripLabel } from '../../CheckoutAdditionalService/utils';
const AeroexpressSummary = props => {
    const { t } = useTranslation('Checkout');
    const translateDirection = (selectedTrip) => {
        let direction;
        const fromDirection = selectedTrip.fromStation.direction;
        if (fromDirection === AeroexpressDirection.FromAirport) {
            direction = [t(`Aeroexpress:${fromDirection}`), t(`Aeroexpress:${selectedTrip.toStation.name}`)];
        }
        else if (fromDirection === AeroexpressDirection.ToAirport) {
            direction = [t(`Aeroexpress:${selectedTrip.fromStation.name}`), t(`Aeroexpress:${fromDirection}`)];
        }
        else {
            direction = [t(`Aeroexpress:${selectedTrip.fromStation.name}`)];
        }
        return direction.join(' – ');
    };
    const onDeleteHandler = (tripId) => {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Aeroexpress, { tripId });
    };
    const items = React.useMemo(() => {
        return props.selectedTrips.map(trip => {
            console.log(trip);
            return {
                header: t('All passengers'),
                items: [
                    {
                        id: trip.id,
                        name: `${getAeroexpressTripLabel(trip)} ${translateDirection(trip)}, ${t(`Checkout:Class_${trip.class}`)}`,
                        price: trip.price,
                        onDelete: () => onDeleteHandler(trip.id)
                    }
                ]
            };
        });
    }, [props.travellers, props.selectedTrips]);
    const content = React.useMemo(() => {
        if (!items.length) {
            return null;
        }
        return (React.createElement(ServiceSummaryDivider, null, items.map((item, index) => (React.createElement(CartItemOptionAdapter, { option: item, key: index })))));
    }, [items]);
    return props.children(content);
};
export default AeroexpressSummary;
