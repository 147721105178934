import { __awaiter } from "tslib";
import * as React from 'react';
import LoyaltyConnect from '../../../../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import { TravellerFieldEnum, useGetAccountInfoQuery } from '@websky/graphql';
import { getUserValue } from '../../../../../../utils';
import { useConfigRedirects } from '../../../../../../hooks';
const LoyaltyCard = props => {
    const { onGoToAccountSettings } = useConfigRedirects();
    const [isLoyaltyConnectOpen, setLoyaltyConnectOpen] = React.useState(false);
    const { data, refetch } = useGetAccountInfoQuery({ fetchPolicy: 'no-cache' });
    const userInfo = React.useMemo(() => {
        var _a;
        if (!(data === null || data === void 0 ? void 0 : data.CurrentUser) || !((_a = data.CurrentUser) === null || _a === void 0 ? void 0 : _a.userProfile)) {
            return {
                lastName: '',
                firstName: '',
                email: ''
            };
        }
        return {
            lastName: getUserValue(data.CurrentUser.userProfile, TravellerFieldEnum.LastName),
            firstName: getUserValue(data.CurrentUser.userProfile, TravellerFieldEnum.FirstName),
            email: getUserValue(data.CurrentUser.userProfile, TravellerFieldEnum.Email)
        };
    }, [data === null || data === void 0 ? void 0 : data.CurrentUser]);
    const onLoyaltyConnectCloseHandler = () => {
        props.setLoyalty('');
        setLoyaltyConnectOpen(false);
    };
    const onLoyaltyConnectCardSavedHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setLoyaltyConnectOpen(false);
        try {
            const { data } = yield refetch();
            if ((_a = data === null || data === void 0 ? void 0 : data.CurrentUser) === null || _a === void 0 ? void 0 : _a.userFfpInfo) {
                props.setLoyalty(data.CurrentUser.userFfpInfo.cardNumber, true);
            }
        }
        catch (e) {
            console.log(e);
        }
    });
    const requestLoyaltyCard = () => {
        var _a, _b;
        if (((_a = data === null || data === void 0 ? void 0 : data.CurrentUser) === null || _a === void 0 ? void 0 : _a.userFfpInfo) && !((_b = data === null || data === void 0 ? void 0 : data.CurrentUser) === null || _b === void 0 ? void 0 : _b.isFfpAuthorizationNeeded)) {
            props.setLoyalty(data.CurrentUser.userFfpInfo.cardNumber);
        }
        else {
            setLoyaltyConnectOpen(true);
        }
    };
    return (React.createElement(React.Fragment, null,
        props.children(requestLoyaltyCard),
        React.createElement(LoyaltyConnect, { userInfo: userInfo, isOpen: isLoyaltyConnectOpen, onLoyaltyCardSaved: onLoyaltyConnectCardSavedHandler, onClose: onLoyaltyConnectCloseHandler, onGoToSettings: onGoToAccountSettings, showRegisterAction: !!(data === null || data === void 0 ? void 0 : data.CurrentUser), authType: "authenticate" })));
};
export default LoyaltyCard;
