import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import SummaryHeader from './SummaryHeader';
const SummaryItem = ({ className, price, header, children }) => {
    const theme = useTheme('PaymentForm').Summary;
    return (React.createElement("div", { className: theme.item },
        header && React.createElement(SummaryHeader, null, header),
        React.createElement("div", { className: cn(theme.item__content, className) },
            React.createElement("div", null, children),
            price)));
};
export default SummaryItem;
