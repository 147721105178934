import * as React from 'react';
import { useTheme } from '../../../../../theme';
const DummyPriceGraph = () => {
    const theme = useTheme('Results').DummyPriceGraph;
    return (React.createElement("div", { className: theme.container },
        React.createElement("div", { className: theme.header }),
        React.createElement("div", { className: theme.calendar },
            React.createElement("div", { className: theme.daysWrapper },
                React.createElement("div", { className: theme.legends },
                    React.createElement("div", { className: theme.line },
                        React.createElement("div", { className: theme.label }))),
                React.createElement("div", { className: theme.days }, [...Array(32).keys()].map(i => (React.createElement("div", { className: theme.day, key: i },
                    React.createElement("div", { className: theme.bar },
                        React.createElement("div", { className: theme.progress })),
                    React.createElement("div", { className: theme.day__header })))))))));
};
export default DummyPriceGraph;
