import { getTravellers } from '../Checkout/store/order/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
export const useIsCanModifyServices = (services) => {
    const travellers = useSelector(getTravellers);
    return useMemo(() => services.some(service => {
        const canBeRemoved = service.allowedPassengers.some(ids => ids.some(passengerId => {
            var _a, _b, _c, _d, _e;
            const passengerServices = (_a = travellers.find(traveller => traveller.id === passengerId)) === null || _a === void 0 ? void 0 : _a.services;
            if (passengerServices) {
                const gdsService = (_c = (_b = passengerServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(srv => srv.serviceId === service.id);
                if (gdsService) {
                    return gdsService.count > gdsService.confirmedCount;
                }
                const brandIncludedService = (_e = (_d = passengerServices.brandIncludedServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.find(srv => srv.serviceId === service.id);
                if (brandIncludedService) {
                    return gdsService.count > gdsService.confirmedCount;
                }
            }
            return false;
        }));
        return canBeRemoved || service.canBeAdded;
    }), [services, travellers]);
};
