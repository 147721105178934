import { put, select, takeEvery } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import { ANALYZE_PURCHASEd_PRODUCTS, FILL_ORDER, setOrder } from './actions';
import { setBreakdownPrices } from '../priceBreakdown/action';
import { setPassengersState } from '../passengers/actions';
import { checkoutSelectedServicesStateFactory, parsePriceBreakdown } from '../../utils';
import { getOrder } from './selectors';
import { ProductType } from '../../../analytics';
import { getAeroexpressProductId } from '../../../Aeroexpress/utils';
import { setOrderPrice } from '../orderPrice/actions';
import { setAllSelectedServices } from '../selectedServices/actions';
import getOrderDataLayer from '../../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../../DataLayerAnalytics/OrderDataLayer/types';
import { setAllEveryPortAction } from '../everyPortServices/actions';
import { setEveryTravelCartIdAction } from '../everyTravel/actions';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
const getEveryPortState = (selectedServices) => {
    const servicesByCategory = {};
    selectedServices.forEach(service => {
        var _a;
        const selected = {
            product: service.product,
            additionalServices: service.additionalService,
            fare: service.fare,
            travellerIds: service.travellers.map(traveller => traveller.id),
            status: service.status,
            orders: service.orders
        };
        servicesByCategory[service.product.category] = [
            ...((_a = servicesByCategory[service.product.category]) !== null && _a !== void 0 ? _a : []),
            selected
        ];
    });
    return servicesByCategory;
};
/**
 * @deprecated Should use DataLayerAnalytics
 */
function* analyzePurchasedProducts() {
    var _a, _b, _c, _d, _e, _f, _g;
    const order = yield select(getOrder);
    const products = [];
    (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances) === null || _b === void 0 ? void 0 : _b.selectedInsurances) === null || _c === void 0 ? void 0 : _c.forEach(({ insuranceProgram }) => {
        products.push({
            id: insuranceProgram.code,
            name: insuranceProgram.name,
            category: ProductType.Insurance,
            price: insuranceProgram === null || insuranceProgram === void 0 ? void 0 : insuranceProgram.totalPrice.amount
        });
    });
    (_g = (_f = (_e = (_d = order.additionalServices) === null || _d === void 0 ? void 0 : _d.transfer) === null || _e === void 0 ? void 0 : _e.aeroexpress) === null || _f === void 0 ? void 0 : _f.selectedTrips) === null || _g === void 0 ? void 0 : _g.forEach(trip => {
        products.push({
            id: getAeroexpressProductId(trip),
            category: ProductType.Transfer
        });
    });
    order.travellers.forEach(({ id: travellerId, services }) => {
        var _a, _b, _c;
        (_b = (_a = services.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.forEach(({ serviceId, count }) => {
            var _a, _b;
            const service = (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(({ id }) => id === serviceId);
            if (service) {
                if (service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage) {
                    const baggage = service;
                    products.push({
                        id: baggage.rfisc,
                        category: ProductType.Baggage,
                        name: baggage.name,
                        quantity: count,
                        variant: baggage.baggageWeight,
                        price: baggage.price.amount
                    });
                }
                if (service.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
                    const meal = service;
                    products.push({
                        id: meal.rfisc,
                        category: ProductType.Meal,
                        name: meal.name,
                        quantity: count,
                        price: meal.price.amount
                    });
                }
            }
        });
        (_c = services.seats) === null || _c === void 0 ? void 0 : _c.forEach(({ seat }) => {
            var _a, _b;
            if (!seat.seatService) {
                console.warn(`seat without service passenger ${travellerId}`);
                products.push({
                    id: null,
                    name: null,
                    category: ProductType.Seat,
                    variant: seat.number,
                    price: seat.price ? seat.price.amount : 0
                });
                return;
            }
            const service = (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(({ id }) => id === seat.seatService.id);
            products.push({
                id: service.rfisc,
                name: service.name,
                category: ProductType.Seat,
                variant: seat.number,
                price: seat.price ? seat.price.amount : 0
            });
        });
    });
}
function* fillOrder({ payload: order }) {
    var _a, _b, _c, _d, _e;
    const actions = [];
    if (order.travellers.length) {
        const passengers = order.travellers.map(passenger => ({
            id: passenger.id,
            type: passenger.type,
            values: passenger.values,
            supplierTravellerType: passenger.supplierTravellerType,
            linkedTraveller: passenger.linkedTraveller,
            seatRestrictions: passenger.seatRestrictions
        }));
        actions.push(setPassengersState(passengers));
        const selectedServices = checkoutSelectedServicesStateFactory(order);
        actions.push(setAllSelectedServices(selectedServices));
    }
    if ((order === null || order === void 0 ? void 0 : order.price) || (order === null || order === void 0 ? void 0 : order.priceToPay)) {
        const orderPrice = {
            priceToPay: Object.assign({}, order.priceToPay),
            price: Object.assign({}, order.price)
        };
        actions.push(setOrderPrice(orderPrice));
    }
    if ((_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.nas) === null || _b === void 0 ? void 0 : _b.selectedServices) {
        actions.push(setAllEveryPortAction(getEveryPortState(order.additionalServices.nas.selectedServices)));
    }
    if (order.priceBreakdown) {
        actions.push(setBreakdownPrices(parsePriceBreakdown(order.priceBreakdown)));
    }
    actions.push(setEveryTravelCartIdAction({
        cartId: (_e = (_d = (_c = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _c === void 0 ? void 0 : _c.everyTravel) === null || _d === void 0 ? void 0 : _d.initializeParameters) === null || _e === void 0 ? void 0 : _e.cartId
    }));
    getOrderDataLayer(OrderType.Checkout).setOrder(order);
    actions.push(setOrder(order));
    yield put(batchActions(actions));
}
export function* fillOrderSaga() {
    yield takeEvery(FILL_ORDER, fillOrder);
}
/**
 * @deprecated Should use DataLayerAnalytics
 */
export function* analyzePurchasedProductsSaga() {
    yield takeEvery(ANALYZE_PURCHASEd_PRODUCTS, analyzePurchasedProducts);
}
