import * as React from 'react';
import { differenceInDays, parseISO } from 'date-fns';
import cn from 'classnames';
import { isDifferentAirportName } from '../utils';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import { useMobileSegmentInfo } from '../hooks';
import { useTranslation } from 'react-i18next';
import MobileSegmentFullInfo from './MobileSegmentFullInfo/MobileSegmentFullInfo';
initI18n('MobileSegmentInfo');
const MobileSegmentInfo = props => {
    const { MobileSegmentInfo: css } = useTheme('MobileSegmentInfo');
    const { variant, showFullInfo } = props;
    const { t } = useTranslation();
    const { isNextDayArrival, allAirlines, toggleDetails, detailsOpen, transferString, firstSegment, lastSegment, departureTime, arrivalTime, durationTime } = useMobileSegmentInfo(props);
    return (React.createElement("div", { className: cn(css.segment, {
            [css.segment_filled]: variant === 'filled'
        }) },
        React.createElement("div", { className: css.dates },
            React.createElement("div", { className: css.date }, departureTime),
            React.createElement("div", { className: css.timeEnRoute },
                React.createElement("span", { className: css.timeEnRoute_text }, durationTime)),
            React.createElement("div", { className: css.date },
                arrivalTime,
                isNextDayArrival && (React.createElement("div", { className: css.date_nextDayArrival }, `+${differenceInDays(parseISO(lastSegment.arrival.date), parseISO(firstSegment.departure.date))}`)))),
        !showFullInfo && (React.createElement("div", { className: css.short },
            React.createElement("span", null, transferString ? transferString : `${t('MobileSegmentInfo:Nonstop')} • `),
            React.createElement("span", null, allAirlines.join(', ')))),
        showFullInfo && (React.createElement("div", { className: css.airports },
            React.createElement("div", { className: css.airport },
                React.createElement("div", { className: css.airport__city }, firstSegment.departure.airport.city.name),
                isDifferentAirportName(firstSegment.departure.airport) && (React.createElement("div", { className: css.airport__name }, firstSegment.departure.airport.title)),
                firstSegment.departure.terminal && (React.createElement("div", { className: css.airport__terminal },
                    t('MobileSegmentInfo:Terminal'),
                    " ",
                    firstSegment.departure.terminal))),
            React.createElement("div", { className: css.airport },
                React.createElement("div", { className: css.airport__city }, lastSegment.arrival.airport.city.name),
                isDifferentAirportName(lastSegment.arrival.airport) && (React.createElement("div", { className: css.airport__name }, lastSegment.arrival.airport.title)),
                lastSegment.arrival.terminal && (React.createElement("div", { className: css.airport__terminal },
                    t('MobileSegmentInfo:Terminal'),
                    " ",
                    lastSegment.arrival.terminal))))),
        showFullInfo && (React.createElement(MobileSegmentFullInfo, Object.assign({}, props, { detailsOpen: detailsOpen, toggleDetails: toggleDetails })))));
};
export default MobileSegmentInfo;
