import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CartService } from '../../Cart/types';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { Meal } from '../../Icons';
import { getActualProduct } from '../utils';
import CommonSummary from './CommonSummary';
const MealSummary = props => {
    const { t } = useTranslation('Cart');
    const onDeleteHandler = (travellerId, serviceId, segmentId, allSegments) => {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Meal, {
            type: OrderAdditionalServiceGdsServiceServiceType.Meal,
            passengerId: travellerId,
            segmentId,
            serviceId,
            allSegments
        });
    };
    return (React.createElement(CommonSummary, { flight: props.flight, travellers: props.travellers, services: props.services, travellersById: props.travellersById, groupType: OrderAdditionalServiceGdsServiceServiceType.Meal, serviceFilterPredicate: () => true, serviceOptionGroupFactory: ({ items, travellerId }) => ({
            traveller: props.travellersById.get(travellerId),
            headerType: 'travellerName',
            items,
            headerIcon: Meal
        }), serviceOptionItemFactory: ({ selectedService, key, travellerId }) => {
            const segment = props.flight.segments.find(segment => segment.segment.id === key), direction = key === 'allSegments'
                ? t('All flights')
                : `${segment.segment.departure.airport.iata} - ${segment.segment.arrival.airport.iata}`;
            const actualProduct = getActualProduct(selectedService.products);
            return {
                name: `${direction}, ${selectedService.count}x ${selectedService.service.name}`,
                id: selectedService.service.id,
                segmentKey: key,
                included: selectedService.count === selectedService.confirmedCount,
                price: selectedService.service.price,
                status: actualProduct === null || actualProduct === void 0 ? void 0 : actualProduct.status,
                onDelete: () => onDeleteHandler(travellerId, selectedService.serviceId, key, key === 'allSegments')
            };
        }, includedOptionItemFactory: ({ selectedService: included, key }) => {
            const segment = props.flight.segments.find(segment => segment.segment.id === key), direction = key === 'allSegments'
                ? t('All flights')
                : `${segment.segment.departure.airport.iata} - ${segment.segment.arrival.airport.iata}`;
            return {
                id: included.serviceId,
                segment: key,
                count: included.count,
                name: `${direction}, ${included.count}x ${included.service.name}`,
                included: true
            };
        } }, children => props.children(children)));
};
export default MealSummary;
