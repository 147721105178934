import * as React from 'react';
import Inner from './Inner/Inner';
import { setOrder } from '../../store/order/actions';
import { connect } from 'react-redux';
import { initI18n } from '../../../../utils';
import { clearFare } from '../../store/newFlights/actions';
initI18n('Exchange');
const Exchange = props => {
    return (React.createElement(Inner, { orderId: props.orderId, setOrder: props.setOrder, clearNewFlight: props.clearNewFlight, onRefetchCheckoutOrder: props.onRefetchCheckoutOrder }));
};
export default connect(null, {
    setOrder: setOrder,
    clearNewFlight: clearFare
})(Exchange);
