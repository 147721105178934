import * as React from 'react';
import Segment from '../../../DesktopFlightRoute/components/Segment/Segment';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../theme';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { MobileSegmentUpgrade } from '../../../MobileSegmentInfo';
export const SelectableCheckoutSegment = ({ index, highlight, parentFlight, segment, transferInfo, showStopsInfo }) => {
    var _a, _b, _c;
    const { SelectableSegment: css } = useTheme('UpgradeFlightClass');
    const checkoutFlight = parentFlight;
    return (React.createElement(ThemeProvider, { value: {
            DesktopFlightRoute: {
                StopsInfoStyles: {
                    stop: css.stopsInfo__stop,
                    stop__label: css.stopsInfo__label
                },
                RouteArrowStyles: {
                    circle: cn({
                        [css.selected]: highlight
                    })
                }
            }
        } },
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(Segment, { first: index === 0, last: false, className: cn(css.segment, {
                    [css.selected]: highlight,
                    [css.transfer]: checkoutFlight.segments.length > 1,
                    [css.first]: index === 0,
                    [css.last]: index !== 0
                }), segment: {
                    segment,
                    transferDuration: showStopsInfo ? transferInfo : null
                }, stops: ((_a = checkoutFlight.segments[index]) === null || _a === void 0 ? void 0 : _a.stops) || [], singleFlight: checkoutFlight.segments.length === 1, multipleSegments: checkoutFlight.segments.length > 1 })),
        React.createElement(MediaQuery, { maxWidth: 'mobile' },
            React.createElement(MobileSegmentUpgrade, { flightNumberClassName: cn(css.flightNumber, { [css.selected]: highlight }), showFullInfo: true, segments: [
                    {
                        segment,
                        transferDuration: showStopsInfo ? transferInfo : null
                    }
                ], className: cn(css.segment, { [css.selected]: highlight }), stopCount: (_c = (_b = checkoutFlight.segments[index]) === null || _b === void 0 ? void 0 : _b.stops) === null || _c === void 0 ? void 0 : _c.length, multipleSegments: checkoutFlight.segments.length > 1, showAirplaneInfo: true, last: false }))));
};
