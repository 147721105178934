import * as React from 'react';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { OrderStatus, InfoMessageTypeEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import { getAdultAccompaniedMap, isTravellerOlderThanMinDate } from '../utils';
import { useChangeLink } from '../hooks';
import Passenger from './Passenger/Passenger';
import Notification from '../../Notification';
import WarningModal from '../../WarningModal';
import { BaseButton } from '../../BaseComponents';
const errorLocale = `Could not be change the 'LinkedTo' link for traveler`;
const PassengersDetails = ({ className, orderId, orderStatus, travellers, changeTravellersAvailability, goToChangePassenger, onClose }) => {
    const { PassengersDetails: css } = useTheme('PassengersDetails');
    const { t } = useTranslation('OrderCard');
    const { confirmRelinking, onLinkTo, isLoading, onSetRelinkingMode, isRelinkingMode, relinkingToPassengerId, relinkingPassenger, error, clearError } = useChangeLink(orderId);
    const isAvailableForRelinking = (traveller) => {
        var _a;
        const isAlreadyAccompanyInf = (_a = adultAccompaniedMap
            .get(traveller.id)) === null || _a === void 0 ? void 0 : _a.some(accompanied => accompanied.type === 'INF');
        const isOlderThanMinDate = isTravellerOlderThanMinDate(traveller, relinkingPassenger);
        const isRelinkingCldOrTravellerCanAccompanyInf = relinkingPassenger.type !== 'INF' || !isAlreadyAccompanyInf;
        return (traveller.type === 'ADT' &&
            isOlderThanMinDate &&
            (traveller.id === relinkingToPassengerId || isRelinkingCldOrTravellerCanAccompanyInf));
    };
    const adultAccompaniedMap = useMemo(() => getAdultAccompaniedMap(travellers), [travellers]);
    const adultsCount = useMemo(() => travellers.reduce((count, traveller) => (traveller.type === 'ADT' ? count + 1 : count), 0), [travellers]);
    const filteredTravellers = useMemo(() => travellers.filter(traveller => { var _a; return isRelinkingMode ? isAvailableForRelinking(traveller) : !((_a = traveller.linkedTraveller) === null || _a === void 0 ? void 0 : _a.id); }), [travellers, adultAccompaniedMap, isRelinkingMode, relinkingPassenger]);
    const errorKey = useMemo(() => {
        if (error === null || error === void 0 ? void 0 : error.includes(errorLocale)) {
            return errorLocale;
        }
        return error;
    }, [error]);
    const getIsChangeAvailable = useCallback((travellerId) => {
        var _a;
        return !!((_a = changeTravellersAvailability === null || changeTravellersAvailability === void 0 ? void 0 : changeTravellersAvailability.find(changeTraveller => changeTraveller.id === travellerId)) === null || _a === void 0 ? void 0 : _a.available);
    }, [changeTravellersAvailability]);
    let passengerIndex = 0;
    const goToChangePassengerHandler = (passengerId) => () => {
        goToChangePassenger(passengerId);
    };
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: css.close, onClick: onClose }),
        React.createElement("div", { className: css.title }, isRelinkingMode ? t('Select an adult for accompany') : t('Passengers details')),
        isRelinkingMode && (React.createElement(Notification, { infoMessage: {
                code: null,
                type: InfoMessageTypeEnum.Info,
                text: t('After selecting a new adult, please double-check all services. Please choose an adult who does not sit on the emergency seat row.')
            }, status: InfoMessageTypeEnum.Info, type: 'context' })),
        React.createElement("div", { className: css.passengers }, filteredTravellers.map(traveller => {
            const accompanied = adultAccompaniedMap.get(traveller.id);
            passengerIndex++;
            const isChangeAvailable = getIsChangeAvailable(traveller.id);
            return (React.createElement("div", { className: cn(css.item, { [css.item_relinking]: isRelinkingMode }), key: passengerIndex },
                React.createElement(Passenger, { index: passengerIndex, traveller: traveller, isRelinkingMode: isRelinkingMode, isDisabled: isLoading, isChangeAvailable: isChangeAvailable, selectedAsAccompanying: traveller.id === relinkingToPassengerId, onLinkTo: onLinkTo, onChangePassenger: isChangeAvailable ? goToChangePassengerHandler(traveller.id) : undefined }),
                !isRelinkingMode && (accompanied === null || accompanied === void 0 ? void 0 : accompanied.length) && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css.accompanies },
                        React.createElement("div", { className: css.accompanies__text }, t('accompanies'))),
                    accompanied.map(accompaniedPassenger => {
                        passengerIndex++;
                        const isChangeAvailable = getIsChangeAvailable(accompaniedPassenger.id);
                        return (React.createElement(Passenger, { key: passengerIndex, index: passengerIndex, traveller: accompaniedPassenger, isAccompanied: true, isRelinkingAvailable: orderStatus !== OrderStatus.Cancelled, isDisabled: isLoading, onSetRelinkingMode: adultsCount > 1 ? onSetRelinkingMode : null, onChangePassenger: isChangeAvailable
                                ? goToChangePassengerHandler(accompaniedPassenger.id)
                                : undefined }));
                    })))));
        })),
        isRelinkingMode && (React.createElement(BaseButton, { type: "submit", variant: "smallThird", size: "small", className: css.confirmRelink, onClick: confirmRelinking, isLoading: isLoading }, t('Confirm'))),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), errorMessage: t(errorKey), onClose: clearError, onButtonClick: clearError, buttonText: t('OK'), isOpen: !!error })));
};
export default PassengersDetails;
