import { __awaiter, __rest } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSegments } from '../../../../../store/order/selectors';
import { BaseButton } from '../../../../../../../index';
import { useTheme } from '../../../../../../../theme';
import { initI18n } from '../../../../../../../utils';
import MediaQuery from '../../../../../../../MediaQuery/MediaQuery';
import { SelectableSegmentDesktop, SelectableSegmentMobile } from '../../../../../../../SelectableSegment';
import ModalConfirm from '../../../../../../../ModalConfirm/components/ModalConfirm';
initI18n('SelectSegment');
const CHECKED_IN_SEGMENT_STATUS = 'CheckedIn';
const SelectSegmentsModal = props => {
    const { onSegmentSelect: onSelect, selectedSegments, onConfirm, header, passedGroupTitle, type = 'default' } = props, modalProps = __rest(props, ["onSegmentSelect", "selectedSegments", "onConfirm", "header", "passedGroupTitle", "type"]);
    const { SelectSegmentsModal: css } = useTheme('Checkin');
    const segments = useSelector(getSegments);
    const { t } = useTranslation('SelectSegment');
    const [isLoading, setIsLoading] = useState(false);
    const renderSegments = useCallback((segment) => {
        const isDisabled = type === 'cancel' && !segment.checkinInfo.cancelAvailable;
        return (React.createElement(React.Fragment, { key: segment.id },
            React.createElement(MediaQuery, { minWidth: 'mobile' },
                React.createElement(SelectableSegmentDesktop, { segment: segment, onChange: onSelect, selected: selectedSegments.has(segment.id), isLoading: isLoading, isDisabled: isDisabled })),
            React.createElement(MediaQuery, { maxWidth: 'mobile' },
                React.createElement(SelectableSegmentMobile, { segment: segment, onChange: onSelect, selected: selectedSegments.has(segment.id), isLoading: isLoading, isDisabled: isDisabled }))));
    }, [selectedSegments, segments, type, isLoading]);
    const segmentsByGroup = React.useMemo(() => {
        return segments.reduce((result, currentSegment) => {
            if (currentSegment.checkinInfo.checkInStatus === CHECKED_IN_SEGMENT_STATUS) {
                return Object.assign(Object.assign({}, result), { passed: [...result.passed, currentSegment] });
            }
            return Object.assign(Object.assign({}, result), { checkin: [...result.checkin, currentSegment] });
        }, {
            passed: [],
            checkin: []
        });
    }, [segments]);
    const onConfirmHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield onConfirm();
        setIsLoading(false);
    });
    return (React.createElement(ModalConfirm, Object.assign({ size: "xlarge" }, modalProps, { isOpen: modalProps.open, hideTitle: true, hideFooter: true, noPadding: true, ignoreClose: true, classes: { root: css.dialogRoot } }),
        React.createElement("div", { className: css.header }, header !== null && header !== void 0 ? header : t('Check-in for flights')),
        React.createElement("div", { className: css.content },
            !!segmentsByGroup.passed.length && (React.createElement(React.Fragment, null,
                React.createElement("h2", { className: css.checkin__status }, passedGroupTitle !== null && passedGroupTitle !== void 0 ? passedGroupTitle : t('GetTalon')),
                segmentsByGroup.passed.map(renderSegments))),
            !!segmentsByGroup.checkin.length && (React.createElement(React.Fragment, null,
                React.createElement("h2", { className: css.checkin__status }, t('GoToCheckin')),
                segmentsByGroup.checkin.map(renderSegments))),
            React.createElement(BaseButton, { variant: 'action', className: css.button, disabled: !selectedSegments.size, isLoading: isLoading, onClick: onConfirmHandler }, t('Confirm')))));
};
export default SelectSegmentsModal;
