import { __awaiter } from "tslib";
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCheckinAddTravellerMutation, useCheckinCancelMutation, useCheckinRemoveTravellerMutation, useCheckinStartMutation, useSelectCheckinSegmentsMutation } from '@websky/graphql';
import { getCheckinHasStarted, getCheckinOrder, getCheckinOrderId, getPassengerIdsWithLinkedPassengers, getSegments } from '../../../../store/order/selectors';
import { fillCheckinOrder } from '../../../../store/order/actions';
import { setCheckinOrder } from '../../../../store/order/actions';
import { CheckinGoal, reachGoal } from '../../../../../../analytics';
import { isDifferentCurrenciesError } from './utils';
export const useSegmentSelect = (setLoading, setError, onCheckinStart) => {
    const [isOpen, setOpen] = useState(false);
    const [selectedSegments, setSelectedSegments] = useState(new Set());
    const dispatch = useDispatch();
    const orderId = useSelector(getCheckinOrderId);
    const segments = useSelector(getSegments);
    const checkinHasStarted = useSelector(getCheckinHasStarted);
    // segments[1] = { ...segments[0] };
    // segments[1].id = '30';
    const [selectSegments, { error: selectSegmentsError, data: selectSegmentsData, loading: selectSegmentsLoading }] = useSelectCheckinSegmentsMutation();
    const [startCheckin, { error: startCheckinErorr, loading: startCheckinLoading, data: startCheckinData }] = useCheckinStartMutation();
    useEffect(() => {
        var _a;
        setLoading(startCheckinLoading);
        if (!startCheckinLoading && !((_a = startCheckinErorr === null || startCheckinErorr === void 0 ? void 0 : startCheckinErorr.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) && (startCheckinData === null || startCheckinData === void 0 ? void 0 : startCheckinData.CheckinStart)) {
            dispatch(fillCheckinOrder(startCheckinData === null || startCheckinData === void 0 ? void 0 : startCheckinData.CheckinStart));
            setTimeout(onCheckinStart, 0);
        }
    }, [startCheckinLoading, startCheckinErorr, startCheckinData, checkinHasStarted]); // checkinHasStarted in dependencies because we use it inside onCheckinStart
    useEffect(() => {
        if (startCheckinErorr === null || startCheckinErorr === void 0 ? void 0 : startCheckinErorr.graphQLErrors[0]) {
            setError(startCheckinErorr === null || startCheckinErorr === void 0 ? void 0 : startCheckinErorr.graphQLErrors[0]);
        }
    }, [startCheckinErorr]);
    useEffect(() => {
        var _a;
        setLoading(selectSegmentsLoading);
        if (!selectSegmentsLoading &&
            !((_a = selectSegmentsError === null || selectSegmentsError === void 0 ? void 0 : selectSegmentsError.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) &&
            (selectSegmentsData === null || selectSegmentsData === void 0 ? void 0 : selectSegmentsData.CheckinSelectSegments)) {
            startCheckin({ variables: { id: orderId } });
        }
    }, [selectSegmentsLoading, selectSegmentsError, selectSegmentsData]);
    useEffect(() => {
        if (selectSegmentsError === null || selectSegmentsError === void 0 ? void 0 : selectSegmentsError.graphQLErrors[0]) {
            setError(selectSegmentsError === null || selectSegmentsError === void 0 ? void 0 : selectSegmentsError.graphQLErrors[0]);
        }
    }, [selectSegmentsError]);
    const onSelect = useCallback((segment) => {
        if (selectedSegments.has(segment.id)) {
            selectedSegments.delete(segment.id);
            setSelectedSegments(new Set(selectedSegments));
        }
        else {
            selectedSegments.add(segment.id);
            setSelectedSegments(new Set(selectedSegments));
        }
    }, []);
    const onStart = () => {
        if (segments.length > 1 && !(selectedSegments === null || selectedSegments === void 0 ? void 0 : selectedSegments.size)) {
            setOpen(true);
        }
        if (segments.length > 1 && selectedSegments.size) {
            setOpen(false);
            selectSegments({ variables: { params: { orderId, segments: [...selectedSegments] } } });
        }
    };
    return { isSegmentsSelectionOpen: isOpen, onSelect, setOpen, selectedSegments, setSelectedSegments, onStart };
};
export const useCheckinCancelModal = () => {
    const dispatch = useDispatch();
    const [checkinCancelResponse, setCheckinCancelResponse] = useState();
    const [isCheckinCancelModalOpen, setCheckinCancelModalOpen] = useState(false);
    const showCheckinCancelModalIfNeeded = useCallback((response) => {
        if (response.travellers.every(traveller => traveller.isConfirmed === false)) {
            setCheckinCancelResponse(response);
            setCheckinCancelModalOpen(true);
        }
        else {
            dispatch(setCheckinOrder(response));
            reachGoal(CheckinGoal.Cancelled);
        }
    }, []);
    const handleAgree = useCallback(() => {
        setCheckinCancelModalOpen(false);
        dispatch(setCheckinOrder(checkinCancelResponse));
        reachGoal(CheckinGoal.Cancelled);
    }, [checkinCancelResponse, isCheckinCancelModalOpen]);
    return { showCheckinCancelModalIfNeeded, handleAgree, isCheckinCancelModalOpen };
};
export const useCheckinTraveller = (showCheckinCancelModalIfNeeded, setError) => {
    const { t } = useTranslation('Checkin');
    const dispatch = useDispatch();
    const order = useSelector(getCheckinOrder);
    const passengerIdsWithLinkedPassengers = useSelector(getPassengerIdsWithLinkedPassengers);
    const [addTraveller, { loading: addTravellerLoading }] = useCheckinAddTravellerMutation();
    const [removeTraveller, { loading: removeTravellerLoading }] = useCheckinRemoveTravellerMutation();
    const [cancelCheckin, { loading: cancelCheckinLoading }] = useCheckinCancelMutation();
    const onError = useCallback((message, error) => setError({ content: message, error }), [setError]);
    const onAddTraveller = useCallback((lastName, id, extraSecret) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { data, errors } = yield addTraveller({
                variables: {
                    params: { id, secret: lastName, orderId: order.id, extraSecret }
                }
            });
            const result = !errors && (data === null || data === void 0 ? void 0 : data.CheckinAddTraveller);
            if (result) {
                reachGoal(CheckinGoal.AddTraveller);
                dispatch(fillCheckinOrder(result));
                return true;
            }
            return false;
        }
        catch (e) {
            // @ts-ignore
            const hasDifferentCurrenciesError = (_a = e === null || e === void 0 ? void 0 : e.graphQLErrors) === null || _a === void 0 ? void 0 : _a.some(isDifferentCurrenciesError);
            if (hasDifferentCurrenciesError) {
                onError(t('You cannot add a passenger because the currency of order is different from the currency of orders of previously added passengers.\nYou can check-in the passenger separately.'), e);
            }
            return false;
        }
    }), [order.id, onError]);
    const onRemoveTraveller = useCallback((traveller) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data, errors } = yield removeTraveller({
                variables: {
                    params: { orderId: order.id, passengerId: traveller.id }
                }
            });
            const result = !errors && (data === null || data === void 0 ? void 0 : data.CheckinRemoveTraveller);
            if (result) {
                reachGoal(CheckinGoal.RemoveTraveller);
                dispatch(fillCheckinOrder(result));
            }
        }
        catch (e) {
            console.error(e);
        }
    }), [order.id]);
    const onCancelCheckinRequest = useCallback((segmentIds, travellerIds) => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        try {
            const { data } = yield cancelCheckin({
                variables: {
                    params: { orderId: order.id, segmentIds, travellerIds }
                }
            });
            if (data === null || data === void 0 ? void 0 : data.CheckinCancel) {
                showCheckinCancelModalIfNeeded(data.CheckinCancel);
            }
        }
        catch (e) {
            onError(t('Unfortunately, check-in cancellation is prohibited for this flight. Please contact a customer support service for further details.'), e);
        }
    }), [order.id, cancelCheckin, onError]);
    const onCancelCheckin = useCallback(() => {
        const segmentIds = order.segments.map(segment => segment.id);
        const travellerIds = order.travellers.map(traveller => traveller.id);
        return onCancelCheckinRequest(segmentIds, travellerIds);
    }, [order, onCancelCheckinRequest]);
    const onCancelCheckinPassenger = useCallback((passengerId, segmentsIds = []) => {
        const travellerIds = new Set(passengerId);
        const linkedTraveller = passengerIdsWithLinkedPassengers.get(passengerId);
        if (linkedTraveller === null || linkedTraveller === void 0 ? void 0 : linkedTraveller.length) {
            linkedTraveller.forEach(traveller => travellerIds.add(traveller.id));
        }
        return onCancelCheckinRequest(segmentsIds !== null && segmentsIds !== void 0 ? segmentsIds : order.segments.map(segment => segment.id), [...travellerIds]);
    }, [order.segments, passengerIdsWithLinkedPassengers, onCancelCheckinRequest]);
    return {
        // data
        passengerIdsWithLinkedPassengers,
        isLoading: addTravellerLoading || removeTravellerLoading || cancelCheckinLoading,
        // methods
        onAddTraveller,
        onRemoveTraveller,
        onCancelCheckin,
        onCancelCheckinPassenger
    };
};
