export const getCategories = (meals, segmentId) => {
    const categories = new Set();
    meals.forEach(meal => {
        if (meal.segmentsId.indexOf(segmentId) !== -1 && !categories.has(meal.category)) {
            categories.add(meal.category);
        }
    });
    return [...categories];
};
export const getSelectedProductsBySegments = (selectedProducts, segments) => {
    return segments.map((segment, index) => {
        if (selectedProducts.length <= index) {
            return [];
        }
        const products = [];
        for (const meal in selectedProducts[segment.id].meals) {
            if (selectedProducts[segment.id].meals.hasOwnProperty(meal) &&
                selectedProducts[segment.id].meals[meal].count > 0) {
                products.push(selectedProducts[segment.id].meals[meal]);
            }
        }
        return products;
    });
};
export const getSegmentsWithMeal = (meals) => {
    const segmentsWithMeal = new Set();
    meals.forEach(meal => meal.segmentsId.forEach(segmentId => segmentsWithMeal.add(segmentId)));
    return segmentsWithMeal;
};
export const removeDuplicatesByName = (meals) => {
    return Array.from(meals
        .reduce((meals, meal) => {
        const uncapitalizedName = meal.name.toLowerCase();
        if (!meals.has(uncapitalizedName)) {
            meals.set(uncapitalizedName, meal);
        }
        return meals;
    }, new Map())
        .values());
};
