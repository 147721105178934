import { SET_ALL_EVERY_PORT_ACTION, SET_EVERY_PORT_ACTION } from './actions';
const defaultState = {
    selectedServices: {}
};
export const everyPortServicesReducer = (state = defaultState, action) => {
    if (action.type === SET_EVERY_PORT_ACTION) {
        const payload = action.payload;
        return {
            selectedServices: Object.assign(Object.assign({}, state.selectedServices), { [payload.category]: [...payload.services] })
        };
    }
    else if (action.type === SET_ALL_EVERY_PORT_ACTION) {
        return Object.assign(Object.assign({}, state), { selectedServices: action.payload });
    }
    return state;
};
