import * as OrderSummary from './components/OrderSummary/OrderSummary.css';
import * as OrderStub from './components/OrderStub/OrderStub.css';
import * as OrderHeader from './components/OrderHeader/OrderHeader.css';
import * as OrderTimeLimit from './components/OrderTimeLimit/OrderTimeLimit.css';
export default {
    OrderSummary,
    OrderStub,
    OrderHeader,
    OrderTimeLimit
};
