import { useMemo, useState } from 'react';
import { addDays, format, startOfWeek } from 'date-fns';
import { useFullFlightsScheduleLazyQuery, AviaPassengerType } from '@websky/graphql';
import { API_DATE_FORMAT } from '../utils';
import { useAvailableDates } from '../Modules/Exchange/components/Exchange/hooks';
export const useSchedule = () => {
    const [startDate, setStartDate] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
    const [getFlightsSchedule, { data, loading }] = useFullFlightsScheduleLazyQuery({ fetchPolicy: 'no-cache' });
    const getSchedule = (parameters, date = startDate) => {
        setStartDate(date);
        getFlightsSchedule({
            variables: {
                parameters: [parameters],
                period: {
                    startDate: format(date, API_DATE_FORMAT),
                    endDate: format(addDays(date, 6), API_DATE_FORMAT)
                },
                groupEqualFlights: true
            }
        });
    };
    const segmentParameters = useMemo(() => {
        var _a, _b;
        const segment = (_b = (_a = data === null || data === void 0 ? void 0 : data.FullFlightsSchedule) === null || _a === void 0 ? void 0 : _a.searchParameters.segments) === null || _b === void 0 ? void 0 : _b[0];
        if (segment) {
            return {
                departure: {
                    iata: segment.departure.iata
                },
                arrival: {
                    iata: segment.arrival.iata
                },
                date: format(startDate, API_DATE_FORMAT)
            };
        }
        return null;
    }, [data === null || data === void 0 ? void 0 : data.FullFlightsSchedule, startDate]);
    const { dates, isLoading } = useAvailableDates(segmentParameters);
    const defaultParameters = useMemo(() => {
        if (data === null || data === void 0 ? void 0 : data.FullFlightsSchedule.searchParameters) {
            return {
                passengers: [
                    {
                        passengerType: AviaPassengerType.ADT,
                        count: 1
                    }
                ],
                segments: data.FullFlightsSchedule.searchParameters.segments.map(segment => (Object.assign(Object.assign({}, segment), { date: format(startDate, API_DATE_FORMAT) })))
            };
        }
        return null;
    }, [data === null || data === void 0 ? void 0 : data.FullFlightsSchedule, startDate]);
    return [
        getSchedule,
        {
            schedule: data === null || data === void 0 ? void 0 : data.FullFlightsSchedule,
            defaultParameters,
            availableDates: dates,
            isLoading: loading || isLoading
        }
    ];
};
