import * as React from 'react';
const Facebook = (React.createElement("svg", { width: "44", height: "44", viewBox: "0 0 44 44", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { width: "44", height: "44", rx: "22", fill: "#3C5B96" }),
    React.createElement("path", { d: "M25.6876 22.5068H23.2748C23.2748 26.3139 23.2748 31 23.2748 31H19.6995C19.6995 31 19.6995 26.3593 19.6995 22.5068H18V19.505H19.6995V17.5634C19.6995 16.1729 20.3686 14 23.3076 14L25.957 14.01V16.9239C25.957 16.9239 24.3471 16.9239 24.034 16.9239C23.721 16.9239 23.276 17.0785 23.276 17.7416V19.5056H26L25.6876 22.5068Z", fill: "white" })));
const Vkontakte = (React.createElement("svg", { width: "44", height: "44", viewBox: "0 0 44 44", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { width: "44", height: "44", rx: "22", fill: "#0077FF" }),
    React.createElement("path", { d: "M23.0718 29.4879C14.8719 29.4879 10.1949 23.8664 10 14.5122H14.1074C14.2423 21.3779 17.2704 24.2861 19.6689 24.8857V14.5122H23.5366V20.4335C25.9051 20.1787 28.3933 17.4804 29.2328 14.5122H33.1004C32.4558 18.1699 29.7575 20.8682 27.8387 21.9775C29.7575 22.877 32.8307 25.2305 34 29.4879H29.7425C28.8281 26.6396 26.5497 24.436 23.5366 24.1362V29.4879H23.0718Z", fill: "white" })));
const Google = (React.createElement("svg", { width: "44", height: "44", viewBox: "0 0 44 44", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { x: "0.5", y: "0.5", width: "43", height: "43", rx: "21.5", fill: "white" }),
    React.createElement("path", { d: "M16.9892 23.8775L16.3626 26.2165L14.0726 26.2649C13.3882 24.9956 13 23.5432 13 21.9999C13 20.5074 13.363 19.1001 14.0063 17.8608H14.0068L16.0456 18.2346L16.9387 20.2612C16.7518 20.8061 16.6499 21.3911 16.6499 21.9999C16.65 22.6605 16.7696 23.2935 16.9892 23.8775Z", fill: "#FBBB00" }),
    React.createElement("path", { d: "M30.8427 20.3188C30.9461 20.8633 31 21.4255 31 22.0002C31 22.6445 30.9322 23.273 30.8032 23.8793C30.3651 25.9424 29.2203 27.7438 27.6344 29.0187L27.6339 29.0182L25.066 28.8872L24.7025 26.6184C25.7548 26.0012 26.5772 25.0354 27.0104 23.8793H22.1978V20.3188H27.0806H30.8427Z", fill: "#518EF8" }),
    React.createElement("path", { d: "M27.6339 29.0178L27.6344 29.0183C26.092 30.258 24.1328 30.9998 22 30.9998C18.5726 30.9998 15.5927 29.0841 14.0726 26.2649L16.9892 23.8774C17.7492 25.9059 19.706 27.3499 22 27.3499C22.986 27.3499 23.9098 27.0833 24.7024 26.618L27.6339 29.0178Z", fill: "#28B446" }),
    React.createElement("path", { d: "M27.7447 15.072L24.8291 17.4589C24.0087 16.9461 23.0389 16.6499 22 16.6499C19.6541 16.6499 17.6607 18.1601 16.9387 20.2613L14.0068 17.861H14.0063C15.5042 14.9731 18.5216 13 22 13C24.1837 13 26.186 13.7779 27.7447 15.072Z", fill: "#F14336" }),
    React.createElement("rect", { x: "0.5", y: "0.5", width: "43", height: "43", rx: "21.5", stroke: "#D9D9D9" })));
const close = (React.createElement("svg", { width: "10", height: "10", viewBox: "0 0 10 10", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8.18198 9.24246L5 6.06048L1.81802 9.24246L0.757359 8.1818L3.93934 4.99982L0.757359 1.81784L1.81802 0.757176L5 3.93916L8.18198 0.757176L9.24264 1.81784L6.06066 4.99982L9.24264 8.1818L8.18198 9.24246Z", fill: "#262626" })));
const TinkoffID = (React.createElement("svg", { width: "44", height: "44", viewBox: "0 0 57 57", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { x: "0.0481415", y: "0.284119", width: "56", height: "56", rx: "28", fill: "#FFDD2D" }),
    React.createElement("path", { d: "M16.0481 16.2841H40.0481V28.3532C40.0481 31.4481 38.4021 34.3014 35.7201 35.8546L28.0539 40.2841L20.3877 35.8546C17.7057 34.3129 16.0597 31.4481 16.0597 28.3532V16.2841H16.0481Z", fill: "white" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M22.6323 22.6349V26.3857C23.1503 25.8104 24.0827 25.4192 25.1416 25.4192H26.3042V29.7797C26.3042 30.9418 25.9934 31.9542 25.5215 32.518H30.5632C30.0913 31.9542 29.7805 30.9418 29.7805 29.7797V25.4192H30.9431C32.0136 25.4192 32.946 25.8104 33.4524 26.3857V22.6349H22.6323Z", fill: "#333333" })));
export const icons = {
    Vkontakte,
    Google,
    Facebook,
    TinkoffID,
    close
};
