import { __awaiter } from "tslib";
import { useLogoutMutation } from '@websky/graphql';
import { useHistory } from 'react-router-dom';
import * as Cookie from 'js-cookie';
import { GRAPHQL_KEY } from '../../cache';
export const useLogout = (replace) => {
    const history = useHistory();
    const [logoutMutation, { loading }] = useLogoutMutation();
    const onLogout = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            Cookie.remove(GRAPHQL_KEY);
            yield logoutMutation();
            if (replace) {
                history.replace(replace);
            }
            window.location.reload();
        }
        catch (e) {
            console.log(e);
        }
    });
    return { onLogout, loading };
};
