import { PriceBreakdownType } from './store/priceBreakdown/action';
import { AeroexpressDirection, AviaPassengerType, BaggageType, NASProductCategory, NASProductDirection, OrderAdditionalServiceGdsServiceServiceType, TimelineStep, SeatType, TravellerFieldEnum } from '@websky/graphql';
import { Currency } from '../enums';
import { getSelectedSeatsBySegment } from '../SeatMap/utils';
import { Measurement } from '../BaggageService/types';
import { getRelatedSegments } from '../Modules/Checkin/utils';
import { API_DATE_FORMAT, format, getMinPrice, getUserValue, setsAreEqual } from '../utils';
import { addServiceAction_NS, removeServiceAction_NS, deleteAllTypesOnSegmentAction_NS, setMultipleSelectedServicesAction_NS, saveServicesAction } from './store/selectedServices/actions';
import { addDays } from 'date-fns';
import { clearPhoneNumber } from '../PhoneInput/utils';
export const parsePriceBreakdown = (priceBreakdown) => {
    const price = {
        totalPrice: {
            amount: 0,
            currency: Currency.USD
        },
        servicePrice: {
            amount: 0,
            currency: Currency.USD
        },
        upsalePrice: {
            amount: 0,
            currency: Currency.USD
        }
    };
    price.totalPrice = priceBreakdown.price;
    priceBreakdown.parts.forEach(part => {
        if (part.type === PriceBreakdownType.Service) {
            price.servicePrice.amount += part.price.amount;
            price.servicePrice.currency += part.price.currency;
            part.parts.forEach(part => {
                if (part.type === PriceBreakdownType.Upsale) {
                    price.upsalePrice.amount += part.price.amount;
                    price.upsalePrice.currency += part.price.currency;
                }
            });
        }
    });
    return price;
};
export const getGdsServiceMinPrice = (order, gdsService, baggageTypes) => {
    var _a, _b, _c;
    let prices = [];
    if ((_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.length) {
        order.additionalServices.gdsServices.services.forEach(service => {
            var _a;
            if (((_a = service.price) === null || _a === void 0 ? void 0 : _a.amount) > 0 && gdsService !== OrderAdditionalServiceGdsServiceServiceType.Seat) {
                const includeBaggageType = !(baggageTypes === null || baggageTypes === void 0 ? void 0 : baggageTypes.length) ||
                    ((baggageTypes === null || baggageTypes === void 0 ? void 0 : baggageTypes.length) && baggageTypes.includes(service.baggageType));
                if (service.type === gdsService && includeBaggageType) {
                    prices = [...prices, service.price];
                }
            }
        });
    }
    return getMinPrice(prices, price => price);
};
export const removeServicesStepsByTypes = (steps, types) => {
    return steps.filter(step => {
        return types.find(type => step.type !== type);
    });
};
const getPassengerDisabilities = (passengerDisabilities) => {
    const disabilities = [];
    if (!(passengerDisabilities === null || passengerDisabilities === void 0 ? void 0 : passengerDisabilities.enabled)) {
        return null;
    }
    Object.entries(passengerDisabilities.values).forEach(([key, isChecked]) => {
        if (isChecked) {
            disabilities.push(key);
        }
    }, null);
    return disabilities.length ? disabilities : null;
};
export const prepareBookingRequest = (orderId, formValues, orderSubsidyState) => {
    const values = {
        params: {
            id: orderId,
            customer: {
                values: [
                    {
                        name: 'email',
                        value: formValues['customer']['email']
                    },
                    {
                        name: 'phone',
                        value: clearPhoneNumber(formValues['customer']['phone'])
                    }
                ],
                subscribed: formValues['subscribed']
            },
            travellers: formValues['passengers'].map((passenger, index) => {
                var _a, _b;
                const values = [];
                for (const fieldName in passenger) {
                    if (passenger.hasOwnProperty(fieldName)) {
                        if (!['type', 'disabilities', 'files'].includes(fieldName)) {
                            values.push({
                                name: fieldName,
                                value: fieldName === 'phone'
                                    ? clearPhoneNumber(passenger[fieldName])
                                    : passenger[fieldName]
                            });
                        }
                    }
                }
                return {
                    id: index.toString(),
                    values: values,
                    subsidies: (orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.isSubsidiesAvailable)
                        ? {
                            selectedSubsidies: (_b = (_a = orderSubsidyState.travellers.find(traveller => traveller.traveller.id === index.toString())) === null || _a === void 0 ? void 0 : _a.subsidies) !== null && _b !== void 0 ? _b : []
                        }
                        : null,
                    disabilities: getPassengerDisabilities(passenger['disabilities'])
                };
            })
        }
    };
    if (formValues['customer']['accompanyingPersonName'] && formValues['customer']['accompanyingPersonTicketNumber']) {
        values.params.customer.values.push({
            name: 'accompanyingPersonName',
            value: formValues['customer']['accompanyingPersonName']
        }, {
            name: 'accompanyingPersonTicketNumber',
            value: formValues['customer']['accompanyingPersonTicketNumber']
        });
    }
    return values;
};
export const checkInternationalFlight = (flight) => {
    return flight.segments.some(segment => segment.segment.arrival.airport.country.id !== segment.segment.departure.airport.country.id);
};
export const getServicesForBaggageIncrease = (selectedService, baggageServices, passengersState) => {
    let createNewServices = true;
    const { baggage, segmentId, passengerId, forAllSegments } = selectedService;
    const services = [];
    const baggageMap = new Map(baggageServices.map(baggage => [baggage.id, true]));
    const relatedSegments = getRelatedSegments(baggage.segmentIds, forAllSegments ? null : segmentId);
    passengersState[parseInt(passengerId)].services.forEach(selectedService => {
        if (baggageMap.has(selectedService.serviceId)) {
            if (selectedService.serviceId === baggage.id &&
                (forAllSegments || setsAreEqual(relatedSegments, new Set(selectedService.segmentIds)))) {
                // Do not create new services, cuz we already have one.
                createNewServices = false;
                services.push(Object.assign(Object.assign({}, selectedService), { count: selectedService.count + 1 }));
            }
            else {
                services.push(selectedService);
            }
        }
    });
    if (createNewServices) {
        services.push({
            serviceId: baggage.id,
            count: 1,
            segmentIds: [...relatedSegments]
        });
    }
    return [
        {
            passengerId,
            services
        }
    ];
};
export const getServicesForBaggageDecrease = (selectedService, baggageServices, passengersState) => {
    const { baggage, segmentId, passengerId, forAllSegments } = selectedService;
    const services = [];
    const relatedSegments = getRelatedSegments(baggage.segmentIds, forAllSegments ? null : segmentId);
    passengersState[parseInt(passengerId)].services.forEach(selectedService => {
        if (selectedService.serviceId === baggage.id &&
            (forAllSegments || setsAreEqual(relatedSegments, new Set(selectedService.segmentIds)))) {
            services.push(Object.assign(Object.assign({}, selectedService), { count: selectedService.count > 0 ? selectedService.count - 1 : 0 }));
        }
        else if (selectedService.serviceId !== baggage.id ||
            (selectedService.segmentIds[0] !== segmentId && !forAllSegments)) {
            services.push(selectedService);
        }
    });
    return [
        {
            passengerId,
            services
        }
    ];
};
export const getServicesForBaggageReplace = (oldBaggage, newBaggage, segmentId, passengerId, forAllSegments, baggageServices, passengersState) => {
    const services = [];
    const relatedSegments = getRelatedSegments(newBaggage.segmentIds, forAllSegments ? null : segmentId);
    // Remove old baggage.
    passengersState[parseInt(passengerId)].services.forEach(service => {
        if (service.serviceId === oldBaggage.id &&
            (forAllSegments || setsAreEqual(relatedSegments, new Set(service.segmentIds)))) {
            services.push(Object.assign(Object.assign({}, service), { count: 0 }));
        }
        else {
            services.push(service);
        }
    });
    // Add new baggage for given segments.
    services.push({
        serviceId: newBaggage.id,
        count: 1,
        segmentIds: [...relatedSegments]
    });
    return [
        {
            passengerId,
            services
        }
    ];
};
export const getServicesForBaggageClear = (passengerId, baggageServices, passengersState) => {
    const newServices = [];
    const baggageServicesIds = baggageServices.map(service => service.id);
    passengersState[parseInt(passengerId)].services.forEach(service => {
        if (baggageServicesIds.includes(service.serviceId)) {
            newServices.push(Object.assign(Object.assign({}, service), { count: 0 }));
        }
        else {
            newServices.push(Object.assign({}, service));
        }
    });
    return [
        {
            passengerId,
            services: newServices
        }
    ];
};
export const getServicesForBaggageModeChange = (passengerId, segments, baggageServices, passengersState) => {
    const services = {};
    passengersState[parseInt(passengerId)].services.forEach(service => {
        if (!services[service.serviceId] || services[service.serviceId] < service.count) {
            services[service.serviceId] = service.count;
        }
    });
    const newServices = [];
    for (const service in services) {
        if (services.hasOwnProperty(service)) {
            segments.forEach(({ id }) => {
                newServices.push({
                    serviceId: service,
                    count: services[service],
                    segmentIds: [id]
                });
            });
        }
    }
    return [{ passengerId: passengerId, services: newServices }];
};
export const getBaggageCallbacks = (runServiceRequest, baggageServices, passengers, segments) => {
    const baggageRequestAdapter = (passengers) => runServiceRequest(passengers.map(({ passengerId, services }) => ({
        passengerId: passengerId,
        setServices: services
    })));
    return {
        onIncrease: (baggage, segmentId, passengerId, forAllSegments) => baggageRequestAdapter(getServicesForBaggageIncrease({ baggage, segmentId, passengerId, forAllSegments }, baggageServices, passengers)),
        onDecrease: (baggage, segmentId, passengerId, forAllSegments) => baggageRequestAdapter(getServicesForBaggageDecrease({ baggage, segmentId, passengerId, forAllSegments }, baggageServices, passengers)),
        onReplace: (oldBaggage, newBaggage, segmentId, passengerId, forAllSegments) => baggageRequestAdapter(getServicesForBaggageReplace(oldBaggage, newBaggage, segmentId, passengerId, forAllSegments, baggageServices, passengers)),
        onModeChange: passengerId => baggageRequestAdapter(getServicesForBaggageModeChange(passengerId, segments, baggageServices, passengers))
    };
};
export const getBaggageReduxCallbacks = (passengers, baggage, segments, addService, removeService, setService) => {
    return {
        onIncrease: (baggage, segmentId, passengerId, forAllSegments) => {
            if (!forAllSegments) {
                addService({
                    segmentId,
                    passengerId: passengerId,
                    serviceId: baggage.id,
                    service: {
                        id: baggage.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                    },
                    allowedSegments: baggage.segmentIds
                });
            }
            else {
                const relatedSegments = getRelatedSegments(baggage.segmentIds, forAllSegments ? null : segmentId);
                relatedSegments.forEach(segmentId => {
                    addService({
                        segmentId,
                        passengerId: passengerId,
                        serviceId: baggage.id,
                        service: {
                            id: baggage.id,
                            type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                        },
                        allowedSegments: baggage.segmentIds
                    });
                });
            }
        },
        onDecrease: (baggage, segmentId, passengerId, forAllSegments) => {
            if (!forAllSegments) {
                removeService({
                    segmentId,
                    passengerId: passengerId,
                    serviceId: baggage.id,
                    service: {
                        id: baggage.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                    },
                    allowedSegments: baggage.segmentIds
                });
            }
            else {
                const relatedSegments = getRelatedSegments(baggage.segmentIds, forAllSegments ? null : segmentId);
                relatedSegments.forEach(segmentId => {
                    removeService({
                        segmentId,
                        passengerId: passengerId,
                        serviceId: baggage.id,
                        service: {
                            id: baggage.id,
                            type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                        },
                        allowedSegments: baggage.segmentIds
                    });
                });
            }
        },
        onReplace: (oldBaggage, newBaggage, segmentId, passengerId, forAllSegments) => {
            const relatedSegments = getRelatedSegments(oldBaggage.segmentIds, forAllSegments ? null : segmentId), newRelatedSegments = getRelatedSegments(newBaggage.segmentIds, forAllSegments ? null : segmentId);
            if (forAllSegments) {
                relatedSegments.forEach(segmentId => {
                    removeService({
                        serviceId: oldBaggage.id,
                        passengerId,
                        segmentId,
                        service: {
                            type: OrderAdditionalServiceGdsServiceServiceType.Baggage,
                            price: oldBaggage.price,
                            id: oldBaggage.id
                        }
                    });
                });
            }
            else {
                removeService({
                    serviceId: oldBaggage.id,
                    passengerId,
                    segmentId
                });
            }
            newRelatedSegments.forEach(segmentId => {
                setService({
                    serviceId: newBaggage.id,
                    passengerId,
                    segmentId,
                    service: {
                        id: newBaggage.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                    },
                    allowedSegments: newBaggage.segmentIds
                });
            });
        },
        onModeChange: passengerId => {
            const passenger = passengers.find(passenger => passenger.id === passengerId);
            const baggageIds = baggage.map(baggage => baggage.id);
            const services = {};
            passenger.services.forEach(service => {
                if (baggageIds.includes(service.serviceId) &&
                    (!services[service.serviceId] || services[service.serviceId] < service.count)) {
                    services[service.serviceId] = service.count;
                }
            });
            for (const service in services) {
                if (services.hasOwnProperty(service)) {
                    const baggageService = baggage.find(baggage => baggage.id === service);
                    segments.forEach(segment => {
                        setService({
                            passengerId,
                            service: {
                                id: service,
                                type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                            },
                            count: services[service],
                            serviceId: service,
                            segmentId: segment.id,
                            allowedSegments: baggageService.segmentIds
                        });
                    });
                }
            }
        },
        onClear: (passengerId, type) => {
            const passenger = passengers.find(passenger => passenger.id === passengerId);
            const baggageIds = baggage
                .filter(service => (type ? service.type === type : true))
                .map(service => service.id);
            passenger.services.forEach(selectedBaggage => {
                selectedBaggage.segmentIds.forEach(segmentId => {
                    if (baggageIds.includes(selectedBaggage.serviceId)) {
                        setService({
                            serviceId: selectedBaggage.serviceId,
                            count: 0,
                            passengerId,
                            segmentId,
                            service: {
                                id: selectedBaggage.serviceId,
                                type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                            },
                            allowedSegments: baggage.find(baggage => baggage.id === selectedBaggage.serviceId)
                                .segmentIds
                        });
                    }
                });
            });
        }
    };
};
export const getMealReduxCallbacks = (dispatch) => {
    return {
        onMealAdd: (meal, segmentId, passengerId, count) => {
            dispatch(addServiceAction_NS({
                passengerId: passengerId,
                segmentId,
                serviceId: meal.id,
                allowedSegments: meal.allowedSegments,
                count,
                service: {
                    id: meal.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Meal,
                    name: meal.name,
                    price: meal.price
                }
            }));
        },
        onMealRemove: (meal, segmentId, passengerId, count) => {
            dispatch(removeServiceAction_NS({
                passengerId: passengerId,
                segmentId,
                serviceId: meal.id,
                count,
                allowedSegments: meal.allowedSegments,
                service: {
                    id: meal.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Meal,
                    name: meal.name,
                    price: meal.price
                }
            }));
        },
        onMealAddForPassengers: (meal, segmentId, passengerIds, count) => {
            const services = passengerIds.map(passengerId => ({
                passengerId,
                segmentId,
                serviceId: meal.id,
                count,
                allowedSegments: meal.allowedSegments,
                service: {
                    id: meal.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Meal,
                    name: meal.name,
                    price: meal.price
                }
            }));
            dispatch(setMultipleSelectedServicesAction_NS(services));
        },
        onMealClear: (meal, segmentId) => {
            dispatch(deleteAllTypesOnSegmentAction_NS({
                segmentId,
                serviceId: meal.id
            }));
        },
        onMealConfirm: () => {
            dispatch(saveServicesAction());
        }
    };
};
export const isSelectedSeatGuard = (seat) => seat.isConfirmed !== undefined;
export const convertSeatsToServicePayload = (seats, passengerId, segmentId, isExtraSeats, count) => {
    return seats.map((seat, index) => {
        var _a;
        return (Object.assign({ segmentId,
            passengerId, serviceId: seat.number, service: {
                letter: seat.letter,
                row: seat.row,
                id: !isSelectedSeatGuard(seat) ? (_a = seat.service) === null || _a === void 0 ? void 0 : _a.id : null,
                deckId: null,
                type: OrderAdditionalServiceGdsServiceServiceType.Seat,
                rfisc: seat.rfisc,
                price: seat.price,
                number: seat.number,
                segmentId,
                seatType: index === 0 && isExtraSeats ? SeatType.extraSeat : null
            } }, (count !== undefined ? { count } : {})));
    });
};
export const getSeatReduxCallbacks = (passengers, setMultipleServicesAction) => {
    return {
        setSeatCallback: (seats, passengerId, segmentId) => {
            var _a;
            const isExtraSeats = (seats === null || seats === void 0 ? void 0 : seats.length) > 1;
            const seatNumbers = (seats === null || seats === void 0 ? void 0 : seats.map(seat => seat.number)) || [];
            let services = (seats === null || seats === void 0 ? void 0 : seats.length)
                ? convertSeatsToServicePayload(seats, passengerId, segmentId, isExtraSeats)
                : [];
            const passengerSeats = ((_a = passengers.find(passenger => passenger.id === passengerId)) === null || _a === void 0 ? void 0 : _a.seats) || [];
            const selectedSeatsBySegmentId = passengerSeats.filter(seat => seat.segmentId === segmentId && !seatNumbers.includes(seat.number));
            const passengerOccupiedThisSeatBefore = passengers
                .filter(passenger => passenger.id !== passengerId)
                .find(passenger => {
                var _a;
                return (_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.some(selectedSeat => selectedSeat.segmentId === segmentId && seatNumbers.includes(selectedSeat.number));
            });
            if (selectedSeatsBySegmentId.length) {
                services = [
                    ...services,
                    ...convertSeatsToServicePayload(selectedSeatsBySegmentId, passengerId, segmentId, false, 0)
                ];
            }
            if (passengerOccupiedThisSeatBefore) {
                services = [
                    ...services,
                    ...convertSeatsToServicePayload(passengerOccupiedThisSeatBefore.seats || [], passengerOccupiedThisSeatBefore.id, segmentId, false, 0)
                ];
            }
            if (services.length) {
                setMultipleServicesAction(services);
            }
        },
        setClearSeatCallback: (seats, passengerId, segmentId) => {
            setMultipleServicesAction(convertSeatsToServicePayload(seats, passengerId, segmentId, false, 0));
        },
        onSegmentClear: (segmentId) => {
            let services = [];
            passengers.forEach(passenger => {
                var _a;
                const selectedSeats = ((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.filter(seat => parseInt(seat.segmentId) === segmentId)) || [];
                services = [
                    ...services,
                    ...convertSeatsToServicePayload(selectedSeats, passenger.id, segmentId.toString(), false, 0)
                ];
            });
            if (services.length) {
                setMultipleServicesAction(services);
            }
        },
        onPassengerClear: (passengerId) => {
            var _a;
            let services = [];
            const passengerSeats = ((_a = passengers.find(passenger => passenger.id === passengerId)) === null || _a === void 0 ? void 0 : _a.seats) || [];
            passengerSeats.forEach(seat => {
                services = [
                    ...services,
                    ...convertSeatsToServicePayload([seat], passengerId, seat.segmentId, false, 0)
                ];
            });
            if (services.length) {
                setMultipleServicesAction(services);
            }
        }
    };
};
export const onClearPassengerMeals = (meal, segmentId, passengers, selectedMeals) => {
    // Remove all meal products of given type for each passenger on given segment.
    const passengersServices = [];
    passengers.forEach(passenger => {
        const products = [];
        selectedMeals.forEach(selectedMeal => {
            if (selectedMeal.passengerId === passenger.id) {
                products.push({
                    serviceId: selectedMeal.mealId,
                    segmentIds: [selectedMeal.segmentId],
                    count: selectedMeal.mealId === meal.id && selectedMeal.segmentId === segmentId ? 0 : selectedMeal.count
                });
            }
        });
        if (products.length) {
            passengersServices.push({
                passengerId: passenger.id,
                setServices: products
            });
        }
    });
    return passengersServices;
};
export const onMealAdd = (meal, segmentId, passengerId, selectedMeals) => {
    let hasFound = false;
    const newServices = selectedMeals
        .filter(selectedMeal => selectedMeal.passengerId === passengerId)
        .map(selectedMeal => {
        const count = selectedMeal.count, isSameService = selectedMeal.mealId === meal.id && selectedMeal.segmentId === segmentId;
        if (isSameService) {
            hasFound = true;
        }
        return {
            serviceId: selectedMeal.mealId,
            segmentIds: [selectedMeal.segmentId],
            count: isSameService ? count + 1 : count,
            confirmedCount: selectedMeal.confirmedCount
        };
    });
    if (!hasFound) {
        newServices.push({
            serviceId: meal.id,
            count: 1,
            segmentIds: [segmentId],
            confirmedCount: 0
        });
    }
    return [{ passengerId, setServices: newServices }];
};
export const onMealRemove = (meal, segmentId, passengerId, selectedMeals) => {
    const newServices = selectedMeals
        .filter(selectedMeal => selectedMeal.passengerId === passengerId)
        .map(selectedMeal => ({
        serviceId: selectedMeal.mealId,
        count: selectedMeal.mealId === meal.id && selectedMeal.segmentId === segmentId && selectedMeal.count > 0
            ? selectedMeal.count - 1
            : selectedMeal.count,
        segmentIds: [selectedMeal.segmentId],
        confirmedCount: selectedMeal.confirmedCount
    }));
    return [{ passengerId, setServices: newServices }];
};
export const getUserInfo = (userInfo) => {
    if (!userInfo || !(userInfo === null || userInfo === void 0 ? void 0 : userInfo.userProfile)) {
        return {
            lastName: '',
            firstName: '',
            email: ''
        };
    }
    return {
        lastName: getUserValue(userInfo.userProfile, TravellerFieldEnum.LastName),
        firstName: getUserValue(userInfo.userProfile, TravellerFieldEnum.FirstName),
        email: getUserValue(userInfo.userProfile, TravellerFieldEnum.Email)
    };
};
export const checkoutSelectedServicesStateFactory = (order) => {
    const selectedServices = {};
    order.travellers.forEach((traveller) => {
        var _a, _b;
        const passengerServices = {};
        if ((_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services.length) {
            traveller.services.gdsServices.services.forEach(service => {
                var _a, _b, _c;
                const passengerService = {
                    segments: service.segmentIds,
                    number: service.count,
                    servicePayload: {
                        id: service.serviceId,
                        type: (_c = (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(additionalService => additionalService.id === service.serviceId)) === null || _c === void 0 ? void 0 : _c.type,
                        confirmedCount: service.confirmedCount
                    }
                };
                if (passengerServices[service.serviceId]) {
                    passengerServices[service.serviceId].push(passengerService);
                }
                else {
                    passengerServices[service.serviceId] = [passengerService];
                }
            });
        }
        if ((_b = traveller.services.seats) === null || _b === void 0 ? void 0 : _b.length) {
            traveller.services.seats.forEach(service => {
                var _a;
                const seatService = {
                    segments: [service.segment.id],
                    servicePayload: {
                        id: (_a = service.seat.seatService) === null || _a === void 0 ? void 0 : _a.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Seat,
                        letter: service.seat.letter,
                        rfisc: service.seat.rfisc,
                        price: service.seat.price,
                        row: service.row.toString(),
                        segmentId: service.segment.id,
                        number: `${service.row}${service.letter}`,
                        isConfirmed: service.isConfirmed,
                        isChangeable: service.isChangeable,
                        seatServices: service.seat.seatServices,
                        seatService: service.seat.seatService,
                        seatType: service.type
                    },
                    number: 1
                };
                if (!passengerServices[`${service.row}${service.letter}`]) {
                    passengerServices[`${service.row}${service.letter}`] = [seatService];
                }
                else {
                    passengerServices[`${service.row}${service.letter}`].push(seatService);
                }
            });
        }
        selectedServices[traveller.id] = passengerServices;
    });
    return selectedServices;
};
export const getIncludedBaggageFromOrderTravellers = (travellers) => {
    const includedBaggages = [];
    travellers.forEach(traveller => {
        var _a, _b;
        return (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.brandIncludedServices) === null || _b === void 0 ? void 0 : _b.services.filter(services => services.service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage).forEach(service => {
            var _a, _b;
            const baggageService = service.service;
            const baggage = {
                id: service.serviceId,
                segmentIds: baggageService.allowedSegments,
                includedSegments: service.segmentIds,
                type: baggageService.baggageType,
                name: baggageService.name,
                description: baggageService.description,
                value: {
                    amount: parseInt((_b = (_a = baggageService.baggageWeight) === null || _a === void 0 ? void 0 : _a.split('x')) === null || _b === void 0 ? void 0 : _b[0]),
                    measurement: Measurement.Kilograms
                },
                size: baggageService.size,
                included: service.confirmedCount,
                price: baggageService.price,
                rfics: baggageService.rfisc,
                canBeAdded: false,
                allowedPassengers: [traveller.id]
            };
            includedBaggages.push(Object.assign(Object.assign({}, service), { baggage }));
        });
    });
    return includedBaggages;
};
export const getDefaultTimelineStep = (serviceType, direction) => {
    if (serviceType === 'transfer') {
        if (direction === AeroexpressDirection.ToAirport) {
            return TimelineStep.ToAirport;
        }
        else {
            return TimelineStep.Landing;
        }
    }
    if (serviceType === NASProductCategory.vip ||
        serviceType === NASProductCategory.fast_track ||
        serviceType === NASProductCategory.lounge ||
        serviceType === NASProductCategory.meet_and_greet) {
        if (direction === NASProductDirection.Departure) {
            return TimelineStep.Airport;
        }
        else if (direction === NASProductDirection.Arrival) {
            return TimelineStep.Landing;
        }
    }
    switch (serviceType) {
        case OrderAdditionalServiceGdsServiceServiceType.Seat:
        case OrderAdditionalServiceGdsServiceServiceType.Meal:
            return TimelineStep.Boarding;
        case OrderAdditionalServiceGdsServiceServiceType.Baggage:
        case NASProductCategory.lounge:
        case NASProductCategory.fast_track:
        case NASProductCategory.vip:
        case OrderAdditionalServiceGdsServiceServiceType.Vip:
            return TimelineStep.Airport;
        default:
            return null;
    }
};
export const getOrderPromoCodeInfoParams = (order, formValues) => {
    const travellersParam = [];
    let hasPromoCodes = false;
    order.travellers.forEach(traveller => {
        const travellerParamValues = [];
        let promoCode = null;
        traveller.values.forEach(travellerValue => {
            var _a;
            travellerParamValues.push({
                name: travellerValue.name,
                value: (_a = formValues['passengers'][traveller.id][travellerValue.name]) !== null && _a !== void 0 ? _a : null
            });
            if (travellerValue.type === TravellerFieldEnum.PromoCode) {
                promoCode = formValues['passengers'][traveller.id][travellerValue.name];
                if ((promoCode === null || promoCode === void 0 ? void 0 : promoCode.length) > 0) {
                    hasPromoCodes = true;
                }
            }
        });
        if (promoCode) {
            travellersParam.push({
                id: traveller.id,
                values: travellerParamValues,
                promoCode: promoCode
            });
        }
    });
    return hasPromoCodes
        ? {
            id: order.id,
            travellers: travellersParam
        }
        : null;
};
export const getTotalDiscount = (promoCodesInfo) => {
    var _a, _b;
    return (_b = (_a = promoCodesInfo === null || promoCodesInfo === void 0 ? void 0 : promoCodesInfo.travellers) === null || _a === void 0 ? void 0 : _a.reduce((total, tr) => { var _a, _b; return total + ((_b = +((_a = tr.promoCodeInfo) === null || _a === void 0 ? void 0 : _a.discount.amount)) !== null && _b !== void 0 ? _b : 0); }, 0)) !== null && _b !== void 0 ? _b : 0;
};
export const getWithValidPromoCodesFormValues = (formValues, promoCodesInfo, valuesNames) => {
    if (promoCodesInfo.success) {
        return formValues;
    }
    const passengers = formValues['passengers'];
    const promoCodeValueName = valuesNames.get(TravellerFieldEnum.PromoCode);
    return Object.assign(Object.assign({}, formValues), { passengers: passengers.map((passenger, idx) => {
            var _a;
            return (Object.assign(Object.assign({}, passenger), { [promoCodeValueName]: ((_a = promoCodesInfo.travellers[idx]) === null || _a === void 0 ? void 0 : _a.promoCodeInfo) ? passenger[promoCodeValueName] : null }));
        }) });
};
export const getIsAllSeatsSelected = (passengers, segments) => {
    return passengers
        .filter(passenger => passenger.type !== AviaPassengerType.INF)
        .every(passenger => {
        const selectedSeatsBySegment = getSelectedSeatsBySegment(passenger.seats);
        const segmentsWithAvailableSeats = segments.filter(segment => !!segment.availableSeatsCount);
        return segmentsWithAvailableSeats.length === selectedSeatsBySegment.size;
    });
};
export const getIsAllExtraSeatsSelected = (services, passengers, segments) => {
    if (!services.length) {
        return true;
    }
    const passengersWithExtraSeat = passengers.filter(passenger => services.find(service => service.passengerId === passenger.id));
    return passengersWithExtraSeat.every(passenger => {
        const selectedSeatsBySegment = getSelectedSeatsBySegment(passenger.seats);
        return segments.length === selectedSeatsBySegment.size;
    });
};
export const mergeDuplicatedTravellerServices = (services, keySelector) => {
    const servicesByKeyMap = new Map();
    services === null || services === void 0 ? void 0 : services.forEach(service => {
        var _a, _b, _c, _d;
        const key = keySelector(service);
        if (servicesByKeyMap.has(key)) {
            const serviceToUpdate = servicesByKeyMap.get(key);
            servicesByKeyMap.set(key, Object.assign(Object.assign({}, serviceToUpdate), { count: ((_a = serviceToUpdate.count) !== null && _a !== void 0 ? _a : 0) + ((_b = service.count) !== null && _b !== void 0 ? _b : 0), confirmedCount: ((_c = serviceToUpdate.confirmedCount) !== null && _c !== void 0 ? _c : 0) + ((_d = service.confirmedCount) !== null && _d !== void 0 ? _d : 0) }));
        }
        else {
            servicesByKeyMap.set(key, service);
        }
    });
    return [...servicesByKeyMap.values()];
};
export const isPetBaggage = (baggage) => {
    return [BaggageType.PetInCabin, BaggageType.PetInHandCarrier, BaggageType.PetInHold].includes(baggage.baggageType);
};
export const calculateCheckinAvailability = (order) => {
    const today = format(new Date(), API_DATE_FORMAT);
    const tomorrow = format(addDays(new Date(), 1), API_DATE_FORMAT);
    if (order.flight.segmentGroups.some(segmentGroup => segmentGroup.segments.some(segment => segment.departure.date === today))) {
        return true;
    }
    else {
        let segmentWithTomorrowDeparture = null;
        for (const segmentGroup of order.flight.segmentGroups) {
            segmentWithTomorrowDeparture = segmentGroup.segments.find(segment => segment.departure.date === tomorrow);
            if (segmentWithTomorrowDeparture) {
                break;
            }
        }
        return (segmentWithTomorrowDeparture &&
            new Date().getHours() > Number(segmentWithTomorrowDeparture.departure.time.substring(0, 2)));
    }
};
export const getIsBrandIncludedService = (order, serviceType) => {
    return order.travellers.some(traveller => {
        var _a;
        return (_a = traveller.services.brandIncludedServices.services) === null || _a === void 0 ? void 0 : _a.some(service => { var _a; return ((_a = service.service) === null || _a === void 0 ? void 0 : _a.type) === serviceType && service.count > 0; });
    });
};
export const getServicePrice = (service) => {
    if ((service === null || service === void 0 ? void 0 : service._serviceType) === 'insurance') {
        return service === null || service === void 0 ? void 0 : service.totalPrice;
    }
    return service === null || service === void 0 ? void 0 : service.price;
};
/**
 * Return services to delete
 * @param {CartServiceGds} payload - Summary gds service
 * @param {PassengerFragment[]} travellers - Travellers list
 * @returns {CardExtraService[]}
 */
export function getCartServicesToDelete(payload, travellers) {
    var _a, _b, _c;
    const removeServices = [];
    const commonRemoveService = {
        count: 0,
        serviceId: payload.serviceId,
        passengerId: payload.passengerId,
        allowedSegments: payload.allowedSegments
    };
    if (payload.allSegments) {
        const travellerServicesById = (_c = (_b = (_a = travellers.find(traveller => traveller.id === payload.passengerId)) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services;
        const service = travellerServicesById.find(service => service.serviceId === payload.serviceId);
        if (service) {
            service.segmentIds.forEach(segmentId => {
                removeServices.push(Object.assign(Object.assign({}, commonRemoveService), { segmentId, segmentIds: [segmentId] }));
            });
        }
    }
    else {
        removeServices.push(Object.assign(Object.assign({}, commonRemoveService), { segmentId: payload.segmentId, segmentIds: [payload.segmentId] }));
    }
    return removeServices;
}
export function getQueryServicesParams(location) {
    const queryParams = new URLSearchParams(location.search);
    const openServiceType = queryParams.get('openService');
    const serviceSubType = queryParams.get('subtype');
    const insuranceCode = queryParams.get('insuranceCode');
    return {
        queryParams,
        openServiceType,
        serviceSubType,
        insuranceCode
    };
}
export function getUrlWithServicesParams(url, location) {
    const { openServiceType, serviceSubType, insuranceCode } = getQueryServicesParams(location);
    if (!openServiceType) {
        return url;
    }
    let urlWithParams = url;
    if (openServiceType) {
        urlWithParams += `?openService=${openServiceType}`;
        if (serviceSubType) {
            urlWithParams += `&subtype=${serviceSubType}`;
        }
        else if (insuranceCode) {
            urlWithParams += `&insuranceCode=${insuranceCode}`;
        }
    }
    return urlWithParams;
}
