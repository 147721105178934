import * as React from 'react';
import { useCallback, useContext } from 'react';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { format, MOBILE_MIN_WIDTH } from '../../../../../../../utils';
import { DatepickerTab } from '../Datepicker';
import { useTheme } from '../../../../../../../theme';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../../../../../context';
import { tryParseDate } from './utils';
import ManualInput from './ManualInput/ManualInput';
import { CalendarIcon, CloseClear } from '../../../../../../../Icons';
const Value = ({ className, isOpen, open, close, setRouteType, segmentId, withDateBack, routeType, dateBack, dateTo, onClear, onDateSelect, setIsRouteTypeSetManually, onClickDatepickerTabReturn, onClickDatepickerTabOutbound }) => {
    const { Datepicker: css } = useTheme('SearchForm');
    const { t } = useTranslation('SearchForm');
    const alternative = useContext(ConfigContext).SearchForm.mobileFormType !== 'common';
    const proMode = React.useContext(ConfigContext).SearchForm.proMode;
    const onManualDateEnter = (value) => {
        const parseDate = tryParseDate(value);
        if (parseDate) {
            onDateSelect(parseDate, routeType === DatepickerTab.Return);
        }
    };
    const handleRouteTypeChange = useCallback((e, routeType) => {
        if (isOpen) {
            e.preventDefault();
            e.stopPropagation();
            setRouteType(routeType);
            setIsRouteTypeSetManually(true);
        }
        if (routeType === DatepickerTab.Return && onClickDatepickerTabReturn) {
            onClickDatepickerTabReturn();
        }
        if (routeType === DatepickerTab.Outbound && onClickDatepickerTabOutbound) {
            onClickDatepickerTabOutbound();
        }
    }, [routeType, isOpen, dateTo, dateBack]);
    return (React.createElement("div", { className: cn(className, css.dates, `${css.dates}_${segmentId}`, {
            [css.oneDate]: !withDateBack,
            [css.dates_focused]: isOpen,
            [css.alternative]: alternative
        }), onClick: () => {
            setRouteType(DatepickerTab.Outbound);
            isOpen ? close() : open();
        } },
        React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
            React.createElement("div", { className: cn(css.dates__to, {
                    [css.dates__to_focused]: isOpen && routeType === DatepickerTab.Outbound
                }) },
                React.createElement(ManualInput, { onEnter: onManualDateEnter, showInput: isOpen && proMode && routeType === DatepickerTab.Outbound, inputClassName: css.date__input },
                    React.createElement("span", { className: css.dates__icon }, CalendarIcon),
                    dateTo && (React.createElement("div", { className: css.date__inputWrapper, onClick: e => {
                            handleRouteTypeChange(e, DatepickerTab.Outbound);
                        } },
                        format(dateTo, 'dd MMM'),
                        React.createElement("span", { className: css.dow }, dateBack || isOpen ? format(dateTo, 'eeeeee') : format(dateTo, 'eeee')))),
                    !dateTo && isOpen && (React.createElement("span", { onClick: e => handleRouteTypeChange(e, DatepickerTab.Outbound) }, t('Outbound'))),
                    !dateTo && !dateBack && !isOpen && React.createElement("span", { className: css.placeholder }, t('Dates')))),
            withDateBack && (isOpen || dateBack) && (React.createElement("div", { className: cn(css.dates__back, {
                    [css.dates__back_focused]: isOpen && routeType === DatepickerTab.Return
                }) },
                React.createElement(ManualInput, { onEnter: onManualDateEnter, showInput: isOpen && proMode && routeType === DatepickerTab.Return, inputClassName: cn(css.date__input, css.date__input_back) }, dateBack ? (React.createElement("div", { onClick: e => {
                        handleRouteTypeChange(e, DatepickerTab.Return);
                    } },
                    format(dateBack, 'dd MMM'),
                    React.createElement("span", { className: css.dow }, format(dateBack, 'eeeeee')))) : (React.createElement("span", { onClick: e => handleRouteTypeChange(e, DatepickerTab.Return) }, t('Return')))))),
            (dateTo || dateBack) && isOpen && (React.createElement("div", { className: css.returnDelete, onClick: e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClear();
                } }, CloseClear))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: cn(css.dates__to) },
                React.createElement("span", { className: css.dates__to_text },
                    dateTo && (React.createElement("span", { className: css.dates__to_text },
                        format(dateTo, 'dd MMM'),
                        React.createElement("span", { className: css.dow }, format(dateTo, 'eeeeee')))),
                    dateBack && (React.createElement("span", { className: css.dates__to_text },
                        ` — ${format(dateBack, 'dd MMM')}`,
                        React.createElement("span", { className: css.dow }, format(dateBack, 'eeeeee'))))),
                alternative && dateTo && (React.createElement("div", { className: css.alternative_dow },
                    React.createElement("span", null, format(dateTo, 'eeee')),
                    dateBack && React.createElement("span", null, ` — ${format(dateBack, 'eeee')}`))),
                !dateTo &&
                    (isOpen ? (t('Outbound')) : (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: css.dates__icon }, CalendarIcon),
                        React.createElement("span", { className: css.placeholder }, t('Dates')))))))));
};
export default Value;
