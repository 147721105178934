import * as React from 'react';
import { TravellerFieldEnum, InfoMessageTypeEnum } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import { Flight, Person } from '../Icons';
import { useTheme } from '../theme';
import { API_DATE_FORMAT, format, getUserValue, initI18n } from '../utils';
import Notification from '../Notification';
import { parse } from 'date-fns';
import ModalConfirm from '../ModalConfirm/components/ModalConfirm';
initI18n('PassengerSubsidy');
const SubsidyRejectPopup = ({ open, segment, traveller, rejectMessage, onClose, onNewSearch }) => {
    const { t } = useTranslation('PassengerSubsidy');
    const theme = useTheme('SubsidyRejectPopup').SubsidyRejectPopup;
    if (!traveller) {
        return null;
    }
    const firstName = getUserValue(traveller, TravellerFieldEnum.FirstName), lastName = getUserValue(traveller, TravellerFieldEnum.LastName), birthDate = getUserValue(traveller, TravellerFieldEnum.BirthDate);
    return (React.createElement(ModalConfirm, { isOpen: open, onClose: onClose, size: "medium", title: t('Attention'), confirmBtn: t('New search'), onConfirm: onNewSearch, cancelBtn: t('Edit passengers data'), onCancel: onClose },
        segment && (React.createElement("div", { className: theme.flight },
            React.createElement("div", { className: theme.flight__direction },
                Flight,
                segment.departure.airport.city.name,
                " \u2013 ",
                segment.arrival.airport.city.name),
            React.createElement("div", null, format(parse(segment.departure.date, API_DATE_FORMAT, new Date()), 'd MMM, eeeeee')))),
        React.createElement("div", { className: theme.passenger },
            Person,
            firstName,
            " ",
            lastName,
            ", ",
            birthDate),
        React.createElement(Notification, { title: rejectMessage, status: InfoMessageTypeEnum.Warning, type: "context", titleClassName: theme.notification__title, infoMessage: {
                title: rejectMessage,
                text: t('A subsidized fare requires all passengers on the booking to have benefits.'),
                type: InfoMessageTypeEnum.Warning,
                code: null
            } })));
};
export default SubsidyRejectPopup;
