import { createSelector } from 'reselect';
export const getPassengers = (refundState) => Object.entries(refundState.passengers).map(([, value]) => value);
export const getPassengersWithChildren = createSelector(getPassengers, passengers => passengers.filter(passenger => { var _a; return ((_a = passenger.children) === null || _a === void 0 ? void 0 : _a.length) > 0; }));
export const getSelectedPassengers = createSelector(getPassengers, passengers => passengers.filter(passenger => passenger.selected));
export const getHasSelectedPassengers = createSelector(getSelectedPassengers, passengers => passengers.length > 0);
export const getSelectedPassengersWithChildrenIds = createSelector(getSelectedPassengers, (selectedPassengers) => {
    const selectedIds = new Set();
    selectedPassengers.forEach(passenger => {
        var _a;
        const passengerId = Number(passenger.id);
        selectedIds.add(passengerId);
        if ((_a = passenger.children) === null || _a === void 0 ? void 0 : _a.length) {
            passenger.children.forEach(child => selectedIds.add(Number(child.id)));
        }
    });
    return [...selectedIds];
});
