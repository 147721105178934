import { produce } from 'immer';
const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'setActiveService':
            return produce(state, draft => {
                draft.activeService = payload;
                return draft;
            });
        case 'setService': {
            return produce(state, draft => {
                for (const [passenger] of Object.keys(payload)) {
                    for (const [segment] of Object.keys(payload[passenger])) {
                        if (!payload[passenger][segment]) {
                            continue;
                        }
                        const relatedServiceIndex = draft.services[passenger][segment].findIndex(service => service.service.id === payload[passenger][segment].service.id);
                        draft.services[passenger][segment][relatedServiceIndex] = payload[passenger][segment];
                    }
                }
                return draft;
            });
        }
        case 'toggleTransfer':
            return produce(state, draft => {
                const stateService = state.services[payload.passengerId][payload.segmentId].find(service => service.service.id === payload.service.id);
                stateService.selected = !stateService.selected;
                // тут можно добавить логику по добавлению на все сегменты из allowedSegments
                // но я пока не представляю в каких случаях на трансферных рейсах можно продавать такси
                // поэтому реализацию опустил
                return draft;
            });
        default:
            return state;
    }
};
export default reducer;
