import { ADD_SELECTED_FARE, SLICE_SELECTED_FARES } from './actions';
export const selectedFaresReducer = (state = [], action) => {
    switch (action.type) {
        case ADD_SELECTED_FARE:
            return [...state, action.payload];
        case SLICE_SELECTED_FARES:
            return state.slice(0, action.payload);
        default:
            return state;
    }
};
