import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import PromoLoader from '../PromoLoader';
import { useConfig } from '../../../context';
const DelayedPromoLoader = ({ loading, children }) => {
    const { global: { companyInfo: { iataCode } } } = useConfig();
    const isF7 = iataCode === 'F7';
    const showDefaultPromoLoader = !isF7;
    if (showDefaultPromoLoader) {
        return loading ? React.createElement(PromoLoader, null) : React.createElement(React.Fragment, null, children);
    }
    const updateId = useRef(0);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        let timeoutId;
        if (loading === true) {
            setIsLoading(true);
            updateId.current++;
        }
        if (loading === false && updateId.current > 0) {
            timeoutId = setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [loading]);
    return isLoading ? React.createElement(PromoLoader, null) : React.createElement(React.Fragment, null, children);
};
export default DelayedPromoLoader;
