import i18n from 'i18next';
export const getTransferShortLabel = (segments) => {
    if (segments.length > 1) {
        const transfers = [];
        segments.forEach(({ segment }, index) => {
            if (index > 0) {
                transfers.push(segment.departure.airport.iata);
            }
        });
        return `${transfers.length} ${i18n.t('MobileSegmentInfo:stop')} ${i18n.t('MobileSegmentInfo:in')} ${transfers.join(', ')} • `;
    }
    return '';
};
export const isDifferentAirportName = (airport) => {
    return airport.city && airport.city.name !== airport.name;
};
// this function used in new version of mobileSegmentInfo component
export const getTransferInfo = (segments) => {
    if (segments.length < 1) {
        return {
            iata: null,
            transfersCount: 0
        };
    }
    const transfersIata = segments.slice(1).map(segment => segment.segment.departure.airport.iata);
    return {
        iata: transfersIata,
        transfersCount: transfersIata.length
    };
};
