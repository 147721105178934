import React from 'react';
import Button from '../../../../BaseComponents/Button';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Money from '../../../../Money/components/Money';
import { overridedIcons } from '../../../../Icons';
import PriceInMiles from '../../../../PriceInMiles/PriceInMiles';
const FareButton = ({ priceInMiles, price, subscription, originalPrice, onClick, disabled, selected, className, onTouchEndCapture }) => {
    const theme = useTheme('Flights').FareButton;
    const { t } = useTranslation('FareGroup');
    const showOriginalPrice = originalPrice && originalPrice.amount !== price.amount;
    const hideSelectText = originalPrice && originalPrice.amount !== price.amount && originalPrice.amount.toString().length > 5;
    return (React.createElement(Button, { classes: {
            root: cn(theme.root, className),
            disabled: theme.disabled,
            endIcon: theme.endIcon,
            startIcon: theme.startIcon
        }, className: cn({ [theme.selected]: selected, [theme.subscription]: subscription }), disabled: disabled, onClick: onClick, startIcon: selected && overridedIcons['Choosed'], endIcon: disabled && overridedIcons['NotIncluded'], onTouchEndCapture: onTouchEndCapture, disableRipple: selected }, !disabled ? (React.createElement(React.Fragment, null,
        !hideSelectText && React.createElement("span", { className: theme.title }, t(selected ? 'Selected' : 'Select')),
        priceInMiles ? (React.createElement(PriceInMiles, { amount: priceInMiles, amountClassName: theme.miles_amount, moneyClassName: theme.miles })) : (React.createElement("div", { className: cn(theme.money_wrapper, { [theme.money_wrapper_center]: hideSelectText }) },
            showOriginalPrice && React.createElement(Money, { moneyClassName: theme.money_original, money: originalPrice }),
            React.createElement(Money, { moneyClassName: theme.money, money: price }))))) : (React.createElement(React.Fragment, null, t('Unavailable')))));
};
export default FareButton;
