import { __rest } from "tslib";
import * as React from 'react';
import { Datepicker as DatepickerComponent } from '../../../../../../../Datepicker/index';
import { ThemeProvider, useTheme } from '../../../../../../../theme';
import { mergeCSS } from '../../../../../../../utils';
const Datepicker = props => {
    const defaultDatepickerTheme = useTheme('Datepicker');
    const _a = useTheme('SearchFormDatepicker'), { SearchFormDatepickerStyles: datepickerTheme } = _a, customDatepickerTheme = __rest(_a, ["SearchFormDatepickerStyles"]);
    const customStyle = {
        Datepicker: Object.assign(Object.assign({}, customDatepickerTheme), { PopupDatepickerStyles: {
                block: props.popupDatepickerStyles
            } })
    };
    const defaultStyles = {
        Datepicker: Object.assign({}, defaultDatepickerTheme)
    };
    const styles = mergeCSS(defaultStyles, customStyle);
    return (React.createElement(ThemeProvider, { value: styles },
        React.createElement(DatepickerComponent, Object.assign({}, props, { highlightedDates: {
                [props.highlightClassName]: props.availableDates
            } })),
        props.renderFooter && props.renderFooter(props)));
};
export default Datepicker;
