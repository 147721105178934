import * as React from 'react';
import { useTheme } from '../../../../../theme';
import { Chevron } from '../../../../../Icons';
import cn from 'classnames';
const LoginTypeSelector = ({ selected, type, value, onClick }) => {
    const { LoginTypeSelector: css } = useTheme('LoginPage');
    return (React.createElement("div", { className: cn(css.item, { [css.selected]: selected }), onClick: onClick },
        React.createElement("div", { className: css.info },
            React.createElement("span", { className: css.type }, type),
            value && React.createElement("span", null, value)),
        React.createElement("span", { className: css.icon }, Chevron)));
};
export default LoginTypeSelector;
