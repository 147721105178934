import * as React from 'react';
import { Passenger } from './Passenger/Passenger';
import { PassengerType } from '../../enums';
import { useTheme } from '../../theme';
import { initI18n, useIsMobile } from '../../utils';
import cn from 'classnames';
import { BaseButton } from '../../BaseComponents';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { useCallback } from 'react';
initI18n('Passengers');
export const Passengers = React.memo(({ passengers, fareGroups, currentGroup, setCurrentGroup, addPassenger, removePassenger }) => {
    const { PassengersStyles: css } = useTheme('Passengers');
    const { t } = useTranslation('Passengers');
    const isMobile = useIsMobile();
    const PassengersList = () => {
        return (React.createElement("ul", { className: css.list }, passengers.map(passenger => (React.createElement("li", { "data-pass-type": passenger.type, key: passenger.type, className: cn(css.item, {
                [css.item_FNN]: passenger.type === PassengerType.Teenager
            }) },
            React.createElement(Passenger, { passenger: passenger, addPassenger: addPassenger, removePassenger: removePassenger, decreaseIsEnabled: passenger.canBeDecreased, increaseIsEnabled: passenger.canBeIncreased }))))));
    };
    if (!fareGroups || fareGroups.length <= 1) {
        return React.createElement(PassengersList, null);
    }
    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        initialSlide: (fareGroups === null || fareGroups === void 0 ? void 0 : fareGroups.findIndex(group => group === currentGroup)) || 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        variableWidth: true,
        centerMode: false,
        arrows: true
    };
    const renderFares = useCallback(() => {
        return fareGroups.map(fareGroup => (React.createElement(BaseButton, { onClick: () => {
                setCurrentGroup === null || setCurrentGroup === void 0 ? void 0 : setCurrentGroup(fareGroup);
            }, key: fareGroup, variant: "text", className: cn(css.fareGroup, fareGroup === currentGroup ? css.currentGroup : null) }, t(fareGroup))));
    }, [currentGroup]);
    return (React.createElement("div", { className: css.withGroupsWrapper },
        React.createElement("div", { className: css.groupsWrapper },
            React.createElement("div", { className: css.groupsTitle }, t('Choose a fare')),
            React.createElement("div", { className: css.fareGroups },
                isMobile && (React.createElement(Slider, Object.assign({}, sliderSettings, { className: css.slider }), renderFares())),
                !isMobile && React.createElement(React.Fragment, null, renderFares()))),
        React.createElement("div", { className: css.withGroupsDelimiter }),
        React.createElement("div", { className: css.passengersWrapper },
            React.createElement("div", { className: css.passengersTitle }, t('Choose passengers')),
            React.createElement(PassengersList, null))));
});
export default Passengers;
