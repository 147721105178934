import * as PassengerStyles from './components/Passenger.css';
import * as Switch from './components/Field/Switch/Switch.css';
import * as Select from './components/Field/Select/Select.css';
import * as LastName from './components/Field/LastName/LastName.css';
import * as LinkedTraveller from './components/Field/LinkedTraveller/LinkedTraveller.css';
export default {
    PassengerStyles,
    Switch,
    Select,
    LastName,
    LinkedTraveller
};
