import * as RefundSummary from './components/RefundSummary.css';
import * as Stub from './components/Services/Stub/Stub.css';
import * as Notice from './components/Notice/Notice.css';
import * as CommonContent from './components/Services/CommonContent.css';
export default {
    RefundSummary,
    Stub,
    Notice,
    CommonContent
};
