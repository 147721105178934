import * as React from 'react';
import { Money } from '../../Money';
import { useTheme } from '../theme';
import { VoucherStatus } from '../types';
import Tooltip from '../../Tooltip/Tooltip';
import MediaQuery from 'react-responsive';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
initI18n('Voucher');
export const Voucher = props => {
    const theme = useTheme().VoucherTheme;
    const { t } = useTranslation('Voucher');
    const [isLoading, setIsLoading] = React.useState(false), [codeValid, setCodeValid] = React.useState(false);
    let inputRef = null;
    const addVoucher = () => {
        const value = inputRef.value;
        if (value && !isLoading) {
            props.addVoucher && props.addVoucher(inputRef.value);
            setIsLoading(true);
        }
    };
    const onKeyUp = (e) => {
        const value = inputRef.value;
        setCodeValid(value.length >= props.minSymbolsCount);
        if (e.key === 'Enter' && codeValid) {
            addVoucher();
        }
    };
    React.useEffect(() => {
        if (props.voucherCode) {
            setIsLoading(false);
        }
    }, [props.voucherCode]);
    return (React.createElement("div", { className: theme.container },
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: theme.voucher__header },
                t('E-voucher'),
                React.createElement(Tooltip, { placement: "left", title: props.detailsTooltip, tooltipClassName: theme.mobileTooltip },
                    React.createElement("div", { className: theme.info })))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Tooltip, { title: props.detailsTooltip },
                React.createElement("div", { className: theme.info }, t('Details')))),
        React.createElement("div", { className: theme.vouchersList },
            props.voucherCode && (React.createElement("div", { className: theme.voucher },
                React.createElement("div", { className: theme.voucher__read },
                    React.createElement("span", null, props.voucherCode),
                    React.createElement("div", { className: theme.voucher__right },
                        props.voucherStatus === VoucherStatus.Complete && props.voucherMoney && (React.createElement(Money, { moneyClassName: theme.voucher__discount, money: Object.assign({}, props.voucherMoney) })),
                        React.createElement("div", { onClick: () => props.removeVoucher && props.removeVoucher(), className: theme.voucher__remove }))))),
            !props.voucherCode && (React.createElement("div", { className: theme.voucher },
                React.createElement("input", { className: cn(theme.voucher__input, { [theme.voucher__input_load]: isLoading }), readOnly: isLoading, autoFocus: true, autoComplete: "one-time-code", autoCorrect: "none", spellCheck: false, ref: ref => (inputRef = ref), onKeyUp: onKeyUp, placeholder: t('Enter code') })))),
        React.createElement("div", { className: theme.footer },
            props.voucherStatus === VoucherStatus.Complete && (React.createElement(Button, { className: theme.button, variant: "flat", onClick: props.onDone }, t('Done'))),
            props.voucherStatus !== VoucherStatus.Complete && (React.createElement(Button, { className: cn(theme.button, {
                    [theme.button_disabled]: !codeValid,
                    [theme.button_loader]: isLoading
                }), disabled: !codeValid, variant: "flat", onClick: addVoucher }, t('Add'))))));
};
export default Voucher;
