import * as React from 'react';
import { useTheme } from '../../../../theme';
const ListTransition = props => {
    const innerRef = React.useRef(null), outerRef = React.useRef(null), theme = useTheme('Contacts').ListTransitionStyles;
    React.useEffect(() => {
        innerRef.current.childNodes.forEach((child, index) => {
            child.classList.add(theme.item);
            if (index === props.visible) {
                child.classList.remove(theme.item_gone);
            }
            else {
                child.classList.add(theme.item_gone);
            }
        });
        const height = outerRef.current.offsetHeight;
        innerRef.current.style.transform = `translateY(-${height * props.visible}px)`;
    }, [props.visible]);
    return (React.createElement("div", { ref: outerRef, className: theme.container },
        React.createElement("div", { ref: innerRef, className: theme.list }, props.children)));
};
export default ListTransition;
