import WebskyServicesAnalytics from '../DataLayerAnalytics/WebskyServicesAnalytics';
import getOrderDataLayer from '../DataLayerAnalytics/orderDataLayerInstance';
/**
 * Calculate the difference in the number of services in two states
 *
 * @param oldSelectedServices array of services before changing
 * @param newSelectedServices array of services after changing
 *
 * @return array of Service with calculated field `delta`. If `delta` > 0, the number of service items was increased.
 * If `delta` < 0, the number was decreased.
 */
export const servicesDiff = (oldSelectedServices, newSelectedServices) => {
    if (!oldSelectedServices.length) {
        return newSelectedServices.map(service => (Object.assign(Object.assign({}, service), { delta: service.count })));
    }
    if (!newSelectedServices.length) {
        return oldSelectedServices.map(service => (Object.assign(Object.assign({}, service), { delta: 0 - service.count })));
    }
    const servicesModifyMap = new Map();
    oldSelectedServices.forEach(service => {
        servicesModifyMap.set(service.id, {
            count: service.count,
            service: service.service,
            delta: 0 - service.count
        });
    });
    newSelectedServices.forEach(service => {
        if (servicesModifyMap.has(service.id)) {
            const existsService = servicesModifyMap.get(service.id);
            servicesModifyMap.set(service.id, Object.assign(Object.assign({}, existsService), { count: service.count, delta: service.count + existsService.delta }));
        }
        else {
            servicesModifyMap.set(service.id, {
                count: service.count,
                service: service.service,
                delta: service.count
            });
        }
    });
    const result = [];
    servicesModifyMap.forEach((value, serviceId) => {
        result.push({
            delta: value.delta,
            id: serviceId,
            count: value.count,
            service: value.service
        });
    });
    return result;
};
const filterInsurances = (programs) => {
    const uniqueItems = [];
    const idsSet = new Set();
    programs.forEach(program => {
        if (!idsSet.has(program.code)) {
            idsSet.add(program.code);
            uniqueItems.push(program);
        }
    });
    return uniqueItems;
};
export const getInsurancesDiff = (oldSelected, newSelected) => {
    const diff = servicesDiff(filterInsurances(oldSelected).map(insurance => ({
        id: insurance.code,
        count: 1,
        service: insurance
    })), filterInsurances(newSelected).map(insurance => ({
        id: insurance.code,
        count: 1,
        service: insurance
    })));
    return {
        added: diff.filter(service => service.delta > 0).map(service => service.service),
        removed: diff.filter(service => service.delta < 0).map(service => service.service)
    };
};
export const analytics_addInsurance = (insurance, travellers) => {
    getOrderDataLayer().addServicesToCart(WebskyServicesAnalytics.insurances([insurance], travellers));
};
export const analytics_removeInsurance = (insurance, travellers) => {
    getOrderDataLayer().removeServicesFromCart(WebskyServicesAnalytics.insurances([insurance], travellers));
};
