import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getTravellersMap } from '../../../utils';
import { GdsServiceVipSubType } from '@websky/graphql';
import { PriorityBoarding as PriorityBoardingIcon } from '../../../Icons';
import VipSummary from '../../../ServiceSummary/services/VipSummary';
const PriorityBoarding = ({ travellers, services, flight, onServiceDelete }) => {
    const { t } = useTranslation('Cart');
    return (React.createElement(VipSummary, { services: services, onServiceDelete: onServiceDelete, travellers: travellers, travellersById: getTravellersMap(travellers), flight: flight, serviceName: t('Priority boarding'), serviceSubType: GdsServiceVipSubType.PriorityBoarding, headerIcon: PriorityBoardingIcon }, children => children && React.createElement("span", null, children)));
};
export default PriorityBoarding;
