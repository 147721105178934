import { __awaiter } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import Traveller from '../../Traveller';
import Loader from '../../Loader';
import { useGetTravellersQuery, useDefaultTravellerFieldsQuery, useDeleteTravellerMutation } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import { TravellerTypes } from '../../Traveller/types';
import { useTheme } from '../../theme';
import { useCreateTraveller, useSubmitPersonalDetails, useUpdateTraveller } from '../hooks';
export var renderType;
(function (renderType) {
    renderType[renderType["Passengers"] = 0] = "Passengers";
    renderType[renderType["Companions"] = 1] = "Companions";
})(renderType || (renderType = {}));
const TravelCompanions = ({ format }) => {
    var _a, _b, _c;
    const getTravellerFields = useDefaultTravellerFieldsQuery();
    const getCompanions = useGetTravellersQuery();
    const [deleteTravellerProfile] = useDeleteTravellerMutation();
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation('Account');
    const theme = useTheme('TravelCompanions').TravelCompanions;
    const { Account: accountTheme } = useTheme('AccountModule');
    const { submit: submitPersonalDetails } = useSubmitPersonalDetails();
    const onSubmitPersonalDetails = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _d;
        const result = yield submitPersonalDetails(values);
        if ((_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.UpdateUserProfile) {
            getCompanions.refetch();
            return true;
        }
        return false;
    });
    const { submit: createNewCompanion } = useCreateTraveller();
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const res = yield createNewCompanion(values);
            if (res && res.data.CreateTravellerProfile) {
                getCompanions.refetch();
                setTimeout(() => {
                    setExpanded(false);
                }, 1000);
                return true;
            }
        }
        catch (_e) {
            return false;
        }
        return false;
    });
    const { submit: updateTravellerSubmit } = useUpdateTraveller();
    const updateTraveller = (values, id) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield updateTravellerSubmit(values, id);
        if (res.data.UpdateTravellerProfile) {
            yield getCompanions.refetch();
            return true;
        }
        else {
            return false;
        }
    });
    const deleteTraveller = (id) => __awaiter(void 0, void 0, void 0, function* () {
        setExpanded(false);
        const res = yield deleteTravellerProfile({
            variables: {
                id
            }
        });
        if (res.data.DeleteTravellerProfile) {
            getCompanions.refetch();
            return true;
        }
        else {
            return false;
        }
    });
    return (React.createElement(React.Fragment, null, !getCompanions.loading &&
        ((_b = (_a = getCompanions === null || getCompanions === void 0 ? void 0 : getCompanions.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.travellerProfiles) &&
        !getTravellerFields.loading &&
        ((_c = getTravellerFields.data) === null || _c === void 0 ? void 0 : _c.DefaultTravellerProfile) ? (React.createElement("div", { className: theme.companions },
        format === renderType.Passengers && (React.createElement(Traveller, { onSubmit: onSubmitPersonalDetails, type: TravellerTypes.PersonalDetails, traveller: Object.assign(Object.assign({}, getCompanions.data.CurrentUser.userProfile), { canBeDeleted: false }), expanded: expanded === 0, handleChange: () => {
                if (expanded === 0) {
                    setExpanded(false);
                }
                else {
                    setExpanded(0);
                }
            } })),
        format === renderType.Companions && (React.createElement(React.Fragment, null,
            React.createElement("h1", { className: accountTheme.route__title }, t('Travel companions')),
            React.createElement("div", { className: theme.description },
                React.createElement("p", null, t('Usually traveling with a friend or family?')),
                React.createElement("p", null, t('Add them to your companions list and make checking-in altogether easier.'))),
            React.createElement(Traveller, { onSubmit: onSubmit, handleChange: () => {
                    if (expanded === 1) {
                        setExpanded(false);
                    }
                    else {
                        setExpanded(1);
                    }
                }, traveller: getTravellerFields.data.DefaultTravellerProfile, expanded: expanded === 1, type: TravellerTypes.CompanionEmpty }),
            getCompanions.data.CurrentUser.travellerProfiles.map((traveller, index) => (React.createElement(Traveller, { key: traveller.id, expanded: index + 2 === expanded, handleChange: () => {
                    if (index + 2 === expanded) {
                        setExpanded(false);
                    }
                    else {
                        setExpanded(index + 2);
                    }
                }, onSubmit: values => updateTraveller(values, traveller.id), onDelete: deleteTraveller, traveller: traveller, type: TravellerTypes.CompanionFilled }))))))) : (React.createElement(Loader, null))));
};
export default TravelCompanions;
