import * as React from 'react';
import { useEffect, useMemo } from 'react';
import ButtonBase from '../../BaseComponents/Button';
import cn from 'classnames';
import { Money } from '../../Money';
import { getDurationString, getMinPrices } from '../../Results/utils';
import { useTheme } from '../../theme';
import { MobileSegmentFlat } from '../../MobileSegmentInfo';
import { useTranslation } from 'react-i18next';
import { InfoMessagePositionsEnum } from '@websky/graphql';
import Modal from '../../Modal';
import DesktopFlightModalContent from '../../DesktopFlight/components/DesktopFlightModalContent/DesktopFlightModalContent';
import { useDesktopFlightWithPopup } from '../../DesktopFlight/components/DesktopFlightPopup/hooks';
import PriceInMiles from '../../PriceInMiles/PriceInMiles';
import { CloseClear, Expand, InfoDetails } from '../../Icons';
import { Collapse } from '@material-ui/core';
import TotalDuration from '../../DesktopFlightRoute/components/TotalDuration/TotalDuration';
import Tooltip from '../../Tooltip';
import { useConfig } from '../../context';
import TransitTooltip from '../../TransitTooltip';
import { TransitTooltipContext } from '../../TransitTooltip/utils';
import InfoMessages from '../../InfoMessages';
const Flight = props => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { t } = useTranslation('FlightPrice');
    const { showAirplane, flight, selectedFares = [], isPreOpen, searchParameters, onSubsidySuggestPopupOpen, renderSelectButton } = props;
    const { subStatuses, upgradeOptions, economyFareGroups, businessFareGroups, milesFareGroups, flightSegments, lastSelectedFare, isModalOpen, openModal, closeModal, getOnFareSelect } = useDesktopFlightWithPopup(props);
    useEffect(() => {
        if (isModalOpen && props.onSetViewFlight) {
            props.onSetViewFlight(flight);
        }
    }, [isModalOpen]);
    const { DesktopFlightPopup: popupCss } = useTheme('DesktopFlight');
    const { FlightStyles: theme } = useTheme('MobileFlight');
    const [open, setOpen] = React.useState(props.readonly);
    const { isTransitTariff } = React.useContext(TransitTooltipContext);
    const { global: { companyInfo: { subsidyFaresConditionURL } } } = useConfig();
    const flightId = (_e = (_d = (_c = (_b = (_a = flight.pricesForFareGroups) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.prices) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.flight) === null || _e === void 0 ? void 0 : _e.id;
    const [fareFamily, selectedFare] = useMemo(() => {
        if (!props.isSelected) {
            return [null, null];
        }
        const fareFamily = flight.pricesForFareGroups[0].fareFamily;
        return [fareFamily, selectedFares[0]];
    }, [flight, selectedFares, props.isSelected]);
    const [price, originalPrice, priceInMiles, prices] = useMemo(() => {
        var _a, _b, _c, _d;
        const prices = getMinPrices(flight.pricesForFareGroups, true, selectedFares, false, props.preselectedFlightClass);
        const minOriginalPrice = getMinPrices(flight.pricesForFareGroups, true, selectedFares, true, props.preselectedFlightClass);
        const minPrice = prices[0] || prices[1];
        return [
            minPrice,
            ((_b = (_a = minOriginalPrice[0]) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.amount) > ((_c = minPrice === null || minPrice === void 0 ? void 0 : minPrice.price) === null || _c === void 0 ? void 0 : _c.amount) && ((_d = minOriginalPrice[0]) === null || _d === void 0 ? void 0 : _d.price),
            prices[0].priceInMiles,
            prices
        ];
    }, [flight, selectedFares, props.preselectedFlightClass]);
    const stopPoints = React.useMemo(() => {
        if (flightSegments.length > 1) {
            return flightSegments
                .filter(segmentWithTransfer => !!segmentWithTransfer.transferDuration)
                .map(segmentWithTransfer => {
                return {
                    airport: segmentWithTransfer.segment.arrival,
                    duration: getDurationString(segmentWithTransfer.transferDuration)
                };
            });
        }
        return [];
    }, [flight]);
    useEffect(() => {
        if (isPreOpen) {
            closeModal();
        }
    }, [isPreOpen]);
    const onExpandHandler = () => setOpen(!open);
    const renderSegment = (segments, segmentId, last, first, multipleSegment) => {
        return (React.createElement("div", { className: cn(theme.segment__container, {
                [theme.summary]: multipleSegment
            }), onClick: multipleSegment && !props.canceled ? onExpandHandler : null, key: segmentId },
            first && (React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.flight, className: theme.flightInfoMessage, itemHtmlClassName: theme.flightInfoMessage__htmlMessage, messages: flight.segments[0].segment.messages })),
            React.createElement(MobileSegmentFlat, { segments: segments, showAirplaneInfo: (flightSegments.length === 1 && !selectedFare) || open, className: theme.segment, stopCount: multipleSegment && stopPoints.length, last: last, multipleSegments: multipleSegment, totalDuration: flightSegments.length > 1 && last ? (React.createElement(TotalDuration, { lastSegment: segments[0].segment, flight: flight })) : null, renderCompanyName: props.renderCompanyName, isReturnFlight: props.isReturnFlight }),
            multipleSegment &&
                stopPoints.map(stop => {
                    return (React.createElement("div", { className: theme.stopPoint },
                        stop.airport.airport.city.name,
                        " \u2013 ",
                        stop.duration));
                })));
    };
    const renderSubsidyInfo = () => {
        return (React.createElement(Tooltip, { title: t('A subsidized fare requires all passengers on the booking to have benefits.') },
            React.createElement("a", { href: subsidyFaresConditionURL, onClick: e => e.stopPropagation(), target: "_blank", className: theme.subsidies },
                t('Subsidized flight'),
                " ",
                InfoDetails)));
    };
    const isExpand = flightSegments.length > 1 && !props.readonly && !props.canceled;
    return (React.createElement("div", { className: cn(theme.flight, {
            [theme.flight_withAircraft]: showAirplane,
            [theme.flight_selected]: props.isSelected,
            [theme.readonly]: props.readonly,
            [theme.expanded]: isExpand
        }) },
        props.flightIcon,
        React.createElement("div", { className: cn(theme.segments, {
                [theme.segments_expanded]: open
            }) },
            flightSegments.length > 1 && (React.createElement(React.Fragment, null,
                React.createElement(Collapse, { in: open },
                    React.createElement("div", null, flightSegments.map((segment, index) => {
                        return renderSegment([segment], `c_${index}`, index === flightSegments.length - 1, index === 0, false);
                    }))),
                !open && renderSegment(flightSegments, '0', true, false, true),
                isExpand && (React.createElement("div", { className: cn(theme.expand, {
                        [theme.expand_open]: open
                    }), onClick: onExpandHandler }, Expand)))),
            flightSegments.length === 1 && renderSegment(flightSegments, '0', true, true)), (_f = renderSelectButton === null || renderSelectButton === void 0 ? void 0 : renderSelectButton({
        readonly: props.readonly,
        isSelected: props.isSelected,
        hidePrice: props.hidePrice,
        prices
    })) !== null && _f !== void 0 ? _f : (React.createElement(React.Fragment, null, !props.readonly && !props.isSelected && (React.createElement("div", { className: theme.price__wrapper },
        isTransitTariff && (React.createElement("div", { className: theme.transitTariff },
            React.createElement(TransitTooltip, null))),
        originalPrice && (React.createElement("div", { className: theme.price_original },
            React.createElement(Money, { money: originalPrice, moneyClassName: theme.price_originalMoney }))),
        React.createElement(ButtonBase, { variant: "optionAction", className: theme.price, onClick: onSubsidySuggestPopupOpen ? () => onSubsidySuggestPopupOpen(openModal) : openModal }, !props.hidePrice && !props.hideOnlyPrice ? (React.createElement("div", { className: theme.price__text },
            React.createElement("span", null, t('Select from')),
            priceInMiles ? (React.createElement(PriceInMiles, { amount: priceInMiles, moneyClassName: cn(theme.price__money, theme.price__miles), currencyClassName: theme.currency })) : (React.createElement(Money, { moneyClassName: theme.price__money, money: price.price })))) : (React.createElement("div", { className: theme.price__text },
            React.createElement("span", null, t('Select'))))),
        React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.flightPrice, className: theme.infoMessage, itemHtmlClassName: theme.infoMessage__htmlMessage, messages: flight.segments[0].segment.messages }))))),
        selectedFare && !subStatuses.length && (React.createElement("div", { className: theme.selectedFare },
            React.createElement("div", null,
                prices[0].hasSubsidyFare && renderSubsidyInfo(),
                fareFamily.title),
            React.createElement("div", { className: theme.selectedFare__priceWrapper },
                props.hidePrice ? null : !props.hideOnlyPrice ? (React.createElement(Money, { moneyClassName: theme.selectedFare__price, money: selectedFare.prices[0].price })) : null,
                props.onClear && (React.createElement("div", { onClick: props.onClear, className: theme.price__close }, CloseClear))))),
        !!subStatuses.length && (React.createElement("div", { className: theme.subStatus },
            React.createElement("span", null, t(`DesktopFlightInfo:SubStatus_${subStatuses[0]}`)))),
        priceInMiles && (React.createElement("div", { className: theme.miles__taxes },
            t('taxes and fees'),
            ":",
            React.createElement(Money, { money: price.price }))),
        React.createElement(Modal, { isRounded: true, maxWidth: 'md', open: isModalOpen, onClose: closeModal, classes: { paper: popupCss.paper, scrollBody: popupCss.scrollBody }, fullScreen: true, closeButtonRenderer: () => null },
            React.createElement(DesktopFlightModalContent, { lastSelectedFare: (_g = flight.pricesForFareGroups.find(fareGroup => { var _a; return fareGroup && ((_a = fareGroup.fareFamily) === null || _a === void 0 ? void 0 : _a.id) === lastSelectedFare; })) === null || _g === void 0 ? void 0 : _g.fareFamily, upgradeOptions: upgradeOptions, flight: flight, flightId: flightId, orderId: props.orderId, economyFares: economyFareGroups, businessFares: businessFareGroups, milesFares: milesFareGroups, getOnFareSelect: getOnFareSelect, hidePrice: props.hidePrice, searchParameters: searchParameters, preselectedFlightClass: props.preselectedFlightClass, onClose: closeModal }))));
};
export default Flight;
