import classnames from 'classnames';
import { addMonths, getMonth, startOfYear } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { isMonthAvailable } from '../../../../utils';
import { RendererFunctionsContext } from '../../../Datepicker';
import { Dropdown } from '../../../Dropdown/Dropdown';
export const MonthHeader = props => {
    const { t } = useTranslation('Datepicker');
    let selectedYear = 0, selectedMonth = 0;
    const monthHeaderAsPlainText = React.useContext(RendererFunctionsContext)
        .monthHeaderAsPlainText;
    const elements = [], month = getMonth(props.currentDate), year = !monthHeaderAsPlainText
        ? props.years.map(year => {
            if (year.getFullYear() === props.currentDate.getFullYear()) {
                selectedYear = year.getTime();
            }
            return {
                id: year.getTime(),
                text: year.getFullYear().toString(),
                isActive: true
            };
        })
        : [];
    if (!monthHeaderAsPlainText) {
        let initialDate = startOfYear(props.currentDate);
        for (let i = 0; i < 12; i++) {
            elements.push({
                id: initialDate.getTime(),
                isActive: isMonthAvailable(initialDate, 0, props.minDate, props.maxDate),
                text: t(`month_${i}`)
            });
            if (month === i) {
                selectedMonth = initialDate.getTime();
            }
            initialDate = addMonths(initialDate, 1);
        }
    }
    const theme = useTheme('Datepicker').MonthHeaderStyles;
    return (React.createElement("div", { className: theme.monthHeader },
        React.createElement(Dropdown, { elements: elements, className: classnames(theme.monthHeader__select, theme.monthHeader__select_month, {
                [theme.monthHeader__select_asPlainText]: monthHeaderAsPlainText
            }), value: selectedMonth, onClick: (id) => {
                props.setCurrentMonth(id, 0 - props.monthIndex);
            }, asPlainText: monthHeaderAsPlainText }, t(`month_${month}`)),
        React.createElement(Dropdown, { elements: year, value: selectedYear, onClick: (id) => {
                props.setCurrentMonth(id, 0 - props.monthIndex);
            }, className: classnames(theme.monthHeader__select, theme.monthHeader__select_year, {
                [theme.monthHeader__select_asPlainText]: monthHeaderAsPlainText
            }), asPlainText: monthHeaderAsPlainText }, props.currentDate.getFullYear())));
};
