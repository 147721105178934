import { DataLayerEvent, DataLayerItemsListName, ItemCategory, OrderStep, Recommendation } from '../../../../DataLayerAnalytics/types';
import getOrderDataLayer from '../../../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../../../DataLayerAnalytics/OrderDataLayer/types';
import { getMinPrices } from '../../../utils';
export const setDataLayerParams = (currency, promotionCode = null) => {
    getOrderDataLayer(OrderType.SearchResults).init(currency, promotionCode);
};
const getTravellersTypeCount = (searchParameters) => {
    try {
        return searchParameters.passengers.map(passenger => `${passenger.count}${passenger.passengerType}`);
    }
    catch (e) {
        return [];
    }
};
const getFlightMinPrices = (flight, selectedFares) => {
    try {
        return getMinPrices(flight.pricesForFareGroups, true, selectedFares, false);
    }
    catch (e) {
        return [];
    }
};
const getFlightInfo = (flight, searchParameters) => {
    return {
        item_id: `${flight.segments[0].segment.marketingAirline.iata}-${flight.segments[0].segment.flightNumber}`,
        item_name: `${flight.segments.map(segment => segment.segment.departure.airport.iata).join('-')}-${flight.segments[flight.segments.length - 1].segment.arrival.airport.iata}`,
        item_category4: flight.segments[0].segment.departure.date,
        item_category: ItemCategory.Flight,
        item_category2: OrderStep.Booking,
        item_category5: getTravellersTypeCount(searchParameters)
    };
};
export const analytics_setFareGroupsListItems = (fareGroup, flight, searchParameters) => {
    const items = fareGroup
        .filter(fare => { var _a; return !!((_a = fare.prices) === null || _a === void 0 ? void 0 : _a.length); })
        .map((fare, key) => {
        var _a, _b, _c, _d;
        return Object.assign(Object.assign({}, getFlightInfo(flight, searchParameters)), { item_brand: (_a = fare.fareFamily) === null || _a === void 0 ? void 0 : _a.title, price: (_c = (_b = fare.prices[0]) === null || _b === void 0 ? void 0 : _b.price) === null || _c === void 0 ? void 0 : _c.amount, item_category3: ((_d = fare.fareFamily) === null || _d === void 0 ? void 0 : _d.isRecommended)
                ? Recommendation.Recommended
                : Recommendation.NoRecommended, quantity: 1, index: key + 1 });
    });
    const dataLayer = getOrderDataLayer();
    if (dataLayer.getOrderType() === OrderType.SearchResults) {
        dataLayer.getDataLayer().pushToDataLayer(DataLayerEvent.ViewItemList, items, {}, DataLayerItemsListName.Fares);
    }
};
export const analytics_setFlightsListItems = (flights, selectedFares, searchParameters) => {
    const items = flights.map((flight, key) => {
        var _a, _b, _c, _d;
        const minPrices = getFlightMinPrices(flight, selectedFares);
        return Object.assign(Object.assign({}, getFlightInfo(flight, searchParameters)), { item_brand: (_b = (_a = minPrices[0]) === null || _a === void 0 ? void 0 : _a.fareFamily.title) !== null && _b !== void 0 ? _b : '', item_category3: 'no recommendation', quantity: 1, price: (_d = (_c = minPrices[0]) === null || _c === void 0 ? void 0 : _c.price.amount) !== null && _d !== void 0 ? _d : 0, index: key + 1 });
    });
    const dataLayer = getOrderDataLayer();
    if (dataLayer.getOrderType() === OrderType.SearchResults) {
        dataLayer
            .getDataLayer()
            .pushToDataLayer(DataLayerEvent.ViewItemList, items, {}, DataLayerItemsListName.SearchResults);
    }
};
export const analytics_fareAddToCart = (fare, index, flight, searchParameters) => {
    var _a, _b, _c, _d, _e;
    const item = Object.assign(Object.assign({}, getFlightInfo(flight, searchParameters)), { item_brand: (_a = fare.fareFamily) === null || _a === void 0 ? void 0 : _a.title, price: (_d = (_c = (_b = fare.prices) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.price) === null || _d === void 0 ? void 0 : _d.amount, item_category3: ((_e = fare.fareFamily) === null || _e === void 0 ? void 0 : _e.isRecommended) ? Recommendation.Recommended : Recommendation.NoRecommended, quantity: 1, index });
    const dataLayer = getOrderDataLayer();
    if (dataLayer.getOrderType() === OrderType.SearchResults) {
        dataLayer.getDataLayer().pushToDataLayer(DataLayerEvent.AddToCart, [item], {}, DataLayerItemsListName.Fares);
    }
};
export const analytics_fareRemoveFromCart = (fareName, farePrice, legId, isRecommended, flight, searchParameters) => {
    const item = Object.assign(Object.assign({}, getFlightInfo(flight, searchParameters)), { item_brand: fareName, price: farePrice, item_category3: isRecommended ? Recommendation.Recommended : Recommendation.NoRecommended, quantity: 1, index: legId });
    const dataLayer = getOrderDataLayer(OrderType.SearchResults);
    dataLayer.getDataLayer().pushToDataLayer(DataLayerEvent.RemoveFromCart, [item], {}, DataLayerItemsListName.Fares);
};
