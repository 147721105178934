import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import Dialog from '../../Modules/Exchange/components/Exchange/Dialog/Dialog';
import PassengerForm from './PassengerForm/PassengerForm';
import FullscreenDialog from '../../FullscreenDialog';
import PassengerTicket from './PassengerTicket/PassengerTicket';
import ProtectedRoute from '../../ProtectedRoute/ProtectedRoute';
import SimpleLoader from '../../SimpleLoader';
import Payment from './Payment/Payment';
import { PaymentResult, useEditPassenger } from '../hooks';
import { CloseClear } from '../../Icons';
initI18n('EditPassenger');
const EditPassenger = ({ passengerId, refetchOrder, returnToOrder }) => {
    const { t } = useTranslation('EditPassenger');
    const { EditPassenger: theme } = useTheme('EditPassenger');
    const { urls, availableRoutes, passenger, changePaxOrder, changeTravellers, prices, paymentStatus, reason, isLoading, onRefetchOrder, onReturnToOrder, onFillOrder, onGoToForm, onGoToRequest, onGoToPayment, setChangePaxOrder, setPaymentStatus, setReason } = useEditPassenger(refetchOrder, returnToOrder, passengerId);
    const onReturnToOrderHandler = () => {
        onReturnToOrder();
    };
    if (!passenger) {
        return null;
    }
    return (React.createElement(FullscreenDialog, { contentClassName: theme.content, title: t('Passenger information update'), isOpen: !!passenger, onClose: onReturnToOrderHandler, fadeTransition: true },
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement(Switch, null,
            React.createElement(ProtectedRoute, { exact: true, isAccessible: availableRoutes.passenger, redirectTo: urls.requestUrl, path: urls.baseUrl, render: () => (React.createElement(PassengerForm, { passenger: passenger, reason: reason, onFillOrder: onFillOrder, setReason: setReason })) }),
            React.createElement(ProtectedRoute, { exact: true, isAccessible: availableRoutes.request, redirectTo: urls.baseUrl, path: urls.requestUrl, render: () => (React.createElement(PassengerTicket, { passenger: passenger, changeTravellers: changeTravellers, lastChangePaxData: changePaxOrder, prices: prices, reason: reason, onRefetchOrder: onRefetchOrder, onGoToForm: onGoToForm, onGoToPayment: onGoToPayment, onReturnToOrder: onReturnToOrder, setChangePaxOrder: setChangePaxOrder })) }),
            React.createElement(ProtectedRoute, { exact: true, isAccessible: availableRoutes.payment, redirectTo: urls.requestUrl, render: () => (React.createElement(React.Fragment, null,
                    React.createElement(Payment, { className: theme.payment, lastChangePaxOrder: changePaxOrder, onPaymentSuccess: () => setPaymentStatus(PaymentResult.Success), onPaymentFailed: () => setPaymentStatus(PaymentResult.Failed), onGoToRequest: onGoToRequest }),
                    React.createElement(Route, { path: urls.successUrl, render: () => {
                            setPaymentStatus(PaymentResult.Success);
                            onReturnToOrder(true);
                            return React.createElement(Redirect, { to: urls.requestUrl });
                        } }),
                    React.createElement(Route, { path: urls.failedUrl, render: () => {
                            setPaymentStatus(PaymentResult.Failed);
                            onRefetchOrder();
                            return React.createElement(Redirect, { to: urls.requestUrl });
                        } }))) })),
        paymentStatus && (React.createElement(Dialog, { text: t(paymentStatus === PaymentResult.Success
                ? 'Exchange:Successful payment'
                : 'Exchange:Failed payment'), iconClassName: cn({
                [theme.paymentIcon_failed]: paymentStatus === PaymentResult.Failed
            }), icon: paymentStatus === PaymentResult.Failed ? CloseClear : null, acceptText: t('OK'), onAccept: () => setPaymentStatus(null), open: true }))));
};
export default EditPassenger;
