import * as React from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
const ExitRow = () => {
    const theme = useTheme('SeatMap').SeatMapStyles;
    const { t } = useTranslation('SeatMap');
    return (React.createElement("div", { className: theme.exit },
        React.createElement("div", { className: theme.exit__label }, t('SeatMap:Exit')),
        React.createElement("div", { className: theme.exit__label }, t('SeatMap:Exit'))));
};
export default ExitRow;
