import { SET_LAST_EXCHANGE, SET_ORDER } from './actions';
export const orderReducer = (state = null, action) => {
    if (action.type === SET_ORDER) {
        return action.payload;
    }
    else if (action.type === SET_LAST_EXCHANGE) {
        return Object.assign(Object.assign({}, state), { exareInfo: Object.assign(Object.assign({}, state.exareInfo), { lastExchange: action.payload }) });
    }
    return state;
};
