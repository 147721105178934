import * as React from 'react';
import MediaQuery from 'react-responsive';
import { TABLET_MIN_WIDTH } from '../../../utils';
import Dropdown from '../../../Dropdown';
import SlideBottom from '../../../SlideBottom/components/SlideBottom';
export const DropdownAdapter = props => {
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH },
            React.createElement(Dropdown, { isOpen: props.isOpen, onClose: props.onClose }, props.children)),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: props.isOpen, onClose: props.onClose }, props.children))));
};
