import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { LockIcon } from '../../Icons';
import { initI18n } from '../../../utils';
import { Choosed } from '../../../Icons';
import { useConfig } from '../../../context';
import { OverrideComponent, useRenderers } from '../../../renderProps';
initI18n('FareLock');
const DescriptionBlock = ({ className, title, description, showConfirmedIcon, freezeUntil }) => {
    var _a;
    const { t, i18n } = useTranslation('FareLock');
    const { DescriptionBlock: css } = useTheme('FareLock');
    const { global: { companyInfo } } = useConfig();
    const { RenderWarningDescription } = useRenderers();
    const existWarningDescription = i18n.exists('WarningDescription', { ns: 'FareLock' });
    return (React.createElement("div", { className: cn(css.root, className) },
        React.createElement("div", { className: css.icon },
            React.createElement(OverrideComponent, { componentProps: {}, component: { RenderFareLockIcon: () => LockIcon } })),
        React.createElement("div", { className: css.title },
            showConfirmedIcon && React.createElement("span", { className: css.confirmedIcon }, Choosed), (_a = title()) !== null && _a !== void 0 ? _a : t('Fixation the cost of the air ticket')),
        React.createElement("div", { className: css.description },
            description ? (React.createElement("div", { dangerouslySetInnerHTML: { __html: description } })) : (t('No need to worry about the price increase or about the fact that all tickets will be sold out, now you can fix the ticket price for {{hours}} {{plural}}', {
                hours: freezeUntil,
                plural: t('hours', { count: freezeUntil })
            })),
            existWarningDescription && (React.createElement("div", { className: css.warning, dangerouslySetInnerHTML: { __html: t('WarningDescription') } })),
            RenderWarningDescription && !existWarningDescription && (React.createElement("div", { className: css.warning },
                React.createElement(RenderWarningDescription, null))),
            companyInfo.farelockURL && (React.createElement("a", { href: companyInfo.farelockURL, className: css.rules, target: "_blank" }, t('Rules of service provision'))))));
};
export default DescriptionBlock;
