import * as React from 'react';
import SeatInfo from '../SeatInfo/SeatInfo';
const SeatInfoGroup = props => {
    const groupedSeatsInfo = React.useMemo(() => {
        const seatsMap = new Map();
        props.seatService.forEach(service => {
            const existService = seatsMap.get(service.comfort);
            if (existService && (existService.price.amount > service.price.amount || !existService.serviceImage)) {
                seatsMap.set(service.comfort, Object.assign(Object.assign({}, existService), { serviceImage: !existService.serviceImage ? service.serviceImage : existService.serviceImage, price: service.price.amount < existService.price.amount ? service.price : existService.price }));
            }
            else if (!existService) {
                seatsMap.set(service.comfort, service);
            }
        });
        return [...seatsMap.values()];
    }, [props.seatService]);
    return (React.createElement(React.Fragment, null, groupedSeatsInfo.map(seat => (React.createElement(SeatInfo, { seatService: seat, key: seat.id, amenities: props.amenities, fullInfo: props.fullInfo, hideImage: props.hideImage, services: props.services, passengerId: props.passengerId, segmentId: props.segmentId })))));
};
export default SeatInfoGroup;
