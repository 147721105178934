import * as React from 'react';
import { endOfWeek, nextMonday, previousMonday } from 'date-fns';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import Dropdown from '../../../Dropdown';
import Button from '../../../Button';
import Weekpicker from '../Weekpicker/Weekpicker';
import { ChevronLeft, Chevron as ChevronRight, CalendarIcon } from '../../../Icons';
import Modal from '../../../Modal';
import { IconButton } from '../../../BaseComponents';
import { format, MOBILE_MIN_WIDTH } from '../../../utils';
import { useTheme } from '../../../theme';
import { useToggleable } from '../../../hooks';
const DateSwitcher = ({ className, date, availableDates = [], onChangeDate, renderTrigger }) => {
    var _a;
    const { DateSwitcher: theme } = useTheme('CoreSchedule');
    const { isOpen, open, close } = useToggleable(false);
    const isTodayInThisWeek = React.useMemo(() => {
        const today = new Date();
        return today > date && today < endOfWeek(date, { weekStartsOn: 1 });
    }, [date]);
    return (React.createElement("div", { className: cn(theme.container, className) },
        React.createElement(IconButton, { className: theme.prev, disabled: isTodayInThisWeek, onClick: () => onChangeDate(previousMonday(date)) }, ChevronLeft), (_a = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger({ open })) !== null && _a !== void 0 ? _a : (React.createElement(Button, { className: theme.selector, onClick: open },
        React.createElement("div", { className: theme.icon }, CalendarIcon),
        format(date, 'dd'),
        " \u2014 ",
        format(endOfWeek(date, { weekStartsOn: 1 }), 'dd MMM'))),
        React.createElement(IconButton, { className: theme.next, onClick: () => onChangeDate(nextMonday(date)) }, ChevronRight),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH + 1 },
            React.createElement(Dropdown, { isOpen: isOpen, onClose: close },
                React.createElement(Weekpicker, { className: theme.weekpicker, date: date, availableDates: availableDates, onSelect: onChangeDate }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { classes: { scrollBody: theme.scrollBody, paper: theme.paper }, open: isOpen, onClose: close },
                React.createElement(Weekpicker, { className: theme.weekpicker, date: date, availableDates: availableDates, onSelect: onChangeDate, monthCount: 3, monthsToScroll: 3 })))));
};
export default DateSwitcher;
