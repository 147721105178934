import * as React from 'react';
import { useCallback } from 'react';
import { isAfter, isToday, isEqual } from 'date-fns';
import cn from 'classnames';
import { API_DATE_FORMAT, format } from '../../../utils';
import { useTheme } from '../../../theme';
import { getWeekDatesIntervalOfDate } from '../../utils';
const WeekDays = ({ className, startDate, availableDates, onClickDate }) => {
    const { WeekDays: theme } = useTheme('CoreSchedule');
    const week = getWeekDatesIntervalOfDate(startDate).map(date => {
        const isAvailable = availableDates.some(availableDate => isEqual(availableDate, date));
        const isAfterOrToday = isAfter(date, new Date()) || isToday(date);
        return {
            isActive: isAvailable && isAfterOrToday,
            metaDate: format(date, API_DATE_FORMAT),
            weekday: format(date, 'EEEEEE'),
            date: format(date, 'dd.MM')
        };
    });
    const onClick = useCallback((date) => () => {
        onClickDate === null || onClickDate === void 0 ? void 0 : onClickDate(date);
    }, [onClickDate]);
    return (React.createElement("div", { className: cn(theme.container, className) }, week.map(day => (React.createElement("div", { key: day.date, className: cn(theme.day, {
            [theme.active]: day.isActive
        }), onClick: day.isActive ? onClick(day.metaDate) : null },
        React.createElement("div", { className: theme.weekday }, day.weekday),
        React.createElement("div", { className: theme.date }, day.date))))));
};
export default WeekDays;
