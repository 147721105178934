import { __rest } from "tslib";
import * as React from 'react';
import { Provider } from 'react-redux';
import ScheduleForm from './ScheduleForm/ScheduleForm';
import { createStore } from '../store';
import { initI18n } from '../../utils';
initI18n('ScheduleForm');
const Component = (_a) => {
    var { initialState, defaultParameters } = _a, restProps = __rest(_a, ["initialState", "defaultParameters"]);
    const store = createStore(initialState, defaultParameters);
    return (React.createElement(Provider, { store: store },
        React.createElement(ScheduleForm, Object.assign({}, restProps))));
};
export default Component;
