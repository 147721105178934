import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { Switch } from '../../../../../index';
import { useTheme } from '../../../../../theme';
import LoyaltyCard from './LoyaltyCard/LoyaltyCard';
import { setRedeemMiles } from '../../../../store/redeemMiles/actions';
import { getRedeemMiles } from '../../../../store/selectors';
import PassengersBlockedModal from './PassengersBlockedModal/PassengersBlockedModal';
import { useToggleable } from '../../../../../hooks';
import { useConfig } from '../../../../../context';
const PayByMiles = () => {
    const theme = useTheme('SearchForm').PayByMiles;
    const { t } = useTranslation('SearchForm');
    const form = useForm();
    const dispatch = useDispatch();
    const isActive = useSelector(getRedeemMiles);
    const clearLoyalty = () => {
        dispatch(setRedeemMiles(false));
    };
    const { isOpen, toggle, close } = useToggleable(false);
    const passengersConfig = useConfig().SearchForm.passengersConfig;
    const isSomePassengersBlocked = React.useMemo(() => {
        return passengersConfig.some(it => it.blockPayByMiles);
    }, [passengersConfig]);
    const onSetLoyalty = (card, logged) => {
        if (isSomePassengersBlocked && !!card) {
            toggle();
            return;
        }
        dispatch(setRedeemMiles(!!card));
        if (logged) {
            try {
                form.submit();
            }
            catch (e) {
                return;
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(LoyaltyCard, { setLoyalty: onSetLoyalty }, requestLoyaltyCard => {
            return (React.createElement(Switch, { mainClassName: theme.switchWrapper, labelClassName: theme.switchBox, label: t('Pay by miles'), activeClassName: theme.switchActive, isActive: isActive, onClick: isActive ? clearLoyalty : requestLoyaltyCard }));
        }),
        isSomePassengersBlocked && (React.createElement(PassengersBlockedModal, { isOpen: isOpen, onClose: close, onConfirm: () => {
                toggle();
                dispatch(setRedeemMiles(true));
            }, onCancel: close }))));
};
export default PayByMiles;
