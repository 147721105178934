import * as React from 'react';
import PriceChanged from '../PriceChanged/PriceChanged';
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from '../../../../theme';
import FlightNotAvailable from '../FlightNotAvailable/FlightNotAvailable';
import ActualizationOk from './components/ActualizationOk/ActualizationOk';
import { ActualizationStatus } from '@websky/graphql';
const Actualization = props => {
    var _a, _b, _c;
    const theme = useTheme('Checkout').ActualizationStyles;
    const onClose = () => {
        props.onClose();
    };
    const bookFlight = () => {
        onClose();
        if (props.startBooking) {
            props.startBooking();
        }
    };
    return props.actualizationInfo ? (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { open: props.open && ((_a = props.actualizationInfo) === null || _a === void 0 ? void 0 : _a.status) === ActualizationStatus.PriceIncreased, onClose: onClose, classes: { paper: theme.price__popup } },
            React.createElement(PriceChanged, { newPrice: props.actualizationInfo.newPrice, oldPrice: props.actualizationInfo.oldPrice, onChangeFlight: props.backToResults, bookFlight: bookFlight })),
        React.createElement(Dialog, { open: props.open && props.showOkMessage && ((_b = props.actualizationInfo) === null || _b === void 0 ? void 0 : _b.status) === ActualizationStatus.Success, onClose: onClose, classes: { paper: theme.price__popup } },
            React.createElement(ActualizationOk, { onClick: onClose })),
        React.createElement(Dialog, { open: props.open && ((_c = props.actualizationInfo) === null || _c === void 0 ? void 0 : _c.status) === ActualizationStatus.NotAvailable, onClose: onClose, classes: { paper: theme.price__popup } },
            React.createElement(FlightNotAvailable, { onClick: () => props.backToResults() })))) : null;
};
export default Actualization;
