export const insuranceInitialStateFactory = (order) => {
    var _a, _b, _c, _d, _e, _f;
    const initialState = {
        availableInsurances: {},
        selectedInsurances: {},
        confirmedInsurances: {},
        orderTravellers: [],
        isLoading: false
    };
    const selectedInsurances = (_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances) === null || _b === void 0 ? void 0 : _b.selectedInsurances) !== null && _c !== void 0 ? _c : [];
    const availableInsurances = (_f = (_e = (_d = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _d === void 0 ? void 0 : _d.insurances) === null || _e === void 0 ? void 0 : _e.insurancePrograms) !== null && _f !== void 0 ? _f : [];
    for (const insurance of selectedInsurances) {
        initialState.selectedInsurances[insurance.insuranceProgram.code] = insurance;
        if (insurance.policyNumber) {
            initialState.confirmedInsurances[insurance.insuranceProgram.code] = insurance;
        }
    }
    for (const insurance of availableInsurances) {
        initialState.availableInsurances[insurance.code] = insurance;
    }
    for (const traveller of order.travellers) {
        initialState.orderTravellers.push(traveller.id);
    }
    return initialState;
};
export const saveOrderServicesParamsFactory = (travellersIds, selectedInsuranceCodes) => {
    return {
        params: {
            insurances: [
                {
                    selectedPrograms: selectedInsuranceCodes,
                    selectedProgramsByTravellers: travellersIds.map(travellerId => ({
                        travellerId,
                        programCodes: selectedInsuranceCodes,
                        travellerDocumentNumber: ''
                    }))
                }
            ]
        }
    };
};
