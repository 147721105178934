import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { BaseButton, Input } from '../../../BaseComponents';
import { useTheme } from '../../../theme';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import ClarifyDocumentModal from '../../../RetrieveBooking/components/ClarifyDocumentModal/ClarifyDocumentModal';
import { useClarifyDocumentModal } from '../../../RetrieveBooking/components/ClarifyDocumentModal/hooks';
import { useRetrieveBookingValidation } from '../../../RetrieveBooking/hooks';
import { isDifferentCurrenciesError } from '../../../Modules/Checkin/components/Checkin/Order/Passengers/utils';
import Notification from '../../../Notification';
var ButtonState;
(function (ButtonState) {
    ButtonState[ButtonState["Common"] = 0] = "Common";
    ButtonState[ButtonState["Loading"] = 1] = "Loading";
    ButtonState[ButtonState["Success"] = 2] = "Success";
})(ButtonState || (ButtonState = {}));
const passengerIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8ZM14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8ZM12 13C9.33 13 4 14.34 4 17V20H20V17C20 14.34 14.67 13 12 13ZM6 17.01V18H18V17C17.8 16.29 14.7 15 12 15C9.3 15 6.2 16.29 6 17.01Z", fill: "#9A9A9A" })));
const AddPassengerForm = ({ onSubmit, onSuccess, initialValues, hideIcon, buttonText, validationType, inputVariant }) => {
    const { t } = useTranslation('AddPassenger');
    const css = useTheme('AddPassenger').AddPassenger;
    const [isError, setIsError] = useState(false);
    const [buttonState, setButtonState] = useState(ButtonState.Common);
    const isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
    const { isOpen, open: openModal, searchParams, setSearchParams, close: closeModal, needToShowClarifyModal } = useClarifyDocumentModal();
    const { lastNameValidation, ticketNumberValidation, ticketNumberFormat } = useRetrieveBookingValidation(validationType);
    const submitForm = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () { return yield onSubmit(values['lastName'], values['id'], values['extraSecret']); }), [onSubmit]);
    const onSubmitHandler = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        setButtonState(ButtonState.Loading);
        try {
            const result = yield submitForm(values);
            if (result) {
                setButtonState(ButtonState.Success);
                setTimeout(() => {
                    onSuccess();
                    setButtonState(ButtonState.Common);
                }, 2000);
            }
            else {
                setButtonState(ButtonState.Common);
                setIsError(true);
            }
        }
        catch (e) {
            setButtonState(ButtonState.Common);
            if (needToShowClarifyModal(e)) {
                setSearchParams(values);
                openModal();
            }
            else if (isDifferentCurrenciesError(e)) {
                setIsError(false);
            }
            else {
                setIsError(true);
            }
        }
    }), [submitForm]);
    const handleClarifySubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const result = yield submitForm(values);
            if (result) {
                setButtonState(ButtonState.Success);
                setTimeout(() => {
                    onSuccess();
                    setButtonState(ButtonState.Common);
                }, 2000);
            }
            return result;
        }
        catch (e) {
            console.warn(e);
            return false;
        }
    }), [submitForm]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ClarifyDocumentModal, { searchParams: searchParams, onSubmit: handleClarifySubmit, open: isOpen, onClose: closeModal }),
        React.createElement(Form, { onSubmit: onSubmitHandler, initialValues: initialValues, render: ({ handleSubmit }) => (React.createElement("form", { className: css.form, onSubmit: handleSubmit },
                !hideIcon && (React.createElement(MediaQuery, { minWidth: "mobile" },
                    React.createElement("div", { className: css.passengerIcon }, passengerIcon))),
                React.createElement("div", { className: css.fields },
                    isMobile && isError && (React.createElement(Notification, { type: "context", className: css.notification, contextClassName: css.notification_context, textClassName: css.notification__text, status: InfoMessageTypeEnum.Warning, infoMessage: {
                            type: InfoMessageTypeEnum.Warning,
                            text: t('Passenger not found'),
                            code: 'add-passenger-error'
                        } })),
                    React.createElement(Field, { name: "lastName", validate: (value, allValues, meta) => {
                            if (!value) {
                                return t('Required field');
                            }
                            return lastNameValidation(value);
                        }, render: ({ input, meta }) => (React.createElement(Input, { className: css.form__input, name: "lastName", label: t('Passenger last name'), variant: inputVariant, InputProps: input, error: !!meta.error && meta.touched, helperText: !!meta.error && meta.touched ? meta.error : '', disabled: buttonState === ButtonState.Loading || buttonState === ButtonState.Success })) }),
                    React.createElement(Field, { name: "id", parse: ticketNumberFormat, validate: (value) => {
                            if (!value) {
                                return t('Required field');
                            }
                            return ticketNumberValidation(value);
                        }, render: ({ input, meta }) => (React.createElement(Input, { className: css.form__input, name: "id", label: t(validationType === 'ticketNumber'
                                ? 'Ticket number'
                                : 'Ticket number or booking reference'), variant: inputVariant, InputProps: input, error: (!!meta.error && meta.touched) || (!isMobile && isError), helperText: !!meta.error && meta.touched
                                ? meta.error
                                : !isMobile && isError
                                    ? t('Passenger not found')
                                    : '', disabled: buttonState === ButtonState.Loading || buttonState === ButtonState.Success })) })),
                React.createElement(BaseButton, { type: "submit", className: css.submitButton, variant: isMobile ? 'smallPrimary' : 'optionAction', isLoading: buttonState === ButtonState.Loading }, buttonText || t('Find')))) })));
};
export default AddPassengerForm;
