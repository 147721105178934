import * as React from 'react';
import { useState } from 'react';
import { Grow, Slide } from '@mui/material';
export const GrowTransition = React.forwardRef(function Transition(props, ref) {
    return React.createElement(Grow, Object.assign({ ref: ref }, props));
});
export const SlideUpTransition = React.forwardRef(function Transition(props, ref) {
    const [direction, setDirection] = useState('up');
    return (React.createElement(Slide, Object.assign({ direction: direction, onEntered: () => setDirection('up'), onExited: () => setDirection('down'), ref: ref }, props)));
});
