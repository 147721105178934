import * as React from 'react';
import { Suspense, memo, lazy } from 'react';
import { Provider } from 'react-redux';
import { createStore } from '../store';
import { useGraphQLClient } from '../../graphql-client';
import { fillOrder } from '../store/order/actions';
import { ApolloProvider } from '@apollo/react-hooks';
import { GuardedApolloProvider } from '../../GuardedApolloProvider';
const CheckoutWrapper = lazy(() => import('./CheckoutWrapper'));
const Component = memo(props => {
    const client = useGraphQLClient();
    const store = createStore();
    if (props.order) {
        store.dispatch(fillOrder(props.order));
    }
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(Provider, { store: store },
            React.createElement(GuardedApolloProvider, { client: client },
                React.createElement(Suspense, { fallback: React.createElement("div", null) },
                    React.createElement(CheckoutWrapper, { orderId: props.orderId, startNewSearch: props.startNewSearch }))))));
});
export default Component;
