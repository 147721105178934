import * as React from 'react';
import { LocationGroupType } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef } from 'react';
export const useNationality = ({ options, onChange, field, fieldState }) => {
    const { t } = useTranslation('Passenger');
    const [optionsList, setOptionsList] = React.useState(options);
    const [value, setValue] = React.useState('');
    const mounted = useRef(false);
    const filterOptions = (value) => options.filter(option => option.label.toLowerCase().includes(value.toLowerCase()));
    // selectors
    const optionsGroup = React.useMemo(() => {
        const countries = [[], optionsList];
        countries[0] = optionsList.filter(option => option.priority > 0).sort((a, b) => b.priority - a.priority);
        return countries.map((option, index) => ({
            label: option[0] ? (index === 0 ? t('Popular countries') : t('Other countries')) : null,
            options: countries[index],
            type: index === 0 ? LocationGroupType.Popular : LocationGroupType.Country
        }));
    }, [optionsList]);
    const isValueInOptions = useMemo(() => filterOptions(value).length, [value, options]);
    const isLastOptionAvailable = useMemo(() => optionsList.length === 1, [optionsList]);
    // effects
    useEffect(() => {
        if (isValueInOptions) {
            setOptionsList(filterOptions(value));
        }
        else {
            setOptionsList(options);
        }
    }, [value, options, isValueInOptions, setOptionsList]);
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            setValue(options.find(option => option.value === fieldState.input.value).label);
        }
    }, [mounted]);
    // methods
    const isValueFullyInOptions = (value) => !!optionsList.find(option => option.label.toLowerCase() === value.toLowerCase());
    const handleFocus = useCallback(e => {
        setValue('');
    }, []);
    const handleParentChange = (value) => {
        onChange(field.name, value);
    };
    const handleChange = useCallback((event) => {
        const value = event.target.value;
        setValue(value);
    }, [options, setOptionsList]);
    const handleSelect = useCallback((value) => {
        const relatedOption = options.find(option => option.value === value);
        handleParentChange(relatedOption.value);
        setValue(relatedOption.label);
    }, [onChange, setValue]);
    const handleBlur = useCallback(() => {
        if (isLastOptionAvailable) {
            setValue(optionsList[0].label);
            handleParentChange(optionsList[0].value);
        }
        if (isValueFullyInOptions(value)) {
            return;
        }
        else {
            setValue('');
            handleParentChange(null);
        }
    }, [optionsList, isLastOptionAvailable, value, isValueFullyInOptions]);
    return {
        options: optionsGroup,
        handleChange,
        handleSelect,
        handleBlur,
        handleFocus,
        value
    };
};
