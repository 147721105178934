import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import PromoLoader from '../PromoLoader/components/PromoLoader';
import FindOrder from '../Checkout/components/Checkout/FindOrder/FindOrder';
import { useRefuseExchangeMutation, ExareStatus } from '@websky/graphql';
import { isCheckinOrderResponse, isOrderResponse } from '../typeguards';
export default function OrderGuard({ children, onOrderLoad, loadOrder, orderId, orderLoaded, onOrderAccessGranted }) {
    const [userHasAccess, setUserHasAccess] = useState(true);
    const { refetch, loading } = loadOrder({ skip: true, fetchPolicy: 'no-cache' });
    const [refuseExchange] = useRefuseExchangeMutation();
    const load = () => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        setUserHasAccess(true);
        try {
            const { data, errors } = yield refetch({ id: orderId });
            if (errors) {
                throw errors;
            }
            else if (data && isOrderResponse(data)) {
                if (((_b = (_a = data.Order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange) === null || _b === void 0 ? void 0 : _b.status) === ExareStatus.TimelimitExpired) {
                    yield refuseExchange({ variables: data.Order.exareInfo.lastExchange });
                    const { data: order, errors } = yield refetch();
                    if (errors) {
                        throw errors;
                    }
                    if (isOrderResponse(order)) {
                        onOrderLoad(order.Order);
                    }
                }
                else {
                    onOrderLoad(data.Order);
                }
            }
            else if (data && isCheckinOrderResponse(data)) {
                onOrderLoad(data.CheckinOrder);
            }
            else {
                setUserHasAccess(false);
            }
        }
        catch (error) {
            console.error(error);
            setUserHasAccess(false);
        }
    });
    const orderAccessGrantedHandler = useCallback((order) => {
        onOrderAccessGranted(order);
        setUserHasAccess(true);
    }, [onOrderAccessGranted]);
    useEffect(() => {
        if (!orderLoaded) {
            load();
        }
    }, [orderLoaded]);
    if (!userHasAccess) {
        return React.createElement(FindOrder, { initialId: orderId, onSuccess: orderAccessGrantedHandler });
    }
    return !orderLoaded || loading ? React.createElement(PromoLoader, { innerPromoLoader: true }) : React.createElement(React.Fragment, null, children);
}
