import { useLayoutEffect, useRef, useState } from 'react';
/** Return computed style property */
export const useComputedStyleProperty = (propertyName, initValue) => {
    const [value, setValue] = useState(initValue);
    const elRef = useRef(null);
    useLayoutEffect(() => {
        if (elRef.current) {
            const value = getComputedStyle(elRef.current).getPropertyValue(propertyName).trim();
            if (value.length > 0) {
                setValue(value);
            }
        }
    }, []);
    return { value, elRef };
};
