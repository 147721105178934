import { FareFamilyOptionsType, FareFeaturePaymentType } from '@websky/graphql';
import * as React from 'react';
import { PhoneSuccess, SportEquipment } from '../Icons';
import { overridedIcons } from '../Icons';
export const infoIcon = (React.createElement("svg", { width: "19", height: "19", viewBox: "0 0 19 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.58301 9.50016C1.58301 5.13016 5.12967 1.5835 9.49968 1.5835C13.8697 1.5835 17.4163 5.13016 17.4163 9.50016C17.4163 13.8702 13.8697 17.4168 9.49968 17.4168C5.12967 17.4168 1.58301 13.8702 1.58301 9.50016ZM10.2913 8.7085V13.4585H8.70801V8.7085H10.2913ZM9.49966 15.8335C6.00842 15.8335 3.16633 12.9914 3.16633 9.50016C3.16633 6.00891 6.00842 3.16683 9.49966 3.16683C12.9909 3.16683 15.833 6.00891 15.833 9.50016C15.833 12.9914 12.9909 15.8335 9.49966 15.8335ZM10.2913 5.54183V7.12516H8.70801V5.54183H10.2913Z", fill: "currentColor" })));
export const closeIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M25.3337 8.5465L23.4537 6.6665L16.0003 14.1198L8.54699 6.6665L6.66699 8.5465L14.1203 15.9998L6.66699 23.4532L8.54699 25.3332L16.0003 17.8798L23.4537 25.3332L25.3337 23.4532L17.8803 15.9998L25.3337 8.5465Z", fill: "white" })));
export const icons = {
    [FareFamilyOptionsType.VipService]: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.84621 9.91304C5.84621 6.64735 8.60136 4 12 4C15.3987 4 18.1539 6.64735 18.1539 9.91304L18.1538 13.6087H5.84621V9.91304ZM19.6923 15.087V10.4059H22V15.3334C22 16.711 21.0194 17.8685 19.6923 18.1967V21H18.1539V18.2899H5.84621V21H4.30773V18.1967C2.98063 17.8685 2 16.711 2 15.3333H2.00004V10.4059H4.30773V15.087H19.6923Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.CarryOn]: (React.createElement("svg", { width: "22", height: "19", viewBox: "0 0 22 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.68805 0.895508H13.553C14.5589 0.895508 15.3773 1.70203 15.3773 2.69332V3.2926H20.4529L18.1592 10.0742C18.076 10.3194 17.8439 10.4839 17.5819 10.4839H14.1611V9.88459C14.1611 9.55343 13.889 9.28532 13.553 9.28532H8.68805C8.352 9.28532 8.07993 9.55343 8.07993 9.88459V10.4839H4.65911C4.39718 10.4839 4.16502 10.3194 4.08188 10.0742L1.78829 3.2926H6.8637V2.69332C6.8637 1.70203 7.68212 0.895508 8.68805 0.895508ZM8.07993 2.69332V3.2926H14.1611V2.69332C14.1611 2.36263 13.8886 2.09405 13.553 2.09405H8.68805C8.35248 2.09405 8.07993 2.36263 8.07993 2.69332ZM17.5821 11.6824H14.1613V12.2816C14.1613 12.6128 13.8892 12.8809 13.5532 12.8809H8.68825C8.35221 12.8809 8.08014 12.6128 8.08014 12.2816V11.6824H4.65932C3.87304 11.6824 3.17767 11.1884 2.92872 10.4534L0.742188 3.98812V17.0758C0.742188 18.0671 1.56061 18.8736 2.56654 18.8736H19.6749C20.6808 18.8736 21.4993 18.0671 21.4993 17.0758V3.98859L19.3126 10.4534C19.0638 11.1884 18.3684 11.6824 17.5821 11.6824ZM9.29658 10.4839H12.9453V11.6824H9.29658V10.4839Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.Baggage]: (React.createElement("svg", { width: "33", height: "32", viewBox: "0 0 33 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.3529 6.00004H9.58348C8.47807 6.00004 7.5818 6.93457 7.58203 8.0877V25.9871C7.58203 27.1402 8.4783 28.075 9.58371 28.075H9.85246V29.5905C9.85246 29.8691 10.0686 30.0952 10.3364 30.0952H12.6252C12.8921 30.0952 13.1091 29.8694 13.1091 29.5905V28.075H20.3584V29.5905C20.3584 29.8691 20.5748 30.0952 20.8423 30.0952H23.1313C23.3982 30.0952 23.615 29.8694 23.615 29.5905V28.075H23.8834C24.9886 28.075 25.8851 27.1404 25.8851 25.9871V8.08758C25.8851 6.93469 24.9888 5.99992 23.8834 5.99992H22.1141V3.72399C22.1141 2.40589 21.0855 1.33325 19.8217 1.33325H13.6452C12.3811 1.33325 11.3529 2.40589 11.3529 3.72399V6.00004ZM20.5861 5.99992H12.8814V3.72399C12.8814 3.28462 13.2243 2.92708 13.6455 2.92708H19.822C20.2433 2.92708 20.5861 3.28462 20.5861 3.72399V5.99992ZM10.0669 10.9999C10.0669 10.4476 10.5146 9.99992 11.0669 9.99992H22.4002C22.9525 9.99992 23.4002 10.4476 23.4002 10.9999C23.4002 11.5522 22.9525 11.9999 22.4002 11.9999H11.0669C10.5146 11.9999 10.0669 11.5522 10.0669 10.9999ZM10.0669 14.9999C10.0669 14.4476 10.5146 13.9999 11.0669 13.9999H22.4002C22.9525 13.9999 23.4002 14.4476 23.4002 14.9999C23.4002 15.5522 22.9525 15.9999 22.4002 15.9999H11.0669C10.5146 15.9999 10.0669 15.5522 10.0669 14.9999ZM11.0669 17.9999C10.5146 17.9999 10.0669 18.4476 10.0669 18.9999C10.0669 19.5522 10.5146 19.9999 11.0669 19.9999H22.4002C22.9525 19.9999 23.4002 19.5522 23.4002 18.9999C23.4002 18.4476 22.9525 17.9999 22.4002 17.9999H11.0669ZM10.0669 22.9999C10.0669 22.4476 10.5146 21.9999 11.0669 21.9999H22.4002C22.9525 21.9999 23.4002 22.4476 23.4002 22.9999C23.4002 23.5522 22.9525 23.9999 22.4002 23.9999H11.0669C10.5146 23.9999 10.0669 23.5522 10.0669 22.9999Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.SeatRegistration]: (React.createElement("svg", { width: "33", height: "32", viewBox: "0 0 33 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.3677 24.4413L13.8409 27.4097H7.9633V29.652H15.1053L15.2343 29.7147L15.2665 29.652H22.1327V27.4097H16.4199L17.9466 24.4413H22.5878C24.0185 24.4413 25.1783 23.3138 25.1783 21.923C25.1783 20.5322 24.0185 19.4047 22.5878 19.4047H13.2812L10.0375 9.80194V4.23912C10.0375 3.16051 9.13806 2.28613 8.02852 2.28613C6.91899 2.28613 6.01953 3.16051 6.01953 4.23912V9.80194L10.3903 24.4413H15.3677ZM28.9581 9.79588C29.3186 9.4354 29.3186 8.85094 28.9581 8.49045C28.5976 8.12997 28.0132 8.12997 27.6527 8.49045L22.0197 14.1235L19.8153 11.919C19.4548 11.5585 18.8703 11.5585 18.5098 11.919C18.1493 12.2795 18.1493 12.864 18.5098 13.2245L22.0197 16.7343L28.9581 9.79588Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.Meal]: (React.createElement("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.1732 0.5V4.31818H21.9459L20.3709 20.0968C20.2754 20.8795 19.6168 21.5 18.815 21.5H17.1923L17.1732 13.8636C17.1732 9.82591 14.2141 7.33455 10.7109 6.52318L10.4913 4.31818H15.2641V0.5H17.1732ZM1.91001 21.4905C1.37547 21.4905 0.945923 21.0609 0.945923 20.5264V19.5909H15.2737V20.5264C15.2737 21.0609 14.8441 21.4905 14.3096 21.4905H1.91001ZM8.10501 8.12681C4.52547 8.12681 0.945923 10.0455 0.945923 13.8636H15.2641C15.2641 10.0455 11.6846 8.12681 8.10501 8.12681ZM15.2641 15.7727H0.945923V17.6818H15.2641V15.7727Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.Refundable]: (React.createElement("svg", { width: "33", height: "32", viewBox: "0 0 33 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.74506 3.58984L4.33301 8.92318L9.74506 14.2565V10.2565H17.863C21.5992 10.2565 24.6281 13.2413 24.6281 16.9232C24.6281 20.6051 21.5992 23.5898 17.863 23.5898H9.74502V26.2565H17.863C23.0937 26.2565 27.3341 22.0778 27.3341 16.9232C27.3341 11.7685 23.0937 7.58984 17.863 7.58984H9.74506V3.58984Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.Exchangeable]: (React.createElement("svg", { width: "33", height: "32", viewBox: "0 0 33 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.5677 4.9231H11.2483V7.03292H9.3252H8.17135C7.15175 7.03292 6.3252 7.85947 6.3252 8.87907V10.0329V13.8901V25.7582C6.3252 26.7778 7.15175 27.6043 8.17135 27.6043H25.3541C26.3737 27.6043 27.2003 26.7778 27.2003 25.7582V13.8901V10.0329V8.87907C27.2003 7.85947 26.3737 7.03292 25.3541 7.03292H24.2003H22.8457V4.9231H20.5262V7.03292H13.5677V4.9231ZM25.9695 13.8901H7.55596V25.7582C7.55596 26.0981 7.83148 26.3736 8.17135 26.3736H25.3541C25.694 26.3736 25.9695 26.0981 25.9695 25.7582V13.8901ZM14.7849 19.3292H14.0646V20.4498H14.8075C15.1005 20.4498 15.33 20.5182 15.4961 20.6551C15.6622 20.792 15.7453 21.0242 15.7453 21.3516C15.7453 21.5807 15.6653 21.7697 15.5052 21.9185C15.3451 22.0644 15.1307 22.1373 14.8619 22.1373C14.6233 22.1373 14.4225 22.0644 14.2594 21.9185C14.0963 21.7727 14.0148 21.6001 14.0148 21.4007H12.479C12.479 21.7667 12.5742 22.0941 12.7644 22.3828C12.9547 22.6685 13.2341 22.8932 13.6025 23.0569C13.974 23.2176 14.3757 23.298 14.8075 23.298C15.5445 23.298 16.1394 23.1239 16.5924 22.7757C17.0455 22.4275 17.272 21.9632 17.272 21.3828C17.272 21.0078 17.1723 20.6938 16.973 20.4408C16.7736 20.1849 16.4988 19.9944 16.1485 19.8694C16.4807 19.7147 16.7329 19.5138 16.905 19.2667C17.0802 19.0167 17.1678 18.7519 17.1678 18.4721C17.1678 17.8947 16.9579 17.4408 16.5381 17.1105C16.1183 16.7801 15.5414 16.615 14.8075 16.615C14.3968 16.615 14.0193 16.6908 13.675 16.8426C13.3337 16.9914 13.0664 17.1998 12.8732 17.4676C12.6799 17.7355 12.5832 18.0405 12.5832 18.3828H14.1099C14.1099 18.2102 14.1839 18.0658 14.3319 17.9498C14.4829 17.8337 14.6596 17.7757 14.8619 17.7757C15.1096 17.7757 15.3013 17.8441 15.4372 17.981C15.5731 18.115 15.6411 18.2891 15.6411 18.5033C15.6411 19.0539 15.3557 19.3292 14.7849 19.3292ZM19.8949 23.2087H21.4261V16.7087H21.2857L18.3909 17.6953V18.8382L19.8949 18.4051V23.2087Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.SportsEquipment]: SportEquipment,
    [FareFamilyOptionsType.OnlineRegistration]: PhoneSuccess
};
export const payment = {
    [FareFeaturePaymentType.Free]: overridedIcons['Included'],
    [FareFeaturePaymentType.Pay]: overridedIcons['Paid'],
    [FareFeaturePaymentType.NotAvailable]: overridedIcons['NotIncluded']
};
