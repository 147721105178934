import { CartService } from '../../Cart/types';
import * as React from 'react';
const InsuranceSummary = props => {
    const onDeleteHandler = (insuranceCode) => {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Insurances, {
            code: insuranceCode
        });
    };
    const items = React.useMemo(() => {
        var _a, _b, _c, _d;
        const insurancesMap = new Map(), availableProgramCodes = new Set((_b = (_a = props.services.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b.map(program => program.code));
        (_d = (_c = props.services.insurances) === null || _c === void 0 ? void 0 : _c.selectedInsurances) === null || _d === void 0 ? void 0 : _d.forEach(insurance => {
            if (availableProgramCodes.has(insurance.insuranceProgram.code)) {
                insurancesMap.set(insurance.insuranceProgram.code, Object.assign(Object.assign({}, insurance.insuranceProgram), { count: 1, policyUrl: insurance.policyUrl }));
            }
        });
        const items = [];
        insurancesMap.forEach(insurance => {
            items.push({
                id: insurance.code,
                name: `${insurance.count}x ${insurance.name}`,
                price: insurance.totalPrice,
                onDelete: props.onServiceDelete ? () => onDeleteHandler(insurance.code) : null,
                included: props.isConfirmed && (!props.onServiceDelete || !!insurance.policyUrl),
                downloadUrl: props.isConfirmed && props.isDownload ? insurance.urlToOffer : null
            });
        });
        return items;
    }, [props.services, props.isConfirmed]);
    return props.children(items);
};
export default InsuranceSummary;
