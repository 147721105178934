import { __rest } from "tslib";
import * as React from 'react';
import Button from '@mui/material/Button';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useComputedStyleProperty } from '../../../hooks';
import { Close } from '../../icons';
const CloseButton = (_a) => {
    var { classes, variant = 'closePopup', disableElevation = true } = _a, restProps = __rest(_a, ["classes", "variant", "disableElevation"]);
    const { CloseButton: theme } = useTheme('BaseComponents');
    const { value: buttonVariant, elRef } = useComputedStyleProperty('--variant', variant);
    return (React.createElement(Button, Object.assign({ variant: "contained", classes: Object.assign(Object.assign({}, classes), { root: cn(theme[buttonVariant], classes === null || classes === void 0 ? void 0 : classes.root) }), disableElevation: disableElevation, ref: elRef }, restProps),
        React.createElement("span", { className: theme.closeIcon }, Close)));
};
export default CloseButton;
