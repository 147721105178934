import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import Money from '../../../../Money';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { isBaggageExcess as getIsBaggageExcess } from '../../utils';
const BaggageSegment = ({ segment, mobileSelectedServiceId, baggageByRfics, baggageTab, isAvailable }) => {
    var _a, _b;
    const from = (_a = segment.departure.airport.city) === null || _a === void 0 ? void 0 : _a.name;
    const to = (_b = segment.arrival.airport.city) === null || _b === void 0 ? void 0 : _b.name;
    const theme = useTheme('UpgradeBaggageDialog').BaggageSegment;
    const { t } = useTranslation('IncludedBaggage');
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const isUnavailable = isAvailable === false;
    const baggagesWithOtherPrices = React.useMemo(() => {
        var _a;
        if (baggageByRfics.length) {
            const firstBaggage = baggageByRfics[0][0];
            const baggageWithOtherPrice = baggageByRfics.find(baggages => baggages.some(baggage => (baggage === null || baggage === void 0 ? void 0 : baggage.price.amount) !== firstBaggage.price.amount));
            return !!baggageWithOtherPrice || ((_a = baggageByRfics[0]) === null || _a === void 0 ? void 0 : _a.length) === 1;
        }
        return !!baggageTab;
    }, [baggageByRfics]);
    const selectedBaggage = React.useMemo(() => {
        var _a;
        const selectedTab = isMobile ? baggageTab !== null && baggageTab !== void 0 ? baggageTab : mobileSelectedServiceId : baggageTab;
        if (selectedTab) {
            // ищем доступный багаж с мин ценой
            let actualBaggage = null;
            let withFrom = false;
            (_a = baggageByRfics
                .find(baggages => baggages.find(bagggage => bagggage.rfics === selectedTab))) === null || _a === void 0 ? void 0 : _a.forEach(bag => {
                const isTargetSegment = bag.segmentIds.some(segments => segments.some(segmentId => segmentId === segment.id));
                if (isTargetSegment && bag.canBeAdded) {
                    if (actualBaggage &&
                        (actualBaggage.price.amount > bag.price.amount ||
                            actualBaggage.price.amount < bag.price.amount)) {
                        withFrom = true;
                    }
                    if (!actualBaggage || bag.price.amount < actualBaggage.price.amount) {
                        actualBaggage = bag;
                    }
                }
            });
            if (!actualBaggage || (actualBaggage && getIsBaggageExcess(actualBaggage))) {
                return null;
            }
            return Object.assign(Object.assign({}, actualBaggage), { withPriceFrom: withFrom });
        }
        return null;
    }, [baggageTab, mobileSelectedServiceId, baggageByRfics, segment, isMobile]);
    return (React.createElement("div", { className: cn(theme.root, { [theme.disabled]: isUnavailable }) },
        React.createElement("div", { className: cn(theme.name, {
                [theme.name_withPrices]: baggagesWithOtherPrices
            }) },
            from,
            " \u2014 ",
            to),
        (baggagesWithOtherPrices || isMobile) && (React.createElement("div", { className: theme.prices },
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, baggageByRfics.map(baggagesWithRfisc => {
                // ищем доступный багаж с мин ценой
                let baggage = null;
                let withFrom = false;
                baggagesWithRfisc.forEach(bag => {
                    const isTargetSegment = isUnavailable ||
                        (!isUnavailable &&
                            bag.segmentIds.some(segments => segments.some(segmentId => segmentId === segment.id)));
                    if (isTargetSegment && bag.canBeAdded) {
                        if (baggage &&
                            (bag.price.amount < baggage.price.amount ||
                                bag.price.amount > baggage.price.amount)) {
                            withFrom = true;
                        }
                        if (!baggage || bag.price.amount < baggage.price.amount) {
                            baggage = bag;
                        }
                    }
                });
                if (!baggage) {
                    return React.createElement("div", { className: theme.price__empty });
                }
                const isBaggageExcess = getIsBaggageExcess(baggage);
                const hidePrice = isBaggageExcess;
                return (React.createElement("div", { className: theme.price }, !hidePrice && (React.createElement(React.Fragment, null,
                    withFrom && t('from'),
                    " ",
                    React.createElement(Money, { money: baggage.price })))));
            })),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { className: selectedBaggage ? theme.price : theme.price__empty }, selectedBaggage && (React.createElement(React.Fragment, null,
                    selectedBaggage.withPriceFrom && t('from'),
                    ' ',
                    React.createElement(Money, { money: selectedBaggage === null || selectedBaggage === void 0 ? void 0 : selectedBaggage.price }))))))),
        !baggagesWithOtherPrices && !isMobile && (React.createElement("div", { className: theme.price },
            (selectedBaggage === null || selectedBaggage === void 0 ? void 0 : selectedBaggage.withPriceFrom) && t('from'),
            " ",
            React.createElement(Money, { money: selectedBaggage === null || selectedBaggage === void 0 ? void 0 : selectedBaggage.price })))));
};
export default BaggageSegment;
