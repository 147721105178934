import * as React from 'react';
import cn from 'classnames';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { useTheme } from '../../../theme';
import NotificationRoot from '../../../Notification';
const Notification = props => {
    const { Notification: theme } = useTheme('EditPassenger');
    return (React.createElement(NotificationRoot, Object.assign({}, props, { className: cn(theme.message, {
            [theme.message_info]: props.status === InfoMessageTypeEnum.Info,
            [theme.message_warning]: props.status === InfoMessageTypeEnum.Warning
        }), contextClassName: theme.message_context, iconClassName: theme.message__icon, titleClassName: theme.message__title, textClassName: theme.message__text })));
};
export default Notification;
