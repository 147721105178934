import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox, Collapse } from '@material-ui/core';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { Chevron } from '../../../Icons';
const SegmentCollapse = ({ className, children, date, info, isActive, isDisabled, type = 'collapse', onChange }) => {
    const { SegmentCollapse: css } = useTheme('AdditionalServiceWrapper');
    const [isCollapse, setIsCollapse] = useState(isActive !== null && isActive !== void 0 ? isActive : false);
    const isCheckbox = type === 'checkbox';
    useEffect(() => {
        if (isCheckbox && isCollapse !== isActive) {
            setIsCollapse(isActive);
        }
    }, [isActive]);
    const onChangeHandler = (_e, checked) => {
        setIsCollapse(checked);
        onChange === null || onChange === void 0 ? void 0 : onChange(checked);
    };
    return (React.createElement("div", { className: cn(css.segment, className, {
            [css.collapse]: type === 'collapse'
        }) },
        React.createElement(FormControlLabel, { classes: {
                root: css.label__root,
                label: css.label__label
            }, checked: isCollapse, disabled: isDisabled, control: React.createElement(Checkbox, { classes: {
                    root: css.checkbox,
                    checked: css.checkbox__checked
                }, disableRipple: true }), label: React.createElement(React.Fragment, null,
                React.createElement("span", { className: css.date }, date),
                info && React.createElement("span", { className: css.info }, info),
                !isCheckbox && (React.createElement("span", { className: cn(css.arrow, {
                        [css.arrow__collapse]: isCollapse
                    }) }, Chevron))), onChange: onChangeHandler }),
        React.createElement(Collapse, { classes: { wrapperInner: css.wrapperInner }, in: isCollapse }, children)));
};
export default SegmentCollapse;
