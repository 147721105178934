import * as React from 'react';
import { cityIn } from 'lvovich';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { getDurationString } from '../../../../Results/utils';
import cn from 'classnames';
const StopsInfo = ({ stops, stopCityName, transferDuration, className, separator = '-' }) => {
    const { t } = useTranslation('DesktopFlightInfo');
    const theme = useTheme('DesktopFlightRoute').StopsInfoStyles;
    return (React.createElement("div", { className: cn(theme.stop, className) },
        React.createElement("span", { className: theme.stop__label },
            stops.length > 0 &&
                `${t('DesktopFlightInfo:TechnicalLandingIn', {
                    count: stops.length
                })} ${stops
                    .map(stop => {
                    var _a, _b;
                    return `${cityIn((_b = (_a = stop.airport) === null || _a === void 0 ? void 0 : _a.city) === null || _b === void 0 ? void 0 : _b.name)}${(stop === null || stop === void 0 ? void 0 : stop.duration) ? ` ${separator} ${getDurationString(stop.duration)}` : ''}`;
                })
                    .join(', ')}. `,
            transferDuration &&
                `${t('Stop in')} ${cityIn(stopCityName)} ${separator} ${getDurationString(transferDuration)}`)));
};
export default StopsInfo;
