import { __rest } from "tslib";
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { fastSearchParse, isFastSearchCorrect } from './utils';
import PromoLoader from '../../../PromoLoader/components/PromoLoader';
import { useConfig } from '../../../context';
import LoyaltyConnect from '../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import { getCurrency } from '../../../cache';
import { getUserValue } from '../../../utils';
import { TravellerFieldEnum, useGetAccountInfoQuery } from '@websky/graphql';
import { useConfigRedirects } from '../../../hooks';
const FastSearch = ({ fastSearch, startSearch, redirect }) => {
    const [isValid, setIsValid] = useState(true);
    const [authorizationFfp, setAuthorizationFfp] = useState(false);
    const config = useConfig();
    const payByMiles = config.SearchForm.payByMiles;
    const allowPromoCodes = config.global.allowPromoCodes;
    const passengersConfig = config.SearchForm.passengersConfig;
    const { onGoToAccountSettings } = useConfigRedirects();
    const { data: currentUser, loading } = useGetAccountInfoQuery({ skip: !payByMiles });
    const userInfo = useMemo(() => {
        var _a;
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) || !((_a = currentUser.CurrentUser) === null || _a === void 0 ? void 0 : _a.userProfile)) {
            return {
                lastName: '',
                firstName: '',
                email: ''
            };
        }
        return {
            lastName: getUserValue(currentUser.CurrentUser.userProfile, TravellerFieldEnum.LastName),
            firstName: getUserValue(currentUser.CurrentUser.userProfile, TravellerFieldEnum.FirstName),
            email: getUserValue(currentUser.CurrentUser.userProfile, TravellerFieldEnum.Email)
        };
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser]);
    useEffect(() => {
        var _a, _b;
        if (isFastSearchCorrect(config, fastSearch)) {
            const parsedSearchParams = fastSearchParse(fastSearch, passengersConfig, allowPromoCodes);
            if (!parsedSearchParams.currency) {
                parsedSearchParams.currency = getCurrency();
            }
            if (payByMiles) {
                if (parsedSearchParams.ffpMode &&
                    (!((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) === null || _a === void 0 ? void 0 : _a.userFfpInfo) || ((_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) === null || _b === void 0 ? void 0 : _b.isFfpAuthorizationNeeded))) {
                    setAuthorizationFfp(true);
                }
                else {
                    startSearch(parsedSearchParams);
                }
            }
            else {
                const { ffpMode } = parsedSearchParams, filteredSearchParams = __rest(parsedSearchParams, ["ffpMode"]);
                startSearch(filteredSearchParams);
            }
        }
        else {
            setIsValid(false);
        }
    }, [fastSearch, currentUser]);
    const onCloseAuthorizationFfp = () => {
        setAuthorizationFfp(false);
        setIsValid(false);
    };
    const onSaveAuthorizationFfp = () => {
        setTimeout(() => {
            startSearch(fastSearchParse(fastSearch, passengersConfig, allowPromoCodes));
        }, 2000);
    };
    if (payByMiles && !loading && authorizationFfp) {
        return (React.createElement(LoyaltyConnect, { userInfo: userInfo, isOpen: authorizationFfp, authType: "authenticate", onLoyaltyCardSaved: onSaveAuthorizationFfp, onClose: onCloseAuthorizationFfp, onGoToSettings: onGoToAccountSettings }));
    }
    return isValid && loading ? React.createElement(PromoLoader, null) : React.createElement(React.Fragment, null, redirect);
};
export default FastSearch;
