import { getCurrency } from '../cache';
export const getAeroexpressProductId = (trip) => `${trip.id}${trip.class}${trip.departureDate}`;
export const isSelectedTrip = (trip) => {
    return (trip === null || trip === void 0 ? void 0 : trip.tickets) !== undefined;
};
export const getAeroexpressMinPrice = (trips) => {
    return {
        amount: trips.map(trip => trip.price.amount).sort((a, b) => a - b)[0],
        currency: getCurrency()
    };
};
