import * as React from 'react';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import Dropdown from '../../../../Dropdown';
import { useConfig } from '../../../../context';
import { getCurrency, setCurrency } from '../../../../cache';
import { useTheme } from '../../../../theme';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import SlideBottom from '../../../../SlideBottom/components/SlideBottom';
import { useToggleable } from '../../../../hooks';
const arrowIcon = (React.createElement("svg", { width: "10", height: "5", viewBox: "0 0 10 5", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M0 0L5 5L10 0L0 0Z", fill: "currentColor" })));
const CurrencyComponent = ({ defaultCurrency = getCurrency(), isDisabled, onSetCurrency }) => {
    const { isOpen, toggle, close } = useToggleable(false);
    const [currentCurrency, setCurrencyState] = React.useState(defaultCurrency);
    const theme = useTheme('SearchForm').Currency;
    const currencies = useConfig().SearchForm.selectableCurrencies;
    const currenciesList = React.useMemo(() => {
        return currencies.map(currency => (Object.assign(Object.assign({}, currency), { selected: currency.code === currentCurrency })));
    }, [currencies, currentCurrency]);
    const selectedCurrency = React.useMemo(() => {
        const currencyFromList = currenciesList.find(currency => currency.code === currentCurrency);
        return currencyFromList !== null && currencyFromList !== void 0 ? currencyFromList : currenciesList[0];
    }, [currenciesList, currentCurrency]);
    const setCurrencyHandler = (code) => {
        const currencyCode = code;
        setCurrency(currencyCode);
        setCurrencyState(currencyCode);
        onSetCurrency === null || onSetCurrency === void 0 ? void 0 : onSetCurrency(currencyCode);
        close();
    };
    const renderList = React.useMemo(() => {
        return (React.createElement("div", { className: theme.dropdown }, currenciesList.map(currency => {
            return (React.createElement("div", { key: currency.code, className: theme.item, onClick: () => setCurrencyHandler(currency.code) },
                currency.symbol,
                " - ",
                currency.code));
        })));
    }, [currenciesList]);
    const onToggle = () => {
        if (!isDisabled) {
            toggle();
        }
    };
    return (React.createElement("div", { className: theme.currency },
        React.createElement("button", { className: cn(theme.selector, {
                [theme.selector_disabled]: isDisabled
            }), type: "button", disabled: isDisabled, onClick: onToggle },
            React.createElement("span", { className: theme.selector__inner },
                React.createElement("span", null, selectedCurrency.symbol),
                React.createElement("span", null, selectedCurrency.code)),
            React.createElement("span", { className: cn(theme.arrowIcon, { [theme.arrowIcon_flipped]: isOpen }) }, arrowIcon)),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dropdown, { isOpen: isOpen, onClose: close, outsideClickIgnoreClass: theme.currency.split(' ')[0] }, renderList)),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: isOpen, onClose: close }, renderList))));
};
export default CurrencyComponent;
