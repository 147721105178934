import * as React from 'react';
import { Field, Form } from 'react-final-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import Tooltip from '../../Tooltip';
import { BaseButton } from '../../BaseComponents';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import { useConfig } from '../../context';
initI18n('CheckinRules');
let lastVisibleError = '';
const CheckinRules = ({ onSumbit, rules }) => {
    var _a, _b, _c;
    const { CheckinRules: css } = useTheme('CheckinRules');
    const { t } = useTranslation('CheckinRules');
    const isDev = process.env.NODE_ENV === 'development';
    const iataCode = (_c = (_b = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.iataCode;
    return (React.createElement("div", { className: css.rules },
        isDev && (React.createElement(Helmet, null,
            React.createElement("link", { rel: "stylesheet", type: "text/css", href: {
                    ZZ: '',
                    N4: 'https://nordwindairlines.ru/build/layout/adaptive.css?v1'
                }[iataCode] }))),
        React.createElement("h3", { className: css.rules__title }, t('Terms & Conditions')),
        React.createElement("div", { className: css.rules__text, dangerouslySetInnerHTML: { __html: rules } }),
        React.createElement(Form, { onSubmit: onSumbit }, ({ handleSubmit }) => (React.createElement("form", { className: css.rules__footer, onSubmit: handleSubmit },
            React.createElement(Field, { type: "checkbox", name: "agree", initialValue: false, validate: value => {
                    if (!value) {
                        return t('Please read and accept the online check-in terms and conditions');
                    }
                } }, ({ meta, input }) => {
                if (meta.error) {
                    lastVisibleError = meta.error;
                }
                return (React.createElement(FormControlLabel, { classes: {
                        label: css.rules__label
                    }, control: React.createElement(Tooltip, { open: meta.submitFailed && !input.value, title: lastVisibleError, placement: "top-start" },
                        React.createElement(Checkbox, { color: "primary", classes: {
                                colorPrimary: css.rules__checkbox,
                                checked: css.rules__checkbox_checked
                            }, checked: input.checked, inputProps: Object.assign({}, input) })), label: React.createElement("div", { dangerouslySetInnerHTML: {
                            __html: t('I have read and agreed to the online check-in terms and conditions')
                        } }) }));
            }),
            React.createElement(BaseButton, { type: "submit", variant: "action", className: css.rules__button }, t('Continue')))))));
};
export default CheckinRules;
