import * as React from 'react';
import { useContext, useMemo } from 'react';
import { OrderCardContext } from '../../../OrderCard';
import { isSameDay, parseISO } from 'date-fns';
import { format } from '../../../../../utils';
import { useTranslation } from 'react-i18next';
const WaitPaymentText = () => {
    const { t: translateFunc } = useTranslation('OrderCard');
    const { order } = useContext(OrderCardContext);
    const now = useMemo(() => new Date(), []);
    const isToday = useMemo(() => {
        return isSameDay(parseISO(order.timelimit), now);
    }, [order.timelimit, now]);
    const t = (translateKey) => translateFunc(translateKey, { nsSeparator: '|' });
    if (isToday) {
        return (React.createElement(React.Fragment, null,
            t('The order must be paid today'),
            " ",
            format(order.timelimit, 'HH:mm')));
    }
    else {
        return (React.createElement(React.Fragment, null,
            t('The order must be paid at'),
            " ",
            format(order.timelimit, 'HH:mm, dd.MM.yyyy')));
    }
};
export default WaitPaymentText;
