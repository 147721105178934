import { z } from 'zod';
import { CardType } from '../PromoLoader/types';
import { PromoMessageIcons } from '../PromoMessage/types';
import { BooleanOptional, NumberOptional, StringOptional } from './schemaTypes';
import { Sorting } from '../Results/enums';
import { CheckoutSteps } from '../MobileStepbar/types';
import { CheckinStep } from '../Modules/Checkin/types';
export const StepSchema = z.object({
    isHidden: z.boolean().optional(),
    isDisabled: z.boolean().optional(),
    label: z.string(),
    type: z.union([z.nativeEnum(CheckoutSteps), z.nativeEnum(CheckinStep)]).optional(),
    alwaysActive: z.boolean().optional(),
    alwaysOpen: z.boolean().optional(),
    onClick: z.function().args().returns(z.void()).optional()
});
export const StepsSchema = z.record(StepSchema);
const CardSchema = z.object({
    city: StringOptional,
    type: z.nativeEnum(CardType).nullish(),
    title: StringOptional,
    text: StringOptional,
    image: z.string()
});
const PromoMessageLinkSchema = z.object({
    text: z.string(),
    url: z.string().url()
});
export const PromoMessageSchema = z.object({
    title: z.string(),
    content: z.string(),
    icon: z.nativeEnum(PromoMessageIcons).optional(),
    link: PromoMessageLinkSchema.optional()
});
export const EngineSchema = z.object({
    defaultSteps: StepsSchema.optional(),
    searchFormURL: StringOptional,
    promoLoaders: z.array(CardSchema).optional(),
    promoMessages: z.array(PromoMessageSchema).optional(),
    checkInEnabled: BooleanOptional,
    checkInURL: StringOptional,
    showRefundRedirectButton: BooleanOptional,
    mealWarning: StringOptional,
    mealDefaultFilterButtonPlacement: z.enum(['start', 'end']).optional(),
    calendarDaysCount: NumberOptional,
    showAvailableFaresOnly: BooleanOptional,
    accountURL: StringOptional,
    sortResultPreferCurrentAirline: BooleanOptional,
    resultsFareSelectorInModal: BooleanOptional,
    ignoreBlockedPaymentToReturnOrder: BooleanOptional,
    googleOneTapSignInClientId: StringOptional,
    exareShowPassengersStep: BooleanOptional,
    resultsDefaultSorting: z.nativeEnum(Sorting).optional()
});
