import { __awaiter } from "tslib";
import React, { useState } from 'react';
import { useTheme } from '../../../theme';
import Modal from '../../../Modal/components/Modal';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { WarningRounded } from '../../../Icons';
import { useDeleteOrderMutation } from '@websky/graphql';
import { useToggleable } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '../../../index';
const DeleteOrderModal = ({ orderId, orderLocator, onDeleted, renderTrigger }) => {
    var _a;
    const { t } = useTranslation('OrderCard');
    const { DeleteOrderModal: css } = useTheme('OrderCard');
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const { isOpen, open, close } = useToggleable(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const closeModal = () => {
        setIsDeleting(false);
        close();
    };
    const [deleteOrderMethod] = useDeleteOrderMutation();
    const deleteOrder = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        setIsDeleting(true);
        try {
            const { data, errors } = yield deleteOrderMethod({
                variables: {
                    params: orderId
                }
            });
            if ((_b = data === null || data === void 0 ? void 0 : data.DeleteOrder) === null || _b === void 0 ? void 0 : _b.result) {
                setIsDeleting(false);
                onDeleted();
                close();
                return true;
            }
            else {
                console.error(errors);
                return false;
            }
        }
        catch (error) {
            console.error(`Probably orderId not found`);
            console.error(error);
            return false;
        }
    });
    return (React.createElement(React.Fragment, null, (_a = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger({ open })) !== null && _a !== void 0 ? _a : (React.createElement(BaseButton, { variant: "smallSecondary", className: css.delete_order, onClick: e => {
            e.preventDefault();
            open();
        } }, t('DeleteFromList'))),
        React.createElement(Modal, Object.assign({ maxWidth: "sm", open: isOpen, onClose: closeModal, closeClassName: css.closeIcon }, (isMobile ? { bottomDrawer: true } : {}), { classes: {
                paper: cn(css.paper)
            } }),
            React.createElement("div", { className: css.header },
                React.createElement("div", { className: css.header__icon }, WarningRounded),
                React.createElement("div", { className: css.header__text }, t('BookingRemoval'))),
            React.createElement("div", { className: css.content },
                React.createElement("div", { className: css.message__text }, t('BookingRemovalMessage', { orderLocator: orderLocator }))),
            React.createElement("div", { className: css.footer },
                React.createElement(BaseButton, { variant: "secondary", isLoading: isDeleting, className: css.button__confirm, onClick: deleteOrder }, t('Remove')),
                React.createElement(BaseButton, { variant: "action", className: css.button__cancel, disabled: isDeleting, onClick: closeModal }, t('Cancel'))))));
};
export default DeleteOrderModal;
