import * as React from 'react';
import { useGetExchangeVariantsMutation } from '@websky/graphql';
// adapter for flights list for manual exchange mode
export const getNewFlightsForManualMode = (exchangeParams, setSearchParams, clearFare, onError) => {
    const [currentLeg, setCurrentLeg] = React.useState(0);
    const [runSearch, { loading, data, error }] = useGetExchangeVariantsMutation();
    const runNewSearch = () => {
        clearFares();
        return runSearch({
            variables: {
                params: exchangeParams
            }
        });
    };
    React.useEffect(() => {
        runNewSearch().catch(onError);
    }, [exchangeParams]);
    const startNewSearch = params => {
        setSearchParams(params);
    };
    const clearFares = () => {
        clearFare();
        setCurrentLeg(0);
    };
    return {
        loading,
        results: data,
        error,
        currentLeg,
        setLeg: setCurrentLeg,
        startNewSearch,
        clearFares
    };
};
