import * as React from 'react';
import { SelectableCheckoutSegment } from './SelectableSegment';
export const SelectableCheckinSegment = props => {
    const checkinFlight = props.segment;
    return (React.createElement(SelectableCheckoutSegment, { index: props.index, highlight: props.highlight, showStopsInfo: props.showStopsInfo, transferInfo: props.transferInfo, parentFlight: props.parentFlight, segment: {
            aircraft: checkinFlight.aircraft,
            duration: checkinFlight.duration,
            flightNumber: checkinFlight.flightNumber,
            operatingAirline: checkinFlight.operatingAirline,
            marketingAirline: checkinFlight.marketingAirline,
            departure: checkinFlight.departure,
            arrival: checkinFlight.arrival,
            stops: checkinFlight.stops,
            messages: []
        } }));
};
