import * as React from 'react';
import { useMemo } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
const OrderCities = ({ flight, bookingPreview }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { OrderPreview: css } = useTheme('OrderCard');
    const airports = useMemo(() => {
        var _a, _b;
        let isRoundTrip = false;
        if (flight.segmentGroups.length === 2) {
            const firstSegments = flight.segmentGroups[0].segments;
            const lastSegments = flight.segmentGroups[1].segments;
            const firstCity = (_a = firstSegments[0].departure.airport.city) === null || _a === void 0 ? void 0 : _a.id;
            const lastCity = (_b = lastSegments[lastSegments.length - 1].departure.airport.city) === null || _b === void 0 ? void 0 : _b.id;
            if (firstCity === lastCity) {
                isRoundTrip = true;
            }
        }
        // if there and back then only the starting city and the ending city of the first flight are displayed
        if (isRoundTrip) {
            const segments = flight.segmentGroups[0].segments;
            const first = segments[0];
            const last = segments[segments.length - 1];
            return [first.departure.airport, last.arrival.airport];
        }
        else {
            const segments = flight.segmentGroups[0].segments;
            const first = segments[0];
            return [
                first.departure.airport,
                ...flight.segmentGroups
                    .map(({ segments }) => segments[segments.length - 1])
                    .map(segment => segment.arrival.airport)
            ];
        }
    }, [flight.segmentGroups]);
    if (airports.length === 2 && !bookingPreview) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.city }, (_d = (_b = (_a = airports[0].city) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : (_c = airports[0]) === null || _c === void 0 ? void 0 : _c.iata) !== null && _d !== void 0 ? _d : ''),
            React.createElement("div", { className: cn(css.city, css.main_city) }, (_h = (_f = (_e = airports[1].city) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : (_g = airports[1]) === null || _g === void 0 ? void 0 : _g.iata) !== null && _h !== void 0 ? _h : '')));
    }
    else {
        return (React.createElement("div", { className: css.cities_chain }, airports.map((airport, i) => {
            var _a, _b, _c;
            return (React.createElement("div", { className: css.city, key: i }, (_c = (_b = (_a = airport.city) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : airport === null || airport === void 0 ? void 0 : airport.iata) !== null && _c !== void 0 ? _c : ''));
        })));
    }
};
export default OrderCities;
