import * as React from 'react';
import { useTheme } from '../../theme';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import Slide from '@material-ui/core/Slide';
import { IconButton } from '@material-ui/core';
import { CloseClear } from '../../Icons';
export const SlideBottom = props => {
    const theme = useTheme('SlideBottom').SlideBottomStyles, [isVisible, setVisibile] = useState(false);
    const onClick = useCallback(() => {
        props.onClose();
        window.document.body.style.overflow = 'auto';
    }, [props.onClose]);
    useEffect(() => {
        const bodyComponent = window.document.body;
        if (props.isOpen) {
            setVisibile(true);
            bodyComponent.style.overflow = 'hidden';
        }
        else {
            if (isVisible) {
                bodyComponent.style.overflow = 'auto';
                setTimeout(() => {
                    setVisibile(false);
                }, 250);
            }
        }
        return () => {
            window.document.body.style.overflow = 'auto';
        };
    }, [props.isOpen]);
    return (isVisible && (React.createElement("div", { className: classnames(theme.mainWrapper, props.wrapperClassName, {
            [theme.wrapper_closed]: !props.isOpen
        }) },
        React.createElement("div", { className: classnames(theme.wrapper, props.overlayClassName), onClick: onClick }),
        React.createElement(Slide, { direction: "up", in: props.isOpen },
            React.createElement("div", { className: classnames(theme.container, props.className, {
                    [theme.container_noPadding]: props.noPadding,
                    [theme.container_scrollable]: props.scrollable
                }) },
                props.header && (React.createElement("div", { className: classnames(theme.header, props.headerClassName) },
                    props.header,
                    React.createElement(IconButton, { onClick: props.onClose }, CloseClear))),
                props.children)))));
};
export default SlideBottom;
