import * as React from 'react';
import { useGraphQLClient } from '../../../graphql-client';
import { GuardedApolloProvider } from '../../../GuardedApolloProvider';
import { Provider } from 'react-redux';
import { createStore } from '../store';
import Exchange from './Exchange/Exchange';
import { useParams } from 'react-router-dom';
const Module = props => {
    var _a;
    const client = useGraphQLClient();
    const store = createStore();
    const params = useParams();
    return (React.createElement(GuardedApolloProvider, { client: client },
        React.createElement(Provider, { store: store },
            React.createElement(Exchange, { orderId: (_a = params.id) !== null && _a !== void 0 ? _a : props.orderId, onRefetchCheckoutOrder: props.onRefetchCheckoutOrder }))));
};
export default Module;
