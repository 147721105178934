import * as React from 'react';
import Money from '../Money';
import { getLabel } from './utils';
import { initI18n } from '../utils';
initI18n('PriceInMiles');
const PriceInMiles = ({ amount, moneyClassName, amountClassName, currencyClassName }) => {
    return (React.createElement(Money, { money: {
            amount: amount,
            currency: 'miles'
        }, currencyRenderer: () => {
            return (React.createElement("span", { style: { paddingLeft: '4px' }, className: currencyClassName }, getLabel(amount)));
        }, amountClassName: amountClassName, moneyClassName: moneyClassName, currencyClassName: currencyClassName }));
};
export default PriceInMiles;
