import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedBaggage } from './store/selector';
import { getTotalPrice } from '../../utils';
import { getSelectedServicesBySegments } from '../AdditionalBaggage/utils';
export const usePassengerState = (passengerId, baggages, segmentIds, segmentId) => {
    const servicesIds = baggages.map(baggage => baggage.id);
    const selectedItems = useSelector((state) => getSelectedBaggage(state, segmentId, passengerId, servicesIds));
    const isSelected = !!selectedItems.length;
    const selectedCount = selectedItems
        .filter(i => !segmentIds || (segmentIds === null || segmentIds === void 0 ? void 0 : segmentIds.length) === 0 || i.segmentIds.includes(segmentIds[0]))
        .reduce((count, item) => count + item.count, 0);
    return {
        items: getEmptyServicesItems(baggages, segmentIds, segmentId, passengerId),
        selectedItems,
        isSelected,
        selectedCount
    };
};
export const getEmptyServicesItems = (baggages, segmentIds, segmentId, passengerId) => {
    const items = [];
    const baggagesServicesWithHighestId = new Map();
    baggages.forEach(bag => {
        bag.segmentIds.forEach(segIds => {
            const segmentsKey = segIds.join('-');
            // WEBSKY-3385: In cases where multiple services have same segmentIds,
            // we should leave a service with "highest" id and mark others as disabledToAdd
            if (bag.canBeAdded &&
                (!baggagesServicesWithHighestId.has(segmentsKey) ||
                    parseInt(bag.id) > parseInt(baggagesServicesWithHighestId.get(segmentsKey).id))) {
                baggagesServicesWithHighestId.set(segmentsKey, bag);
            }
            const disabledToAdd = !bag.canBeAdded ||
                (segmentId && !segIds.includes(segmentId)) ||
                bag.allowedPassengers.indexOf(passengerId) === -1;
            items.push({
                serviceId: bag.id,
                passengerId,
                count: 0,
                segmentIds: segIds,
                disabledToAdd: disabledToAdd
            });
        });
    });
    return items;
};
export const serviceForMultipleSegments = (service) => {
    var _a;
    return ((_a = service.segmentIds[0]) === null || _a === void 0 ? void 0 : _a.length) > 1;
};
export const useSelectedServicesBySegments = (services, segments, selectedServices) => {
    const selectedItems = useMemo(() => {
        const selectedServicesBySegments = getSelectedServicesBySegments(services, selectedServices, segments);
        return selectedServicesBySegments.sort((a, b) => {
            if (a.allSegments && !b.allSegments) {
                return -1;
            }
            else if (!a.allSegments && b.allSegments) {
                return 1;
            }
            return +a.service.id - +b.service.id;
        });
    }, [services, selectedServices, segments]);
    const totalPrice = useMemo(() => {
        return getTotalPrice(selectedItems, item => (Object.assign(Object.assign({}, item.service.price), { amount: item.service.price.amount * item.directions.length * item.count })));
    }, [selectedItems]);
    return {
        selectedItems,
        hasSelected: selectedItems.length > 0,
        totalPrice
    };
};
