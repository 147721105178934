import { OrderStatus } from '@websky/graphql';
export const findUpcomingFlight = (orders) => {
    if (!(orders === null || orders === void 0 ? void 0 : orders.length)) {
        return null;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const upcomingOrders = orders.filter(order => order.status === OrderStatus.Confirmed &&
        order.flight.segmentGroups.some(group => group.segments.some(segment => new Date(segment.departure.date).getTime() >= today.getTime())));
    if (upcomingOrders.length === 0) {
        return null;
    }
    const upcomingOrderWithMinDate = upcomingOrders.reduce((minDateAndOrder, order) => {
        const minDepartureDate = order.flight.segmentGroups.reduce((minDate, group) => {
            var _a, _b;
            const departureTimestamp = new Date((_b = (_a = group.segments[0]) === null || _a === void 0 ? void 0 : _a.departure) === null || _b === void 0 ? void 0 : _b.date).getTime();
            if (departureTimestamp < minDate) {
                return departureTimestamp;
            }
            return minDate;
        }, Infinity);
        if (minDepartureDate < minDateAndOrder.minTimestamp) {
            return {
                minTimestamp: minDepartureDate,
                order: order
            };
        }
        return minDateAndOrder;
    }, {
        minTimestamp: Infinity,
        order: orders[0]
    });
    return upcomingOrderWithMinDate.order;
};
