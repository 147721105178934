import * as React from 'react';
import { memo } from 'react';
import cn from 'classnames';
import { LocationMatchType } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
const Option = memo(({ option, onClick }) => {
    const { Option: css } = useTheme('LocationAutocomplete');
    const { t } = useTranslation('LocationAutocomplete');
    const city = option.value;
    const match = city ? city.match : null;
    let meta;
    if (match) {
        if ((match.type === LocationMatchType.IataCode ||
            match.type === LocationMatchType.AlternateName ||
            match.type === LocationMatchType.Airport) &&
            match.name &&
            match.iata) {
            meta = (React.createElement("div", { className: cn(css.meta, css.meta_gray) },
                React.createElement("span", { className: css.meta__label }, match.name),
                React.createElement("span", { className: css.meta__value }, match.iata)));
        }
        else if (match.type === LocationMatchType.ProbableName) {
            meta = React.createElement("div", { className: cn(css.meta, css.meta_probable) }, t('Perhaps you are looking for'));
        }
    }
    return (React.createElement("div", { className: css.option, onMouseDown: city ? onClick : null },
        React.createElement("div", { className: css.info },
            React.createElement("span", { className: css.label }, option.label),
            city && (React.createElement("span", { className: css.aside },
                city.stopPointsCount > 0 && (React.createElement("span", { className: css.stops },
                    city.stopPointsCount,
                    ' ',
                    t('Stop', {
                        count: city.stopPointsCount
                    }))),
                React.createElement("span", { className: css.code }, city.iata)))),
        meta));
});
export default Option;
