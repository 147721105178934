import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../theme';
const Disclaimer = props => {
    const { DisclaimerStyles: theme } = useTheme('Disclaimer');
    return (React.createElement("div", { className: cn(theme.disclaimer, props.wrapperClassName) },
        React.createElement("div", { className: cn(theme.disclaimer__inner, props.className) },
            props.icon && React.isValidElement(props.icon) ? (props.icon) : (React.createElement("span", { className: theme.disclaimer__icon }, props.icon)),
            props.children)));
};
export default Disclaimer;
