import React from 'react';
import { useTheme } from '../../../theme';
import { Group } from './Group/Group';
import { UnreadDot } from './UnreadDot/UnreadDot';
import { Info } from './Info/Info';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import cn from 'classnames';
import Loader from '../../../Loader';
import { useTranslation } from 'react-i18next';
export const NotificationCard = props => {
    const { NotificationCard: css } = useTheme('Communications');
    const { t } = useTranslation('Communications');
    const onClick = (e) => {
        e.preventDefault();
        props.markAsRead();
    };
    const renderDot = () => !props.isRead && (React.createElement("div", { onClick: onClick, title: t('Mark as read'), className: css.card__dot },
        React.createElement(UnreadDot, null)));
    return (React.createElement("div", { className: cn(css.card, {
            [css.card_unread]: !props.isRead
        }) },
        React.createElement("div", { className: css.card__wrapper },
            (props.title || props.date) && (React.createElement(Group, null,
                props.title && React.createElement("div", { className: css.card__title }, props.title),
                props.date && React.createElement(Info, { align: "right" }, props.date))),
            props.preDescRender && props.preDescRender(),
            React.createElement(Group, { className: css.card__group_content },
                props.children,
                React.createElement(MediaQuery, { maxWidth: 'mobile' }, renderDot()))),
        React.createElement(MediaQuery, { minWidth: 'mobile' }, renderDot()),
        props.loading && React.createElement(Loader, { className: css.card__loading, hideText: true })));
};
