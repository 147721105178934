import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { InfoMessagePositionsEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import { useDesktopFlightWithPopup } from '../../DesktopFlight/components/DesktopFlightPopup/hooks';
import Segment from './Segment/Segment';
import FlightPrice from './FlightPrice/FlightPrice';
import DesktopFlightModalContent from '../../DesktopFlight/components/DesktopFlightModalContent/DesktopFlightModalContent';
import Modal from '../../Modal';
import DesktopFlightModalContentSelected from '../../DesktopFlight/components/DesktopFlightModalContentSelected/DesktopFlightModalContentSelected';
import TotalDuration from './TotalDuration/TotalDuration';
import Dropdown from '../../Dropdown';
import { Chevron } from '../../Icons';
import { OverrideComponent } from '../../renderProps';
import InfoMessages from '../../InfoMessages';
const DesktopFlightRoute = props => {
    var _a, _b, _c;
    const { t } = useTranslation('DesktopFlightInfo');
    const { flight, flightIcon, isRecommended, isSelected, isSelectorInModal = true, singleFlight, selectedFares, canceled, searchParameters, onClear, hideOnlyPrice, onSubsidySuggestModalOpen, onSetViewFlight } = props;
    const { DesktopFlightRouteStyles: theme } = useTheme('DesktopFlightRoute');
    const { DesktopFlightPopup: popupCss } = useTheme('DesktopFlight');
    const { openModal, isModalOpen, subStatuses, flightSegments, lastSelectedFare: lastSelectedFareId, closeModal, businessFareGroups, economyFareGroups, milesFareGroups, getOnFareSelect, upgradeOptions, isComparisonOpen, setIsComparisonOpen } = useDesktopFlightWithPopup(props, !isSelectorInModal && singleFlight && !onSubsidySuggestModalOpen);
    const FlightContainer = React.useCallback((children) => {
        return isSelectorInModal || props.isSelected ? (React.createElement(React.Fragment, null, children)) : (React.createElement(Dropdown, { isOpen: true, onClose: (!singleFlight && !isComparisonOpen) || !!onSubsidySuggestModalOpen ? closeModal : () => { }, outsideClickIgnoreClass: theme.fares.split(' ')[0] }, children));
    }, [props.isSelected]);
    const lastSegment = flightSegments[flightSegments.length - 1].segment;
    const isReadOnly = useMemo(() => {
        const flightInProcess = subStatuses.some(status => status === 'AwaitingExchange' || status === 'Upgrade');
        return props.readonly || flightInProcess;
    }, [props.readonly, subStatuses]);
    const renderTotalDuration = useCallback(() => React.createElement(TotalDuration, { lastSegment: lastSegment, flight: flight }), [
        lastSegment,
        flight
    ]);
    const lastSelectedFare = useMemo(() => {
        var _a;
        return (_a = props.flight.pricesForFareGroups.find(fareGroup => fareGroup.fareFamily && fareGroup.fareFamily.id === lastSelectedFareId)) === null || _a === void 0 ? void 0 : _a.fareFamily;
    }, [props.flight.pricesForFareGroups, lastSelectedFareId]);
    const flightId = (_b = (_a = flight.pricesForFareGroups.find(price => { var _a; return ((_a = price === null || price === void 0 ? void 0 : price.fareFamily) === null || _a === void 0 ? void 0 : _a.id) === (lastSelectedFare === null || lastSelectedFare === void 0 ? void 0 : lastSelectedFare.id); })) === null || _a === void 0 ? void 0 : _a.prices) === null || _b === void 0 ? void 0 : _b[0].flight.id;
    const faresContent = isSelected ? (React.createElement(DesktopFlightModalContentSelected, { flightId: flightId, lastSelectedFare: lastSelectedFare, flight: flight, orderId: props.orderId, onClose: closeModal, hideConditionsButton: props.hideConditionsButton, isReturnFlight: props.isReturnFlight, isSelectorInModal: isSelectorInModal, hidePrice: props.hidePrice })) : (React.createElement(OverrideComponent, { componentProps: {
            lastSelectedFare,
            flight,
            flightId,
            orderId: props.orderId,
            economyFares: economyFareGroups,
            businessFares: businessFareGroups,
            milesFares: milesFareGroups,
            getOnFareSelect,
            upgradeOptions,
            hidePrice: props.hidePrice,
            searchParameters,
            onClose: closeModal,
            preselectedFlightClass: props.preselectedFlightClass,
            isReturnFlight: props.isReturnFlight,
            isSelectorInModal,
            setIsComparisonOpen
        }, component: { DesktopFlightRouteModal: DesktopFlightModalContent } }));
    const onToggle = () => {
        if (!isReadOnly && !canceled) {
            if (onSubsidySuggestModalOpen) {
                onSubsidySuggestModalOpen(openModal);
            }
            else if (isModalOpen) {
                closeModal();
            }
            else {
                openModal();
            }
        }
    };
    useEffect(() => {
        onSetViewFlight === null || onSetViewFlight === void 0 ? void 0 : onSetViewFlight(isModalOpen ? flight : null);
    }, [isModalOpen]);
    return (React.createElement(React.Fragment, null,
        FlightContainer(React.createElement("div", { className: theme.container },
            React.createElement("div", { className: cn(theme.flight, {
                    [theme.flight_readonly]: isReadOnly,
                    [theme.flight_canceled]: canceled,
                    [theme.flight_opened]: isModalOpen,
                    [theme.flight_dropdown]: !isSelectorInModal,
                    [theme.selected]: props.isSelected
                }), onClick: onToggle },
                flightIcon,
                React.createElement("div", { className: cn(theme.segments, { [theme.segments_withoutPrices]: props.hidePrice }) },
                    React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.flight, className: theme.infoMessage, itemHtmlClassName: theme.infoMessage__htmlMessage, messages: flight.segments[0].segment.messages }),
                    flightSegments.map((segment, key) => {
                        var _a;
                        return (React.createElement(Segment, { segment: segment, key: segment.segment.id, first: key === 0, last: key === flightSegments.length - 1, stops: ((_a = segment.segment) === null || _a === void 0 ? void 0 : _a.stops) || [], singleFlight: singleFlight, totalDuration: key === flightSegments.length - 1 && renderTotalDuration(), nextDay: key >= 1 &&
                                flightSegments[key - 1].segment.departure.date !==
                                    segment.segment.arrival.date, multipleSegments: flightSegments.length > 1, isReturnFlight: props.isReturnFlight }));
                    }),
                    (flightSegments.length > 1 || ((_c = lastSegment.stops) === null || _c === void 0 ? void 0 : _c.length) > 0) && false && (React.createElement("div", { className: theme.duration }))),
                !props.hidePrice && !subStatuses.length && (React.createElement(React.Fragment, null,
                    React.createElement(FlightPrice, { best: isRecommended, singleFlight: singleFlight, flight: flight, selectedFares: selectedFares, isSelected: isSelected, canceled: props.canceled, onClear: onClear, hideOnlyPrice: hideOnlyPrice, preselectedFlightClass: props.preselectedFlightClass, showSeatsLeftForAllFares: props.showSeatsLeftForAllFares }),
                    !isSelectorInModal && (React.createElement("div", { className: theme.dropdown__icon },
                        React.createElement("span", { className: cn(theme.arrow, { [theme.arrow_opened]: isModalOpen }) }, Chevron))))),
                !!subStatuses.length && (React.createElement("div", { className: theme.subStatus },
                    React.createElement("span", null, t(`SubStatus_${subStatuses[0]}`))))),
            !isSelectorInModal && (React.createElement(Collapse, { className: theme.fares, in: isModalOpen, mountOnEnter: true }, faresContent)))),
        isSelectorInModal && (React.createElement(Modal, { closeClassName: cn({
                [theme.modal__close]: !isSelected
            }), isRounded: isSelected, fullScreen: true, scroll: 'paper', classes: {
                paper: cn(popupCss.paper, popupCss.paper_fullScreen),
                scrollBody: popupCss.scrollBody
            }, closeButtonRenderer: () => null, open: isModalOpen, onClose: closeModal }, faresContent))));
};
export default DesktopFlightRoute;
