import { __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { Plane } from '../../../Icons';
import Button from '../../../Button/Button';
const Location = (_a) => {
    var { classes, country, city, time, distance } = _a, restProps = __rest(_a, ["classes", "country", "city", "time", "distance"]);
    const { Location: css } = useTheme('CoreSchedule');
    return (React.createElement(Button, Object.assign({ classes: Object.assign(Object.assign({}, classes), { root: cn(css.container, classes === null || classes === void 0 ? void 0 : classes.root), disabled: cn(css.disabled, classes === null || classes === void 0 ? void 0 : classes.disabled) }) }, restProps),
        React.createElement("div", { className: css.wrapper },
            React.createElement("div", { className: css.icon }, Plane),
            React.createElement("div", { className: css.content },
                React.createElement("div", { className: css.row },
                    React.createElement("span", { className: css.city }, city),
                    time && React.createElement("span", { className: css.time }, time)),
                React.createElement("div", { className: css.row },
                    React.createElement("span", { className: css.country }, country),
                    distance && React.createElement("span", { className: css.distance }, distance))))));
};
export default Location;
