import * as React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import BaggagePassenger from '../../BaggagePassenger/BaggagePassenger';
import BaggageSegment from '../../BaggageSegment/BaggageSegment';
import { getAvailableSegmentIds } from '../../../store/selector';
const BySegment = props => {
    const commonTheme = useTheme('UpgradeBaggageDialog').UpgradeBaggageDialog;
    const availableSegmentIds = useSelector(getAvailableSegmentIds);
    return (React.createElement("div", { className: cn(commonTheme.body, { [commonTheme.withTabs]: !!props.baggageTab }) }, props.segments.map(segment => {
        const isAvailable = availableSegmentIds.includes(segment.id);
        return (React.createElement(React.Fragment, { key: segment.id },
            React.createElement(BaggageSegment, { segment: segment, baggageByRfics: props.upgradeBaggages, baggageTab: props.baggageTab, mobileSelectedServiceId: props.mobileSelectedServiceId, isAvailable: isAvailable }),
            props.passengers.map((passenger, i) => {
                var _a;
                const key = `${segment.id}_${passenger.id}`;
                const label = props.passengerLabels[passenger.id];
                const number = i + 1;
                const includedSegment = (_a = props.upgradeBaggages[0][0].segmentIds) === null || _a === void 0 ? void 0 : _a.some(segments => segments.includes(segment.id));
                return (React.createElement(BaggagePassenger, { baggageByRfics: props.upgradeBaggages, name: label, number: number, key: key, includedSegment: includedSegment, segmentId: segment.id, mobileSelectedServiceId: props.mobileSelectedServiceId, isAvailable: isAvailable, passenger: passenger }));
            })));
    })));
};
export default BySegment;
