import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Location from '../Location/Location';
import { getDurationString } from '../../../Results/utils';
import FlightCard from '../../../FlightCard';
import TransferInfo from '../../../TransferInfo';
import { useMediaQuery } from 'react-responsive';
import { TABLET_MIDDLE_WIDTH } from '../../../utils';
const FlightInfoSegment = ({ className, cardClassName, transferClassName, segment, nextSegment, isMobile: isMobileParam, isReturnFlight }) => {
    var _a, _b, _c, _d, _e;
    const { FlightInfoSegment: css } = useTheme('DesktopFlight');
    const currentSegment = segment.segment;
    const mobile = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    const isMobile = mobile || isMobileParam;
    return (React.createElement("div", { className: cn(className, css.wrapper, {
            [css.isMobile]: isMobile
        }) },
        React.createElement("div", { className: cn(css.card, cardClassName) },
            React.createElement("div", { className: css.locations },
                React.createElement(Location, Object.assign({ type: "departure", className: css.location, isMobile: isMobile }, currentSegment.departure)),
                React.createElement(Location, Object.assign({ type: "arrival", className: css.location, isMobile: isMobile }, currentSegment.arrival))),
            React.createElement(FlightCard, { className: css.flight, airline: currentSegment.operatingAirline, flightNumber: `${(_a = currentSegment.marketingAirline) === null || _a === void 0 ? void 0 : _a.iata}-${currentSegment.flightNumber}`, duration: getDurationString(currentSegment.duration), aircraft: currentSegment.aircraft, amenities: (_b = currentSegment.flightInfo) === null || _b === void 0 ? void 0 : _b.amenities, markers: (_c = currentSegment.flightInfo) === null || _c === void 0 ? void 0 : _c.markers, statistics: (_d = currentSegment.flightInfo) === null || _d === void 0 ? void 0 : _d.statistics, isReturnFlight: isReturnFlight })),
        (segment.transferDuration || ((_e = currentSegment === null || currentSegment === void 0 ? void 0 : currentSegment.stops) === null || _e === void 0 ? void 0 : _e.length) > 0) && (React.createElement(TransferInfo, { className: cn(css.transfer, transferClassName), segment: currentSegment, nextSegment: nextSegment, transferDuration: segment.transferDuration, showStops: true }))));
};
export default FlightInfoSegment;
