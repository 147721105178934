import { applyMiddleware } from 'redux';
import { createStore as originalCreateStore } from 'redux';
import { rootReducer } from './reducers';
export const createStore = () => {
    const middlewares = [];
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(require('redux-logger').default);
    }
    const store = originalCreateStore(rootReducer, applyMiddleware(...middlewares));
    return store;
};
