import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import { Metasearch } from '../../types';
import { useConfig } from '../../context';
import { iataCodeLogoMap } from './logotypesMap';
import { useRenderers } from '../../renderProps';
import ActionButton from '../../Button/components/ActionButton/ActionButton';
initI18n('MetasearchLoader');
const MetasearchLoader = ({ variant = Metasearch.Aviasales, hasError = false, onRetry }) => {
    var _a, _b;
    const { MetasearchLoader: css } = useTheme('MetasearchLoader');
    const { t } = useTranslation('MetasearchLoader');
    const { global: { companyInfo: { iataCode } } } = useConfig();
    const engineRenderers = useRenderers();
    const logo = (_b = (_a = engineRenderers.MetasearchLoaderLogo) !== null && _a !== void 0 ? _a : iataCodeLogoMap.get(iataCode)) !== null && _b !== void 0 ? _b : null;
    return (React.createElement("div", { className: cn(css.wrapper, {
            [css.wrapper_aviasales]: variant === Metasearch.Aviasales
        }) },
        React.createElement("div", { className: css.holder },
            React.createElement("div", { className: css.logo }, logo),
            React.createElement("div", { className: css.loader }, !hasError && variant === Metasearch.Aviasales && React.createElement("div", { className: css.aviasalesLoader })),
            React.createElement("div", { className: cn(css.footer, {
                    [css.footer_withErrors]: hasError
                }) },
                hasError && (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: css.text }, t('Booking service is temporary unavailable')),
                    React.createElement("p", { className: css.text }, t('Please reload the page')),
                    React.createElement(ActionButton, { className: css.retryButton, onClick: onRetry }, t('Reload')))),
                !hasError && (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: css.text }, t('Checking seats availability')),
                    React.createElement("p", { className: css.text }, t('Just a few seconds...'))))))));
};
export default MetasearchLoader;
