import * as React from 'react';
import { useMemo, useState } from 'react';
import { useTheme } from '../theme';
import SimpleLoader from '../SimpleLoader';
import SegmentTabs from '../SegmentTabs/SegmentTabs';
import MediaQuery from '../MediaQuery/MediaQuery';
import { initI18n, scrollTo } from '../utils';
import FooterMobile from '../widgets/navigation/FooterMobile';
import cn from 'classnames';
initI18n('ServiceWithSegments');
const ServiceWithSegments = ({ children, isLoading, title, segments, enabledSegments, onMobileProceed, onMobileBack, initialSegmentId = '0', onClose, segmentTabsClassName, segmentTabsWrapperClassName, segmentTabsSegmentsClassName, className }) => {
    const { ServiceWithSegments: css } = useTheme('ServiceWithSegments');
    const [activeSegmentId, setActiveSegmentId] = useState(initialSegmentId);
    const activeSegment = segments.find(({ id }) => id === activeSegmentId);
    const nextSegmentId = useMemo(() => [...enabledSegments]
        .filter(segmentId => segments.find(segment => parseInt(segment.id, 10) === parseInt(segmentId, 10)))
        .sort()
        .find(segmentId => parseInt(segmentId, 10) > parseInt(activeSegmentId, 10)), [enabledSegments, activeSegmentId]);
    const onContinue = () => {
        if (typeof nextSegmentId !== 'undefined') {
            setActiveSegmentId(nextSegmentId);
        }
        else {
            onMobileProceed();
        }
        scrollTo(0);
    };
    const onBack = () => {
        const prevSegmentId = [...enabledSegments]
            .sort()
            .reverse()
            .find(segmentId => parseInt(segmentId) < parseInt(activeSegmentId));
        if (typeof prevSegmentId !== 'undefined') {
            setActiveSegmentId(prevSegmentId);
        }
        else {
            onMobileBack();
        }
        scrollTo(0);
    };
    return (React.createElement("div", { className: cn(css.service, className) },
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement(MediaQuery, { minWidth: "mobile" },
            React.createElement(SegmentTabs, { title: title, segments: segments, sticky: true, onSegmentClick: setActiveSegmentId, activeSegment: activeSegmentId, onClose: onClose, className: segmentTabsClassName, wrapperClassName: segmentTabsWrapperClassName, segmentsClassName: segmentTabsSegmentsClassName })),
        React.createElement("div", { className: css.wrapper },
            React.createElement(MediaQuery, { maxWidth: "mobile" },
                React.createElement("div", { className: css.title }, activeSegment.title)),
            children({
                activeSegmentId
            })),
        React.createElement(MediaQuery, { maxWidth: "mobile" },
            React.createElement(FooterMobile, { onBack: onBack, onContinue: onContinue }))));
};
export default ServiceWithSegments;
