import { Tabs } from './types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { filterFareGroups } from '../Results/utils';
import { useConfig } from '../context';
import { FlightClass } from '@websky/graphql';
export const useDesktopFlight = (componentProps) => {
    const { selectedFares, flight, isPreOpen, onSelect } = componentProps;
    const { showAvailableFaresOnly } = useConfig().Engine;
    const flightBlock = useRef(null);
    const [activeTab, openTab] = useState(null);
    const lastSelectedFare = useMemo(() => {
        var _a, _b;
        return (_b = (_a = selectedFares === null || selectedFares === void 0 ? void 0 : selectedFares[selectedFares.length - 1]) === null || _a === void 0 ? void 0 : _a.fareGroupId) !== null && _b !== void 0 ? _b : null;
    }, [selectedFares]);
    // #fixme: вынести во Flight
    const economyFareGroups = filterFareGroups(flight.pricesForFareGroups, selectedFares !== null && selectedFares !== void 0 ? selectedFares : [], FlightClass.Economy, showAvailableFaresOnly);
    const businessFareGroups = filterFareGroups(flight.pricesForFareGroups, selectedFares !== null && selectedFares !== void 0 ? selectedFares : [], FlightClass.Business, showAvailableFaresOnly);
    const milesFareGroups = filterFareGroups(flight.pricesForFareGroups, selectedFares !== null && selectedFares !== void 0 ? selectedFares : [], FlightClass.Miles, showAvailableFaresOnly);
    const filteredPrices = useMemo(() => {
        return economyFareGroups.concat(businessFareGroups);
    }, [economyFareGroups, businessFareGroups]);
    const subStatuses = useMemo(() => {
        const subStatusSet = new Set();
        flight.segments.forEach(segment => {
            var _a;
            if ((_a = segment === null || segment === void 0 ? void 0 : segment.segment) === null || _a === void 0 ? void 0 : _a.subStatus) {
                const subStatus = segment.segment.subStatus;
                subStatusSet.add(subStatus);
            }
        });
        return [...subStatusSet];
    }, [flight.segments]);
    const flightSegments = useMemo(() => {
        if (subStatuses.includes('Upgrade') || subStatuses.includes('AwaitingExchange')) {
            return flight.segments.filter(segment => {
                var _a;
                const subStatus = (_a = segment === null || segment === void 0 ? void 0 : segment.segment) === null || _a === void 0 ? void 0 : _a.subStatus;
                if (!subStatus) {
                    return true;
                }
                return !['Upgrade', 'AwaitingExchange'].includes(subStatus);
            });
        }
        return flight.segments;
    }, [flight.segments, subStatuses]);
    useEffect(() => {
        activeTab
            ? setTimeout(() => {
                var _a;
                (_a = flightBlock === null || flightBlock === void 0 ? void 0 : flightBlock.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
            }, 120)
            : null;
    }, [activeTab]);
    useEffect(() => {
        if (isPreOpen) {
            if (economyFareGroups.some(fareGroup => fareGroup.prices)) {
                openTab(Tabs.EconomyFares);
            }
            else if (businessFareGroups.some(fareGroup => fareGroup.prices)) {
                openTab(Tabs.BusinessFares);
            }
        }
    }, [isPreOpen]);
    const onOpenFlightInfoTab = useCallback((tab) => () => openTab(active => (active !== tab ? tab : null)), []);
    const getFareGroupTabHandler = useCallback((serviceClass) => {
        if (serviceClass === FlightClass.Business) {
            return onOpenFlightInfoTab(Tabs.BusinessFares);
        }
        return onOpenFlightInfoTab(Tabs.EconomyFares);
    }, [onOpenFlightInfoTab]);
    // #fixme: this thing needs to be isolated
    const getOnFareSelect = (group) => {
        return () => {
            if (onSelect) {
                onSelect({
                    fareGroupId: group.fareFamily.id,
                    fareFamily: group.fareFamily,
                    fareName: group.fareFamily.title,
                    prices: group.prices,
                    flightInfo: {
                        id: group.prices[0].flight.id,
                        segments: flight.segments
                    }
                });
                openTab(null);
            }
        };
    };
    return {
        // data
        lastSelectedFare,
        subStatuses,
        flightSegments,
        flightBlock,
        activeTab,
        flight,
        filteredPrices,
        economyFareGroups,
        businessFareGroups,
        milesFareGroups,
        // methods
        getOnFareSelect,
        getFareGroupTabHandler,
        handleFlightInfoTab: onOpenFlightInfoTab(Tabs.FlightInfo),
        handleEconomyFareGroupsTab: onOpenFlightInfoTab(Tabs.EconomyFares),
        handleBusinessFareGroupsTab: onOpenFlightInfoTab(Tabs.BusinessFares),
        handleMilesFareGroupsTab: onOpenFlightInfoTab(Tabs.MilesFares)
    };
};
