import * as React from 'react';
import { useContext, useMemo } from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import { getReadablePeriod } from '../../hooks';
import OrderCities from './OrderCities/OrderCities';
import { OrderCardContext } from '../OrderCard';
const OrderPreview = ({ className, onClick }) => {
    const { OrderPreview: css } = useTheme('OrderCard');
    const { order } = useContext(OrderCardContext);
    const period = getReadablePeriod(order);
    const styles = useMemo(() => {
        var _a, _b, _c, _d;
        const segments = order.flight.segmentGroups[0].segments;
        const overlay = (_d = (_c = (_b = (_a = segments[segments.length - 1].arrival.airport) === null || _a === void 0 ? void 0 : _a.city) === null || _b === void 0 ? void 0 : _b.images) === null || _c === void 0 ? void 0 : _c.panorama) === null || _d === void 0 ? void 0 : _d.url;
        if (overlay) {
            return {
                backgroundImage: `url('${overlay}')`
            };
        }
        else {
            return {};
        }
    }, [order.flight.segmentGroups]);
    return (React.createElement("div", { className: cn(css.root, className, { [css.clickable]: onClick }), onClick: onClick, style: styles },
        React.createElement("div", { className: css.overlay }),
        React.createElement("div", { className: css.inner },
            React.createElement(OrderCities, { flight: order.flight }),
            React.createElement("div", { className: css.period }, period))));
};
export default OrderPreview;
