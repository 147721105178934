import { z } from 'zod';
import { AccountSchema } from './AccountSchema';
import { FlightStatusSchema } from './FlightStatusSchema';
import { SearchFormSchema } from './SearchFormSchema';
import { EngineSchema } from './EngineSchema';
import { CheckinSchema } from './CheckinSchema';
import { GlobalConfigSchema } from './GlobalConfigSchema';
const ConfigSchema = z.object({
    FlightStatus: FlightStatusSchema.optional(),
    SearchForm: SearchFormSchema.optional(),
    Engine: EngineSchema.optional(),
    Account: AccountSchema.optional(),
    Checkin: CheckinSchema.optional(),
    global: GlobalConfigSchema
});
export default ConfigSchema;
