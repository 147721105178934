import * as React from 'react';
import { useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import { ACCESS_DENIED_ERROR_EVENT } from '../../apolloErrorLink';
import { useToggleable } from '../../hooks';
import { useTranslation } from 'react-i18next';
const AccessDeniedError = () => {
    const { t } = useTranslation('Checkout');
    const { isOpen, close, open } = useToggleable(false);
    useEffect(() => {
        const eventHandler = (event) => open();
        document.addEventListener(ACCESS_DENIED_ERROR_EVENT, eventHandler);
        return () => document.removeEventListener(ACCESS_DENIED_ERROR_EVENT, eventHandler);
    }, []);
    return (React.createElement(Snackbar, { anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 4000, open: isOpen, onClose: close, message: t('You are not logged in') }));
};
export default AccessDeniedError;
