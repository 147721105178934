import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import MealPage from '../../../../MealPage';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import { getPassengerLabels } from '../../../store/passengers/selectors';
import { getBonusForFlight, getIsConfirmed, getMeals, getOrder, getMessages, getOrderId, getPriceInMiles, getRecommendedMeals, getSegmentsForServices, getServices, getShowMealRecommendations, getTravellers, hasBaggageServices, hasSeatsServices } from '../../../store/order/selectors';
import { fillOrder } from '../../../store/order/actions';
import { MEAL_RECOMMENDATION_SHOWN, set } from '../../../../cache';
import ServicesToolbar from '../../../../ServicesToolbar';
import { getMealsPriceToPay, getTotalPriceToPay } from '../../../store/orderPrice/selectors';
import { addServiceAction, removeServiceAction, deleteAllTypesOnSegment, setServiceAction, saveServicesAction, setMultipleSelectedServices as setMultipleSelectedServicesAction } from '../../../store/selectedServices/actions';
import { getPassengerStateCompatible, getSelectedServicesState } from '../../../store/selectedServices/selectors';
import { getMealReduxCallbacks } from '../../../utils';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { useMediaQuery } from 'react-responsive';
import { isServicesSaving } from '../../../store/servicesIsSaving/selectors';
import AvailableServicesCountContextProvider from '../../../../AdditionalServices/context';
import InfoMessagesContextProvider from '../../../../InfoMessages/context';
const Meal = ({ totalPrice, passengersState, goToNextStep: setNextStep, goToPrevStep, hasBaggageStep, hasSeatsStep, isConfirmed, passengerLabels, mealTotalSum, setService, services }) => {
    const { t } = useTranslation('Checkout');
    const css = useTheme('Checkout').CheckoutStyles;
    const dispatch = useDispatch();
    const segments = useSelector(getSegmentsForServices);
    const meals = useSelector(getMeals);
    const recommendedMeals = useSelector(getRecommendedMeals);
    const showRecommendations = useSelector(getShowMealRecommendations);
    const orderId = useSelector(getOrderId);
    const travellers = useSelector(getTravellers);
    const bonusForFlight = useSelector(getBonusForFlight);
    const priceInMiles = useSelector(getPriceInMiles);
    const isOrderServicesSaving = useSelector(isServicesSaving);
    const order = useSelector(getOrder);
    const selectedServices = useSelector(getSelectedServicesState);
    const orderMessages = useSelector(getMessages);
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    React.useEffect(() => {
        if (isMobile) {
            window.scrollTo(0, 0);
        }
    }, []);
    const selectedMeals = [];
    passengersState.forEach(passenger => {
        if (passenger.services) {
            passenger.services.forEach(product => {
                const serviceObject = services.find(({ id }) => id === product.serviceId);
                if (serviceObject && serviceObject.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
                    selectedMeals.push({
                        passengerId: passenger.id,
                        segmentId: product.segmentIds[0],
                        mealId: product.serviceId,
                        count: product.count,
                        confirmedCount: product.confirmedCount
                    });
                }
            });
        }
    });
    // Do not show recommendations twice.
    const cacheRecommendationState = () => set(`${MEAL_RECOMMENDATION_SHOWN}_${orderId}`, true);
    const mealCallbacks = useMemo(() => getMealReduxCallbacks(dispatch), [dispatch]);
    const passengers = useMemo(() => travellers.map(traveller => (Object.assign(Object.assign({}, traveller), { label: passengerLabels[traveller.id] }))), [travellers, passengerLabels]);
    return (React.createElement(ServicesToolbar, { toolbarProps: {
            totalPrice,
            onButtonClick: setNextStep,
            onBackButtonClick: hasSeatsStep || hasBaggageStep ? goToPrevStep : null,
            additional: mealTotalSum.amount ? (React.createElement("div", { className: css.gdsTotalPrice },
                t('Meal'),
                ": ",
                React.createElement(Money, { money: mealTotalSum }))) : null,
            miles: bonusForFlight,
            priceInMiles
        } },
        React.createElement(AvailableServicesCountContextProvider, { value: { order, selectedServices } },
            React.createElement(InfoMessagesContextProvider, { value: { messages: orderMessages } },
                React.createElement(MealPage, Object.assign({ segments: segments, passengers: passengers, readonly: isConfirmed, showRecommendations: showRecommendations, meals: meals, gdsServices: order.additionalServices.gdsServices, selectedMeals: selectedMeals, recommendedMeals: recommendedMeals, onMobileProceed: setNextStep, onMobileBack: goToPrevStep, onRecommendationConfirm: () => {
                        travellers.map(({ preselectedServices, id }) => {
                            preselectedServices.gdsServices.services
                                .filter(service => meals.some(meal => meal.id === service.serviceId))
                                .forEach(service => {
                                setService({
                                    passengerId: id,
                                    count: service.count,
                                    segmentId: service.segmentIds[0],
                                    allowedSegments: [service.segmentIds],
                                    serviceId: service.serviceId,
                                    service: {
                                        id: service.serviceId,
                                        type: OrderAdditionalServiceGdsServiceServiceType.Meal
                                    }
                                });
                            });
                            cacheRecommendationState();
                        });
                    }, onRecommendationDisagree: cacheRecommendationState, isLoading: isOrderServicesSaving }, mealCallbacks))))));
};
const mapStateToProps = () => {
    return (state, ownProps) => (Object.assign(Object.assign({}, ownProps), { isConfirmed: getIsConfirmed(state), passengersState: getPassengerStateCompatible(state), totalPrice: getTotalPriceToPay(state), hasSeatsStep: hasSeatsServices(state), hasBaggageStep: hasBaggageServices(state), passengerLabels: getPassengerLabels(state), mealTotalSum: getMealsPriceToPay(state), services: getServices(state) }));
};
const mapDispatchToProps = {
    fillOrder,
    addService: addServiceAction,
    removeService: removeServiceAction,
    setService: setServiceAction,
    saveService: saveServicesAction,
    setMultipleSelectedServices: setMultipleSelectedServicesAction,
    deleteAllTypesOnSegment
};
export default connect(mapStateToProps, mapDispatchToProps)(Meal);
