import { createTheme } from '../utils';
import * as MobileSegmentInfo from './components/MobileSegmentInfo.css';
import * as MobileSegmentFlat from './components/MobileSegmentFlat/MobileSegmentFlat.css';
import * as MobileSegmentUpgrade from './components/MobileSegmentUpgrade/MobileSegmentUpgrade.css';
export const defaultTheme = {
    MobileSegmentInfo,
    MobileSegmentFlat,
    MobileSegmentUpgrade
};
export default defaultTheme;
export const { useTheme } = createTheme(defaultTheme);
