import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
import Card from './Card/Card';
import FfpInfo from './FfpInfo/FfpInfo';
const LoyaltyInfo = ({ className, ffpInfo, userInfo, loyaltyName, links, showLinksCount, onLoyaltySaved, onLogoutFfp, onGoToSettings, isLoading, hideProgress }) => {
    const { LoyaltyInfo: css } = useTheme('LoyaltyInfo');
    return (React.createElement("div", { className: cn(className, css.container) },
        React.createElement(Card, { className: css.card, level: ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.currentLevel, number: ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.cardNumber, cardHolder: ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.cardHolder, loyaltyName: loyaltyName }),
        React.createElement(FfpInfo, { className: css.content, userInfo: userInfo, ffpInfo: ffpInfo, links: links, showLinksCount: showLinksCount, onLoyaltySaved: onLoyaltySaved, onLogoutFfp: onLogoutFfp, onGoToSettings: onGoToSettings, isLoading: isLoading, hideProgress: hideProgress })));
};
export default LoyaltyInfo;
