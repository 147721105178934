import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { CommunicationDetailPageWrapper } from '../CommunicationDetailPageWrapper/CommunicationDetailPageWrapper';
import { PaximizerCommunicationSectionEnum, useGetPaxPromotionTeasersContentQuery } from '@websky/graphql';
import { useCommunicationDetail } from '../../hooks';
export const PromotionsDetailPage = () => {
    const { params } = useRouteMatch();
    const { data, loading, error } = useGetPaxPromotionTeasersContentQuery({
        variables: {
            messageId: params.detailId
        }
    });
    useCommunicationDetail(PaximizerCommunicationSectionEnum.promotions, data === null || data === void 0 ? void 0 : data.PaximizerCommunicationPromotionContent);
    return (React.createElement("div", null,
        React.createElement(CommunicationDetailPageWrapper, { error: !!(error === null || error === void 0 ? void 0 : error.graphQLErrors.length), loading: loading, body: data === null || data === void 0 ? void 0 : data.PaximizerCommunicationPromotionContent.body })));
};
