import { __rest } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Money } from '../../Money';
import { getLocale, initI18n } from '../../utils';
import PriceParts from './PriceParts/PriceParts';
import { useTheme } from '../../theme';
import GooglePay from './Gateway/GooglePay/GooglePay';
import Kaspi from './Gateway/Kaspi/Kaspi';
import Iframe from './Gateway/Iframe/Iframe';
import { PaymentType } from '../types';
import Link from '../../Link';
import Test from './Gateway/Test/Test';
import MediaQuery from '../../MediaQuery/MediaQuery';
import { arrowIcon, emptyCard } from '../Icons';
import { BaseButton } from '../../BaseComponents';
import Notification from '../../Notification/components/Notification';
import { InfoMessageTypeEnum, PaymentProvider } from '@websky/graphql';
import Segments from './Segments/Segments';
import SummaryGroup from './Summary/SummaryGroup';
import Summary from './Summary/Summary';
import SummaryItem from './Summary/SummaryItem';
import PaymentTimeLimit from '../../PaymentTimeLimit';
import Cash from './Gateway/Cash/Cash';
import Invoice from './Gateway/Invoice/Invoice';
import Qiwi from './Gateway/Qiwi/Qiwi';
import EmptyWrapper from './EmptyWrapper/EmptyWrapper';
import PaymentResult from '../../PaymentResult';
import Modal from '../../Modal';
import SlideBottom from '../../SlideBottom';
import { useConfig } from '../../context';
import { OverrideComponent } from '../../renderProps';
import { Currency } from '../../enums';
import Tooltip from '../../Tooltip';
import { useSentryTranslation } from '../../hooks';
initI18n('PaymentForm');
const mastercard = require('../images/mastercard.svg');
const visa = require('../images/visa.svg');
const mir = require('../images/mir.svg');
const paylate = require('../images/paylate.svg');
const moneycare = require('../images/moneyCare.svg');
const invoicebox = require('../images/invoicebox.svg');
const invoiceboxRu = require('../images/invoicebox-ru.svg');
const iconKaspi = require('../images/kaspi.svg');
const applePay = require('../images/apple-pay.svg');
const googlePay1 = require('../images/google-pay-1.svg');
const qiwiIcon = require('../images/qiwi.svg');
const sberpay = require('../images/sberpay.svg');
const yandexpay = require('../images/yandex-pay.svg');
const sbp = require('../images/sbp.svg');
const idram = require('../images/idram.svg');
const vakifBank = require('../images/vakifbank.svg');
const unionPay = require('../images/unionPay.svg');
const PaymentForm = ({ card, onPaymentFrameLoaded, credit, googlePay, itransfer, kaspi, cash, fps, sbol, invoice, qiwi, priceToPay, onSuccess, onFail, onTestPayment, onBackToOrder, onPaymentMethodSelected, paymentPage, order, onCompletePayment, isPaymentAvailable, orderIsRefetching, openFrameInNewTab }) => {
    const [page, setPage] = useState(paymentPage !== null && paymentPage !== void 0 ? paymentPage : null);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const { PaymentForm: css } = useTheme('PaymentForm');
    const { t } = useTranslation('PaymentForm');
    const { t: sentryT } = useSentryTranslation('PaymentForm');
    let isPreOpened = false;
    const isOneMethodOnly = !googlePay &&
        !onTestPayment &&
        !cash &&
        !itransfer &&
        !kaspi &&
        !qiwi &&
        ((card && !credit) || (credit && !card));
    const { global: { companyInfo: { iataCode } } } = useConfig();
    const locale = getLocale();
    isPreOpened = isOneMethodOnly || !!paymentPage;
    const resetPage = () => location.reload();
    const { total } = priceToPay, priceToPayWithoutTotal = __rest(priceToPay, ["total"]);
    const currency = total === null || total === void 0 ? void 0 : total.currency;
    const testPaymentEnabled = process.env.TEST_PAYMENT_ENABLED === 'true';
    useEffect(() => {
        onPaymentMethodSelected(page);
    }, [page, paymentPage]);
    const renderEmptyPaymentNotice = () => (React.createElement(Notification, { infoMessage: {
            text: t('Payment by this method is temporarily unavailable, please try refreshing the page or try again later.'),
            type: InfoMessageTypeEnum.Info,
            code: null
        }, status: InfoMessageTypeEnum.Info, type: "context" }));
    const onStartRedirecting = () => setIsRedirecting(true);
    const getGatewayTranslation = useCallback((method) => {
        // [WEBSKY-4989]: display standard translations if the currency is different from RUB
        if (iataCode === '2S' && currency !== Currency.RUB) {
            return { title: null, header: null };
        }
        return {
            title: sentryT(`Title_${method.paymentProvider}`),
            header: sentryT(`Header_${method.paymentProvider}`)
        };
    }, [iataCode, currency, sentryT]);
    const renderGatewayCards = useCallback((method, paymentType) => {
        if (paymentType === PaymentType.Card ||
            // [WEBSKY-4989]: display common cards if the currency is different from RUB
            (iataCode === '2S' && currency !== Currency.RUB)) {
            return (React.createElement(React.Fragment, null,
                React.createElement("img", { src: sbp, alt: "SBP", className: cn(css.card, css.sbp) }),
                React.createElement("img", { src: sberpay, alt: "SberPay", className: cn(css.card, css.sberpay) }),
                React.createElement("img", { src: mastercard, alt: "Mastercard", className: cn(css.card, css.mastercard) }),
                React.createElement("img", { src: visa, alt: "VISA", className: cn(css.card, css.visa) }),
                React.createElement("img", { src: mir, alt: "\u041C\u0418\u0420", className: cn(css.card, css.mir) }),
                React.createElement("img", { src: applePay, alt: "ApplePay", className: cn(css.card, css.applepay) }),
                React.createElement("img", { src: googlePay1, alt: "GooglePay", className: cn(css.card, css.googlepay) }),
                React.createElement("img", { src: yandexpay, alt: "YandexPay", className: cn(css.card, css.yandexpay) }),
                React.createElement("img", { src: vakifBank, alt: "VakifBank", className: cn(css.card, css.vakifbank) }),
                React.createElement("img", { src: paylate, alt: "PaylateAsCard", className: cn(css.card, css.paylate_asCard) }),
                React.createElement("img", { src: unionPay, alt: "UnionPay", className: cn(css.card, css.unionpay) })));
        }
        switch (method.paymentProvider) {
            case PaymentProvider.Idram:
                return React.createElement("img", { src: idram, alt: "Idram", className: cn(css.idram, css.visible) });
            case PaymentProvider.PayLate:
                return React.createElement("img", { src: paylate, alt: "Paylate", className: cn(css.card, css.paylate, css.visible) });
            case PaymentProvider.MoneyCarePro:
                return React.createElement("img", { src: moneycare, alt: "Paylate", className: cn(css.paylate, css.visible) });
            case PaymentProvider.YandexPay:
                return React.createElement("img", { src: yandexpay, alt: "YandexPay", className: cn(css.card, css.yandexpay, css.visible) });
            default:
                return React.createElement(React.Fragment, null, emptyCard);
        }
    }, [iataCode, currency]);
    return (React.createElement("div", { className: cn(css.wrapper, {
            [css.oneMethod]: isOneMethodOnly
        }), "data-gatewaytype": card === null || card === void 0 ? void 0 : card.gatewayType },
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(Modal, { open: !page && isPaymentAvailable === false, classes: { paper: css.payment__result } },
                React.createElement(PaymentResult, { result: "fail", onClick: resetPage, onBackToOrder: onBackToOrder, isModal: true }))),
        React.createElement(MediaQuery, { maxWidth: 'mobile' },
            React.createElement(SlideBottom, { isOpen: !page && isPaymentAvailable === false, onClose: onBackToOrder },
                React.createElement(PaymentResult, { result: "fail", onClick: resetPage, onBackToOrder: onBackToOrder, isModal: true }))),
        React.createElement(Summary, { className: css.details },
            order && (React.createElement(SummaryGroup, { className: css.summaryGroup_order },
                React.createElement(Segments, { order: order }))),
            React.createElement(SummaryGroup, { className: css.summaryGroup_priceParts },
                React.createElement(OverrideComponent, { componentProps: {
                        prices: priceToPayWithoutTotal
                    }, component: { PriceParts } })),
            React.createElement(SummaryGroup, { className: css.summaryGroup_total },
                React.createElement(SummaryItem, { className: css.details__totalItem, price: React.createElement("h2", { className: css.details__total },
                        t('Total'),
                        ": ",
                        React.createElement(Money, { money: total })) }, !!onBackToOrder && (React.createElement(BaseButton, { className: css.details__button, variant: "text", onClick: onBackToOrder }, t('Edit order')))))),
        React.createElement("div", { className: css.content },
            React.createElement("h3", { className: css.content__title },
                React.createElement(MediaQuery, { maxWidth: 'mobile' }, page && !isOneMethodOnly && (React.createElement(Link, { className: css.content__title__back, action: resetPage }, arrowIcon))),
                React.createElement("span", null, page ? page.title : t('Choose a convenient payment method')),
                React.createElement(MediaQuery, { minWidth: 'mobile' }, page && !isOneMethodOnly && (React.createElement(BaseButton, { variant: "text", className: css.content__title__back, onClick: resetPage }, t('Other methods'))))),
            React.createElement("div", { className: cn(css.content__tooltip) },
                React.createElement(Tooltip, { title: React.createElement("div", { className: cn(css.content__tooltip__text) }, t('Payment problems hint')), tooltipClassName: cn(css.tooltip), enterTouchDelay: 0 },
                    React.createElement("span", null, t('Problems with payment?')))),
            page && page.description && React.createElement("p", { className: css.description }, page.description),
            (order === null || order === void 0 ? void 0 : order.timelimit) && (React.createElement(PaymentTimeLimit, { variant: "normal", className: css.timelimit, orderIsBooked: true, date: new Date(order.timelimit), orderId: order.id })),
            React.createElement("ul", { className: cn(css.gateways, {
                    [css.gateways_hidden]: !!page
                }) },
                React.createElement(EmptyWrapper, { emptyRender: renderEmptyPaymentNotice, isRefetching: orderIsRefetching },
                    googlePay && (React.createElement(GooglePay, { price: priceToPay === null || priceToPay === void 0 ? void 0 : priceToPay.total, onClick: setPage, onSuccess: onSuccess, onFail: onFail })),
                    card && (React.createElement(Iframe, { openFrameInNewTab: openFrameInNewTab, type: PaymentType.Card, frameInfo: card, onClick: setPage, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Card, onPaymentFrameLoaded: onPaymentFrameLoaded, gatewayTitle: getGatewayTranslation(card).title, headerTitle: getGatewayTranslation(card).header, children: React.createElement("div", { className: css.cards }, renderGatewayCards(card, PaymentType.Card)) })),
                    kaspi && (React.createElement(Kaspi, { isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Kaspi, frameInfo: kaspi, headerTitle: t('Kaspi'), onClick: setPage },
                        React.createElement("div", { className: css.cards },
                            React.createElement("img", { src: iconKaspi, alt: t('Kaspi'), className: cn(css.card, css.kaspi) })))),
                    credit && (React.createElement(Iframe, { openFrameInNewTab: openFrameInNewTab, type: PaymentType.Credit, frameInfo: credit, onClick: setPage, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Credit, gatewayTitle: getGatewayTranslation(credit).title, headerTitle: getGatewayTranslation(credit).header, children: React.createElement("div", { className: css.cards }, renderGatewayCards(credit, PaymentType.Credit)) })),
                    itransfer && (React.createElement(Iframe, { openFrameInNewTab: openFrameInNewTab, type: PaymentType.Itransfer, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Itransfer, frameInfo: itransfer, onClick: setPage, headerTitle: t('Invoicebox'), gatewayTitle: t('Invoicebox'), variant: "page" },
                        React.createElement("div", { className: css.cards },
                            React.createElement("img", { src: locale === 'ru' ? invoiceboxRu : invoicebox, alt: t('Invoicebox'), className: cn(css.card, css.invoicebox) })))),
                    sbol && (React.createElement(Iframe, { type: PaymentType.Sbol, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Sbol, frameInfo: sbol, onClick: setPage, headerTitle: t('SberPay'), gatewayTitle: t('SberPay'), variant: "page", onStartRedirecting: onStartRedirecting },
                        React.createElement("div", { className: css.cards },
                            React.createElement("img", { src: sberpay, alt: t('SberPay'), className: cn(css.icon) })))),
                    fps && (React.createElement(Iframe, { type: PaymentType.Fps, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Fps, frameInfo: fps, onClick: setPage, headerTitle: t('FPS'), gatewayTitle: t('FPS'), variant: "page", onStartRedirecting: onStartRedirecting },
                        React.createElement("div", { className: css.cards },
                            React.createElement("img", { src: sbp, alt: t('FPS'), className: cn(css.icon) })))),
                    cash && testPaymentEnabled && (React.createElement(Cash, { isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Cash, frameInfo: cash, headerTitle: t('Cash'), type: PaymentType.Cash, onClick: setPage, onCompletePayment: onCompletePayment })),
                    invoice && (React.createElement(Invoice, { isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Invoice, frameInfo: invoice, headerTitle: t('Invoice'), type: PaymentType.Invoice, onClick: setPage, onCompletePayment: onCompletePayment })),
                    qiwi && (React.createElement(Qiwi, { isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Qiwi, frameInfo: qiwi, headerTitle: t('QIWI'), onClick: setPage },
                        React.createElement("div", { className: css.cards },
                            React.createElement("img", { src: qiwiIcon, alt: t('QIWI'), className: css.qiwi })))),
                    onTestPayment && React.createElement(Test, { onClick: onTestPayment }))),
            page && (React.createElement("div", null,
                React.createElement(EmptyWrapper, { emptyRender: renderEmptyPaymentNotice, isRefetching: orderIsRefetching || isRedirecting }, page.content))))));
};
export default PaymentForm;
