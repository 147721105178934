import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { BaggageType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { useTheme } from '../../../../../theme';
import { baggageIcon, mealIcon, carryonIcon, sportBaggageIcon } from '../../../Icons';
const SelectedService = ({ className, services }) => {
    const { SelectedService: css } = useTheme('Checkin');
    const { t } = useTranslation('Checkin');
    const service = services[0];
    let serviceIcon;
    let description;
    let baggageType;
    switch (service.type) {
        case OrderAdditionalServiceGdsServiceServiceType.Meal:
            serviceIcon = mealIcon;
            description = services.map(service => `${service.count} x ${service.name}`).join(', ');
            break;
        case OrderAdditionalServiceGdsServiceServiceType.Baggage:
            const baggageService = services[0];
            serviceIcon = baggageIcon;
            baggageType = baggageService.baggageType;
            if (baggageType === BaggageType.CarryOn) {
                serviceIcon = carryonIcon;
            }
            else if (baggageType === BaggageType.SportingEquipment) {
                serviceIcon = sportBaggageIcon;
            }
            if (services.length > 1) {
                description = services
                    .map(service => {
                    const baggageService = service;
                    return `${service.count} x ${baggageService.baggageWeight}, ${baggageService.name}`;
                })
                    .join(', ');
            }
            else {
                description = `${service.count > 1 ? `${service.count} x ` : ''}${baggageService.baggageWeight}`;
            }
            break;
    }
    return (React.createElement("div", { className: cn(css.service, className) },
        serviceIcon && React.createElement("div", { className: css.service__icon }, serviceIcon),
        React.createElement("div", { className: css.service__info },
            React.createElement("span", { className: css.service__name }, t(baggageType !== null && baggageType !== void 0 ? baggageType : service.type)),
            React.createElement("span", { className: css.service__description }, description))));
};
export default SelectedService;
