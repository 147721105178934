import * as React from 'react';
import { useContext, useMemo } from 'react';
import BaggageIcon from './BaggageIcon/BaggageIcon';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import { OverrideComponent } from '../../../renderProps';
import { BaggageContext } from '../../context';
const Baggage = ({ baggage, selected, realIconSize = false, onlyIcon, short, iconClassName }) => {
    var _a;
    const { Baggage: css } = useTheme('Baggage');
    const baggageIconProps = useMemo(() => ({
        baggage,
        className: cn(css.icon, iconClassName),
        realSize: realIconSize,
        onlyIcon
    }), [baggage, selected, onlyIcon, short, realIconSize]);
    const baggageIconClickHandler = (_a = useContext(BaggageContext)) === null || _a === void 0 ? void 0 : _a.onBaggageIconClick;
    return (React.createElement("div", { className: cn(css.root, {
            [css.selected]: selected
        }) },
        React.createElement("div", { className: baggageIconClickHandler ? css.iconWrapper_pointer : null, onClick: baggageIconClickHandler },
            React.createElement(OverrideComponent, { componentProps: baggageIconProps, component: { BaggageIcon } })),
        !short && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.name }, baggage.name),
            React.createElement("div", { className: css.size }, baggage.size)))));
};
export default Baggage;
