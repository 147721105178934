import { __rest } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../../../theme';
import { format } from '../../../../../../../utils';
import { getAbsoluteDate } from '../../../../../../../Engine/components/FastSearch/utils';
import Notification from '../../../../../../../Notification/components/Notification';
import { InfoMessageTypeEnum } from '@websky/graphql';
const Timelimit = (_a) => {
    var { timelimit, info } = _a, restProps = __rest(_a, ["timelimit", "info"]);
    const css = useTheme('Checkin').Passengers;
    const { t } = useTranslation('Checkin');
    const text = `${t('Online check-in will close at')} ${format(getAbsoluteDate(timelimit), 'HH:mm, dd MMMM')}${info ? ` ${info}` : ''}`;
    return (React.createElement(Notification, Object.assign({ className: css.timelimit, status: InfoMessageTypeEnum.Warning, type: "context", title: t('Attention'), infoMessage: {
            text,
            type: InfoMessageTypeEnum.Warning,
            code: 'timelimit'
        } }, restProps)));
};
export default Timelimit;
