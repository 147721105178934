import { __awaiter } from "tslib";
import i18next from 'i18next';
import { initI18n } from '../utils';
const DEFAULT_NAMESPACES = 'translation';
const I18N_SEPARATOR = ':';
export const addExternalResource = (i18next, externalResource) => __awaiter(void 0, void 0, void 0, function* () {
    const languages = i18next.languages;
    const errors = [];
    for (const lang in externalResource) {
        if (languages.indexOf(lang) >= 0) {
            const namespacesMap = new Map();
            for (const key in externalResource[lang]) {
                const splitKey = key.split(I18N_SEPARATOR), translate = externalResource[lang][key];
                if (splitKey.length === 1) {
                    addTranslationToMap(DEFAULT_NAMESPACES, splitKey[0], translate, namespacesMap);
                }
                else {
                    const [namespace, ...key] = splitKey;
                    const bundle = getExistsBundle(lang, namespace);
                    if (!bundle) {
                        errors.push(`Bundle with name '${namespace}' is not exists`);
                    }
                    else {
                        addTranslationToMap(namespace, key.join(I18N_SEPARATOR), translate, namespacesMap);
                    }
                }
            }
            namespacesMap.forEach((resources, ns) => {
                i18next.addResourceBundle(lang, ns, resources, true, true);
            });
        }
        else {
            errors.push(`Lang '${lang}' is not defined or initialized`);
        }
    }
    return errors;
});
const addTranslationToMap = (ns, key, translate, map) => {
    map.set(ns, Object.assign(Object.assign({}, (map.get(ns) || {})), { [key]: translate }));
};
const getExistsBundle = (lang, ns) => {
    try {
        if (!i18next.hasResourceBundle(lang, ns)) {
            initI18n(ns);
        }
        return i18next.getResourceBundle(lang, ns);
    }
    catch (e) {
        return undefined;
    }
};
