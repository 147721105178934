import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Page404 from '../../../../Page404/Page404';
import Initial from './Initial/Initial';
import OrderWrapper from './OrderWrapper';
import { createDataLayer } from '../../../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../../../DataLayerAnalytics/OrderDataLayer/types';
import { useCurrentUser } from '../../../../CurrentUser/CurrentUser.hooks';
const Checkin = () => {
    createDataLayer(OrderType.Checkin);
    useCurrentUser();
    const pnr = '[0-9БВГДКЛМНПРСТФХЦЖШA-Z]{6,6}';
    const ticketNumber = '[0-9A-ZА-Я]{13,13}';
    const lastName = '[\\wА-яA-z\\s-—]+';
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: "/", exact: true, component: Initial }),
        React.createElement(Route, { path: "/:id(\\d+)", render: ({ match }) => React.createElement(OrderWrapper, { orderId: match.params.id }) }),
        React.createElement(Route, { path: [
                `/search/:PNR(${pnr})?/:lastName(${lastName})?`,
                `/search/:PNR(${ticketNumber})?/:lastName(${lastName})?`,
                `/search/:PNR(${pnr})?/:lastName(${lastName})?/submit`,
                `/search/:PNR(${ticketNumber})?/:lastName(${lastName})?/submit`
            ], exact: true, component: Initial }),
        React.createElement(Page404, { onClick: () => (window.location.href = '/') })));
};
export default Checkin;
