export const getTravellerItemData = (traveller, promoCodesInfo, error) => {
    var _a, _b, _c, _d;
    const travellerId = Number.parseInt(traveller.id);
    const promoCodeInfo = (_a = promoCodesInfo.travellers[travellerId]) === null || _a === void 0 ? void 0 : _a.promoCodeInfo;
    const hasError = !!promoCodesInfo.travellers[travellerId] && (!promoCodeInfo || !!error);
    let origPriceAmount = 0;
    traveller.fares.forEach(fare => fare.passengerFares.forEach(segFare => (origPriceAmount += segFare.totalFare.amount)));
    const origPrice = {
        amount: origPriceAmount,
        currency: (_c = (_b = traveller.fares[0]) === null || _b === void 0 ? void 0 : _b.passengerFares[0]) === null || _c === void 0 ? void 0 : _c.totalFare.currency
    };
    const discountPrice = (_d = promoCodeInfo === null || promoCodeInfo === void 0 ? void 0 : promoCodeInfo.resultPrice) !== null && _d !== void 0 ? _d : origPrice;
    return {
        hasError,
        origPrice,
        discountPrice
    };
};
