import { __awaiter } from "tslib";
import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { LoginType, useAuthenticateMutation } from '@websky/graphql';
import { useConfig } from '../../context';
import { useCurrentUser } from '../../CurrentUser/CurrentUser.hooks';
const GoogleOneTapSignIn = memo(() => {
    const { Engine: { googleOneTapSignInClientId } } = useConfig();
    if (!googleOneTapSignInClientId) {
        return null;
    }
    const [authenticate] = useAuthenticateMutation();
    const { data: accountData, reinitialize, loading } = useCurrentUser(false);
    const [googleAccountId, setGoogleAccountId] = useState(null);
    const handleSignInResponse = (response) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const result = yield authenticate({
            variables: {
                params: {
                    loginType: LoginType.GoogleOneTap,
                    secureCode: response.credential
                }
            }
        });
        if ((_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.Authenticate) === null || _b === void 0 ? void 0 : _b.token) {
            reinitialize();
        }
    });
    const handleClientStateChange = (_, addedTags) => {
        var _a;
        const script = (_a = addedTags === null || addedTags === void 0 ? void 0 : addedTags.scriptTags) === null || _a === void 0 ? void 0 : _a[0];
        if (script) {
            script.onload = () => {
                var _a, _b;
                if ((_b = (_a = window.google) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.id) {
                    setGoogleAccountId(window.google.accounts.id);
                }
            };
        }
    };
    useEffect(() => {
        var _a;
        if (googleAccountId && !((_a = accountData === null || accountData === void 0 ? void 0 : accountData.CurrentUser) === null || _a === void 0 ? void 0 : _a.userProfile) && !loading) {
            googleAccountId.initialize({
                client_id: googleOneTapSignInClientId,
                callback: handleSignInResponse
            });
            googleAccountId.prompt();
        }
    }, [googleAccountId, loading, accountData]);
    return (React.createElement(Helmet, { async: true, script: [{ src: 'https://accounts.google.com/gsi/client' }], onChangeClientState: handleClientStateChange }));
});
export default GoogleOneTapSignIn;
