import * as React from 'react';
import cn from 'classnames';
import { UserLoyaltyCardLevels } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { CORPORATE_CARD } from '../../../utils';
const Card = ({ className, cardHolder, level, loyaltyName, number }) => {
    const { Card: css } = useTheme('LoyaltyInfo');
    let fullName = '';
    if ((cardHolder === null || cardHolder === void 0 ? void 0 : cardHolder.firstName) && (cardHolder === null || cardHolder === void 0 ? void 0 : cardHolder.lastName)) {
        fullName = `${cardHolder.firstName} ${cardHolder.lastName}`;
    }
    const isAuthorized = level && loyaltyName && number;
    const isCardCorporate = (number === null || number === void 0 ? void 0 : number.startsWith('70000')) || !!localStorage.getItem(CORPORATE_CARD);
    return (React.createElement("div", { className: cn(className, css.wrapper, {
            [css.authorized]: isAuthorized,
            [css.red]: level === UserLoyaltyCardLevels.red,
            [css.silver]: level === UserLoyaltyCardLevels.silver,
            [css.gold]: level === UserLoyaltyCardLevels.gold,
            [css.platinum]: level === UserLoyaltyCardLevels.platinum,
            [css.nickel]: level === UserLoyaltyCardLevels.nickel,
            [css.base]: level === UserLoyaltyCardLevels.base,
            [css.bronze]: level === UserLoyaltyCardLevels.bronze,
            [css.basic]: level === UserLoyaltyCardLevels.bronze,
            [css.compliment]: level === UserLoyaltyCardLevels.compliment,
            [css.appreciation]: level === UserLoyaltyCardLevels.appreciation,
            [css.gratitude]: level === UserLoyaltyCardLevels.gratitude,
            [css.corporate]: isCardCorporate
        }) }, isAuthorized && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.header },
            React.createElement("span", null, loyaltyName),
            React.createElement("span", { className: css.level }, level)),
        React.createElement("div", { className: css.info },
            React.createElement("span", null, fullName),
            React.createElement("span", { className: css.number }, number))))));
};
export default Card;
