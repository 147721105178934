import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ExchangeStep } from '../../../types';
import { passengerIcon } from '../icons';
import { Reasons as ReasonsType } from '../../../store/types';
import CommonSelect from '../CommonSelect/CommonSelect';
import { setReason } from '../../../store/reason/actions';
import { getSelectedReason } from '../../../store/reason/selectors';
import { getOrder, getPassengers } from '../../../store/order/selectors';
const Reasons = ({ setStep, passengersPageURL }) => {
    const { t } = useTranslation('Exchange');
    const dispatch = useDispatch();
    const history = useHistory();
    const order = useSelector(getOrder);
    const selectedReason = useSelector(getSelectedReason);
    const passengers = useSelector(getPassengers);
    const reasons = [
        { value: t('Voluntary change'), key: ReasonsType.Voluntary },
        { value: t('Involuntary change'), key: ReasonsType.Unvoluntary }
    ];
    const onChangeHandler = (selectedValue) => {
        dispatch(setReason(selectedValue.key));
    };
    const onNextHandler = () => {
        setStep(ExchangeStep.Segments);
    };
    // WEBSKY-2664: only Voluntary, skip this page
    React.useEffect(() => {
        dispatch(setReason(ReasonsType.Voluntary));
        onNextHandler();
    }, [reasons]);
    const onBackHandler = useCallback(() => {
        var _a;
        const isOnlyPassenger = passengers.length === 1;
        if (((_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange) && isOnlyPassenger) {
            setStep(ExchangeStep.Request);
        }
        else if (isOnlyPassenger) {
            history.push(passengersPageURL);
        }
        else {
            history.goBack();
        }
    }, [order, passengers, history, passengersPageURL]);
    return (React.createElement(CommonSelect, { name: t('Select type of change'), items: reasons, onChange: onChangeHandler, onNextHandler: onNextHandler, selectedKeys: [{ key: selectedReason }], onBackHandler: onBackHandler, icon: passengerIcon, controlType: "radio" }));
};
export default Reasons;
