export const scheduleFlightAdapter = (flight, date = null) => ({
    segments: flight.segments.map(({ segment, transferDuration }) => ({
        segment: {
            id: segment.id,
            flightNumber: segment.flightNumber,
            flightInfo: segment.flightInfo,
            operatingAirline: segment.operatingAirline,
            marketingAirline: segment.marketingAirline,
            duration: segment.duration,
            departure: Object.assign(Object.assign({}, segment.departure), { date }),
            arrival: Object.assign(Object.assign({}, segment.arrival), { date }),
            aircraft: segment.aircraft,
            stops: segment.stops,
            messages: []
        },
        transferDuration
    })),
    pricesForFareGroups: []
});
