import * as React from 'react';
import { useMemo } from 'react';
import MediaQuery from 'react-responsive';
import MobileFlight from '../MobileFlight';
import { isIncludePricesGroupM2Flight, TABLET_MIN_WIDTH } from '../utils';
import DesktopFlightRoute from '../DesktopFlightRoute/components/DesktopFlightRoute';
import { TransitTooltipContext } from '../TransitTooltip/utils';
const Flight = ({ flight, singleFlight, onSelect, selectedFares = [], preselectedFlightClass, hidePrice, readonly, isRecommended, isSelected, canceled, paid, orderId, searchParameters, hideOnlyPrice, onSubsidySuggestPopupOpen, showSeatsLeftForAllFares, hideConditionsButton, isReturnFlight, isSelectorInModal = true, onSetViewFlight }) => {
    const isTransitTariff = useMemo(() => isIncludePricesGroupM2Flight(flight.pricesForFareGroups), [
        flight.pricesForFareGroups
    ]);
    return (React.createElement(TransitTooltipContext.Provider, { value: { isTransitTariff } },
        React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
            React.createElement(DesktopFlightRoute, { orderId: orderId, selectedFares: selectedFares, flight: flight, isRecommended: isRecommended, singleFlight: singleFlight, onSelect: onSelect, isSelected: isSelected, canceled: canceled, hidePrice: hidePrice, readonly: readonly, searchParameters: searchParameters, hideOnlyPrice: hideOnlyPrice, onSubsidySuggestModalOpen: onSubsidySuggestPopupOpen, preselectedFlightClass: preselectedFlightClass, showSeatsLeftForAllFares: showSeatsLeftForAllFares, hideConditionsButton: hideConditionsButton, isReturnFlight: isReturnFlight, isSelectorInModal: isSelectorInModal, onSetViewFlight: onSetViewFlight })),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement(MobileFlight, { showAirplane: singleFlight, isRecommended: isRecommended, isSelected: isSelected, isPreOpen: false, flight: flight, onSelect: onSelect, selectedFares: selectedFares, hidePrice: hidePrice, readonly: readonly, searchParameters: searchParameters, hideOnlyPrice: hideOnlyPrice, canceled: canceled, orderId: orderId, onSubsidySuggestPopupOpen: onSubsidySuggestPopupOpen, preselectedFlightClass: preselectedFlightClass, onSetViewFlight: onSetViewFlight, isReturnFlight: isReturnFlight }))));
};
export default Flight;
