import * as React from 'react';
import { usePaymentMethodsQuery } from '@websky/graphql';
import PaymentComponent from '../../../Modules/Exchange/components/Exchange/Payment/Payment';
import SimpleLoader from '../../../SimpleLoader';
const Payment = ({ className, lastChangePaxOrder, onPaymentSuccess, onPaymentFailed, onGoToRequest }) => {
    const baseUrl = window.location.href;
    const { data, loading } = usePaymentMethodsQuery({
        fetchPolicy: 'no-cache',
        variables: {
            withKaspi: true,
            withItransfer: true,
            withGpay: true,
            withCredit: true,
            withCard: true,
            withQiwi: true,
            withFps: true,
            withSbol: true,
            parameters: {
                orderId: lastChangePaxOrder.id,
                backRedirectUrl: `${baseUrl}/success`,
                backRedirectUrlOnFail: `${baseUrl}/failed`
            }
        }
    });
    const onGoToRequestHandler = () => {
        onGoToRequest(true);
    };
    if (loading) {
        return React.createElement(SimpleLoader, null);
    }
    return (React.createElement(PaymentComponent, { className: className, exchangeOrder: Object.assign(Object.assign({}, lastChangePaxOrder), { isCompelled: lastChangePaxOrder.isCompelled }), paymentMethods: data === null || data === void 0 ? void 0 : data.PaymentMethods, successPayment: onPaymentSuccess, failedPayment: onPaymentFailed, goToOrder: onGoToRequestHandler }));
};
export default Payment;
