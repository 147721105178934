import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import CheckoutAdditionalService from '../..';
import { useTheme } from '../../../theme';
import Modal from '../../../Modal';
import { getBusinessLounge, getOrder, getSegments, getTravellers } from '../../../Checkout/store/order/selectors';
import { getTravellersMap, useIsMobile } from '../../../utils';
import { useOrderService } from '../hooks';
import { getCalculatedVipServicesPrice } from '../../../Checkout/store/orderPrice/selectors';
import { useRenderers } from '../../../renderProps';
import ServiceError from '../ServiceError/ServiceError';
import { useIsCanModifyServices } from '../../hooks';
import VipServiceSelect from '../../../VipServiceSelect';
import { closeRender } from '../Close/Close';
import VipSummary from '../../../ServiceSummary/services/VipSummary';
const Business = ({ services }) => {
    var _a, _b, _c;
    const { Business: theme } = useTheme('CheckoutAdditionalService');
    const { t } = useTranslation('Checkout');
    const order = useSelector(getOrder);
    const travellers = useSelector(getTravellers);
    const segments = useSelector(getSegments);
    const businessServices = useSelector(getBusinessLounge);
    const businessLoungeServices = useMemo(() => {
        if (services === null || services === void 0 ? void 0 : services.length) {
            return services;
        }
        return businessServices;
    }, [services, businessServices]);
    const price = useSelector(getCalculatedVipServicesPrice(businessLoungeServices));
    const { selectedServices, unconfirmedServices, renderPassenger, renderSubheader, onOpen, onClose, setOpened, onSaveHandler, onRemoveHandler, onRemoveCartService, passengers, opened } = useOrderService(businessLoungeServices, OrderAdditionalServiceGdsServiceServiceType.BusinessLounge);
    if (!businessLoungeServices.length) {
        return null;
    }
    const isMobile = useIsMobile();
    const isCanModifyServices = useIsCanModifyServices(businessLoungeServices);
    const title = useMemo(() => {
        if ((services === null || services === void 0 ? void 0 : services.length) === 1) {
            return services[0].name;
        }
        return t('Business lounge');
    }, [services]);
    const additionalServices = useMemo(() => {
        var _a, _b, _c, _d;
        if (!(services === null || services === void 0 ? void 0 : services.length)) {
            return order.additionalServices;
        }
        return Object.assign(Object.assign({}, order.additionalServices), { gdsServices: Object.assign(Object.assign({}, (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices), { services: (_d = (_c = (_b = order.additionalServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.filter(gdsService => services.some(service => service.id === gdsService.id))) !== null && _d !== void 0 ? _d : [] }) });
    }, [services, order.additionalServices]);
    const buttons = useMemo(() => {
        if (unconfirmedServices.length) {
            return [
                {
                    className: theme.button__remove,
                    action: () => onRemoveHandler(unconfirmedServices),
                    text: t('Remove'),
                    buttonType: 'change',
                    variant: 'optionEdit'
                }
            ];
        }
        return [
            {
                action: () => setOpened(true),
                text: isCanModifyServices ? t('Change') : t('More details'),
                buttonType: 'change'
            }
        ];
    }, [unconfirmedServices]);
    const { CheckoutAdditionalService: CustomCheckoutAdditionalService } = useRenderers();
    const ServiceWrapper = (_a = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _a !== void 0 ? _a : CheckoutAdditionalService;
    return (React.createElement(React.Fragment, null,
        React.createElement(VipSummary, { flight: order.flight, travellers: travellers, services: additionalServices, travellersById: getTravellersMap(order.travellers), onServiceDelete: onRemoveCartService, serviceName: title, serviceSubType: (_c = (_b = services === null || services === void 0 ? void 0 : services[0]) === null || _b === void 0 ? void 0 : _b.subType) !== null && _c !== void 0 ? _c : GdsServiceVipSubType.BusinessLounge }, content => {
            const isSelected = !!content;
            return (React.createElement(ServiceWrapper, { header: title, description: businessLoungeServices[0].description, className: theme.business, priceFrom: isSelected ? price : null, priceTotal: price, isSelected: isSelected, addClick: onOpen, descriptionClassName: theme.descriptions, buttons: buttons, addButtonText: !isCanModifyServices ? t('More details') : null },
                React.createElement(ServiceError, { isShow: !!unconfirmedServices.length }, content)));
        }),
        React.createElement(Modal, { open: opened, classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, slideUp: isMobile, maxWidth: "sm", closeButtonRenderer: closeRender, onClose: onClose },
            React.createElement(VipServiceSelect, { header: title, headerClassName: theme.header, className: theme.serviceSelect, subheader: renderSubheader(), passengers: passengers, services: businessLoungeServices, segments: segments, selectedServices: selectedServices, type: "checkbox", onSave: onSaveHandler, onClose: onClose, renderPassenger: renderPassenger, readOnly: !isCanModifyServices, showTotalPrice: true, showFromPrice: true }))));
};
export default Business;
