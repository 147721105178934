import { BaggageType } from '@websky/graphql';
export function getServiceKey(service) {
    const segmentIds = service.segmentIds.join('-');
    return [service.serviceId, service.passengerId, segmentIds].join('/');
}
export function getSelectedServicesByType(state, action) {
    const { items, type } = action.payload;
    const selectedServicesMap = new Map();
    state.selectedServices.forEach(service => {
        const key = getServiceKey(service);
        selectedServicesMap.set(key, service);
    });
    if (type === 'radio') {
        // reset the selected services on the segment for the passenger, as only one service can be selected
        selectedServicesMap.forEach((selectedService, key) => {
            var _a;
            const baggageType = (_a = state.services.find(service => service.id === selectedService.serviceId)) === null || _a === void 0 ? void 0 : _a.type;
            if (baggageType === BaggageType.BaggageExcess) {
                items.forEach(newService => {
                    if (newService.passengerId === selectedService.passengerId &&
                        newService.segmentIds.join('/') === selectedService.segmentIds.join('/')) {
                        selectedServicesMap.set(key, Object.assign(Object.assign({}, selectedService), { count: 0 }));
                    }
                });
            }
        });
    }
    items.forEach(newService => {
        const key = getServiceKey(newService);
        selectedServicesMap.set(key, newService);
    });
    return [...selectedServicesMap.values()];
}
