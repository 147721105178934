import * as React from 'react';
import { ArrowForward } from '../../../Icons';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import FullscreenDialogClose from '../Close/Close';
import { useRenderers } from '../../../renderProps';
const FullScreenDialogHeader = ({ onClose, closeClassName, headerClassName, title, renderBeforeCloseButton, hideTitle }) => {
    const { FullscreenDialog: theme } = useTheme('FullscreenDialog');
    const { renderFullScreenDialogClose } = useRenderers();
    const renderCloseButton = React.useMemo(() => {
        if (renderFullScreenDialogClose) {
            return renderFullScreenDialogClose({
                onClose,
                closeClassName
            });
        }
        else {
            return React.createElement(FullscreenDialogClose, { onClose: onClose, closeClassName: closeClassName });
        }
    }, [onClose, closeClassName, renderFullScreenDialogClose]);
    return (React.createElement("div", { className: cn(theme.header, headerClassName) },
        React.createElement("div", { className: theme.header__wrapper },
            !hideTitle && (React.createElement("div", { className: theme.header__title },
                React.createElement("div", { className: theme.back, onClick: onClose }, ArrowForward),
                title)), renderBeforeCloseButton === null || renderBeforeCloseButton === void 0 ? void 0 :
            renderBeforeCloseButton(),
            renderCloseButton)));
};
export default FullScreenDialogHeader;
