import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
const crossIcon = (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M12.6668 4.2735L11.7268 3.3335L8.00016 7.06016L4.2735 3.3335L3.3335 4.2735L7.06016 8.00016L3.3335 11.7268L4.2735 12.6668L8.00016 8.94016L11.7268 12.6668L12.6668 11.7268L8.94016 8.00016L12.6668 4.2735Z", fill: "currentColor" })));
const CloseIcon = ({ isGray, onClick, className }) => {
    const { CloseIcon: css } = useTheme('CloseIcon');
    return (React.createElement("div", { className: cn(css.wrapper, className, {
            [css.wrapper_gray]: isGray
        }), onClick: onClick }, crossIcon));
};
export default CloseIcon;
