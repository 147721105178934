import * as React from 'react';
import { NASStatus } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import CheckoutAdditionalService from '../../../index';
import Button from '../../../../Button/Button';
const CommonCard = ({ header, serviceType, description, coverClassName, icons, children, priceFrom, totalPrice, isSelected, openDetails, onOpen, selectedServicesText, selectedServicesPassengerNames, selectedServicesDetailsLink, isModifyAvailable, hideButtons, renderHeaderPrice }) => {
    const { t } = useTranslation('Checkout');
    const { t: aasT } = useTranslation('ServiceAggregation');
    const everyPortTheme = useTheme('CheckoutAdditionalService').EveryPortLounge;
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckoutAdditionalService, { mode: "aggregator", header: header, description: description, coverClassName: coverClassName, isSelected: isSelected, addClick: onOpen, priceTotal: totalPrice, priceFrom: priceFrom, aggregationIcons: icons, renderHeaderPrice: renderHeaderPrice, buttons: !hideButtons
                ? [
                    {
                        text: t(isModifyAvailable ? 'Change' : 'Details'),
                        action: onOpen
                    }
                ]
                : [], discount: aasT(`${serviceType}_discount`, {
                defaultValue: ''
            }) },
            React.createElement("div", null, selectedServicesText === null || selectedServicesText === void 0 ? void 0 : selectedServicesText.map(text => (React.createElement("span", { key: text.name },
                text.name,
                text.terminal ? `, ${aasT('Terminal')} ${text.terminal}` : '')))),
            (selectedServicesPassengerNames === null || selectedServicesPassengerNames === void 0 ? void 0 : selectedServicesPassengerNames.length) > 0 && (React.createElement("ul", { className: everyPortTheme.services }, selectedServicesPassengerNames.map((passenger, key) => {
                return (React.createElement("li", { key: key },
                    passenger.name.toLowerCase(),
                    ' ',
                    passenger.status === NASStatus.Refunded && (React.createElement("span", { className: everyPortTheme.refunded },
                        " \u2013 ",
                        aasT('refunded')))));
            }))),
            React.createElement("div", { className: everyPortTheme.details },
                children,
                selectedServicesDetailsLink && (React.createElement("div", null,
                    React.createElement(Button, { variant: "text", className: everyPortTheme.details__button, onClick: openDetails }, t('Details'))))))));
};
export default CommonCard;
