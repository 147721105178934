import * as React from 'react';
import cn from 'classnames';
import Dropdown from '../../Dropdown/Dropdown';
import { useTheme } from '../../theme';
const Lang = props => {
    const selectedLang = props.items.find(item => item.code === props.selectedCode);
    const [isListOpen, setListOpen] = React.useState(false);
    const css = useTheme('Lang').LangStyles;
    const onChange = (code) => {
        props.onChange(code);
        setListOpen(false);
    };
    return (React.createElement("div", { className: cn(css.lang, { [css.lang_opened]: isListOpen }) },
        React.createElement("div", { className: css.lang__selected, onClick: () => setListOpen(!isListOpen) }, props.selectedLangRenderer ? props.selectedLangRenderer(selectedLang) : selectedLang.icon),
        React.createElement(Dropdown, { isOpen: isListOpen, onClose: () => setListOpen(false) },
            React.createElement("div", { className: cn(css.dropdown, props.dropdownClassName) },
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: cn(css.dropdown__item, css.selected), onClick: () => setListOpen(false) },
                        React.createElement("div", { className: css.dropdown__icon }, selectedLang.icon),
                        React.createElement("div", null, selectedLang.name)),
                    props.items.map(item => {
                        return item.code !== props.selectedCode ? (React.createElement("div", { className: cn(css.dropdown__item), key: item.code, onClick: () => onChange(item.code) },
                            React.createElement("div", { className: css.dropdown__icon }, item.icon),
                            React.createElement("div", null, item.name))) : null;
                    }))))));
};
export default Lang;
