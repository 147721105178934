import { __awaiter } from "tslib";
import * as React from 'react';
import { TravellerFieldEnum, useAddTravellersDataMutation } from '@websky/graphql';
import { useDispatch } from 'react-redux';
import { fillOrder } from '../Checkout/store/order/actions';
export const useChangeLink = (orderId) => {
    const [addTravellersDataMutation, { loading }] = useAddTravellersDataMutation();
    const dispatch = useDispatch();
    const [relinkingPassenger, setRelinkingPassenger] = React.useState(null);
    const [relinkingToPassengerId, setRelinkingToPassengerId] = React.useState(null);
    const [error, setError] = React.useState(null);
    const onSetRelinkingMode = (traveller) => {
        setRelinkingPassenger(traveller);
        setRelinkingToPassengerId(traveller.linkedTraveller.id);
    };
    const onLinkTo = (passengerId) => {
        setRelinkingToPassengerId(passengerId);
    };
    const confirmRelinking = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            const { data } = yield addTravellersDataMutation({
                variables: {
                    variables: {
                        orderId,
                        travellersData: [
                            {
                                id: relinkingPassenger.id,
                                values: [
                                    {
                                        type: TravellerFieldEnum.LinkedTraveller,
                                        value: relinkingToPassengerId
                                    }
                                ]
                            }
                        ]
                    }
                }
            });
            if (data.SetTravellersData) {
                dispatch(fillOrder(data.SetTravellersData));
                setRelinkingPassenger(null);
                setRelinkingToPassengerId(null);
            }
        }
        catch (e) {
            setError(((_b = (_a = e === null || e === void 0 ? void 0 : e.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message) || 'Error during relinking');
        }
    });
    const clearError = () => setError(null);
    return {
        isRelinkingMode: !!relinkingPassenger,
        relinkingPassenger,
        relinkingToPassengerId,
        isLoading: loading,
        error,
        clearError,
        onSetRelinkingMode,
        onLinkTo,
        confirmRelinking
    };
};
