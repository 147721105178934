import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import Button from '../../../../Button/Button';
const FlightNotAvailable = ({ onClick }) => {
    const { FlightNotAvailable: css } = useTheme('Checkout');
    const { t } = useTranslation('Checkout');
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.header },
            React.createElement("span", null,
                t('Sorry, flight is either not available or sold out'),
                " :(")),
        React.createElement("div", { className: css.desc }, t('Please, choose another flight.')),
        React.createElement("div", { className: css.buttons },
            React.createElement(Button, { onClick: onClick }, t('Choose another flight')))));
};
export default FlightNotAvailable;
