import * as React from 'react';
import { getNewFlightsForManualMode } from './hooks';
const SearchResultsAdapter = props => {
    const data = getNewFlightsForManualMode(props.exchangeParams, props.setSearchParams, props.clearFares);
    React.useEffect(() => {
        props.setLoading(data.loading);
    }, [data.loading]);
    const onSelect = (fare) => {
        var _a, _b;
        const exarePrice = {
            fare,
            exarePrice: (_b = (_a = data.results.GetExchangeVariants) === null || _a === void 0 ? void 0 : _a.exchangeVariantPriceInfo) === null || _b === void 0 ? void 0 : _b.find(price => price.flightId === fare.flightInfo.id)
        };
        props.onSelect(exarePrice, data.currentLeg, data.results.GetExchangeVariants.flightDirections.length);
        if (data.currentLeg < data.results.GetExchangeVariants.flightDirections.length - 1) {
            data.setLeg(data.currentLeg + 1);
        }
    };
    return (React.createElement(React.Fragment, null, props.children(Object.assign({}, data), onSelect)));
};
export default SearchResultsAdapter;
