import { __decorate } from "tslib";
import * as React from 'react';
import { withTheme } from '../theme';
import { Outbound } from './Outbound/Outbound';
import { addDays, toDate } from 'date-fns';
import { Matrix } from './Matrix/Matrix';
import { createDatesArray } from '../utils';
export var HeaderType;
(function (HeaderType) {
    HeaderType[HeaderType["Outbound"] = 0] = "Outbound";
    HeaderType[HeaderType["Return"] = 1] = "Return";
})(HeaderType || (HeaderType = {}));
const memoize = require('memoize-one');
let PriceMatrix = class PriceMatrix extends React.Component {
    constructor(props) {
        super(props);
        this.minOutboundDate = Infinity;
        this.maxOutboundDate = 0;
        this.minReturnDate = Infinity;
        this.maxReturnDate = 0;
        this.minPrice = {
            amount: Infinity,
            currency: null
        };
        this.maxPrice = {
            amount: 0,
            currency: null
        };
        this.memoizedFill = typeof memoize === 'function' ? memoize(this.fill) : memoize.default(this.fill);
        this.state = {
            [HeaderType.Outbound]: null,
            [HeaderType.Return]: null
        };
        this.getDatesRange = this.getDatesRange.bind(this);
        this.arrowHandler = this.arrowHandler.bind(this);
    }
    getDatesRange(type) {
        const minDateFromState = this.state[type], minDateFromClass = type === HeaderType.Outbound ? this.minOutboundDate : this.minReturnDate, minDate = minDateFromState ? minDateFromState : toDate(minDateFromClass);
        return {
            minDate: minDate,
            maxDate: addDays(minDate, this.props.size - 1)
        };
    }
    getDatesMatrix() {
        const outboundDatesRange = this.getDatesRange(HeaderType.Outbound), returnDatesRange = this.getDatesRange(HeaderType.Return), outboundDatesArray = createDatesArray(outboundDatesRange.minDate, outboundDatesRange.maxDate), returnDatesArray = createDatesArray(returnDatesRange.minDate, returnDatesRange.maxDate);
        return outboundDatesArray.map(date => {
            return {
                outboundDate: date,
                returnDates: returnDatesArray
            };
        });
    }
    checkDatesRange(type, startDate) {
        const minDate = type === HeaderType.Outbound ? this.minOutboundDate : this.minReturnDate, maxDate = type === HeaderType.Outbound ? this.maxOutboundDate : this.maxReturnDate, startDateTimestamp = startDate.getTime(), endDateTimestamp = addDays(startDate, this.props.size - 1).getTime();
        return startDateTimestamp >= minDate && endDateTimestamp <= maxDate;
    }
    arrowHandler(type, direction) {
        const datesRange = this.getDatesRange(type), newStartDate = addDays(datesRange.minDate, direction === 'next' ? 1 : -1);
        this.checkDatesRange(type, newStartDate) &&
            this.setState(() => {
                if (this.props.onRangeChanges) {
                    this.props.onRangeChanges(type === HeaderType.Outbound ? 'vertical' : 'horizontal', newStartDate, addDays(newStartDate, this.props.size - 1));
                }
                return {
                    [type]: newStartDate
                };
            });
    }
    fill(prices) {
        const pricesMap = {};
        prices.map(price => {
            const returnTimestamp = price.dateBack.getTime(), outboundTimestamp = price.dateTo.getTime();
            if (returnTimestamp > this.maxReturnDate) {
                this.maxReturnDate = returnTimestamp;
            }
            if (returnTimestamp < this.minReturnDate) {
                this.minReturnDate = returnTimestamp;
            }
            if (outboundTimestamp > this.maxOutboundDate) {
                this.maxOutboundDate = outboundTimestamp;
            }
            if (outboundTimestamp < this.minOutboundDate) {
                this.minOutboundDate = outboundTimestamp;
            }
            if (price.price && price.price.amount < this.minPrice.amount) {
                this.minPrice.amount = price.price.amount;
            }
            if (price.price && price.price.amount > this.maxPrice.amount) {
                this.maxPrice.amount = price.price.amount;
            }
            pricesMap[`${price.dateTo.getTime()}-${price.dateBack.getTime()}`] = {
                money: price.price,
                isLoading: price.isLoading
            };
        });
        return pricesMap;
    }
    render() {
        const pricesMap = this.memoizedFill(this.props.prices), styles = this.props.theme.PriceMatrixStyles;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Matrix, { dates: this.getDatesMatrix(), prices: pricesMap, minPrice: this.minPrice, maxPrice: this.maxPrice, onDateSelect: this.props.onDateSelect, verticalHeader: ref => (React.createElement(Outbound, Object.assign({}, this.getDatesRange(HeaderType.Outbound), { type: HeaderType.Outbound, onArrowClick: this.arrowHandler, innerRef: ref }))), horizontalHeader: ref => (React.createElement(Outbound, Object.assign({}, this.getDatesRange(HeaderType.Return), { type: HeaderType.Return, onArrowClick: this.arrowHandler, innerRef: ref }))) })));
    }
};
PriceMatrix.displayName = 'PriceMatrix';
PriceMatrix = __decorate([
    withTheme
], PriceMatrix);
export { PriceMatrix };
export default PriceMatrix;
