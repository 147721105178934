import * as React from 'react';
const ManualInput = ({ children, inputClassName, onEnter, showInput }) => {
    const [isInputActive, setInputActive] = React.useState(false);
    const onInputChange = (e) => {
        setInputActive(!!e.target.value);
    };
    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            onEnter(e.target.value);
        }
    };
    React.useEffect(() => {
        if (!showInput) {
            setInputActive(false);
        }
    }, [showInput]);
    return (React.createElement(React.Fragment, null,
        showInput && (React.createElement("input", { type: "text", autoFocus: true, className: inputClassName, onChange: onInputChange, onKeyDown: onKeyDown })),
        !isInputActive && children));
};
export default ManualInput;
