import * as React from 'react';
import { YMInitializer } from 'react-yandex-metrika';
import { memo, useEffect, useState } from 'react';
import { DATA_LAYER_KEY } from '../../analytics';
import { getLocale } from '../../utils';
import { useConfig } from '../../context';
const isProd = process.env.NODE_ENV === 'production';
const Analytics = memo(() => {
    if (!isProd) {
        return null;
    }
    const { global: { yandexMetrikaId } } = useConfig();
    // default websky metrika account
    const metrikaAccounts = [94079362];
    if (yandexMetrikaId) {
        if (Array.isArray(yandexMetrikaId)) {
            metrikaAccounts.push(...yandexMetrikaId.map(it => parseInt(it, 10)));
        }
        else {
            metrikaAccounts.push(parseInt(yandexMetrikaId, 10));
        }
    }
    const [accountsToInitialize, setAccountsToInitialize] = useState([]);
    useEffect(() => {
        setTimeout(() => {
            // setTimout because when this component uses in multiple places we need time gap between yandex metrica initializations
            const alreadyInitializedAccounts = window.yandex_metrika_accounts || [];
            setAccountsToInitialize(metrikaAccounts.filter(account => !alreadyInitializedAccounts.includes(account)));
        }, 0);
    }, []);
    return (React.createElement(React.Fragment, null, !!accountsToInitialize.length && (React.createElement(YMInitializer, { version: "2", accounts: accountsToInitialize, options: {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            trackHash: true,
            ecommerce: DATA_LAYER_KEY,
            params: {
                locale: getLocale()
            }
        } }))));
});
export default Analytics;
