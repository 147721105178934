import { __awaiter, __rest } from "tslib";
import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from './context';
import { Log } from './sentry';
import { getOffsetTop, useIsDesktop, useIsMobile } from './utils';
export const useToggleable = initialState => {
    const [isOpen, setOpen] = useState(initialState);
    const open = useCallback(() => {
        setOpen(true);
    }, [isOpen]);
    const close = useCallback(() => {
        setOpen(false);
    }, [isOpen]);
    const toggle = useCallback(() => {
        setOpen(!isOpen);
    }, [isOpen]);
    return {
        isOpen,
        open,
        close,
        toggle,
        setOpen
    };
};
export function useSelectable(initial) {
    const [active, setActive] = useState(initial);
    const onSelect = useCallback((newActive) => {
        setActive(newActive);
        return newActive;
    }, [active]);
    return {
        active,
        onSelect
    };
}
export const useIataCode = () => {
    var _a, _b, _c;
    try {
        const { global: { companyInfo: { iataCode } } } = useConfig();
        return iataCode;
    }
    catch (_d) {
        return (_c = (_b = (_a = window.__webskyEngineConfig) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.iataCode;
    }
};
export const useConfigRedirects = () => {
    const { Engine: { accountURL } } = useConfig();
    const onGoToAccountSettings = () => {
        window.open(`${accountURL}/settings`);
    };
    return { onGoToAccountSettings };
};
export const useHasLoyaltyProgram = () => {
    var _a, _b, _c;
    try {
        const { global: { companyInfo: { loyaltyProgramRegistrationUrl } } } = useConfig();
        return !!loyaltyProgramRegistrationUrl;
    }
    catch (_d) {
        return !!((_c = (_b = (_a = window.__webskyEngineConfig) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.loyaltyProgramRegistrationUrl);
    }
};
export const useSentryTranslation = (ns, options) => {
    const _a = useTranslation(ns, options), { t: i18nT, i18n } = _a, translation = __rest(_a, ["t", "i18n"]);
    const t = (key) => {
        if (!i18n.exists(key, { ns })) {
            Log({ level: 'warning', message: `The translation key '${ns}:${key}' is missing` });
            return null;
        }
        return i18nT(key);
    };
    return Object.assign(Object.assign({}, translation), { t, i18n });
};
export const useConditionalPolling = (fetchFn, condition, options) => {
    const { interval = 3000, maxQueryCount = 3, progressiveIntervalPerQuery } = options;
    const timeoutId = useRef();
    const queryCount = useRef(1);
    const onFetchHandler = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield fetchFn();
        }
        catch (e) {
            console.log(e);
        }
    }), [fetchFn]);
    useEffect(() => {
        const polling = () => __awaiter(void 0, void 0, void 0, function* () {
            yield onFetchHandler();
            if (!condition || queryCount.current >= maxQueryCount) {
                clearTimeout(timeoutId.current);
                timeoutId.current = undefined;
                queryCount.current = 1;
                return;
            }
            const timeout = progressiveIntervalPerQuery ? interval * queryCount.current : interval;
            timeoutId.current = setTimeout(polling, timeout);
            queryCount.current++;
        });
        if (condition) {
            polling();
        }
        return () => {
            setTimeout(() => {
                if (timeoutId.current) {
                    clearTimeout(timeoutId.current);
                    timeoutId.current = undefined;
                    queryCount.current = 1;
                }
            });
        };
    }, [onFetchHandler, condition, interval, queryCount, maxQueryCount, progressiveIntervalPerQuery]);
};
export const useOffsetTop = (elem) => {
    const isDesktop = useIsDesktop();
    const isMobile = useIsMobile();
    const offset = useMemo(() => getOffsetTop(elem.current), [elem.current, isDesktop, isMobile]);
    return offset;
};
export const useCustomCssVariable = (variable, value) => {
    const variableWithPrefix = `--wx-custom-variable-${variable}`;
    useEffect(() => {
        document.documentElement.style.setProperty(variableWithPrefix, value);
        return () => {
            document.documentElement.style.removeProperty(variableWithPrefix);
        };
    }, [variable, value]);
};
export const useDebounce = (value, timeout) => {
    const [state, setState] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => setState(value), timeout);
        return () => clearTimeout(handler);
    }, [value, timeout]);
    return state;
};
