import { OrderStatus } from '@websky/graphql';
import OrderDataLayer from './OrderDataLayer';
import { OrderStep } from '../types';
import { OrderType } from './types';
class CheckoutDataLayer extends OrderDataLayer {
    constructor(instance) {
        super(instance);
    }
    getOrderType() {
        return OrderType.Checkout;
    }
    getFlightSegments() {
        return this.getOrder().flight.segments.map(segment => segment.segment);
    }
    getOrderStatus() {
        return this.getOrder().status === OrderStatus.New
            ? OrderStep.Booking
            : this.getOrder().status === OrderStatus.Confirmed
                ? OrderStep.Paid
                : OrderStep.Checkout;
    }
}
export default CheckoutDataLayer;
