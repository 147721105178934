import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import i18n from 'i18next';
import { subMonths, parseISO, isBefore, isAfter } from 'date-fns';
import SearchFormDatepicker from '../../../../../../SearchFormDatepicker';
import Tooltip from '../../../../../../Tooltip/Tooltip';
import { useConfig } from '../../../../../../context';
import { useTheme } from '../../../../../../theme';
import { getMinPricesRequestVariables, getPrices } from '../../../../../utils';
import { safeStartOfMonth } from '../../../../../../PriceGraph/utils';
import { getDateWithoutTimezone, getDateWithTimezone } from '../../../../../../Engine/components/FastSearch/utils';
import Value from './Value/Value';
import { AviaPassengerType, useFlightsMinPricesInPeriodQuery, useGetAvailableDatesQuery } from '@websky/graphql';
import { getMinMaxPrice, isSelectedOnlyDepartureDate } from './utils';
import { useSelector } from 'react-redux';
import { CURRENCY_KEY } from '../../../../../../cache';
import { isMultiCity as isMultiCitySelector } from '../../../../../store/segments/selectors';
import { OverrideComponent, useRenderers } from '../../../../../../renderProps';
export var DatepickerTab;
(function (DatepickerTab) {
    DatepickerTab["Outbound"] = "outbound";
    DatepickerTab["Return"] = "return";
})(DatepickerTab || (DatepickerTab = {}));
const startDate = new Date();
const validate = (value, allValues, meta) => {
    if (!meta.data.departure) {
        return i18n.t('SearchForm:Please select departure date');
    }
};
let lastVisibleError = '';
const Datepicker = ({ valueClassName, selectedDates, onDateChange, dateTo, dateBack = null, withDateBack = false, clearDates, segmentId, locations, passengers, showPrices, renderValue, disablePrices }) => {
    var _a;
    const { Datepicker: css } = useTheme('SearchForm');
    const hasSelectedDates = !!selectedDates.length;
    const [routeType, setRouteType] = useState(dateTo ? DatepickerTab.Return : DatepickerTab.Outbound);
    const [openDate, setOpenDate] = useState(new Date());
    const [hoverDates, setHoverDates] = useState([null, null]);
    const [pricesLoading, setPricesLoading] = useState(false);
    const [isRouteTypeSetManually, setIsRouteTypeSetManually] = useState(false);
    const [previousManuallyRoute, setPreviousManuallyRoute] = useState(null);
    const [isThisFirstSelectableDate, setIsThisFirstSelectableDate] = useState(false);
    const [minPricesTo, setMinPricesTo] = useState(null);
    const [minPricesBack, setMinPricesBack] = useState(null);
    const isMultiCity = useSelector(isMultiCitySelector);
    const OverrideSearchFormDatepicker = (_a = useRenderers().SearchFormDatePicker) !== null && _a !== void 0 ? _a : SearchFormDatepicker;
    const { SearchForm: { showPriceGraph } } = useConfig();
    const onHover = useCallback((hoverDate) => {
        if (!selectedDates[0] && !selectedDates[1]) {
            setHoverDates([hoverDate, null]);
        }
        if (routeType === DatepickerTab.Outbound) {
            if (isBefore(hoverDate, selectedDates[1])) {
                setHoverDates([hoverDate, selectedDates[1]]);
            }
            else {
                setHoverDates([null, null]);
            }
        }
        if (routeType === DatepickerTab.Return) {
            if (isAfter(hoverDate, selectedDates[0])) {
                setHoverDates([selectedDates[0], hoverDate]);
            }
            else {
                setHoverDates([null, null]);
            }
        }
    }, [selectedDates, isRouteTypeSetManually, routeType, isThisFirstSelectableDate, previousManuallyRoute]);
    const { data: scheduleTo } = useGetAvailableDatesQuery({
        skip: !locations.departure || !locations.arrival || showPrices || showPriceGraph,
        variables: {
            departure: locations.departure ? locations.departure.iata : '',
            arrival: locations.arrival ? locations.arrival.iata : ''
        }
    });
    const { data: scheduleBack } = useGetAvailableDatesQuery({
        skip: !locations.departure || !locations.arrival || showPrices || showPriceGraph || routeType === 'outbound',
        variables: {
            departure: locations.arrival ? locations.arrival.iata : '',
            arrival: locations.departure ? locations.departure.iata : ''
        }
    });
    const alternativeHighlighting = routeType === 'outbound'
        ? scheduleTo && scheduleTo.FlightsSchedule && scheduleTo.FlightsSchedule.flightProbability > 0.5
        : scheduleBack && scheduleBack.FlightsSchedule && scheduleBack.FlightsSchedule.flightProbability > 0.5;
    const { refetch: refetchMinPrices } = useFlightsMinPricesInPeriodQuery({ skip: true });
    let highlightingClassName;
    if (showPrices) {
        highlightingClassName = css.highlightedDate_withPrice;
    }
    else {
        highlightingClassName = alternativeHighlighting ? css.highlightedDate_lite : css.highlightedDate;
    }
    const getAvailableDatesFromSchedule = () => {
        if (routeType === 'outbound' && scheduleTo && scheduleTo.FlightsSchedule) {
            return scheduleTo.FlightsSchedule.datesData.map(scheduleItem => getDateWithoutTimezone(new Date(scheduleItem.date)));
        }
        else if (routeType === 'return' && scheduleBack && scheduleBack.FlightsSchedule) {
            return scheduleBack.FlightsSchedule.datesData.map(scheduleItem => getDateWithoutTimezone(new Date(scheduleItem.date)));
        }
        else {
            return [];
        }
    };
    const getAvailableDates = () => {
        const prices = routeType === 'outbound' ? minPricesTo : minPricesBack;
        const availableDates = [];
        if (showPrices) {
            if (!prices) {
                return [];
            }
            for (const [key, value] of Object.entries(prices)) {
                if (value.price.amount || value.hasFlight) {
                    availableDates.push(getDateWithTimezone(new Date(key)));
                }
            }
            return availableDates;
        }
        else {
            return getAvailableDatesFromSchedule();
        }
    };
    const getDatesWithMinPrices = useMemo(() => {
        var _a;
        let dates = [];
        const prices = routeType === 'outbound' ? minPricesTo : minPricesBack;
        if (!prices) {
            return [];
        }
        const [min, max] = getMinMaxPrice(prices);
        const minPrice = min < max ? min + ((max - min) * 20) / 100 : min;
        if (prices && minPrice) {
            for (const [key, value] of Object.entries(prices)) {
                if (((_a = value.price) === null || _a === void 0 ? void 0 : _a.amount) <= minPrice) {
                    dates = [...dates, getDateWithTimezone(new Date(key))];
                }
            }
        }
        return dates;
    }, [routeType, minPricesTo, minPricesBack]);
    const setDate = (date, isBackDate) => {
        const config = {};
        // departure
        if (!isBackDate) {
            if (selectedDates[1] && isAfter(date, selectedDates[1])) {
                config.return = null;
            }
            config.departure = date;
        } /*return */
        else {
            config.return = date;
            config.departure = selectedDates[0];
            if (selectedDates[0] && isBefore(date, selectedDates[0])) {
                config.departure = date;
                config.return = null;
            }
        }
        if (withDateBack) {
            onDateChange(config.departure, false);
            onDateChange(config.return, true);
            if (!isBackDate && withDateBack && !config.return) {
                setRouteType(DatepickerTab.Return);
            }
        }
        else {
            onDateChange(date);
        }
        return config;
    };
    const handlerClear = useCallback(() => {
        setRouteType(DatepickerTab.Outbound);
        clearDates();
    }, [clearDates]);
    if (!openDate) {
        setOpenDate(!withDateBack && selectedDates.length ? selectedDates[selectedDates.length - 1] : new Date());
    }
    const clearOpenDate = () => {
        setOpenDate(!withDateBack && selectedDates.length ? selectedDates[selectedDates.length - 1] : new Date());
    };
    const passengerTypesToSend = [
        AviaPassengerType.ADT,
        AviaPassengerType.CLD,
        AviaPassengerType.INF,
        AviaPassengerType.INS
    ];
    const onPricesEnd = (date, direction) => __awaiter(void 0, void 0, void 0, function* () {
        setPricesLoading(true);
        const { data } = yield refetchMinPrices({
            params: getMinPricesRequestVariables(locations, passengers.filter(passenger => passengerTypesToSend.includes(passenger.passengerType)), date, routeType !== DatepickerTab.Outbound)
        });
        const prices = getPrices(data);
        if (routeType === DatepickerTab.Outbound) {
            setMinPricesTo(prices);
            if (direction === 'prev') {
                const pricesKeys = Object.keys(prices);
                const lastMonth = pricesKeys[pricesKeys.length - 1];
                const prevMonth = safeStartOfMonth(subMonths(parseISO(lastMonth), 1));
                setOpenDate(prevMonth);
            }
        }
        else {
            setMinPricesBack(prices);
        }
        setPricesLoading(false);
    });
    const handlerClickDatepickerTabOutbound = useCallback(() => {
        setRouteType(DatepickerTab.Outbound);
        setPreviousManuallyRoute(DatepickerTab.Outbound);
        setIsRouteTypeSetManually(true);
        if (isSelectedOnlyDepartureDate(selectedDates)) {
            setIsThisFirstSelectableDate(true);
        }
    }, [selectedDates]);
    const handlerClickDatepickerTabReturn = useCallback(() => {
        if (!selectedDates[0]) {
            return;
        }
        setRouteType(DatepickerTab.Return);
        setPreviousManuallyRoute(DatepickerTab.Return);
        setIsThisFirstSelectableDate(false);
    }, [selectedDates[0]]);
    const handlerCalendarOpen = useCallback(() => {
        setRouteType(DatepickerTab.Outbound);
        setPreviousManuallyRoute(DatepickerTab.Outbound);
        setIsRouteTypeSetManually(true);
        if (isSelectedOnlyDepartureDate(selectedDates) || selectedDates.length === 0) {
            setIsThisFirstSelectableDate(true);
        }
    }, [selectedDates]);
    const isReadyToClose = useCallback((date) => {
        const filterSelectedDates = selectedDates.filter(date => !!date);
        if (filterSelectedDates.length) {
            const isBeforeDate = isBefore(date, filterSelectedDates[0]);
            if (isBeforeDate && !isThisFirstSelectableDate) {
                return false;
            }
            return routeType === DatepickerTab.Return;
        }
        return false;
    }, [selectedDates, isThisFirstSelectableDate, routeType]);
    const fetchPrices = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!locations.arrival || !locations.departure) {
            return;
        }
        if (locations.arrival.iata === locations.departure.iata) {
            return;
        }
        setPricesLoading(true);
        try {
            const { data: minPricesToResp } = yield refetchMinPrices({
                params: getMinPricesRequestVariables(locations, passengers, dateTo, false)
            });
            if (!isMultiCity) {
                const { data: minPricesBackResp } = yield refetchMinPrices({
                    params: getMinPricesRequestVariables(locations, passengers, dateTo, true)
                });
                setMinPricesBack(getPrices(minPricesBackResp));
            }
            setMinPricesTo(getPrices(minPricesToResp));
            setPricesLoading(false);
        }
        catch (e) {
            console.log(e.message);
        }
        finally {
            setPricesLoading(false);
        }
    });
    const onChangeLocalStorage = (e) => {
        if (e.key === CURRENCY_KEY && e.oldValue !== e.newValue) {
            fetchPrices();
        }
    };
    useEffect(() => {
        fetchPrices();
        window.addEventListener('storage', onChangeLocalStorage);
        return () => {
            window.removeEventListener('storage', onChangeLocalStorage);
        };
    }, [locations.departure && locations.departure.iata, locations.arrival && locations.arrival.iata]);
    useEffect(() => {
        if (withDateBack && selectedDates.length === 0 && routeType === DatepickerTab.Return) {
            setRouteType(DatepickerTab.Outbound);
        }
    }, [withDateBack]);
    const isShowPriceGraph = !pricesLoading && !showPrices && showPriceGraph && !!Object.keys(minPricesTo || {}).length;
    return (React.createElement(FormSpy, { subscription: {
            submitFailed: true
        } }, formState => (React.createElement(Field, { name: `dates_${segmentId}`, validate: validate, subscription: {
            data: true,
            error: true
        } }, fieldState => {
        const onSelect = (date, isBackDate) => {
            setHoverDates([null, null]);
            const config = setDate(date, isBackDate);
            setRouteType(DatepickerTab.Return);
            setPreviousManuallyRoute(DatepickerTab.Return);
            setIsThisFirstSelectableDate(false);
            formState.form.mutators.setFieldData(fieldState.input.name, config);
        };
        // We need this for filling out the search form from the outside.
        if ((dateTo || dateBack) && !fieldState.meta.data.departure) {
            const config = {};
            if (dateTo) {
                config.departure = dateTo;
            }
            if (dateBack) {
                config.return = dateBack;
            }
            formState.form.mutators.setFieldData(fieldState.input.name, config);
        }
        if (!dateTo || !dateBack) {
            const config = {};
            config.departure = dateTo;
            config.return = dateBack;
            formState.form.mutators.setFieldData(fieldState.input.name, {
                departure: dateTo,
                return: dateBack
            });
        }
        if (fieldState.meta.error) {
            lastVisibleError = fieldState.meta.error;
        }
        return (React.createElement(Tooltip, { title: lastVisibleError, open: !!fieldState.meta.error && formState.submitFailed },
            React.createElement("div", { className: css.datepicker },
                React.createElement(OverrideSearchFormDatepicker, { monthCount: 3, showProgress: showPrices && pricesLoading, onSelect: onSelect, onHover: withDateBack ? onHover : null, hoverDates: hoverDates, onClear: handlerClear, clearOpenDate: clearOpenDate, openDate: openDate, selectedDates: selectedDates.filter(date => !!date), minDate: startDate, isClearable: hasSelectedDates, isDoneable: hasSelectedDates, inputClassName: css.input, routeType: routeType, onOpen: handlerCalendarOpen, inputFocusedClassName: css.input_focused, outsideClickIgnoreClass: segmentId > 0
                        ? `${css.dates.split(' ')[0]}_${segmentId}`
                        : css.dates.split(' ')[0], showPriceGraph: isShowPriceGraph, onPriceGraphPricesEnd: onPricesEnd, pricesTo: minPricesTo, pricesBack: minPricesBack, showPriceMatrix: false, isDateToSelected: !!dateTo, isDateBackSelected: !!dateBack, datepickerAlternativeHighlighting: alternativeHighlighting, useThemeWithPrices: showPrices, highlightedDates: {
                        [highlightingClassName]: getAvailableDates(),
                        [css.highlighted_minPrice]: getDatesWithMinPrices
                    }, closeAfterSelectReturnDate: isReadyToClose, onClickOutboundDate: handlerClickDatepickerTabOutbound, onClickReturnDate: handlerClickDatepickerTabReturn, valueRenderer: (isOpen, open, close) => {
                        var _a, _b;
                        const valueProps = {
                            className: valueClassName,
                            dateTo: (_a = hoverDates[0]) !== null && _a !== void 0 ? _a : dateTo,
                            dateBack: (_b = hoverDates[1]) !== null && _b !== void 0 ? _b : dateBack,
                            isOpen,
                            open,
                            close,
                            withDateBack,
                            routeType,
                            setRouteType,
                            onClear: handlerClear,
                            segmentId,
                            setIsRouteTypeSetManually,
                            onClickDatepickerTabReturn: handlerClickDatepickerTabReturn,
                            onClickDatepickerTabOutbound: handlerClickDatepickerTabOutbound
                        };
                        if (renderValue) {
                            return renderValue(valueProps);
                        }
                        return (React.createElement(OverrideComponent, { componentProps: valueProps, component: { SearchFormDatepickerValue: Value } }));
                    } }))));
    }))));
};
export default Datepicker;
