export const isPassengersHeightExceedViewport = (passengersRect) => {
    const segmentTabs = document.getElementById('segment-tabs');
    const toolbar = document.getElementById('toolbar');
    if (!segmentTabs || !toolbar) {
        return false;
    }
    const viewPort = toolbar.getBoundingClientRect().top - segmentTabs.getBoundingClientRect().bottom;
    const margin = 40;
    return passengersRect.height + margin > viewPort;
};
export const isSegmentSeatMapTooWide = (segment) => { var _a, _b, _c, _d; return ((_d = (_c = (_b = (_a = segment.decks[0]) === null || _a === void 0 ? void 0 : _a.rows) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.seats) === null || _d === void 0 ? void 0 : _d.length) >= 10; };
