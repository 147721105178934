import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { PromoCodeStatus } from '../types';
import Link from '../../Link';
import PromoCodeForm from '../Form/PromoCodeForm';
import { useTheme } from '../../theme';
import * as icons from '../../Icons/';
import { initI18n } from '../../utils';
import { useConfig } from '../../context';
import { OverrideComponent } from '../../renderProps';
import ModalConfirm from '../../ModalConfirm';
initI18n('PromoCode');
/**
 * you can get all needed data from ../hooks.ts ( except onSubmit handler )
 */
const PromoCode = props => {
    const { t } = useTranslation('PromoCode');
    const { PromoCode: theme } = useTheme('PromoCode');
    const { global: { allowPromoCodes } } = useConfig();
    const SHOW_MODAL_IF_STATUS = [PromoCodeStatus.Empty, PromoCodeStatus.NotApplied];
    const SHOW_CLEAR_IF_STATUS = [PromoCodeStatus.Applied, PromoCodeStatus.Provided, PromoCodeStatus.NotApplied];
    const clearTrigger = props.onClear && (React.createElement("span", { className: theme.clearWrapper },
        React.createElement(Link, { action: props.onClear, className: theme.clear },
            t('reset'),
            " ",
            React.createElement("span", { className: theme.clearIcon }, icons.CloseClear))));
    return allowPromoCodes ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(theme.promoCode, props.className, {
                [theme.promoCode_applied]: props.status === PromoCodeStatus.Applied,
                [theme.promoCode_notApplied]: props.status === PromoCodeStatus.NotApplied,
                [theme.promoCode_empty]: props.status === PromoCodeStatus.Empty
            }) },
            React.createElement("div", { className: theme.contentWrp },
                SHOW_MODAL_IF_STATUS.includes(props.status) && (React.createElement("span", { className: theme.content },
                    props.status === PromoCodeStatus.Empty && (React.createElement(Link, { className: cn(theme.trigger, props.promoCodeClassName), action: props.onOpenForm }, t('Do you have a promo code?'))),
                    props.status === PromoCodeStatus.NotApplied && (React.createElement(Link, { className: theme.trigger, action: props.onOpenForm },
                        React.createElement("span", { className: theme.icon_notApplied }, icons.Warning),
                        t('Promo code not applied'))))),
                SHOW_CLEAR_IF_STATUS.includes(props.status) && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: theme.content },
                        props.status === PromoCodeStatus.Provided && props.promoCode && (React.createElement("span", null,
                            t('Promo code'),
                            ": ",
                            props.promoCode)),
                        props.status === PromoCodeStatus.Applied && props.promoCode && (React.createElement(React.Fragment, null,
                            React.createElement("span", { className: theme.icon }, icons.Included),
                            t('Promo code applied')))),
                    props.promoCode && clearTrigger)),
                props.renderAfter && props.renderAfter(props))),
        React.createElement(ModalConfirm, { isOpen: props.isFormOpen, onClose: props.onCloseForm, hideTitle: true, hideFooter: true },
            React.createElement(OverrideComponent, { componentProps: {
                    isLoading: props.isLoading,
                    status: props.status,
                    onSubmit: props.onAddPromoCode
                }, component: {
                    renderPromoCodeForm: PromoCodeForm
                } })))) : null;
};
export default PromoCode;
