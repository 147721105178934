import * as React from 'react';
import Segment from './Segment/Segment';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import { BaseButton } from '../../BaseComponents';
initI18n('DesktopFlightInfo');
const FlightInfo = ({ flight, onButtonClick, buttonText = '', buttonType = 'common', renderPreFooter }) => {
    const { DesktopFlightInfo: css } = useTheme('DesktopFlightInfo');
    const getNextSegment = (index) => flight.segments[index + 1].segment;
    return (React.createElement("div", { className: css.info },
        React.createElement("div", { className: css.segments }, flight.segments.map(({ segment, transferDuration }, index) => (React.createElement(Segment, { key: index, segment: segment, transferDuration: transferDuration, nextSegment: transferDuration ? getNextSegment(index) : undefined })))),
        renderPreFooter && renderPreFooter(),
        onButtonClick && (React.createElement("div", { className: css.footer },
            React.createElement(BaseButton, { variant: "optionEdit", onClick: onButtonClick, classes: { root: css.button_default } }, buttonText)))));
};
export default FlightInfo;
