import { __rest } from "tslib";
import * as React from 'react';
import { CartService } from '../types';
import Flights from './Flights';
import Baggage from './Baggage';
import Seats from './Seats';
import Meals from './Meals';
import Passengers from './Passengers';
import Extra from './Extra';
import Insurance from './Extra/Insurance';
const CartFactory = (_a) => {
    var { type } = _a, props = __rest(_a, ["type"]);
    switch (type) {
        case CartService.Flights:
            return React.createElement(Flights, Object.assign({}, props));
        case CartService.Baggage:
            return React.createElement(Baggage, Object.assign({}, props));
        case CartService.Seats:
            return React.createElement(Seats, Object.assign({}, props));
        case CartService.Meal:
            return React.createElement(Meals, Object.assign({}, props));
        case CartService.Passengers:
            return React.createElement(Passengers, Object.assign({}, props));
        case CartService.Extra:
            return React.createElement(Extra, Object.assign({}, props));
        case CartService.Insurances:
            return React.createElement(Insurance, Object.assign({}, props));
        default:
            return null;
    }
};
export default CartFactory;
