import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
const getHeader = (theme) => {
    const { t } = useTranslation('Datepicker');
    const headers = [];
    for (let i = 0; i < 7; i++) {
        headers.push(React.createElement("div", { key: i, className: `${theme.day} ${theme.dayOfWeekName}` },
            React.createElement("span", null, t(`week_short_${i}`))));
    }
    return headers;
};
export const WeeklyHeader = () => {
    const theme = useTheme('Datepicker').WeeklyHeaderStyles;
    return React.createElement("div", { className: theme.week }, getHeader(theme));
};
