import { useEffect, useReducer } from 'react';
import { initialStateFactory } from './initialStateFactory';
import reducer from './reducer';
import { useVariables } from './variables';
import { useMethods } from './methods';
export * from './types';
export const useTransferService = (order, onChange, onStateChanged, activeSegment) => {
    const [state, dispatch] = useReducer(reducer, order, initialStateFactory);
    const variables = useVariables(order, state, activeSegment);
    const methods = useMethods(dispatch);
    useEffect(() => {
        const servicesToSave = { id: order.id, services: [] };
        for (const [passengerId] of Object.keys(state.services)) {
            const travellerSetServices = [];
            for (const [segmentId] of Object.keys(state.services[passengerId])) {
                for (const service of state.services[passengerId][segmentId]) {
                    if (service.selected || service.confirmed) {
                        travellerSetServices.push({
                            serviceId: service.service.id,
                            segmentIds: [segmentId],
                            count: 1
                        });
                    }
                    else {
                        travellerSetServices.push({
                            serviceId: service.service.id,
                            segmentIds: [segmentId],
                            count: 0
                        });
                    }
                }
            }
            servicesToSave.services.push({
                travellerId: passengerId,
                gdsServices: {
                    setServices: travellerSetServices
                }
            });
        }
        onChange(servicesToSave);
    }, [state.services]);
    useEffect(() => {
        onStateChanged(variables.stateChanged);
    }, [variables.stateChanged]);
    return {
        activeService: state.activeService,
        services: state.services,
        transferServices: variables.transferServices,
        segmentsById: variables.segmentsById,
        passengersById: variables.passengersById,
        selectedServices: variables.selectedServicesIds,
        activeSegmentServices: variables.activeSegmentServices,
        setActiveService: methods.setActiveService,
        toggleService: methods.toggleService,
        onChangeService: methods.setServiceState
    };
};
