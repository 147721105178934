import { __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { Money } from '../../../Money';
import Control from '../../../VipServiceSelect/components/Control/Control';
const PassengerPriceControl = (_a) => {
    var { price, passenger, serviceType, isConfirmed, onSetCount } = _a, props = __rest(_a, ["price", "passenger", "serviceType", "isConfirmed", "onSetCount"]);
    const { Control: theme } = useTheme('CheckoutAdditionalService');
    return (React.createElement("div", { className: cn(theme.passenger, {
            [theme.passenger_vipService]: serviceType === OrderAdditionalServiceGdsServiceServiceType.Vip
        }) },
        React.createElement(Control, Object.assign({}, props, { label: React.createElement("div", { className: theme.label }, passenger.name), disabled: isConfirmed, onSetCount: onSetCount(passenger) })),
        React.createElement("div", { className: theme.price }, props.count > 0 && React.createElement(Money, { money: price }))));
};
export default PassengerPriceControl;
