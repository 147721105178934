import { createSelector } from 'reselect';
import { convertBaggageObject, convertMealObject } from '../../types';
import { get, MEAL_RECOMMENDATION_SHOWN } from '../../../cache';
import { Measurement } from '../../../BaggageService/types';
import { getDefaultTimelineStep, getGdsServiceMinPrice, getServicePrice, getIsBrandIncludedService } from '../../utils';
import { parseIncludedBaggage } from '../../../BaggagePage/utils';
import { getFlatArraySet, getHasRequestedServices, getTotalPrice, isOdd } from '../../../utils';
import { getHasSelectedSeats, getPassengerStateCompatible, getSelectedServicesState } from '../selectedServices/selectors';
import { useConfig } from '../../../context';
import { AviaPassengerType, BaggageType, FlightSegmentStatus, GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType, OrderStatus, PaymentStatusEnum, TravellerFieldEnum } from '@websky/graphql';
import { getRelatedSegments } from '../../../Baggage/UpgradeBaggageDialog/utils';
import { Currency } from '../../../enums';
export const getSelectedTravellerServices = (gdsServices, travellers, onlyConfirmed = false) => {
    const selectedServices = [];
    gdsServices.forEach(service => {
        const allowedPassengerIdSet = getFlatArraySet(service.allowedPassengers);
        if (allowedPassengerIdSet.size) {
            travellers.forEach(traveller => {
                var _a, _b, _c;
                if (allowedPassengerIdSet.has(traveller.id)) {
                    const allowedSegmentIdSet = getFlatArraySet(service.allowedSegments);
                    if (allowedSegmentIdSet.size) {
                        (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(selectedService => {
                            var _a;
                            if (selectedService.serviceId === service.id &&
                                selectedService.segmentIds.every(id => allowedSegmentIdSet.has(id)) &&
                                (onlyConfirmed
                                    ? selectedService.confirmedCount > 0
                                    : selectedService.confirmedCount === 0)) {
                                selectedServices.push(Object.assign(Object.assign({}, service), { price: Object.assign(Object.assign({}, service.price), { amount: service.price.amount * (((_a = selectedService.segmentIds) === null || _a === void 0 ? void 0 : _a.length) || 1) }) }));
                            }
                        });
                    }
                }
            });
        }
    });
    return selectedServices;
};
export const getSelectedServicesTotalPrice = (services) => {
    if (!services.length) {
        return null;
    }
    const price = getTotalPrice(services, service => service.price);
    if (price.amount === 0) {
        return null;
    }
    return price;
};
const getState = (state) => state;
export const getOrder = createSelector(getState, state => state.order);
export const getShowServicesBeforeBooking = createSelector(getOrder, order => { var _a; return (_a = order.showServicesBeforeBooking) !== null && _a !== void 0 ? _a : true; });
export const getPaymentStatus = createSelector(getOrder, order => order.paymentStatus);
export const getIsBlockedForPayment = createSelector(getOrder, order => order.isBlockedForPayment);
export const getFlight = createSelector(getOrder, order => order.flight);
export const getCanceledFlights = createSelector(getFlight, flight => flight.segmentGroups.filter(group => {
    return group.segments.some(segment => segment.status === FlightSegmentStatus.Canceled);
}));
export const getAdditionalServices = createSelector(getOrder, order => order.additionalServices);
export const getServices = createSelector(getOrder, order => { var _a, _b; return (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) !== null && _b !== void 0 ? _b : []; });
export const getEveryPortServices = createSelector(getOrder, order => order.additionalServices.nas);
export const getRecheckAvailabilityTimout = createSelector(getOrder, order => order.timeoutToReCheckAvailability);
export const getActualizationResult = createSelector(getOrder, order => order.actualizationResult);
export const getIsNeedToHideFlightPrice = createSelector(getOrder, order => (order.hideItFares || false) && [OrderStatus.Confirmed, OrderStatus.Cancelled].includes(order.status));
export const getTravellers = createSelector(getOrder, order => order.travellers);
export const getBonusForFlight = createSelector(getOrder, order => { var _a; return (_a = order.ffpInfo) === null || _a === void 0 ? void 0 : _a.bonusForFlight; });
export const getPriceInMiles = createSelector(getOrder, order => { var _a; return (_a = order.price) === null || _a === void 0 ? void 0 : _a.totalInMiles; });
export const getCustomerSubscribed = createSelector(getOrder, order => order.customer.subscribed);
export const getAvailableServices = createSelector(getServices, services => {
    const availableGdsServices = {};
    services.forEach(service => {
        const serviceType = service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage
            ? service.baggageType
            : service.type;
        if (!availableGdsServices.hasOwnProperty(serviceType)) {
            availableGdsServices[serviceType] = serviceType;
        }
    });
    return availableGdsServices;
});
export const getSegmentGroups = createSelector(getOrder, order => { var _a; return (_a = order.flight) === null || _a === void 0 ? void 0 : _a.segmentGroups; });
export const getFareFamiliesMap = createSelector(getSegmentGroups, segmentGroups => {
    const fareFamiliesMap = new Map();
    segmentGroups.forEach(segmentGroup => {
        const fareFamily = segmentGroup.fareFamily;
        if (!fareFamily) {
            return;
        }
        if (!fareFamiliesMap.has(fareFamily.id)) {
            fareFamiliesMap.set(fareFamily.id, {
                fareFamily,
                segmentIds: []
            });
        }
        segmentGroup.segments.forEach(segment => fareFamiliesMap.get(fareFamily.id).segmentIds.push(segment.id));
    });
    return fareFamiliesMap;
});
export const getIsOrderBlockedForPayment = createSelector(getOrder, order => order.isBlockedForPayment);
export const getOldOrderPrice = createSelector(getOrder, order => { var _a; return (_a = order === null || order === void 0 ? void 0 : order.actualizationResult) === null || _a === void 0 ? void 0 : _a.oldPrice; });
export const getNewOrderPrice = createSelector(getOrder, order => { var _a; return (_a = order === null || order === void 0 ? void 0 : order.actualizationResult) === null || _a === void 0 ? void 0 : _a.newPrice; });
export const getFareLockService = createSelector(getServices, services => services.find(service => service.isPriceFreeze));
export const getIsFareLockSelected = createSelector(getTravellers, getFareLockService, (travellers, fareLockService) => travellers === null || travellers === void 0 ? void 0 : travellers.some(traveller => { var _a, _b, _c; return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => (service === null || service === void 0 ? void 0 : service.serviceId) === (fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.id)); }));
export const getIsFareLockConfirmed = createSelector(getTravellers, getFareLockService, (travellers, fareLockService) => travellers === null || travellers === void 0 ? void 0 : travellers.some(traveller => {
    var _a, _b, _c;
    return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => (service === null || service === void 0 ? void 0 : service.serviceId) === (fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.id) && (service === null || service === void 0 ? void 0 : service.confirmedCount));
}));
export const getIsPromoCodeAppliedToFlight = createSelector(getOldOrderPrice, getNewOrderPrice, (oldPrice, newPrice) => (newPrice === null || newPrice === void 0 ? void 0 : newPrice.amount) < (oldPrice === null || oldPrice === void 0 ? void 0 : oldPrice.amount));
const _getIncludedBaggage = createSelector(getFareFamiliesMap, getTravellers, useConfig, (fareFamiliesMap, travellers, config) => {
    var _a, _b;
    if (['N4', 'DV', 'YC'].includes((_b = (_a = config === null || config === void 0 ? void 0 : config.global) === null || _a === void 0 ? void 0 : _a.companyInfo) === null || _b === void 0 ? void 0 : _b.iataCode)) {
        // filter out all included baggage for INF and INS
        const travellersWithoutInfants = travellers.filter(traveller => traveller.type !== AviaPassengerType.INF && traveller.type !== AviaPassengerType.INS);
        return fareFamiliesMap
            ? parseIncludedBaggage(fareFamiliesMap, travellersWithoutInfants.map(({ id }) => id))
            : [];
    }
    return fareFamiliesMap
        ? parseIncludedBaggage(fareFamiliesMap, travellers.map(({ id }) => id))
        : [];
});
export const getBrandIncludedBaggage = createSelector(getTravellers, travellers => {
    const includedBaggages = [];
    travellers.forEach(traveller => {
        var _a, _b;
        return (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.brandIncludedServices) === null || _b === void 0 ? void 0 : _b.services.filter(services => services.service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage).forEach(service => {
            var _a;
            const baggageService = service.service;
            const baggage = {
                id: service.serviceId,
                segmentIds: baggageService.allowedSegments,
                includedSegments: service.segmentIds,
                type: baggageService.baggageType,
                name: baggageService.name,
                description: baggageService.description,
                value: {
                    amount: parseInt((_a = baggageService.baggageWeight) === null || _a === void 0 ? void 0 : _a.split('x')[0]),
                    measurement: Measurement.Kilograms
                },
                size: baggageService.size,
                included: service.confirmedCount,
                price: baggageService.price,
                rfics: baggageService.rfisc,
                canBeAdded: false,
                allowedPassengers: [traveller.id],
                isReplacedBy: baggageService.isReplacedBy
            };
            includedBaggages.push(Object.assign(Object.assign({}, service), { passengerId: traveller.id, baggage }));
        });
    });
    return includedBaggages;
});
export const getAnimalServices = createSelector(getServices, services => services.filter(service => [BaggageType.PetInCabin, BaggageType.PetInHold, BaggageType.PetInHandCarrier].includes(service.baggageType)));
export const getSelectedTravellerAnimalServices = createSelector(getAnimalServices, getTravellers, getSelectedTravellerServices);
export const getSelectedAnimalServicesPrice = createSelector(getSelectedTravellerAnimalServices, getSelectedServicesTotalPrice);
export const hasSeatsServices = createSelector(getServices, services => services.some(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Seat));
export const hasBaggageServices = createSelector(getServices, services => services.some(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage));
export const getSeatMap = createSelector(getOrder, order => order.flight.seatMap);
export const getOrderId = createSelector(getOrder, order => order.id);
export const getIsOddOrderId = createSelector(getOrderId, isOdd);
export const getIsNew = createSelector(getOrder, order => order.status === OrderStatus.New);
export const getIsNotNew = createSelector(getOrder, order => order.status !== OrderStatus.New);
export const getIsTravellersDataFilled = createSelector(getOrder, order => order.isTravellersDataFilled);
export const getIsReadonly = createSelector(getIsTravellersDataFilled, getIsNew, (isTravellersDataFilled, isNew) => isTravellersDataFilled && !isNew);
export const getIsRedirectFromMetasearch = createSelector(getOrder, order => order.redirectFromMetasearch);
export const getOrderStatus = createSelector(getOrder, order => order.status);
export const getHasAvailableSeats = createSelector(getSeatMap, seatMap => { var _a; return (_a = seatMap === null || seatMap === void 0 ? void 0 : seatMap.segments) === null || _a === void 0 ? void 0 : _a.some(({ availableSeatsCount }) => availableSeatsCount > 0); });
export const getIsAllSegmentsAvailableOnlyOnCheckin = createSelector(getSeatMap, seatMap => seatMap.segments.every(segment => segment.isSeatsAvailableInCheckinOnly === true));
export const getIsBookedOrConfirmed = createSelector(getOrder, order => order.status === OrderStatus.Booked || order.status === OrderStatus.Confirmed);
export const getTotalInsurancePrice = createSelector(getOrder, state => state.price.insurance);
export const getIsBooked = createSelector(getOrder, order => order.status === OrderStatus.Booked);
export const getIsConfirmed = createSelector(getOrder, order => order.status === OrderStatus.Confirmed);
export const getIsConfirmedOrCancelled = createSelector(getOrder, order => order.status === OrderStatus.Confirmed || order.status === OrderStatus.Cancelled);
export const getIsPriceFreezeProductSelected = createSelector(getOrder, order => order.isPriceFreezeProductSelected);
export const getIsCancelled = createSelector(getOrder, order => order.status === OrderStatus.Cancelled);
export const getIsFFPUsed = createSelector(getOrder, order => !!order.ffpInfo);
export const getMilesForOrder = createSelector(getOrder, order => { var _a; return (_a = order.ffpInfo) === null || _a === void 0 ? void 0 : _a.bonusForFlight; });
// comment for next php release
export const getFfpBonusesForFlightByTravellers = createSelector(getOrder, order => {
    var _a;
    const orderTravellersById = {};
    for (const traveller of order.travellers) {
        orderTravellersById[traveller.id] = null;
    }
    (_a = order.ffpInfo.bonusByCard) === null || _a === void 0 ? void 0 : _a.forEach(travellerBonus => {
        const orderTraveller = order.travellers.find(traveller => {
            return traveller.values.some(value => value.type === TravellerFieldEnum.LoyaltyNumber && value.value === travellerBonus.cardNumber);
        });
        if (orderTraveller) {
            orderTravellersById[orderTraveller.id] = Object.assign(Object.assign({}, travellerBonus), { cardNumber: travellerBonus.cardNumber });
        }
    });
    return orderTravellersById;
});
export const getAvailableGdsServicesByType = createSelector(getOrder, getSeatMap, getHasSelectedSeats, (order, seatMap, hasSelectedSeats) => {
    var _a, _b, _c, _d;
    const availableServices = {};
    (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(service => {
        // TODO: check that service.canBeAdded = true when php fix it
        if (!availableServices.hasOwnProperty(service.type)) {
            availableServices[service.type] = true;
        }
    });
    return Object.assign(Object.assign(Object.assign(Object.assign({}, availableServices), {
        [OrderAdditionalServiceGdsServiceServiceType.Seat]: ((_d = seatMap === null || seatMap === void 0 ? void 0 : seatMap.segments) === null || _d === void 0 ? void 0 : _d.some(segment => segment.availableSeatsCount > 0 || segment.isSeatsAvailableInCheckinOnly)) || hasSelectedSeats
    }), {
        [OrderAdditionalServiceGdsServiceServiceType.Baggage]: availableServices.Baggage ||
            getIsBrandIncludedService(order, OrderAdditionalServiceGdsServiceServiceType.Baggage)
    }), {
        [OrderAdditionalServiceGdsServiceServiceType.Meal]: availableServices.Meal ||
            getIsBrandIncludedService(order, OrderAdditionalServiceGdsServiceServiceType.Meal)
    });
});
export const getPaymentIsRequired = createSelector(getOrder, order => order.paymentStatus !== PaymentStatusEnum.Full);
export const getCustomer = createSelector(getOrder, order => order === null || order === void 0 ? void 0 : order.customer);
export const getIsContactsInfoIsEmpty = createSelector(getCustomer, customer => customer.contactInfoIsEmpty);
export const isRoundTrip = createSelector(getOrder, (order) => {
    const segmentGroups = order.flight.segmentGroups;
    if (segmentGroups.length !== 2) {
        return false;
    }
    const outbound = segmentGroups[0];
    const outboundDeparture = outbound.segments[0].departure.airport.iata;
    const outboundArrival = outbound.segments[outbound.segments.length - 1].arrival.airport.iata;
    const inbound = segmentGroups[1];
    const inboundDeparture = inbound.segments[0].departure.airport.iata;
    const inboundArrival = inbound.segments[inbound.segments.length - 1].arrival.airport.iata;
    return outboundArrival === inboundDeparture && outboundDeparture === inboundArrival;
});
export const getSegmentsForServices = createSelector(getOrder, (order) => {
    const segments = [];
    order.flight.segmentGroups.map(group => group.segments.map(segment => {
        var _a;
        segments.push({
            id: segment.id,
            arrival: segment.arrival,
            departure: segment.departure,
            amenities: (_a = segment.flightInfo) === null || _a === void 0 ? void 0 : _a.amenities,
            groupId: group.groupId
        });
    }));
    return segments;
});
export const getSegmentsWithAvailableSeatsCount = createSelector(getSegmentsForServices, getSeatMap, (segments, seatMap) => {
    return segments.map(segment => {
        var _a;
        return (Object.assign(Object.assign({}, segment), { availableSeatsCount: (_a = seatMap.segments.find(seatMapSegment => seatMapSegment.segmentsInfo.id === segment.id)) === null || _a === void 0 ? void 0 : _a.availableSeatsCount }));
    });
});
export const getSegmentsForBaggage = createSelector(getOrder, (order) => {
    return order.flight.segments.map(seg => ({
        id: seg.segment.id,
        departure: seg.segment.departure,
        arrival: seg.segment.arrival
    }));
});
export const getSegments = createSelector(getOrder, (order) => order.flight.segments.map(segment => segment.segment));
export const getSegmentIds = createSelector(getOrder, order => order.flight.segments.map(segment => segment.segment.id));
export const getMessages = createSelector(getOrder, order => order.messages);
export const getBaggageMinPrice = createSelector(getOrder, order => getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Baggage));
export const getGroundTransportationServices = createSelector(getServices, services => {
    return services.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
        service.subType ===
            GdsServiceVipSubType.GroundTransportation);
});
export const getSelectedTravellerGroundTransportationServices = createSelector(getGroundTransportationServices, getTravellers, getSelectedTravellerServices);
export const getSelectedGroundTransportationServicesPrice = createSelector(getSelectedTravellerGroundTransportationServices, getSelectedServicesTotalPrice);
export const getUpgradeBaggageMinPrice = createSelector(getOrder, order => getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Baggage, [BaggageType.HandLuggageExcess]));
export const getSportsEquipmentMinPrice = createSelector(getOrder, order => getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Baggage, [
    BaggageType.SportingEquipment,
    BaggageType.PetInHold,
    BaggageType.PetInCabin,
    BaggageType.MusicalInstrument,
    BaggageType.FishingEquipment,
    BaggageType.SurfingEquipment,
    BaggageType.Ski,
    BaggageType.Bike,
    BaggageType.PetInHandCarrier
]));
export const getOverweightMinPrice = createSelector(getOrder, order => getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Baggage, [BaggageType.BaggageExcess]));
export const getCurrencyFromOrder = createSelector(getOrder, order => { var _a; return (_a = order.price.total) === null || _a === void 0 ? void 0 : _a.currency; });
export const getSeatsMinPrice = createSelector(getOrder, order => {
    var _a, _b, _c, _d, _e;
    let minPrice = 0;
    const currency = (_c = (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(service => { var _a; return !!((_a = service === null || service === void 0 ? void 0 : service.price) === null || _a === void 0 ? void 0 : _a.currency); })) === null || _c === void 0 ? void 0 : _c.price.currency;
    (_e = (_d = order.flight.seatMap) === null || _d === void 0 ? void 0 : _d.segments) === null || _e === void 0 ? void 0 : _e.forEach(segment => {
        var _a;
        (_a = segment === null || segment === void 0 ? void 0 : segment.decks) === null || _a === void 0 ? void 0 : _a.forEach(deck => {
            var _a;
            (_a = deck === null || deck === void 0 ? void 0 : deck.rows) === null || _a === void 0 ? void 0 : _a.forEach(row => {
                row === null || row === void 0 ? void 0 : row.seats.forEach(seat => {
                    var _a, _b;
                    if (!(seat === null || seat === void 0 ? void 0 : seat.isAvailable)) {
                        return;
                    }
                    if (!minPrice) {
                        minPrice = (_a = seat === null || seat === void 0 ? void 0 : seat.price) === null || _a === void 0 ? void 0 : _a.amount;
                    }
                    if (((_b = seat === null || seat === void 0 ? void 0 : seat.price) === null || _b === void 0 ? void 0 : _b.amount) < minPrice) {
                        minPrice = seat.price.amount;
                    }
                });
            });
        });
    });
    return {
        currency,
        amount: minPrice
    };
});
export const getMealMinPrice = createSelector(getOrder, order => getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Meal));
// #todo: add tests
export const getSearchParameters = createSelector(getOrder, getCurrencyFromOrder, ({ flight, travellers }, orderCurrency) => {
    const passengersMap = {};
    const passengers = [];
    travellers.forEach(({ type }) => {
        if (passengersMap.hasOwnProperty(type)) {
            passengersMap[type]++;
        }
        else {
            passengersMap[type] = 1;
        }
    });
    for (const passengerType in passengersMap) {
        if (passengersMap.hasOwnProperty(passengerType)) {
            passengers.push({
                passengerType: passengerType,
                extendedPassengerType: null,
                count: passengersMap[passengerType]
            });
        }
    }
    return {
        promotionCode: null,
        currency: orderCurrency,
        passengers,
        segments: flight.segmentGroups.map(group => {
            const firstSegment = group.segments[0];
            const lastSegment = group.segments[group.segments.length - 1];
            return {
                date: firstSegment.departure.date,
                departure: {
                    name: firstSegment.departure.airport.name,
                    iata: firstSegment.departure.airport.iata,
                    city: {
                        name: firstSegment.departure.airport.city.name
                    }
                },
                arrival: {
                    name: lastSegment.arrival.airport.name,
                    iata: lastSegment.arrival.airport.iata,
                    city: {
                        name: lastSegment.arrival.airport.city.name
                    }
                }
            };
        }),
        ffpMode: false
    };
});
// check that canAddServices is boolean ( because in CheckinOrder there is no such props and shouldRedirectToFallback always returns true )
export const shouldRedirectToFallback = createSelector(getOrder, ({ canAddServices, canRemoveServices }) => typeof canAddServices === 'boolean' &&
    typeof canRemoveServices === 'boolean' &&
    (!canAddServices || !canRemoveServices));
export const getServicesFallbackURL = createSelector(getOrder, ({ servicesFallbackURL }) => servicesFallbackURL);
export const getAeroexpress = createSelector(getOrder, order => { var _a, _b; return (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.transfer) === null || _b === void 0 ? void 0 : _b.aeroexpress; });
export const getInsurances = createSelector(getOrder, order => { var _a; return (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances; });
export const getInsurancesGroups = createSelector(getInsurances, insurances => insurances === null || insurances === void 0 ? void 0 : insurances.insuranceProgramGroups);
export const getInsurancesByGroups = createSelector(getInsurances, getInsurancesGroups, (insurances, groups) => {
    var _a;
    if (!((_a = insurances === null || insurances === void 0 ? void 0 : insurances.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length)) {
        return [];
    }
    if (!(groups === null || groups === void 0 ? void 0 : groups.length)) {
        return [{ groupName: null, insurancePrograms: insurances.insurancePrograms }];
    }
    return groups.map(group => ({
        groupName: group.groupValue,
        insurancePrograms: insurances.insurancePrograms.filter(program => group.insurancePrograms.some(({ code }) => code === program.code))
    }));
});
export const getAvailableInsuranceCodes = createSelector(getInsurances, insurance => {
    var _a;
    const availableInsuranceCodeSet = new Set();
    if ((_a = insurance === null || insurance === void 0 ? void 0 : insurance.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) {
        insurance.insurancePrograms.forEach(insurance => availableInsuranceCodeSet.add(insurance.code));
    }
    return [...availableInsuranceCodeSet];
});
export const getSelectedInsuranceCodes = createSelector(getInsurances, insurance => {
    var _a;
    const selectedInsuranceCodeSet = new Set();
    if ((_a = insurance === null || insurance === void 0 ? void 0 : insurance.selectedInsurances) === null || _a === void 0 ? void 0 : _a.length) {
        insurance.selectedInsurances.forEach(insurance => selectedInsuranceCodeSet.add(insurance.insuranceProgram.code));
    }
    return [...selectedInsuranceCodeSet];
});
export const getImportantInsuranceCodes = createSelector(getInsurances, insurance => {
    var _a;
    const importantInsuranceCodeSet = new Set();
    if ((_a = insurance === null || insurance === void 0 ? void 0 : insurance.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) {
        insurance.insurancePrograms.forEach(insurance => {
            if (insurance.important) {
                importantInsuranceCodeSet.add(insurance.code);
            }
        });
    }
    return [...importantInsuranceCodeSet];
});
export const getAvailableInsuranceCodesByPrograms = (insurancePrograms) => createSelector(getAvailableInsuranceCodes, availableCodes => {
    if (!(insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.length)) {
        return availableCodes;
    }
    return availableCodes.filter(code => insurancePrograms.some(program => program.code === code));
});
export const getAvailableSelectedInsuranceCodes = (availableCodes) => createSelector(getSelectedInsuranceCodes, selectedCodes => {
    if (!(availableCodes === null || availableCodes === void 0 ? void 0 : availableCodes.length)) {
        return selectedCodes;
    }
    return selectedCodes.filter(code => !availableCodes.includes(code));
});
export const getInsuranceCodeBySalePriority = createSelector(getInsurances, insurance => {
    var _a;
    const insuranceCodeBySalePriorityMap = new Map();
    if ((_a = insurance === null || insurance === void 0 ? void 0 : insurance.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) {
        insurance.insurancePrograms.forEach(insuranceProgram => {
            var _a, _b;
            const salePriority = (_b = (_a = insuranceProgram.extraParams) === null || _a === void 0 ? void 0 : _a.find(param => param.key === 'salePriority')) === null || _b === void 0 ? void 0 : _b.value;
            if (salePriority) {
                insuranceCodeBySalePriorityMap.set(insuranceProgram.code, +salePriority);
            }
        });
    }
    return insuranceCodeBySalePriorityMap;
});
export const getSortedPriorityInsurancePrograms = createSelector(getInsurances, getInsuranceCodeBySalePriority, (insurance, insuranceCodeBySalePriority) => {
    var _a;
    if (!((_a = insurance === null || insurance === void 0 ? void 0 : insurance.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) || !insuranceCodeBySalePriority.size) {
        return [];
    }
    return [...insurance.insurancePrograms]
        .filter(insuranceProgram => insuranceCodeBySalePriority.has(insuranceProgram.code))
        .sort((a, b) => {
        var _a, _b;
        const aPriority = (_a = insuranceCodeBySalePriority.get(a.code)) !== null && _a !== void 0 ? _a : 0;
        const bPriority = (_b = insuranceCodeBySalePriority.get(b.code)) !== null && _b !== void 0 ? _b : 0;
        return bPriority - aPriority;
    });
});
export const getPriorityInsuranceProgram = createSelector(useConfig, getSelectedInsuranceCodes, getSortedPriorityInsurancePrograms, (config, selectedInsuranceCodes, sortedPriorityInsurances) => {
    if (config.global.insurance.multiplePriorityInsurances) {
        return sortedPriorityInsurances.filter(insurance => !selectedInsuranceCodes.includes(insurance.code))[0];
    }
    return sortedPriorityInsurances[0];
});
function singleInsuranceProgramFilter(insuranceProgram, inversion) {
    return insuranceProgram.important !== inversion;
}
export const getSingleInsurancePrograms = (inversion = false) => createSelector(getInsurances, services => { var _a, _b; return (_b = (_a = services === null || services === void 0 ? void 0 : services.insurancePrograms) === null || _a === void 0 ? void 0 : _a.filter(i => singleInsuranceProgramFilter(i, inversion))) !== null && _b !== void 0 ? _b : []; });
export const getSelectedSingleInsurances = (inversion = false) => createSelector(getInsurances, services => services.selectedInsurances.filter(i => singleInsuranceProgramFilter(i.insuranceProgram, inversion)));
export const getMealServices = createSelector(getServices, (services) => services.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Meal));
export const getBaggageServices = createSelector(getServices, (services) => services.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage));
export const getVipServices = createSelector(getServices, (services) => services.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Vip));
export const getMeals = createSelector(getMealServices, (meals) => meals.map(convertMealObject));
export const getBaggage = createSelector(getBaggageServices, (baggage) => baggage.map(convertBaggageObject));
export const getUpgradeClassServices = createSelector(getVipServices, vipServices => { var _a; return (_a = vipServices.filter(service => service.subType === GdsServiceVipSubType.Upgrade)) !== null && _a !== void 0 ? _a : []; });
export const getIsUpgradeClassServiceSelected = createSelector(getUpgradeClassServices, getTravellers, (upgradeServices, travellers) => {
    const upgradeServicesIds = upgradeServices.map(({ id }) => id);
    return travellers.some(traveller => {
        var _a, _b, _c;
        return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => {
            return ((upgradeServicesIds.includes(service.serviceId) && service.count > 0) ||
                (upgradeServicesIds.includes(service.serviceId) && service.confirmedCount > 0));
        });
    });
});
export const getIsUpgradeClassSelectedForAllSegments = createSelector(getUpgradeClassServices, getTravellers, getSegments, (upgradeServices, travellers, segments) => {
    var _a, _b, _c, _d;
    const upgradeServicesIds = upgradeServices.map(({ id }) => id);
    const upgradeSelectedOnSegments = {};
    for (const traveller of travellers) {
        const upgradeServices = (_d = (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.filter(service => upgradeServicesIds.includes(service.serviceId))) !== null && _d !== void 0 ? _d : [];
        for (const upgradeService of upgradeServices) {
            for (const segmentId of upgradeService.segmentIds) {
                if (!upgradeSelectedOnSegments[segmentId] &&
                    (upgradeService.count > 0 || upgradeService.confirmedCount > 0)) {
                    upgradeSelectedOnSegments[segmentId] = true;
                }
            }
        }
    }
    return (Object.keys(upgradeSelectedOnSegments).length ===
        segments.filter(segment => segment.subStatus === null).length);
});
export const getCanAddUpgradeFlightClass = createSelector(getUpgradeClassServices, upgradeServices => !!(upgradeServices === null || upgradeServices === void 0 ? void 0 : upgradeServices.length));
export const getBusinessLounge = createSelector(getServices, services => services.filter(service => service.subType === GdsServiceVipSubType.BusinessLounge));
export const getBusinessLoungeByTypes = createSelector(getServices, services => {
    const businessLoungeByTypeMap = new Map();
    services.forEach(service => {
        var _a;
        const vipService = service;
        if (vipService.subType === GdsServiceVipSubType.BusinessLounge ||
            vipService.subType === GdsServiceVipSubType.BusinessLoungeInternational) {
            businessLoungeByTypeMap.set(vipService.subType, [
                ...((_a = businessLoungeByTypeMap.get(vipService.subType)) !== null && _a !== void 0 ? _a : []),
                vipService
            ]);
        }
    });
    return [...businessLoungeByTypeMap.values()];
});
export const getBaggageDelivery = createSelector([getOrder, getServices], (order, services) => {
    return services.reduce((services, service) => {
        const newServices = [...services];
        if (service.subType ===
            GdsServiceVipSubType.BaggageDelivery) {
            const serviceSegments = [...getRelatedSegments(service.allowedSegments)];
            // WEBSKY-2899: сегмент должен быть без пересадки
            serviceSegments
                .filter(segmentId => {
                var _a, _b;
                return ((_b = (_a = order.flight.segmentGroups.find(group => group.segments.some(segment => segment.id === segmentId))) === null || _a === void 0 ? void 0 : _a.segments) === null || _b === void 0 ? void 0 : _b.length) === 1;
            })
                .forEach(segmentId => newServices.push(Object.assign(Object.assign({}, service), { allowedSegments: [[segmentId]] })));
        }
        return newServices;
    }, []);
});
export const getCapsuleHotel = createSelector(getServices, services => services.filter(service => service.subType === GdsServiceVipSubType.CapsuleHotel));
export const getSmsNotificationServices = createSelector(getServices, services => services.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
    service.subType === GdsServiceVipSubType.SMS));
export const getSelectedTravellerSmsNotifications = createSelector(getSmsNotificationServices, getTravellers, getSelectedTravellerServices);
export const getSelectedSmsNotificationsPrice = createSelector(getSelectedTravellerSmsNotifications, getSelectedServicesTotalPrice);
export const getPriorityBoarding = createSelector(getServices, services => services.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
    service.subType ===
        GdsServiceVipSubType.PriorityBoarding));
export const getSelectedTravellerPriorityBoarding = createSelector(getPriorityBoarding, getTravellers, getSelectedTravellerServices);
export const getSelectedTravellerBaggageDelivery = createSelector(getBaggageDelivery, getTravellers, getSelectedTravellerServices);
export const getSelectedPriorityBoardingPrice = createSelector(getSelectedTravellerPriorityBoarding, getSelectedServicesTotalPrice);
export const getAdditionalBaggage = createSelector(getBaggage, items => {
    return items.filter(item => item.type === BaggageType.CheckedBaggage);
});
const getFakeBaggage = createSelector(getBaggageServices, (services) => {
    return services.filter(service => service.isFake).map(convertBaggageObject);
});
export const getIncludedBaggage = createSelector(_getIncludedBaggage, getFakeBaggage, (included, fake) => {
    return included.concat(fake);
});
export const getUpgradeBaggage = createSelector(getBaggage, getTravellers, (baggageItems, travellers) => {
    const travellersByIdMap = new Map(travellers.map(traveller => {
        var _a, _b;
        if (!((_b = (_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.length)) {
            return [traveller.id, null];
        }
        const travellerGdsServicesById = new Map(traveller.services.gdsServices.services.map(service => [
            service.serviceId,
            service.count > service.confirmedCount
        ]));
        return [traveller.id, travellerGdsServicesById];
    }));
    const isCanBeAddedOrRemoved = (item) => {
        const canBeRemoved = item.allowedPassengers.some(passengerId => { var _a; return (_a = travellersByIdMap.get(passengerId)) === null || _a === void 0 ? void 0 : _a.get(item.id); });
        return item.canBeAdded || canBeRemoved;
    };
    return baggageItems.filter(item => {
        return ((item.type === BaggageType.HandLuggageExcess || item.type === BaggageType.CarryOn) &&
            isCanBeAddedOrRemoved(item));
    });
});
export const getSpecialEquipment = createSelector(getBaggage, items => {
    return items.filter(item => [
        BaggageType.SportingEquipment,
        BaggageType.PetInCabin,
        BaggageType.PetInHold,
        BaggageType.MusicalInstrument
    ].includes(item.type));
});
export const getOverweightBaggage = createSelector(getBaggage, items => items.filter(item => item.type === BaggageType.BaggageExcess));
export const getUpgradableBaggage = createSelector([getUpgradeBaggage, getAdditionalBaggage, getSpecialEquipment, getOverweightBaggage], (...baggage) => baggage.reduce((services, items) => [...services, ...items], []));
export const getRecommendedMeals = createSelector(getMeals, getTravellers, (meals, travellers) => {
    const result = [];
    travellers.forEach(traveller => {
        traveller.preselectedServices.gdsServices.services.forEach(service => {
            const meal = meals.find(meal => meal.id === service.serviceId);
            if (meal) {
                result.push({
                    meal,
                    travellerId: traveller.id,
                    segmentIds: service.segmentIds,
                    count: service.count
                });
            }
        });
    });
    return result;
});
export const getRecommendedBaggage = createSelector(getBaggage, getTravellers, (baggage, travellers) => {
    const recommendedBaggage = [];
    travellers.forEach(traveller => {
        var _a, _b, _c;
        (_c = (_b = (_a = traveller.preselectedServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(service => {
            const baggageService = baggage.find(baggage => baggage.id === service.serviceId);
            if (baggageService) {
                recommendedBaggage.push({
                    baggage: baggageService,
                    travellerId: traveller.id,
                    count: service.count,
                    segmentIds: service.segmentIds,
                    confirmedCount: service.confirmedCount
                });
            }
        });
    });
    return recommendedBaggage;
});
export const getHasEverySelectedSeats = createSelector(getOrder, getPassengerStateCompatible, (order, passengers) => {
    const segmentsCount = order.flight.segments.length;
    return passengers
        .filter(passenger => passenger.type !== AviaPassengerType.INF)
        .some(({ seats = [] }) => {
        const segmentsBySeats = new Set(seats.map(seat => seat.segmentId));
        return seats.length > 0 && segmentsBySeats.size === segmentsCount;
    });
});
export const getHasAnySelectedSeats = createSelector(getPassengerStateCompatible, passengers => {
    return passengers
        .filter(passenger => passenger.type !== AviaPassengerType.INF)
        .some(({ seats = [] }) => {
        return seats.length > 0;
    });
});
export const getHasAnySelectedMeals = createSelector(getPassengerStateCompatible, getRecommendedMeals, (passengers, recommendedMeals) => passengers.some(({ services }) => services === null || services === void 0 ? void 0 : services.some(service => recommendedMeals.some(({ meal }) => meal.id === service.serviceId))));
export const getSkipRecommendations = createSelector(getIsRedirectFromMetasearch, getIsOddOrderId, (isFromMeta, orderIdIsOdd) => isFromMeta && orderIdIsOdd);
export const getIsTransferSelected = createSelector(getOrder, order => { var _a, _b, _c, _d; return ((_d = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.transfer) === null || _b === void 0 ? void 0 : _b.aeroexpress) === null || _c === void 0 ? void 0 : _c.selectedTrips) === null || _d === void 0 ? void 0 : _d.length) > 0; });
export const getIsInsuranceSelected = createSelector(getOrder, order => { var _a, _b, _c; return ((_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances) === null || _b === void 0 ? void 0 : _b.selectedInsurances) === null || _c === void 0 ? void 0 : _c.length) > 0; });
export const getShowSeatRecommendations = createSelector(getSkipRecommendations, getOrderId, getTravellers, getHasEverySelectedSeats, (skipRecommendations, orderId, travellers, getHasEverySelectedSeats) => !skipRecommendations &&
    // Check if we have something to recommend.
    travellers.some(traveller => traveller.preselectedServices.seats.length));
export const getShowMealRecommendations = createSelector(getSkipRecommendations, getOrderId, getRecommendedMeals, getHasAnySelectedMeals, (skipRecommendations, orderId, recommendedMeals, hasAnySelectedMeals) => !skipRecommendations &&
    // Check if user hasn't selected any meals.
    !hasAnySelectedMeals &&
    // Check if we have something to recommend.
    !!recommendedMeals.length &&
    // Check if user hasn't declined our recommendations yet.
    !get(`${MEAL_RECOMMENDATION_SHOWN}_${orderId}`));
export const getIsNeedToConfirmConsents = createSelector(useConfig, config => {
    return !['A9', 'RM', 'DV', 'JI', '5G', '2S'].includes(config.global.companyInfo.iataCode);
});
const addServiceIntoGroup = (servicesByTypes, type, segmentId, service, timelineStep, canBeAdded = true, selectedServices = []) => {
    var _a;
    const servicePrice = getServicePrice(service);
    if (servicesByTypes[segmentId].hasOwnProperty(type)) {
        servicesByTypes[segmentId][type].services.push(service);
        if (servicePrice && servicesByTypes[segmentId][type].minPrice.amount > servicePrice.amount) {
            servicesByTypes[segmentId][type].minPrice.amount = servicePrice.amount;
        }
        if (canBeAdded) {
            servicesByTypes[segmentId][type].canAddService = true;
        }
    }
    else {
        servicesByTypes[segmentId][type] = {
            type: type,
            services: [service],
            selectedServices: selectedServices,
            totalPrice: {
                amount: 0,
                currency: null
            },
            minPrice: {
                amount: (_a = servicePrice === null || servicePrice === void 0 ? void 0 : servicePrice.amount) !== null && _a !== void 0 ? _a : 0,
                currency: servicePrice.currency
            },
            canAddService: canBeAdded,
            step: timelineStep
        };
    }
};
const getRelatedAndSelectedSegmentIdsForService = (service, selectedServices) => {
    var _a;
    const relatedServicesSegments = getRelatedSegments((_a = service.allowedSegments) !== null && _a !== void 0 ? _a : []);
    Object.keys(selectedServices).forEach(passengerId => {
        if (selectedServices[passengerId].hasOwnProperty(service.id)) {
            selectedServices[passengerId][service.id].forEach(selected => selected.segments.forEach(segmentId => relatedServicesSegments.add(segmentId)));
        }
    });
    return [...relatedServicesSegments];
};
export const getGdsServicesBySegments = createSelector([
    getOrder,
    getSegments,
    getSelectedServicesState,
    getBrandIncludedBaggage,
    getServices,
    getSeatMap,
    getInsurancesByGroups,
    getAeroexpress,
    getEveryPortServices
], (order, segments, selectedServices, brandIncluded, gdsServices, seatMap, insurances, aeroexpress, everyPort) => {
    var _a;
    const servicesByTypes = {};
    segments.forEach(segment => {
        servicesByTypes[segment.id] = {};
    });
    gdsServices.forEach(service => {
        var _a;
        let convertedService, canBeAdded = true;
        if (service.type === OrderAdditionalServiceGdsServiceServiceType.Seat) {
            return;
        }
        else if (service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage) {
            convertedService = Object.assign(Object.assign({}, convertBaggageObject(service)), { _serviceType: 'gdsService' });
            canBeAdded = service.canBeAdded;
        }
        else if (service.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
            convertedService = Object.assign(Object.assign({}, convertMealObject(service)), { _serviceType: 'gdsService' });
            canBeAdded = service.canBeAdded;
        }
        else {
            convertedService = Object.assign(Object.assign({}, service), { _serviceType: 'gdsService' });
        }
        const relatedSegments = getRelatedSegments((_a = service.allowedSegments) !== null && _a !== void 0 ? _a : []);
        getRelatedAndSelectedSegmentIdsForService(service, selectedServices).forEach(segmentId => {
            var _a;
            addServiceIntoGroup(servicesByTypes, service.type, segmentId, convertedService, (_a = service.timelineStep) !== null && _a !== void 0 ? _a : getDefaultTimelineStep(service.type), relatedSegments.has(segmentId) && canBeAdded);
        });
    });
    brandIncluded.forEach(service => {
        service.baggage.includedSegments.forEach(segmentId => {
            var _a;
            if ((_a = servicesByTypes[segmentId]) === null || _a === void 0 ? void 0 : _a[OrderAdditionalServiceGdsServiceServiceType.Baggage]) {
                servicesByTypes[segmentId][OrderAdditionalServiceGdsServiceServiceType.Baggage].selectedServices.push({
                    count: service.count,
                    type: OrderAdditionalServiceGdsServiceServiceType.Baggage,
                    service: Object.assign(Object.assign({}, service.baggage), { _serviceType: 'gdsService' })
                });
            }
        });
    });
    seatMap.segments.forEach(segment => {
        const minPrice = {
            amount: Infinity,
            currency: 'RUB'
        };
        segment.decks.forEach(desk => {
            desk.rows.forEach(row => row.seats.forEach(seat => {
                if (seat.isAvailable && minPrice.amount > seat.price.amount) {
                    minPrice.amount = seat.price.amount;
                    minPrice.currency = seat.price.currency;
                }
            }));
        });
        servicesByTypes[segment.segmentsInfo.id][OrderAdditionalServiceGdsServiceServiceType.Seat] = {
            type: OrderAdditionalServiceGdsServiceServiceType.Seat,
            canAddService: segment.availableSeatsCount > 0,
            services: [],
            selectedServices: [],
            minPrice,
            totalPrice: {
                amount: 0,
                currency: Currency.RUB
            },
            step: getDefaultTimelineStep(OrderAdditionalServiceGdsServiceServiceType.Seat)
        };
    });
    if ((_a = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.trips) === null || _a === void 0 ? void 0 : _a.length) {
        aeroexpress.trips.forEach(trip => {
            var _a;
            (_a = trip.segments) === null || _a === void 0 ? void 0 : _a.forEach(segment => {
                var _a;
                addServiceIntoGroup(servicesByTypes, 'transfer', segment.id, Object.assign(Object.assign({}, trip), { _serviceType: 'aeroexpress' }), getDefaultTimelineStep('transfer', (_a = trip.toStation) === null || _a === void 0 ? void 0 : _a.direction));
            });
        });
        aeroexpress.selectedTrips.forEach(selectedTrip => {
            var _a;
            (_a = selectedTrip.segments) === null || _a === void 0 ? void 0 : _a.forEach(segment => {
                servicesByTypes[segment.id]['transfer'].selectedServices.push({
                    count: 1,
                    type: 'transfer',
                    service: Object.assign(Object.assign({}, selectedTrip), { _serviceType: 'aeroexpress' })
                });
            });
        });
    }
    if (insurances.length) {
        Object.keys(servicesByTypes).forEach(segmentId => {
            servicesByTypes[segmentId]['insuranceGroups'] = {
                type: 'insuranceGroups',
                services: [
                    {
                        _serviceType: 'insuranceGroups',
                        groups: insurances
                    }
                ],
                selectedServices: [],
                canAddService: true
            };
        });
    }
    if (everyPort === null || everyPort === void 0 ? void 0 : everyPort.services) {
        everyPort.services.forEach(everyPortService => {
            var _a, _b, _c;
            const segmentId = everyPortService.segmentId.toString();
            const selectedServices = (_b = (_a = everyPort.selectedServices) === null || _a === void 0 ? void 0 : _a.filter(service => service.product.id === everyPortService.id &&
                service.product.category === everyPortService.category)) !== null && _b !== void 0 ? _b : [];
            addServiceIntoGroup(servicesByTypes, everyPortService.category, segmentId, Object.assign(Object.assign({}, everyPortService), { _serviceType: 'nas' }), (_c = everyPortService.timelineStep) !== null && _c !== void 0 ? _c : getDefaultTimelineStep(everyPortService.category, everyPortService.direction), order.status !== OrderStatus.Cancelled && everyPortService.canBeAdded, selectedServices.map(service => ({
                service: Object.assign(Object.assign({}, everyPort.services.find(({ id }) => id === service.product.id)), { _serviceType: 'nas' }),
                type: service.product.category,
                count: 1
            })));
        });
    }
    Object.keys(selectedServices).forEach(passengerId => {
        Object.keys(selectedServices[passengerId]).forEach(serviceId => {
            selectedServices[passengerId][serviceId].forEach(service => {
                if (!!service.servicePayload.type) {
                    service.segments.forEach(segmentId => {
                        var _a;
                        const gdsService = service.servicePayload.type === OrderAdditionalServiceGdsServiceServiceType.Seat
                            ? Object.assign(Object.assign({}, service.servicePayload), { _serviceType: 'seat' }) : servicesByTypes[segmentId][service.servicePayload.type].services.find(service => service.id === serviceId);
                        (_a = servicesByTypes[segmentId][service.servicePayload.type]) === null || _a === void 0 ? void 0 : _a.selectedServices.push({
                            count: service.number,
                            service: gdsService,
                            type: service.servicePayload.type
                        });
                        const servicePrice = getServicePrice(gdsService);
                        servicesByTypes[segmentId][service.servicePayload.type].totalPrice.amount +=
                            servicePrice === null || servicePrice === void 0 ? void 0 : servicePrice.amount;
                        servicesByTypes[segmentId][service.servicePayload.type].totalPrice.currency =
                            servicePrice === null || servicePrice === void 0 ? void 0 : servicePrice.currency;
                    });
                }
            });
        });
    });
    const aggregatedServices = [];
    Object.keys(servicesByTypes).forEach(segmentId => {
        const services = [];
        Object.keys(servicesByTypes[segmentId]).forEach(type => {
            services.push(servicesByTypes[segmentId][type]);
        });
        aggregatedServices.push({
            segmentId,
            services
        });
    });
    return aggregatedServices;
});
export const getTravellersValueNames = createSelector(getTravellers, travellers => {
    const map = new Map();
    if ((travellers === null || travellers === void 0 ? void 0 : travellers.length) !== 0) {
        travellers[0].values.forEach(v => {
            map.set(v.type, v.name);
        });
    }
    return map;
});
export const getHasRequestedOrderServices = createSelector(getOrder, getIsBookedOrConfirmed, getTravellers, (order, isBookedOrConfirmed, travellers) => {
    return isBookedOrConfirmed && getHasRequestedServices(travellers, order.ssrData);
});
export const getExistGdsServicesOrInsurances = createSelector(getServices, getInsurances, (gdsServices, insurances) => {
    var _a;
    return gdsServices.length > 0 || ((_a = insurances === null || insurances === void 0 ? void 0 : insurances.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) > 0;
});
