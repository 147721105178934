import * as React from 'react';
import { Grid } from '@mui/material';
import { Field, useForm } from 'react-final-form';
import { TravellerFields } from '../../types';
import Input from '../../../BaseComponents/Input';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import PhoneInput from '../../../PhoneInput';
const ContactsSection = () => {
    const { ContactsSection: css } = useTheme('TravellerForm');
    const { t: tDocumentCardForm } = useTranslation('DocumentCardForm');
    const { t: tTraveller } = useTranslation('Traveller');
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const variant = isMobile ? 'underline' : 'labelIn';
    const form = useForm();
    return (React.createElement("div", { className: css.contactsSection },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                React.createElement(Field, { name: TravellerFields.Email, render: ({ input, meta }) => (React.createElement(Input, Object.assign({}, input, { className: css.input, variant: variant, value: input.value, label: `${tTraveller('email')}`, error: meta.error && meta.touched, helperText: meta.touched && tDocumentCardForm(meta.error), onChange: (event) => {
                            input.onChange(event);
                            form.mutators.fixEmptyFieldMutator({ fieldName: TravellerFields.Email });
                        } }))) })),
            React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                React.createElement(Field, { name: TravellerFields.Phone, type: "tel", render: ({ input, meta }) => (React.createElement(PhoneInput, Object.assign({}, input, { className: css.phoneInput, textFieldsProps: {
                            className: css.phoneInputInput,
                            variant: 'filled',
                            label: `${tTraveller('phone')}`,
                            error: meta.error && meta.touched,
                            helperText: meta.touched && tDocumentCardForm(meta.error),
                            onChange: (event) => {
                                input.onChange(event);
                                form.mutators.fixEmptyFieldMutator({ fieldName: TravellerFields.Phone });
                            }
                        }, value: input.value }))) })))));
};
export default ContactsSection;
