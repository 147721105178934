const restrictPerSegment = (travellers, restriction, currentCount, segmentId) => {
    const { restrictionType, value, maxCount } = restriction;
    let travellersServicesCount = 0;
    travellers.forEach(traveller => {
        const filteredServices = filterServices(traveller.services, restrictionType, value);
        filteredServices === null || filteredServices === void 0 ? void 0 : filteredServices.forEach(service => {
            const segment = service.segments.find(segment => segment.segmentId === segmentId);
            if (!!segment) {
                travellersServicesCount += segment.count;
            }
        });
    });
    const canBeAddedCount = maxCount - travellersServicesCount;
    return currentCount !== canBeAddedCount ? maxCount : canBeAddedCount;
};
const restrictOrder = (travellers, restriction, currentCount) => {
    const { restrictionType, value, maxCount } = restriction;
    let travellersServicesCount = 0;
    travellers.forEach(traveller => {
        const filteredServices = filterServices(traveller.services, restrictionType, value);
        filteredServices === null || filteredServices === void 0 ? void 0 : filteredServices.forEach(service => service.segments.forEach(segment => {
            travellersServicesCount += segment.count;
        }));
    });
    const canBeAddedCount = maxCount - travellersServicesCount;
    return currentCount !== canBeAddedCount ? maxCount : canBeAddedCount;
};
const filterServices = (travellersServices, restrictionType, value) => {
    if (restrictionType.includes('byRfisc')) {
        return travellersServices === null || travellersServices === void 0 ? void 0 : travellersServices.filter(service => value.includes(service.rfisc));
    }
    else if (restrictionType.includes('bySubType')) {
        return travellersServices === null || travellersServices === void 0 ? void 0 : travellersServices.filter(service => value.includes(service['subType']));
    }
    else if (restrictionType.includes('byType')) {
        return travellersServices === null || travellersServices === void 0 ? void 0 : travellersServices.filter(service => value.includes(service.type));
    }
    return [];
};
const detectRestriction = (gdsServiceRestrictions, gdsServiceToCheck) => {
    return gdsServiceRestrictions.find(({ restrictionType, value }) => {
        if (restrictionType.includes('byRfisc')) {
            return value.includes(gdsServiceToCheck.rfisc);
        }
        else if (restrictionType.includes('bySubType')) {
            return value.includes(gdsServiceToCheck['subType']);
        }
        else {
            return value.includes(gdsServiceToCheck.type);
        }
    });
};
export const calculateMaxServiceCount = (travellers, gdsServices, serviceIdToCheck, currentCount, segmentId) => {
    var _a;
    const formattedTravellers = travellers.map(traveller => {
        var _a, _b;
        const services = [];
        (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services.forEach(travellerService => {
            var _a;
            const gdsService = (_a = gdsServices.services) === null || _a === void 0 ? void 0 : _a.find(service => travellerService.serviceId === service.id);
            if (!!gdsService) {
                services.push({
                    rfisc: gdsService.rfisc,
                    type: gdsService.type,
                    subType: gdsService['subType'],
                    segments: travellerService.segmentIds.map(id => ({ segmentId: id, count: travellerService.count }))
                });
            }
            return null;
        });
        return { services };
    });
    const gdsServiceToCheck = (_a = gdsServices.services) === null || _a === void 0 ? void 0 : _a.find(service => service.id === serviceIdToCheck);
    const restriction = detectRestriction(gdsServices.servicesRestrictionsByType, gdsServiceToCheck);
    if (restriction === null || restriction === void 0 ? void 0 : restriction.restrictionType.includes('Segment')) {
        return restrictPerSegment(formattedTravellers, restriction, currentCount, segmentId);
    }
    else if (restriction === null || restriction === void 0 ? void 0 : restriction.restrictionType.includes('Order')) {
        return restrictOrder(formattedTravellers, restriction, currentCount);
    }
    return 9999999999;
};
