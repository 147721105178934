import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { OauthServiceType } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { initI18n } from '../../../utils';
import { icons } from './icons';
import AuthMethod from '../AuthMethods/AuthMethod/AuthMethod';
initI18n('SocialAuth');
const SocialAuth = ({ authMethods, onAuthRedirect, onDelete, socialAuthMethods }) => {
    const { t } = useTranslation('SocialAuth');
    const { SocialAuth: css } = useTheme('Account');
    const activeAuthMethods = React.useMemo(() => {
        if (authMethods === null || authMethods === void 0 ? void 0 : authMethods.length) {
            return authMethods.filter(method => method.status);
        }
        return [];
    }, [authMethods]);
    const availableCommonAuthMethods = React.useMemo(() => {
        if (activeAuthMethods.length) {
            return socialAuthMethods.filter(method => {
                var _a;
                return !((_a = activeAuthMethods.find(activeMethod => activeMethod.service.includes(method.service))) === null || _a === void 0 ? void 0 : _a.status) &&
                    method.isAvailable;
            });
        }
        return socialAuthMethods.filter(method => method.isAvailable);
    }, [activeAuthMethods, socialAuthMethods]);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: cn(css.wrapper__auth) },
            React.createElement("span", { className: css.wrapper__title }, t('Quick login to your account')), activeAuthMethods === null || activeAuthMethods === void 0 ? void 0 :
            activeAuthMethods.map(item => (React.createElement(AuthMethod, { key: item.id, id: item.id, icon: icons[item.service], type: item.service, value: item.userName, confirmed: true, isRemovable: true, onDelete: onDelete }))),
            availableCommonAuthMethods.length > 0 && (React.createElement("div", { className: css.login },
                activeAuthMethods.length > 0 && (React.createElement("span", { className: css.login__subtitle }, t('Connect another social media account'))),
                React.createElement("div", { className: css.login__auth }, availableCommonAuthMethods.map(method => (React.createElement("div", { "data-type": OauthServiceType[method.service], className: css.login__auth_icon, onClick: () => {
                        onAuthRedirect(OauthServiceType[method.service]);
                    } }, icons[method.service])))))))));
};
export default SocialAuth;
