import { applyMiddleware, createStore as originalCreateStore } from 'redux';
import logger from 'redux-logger';
import { rootReducer } from './reducers';
import memes from '../../memes';
export const createStore = memes((defaultParameters) => {
    let defaultState = {};
    const middlewares = [];
    if (defaultParameters === null || defaultParameters === void 0 ? void 0 : defaultParameters.segments) {
        defaultState = Object.assign(Object.assign({}, defaultState), { segments: defaultParameters.segments[0] });
    }
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(logger);
    }
    return originalCreateStore(rootReducer, defaultState, applyMiddleware(...middlewares));
});
