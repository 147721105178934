import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../utils';
import SocialAuth from '../../SocialAuth';
import { BaseButton } from '../../BaseComponents';
import { useTheme } from '../../theme';
import LoginPage from '../../LoginPage';
initI18n('MobileAuthForm');
const MobileAuthForm = ({ onSuccess, onReject, onSocialAuthClick }) => {
    const [showLoginForm, setShowLoginForm] = useState(false);
    const { MobileAuthForm: css } = useTheme('MobileAuthForm');
    const { t } = useTranslation('MobileAuthForm');
    const openLoginForm = () => setShowLoginForm(true);
    return !showLoginForm ? (React.createElement("div", { className: css.wrapper },
        React.createElement("h1", { className: css.title }, t('Sign in')),
        React.createElement("p", { className: css.sub },
            t('and fill in passengers info with a single click'),
            "."),
        React.createElement("div", { className: css.social },
            React.createElement(SocialAuth, { onClick: onSocialAuthClick, variant: "square" })),
        React.createElement("p", { className: css.email, onClick: openLoginForm }, t('Sign in with email')),
        React.createElement(BaseButton, { className: css.button, size: "large", variant: "smallSecondary", onClick: onReject }, t('Continue as a guest')))) : (React.createElement("div", { className: css.wrapper },
        React.createElement(LoginPage, { onSuccess: onSuccess, variant: "compact" })));
};
export default MobileAuthForm;
