import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { Umbrella, Dollars } from '../../../Icons';
export const Banner = ({ className, code, popular }) => {
    const { t } = useTranslation('InsurancePage');
    const { Banner: theme } = useTheme('InsurancePage');
    return (React.createElement("div", { className: cn(theme.banner, className), "data-insurance-program-code": code },
        popular && React.createElement("div", { className: theme.popularBadge }, t('Most popular')),
        React.createElement("div", { className: theme.icons },
            React.createElement("div", { className: theme.icon }, Umbrella),
            React.createElement("div", { className: theme.icon }, Dollars))));
};
