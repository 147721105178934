import * as React from 'react';
import OrderCard from '../../../OrderCard';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
const NextFlight = props => {
    const { t } = useTranslation('Account');
    const { NextFlight: css } = useTheme('Account');
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.title }, t('Next flights')),
        React.createElement(OrderCard, Object.assign({ type: 'account' }, props))));
};
export default NextFlight;
