import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSaveOrderServicesMutation } from '@websky/graphql';
import { CartService } from '../../../../Cart/types';
import { getAeroexpress, getOrder, getTravellers } from '../../../store/order/selectors';
import { setServiceAction } from '../../../store/selectedServices/actions';
import { useInsurance } from '../../../../CheckoutAdditionalService/components/Insurance/hooks';
import { getPassengerState } from '../../../store/passengers/selectors';
import { fillOrder } from '../../../store/order/actions';
import { isServicesSaving } from '../../../store/servicesIsSaving/selectors';
import { getCartServicesToDelete } from '../../../utils';
export const useCart = () => {
    var _a, _b;
    const order = useSelector(getOrder), dispatch = useDispatch();
    const [saveOrderServicesMutation, { data, loading }] = useSaveOrderServicesMutation();
    const travellers = useSelector(getTravellers);
    const passengers = useSelector(getPassengerState);
    const aeroexpress = useSelector(getAeroexpress);
    const servicesSaving = useSelector(isServicesSaving);
    const { onSave } = useInsurance({
        orderId: order.id,
        insurances: order.additionalServices.insurances,
        insurancePrograms: (_a = order.additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms,
        passengers,
        onSaveServices: saveOrderServicesMutation
    });
    const aeroexpressSelectedIds = (_b = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.selectedTrips) === null || _b === void 0 ? void 0 : _b.map(trip => trip.id);
    useEffect(() => {
        if (!loading && data && data.SaveOrderServices) {
            dispatch(fillOrder(data === null || data === void 0 ? void 0 : data.SaveOrderServices));
        }
    }, [data, loading]);
    const onDeleteItem = useCallback((type, service) => {
        // GdsServices
        if (type === CartService.Baggage ||
            type === CartService.Meal ||
            type === CartService.Seats ||
            type === CartService.Extra) {
            const gdsService = service;
            const segmentIds = gdsService.allSegments
                ? order.flight.segments.map(segment => segment.segment.id)
                : [gdsService.segmentId];
            segmentIds.forEach(segmentId => {
                var _a;
                dispatch(setServiceAction({
                    allowedSegments: gdsService.allowedSegments,
                    passengerId: gdsService.passengerId,
                    segmentId,
                    serviceId: gdsService.serviceId,
                    count: (_a = gdsService.count) !== null && _a !== void 0 ? _a : 0
                }));
            });
        }
        else if (type === CartService.BusinessLounge || type === CartService.SMS) {
            const gdsService = service;
            const servicesToDelete = getCartServicesToDelete(gdsService, travellers);
            servicesToDelete.forEach(service => {
                dispatch(setServiceAction(service));
            });
        }
        else if (type === CartService.Insurances) {
            onSave(order.additionalServices.insurances.selectedInsurances
                .filter(selected => selected.insuranceProgram.code !== service.code)
                .map(selected => selected.insuranceProgram.code));
        }
        else if (type === CartService.Aeroexpress) {
            const aeroexpress = service;
            saveOrderServicesMutation({
                variables: {
                    params: {
                        id: order.id,
                        aeroexpress: {
                            selectedTrips: aeroexpressSelectedIds.filter(id => id !== aeroexpress.tripId)
                        }
                    }
                }
            });
        }
    }, [order, travellers, passengers, aeroexpressSelectedIds, onSave, dispatch]);
    return {
        onDeleteItem,
        loading: loading || servicesSaving
    };
};
