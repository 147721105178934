import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { seatIcon } from '../../Icons';
import { useTheme } from '../../../theme';
import MediaQuery from '../../../MediaQuery/MediaQuery';
const Header = ({ className, name, seats, controls, isCheckedIn = true }) => {
    var _a, _b;
    const { Header: css } = useTheme('RegisteredPassenger');
    const { t } = useTranslation('RegisteredPassenger');
    const seatServiceClass = (_b = (_a = seats === null || seats === void 0 ? void 0 : seats[0]) === null || _a === void 0 ? void 0 : _a.seat.seatService) === null || _b === void 0 ? void 0 : _b.serviceClass;
    return (React.createElement("div", { className: cn(css.container, className, {
            [css.disabled]: !isCheckedIn
        }) },
        React.createElement("div", { className: css.info },
            React.createElement("div", { className: css.passenger },
                React.createElement("div", { className: css.passenger__label }, t('Passenger')),
                React.createElement("div", { className: css.passenger__name }, name)),
            (seats === null || seats === void 0 ? void 0 : seats.length) > 0 && (React.createElement("div", { className: css.seats },
                React.createElement("div", { className: css.seats__label }, t('Seat')),
                React.createElement("div", { className: css.seats__icon }, seatIcon),
                React.createElement("span", { className: css.seats__text },
                    seats.map(service => service.seat.number).join(', '),
                    React.createElement(MediaQuery, { minWidth: "mobile" }, seatServiceClass ? React.createElement(React.Fragment, null,
                        ", ",
                        t(seatServiceClass)) : ''))))),
        React.createElement("div", { className: css.controls }, controls)));
};
export default Header;
