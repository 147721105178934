import { __awaiter } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ApolloError } from 'apollo-boost';
import { ExareReason, ExareStatus, TravellerFieldEnum, useConfirmChangeTravellerOrderMutation, useCreateChangeTravellerDataMutation, useRefuseExareMutation } from '@websky/graphql';
import { getCustomer, getOrder } from '../../../Checkout/store/order/selectors';
import { capitalizeFirstLetter, getPassengerNames, getUserValue } from '../../../utils';
import { getDifferentPassengerValues } from '../../utils';
export const usePassengerTicketControls = ({ lastChangePaxData }) => {
    const [requestError, setRequestError] = React.useState(null);
    const [createChangePaxData, { loading: createChangeDataLoading }] = useCreateChangeTravellerDataMutation();
    const [refuseExare, { loading: refuseExareLoading }] = useRefuseExareMutation();
    const [confirmChangeTravellerOrder, { loading: confirmChangeLoading }] = useConfirmChangeTravellerOrderMutation();
    const availableActions = React.useMemo(() => {
        return {
            refuse: !!(lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.refuseAllowed),
            payment: (lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.status) === ExareStatus.AwaitingPayment,
            confirm: (lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.status) === ExareStatus.NeedConfirm,
            createNew: lastChangePaxData && [ExareStatus.Rejected, ExareStatus.Finished].includes(lastChangePaxData.status)
        };
    }, [lastChangePaxData]);
    const onRequestError = React.useCallback((e) => {
        var _a;
        if (e instanceof ApolloError) {
            if ((_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
                setRequestError(e.graphQLErrors[0]);
            }
        }
    }, [setRequestError]);
    const onClearError = React.useCallback(() => {
        setRequestError(null);
    }, [setRequestError]);
    const onCreateOrderRequest = React.useCallback((orderId, values, changeTravellers) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield createChangePaxData({
                variables: {
                    parameters: {
                        orderId,
                        name: values.name,
                        email: values.email,
                        phone: values.phone,
                        comment: values.comment,
                        travellers: [...changeTravellers]
                    }
                }
            });
            return data;
        }
        catch (e) {
            onRequestError(e);
            return null;
        }
    }), [createChangePaxData, onRequestError]);
    const onRefuseRequest = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield refuseExare({
                variables: { id: lastChangePaxData.id }
            });
            return data;
        }
        catch (e) {
            onRequestError(e);
            return null;
        }
    }), [refuseExare, lastChangePaxData, onRequestError]);
    const onConfirmRequest = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield confirmChangeTravellerOrder({ variables: { id: lastChangePaxData.id } });
            return data;
        }
        catch (e) {
            onRequestError(e);
            return null;
        }
    }), [confirmChangeTravellerOrder, lastChangePaxData, onRequestError]);
    return {
        // data
        requestError,
        availableActions,
        isLoading: createChangeDataLoading || refuseExareLoading || confirmChangeLoading,
        // functions
        onCreateOrderRequest,
        onRefuseRequest,
        onConfirmRequest,
        onRequestError,
        onClearError
    };
};
export const usePassengerTicket = (passenger, prices, onGoToPayment, onRefetchOrder, onReturnToOrder, setChangePaxOrder, changeTravellers, lastChangePaxData, reason) => {
    const { t } = useTranslation('EditPassenger');
    const order = useSelector(getOrder);
    const customer = useSelector(getCustomer);
    const ticketControls = usePassengerTicketControls({ lastChangePaxData });
    const fullName = React.useMemo(() => {
        const { firstName, lastName } = getPassengerNames(passenger);
        return [lastName, firstName].map(capitalizeFirstLetter).join(' ');
    }, [passenger]);
    const customerValues = React.useMemo(() => {
        const isFirstPassenger = passenger.id === '0';
        let name = getUserValue(customer, TravellerFieldEnum.FirstName);
        if (isFirstPassenger || !name) {
            const changedName = (changeTravellers === null || changeTravellers === void 0 ? void 0 : changeTravellers.length)
                ? getUserValue(changeTravellers[0], TravellerFieldEnum.FirstName)
                : null;
            const passengerName = getUserValue(passenger, TravellerFieldEnum.FirstName);
            name = changedName || passengerName;
        }
        return {
            name,
            email: getUserValue(customer, TravellerFieldEnum.Email),
            phone: getUserValue(customer, TravellerFieldEnum.Phone)
        };
    }, [customer, passenger, changeTravellers]);
    const editableValues = React.useMemo(() => {
        var _a;
        let actualTravellerValues = [];
        if (changeTravellers === null || changeTravellers === void 0 ? void 0 : changeTravellers.length) {
            actualTravellerValues = changeTravellers[0].values;
        }
        else if ((_a = lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.travellers) === null || _a === void 0 ? void 0 : _a.length) {
            actualTravellerValues = lastChangePaxData.travellers[0].values;
        }
        const differentValues = getDifferentPassengerValues(passenger.values, actualTravellerValues);
        return [...differentValues.entries()].map(([type, inputParameter]) => {
            const value = inputParameter.value;
            if (type === TravellerFieldEnum.DocType) {
                return `${t(`Passenger:docType`)}: ${t(`Passenger:${value}`)}`;
            }
            return `${t(`Passenger:${inputParameter.name}`)}: ${value}`;
        });
    }, [passenger, changeTravellers, lastChangePaxData]);
    const actualPrices = React.useMemo(() => {
        var _a;
        let ticketPrices;
        if (lastChangePaxData) {
            ticketPrices = lastChangePaxData.prices;
        }
        else if (prices) {
            ticketPrices = prices;
        }
        if (ticketPrices) {
            let priceToPay = null;
            if (((_a = ticketPrices.priceToPay) === null || _a === void 0 ? void 0 : _a.amount) !== undefined) {
                priceToPay = Object.assign(Object.assign({}, ticketPrices.priceToPay), { amount: Math.abs(ticketPrices.priceToPay.amount) });
            }
            return Object.assign(Object.assign({}, ticketPrices), { priceToPay });
        }
        return null;
    }, [lastChangePaxData, prices]);
    const isAutoMode = React.useMemo(() => {
        var _a, _b, _c;
        const autoMode = (_c = (_b = (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.changeTravellerDataAvailability) === null || _b === void 0 ? void 0 : _b.find(changeTraveller => changeTraveller.id === passenger.id)) === null || _c === void 0 ? void 0 : _c.autoMode;
        return reason !== ExareReason.DocumentReplacement && autoMode;
    }, [order.exareInfo, passenger, reason]);
    const onFillOrder = React.useCallback((changeTravellerOrder) => {
        const status = changeTravellerOrder === null || changeTravellerOrder === void 0 ? void 0 : changeTravellerOrder.status;
        if ([ExareStatus.AwaitingPayment, ExareStatus.NeedConfirm].includes(status)) {
            setChangePaxOrder(changeTravellerOrder);
            if (status === ExareStatus.AwaitingPayment) {
                onGoToPayment();
            }
        }
        else {
            onReturnToOrder(true);
        }
    }, [setChangePaxOrder, onGoToPayment, onReturnToOrder]);
    const onCreateOrderRequest = React.useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield ticketControls.onCreateOrderRequest(order.id, values, changeTravellers);
        if (data === null || data === void 0 ? void 0 : data.CreateChangeTravellerData) {
            onFillOrder(data.CreateChangeTravellerData);
        }
        return !!data;
    }), [ticketControls.onCreateOrderRequest, order, changeTravellers, onFillOrder]);
    const onRefuseRequest = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield ticketControls.onRefuseRequest();
        if ((data === null || data === void 0 ? void 0 : data.RefuseExare) && data.RefuseExare.status === ExareStatus.Refused) {
            yield onRefetchOrder();
        }
    }), [ticketControls.onRefuseRequest, onRefetchOrder]);
    const onConfirmRequest = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield ticketControls.onConfirmRequest();
        if (data === null || data === void 0 ? void 0 : data.ConfirmExare) {
            onFillOrder(data.ConfirmExare);
        }
    }), [ticketControls.onConfirmRequest, setChangePaxOrder]);
    return {
        // data
        order,
        customer,
        fullName,
        customerValues,
        editableValues,
        actualPrices,
        requestError: ticketControls.requestError,
        availableActions: ticketControls.availableActions,
        isLoading: ticketControls.isLoading,
        isAutoMode,
        // functions
        onCreateOrderRequest,
        onRefuseRequest,
        onConfirmRequest,
        onClearError: ticketControls.onClearError
    };
};
