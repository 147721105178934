import { __rest } from "tslib";
import * as React from 'react';
import { useMemo, useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import MediaQuery from 'react-responsive';
import { AircraftInfoParameterType } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { ArrowForward, Chevron, Overweight, Passenger, Planet } from '../../../Icons';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../utils';
import { SeatIcon } from '../../Icons';
const availableParameters = [
    AircraftInfoParameterType.CruisingSpeed,
    AircraftInfoParameterType.MaxFlightRange,
    AircraftInfoParameterType.PassengerCapacity
];
initI18n('Aircraft');
const AircraftPopupContent = ({ className, name, aircraftInfo, onClose }) => {
    var _a, _b, _c, _d, _e;
    const { AircraftPopupContent: css } = useTheme('AircraftPopup');
    const { t } = useTranslation('Aircraft');
    const Arrow = (_a) => {
        var { className, style, isPrev } = _a, props = __rest(_a, ["className", "style", "isPrev"]);
        const { currentSlide, slideCount } = props, restProps = __rest(props, ["currentSlide", "slideCount"]);
        return (React.createElement("div", Object.assign({ className: cn(css.arrow, {
                [css.arrow_prev]: isPrev
            }) }, restProps), Chevron));
    };
    const sliderSettings = {
        slidesToShow: 1,
        infinite: true,
        speed: 600,
        dots: true,
        prevArrow: React.createElement(Arrow, { isPrev: true }),
        nextArrow: React.createElement(Arrow, null),
        appendDots: dots => React.createElement("ul", null, dots)
    };
    const icons = {
        [AircraftInfoParameterType.CruisingSpeed]: Overweight,
        [AircraftInfoParameterType.MaxFlightRange]: Planet,
        [AircraftInfoParameterType.PassengerCapacity]: Passenger
    };
    const filteredParameters = useMemo(() => {
        var _a, _b, _c;
        if ((_a = aircraftInfo.parameters) === null || _a === void 0 ? void 0 : _a.length) {
            return (_c = (_b = aircraftInfo.parameters) === null || _b === void 0 ? void 0 : _b.filter(param => availableParameters.includes(param.type))) !== null && _c !== void 0 ? _c : [];
        }
        return [];
    }, [aircraftInfo.parameters]);
    const renderParameters = useCallback(() => (React.createElement("div", { className: css.groups }, filteredParameters.map((parameter, index) => {
        const isNanValue = isNaN(+parameter.value.replace(/\s+/gm, ''));
        return (React.createElement("div", { className: cn(css.groups_item, css[parameter.type]), key: index },
            React.createElement("div", { className: css.groups_itemTitle },
                React.createElement("div", { className: css.groups_itemIcon }, icons[parameter.type]),
                React.createElement("span", null, parameter.title)),
            React.createElement("div", { className: css.groups_itemDescription },
                React.createElement("span", { className: cn(css.groups_itemValue, {
                        [css.groups_itemValue_text]: isNanValue
                    }) },
                    parameter.value,
                    !isNanValue && (React.createElement("span", { className: css.groups_itemUnit }, t(`Unit_${parameter.type}`)))))));
    }))), [filteredParameters]);
    const hasImagesOrSchema = ((_a = aircraftInfo.images) === null || _a === void 0 ? void 0 : _a.length) > 0 || aircraftInfo.scheme;
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.back, onClick: onClose }, ArrowForward),
            React.createElement("div", { className: css.title }, (_b = aircraftInfo.title) !== null && _b !== void 0 ? _b : name),
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, renderParameters())),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Slider, Object.assign({}, sliderSettings, { className: css.slider, dotsClass: css.slider_dots }), (_c = aircraftInfo.images) === null || _c === void 0 ? void 0 :
                _c.map((image, index) => (React.createElement("div", { className: css.image, key: index },
                    React.createElement("img", { src: image, alt: "" })))),
                aircraftInfo.scheme && (React.createElement("div", { className: css.image },
                    React.createElement("img", { src: aircraftInfo.scheme, alt: "" }))),
                !hasImagesOrSchema && React.createElement("div", { className: cn(css.image, css.default_aircraft) }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: css.content },
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, renderParameters()), (_d = aircraftInfo.images) === null || _d === void 0 ? void 0 :
                _d.map((image, index) => (React.createElement("div", { key: index, className: css.image },
                    React.createElement("img", { src: image, alt: "" })))),
                aircraftInfo.scheme && (React.createElement("div", { className: css.schema },
                    React.createElement("img", { src: aircraftInfo.scheme, alt: "" }))),
                !hasImagesOrSchema && React.createElement("div", { className: cn(css.image, css.default_aircraft) })),
            aircraftInfo.scheme && (React.createElement("div", { className: css.footer },
                React.createElement("div", { className: css.cabins }, (_e = aircraftInfo.cabins) === null || _e === void 0 ? void 0 : _e.map(cabin => (React.createElement("div", { className: css.cabins_item, key: cabin.title },
                    React.createElement("div", { className: cn(css.cabins_itemIcon, {
                            [css[cabin.title]]: css === null || css === void 0 ? void 0 : css[cabin.title]
                        }) }, SeatIcon),
                    React.createElement("div", { className: css.cabins_itemTitle }, t(`${cabin.title}`)))))))))));
};
export default AircraftPopupContent;
