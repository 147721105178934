import * as React from 'react';
import PromoLoader from '../../../PromoLoader';
import { useLastRefundQuery } from '../Refund/hooks';
const GetLastRefundTicket = ({ children, orderId }) => {
    const { data: lastRefund, loading } = useLastRefundQuery(orderId);
    if (loading) {
        return React.createElement(PromoLoader, null);
    }
    return children(lastRefund);
};
export default GetLastRefundTicket;
