import { __awaiter } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Passengers from '../routes/Passengers';
import Segments from '../routes/Segments';
import { useSelector } from 'react-redux';
import { getOrder, isOpenedExchange } from '../../../store/order/selectors';
import SearchFlights from '../routes/SearchFlights';
import { useTheme } from '../../../../../theme';
import Request from '../routes/Request';
import Selections from './Selections/Selections';
import { useTranslation } from 'react-i18next';
import Payment from '../routes/Payment';
import Dialog from '../Dialog/Dialog';
import { getNewFlightId } from '../../../store/newFlights/selectors';
import { getSelectedPassengerIds } from '../../../store/passengers/selectors';
import { getFlightsToExchange } from '../../../store/selectors';
import WarningModal from '../../../../../WarningModal';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../../../utils';
import { ExchangeStep } from '../../../types';
import { ExareStatus, useOrderQuery } from '@websky/graphql';
import Reasons from '../routes/Reasons';
import MediaQuery from 'react-responsive';
import SimpleLoader from '../../../../../SimpleLoader';
import PromoLoader from '../../../../../PromoLoader';
import FullscreenDialogClose from '../../../../../FullscreenDialog/components/Close/Close';
import { useConfig } from '../../../../../context';
var PaymentResult;
(function (PaymentResult) {
    PaymentResult["Success"] = "success";
    PaymentResult["Failed"] = "failed";
})(PaymentResult || (PaymentResult = {}));
initI18n('Exchange');
const Inner = props => {
    const orderQuery = useOrderQuery({
        variables: {
            id: props.orderId
        },
        skip: true,
        fetchPolicy: 'no-cache'
    });
    const { Engine: { exareShowPassengersStep } } = useConfig();
    const order = useSelector(getOrder);
    const match = useRouteMatch(), baseUrl = match.url;
    const history = useHistory();
    const openedExchange = useSelector(isOpenedExchange);
    const newFlightId = useSelector(getNewFlightId);
    const [isLoading, setLoading] = React.useState(false);
    const selectedPassengers = useSelector(getSelectedPassengerIds);
    const selectedFlights = useSelector(getFlightsToExchange);
    const [requestError, setRequestError] = React.useState(null);
    const [paymentStatus, setPaymentStatus] = React.useState(null);
    const passengersPageURL = baseUrl.replace('exchange', 'order');
    const handleReturnToPassengers = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        yield (props === null || props === void 0 ? void 0 : props.onRefetchCheckoutOrder());
        history.push(passengersPageURL);
        setLoading(false);
    });
    const { t } = useTranslation('Exchange');
    const reloadOrder = () => {
        setLoading(true);
        orderQuery.refetch().then(data => {
            props.setOrder(data.data.Order);
            setLoading(false);
        });
    };
    useEffect(() => {
        if (!order || order.id !== props.orderId) {
            reloadOrder();
        }
        return () => {
            setLoading(false);
        };
    }, [order]);
    const refreshOrder = (failedExchange) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g;
        setLoading(true);
        let order;
        try {
            order = yield orderQuery.refetch();
        }
        catch (error) {
            setRequestError(error);
        }
        if (failedExchange) {
            if ((_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.data) === null || _a === void 0 ? void 0 : _a.Order) === null || _b === void 0 ? void 0 : _b.exareInfo) === null || _c === void 0 ? void 0 : _c.lastExchange) {
                props.setOrder(order.data.Order);
                props.clearNewFlight();
            }
            else {
                yield handleReturnToPassengers();
            }
            setLoading(false);
            setPaymentStatus(null);
            return;
        }
        if (((_g = (_f = (_e = (_d = order === null || order === void 0 ? void 0 : order.data) === null || _d === void 0 ? void 0 : _d.Order) === null || _e === void 0 ? void 0 : _e.exareInfo) === null || _f === void 0 ? void 0 : _f.lastExchange) === null || _g === void 0 ? void 0 : _g.status) === ExareStatus.Finished) {
            props.setOrder(order.data.Order);
            props.clearNewFlight();
            yield handleReturnToPassengers();
            setLoading(false);
            setPaymentStatus(null);
        }
        else {
            const polling = (interval) => __awaiter(void 0, void 0, void 0, function* () {
                var _h, _j, _k, _l;
                const order = yield orderQuery.refetch();
                if (((_l = (_k = (_j = (_h = order === null || order === void 0 ? void 0 : order.data) === null || _h === void 0 ? void 0 : _h.Order) === null || _j === void 0 ? void 0 : _j.exareInfo) === null || _k === void 0 ? void 0 : _k.lastExchange) === null || _l === void 0 ? void 0 : _l.status) === ExareStatus.Finished) {
                    props.setOrder(order.data.Order);
                    props.clearNewFlight();
                    yield handleReturnToPassengers();
                    setLoading(false);
                    setPaymentStatus(null);
                }
                else {
                    setTimeout(() => polling(interval * 1.5), interval * 1.5);
                }
            });
            setTimeout(() => polling(500), 500);
        }
        setLoading(false);
    });
    React.useEffect(() => {
        if (paymentStatus === PaymentResult.Success) {
            refreshOrder();
        }
    }, [paymentStatus]);
    const setStep = (newStep) => {
        if (newStep === ExchangeStep.Segments) {
            history.push(`${baseUrl}/segments`);
        }
        else if (newStep === ExchangeStep.NewFlights) {
            history.push(`${baseUrl}/flights`);
        }
        else if (newStep === ExchangeStep.Request) {
            history.push(`${baseUrl}/request`);
        }
        else if (newStep === ExchangeStep.Passengers) {
            history.push(`${baseUrl}/passengers`);
        }
        else if (newStep === ExchangeStep.Payment) {
            history.push(`${baseUrl}/payment`);
        }
        else if (newStep === ExchangeStep.Reasons) {
            history.push(`${baseUrl}/reasons`);
        }
    };
    const theme = useTheme('Exchange').Inner;
    const successPayment = () => setPaymentStatus(PaymentResult.Success);
    const failedPayment = () => setPaymentStatus(PaymentResult.Failed);
    const setExchangeSuccess = () => {
        history.push(`${baseUrl}/payment/success`);
    };
    if (!order) {
        return React.createElement(PromoLoader, null);
    }
    return (React.createElement("div", { className: theme.exchange },
        paymentStatus === PaymentResult.Success && (React.createElement(Dialog, { text: t('Successful payment'), acceptText: t('OK'), onAccept: () => setPaymentStatus(null), iconClassName: theme.dialog__paymentIcon, open: true })),
        paymentStatus === PaymentResult.Failed && (React.createElement(Dialog, { text: t('Failed payment'), acceptText: t('OK'), onAccept: () => setPaymentStatus(null), iconClassName: theme.dialog__failedIcon, open: true })),
        isLoading && React.createElement(SimpleLoader, null),
        requestError && React.createElement(WarningModal, { title: t('Oops...'), content: requestError }),
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.header },
                React.createElement("div", { className: theme.header__inner },
                    React.createElement("div", { className: theme.header__text }, t('Change ticket')),
                    React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, !openedExchange && React.createElement(Selections, { setStep: setStep })),
                    React.createElement(FullscreenDialogClose, { text: t('Back to order'), onClose: handleReturnToPassengers, closeClassName: theme.close, textClassName: theme.close__text }))),
            React.createElement("div", { className: theme.container },
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, !openedExchange && React.createElement(Selections, { setStep: setStep })),
                React.createElement(Switch, null,
                    React.createElement(Route, { path: `${baseUrl}/passengers`, render: () => !openedExchange ? (React.createElement(Passengers, { setStep: setStep, skipPassengersStep: !exareShowPassengersStep })) : (React.createElement(Redirect, { to: `${baseUrl}/request` })) }),
                    React.createElement(Route, { path: `${baseUrl}/reasons`, render: () => selectedPassengers.length ? (React.createElement(Reasons, { setStep: setStep, passengersPageURL: passengersPageURL })) : (React.createElement(Redirect, { to: `${baseUrl}/passengers` })) }),
                    React.createElement(Route, { path: `${baseUrl}/segments`, render: () => selectedPassengers.length ? (React.createElement(Segments, { setStep: setStep, passengersPageURL: passengersPageURL })) : (React.createElement(Redirect, { to: `${baseUrl}/passengers` })) }),
                    React.createElement(Route, { path: `${baseUrl}/flights`, render: () => selectedPassengers.length && selectedFlights.flightsByIds.length ? (React.createElement(SearchFlights, { setStep: setStep, setLoading: setLoading })) : (React.createElement(Redirect, { to: `${baseUrl}/passengers` })) }),
                    React.createElement(Route, { path: `${baseUrl}/request`, render: () => {
                            var _a;
                            return ((_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange) || newFlightId ? (React.createElement(Request, { baseUrl: baseUrl, setStep: setStep, clearSelectedFares: props.clearNewFlight, setExchangeSuccess: setExchangeSuccess, refreshOrderAfterExchange: refreshOrder, reloadOrder: reloadOrder, newFlightId: newFlightId, goToOrder: handleReturnToPassengers, returnToOrder: handleReturnToPassengers, refreshOrder: refreshOrder })) : (React.createElement(Redirect, { to: `${baseUrl}/passengers` }));
                        } }),
                    React.createElement(Route, { exact: true, path: `${baseUrl}/payment`, render: () => (React.createElement(Payment, { setStep: setStep, successPayment: successPayment, failedPayment: failedPayment, setLoading: setLoading })) }),
                    React.createElement(Route, { path: `${baseUrl}/payment/success`, render: () => {
                            setPaymentStatus(PaymentResult.Success);
                            return React.createElement(Redirect, { to: `${baseUrl}/request` });
                        } }),
                    React.createElement(Route, { path: `${baseUrl}/payment/failed`, render: () => {
                            setPaymentStatus(PaymentResult.Failed);
                            return React.createElement(Redirect, { to: `${baseUrl}/request` });
                        } }),
                    React.createElement(Route, { render: () => React.createElement(Redirect, { to: `${baseUrl}/request` }) }))))));
};
export default Inner;
