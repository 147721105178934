import * as React from 'react';
import { useTheme } from '../../../theme';
import { arrowIcon, emptyCard } from '../../Icons';
import { ButtonBase } from '@material-ui/core';
const Gateway = ({ type, header, description, onClick, paymentType }) => {
    const { Gateway: css } = useTheme('PaymentForm');
    return (React.createElement(ButtonBase, { className: css.selector, onClick: onClick, "data-payment-type": paymentType },
        React.createElement("div", { className: css.header }, header !== null && header !== void 0 ? header : emptyCard,
            React.createElement("span", { className: css.header__arrow },
                React.createElement("span", null, type),
                " ",
                React.createElement("span", { className: css.arrow }, arrowIcon))),
        description && React.createElement("p", { className: css.description }, description)));
};
export default Gateway;
